export const listAcceptedDomains = /* GraphQL */ `
  query ListAcceptedDomains($filter:ModelAcceptedDomainFilterInput, $limit:Int, $nextToken: String ) {
    listAcceptedDomains(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        emailDomain
        createdAt
        updatedAt
        status
        _version
        _deleted
        company {
          id
          name
        }
      }
      nextToken
    }
  }
`;