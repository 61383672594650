/**
 * Este reducer maneja el estado para los componentes del catalogo de unidades de medida
 */
import dayjs from "dayjs";

import {
  FETCH_UOM_CATALAOG_ERROR,
  FETCH_UOM_CATALAOG_PENDING,
  FETCH_UOM_CATALAOG_SUCCESS,
  FETCH_NEXT_UOM_CATALOG_SUCCESS,
  CREATE_UOM_SUCCESS,
  CREATE_UOM_PENDING,
  CREATE_UOM_ERROR,
  UPDATE_UOM_SUCCESS,
  UPDATE_UOM_PENDING,
  UPDATE_UOM_ERROR,
  CLEAN_UOM_REDUCER,
} from "redux/action";

import { REQUEST_STATUS } from "constant/requestStatus";

const INITIAL_STATE = {
  uoms: [],
  uomsToken: null,
  fetchStatus: REQUEST_STATUS.INACTIVE,
  fetchCreationStatus: REQUEST_STATUS.INACTIVE,
  fetchUpdateStatus: REQUEST_STATUS.INACTIVE,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_UOM_CATALAOG_PENDING:
      return { ...state, fetchStatus: REQUEST_STATUS.PENDING };
    case FETCH_UOM_CATALAOG_SUCCESS:
      return addUOMCatalog(state, action);
    case FETCH_NEXT_UOM_CATALOG_SUCCESS:
      const newState = addUOMCatalog(state, action);
      newState.uoms = [...state.uoms, ...newState.uoms];
      return newState;
    case FETCH_UOM_CATALAOG_ERROR:
      return { ...state, fetchStatus: REQUEST_STATUS.FAILED, uoms: [] };
    case CREATE_UOM_SUCCESS:
      return { ...state, fetchCreationStatus: REQUEST_STATUS.SUCCESSFUL };
    case CREATE_UOM_PENDING:
      return { ...state, fetchCreationStatus: REQUEST_STATUS.PENDING };
    case CREATE_UOM_ERROR:
      return { ...state, fetchCreationStatus: REQUEST_STATUS.FAILED };
    case UPDATE_UOM_SUCCESS:
      return { ...state, fetchUpdateStatus: REQUEST_STATUS.SUCCESSFUL };
    case UPDATE_UOM_PENDING:
      return { ...state, fetchUpdateStatus: REQUEST_STATUS.PENDING };
    case UPDATE_UOM_ERROR:
      return { ...state, fetchUpdateStatus: REQUEST_STATUS.FAILED };
    case CLEAN_UOM_REDUCER:
      return INITIAL_STATE;
    default:
      return state;
  }
}

const addUOMCatalog = (state, action) => {
  const {
    listUnitOfMeassures: { items = [], nextToken },
  } = action.payload;
  return {
    ...state,
    uoms: transformUomsData(items),
    uomsToken: nextToken,
    fetchStatus: REQUEST_STATUS.SUCCESSFUL,
  };
};

const transformUomsData = (items) => {
  return items.map((item) => {
    const formattedCreatedAtDate = dayjs(item.createdAt).format("DD/MM/YYYY HH:mm");
    const formattedUpdatedAtDate = dayjs(item.updatedAt).format("DD/MM/YYYY HH:mm");
    return {
      ...item,
      createdAt: formattedCreatedAtDate,
      updatedAt: formattedUpdatedAtDate,
    };
  });
};
