import { TaskStatus } from "models";

export function scheduleAssetsSend(deliveryData, assets = [], projectId) {
  const taskParams = `$input: CreateTaskInput!, `;
  const taskParamsObj = {
    input: {
      status: TaskStatus.SHIPMENT_SCHEDULED,
      taskSupervisorId: deliveryData.supervisorId,
      taskTaskTypeId: "assets-shipment",
      taskProjectId: projectId,
      taskPremisesId: deliveryData.premise,
      startDate: deliveryData.date,
      startTime: deliveryData.time,
      category: "SHIPMENT",
    },
  };
  const taskOperation = `
    createTask(input: $input) {  
      id
      status
      startDate
      endDate
      startTime
      endTime
      category
      owner
      createdAt
      updatedAt
      _version
      _lastChangedAt
      _deleted
      premises {
        id
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      buildingLocation {
        id
        name
        code
        buildingBlock
        floor
        area
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      taskType {
        id
        name
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      supervisor {
        id
        name
        middlename
        lastnameP
        lastnameM
        email
        phone
        extension
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      assets {
        items {
          id
          asset {
            id
            code
            serial
            quantity
            type
            material {
              id
              name
              code
              description
              manufacturer
              trackable
              enabled
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            locationCode
            tasks {
              nextToken
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      attachments {
        items {
          id
          file {
            bucket
            region
            key
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      project {
        id
        name
        code
        company {
          id
          name
        }
      }
    },\n
  `;

  let assetsParams = "";
  let assetsParamsObj = {};
  let assetsOperations = "";

  assets.forEach(({ id, _version }, index) => {
    assetsParams += `$assetInput${index}: UpdateAssetInput!, `;
    assetsParamsObj[`assetInput${index}`] = {
      id,
      type: TaskStatus.SHIPMENT_SCHEDULED,
      enabled: false,
      _version,
    };
    assetsOperations += `
      updateAsset${index}: updateAsset(input: $assetInput${index}) {
        id
        code
        serial
        quantity
        type
        enabled
        history
        comment
        material {
          id
          name
          code
          description
          manufacturer
          trackable
          enabled
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        trackingID
        tracking {
          id
          status
          ikaID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        locationCode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      },\n
    `;
  });

  const mutation = `
    mutation ScheduleShipment(${taskParams + assetsParams}) {
      ${taskOperation + assetsOperations}
    }
  `;
  const params = {
    ...taskParamsObj,
    ...assetsParamsObj,
  };

  return { mutation, params };
}
