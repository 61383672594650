import { CameraSource } from "@capacitor/camera";
import { AttachmentSource, AttachmentPlatform } from "models";

export const ATTACHMENT_PLATFORM_MAP = {
  web: AttachmentPlatform.WEB,
  android: AttachmentPlatform.ANDROID,
  ios: AttachmentPlatform.IOS,
};

export const ATTACHMENT_SOURCE_MAP = {
  [CameraSource.Camera]: AttachmentSource.CAMERA,
  [CameraSource.Photos]: AttachmentSource.ALBUM,
};
