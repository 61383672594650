import React from "react";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";


function TicketTimerLogDescription(props) {
    const { variantT, componentT, description, classeNameContent, renderContent } = props
    return (
        <Typography variant={variantT} gutterBottom component={componentT} className={classeNameContent ? classeNameContent : ''}>
            {description} {renderContent}
        </Typography>
    )
}

TicketTimerLogDescription.propTypes = {
    variantT: PropTypes.string,
    componentT: PropTypes.string,
    description: PropTypes.string,
};

TicketTimerLogDescription.defaultProps = {
    variantT: 'subtitle2',
    componentT: 'div',
    description: '',
};


export default TicketTimerLogDescription;