import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";

function Title({ title, children, ...props }) {
  let titleElement = null;

  if (title) {
    titleElement = <Typography {...props}>{title}</Typography>;
  } else if (children) {
    titleElement = <Typography {...props}>{children}</Typography>;
  } else {
    return null;
  }

  return <Box py={2}>{titleElement}</Box>;
}

Title.propTypes = {
  title: PropTypes.element,
  children: PropTypes.element,
  variant: PropTypes.string,
  gutterBottom: PropTypes.bool,
};

Title.defaultProps = {
  variant: "h6",
  gutterBottom: true,
};

export default Title;
