import { useCallback, useMemo } from "react";
import { BarcodeScanner, SupportedFormat } from "@capacitor-community/barcode-scanner";
import { useDispatch } from "react-redux";
import * as ActionTypes from "redux/action";
import { playAudio } from "components/common/CodeReader/codeReaderAudio";

export default function useBarcodeScanner() {
  const dispatch = useDispatch();

  const stop = useCallback(async () => {
    dispatch({ type: ActionTypes.STOP_BARCODE_SCANNER });
    await BarcodeScanner.stopScan();
  }, [dispatch]);

  const start = useCallback(async () => {
    BarcodeScanner.prepare();
    const permission = await BarcodeScanner.checkPermission({ force: true });
    if (permission.granted) {
      dispatch({ type: ActionTypes.START_BARCODE_SCANNER });
      BarcodeScanner.hideBackground();
      const scanResult = await BarcodeScanner.startScan({ targetedFormats: [SupportedFormat.QR_CODE] });
      playAudio();
      if (scanResult.hasContent) {
        dispatch({
          type: ActionTypes.SET_CONTENT_BARCODE_SCANNER,
          payload: { content: scanResult.content },
        });
      } else {
        stop();
        BarcodeScanner.showBackground();
      }
    } else if (permission.denied || permission.neverAsked || permission.restricted || permission.unknown) {
      throw new Error("Uso de cámara no autorizado. Por favor, habilite los permisos de la cámara.");
    }
  }, [dispatch, stop]);

  const setContainerRef = useCallback(
    (containerRef) => {
      dispatch({
        type: ActionTypes.SET_CONTAINER_BARCODE_SCANNER,
        payload: { ref: containerRef },
      });
    },
    [dispatch]
  );

  return useMemo(() => ({ start, stop, setContainerRef }), [start, stop, setContainerRef]);
}
