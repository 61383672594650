import React from "react";
import Typography from "@material-ui/core/Typography";
import DataLoadView from "components/admin/DataLoadView";
import { MATERIAL_CATEGORY_CATALOG } from "constant/route/admin";

const TABLE_COLUMNS = [
  { title: "Nombre", field: "name", required: true },
  { title: "Abreviatura", field: "abbreviation", required: true },
  { title: "Descripción", field: "description", required: true },
];

function MaterialCategoryDataLoadView() {
  return (
    <DataLoadView
      appbarTitle="Categorías - Carga Manual"
      sectionTitle="Carga de Categorías por archivo"
      goBackTo={MATERIAL_CATEGORY_CATALOG}
      description={
        <>
          <Typography paragraph>
            En este apartado puede realizar la carga de categorías mediante un
            archivo en formato CSV.
          </Typography>
          <Typography paragraph>
            Cargue un archivo con el formato especificado y revise los registros
            ingresados. Puede modificar los registros cargados en caso de ser
            necesario. Una vez revisados los datos puede confirmar su ingreso al
            sistema.
          </Typography>
        </>
      }
      templatePath="tmp/templates/category_template_es.csv"
      templateName="category_template.csv"
      job="CATEGORY"
      dataLoadPrefix="category"
      tableColumns={TABLE_COLUMNS}
    />
  );
}

export default MaterialCategoryDataLoadView;
