import React, { useState, useEffect, useRef, useMemo } from "react";
import PropTypes from "prop-types";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import CloseIcon from "@material-ui/icons/Close";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Grid, Box, ButtonGroup, Button, IconButton } from '@material-ui/core';
import Collapse from "@material-ui/core/Collapse";

import Tooltip from "@material-ui/core/Tooltip";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import makeStyles from "@material-ui/core/styles/makeStyles";

import ConfirmationDialog from "components/ConfirmationDialog";

import EvidenceViewV2 from "components/common/EvidenceViewV2";
import TaskProgressTracker from "../TaskProgressTracker";
import EvidenceViewInstall from "./Components/EvidenceViewInstall";
import TaskStepper from "components/custom/TaskStepper";

import useNotifier from "hooks/useNotifier";
import useLoadingStatus from "hooks/useLoadingStatus";
import useWatchPosition from "hooks/useWatchPosition";
import { useIndexedDB } from "react-indexed-db";
import useStepper from "hooks/useStepper";

import * as dataStoreHelpers from "./helpers/datastore";
import { TaskStatus } from "models";
import { filterEvidencesByStatus } from "components/Tickets/TicketActivityTrackingDialog/helpers";
import { ACTIVITY_CATEGORY, S3_KEY_PREFIXES } from "constant/attachments";
import { TASK_TYPES } from "constant/task/types";

export const STEPS = {
    BEGIN_INSTALLATION: 0,
    INSTALLATION_IN_PROGRESS: 1,
    EVIDENCE_INSTALLATION: 2,
    END_INSTALLATION: 3
};

const STEPS_MOCK_STATE = [
    {
        step: STEPS.BEGIN_INSTALLATION,
        stepKeyname: "BEGIN_INSTALLATION",
        isStepCompleted: false,
        isLastStep: true,
    },
    {
        step: STEPS.INSTALLATION_IN_PROGRESS,
        stepKeyname: "INSTALLATION_IN_PROGRESS",
        isStepCompleted: false,
        isLastStep: false,
    },
    {
        step: STEPS.EVIDENCE_INSTALLATION,
        stepKeyname: "EVIDENCE_ISNTALLATION",
        isStepCompleted: false,
        isLastStep: false,
    },
    {
        step: STEPS.END_INSTALLATION,
        stepKeyname: "END_INSTALLATION",
        isStepCompleted: false,
        isLastStep: false,
    },
];

function calculateStepsState({
    stepsState,
    currentStep,
    attachmentsByStatus = { beginning: [], inProgress: [], completed: [] },
} = {}) {
    const newStepsState = stepsState.map((stepState) => {
        const isLastStep = currentStep === stepState.step;
        let isStepCompleted = false;

        if (stepState.step === STEPS.BEGIN_INSTALLATION) {
            isStepCompleted = currentStep > STEPS.BEGIN_INSTALLATION;
        }
        if (stepState.step === STEPS.INSTALLATION_IN_PROGRESS) {
            isStepCompleted = attachmentsByStatus.inProgress.length >= 4; //ANALIZAR SI ES NECESARIO GENERAR UN NUEVO IF
        }
        if (stepState.step === STEPS.EVIDENCE_INSTALLATION) {
            isStepCompleted = attachmentsByStatus.inProgress.length >= 4; //MIN_PROGRESS_ATTACHMENTS
        }
        if (stepState.step === STEPS.END_INSTALLATION) {
            isStepCompleted = attachmentsByStatus.completed.length >= 1; //MIN_END_ATTACHMENTS
        }

        return {
            ...stepState,
            isStepCompleted,
            isLastStep,
        };
    });
    const newCurrentStepState = newStepsState.find((stepState) => stepState.isLastStep);
    return {
        stepsState: newStepsState,
        currentStepState: newCurrentStepState,
    };
}

function countInProgressAttachments(attachments = []) {
    const attachmentsCount = attachments.reduce((prev, current) => {
        if (current.status === TaskStatus.IN_PROGRESS) return prev + 1;
        return prev;
    }, 0);

    return attachmentsCount;
}

const steps = ["Iniciando Instalación", "Instalación en Curso", "Evidencias de la Instalación", "Finalizando Instalación"]

function NewInstallationDialog(props) {
    const {
        stepsState: _stepsState,
        setStepsState,
        handleNext,
        handleBack,
    } = useStepper({
        currentStepState: STEPS_MOCK_STATE[0],
        stepsState: STEPS_MOCK_STATE,
    })
    const { stepsState, currentStepState: currentStep } = _stepsState;
    const [attachmentsProgress, setAttachmentsProgress] = useState([]);
    // const [completed, setCompleted] = useState({});

    const [confirmStartDialogOpen, setConfirmStartDialogOpen] = useState(false);
    const [confirmContinueDialogOpen, setConfirmContinueDialogOpen] = useState((false))
    const [confirmEndDialogOpen, setConfirmEndDialogOpen] = useState(false);
    const evidenceRef = useRef(null);
    const evidenceRef2 = useRef(null)
    const evidenceRef3 = useRef(null)

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const notifier = useNotifier();
    const imagesDb = useIndexedDB("offline_images");

    const [starting, startInstallationWrapped] = useLoadingStatus(dataStoreHelpers.startInstallation);
    const [loadingContinue, setLoadingContinue] = useState(false)
    const [ending, endInstallationWrapped] = useLoadingStatus(dataStoreHelpers.endInstallation);
    const [addingAttachment, setAddingAttachment] = useState(false);
    const [evidenceLoading, setEvidenceLoading] = useState(false);
    const isLoading = starting || ending || addingAttachment || evidenceLoading;

    const { attachments = [] } = props.installationDetails;
    const inProgressAttchsCount = countInProgressAttachments(attachments);
    const { position, loading: loadingPosition, error: positionError } = useWatchPosition();

    const instructions = ["Para iniciar el proceso de la instalación deberás adjuntar mínimo 3 fotografías de la zona de trabajo previo a la instalación de los activos.", "Agregue los activos utilizados en la instalación.", "Para continuar con el proceso de instalación deverás adjuntar como mínimo 4 fotografías del avance de la tarea.", "Para concluir con el proceso de la instalación deberás adjuntar la fotorafía final de la tarea."]

    const evidenceListHeight = fullScreen
        ? "calc(100vhactiveStep - 90px - 60px - 72px - 48px - 8px)"
        : "calc(100vh - 90px - 60px - 72px - 48px - 80px - 8px)";

    const attachmentsByStatus = useMemo(() => {
        return filterEvidencesByStatus(attachments);
    }, [attachments]);

    useEffect(() => {
        if (props.open) {
            setStepsState(({ stepsState }) =>
                calculateStepsState({ stepsState, currentStep: props.step, attachmentsByStatus })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.open]);

    useEffect(() => {
        const copyInitialAttachments = props?.installationDetails?.attachments || []
        const attachmentList = [];
        copyInitialAttachments.forEach((attachment) => {
            if (attachment.status === "IN_PROGRESS") {
                attachmentList.push({
                    ...attachment.file,
                    attachmentId: attachment.id,
                });
            }
        });

        setAttachmentsProgress(attachmentList);
    }, [props.installationDetails]);

    // const handleFinilizedTask = () => {
    //     if (attachmentsByStatus.completed.length >= 1) { // Se debe de cumplir por lo menos con 1 evidencia
    //         setConfirmEndDialogOpen(true);
    //     } else {
    //         notifier.showMessage(`Se requieren ${1} evidencias para finalizar la instalación`, {
    //             anchorOrigin: {
    //                 vertical: "top",
    //                 horizontal: "left",
    //             },
    //         });
    //     }
    // }

    const handleStartDialogConfirm = () => {
        setLoadingContinue(true)
        const taskID = props?.installationDetails?.task?.id;
        startInstallationWrapped(taskID, attachmentsByStatus.beginning, imagesDb)
            .then((updatedTask) => {
                props.onInstallationUpdate(updatedTask);
                handleNext();
                setConfirmStartDialogOpen(false);
            })
            .catch((err) => {
                console.error(err);
                notifier.showMessage("Ocurrió un error inesperado");
            })
            .finally((_) => {
                setLoadingContinue(false)
            })
    }

    const handleContinueDialogConfirm = async () => {
        setConfirmContinueDialogOpen(false)
        setLoadingContinue(true)
        await handleNext().then((res) => {
        })
            .catch((err) => {
            })
            .finally((_) => {
                setLoadingContinue(false)
            })
    }

    const handleCloseDialogConfirm = () => {
        setConfirmStartDialogOpen(false)
    }

    const handleEndDialogConfirm = async () => {
        try {
            const updatedTask = await endInstallationWrapped(props?.installationDetails?.task?.id);
            notifier.showMessage("Instalación finalizada y pendiente de validación");
            props.onSuccess({ ...props.installationDetails, task: updatedTask });
            setConfirmEndDialogOpen(false);
        } catch (err) {
            notifier.showMessage("Ocurrió un error inesperado");
            setConfirmEndDialogOpen(false);
        }
    }

    const handleEndDialogCancel = () => {
        setConfirmEndDialogOpen(false);
    }

    const handleOpenDialogConfim = () => {
        if (currentStep.step !== STEPS.END_INSTALLATION) setConfirmStartDialogOpen(true);
        else if (currentStep.step === STEPS.END_INSTALLATION) setConfirmEndDialogOpen(true)
    }

    const maxWidth = () => {
        if (currentStep.step === 1) return "lg"
        else return "sm"
    }

    const backBtn = () => {
        if (currentStep.step !== 0) {
            return (
                <IconButton className={classes.backBtnContainer} onClick={handleBack}>
                    <ArrowBackIosIcon aria-label="Regresar" color="inherit" />
                </IconButton>
            )
        }
    }

    function deleteAttachment(attachmentId) {
        props.deleteOptimisticAttachment(attachmentId);
    }

    const classes = useStyles();

    const attachmentPrefix = `${S3_KEY_PREFIXES.EVIDENCES_UNCOMPRESSED}/${ACTIVITY_CATEGORY.IMPLEMENTATION}/${props.taskId}`;

    return (
        <>
            <Dialog
                open={props.open}
                onClose={props.onCancel}
                fullScreen={fullScreen}
                maxWidth={maxWidth()}
            >
                <DialogTitle className={classes.containerDialog}>
                    <span className={classes.container}>
                        <IconButton className={classes.closeBtnContainer} onClick={props.onCancel}>
                            <CloseIcon aria-label="Cerrar modal" color="inherit" />
                        </IconButton>

                        {backBtn()}
                    </span>
                    <TaskStepper
                        isLoading={isLoading}
                        isStepCompleted={currentStep.isStepCompleted}
                        isFirstStep={currentStep.step === STEPS.BEGIN_INSTALLATION}
                        currentStep={currentStep}
                        steps={stepsState}
                        onBackLongPressClick={() => props.onCancel()}
                    // isNextStepDisabled={isNextStepDisabled}
                    />
                    <DialogContentText id="alert-dialog-slide-description" className={classes.label}>
                        <Box className={classes.contentInstructions}>
                            <Grid>
                                <h3>{steps[currentStep.step]}</h3>
                            </Grid>
                            <Grid>
                                <Tooltip className={classes.iconHelp} title={instructions[currentStep.step]}>
                                    <IconButton>
                                        <HelpOutlineIcon fontSize="large" color="primary" />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Box>

                    </DialogContentText>
                </DialogTitle>
                <DialogContent>
                    <Collapse in={currentStep.step === STEPS.BEGIN_INSTALLATION} timeout={0}>
                        <EvidenceViewV2
                            onLoading={setAddingAttachment}
                            onEvidenceChange={props.addOptimisticAttachments}
                            onDeleteItem={props.deleteOptimisticAttachment}
                            handleOpenDialogConfim={handleOpenDialogConfim}
                            taskId={props.taskId}
                            taskType={TASK_TYPES.INSTALLATION}
                            evidenceListHeight={evidenceListHeight}
                            inputName="begin_inputFile"
                            prefix={attachmentPrefix}
                            status={TaskStatus.SCHEDULED}
                            min={3} //Mínimo se necesitan 3 evidencias
                            loading={addingAttachment}
                            loadingPosition={loadingPosition}
                            attachments={attachmentsByStatus.beginning}
                            position={position}
                            positionError={positionError}
                            ref={evidenceRef}
                            fullScreen={fullScreen}
                        />
                    </Collapse>
                    <Collapse in={currentStep.step === STEPS.INSTALLATION_IN_PROGRESS} timeout={0}>
                        <TaskProgressTracker
                            taskId={props?.installationDetails?.task?.id || ""}
                            initialAttachments={props?.installationDetails?.attachments || []}
                            initialMaterials={props.installationMaterials}
                            addOptimisticMaterial={props.addOptimisticMaterial}
                            deleteOptimisticMaterial={props.deleteOptimisticMaterial}
                            updateOptimisticMaterial={props.updateOptimisticMaterial}
                            addOptimisticAttachments={props.addOptimisticAttachments}
                            deleteOptimisticAttachment={props.deleteOptimisticAttachment}
                            updateOptimisticAttachment={props.updateOptimisticAttachment}
                            attachmentsCount={inProgressAttchsCount}
                            taskType={TASK_TYPES.INSTALLATION}
                            position={position}
                            loadingPosition={loadingPosition}
                            positionError={positionError}
                            handleOpenDialogConfim={handleOpenDialogConfim}
                            handleNext={handleNext}
                            fullScreen={fullScreen}
                        />
                    </Collapse>
                    <Collapse in={currentStep.step === STEPS.EVIDENCE_INSTALLATION} timeout={0}>
                        <EvidenceViewV2
                            onLoading={setEvidenceLoading}
                            onEvidenceChange={props.addOptimisticAttachments}
                            onDeleteItem={deleteAttachment}
                            handleOpenDialogConfim={handleOpenDialogConfim}
                            taskId={props.taskId}
                            taskType={TASK_TYPES.INSTALLATION}
                            inputName="inProgress_inputFile"
                            prefix={attachmentPrefix}
                            status={TaskStatus.IN_PROGRESS} //...
                            min={4}
                            loading={evidenceLoading}
                            loadingPosition={loadingPosition}
                            attachments={attachmentsProgress}
                            position={position}
                            positionError={positionError}
                            ref={evidenceRef2}
                            fullScreen={fullScreen}
                        />
                    </Collapse>
                    <Collapse in={currentStep.step === STEPS.END_INSTALLATION} timeout={0}>
                        <EvidenceViewV2
                            onLoading={setAddingAttachment}
                            onEvidenceChange={props.addOptimisticAttachments}
                            onDeleteItem={props.deleteOptimisticAttachment}
                            handleOpenDialogConfim={handleOpenDialogConfim}
                            taskId={props.taskId}
                            taskType={TASK_TYPES.INSTALLATION}
                            evidenceListHeight={evidenceListHeight}
                            inputName="completed_inputFile"
                            prefix={attachmentPrefix}
                            status={TaskStatus.COMPLETED}
                            min={1}
                            step={3}
                            loading={addingAttachment}
                            loadingPosition={loadingPosition}
                            attachments={attachmentsByStatus.completed}
                            position={position}
                            positionError={positionError}
                            ref={evidenceRef3}
                            fullScreen={fullScreen}
                        />
                    </Collapse>
                </DialogContent>
            </Dialog>

            <ConfirmationDialog
                open={confirmStartDialogOpen}
                title="Confirmación"
                onConfirm={handleStartDialogConfirm}
                onCancel={handleCloseDialogConfirm}
                loading={loadingContinue}
            >
                ¿Estás seguro querer pasar al siguiente paso?
            </ConfirmationDialog>

            <ConfirmationDialog
                open={confirmContinueDialogOpen}
                title="Confirmación"
                onConfirm={handleContinueDialogConfirm}
                onCancel={handleCloseDialogConfirm}
                loading={loadingContinue}
            >
                ¿Estás seguro querer pasar al siguiente paso?
            </ConfirmationDialog>

            <ConfirmationDialog
                open={confirmEndDialogOpen}
                title="Confirmación"
                onConfirm={handleEndDialogConfirm}
                onCancel={handleEndDialogCancel}
                loading={ending}
            >
                ¿Estás seguro querer terminar la tarea?
            </ConfirmationDialog>
        </>
    )
}

NewInstallationDialog.propTypes = {
    open: PropTypes.bool,
    step: PropTypes.oneOf(Object.values(STEPS)),
    taskId: PropTypes.string.isRequired,
    installationDetails: PropTypes.shape(),
    installationMaterials: PropTypes.array,
    onSuccess: PropTypes.func,
    onCancel: PropTypes.func,

    onInstallationUpdate: PropTypes.func,

    addOptimisticAttachments: PropTypes.func,
    addOptimisticMaterial: PropTypes.func,
    deleteOptimisticAttachment: PropTypes.func,
    deleteOptimisticMaterial: PropTypes.func,
    updateOptimisticAttachment: PropTypes.func,
    updateOptimisticMaterial: PropTypes.func,
}

NewInstallationDialog.defaultProps = {
    open: false,
    step: STEPS.BEGIN_INSTALLATION,
    taskId: "",
    installationMaterials: [],
    onCancel: () => { },

    onInstallationUpdate: (newData) => { },

    addOptimisticAttachments: () => { },
    addOptimisticMaterial: () => { },
    deleteOptimisticAttachment: () => { },
    deleteOptimisticMaterial: () => { },
    updateOptimisticAttachment: () => { },
    updateOptimisticMaterial: () => { },
}

export default NewInstallationDialog

const useStyles = makeStyles((theme) => ({
    containerDialog: {
        padding: "0px 60px"
    },
    container: {
        display: "flex",
        flexDirection: "column",
    },
    backBtnContainer: {
        position: "absolute",
        left: "6px",
        [theme.breakpoints.down(360)]: {
            left: "6px",
        },
    },
    closeBtnContainer: {
        position: "absolute",
        right: "6px",
        [theme.breakpoints.down(360)]: {
            right: "6px",
        },
    },
    label: {
        color: "rgba(31, 31, 31, 0.67);",
        textAlign: "center",
        padding: "0 auto 0 auto",
        marginTop: "15px"
    },
    contentInstructions: {
        display: "flex",
        height: "50px",
        width: "100%",
        marginBotton: "20px",
        justifyContent: "center"
    },
    iconHelp: {
        position: "absolute",
        '&::after': {
            content: '""',
            position: "absolute",
            fontSize: '14px',
        }
    }
}));