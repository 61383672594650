const ID = "support";

const KEYS = {
  ASSET_CREATION: "asset_creation",
  ASSET_CREATION_REQUIRED_FIELDS: "asset_creation_required_fields",
  REMOTE_SUPPORT: "remote_support",
  TICKET_CANCELLATION: "ticket_cancellation",
  REQUIRED_EVIDENCE: "required_evidence",
};

export default {
  ID,
  KEYS,
};
