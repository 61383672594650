const listPremisesCatalog = /* GraphQL */ `
  query ListPremisesCatalog($filter: ModelPremisesFilterInput, $limit: Int, $nextToken: String) {
    listPremisess(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        code
        owner
        _deleted
        company {
          id
          name
        }
        custodian {
          id
          name
          middlename
          lastnameP
          lastnameM
          phone
          email
          extension
        }
        address {
          id
          street
          number
          zipcode
          city
          settlement
          locality
        }
        buildingLocations {
          items {
            id
            name
            code
            buildingBlock
            floor
            area
            owner
            createdAt
            updatedAt
            _deleted
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export default listPremisesCatalog;
