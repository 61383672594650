export const getKit = /* GraphQL */ `
  query GetKit($kitID: ID!) {
    getKit(id: $kitID) {
      id
      name
      description
      materials {
        items {
          id
          quantity
          uom {
            id
            name
            abbreviation
          }
          material {
            id
            name
            code
            description
            _version
            _deleted
            category {
              id
              name
            }
          }
          _version
          _deleted
        }
        nextToken
      }
      _version
      _deleted
    }
  }
`;

export default getKit;
