/**
 * Recibe los parámetros necesarios para construir un mutation con mutaciones
 * variables dependiendo de la longitud del arreglo de evidencias.
 *
 * @param {string} taskID Identificador del Task que recibirá la actualización
 * @param {Date} startedAt Fecha en que sucede la actualización
 * @param {string} region Clave de la región donde están almacenadas las evidencias
 * @param {string} bucket Nombre del bucket donde están almacenadas las evidencias
 * @param {Array} attachments Arreglo de evidencias
 * @param {Object} coords Objeto que contiene la latitud y longitud de una ubicación geográfica
 * @param {Object} coords.latitude Latitud de una ubicación geográfica
 * @param {Object} coords.longitude Longitud de una ubicación geográfica
 */
function startAfterCreateInstallation(taskID, userID, startedAt, region, bucket, coords, attachments = []) {
  const variables = {
    taskID,
    userID,
    startedAt,
    region,
    bucket,
    latitude: coords.latitude,
    longitude: coords.longitude,
  };
  let mutationParams = "$taskID: ID!, $userID: ID!, $startedAt: AWSDateTime!, $latitude: Float!, $longitude: Float!";
  let mutation = "";

  if (attachments.length > 0) {
    mutationParams += ",$bucket: String!, $region: String!";
    attachments.forEach((attachment, index) => {
      mutationParams += `, $objectKey${index}: String!`;
      mutation += `,
      createAttachment${index}: createAttachment(input:{
        taskID: $taskID
        file:{
          bucket: $bucket,
          key: $objectKey${index},
          region: $region
        }
      }) {
        id
      }
      `;
      variables[`objectKey${index}`] = attachment.s3KeyFile;
    });
  }

  mutation = `mutation AfterCreateInstallation(${mutationParams}) {
    createUserTask(input: {
      userID: $userID
      taskID: $taskID
    }) {
      id
    },
    updateTask(
      input: {
        id: $taskID,
        status: IN_PROGRESS,
        startedAt: $startedAt,
        startLocation: {
          latitude: $latitude,
          longitude: $longitude
        }
      }
    ) {
      id
      status
        startDate
        endDate
        startTime
        endTime
        users {
          items {
            id
            userID
            taskID
            head
            createdAt
            updatedAt
          }
          nextToken
        }
        buildingLocation {
          id
          name
          code
          buildingBlock
          floor
          area
          premisesID
          premises {
            id
            name
            owner
            address {
              id
              street
              number
              zipcode
              city
            }
            custodian {
              name
              lastnameP
              email
              phone
            }
          }
        }
        taskType {
          id
          name
          description
        }
        supervisor {
          id
          name
          middlename
          lastnameP
          lastnameM
          email
          phone
          extension
        }
        assets {
          items {
            id
            asset {
              id
              code
              serial
              quantity
              type
              comment
              material {
                id
                name
                code
                uom {
                  name
                  abbreviation
                }
              }
            }
          }
          nextToken
        }
        attachments {
          items {
            id
            file {
              key
            }
          }
          nextToken
        }
        notes {
          items {
            id
            taskID
            comments
            supervisor {
              id
              name
              lastnameP
              email
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        owner
        createdAt
        updatedAt
    }
    ${mutation}
  }
  `;

  return {
    mutation,
    variables,
  };
}

export default startAfterCreateInstallation;
