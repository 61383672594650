import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Controller, useForm } from "react-hook-form";
import { Logger } from "@aws-amplify/core";
import Alert from "@material-ui/lab/Alert";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionActions from "@material-ui/core/AccordionActions";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import DialogContentText from "@material-ui/core/DialogContentText";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/custom/Button";
import useLoadingStatus from "hooks/useLoadingStatus";
import * as graphql from "../helpers/graphql";
import useBooleanFlag from "hooks/useBooleanFlag";
import LoadingPanel from "components/common/LoadingPanel";
import WordConfirmationDialog from "components/common/WordConfirmationDialog";
import useNotifier from "hooks/useNotifier";
const logger = new Logger("SettingsSection");

const ACTION = {
  SEND_REQUEST: "SEND_REQUEST",
  SAVE_CONFIG: "SAVE_CONFIG",
  TEST_CONFIG: "TEST_CONFIG",
  CANCEL_REQUEST: "CANCEL_REQUEST",
};

const notEmpty = (value) => value?.trim() !== "" || "Campo obligatorio";
const TEXT_RULES = {
  required: "Campo obligatorio",
  maxLength: { value: 150, message: "Máximo 150 caracteres" },
  validate: { notEmpty },
};
const URL_RULES = {
  required: "Campo obligatorio",
  maxLength: { value: 200, message: "Máximo 200 caracteres" },
  pattern: {
    value: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
    message: "Ingrese una URL válida",
  },
};

function SettingsSection({ classes, integrationEnabled, ...props }) {
  const settingsForm = useForm({ mode: "onChange", defaultValues: { clientId: "", token: "", serviceUrl: "" } });
  const [saveDialogOpen, openSaveDialog, closeSaveDialog] = useBooleanFlag();
  const [successDialogOpen, openSuccessDialog, closeSuccessDialog] = useBooleanFlag();
  const [savingSettings, _saveSettings] = useLoadingStatus(saveSettings);
  const [loadingSettings, _loadSettings] = useLoadingStatus(loadSettings);
  const notifier = useNotifier();

  const [succededAction, setSuccededAction] = useState(null);

  useEffect(() => {
    _loadSettings();
  }, []);

  async function loadSettings() {
    try {
      logger.debug("loadSettings: Consultando por configuración existente...");

      const currentSettings = await graphql.fetchCurrentIntegrationSettings();
      logger.debug("loadSettings: Consultando consulta terminada...");

      if (currentSettings) {
        logger.debug("loadSettings: Configuración actual encontrada...");
        setSettings(currentSettings);
      } else {
        logger.debug("loadSettings: No se cuenta con una configuración actual...");
      }
    } catch (error) {
      logger.error("loadSettings: Error al consultar las configuraciones actuales ", error);
      notifier.showError("Ocurrió un error inesperado. Revisar consola.");
      // TODO: Cubrir errores típicos de los servicios de esta integración.
      // Este caso ameríta un manejo específico dentro del front.
    }
  }

  async function handleSaveBtnClick() {
    const valid = await settingsForm.trigger();
    if (valid) openSaveDialog();
  }

  async function saveSettings() {
    const newSettings = {
      ...settingsForm.getValues(),
    };

    logger.debug("saveSettings: Información a guardar...", newSettings);

    try {
      logger.debug("saveSettings: Guardando nueva configuración...");
      const newSettingsResult = await graphql.saveIntegrationSettings(newSettings);
      setSettings(newSettingsResult);
      logger.debug("saveSettings: Configuración guardada exitósamente...");
      closeSaveDialog();
      openSuccessDialog();
      setSuccededAction(ACTION.SAVE_CONFIG);
    } catch (error) {
      logger.error("saveSettings: Error guardando configuración ", error);
      notifier.showError("Ocurrió un error inesperado. Revisar consola.");
      // TODO: Cubrir errores típicos de los servicios de esta integración.
    }

    return;
  }

  function setSettings(data) {
    logger.debug("setSettings: Configuración actual encontrada...");
    settingsForm.setValue("clientId", data.client_id_perm);
    settingsForm.setValue("token", data.client_auth_perm);
    settingsForm.setValue("serviceUrl", data.endpoint_perm);
    settingsForm.setValue("clientIdVersion", data.clientIdVersion);
    settingsForm.setValue("tokenVersion", data.tokenVersion);
    settingsForm.setValue("serviceUrlVersion", data.serviceUrlVersion);
  }

  return (
    <LoadingPanel show={loadingSettings}>
      <AccordionDetails className={classes.accordionDetails}>
        <Typography variant="h6" paragraph>
          Datos de Integración
        </Typography>
        <Box pb={3}>
          <Alert severity="info">
            Esta información es proporcionada por el equipo Indika360 al aprobarse su solicitud. Si no cuenta con el
            suyo contacte a soporte@indika360.com
          </Alert>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Controller
              control={settingsForm.control}
              as={TextField}
              label="ID Cliente"
              name="clientId"
              variant="outlined"
              size="small"
              fullWidth
              required
              disabled={!integrationEnabled}
              value={settingsForm.clientId}
              rules={TEXT_RULES}
              error={!!settingsForm.errors.clientId}
              helperText={
                settingsForm.errors.clientId?.message || "Ingrese el ID de cliente de integración del sistema"
              }
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Controller
              control={settingsForm.control}
              as={TextField}
              label="URL de servicio"
              name="serviceUrl"
              variant="outlined"
              size="small"
              fullWidth
              required
              disabled={!integrationEnabled}
              value={settingsForm.serviceUrl}
              rules={URL_RULES}
              error={!!settingsForm.errors.serviceUrl}
              helperText={
                settingsForm.errors.serviceUrl?.message || "Ingrese la URL del servicio de integración de Manage Engine"
              }
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={settingsForm.control}
              as={TextField}
              label="Token de servicio"
              name="token"
              variant="outlined"
              size="small"
              fullWidth
              required
              disabled={!integrationEnabled}
              value={settingsForm.token}
              rules={TEXT_RULES}
              error={!!settingsForm.errors.token}
              helperText={settingsForm.errors.token?.message || "Credencial de acceso a la plataforma"}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
      <Divider />
      <AccordionActions>
        {/* TODO: Integrar cuando el servicio tenga habilitado un endpoint para comprobar la configuración */}
        {/* <Button
          color="primary"
          variant="outlined"
          disableElevation
          //  disabled={loadingSettings}
        >
          PROBAR CONFIGURACIÓN
        </Button> */}
        <Button
          color="primary"
          variant="contained"
          disableElevation
          disabled={!integrationEnabled || loadingSettings}
          onClick={handleSaveBtnClick}
        >
          GUARDAR
        </Button>
      </AccordionActions>

      <WordConfirmationDialog
        open={saveDialogOpen}
        title="Confirmación"
        onCancel={closeSaveDialog}
        onConfirm={_saveSettings}
        loading={savingSettings}
        confirmationWord="Confirmo"
      >
        <DialogContentText>La configuración de integración con Manage Engine será guardada.</DialogContentText>
        <DialogContentText>
          Verifique que los datos sean correctos e ingrese la palabra{" "}
          <b>
            <i>Confirmo</i>
          </b>{" "}
          en el campo de texto debajo y de click en el botón para continuar.
        </DialogContentText>
      </WordConfirmationDialog>
      <Dialog open={successDialogOpen}>
        <DialogTitle>
          {succededAction === ACTION.SAVE_CONFIG && "Configuración Guardada"}
          {succededAction === ACTION.TEST_CONFIG && "Prueba exitósa"}
        </DialogTitle>
        <DialogContent dividers>
          {succededAction === ACTION.TEST_CONFIG && (
            <>
              <DialogContentText>Prueba exitósa.</DialogContentText>
            </>
          )}
          {succededAction === ACTION.SAVE_CONFIG && (
            <DialogContentText>Su configuración se ha guardado exitósamente.</DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button color="default" onClick={closeSuccessDialog}>
            CERRAR
          </Button>
        </DialogActions>
      </Dialog>
    </LoadingPanel>
  );
}

SettingsSection.propTypes = {
  classes: PropTypes.shape(),
  integrationEnabled: PropTypes.bool,
};

export default SettingsSection;
