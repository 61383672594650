import { device } from "redux/initialStore";
import * as ActionTypes from "redux/action";

const INITIAL_STATE = device;

export default function deviceInfoReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ActionTypes.SET_DEVICE_INFO: {
      const {
        payload: { device = {} },
      } = action;
      return { ...device };
    }
    case ActionTypes.RESET_DEVICE_INFO: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
}
