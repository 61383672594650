import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import Autocomplete from "@material-ui/lab/Autocomplete";

export default function AutocompleteField({
  name,
  control,
  rules,
  options,
  renderInput,
  getOptionLabel,
  renderOption,
  onInputChange,
  onSelectionChange,
  filterOptions,
  defaultValue,
  disabled,
  disableClearable,
  autoSelect,
  ListboxProps,
}) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ onChange, ...props }) => (
        <Autocomplete
          options={options}
          getOptionLabel={getOptionLabel}
          renderOption={renderOption}
          renderInput={renderInput}
          noOptionsText="Sin opciones"
          disabled={disabled}
          disableClearable={disableClearable}
          autoSelect={autoSelect}
          onInputChange={onInputChange}
          onChange={(event, newValue, reason) => {
            const selectedValue = onSelectionChange(event, newValue, reason);
            onChange(selectedValue || newValue);
          }}
          filterOptions={(options, params) => filterOptions(options, params)}
          freeSolo
          selectOnFocus
          ListboxProps={ListboxProps}
          {...props}
        />
      )}
    />
  );
}

AutocompleteField.propTypes = {
  name: PropTypes.string,
  control: PropTypes.object,
  rules: PropTypes.object,
  options: PropTypes.array,
  renderInput: PropTypes.func,
  getOptionLabel: PropTypes.func,
  renderOption: PropTypes.func,
  onInputChange: PropTypes.func,
  onSelectionChange: PropTypes.func,
  filterOptions: PropTypes.func,
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
  disableClearable: PropTypes.bool,
  autoSelect: PropTypes.bool,
  ListboxProps: PropTypes.object,
};

AutocompleteField.defaultProps = {
  name: "",
  control: null,
  rules: null,
  options: [],
  renderInput: () => null,
  getOptionLabel: undefined,
  renderOption: undefined,
  onInputChange: () => {},
  onSelectionChange: () => null,
  filterOptions: (options) => options,
  defaultValue: null,
  disabled: false,
  disableClearable: false,
  autoSelect: false,
  ListboxProps: {},
};
