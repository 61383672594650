import API, { graphqlOperation } from "@aws-amplify/api";
import { filterDeletedItems } from "util/lists";
import * as queries from "graphql-custom/queries";
import makeOptionsList from "util/makeOptionsList";

export async function fetchMaterialKits() {
  const { data } = await API.graphql(graphqlOperation(queries.listKits, { limit: 1000 }));
  const enabledItems = filterDeletedItems(data?.listKits?.items);
  const options = makeOptionsList(enabledItems, { value: "id", label: "name" });
  return options;
}