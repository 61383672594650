import React from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import MaterialTable from "../MaterialTable";
import FloatingButton from "components/FloatingButton";
import NetworkTooltip from "../NetworkTooltip";
import useHeaderTitle from "hooks/useHeaderTitle";
import { defaultFloatingButtonProps } from "components/admin/Companies/constant";

const ListView = ({
  children,
  headerTitle,
  floatingButtonText,
  materialTableProps,
  networkTooltipProps,
  showFloatingButton,
  floatingButtonProps,
}) => {
  useHeaderTitle(headerTitle);

  return (
    <Box>
      <MaterialTable {...materialTableProps} />
      {children}
      {showFloatingButton && (
        <NetworkTooltip {...networkTooltipProps}>
          <FloatingButton {...defaultFloatingButtonProps} {...floatingButtonProps}>
            {floatingButtonText}
          </FloatingButton>
        </NetworkTooltip>
      )}
    </Box>
  );
};

export default ListView;

ListView.propTypes = {
  children: PropTypes.node,
  headerTitle: PropTypes.string,
  showFloatingButton: PropTypes.bool,
  floatingButtonText: PropTypes.string,
  materialTableProps: PropTypes.shape(),
  networkTooltipProps: PropTypes.shape(),
  floatingButtonProps: PropTypes.shape(),
};

ListView.defaultProps = {
  showFloatingButton: true,
  headerTitle: "",
};
