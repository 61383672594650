import React, { useEffect, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Device } from "@capacitor/device";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ScrollContextProvider from "context/ScrollContextProvider";
import { useMainLayoutStyles } from "./index.jss";
import Header from "./Header";
import AppDrawer from "./AppDrawer";

const TOP_ANCHOR_ID = "top-anchor";
function MainLayout({ children, drawerItems }) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDeviceWithVhBug, setIsDeviceWithVhBug] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const classes = useMainLayoutStyles();
  const isXs = useMediaQuery("(max-width:600px)");
  const mainClass = isXs ? classes.mobileContent : classes.content;

  useEffect(() => {
    getDeviceDetails();
  }, []);

  useEffect(() => {
    setIsDrawerOpen(!isXs);
  }, [isXs]);

  const handleDrawerOpen = (event) => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = (event) => {
    setIsDrawerOpen(false);
  };

  async function getDeviceDetails() {
    let { platform, model, osVersion } = await Device.getInfo();
    osVersion = osVersion.split(/\./g)[0];
    setIsDeviceWithVhBug((platform === "ios" || model === "iPhone") && (osVersion === "12" || osVersion === "13"));
    setIsMobile(platform !== "web" && platform !== "electron");
  }

  return (
    <>
      <div className={isDeviceWithVhBug ? classes.rootIOS : classes.root}>
        <Header isDrawerOpen={isDrawerOpen} openDrawer={handleDrawerOpen} />
        <AppDrawer
          isOpen={isDrawerOpen}
          openDrawer={handleDrawerOpen}
          closeDrawer={handleDrawerClose}
          drawerItems={drawerItems}
          isMobile={isMobile}
        />
        <main
          className={clsx(mainClass, {
            [classes.contentShift]: isDrawerOpen,
          })}
        >
          <div id={TOP_ANCHOR_ID} />
          <ScrollContextProvider>{children}</ScrollContextProvider>
        </main>
      </div>
    </>
  );
}

MainLayout.propTypes = {
  children: PropTypes.node,
  drawerItems: PropTypes.arrayOf(PropTypes.shape()),
};

export default MainLayout;
