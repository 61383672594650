export function generateUpdateAssetsMutations(assets = []) {
  let args = ``;
  let operations = ``;
  let params = {};

  assets.forEach(({ taskAsset, ...asset }, index) => {
    args += `$input${index}: UpdateAssetInput!, `;
    params[`input${index}`] = asset;
    operations += `
      up${index}: updateAsset(input: $input${index}) {
        id
        code
        serial
        quantity
        type
        enabled
        material {
          id
          name
          code
          _version
          _deleted
          _lastChangedAt
        }
        trackingID
        _version
        _deleted
        _lastChangedAt
      },
    `;
  });

  let mutation = `mutation UpdateAssets(${args}) {
    ${operations}
  }`;

  return { mutation, variables: params };
}