/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/display-name */
import React, { useState, useEffect, useReducer, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { Logger } from "@aws-amplify/core";
import { useSelector } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import DialogContentText from "@material-ui/core/DialogContentText";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import { Backdrop } from "@material-ui/core";

import AddIcon from "@material-ui/icons/AddBox";
import ListIcon from "@material-ui/icons/List";
import CropFreeOutlinedIcon from "@material-ui/icons/CropFreeOutlined";
import AddAPhotoOutlinedIcon from "@material-ui/icons/AddAPhotoOutlined";
import DoneIcon from "@material-ui/icons/Done";
import WarningIcon from "@material-ui/icons/Warning";
// import DeleteIcon from "@material-ui/icons/Delete";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import maintenanceStyles from "../MaintenanceStyles";
import useTableMobileStyles from "hooks/useTableMobileStyles";

import TableCellMobileFormat from "components/custom/TableCellMobileFormat";
import MaterialTable from "components/custom/MaterialTable";
import BottomPanel from "components/common/CodeReader/BottomPanel";
import SuccessDialog, { MessageDialog } from "components/ConfirmationDialog";
import DownloadQRButton from "components/common/DownloadQRButton";
import MaterialListDialog from "components/Materials/MaterialListDialog";
import Select from "components/custom/Select";
import AssetSearchDialog from "components/AssetSearchDialog";
import AssetAttributesDialog from "components/AssetAttributesDialog";

import useAPIHelpers from "./helpers/useAPIHelpers";
import useUpdateEffect from "hooks/useUpdateEffect";
import { validateEvidences, validateQRScanned } from "./helpers/lib";
import { UPDATE_TYPES, PROGRESS_STATUS } from "constant/Maintenance";
import MaintenanceEvidenceDialog from "components/validation/MaintenanceEvidenceDialog";
import { VISUALIZATION_TYPES } from "constant/visualizationTypes";
import { CODE_READER_STATUS } from "constant/codeReaderStatus";
import CodeScanner from "components/common/CodeScanner";
import FilterAssetByQr from "components/common/FilterAssetByQr";
import STATUSES from "../../AssociateQRDialog/constants/status";
import { formatAssetsSeries, FormatUserName } from "util/text";
import cleanUUID from "util/cleanUUID";
import useNotifier from "hooks/useNotifier";
import * as ActionTypes from "redux/action";
import taskMaterialsReducer, {
  transformSelectedMaterials,
  transformDeletedMaterials,
  transformUpdatedMaterials,
  transformMaterials,
} from "redux/reducer/hook/installationMaterialsReducer";
import useLoadingStatus from "hooks/useLoadingStatus";
import HandleMaintenanceValidation from "./helpers/handleMaintenanceValidation";
import { TaskStatus } from "models";
import { filterDeletedItems } from "util/lists";
import { validateOneAssetEvidences } from "./helpers/lib";
import useBooleanFlag from "hooks/useBooleanFlag";
import { handleElementId, handleGetUOMname, handleGetName, handleGetMaterialCode } from "util/text";
import useDetailViewAPIHelpers from "../MaintenanceDetailView/helpers/useAPIHelpers";

import useOnlineStatus from "@rehooks/online-status";
import NetworkTooltip from "components/custom/NetworkTooltip";

const logger = new Logger("MaintenanceProgressDialog");
export default function MaintenanceProgressDialog({
  isOpen,
  onClose,
  updateDetailViewLocalState,
  maintenanceData,
  loadingMaintenanceData,
  attachmentPrefix,
  enableSimultaneousUpdate,
}) {
  //core states
  const [assetsList, setAssetsList] = useState([]);
  const [maintenanceMaterials, dispatch] = useReducer(taskMaterialsReducer, {
    all: [],
    added: [],
    updated: [],
    deleted: [],
  });
  const [maintenanceInfo, setMaintenanceInfo] = useState(null);
  const [fetchingGlobalState, _onProgressDialogOpen] = useLoadingStatus(onProgressDialogOpen);
  const APIHelpers = useAPIHelpers();
  const { loading: fetchingMaintenanceData, fetchMaintenanceData: _fetchMaintenanceData } = useDetailViewAPIHelpers();

  //global
  const userId = useSelector(({ session }) => session.userId);
  const isOnline = useOnlineStatus();
  const { showError, showMessage } = useNotifier();

  //show dialogs
  const [showAssetSelectorDialog, setShowAssetSelectorDialog] = useState(false);
  const [openCodeReader, setOpenCodeReader] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [showAssetEvidenceDialog, setShowAssetEvidenceDialog] = useState(false);
  const [showMaterialSelectorDialog, setShowMaterialSelectorDialog] = useState(false);
  const [showAssetAttributesDialog, setShowAssetAttributesDialog] = useState(false);

  //actualización simultanea
  const [isProgressCompleted, setIsProgressCompleted] = useState(PROGRESS_STATUS.progress);
  const [validating, _validateProgress] = useLoadingStatus(validateProgress);
  const validationHelpers = HandleMaintenanceValidation();
  const [messageDialogOpen, openMessageDialog, closeMessageDialog] = useBooleanFlag();
  const [messageDialosProps, setMessageDialogProps] = useState(null);
  const [elementValidating, setElementValidating] = useState(ELEMENT_VALIDATING.NULL);

  //codeReader
  const [codeReaderStatus, setCodeReaderStatus] = useState(CODE_READER_STATUS.STOP);
  const [codeReaderMode, setCodeReaderMode] = useState(null);
  const [qrCodeStatus, setQrCodeStatus] = useState(STATUSES.EMPTY);
  const [codeDetected, setCodeDetected] = useState("");
  const [filteredAssetsByQr, setFilteredAssetsByQr] = useState([]);

  //assets
  const [deletingAsset, _removeAsset] = useLoadingStatus(removeAsset);
  const [addingAssets, _addSelectedAssets] = useLoadingStatus(addSelectedAssets);
  const assetsTableRef = useRef(null);
  const [activeTaskAsset, setActiveTaskAsset] = useState(null);
  const [currentAssetAttachments, setCurrentAssetAttachments] = useState({ begin: [], progress: [], end: [] });
  const selectedAssetIds = useMemo(() => {
    return assetsList.map(({ asset }) => {
      return asset.id;
    });
  }, [assetsList]);

  //materiales
  const [uomList, setUomList] = useState([]);
  const [, _loadUOM] = useLoadingStatus(loadUOM);
  const [addingMaterials, _addSelectedMaterials] = useLoadingStatus(addSelectedMaterials);
  const [deletingMaterials, _deleteMaterial] = useLoadingStatus(deleteMaterial);
  const maintenanceMaterialIds = useMemo(() => {
    return maintenanceMaterials.all.map(({ materialId }) => materialId);
  }, [maintenanceMaterials.all]);
  const materialsColumns = useMemo(() => {
    const COLUMNS_TO_USE = [...MATERIALS_COLUMNS_DESKTOP];
    const uomColIndex = COLUMNS_TO_USE.findIndex((column) => column?.title === "Unidad");
    if (isOnline) {
      const uomLookup = uomList.reduce((uomLookup, { id, name }) => {
        uomLookup[id] = name;
        return uomLookup;
      }, {});
      COLUMNS_TO_USE[uomColIndex] = {
        ...COLUMNS_TO_USE[uomColIndex],
        lookup: uomLookup,
        editComponent: (fieldProps) => {
          const { value, onChange } = fieldProps;
          return <Select value={value} onChange={(e) => onChange(e.target.value)} options={uomList} fullWidth />;
        },
      };
    } else {
      COLUMNS_TO_USE[uomColIndex] = {
        ...COLUMNS_TO_USE[uomColIndex],
        editable: "never",
        render: (rowData) => (
          <NetworkTooltip offlineTitle="Actualización de Unidad deshabilitada." placement="top">
            <Typography variant="body2" color="textSecondary">
              {rowData?.uom?.name || "Sin unidad"}
            </Typography>
          </NetworkTooltip>
        ),
      };
    }
    return COLUMNS_TO_USE;
  }, [uomList, isOnline]);

  //styles
  const theme = useTheme();
  const tableMobileStyles = useTableMobileStyles();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = maintenanceStyles({ completed: isProgressCompleted, validating, status: PROGRESS_STATUS });

  //valores calculados
  const maintenanceId = maintenanceData.maintenanceInfo?.id;
  const maintenanceIdParsed = cleanUUID(maintenanceId);

  const availableFilteredAsset = Boolean(filteredAssetsByQr) && Boolean(filteredAssetsByQr?.length);
  const filteredAssetTrackingId = availableFilteredAsset
    ? filteredAssetsByQr[0]?.asset?.trackingID || filteredAssetsByQr[0]?.asset?.tracking?.id
    : "";
  const filteredAssetTextFieldValue = cleanUUID(filteredAssetTrackingId, "");
  const codeReaderAlertIcon =
    qrCodeStatus === STATUSES.LOADING || qrCodeStatus === STATUSES.UPDATING ? (
      <CircularProgress color="inherit" size={20} />
    ) : undefined;
  const codeReaderAlertContent =
    codeReaderMode === CODE_READER_MODE.SEARCH && qrCodeStatus === STATUSES.SELECTED
      ? ASSET_ALERT_CONTENT.SUCCESS
      : ASSET_ALERT_CONTENT[qrCodeStatus];
  const codeReaderTitle = activeTaskAsset ? `Activo ${cleanUUID(handleElementId(activeTaskAsset))}` : "";

  //seteo del estado del flujo que se esta ejecutando
  useEffect(() => {
    if (!isOpen) return;
    if (!showAssetEvidenceDialog && !showAssetSelectorDialog && !showMaterialSelectorDialog && !openCodeReader) {
      setElementValidating(ELEMENT_VALIDATING.NULL);
      setActiveTaskAsset(null);
      //mostrar dialog de cambios al cerrar dialogs

      _validateProgress({
        showAlert: false,
        maintenanceData: maintenanceInfo,
        shouldUpdateData: true,
      });
    }

    if (showAssetSelectorDialog) return setElementValidating(ELEMENT_VALIDATING.ASSET);
    if (showAssetEvidenceDialog) return setElementValidating(ELEMENT_VALIDATING.EVIDENCE);
    if (showMaterialSelectorDialog) return setElementValidating(ELEMENT_VALIDATING.MATERIAL);
    if (openCodeReader) return setElementValidating(ELEMENT_VALIDATING.SCAN);
  }, [showAssetEvidenceDialog, showAssetSelectorDialog, showMaterialSelectorDialog, openCodeReader]);

  useUpdateEffect(() => {
    if (!openCodeReader) {
      resetCodeReaderStates();
    }
  }, [openCodeReader]);

  useEffect(() => {
    //limpiar las propiedades del dialog de mensaje
    if (!messageDialogOpen) {
      setTimeout(() => {
        setMessageDialogProps(null);
      }, 100);
    }
  }, [messageDialogOpen]);

  useUpdateEffect(() => {
    if (isOpen) {
      _onProgressDialogOpen();
    } else {
      afterCloseDialog();
    }
  }, [isOpen]);

  //callback para actualizar estado después de volver de online a offline
  useUpdateEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        const { assets, materials, maintenanceInfo } = setProgressState();
        _validateProgress({
          showAlert: false,
          assets,
          materials,
          maintenanceData: maintenanceInfo,
        });
      }, 2000);
    }
  }, [loadingMaintenanceData]);

  async function onProgressDialogOpen() {
    const { assets, materials, maintenanceInfo } = setProgressState();
    _loadUOM();
    _validateProgress({
      showAlert: false,
      assets,
      materials,
      maintenanceData: maintenanceInfo,
    });
    return { assets, materials, maintenanceInfo };
  }

  function setProgressState({
    assets = maintenanceData.assets,
    materials = maintenanceData.materials,
    maintenanceInfo = maintenanceData.maintenanceInfo,
  } = {}) {
    dispatch({ type: ActionTypes.SET_ALL_INSTALLATION_MATERIAL, payload: materials });
    setAssetsList(assets);
    setMaintenanceInfo(maintenanceInfo);
    return {
      assets,
      materials,
      maintenanceInfo,
    };
  }

  async function beforeAssociateQROpen(rowData) {
    //fetch del estado actual
    const { assets } = await _fetchMaintenanceData(maintenanceId);

    //obtener contenido de mensaje en caso de que haya sido eliminado o escaneado previamente
    const { showMessageDialog, title, content, isDeleted, isScanned, assetEvaluatedId } =
      validationHelpers.getScanChangesContent({
        rowData,
        currentAssets: assets,
      });
    if (showMessageDialog) {
      function onCancel() {
        closeMessageDialog();
        const assetsListCopy = assetsList;
        if (isDeleted) {
          const assetsExceptDeleted = assetsListCopy.filter((asset) => handleElementId(asset) !== assetEvaluatedId);
          setAssetsList(assetsExceptDeleted);
          return _validateProgress({
            showAlert: false,
            assets: assetsExceptDeleted,
          });
        }
        if (isScanned && assets?.length) {
          const assetIndex = assetsListCopy.findIndex((asset) => handleElementId(asset) === assetEvaluatedId);
          const assetUpdatedIndex = assets.findIndex((asset) => handleElementId(asset) === assetEvaluatedId);
          assetsListCopy[assetIndex] = { ...assets[assetUpdatedIndex] };
          setAssetsList(assetsListCopy);
          return _validateProgress({
            showAlert: false,
            assets: assetsListCopy,
          });
        }
      }
      async function onConfirm() {
        const assetsListCopy = assetsList;
        const assetsExceptDeleted = assetsListCopy.filter((asset) => handleElementId(asset) !== assetEvaluatedId);
        // setAssetsList(assetsExceptDeleted);

        const { newAssetsAdded, assetsPreviouslyAdded, messageDialogOpen } = await _addSelectedAssets([rowData], {
          currentAssets: assetsExceptDeleted,
          elementValidating: ELEMENT_VALIDATING.SCAN,
        });
        if (!messageDialogOpen) {
          closeMessageDialog();
          return onCodeReaderOpen(CODE_READER_MODE.VALIDATION, newAssetsAdded[0] || assetsPreviouslyAdded[0]);
        }
      }
      const messageFooter = buildFooterContent();
      const messageDialogProps = {
        title: `Actualización de cambios.\n ${title}`,
        showOkBtn: isDeleted,
        content: [...content, ...messageFooter],
        cancelBtnText: "Cerrar",
        onConfirm: isDeleted ? onConfirm : () => {},
        okBtnText: "Añadir nuevamente",
        onCancel,
      };
      setMessageDialogProps(messageDialogProps);
      openMessageDialog();
    }

    return showMessageDialog;
  }

  async function handleAssociateQR(rowData) {
    try {
      if (!enableSimultaneousUpdate) {
        return onCodeReaderOpen(CODE_READER_MODE.VALIDATION, rowData);
      } else {
        const isMessageDialogOpen = await beforeAssociateQROpen(rowData);
        if (!isMessageDialogOpen) {
          onCodeReaderOpen(CODE_READER_MODE.VALIDATION, rowData);
        }
      }
    } catch (error) {
      console.error({ error });
      showError("Error abriendo el lector de códigos QR, vuelve a intentarlo.");
    }
  }

  function onCodeReaderOpen(codeReaderMode, rowData = []) {
    if (codeReaderMode === CODE_READER_MODE.VALIDATION) {
      setActiveTaskAsset(rowData);
    }
    setCodeReaderMode(codeReaderMode);
    setCodeReaderStatus(CODE_READER_STATUS.START);
    setOpenCodeReader(true);
  }

  // función que se ejecuta al realizar la lectura de un QR
  function handleCodeDetection(code) {
    code = code.text || code;
    if (qrCodeStatus === STATUSES.LOADING) {
      return;
    } else if (codeReaderMode === CODE_READER_MODE.SEARCH) {
      setQrCodeStatus(STATUSES.SELECTED);
    } else if (codeReaderMode === CODE_READER_MODE.VALIDATION) {
      fetchQrCode(code);
    }
    setCodeDetected(code);
  }

  // funcion que se ejecuta para validar un QR con un activo
  async function fetchQrCode(QR = codeDetected) {
    if (!QR.length) {
      return;
    }
    setQrCodeStatus(STATUSES.LOADING);
    try {
      const assetFoundID = await APIHelpers.fetchQr(QR);
      const matchesQrCode = assetFoundID === activeTaskAsset?.asset?.id;
      setQrCodeStatus(matchesQrCode ? STATUSES.SELECTED : STATUSES.NOT_AVAILABLE);
    } catch (error) {
      setQrCodeStatus(STATUSES.NOT_FOUND);
    }
  }

  async function searchOrValidateQrCode() {
    if (codeReaderMode === CODE_READER_MODE.SEARCH) {
      searchAssetByQr();
    }
    if (codeReaderMode === CODE_READER_MODE.VALIDATION) {
      await scanQr();
    }
    setOpenCodeReader(false);
  }

  async function scanQr() {
    setQrCodeStatus(STATUSES.UPDATING);
    const { id, _version } = activeTaskAsset;
    const { response } = await APIHelpers.scanQr(id, _version);
    patchAssetList(response);

    if (!availableFilteredAsset) return; //early exit
    setFilteredAssetsByQr((currentValue) => {
      let currentAssetsFiltered = currentValue;
      const assetResponseId = response?.id;
      const currentAssetIndex = currentAssetsFiltered?.findIndex(
        (currentAsset) => currentAsset?.id === assetResponseId
      );

      if (currentAssetIndex > -1 && Boolean(assetResponseId)) {
        currentAssetsFiltered[currentAssetIndex] = {
          ...currentAssetsFiltered[currentAssetIndex],
          scanned: response?.scanned,
          _version: response?._version,
          _lastChangedAt: response?._lastChangedAt || null,
        };

        return currentAssetsFiltered;
      }
    });
  }

  function searchAssetByQr() {
    setQrCodeStatus(STATUSES.LOADING);
    const filteredAssets = assetsList.filter(({ asset }) => {
      return codeDetected === asset?.trackingID || codeDetected === asset?.tracking?.id;
    });
    if (Boolean(filteredAssets) && Boolean(filteredAssets?.length)) {
      setFilteredAssetsByQr(filteredAssets);
    } else {
      const messageDialogProps = {
        title: "Mensaje.",
        content: [
          <>
            El activo con código QR <b>{cleanUUID(codeDetected)}</b>. No sé encuentra dentro de los activos asignados a
            este mantenimiento.
          </>,
          "Prueba añadiendo el activo a tu lista, posteriormente vuelve a intentarlo.",
        ],
        showOkBtn: false,
        cancelBtnText: "Cerrar",
      };
      setMessageDialogProps(messageDialogProps);
      openMessageDialog();
    }
    setQrCodeStatus(STATUSES.SELECTED);
  }

  function cleanAssetSearchByQr() {
    setCodeDetected("");
    setFilteredAssetsByQr(null);
  }

  function onRestartReading() {
    setQrCodeStatus(STATUSES.EMPTY);
    setCodeDetected("");
  }

  function patchAssetList(updatedTaskAsset) {
    const updatedList = [...assetsList];
    const taIndex = updatedList.findIndex((ta) => ta.id === updatedTaskAsset.id);
    updatedList[taIndex] = {
      ...updatedList[taIndex],
      scanned: updatedTaskAsset?.scanned,
      _version: updatedTaskAsset?._version,
      _lastChangedAt: updatedTaskAsset?._lastChangedAt || null,
    };

    setAssetsList(updatedList);
  }

  function resetCodeReaderStates() {
    setCodeReaderMode(null);
    setCodeReaderStatus(CODE_READER_STATUS.STOP);
    setQrCodeStatus(STATUSES.EMPTY);
    setCodeDetected("");
  }

  //handlers del dialog principal
  async function handleFinalizeMaintenance() {
    if (!isOnline) return showDenyCompleteActivityDialog();

    const { isValid, isMessageDialogOpen } = await _validateProgress({
      assets: assetsList,
      finalizingMaintenance: true,
    });
    if (!isValid || isMessageDialogOpen) return;

    //1. set del contenido del dialog de confirmación
    setMessageDialogProps({
      title: "Confirmación",
      content: ["Estás a punto de finalizar el progreso del mantenimiento.", "¿Seguro que deseas continuar?"],
      loading: APIHelpers.loading,
      onConfirm: handleConfirm,
    });
    //2. abrir dialog de confirmación
    openMessageDialog();
  }

  async function handleConfirm() {
    try {
      const { response, success } = await APIHelpers.finalizeMaintenance({
        taskId: maintenanceId,
        taskVersion: maintenanceData.maintenanceInfo._version,
        endedBy: userId,
      });

      if (success) {
        closeMessageDialog();
        //actualizar estado y dialog de success
        setMaintenanceInfo((currentValues) => ({ ...currentValues, ...response }));
        setOpenSuccessDialog(true);
      }
    } catch (error) {
      console.error(error);
    }
  }

  function showDenyCompleteActivityDialog() {
    const messageDialogProps = {
      title: "Mensaje",
      content: ["Para finalizar la actividad de mantenimiento es necesario contar con conexión a internet."],
      showOkBtn: false,
      onCancel: closeMessageDialog,
      cancelBtnText: "Cerrar",
    };
    setMessageDialogProps(messageDialogProps);
    return openMessageDialog();
  }

  function buildFooterContent() {
    let content = [];
    content.push("De ser posible echa un vistazo a los cambios presentados.");
    content.push(
      "Al cerrar este cuadro de mensaje se actualizará tu listado de materiales y activos a su última versión."
    );
    return content;
  }

  function showTaskAlreadyCompletedNotification({ endedBy, onCancelCB, ...messageProps } = {}) {
    const messageDialogProps = {
      content: [
        <p key={endedBy}>
          El progreso del mantenimiento ya ha sido completado por el usuario: <strong>{endedBy}</strong>.
        </p>,
        "Por lo que no se realizará esta acción.",
        "Al cerrar este cuadro de dialogo el mantenimiento se actualizará automaticamente.",
      ],
      showOkBtn: false,
      cancelBtnText: "Continuar",
      onCancel: () => {
        onCancelCB();
        closeMessageDialog();
      },
      ...messageProps,
    };
    setMessageDialogProps(messageDialogProps);
    openMessageDialog();
  }

  async function showChangeUpdateNotification({ currentAssets, currentMaterials, finalizingMaintenance = false } = {}) {
    let messageContent = [];
    let elementKeyWord;
    let isMessageDialogOpen = false;
    const maintenanceAllData = await _fetchMaintenanceData(maintenanceId);
    const assetsInFrontend = currentAssets;
    const assetsInDB = maintenanceAllData.assets;
    const materialsInFrontend = currentMaterials;
    const materialsInDB = maintenanceAllData.materials;
    let resultObject = {
      currentDBAssets: assetsInDB,
      currentDBMaterials: materialsInDB,
      currentDBMaintenanceInfo: maintenanceAllData.maintenanceInfo,
      currentDBAllTaskAttachments: maintenanceAllData.allTaskAttachments,
      isOpen: false,
    };

    //Si no hay elementos en DB actualizamos su listado local
    if (!maintenanceAllData.assets.length) {
      setAssetsList(assetsInDB);
    }
    if (!maintenanceAllData.materials.length) {
      dispatch({ type: ActionTypes.SET_ALL_INSTALLATION_MATERIAL, payload: materialsInDB });
    }

    //obtenemos contenido de cambios de adición o eliminación de activos
    const materialsAndAssetsAditionChanges = validationHelpers.getMaterialsAndAssetsChanges({
      assetsToValidate: assetsInFrontend,
      materialsToValidate: materialsInFrontend,
      assetsToEvaluate: assetsInDB,
      materialsToEvaluate: materialsInDB,
    });

    //obtenemos contenido de cambios en los valores de cantidad o unidad de medida de los materiales
    const uomOrQuantityChanges = validationHelpers.getContentOfUOMOrQuantityChanges({
      materialsInFrontend,
      materialsInDB,
    });

    //Filtrar/utilizar unicamente los activos en el front que si esten presentas en el back
    const assetsInFrontNotInBack = assetsInFrontend.filter((assetInFront) =>
      assetsInDB.some((assetInDB) => assetInFront.id === assetInDB.id)
    ); // assets en front que estan en el back

    const assetsInBackNotInFront = assetsInDB.filter((assetInDB) =>
      assetsInFrontend.every((assetInFront) => assetInDB.id !== assetInFront.id)
    ); //assets en el back que no estan en el front
    const assetsInFrontFiltered = [...assetsInFrontNotInBack, ...assetsInBackNotInFront];
    //obtenemos contenido de cambios en las evidencias de activos.
    const evidencesChanges = validationHelpers.getContentOfEvidencesChanges({
      assetsInFrontend: assetsInFrontFiltered,
      assetsInDB,
    });

    //validate scanning
    const scanChanges = validationHelpers.getContentOfScanedChanges({
      assetsInFrontend: assetsInFrontFiltered,
      assetsInDB: assetsInDB,
    });
    isMessageDialogOpen =
      materialsAndAssetsAditionChanges?.showMessageDialog ||
      uomOrQuantityChanges?.isUpdated ||
      evidencesChanges?.isUpdated ||
      scanChanges?.isUpdated;

    //Si no hay cambios retornamos
    if (!isMessageDialogOpen) return resultObject;
    //actualizar estados
    if (uomOrQuantityChanges?.isUpdated)
      dispatch({ type: ActionTypes.SET_ALL_INSTALLATION_MATERIAL, payload: maintenanceAllData.materials });
    if (evidencesChanges?.isUpdated || scanChanges?.isUpdated) setAssetsList(maintenanceAllData.assets);
    if (materialsAndAssetsAditionChanges?.showMessageDialog) {
      setProgressState({
        assets: maintenanceAllData.assets,
        materials: maintenanceAllData.materials,
        maintenanceInfo: maintenanceAllData.maintenanceInfo,
      });
    }

    //construir el mensaje y lo mostrarlo
    resultObject.isOpen = isMessageDialogOpen;
    if (
      (uomOrQuantityChanges?.isUpdated && (evidencesChanges?.isUpdated || scanChanges?.isUpdated)) ||
      materialsAndAssetsAditionChanges?.showMessageDialog
    )
      elementKeyWord = "activos y materiales";
    if (!uomOrQuantityChanges?.isUpdated && (evidencesChanges?.isUpdated || scanChanges?.isUpdated))
      elementKeyWord = "activos";
    if (uomOrQuantityChanges?.isUpdated && (!evidencesChanges?.isUpdated || !scanChanges?.isUpdated))
      elementKeyWord = "materiales";

    messageContent = [
      ...materialsAndAssetsAditionChanges?.content,
      ...uomOrQuantityChanges?.content,
      ...evidencesChanges?.content,
      ...scanChanges?.content,
    ];

    messageContent.push("De ser posible echa un vistazo a los cambios presentados.");
    if (!finalizingMaintenance) {
      messageContent.push(
        `Al cerrar este cuadro de mensaje se actualizará tu listado de ${elementKeyWord} a su última versión.`
      );
    } else {
      messageContent.push("Posteriormente intenta nuevamente finalizar el mantenimiento.");
    }

    const messageDialogProps = {
      showOkBtn: false,
      content: messageContent,
      cancelBtnText: "Continuar",
    };
    setMessageDialogProps(messageDialogProps);
    openMessageDialog();

    return resultObject;
  }

  async function validateProgress({
    showAlert = true,
    assets = assetsList,
    materials = maintenanceMaterials.all,
    maintenanceData = maintenanceInfo,
    shouldUpdateData = true,
    finalizingMaintenance = false,
  } = {}) {
    try {
      let currentAssets = assets;
      let currentMaterials = materials;
      let currentMaintenanceInfo = maintenanceData;
      let isMessageDialogOpen = false;
      if (enableSimultaneousUpdate && shouldUpdateData) {
        const { currentDBAssets, currentDBMaterials, isOpen, currentDBMaintenanceInfo } =
          await showChangeUpdateNotification({
            currentAssets,
            currentMaterials,
            finalizingMaintenance,
          });
        isMessageDialogOpen = isOpen;
        currentAssets = currentDBAssets;
        currentMaterials = currentDBMaterials;
        currentMaintenanceInfo = currentDBMaintenanceInfo;
      }

      if (currentMaintenanceInfo?.status === TaskStatus.COMPLETED) {
        const { user: userWhoCompleteTheActivity } = currentMaintenanceInfo.users.items.find(
          ({ userID }) => userID === currentMaintenanceInfo.endedBy
        );
        function onCancelCB() {
          setProgressState({
            assets: currentAssets,
            materials: currentMaterials,
            maintenanceInfo: currentMaintenanceInfo,
          });
          onClose();
        }
        showTaskAlreadyCompletedNotification({ endedBy: FormatUserName(userWhoCompleteTheActivity), onCancelCB });
        isMessageDialogOpen = true;
      }

      if (!currentAssets.length && !isMessageDialogOpen) {
        console.error("==validateProgress: No hay activos para validar==");
        throw new Error("Error: Añade el activo al que le darás mantenimiento");
      }
      const isScanValid = validateQRScanned(currentAssets, !isMessageDialogOpen);

      const areEvidencesValid = validateEvidences(currentAssets, !isMessageDialogOpen);
      const isValid = isScanValid && areEvidencesValid && Boolean(currentAssets?.length);
      if (isValid) {
        setIsProgressCompleted(PROGRESS_STATUS.completed);
      } else {
        setIsProgressCompleted(PROGRESS_STATUS.progress);
      }

      return {
        isValid: isValid,
        isScanValid,
        areEvidencesValid,
        isMessageDialogOpen,
      };
    } catch (error) {
      if (showAlert) {
        showError(error.message);
      }
      if (isProgressCompleted) {
        setIsProgressCompleted(PROGRESS_STATUS.progress);
      }
      return false;
    }
  }

  function afterCloseDialog() {
    dispatch({ type: ActionTypes.RESET_MATERIALS });
    updateDetailViewLocalState({
      reducerParams: {
        type: UPDATE_TYPES.ALL,
        payload: { maintenanceInfo, assets: assetsList, materials: maintenanceMaterials.all },
      },
    });
  }

  async function beforeOpenAssetsOrMaterialsSelector(currentAssetsList, currentMaterialsList, isMaterialsFlow) {
    //consultar estado actual
    const { assets, materials, newMaintenanceInfo } = await _fetchMaintenanceData(maintenanceId);
    // const elementsToEvaluate = isMaterialsFlow ? materials : assets;

    //callback que se dispara al confirmar la apertura de adición de activos
    async function onConfirm() {
      const result = await onOpenAssetsOrMaterialsSelectorDialog({
        currentAssetsList: assets,
        currentMaterialsList: materials,
        isMaterialsFlow,
      });
      if (result?.continue) closeMessageDialog();
    }

    const validationObject = {
      assetsToEvaluate: assets,
      materialsToEvaluate: materials,
      assetsToValidate: currentAssetsList,
      materialsToValidate: currentMaterialsList,
    };

    //handler que determina cambios previos y genera el dialog de mensaje
    const validationResult = validationHelpers.getMaterialsAndAssetsChanges(validationObject);

    //Si ocurrieron cambios previos actualizamos el prorgeso
    if (validationResult.showMessageDialog) {
      setProgressState({ assets, materials, newMaintenanceInfo });
      const contentFooter = buildFooterContent();
      const messageDialogProps = {
        title: "Actualización de cambios.",
        content: [...validationResult.content, ...contentFooter],
        showOkBtn: true,
        cancelBtnText: "Cerrar",
        okBtnText: "Continuar",
        onConfirm,
      };
      setMessageDialogProps(messageDialogProps);
      openMessageDialog();
    }

    return validationResult;
  }

  async function onOpenAssetsOrMaterialsSelectorDialog({
    currentAssetsList = assetsList,
    currentMaterialsList = maintenanceMaterials.all,
    isMaterialsFlow = false,
  } = {}) {
    try {
      if (!enableSimultaneousUpdate) {
        if (isMaterialsFlow) {
          setShowMaterialSelectorDialog(true);
        } else {
          setShowAssetSelectorDialog(true);
        }
        return {
          continue: true,
        };
      } else {
        //handler de validación que se dispara previo a la apertura del selector
        const { showMessageDialog } = await beforeOpenAssetsOrMaterialsSelector(
          currentAssetsList,
          currentMaterialsList,
          isMaterialsFlow
        );

        if (!showMessageDialog) {
          if (isMaterialsFlow) {
            setShowMaterialSelectorDialog(true);
          } else {
            setShowAssetSelectorDialog(true);
          }
        }
        return {
          continue: true,
        };
      }
    } catch (error) {
      console.error({ error });
      showError("Error abriendo el selector, vuelva a intentarlo");
      return {
        continue: false,
      };
    }
  }

  async function getChangesOnAddingElements({
    newElements = [],
    isMaterialsFlow = false,
    elementValidating = null,
    isDeletingElements = false,
  } = {}) {
    let elementsToEvaluate;
    let validCurrentElements;
    let elementsToAdd;
    let shouldUpdateAssetsList =
      (elementValidating === ELEMENT_VALIDATING.ASSET || elementValidating === ELEMENT_VALIDATING.MATERIAL) &&
      !isDeletingElements;
    const elementWordPlural = isMaterialsFlow ? "materiales" : "activos";

    //Obtener elementos en el backend
    const { assets, materials } = await _fetchMaintenanceData(maintenanceId);

    //definir los elementos que se van a evaluar
    elementsToEvaluate = isMaterialsFlow ? materials : assets;

    //handler que muestra dialog de elementos previamente añadidos
    const changesOnAddingResult = validationHelpers.getElementsPreviouslyAddedOnAdding({
      elementsToValidate: newElements,
      elementsToEvaluate,
      isMaterialsFlow,
    });

    //definir los elementos actuales en DB
    validCurrentElements = changesOnAddingResult?.validCurrentElements;
    //parseo de los elementos a añadir en los flujos de escaneo y evidencias
    if (
      changesOnAddingResult?.elementsToAdd?.length === 1 &&
      changesOnAddingResult?.elementsToAdd[0]?.hasOwnProperty("asset")
    ) {
      elementsToAdd = [changesOnAddingResult?.elementsToAdd[0]?.asset];
    } else {
      elementsToAdd = changesOnAddingResult?.elementsToAdd;
    }

    //actualizamos maintenanceInfo si hubieron elementos previamente añadidos
    if (changesOnAddingResult?.showMessageDialog) {
      async function onCancel() {
        let elementPreviouslyAdded;
        let updatedElementsList;
        //cerramos el dialog de mensaje
        closeMessageDialog();

        //callback posterior a la añadir elementos
        if (
          elementValidating === ELEMENT_VALIDATING.SCAN ||
          elementValidating === ELEMENT_VALIDATING.EVIDENCE ||
          isDeletingElements
        ) {
          const elementsToEvaluate = isMaterialsFlow ? materials : assets;
          const localElementsToEvaluate = isMaterialsFlow ? maintenanceMaterials.all : assetsList;
          //hallamos la nueva versión del activo previamente añadadido
          const updatedElement = elementsToEvaluate.find(
            (asset) => handleElementId(asset) === handleElementId(newElements[0])
          );

          elementPreviouslyAdded = updatedElement;

          //actualizamos el activo con su nueva versión
          let localElementsListCopy = localElementsToEvaluate;
          const updatedAssetIndex = localElementsListCopy.findIndex(
            (asset) => handleElementId(asset) === handleElementId(newElements[0])
          );
          localElementsListCopy[updatedAssetIndex] = updatedElement;
          if (!localElementsListCopy?.length) {
            localElementsListCopy.push(updatedElement);
          }
          updatedElementsList = localElementsListCopy;

          if (isMaterialsFlow) {
            dispatch({ type: ActionTypes.SET_ALL_INSTALLATION_MATERIAL, payload: localElementsListCopy });
          } else {
            setAssetsList(localElementsListCopy);
          }
        }

        if (elementValidating === ELEMENT_VALIDATING.ASSET) {
          if (isDeletingElements) {
            return _validateProgress({ showAlert: false, assets: updatedElementsList });
          }
          return setShowAssetSelectorDialog(false);
        }
        if (elementValidating === ELEMENT_VALIDATING.MATERIAL) {
          if (isDeletingElements) {
            return _validateProgress({ showAlert: false, assets: updatedElementsList });
          }
          return setShowMaterialSelectorDialog(false);
        }
        //abrimos modal de evidencias o código qr
        if (elementValidating === ELEMENT_VALIDATING.SCAN) {
          return onCodeReaderOpen(CODE_READER_MODE.VALIDATION, elementPreviouslyAdded);
        }
        if (elementValidating === ELEMENT_VALIDATING.EVIDENCE) {
          return onOpenAddAssetAttachment(elementPreviouslyAdded);
        }
      }
      const contentFooter = buildFooterContent();
      const messageDialogProps = {
        title: `Actualización de cambios al añadir ${elementWordPlural}.`,
        showOkBtn: false,
        cancelBtnText: "Continuar",
        content: [...changesOnAddingResult?.content, ...contentFooter],
        onCancel,
      };
      // setProgressState({ assets, materials, maintenanceInfo });
      setMessageDialogProps(messageDialogProps);
      openMessageDialog();
    }
    return {
      validCurrentElements,
      elementsToAdd,
      elementsPreviouslyAdded: changesOnAddingResult?.elementsPreviouslyAdded,
      elementsPreviouslyDeleted: changesOnAddingResult?.elementsPreviouslyDeleted,
      messageDialogOpen: changesOnAddingResult?.showMessageDialog,
      shouldUpdateAssetsList,
    };
  }

  async function addSelectedAssets(
    newAssets = [],
    {
      currentAssets = null,
      elementValidating = ELEMENT_VALIDATING.ASSET,
      shouldValidate = true,
      isDeletingElements = false,
    } = {}
  ) {
    try {
      let validCurrentAssets = Boolean(currentAssets) ? currentAssets : assetsList;
      let assetsToAdd = newAssets;
      let assetsPreviouslyAdded;
      let messageDialogOpen = false;
      if (enableSimultaneousUpdate && shouldValidate) {
        const validationResult = await getChangesOnAddingElements({
          newElements: newAssets,
          elementValidating,
          isDeletingElements,
        });
        // validCurrentAssets = validationResult?.validCurrentElements;
        assetsToAdd = validationResult?.elementsToAdd;
        assetsPreviouslyAdded = validationResult?.elementsPreviouslyAdded;
        messageDialogOpen = validationResult?.messageDialogOpen;
        if (validationResult?.shouldUpdateAssetsList) {
          validCurrentAssets = null;
          validCurrentAssets = validationResult?.validCurrentElements;
        }
        //si hay un mensaje abierto no añadimos nada
        if (!assetsToAdd || !assetsToAdd.length) {
          return {
            success: true,
            newAssetsAdded: [],
            assetsToAdd,
            newAssetList: validCurrentAssets,
            assetsPreviouslyAdded: validationResult?.elementsPreviouslyAdded,
            assetsPreviouslyDeleted: validationResult?.elementsPreviouslyDeleted,
            messageDialogOpen: validationResult?.messageDialogOpen,
          };
        }
      }

      const { formatedAssets } = await APIHelpers.addNewAssets(maintenanceId, assetsToAdd);
      const newAssetsList = concatAssetsSelected(formatedAssets, validCurrentAssets); // formatedAssets, assetsList

      showMessage("Se añadieron nuevos activos al mantenimiento", { variant: "success" });
      return {
        success: true,
        newAssetsAdded: formatedAssets,
        newAssetsList,
        assetsPreviouslyAdded: assetsPreviouslyAdded || [],
        messageDialogOpen,
      };
    } catch (error) {
      console.error(error);
      showError("Error actualizando el listado de activos");
      return {
        success: false,
        newAssetList: assetsList,
        newAssetsAdded: [],
      };
    }
  }

  function concatAssetsSelected(newAssets, currentAssets = assetsList) {
    const currentAssetsCopy = currentAssets;
    const newAssetsList = [...currentAssetsCopy, ...newAssets];

    setAssetsList(newAssetsList);
    return newAssetsList;
  }

  async function onRemoveElementAttempt(elementToRemove, isMaterialsFlow = false) {
    if (!enableSimultaneousUpdate) {
      if (isMaterialsFlow) return _deleteMaterial(elementToRemove);
      return _removeAsset(elementToRemove);
    }
    //flujo actualización simultanea
    else {
      //obtener estado en DB y validar si el elemento fue previamente eliminado
      const { assets, materials } = await _fetchMaintenanceData(maintenanceId);
      const elementsToEvalute = isMaterialsFlow ? materials : assets;
      const elementEvaluatedIndex = elementsToEvalute.findIndex(
        (element) => handleElementId(element) === handleElementId(elementToRemove)
      );
      //si el elemento no ha sido previamente eliminado hay que eliminarlo
      if (elementEvaluatedIndex > -1) {
        if (isMaterialsFlow) return _deleteMaterial(elementToRemove);
        return _removeAsset(elementToRemove);
      }
      //si el elemento fue previamente eliminado
      if (elementEvaluatedIndex === -1) {
        const elementValidating = isMaterialsFlow ? ELEMENT_VALIDATING.MATERIAL : ELEMENT_VALIDATING.ASSET;

        //remover el elemento en el frontend de su respectivo listado
        const localElementsEvaluated = isMaterialsFlow ? maintenanceMaterials.all : assetsList;
        const elementsExceptDeleted = localElementsEvaluated.filter(
          (element) => handleElementId(element) !== handleElementId(elementToRemove)
        );

        let validateProgressProps = { showAlert: false };
        if (isMaterialsFlow) {
          dispatch({ type: ActionTypes.SET_ALL_INSTALLATION_MATERIAL, payload: elementsExceptDeleted });
          validateProgressProps.materials = elementsExceptDeleted;
        } else {
          setAssetsList(elementsExceptDeleted);
          validateProgressProps.assets = elementsExceptDeleted;
        }
        function onCancel() {
          //validar otros cambios
          closeMessageDialog();
          return _validateProgress(validateProgressProps);
        }

        async function onConfirm() {
          closeMessageDialog();

          //Añadir nuevamente el activo
          let isMessageDialogOpen = false;
          if (isMaterialsFlow) {
            const { newMaterialsList, messageDialogOpen } = await _addSelectedMaterials(
              [elementToRemove],
              elementsExceptDeleted,
              ELEMENT_VALIDATING.MATERIAL,
              true
            );
            validateProgressProps.materials = newMaterialsList;
            isMessageDialogOpen = messageDialogOpen;
          } else {
            const { newAssetsList, messageDialogOpen } = await _addSelectedAssets([elementToRemove], {
              currentAssets: elementsExceptDeleted,
              isDeletingElements: true,
            });
            validateProgressProps.assets = newAssetsList;
            isMessageDialogOpen = messageDialogOpen;
          }
          //validar otros cambios
          if (!isMessageDialogOpen) return _validateProgress(validateProgressProps);
        }

        //build del mensaje
        const deletedContent = validationHelpers.getElementPreviouslyDeletedContent({
          elementValidating,
          isMaterialsFlow,
        });
        const messageFooterContent = buildFooterContent();
        const messageDialogProps = {
          okBtnText: "Añadir nuevamente",
          cancelBtnText: "Cerrar",
          onConfirm,
          onCancel,
          content: [...deletedContent, ...messageFooterContent],
        };

        setMessageDialogProps(messageDialogProps);
        openMessageDialog();
      }
    }
  }

  async function removeAsset(oldData, currentAssetsList = assetsList) {
    try {
      const { assetsRemovedIds } = await APIHelpers.removeAsset(oldData);
      //remover activos en el frontend
      const newAssetList = currentAssetsList.filter(({ asset }) => -1 === assetsRemovedIds.indexOf(asset.id));
      if (filteredAssetsByQr && filteredAssetsByQr?.length) {
        cleanAssetSearchByQr();
      }
      setAssetsList(newAssetList);
      _validateProgress({ assets: newAssetList, shouldUpdateData: false, showAlert: false });
      showMessage("Se eliminaron activos de tu lista satisfactoriamente.", { variant: "success" });
    } catch (error) {
      console.error(error);
      showError("Ocurrio un error al intentar eliminar el activo de tu lista, intentalo nuevamente.");
    }
  }

  function handleOnAssetAttributesDialogOpen({ asset }) {
    setActiveTaskAsset(asset);
    setShowAssetAttributesDialog(true);
  }

  function resetAttributesDialog() {
    // setActiveTaskAsset(null);
    setShowAssetAttributesDialog(false);
  }

  async function addSelectedMaterials(
    newMaterials = [],
    currentMaterials = null,
    elementValidating = ELEMENT_VALIDATING.MATERIAL,
    isDeletingElements = false
  ) {
    try {
      let materialsToAdd = newMaterials;
      let validCurrentMaterials = Boolean(currentMaterials) ? currentMaterials : maintenanceMaterials.all;
      let messageDialogOpen = false;
      if (enableSimultaneousUpdate) {
        const newMaterialsParsed = transformSelectedMaterials(newMaterials);
        const validationResult = await getChangesOnAddingElements({
          newElements: newMaterialsParsed.all,
          isMaterialsFlow: true,
          elementValidating,
          isDeletingElements,
        });
        materialsToAdd = validationResult?.elementsToAdd;
        messageDialogOpen = validationResult?.messageDialogOpen;
        if (validationResult?.shouldUpdateAssetsList) {
          validCurrentMaterials = null;
          validCurrentMaterials = validationResult?.validCurrentElements;
        }
        if (!materialsToAdd || !materialsToAdd.length) {
          return {
            success: true,
            newAssetsAdded: [],
            newAssetList: validCurrentMaterials,
            assetsPreviouslyAdded: validationResult?.elementsPreviouslyAdded,
            assetsPreviouslyDeleted: validationResult?.elementsPreviouslyDeleted,
            messageDialogOpen: validationResult?.messageDialogOpen,
          };
        }
      }

      const materialsToAddParsed = transformSelectedMaterials(materialsToAdd);
      const materialsAdded = await APIHelpers.addNewMaterials(maintenanceId, materialsToAddParsed.all);
      if (!materialsAdded || !materialsAdded?.length)
        throw new Error("addSelectedMaterials: Error obteniendo los materiales añadidos.");

      const newMaterialsList = concatMaterialsSelected(materialsAdded, validCurrentMaterials);
      dispatch({ type: ActionTypes.SET_ALL_INSTALLATION_MATERIAL, payload: newMaterialsList });
      showMessage("Se añadieron nuevos materiales al mantenimiento", { variant: "success" });
      return {
        success: true,
        newMaterialsAdded: materialsAdded,
        newMaterialsList,
        messageDialogOpen,
      };
    } catch (error) {
      console.error(error);
      showError("Error actualizando el listado de materiales");
      return {
        success: false,
      };
    }
  }

  function concatMaterialsSelected(newMaterials = [], currentMaterials = maintenanceMaterials.all) {
    const newMaterialsList = [...currentMaterials, ...newMaterials];
    return newMaterialsList;
  }

  async function onUpdateMaterialAttempt(newValue, oldValue, rowData, column, isMaterialsFlow = true) {
    if (!enableSimultaneousUpdate) {
      return updateMaterial(newValue, rowData, column);
    }
    //Actualización simultanea
    else {
      const { materials } = await _fetchMaintenanceData(maintenanceId);
      const { showMessageDialog, isEdited, isDeleted, content, title, materialToUpdateOnDB, isSameValue } =
        validationHelpers.getOnMaterialEditContent({
          newValue,
          materialToEdit: rowData,
          column,
          isMaterialsFlow,
          elementsToEvalute: materials,
        });

      if (!showMessageDialog) {
        const { updatedMaterialsList } = await updateMaterial(newValue, rowData, column);
        return updatedMaterialsList;
      }
      async function onConfirm() {
        const parsedMaterials = transformMaterials([materialToUpdateOnDB]);
        //actualizar al valor deseado
        const updatedMaterialsList = await onUpdateMaterialAttempt(
          newValue,
          null,
          parsedMaterials[0],
          column,
          isMaterialsFlow
        );
        closeMessageDialog();
        //validar cambios de adición y eliminación
        return _validateProgress({ materials: updatedMaterialsList.all, showAlert: false });
      }
      function onCancel() {
        closeMessageDialog();
        //eliminar activo de mi lista
        let updatedMaterialsList;
        if (isDeleted) {
          updatedMaterialsList = maintenanceMaterials.all.filter(
            (asset) => handleElementId(asset) !== handleElementId(rowData)
          );
        }
        if (isEdited) {
          const localMaterials = maintenanceMaterials.all;
          const materialToUpdateIndex = localMaterials.findIndex(
            (material) => handleElementId(material) === handleElementId(materialToUpdateOnDB)
          );
          const materialOnDBParsed = transformMaterials([materialToUpdateOnDB]);
          localMaterials[materialToUpdateIndex] = materialOnDBParsed[0];

          if (!localMaterials?.length) localMaterials.push(materialOnDBParsed[0]);
          updatedMaterialsList = localMaterials;
        }
        dispatch({ type: ActionTypes.SET_ALL_INSTALLATION_MATERIAL, payload: updatedMaterialsList });
        //validar cambios de adición y eliminación
        return _validateProgress({ showAlert: false, materials: updatedMaterialsList });
      }
      if (isEdited && !isSameValue) {
        content.push(
          <>
            Si a pesar de esto deseas actualizar el valor, haz click en <strong>ACTUALIZAR</strong>.
          </>
        );
      }
      const messageDialogFooter = buildFooterContent();
      const myContent = isDeleted || isSameValue ? [...content, ...messageDialogFooter] : content;
      const messageDialogProps = {
        title,
        content: myContent,
        okBtnText: "Actualizar",
        onConfirm,
        onCancel,
        showOkBtn: isEdited && !isSameValue,
        cancelBtnText: isDeleted || isSameValue ? "Cerrar" : "Cancelar",
      };

      setMessageDialogProps(messageDialogProps);
      openMessageDialog();
    }
  }

  async function updateMaterial(newValue, rowData, column) {
    console.log("==ENTRANDO A updateMaterial==", { newValue, rowData, column });
    try {
      let dataToUpdate = rowData;
      if (column.field === "quantity") {
        dataToUpdate.propName = "quantity";
        dataToUpdate.newValue = newValue;
      } else if (column.field === "uom.name") {
        dataToUpdate.propName = "uom";
        dataToUpdate.newValue = { id: newValue, name: column.lookup[newValue] };
      }
      const updatedMaterialsList = transformUpdatedMaterials(maintenanceMaterials, dataToUpdate);
      const result = await APIHelpers.updateMaterials(maintenanceId, updatedMaterialsList, {
        name: dataToUpdate.propName,
        newValue: dataToUpdate.newValue,
      });
      const [updatedAsset] = Object.keys(result);
      const updatedIndex = updatedMaterialsList.all.findIndex(
        (material) => material.assetId === result[updatedAsset].id
      );
      updatedMaterialsList.all[updatedIndex] = {
        ...updatedMaterialsList.all[updatedIndex],
        assetVersion: result[updatedAsset]._version,
      };

      dispatch({
        type: ActionTypes.SET_ONLY_ALL_MATERIAL,
        payload: updatedMaterialsList,
      });
      showMessage("Se actualizo el material satisfactoriamente", { variant: "success" });

      return {
        updatedMaterialsList,
        materialEdited: rowData,
        newValue,
      };
    } catch (error) {
      console.error(error);
      showError("Error actualizando el material");
    }
  }

  async function deleteMaterial(oldData, currentMaterialsList = maintenanceMaterials) {
    try {
      const updatedMaterialsList = transformDeletedMaterials(currentMaterialsList, oldData);
      const { deleted } = updatedMaterialsList;
      await APIHelpers.removeMaterials(deleted);
      dispatch({
        type: ActionTypes.SET_ONLY_ALL_MATERIAL,
        payload: updatedMaterialsList,
      });
      showMessage("Se eliminaron materiales de tu lista satisfactoriamente", { variant: "success" });
    } catch (error) {
      console.error(error);
      showError("Error eliminando el material del listado de materiales");
    }
  }

  async function beforeOpenAttachments(rowData) {
    const { assets } = await _fetchMaintenanceData(maintenanceId);
    const { showMessageDialog, content, title, isUpdated, isDeleted, assetEvaluatedId, currentAssetData } =
      validationHelpers.getEvidencesChangesContent({
        rowData,
        currentAssets: assets,
      });

    if (showMessageDialog) {
      function onCancel() {
        closeMessageDialog();
        const assetsListCopy = assetsList;
        if (isDeleted) {
          const assetsExceptDeleted = assetsListCopy.filter((asset) => handleElementId(asset) !== assetEvaluatedId);
          setAssetsList(assetsExceptDeleted);
          return _validateProgress({
            showAlert: false,
            assets: assetsExceptDeleted,
          });
        }
        if (isUpdated && assets?.length) {
          const assetIndex = assetsListCopy.findIndex((asset) => handleElementId(asset) === assetEvaluatedId);
          assetsListCopy[assetIndex] = { ...currentAssetData };
          setAssetsList(assetsListCopy);
          onOpenAddAssetAttachment(currentAssetData);
        }
      }
      async function onConfirm() {
        const assetsListCopy = assetsList;
        const assetsExceptDeleted = assetsListCopy.filter((asset) => handleElementId(asset) !== assetEvaluatedId);
        const { newAssetsAdded, assetsPreviouslyAdded, messageDialogOpen } = await _addSelectedAssets([rowData], {
          currentAssets: assetsExceptDeleted,
          elementValidating: ELEMENT_VALIDATING.EVIDENCE,
        });
        if (!messageDialogOpen) {
          closeMessageDialog();
          return onOpenAddAssetAttachment(newAssetsAdded[0] || assetsPreviouslyAdded[0]);
        }
      }
      const messageFooter = buildFooterContent();
      const messageDialogProps = {
        title: `Actualización de cambios.\n ${title}`,
        showOkBtn: isDeleted,
        content: [...content, ...messageFooter],
        cancelBtnText: isDeleted ? "Cerrar" : "Continuar",
        onConfirm: isDeleted ? onConfirm : () => {},
        okBtnText: "Añadir nuevamente",
        onCancel,
      };
      setMessageDialogProps(messageDialogProps);
      openMessageDialog();
    }

    return { showMessageDialog, currentAssetData };
  }

  function onOpenAddAssetAttachment(currentAssetData) {
    setActiveTaskAsset({ ...currentAssetData });
    const attachmentsListMap = {
      [TaskStatus.SCHEDULED]: "begin",
      [TaskStatus.IN_PROGRESS]: "progress",
      [TaskStatus.FINALIZED]: "end",
    };
    let filteredAttachments = { begin: [], progress: [], end: [] };
    if (currentAssetData?.attachments?.items?.length) {
      filteredAttachments = filterDeletedItems(currentAssetData.attachments.items).reduce(
        (attachments, attachment) => {
          const list = attachmentsListMap[attachment.status];
          if (list) {
            attachments[list].push(attachment);
          }
          return attachments;
        },
        { begin: [], progress: [], end: [] }
      );
    }
    setCurrentAssetAttachments(filteredAttachments);
    setShowAssetEvidenceDialog(true);
  }

  async function addAssetAttachment(rowData) {
    if (!enableSimultaneousUpdate) {
      return onOpenAddAssetAttachment(rowData);
    } else {
      const { showMessageDialog, currentAssetData } = await beforeOpenAttachments(rowData);
      if (!showMessageDialog) {
        onOpenAddAssetAttachment(currentAssetData);
      }
    }
  }

  function hideAndUpdateEvidences(
    taskAssetId,
    { startAttachments, progressAttachments, endAttachments },
    shouldUpdateStates
  ) {
    setShowAssetEvidenceDialog(false);
    setCurrentAssetAttachments({ begin: [], progress: [], end: [] });
    if (shouldUpdateStates) {
      const aIndex = assetsList.findIndex((ta) => ta?.id === taskAssetId);
      let newAssetsList = assetsList;
      newAssetsList[aIndex] = { ...newAssetsList[aIndex] };
      newAssetsList[aIndex].attachments.items = [...startAttachments, ...progressAttachments, ...endAttachments];
      setAssetsList(newAssetsList);
    }
  }

  async function loadUOM() {
    try {
      const UOMs = await APIHelpers.getUOMs();
      setUomList(UOMs);
    } catch (error) {
      logger.log(error);
      showError("Ocurrio un error al consultar las unidades de medida.");
    }
  }

  function getAssetTableColumns() {
    if (isMobileScreen) {
      return getAssetsColumnsMobile(handleAfertDownloadQR);
    } else {
      return getAssetsColumnsDesktop(handleAfertDownloadQR);
    }
  }

  function handleRenderActionIcon() {
    if (validating) return <CircularProgress size={20} color="primary" disableShrink />;
    if (isProgressCompleted === PROGRESS_STATUS.completed)
      return <DoneIcon className={classes.dialogActions} color="primary" style={{ fontSize: "16px" }} />;
    if (isProgressCompleted === PROGRESS_STATUS.progress)
      return (
        <WarningIcon className={classes.dialogActions} style={{ color: "#ff9800", fontSize: "16px", opacity: "0.5" }} />
      );
  }

  const handleAfertDownloadQR = (assetId) => (QrId) => {
    const _assetsList = [...assetsList];
    const changeIndex = _assetsList.map(({ asset }) => asset.id).indexOf(assetId);
    if (!_assetsList[changeIndex]?.asset?.trackingID || _assetsList[changeIndex]?.asset?.trackingID === "unassigned") {
      _assetsList[changeIndex].asset.trackingID = QrId;
      setAssetsList(_assetsList);
    }
  };

  function handleAssetsSelectorClose() {
    if (!elementValidating) return;
    if (!messageDialogOpen) {
      if (elementValidating === ELEMENT_VALIDATING.ASSET && showAssetSelectorDialog) setShowAssetSelectorDialog(false);
      if (elementValidating === ELEMENT_VALIDATING.MATERIAL && showMaterialSelectorDialog)
        setShowMaterialSelectorDialog(false);
      if (elementValidating === ELEMENT_VALIDATING.EVIDENCE && showAssetEvidenceDialog)
        setShowAssetEvidenceDialog(false);
      if (elementValidating === ELEMENT_VALIDATING.ASSET && openCodeReader) setOpenCodeReader(false);
    }
  }

  return (
    <>
      {/* Dialog de prorgeso */}
      <Backdrop
        open={fetchingGlobalState || fetchingMaintenanceData || validating}
        style={{ zIndex: 1301, color: "#fff" }}
      >
        <Typography>Cargando...</Typography>
      </Backdrop>
      <Dialog id="progress-dialog" maxWidth="lg" fullWidth fullScreen={isMobileScreen} open={isOpen} disablePortal>
        <DialogTitle>Progreso del mantenimiento {maintenanceIdParsed}</DialogTitle>
        <DialogContent dividers>
          <Typography variant="h6" color="textPrimary">
            Instrucciones:
          </Typography>
          <DialogContentText paragraph variant="subtitle1" color="textPrimary">
            Escanea el código QR correspondiente a cada activo y añade imagenes como evidencias del progreso.
          </DialogContentText>
          {/* Tabla de activos */}
          <Box mb={5} mt={2}>
            <Box>
              <Typography variant="h6">Activos</Typography>
            </Box>
            <FilterAssetByQr
              textFieldValue={filteredAssetTextFieldValue}
              onSearch={() => onCodeReaderOpen(CODE_READER_MODE.SEARCH)}
              onClear={cleanAssetSearchByQr}
              disabled={!assetsList || !assetsList?.length}
            />
            <div id="progress-assets-table" className={tableMobileStyles.root}>
              <MaterialTable
                columns={getAssetTableColumns()}
                options={TABLE_ASSETS_OPTIONS}
                data={availableFilteredAsset ? filteredAssetsByQr : assetsList}
                editable={{ onRowDelete: onRemoveElementAttempt }}
                localization={{ body: { deleteTooltip: "Eliminar" } }}
                isLoading={addingAssets || deletingAsset}
                tableRef={assetsTableRef}
                actions={[
                  {
                    icon: () => <AddIcon id="add-assets-icon" />,
                    position: "toolbar",
                    isFreeAction: true,
                    onClick: onOpenAssetsOrMaterialsSelectorDialog,
                    hidden: filteredAssetsByQr && filteredAssetsByQr?.length,
                    disabled: !isOnline,
                    // eslint-disable-next-line react/prop-types
                    WrapperComponent: ({ children }) => (
                      <NetworkTooltip
                        onlineTitle="Añadir activos"
                        offlineTitle="La adición de activos requiere conexión a internet."
                      >
                        {children}
                      </NetworkTooltip>
                    ),
                  },

                  (rowData) => ({
                    icon: () => (
                      <AddAPhotoOutlinedIcon id={`add-evidences-icon-${cleanUUID(rowData.asset?.id)}`} color="action" />
                    ),
                    tooltip: "Agregar Evidencias",
                    position: "row",
                    onClick: () => addAssetAttachment(rowData),
                  }),
                  (rowData) => ({
                    icon: () => <ListIcon id={`show-attributes-icon-${cleanUUID(rowData.asset?.id)}`} color="action" />,
                    tooltip: "Ver Atributos",
                    position: "row",
                    onClick: () => handleOnAssetAttributesDialogOpen(rowData),
                  }),
                  (rowData) => {
                    const codeScanned = Boolean(rowData.scanned);
                    if (codeScanned) {
                      return {
                        icon: () => <DoneIcon id={`done-icon-${cleanUUID(rowData.asset?.id)}`} color="primary" />,
                        position: "row",
                        tooltip: "QR escaneado",
                      };
                    } else {
                      return {
                        icon: () => (
                          <CropFreeOutlinedIcon id={`scan-qr-icon-${cleanUUID(rowData.asset?.id)}`} color="action" />
                        ),
                        onClick: () => handleAssociateQR(rowData),
                        position: "row",
                        tooltip: "Escanear QR",
                      };
                    }
                  },
                ]}
              />
            </div>
          </Box>
          {/* Tabla de materiales */}
          <Box mb={5}>
            <Typography variant="h6">Materiales</Typography>
            <Typography paragraph>Gestiona los materiales utilizados durante el progreso del mantenimiento.</Typography>
            <MaterialTable
              columns={materialsColumns}
              options={TABLE_OPTIONS}
              data={maintenanceMaterials.all}
              editable={{
                onRowDelete: (rowData) => onRemoveElementAttempt(rowData, true),
              }}
              cellEditable={{ onCellEditApproved: onUpdateMaterialAttempt }}
              localization={{ body: { deleteTooltip: "Eliminar", editTooltip: "Editar" } }}
              isLoading={addingMaterials || deletingMaterials}
              actions={[
                {
                  icon: () => <AddIcon id="add-materials-icon" />,
                  position: "toolbar",
                  onClick: () => onOpenAssetsOrMaterialsSelectorDialog({ isMaterialsFlow: true }),
                  disabled: !isOnline,
                  isFreeAction: true,
                  // eslint-disable-next-line react/prop-types
                  WrapperComponent: ({ children }) => (
                    <NetworkTooltip
                      onlineTitle="Añadir materiales"
                      offlineTitle="La adición de materiales requiere conexión a internet."
                    >
                      {children}
                    </NetworkTooltip>
                  ),
                },
              ]}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button id="close-progress-modal-btn" color="default" onClick={onClose} disabled={validating}>
            Cerrar
          </Button>

          <Button
            id="finalize-progress-modal-btn"
            color="primary"
            onClick={handleFinalizeMaintenance}
            disabled={validating}
            className={isProgressCompleted === PROGRESS_STATUS.progress ? classes.flicker : ""}
            title="Finalizar mantenimiento."
            endIcon={handleRenderActionIcon()}
          >
            Finalizar
          </Button>
        </DialogActions>
      </Dialog>

      <CodeScanner
        open={openCodeReader}
        title={codeReaderTitle}
        status={codeReaderStatus}
        onClose={() => setOpenCodeReader(false)}
        onCodeDetected={handleCodeDetection}
        showPreviewPanel
        onRestartReading={onRestartReading}
        bottomPanel={
          <BottomPanel
            emptyCodeMsg="En espera de escaneo"
            codeDetected={codeDetected}
            disabledBtn={qrCodeStatus !== STATUSES.SELECTED}
            fetchQrCode={searchOrValidateQrCode}
          >
            {
              <Box mb={2}>
                <Alert icon={codeReaderAlertIcon} severity={ASSET_ALERT_SEVERITY[qrCodeStatus]}>
                  {codeReaderAlertContent}
                </Alert>
              </Box>
            }
          </BottomPanel>
        }
      />

      {/* Dialog para mensajes de validación */}
      <MessageDialog
        open={messageDialogOpen}
        onCancel={closeMessageDialog}
        title={messageDialosProps?.title || "Actualización de cambios."}
        {...messageDialosProps}
        loading={APIHelpers.loadingOnline || fetchingMaintenanceData}
      >
        {messageDialosProps?.content.map((contentText, index) => (
          <DialogContentText key={`messageDialogContentText${index}`} color="textPrimary">
            {contentText}
          </DialogContentText>
        ))}
      </MessageDialog>

      <SuccessDialog
        open={openSuccessDialog}
        onCancel={() => {
          setOpenSuccessDialog(false);
          onClose();
        }}
        cancelBtnText="Cerrar"
        showOkBtn={false}
        title="Mensaje"
      >
        <DialogContentText color="textPrimary">
          El progreso del mantenimiento se ha finalizado correctamente.
        </DialogContentText>
        <DialogContentText color="textPrimary">
          Para la validación de esta tarea es necesario solicitar la aprobación del cliente mediante su firma
          electrónica.
        </DialogContentText>
      </SuccessDialog>

      {/* Dialog para la busqueda de activos */}
      {isOnline && (
        <AssetSearchDialog
          open={showAssetSelectorDialog}
          onClose={handleAssetsSelectorClose}
          updateAssetsList={_addSelectedAssets}
          excludedAssetIds={selectedAssetIds}
          initialData={{ premise: maintenanceInfo?.premises }}
        />
      )}

      {/* Dialogo para las evidencias por activo */}
      <MaintenanceEvidenceDialog
        isOpen={showAssetEvidenceDialog}
        attachmentPrefix={attachmentPrefix}
        onClose={hideAndUpdateEvidences}
        visualizationType={VISUALIZATION_TYPES.ACTIVITY}
        taskAsset={activeTaskAsset}
        assetAttachments={currentAssetAttachments}
      />

      <MaterialListDialog
        open={showMaterialSelectorDialog}
        onClose={handleAssetsSelectorClose}
        onCompleteSelection={_addSelectedMaterials}
        excludedMaterialIds={maintenanceMaterialIds}
      />

      {/* Dialog de atributos de activos */}
      <AssetAttributesDialog
        isOpen={showAssetAttributesDialog}
        onClose={resetAttributesDialog}
        asset={activeTaskAsset}
        onComplete={() => {}}
        readOnly={true}
      />
    </>
  );
}

MaintenanceProgressDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  attachmentPrefix: PropTypes.string,
  updateDetailViewLocalState: PropTypes.func,
  maintenanceData: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.array, PropTypes.object])),
  loadingMaintenanceData: PropTypes.bool,
  enableSimultaneousUpdate: PropTypes.bool,
};

MaintenanceProgressDialog.defaultProps = {
  isOpen: false,
  onClose: () => {},
  attachmentPrefix: "",
  updateDetailViewLocalState: () => {},
  maintenanceData: { maintenanceInfo: {}, assets: [], materials: [], observations: [] },
  loadingMaintenanceData: false,
  enableSimultaneousUpdate: false,
};

const TABLE_OPTIONS = {
  exportButton: false,
  columnsButton: false,
};

const TABLE_ASSETS_OPTIONS = {
  exportButton: false,
  columnsButton: false,
  debounceInterval: 1000,
  actionsCellStyle: { width: "80px", paddingLeft: "16px" },
  rowStyle: (rowData) => {
    const { areEvidencesValid, withEvidences } = validateOneAssetEvidences(rowData.attachments?.items);
    const isScannedValid = Boolean(rowData.scanned);

    function handleAssetRowBackgroundColor(areEvidencesValid, isScannedValid, withEvidences) {
      if (!areEvidencesValid && !isScannedValid && !withEvidences) return "#fff";
      if (withEvidences && !areEvidencesValid && !isScannedValid) return "#FFF4E5";
      if ((!areEvidencesValid && isScannedValid) || (areEvidencesValid && !isScannedValid)) return "#e8f4fd";
      return "#EDF7ED";
    }

    const rowBgColor = handleAssetRowBackgroundColor(areEvidencesValid, isScannedValid, withEvidences);
    return {
      backgroundColor: rowBgColor,
    };
  },
};

const CODE_READER_MODE = {
  SEARCH: "SEARCH",
  VALIDATION: "VALIDATION",
};

function getTracking(trackingId) {
  if (trackingId !== "unassigned") {
    return cleanUUID(trackingId);
  } else {
    return "Asignar QR";
  }
}

function getAssetsColumnsDesktop(handleAfertDownloadQR) {
  return [
    { title: "Nombre", field: "asset.material.name" },
    { title: "Código Material", field: "asset.material.code" },
    { title: "Serie", field: "asset.serial", render: (rowData) => formatAssetsSeries(rowData.asset?.serial) },
    {
      title: "Código QR",
      field: "asset.trackingID",
      render: (rowData) => {
        let trackingId = rowData?.asset?.trackingID || rowData?.asset?.tracking?.id;
        function getContent() {
          if (trackingId !== "unassigned") {
            return cleanUUID(trackingId);
          } else {
            return "Asignar QR";
          }
        }
        return (
          <DownloadQRButton
            variant="contained"
            color="primary"
            id={`downloadQR-${
              trackingId !== "unassigned" ? cleanUUID(trackingId) : "unassigned-" + cleanUUID(rowData.asset?.id)
            }-btn`}
            assetID={rowData.asset?.id}
            content={getContent()}
            afterDownload={handleAfertDownloadQR(rowData.asset?.id)}
          />
        );
      },
    },
  ];
}

function getAssetsColumnsMobile(handleAfertDownloadQR) {
  return [
    {
      title: "Detalles",
      field: "id",
      render: (rowData) => {
        const columns = [
          { title: "Nombre", description: handleGetName(rowData) },
          { title: "Código de material", description: handleGetMaterialCode(rowData) },
          { title: "Serie", description: formatAssetsSeries(rowData?.asset?.serial) },
          {
            title: "Código QR",
            description: (() => {
              let trackingId = rowData?.asset?.trackingID || rowData?.asset?.tracking?.id;

              return (
                <DownloadQRButton
                  variant="contained"
                  color="primary"
                  style={{ marginTop: "0.5em" }}
                  id={`downloadQR-${
                    trackingId !== "unassigned" ? cleanUUID(trackingId) : "unassigned-" + cleanUUID(rowData.asset?.id)
                  }-btn`}
                  assetID={rowData.asset?.id}
                  content={getTracking(trackingId)}
                  afterDownload={handleAfertDownloadQR(rowData.asset?.id)}
                />
              );
            })(),
          },
        ];
        return <TableCellMobileFormat columns={columns} sm={6} xs={12} />;
      },
      customFilterAndSearch: (value, rowData) => {
        const parsedValue = value?.toLowerCase();
        let trackingId = rowData?.asset?.trackingID || rowData?.asset?.tracking?.id;

        return (
          handleGetMaterialCode(rowData).toLowerCase().includes(parsedValue) ||
          handleGetName(rowData).toLowerCase().includes(parsedValue) ||
          formatAssetsSeries(rowData?.asset?.serial).toLowerCase().includes(parsedValue) ||
          getTracking(trackingId).toLowerCase().includes(parsedValue)
        );
      },
    },
  ];
}

const MATERIALS_COLUMNS_DESKTOP = [
  { title: "Nombre", field: "name", editable: "never" },
  { title: "Código", field: "code", editable: "never" },
  { title: "Unidad", field: "uom.name", render: (rowData) => handleGetUOMname(rowData) },
  { title: "Cantidad", field: "quantity", type: "numeric", initialEditValue: 1, align: "left" },
  // { title: "Categoría", field: "category.name" },
];

const ASSET_ALERT_SEVERITY = {
  [STATUSES.EMPTY]: "info",
  [STATUSES.LOADING]: "info",
  [STATUSES.UPDATING]: "info",
  [STATUSES.NOT_AVAILABLE]: "warning",
  [STATUSES.NOT_FOUND]: "warning",
  [STATUSES.SELECTED]: "success",
};

const ASSET_ALERT_CONTENT = {
  [STATUSES.EMPTY]: "Aún no se ha detectado un QR",
  [STATUSES.LOADING]: "Cargando la información del código QR",
  [STATUSES.UPDATING]: "Validando el código QR con el activo",
  [STATUSES.NOT_AVAILABLE]: "El código QR no corresponde al activo",
  [STATUSES.NOT_FOUND]: "Código QR no encontrado",
  [STATUSES.SELECTED]: "El código QR coincide con el activo",
  SUCCESS: "QR escaneado con éxito",
};

const ELEMENT_VALIDATING = {
  NULL: null,
  EVIDENCE: "EVIDENCE",
  SCAN: "SCAN",
  ASSET: "ASSET",
  MATERIAL: "MATERIAL",
};
