import {
  FETCH_MATERIAL_CATEGORY_CATALAOG_PENDING,
  FETCH_MATERIAL_CATEGORY_CATALAOG_SUCCESS,
  FETCH_MATERIAL_CATEGORY_CATALAOG_ERROR,
} from "redux/action";

import { listMaterialCategories } from "graphql-custom/queries/listMaterialCategories";

export const fetchMaterialCategoriesCatalog = () => ({
  filter: "graphql",
  operation: listMaterialCategories,
  types: [FETCH_MATERIAL_CATEGORY_CATALAOG_PENDING, FETCH_MATERIAL_CATEGORY_CATALAOG_SUCCESS, FETCH_MATERIAL_CATEGORY_CATALAOG_ERROR],
  options: { errorMessage: "Error al consultar el catalogo" },
});
