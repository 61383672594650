export const patchTaskAsset = /* GraphQL */ `
  mutation UpdateTaskAsset($input: UpdateTaskAssetsInput!) {
    updateTaskAssets(input: $input) {
      id
      _version
      _deleted
      _lastChangedAt
      scanned
      taskAssetsTaskId
      taskAssetsAssetId
      attachments {
        items {
          id
          status
          file {
            bucket
            key
            region
          }
        }
      }
      asset {
        id
        code
        serial
        quantity
        type
        comment
        _version
        _deleted
        material {
          id
          name
          code
          uom {
            name
            abbreviation
          }
        }
        uom {
          id
          name
          description
        }
        trackingID
        tracking {
          id
          status
          ikaID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
    }
  }
`;
