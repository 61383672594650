import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Typography, TextField, Grid, Button, makeStyles, Tooltip, ClickAwayListener } from "@material-ui/core";
import CropFreeOutlinedIcon from "@material-ui/icons/CropFreeOutlined";

const FilterAssetByQr = (props) => {
  const { descriptionText, textFieldProps, textFieldValue, onSearch, onClear, searchBtnText, clearBtnText, disabled } =
    props;
  const [tooltipOpen, setOpenTooltip] = useState(false);
  const openTooltip = () => setOpenTooltip(true);
  const closeTooltip = () => setOpenTooltip(false);
  const toggleShowTooltip = () => setOpenTooltip((currentValue) => !currentValue);
  const classes = useStyles();

  const defaultTextFieldProps = {
    id: "search-by-qr-field",
    label: "Búsqueda con QR",
    name: "qrCode",
    placeholder: "Escanea el QR del activo deseado",
    InputProps: {
      readOnly: true,
      classes: { underline: classes.searchAssetByQrTextField, input: classes.searchAssetByQrTextField },
      disabled: false,
    },
    InputLabelProps: {
      shrink: true,
      disabled: false,
      classes: { root: classes.searchAssetByQrTextField },
    },
    disabled: true,
    fullWidth: true,
  };

  return (
    <Box>
      <Typography gutterBottom>{descriptionText}</Typography>
      <ClickAwayListener onClickAway={closeTooltip}>
        <Tooltip
          open={disabled && tooltipOpen}
          onClose={closeTooltip}
          onOpen={openTooltip}
          enterTouchDelay={200}
          leaveTouchDelay={2500}
          arrow
          title="El mantenimiento no cuenta con activos asignados."
        >
          <Grid container spacing={2} className={classes.searchAssetByQrContainer} onClick={toggleShowTooltip}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <TextField {...defaultTextFieldProps} {...textFieldProps} value={textFieldValue} />
            </Grid>
            <Grid item lg={3} md={3} sm={12} xs={12}>
              <Box mt={1}>
                <Button
                  id="search-by-qr-btn"
                  variant="contained"
                  color="primary"
                  onClick={onSearch}
                  fullWidth
                  endIcon={<CropFreeOutlinedIcon />}
                  disabled={disabled}
                >
                  {searchBtnText}
                </Button>
              </Box>
            </Grid>
            <Grid item lg={3} md={3} sm={12} xs={12}>
              <Box mt={1}>
                <Button
                  id="clean-search-by-qr-btn"
                  variant="outlined"
                  color="primary"
                  onClick={onClear}
                  disabled={disabled}
                  fullWidth
                >
                  {clearBtnText}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Tooltip>
      </ClickAwayListener>
    </Box>
  );
};

export default FilterAssetByQr;

FilterAssetByQr.propTypes = {
  descriptionText: PropTypes.string,
  textFieldValue: PropTypes.string,
  textFieldProps: PropTypes.object,
  onSearch: PropTypes.func,
  onClear: PropTypes.func,
  searchBtnText: PropTypes.string,
  clearBtnText: PropTypes.string,
  disabled: PropTypes.bool,
};

FilterAssetByQr.defaultProps = {
  descriptionText:
    "Puedes filtrar un activo especifico dentro de tu lista de activos a los que dar mantenimiento. Realizando el escaneo de su código QR.",
  textFieldValue: "",
  textFieldProps: {},
  onSearch: null,
  onClear: null,
  searchBtnText: "Buscar",
  clearBtnText: "Limpiar",
  disabled: false,
};

const useStyles = makeStyles((theme) => ({
  assetsHeaderContainer: {
    marginBottom: "-40px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2),
    },
  },
  searchAssetByQrContainer: {
    position: "relative",
    bottom: "-56px",
    "z-index": 1,
    maxWidth: "70%",
    [theme.breakpoints.down(1224)]: {
      maxWidth: "60%",
    },
    [theme.breakpoints.down("sm")]: {
      position: "static",
      maxWidth: "none",
    },
  },
  searchAssetByQrTextField: {
    color: theme.palette.text.primary,
    cursor: "auto",
    "&:hover:not(.Mui-disabled)": {
      "&:before": {
        "border-bottom": "1px solid rgba(0, 0, 0, 0.42)",
      },
    },
  },
}));
