import { Logger } from "@aws-amplify/core";
import { Geolocation } from "@capacitor/geolocation";
import { captureException } from "setup/sentry";
import wait from "util/wait";

export { Geolocation };
export class GeolocationPermissionDeniedException extends Error {}
export class UnknownGeolocationException extends Error {}
export class TimeoutExpiredException extends Error {}

const logger = new Logger("geolocation");

const PERMISSION_DENIED_MESSAGES = [
  "User denied location permission",
  "Location permission was denied",
  "The operation couldn’t be completed. (kCLErrorDomain error 1.)",
  "User denied Geolocation",
];

export async function _getCurrentPosition() {
  let coordinates;
  try {
    const start = Date.now();
    logger.debug(`Inicio de consulta ${start}`);
    coordinates = await Geolocation.getCurrentPosition({
      timeout: 8000,
      maximumAge: 60 * 1000,
      enableHighAccuracy: true,
    });
    const end = Date.now();
    const diff = Math.abs((end - start) / 1000);
    logger.debug(`Fin de consulta ${end}`);
    logger.debug(`Tiempo que tomó ${diff}`);
  } catch (error) {
    logger.error("getCurrentPosition: error ", JSON.stringify(error));
    const gError = mapGeolocationError(error, "_getCurrentPosition");
    throw gError;
  }

  return coordinates;
}

export async function getCurrentPosition() {
  const timeoutPromise = wait(30000).then(() => {
    logger.debug(`Expiró el tiempo de espera para obtener la ubicación`);
    throw new TimeoutExpiredException();
  });
  const promises = [_getCurrentPosition(), timeoutPromise];

  return Promise.race(promises);
}

export function mapGeolocationError(error, context = "handleGeolocationError") {
  const errorMessage = error.message || error.errorMessage;
  captureException(errorMessage, context);
  if (error.code === 3 && errorMessage === "Timeout expired") {
    return new TimeoutExpiredException();
  } else if (PERMISSION_DENIED_MESSAGES.includes(errorMessage)) {
    return new GeolocationPermissionDeniedException();
  } else {
    return new UnknownGeolocationException("");
  }
}

export default {
  getCurrentPosition,
  Geolocation,
  mapGeolocationError,
};
