import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import TabContainer from "components/Tabs/TabContainer";
import * as ROLES from "constant/roles";
import useHeaderTitle from "hooks/useHeaderTitle";
import Title from "components/common/Title";
import MaintenanceListViewTable from "./MaintenanceListViewTable";
import { styleStatus, getStyleForTab, SECTIONS } from "../../../util/homologationOfColumns";
// CUSTOM STYLES
const useStyles = makeStyles((theme) => styleStatus(SECTIONS.MAINTENANCE));

// CUSTOM SELECTED STYLES
const useStylesSelected = makeStyles(getStyleForTab(SECTIONS.MAINTENANCE));

let SECTION_TITLE = "";
const isWeb = window.matchMedia("(min-width: 768px)").matches;
if (!isWeb) {
  SECTION_TITLE = "MANTENIMIENTOS";
}
function MaintenanceListView({ type }) {
  useHeaderTitle(SECTION_TITLE);

  const stylesTabs = useStyles();
  const stylesSelected = useStylesSelected();

  const _tabData = React.useMemo(() => {
    // RENDER COMPONENT TABS
    const listTableGeneral = <MaintenanceListViewTable type={type} status="GENERAL" />;
    const listTableSheduled = <MaintenanceListViewTable type={type} status="SCHEDULED" />;
    const listTableInProgres = <MaintenanceListViewTable type={type} status="IN_PROGRESS" />;
    const listTableComplete = <MaintenanceListViewTable type={type} status="COMPLETED" />;
    const listTableReviewed = <MaintenanceListViewTable type={type} status="REVIEWED" />;
    const listTableNotes = <MaintenanceListViewTable type={type} status="WITH_INSPECTION_NOTES" />;
    const listTableFinalized = <MaintenanceListViewTable type={type} status="FINALIZED" />;

    // Obj to define tabs
    const tabData = [
      {
        name: "General",
        index: 0,
        component: listTableGeneral,
        styles: "GENERAL",
      },
      {
        name: "Asignados",
        index: 1,
        component: listTableSheduled,
        styles: "SCHEDULED",
      },
      {
        name: "En progreso ",
        index: 2,
        component: listTableInProgres,
        styles: "IN_PROGRESS",
      },
      {
        name: "Por Firmar",
        index: 3,
        component: listTableComplete,
        styles: "COMPLETED",
      },
      {
        name: "Con observaciones",
        index: 4,
        component: listTableNotes,
        styles: "WITH_INSPECTION_NOTES",
      },
      {
        name: "Validado",
        index: 5,
        component: listTableReviewed,
        styles: "FINALIZED",
      },
      {
        name: "Finalizado",
        index: 6,
        component: listTableFinalized,
        styles: "REVIEWED",
      },
    ];

    return tabData;
  }, [type]);

  if (![ROLES.SUPPORT_ENGINEERS, ROLES.SUPERVISORS].includes(type)) {
    _tabData.splice(0, 1);
  }
  return (
    <>
      {isWeb && <Title>MANTENIMIENTOS</Title>}
      <TabContainer tabData={_tabData} stylesTabs={stylesTabs} stylesSelected={stylesSelected} />
    </>
  );
}

MaintenanceListView.propTypes = {
  type: PropTypes.oneOf([ROLES.SUPERVISORS, ROLES.SUPPORT_ENGINEERS]),
};

MaintenanceListView.defaultProps = {
  type: ROLES.SUPERVISORS,
};

export default MaintenanceListView;
