import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import GroupRestrictedView from "../components/common/GroupRestrictedView";

function GroupRestrictedRoute({ component, render, children, ...props }) {
  return (
    <GroupRestrictedView group={props.group}>
      {(allowed) => {
        if (allowed)
          return children
            ? typeof children === "function"
              ? children(props)
              : children
            : component
            ? React.createElement(component, props)
            : render
            ? render(props)
            : null;

        return <Redirect to="/inicio" />;
      }}
    </GroupRestrictedView>
  );
}

GroupRestrictedRoute.defaultProps = {};

GroupRestrictedRoute.propTypes = {
  // String o arreglo de strings que especifica el/los grupo(s) autorizados
  group: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
};

export default GroupRestrictedRoute;
