import { API, graphqlOperation } from "@aws-amplify/api";
import * as queries from "graphql-custom/queries";

export async function generateQrs(quantity) {
  const res = await API.graphql(graphqlOperation(queries.lambdaQrGenerator, { input: { quantity } }));
  const { ids, fileUrl } = res.data.lambdaQrGenerator;
  await updateTrackIds(ids);
  return fileUrl;
}

export async function updateTrackIds(ids = []) {
  let args = ``;
  let operations = ``;
  let params = {};

  ids.forEach((id, index) => {
    args = `${args} $input${index}: UpdateTrackingInput!,`;
    operations = `${operations} up${index}: updateTracking(input: $input${index}) {
          id
          status
          ikaID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        },`;
    params[`input${index}`] = { id };
  });
  args = args.slice(0, -1);
  operations = operations.slice(0, -1);

  let mutation = `mutation UpdateTrackIDs(${args}){
    ${operations}
  }`;
  return API.graphql(graphqlOperation(mutation, params));
}
