// import { Logger } from "aws-amplify";
import { filterNullAssets, filterDeletedItems } from "./lists";
import { AssetType } from "models";
import { WARRANTY, CLIENT_WARRANTY } from "constant/system/attributes";
import { captureException } from "setup/sentry";

// const logger = new Logger("util/asset");

export function filterAssetsByWhCode(assets, whCode) {
  return assets.filter((asset) => {
    // Si no tiene atributos asignados no es seleccionable
    if (!asset.embeddedAttributes?.length) {
      return false;
    }

    const whCodeAttribute = asset.embeddedAttributes.find((att) => att.attributeID === "WH_CODE");
    // Si no posee atributo de WH_CODE no es seleccionable
    if (!whCodeAttribute) {
      return false;
    }

    return whCodeAttribute.value === whCode;
  });
}

export const filterAssetsAndMaterialsReducer = (accumulator, currentValue, index, array) => {
  if ([AssetType.MATERIAL_ALLOCATED, AssetType.MATERIAL_USED].includes(currentValue.asset?.type)) {
    accumulator.materials.push(currentValue);
  } else {
    accumulator.assets.push(currentValue);
  }
  return accumulator;
};

/**
 * Handler encargado de separar materiales de activos, a partir de arreglo de taskAssets
 *
 * @param {Array} assets
 * @returns {Object} { materials: [Object], assets: [Object] }
 */
export const divideAssetsAndMaterials = (taskAssets = []) => {
  if (!taskAssets || !taskAssets.length) {
    return { assets: [], materials: [] };
  }

  let materialsAndAssets = [...taskAssets];
  materialsAndAssets = filterNullAssets(materialsAndAssets);
  materialsAndAssets = filterDeletedItems(materialsAndAssets);

  return materialsAndAssets.reduce(filterAssetsAndMaterialsReducer, {
    assets: [],
    materials: [],
  });
};

export const getAssetGarrantyByAsset = (asset = null) => {
  const defaultValue = "Sin fecha límite asignada.";

  if (!asset || !asset.embeddedAttributes) {
    return defaultValue;
  }

  return (
    asset?.embeddedAttributes?.filter((attribute) => attribute?.attributeID === WARRANTY)[0]?.value || defaultValue
  );
};

export const getAssetClientGarrantyByAsset = (asset = null) => {
  const defaultValue = "Sin fecha límite asignada.";

  if (!asset || !asset.embeddedAttributes) {
    return defaultValue;
  }

  return (
    asset?.embeddedAttributes?.filter((attribute) => attribute?.attributeID === CLIENT_WARRANTY)[0]?.value ||
    defaultValue
  );
};

export function formatAssetAttribute(assetAttribute = null) {
  const ERROR_MSG = "Error obteniendo los attributos del activo";
  if (!Boolean(assetAttribute) || !Object.keys(assetAttribute).length) {
    captureException(ERROR_MSG, "formatAssetAttribute");
    throw new Error(ERROR_MSG);
  }

  return {
    id: assetAttribute.id,
    attributeId: assetAttribute.attribute.id,
    value: assetAttribute.value,
    name: assetAttribute.attribute.name,
    attribute: assetAttribute.attribute,
    _deleted: assetAttribute._deleted,
    _version: assetAttribute._version,
  };
}
