/**
 * Gestiona el almacenamiento de datos en localStorage y lo habilita como un estado dentro de un componente
 *
 * usage:
 * const [stateInLs, setStateInLS] = useLocalStorageState(
    "myLocalStorageKey",
    "initialStateInLs" ó initialStateInLs(),
  );
 */

import * as React from "react";

/**
 *
 * @param {String} key The key to set in localStorage for this value
 * @param {Object} defaultValue The value to use if it is not already in localStorage
 * @param {{serialize: Function, deserialize: Function}} options The serialize and deserialize functions to use (defaults to JSON.stringify and JSON.parse respectively)
 */

function useLocalStorageState(
  key,
  defaultValue = "",
  { serialize = JSON.stringify, deserialize = JSON.parse, storeFalsyValues = true } = {}
) {
  const [state, setState] = React.useState(() => {
    const valueInLocalStorage = window.localStorage.getItem(key);
    if (valueInLocalStorage) {
      return deserialize(valueInLocalStorage);
    }
    return typeof defaultValue === "function" ? defaultValue() : defaultValue;
  });

  const prevKeyRef = React.useRef(key);

  React.useEffect(() => {
    const prevKey = prevKeyRef.current;
    if (prevKey !== key) {
      window.localStorage.removeItem(prevKey);
    }
    prevKeyRef.current = key;
    if (!storeFalsyValues && !Boolean(state)) {
      return;
    }
    window.localStorage.setItem(key, serialize(state));
  }, [key, state, serialize, storeFalsyValues]);

  return [state, setState];
}

export default useLocalStorageState;
