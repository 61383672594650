import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import AddBoxIcon from "@material-ui/icons/AddBox";
import EditIcon from "@material-ui/icons/Edit";
import MaterialTable from "components/custom/MaterialTable";
import AttributeDialog from "components/AttributeDialog";
import useLoadingStatus from "hooks/useLoadingStatus";
import { deleteAssetAttribute } from "./datastore";
import useNotifier from "hooks/useNotifier";
import TableCellMobileFormat from "components/custom/TableCellMobileFormat";
import useTableMobileStyles from "hooks/useTableMobileStyles";
import { formatAssetAttribute } from "util/assets";

export default function AssetAttributesDialog({ isOpen, onClose, asset, onComplete, readOnly }) {
  const tableMobileStyles = useTableMobileStyles();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [assetAttributes, setAssetAttributes] = useState([]);
  const [selectedAttribute, setSelectedAttribute] = useState(null);
  const [showAttributeDialog, setShowAttributeDialog] = useState(false);
  const [deleting, _deleteAttribute] = useLoadingStatus(deleteAssetAttribute);
  const { showMessage, showError } = useNotifier();
  useEffect(() => {
    if (isOpen) {
      let attributes =
        asset?.embeddedAttributes?.items || asset?.attributes?.items || asset?.embeddedAttributes || asset?.attributes;
      attributes = attributes || [];
      setAssetAttributes([...attributes.map((assetAttribute) => formatAssetAttribute(assetAttribute))]);
    }
  }, [isOpen, asset]);

  function handleAddAttribute() {
    setShowAttributeDialog(true);
  }

  function addAttribute(assetAttribute) {
    setAssetAttributes([formatAssetAttribute(assetAttribute), ...assetAttributes]);
  }

  async function handleUpdateAttribute(e, rowData) {
    setSelectedAttribute({ ...rowData });
    setShowAttributeDialog(true);
  }

  function updateAttribute(updatedAssetAttribute) {
    updatedAssetAttribute = formatAssetAttribute(updatedAssetAttribute);
    const newAssetAttributes = [...assetAttributes];
    const index = newAssetAttributes.findIndex((a) => a.id === updatedAssetAttribute.id);
    newAssetAttributes[index] = {
      ...newAssetAttributes[index],
      value: updatedAssetAttribute.value,
    };
    setAssetAttributes(newAssetAttributes);
  }

  async function deleteAttribute({ id }) {
    try {
      await _deleteAttribute(id);
      showMessage("Se eliminó el atributo de forma exitosa.");
      const newAttributes = assetAttributes.filter((a) => a.id !== id);
      setAssetAttributes(newAttributes);
    } catch (error) {
      console.error(error);
      showError("Ocurrió un error al borrar el atributo.");
    }
  }

  function hideAttributeDialog() {
    setShowAttributeDialog(false);
    setSelectedAttribute(null);
  }

  function hideAssetAttributeDialog() {
    onComplete(assetAttributes);
    onClose();
  }
  return (
    <>
      <Dialog open={isOpen} fullScreen={isMobileScreen} fullWidth>
        <DialogTitle>Atributos del Activo</DialogTitle>
        <DialogContent dividers>
          {!readOnly && (
            <Box>
              <DialogContentText>
                <strong>Indicaciones:</strong>
              </DialogContentText>
              <Typography>Ingresa, actualiza o elimina los atributos deseados.</Typography>
            </Box>
          )}

          <Box>
            <Grid container>
              <Grid item xs={12}>
                <div className={tableMobileStyles.root}>
                  <MaterialTable
                    options={TABLE_OPTIONS}
                    columns={isMobileScreen ? TABLE_COLUMNS_MOBILE(readOnly) : TABLE_COLUMNS_DESKTOP}
                    data={assetAttributes}
                    isLoading={deleting}
                    editable={
                      readOnly
                        ? {}
                        : {
                            isDeletable: () => !readOnly,
                            onRowDelete: deleteAttribute,
                          }
                    }
                    actions={
                      readOnly
                        ? []
                        : [
                            {
                              icon: AddBoxIcon,
                              tooltip: "Agregar atributo",
                              isFreeAction: true,
                              onClick: handleAddAttribute,
                              disabled: readOnly,
                            },
                            {
                              // eslint-disable-next-line react/display-name
                              icon: () => <EditIcon color="action" />,
                              tooltip: "Actualizar atributo",
                              onClick: handleUpdateAttribute,
                              disabled: readOnly,
                            },
                          ]
                    }
                  />
                </div>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={hideAssetAttributeDialog} color="default">
            {readOnly ? "CERRAR" : "CANCELAR"}
          </Button>
          {!readOnly && (
            <Button onClick={hideAssetAttributeDialog} color="primary">
              CONTINUAR
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <AttributeDialog
        isOpen={showAttributeDialog}
        asset={asset}
        attribute={selectedAttribute}
        onClose={hideAttributeDialog}
        onCreate={addAttribute}
        onUpdate={updateAttribute}
      />
    </>
  );
}

AssetAttributesDialog.propTypes = {
  isOpen: PropTypes.bool,
  asset: PropTypes.object,
  onClose: PropTypes.func,
  onComplete: PropTypes.func,
  readOnly: PropTypes.bool,
};

AssetAttributesDialog.defaultProps = {
  isOpen: false,
  asset: null,
  onClose: () => {},
  onComplete: () => {},
  readOnly: false,
};

const TABLE_OPTIONS = {
  exportButton: false,
  columnsButton: false,
  paging: false,
};

const TABLE_COLUMNS_DESKTOP = [
  {
    title: "Etiqueta",
    field: "name",
  },
  {
    title: "Valor",
    field: "value",
  },
];

const TABLE_COLUMNS_MOBILE = (readOnly) => [
  {
    title: "Activos Actuales",
    render: ({ name, value }) => {
      const columns = [
        {
          title: "Etiqueta",
          description: name,
        },
        {
          title: "Valor",
          description: value,
        },
      ];
      return <TableCellMobileFormat columns={columns} sm={6} xs={readOnly ? 6 : 12} />;
    },
  },
];
