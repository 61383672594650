import { REQUEST_STATUS } from "constant/requestStatus";
import * as ActionTypes from "redux/action";
import dayjs from "dayjs";

const INITIAL_STATE = {
  rdxUsers: [],
  rdxStatus: REQUEST_STATUS.INACTIVE,
  rdxNextToken: null,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case ActionTypes.USERS_FETCH_ALL_PENDING:
      return { ...state, rdxStatus: REQUEST_STATUS.PENDING };
    case ActionTypes.USERS_FETCH_ALL_SUCCESS:
      return addUsers(state, action);
    case ActionTypes.USERS_FETCH_NEXT_SUCCESS:
      const newState = addUsers(state, action);
      newState.rdxUsers = [...state.rdxUsers, ...newState.rdxUsers];
      return newState;
    case ActionTypes.USERS_FETCH_ALL_ERROR:
      return { ...state, rdxStatus: REQUEST_STATUS.FAILED };
    default:
      return state;
  }
}

const addUsers = (state, action) => {
  const {
    listUsers: { items, nextToken },
  } = action.payload;
  const users = transformUsersData(items);
  return {
    ...state,
    rdxStatus: REQUEST_STATUS.SUCCESSFUL,
    rdxUsers: users,
    rdxNextToken: nextToken,
  };
};

const transformUsersData = (users) => {
  return users.map(
    ({
      id,
      name,
      lastnameP,
      lastnameM,
      phone,
      extension,
      email,
      createdAt,
      updatedAt,
    }) => {
      const fullName = `${name} ${lastnameP || ""} ${lastnameM || ""}`.trim();
      const formattedCreatedAtDate = dayjs(createdAt).format(
        "DD/MM/YYYY HH:mm"
      );
      const formattedUpdatedAtDate = dayjs(updatedAt).format(
        "DD/MM/YYYY HH:mm"
      );
      return {
        id,
        fullName,
        phone,
        extension,
        email,
        createdAt: formattedCreatedAtDate,
        updatedAt: formattedUpdatedAtDate,
      };
    }
  );
};
