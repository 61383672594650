import * as queries from "graphql-custom/queries";
import * as mutations from "graphql-custom/mutations";
import { getMaterialQuantity } from "redux/reducer/hook/installationMaterialsReducer";
import useGraphQL from "hooks/useGraphQL";
import useOnlineStatus from "@rehooks/online-status";
import useDataStore from "hooks/useDataStore";
import * as DS_MODELS from "models";
import removeProps from "util/removeProps";
import dayjs from "dayjs";
import { Logger } from "@aws-amplify/core";

const logger = new Logger("API-helper-progreso-mantenimiento");

export default function useAPIHelpers() {
  const isOnline = useOnlineStatus();
  const { loading: loadingOnline, runGraphQLOperation } = useGraphQL();

  const DataStoreTracking = useDataStore(DS_MODELS.Tracking);
  const DataStoreAsset = useDataStore(DS_MODELS.Asset);
  const DataStoreTaskAssets = useDataStore(DS_MODELS.TaskAssets);
  const DataStoreUnitOfMeassure = useDataStore(DS_MODELS.UnitOfMeassure);
  const DataStoreMaterial = useDataStore(DS_MODELS.Material);
  const DataStoreTask = useDataStore(DS_MODELS.Task);

  const loadingOffline =
    DataStoreTracking.loading ||
    DataStoreAsset.loading ||
    DataStoreTaskAssets.loading ||
    DataStoreUnitOfMeassure.loading ||
    DataStoreMaterial.loading ||
    DataStoreTask.loading;

  const loading = loadingOnline || loadingOffline;

  //generales
  async function finalizeMaintenance({ taskId, taskVersion, endedBy } = {}) {
    let result = null;

    if (isOnline) {
      result = await finalizeMaintenanceOnline(taskId, taskVersion, endedBy);
    } else {
      result = await finalizeMaintenanceOffline(taskId, endedBy);
    }

    if (!result || !result?.response || !result?.success) {
      throw new Error("finalizeMaintenance Error: Sin valores de respuesta");
    }
    result.response = formatFinalizeTaskResponse(result.response);
    logger.debug("finalizeMaintenance", result);
    return result;
  }

  async function finalizeMaintenanceOnline(taskId, taskVersion, endedBy) {
    let resultObject = null;
    //--> actualizar task
    const response = await runGraphQLOperation({
      operation: mutations.updateTask,
      variables: {
        input: {
          id: taskId,
          status: DS_MODELS.TaskStatus.COMPLETED,
          _version: taskVersion,
          endedAt: dayjs().toISOString(),
          endedBy,
        },
      },
      notifications: { errorMsg: "Error finalizando el mantenimiento, vuelve a intentarlo" },
    });

    //Parseo de la respuesta
    if (response?.updateTask && Object.keys(response?.updateTask)?.length) {
      resultObject = {
        response: response?.updateTask,
        success: true,
      };
    } else {
      resultObject = {
        response,
        success: false,
      };
    }
    return resultObject;
  }

  async function finalizeMaintenanceOffline(taskId, endedBy) {
    let resultObject = null;
    //--> actualizar task
    const response = await DataStoreTask.update({
      id: taskId,
      values: {
        status: DS_MODELS.TaskStatus.COMPLETED,
        endedAt: dayjs().toISOString(),
        endedBy,
      },
      notifications: { errorMsg: "Error finalizando el mantenimiento, vuelve a intentarlo" },
    });

    //Parseo de la respuesta
    let myResponse = { ...response };
    if (myResponse && Object.keys(myResponse)?.length) {
      // myResponse._version = myResponse?._version;
      resultObject = {
        response: myResponse,
        success: true,
      };
    } else {
      resultObject = {
        response: myResponse,
        success: false,
      };
    }
    return resultObject;
  }

  async function getUOMs() {
    let result;

    if (isOnline) {
      result = await getUOMsOnline();
    } else {
      result = await getUOMsOffline();
    }

    return result;
  }

  async function getUOMsOnline() {
    const UOMs = await runGraphQLOperation({
      operation: queries.listUoms,
    });
    return UOMs.listUnitOfMeassures.items;
  }

  async function getUOMsOffline() {
    const UOMs = await DataStoreUnitOfMeassure.get({ criteria: null });
    const parsedUOMs = UOMs.map((data) => ({ ...data }));
    return parsedUOMs;
  }

  //assets and materials operations
  async function addNewAssets(taskID, newAssets) {
    //1. update assets type to "ALLOCATED"
    const updatedAssetsType = await updateAssetsType(newAssets);

    //2. relate asset with task
    const relationResponse = await relateAssetsWithTask(taskID, updatedAssetsType);

    //3. hacer un format de la estructura de los datos
    const assetsData = updatedAssetsType;
    const relationData = relationResponse;
    const formatedAssets = formatAssetsAdded(assetsData, relationData);

    return {
      assetsData,
      relationData,
      formatedAssets,
    };
  }

  async function addNewMaterials(taskId, newMaterials) {
    if (!taskId || !newMaterials?.length) throw new Error("Error añadiendo materiales: datos invalidos.");

    let result = null;

    if (isOnline) {
      result = await addNewMaterialsOnline(taskId, newMaterials);
    } else {
      result = await addNewMaterialsOffline(taskId, newMaterials);
    }
    return result;
  }

  async function addNewMaterialsOnline(taskId, newMaterials) {
    //1. generar assets por cada material
    const assetsCreatedOutput = await generateAssetsForEachMaterial(newMaterials);

    //2. generar y crear una relación entre cada material con el task
    const relationTaskAssetOutput = await relateAssetsWithTask(taskId, assetsCreatedOutput);

    //3. hacer un format de la estructura de los materiales que se añadiran al estado
    const parsedMaterialsAdded = formatMaterialsAdded(assetsCreatedOutput, relationTaskAssetOutput);

    return parsedMaterialsAdded;
  }

  async function addNewMaterialsOffline(taskId, newMaterials) {
    logger.log("addNewMaterialsOffline:", { taskId, newMaterials });

    //1. generar un activo por cada material
    const assetsCreatedOutput = await createAssetsOffline(newMaterials);

    //2. relacionar cada activo con el task y parsear estructura de información
    const newMaterialsAdded = await relateTaskAssetOffline(taskId, assetsCreatedOutput);

    return newMaterialsAdded;
  }

  async function createAssetsOffline(newElements = []) {
    return Promise.all(
      newElements.map(async (material) => {
        const values = getMateralInputValuesOffline(material);
        logger.log("createAssetsOffline: input para crear asset", values);
        return DataStoreAsset.create({
          values,
        });
      })
    );
  }

  async function relateTaskAssetOffline(taskId, assets) {
    return Promise.all(
      assets.map(async (materialAsset) => {
        //input
        let newTaskAssetInput = {
          asset: materialAsset,
          taskAssetsAssetId: materialAsset.id,
          task: DataStoreTask.get({ criteria: taskId }),
          taskAssetsTaskId: taskId,
        };
        logger.log("addNewMaterialsOffline: input para crear taskAsset", { newTaskAssetInput, materialAsset });
        //operación para la relación
        const newTaskAsset = await DataStoreTaskAssets.create({
          values: newTaskAssetInput,
          notifications: {
            errorMsg: `Error añadiendo el material: ${materialAsset?.name}`,
          },
        });

        //parseo de la estructura de los datos
        const formatedMaterial = formatMaterialAddedOffline(newTaskAsset.id, materialAsset);
        logger.log({ formatedMaterial });
        return formatedMaterial;
      })
    );
  }

  function getMateralInputValuesOffline(material = null) {
    if (!material) throw new Error("getMateralInputValuesOffline: Error obteniendo valores del material");
    const uomId = material?.uomId;

    let input = {
      material: DataStoreMaterial.get({ criteria: material?.id }),
      assetMaterialId: material?.id,
      type: DS_MODELS.AssetType.MATERIAL_ALLOCATED,
      code: material?.code,
      quantity: material?.quantity,
      //default values
      serial: "DEFAULT_SERIE",
      tracking: DataStoreTracking.get({ criteria: "unassigned" }),
      trackingID: "unassigned",
      locationCode: "",
    };

    if (uomId) {
      input.uom = DataStoreUnitOfMeassure.get({ criteria: uomId });
      input.assetUomId = uomId;
    }

    return input;
  }

  function formatMaterialAddedOffline(taskAssetId = null, materialAssetData = null) {
    if (!taskAssetId || !materialAssetData)
      throw new Error("formatMaterialAddedOffline: Error obteniendo valores del material");

    return {
      relationId: taskAssetId,
      assetId: materialAssetData?.id,
      materialId: materialAssetData?.material?.id,
      uomId: materialAssetData?.uom?.id || null,
      assetVersion: 1,
      relationVersion: 1,
      code: materialAssetData?.code,
      name: materialAssetData?.material?.name,
      quantity: materialAssetData.quantity || 1,
      uom: materialAssetData?.uom ? { ...materialAssetData?.uom } : null,
      type: materialAssetData?.type || DS_MODELS.AssetType.MATERIAL_ALLOCATED,
      serial: materialAssetData?.serial || "DEFAULT_SERIE",
      trackingID: materialAssetData?.tracking.id || materialAssetData?.trackingID || null,
    };
  }

  async function updateMaterials(maintenanceId = null, materialsReducerObj, propToUpdate) {
    const { updated, deleted, all } = materialsReducerObj;
    if ((!updated?.length && !deleted?.length) || !maintenanceId) {
      throw new Error("Error en updateMaterials: Información invalida");
    }
    let result = null;

    if (isOnline) {
      result = await updateMaterialsOnline(maintenanceId, materialsReducerObj);
    } else {
      const materialFound = all?.find((material) => material?.materialId === updated[0]);
      result = await updateMaterialsOffline(materialFound, propToUpdate);
    }
    return result;
  }

  async function updateMaterialsOnline(maintenanceId, { all, added, updated, deleted }) {
    const mutationInfo = mutations.generateMaterialsUpdate(maintenanceId, all, added, updated, deleted);
    if (!mutationInfo) {
      return null;
    }
    const { mutation, params } = mutationInfo;
    const result = await runGraphQLOperation({
      operation: mutation,
      variables: params,
    });
    return result;
  }

  async function updateMaterialsOffline(asset, updateProp) {
    logger.log("updateMaterialsOffline", { asset, updateProp });
    // const uomId = asset.uom?.id
    //TODO Fix para habilitar la actualización de UOM
    let resultObj = null;
    let propToUpdateName = {
      // uom: "unidad de medida",
      quantity: "cantidad",
    };
    let valuesToUpdate = {
      quantity: asset.quantity,
    };
    // if(uomId) {
    //   valuesToUpdate.uom = DataStoreUnitOfMeassure.get({ criteria: asset.uom.id })
    //   valuesToUpdate.assetUomId = uomId
    // }

    logger.log("updateMaterialsOffline", { valuesToUpdate });
    const updatedMaterial = await DataStoreAsset.update({
      id: asset.assetId,
      values: valuesToUpdate,
      notifications: {
        errorMsg: `Error al actualizar la ${propToUpdateName[updateProp]}, vuelva a intentarlo.`,
      },
    });

    resultObj = {
      updateAsset0: {
        id: updatedMaterial.id,
        _deleted: updatedMaterial._deleted,
        _lastChangedAt: updatedMaterial._lastChangedAt,
        _version: updatedMaterial._version,
      },
    };
    logger.log("updateMaterialsOffline", { resultObj, updatedMaterial });

    return resultObj;
  }

  async function removeMaterials(materialsToRemove = []) {
    if (!materialsToRemove || !materialsToRemove?.length) {
      throw new Error(" Error en removeMaterials: Sin materiales que eliminar.");
    }
    let result = null;

    if (isOnline) {
      const updateMaterialsInput = {
        deleted: materialsToRemove,
        all: [],
        added: [],
        updated: [],
      };
      result = await updateMaterials(true, updateMaterialsInput);
    } else {
      result = await removeAssetOffline(materialsToRemove[0]?.id);
    }

    return result;
  }

  async function removeAsset(assetsToRemove) {
    if (!assetsToRemove || !Object.keys(assetsToRemove)?.length) throw new Error("Error: información invalida");
    let result = null;

    if (isOnline) {
      result = await removeAssetOnline(assetsToRemove);
    } else {
      result = await removeAssetOffline(assetsToRemove.id);
    }
    return result; // { assetsRemoved =[], assetsRemovedIds=[] }
  }

  async function removeAssetOnline(assetToRemove) {
    //1. generar inputs por cada activo a eliminar
    const inputsToRemoveAssets = generateInputsToRemoveAssets(assetToRemove);

    //2. desvincular cada activo del task
    const assetsRemovedResponse = await unrelateAssetFromTask(inputsToRemoveAssets);

    //3.formateo de datos de respuesta
    const assetsRemoved = fromObjectToArray(assetsRemovedResponse);
    const assetsRemovedIds = assetsRemoved.map(({ asset }) => {
      return asset.id;
    });

    return { assetsRemoved, assetsRemovedIds };
  }

  async function removeAssetOffline(taskAssetId = null) {
    if (!taskAssetId) {
      throw new Error("Error en removeAssetOffline: taskAssetId invalido");
    }

    const assetsDeleted = await DataStoreTaskAssets.remove({
      condition: taskAssetId,
    });

    const assetsRemoved = removeProps([assetsDeleted], { propsToDelete: defaultPropsToDelete });

    const assetsRemovedIds = assetsRemoved.map(({ asset }) => {
      return asset.id;
    });

    return { assetsRemoved, assetsRemovedIds };
  }

  async function updateAssetsType(newAssets = []) {
    //filtramos los activos que requieren actualizar su type
    const assetsToUpdate = newAssets.filter((asset) => asset.type !== DS_MODELS.AssetType.ALLOCATED);

    if (!assetsToUpdate.length) {
      const keys = newAssets.map((asset, index) => `updateAsset${index}`);
      return convertArrayToObject(newAssets, keys);
    }
    //filtramos los activos que requieren unicamente ser parseados
    const assetsToParse = newAssets.filter((asset) => asset.type === DS_MODELS.AssetType.ALLOCATED);
    const { assets, assetIds } = assetsToUpdate.reduce(
      (result, { id, _version }) => {
        result.assets.push({
          assetId: id,
          materialId: id,
          assetVersion: _version,
          type: DS_MODELS.AssetType.ALLOCATED,
        });
        result.assetIds.push(id);
        return result;
      },
      { assets: [], assetIds: [] }
    );
    const { mutation, params } = mutations.generateAssetTypeUpdateMutation(assets, assetIds, []);
    const updatedAssetsType = await runGraphQLOperation({
      operation: mutation,
      variables: params,
    });

    const assetsToParseKeys = assetsToParse.map(
      (asset, index) => `updateAsset${Object.keys(updatedAssetsType).length + index}`
    );

    const parsedAssets = convertArrayToObject(assetsToParse, assetsToParseKeys);

    return { ...updatedAssetsType, ...parsedAssets };
  }

  async function unrelateAssetFromTask(inputsToRemoveAssets) {
    const { mutation, params } = mutations.removeAssetFromTask(inputsToRemoveAssets);
    return runGraphQLOperation({ operation: mutation, variables: params });
  }

  async function generateAssetsForEachMaterial(newMaterials) {
    let materialsMutation = null;

    if (!newMaterials?.length) return;

    //1. generar y crear un asset por cada material
    const { variables, mutation } = mutations.generateAssetsInstallationMutation(
      newMaterials,
      DS_MODELS.AssetType.MATERIAL_ALLOCATED
    );

    materialsMutation = await runGraphQLOperation({ operation: mutation, variables });
    return materialsMutation;
  }

  async function relateAssetsWithTask(taskId, assetsIdsMap) {
    const { relationsVariables, relationsMutation } = mutations.generateRelations(taskId, assetsIdsMap);

    const relationsMutationRequest = await runGraphQLOperation({
      operation: relationsMutation,
      variables: relationsVariables,
    });
    return relationsMutationRequest;
  }

  //qr operations
  async function fetchQr(trackingId) {
    let result;

    if (isOnline) {
      result = await fetchQrOnline(trackingId);
    } else {
      result = await fetchQrOffline(trackingId);
    }

    return result;
  }

  async function fetchQrOnline(trackingId) {
    const { getTracking: tracking } = await runGraphQLOperation({
      operation: queries.getTracking,
      variables: { id: trackingId },
      notifications: { errorMsg: "Ocurrió un error al cargar los datos del código QR" },
    });
    return tracking?.assets?.items[0]?.id || [];
  }

  async function fetchQrOffline(trackingId) {
    const tracking = await DataStoreAsset.get({
      criteria: (asset) => asset.trackingID("eq", trackingId),
      notifications: { errorMsg: "Ocurrió un error al cargar los datos del código QR" },
    });
    return tracking[0]?.id;
  }

  async function scanQr(taskAssetId, assetVersion) {
    let resultObj = null;

    if (isOnline) {
      const response = await scanQrOnline(taskAssetId, assetVersion);
      resultObj = { response };
    } else {
      const response = await scanQrOffline(taskAssetId);
      resultObj = { response };
    }

    return resultObj;
  }

  async function scanQrOnline(taskAssetId, assetVersion) {
    const response = await runGraphQLOperation({
      operation: mutations.patchTaskAsset,
      variables: { input: { id: taskAssetId, _version: assetVersion, scanned: true } },
      notifications: {
        successMsg: "El código QR se validó correctamente con el activo",
        errorMsg: "Ocurrió un error al validar el código QR con el activo",
      },
    });
    return response?.updateTaskAssets;
  }

  async function scanQrOffline(taskAssetId) {
    const updatedAsset = await DataStoreTaskAssets.update({
      id: taskAssetId,
      values: {
        scanned: true,
      },
      notifications: {
        successMsg: "El código QR se validó correctamente con el activo",
        errorMsg: "Error al scanear QR de activo, vuelva a intentarlo.",
      },
    });
    const parsedAssetArray = removeProps([updatedAsset], { propsToDelete: [...defaultPropsToDelete, "asset"] });

    return parsedAssetArray[0];
  }

  return {
    finalizeMaintenance,
    addNewAssets,
    removeAsset,
    addNewMaterials,
    updateMaterials,
    removeMaterials,
    getUOMs,
    scanQr,
    fetchQr,
    loading,
    loadingOnline,
    loadingOffline,
  };
}

function fromObjectToArray(object) {
  const arrayOfKeys = Object.keys(object);

  return arrayOfKeys.map((relation, index) => object[arrayOfKeys[index]]);
}

const convertArrayToObject = (array = [], keys) => {
  const initialValue = {};
  return array.reduce((obj, item, index) => {
    return {
      ...obj,
      [keys[index]]: item,
    };
  }, initialValue);
};

function formatAssetsAdded(assetsData = {}, relationData = {}) {
  //1. union de ambos objetos y conversion a array
  /* 
  estructura deseada
  const result = [
    {schedule1: {...}, relation1: {...}},
    {schedule2: {...}, relation2: {...}},
  ]
  */
  const relationDataKeys = Object.keys(relationData);
  const assetsDataKeys = Object.keys(assetsData);

  const fromObjectToArray = relationDataKeys.map((relation, index) => ({
    [assetsDataKeys[index]]: assetsData[assetsDataKeys[index]],
    [relation]: relationData[relation],
  }));

  //2. formateo de la estructura de los materiales seleccionados
  const formatedMaterials = fromObjectToArray.map((objectPar, index) => {
    const assetsData = objectPar[assetsDataKeys[index]];
    const relationData = objectPar[relationDataKeys[index]];

    return {
      _deleted: relationData._deleted,
      _version: relationData._version,
      scanned: relationData.scanned,
      id: relationData.id,
      attachments: { items: [] },
      asset: {
        ...assetsData,
        comment: assetsData.comment || "",
      },
    };
  });

  return formatedMaterials;
}

function formatMaterialsAdded(materialsData = {}, relationData = {}) {
  //1. union de ambos objetos y conversion a array
  /* 
  estructura deseada
  const result = [
    {schedule1: {...}, relation1: {...}},
    {schedule2: {...}, relation2: {...}},
  ]
  */
  const relationDataKeys = Object.keys(relationData);
  const materialsDataKeys = Object.keys(materialsData);

  const fromObjectToArray = relationDataKeys.map((relation, index) => ({
    [materialsDataKeys[index]]: materialsData[materialsDataKeys[index]],
    [relation]: relationData[relation],
  }));

  //2. formateo de la estructura de los materiales seleccionados
  const formatedMaterials = fromObjectToArray.map((objectPar, index) => {
    const materialsData = objectPar[materialsDataKeys[index]];
    const relationData = objectPar[relationDataKeys[index]];

    return {
      relationId: relationData.id,
      relationVersion: relationData._version,
      materialId: materialsData?.material.id,
      code: materialsData?.material.code,
      name: materialsData?.material.name,
      uomId: materialsData?.uom?.id,
      assetId: materialsData?.id,
      quantity: getMaterialQuantity(materialsData?.quantity),
      assetVersion: materialsData?._version,
      uom: materialsData?.uom,
      type: materialsData?.type || DS_MODELS.AssetType.MATERIAL_ALLOCATED,
      serial: null,
      trackingID: null,
    };
  });

  return formatedMaterials;
}

function generateInputsToRemoveAssets(assetsToRemove) {
  //1. generar inputs por cada activo a eliminar
  let assetsToRemoveInputStructure;

  if (assetsToRemove.length) {
    assetsToRemoveInputStructure = assetsToRemove.map((asset) => ({
      id: asset.id,
      _version: asset._version,
    }));
  } else {
    assetsToRemoveInputStructure = [assetsToRemove].map((asset) => ({
      id: asset.id,
      _version: asset._version,
    }));
  }

  const assetsToRemoveInputs = mutations.generateInputs(assetsToRemoveInputStructure);
  return assetsToRemoveInputs;
}

function formatFinalizeTaskResponse(response) {
  return {
    _version: response._version,
    status: response.status,
    endedAt: response.endedAt,
    endedBy: response.endedBy,
    _lastChangedAt: response._lastChangedAt,
    updatedAt: response.updatedAt || dayjs(response?._lastChangedAt).toISOString(),
  };
}

const defaultPropsToDelete = ["warehouseKeepers", "supervisors", "supportEngineers", "task"];
