const createUserAgreement = /* GraphQL */ `
  mutation CreateUserAgreement($input: CreateUserAgreementInput!) {
    createUserAgreement(input: $input) {
      id
      isGranted
      legalDocumentID
      grantedAt
      _version
      createdAt
      userID
      agreement {
        _version
        name
        status
        description
        effectiveDate
        expirationDate
        id
        type
        version
      }
    }
  }
`;

export default createUserAgreement;
