export const getJobRunState = /* GraphQL */ `
  query GetJobRunState($input: lambdaJobsPollingInput) {
    lambdaJobsPolling(input: $input) {
      statusCode
      jobName
      jobRunState
      error
      errorMessage
    }
  }
`;

export default getJobRunState;
