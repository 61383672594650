import dayjs from "dayjs";
import { COUNTER_MODE, COUNTER_STATUS, COUNTER_COLORS } from "../constants";
import { TaskStatus, TicketStatus, TimerEventType } from "models";
import { Logger } from "@aws-amplify/core";
const logger = new Logger("Timer-helpers");

export function getCounterParams(taskInfo, { counterMode = COUNTER_MODE.COUNTUP, useMockData = false } = {}) {
  let result = {
    initialCount: 0,
    status: COUNTER_STATUS.STOPPED,
    fromStartToNow: 0,
    totalRangeTime: 0,
  };

  if (!taskInfo || !Object.keys(taskInfo)?.length) {
    logger.debug("getCounterParams taskInfo invalida.", { taskInfo, result });
    return result;
  }

  const taskStatus = taskInfo.status;
  const startDate = dayjs(`${taskInfo.startDate}T${taskInfo.startTime}`);
  const endDate = dayjs(`${taskInfo.endDate}T${taskInfo.endTime}`);
  const startedAt = taskInfo.startedAt || null;
  const endedAt = taskInfo.endedAt || null;
  const effectiveTime = taskInfo.effectiveTime;
  const embeddedLastEvent = taskInfo.embeddedLastEvent;

  // diferencia entre el tiempo desde que se inicio la actividad al tiempo de fin programado
  result.totalRangeTime = getTotalRangeTime(startDate, endDate);

  // status initial del counter
  result.status = getCounterStatus(taskInfo, { useMockData });

  //ms del tiempo efectivo de la actividad
  result.initialCount = getInitialCount(
    effectiveTime,
    result.status,
    taskStatus,
    startedAt,
    endedAt,
    embeddedLastEvent,
    { useMockData }
  );

  //diferencia entre el tiempo actual y la fecha programada de inicio
  result.fromStartToNow = dayjs(startDate).diff();

  if (isNaN(result.fromStartToNow) || result.fromStartToNow === null) {
    result.fromStartToNow = 0;
  }

  // logger.debug("getCounterParams", result);
  return result;
}

function getTotalRangeTime(startDate, endDate) {
  let totalRangeTime = dayjs(endDate).diff(dayjs(startDate));
  if (isNaN(totalRangeTime) || totalRangeTime === null) {
    totalRangeTime = 0;
  }
  return Math.abs(totalRangeTime);
}

export function getInitialCount(
  effectiveTime,
  counterStatus,
  taskStatus,
  startedAt,
  endedAt,
  embeddedLastEvent,
  { useMockData = false } = {}
) {
  let initialCount = 0;

  if (isNaN(effectiveTime) || effectiveTime === null) {
    effectiveTime = 0;
  }

  //early exits
  if (taskStatus === TaskStatus.SCHEDULED) {
    return initialCount;
  }
  if (taskStatus !== TaskStatus.IN_PROGRESS) {
    if (useMockData || effectiveTime === 0) {
      // tiempo de inicio a fin de la actividad registrados
      initialCount = dayjs(endedAt).diff(dayjs(startedAt));
    } else {
      initialCount = effectiveTime;
    }
    if (isNaN(initialCount) || initialCount === null) {
      initialCount = 0;
    }
    return initialCount;
  }
  //del inicio de la actividad al tiempo actual
  if (useMockData || effectiveTime === 0) {
    // nunca hubo una pausa
    initialCount = dayjs().diff(dayjs(startedAt));
  } else {
    //tiempo desde el tiempo efectivo a la fecha actual
    if (counterStatus === COUNTER_STATUS.RUNNING) {
      const fromEfectiveToCurrent = dayjs().diff(dayjs(embeddedLastEvent.timestamp));
      initialCount = effectiveTime + fromEfectiveToCurrent;
      // initialCount = effectiveTime;
    } else {
      initialCount = effectiveTime;
    }
  }

  if (isNaN(initialCount) || initialCount === null) {
    initialCount = 0;
  }
  return initialCount;
}

function getCounterStatus(taskInfo, { useMockData }) {
  const taskStatus = taskInfo.status;
  const eventCauseType = taskInfo.embeddedLastEvent?.embeddedCause?.type || null;
  const isStopped = taskStatus !== TaskStatus.IN_PROGRESS;

  let status = null;
  if (isStopped) {
    status = COUNTER_STATUS.STOPPED;
    return status;
  }
  if (!useMockData && eventCauseType) {
    if (eventCauseType === TimerEventType.PAUSE) {
      status = COUNTER_STATUS.PAUSED;
    } else {
      status = COUNTER_STATUS.RUNNING;
    }
  }

  if (!status) {
    status = COUNTER_STATUS.RUNNING;
  }
  return status;
}

export function handleButtonAriaLabel(timerMode) {
  let iconButtonAriaLabel = "Parado";
  if (timerMode === COUNTER_STATUS.PAUSED) {
    iconButtonAriaLabel = "Iniciar";
  }
  if (timerMode === COUNTER_STATUS.RUNNING) {
    iconButtonAriaLabel = "Pausar";
  }
  return iconButtonAriaLabel;
}

export function handleCounterColor(timerMode, timerStatus, count, totalRangeTime) {
  // logger.debug("handleCounterColor: props", { timerMode, timerStatus, count, totalRangeTime });
  if (timerStatus === COUNTER_STATUS.STOPPED) {
    return COUNTER_COLORS.PAUSED;
  }
  const oneQuarterOfTime = totalRangeTime / 4;
  const halfTime = oneQuarterOfTime * 2;
  if (timerMode === COUNTER_MODE.COUNTDOWN && timerStatus === COUNTER_STATUS.RUNNING) {
    if (count < oneQuarterOfTime) {
      return COUNTER_COLORS.LITTLE_TIME;
    }
    if (count < halfTime) {
      return COUNTER_COLORS.HALF_TIME;
    }
    return COUNTER_COLORS.RUNNING;
  }

  if (timerMode === COUNTER_MODE.COUNTUP && timerStatus === COUNTER_STATUS.RUNNING) {
    if (count > oneQuarterOfTime * 3) {
      return COUNTER_COLORS.LITTLE_TIME;
    }
    if (count > halfTime) {
      return COUNTER_COLORS.HALF_TIME;
    }
    return COUNTER_COLORS.RUNNING;
  }

  return COUNTER_COLORS.PAUSED;
}

export function formatCount(count, timerStatus, taskStatus, timerMode, totalRangeTime) {
  if (taskStatus === TicketStatus.OPEN) {
    return "Actividad aún no programada";
  }
  if (taskStatus === TaskStatus.SCHEDULED) {
    return "Actividad no iniciada";
  }
  if (taskStatus === TicketStatus.CANCELLED) {
    return "Actividad cancelada";
  }
  let formatedCount = "00:00:00";
  let timeToConvert = count;

  if (timerStatus === COUNTER_STATUS.STOPPED && timerMode === COUNTER_MODE.COUNTDOWN) {
    timeToConvert = totalRangeTime;
  }
  const { days, hours, minutes, seconds } = getTimeFromMS(timeToConvert);
  if (days !== "00") {
    formatedCount = `${days}:${hours}:${minutes}:${seconds}`;
  } else {
    formatedCount = `${hours}:${minutes}:${seconds}`;
  }
  return formatedCount;
}

export function getTimeFromMS(duration) {
  let seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24),
    days = Math.floor((duration / (1000 * 60 * 60 * 24)) % 365);

  days = days < 10 ? "0" + days : days;
  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;

  return {
    days,
    hours,
    minutes,
    seconds,
  };
}

export function getCounterStyles(formatedCount, counterColor) {
  const defaultStyles = {
    color: COUNTER_COLORS.PAUSED,
    fontWeight: 400,
    opacity: 0.7,
  };

  const dynamicStyles = {
    color: counterColor,
  };

  if (
    formatedCount === "Actividad aún no programada" ||
    formatedCount === "Actividad no iniciada" ||
    formatedCount === "Actividad cancelada"
  ) {
    return defaultStyles;
  } else {
    return dynamicStyles;
  }
}
