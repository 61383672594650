export const createBuildingLocation = /* GraphQL */ `
  mutation CreateBuildingLocation($input: CreateBuildingLocationInput!) {
    createBuildingLocation(input: $input) {
      id
      code
      name
      area
      buildingBlock
      floor
      premisesID
      premises {
        id
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export default createBuildingLocation;
