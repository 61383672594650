const updateTicket = /* GraphQL */ `
  mutation UpdateTicket($input: UpdateTicketInput!) {
    updateTicket(input: $input) {
      warehouseKeepers
      verificatorName
      urgency
      updatedAt
      ticketRequesterId
      ticketPremisesId
      ticketAssetId
      supportType
      premises {
        _lastChangedAt
        _version
        code
        createdAt
        id
        name
        owner
        updatedAt
        address {
          city
          id
          locality
          number
          settlement
          street
          updatedAt
          zipcode
        }
        company {
          owner
          name
          enabled
          id
          description
          createdAt
          _version
          _lastChangedAt
        }
      }
      requester {
        _lastChangedAt
        _version
        createdAt
        email
        extension
        id
        lastnameM
        lastnameP
        middlename
        name
        phone
        status
        supervisors
        supportEngineers
        updatedAt
        warehouseKeepers
      }
      project {
        _lastChangedAt
        _version
        code
        companyID
        contractNumber
        createdAt
        description
        id
        name
        updatedAt
        company {
          _lastChangedAt
          _version
          createdAt
          description
          enabled
          id
          name
          owner
          updatedAt
        }
      }
      asset {
        warehouseKeepers
        updatedAt
        type
        trackingID
        uom {
          updatedAt
          name
          id
          description
          abbreviation
          createdAt
          _version
          _lastChangedAt
        }
        tracking {
          updatedAt
          status
          ikaID
          id
          createdAt
          _version
          _lastChangedAt
        }
        supportEngineers
        supervisors
        serial
        quantity
        name
        locationCode
        id
        history
        enabled
        createdAt
        comment
        code
        _version
        _lastChangedAt
        embeddedAttributes {
          value
          name
          id
          attributeID
        }
        material {
          _lastChangedAt
          _version
          code
          createdAt
          deleted
          description
          enabled
          id
          manufacturer
          name
          trackable
          updatedAt
          category {
            name
            id
            description
            deleted
            createdAt
            abbreviation
            _version
            _lastChangedAt
            updatedAt
          }
        }
      }
      tasks {
        items {
          category
          createdAt
          deliveryInstallment
          effectiveTime
          endDate
          endTime
          endedAt
          endedBy
          externalTicketId
          id
          level
          owner
          priority
          rating
          startDate
          startTime
          startedAt
          startedBy
          status
          supervisors
          supportEngineers
          targetResolutionTime
          taskTicketId
          updatedAt
          _version
          _lastChangedAt
          _deleted
        }
      }
      externalRequester {
        email
        lastname
        name
      }
      validationSignatureFile {
        bucket
        key
        region
      }
      remarks {
        createdAt
        id
        remark
        ticketStatus
        updatedAt
      }
      reportedAsset {
        name
        serial
        location
      }
      supportEngineers
      supervisors
      subject
      status
      serviceSubcategory
      serviceCategory
      description
      id
      impact
      impactDetails
      mode
      requestType
      requestChannel
      ratingComment
      rating
      priority
      _version
      _lastChangedAt
      _deleted
      createdAt
    }
  }
`;

export default updateTicket;
