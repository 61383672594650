import React from "react";
import Typography from "@material-ui/core/Typography";
import DataLoadView from "./DataLoadView";
import { MATERIAL_CATALOG } from "constant/route/admin";

const TABLE_COLUMNS = [
  { title: "Nombre", field: "name", required: true },
  { title: "Código", field: "code", required: true },
  { title: "Descripción", field: "description" },
  { title: "Fabricante", field: "manufacturer" },
];

function MaterialDataLoadView() {
  return (
    <DataLoadView
      appbarTitle="Materiales - Carga Manual"
      sectionTitle="Carga de Materiales por archivo"
      goBackTo={MATERIAL_CATALOG}
      description={
        <>
          <Typography paragraph>
            En este apartado puede realizar la carga de materiales mediante un
            archivo en formato CSV.
          </Typography>
          <Typography paragraph>
            Cargue un archivo con el formato especificado y revise los registros
            ingresados. Puede modificar los registros cargados en caso de ser
            necesario. Una vez revisados los datos puede confirmar su ingreso al
            sistema.
          </Typography>
        </>
      }
      templatePath="tmp/templates/materials_template_es.csv"
      templateName="materials_template.csv"
      job="MATERIAL"
      dataLoadPrefix="material"
      tableColumns={TABLE_COLUMNS}
    />
  );
}

export default MaterialDataLoadView;
