export const getGoodsReceipt = /* GraphQL */ `
  query GetGoodsReceipt($id: ID!) {
    getGoodsReceipt(id: $id) {
      id
      _version
      quantity
      user {
        name
        middlename
        lastnameP
        lastnameM
        email
        phone
      }
      type
      fileName
      createdAt
      assets(limit: 350) {
        items {
          id
          asset {
            id
            code
            serial
            quantity
            type
            comment
            trackingID
            tracking {
              id
              status
              ikaID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            material {
              id
              name
              code
            }
          }
        }
      }
      project {
        id
        name
        code
        company {
          id
          name
        }
      }
      file {
        bucket
        region
        key
      }
    }
  }
`;
