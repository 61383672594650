import { useEffect, useRef } from "react";
import { MAINTENANCE_DETAIL } from "constant/route/operation";
import { useIndexedDB } from "react-indexed-db";
import { Logger } from "@aws-amplify/core";
import useGetLastAndCurrentPathname from "hooks/useGetLastAndCurrentPathname";

const logger = new Logger("useClearIDBTaskEvidences");

const useClearIDBTaskEvidences = () => {
  const [currentPathname, lastPathname] = useGetLastAndCurrentPathname();
  const firstUseEffectCall = useRef(true);
  const task_images = useIndexedDB("task_images");

  //limpiar evidencias del task al cambiar de locación dentro del app
  useEffect(() => {
    // la url actual pertenece a una vista de detalle de mantenimiento
    const iAmInAMaintenaceDetail = currentPathname.includes(MAINTENANCE_DETAIL);

    //la url anterior pertenecia a una vista de detalle de matenimiento
    const iWasInAMaintenanceDetail = lastPathname.includes(MAINTENANCE_DETAIL);

    if (firstUseEffectCall.current) {
      firstUseEffectCall.current = false;

      if (!iAmInAMaintenaceDetail) {
        clearIDBTaskImages();
        return;
      }
    }

    if ((firstUseEffectCall.current && iAmInAMaintenaceDetail) || (iAmInAMaintenaceDetail && iWasInAMaintenanceDetail))
      return;

    if (iWasInAMaintenanceDetail && !iAmInAMaintenaceDetail) {
      clearIDBTaskImages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPathname, lastPathname]);

  async function clearIDBTaskImages() {
    try {
      return task_images.clear().then(() => logger.log("EVIDENCIAS POR TASK LIMPIADAS EN INDEXED-DB"));
    } catch (error) {
      logger.error("clearIDBTaskImages", error);
    }
  }
};

export default useClearIDBTaskEvidences;
