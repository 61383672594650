export const ROOT = "/almacen";

export const MATERIAL_ROOT = `${ROOT}/material`;
export const ASSETS_ROOT = `${ROOT}/activo`;
export const ASSETS_IN = `${ROOT}/ingreso`;

export const IN_MAT_MOVEMENTS = `${MATERIAL_ROOT}/movimientos/entrada`;
export const IN_MAT_MOVEMENT = `${IN_MAT_MOVEMENTS}/:entryId`;
export const IN_MAT_PENDING_MOVEMENTS = `${IN_MAT_MOVEMENTS}/pendientes`;
export const IN_MAT_VALIDATED_MOVEMENTS = `${IN_MAT_MOVEMENTS}/validados`;
export const OUT_MAT_MOVEMENTS = `${MATERIAL_ROOT}/movimientos/salida`;
export const OUT_MAT_MOVEMENT = `${OUT_MAT_MOVEMENTS}/:entryId`;
export const OUT_MAT_PENDING_MOVEMENTS = `${OUT_MAT_MOVEMENTS}/pendientes`;
export const OUT_MAT_VALIDATED_MOVEMENTS = `${OUT_MAT_MOVEMENTS}/validados`;

export const IN_ASSET_MOVEMENTS = `${ASSETS_ROOT}/movimientos/entrada`;
export const IN_ASSET_MOVEMENT = `${IN_ASSET_MOVEMENTS}/:entryId`;
export const IN_ASSET_PENDING_MOVEMENTS = `${IN_ASSET_MOVEMENTS}/pendientes`;
export const IN_ASSET_VALIDATED_MOVEMENTS = `${IN_ASSET_MOVEMENTS}/validados`;
export const OUT_ASSET_MOVEMENTS = `${ASSETS_ROOT}/movimientos/salida`;
export const OUT_ASSET_MOVEMENT = `${OUT_ASSET_MOVEMENTS}/:entryId`;
export const OUT_ASSET_PENDING_MOVEMENTS = `${OUT_ASSET_MOVEMENTS}/pendientes`;
export const OUT_ASSET_VALIDATED_MOVEMENTS = `${OUT_ASSET_MOVEMENTS}/validados`;

export const MATERIAL_REQUIREMENT = `${MATERIAL_ROOT}/requerimientos`;
export const MATERIAL_REQUIREMENT_DETAIL = `${MATERIAL_ROOT}/requerimiento`;
export const MATERIAL_REQUIREMENT_DETAIL_WITH_PARAM = `${MATERIAL_ROOT}/requerimiento/:actionId`;
export const MATERIAL_PENDING_REQUIREMENT = `${MATERIAL_ROOT}/requerimientos/pendientes`;
export const MATERIAL_APPROVED_REQUIREMENT = `${MATERIAL_ROOT}/requerimientos/aprobados`;
export const MATERIAL_REJECTED_REQUIREMENT = `${MATERIAL_ROOT}/requerimientos/rechazados`;
export const MATERIAL_TRANSFER = `${MATERIAL_ROOT}/transferencias`;
export const MATERIAL_PENDING_TRANSFERS = `${MATERIAL_ROOT}/transferencias/pendientes`;
export const MATERIAL_FINALIZED_TRANSFERS = `${MATERIAL_ROOT}/transferencias/finalizadas`;
export const MATERIAL_CANCELED_TRANSFERS = `${MATERIAL_ROOT}/transferencias/canceladas`;
export const MATERIAL_TRANSFER_DETAIL = `${MATERIAL_ROOT}/transferencia`;
export const MATERIAL_TRANSFER_DETAIL_WITH_PARAM = `${MATERIAL_ROOT}/transferencia/:actionId`;

export const ASSETS_IN_WAREHOUSE = `${ASSETS_ROOT}/existencias`;
export const WAREHOUSE_DELIVERIES = `${ROOT}/envios`;
export const WAREHOUSE_DELIVERY_DETAILS = `${ROOT}/envio/:id`;

//**************** */
export const WAREHOUSE_ASSET_INCOME = `${ROOT}/ingresos`;
export const WAREHOUSE_ASSET_INCOME_DETAIL = `${ROOT}/ingresos/:incomeId`;

export const QR_GENERATOR = `${ROOT}/generador-qr`;
// Ruta de listado de activos en el sistema
export const WAREHOUSE_ASSETS = `${ROOT}/activos`;
export const WAREHOUSE_ASSET_DETAILS = `${ROOT}/activo`;
export const WAREHOUSE_ASSET_DETAILS_WITH_PARAM = `${ROOT}/activo/:id`;
export const WAREHOUSE_ASSETS_NEW = `${ASSETS_ROOT}/activos/ingreso`;

export const MATERIAL = {
  ROOT: MATERIAL_ROOT,
  IN_MOVEMENTS: IN_MAT_MOVEMENTS,
  IN_MOVEMENT: IN_MAT_MOVEMENT,
  IN_PENDING_MOVEMENTS: IN_MAT_PENDING_MOVEMENTS,
  IN_VALIDATED_MOVEMENTS: IN_MAT_VALIDATED_MOVEMENTS,
  OUT_MOVEMENTS: OUT_MAT_MOVEMENTS,
  OUT_MOVEMENT: OUT_MAT_MOVEMENT,
  OUT_PENDING_MOVEMENTS: OUT_MAT_PENDING_MOVEMENTS,
  OUT_VALIDATED_MOVEMENTS: OUT_MAT_VALIDATED_MOVEMENTS,
  REQUIREMENT: MATERIAL_REQUIREMENT,
  REQUIREMENT_DETAIL: MATERIAL_REQUIREMENT_DETAIL,
  REQUIREMENT_DETAIL_WITH_PARAM: MATERIAL_REQUIREMENT_DETAIL_WITH_PARAM,
  PENDING_REQUIREMENT: MATERIAL_PENDING_REQUIREMENT,
  APPROVED_REQUIREMENT: MATERIAL_APPROVED_REQUIREMENT,
  REJECTED_REQUIREMENT: MATERIAL_REJECTED_REQUIREMENT,
  TRANSFER: MATERIAL_TRANSFER,
  PENDING_TRANSFERS: MATERIAL_PENDING_TRANSFERS,
  FINALIZED_TRANSFERS: MATERIAL_FINALIZED_TRANSFERS,
  CANCELED_TRANSFERS: MATERIAL_CANCELED_TRANSFERS,
  TRANSFER_DETAIL: MATERIAL_TRANSFER_DETAIL,
  TRANSFER_DETAIL_WITH_PARAM: MATERIAL_TRANSFER_DETAIL_WITH_PARAM,
};

export const ASSET = {
  ROOT: ASSETS_ROOT,
  IN_MOVEMENTS: IN_ASSET_MOVEMENTS,
  IN_MOVEMENT: IN_ASSET_MOVEMENT,
  IN_PENDING_MOVEMENTS: IN_ASSET_PENDING_MOVEMENTS,
  IN_VALIDATED_MOVEMENTS: IN_ASSET_VALIDATED_MOVEMENTS,
  OUT_MOVEMENTS: OUT_ASSET_MOVEMENTS,
  OUT_MOVEMENT: OUT_ASSET_MOVEMENT,
  OUT_PENDING_MOVEMENTS: OUT_ASSET_PENDING_MOVEMENTS,
  OUT_VALIDATED_MOVEMENTS: OUT_ASSET_VALIDATED_MOVEMENTS,
  IN_WAREHOUSE: ASSETS_IN_WAREHOUSE,
  NEW_ASSETS: WAREHOUSE_ASSETS_NEW,
};
const WAREHOUSE = {
  ROOT,
  MATERIAL,
  ASSET,
  WAREHOUSE_DELIVERIES,
  WAREHOUSE_DELIVERY_DETAILS,
  WAREHOUSE_ASSET_INCOME,
  WAREHOUSE_ASSET_INCOME_DETAIL,
  QR_GENERATOR,
  WAREHOUSE_ASSETS,
};

export default WAREHOUSE;
