// Componente container para la vista de instalaciones del modulo de validación
import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import ValidatorInstallationsViewUI from "./ValidatorInstallationsView.ui";

import useHeaderTitle from "hooks/useHeaderTitle";
import useLoadingStatus from "hooks/useLoadingStatus";
import useNotifier from "hooks/useNotifier";
import useGraphQL from "hooks/useGraphQL";
import * as queries from "graphql-custom/queries";
import { TaskCategory } from "models";

import { testForDuplicated, removeDuplicated } from "hooks/useFetchTasks";
import { INSTALLATION_DETAIL } from "constant/route/validation";
import { TaskStatus } from "models";

const DEFAULT_ROWS_PER_PAGE = 100;
const SECTION_TITLE = "Validación de Instalaciones";

function ValidatorInstallationsView() {
  const [installations, setInstallations] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [instDialogOpen, setInstDialogOpen] = useState(false);
  const [newKitDialogOpen, setNewKitDialogOpen] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const cognitoGroups = useSelector((state) => state.session.groups);
  const rdxUserId = useSelector((store) => store.session.userId);
  const { runGraphQLOperation } = useGraphQL();
  const { showError } = useNotifier();
  const history = useHistory();

  const [isFetching, _fetchInstallations] = useLoadingStatus(fetchInstallations);

  useHeaderTitle(SECTION_TITLE);
  useEffect(() => {
    _fetchInstallations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTabIndex, rowsPerPage]);

  const handlePageChange = useCallback((page, pageSize) => {
    const forward = currentPage < page;
    const lastPageNumber = installations.length / pageSize - 1;
    const isLastPage = lastPageNumber === page;
    const hasMoreItems = isLastPage && !!nextToken;

    if (forward && hasMoreItems) {
      _fetchInstallations();
    }

    setCurrentPage(page);
  }, []);

  const handleChageTab = useCallback((index) => {
    setInstallations([]);
    setNextToken(null);
    setActiveTabIndex(index);
  }, []);

  async function fetchInstallations() {
    try {
      let taskLists;
      const rowsLimit = rowsPerPage * 20;
      if (cognitoGroups.includes("auditors")) {
        taskLists = await fetchTasks({ nextToken, limit: rowsLimit });
      } else {
        taskLists = await fetchTasksBySupervisor({ nextToken, limit: rowsLimit });
      }
      setInstallations((installations) => {
        return [...installations, ...taskLists.items];
      });
      setNextToken(taskLists.nextToken);
    } catch (error) {
      console.error(error);
      showError("Ocurrió un error al realizar la consulta de instalaciones.");
    }
  }

  async function fetchTasks({ nextToken = null, limit }) {
    const tabStatus = TAB_TASK_STATUSES[activeTabIndex];

    let variables = {
      nextToken,
      category: TaskCategory.IMPLEMENTATION,
      limit,
    };

    if (tabStatus !== TaskStatus.GENERAL) {
      variables.filter = { status: { eq: tabStatus } };
    }

    const { tasksByCategory } = await runGraphQLOperation({
      operation: queries.listTasksByCategory,
      variables,
    });

    let { items: tasks = [], nextToken: token } = tasksByCategory;

    if (testForDuplicated(tasks)) {
      tasks = removeDuplicated(tasks);
      return { tasks, nextToken: token };
    }

    if (!!token && tasks.length < limit) {
      let { items: nextTasks, nextToken: _token } = await fetchTasks({ items: tasks, nextToken: token });
      tasks = [...tasks, ...nextTasks];
      token = _token;
    }

    return { items: tasks, nextToken: token };
  }

  async function fetchTasksBySupervisor({ nextToken = null, limit }) {
    const tabStatus = TAB_TASK_STATUSES[activeTabIndex];

    let operation = queries.getTasksBySupervisorAndCategory;
    let variables = {
      limit,
      userId: rdxUserId,
      status: tabStatus,
      nextToken,
      filter: {
        and: [{ category: { eq: TaskCategory.IMPLEMENTATION } }],
      },
    };

    if (tabStatus !== TaskStatus.GENERAL) {
      variables.filter.and.push({ status: { eq: tabStatus } });
    }

    const res = await runGraphQLOperation({ operation, variables });

    let { items: tasks = [], nextToken: token } = res.getUser.tasksSupervised;

    if (testForDuplicated(tasks)) {
      tasks = removeDuplicated(tasks);
      return { tasks, nextToken: token };
    }

    if (!!token && tasks.length < limit) {
      let { items: nextTasks, nextToken: _token } = await fetchTasks({ items: tasks, nextToken: token });
      tasks = [...tasks, ...nextTasks];
      token = _token;
    }

    return { items: tasks, nextToken: token };
  }

  const handleFloatingButtonClick = useCallback(() => {
    setInstDialogOpen(true);
  }, []);

  const handleCancelation = useCallback(() => {
    setInstDialogOpen(false);
  }, []);

  function handleInstalationSuccess() {
    setInstDialogOpen(false);
    setInstallations([]);
    _fetchInstallations();
  }

  function handleNewKitDialogStatus(status) {
    setNewKitDialogOpen(status);
  }

  const handleRowClick = useCallback((event, rowData) => {
    history.push(`${INSTALLATION_DETAIL}/${rowData.id}`, {
      action: rowData,
    });
  }, []);

  return (
    <ValidatorInstallationsViewUI
      tasks={installations}
      onFloatingButtonClick={handleFloatingButtonClick}
      onRowClick={handleRowClick}
      installationDialogProps={{
        open: instDialogOpen,
        onCancel: handleCancelation,
        onSuccess: handleInstalationSuccess,
        newKitDialogOpen: newKitDialogOpen,
        handleNewKitDialogStatus: handleNewKitDialogStatus,
      }}
      isLoading={isFetching}
      onChangeTab={handleChageTab}
      onChangeRowsPerPage={setRowsPerPage}
      onChangePage={handlePageChange}
    />
  );
}

const TAB_TASK_STATUSES = {
  0: TaskStatus.GENERAL,
  1: TaskStatus.SCHEDULED,
  2: TaskStatus.IN_PROGRESS,
  3: TaskStatus.COMPLETED,
  4: TaskStatus.WITH_INSPECTION_NOTES,
  5: TaskStatus.FINALIZED, // Se utiliza status finalized para instalaciones validadas y firmadas por el cliente.
  6: TaskStatus.REVIEWED, // status final disparado por usuario coordinador
};

export default ValidatorInstallationsView;
