const dict = {
  es: {
    "Create a new account": "Crear nueva cuenta",
    "Forgot your password?": "¿Olvidaste tu contraseña?",
    "Sign in to your account": "Inicia sesión en tu cuenta",
    "Sign In": "Iniciar sesión",
    "Sign in": "Iniciar sesión",
    "Sign Up": "Regístrate",
    "Username *": "Usuario *",
    Username: "Usuario",
    "Password *": "Contraseña *",
    "Email Address *": "Correo *",
    "Phone Number *": "Número Telefónico *",
    Email: "correo@dominio.com",
    Password: "Contraseña",
    "Enter your password": "Ingresa tu contraseña",
    "Enter your Username": "Ingresa tu usuario",
    "Enter your username": "Ingresa tu usuario",
    "No account?": "¿No tienes una cuenta?",
    "Create account": "Crea una cuenta",
    "Create Account": "Crear cuenta",
    "Have an account?": "¿Tenes cuenta?",
    "Have a code?": "¿Tienes un código?",
    "Code *": "Codigo *",
    Confirm: "Confirmar",
    "Verification code": "Verificar código",
    "Enter code": "Ingresar código",
    "New password": "Nueva contraseña",
    "Enter your new password": "Ingresa nueva contraseña",
    "Reset Password": "Recuperar Contraseña",
    "Reset password": "Recuperar contraseña",
    "Back to Sign in": "Volver al inicio de sesion",
    "You will receive a verification code to reset your password":
      "Recibirás un codigo de verificacion para restablecer tu contraseña",
    Submit: "Enviar",
    Verify: "Verificar",
    "Verify Contact": "Verificar contacto",
    Skip: "Omitir",
    "Lost your code?'": "¿Perdiste tu código?",
    Resend: "Reenviar",
    Code: "Código",
    "Account recovery requires verified contact information":
      "La recuperación de la cuenta requiere información de contacto verificada",
    "User does not exist": "El usuario no existe",
    "User already exists": "El usuario ya existe",
    "Incorrect username or password": "Nombre de usuario o contraseña incorrecta",
    "Invalid password format": "Formato de contraseña inválido",
    "Username cannot be empty": "El nombre de usuario no puede estar vacio",
    "Custom auth lambda trigger is not configured for the user pool.":
      "No esta permitido el inicio de sesión sin contraseña",
    "Incorrect username or password.": "Usuario o contraseña incorrecto",
    "Forget your password? ": "¿Olvidaste tu contraseña?",
    "No account? ": "¿No tienes una cuenta?",
    "Have an account? ": "¿Ya tienes un cuenta?",
    "Send Code": "Enviar código",
    "Back to Sign In": "Regresa al inicio de sesión",

    //Añadidos por versión 2 de amplify react-ui
    "Change Password": "Cambiar contraseña",
    Changing: "Cambiando",
    "Confirm a Code": "Confirmar un código",
    "Confirm Sign In": "Confirmar inicio de sesión",
    "Confirm Sign Up": "Confirmar registro",
    "Confirm TOTP Code": "Confirmar código TOTP",
    "Confirmation Code": "Código de confirmación",
    Confirming: "Confirmando",
    "Creating Account": "Creando cuenta",
    "Forgot Password": "Olvidé mi contraseña",
    "Invalid phone number format": "Formato de número de teléfono inválido",
    "Hide password": "Ocultar contraseña",
    "Loading...": "Cargando...",
    or: "o",
    "Phone Number": "Número de teléfono",
    "Resend a Code": "Reenviar un código",
    "Reset your password": "Restablecer su contraseña",
    "Reset your Password": "Restablecer su Contraseña",
    "Setup TOTP": "Configurar TOTP",
    "Show password": "Mostrar contraseña",
    "Sign In with Amazon": "Iniciar Sesión con Amazon",
    "Sign In with Apple": "Iniciar Sesión con Apple",
    "Sign In with Facebook": "Iniciar Sesión con Facebook",
    "Sign In with Google": "Iniciar Sesión con Google",
    "Sign Out": "Cerrar sesión",
    "Signing in": "Iniciando sesión",

    "An account with the given email already exists.": "Ya existe una cuenta con el correo electrónico proporcionado. ",
    "Your passwords must match": "Tus contraseñas deben coincidir",
    "Password attempts exceeded": "Excedido el tiempo de reintentos, espera unos segundos",
    "Invalid phone number format.":
      "Formato de número de teléfono inválido. Utiliza el formato de número de teléfono 345678900",
    "Forgot Password?": "¿Olvidaste tu contraseña?",
    "You will receive a verification code": "Recibirás un codigo de verificación",
    "Password did not conform with policy: Password not long enough":
      "la contraseña debe contener una longitud minima de 8 caracteres.",
    "Enter your code": "Ingresar tú código",
    "Your code is on the way. To log in, enter the code we sent you. It may take a minute to arrive.":
      "Su código se ha enviado. Para iniciar sesión, ingrese el código que le enviamos a su correo. Puede demorar unos segundos.",
    "Invalid code provided, please request a code again.":
      "El código ingresado es invalido, por favor solicite un código nuevamente",
    "We Sent A Code": "Hemos enviado un código de verificación",
    "Invalid verification code provided, please try again.":
      "El código de verificación proporcionado no es valido, inténtelo de nuevo.",
    "Enter your email": "Introduce to correo eléctronico",
    "Password does not conform to policy: Password not long enough":
      "La contraseña debe tener una longitud minima de 8 caracteres",
    "Resend Code": "Reenviar Código",
    "Send code": "Enviar código",
    "User does not exist.": "El usuario no existe.",
    Sending: "Enviando...",
    Submitting: "Enviando...",
    "Submitting...": "Enviando...",
    "New Password": "Nueva Contraseña",
    "Confirm Password": "Confirmar Contraseña",
    "Attempt limit exceeded, please try after some time.":
      "Se excedió el límite de intentos, intente después de un tiempo.",
    "We Emailed You": "Te enviamos un correo electrónico",

    "Your code is on the way. To log in, enter the code we emailed to":
      "Te enviamos un correo electrónico. Para iniciar sesión, introduce el código que hemos enviado a",

    "It may take a minute to arrive.": "Puede tardar un momento en llegar",
    "PreAuthentication failed with error User has no groups assigned.":
      "Tu usuario no cuenta con ningún rol asignado. Contacta a tu administrador para que seas asignado a uno.",
    "Invalid email address format.": "Formato de email invalido.",
    "PreSignUp failed with error Es necesario aceptar los acuerdos de usuario para el registro de una nueva cuenta..":
      "Es necesario aceptar los acuerdos de usuario para el registro de una nueva cuenta.",
  },
};

export default dict;
