import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "components/custom/Button";
import ConfirmationDialog from "components/ConfirmationDialog";
import useBooleanFlag from "hooks/useBooleanFlag";
import useNotifier from "hooks/useNotifier";
import { useActions } from "hooks/useActions";
import * as adminSiteCatalog from "redux/dispatcher/adminSiteCatalog";
import { REQUEST_STATUS } from "constant/requestStatus";
import { FORM_MODES } from "constant/formModes";
import CustomerProjectSelector from "components/CustomerProjectSelector";

const EXTERIOR_NUMBER_RULE = {
  required: "Campo obligatorio",
  validate: {
    notNegative: (value) => Number(value) >= 0 || "Sólo valores positivos",
  },
};
const NAME_FORM_RULES = {
  required: "Campo obligatorio",
  maxLength: { value: 50, message: "Máximo 50 caracteres" },
  validate: {
    notEmpty: (value) => value?.trim() !== "" || "Campo obligatorio",
  },
};
const CODE_FORM_RULES = {
  required: "Campo obligatorio",
  maxLength: { value: 50, message: "Máximo 10 caracteres" },
  validate: {
    notEmpty: (value) => value?.trim() !== "" || "Campo obligatorio",
  },
};

const DEFAULT_FORM = {
  // Premise own data
  premiseName: "",
  premiseCode: "",
  // Company data
  companyID: "",
  // Address data
  street: "",
  number: "",
  zipcode: "",
  city: "",
  settlement: "",
  locality: "",
  // Custodian data
  custodianName: "",
  middlename: "",
  lastnameP: "",
  lastnameM: "",
  email: "",
  phone: "",
  extension: "",
};

function mapPremiseToForm(premise) {
  const { name, company, address, custodian } = premise;
  return {
    companyID: company.id,
    premiseName: name,
    premiseCode: premise.code,
    street: address.street,
    number: address.number,
    zipcode: address.zipcode,
    city: address.city,
    settlement: address.settlement,
    locality: address.locality,
    custodianName: custodian?.name || "",
    middlename: custodian?.middlename || "",
    lastnameP: custodian?.lastnameP || "",
    lastnameM: custodian?.lastnameM || "",
    email: custodian?.email || "",
    phone: custodian?.phone || "",
    extension: custodian?.extension || "",
  };
}

function valueTo(object, originalValue, newValue) {
  const newObject = { ...object };
  Object.keys(newObject).forEach((key) => {
    if (newObject[key] === originalValue) {
      newObject[key] = newValue;
    }
  });
  return newObject;
}

function getDialogText(formMode) {
  if (formMode === FORM_MODES.CREATE) {
    return {
      title: "Nuevo Sitio",
      confirmBtn: "Crear",
      confirmationMsg: "Se registrará un nuevo sitio en el sistema.",
      successMsg: "Creación exitosa",
    };
  } else if (formMode === FORM_MODES.UPDATE) {
    return {
      title: "Actualizar Sitio",
      confirmBtn: "Actualizar",
      confirmationMsg: "Se actualizará el sitio en el sistema.",
      successMsg: "Actualización exitosa",
    };
  }
}

function PremiseDialog(props) {
  const { rdxBeforePremise, rdxBeforeCreateStatus, rdxCreateStatus, rdxUpdateStatus } = useSelector(
    ({ adminPremisesCreate }) => adminPremisesCreate
  );
  const [createBeforePremise, createBeforePremiseClear, createPremise, createPremiseClear, patchPremise] = useActions([
    adminSiteCatalog.createBeforePremise,
    adminSiteCatalog.createBeforePremiseClear,
    adminSiteCatalog.createPremise,
    adminSiteCatalog.createPremiseClear,
    adminSiteCatalog.updatePremise,
  ]);
  const [confirmDialogOpen, openConfirmDialog, closeConfirmDialog] = useBooleanFlag();
  const { control, getValues, trigger, errors, setValue, watch, setError, clearErrors } = useForm({
    mode: "onChange",
    defaultValues: props.premise ? mapPremiseToForm(props.premise) : DEFAULT_FORM,
  });
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const { showMessage } = useNotifier();
  const [formMode] = useState(props.premise ? FORM_MODES.UPDATE : FORM_MODES.CREATE);
  const [dialogText] = useState(getDialogText(formMode));
  const companyID = watch("companyID");

  // Monitoreo para cambios que ocurren de la primera parte de la operación
  // de creación (creación de address, custodian y company) de un Premise (Sitio)
  useEffect(() => {
    if (rdxBeforeCreateStatus === REQUEST_STATUS.SUCCESSFUL) {
      let values = getValues();
      values = valueTo(values, "", undefined);
      createPremise({
        ...values,
        premisesCompanyId: companyID,
        premisesAddressId: rdxBeforePremise.createAddress.id,
        premisesCustodianId: rdxBeforePremise.createCustodian.id,
      });
      createBeforePremiseClear();
    } else if (rdxBeforeCreateStatus === REQUEST_STATUS.FAILED) {
      createBeforePremiseClear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rdxBeforeCreateStatus]);

  // Monitoreo de cambios ocurridos por la segunda parte de la operación
  // de creación (creación de premise).
  useEffect(() => {
    if (rdxCreateStatus === REQUEST_STATUS.SUCCESSFUL || rdxUpdateStatus === REQUEST_STATUS.SUCCESSFUL) {
      showMessage(dialogText.successMsg);
      closeConfirmDialog();
      props.onClose();
    }

    return () => {
      createPremiseClear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rdxCreateStatus, rdxUpdateStatus]);

  async function updateOrCreatePremise() {
    let values = getValues();
    values = valueTo(values, "", null);
    if (formMode === FORM_MODES.CREATE) {
      // Crear Address, Custodian y Company
      createBeforePremise(values);
    } else if (formMode === FORM_MODES.UPDATE) {
      const { id, company, address, custodian, _version } = props.premise;
      const ids = {
        premisesId: id,
        companyId: companyID,
        addressId: address.id,
        custodianId: custodian.id,
      };
      const versions = {
        premiseVersion: _version,
        companyVersion: company._version,
        addressVersion: address._version,
        custodianVersion: custodian._version,
      };
      patchPremise({ ...values, ...ids, ...versions });
    }
  }

  async function validateForm() {
    if (!companyID) {
      setError("companyID", { type: "required", message: "Este campo es obligatorio" });
    }
    const isValid = await trigger();
    if (!isValid || !companyID) {
      return;
    }
    openConfirmDialog();
  }

  function onChangeCompany(companyID) {
    setValue("companyID", companyID);
    clearErrors("companyID");
  }

  return (
    <>
      <Dialog open fullScreen={fullScreen} fullWidth scroll="paper" maxWidth="md">
        <DialogTitle>{dialogText.title}</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Controller
                control={control}
                as={TextField}
                label="Nombre"
                name="premiseName"
                type="text"
                fullWidth
                required
                rules={NAME_FORM_RULES}
                error={errors.premiseName ? true : false}
                helperText={errors.premiseName?.message}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Controller
                control={control}
                as={TextField}
                label="Código"
                name="premiseCode"
                type="text"
                fullWidth
                required
                rules={CODE_FORM_RULES}
                error={errors.premiseCode ? true : false}
                helperText={errors.premiseCode?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomerProjectSelector
                customerId={companyID}
                onChangeCompany={onChangeCompany}
                hideProjectSelect
                hideTitle
                companyTextFieldProps={{
                  required: true,
                  error: !!errors.companyID,
                  helperText: errors.companyID?.message,
                }}
              />
            </Grid>
          </Grid>
          <Box mt={2} mb={1}>
            <Typography variant="subtitle2">Dirección</Typography>
            <Divider />
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Controller
                control={control}
                as={TextField}
                label="Calle"
                name="street"
                type="text"
                fullWidth
                required
                rules={{
                  required: "Campo obligatorio",
                  maxLength: { value: 100, message: "Máximo 100 caracteres" },
                }}
                error={errors.street ? true : false}
                helperText={errors.street?.message}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Controller
                control={control}
                as={TextField}
                label="No. Exterior"
                name="number"
                type="number"
                fullWidth
                required
                rules={EXTERIOR_NUMBER_RULE}
                error={errors.number ? true : false}
                helperText={errors.number?.message}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                control={control}
                as={TextField}
                label="Colonia"
                name="settlement"
                type="text"
                fullWidth
                required
                rules={NAME_FORM_RULES}
                error={errors.settlement ? true : false}
                helperText={errors.settlement?.message}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Controller
                control={control}
                as={TextField}
                label="Código Postal"
                name="zipcode"
                type="number"
                fullWidth
                required
                rules={{
                  required: "Campo obligatorio",
                  maxLength: { value: 5, message: "Máximo 5 caracteres" },
                  minLength: { value: 5, message: "Mínimo 5 caracteres" },
                }}
                error={errors.zipcode ? true : false}
                helperText={errors.zipcode?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                as={TextField}
                label="Delegación"
                name="locality"
                type="text"
                fullWidth
                required
                rules={NAME_FORM_RULES}
                error={errors.locality ? true : false}
                helperText={errors.locality?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                as={TextField}
                label="Ciudad"
                name="city"
                type="text"
                fullWidth
                required
                rules={NAME_FORM_RULES}
                error={errors.city ? true : false}
                helperText={errors.city?.message}
              />
            </Grid>
          </Grid>
          <Box mt={2} mb={1}>
            <Typography variant="subtitle2">Responsable</Typography>
            <Divider />
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                control={control}
                as={TextField}
                label="Nombre"
                name="custodianName"
                type="text"
                fullWidth
                required
                rules={NAME_FORM_RULES}
                error={errors.custodianName ? true : false}
                helperText={errors.custodianName?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                control={control}
                as={TextField}
                label="Segundo Nombre"
                name="middlename"
                type="text"
                fullWidth
                rules={{
                  maxLength: { value: 50, message: "Máximo 50 caracteres" },
                }}
                error={errors.middlename ? true : false}
                helperText={errors.middlename?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                control={control}
                as={TextField}
                label="Apellido Paterno"
                name="lastnameP"
                type="text"
                fullWidth
                required
                rules={NAME_FORM_RULES}
                error={errors.lastnameP || false}
                helperText={errors.lastnameP?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                control={control}
                as={TextField}
                label="Apellido Materno"
                name="lastnameM"
                type="text"
                fullWidth
                rules={{
                  maxLength: { value: 50, message: "Máximo 50 caracteres" },
                }}
                error={errors.lastnameM ? true : false}
                helperText={errors.lastnameM?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                control={control}
                as={TextField}
                label="Correo electrónico"
                name="email"
                type="email"
                fullWidth
                rules={{
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Formato de email inválido",
                  },
                }}
                error={errors.email ? true : false}
                helperText={errors.email?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                control={control}
                as={TextField}
                label="Número Telefónico"
                name="phone"
                type="tel"
                fullWidth
                rules={{
                  pattern: {
                    value: /^[0-9]{7,10}$/i,
                    message: "Formato de teléfonico inválido",
                  },
                  minLength: { value: 7, message: "Mínimo 7 dígitos" },
                  maxLength: { value: 10, message: "Máximo 10 dígitos" },
                }}
                error={errors.phone ? true : false}
                helperText={errors.phone?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                control={control}
                as={TextField}
                label="Extensión"
                name="extension"
                type="number"
                fullWidth
                rules={{
                  pattern: {
                    value: /^[0-9]{3,3}$/i,
                    message: "Formato de extensión inválido",
                  },
                  minLength: { value: 3, message: "Mínimo 3 dígitos" },
                  maxLength: { value: 3, message: "Máximo 3 dígitos" },
                }}
                error={errors.extension ? true : false}
                helperText={errors.extension?.message}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="default" onClick={props.onClose}>
            CANCELAR
          </Button>
          <Button onClick={validateForm} color="primary" type="submit">
            {dialogText.confirmBtn}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        open={confirmDialogOpen}
        title="Confirmación"
        onConfirm={updateOrCreatePremise}
        onCancel={closeConfirmDialog}
        loading={
          rdxBeforeCreateStatus === REQUEST_STATUS.PENDING ||
          rdxCreateStatus === REQUEST_STATUS.PENDING ||
          rdxUpdateStatus === REQUEST_STATUS.PENDING
        }
      >
        <Typography paragraph>{dialogText.confirmationMsg}</Typography>
        <Typography>¿Desea continuar?.</Typography>
      </ConfirmationDialog>
    </>
  );
}

PremiseDialog.propTypes = {
  // Valores del formulario para determinar si se crea o modifica un sitio
  premise: PropTypes.object,
  // Maneja el evento onClick del botón "Cancelar"
  onClose: PropTypes.func,
};

PremiseDialog.defaultProps = {
  premise: null,
  onClose: (event) => {},
};

export default PremiseDialog;
