import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
  },
  iconButton: {
    padding: "6px",
  },
  icon: {
    fontSize: "2.5rem",
  },
}));

export default useStyles;
