import { useRef } from "react";
import API, { graphqlOperation } from "@aws-amplify/api";
import { Logger } from "@aws-amplify/core";
import useOnlineStatus from "@rehooks/online-status";
import useNotifier from "hooks/useNotifier";
import * as queries from "graphql-custom/queries";
import * as ROLES from "constant/roles";
import { TaskCategory } from "models";
import useGraphQL from "hooks/useGraphQL";
import useFetchTasks from "hooks/useFetchTasks";

const logger = new Logger("MaintenanceListView:useAPIHelpers");

/**
 * * useAPIHelpers -> Helper to validate and consult the maintenance list, online and offline
 * loadMaintenanceList -> initial validation call  1.0
 * fetchMaintenanceList Online -> use GraphQL 2.0
 * @Docs https://docs.amplify.aws/lib/q/platform/js/
 */
export default function useAPIHelpers() {
  const isOnline = useOnlineStatus();
  const { runGraphQLOperation } = useGraphQL();
  const { fetchTaskListWithDS } = useFetchTasks();
  const { showError } = useNotifier();
  const firstRenderRef = useRef(true);

  /**
   * @Func fetchMaintenanceList 2.0
   * @param {*} items
   * @param {*} nextToken
   * @param {*} limit
   * @param {*} userRole
   * @param {*} userId
   * @param {*} status
   * @param {*} updatedLimit
   * @param {*} isReloading
   * @returns @list Obj
   */
  async function fetchMaintenanceList({
    items = [],
    nextToken,
    limit,
    userRole,
    userId,
    status,
    updatedLimit,
    isReloading,
  }) {
    // initial validation of recurrence
    if (
      (items.length && !nextToken) ||
      (!items.length && !nextToken && !firstRenderRef.current && !updatedLimit && !isReloading) ||
      items.length > limit
    ) {
      return { items, nextToken };
    }

    firstRenderRef.current = false;
    // Query to the maintenance list db with GraphQL
    const query = {
      operation: queries.listTasksByCategory,
      variables: {
        category: TaskCategory.MAINTENANCE,
        limit,
        nextToken,
        filter: {
          status: { eq: status },
        }
      },
    }

    if (status === 'GENERAL') {
      delete query.variables.filter;
    }

    const { tasksByCategory } = await runGraphQLOperation(query);

    //role validation
    if (userRole === ROLES.SUPERVISORS) {
      items = [...items, ...tasksByCategory.items];
    } else if (userRole === ROLES.SUPPORT_ENGINEERS) {
      const newTasks = tasksByCategory.items.filter((maintenance) => {
        const userIndex = maintenance.users.items.findIndex(({ user }) => user.id === userId);
        return userIndex >= 0;
      });
      items = [...items, ...newTasks];
    } else {
      throw new Error("La consulta de mantenimientos solo se permite a Coordinadores y Técnicos.");
    }

    if (limit > items.length) {
      const maintenanceData = await fetchMaintenanceList({
        items,
        nextToken: tasksByCategory.nextToken,
        limit,
        userRole,
        userId,
        status,
      });
      return {
        items: maintenanceData.items,
        nextToken: maintenanceData.nextToken,
      };
    } else {
      return {
        items,
        nextToken: tasksByCategory.nextToken,
      };
    }
  }

  /**
   * @Func fetchMaintenanceDocument
   * @param {*} taskId
   * @returns
   */
  function fetchMaintenanceDocument(taskId) {
    return API.graphql(
      graphqlOperation(queries.lambdaDownloadTaskDocument, { input: { documentType: "MAINTENANCE_REPORT", taskId } })
    );
  }

  /**
   * @Func loadMaintenanceList 1.0
   * @param {*} userRole
   * @param {*} userId
   * @param {*} status
   * @param {*} Obj {page, limit, token, updatedLimit, isReloading}
   * @returns  @list Obj,  @token String,  @page Number
   */
  async function loadMaintenanceList(
    userRole,
    userId,
    status,
    { page = 0, limit = 100, token = null, updatedLimit = false, isReloading = false } = {}
  ) {
    let maintenanceItems = [];
    let newToken = null;
    let currentPage = page;

    try {
      if (isOnline) {
        const { items, nextToken } = await fetchMaintenanceList({
          nextToken: token,
          limit,
          userRole,
          userId,
          status,
          updatedLimit,
          isReloading,
        });
        maintenanceItems = items;
        newToken = nextToken;
      } else {
        const { items, page: cPage } = await fetchTaskListWithDS({
          category: TaskCategory.MAINTENANCE,
          page,
          limit,
          userRole,
          userId,
          status,
        });
        maintenanceItems = items;
        currentPage = cPage;
      }

      return { list: maintenanceItems, token: newToken, page: currentPage };
    } catch (error) {
      logger.error(error);
      showError("Ocurrió un error al consultar el listado de mantenimientos.");
      return { list: [], token: null };
    }
  }

  return {
    fetchMaintenanceList,
    fetchMaintenanceDocument,
    loadMaintenanceList,
  };
}
