import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Logger } from "@aws-amplify/core";
import { Auth } from "@aws-amplify/auth";
import { API, graphqlOperation } from "@aws-amplify/api";

import makeStyles from "@material-ui/styles/makeStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import WordConfirmationDialog, { KeyWord } from "components/common/WordConfirmationDialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import Alert from "@material-ui/lab/Alert";
import CancelIcon from "@material-ui/icons/Cancel";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Popover from "@material-ui/core/Popover";
import ButtonBase from "@material-ui/core/ButtonBase";
import IconButton from "@material-ui/core/IconButton";
import MoreIcon from "@material-ui/icons/MoreVert";
import DataLabel from "components/DataLabel";
import Section from "components/Section";
import AttachmentsDialog from "components/common/dialog/AttachmentsDialog";
import useHeaderTitle from "hooks/useHeaderTitle";
import cleanUUID from "util/cleanUUID";
import { fetchTicketById, getSupportByTicketId, cancelTicket } from "datastore";
import * as mutations from "graphql-custom/mutations";
import * as ROLES from "constant/roles";
import { ASSET_MODE } from "constant/ticketConstants";
import { TicketStatus, TaskStatus, TimerEventType } from "models";
import SignatureDialog from "components/common/SignatureDialog";
import GroupRestrictedView from "components/common/GroupRestrictedView";
import TicketDetailsCard from "components/general/TicketDetailsCard";
import ReviewTaskDialog from "components/common/ReviewTaskDialog";
import TicketAssetCard from "components/general/TicketAssetCard";
import TicketReviewCard from "components/general/TicketReviewCard";
import SupportScheduleDialog from "../SupportCreateDialog";
import useLoadingStatus from "hooks/useLoadingStatus";
import useNotifier from "hooks/useNotifier";
import SupportTicketDialog from "components/SupportTicketDialog";
import MaterialTable from "components/custom/MaterialTable";
import { formatAssetsCode, formatAssetsSeries } from "util/text";
import RemarkDialog from "components/common/RemarkDialog";
import PDFReportButton from "components/general/Buttons/PDFReportButton";
import TimerAlert from "components/Timer/TimerAlert";
import ticketUtil from "util/ticket";
import { divideAssetsAndMaterials, getAssetGarrantyByAsset, getAssetClientGarrantyByAsset } from "util/assets";

const logger = new Logger("TicketDetails");
const SECTION_TITLE = "Ticket";

const REMARK_TYPES = {
  issue: "Problemática",
  analysis: "Análisis",
  solution: "Solución",
};

function handleRemarkRender(remark) {
  const hasSimbol = remark?.includes("<***>");

  const splitType = remark?.split("<***>")[0];
  const splitValue = remark?.split("<***>")[1];

  const remarkType = REMARK_TYPES[splitType] || "Nota Adicional";
  const remarkValue = hasSimbol ? splitValue : remark;

  return {
    remarkType,
    remarkValue,
  };
}

export default function TicketDetailsView({ type }) {
  const { id } = useParams();
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const { showMessage, showError } = useNotifier();

  useHeaderTitle(`${SECTION_TITLE} ${cleanUUID(id)}`);

  const [isFetching, fetchTicketByIdWrapped] = useLoadingStatus(fetchTicketById);
  const [isFetchingSupportTask, _getSupportByTicketId] = useLoadingStatus(getSupportByTicketId);
  const [isCancelling, _cancelTicket] = useLoadingStatus(cancelTicket);
  const supportSettings = useSelector(({ systemConfigs }) => systemConfigs.support);
  const [isCreatingLog, _createTicketLog] = useLoadingStatus(createTicketLog);
  const [isRequestError, setIsRequestError] = useState(false);
  const [ticketDetails, setTicketDetails] = useState({});
  const [externalData, setExternalData] = useState(null);
  const [ticketSupportDetails, setTicketSupportDetails] = useState({});
  const [currentAttachments, setCurrentAttachments] = useState([]);

  const [isEvidenceDialogOpen, setIsEvidenceDialogOpen] = useState(false);
  const [showSignatureDialog, setShowSignatureDialog] = useState(false);
  const [isScheduleSupportDialogOpen, setIsScheduleSupportDialogOpen] = useState(false);
  const [isReviewDialogOpen, setIsReviewDialogOpen] = useState(false);
  const [openUpdateTicketDialog, setOpenUpdateTicketDialog] = useState(false);
  const [showCancellationDialog, setShowCancellationDialog] = useState(false);
  const [showCancellationRemarkDialog, setShowCancellationRemarkDialog] = useState(false);
  const [cancellationRemark, setCancellationRemark] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);

  const lastTimerEvent = ticketSupportDetails?.embeddedLastEvent;
  const showTimerAlert = lastTimerEvent?.embeddedCause?.type === TimerEventType.PAUSE && type === ROLES.SUPERVISORS;
  const reportedAsset = ticketDetails?.reportedAsset || null;
  const isLoading = isFetching || isFetchingSupportTask || isCancelling || isCreatingLog;
  const SignatureS3PrefixKey = `ticket/${id}`;

  const { taskAssets, taskMaterials } = useMemo(() => {
    if (!ticketSupportDetails || !ticketSupportDetails?.taskAssets) {
      return { taskAssets: [], taskMaterials: [] };
    }
    const parsedTaskAssets = ticketSupportDetails.taskAssets.map((taskAsset) => ({
      ...taskAsset.taskAssets,
    }));
    const { assets, materials } = divideAssetsAndMaterials(parsedTaskAssets);
    return { taskAssets: assets, taskMaterials: materials };
  }, [ticketSupportDetails]);

  useEffect(() => {
    fetchTicket();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchTicket() {
    try {
      const _ticket = await fetchTicketByIdWrapped(id);
      if (!_ticket || !Object.keys(_ticket).length) {
        throw new Error("Error obteniendo la información del ticket");
      }

      if (_ticket.status !== TicketStatus.OPEN) {
        _getSupportByTicketId(_ticket.id).then((result) => {
          logger.debug(result);
          setTicketSupportDetails(result);
        });
      }
      const externalData = ticketUtil.parseExternalData(_ticket.externalData);
      setTicketDetails(_ticket);
      setExternalData(externalData);
    } catch (error) {
      logger.error(error, { id });
      setIsRequestError(true);
    }
  }

  function onScheduleSupport() {
    fetchTicket();
    setIsScheduleSupportDialogOpen(false);
  }

  async function cancelCurrentTicket() {
    try {
      const ticket = await _cancelTicket(id, cancellationRemark);
      const {
        attributes: { sub: userId },
      } = await Auth.currentAuthenticatedUser();
      const logVariables = {
        dataType: "TICKET",
        data: JSON.stringify(ticketDetails),
        userID: userId,
      };
      await _createTicketLog(logVariables);
      setShowCancellationRemarkDialog(false);
      setShowCancellationDialog(false);
      setTicketDetails({ ...ticketDetails, status: ticket.status });
      setCancellationRemark("");
      showMessage("Ticket ha sido cancelado de forma exitosa.");
    } catch (error) {
      logger.error(error);
      showError("Ocurrió un error durante la cancelación del Ticket, intente nuevamente.");
    }
  }

  function handleCurrentAttachments(attachments, isWithSections = false) {
    setCurrentAttachments(attachments);
    setIsEvidenceDialogOpen(true);
  }

  function onCloseTicketReviewedDialog() {
    setIsReviewDialogOpen(false);
  }

  function onReviewTicket() {
    fetchTicket();
    setIsReviewDialogOpen(false);
  }

  function handleUpdateTicket() {
    fetchTicket();
    setOpenUpdateTicketDialog(false);
  }

  function getTicketActions() {
    let actions = [];
    const isOpen = ticketDetails.status === TicketStatus.OPEN;
    if (isOpen && (type === ROLES.SUPERVISORS || type === ROLES.SERVICE_USERS)) {
      actions = [{ text: "Editar", onClick: () => setOpenUpdateTicketDialog(true) }];

      // Condicionado de opción de cancelación dependiendo de la configuración del sistema
      if (supportSettings.ticket_cancellation.value) {
        actions.push({ text: "Cancelar", onClick: () => setShowCancellationRemarkDialog(true) });
      }
    }
    return actions;
  }

  function onSignedTicket(updatedData) {
    const { taskData, ticketData } = updatedData;
    setTicketDetails((currentData) => ({ ...currentData, ...ticketData }));
    setTicketSupportDetails((currentData) => ({ ...currentData, ...taskData }));
    setShowSignatureDialog(false);
  }

  function onAddInspectionNoteSuccess(updatedData) {
    const { taskData, ticketData } = updatedData;
    setTicketDetails((currentData) => ({ ...currentData, ...ticketData }));
    setTicketSupportDetails((currentData) => ({ ...currentData, ...taskData }));
    setShowSignatureDialog(false);
  }

  function onCloseTicketSignatureDialog() {
    setShowSignatureDialog(false);
  }

  const onCancellationRemarkAdded = (remark) => {
    setCancellationRemark(remark);
    setShowCancellationDialog(true);
  };

  function onGenerateOrDownloadAssetQR(trackingID) {
    const _ticketDetails = { ...ticketDetails };
    _ticketDetails.asset.trackingID = trackingID;
    setTicketDetails(_ticketDetails);
  }

  return (
    <>
      {ticketDetails.status === TicketStatus.CANCELLED && (
        <Box mb={2}>
          <Alert icon={<CancelIcon fontSize="inherit" />} variant="filled" severity="info">
            Este ticket está en estatus cancelado con el siguiente comentario de cancelación:
            <div>{ticketDetails?.remarks?.find((r) => r.id === "cancellation-remark")?.remark}</div>
          </Alert>
        </Box>
      )}
      {showTimerAlert && (
        <TimerAlert userType={type}>{lastTimerEvent?.description || "La actividad esta en pausa"}</TimerAlert>
      )}
      <TicketDetailsCard
        ticketDetails={ticketDetails || {}}
        externalData={externalData}
        taskInfo={ticketSupportDetails}
        isFetching={isLoading}
        to={type === ROLES.SUPERVISORS ? `/validacion/tickets` : `/soporte/mis-tickets`}
        type={type}
        isRequestError={isRequestError}
        setIsScheduleSupportDialogOpen={setIsScheduleSupportDialogOpen}
        setIsReviewDialogOpen={setIsReviewDialogOpen}
        actions={getTicketActions()}
        setShowSignatureDialog={setShowSignatureDialog}
      />
      {Boolean(reportedAsset) && Boolean(Object.keys(reportedAsset).length) && (
        <TicketAssetCard
          userType={type}
          asset={reportedAsset}
          isFetching={isLoading}
          mode={ASSET_MODE.REPORTED_ASSET}
        />
      )}
      {Boolean(taskAssets) &&
        Boolean(taskAssets.length) &&
        taskAssets.map(({ id, asset }) => (
          <TicketAssetCard
            key={id}
            asset={asset}
            isFetching={isLoading}
            assetWarranty={getAssetGarrantyByAsset(asset)}
            assetClientWarranty={getAssetClientGarrantyByAsset(asset)}
            onGenerateOrDownloadAssetQR={onGenerateOrDownloadAssetQR}
            userType={type}
            mode={ASSET_MODE.TASK_ASSET}
          />
        ))}

      {ticketDetails.status !== TicketStatus.OPEN &&
        ticketDetails.status !== TicketStatus.SCHEDULED &&
        type === ROLES.SUPERVISORS &&
        Boolean(taskMaterials) &&
        Boolean(taskMaterials.length) && (
          <GroupRestrictedView group={[ROLES.SUPERVISORS, ROLES.SUPPORT_ENGINEERS]}>
            <Section>
              <Grid container className={classes.detailsGrid}>
                <Grid item xs={12}>
                  <Box>
                    <Typography variant="h6">Materiales</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <MaterialTable
                    columns={matches ? TABLE_COLUMNS_MOBILE : TABLE_COLUMNS}
                    data={taskAssets}
                    options={TABLE_OPTIONS}
                  />
                </Grid>
              </Grid>
            </Section>
          </GroupRestrictedView>
        )}

      {ticketDetails.status !== TicketStatus.OPEN && ticketDetails.status !== TicketStatus.CANCELLED && (
        <Section>
          <Grid container className={classes.detailsGrid} spacing={2}>
            <Grid item container xs={12}>
              <Grid item xs>
                <Box>
                  <Typography variant="h6">Soporte asignado</Typography>
                </Box>
              </Grid>
              {type === ROLES.SUPERVISORS && ticketSupportDetails?.status === TaskStatus.SCHEDULED && (
                <GroupRestrictedView group={["support-engineers", "supervisors"]}>
                  <Grid item>
                    <IconButton aria-label="more" onClick={(e) => setAnchorEl(e.currentTarget)}>
                      <MoreIcon />
                    </IconButton>
                    <Popover
                      open={!!anchorEl}
                      anchorEl={anchorEl}
                      onClose={() => setAnchorEl(null)}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <ButtonBase
                        className={classes.detailsContent}
                        onClick={() => {
                          setIsScheduleSupportDialogOpen(true);
                          setAnchorEl(null);
                        }}
                      >
                        Editar
                      </ButtonBase>
                    </Popover>
                  </Grid>
                </GroupRestrictedView>
              )}
            </Grid>

            {Boolean(ticketSupportDetails) && Boolean(Object.keys(ticketSupportDetails).length) && (
              <>
                <Grid item xs={12} md={4}>
                  <Box>
                    <DataLabel
                      label="Fecha de inicio"
                      data={`${ticketSupportDetails.startDate} ${ticketSupportDetails.startTime}`}
                      loading={isLoading}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box>
                    <DataLabel
                      label="Fecha de finalización estimada"
                      data={`${ticketSupportDetails.endDate} ${ticketSupportDetails.endTime}`}
                      loading={isLoading}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6} md={4}>
                  <Box>
                    <DataLabel
                      label="Lugar de atención"
                      data={ticketSupportDetails.premise?.name}
                      loading={isLoading}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6} md={4}>
                  <Box>
                    <DataLabel
                      label="Zona de atención"
                      data={`${ticketSupportDetails.buildingLocation?.code || ""} ${ticketSupportDetails.buildingLocation?.name || ""
                        } ${ticketSupportDetails.buildingLocation?.buildingBlock || ""} ${ticketSupportDetails.buildingLocation?.floor
                          ? `Piso: ${ticketSupportDetails.buildingLocation?.floor}`
                          : ""
                        } ${ticketSupportDetails.buildingLocation?.area
                          ? `Área: ${ticketSupportDetails.buildingLocation?.area}`
                          : ""
                        }`}
                      loading={isLoading}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6} md={4}>
                  <Box>
                    <DataLabel
                      label="Técnico"
                      data={`${ticketSupportDetails.userTask?.user?.name || ""} ${ticketSupportDetails.userTask?.user?.middlename || ""
                        } ${ticketSupportDetails.userTask?.user?.lastnameP || ""} ${ticketSupportDetails.userTask?.user?.lastnameM || ""
                        } (${ticketSupportDetails.userTask?.user?.email || "S/I"}, ${ticketSupportDetails.userTask?.user?.phone || "S/I"
                        })`}
                      loading={isLoading}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6} md={4}>
                  <Box>
                    <DataLabel
                      label="Tipo de soporte"
                      data={ticketSupportDetails.taskType?.name || "Sin asignación"}
                      loading={isLoading}
                    />
                  </Box>
                </Grid>

                {type === ROLES.SUPERVISORS && (
                  <GroupRestrictedView group={[ROLES.SUPERVISORS, ROLES.SUPPORT_ENGINEERS]}>
                    <Grid item xs={12}>
                      <Box>
                        <Typography variant="subtitle2" component="span" color="textSecondary">
                          <b>Comentarios</b>
                        </Typography>
                        <div>
                          {ticketSupportDetails.remarks?.length ? (
                            ticketSupportDetails.remarks?.map((remark) => {
                              const { remarkType, remarkValue } = handleRemarkRender(remark?.remark);
                              if (!remarkType || !remarkValue) return null;

                              return (
                                <div key={remark?.id}>
                                  <p>
                                    <strong>{remarkType}</strong>
                                  </p>
                                  <p>{remarkValue}</p>
                                </div>
                              );
                            })
                          ) : (
                            <Typography variant="body2">Sin información</Typography>
                          )}
                        </div>
                      </Box>
                    </Grid>
                  </GroupRestrictedView>
                )}
              </>
            )}
            <Grid item xs={12}>
              <Box textAlign="right">
                {ticketSupportDetails.attachments && (
                  <Box component="div" display={matches ? "block" : "inline"} mr={matches ? 0 : 2} mb={matches ? 2 : 0}>
                    <Button
                      variant="outlined"
                      color="primary"
                      fullWidth={matches}
                      onClick={() => handleCurrentAttachments(ticketSupportDetails.attachments, true)}
                    >
                      VER EVIDENCIAS
                    </Button>
                  </Box>
                )}
                {(ticketSupportDetails?.status === TaskStatus.COMPLETED ||
                  ticketSupportDetails?.status === TaskStatus.REVIEWED ||
                  ticketSupportDetails?.status === TaskStatus.FINALIZED) && (
                    <Box textAlign="right" component="div" display="inline">
                      <PDFReportButton
                        variant="outlined"
                        buttonType={"button"}
                        by={"task"}
                        elementId={ticketSupportDetails.id}
                        elementStatus={ticketSupportDetails.status}
                        reportType={"support"}
                      />
                    </Box>
                  )}
              </Box>
            </Grid>
          </Grid>
        </Section>
      )}

      {ticketDetails.status === TicketStatus.RATED && (
        <TicketReviewCard reviewRating={ticketDetails.rating} reviewComment={ticketDetails.ratingComment} />
      )}

      <SupportTicketDialog
        isOpen={openUpdateTicketDialog}
        ticket={ticketDetails}
        onUpdate={handleUpdateTicket}
        onClose={() => setOpenUpdateTicketDialog(false)}
      />

      <AttachmentsDialog
        open={isEvidenceDialogOpen}
        attachments={currentAttachments}
        withSections
        fullWidth
        onClose={() => setIsEvidenceDialogOpen(false)}
      />

      <SupportScheduleDialog
        open={isScheduleSupportDialogOpen}
        onClose={() => setIsScheduleSupportDialogOpen(false)}
        matches={matches}
        onScheduleSupport={onScheduleSupport}
        onUpdateSupport={fetchTicket}
        ticketDetails={ticketDetails}
        supportDetails={ticketSupportDetails}
      />

      <ReviewTaskDialog
        open={isReviewDialogOpen}
        isMobile={matches}
        ticketId={ticketDetails.id}
        onCancel={() => onCloseTicketReviewedDialog()}
        onReview={() => onReviewTicket()}
      />

      {Boolean(ticketSupportDetails?.id) && (
        <SignatureDialog
          isOpen={showSignatureDialog}
          onClose={() => onCloseTicketSignatureDialog()}
          ticketId={ticketDetails.id}
          onSigned={onSignedTicket}
          onAddInspectionNote={onAddInspectionNoteSuccess}
          taskId={ticketSupportDetails?.id}
          S3PrefixKey={SignatureS3PrefixKey}
          taskStatusToUpdate={TaskStatus.FINALIZED}
        />
      )}
      <RemarkDialog
        open={showCancellationRemarkDialog}
        okBtnText="Continuar"
        confirmationWord="cancelar"
        title="Razón de la cancelación"
        onConfirm={onCancellationRemarkAdded}
        onCancel={() => setShowCancellationRemarkDialog(false)}
        loading={isLoading}
      >
        <DialogContentText>¿Cuál es el motivo de la cancelación del ticket?</DialogContentText>
      </RemarkDialog>
      <WordConfirmationDialog
        open={showCancellationDialog}
        okBtnText="Confirmar"
        confirmationWord="cancelar"
        title="ATENCIÓN"
        onConfirm={cancelCurrentTicket}
        onCancel={() => setShowCancellationDialog(false)}
        loading={isLoading}
      >
        <DialogContentText>Está a punto de cancelar un ticket. Esta es una acción irreversible.</DialogContentText>
        <DialogContentText>
          Si desea continuar, ingrese la palabra{" "}
          <KeyWord bold italic>
            {"cancelar"}
          </KeyWord>{" "}
          en el campo de texto debajo y de click en el botón &quot;CONFIRMAR&quot;.
        </DialogContentText>
      </WordConfirmationDialog>
    </>
  );
}

TicketDetailsView.propTypes = {
  type: PropTypes.string,
};

const useStyles = makeStyles((theme) => ({
  detailsGrid: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  detailsContent: {
    padding: theme.spacing(2),
  },
  username: {
    "& > *": {
      marginRight: theme.spacing(2),
    },
  },
  roleControl: {
    minWidth: 200,
    marginTop: theme.spacing(2),
  },
  root: {
    [theme.breakpoints.down("sm")]: {
      "& .MuiTableHead-root": {
        display: "none",
      },
      "& .MuiToolbar-root": {
        flexDirection: "column",
        padding: theme.spacing(2),
      },
      "& div[class*='MTableToolbar-actions-']": {
        order: 2,
        textAlign: "right",
        width: "100%",
      },
      "& div[class*='MTableToolbar-title-']": {
        order: 1,
        textAlign: "left",
        width: "100%",
      },
      "& div[class*='MTableToolbar-searchField-']": {
        width: "100%",
        order: 0,
        marginBottom: theme.spacing(2),
      },
    },
  },
  codeReaderPanel: {
    backgroundColor: "white",
    overflow: "clip",
    display: "relative",
  },
}));

function createTicketLog(logVariables) {
  return API.graphql(graphqlOperation(mutations.createLog, logVariables));
}
/* eslint-disable react/display-name */
const TABLE_COLUMNS = [
  { title: "ID", field: "id", hidden: true },
  {
    title: "Serie",
    field: "serial",
    render: (rowData) => <p>{formatAssetsSeries(rowData?.asset?.asset?.serial)}</p>,
  },
  {
    title: "Código",
    field: "code",
    render: (rowData) => <p>{formatAssetsCode(rowData?.asset?.asset?.code)}</p>,
  },
  {
    title: "Nombre",
    field: "name",
    render: (rowData) => <p>{rowData?.asset?.asset?.material?.name || "S/I"}</p>,
  },
  {
    title: "Cantidad",
    field: "asset.asset.quantity",
  },
];

const TABLE_COLUMNS_MOBILE = [
  {
    field: "asset.asset.serial",
    render: ({
      // eslint-disable-next-line react/prop-types
      asset: {
        // eslint-disable-next-line react/prop-types
        asset: { name, code, material, serial, quantity },
      },
    }) => (
      <Box display="flex">
        <Box>
          <Typography paragraph>
            <strong>Código de Material: </strong>
            <span>{formatAssetsCode(code)}</span>
          </Typography>
          <Typography paragraph>
            <strong>Nombre: </strong>
            {/*eslint-disable-next-line react/prop-types */}
            <span>{name || material?.name}</span>
          </Typography>
          <Typography paragraph>
            <strong>Serie: </strong>
            <span>{formatAssetsSeries(serial)}</span>
          </Typography>
          <Typography paragraph>
            <strong>Cantidad: </strong>
            <span>{quantity}</span>
          </Typography>
        </Box>
      </Box>
    ),
  },
];

const TABLE_OPTIONS = {
  exportButton: false,
  columnsButton: false,
};
