import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import MaterialTable from "components/custom/MaterialTable";

import Section from "components/Section";
import TableCellMobileFormat from "components/custom/TableCellMobileFormat";

import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import maintenanceStyles from "../../MaintenanceStyles";

import useTableMobileStyles from "hooks/useTableMobileStyles";
import cleanUUID from "util/cleanUUID";

import dayjs from "dayjs";
import useOnlineStatus from "@rehooks/online-status";
import exportCsv from "util/exportCsv";

import { FormatUserName } from "util/FormatDetails";

export default function MaintenanceDetailObservations(props) {
  const { maintenanceState, loading } = props;

  const { observations } = maintenanceState;
  const { id: taskId } = useParams();
  const cleanedID = cleanUUID(taskId);

  const isOnline = useOnlineStatus();
  const classes = maintenanceStyles();
  const tableMobileStyles = useTableMobileStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const matches1024 = useMediaQuery(theme.breakpoints.down(1024));

  return (
    <Box mb={2} className={classes.sectionSpacing}>
      <Section>
        <div id="detail-view-observations-table" className={tableMobileStyles.root}>
          <MaterialTable
            title="Observaciones"
            columns={matches ? COLUMNS_OBSERVATIONS_MOBILE : COLUMNS_OBSERVATIONS}
            data={observations}
            isLoading={loading}
            options={{
              columnsButton: matches1024 ? false : true,
              exportButton: Boolean(observations?.length) && isOnline,
              exportCsv: (columnList, initialData) =>
                exportCsv(
                  COLUMNS_OBSERVATIONS,
                  initialData,
                  `Tabla_observaciones-${cleanedID}-${dayjs().format("YYYY-MM-DD-HH:mm")}`
                ),
            }}
          />
        </div>
      </Section>
    </Box>
  );
}

const COLUMNS_OBSERVATIONS_MOBILE = [
  {
    title: "",
    field: "asset.state",
    render: (rowData) => {
      const columns = [
        { title: "MENSAJE", description: rowData.comments },
        {
          title: "FECHA",
          description: rowData.createdAt ? dayjs(rowData.createdAt).format("DD/MM/YYYY HH:mm") : "Sin información",
        },
        {
          title: "USUARIO",
          description: rowData.supervisor ? FormatUserName(rowData.supervisor) : "Sin información",
        },
      ];
      return <TableCellMobileFormat columns={columns} xs={6} />;
    },

    customFilterAndSearch: (value, rowData) => {
      const parsedValue = value?.toLowerCase();
      const fullName = FormatUserName(rowData.supervisor);

      return (
        rowData?.comments?.toLowerCase().includes(parsedValue) ||
        dayjs(rowData?.createdAt)?.format("DD/MM/YYYY HH:mm")?.toLowerCase().includes(value.toLowerCase()) ||
        fullName?.toLowerCase().includes(parsedValue)
      );
    },
  },
];

const COLUMNS_OBSERVATIONS = [
  { title: "MENSAJE", field: "comments" },
  {
    title: "FECHA",
    field: "createdAt",
    defaultSort: "desc",
    render: (rowData) => (rowData.createdAt ? dayjs(rowData.createdAt).format("DD/MM/YYYY HH:mm") : "Sin información"),
  },
  {
    title: "USUARIO",
    field: "supervisor",
    render: (rowData) => (rowData.supervisor ? FormatUserName(rowData.supervisor) : "Sin información"),
    defaultSort: "desc",
  },
];

MaintenanceDetailObservations.propTypes = {
  maintenanceState: PropTypes.object,
  loading: PropTypes.bool,
};
