import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useHeaderTitle from "hooks/useHeaderTitle";
import MaterialTable from "components/custom/MaterialTable";
import { useSelector } from "react-redux";
import { useActions } from "hooks/useActions";
import * as userActions from "redux/dispatcher/usersManagement";
import { REQUEST_STATUS } from "constant/requestStatus";
import { checkNextItems } from "util/lists";

function UserListView() {
  useHeaderTitle("Usuarios");
  const { rdxUsers, rdxStatus, rdxNextToken } = useSelector(({ users }) => users);
  const fetchUsers = useActions(userActions.getUsers);
  const history = useHistory();
  const [tableOptions] = useState({
    exportButton: false,
    columnsButton: false,
  });
  const [tableColumns] = useState([
    { title: "Nombre", field: "fullName" },
    { title: "Correo", field: "email" },
    { title: "Teléfono", field: "phone" },
    { title: "Extensión", field: "extension" },
    { title: "Fecha de registro", field: "createdAt" },
    { title: "Última actualización", field: "updatedAt" },
  ]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const goToUserDetails = (event, row) => {
    history.push(`/admin/usuarios/${row.id}`);
  };

  const loadMoreUsers = page => {
    const hasMoreItems = checkNextItems(page, rdxUsers.length);
    if (hasMoreItems && rdxNextToken) {
      fetchUsers(rdxNextToken);
    }
  };

  return (
    <>
      <MaterialTable
        options={tableOptions}
        columns={tableColumns}
        data={rdxUsers}
        isLoading={rdxStatus === REQUEST_STATUS.PENDING}
        onRowClick={goToUserDetails}
        onChangePage={loadMoreUsers}
      />
    </>
  );
}

export default UserListView;
