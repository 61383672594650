import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/custom/Button";
import { Grid } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import Select from "components/custom/Select";
import { makeStyles } from "@material-ui/styles";
import ConfirmationDialog from "components/ConfirmationDialog/ConfirmationDialog";
import makeOptionsList from "util/makeOptionsList";
import API, { graphqlOperation } from "@aws-amplify/api";
import * as queries from "graphql-custom/queries";
import * as mutations from "graphql-custom/mutations";
import useNotifier from "hooks/useNotifier";

const useStyles = makeStyles(theme => ({
  dialogContent: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(4)
  } 
}));

function NewDomainsDialog({ onClose, onUpdateDomains }) {
  const classes = useStyles();
  const { control, getValues, trigger, errors } = useForm({ 
    mode: "onChange",
    defaultValues: { company: "", domain: "" } 
  });
  const [companyRules] = useState({ required: "Campo obligatorio" });
  const [domainRules] = useState({
    required: "Campo obligatorio",
    maxLength: { value: 100, message: "Máximo 100 caracteres" },
    pattern: { 
      value: /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/, 
      message: "Dominio con formato inválido" 
    } 
  });
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [creatingDomain, setCreatingDomain] = useState(false);
  const { showError } = useNotifier();

  useEffect(() => {
    (async function() {
      const items = await getCompanies();
      const optionList = makeOptionsList(items, { label: "name", value: "id" });
      setCompanies(optionList);
    })();
  }, []);

  const getCompanies = async () => {
    try {
      const { data } = await API.graphql(graphqlOperation(queries.listCompanies));
      return data.listCompanys.items;
    } catch(error) {
      showError("Ocurrió un error.");
      return [];
    }
  };

  const validateForm = async () => {
    const valid = await trigger();
    if (valid) {
      setShowConfirmation(true);
    } 
  };

  const addDomain = async () => {
    setCreatingDomain(true)
    const { company, domain } = getValues();
    const newDomain = await createDomain(company, domain);
    setCreatingDomain(false);
    if (newDomain) {
      onUpdateDomains(newDomain);
      setShowConfirmation(false);
      onClose();
    }
  };

  const createDomain = async (companyId, domain) => {
    try {
      const { data } = await API.graphql(graphqlOperation(mutations.createAcceptedDomain, { companyId, domain }));
      return data.createAcceptedDomain;
    } catch (error) {
      showError("Ocurrió un error.");
      return "";
    }
  };

  return (
    <>
      <Dialog maxWidth="md" fullWidth open>
        <DialogTitle>Dominio Autorizado</DialogTitle>
        <DialogContent className={classes.dialogContent} dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                as={Select}
                label="Compañía"
                name="company"
                fullWidth
                required
                rules={companyRules}
                options={companies}
                error={!!errors.company}
                helperText={errors.company?.message}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller 
                control={control}
                as={TextField}
                rules={domainRules}
                name="domain"
                type="text"
                label="Dominio"
                fullWidth
                required
                error={!!errors.domain}
                helperText={errors.domain?.message}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="default" onClick={onClose}>
            Cancelar
            </Button>
          <Button color="primary" onClick={validateForm}>
            Agregar
          </Button>
        </DialogActions>
      </Dialog>

      <ConfirmationDialog
        open={showConfirmation}
        title="Confirmación"
        onConfirm={addDomain}
        onCancel={() => setShowConfirmation(false)}
        loading={creatingDomain}
      >
        Está a punto de registrar un nuevo dominio autorizado. Esto permitirá que usuarios 
        con correos con el dominio especificado puedan registrarse en el sistema.
        <br/><br/>
        ¿Deseas continuar?
      </ConfirmationDialog>
    </>
  );
}

NewDomainsDialog.propTypes = {
  onClose: PropTypes.func,
  onUpdateDomains: PropTypes.func
};

NewDomainsDialog.defaultProps = {
  onClose: () => {},
  onUpdateDomains: () => {} 
}; 

export default NewDomainsDialog;