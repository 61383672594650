import { getDataFromGraphQLResponse } from "./manageReponse";

/**
 * Devuelve el total de items que una consulta de graphQL limitada con la variable "limit"
 * hace al servicio de appSync utilizando recursividad.
 *
 * @param {Function} graphQLQuery consulta de graphQL al servicio de AppSync
 * @param {String} queryKey referencia al key donde se alojan los datos de una consulta de graphql
 * Ejemplo:
 * RespuestaDeGraphQL = { queryKey: { items: [], nextToken: "", startedAt: "" } }
 * @param {String} nextToken token de los demás elementos del query inicial
 * @param {(String|Number)} [limitIterations=UNLIMITED] - Limite de queries a realizar ó UNLIMITED
 * @param {Number} iterationsCount - propiedad local para llevar la cantidad de las veces que se ha ejecutado la función.
 * @returns los items totales de una consulta con un limite definido en base a su limite de iteraciones
 */

export default async function retriveAllItemsRecursively(
  graphQLQuery = async () => {},
  queryKey,
  nextToken = null,
  // limitIterations = "UNLIMITED",
  iterationsCount = 0
) {
  //1. Ejecución de la consulta
  const graphQLResponse = await graphQLQuery(nextToken);

  //2. obtener la información principal de la respuesta { items: [], nextToken: "" }
  const responseData = getDataFromGraphQLResponse(graphQLResponse, queryKey);
  const { nextToken: responseNextToken } = responseData;

  //3. Store global
  let _iterationsCount = iterationsCount + 1;
  // const allowQueryAgain = limitIterations === "UNLIMITED" || _iterationsCount < limitIterations;
  let result = responseData;

  if (responseNextToken ) {
    //4. Obtención de los demás items recursivamente hasta que ya no allá más
    const { items, ...rest } = await retriveAllItemsRecursively(
      graphQLQuery,
      queryKey,
      responseNextToken,
      _iterationsCount + 1
    );
    //5. Merge de los items obtenidos en cada iteración
    result = {
      ...rest,
      items: [...result.items, ...items],
    };
  }

  //6. Retorno del store con los items mergeados en cada iteración
  return result;
}
