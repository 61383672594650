import useGraphQL from "hooks/useGraphQL";
import { AssetType, TaskStatus } from "models";
import dayjs from "dayjs";
import * as mutations from "graphql-custom/mutations";
import { 
  UpdateMaintenance, 
  CreateRemark,
  UpdateRemark,
  CreateAssets,
  TaskAssetsReducer,
  PatchCurrentMaterials,
  UpdateTaskAssets,
  UsersTask,
  CreateUserTask,
} from "./types";

export default function useGraphQLHelpers() {
  const { loading: updating, runGraphQLOperation } = useGraphQL();

  function updateMaintenanceDetails({ newValues, oldValues }: UpdateMaintenance) {
    const { type, premise, startDate, endDate } = newValues;
    const maintenanceInput = {
      id: oldValues.id,
      _version: oldValues._version,
      taskTaskTypeId: type,
      taskPremisesId: premise,
      taskProjectId: newValues.projectId || oldValues.projectId,
      startDate: dayjs(startDate).format("YYYY-MM-DD"),
      endDate: dayjs(endDate).format("YYYY-MM-DD"),
      startTime: dayjs(startDate).format("HH:mm:ss"),
      endTime: dayjs(endDate).format("HH:mm:ss"),
    };
    return runGraphQLOperation({
      operation: mutations.updateTask,
      variables: { input: maintenanceInput },
    });
  }

  function createRemark({ taskId, content }: CreateRemark) {
    return runGraphQLOperation({
      operation: mutations.createRemark,
      variables: { 
        input: { 
          taskID: taskId, 
          taskStatus: TaskStatus.SCHEDULED, 
          remark: content,
        },
      },
    });
  }
  
  function updateRemark({ remark, content }: UpdateRemark) {
    const { id, _version } = remark;
    return runGraphQLOperation({
      operation: mutations.updateRemark,
      variables: { input: { id, _version, remark: content } },
    });
  }

  async function createNewAssets({ materials, addedIds }: CreateAssets) {
    if (!addedIds.length) {
      return null;
    }
    const { mutation, params } = mutations.generateNewAssetsMutation(materials, addedIds, AssetType.MATERIAL_ALLOCATED);
    const assetsData = await runGraphQLOperation({ operation: mutation, variables: params });
    return patchCurrentMaterials({ materials, added: addedIds, newAssets: assetsData });
  }

  function updateAssetTypes({ all, added, deleted }: TaskAssetsReducer) {
    if (!added.length && !deleted.length) {
      return null;
    }
    const result = mutations.generateAssetTypeUpdateMutation(all, added, deleted);
    return runGraphQLOperation({ operation: result?.mutation, variables: result?.params });
  }

  function patchCurrentMaterials({ materials, added, newAssets }: PatchCurrentMaterials) {
    return added.reduce((updated, id, index) => {
      const mIndex = updated.findIndex((m) => m.materialId === id);
      updated[mIndex] = {
        ...updated[mIndex],
        assetId: newAssets[`createAsset${index}`].id,
        assetVersion: newAssets[`createAsset${index}`]._version,
      };
      return updated;
    }, [...materials]);
  }
  
  function updateTaskAssets({ taskId, assets: { all, added, updated, deleted } }: UpdateTaskAssets) {
    const mutationInfo = mutations.generateMaterialsUpdate(taskId, all, added, updated, deleted);
    if (!mutationInfo) {
      return null;
    }
    const { mutation, params } = mutationInfo;
    return runGraphQLOperation({ operation: mutation, variables: params });
  }

  function addTechnicians({ taskId, selectedUsers, originalUsers }: CreateUserTask) {
    const addedTechnicians = getAddedTechnicianUsers({ selectedUsers, originalUsers });
    if (!addedTechnicians.length) {
      return null;
    }
    const { relationsVariables, relationsMutation } = mutations.generateRelations(taskId, {}, addedTechnicians)
    return runGraphQLOperation({ operation: relationsMutation, variables: relationsVariables });
  }
    
  function getAddedTechnicianUsers({ selectedUsers, originalUsers }: UsersTask) {
    return selectedUsers.reduce<string[]>((addedUsers, su) => {
      const isNew = originalUsers.find((ou) => ou.userID === su);
      if (!isNew) {
        addedUsers.push(su);
      }
      return addedUsers;
    }, []);
  }
  
  function deleteTechnicians({ selectedUsers, originalUsers }: UsersTask) {
    const deletedTechnicians = getDeletedTechnicianUsers({ selectedUsers, originalUsers });
    if (!deletedTechnicians.length) {
      return null;
    }
    const { relationsVariables, relationsMutation } = mutations.generateDeletedUserTaskRelations(deletedTechnicians);
    return runGraphQLOperation({ operation: relationsMutation, variables: relationsVariables });
  }

  function getDeletedTechnicianUsers({ selectedUsers, originalUsers }: UsersTask) {
    return originalUsers.reduce<{ id: string, version: number }[]>((deletedUsers, ou) => {
      const isDelete = selectedUsers.find((su) => su === ou.userID);
      if (isDelete === undefined) {
        deletedUsers.push({ id: ou.id, version: ou._version });
      }
      return deletedUsers;
    }, []);
  }

  return {
    updateMaintenanceDetails,
    createRemark,
    updateRemark,
    createNewAssets,
    updateAssetTypes,
    updateTaskAssets,
    addTechnicians,
    deleteTechnicians,
    updating,
  };
}

