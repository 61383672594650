import { useState, useRef, useCallback, useMemo } from "react";
import { Logger } from "@aws-amplify/core";
import useSafeDispatch from "./useSafeDispatch";
const logger = new Logger("useCounter");

const useCounter = (initialCount) => {
  const [count, setCount] = useState(initialCount);
  const safeSetCount = useSafeDispatch(setCount);
  const timeoutId = useRef(null);

  function validateDate(loopTime = null, updateTime = null) {
    const errorMsg = "validateData: información invalida";
    if ((!loopTime && loopTime !== 0) || (!updateTime && updateTime < 0)) {
      logger.error(errorMsg, { loopTime, updateTime });
      throw new Error(errorMsg);
    }
  }

  const increaseCount = useCallback(
    (loopTime = 1000, updateTime = 1000) => {
      timeoutId.current = setTimeout(() => {
        validateDate(loopTime, updateTime);
        const newValue = count + updateTime;
        return safeSetCount(newValue);
      }, loopTime);
    },
    [count, safeSetCount]
  );

  const decreaseCount = useCallback(
    (loopTime = 1000, updateTime = 1000, { onTimeExpired } = {}) => {
      timeoutId.current = setTimeout(() => {
        validateDate(loopTime, updateTime);
        let newValue = count - updateTime;
        if (newValue < updateTime) {
          newValue = 0;
          onTimeExpired();
        }
        return safeSetCount(newValue);
      }, loopTime);
    },
    [count, safeSetCount]
  );

  const stopTimer = useCallback(() => clearTimeout(timeoutId.current), []);

  return useMemo(
    () => [count, { increaseCount, decreaseCount, stopTimer, dispatchCount: safeSetCount }],
    [count, increaseCount, decreaseCount, stopTimer, safeSetCount]
  );
};

export default useCounter;
