import React, { useEffect, useMemo, useRef, useState } from "react";

//libraries
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { Logger } from "@aws-amplify/core";

//hooks
import useNotifier from "hooks/useNotifier";
import useTicketActivityHelper from "./helpers/useTicketActivityHelper";
import useLoadingStatus from "hooks/useLoadingStatus";
import useLocalStorageState from "hooks/useLocalStorageState";
import useLongPress from "hooks/useLongPress";
import useEventListener from "hooks/useEventListener";

//material ui
import Alert from "@material-ui/lab/Alert";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import {
  makeStyles,
  useMediaQuery,
  useTheme,
  Typography,
  Box,
  Button,
  IconButton,
  CircularProgress,
  Collapse,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";

//components
import ConfirmationDialog from "components/ConfirmationDialog";
import TicketActivityConfirmDailog from "../TicketActivityConfirmDailog";
import IdentifyAssetView from "components/common/IdentifyAssetView";
import InitialEvidence from "../InitialEvidence";
import ProgressEvidence from "../ProgressEvidence";
import EndEvidence from "../EndEvidence";
import FinalTicketComments from "../FinalTicketComments";
import CustomStepper from "components/common/CustomStepper";
import Select from "components/custom/Select";
import CustomButton from "components/custom/Button";
import QRButtonIcon from "components/custom/QRButtonIcon";

//handlers / helpers / utils
import { SupportType, TaskStatus } from "models";
import { addRemarkTask, updateRemarkTask } from "./helpers/datastore";
import { getInitialActivityStepsState } from "../TicketActivityDetailsView/helpers";
import {
  getEvidencesMinRulesAndSupportTypes,
  filterEvidencesByStatus,
  isEvidenceStepValid,
  getSupportActivitySteps,
  handleGetRemarks,
} from "./helpers";
import { addTaskIdToLocalStorageKey, clearLocalStorage } from "util/localStorage";
import { captureException } from "setup/sentry";

//constants
import {
  LOCAL_STORAGE_CURRENT_STEP_KEY,
  DIALOG_CONTENT_BY_STEP,
  SUPPORT_STEPS_FORMS_RULES,
  DEFAULT_SUPPORT_ACTIVITY_STEPS_KEYNAME,
  LOCAL_STORAGE_FORM_VALUES_KEY,
  STATUS_THAT_ENABLE_ACTIVITY_PROGRESS,
} from "constant/ticketActivity";
import { ACTIVITY_CATEGORY, S3_KEY_PREFIXES } from "constant/attachments";
import { TASK_TYPES } from "constant/task/types";
import useWatchPosition from "hooks/useWatchPosition";

const STEPS_KEYNAME_PRESENTIAL = DEFAULT_SUPPORT_ACTIVITY_STEPS_KEYNAME(SupportType.PRESENTIAL);
const STEPS_KEYNAME_REMOTE = DEFAULT_SUPPORT_ACTIVITY_STEPS_KEYNAME(SupportType.REMOTE);

const logger = new Logger("TicketActivityTrackingDialog");

export default function TicketActivityTrackingDialog(props) {
  const {
    isOpen,
    ticketData,
    taskData,
    assets,
    setAssets,
    taskAttachments,
    onUpdate,
    onClose,
    onAddOptimisticAttachments,
    onDeleteOptimisticAttachment,
    getEffectiveTime,
    reloadData,
  } = props;
  const { id: taskId, status: taskStatus, remarks = [], embeddedEvents = [] } = taskData;
  const { id: ticketId, supportType, reportedAsset } = ticketData;
  const attachmentPrefix = `${S3_KEY_PREFIXES.EVIDENCES_UNCOMPRESSED}/${ACTIVITY_CATEGORY.SUPPORT}/${taskId}`;

  //states
  const [openConfirmEndDialog, setOpenConfirmEndDialog] = useState(false);
  const [openConfirmEndMessage, setOpenConfirmEndMessage] = useState(false);
  const [showAssetScanView, setShowAssetScanView] = useState(false);
  const [isTicketActivityConfirmOpen, setTicketActivityConfirmOpen] = useState(false);
  const [showNotRealAssetConfirmationDialog, setShowNotRealAssetConfirmationDialog] = useState(false);
  const [requestError, setRequestError] = useState("");

  //styles hooks
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  //helpers hook
  const { startTicketActivityHandler, endTicketActivityHandler } = useTicketActivityHelper();
  //loadings
  const [startingActivity, _startActivity] = useLoadingStatus(startActivity);
  const [endingActivity, _endTicketActivityHandler] = useLoadingStatus(endTicketActivityHandler);
  const [addingAttachments, setAddingAttachments] = useState(false);
  const isLoading = startingActivity || endingActivity || addingAttachments;

  const { showMessage, showError } = useNotifier();
  const supportSettings = useSelector(({ systemConfigs }) => systemConfigs.support);
  const longPressEvent = useLongPress(onClose, goToPreviousStep);

  const { evidencesMinRules, supportTypeOptions } = useMemo(
    () => getEvidencesMinRulesAndSupportTypes(supportSettings),
    [supportSettings]
  );
  const evidencesByStatus = useMemo(() => filterEvidencesByStatus(taskAttachments), [taskAttachments]);

  const [activityFormValuesLS, setActivityFormValuesInLS] = useLocalStorageState(
    addTaskIdToLocalStorageKey(LOCAL_STORAGE_FORM_VALUES_KEY, taskId),
    null,
    { storeFalsyValues: false }
  );
  const { control, errors, trigger, getValues, watch } = useForm({
    mode: "onChange",
    defaultValues: activityFormValuesLS || DEFAULT_FORM_VALUES,
  });
  const {
    supportType: supportTypeValue,
    issue: issueValue,
    analysis: analysisValue,
    solution: solutionValue,
    resolutionComment: resolutionCommentValue,
  } = watch(["supportType", "issue", "analysis", "solution", "resolutionComment"]);

  const [stepsStateInLs, setCurrentStepInLS] = useLocalStorageState(
    addTaskIdToLocalStorageKey(LOCAL_STORAGE_CURRENT_STEP_KEY, taskId),
    null,
    { storeFalsyValues: false }
  );
  let stepsState, currentStepState, currentStep, isStepCompleted, currentStepKeyname;
  if (stepsStateInLs && Object.keys(stepsStateInLs).length) {
    stepsState = stepsStateInLs.stepsState;
    currentStepState = stepsStateInLs.currentStepState;
    currentStep = currentStepState.step;
    isStepCompleted = currentStepState.isStepCompleted;
    currentStepKeyname = currentStepState.stepKeyname;
  }
  const SUPPORT_ACTIVITY_STEPS = useMemo(() => getSupportActivitySteps(stepsState), [stepsState]);
  const classes = useStyles({ currentStepKeyname });
  const { position, loading: loadingPosition, error: positionError } = useWatchPosition();

  //refs
  const evidenceRef = useRef(null);
  const evidenceRef2 = useRef(null);
  const evidenceRef3 = useRef(null);

  const IS_NEXT_BUTTON_DISABLED = useMemo(() => {
    let isDisabled = false;
    if (!STATUS_THAT_ENABLE_ACTIVITY_PROGRESS.includes(taskStatus)) {
      return isDisabled;
    }

    const SELECT_SUPPORT_TYPE_STEP_VALID = Boolean(supportType || supportTypeValue);
    const INITIAL_EVIDENCE_STEP_VALID = isEvidenceStepValid(
      STEPS_KEYNAME_PRESENTIAL.INITIAL_EVIDENCE,
      evidencesByStatus,
      evidencesMinRules
    );
    const EVIDENCE_PROGRESS_STEP_VALID = isEvidenceStepValid(
      STEPS_KEYNAME_PRESENTIAL.PROGRESS_EVIDENCE,
      evidencesByStatus,
      evidencesMinRules
    );
    const END_EVIDENCE_STEP_VALID = isEvidenceStepValid(
      STEPS_KEYNAME_PRESENTIAL.END_EVIDENCE,
      evidencesByStatus,
      evidencesMinRules
    );
    const ACTIVITY_FEEDBACK_STEP_VALID = issueValue && analysisValue && solutionValue;
    const REMOTE_ACTIVITY_STEP_VALID =
      SELECT_SUPPORT_TYPE_STEP_VALID &&
      supportType === SupportType.REMOTE &&
      END_EVIDENCE_STEP_VALID &&
      resolutionCommentValue;

    if (
      (currentStep === SUPPORT_ACTIVITY_STEPS[STEPS_KEYNAME_PRESENTIAL.SUPPORT_TYPE] &&
        !SELECT_SUPPORT_TYPE_STEP_VALID) ||
      (currentStep === SUPPORT_ACTIVITY_STEPS[STEPS_KEYNAME_REMOTE.REMOTE_ACTIVITY] && !REMOTE_ACTIVITY_STEP_VALID) ||
      (currentStep === SUPPORT_ACTIVITY_STEPS[STEPS_KEYNAME_PRESENTIAL.INITIAL_EVIDENCE] &&
        !INITIAL_EVIDENCE_STEP_VALID) ||
      (currentStep === SUPPORT_ACTIVITY_STEPS[STEPS_KEYNAME_PRESENTIAL.PROGRESS_EVIDENCE] &&
        !EVIDENCE_PROGRESS_STEP_VALID) ||
      (currentStep === SUPPORT_ACTIVITY_STEPS[STEPS_KEYNAME_PRESENTIAL.END_EVIDENCE] && !END_EVIDENCE_STEP_VALID) ||
      (currentStep === SUPPORT_ACTIVITY_STEPS[STEPS_KEYNAME_PRESENTIAL.ACTIVITY_RETROSPECTIVE] &&
        !ACTIVITY_FEEDBACK_STEP_VALID) ||
      isLoading
    ) {
      isDisabled = true;
    }
    return isDisabled;
  }, [
    supportType,
    supportTypeValue,
    issueValue,
    analysisValue,
    solutionValue,
    resolutionCommentValue,
    currentStep,
    SUPPORT_ACTIVITY_STEPS,
    isLoading,
    taskStatus,
    evidencesByStatus,
    evidencesMinRules,
  ]);

  const showAssetScanButton = !showAssetScanView && supportType && supportType === SupportType.PRESENTIAL;
  //allheight - stepper - instrucctions - evidencesCount - addEvidenceButton - margin
  const evidenceListHeight = isMobileScreen
    ? "calc(100vh - 90px - 60px - 72px - 48px - 8px)"
    : "calc(100vh - 90px - 60px - 72px - 48px - 80px - 8px)";

  //Se encarga de forzar la ejecución de onDialogExit al salir de la app por algún motivo
  useEventListener("beforeunload", onDialogExit);

  useEffect(() => {
    if (isOpen && !stepsStateInLs) {
      const stepsState = getInitialActivityStepsState(taskData.status, supportType, {
        evidencesByStatus,
        evidencesMinRules,
        issueValue,
        analysisValue,
        solutionValue,
        resolutionCommentValue,
      });
      setCurrentStepInLS(stepsState);
    }

    if (isOpen && !activityFormValuesLS) {
      const initialFormStepState = handleGetInitalFormValues(taskStatus, supportType, remarks);
      setActivityFormValuesInLS(initialFormStepState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, stepsStateInLs]);

  //handlers
  const handleNext = () => {
    setCurrentStepInLS(({ stepsState, currentStepState }) => {
      let nextStep = stepsState[currentStepState.step + 1];
      let isNextStepCompleted = nextStep.isStepCompleted;

      //si el siguiente paso no esta completado, entonces ahora es el último paso
      if (!isNextStepCompleted) {
        nextStep.isLastStep = true;
      }

      //actualizamos el valor del último paso en el arreglo "stepsState"
      stepsState[nextStep.step] = nextStep;

      //actualizamos el valor actual para que ya no sea el último paso
      stepsState[currentStepState.step] = {
        ...stepsState[currentStepState.step],
        isLastStep: false,
        isStepCompleted: true,
      };
      return {
        stepsState,
        currentStepState: nextStep,
      };
    });
  };

  const handleBack = () => {
    setCurrentStepInLS(({ stepsState, currentStepState }) => {
      currentStepState = stepsState[currentStepState.step - 1];
      return {
        stepsState,
        currentStepState,
      };
    });
  };

  function goToPreviousStep() {
    switch (currentStep) {
      case SUPPORT_ACTIVITY_STEPS[STEPS_KEYNAME_PRESENTIAL.SUPPORT_TYPE]:
        onClose();
        break;
      // case SUPPORT_ACTIVITY_STEPS[STEPS_KEYNAME_PRESENTIAL.SCAN_ASSET]:
      case SUPPORT_ACTIVITY_STEPS[STEPS_KEYNAME_PRESENTIAL.INITIAL_EVIDENCE]:
      case SUPPORT_ACTIVITY_STEPS[STEPS_KEYNAME_REMOTE.REMOTE_ACTIVITY]:
      case SUPPORT_ACTIVITY_STEPS[STEPS_KEYNAME_PRESENTIAL.PROGRESS_EVIDENCE]:
      case SUPPORT_ACTIVITY_STEPS[STEPS_KEYNAME_PRESENTIAL.END_EVIDENCE]:
      case SUPPORT_ACTIVITY_STEPS[STEPS_KEYNAME_PRESENTIAL.ACTIVITY_RETROSPECTIVE]:
        handleBack();
        break;
      default:
        return;
    }
  }

  async function goToNextStep() {
    switch (currentStep) {
      case SUPPORT_ACTIVITY_STEPS[STEPS_KEYNAME_PRESENTIAL.SUPPORT_TYPE]: {
        if (!supportType) {
          setTicketActivityConfirmOpen(true);
        } else {
          handleNext();
        }
        break;
      }

      case SUPPORT_ACTIVITY_STEPS[STEPS_KEYNAME_PRESENTIAL.INITIAL_EVIDENCE]:
        validateEvidence(STEPS_KEYNAME_PRESENTIAL.INITIAL_EVIDENCE, evidencesMinRules.MIN_START);
        break;
      case SUPPORT_ACTIVITY_STEPS[STEPS_KEYNAME_PRESENTIAL.PROGRESS_EVIDENCE]:
        validateEvidence(STEPS_KEYNAME_PRESENTIAL.PROGRESS_EVIDENCE, evidencesMinRules.MIN_PROGRESS);
        break;
      case SUPPORT_ACTIVITY_STEPS[STEPS_KEYNAME_PRESENTIAL.END_EVIDENCE]:
        validateEvidence(STEPS_KEYNAME_PRESENTIAL.END_EVIDENCE, evidencesMinRules.MIN_END);
        break;
      case SUPPORT_ACTIVITY_STEPS[STEPS_KEYNAME_PRESENTIAL.ACTIVITY_RETROSPECTIVE]:
      case SUPPORT_ACTIVITY_STEPS[STEPS_KEYNAME_REMOTE.REMOTE_ACTIVITY]:
        await validateActivityFeedback();
        break;
      default:
        return;
    }
  }

  async function validateStartActivity() {
    const isFormValid = await trigger("supportType");
    if (!isFormValid) {
      showError("Selecciona un tipo de soporte para continuar.");
    }

    return isFormValid;
  }

  /**
   *
   * This function starts the activity / initialize timer and sets the status of the taskData/ticketData in progress and set the supportType.
   */
  async function startActivity() {
    const TASK_ERROR = "startActivity: Ocurrio un error al iniciar la actividad.";

    try {
      const isFormValid = await validateStartActivity();
      if (!isFormValid) {
        return;
      }
      const { success, data } = await startTicketActivityHandler(taskId, ticketId, supportTypeValue);
      if (!success) {
        throw new Error(TASK_ERROR);
      }
      onStartActivitySuccess(data.updatedTicket, data.updatedTask);
    } catch (error) {
      logger.error(error, { ticketId, taskId, supportType: supportTypeValue });
      setRequestError(
        "Ocurrió un error durante el inicio de la atención del ticket. Vuelvelo a intentar o recarga la aplicación."
      );
      captureException(error.message, "TicketActivityTrackingDialog:startActivity");
    }
  }

  function onStartActivitySuccess(updatedTicket, updatedTask) {
    onUpdate(updatedTicket, updatedTask);
    showMessage("Se registró de forma exitosa el inicio de la atención del ticket.");
    setRequestError("");

    setCurrentStepInLS(
      getInitialActivityStepsState(updatedTask.status, updatedTicket.supportType, {
        evidencesByStatus,
        evidencesMinRules,
        issueValue,
        analysisValue,
        solutionValue,
        resolutionCommentValue,
      })
    );
  }

  /**
   *
   */
  function validateEvidence(evidenceStep, minEvidences) {
    const IS_EVIDENCE_STEP_VALID = isEvidenceStepValid(evidenceStep, evidencesByStatus, minEvidences);
    if (IS_EVIDENCE_STEP_VALID) {
      handleNext();
    } else {
      onInvalidEvidenceStep(minEvidences);
    }
  }

  function onInvalidEvidenceStep(evidencesMin) {
    showError(`Se requieren ${evidencesMin} evidencias para continuar el soporte.`);
  }

  /**
   * The function validates the final comments
   */
  async function validateActivityFeedback() {
    let isValid = false;

    if (supportType === SupportType.PRESENTIAL) {
      isValid = await validatePresentialActivity();
    }

    if (supportType === SupportType.REMOTE) {
      isValid = await validateRemoteActivity();
    }

    if (isValid) {
      setOpenConfirmEndDialog(true);
    }
  }

  async function validatePresentialActivity() {
    let isValid = false;

    //validación formulario
    const validForm = await trigger(["issue", "analysis", "solution"]);
    if (!validForm) {
      showError("Completa los comentarios y sus requisitos para poder finalizar la actividad");
      return isValid;
    }

    //valdiación evidencias
    const INITIAL_EVIDENCE_STEP_VALID = evidencesByStatus.beginning.length >= evidencesMinRules.MIN_START;
    const EVIDENCE_PROGRESS_STEP_VALID = evidencesByStatus.inProgress.length >= evidencesMinRules.MIN_PROGRESS;
    const END_EVIDENCE_STEP_VALID = evidencesByStatus.completed.length >= evidencesMinRules.MIN_END;
    const areEvidencesStepsCompleted =
      INITIAL_EVIDENCE_STEP_VALID && EVIDENCE_PROGRESS_STEP_VALID && END_EVIDENCE_STEP_VALID;

    if (!areEvidencesStepsCompleted) {
      showError("Añade las evidencias requeridas en cada uno de los pasos");
      setCurrentStepInLS(({ stepsState }) => ({ stepsState, currentStepState: stepsState[1] }));
      return isValid;
    }

    //validación activo
    const showNotRealAssetDialog =
      supportType === SupportType.PRESENTIAL &&
      (!assets || !assets.length) &&
      reportedAsset &&
      Object.keys(reportedAsset).length;

    if (showNotRealAssetDialog) {
      setShowNotRealAssetConfirmationDialog(true);
      return isValid;
    }

    isValid = true;
    return isValid;
  }

  async function validateRemoteActivity() {
    const END_EVIDENCE_STEP_VALID = evidencesByStatus.completed.length >= evidencesMinRules.MIN_END;
    const isFormValid = await trigger("resolutionComment");

    return isFormValid && END_EVIDENCE_STEP_VALID;
  }

  async function handleActivityEnding() {
    try {
      let newRemarksState = [];
      const efectiveTime = getEffectiveTime();
      const _remarks = handleGetRemarks(remarks);
      const oldRemarks = _remarks.filter((remark) => remark.remark.includes("<***>"));

      if (!oldRemarks || !oldRemarks.length) {
        newRemarksState = await _addRemarksTask(_remarks);
      } else {
        newRemarksState = await _updateRemarksTask(_remarks, oldRemarks);
      }
      let { success, data: updatedData } = await _endTicketActivityHandler(
        taskId,
        ticketId,
        efectiveTime,
        embeddedEvents
      );
      if (success) {
        onUpdate(updatedData.ticket, { ...updatedData.task, remarks: newRemarksState });
        onActivityEndSuccess();
      }
    } catch (error) {
      logger.error(error);
      setRequestError(
        "Ocurrió un error durante la finalización de atención del ticket. Vuelvelo a intentar o recarga la aplicación."
      );
      captureException(error.message, "TicketActivityTrackingDialog:handleActivityEnding");
      setOpenConfirmEndDialog(false);
    }
  }

  function onActivityEndSuccess() {
    clearLocalStorage(addTaskIdToLocalStorageKey(LOCAL_STORAGE_CURRENT_STEP_KEY, taskId));
    clearLocalStorage(addTaskIdToLocalStorageKey(LOCAL_STORAGE_FORM_VALUES_KEY, taskId));
    setShowNotRealAssetConfirmationDialog(false);
    setOpenConfirmEndDialog(false);
    setOpenConfirmEndMessage(true);
    setRequestError("");
    onClose();
  }

  async function _addRemarksTask(currentRemarks = []) {
    let newRemarksState = [...currentRemarks];
    const remarksPromises = handleGetAddRemarksPromises();
    if (!remarksPromises || !remarksPromises.length) {
      onAddRemarksTaskError(remarksPromises);
    }
    const newRemarks = await Promise.all(remarksPromises);
    newRemarksState = [...newRemarksState, ...newRemarks];
    return newRemarksState;
  }

  function handleGetAddRemarksPromises() {
    let promises = [];

    if (!supportType || supportType === SupportType.UNDEFINED) {
      return promises;
    }

    const isPresential = supportType === SupportType.PRESENTIAL;
    const isPresentialValid = Boolean(issueValue) && Boolean(analysisValue) && Boolean(solutionValue);

    if (isPresential && isPresentialValid) {
      promises = [
        addRemarkTask(taskId, `issue<***>${issueValue.trim()}`),
        addRemarkTask(taskId, `analysis<***>${analysisValue.trim()}`),
        addRemarkTask(taskId, `solution<***>${solutionValue.trim()}`),
      ];
    }

    const isRemote = supportType === SupportType.REMOTE;
    const isRemoteValid = Boolean(resolutionCommentValue);

    if (isRemote && isRemoteValid) {
      promises = [addRemarkTask(taskId, `resolutionComment<***>${resolutionCommentValue.trim()}`)];
    }

    return promises;
  }

  function onAddRemarksTaskError(remarksPromises) {
    const ERROR_MSG = "_addRemarksTask: Invalid remark promises.";
    logger.error(ERROR_MSG, {
      remarksPromises,
      supportType,
      issueValue,
      analysisValue,
      solutionValue,
      resolutionCommentValue,
    });
    throw new Error(ERROR_MSG);
  }

  async function _updateRemarksTask(currentRemarks = [], oldRemarks = []) {
    let newRemarksState = [...currentRemarks];

    const updateRemarksPromises = oldRemarks.map(async (oldRemark) => {
      //actualizar valor del remark
      const [fieldName, oldRemarkValue] = oldRemark.remark.split("<***>");
      const remarkCurrentValue = getValues(fieldName);

      // early exit / solo se actualizan los remarks cuyo valor sea distinto
      if (oldRemarkValue === remarkCurrentValue) {
        return oldRemark;
      }

      const updatedRemark = await updateRemarkTask(oldRemark.id, `${fieldName}<***>${remarkCurrentValue.trim()}`);

      //actualizar remark en listado de remarks
      const remarkInTaskDataIndex = newRemarksState.findIndex((remark) => remark.id === oldRemark.id);
      newRemarksState[remarkInTaskDataIndex] = { ...updatedRemark };

      return updatedRemark;
    });

    await Promise.all(updateRemarksPromises);
    return newRemarksState;
  }

  function handleNextButtonIcon() {
    if (isLoading) {
      return <CircularProgress color="inherit" size="2rem" />;
    }

    if (isStepCompleted) {
      return <ArrowForwardIosIcon className={classes.iconSize} aria-label="Avanzar al siguiente paso" color="action" />;
    }

    return <CheckIcon className={classes.iconSize} aria-label="Confirmar paso actual" color="inherit" />;
  }

  function handleBackButtonIcon() {
    if (currentStep === SUPPORT_ACTIVITY_STEPS[STEPS_KEYNAME_PRESENTIAL.SUPPORT_TYPE]) {
      return <CloseIcon className={classes.iconSize} aria-label="Cerrar modal" color="inherit" />;
    }
    return <ArrowBackIosIcon className={classes.iconSize} aria-label="Regresar al paso anterior" color="inherit" />;
  }

  // almacena en local storage los valores del formulario
  function onDialogExit() {
    if (
      STATUS_THAT_ENABLE_ACTIVITY_PROGRESS.includes(taskStatus) &&
      (supportTypeValue || issueValue || analysisValue || solutionValue || resolutionCommentValue)
    ) {
      setActivityFormValuesInLS({
        supportType: supportTypeValue,
        issue: issueValue,
        analysis: analysisValue,
        solution: solutionValue,
        resolutionComment: resolutionCommentValue,
      });
    }
  }

  function onReload() {
    onDialogExit();
    reloadData();
  }

  return (
    <>
      {STATUS_THAT_ENABLE_ACTIVITY_PROGRESS.includes(taskStatus) && (
        <Dialog
          open={isOpen}
          fullScreen={isMobileScreen}
          scroll="paper"
          PaperProps={!isMobileScreen ? { className: classes.desktopStyles } : {}}
          onExit={onDialogExit}
          disablePortal={true}
        >
          <DialogTitle className={classes.dialogTitle}>
            <div>{`${DIALOG_CONTENT_BY_STEP[currentStepKeyname]?.title}`}</div>

            {(taskData.status === TaskStatus.IN_PROGRESS || taskData.status === TaskStatus.WITH_INSPECTION_NOTES) &&
              supportType && (
                <>
                  {/* -----backButton----- */}
                  <div className={classes.backBtnContainer}>
                    <IconButton disabled={isLoading} {...longPressEvent}>
                      {handleBackButtonIcon()}
                    </IconButton>
                  </div>

                  {/* -----Stepper----- */}
                  <CustomStepper activeStep={currentStep} steps={stepsState} />

                  {/* -----NextButton----- */}
                  <div className={classes.nextBtnContainer}>
                    <IconButton
                      onClick={goToNextStep}
                      color="primary"
                      name="dialog-primary-button"
                      disabled={IS_NEXT_BUTTON_DISABLED}
                    >
                      {handleNextButtonIcon()}
                    </IconButton>
                  </div>
                </>
              )}
          </DialogTitle>
          <DialogContent
            dividers
            className={classes.dialogContent}
            classes={{ dividers: classes.dialogContentDividers }}
          >
            {requestError && (
              <Box display="flex" flexDirection="column" gridGap="4px" p={2}>
                <Alert severity="error">{requestError}</Alert>
                <Button onClick={onReload} variant="contained" color="primary">
                  Recargar
                </Button>
              </Box>
            )}

            {currentStep === SUPPORT_ACTIVITY_STEPS[STEPS_KEYNAME_PRESENTIAL.SUPPORT_TYPE] && (
              <DialogContentText color="textPrimary">
                {DIALOG_CONTENT_BY_STEP[currentStepKeyname]?.instructions(supportType)}
              </DialogContentText>
            )}

            {/* Primer paso. Confirmación de tipo de soporte */}
            <Collapse in={currentStep === SUPPORT_ACTIVITY_STEPS[STEPS_KEYNAME_PRESENTIAL.SUPPORT_TYPE]} timeout={0}>
              <Controller
                control={control}
                as={Select}
                label="Tipo de soporte"
                name="supportType"
                fullWidth
                required
                rules={SUPPORT_STEPS_FORMS_RULES.supportType}
                options={supportTypeOptions}
                error={!!errors.supportType}
                helperText={errors.supportType?.message}
                disabled={Boolean(supportType)}
              />
            </Collapse>

            {/* Paso 1.1. Registro de comentario y evidencia en actividades remotas. */}
            <Collapse
              in={currentStepKeyname === STEPS_KEYNAME_REMOTE.REMOTE_ACTIVITY && supportType === SupportType.REMOTE}
              timeout={0}
            >
              <>
                <Typography>
                  Los siguientes campos son requeridos, completalos para poder finalizar tu actividad.
                </Typography>
                <Box mt={3}>
                  <Controller
                    control={control}
                    name="resolutionComment"
                    rules={SUPPORT_STEPS_FORMS_RULES.resolutionComment}
                    as={
                      <TextField
                        type="text"
                        label="Comentario de resolución"
                        error={!!errors.resolutionComment}
                        helperText={errors.resolutionComment?.message}
                        variant="outlined"
                        fullWidth
                        required
                        multiline
                        rows={4}
                      />
                    }
                  />
                </Box>
                <Box mt={3}>
                  <EndEvidence
                    taskId={taskId}
                    attachmentPrefix={attachmentPrefix}
                    evidenceStatus={TaskStatus.COMPLETED}
                    evidencesMinRules={evidencesMinRules.MIN_END}
                    evidenceRef={evidenceRef3}
                    endEvidences={evidencesByStatus.completed}
                    evidenceLoading={addingAttachments}
                    setEvidenceLoading={(isLoading) => setAddingAttachments(isLoading)}
                    onAddOptimisticAttachments={onAddOptimisticAttachments}
                    onDeleteOptimisticAttachment={onDeleteOptimisticAttachment}
                    taskType={TASK_TYPES.SUPPORT}
                  />
                </Box>
              </>
            </Collapse>

            {/* Segundo paso. Registro de evidencias de inicio  */}
            <Collapse
              in={currentStep === SUPPORT_ACTIVITY_STEPS[STEPS_KEYNAME_PRESENTIAL.INITIAL_EVIDENCE]}
              timeout={0}
            >
              <>
                <InitialEvidence
                  taskId={taskId}
                  attachmentPrefix={attachmentPrefix}
                  evidenceStatus={TaskStatus.SCHEDULED}
                  evidencesMinRules={evidencesMinRules.MIN_START}
                  evidenceRef={evidenceRef}
                  initialEvidences={evidencesByStatus.beginning}
                  evidenceLoading={addingAttachments}
                  setEvidenceLoading={(isLoading) => setAddingAttachments(isLoading)}
                  onAddOptimisticAttachments={onAddOptimisticAttachments}
                  onDeleteOptimisticAttachment={onDeleteOptimisticAttachment}
                  taskType={TASK_TYPES.SUPPORT}
                  evidenceListHeight={evidenceListHeight}
                  position={position}
                  loadingPosition={loadingPosition}
                  positionError={positionError}
                />
              </>
            </Collapse>

            {/* Tercer paso. Registro de evidencias de progreso  */}

            <Collapse
              in={currentStep === SUPPORT_ACTIVITY_STEPS[STEPS_KEYNAME_PRESENTIAL.PROGRESS_EVIDENCE]}
              timeout={0}
            >
              <ProgressEvidence
                taskId={taskId}
                attachmentPrefix={attachmentPrefix}
                evidenceStatus={TaskStatus.IN_PROGRESS}
                evidencesMinRules={evidencesMinRules.MIN_PROGRESS}
                evidenceRef={evidenceRef2}
                progressEvidences={evidencesByStatus.inProgress}
                evidenceLoading={addingAttachments}
                setEvidenceLoading={(isLoading) => setAddingAttachments(isLoading)}
                onAddOptimisticAttachments={onAddOptimisticAttachments}
                onDeleteOptimisticAttachment={onDeleteOptimisticAttachment}
                taskType={TASK_TYPES.SUPPORT}
                evidenceListHeight={evidenceListHeight}
                position={position}
                loadingPosition={loadingPosition}
                positionError={positionError}
              />
            </Collapse>

            {/* Cuarto paso. Registro de evidencias de fin  */}

            <Collapse in={currentStep === SUPPORT_ACTIVITY_STEPS[STEPS_KEYNAME_PRESENTIAL.END_EVIDENCE]} timeout={0}>
              <EndEvidence
                taskId={taskId}
                attachmentPrefix={attachmentPrefix}
                evidenceStatus={TaskStatus.COMPLETED}
                evidencesMinRules={evidencesMinRules.MIN_END}
                evidenceRef={evidenceRef3}
                endEvidences={evidencesByStatus.completed}
                evidenceLoading={addingAttachments}
                setEvidenceLoading={(isLoading) => setAddingAttachments(isLoading)}
                onAddOptimisticAttachments={onAddOptimisticAttachments}
                onDeleteOptimisticAttachment={onDeleteOptimisticAttachment}
                taskType={TASK_TYPES.SUPPORT}
                evidenceListHeight={evidenceListHeight}
                position={position}
                loadingPosition={loadingPosition}
                positionError={positionError}
              />
            </Collapse>

            {/* Quinto paso. Registro de detalles de Finalización de soporte */}
            <Collapse
              in={currentStep === SUPPORT_ACTIVITY_STEPS[STEPS_KEYNAME_PRESENTIAL.ACTIVITY_RETROSPECTIVE]}
              timeout={0}
            >
              <FinalTicketComments control={control} errors={errors} />
            </Collapse>
          </DialogContent>

          {/* This validation enables the buttons to continue with the selection of support type */}
          {currentStep === SUPPORT_ACTIVITY_STEPS[STEPS_KEYNAME_PRESENTIAL.SUPPORT_TYPE] && !supportType && (
            <DialogActions>
              <Button onClick={goToPreviousStep} color="default" disabled={isLoading}>
                {DIALOG_CONTENT_BY_STEP[currentStepKeyname]?.previousBtnLabel}
              </Button>
              <CustomButton
                onClick={goToNextStep}
                color="primary"
                name="dialog-primary-button"
                disabled={isLoading || !supportTypeValue}
                loading={isLoading}
              >
                {DIALOG_CONTENT_BY_STEP[currentStepKeyname]?.nextBtnLabel}
              </CustomButton>
            </DialogActions>
          )}

          {showAssetScanButton && (
            <QRButtonIcon
              width="56px"
              height="56px"
              variant="FILLED"
              onClick={() => setShowAssetScanView(true)}
              color="primary"
              name="dialog-primary-button"
              disabled={showAssetScanView}
              className={classes.QRButtonIcon}
            />
          )}
        </Dialog>
      )}

      {/* Dialog confirmación de tipo de soporte */}
      {!supportType && taskStatus === TaskStatus.SCHEDULED && (
        <TicketActivityConfirmDailog
          open={isTicketActivityConfirmOpen}
          onClose={() => setTicketActivityConfirmOpen(false)}
          fullWidth={false}
          maxWidth={false}
          onSuccess={_startActivity}
          supportType={supportTypeValue || SupportType.UNDEFINED}
          isLoading={isLoading}
        />
      )}

      {/* Dialog para visualizar la información del activo reportado y escanear activo en campo */}
      <Dialog
        open={showAssetScanView}
        fullScreen={isMobileScreen}
        scroll="paper"
        PaperProps={!isMobileScreen ? { className: classes.desktopStyles } : {}}
        disablePortal={true}
      >
        <DialogContent>
          <IdentifyAssetView
            taskData={taskData}
            realAssetInField={assets[0]}
            reportedAsset={ticketData.reportedAsset}
            assets={assets}
            setAssets={setAssets}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowAssetScanView(false)}>Cerrar</Button>
        </DialogActions>
      </Dialog>

      {/* Dialog de confirmación para finalizar actividad sin escanear algún activo */}
      <ConfirmationDialog
        open={showNotRealAssetConfirmationDialog}
        title="Confirmación"
        onConfirm={handleActivityEnding}
        onCancel={() => setShowNotRealAssetConfirmationDialog(false)}
        loading={endingActivity}
      >
        Esta actividad cuenta con un activo reportado, pero no has validado el código QR de ningún activo.
        <br />
        <br />
        ¿Estás seguro que deseas finalizar la actividad?
        <br />
        <br />
        <strong>Activo Reportado:</strong>
        <ul>
          <li>
            <strong>Nombre:</strong>
            <span className={classes["dataLabel-data"]}>{reportedAsset?.name || "Sin nombre registrado"}</span>
          </li>
          <li>
            <strong>Número de serie:</strong>
            <span className={classes["dataLabel-data"]}>
              {reportedAsset?.serial || "Sin número de serie registrado"}
            </span>
          </li>
          <li>
            <strong>Ubicación:</strong>
            <span className={classes["dataLabel-data"]}>
              {reportedAsset?.location || "Sin ubicación en sede registrada"}
            </span>
          </li>
        </ul>
      </ConfirmationDialog>

      {/* Dialog de confirmación para finalizar actividad */}
      <ConfirmationDialog
        open={openConfirmEndDialog}
        title="Confirmación"
        onConfirm={handleActivityEnding}
        onCancel={() => setOpenConfirmEndDialog(false)}
        loading={endingActivity}
      >
        ¿Estás seguro que deseas finalizar la atención de soporte?
      </ConfirmationDialog>

      {/* Dialog de feedback al finalizar actividad */}
      <ConfirmationDialog
        open={openConfirmEndMessage}
        title="Mensaje"
        onCancel={() => setOpenConfirmEndMessage(false)}
        cancelBtnText="Cerrar"
        showOkBtn={endingActivity}
      >
        <DialogContentText color="textPrimary">
          Hemos registrado el detalle de su atención de soporte.
          <br />A continuación debemos confirmar con el usuario la resolución de su incidencia.
        </DialogContentText>
      </ConfirmationDialog>
    </>
  );
}

const DEFAULT_FORM_VALUES = {
  supportType: "",
  resolutionComment: " ",
  issue: " ",
  analysis: " ",
  solution: " ",
};

function handleGetInitalFormValues(activityStatus, supportType, remarks) {
  if (!STATUS_THAT_ENABLE_ACTIVITY_PROGRESS.includes(activityStatus)) {
    return;
  }
  let result = DEFAULT_FORM_VALUES;
  remarks = [...handleGetRemarks(remarks)];
  const HAS_SUPPORT_TYPE = Boolean(supportType);
  const HAS_REMARKS = remarks && remarks.length;

  if (!HAS_SUPPORT_TYPE && !HAS_REMARKS) {
    return result;
  }
  if (HAS_SUPPORT_TYPE) {
    result.supportType = supportType;
  }
  if (HAS_REMARKS) {
    const currentTaskRemarks = remarks.reduce(
      (allRemarks, taskRemark) => {
        if (taskRemark.remark.includes("<***>")) {
          const [fieldName, value] = taskRemark.remark.split("<***>");
          allRemarks[fieldName] = value;
          return allRemarks;
        }
        return allRemarks;
      },
      { resolutionComment: "", issue: "", analysis: "", solution: "" }
    );
    result = {
      ...result,
      ...currentTaskRemarks,
    };
  }

  return result;
}

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    flexDirection: "column",
    "& > *": {
      flexDirection: "column",
    },
  },
  dialogContent: ({ currentStepKeyname }) => ({
    overflow: currentStepKeyname !== STEPS_KEYNAME_REMOTE.REMOTE_ACTIVITY ? "hidden" : "auto",
    height: "calc(100vh - 90px)",
    marginBottom:
      currentStepKeyname &&
      [
        STEPS_KEYNAME_PRESENTIAL.INITIAL_EVIDENCE,
        STEPS_KEYNAME_PRESENTIAL.PROGRESS_EVIDENCE,
        STEPS_KEYNAME_PRESENTIAL.END_EVIDENCE,
        STEPS_KEYNAME_REMOTE.REMOTE_ACTIVITY,
      ].includes(currentStepKeyname)
        ? "48px"
        : "0px",
  }),
  dialogContentDividers: {
    padding: "16px 24px",
    borderTop: "5px solid rgba(16, 82, 133, 0.2)",
    borderBottom: "none",
  },
  desktopStyles: {
    height: "90%",
  },
  backBtnContainer: {
    position: "absolute",
    left: "8px",
    [theme.breakpoints.down(360)]: {
      left: "0px",
    },
  },
  nextBtnContainer: {
    position: "absolute",
    right: "8px",
    [theme.breakpoints.down(360)]: {
      right: "0px",
    },
  },
  iconSize: {
    fontSize: "2rem",
  },
  QRButtonIcon: {
    position: "absolute",
    right: "24px",
    bottom: "50px",
  },
  "dataLabel-data": {
    marginLeft: "4px",
  },
}));

TicketActivityTrackingDialog.propTypes = {
  isOpen: PropTypes.bool,
  ticketData: PropTypes.object,
  taskData: PropTypes.object,
  assets: PropTypes.array,
  setAssets: PropTypes.func,
  taskAttachments: PropTypes.array,
  onUpdate: PropTypes.func,
  onClose: PropTypes.func,
  onAddOptimisticAttachments: PropTypes.func,
  onDeleteOptimisticAttachment: PropTypes.func,
  getEffectiveTime: PropTypes.func,
  reloadData: PropTypes.func,
};

TicketActivityTrackingDialog.defaultProps = {
  isOpen: false,
  ticketData: null,
  taskData: {},
  assets: [],
  setAssets: () => {},
  taskAttachments: [],
  onUpdate: () => {},
  onClose: () => {},
  onAddOptimisticAttachments: () => {},
  onDeleteOptimisticAttachment: () => {},
  getEffectiveTime: () => {},
  reloadData: () => {},
};
