// TODO: Descomentar atributo status del mutation cuando el ambiente productivo esté actualizado
export function copyAdmAlmacenesToBuildingLocations(premisesId, whs) {
  const variables = {
    premisesID: premisesId,
  };
  let mutationParams = "$premisesID: ID!";
  let mutation = "";

  whs.forEach((wh, index) => {
    mutationParams += `, $id${index}: ID!, $codigoAlmacen${index}: String!, $nombreAlmacen${index}: String!`;
    mutation += `,
      createBuildingLocation${index}: createBuildingLocation(input: {
        premisesID: $premisesID
        code: $codigoAlmacen${index},
        name: $nombreAlmacen${index},
      }){
        id
      },
      updateAdmAlmacenes${index}: updateAdmAlmacenes(input: {
        id: $id${index},
        status: SYNCHRONIZED
      }) {
        id
      },
      `;
    variables[`id${index}`] = wh.id;
    variables[`codigoAlmacen${index}`] = wh.codigoAlmacen;
    variables[`nombreAlmacen${index}`] = wh.nombreAlmacen;
  });

  // Eliminado de coma sobrante
  mutation = mutation.slice(0, -1);

  mutation = `mutation CopyBuildingZones(${mutationParams}) {
    ${mutation}
  }`;

  return { mutation, variables };
}

export default copyAdmAlmacenesToBuildingLocations;
