export const getTracking = /* GraphQL */ `
  query GetTracking($id: ID!) {
    getTracking(id: $id) {
      id
      ikaID
      status
      updatedAt
      createdAt
      assets {
        items {
          id
        }
      }
    }
  }
`;