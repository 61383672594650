export const createAcceptedDomain = /* GraphQL */ `
  mutation CreateAcceptedDomain($companyId: ID!, $domain: String!) {
    createAcceptedDomain(input: {
      emailDomain: $domain,
      acceptedDomainCompanyId: $companyId,
      status: ENABLED
    }) {
      id
      emailDomain
      createdAt
      updatedAt
      status
      company {
        id
        name
      }
      _version
      _deleted
    }
  }
`;