export const createIntegrationRequest = /* GraphQL */ `
  mutation CreateIntegrationRequest($input: CreateIntegrationRequestInput!) {
    createIntegrationRequest(input: $input) {
      id
      status
      email
      firstname
      middlename
      lastnamep
      lastnamem
      organization
      comments
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export default createIntegrationRequest;
