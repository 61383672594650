import { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";

import ActionTypes from "../redux/action";

function useNetworkStatus(callback = () => {}) {
  const networkStatus = useSelector(state => state.app.networkStatus);
  const dispatcher = useDispatch();
  const [status, setStatus] = useState({
    current: networkStatus,
    old: networkStatus
  });

  useEffect(() => {
    if (networkStatus !== status.current) {
      setStatus({
        current: networkStatus,
        old: status.current
      });
      callback(networkStatus, status.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [networkStatus]);

  function updateNetworkStatus(status = "OFFLINE") {
    dispatcher({
      type: ActionTypes.NETWORK_STATUS_CHANGE,
      payload: { networkStatus: status }
    });
  }

  return {
    networkStatus: status.current,
    oldNetworkStatus: status.old,
    updateNetworkStatus
  };
}

export default useNetworkStatus;
