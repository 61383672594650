export function checkNextItems(pageIndex, itemsLength, pageSize = 5) {
  const maxItems = (pageIndex + 1) * pageSize;
  return maxItems >= itemsLength;
}

export function checkLastPage(pageIndex, itemsLength, pageSize = 5) {
  const maxItems = (pageIndex + 1) * pageSize;
  return maxItems >= itemsLength && itemsLength % pageSize === 0;
}

export function filterDeletedItems(list) {
  return list.filter(({ _deleted, deleted }) => !Boolean(_deleted || deleted));
}

export function filterNullAssets(list = []) {
  return list.reduce((taskAssets, current) => {
    if (!!current.asset) {
      taskAssets.push({ ...current });
    } else {
      console.warn(`Relación TaskAsset (${current.id}) incompleta, no existe Asset asociado`);
    }
    return taskAssets;
  }, []);
}

/**
 * Función para filtrar elementos nulos en un arreglo.
 * @param {Array} list lista de elementos de elementos que se filtrará
 * @returns copia de la lista de elementos original sin los elementos nulos que hubiese contenido
 */
export function filterNull(list = []) {
  return list.filter((item) => !!item);
}

export function filterExcluded(list = []) {
  return list.filter(({ excluded }) => excluded === null);
}

export function filterDeletedOrDisabledItems(list) {
  return list.filter(({ _deleted, status }) => !Boolean(_deleted) && status === "ENABLED");
}

export function createSearchFilter(keys = [], searchTerm = "") {
  searchTerm = searchTerm.toLocaleLowerCase();
  return keys.reduce(
    (filter, key) => {
      const search = { match: `*${searchTerm}*` };
      filter.or.push({ [key]: search });
      return filter;
    },
    { or: [] }
  );
}
export function createWildcardSearchFilter(keys = [], searchTerm = "") {
  searchTerm = searchTerm.toLocaleLowerCase();
  return keys.reduce(
    (filter, key) => {
      const search = { wildcard: `*${searchTerm}*` };
      filter.or.push({ [key]: search });
      return filter;
    },
    { or: [] }
  );
}

export function excludeSystemItems(list) {
  return list.filter(({ owner }) => owner !== "system");
}

export function excludeItemById(list = [], id) {
  return list.filter((item) => item.id !== id);
}
