import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import { useSelector } from "react-redux";
import API, { graphqlOperation } from "@aws-amplify/api";
import PropTypes from "prop-types";
import Button from "../custom/Button";
import MaterialTable from "components/custom/MaterialTable";
import ConfirmationDialog from "components/ConfirmationDialog/ConfirmationDialog";
import { useActions } from "hooks/useActions";
import { getUsers, assignUsers, cleanSiteData } from "redux/dispatcher/siteOperations";
import * as queries from "graphql-custom/queries";
import { REQUEST_STATUS } from "constant/requestStatus";
import useBooleanFlag from "hooks/useBooleanFlag";

const TABLE_OPTIONS = { exportButton: false, columnsButton: false, selection: true };

async function getUserTeams(userID, nextToken = null) {
  const result = await API.graphql(
    graphqlOperation(queries.getUserTeam, {
      filter: {
        head: {
          eq: userID,
        },
      },
      nextToken,
    })
  );

  const { items, nextToken: token } = result.data.listTeams;

  if (token) {
    const nextItems = await getUserTeams(userID, token);
    return [...items, ...nextItems];
  }

  return [...items];
}

/**
 * @component NewTeamMember
 *
 * Componente que implementa el manejo centralizado de llamadas a GraphQL con Redux. Para conectarse al store de
 * Redux se usa el hook useSelector y para poder despachar acciones se utliza el hook personalizado useActions, el
 * cual recibe una función o un arreglo de funciones que retornan una acción, y devuelve las mismas funciones unidas
 * a Redux para que sean despachadas.
 */
function NewTeamMember({ onCancel, onUpdateTeam, usersWithTeam }) {
  const { users, fetchStatus, assignStatus } = useSelector(({ site }) => site);
  const userId = useSelector((store) => store.session.userId);
  const [teamId, setTeamId] = useState();
  const [fetchUsers, addUserToTeam, cleanSiteState] = useActions([getUsers, assignUsers, cleanSiteData]);
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [tableColums] = useState([
    { title: "Nombre", field: "fullName" },
    { title: "Correo", field: "email" },
    { title: "Fecha de ingreso", field: "createdAt" },
  ]);
  const [showConfirmation, openConfirmDialog, closeConfirmDialog] = useBooleanFlag();

  useEffect(() => {
    return () => {
      cleanSiteState();
    };
  }, []);

  useEffect(() => {
    fetchUsers();
    getUserTeam();
  }, [fetchUsers]);

  useEffect(() => {
    if (assignStatus === REQUEST_STATUS.SUCCESSFUL) {
      closeConfirmDialog();
      onUpdateTeam();
      onCancel();
    }
  }, [assignStatus]);

  async function getUserTeam() {
    return getUserTeams(userId).then((items) => {
      let [team] = items;
      setTeamId(team.id);
    });
  }

  const changeCandidateSelection = (rows) => {
    const newCandidateIds = rows.map(({ id }) => id);
    setSelectedCandidates(newCandidateIds);
  };

  const assignUsersToTeam = async () => {
    const memberIdParams = generateMemberArgs();
    addUserToTeam(selectedCandidates, memberIdParams);
  };

  const generateMemberArgs = () => {
    const memberArgs = selectedCandidates.reduce((memberArgs, id, index) => {
      const memberKey = `memberId${index}`;
      memberArgs[memberKey] = id;
      return memberArgs;
    }, {});
    return { teamId, ...memberArgs };
  };

  const confirmBtnDisabled = selectedCandidates?.length === 0;

  return (
    <>
      <Dialog open={true} maxWidth={false} fullWidth>
        <DialogTitle>Nuevo integrante de equipo</DialogTitle>
        <DialogContent dividers>
          <p>
            Ingrese los parámetros de búsqueda necesarios y presione el botón &quot;Buscar&quot; para consultar el
            listado de usuarios.
          </p>
          <MaterialTable
            columns={tableColums}
            data={users.filter(({ id }) => !usersWithTeam.includes(id))}
            isLoading={fetchStatus === REQUEST_STATUS.PENDING}
            options={TABLE_OPTIONS}
            onSelectionChange={changeCandidateSelection}
          />
        </DialogContent>
        <DialogActions>
          <Button color="default" onClick={onCancel}>
            CANCELAR
          </Button>
          <Button color="primary" disabled={confirmBtnDisabled} onClick={openConfirmDialog}>
            Agregar a equipo
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        open={showConfirmation}
        title="Confirmación"
        onConfirm={assignUsersToTeam}
        onCancel={closeConfirmDialog}
        loading={assignStatus === REQUEST_STATUS.PENDING}
      >
        ¿Deseas agregar a los miembros del equipo?
      </ConfirmationDialog>
    </>
  );
}

NewTeamMember.propTypes = {
  onCancel: PropTypes.func,
  onUpdateTeam: PropTypes.func,
  usersWithTeam: PropTypes.array.isRequired,
};

NewTeamMember.defaultProps = {
  onCancel: () => {},
  onUpdateTeam: () => {},
  usersWithTeam: [],
};

export default NewTeamMember;
