import React, { useState } from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import { Paper, Container, TextField, Stepper, Step, StepLabel, Grid, Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import useLoadingStatus from "hooks/useLoadingStatus";
import Section from "components/Section";
import useNotifier from "hooks/useNotifier";
import useHeaderTitle from "hooks/useHeaderTitle";
import { generateQrs } from "./helpers/graphql";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    justifyContent: "center",
  },
  container: {
    padding: theme.spacing(3),
    justifyContent: "center",
  },
  containerBottom: {
    padding: theme.spacing(3),
    justifyContent: "center",
  },
  paper: {
    padding: theme.spacing(5),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  button: {
    width: "100%",
  },
  header: {
    ...theme.typography.h5,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function QRCodeGeneratorView(props) {
  const classes = useStyles();
  useHeaderTitle("Generador QRs");
  const [quantity, setQuantity] = useState(1);
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const [loading, _generateQRs] = useLoadingStatus(fetchFileUrl);
  const notifier = useNotifier();

  async function fetchFileUrl() {
    try {
      const fileUrl = await generateQrs(quantity);
      setActiveStep(1);
      window.open(fileUrl);
    } catch (error) {
      console.error("generateQrs: error", error);
      notifier.showError("Ocurrió un error al generar el archivo. Contacte a su administrador.");
    }
  }

  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <Box mb={3}>
              <Paper className={classes.paper} elevation={3}>
                <Typography className={classes.header}>Ingrese el número de etiquetas a generar.</Typography>
                <Typography>Mínimo 1, Máximo 100</Typography>
                <br />
                <br />
                <br />
                <TextField
                  required
                  id="outlined-required"
                  label="Requerido"
                  defaultValue="1"
                  variant="outlined"
                  helperText="Número de etiquetas"
                  type="number"
                  InputProps={{
                    inputProps: {
                      max: 100,
                      min: 1,
                    },
                  }}
                  onChange={(event) => {
                    const { value } = event.target;
                    if (value <= 100 && value > 0) {
                      setButtonEnabled(false);
                    } else {
                      setButtonEnabled(true);
                    }
                    setQuantity(value);
                  }}
                />
              </Paper>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box mb={3}>
              <Section>
                <Stepper alternativeLabel activeStep={activeStep}>
                  <Step key={"generate"}>
                    <StepLabel>Ingresar el número de etiquetas a generar y dar clic en "Generar".</StepLabel>
                  </Step>
                  <Step key={"paste"}>
                    <StepLabel>Pegar la etiqueta al artículo.</StepLabel>
                  </Step>
                  <Step key={"associate"}>
                    <StepLabel>Asociar la información en sistema.</StepLabel>
                  </Step>
                </Stepper>
              </Section>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              disabled={buttonEnabled}
              onClick={_generateQRs}
            >
              Generar
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

QRCodeGeneratorView.propTypes = {};

QRCodeGeneratorView.defaultProps = {};

export default QRCodeGeneratorView;
