export const createMaterial = /* GraphQL */ `
  mutation CreateMaterial($name: String!, $code: String!, $manufacturer: String!, $description: String, $categoryId: ID!, $uomId: ID!) {
    createMaterial(
      input: {
        name: $name
        code: $code
        enabled: true
        trackable: true
        manufacturer: $manufacturer
        materialCategoryId: $categoryId
        materialUomId: $uomId
        description: $description
      }
    ) {
      id
      _version
      _deleted
    }
  }
`;
