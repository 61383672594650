import { SupportType, TaskStatus } from "models";
import { SUPPORT } from "constant/settings";
import {
  ATTACHMENT_LENGTHS,
  DEFAULT_EVIDENCES_BY_STATUS,
  DEFAULT_SUPPORT_ACTIVITY_STEPS_KEYNAME,
  SUPPORT_ACTIVITY_STEPS_MODE,
} from "constant/ticketActivity";
const STEPS_KEYNAME_PRESENTIAL = DEFAULT_SUPPORT_ACTIVITY_STEPS_KEYNAME();

export function getSupportTypeOptions(supportSettings) {
  let baseOptions = [{ label: "Presencial", value: SupportType.PRESENTIAL }];
  if (supportSettings?.remote_support?.value) {
    baseOptions.push({ label: "Remoto", value: SupportType.REMOTE });
  }

  baseOptions = baseOptions.map((option) => ({ ...option, data: option }));

  return baseOptions;
}

export function filterEvidencesByStatus(taskAttachments) {
  if (taskAttachments?.length) {
    return taskAttachments.reduce(
      (filtered, attachment) => {
        if (attachment.status === TaskStatus.SCHEDULED) {
          filtered.beginning.push({
            ...attachment.file,
            attachmentId: attachment.id,
          });
        } else if (attachment.status === TaskStatus.IN_PROGRESS) {
          filtered.inProgress.push({
            ...attachment.file,
            attachmentId: attachment.id,
          });
        } else if (attachment.status === TaskStatus.COMPLETED) {
          filtered.completed.push({
            ...attachment.file,
            attachmentId: attachment.id,
          });
        }
        return filtered;
      },
      { beginning: [], inProgress: [], completed: [] }
    );
  } else {
    return { beginning: [], inProgress: [], completed: [] };
  }
}

export function getEvidencesMinRules(supportSettings) {
  let evidencesMinRules = ATTACHMENT_LENGTHS;
  const supportRequiredEvidence = supportSettings[SUPPORT.KEYS.REQUIRED_EVIDENCE]?.value;

  if (Boolean(supportRequiredEvidence)) {
    // En caso de que exista un configuración almacenada en memoria de app se aplicarán los minimos que estén definidos
    evidencesMinRules = {
      MIN_START: supportRequiredEvidence.start,
      MIN_PROGRESS: supportRequiredEvidence.progress,
      MIN_END: supportRequiredEvidence.end,
    };
  }

  return evidencesMinRules;
}

export function getEvidencesMinRulesAndSupportTypes(supportSettings) {
  return {
    supportTypeOptions: getSupportTypeOptions(supportSettings),
    evidencesMinRules: getEvidencesMinRules(supportSettings),
  };
}

export function getSupportActivitySteps(stepsState = [], { mode = SUPPORT_ACTIVITY_STEPS_MODE.OBJECT } = {}) {
  if (!stepsState || !stepsState.length) {
    return [];
  }
  if (mode === SUPPORT_ACTIVITY_STEPS_MODE.OBJECT) {
    return stepsState.reduce(
      (activitySteps, stepState) => ({
        ...activitySteps,
        [stepState.stepKeyname]: stepState.step,
      }),
      {}
    );
  }

  if (mode === SUPPORT_ACTIVITY_STEPS_MODE.ARRAY) {
    return stepsState.map((stepState) => stepState.stepKeyname);
  }
}

export function isEvidenceStepValid(
  evidenceStep,
  evidencesByStatus = DEFAULT_EVIDENCES_BY_STATUS,
  evidencesMinRules = ATTACHMENT_LENGTHS
) {
  const MIN_START = evidencesMinRules?.MIN_START;
  const MIN_PROGRESS = evidencesMinRules?.MIN_PROGRESS;
  const MIN_END = evidencesMinRules?.MIN_END;

  // si no hay una configuración minima para el cliente definida, el paso es valido
  if (
    (!Boolean(MIN_START) && MIN_START !== 0) ||
    (!Boolean(MIN_PROGRESS) && MIN_PROGRESS !== 0 && !Boolean(MIN_END) && MIN_END !== 0)
  ) {
    return true;
  }

  if (evidenceStep === STEPS_KEYNAME_PRESENTIAL.INITIAL_EVIDENCE) {
    return evidencesByStatus.beginning.length >= MIN_START;
  }
  if (evidenceStep === STEPS_KEYNAME_PRESENTIAL.PROGRESS_EVIDENCE) {
    return evidencesByStatus.inProgress.length >= MIN_PROGRESS;
  }
  if (evidenceStep === STEPS_KEYNAME_PRESENTIAL.END_EVIDENCE) {
    return evidencesByStatus.completed.length >= MIN_END;
  }
}

/**
 *
 * @param {Array} remarks
 * @returns {Array} [Remarks]
 */
export function handleGetRemarks(remarks = []) {
  if (!Boolean(remarks)) {
    return [];
  }
  if (Array.isArray(remarks)) {
    return remarks;
  }
  if (remarks.hasOwnProperty("items") && Array.isArray(remarks.items)) {
    return remarks.items;
  }
  return [];
}
