/* eslint-disable jsx-a11y/alt-text */
// Componente para agregar evidencias por medio de un dropzone
import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { Logger } from "@aws-amplify/core";
import { useDropzone } from "react-dropzone";
import { Auth } from "@aws-amplify/auth";
import { Storage } from "@aws-amplify/storage";
import { useIndexedDB } from "react-indexed-db";

import ClearIcon from "@material-ui/icons/Clear";

import imageCompression from "browser-image-compression";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import AddIcon from "@material-ui/icons/Add";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import IconButton from "@material-ui/core/IconButton";

import useLoadingStatus from "hooks/useLoadingStatus";
import useOnlineStatus from "@rehooks/online-status";
import { uploadFile } from "util/file";
import useNotifier from "hooks/useNotifier";
import { fetchAttachmentById, loadTasksPrechargedFiles } from "datastore";
import {
  takePicture,
  CameraPermissionDeniedException,
  CameraCanceledException,
  GalleryAccessPermissionDeniedException,
  GaleryCanceledException,
} from "util/camera";
import { getCurrentPosition, GeolocationPermissionDeniedException } from "util/geolocation";
import { DataStore } from "@aws-amplify/datastore";
import { CameraSource } from "@capacitor/camera";
import { Attachment } from "models";
import { EVIDENCE_TYPES } from "constant/evidenceTypes";
import PhotoSourceDialog from "components/common/PhotoSourceDialog";
import useDeviceSettings from "hooks/useDeviceSettings";
import { captureException } from "setup/sentry";

const logger = new Logger("Evidence With DropZone");

/**
 * Genera URLs prefirmadas para las evidencias proporcionadas.
 *
 * @param {Array} attachments Arreglo de evidencias de un task
 */
async function signFileURLs(attachments = []) {
  if (attachments === null) attachments = [];
  return Promise.all(
    attachments.map(async (a) => {
      const signedURL = await Storage.get(a.file.key);
      return {
        ...a,
        source: signedURL,
      };
    })
  );
}

class StorageException extends Error {}

const LOADING_GIF = `${process.env.PUBLIC_URL}/img/upload-img-loader.gif`;
const region = process.env.REACT_APP_AWS_REGION;
const bucket = process.env.REACT_APP_AWS_USER_FILES_S3_BUCKET;

async function _takePicture(photoSource, userId = "") {
  let picture;
  let compressedPicture;
  let position;

  // Paso 1: Captura de imagen
  picture = await takePicture({ source: photoSource });
  const compressionOptions = {
    maxSizeMB: 0.2,
    maxWidthOrHeight: 1024,
    useWebWorker: true,
  };
  const preimage = await imageCompression.getFilefromDataUrl(picture.dataUrl, "image");

  // Paso 2: Compresión de imagen
  compressedPicture = await imageCompression(preimage, compressionOptions);
  console.log(`compressedImage size ${compressedPicture.size / 1024 / 1024} MB`); // smaller than maxSizeMB

  console.log("_takePicture: Obteniendo ubicación");
  // Paso 3: Obtención de coordenadas
  position = await getCurrentPosition();
  console.log("_takePicture: Ubicación obtenida");

  // Paso 4: Escribir datos en la imagen
  const texts = [
    userId,
    // Texto de coordenadas actuales
    `${position.coords.latitude}, ${position.coords.longitude}`, // Texto de fecha actual
    `${new Date().toLocaleDateString("es-MX", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      minute: "numeric",
      hour: "numeric",
    })}`,
  ];
  picture = await writeTextOnImage(compressedPicture, texts);

  return picture;
}

async function writeTextOnImage(image, texts) {
  return new Promise((resolve, reject) => {
    try {
      var canvas = document.createElement("canvas");
      const heightAtt = document.createAttribute("height");
      const widthAtt = document.createAttribute("width");

      var context = canvas.getContext("2d");
      var imageObj = new Image();
      imageObj.onload = function () {
        heightAtt.value = this.height;
        widthAtt.value = this.width;

        canvas.setAttributeNode(heightAtt);
        canvas.setAttributeNode(widthAtt);

        context.drawImage(this, 0, 0, this.width, this.height);
        context.font = "15px Arial";

        context.fillStyle = "aqua";
        context.strokeStyle = "black";

        const textPosition = {
          x: 20,
          y: 30,
        };
        let increment = 20;
        texts.forEach((text) => {
          context.fillText(text, textPosition.x, textPosition.y);
          textPosition.y += increment;
        });

        context.fill();
        context.stroke();

        resolve({ dataUrl: canvas.toDataURL() });
      };
      imageObj.src = URL.createObjectURL(image);
    } catch (error) {
      logger.error(error);
      captureException(error, "writeTextOnImage");
      reject(error);
    }
  });
}

async function toImageObject(imageData) {
  return toBlobObject(imageData.dataUrl)
    .then((blob) => {
      const name = `ain_${Date.now()}`;
      const ext = getExtension(blob.type);
      return {
        ...imageData,
        blob,
        ext,
        name,
        filename: `${name}${ext}`,
      };
    })
    .catch((error) => {
      throw error;
    });
}
async function toBlobObject(dataUrl) {
  return fetch(dataUrl).then((file) => file.blob());
}

/**
 * Reference https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Complete_list_of_MIME_types
 * @param {*} imageType
 */
function getExtension(imageType) {
  switch (imageType) {
    case "image/png":
      return ".png";
    case "image/bmp":
      return ".bmp";
    case "image/gif":
      return ".gif";
    case "image/jpeg":
      return ".jpg";
    case "image/jpg":
      return ".jpg";
    case "image/svg+xml":
      return ".svg";
    case "image/tiff":
      return ".tiff";
    case "image/webp":
      return ".webp";

    default:
      return ".jpg";
  }
}

// TODO agregar soporte min max
// TODO agregar zoom a las fotos
// TODO agregar soporte para elminar evidencias del componente

function EvidenceWithDropZone({ onLoading, min, max, taskId, status, isMobile, onNewFiles, type, initialAttachments }) {
  const classes = useStyles();
  const onlineStatus = useOnlineStatus();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const { showError, showMessage } = useNotifier();
  const imagesDb = useIndexedDB("offline_images");
  const { getAll, deleteRecord, add } = useIndexedDB("offline_images");

  const [files, setFiles] = useState([]);
  const [preloadFiles, setPreloadFiles] = useState([]);
  const [isUploading, uploadFileWrapped] = useLoadingStatus(uploadFile);
  const [isTakingPicture, takePictureWrapped] = useLoadingStatus(_takePicture);
  const [openPhotoSourceDialog, setOpenPhotoSourceDialog] = useState(false);
  const device = useDeviceSettings();

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/png,image/jpg,image/jpeg",
    onDrop: (acceptedFiles) => {
      const newFiles = acceptedFiles.map((file) => {
        return Object.assign(file, { preview: URL.createObjectURL(file) });
      });

      const _newFiles = newFiles.map(async (file, index) => {
        handleAddNewFile(file, index);
      });
    },
  });

  useEffect(() => {
    onNewFiles([...files, ...preloadFiles]);
  }, [files, preloadFiles]);

  useEffect(() => {
    loadPrechargedImages();
  }, []);

  useEffect(() => {
    return () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    };
  }, [files]);

  useEffect(() => {
    onLoading(isUploading);
  }, [isUploading]);

  /**
   * Función que busca si hay imagenes en estado intermedio para cargarlas a los archivos de este envio/despacho
   */
  async function loadPrechargedImages() {
    if (type === EVIDENCE_TYPES.TICKET) {
      let signedAttachments = await signFileURLs(initialAttachments);
      signedAttachments = signedAttachments.map((sa) => ({ ...sa, origin: "s3" }));
      setPreloadFiles(signedAttachments);
    } else {
      let _preloadFiles = [];
      const currentUser = await Auth.currentAuthenticatedUser();
      // primero buscamos en datastore
      const remoteFiles = await loadTasksPrechargedFiles(taskId, currentUser.username);
      const signedRemoteFiles = await signFileURLs(remoteFiles);
      _preloadFiles = signedRemoteFiles.map((f) => ({ ...f, origin: "s3" }));
      // buscamos en los archivos dbindex en busca de imagenes locales
      const offlineFiles = await getAll();
      logger.debug({ offlineFiles });
      _preloadFiles = _preloadFiles.concat(
        offlineFiles.filter((of) => of.taskID === taskId).map((of) => ({ ...of, origin: "indexDB" }))
      );
      // cargamos las imagenes al estado interno
      setPreloadFiles(_preloadFiles);
    }
  }

  function renderPreloadFiles() {
    // debugger;
    if (preloadFiles.length) {
      const pdfIcon = "/img/pdf_file_icon.png";
      return preloadFiles.map((file) => {
        if (file.origin === "s3") {
          const nameStartIndex = file.source.lastIndexOf("/") + 1;
          const name = file.source.substr(nameStartIndex);
          return (
            <GridListTile key={file.id} className={classes.evidenceGridTile}>
              <img src={file.source} alt="attachment" />
              <GridListTileBar
                title={name}
                actionIcon={
                  <IconButton className={classes.icon} onClick={() => handleOnDeleteFile(file.id)}>
                    <ClearIcon />
                  </IconButton>
                }
              />
            </GridListTile>
          );
        } else if (file.origin === "indexDB") {
          return (
            <GridListTile key={file.id} className={classes.evidenceGridTile}>
              <img
                src={file?.blob?.type === "application/pdf" ? pdfIcon : file?.blob?.thumbnail}
                alt={file?.blob?.name}
              />
              <GridListTileBar
                title={file?.blob?.name}
                actionIcon={
                  <IconButton className={classes.icon} onClick={() => handleOnDeleteFile(file.id)}>
                    <ClearIcon />
                  </IconButton>
                }
              />
            </GridListTile>
          );
        }
        return null;
      });
    }
    return null;
  }

  function renderFilePreviews() {
    if (files.length) {
      const pdfIcon = "/img/pdf_file_icon.png";

      return files.map((file) => (
        <GridListTile key={file.name} className={classes.evidenceGridTile}>
          <img src={file.type === "application/pdf" ? pdfIcon : file.thumbnail} alt={file.name} />
          <GridListTileBar
            title={file.name}
            actionIcon={
              <IconButton className={classes.icon} onClick={() => handleOnDeleteFile(file.id)}>
                <ClearIcon />
              </IconButton>
            }
          />
        </GridListTile>
      ));
    }
    return null;
  }

  async function handleCameraBtnClick(photoSource) {
    logger.debug("Captura de fotografia con camara");
    const currentUser = await Auth.currentAuthenticatedUser();
    let image;
    try {
      image = await takePictureWrapped(photoSource, currentUser.username);
      // Transform the data url to an object containing the blob data
      image = await toImageObject(image);
      logger.debug(image);
      handleAddNewFile(image, 0);
    } catch (error) {
      logger.error(error);
      if (error instanceof CameraCanceledException || error instanceof GaleryCanceledException) {
        logger.debug("Toma de fotografía cancelada por el usuario.");
      } else if (error instanceof CameraPermissionDeniedException) {
        showError("Uso de cámara no autorizado.");
      } else if (error instanceof GalleryAccessPermissionDeniedException) {
        showMessage("Acceso a la galería de fotos no autorizado.");
      } else if (error instanceof GeolocationPermissionDeniedException) {
        showError("Uso de geolocalización no autorizado");
      } else {
        showError("Ocurrió un error durante la carga de evidencia");
        captureException(error, "handleCameraBtnClick");
      }
      return;
    }
  }

  function handleOpenPhotoSourceDialog() {
    const deviceInfo = device.getInfo();
    if (deviceInfo.platform === "web") {
      return handleCameraBtnClick(CameraSource.Camera);
    } else {
      return setOpenPhotoSourceDialog(true);
    }
  }

  async function handleAddNewFile(file, index) {
    if (type !== EVIDENCE_TYPES.TICKET) {
      const pointIndex = file.name.lastIndexOf(".");
      const extension = file.ext || file.name.substr(pointIndex);
      const finalStatus = status === "SHIPMENT_SCHEDULED" ? "SHIPMENT_PREV_SENT" : "SHIPMENT_PREV_RECEIVED";
      const filename = `task/${taskId}/attachments/${dayjs().format("YYYYMMDDHHmmss")}_${index}${extension}`;
      logger.debug({ finalStatus });

      // Se crea un objeto attachment
      let attachment = new Attachment({
        taskID: taskId,
        status: finalStatus,
        file: {
          key: filename,
          bucket,
          region,
        },
      });

      try {
        // agregamos de forma optimista el file al arreglo de files
        setFiles((value) => [
          {
            id: attachment.id,
            loading: true,
            thumbnail: LOADING_GIF,
          },
          ...value,
        ]);
        // Se genera un file con lo necesario para agregarlo al arreglo interno de files

        logger.debug("Se intenta subir");

        let uploaded = false;
        let s3KeyFile = {};

        if (onlineStatus) {
          logger.debug("Se subira a S3");
          s3KeyFile = await uploadFileWrapped(filename, file?.blob || file);
          await DataStore.save(attachment);
          uploaded = true;
        } else {
          logger.debug("Se guardara de manera local en indexdb");
          // se guardará de manera local
          const attach = {
            id: attachment.id,
            taskID: taskId,
            status: finalStatus,
            file: {
              region,
              bucket,
              key: "",
            },
          };
          attach.blob = file?.blob || file;
          attach.filename = filename;
          await imagesDb.add(attach);
        }

        logger.debug({ s3KeyFile });

        // Replace the loading image for the actual image
        setFiles((value = []) => {
          const index = value.findIndex((a) => a.id === attachment.id);
          if (index === -1) return value;
          const copy = [...value];
          copy[index].blob = file?.blob || file;
          copy[index].filename = uploaded ? s3KeyFile : filename;
          copy[index].taskID = taskId;
          copy[index].uploaded = uploaded;
          copy[index].origin = uploaded ? "s3" : "indexDB";
          copy[index].file = {
            region,
            bucket,
            key: "",
          };
          copy[index].name = file.name;
          copy[index].s3KeyFile = s3KeyFile.key;
          copy[index].thumbnail = file.dataUrl || file.preview;
          copy[index].loading = false;
          copy[index].status = finalStatus;
          return copy;
        });
      } catch (error) {
        logger.error(error);
        // Remove the loading image
        setFiles((value = []) => {
          const index = value.findIndex((a) => a.id === attachment.id);
          if (index === -1) return value;
          const first = value.slice(0, index);
          const last = value.slice(index + 1);
          return [...first, ...last];
        });
        if (error instanceof StorageException) {
          showError("Error al cargar la imagen");
        } else {
          showError("Error inesperado al cargar la imagen");
        }
      }
    } else {
      // cuando es para ticket no se necesita precargado
      let imgPreviewId = Date.now();

      setFiles((value) => [
        {
          id: imgPreviewId,
          thumbnail: file.dataUrl || file.preview,
          name: file.filename || file.name,
          blob: file.blob || file,
        },
        ...value,
      ]);
    }
  }

  /**
   * Función para eliminar archivos no finales de la precarga
   * @param {*} fileId
   */
  async function handleOnDeleteFile(fileId) {
    if (type !== EVIDENCE_TYPES.TICKET) {
      logger.debug(`Se eliminará archivo con id ${fileId}`);
      // Se busca archivo en datastore
      const attachment = await fetchAttachmentById(fileId);
      logger.debug(attachment);

      // se borra de s3
      if (attachment) {
        if (onlineStatus) {
          Storage.remove(attachment?.file?.key)
            .then((result) => logger.debug(result))
            .catch((err) => logger.debug(err));
        } else {
          const attach = {
            id: attachment.id,
            taskID: taskId,
            pendingDelete: true,
            file: {
              ...attachment.file,
            },
          };
          add(attach)
            .then((result) => {
              logger.debug(result);
            })
            .catch((error) => {
              logger.error(error);
            });
        }
        await DataStore.delete(attachment);
      } else {
        // se busco archivo en la bdlocal
        await deleteRecord(fileId);
      }

      //TODO revisar flujo donde se va el internet suben una foto offline, regresa el internet y sin recargar la pantalla, borran el archivo previamente dado de alta(no se borra en s3) condición de carrera
    }
    // else {
    //   // en ticket se borran del estado actual
    //   // se elimina archivo de alguno de los estados del componente
    //   setFiles((value = []) => {
    //     const index = value.findIndex((a) => a.id === fileId);
    //     if (index === -1) return value;
    //     const first = value.slice(0, index);
    //     const last = value.slice(index + 1);
    //     return [...first, ...last];
    //   });
    // }
    // se elimina archivo de alguno de los estados del componente
    setFiles((value = []) => {
      const index = value.findIndex((a) => a.id === fileId);
      if (index === -1) return value;
      const first = value.slice(0, index);
      const last = value.slice(index + 1);
      return [...first, ...last];
    });

    setPreloadFiles((value = []) => {
      const index = value.findIndex((a) => a.id === fileId);
      if (index === -1) return value;
      const first = value.slice(0, index);
      const last = value.slice(index + 1);
      return [...first, ...last];
    });

    // se muestra mensaje de confirmación
    showMessage("Se ha eliminado el archivo");
  }

  return (
    <div>
      <Grid item xs={12} className={classes.evidenceGrid}>
        <GridList className={classes.evidenceGridList}>
          {isMobile && matches && (
            <GridListTile className={classes.evidenceGridTile} onClick={handleOpenPhotoSourceDialog}>
              <div className={classes.evidencePicker}>
                <CameraAltIcon />
              </div>
            </GridListTile>
          )}

          {device.getInfo().platform === "web" && (
            <GridListTile className={classes.evidenceGridTile}>
              <div {...getRootProps({ className: classes.evidencePicker })}>
                <input {...getInputProps()} />
                <AddIcon />
              </div>
            </GridListTile>
          )}

          {renderFilePreviews()}
          {renderPreloadFiles()}
        </GridList>
      </Grid>

      <PhotoSourceDialog
        open={openPhotoSourceDialog}
        onClose={() => setOpenPhotoSourceDialog(false)}
        onSelectSource={handleCameraBtnClick}
      />
    </div>
  );
}

EvidenceWithDropZone.propTypes = {
  onNewFiles: PropTypes.func,
  onLoading: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  taskId: PropTypes.string,
  isMobile: PropTypes.bool,
  status: PropTypes.string,
  type: PropTypes.string,
  initialAttachments: PropTypes.array,
};

EvidenceWithDropZone.defaultProps = {
  onNewFiles: () => {},
  onLoading: () => {},
  min: 1,
  max: 10,
  initialAttachments: [],
};

export default EvidenceWithDropZone;

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  evidenceGrid: {
    overflowX: "visible",
  },
  evidenceGridList: {
    height: "135px !important",
    flexWrap: "nowrap",
    transform: "translateZ(0)",
  },
  evidenceGridTile: {
    width: "100px !important",
    height: "100% !important",
    marginRight: "6px",
  },
  evidencePicker: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    borderRadius: 4,
    borderWidth: 1,
    borderStyle: "dashed",
    borderColor: theme.palette.grey[500],
  },
  icon: {
    color: "rgba(255, 255, 255, 0.54)",
  },
}));
