/* eslint-disable react/display-name */
//  Componente para mostrar los envios de los activos
import React, { useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import Auth from "@aws-amplify/auth";
import dayjs from "dayjs";
import AddIcon from "@material-ui/icons/Add";
import SyncIcon from "@material-ui/icons/Sync";
import PostAddIcon from "@material-ui/icons/PostAdd";
import Fab from "@material-ui/core/Fab";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import makeStyles from "@material-ui/styles/makeStyles";
import MaterialTable from "components/custom/MaterialTable";
import Section from "components/Section";
import useHeaderTitle from "hooks/useHeaderTitle";
import useLoadingStatus from "hooks/useLoadingStatus";
import DeliveryScheduleDialog from "components/DeliveryScheduleDialog";
import { checkNextItems } from "util/lists";
import cleanUUID from "util/cleanUUID";
import * as ROLES from "constant/roles";
import { fetchWarehouseKeepersTasks, fetchShipmments } from "datastore";

const SECTION_TITLE = "Envíos";
export default function DeliveriesListView({ type }) {
  useHeaderTitle(SECTION_TITLE);
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const [assetIncomeList, setAssetIncomeList] = useState([]);
  const [paginationToken, setPaginationToken] = useState(null);
  const [isRequestError, setIsRequestError] = useState(false);
  const [showScheduleDialog, setShowScheduleDialog] = useState(false);
  const [loading, _fetchAssetIncomeList] = useLoadingStatus(fetchAssetIncomeList);

  useEffect(() => {
    _fetchAssetIncomeList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchAssetIncomeList(nextToken) {
    if (type === ROLES.WAREHOUSE_KEEPERS) {
      try {
        const taskList = await fetchWarehouseKeepersTasks();
        setAssetIncomeList(taskList);
      } catch (error) {
        console.log(error);
        setIsRequestError(true);
      }
    } else if (type === ROLES.SUPERVISORS) {
      try {
        const {
          attributes: { sub },
        } = await Auth.currentAuthenticatedUser();
        const taskList = await fetchShipmments(sub);
        setAssetIncomeList(taskList);
      } catch (error) {
        console.log(error);
        setIsRequestError(true);
      }
    }
  }

  function addNewDelivery(task) {
    setAssetIncomeList([...assetIncomeList, task]);
  }

  const loadMoreGoodsReceipts = async (page) => {
    const hasMoreItems = checkNextItems(page, assetIncomeList.length);
    if (hasMoreItems && paginationToken) {
      const { items, nextToken } = await _fetchAssetIncomeList(paginationToken);
      setAssetIncomeList(items);
      setPaginationToken(nextToken);
    }
  };

  const goToAssetIncomeDetails = (event, row) => {
    if (type === ROLES.WAREHOUSE_KEEPERS) {
      history.push(`/almacen/envio/${row.id}`);
    } else if (type === ROLES.SUPERVISORS) {
      history.push(`/validacion/envio/${row.id}`);
    } else if (type === "support-engineer ") {
      history.push(`/site/envio/${row.id}`);
    }
  };

  function handleScheduleBtnClick() {
    setShowScheduleDialog(true);
  }

  const tableTitle = type === "supervisors" ? "Recepciones de envíos" : "Envíos de activos";

  return (
    <>
      <Section>
        {isRequestError && (
          <Box p={2}>
            <Alert severity="error">Ha ocurrido un error con la carga del catálogo, recarga la página por favor.</Alert>
          </Box>
        )}
        <div className={classes.root}>
          <MaterialTable
            title={tableTitle}
            options={TABLE_OPTIONS}
            columns={!matches ? TABLE_COLUMNS : TABLE_COLUMNS_MOBILE}
            data={assetIncomeList}
            isLoading={loading}
            onRowClick={goToAssetIncomeDetails}
            onChangePage={loadMoreGoodsReceipts}
          />
        </div>
      </Section>
      <DeliveryScheduleDialog
        open={showScheduleDialog}
        onClose={() => setShowScheduleDialog(false)}
        onScheduleDelivery={addNewDelivery}
      />
      {type === ROLES.WAREHOUSE_KEEPERS && (
        <Box position="fixed" zIndex="modal" bottom={15} right={15}>
          <Fab color="primary" onClick={handleScheduleBtnClick}>
            <AddIcon />
          </Fab>
        </Box>
      )}
    </>
  );
}

DeliveriesListView.propTypes = {
  type: PropTypes.oneOf([ROLES.SUPERVISORS, ROLES.WAREHOUSE_KEEPERS]),
};

const TABLE_STATUSES = {
  SHIPMENT_SCHEDULED: "Programado",
  SHIPMENT_SENT: "Envíado",
  SHIPMENT_PREV_SENT: "En curso de envío",
  SHIPMENT_RECEIVED: "Recibido",
  SHIPMENT_PREV_RECEIVED: "En recepción",
};

const TABLE_OPTIONS = {
  exportButton: false,
  columnsButton: false,
  filtering: true,
};

const TABLE_COLUMNS = [
  {
    title: "Folio",
    field: "id",
    filtering: false,
    render: (rowData) => cleanUUID(rowData.id),
  },
  {
    title: "Fecha",
    field: "startDate",
    filtering: false,
    render: (rowData) => `${dayjs(rowData?.startDate).format("DD/MM/YYYY")}`,
  },
  {
    title: "Estado",
    field: "status",
    lookup: TABLE_STATUSES,
  },
  {
    title: "Destino",
    field: "buildingLocation",
    filtering: false,
    render: (rowData) => {
      if (rowData.premises) {
        return `${rowData?.premises?.name}`;
      } else if (rowData.buildingLocation) {
        return `${rowData?.buildingLocation?.premises?.name}`;
      } else {
        return "Sin información";
      }
    },
  },
  {
    title: "Proyecto",
    field: "project.name",
    filtering: false,
    render: (rowData) => (rowData.project ? `${rowData?.project?.name} (${rowData?.project?.code})` : "Sin asignación"),
  },
  {
    title: "Programado por",
    field: "user",
    filtering: false,
    render: (rowData) =>
      `${rowData?.supervisor?.name} ${rowData?.supervisor?.lastnameP} (${rowData?.supervisor?.email})`,
  },
  {
    title: "Destinatario",
    field: "supervisor",
    filtering: false,
    render: (rowData) => `${rowData?.user?.name} ${rowData?.user?.lastnameP} (${rowData?.user?.email})`,
  },
];

const TABLE_COLUMNS_MOBILE = [
  {
    title: "",
    field: "id",
    hidden: true,
    searchable: true,
  },
  {
    title: "",
    field: "createdAt",
    hidden: true,
    searchable: true,
  },
  {
    title: "",
    field: "buildingLocation",
    hidden: true,
    searchable: true,
  },
  {
    title: "",
    field: "user",
    hidden: true,
    searchable: true,
  },
  {
    title: "",
    field: "supervisor",
    hidden: true,
    searchable: true,
  },
  {
    title: "",
    field: "status",
    lookup: TABLE_STATUSES,
    render: (rowData) => {
      return (
        <Grid container spacing={2}>
          <Grid item sm={12} xs={12}>
            <strong>Folio: </strong>
            {cleanUUID(rowData.id)}
          </Grid>
          <Grid item sm={12} xs={12}>
            <strong>Fecha: </strong>
            {dayjs(rowData?.startDate).format("DD/MM/YYYY")}
          </Grid>
          <Grid item sm={6} xs={6}>
            <strong>Destino: </strong>
            {`${rowData?.buildingLocation?.name} ${rowData?.buildingLocation?.buildingBlock} ${rowData?.buildingLocation?.floor} ${rowData?.buildingLocation?.area}`}
          </Grid>
          <Grid item sm={6} xs={6}>
            <strong>Estado: </strong>
            {`${TABLE_STATUSES[rowData?.status] || ""}`}
          </Grid>
          <Grid item sm={12} xs={12}>
            <strong>Proyecto: </strong>
            {rowData.project ? `${rowData?.project?.name} (${rowData?.project?.code})` : "Sin asignación"}
          </Grid>
          <Grid item sm={12} xs={12}>
            <strong>Programado por: </strong>
            {`${rowData?.supervisor?.name} ${rowData?.supervisor?.lastnameP} (${rowData?.supervisor?.email})`}
          </Grid>
          <Grid item sm={12} xs={12}>
            <strong>Destinatario: </strong>
            {`${rowData?.user?.name} ${rowData?.user?.lastnameP} (${rowData?.user?.email})`}
          </Grid>
        </Grid>
      );
    },
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      "& .MuiTableHead-root": {
        display: "none",
      },
      "& .MuiToolbar-root": {
        flexDirection: "column",
        padding: theme.spacing(2),
      },
      "& div[class*='MTableToolbar-title-']": {
        order: 1,
        textAlign: "left",
        width: "100%",
      },
      "& div[class*='MTableToolbar-searchField-']": {
        width: "100%",
        order: 0,
        marginBottom: theme.spacing(2),
      },
    },
  },
}));
