export default function removeProps(arrayToMutate, { propsToDelete = [] } = {}) {
  // console.log({ arrayToMutate });
  return arrayToMutate?.map((data) => {
    let dataCopy = { ...data };

    propsToDelete.forEach((prop) => {
      if (dataCopy?.hasOwnProperty(prop)) delete dataCopy[prop];
    });
    return { ...dataCopy };
  });
}
