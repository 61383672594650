import * as queries from "graphql-custom/queries";
import useGraphQL from "hooks/useGraphQL";
import { DocumentPDFInput, ElementByType } from "./types";

export default function useGraphQLHelpers() {
  const { loading, runGraphQLOperation } = useGraphQL();

  function fetchSupportDocument({ elementBy, elementId, documentType }: DocumentPDFInput) {
    let inputObject: any = {
      documentType,
    };
    if (elementBy === ElementByType.task) {
      inputObject = { ...inputObject, taskId: elementId };
    } else if (elementBy === ElementByType.ticket) {
      inputObject = { ...inputObject, ticketId: elementId, taskId: null };
    }

    return runGraphQLOperation({
      operation: queries.lambdaDownloadTaskDocument,
      variables: {
        input: {
          ...inputObject,
        },
      },
      notifications: { errorMsg: "Error al descargar la bitácora, por favor recarge la página" },
    }).then((response) => {
      return response.lambdaDownloadTaskDocument.document;
    });
  }

  return { fetchSupportDocument, loading };
}
