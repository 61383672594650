import { useCallback, useMemo } from "react";
import { Logger } from "@aws-amplify/core";
import { useIndexedDB } from "react-indexed-db";
import useOnlineStatus from "@rehooks/online-status";
import useLoadingStatus from "./useLoadingStatus";
import useNotifier from "./useNotifier";

import getS3EvidencesFiles from "util/getS3EvidencesFiles";
const logger = new Logger("useStoreTaskEvidences");

const useStoreTaskEvidences = () => {
  const taskEvidencesIDB = useIndexedDB("task_images");
  const offlineEvidences = useIndexedDB("offline_images");
  const { showMessage, showError } = useNotifier();

  const isOnline = useOnlineStatus();
  const isOffline = !isOnline;

  const showSuccessMsg = useCallback(
    (successMsg) => {
      if (successMsg?.length) {
        showMessage(successMsg);
      }
    },
    [showMessage]
  );

  const showErrorMsg = useCallback(
    (errorMsg) => {
      if (errorMsg?.length) {
        showError(errorMsg);
      }
    },
    [showError]
  );

  const getAllOfflineTaskEvidences = useCallback(
    async (taskId, config = configPropTypes) => {
      const { successMsg, errorMsg, showSuccessLog } = config;
      try {
        if (!taskId) throw new Error("Información invalida");
        const allOfflineEvidences = await offlineEvidences.getAll();
        const allOfflineTaskEvidence = allOfflineEvidences.reduce((allOfflineTaskEvidence, offlineEvidence) => {
          const isPendingToDelete = offlineEvidence.hasOwnProperty("pendingDelete") && offlineEvidence.pendingDelete;
          const isFromThisTask = offlineEvidence.taskId === taskId;

          if (!isPendingToDelete && isFromThisTask) {
            const { blob, taskAssetID, type, ...evidenceData } = offlineEvidence;

            const evidenceOfflineData = {
              ...evidenceData,
              taskAssetId: taskAssetID,
              awsSource: offlineEvidence.awsSource || null,
              sourceBlob: blob || null,
              uploaded: false,
            };
            allOfflineTaskEvidence.push(evidenceOfflineData);
          }

          return allOfflineTaskEvidence;
        }, []);
        if (showSuccessLog) logger.debug("Evidencias offline en indexedDB de este task", allOfflineEvidences);
        showSuccessMsg(successMsg);
        return allOfflineTaskEvidence;
      } catch (error) {
        logger.error("getAllOfflineTaskEvidences", error);
        showErrorMsg(errorMsg);
      }
    },
    [offlineEvidences, showSuccessMsg, showErrorMsg]
  );

  const getAllTaskEvidences = useCallback(
    async (taskId, config = configPropTypes) => {
      const { successMsg, errorMsg, showSuccessLog } = config;
      try {
        if (!taskId) throw new Error("Información invalida");
        const IDBEvidences = await taskEvidencesIDB.getAll();
        const IDBTaskEvidences = IDBEvidences.filter((evidence) => evidence.taskId === taskId);
        if (showSuccessLog) logger.debug("Evidencias de este task en indexedDB", IDBTaskEvidences);
        showSuccessMsg(successMsg);
        return IDBTaskEvidences;
      } catch (error) {
        logger.error("getAllTaskEvidences", error);
        showErrorMsg(errorMsg);
      }
    },
    [taskEvidencesIDB, showSuccessMsg, showErrorMsg]
  );

  const storeOneEvidenceInIDB = useCallback(
    (storeInput = null, config = configPropTypes) => {
      const { successMsg, errorMsg, showSuccessLog } = config;
      try {
        const validatedInput = validateStoreOneEvidenceInput(storeInput);
        return taskEvidencesIDB.add(validatedInput).then((data) => {
          if (showSuccessLog) logger.debug("Evidencia añadida exitosamente", { data: storeInput, evidenceId: data });
          showSuccessMsg(successMsg);
          return data;
        });
      } catch (error) {
        logger.error("storeOneEvidenceInIDB", { storeInput, error });
        showErrorMsg(errorMsg);
      }
    },
    [taskEvidencesIDB, showSuccessMsg, showErrorMsg]
  );

  const storeEvidencesInIDB = useCallback(
    async (evidencesToAdd = [], taskId, config = configPropTypes) => {
      const { successMsg, errorMsg, showSuccessLog } = config;
      try {
        logger.debug("===Evidences por añadir a indexedDB===", evidencesToAdd.length);
        const evidencesAdded = await Promise.all(
          evidencesToAdd.map(async (evidence) => {
            const storeInput = validateStoreOneEvidenceInput({ taskId, ...evidence });
            return storeOneEvidenceInIDB(storeInput);
          })
        );
        if (showSuccessLog)
          logger.debug("storeEvidencesInIDB: Evidences añadidas a indexedDB exitosamente", evidencesAdded);
        showSuccessMsg(successMsg);
        return evidencesAdded;
      } catch (error) {
        logger.error("storeEvidencesInIDB", error);
        showErrorMsg(errorMsg);
      }
    },
    [storeOneEvidenceInIDB, showSuccessMsg, showErrorMsg]
  );

  const removeOneEvidenceInIDB = useCallback(
    async (evidenceId = null, config = configPropTypes) => {
      const { successMsg, errorMsg, showSuccessLog } = config;
      try {
        if (!evidenceId) {
          throw new Error("Error en removeOneEvidenceInIDB: Información invalida.");
        }
        return taskEvidencesIDB.deleteRecord(evidenceId).then((data) => {
          if (showSuccessLog) logger.debug("Evidencia eliminada", { evidenceId, data });
          showSuccessMsg(successMsg);
          return { evidenceId, data };
        });
      } catch (error) {
        logger.error("removeOneEvidenceInIDB", { evidenceId, error });
        showErrorMsg(errorMsg);
      }
    },
    [taskEvidencesIDB, showSuccessMsg, showErrorMsg]
  );

  const removeEvidencesInIDB = useCallback(
    async (imagesToDeleteIds = [], config = configPropTypes) => {
      const { successMsg, errorMsg, showSuccessLog } = config;
      logger.log("===Evidences por eliminar de indexedDB===", imagesToDeleteIds.length);
      try {
        const imagesDeleted = await Promise.all(
          imagesToDeleteIds.map(async (imageId) => removeOneEvidenceInIDB(imageId))
        );
        if (showSuccessLog)
          logger.debug("removeEvidencesInIDB: Evidences eliminadas en indexedDB exitosamente", imagesDeleted);
        showSuccessMsg(successMsg);
        return imagesDeleted;
      } catch (error) {
        logger.error("removeEvidencesInIDB", error);
        showErrorMsg(errorMsg);
      }
    },
    [removeOneEvidenceInIDB, showSuccessMsg, showErrorMsg]
  );

  const updateEvidenceInIDB = useCallback(
    async (updateInput = null, config = configPropTypes) => {
      const { successMsg, errorMsg, showSuccessLog } = config;
      try {
        const validatedInput = validateStoreOneEvidenceInput(updateInput);
        const evidenceId = updateInput.id;
        return taskEvidencesIDB.update(validatedInput).then((idbEvent) => {
          if (showSuccessLog)
            logger.debug("Evidencia actualizada exitosamente", { evidenceId, newData: updateInput, idbEvent });
          showSuccessMsg(successMsg);
          return { evidenceId, newData: updateInput };
        });
      } catch (error) {
        logger.error("updateEvidenceInIDB", error);
        showErrorMsg(errorMsg);
      }
    },
    [taskEvidencesIDB, showSuccessMsg, showErrorMsg]
  );

  const isStoringAvailable = useCallback(
    (allTaskEvidences, imagesToAdd) => {
      let logsReasons = [];
      // si la conexión a internet es lenta (2g), no se realiza el almacenamiento de evidencias
      const connectionSpeedType = navigator.connection?.effectiveType;
      const isConnectionSlow =
        !Boolean(connectionSpeedType) ||
        connectionSpeedType === "slow-2g" ||
        connectionSpeedType === "2g" ||
        typeof connectionSpeedType === undefined;
      const noTaskEvidences = !allTaskEvidences || !Boolean(allTaskEvidences?.length);
      const noEvidencesToAdd = !imagesToAdd || !Boolean(imagesToAdd?.length);
      if (isConnectionSlow) logsReasons.push(DISABLED_STORE_LOG_REASONS.CONNECTION_SLOW);
      if (isOffline) logsReasons.push(DISABLED_STORE_LOG_REASONS.OFFLINE);
      if (noTaskEvidences) logsReasons.push(DISABLED_STORE_LOG_REASONS.NO_TASK_EVIDENCES);
      if (noEvidencesToAdd) logsReasons.push(DISABLED_STORE_LOG_REASONS.NO_EVIDENCES_TO_ADD);
      const isAvailable = !Boolean(noTaskEvidences || isOffline || isConnectionSlow || noEvidencesToAdd);
      if (!isAvailable) {
        logger.debug("===No se realiza la adición de evidencias a indexedDB===", {
          reasons: logsReasons,
        });
      }
      return isAvailable;
    },
    [isOffline]
  );

  const storeTaskEvidences = useCallback(
    async (allTaskEvidences = [], taskId = null, config = storeTaskEvidencesProps) => {
      const { successMsg, errorMsg, showSuccessLog, limit } = config;
      try {
        if (!taskId) throw new Error("Información invalida");

        let evidencesToAdd;
        const taskEvidencesIDB = await getAllTaskEvidences(taskId);
        const offlineTaskEvidencesIDB = await getAllOfflineTaskEvidences(taskId);
        const alltaskEvidencesIDB = [...taskEvidencesIDB, ...offlineTaskEvidencesIDB];
        const allTaskEvidencesIDBIds = alltaskEvidencesIDB.map((idbImage) => idbImage.id);
        const imagesToDeleteIds = getImagesToDeleteFromIDB(allTaskEvidences, allTaskEvidencesIDBIds);
        if (imagesToDeleteIds && Boolean(imagesToDeleteIds?.length)) {
          await removeEvidencesInIDB(imagesToDeleteIds, { showSuccessLog: true });
        }

        evidencesToAdd = getImagesToAddToIBD(allTaskEvidences, alltaskEvidencesIDB, { limit });
        const enableStoring = isStoringAvailable(allTaskEvidences, evidencesToAdd);
        if (!enableStoring) return null;

        const evidenceS3Signed = await getS3EvidencesFiles(evidencesToAdd, { withAwsUrl: false, download: true });
        const evidencesAdded = await storeEvidencesInIDB(evidenceS3Signed, taskId);
        showSuccessMsg(successMsg);
        if (showSuccessLog) logger.debug("storeTaskEvidences: Evidencias añadidas con exito");
        return evidencesAdded;
      } catch (error) {
        logger.error("storeTaskEvidences", { error, allTaskEvidences, taskId });
        showErrorMsg(errorMsg);
      }
    },
    [
      isStoringAvailable,
      storeEvidencesInIDB,
      removeEvidencesInIDB,
      getAllTaskEvidences,
      getAllOfflineTaskEvidences,
      showSuccessMsg,
      showErrorMsg,
    ]
  );

  function cleanUpTaskEvidences(evidences) {
    if (!evidences || !evidences?.length) {
      logger.debug("cleanUpTaskEvidences: No hay evidencias por limpiar", evidences);
      return evidences;
    }

    logger.debug("Revocando objeto de URL de las siguientes evidencias", evidences);
    evidences.forEach((evidence) => {
      if (evidence.urlObjectSource && evidence.urlObjectSource?.includes("blob")) {
        URL.revokeObjectURL(evidence.urlObjectSource);
      } else {
        logger.debug("cleanUpTaskEvidences: La evidencia no cuenta con un urlObjectSource valido", evidence);
      }
    });
  }

  const [globalStoring, _storeTaskEvidences] = useLoadingStatus(storeTaskEvidences);
  const [fetchingAll, _getAllTaskEvidences] = useLoadingStatus(getAllTaskEvidences);
  const [fetchingAllOffline, _getAllOfflineTaskEvidences] = useLoadingStatus(getAllOfflineTaskEvidences);
  const [storingMultiple, _storeEvidencesInIDB] = useLoadingStatus(storeEvidencesInIDB);
  const [removingMultiple, _removeEvidencesInIDB] = useLoadingStatus(removeEvidencesInIDB);
  const [storingOne, _storeOneEvidenceInIDB] = useLoadingStatus(storeOneEvidenceInIDB);
  const [removeOne, _removeOneEvidenceInIDB] = useLoadingStatus(removeOneEvidenceInIDB);
  const [updating, _updateEvidenceInIDB] = useLoadingStatus(updateEvidenceInIDB);

  const loading =
    globalStoring ||
    fetchingAll ||
    storingMultiple ||
    removingMultiple ||
    updating ||
    storingOne ||
    removeOne ||
    fetchingAllOffline;

  return useMemo(() => {
    return {
      storeTaskEvidences: _storeTaskEvidences,
      taskEvidencesIDB,
      loading,
      methods: {
        getAllTaskEvidences: _getAllTaskEvidences,
        getAllOfflineTaskEvidences: _getAllOfflineTaskEvidences,
        storeEvidencesInIDB: _storeEvidencesInIDB,
        removeEvidencesInIDB: _removeEvidencesInIDB,
        updateEvidenceInIDB: _updateEvidenceInIDB,
        storeOneEvidenceInIDB: _storeOneEvidenceInIDB,
        removeOneEvidenceInIDB: _removeOneEvidenceInIDB,
        isStoringAvailable,
        cleanUpTaskEvidences,
      },
    };
  }, [
    _storeTaskEvidences,
    _storeEvidencesInIDB,
    _removeEvidencesInIDB,
    _updateEvidenceInIDB,
    _getAllTaskEvidences,
    _getAllOfflineTaskEvidences,
    _storeOneEvidenceInIDB,
    _removeOneEvidenceInIDB,
    isStoringAvailable,
    loading,
    taskEvidencesIDB,
  ]);
};

export default useStoreTaskEvidences;

const notificationsType = { successMsg: "", errorMsg: "" };

const configPropTypes = {
  ...notificationsType,
  showSuccessLog: false,
};

const storeTaskEvidencesProps = {
  ...configPropTypes,
  limit: null,
};

const DISABLED_STORE_LOG_REASONS = {
  CONNECTION_SLOW: "===La conexión a internet es lenta===",
  OFFLINE: "===No hay conexión a internet===",
  NO_TASK_EVIDENCES: "===El mantenimiento no cuenta con evidencias===",
  NO_EVIDENCES_TO_ADD: "===No hay evidencias para añadir a IndexedDB===",
};

function getImagesToAddToIBD(allTaskEvidences = [], allTaskEvidencesInIDB, { limit = null } = {}) {
  let imagesToAdd;
  const evidencesToEvaluate = limit ? allTaskEvidences.slice(0, limit) : allTaskEvidences;
  if (limit) logger.debug("===Limite de evidencias por añadir a indexedDB===", limit);
  //obtener las imagenes que se van a añadir a IDB
  if (!allTaskEvidencesInIDB || !Boolean(allTaskEvidencesInIDB?.length)) {
    imagesToAdd = evidencesToEvaluate;
  } else {
    imagesToAdd = evidencesToEvaluate.reduce((imagesToAdd, attachment) => {
      const shouldBeAdded = !allTaskEvidencesInIDB.some((IDBImage) => attachment.id === IDBImage.id);
      if (shouldBeAdded) imagesToAdd.push(attachment);
      return imagesToAdd;
    }, []);
  }
  return imagesToAdd;
}

function getImagesToDeleteFromIDB(allTaskEvidences, allTaskEvidencesInIDBIds) {
  let imagesToDelete;
  if (!allTaskEvidences || !Boolean(allTaskEvidences?.length)) {
    imagesToDelete = allTaskEvidencesInIDBIds;
  }

  imagesToDelete = allTaskEvidencesInIDBIds.reduce((imagesToDeleted, IDBEvidenceId) => {
    const shouldBeDeleted = !allTaskEvidences.some((attachment) => attachment.id === IDBEvidenceId);
    if (shouldBeDeleted) imagesToDeleted.push(IDBEvidenceId);
    return imagesToDeleted;
  }, []);
  return imagesToDelete;
}

function validateStoreOneEvidenceInput(storeInput) {
  const error = new Error("Error storeOneEvidenceInIDB: Información invalida.");
  if (!storeInput) throw error;
  const validId = Boolean(storeInput.id) && typeof storeInput.id === "string";
  const validTaskId = Boolean(storeInput.taskId) && typeof storeInput.taskId === "string";
  const validTaskAssetId = Boolean(storeInput.taskAssetId) && typeof storeInput.taskAssetId === "string";
  const validSourceBlob = storeInput.hasOwnProperty("sourceBlob");
  const validAwsSource = storeInput.hasOwnProperty("awsSource");
  if (!validId || !validTaskId || !validTaskAssetId || !validSourceBlob || !validAwsSource) throw error;

  return {
    id: storeInput.id,
    taskId: storeInput.taskId,
    taskAssetId: storeInput.taskAssetId,
    awsSource: storeInput.awsSource,
    sourceBlob: storeInput.sourceBlob,
  };
}
