import React from "react";
import PropTypes from "prop-types";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import DataLabel from "components/DataLabel";

function ExternalDataSection(props) {
  // No existe información para mostrar
  if (!props.data || !(props.data.length > 0)) return null;

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="subtitle2" paragraph>
          Información Externa
        </Typography>
        <Grid container spacing={2}>
          {props.data.map((item, index) => (
            <Grid key={`ext-data-${index}`} item xs={12} md={4}>
              <DataLabel label={item.label} data={item.value} />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
}

ExternalDataSection.defaultProps = {
  data: [],
};

ExternalDataSection.propTypes = {
  data: PropTypes.arrayOf({
    key: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
  }),
};

export default ExternalDataSection;
