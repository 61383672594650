import React from "react";
import PropTypes from "prop-types";
import { Box, Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { CameraSource } from "@capacitor/camera";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PhotoSourceDialog({ open, onClose, onSelectSource }) {
  const classes = useStyles();
  function changeSource(source) {
    return () => {
      // setPhotoSource(source);
      onSelectSource(source);
      onClose();
    };
  }

  return (
    <Dialog open={open} maxWidth="xs" TransitionComponent={Transition} onClose={onClose} classes={classes}>
      <DialogTitle>Evidencia</DialogTitle>
      <DialogContent>
        <Box display="flex" alignItems="center">
          <Grid container alignItems="center" justify="center">
            <Button variant="outlined" size="medium" color="primary" onClick={changeSource(CameraSource.Photos)}>
              Añadir desde galería
            </Button>
          </Grid>
          <Grid container alignItems="center" justify="center">
            <Button className={classes.buttonStyle} variant="outlined" size="medium" color="primary" onClick={changeSource(CameraSource.Camera)}>
              Tomar fotografía
            </Button>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="default">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

PhotoSourceDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSelectSource: PropTypes.func,
};

PhotoSourceDialog.defaultProps = {
  open: false,
  onClose: () => { },
  onSelectSource: () => { },
};

const useStyles = makeStyles(() => ({
  paper: {
    margin: "0",
    width: "100%",
  },
  buttonStyle: {
    paddingLeft: "25px",
    paddingRight: "25px"
  }
}));
