const STATUSES = {
  EMPTY: "EMPTY",
  LOADING: "LOADING",
  UPDATING: "UPDATING",
  NOT_AVAILABLE: "NOT_AVAILABLE",
  SELECTED: "SELECTED",
  NOT_FOUND: "NOT_FOUND",
  SUCCESS: "SUCCESS",
};

export default STATUSES;
