import ActionTypes from "redux/action";

export const showNotification = (message, options) => ({  
  type: ActionTypes.SHOW_NOTIFICATION,
  payload: { message, options }
});

export const removeNotification = key => ({
  type: ActionTypes.REMOVE_NOTIFICATION,
  payload: { key }
});