const getEventCause = `
  query GetEventCause($id: ID!) {
    getEventCause(id: $id) {
      type
      name
      id
      description
      _version
    }
  }

`;

export default getEventCause;
