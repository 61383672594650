/**
 * En caso de definir la propiedad sorted como @param {Boolean} sorted
 * Deberas definir la propiedad order en cada uno de las columnas de @param {Array<Object>} columns
 * Ej
 * columns = [
 *  { order: 1, title: "fecha", description: "10-12-2021"},
 *  { order: 2, title: "nombre", description: "Juan"}
 * ]
 *
 *
 * @param {Array<Object>} columns de objetos donde una propiedad se repite con el mismo valor y se quieren eliminar los duplicados
 * @param {Boolean} sorted La propiedad que no debe tener duplicados
 * @returns React component
 */

import React from "react";
import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";

//sort values to defined order
function sortValues(columns = []) {
  return columns.sort((a, b) => a.order - b.order);
}

const TableCellMobileFormat = ({ columns, sorted, ...props }) => {
  const classes = useStyles();
  const cells = sorted === false ? columns : sortValues(columns);
  return (
    <Grid container spacing={2} className={classes.cellContainer}>
      {cells.map((value) => (
        <Grid key={value.title} item {...props}>
          <strong className={classes.title}>{value.title}: </strong>
          {value.description || "Sin información"}
        </Grid>
      ))}
    </Grid>
  );
};

TableCellMobileFormat.propTypes = {
  columns: PropTypes.array,
  sorted: PropTypes.bool,
};

TableCellMobileFormat.defaultProps = {
  columns: [],
  sorted: false,
};

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
  },

  cellContainer: {
    maxWidth: "600px",
    margin: "0 auto",
  },
}));
export default TableCellMobileFormat;
