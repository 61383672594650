import React from "react";
import PropTypes from "prop-types";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { makeStyles } from "@material-ui/core/styles";
// import Alert from "@material-ui/lab/Alert";

const AssetsDialogLoadingError = ({ open, onClose, fullWidth, maxWidth }) => {
  const isXs = useMediaQuery("(max-width:765px)");
  //const classes = useStyles();
  //   const classesBtn = useStylesBtn();
  //   const classesActions = useStylesActions();
  //   const classesBtnPrimary = useStylesBtnPrimary();
  //   const classesTitle = useStylesTitle();

  return (
    <>
      <Dialog open={open} fullScreen={isXs} fullWidth={fullWidth} maxWidth={maxWidth}>
        <DialogTitle>Error al cargar el CSV</DialogTitle>
        <DialogContent>El número maximo de activos para su carga es de 500.</DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="default">
            Cerrar
          </Button>

          <Button onClick={onClose} color="default" form="timer-form-dialog">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

AssetsDialogLoadingError.propTypes = {
  open: PropTypes.bool,
  maxWidth: PropTypes.string,
  fullWidth: PropTypes.bool,
  onClose: PropTypes.func,
};

export default AssetsDialogLoadingError;

const useStylesBtn = makeStyles((theme) => ({
  root: {
    width: "50%",
    backgroundColor: "white",
    color: "#00838f",
    border: "1px solid #00838f",
    height: "100%",
    borderRadius: "0",
  },
}));

const useStylesBtnPrimary = makeStyles((theme) => ({
  root: {
    color: "white",
    width: "50%",
    border: "1px solid #00838f",
    height: "100%",
    backgroundColor: "#00838f",
    marginLeft: "0px !important",
    borderRadius: "0",
    "&:hover": {
      backgroundColor: "#00838f",
    },
  },
}));

const useStylesActions = makeStyles((theme) => ({
  root: {
    padding: "12px 0 0 0",
    height: "60px",
  },
}));

const useStylesTitle = makeStyles((theme) => ({
  root: {
    height: "65px",
    textAlign: "center",
    marginTop: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  "@global": {
    ".MuiDialogTitle-root > h2 ": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    ".MuiDialogTitle-root > h2 > img": {
      marginRight: "10px",
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    position: "sticky !important",
    borderTopLeftRadius: "40%",
    borderTopRightRadius: "40%",

    "@global": {
      ".MuiDialog-container > div:nth-of-type(1)": {
        borderTopLeftRadius: "15%",
        borderTopRightRadius: "15%",
      },
    },
  },
  circularProgress: {
    color: "#29d8c8",
  },
}));
