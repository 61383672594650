import React from "react";
import Grid from "@material-ui/core/Grid";

import cleanUUID from "./cleanUUID";
import { TICKET_STATUSES_COLOR } from "constant/ticketConstants";
import { TASK_STATUS_MAP_COLOR, MAINTENANCE_STATUS_MAP_COLOR } from "constant/task/status";
import { TASK_STATUS_MAP } from "constant/task/support/status";
import { TICKET_STATUSES } from "constant/ticketConstants";
import PauseIcon from "@material-ui/icons/PauseCircleOutline";
import { formatCount, getCounterParams } from "components/Timer/helpers";
import { COUNTER_STATUS } from "components/Timer/constants";
import Counter from "components/Timer/Counter";
import StatusSpan from "components/common/StatusSpan";
import { MAINTENANCE_STATUS } from "constant/Maintenance";
import { FormatUserName } from "util/FormatDetails";
import filterRowByStatus from "util/filterRowByStatus";

export const PAGE_SIZE_OPTIONS = [20, 50, 100, 200];
export const MAX_BODY_HEIGHT = 600;
export const PAGE_SIZE_DEFAULT = 20;
export const PAGE_SIZE_GENERAL = 200;

export const SECTIONS = {
  SUPPORT: "SUPPORT",
  INSTALLATION: "INSTALLATION",
  IMPLEMENTATION: "IMPLEMENTATION",
  MAINTENANCE: "MAINTENANCE",
};

export const MODULES = {
  ACTIVITIES: "ACTIVITIES",
  FIELDWORK: "FIELDWORK",
};

export function getPageSize(status) {
  return status === "GENERAL" ? PAGE_SIZE_GENERAL : PAGE_SIZE_DEFAULT;
}

export function getStyleForTab(section, module = MODULES.ACTIVITIES) {
  let statusColor = TICKET_STATUSES_COLOR;

  if ([SECTIONS.MAINTENANCE, SECTIONS.IMPLEMENTATION].includes(section)) {
    statusColor = TASK_STATUS_MAP_COLOR;
  }

  const status = Object.keys(statusColor);
  status.push("GENERAL");

  if (section === SECTIONS.SUPPORT) {
    status.push("CANCELED_BY_CUSTOMER");
    status.push("REVIEWED");
  }

  if (section === SECTIONS.SUPPORT) {
    status.push("CANCELED_BY_CUSTOMER");
    status.push("REVIEWED");
  }

  const styles = status.reduce(
    (a, v) => ({
      ...a,
      [v]: {
        color: `${statusColor[v]}!important`,
        borderBottom: `3px solid ${statusColor[v]}`,
        zIndex: 10,
        "&::before": {
          content: '""',
          display: "inline-block",
          width: "10px",
          height: "20px",
          background: statusColor[v],
        },
        // eslint-disable-next-line no-useless-computed-key
        ["@media (max-width:780px)"]: {
          "&::before": {
            display: "none",
          },
        },
      },
    }),
    {}
  );
  return { ...styles };
}

export function styleStatus(section, module = MODULES.ACTIVITIES) {
  let statusColor = TICKET_STATUSES_COLOR;

  if ([SECTIONS.MAINTENANCE, SECTIONS.IMPLEMENTATION].includes(section)) {
    statusColor = TASK_STATUS_MAP_COLOR;
  }

  const status = Object.keys(statusColor);
  status.push("GENERAL");
  const styles = status.reduce(
    (a, v) => ({
      ...a,
      [v]: {
        backgroundColor: "white",
        color: statusColor[v],
      },
    }),
    {}
  );

  return { ...styles };
}

export function getTableColumns(section, status, data, module = MODULES.ACTIVITIES, enviroment = "web") {
  let columns = [];
  let dataToFilters = getDataTable(data, section);
  let statusColor = {};
  let statusDescription = {};

  switch (section) {
    case SECTIONS.SUPPORT: {
      statusDescription = TICKET_STATUSES;
      statusColor = TICKET_STATUSES_COLOR;
      break;
    }
    case SECTIONS.MAINTENANCE: {
      statusDescription = MAINTENANCE_STATUS;
      statusColor = MAINTENANCE_STATUS_MAP_COLOR;
      break;
    }
    case SECTIONS.IMPLEMENTATION:
    default:
      statusDescription = TASK_STATUS_MAP;
      statusColor = TASK_STATUS_MAP_COLOR;
  }

  if (enviroment === "web") {
    if (section === SECTIONS.SUPPORT) {
      if (status === "OPEN") columns = WEB_TICKET_COLUMNS_OPEN;
      else columns = WEB_TICKET_COLUMNS;
    } else {
      columns = WEB_COLUMNS;
    }

    columns = columns.map((column) => {
      if (column.field === "status") {
        column["lookup"] = statusDescription;
        // eslint-disable-next-line react/display-name
        column["render"] = (rowData) => (
          <StatusSpan status={rowData.status} taskCategory={section}>
            {statusDescription[rowData.status]}
          </StatusSpan>
        );
        column["filtering"] = true;
      }

      if (dataToFilters && dataToFilters?.length) {
        if (["place", "client", "project", "typeActivity", "supervisor", "technical"].includes(column.field)) {
          column["lookup"] = getDataFilter(dataToFilters, column.field);
          column["filtering"] = enviroment === "web" ? true : false;
        }
      }

      if (data.length === 0) {
        column["filtering"] = false;
      }

      return column;
    });

    if ([SECTIONS.MAINTENANCE, SECTIONS.IMPLEMENTATION].includes(section)) {
      columns.splice(10, 1);
    }

    if (status !== "GENERAL") {
      columns = columns.filter((column) => column.field !== "status");
    }
  }

  if (enviroment === "mobile") {
    columns = COLUMNSMOBILE;
    columns[0].lookup = statusDescription;

    if (status !== "GENERAL") {
      columns[0].filtering = false;
    }

    columns[0].customFilterAndSearch = (value, rowData) => {
      if (Array.isArray(value)) {
        return filterRowByStatus(value, rowData);
      }
      let searchMatches = false;
      const parsedValue = value.toLowerCase();
      if (
        statusDescription[rowData.status]?.toLowerCase().includes(parsedValue) ||
        rowData?.id.toLowerCase().includes(parsedValue) ||
        rowData?.startDate.toLowerCase().includes(parsedValue) ||
        rowData?.endDate.toLowerCase().includes(parsedValue) ||
        rowData?.client.toLowerCase().includes(parsedValue) ||
        rowData?.project.toLowerCase().includes(parsedValue) ||
        rowData?.place.toLowerCase().includes(parsedValue) ||
        rowData?.typeActivity.toLowerCase().includes(parsedValue) ||
        rowData?.supervisor.toLowerCase().includes(parsedValue) ||
        rowData?.technical.toLowerCase().includes(parsedValue) ||
        rowData?.timeElapsed?.toLowerCase().includes(parsedValue)
      ) {
        searchMatches = true;
      }
      return searchMatches;
    };

    if (status !== "OPEN") {
      columns[0].render = (rowData) => {
        const counterProps = getCounterParams(rowData.timeElapsed);
        function serializeCount(count, mode) {
          return formatCount(count, counterProps.status, rowData?.status, mode, counterProps.totalRangeTime);
        }
        const pauseIcon = counterProps.status === COUNTER_STATUS.PAUSED ? <PauseIcon /> : <></>;
        return (
          <Grid container spacing={2}>
            <Grid item sm={12} xs={12}>
              <strong>Folio: </strong>
              {cleanUUID(rowData.id)}
            </Grid>
            {status == "GENERAL" && (
              <Grid item sm={6} xs={6}>
                <strong>Estatus: </strong>
                <span
                  style={{
                    color: statusColor[rowData.status],
                  }}
                >
                  {statusDescription[rowData.status]}
                </span>
              </Grid>
            )}
            <Grid item sm={12} xs={12}>
              <strong>Fecha de inicio: </strong>
              {rowData?.startDate}
            </Grid>
            <Grid item sm={12} xs={12}>
              <strong>"Fecha de Fin: </strong>
              {rowData?.endDate}
            </Grid>
            <Grid item sm={12} xs={12}>
              <strong>Cliente: </strong>
              {rowData.client}
            </Grid>
            <Grid item sm={12} xs={12}>
              <strong>Proyecto: </strong>
              {rowData.project}
            </Grid>
            <Grid item xs={12}>
              <strong>Sitio: </strong>
              {rowData?.place}
            </Grid>

            <Grid item xs={12}>
              <strong>Tipo de actividad: </strong>
              {rowData?.typeActivity}
            </Grid>
            <Grid item xs={12}>
              <strong>Coordinador: </strong>
              {rowData?.supervisor}
            </Grid>
            <Grid item xs={12}>
              <strong>Tecnico(s) Responsable(s): </strong>
              {rowData?.technical}
            </Grid>
            {![SECTIONS.MAINTENANCE, SECTIONS.IMPLEMENTATION].includes(section) && (
              <Grid item xs={12}>
                <strong>
                  Tiempo transcurrido <br /> dd:hh:mm:ss
                </strong>
                <Counter serializeCount={serializeCount} startIcon={pauseIcon} {...counterProps} />
              </Grid>
            )}
          </Grid>
        );
      };
    } else {
      columns[0].render = (rowData) => {
        const counterProps = getCounterParams(rowData.timeElapsed);
        function serializeCount(count, mode) {
          return formatCount(count, counterProps.status, rowData?.status, mode, counterProps.totalRangeTime);
        }
        const pauseIcon = counterProps.status === COUNTER_STATUS.PAUSED ? <PauseIcon /> : <></>;
        return (
          <Grid container spacing={2}>
            <Grid item sm={12} xs={12}>
              <strong>Folio: </strong>
              {cleanUUID(rowData.id)}
            </Grid>

            <Grid item xs={12}>
              <strong>Asunto: </strong>
              {rowData?.subject}
            </Grid>

            <Grid item xs={12}>
              <strong>Sitio: </strong>
              {rowData?.place}
            </Grid>
          </Grid>
        );
      };
    }
  }

  return columns;
}

export function getDataFilter(data, key) {
  return [...new Set(data.map((data_) => data_[key]))].reduce((a, v) => ({ ...a, [v]: v }), {});
}

export function getDataTable(data, section = SECTIONS.ACTIVITIES) {
  console.log("olas", data);
  if ([SECTIONS.IMPLEMENTATION, SECTIONS.MAINTENANCE].includes(section)) {
    return data.map((data_) => {
      const technicals = data_?.users?.items.map((item) => {
        return FormatUserName(item.user);
      });

      // console.log("olas", data_?.premises?.name);

      let place = data_?.premises?.name || data_?.buildingLocation?.premises.name;
      place = place || "Sin Información";

      return {
        id: data_.id,
        status: data_.status,
        startDate: data_?.startDate ? `${data_?.startDate} ${data_?.startTime}` : "Sin Información",
        endDate: data_?.endDate ? `${data_?.endDate} ${data_?.endTime}` : "Sin Información",
        client: data_?.project?.company ? data_?.project?.company.name : "Sin Información",
        project: data_?.project ? data_?.project?.name : "Sin Información",
        typeActivity: data_?.taskType?.name ? data_?.taskType?.name : "Sin Información",
        place,
        supervisor: data_?.supervisor.name ? FormatUserName(data_?.supervisor) : "Sin Información",
        technical: technicals && technicals.length > 0 ? technicals.toString() : "Sin Información",
      };
    });
  } else {
    const dataF = [];
    for (let data_ of data) {
      let place = "";
      place = data_?.premises?.name || "Sin Información";

      const technicals = data_.taskInfo
        ? data_.taskInfo?.users?.items?.map((item) => {
            return FormatUserName(item.user);
          })
        : [];

      data_ = {
        id: data_.id,
        status: data_?.status,
        taskID: data_?.taskInfo?.id,
        startDate: data_.taskInfo?.startDate
          ? `${data_.taskInfo?.startDate} ${data_.taskInfo?.startTime}`
          : "Sin Información",
        subject: data_.subject,
        description: data_.description || data_.impactDetails || "Sin información",
        endDate: data_.taskInfo?.endDate ? `${data_.taskInfo?.endDate} ${data_.taskInfo?.endTime}` : "Sin Información",
        client: data_.taskInfo?.project?.company ? data_.taskInfo?.project?.company?.name : "Sin Información",
        project: data_.taskInfo?.project ? data_.taskInfo?.project?.name : "Sin Información",
        typeActivity: data_.taskInfo?.taskType?.name ? data_.taskInfo?.taskType?.name : "Sin Información",
        supervisor: data_.taskInfo?.supervisor ? FormatUserName(data_.taskInfo?.supervisor) : "Sin Información",
        place,
        technical: technicals.length > 0 ? technicals.toString() : "Sin Información",
        timeElapsed: data_.taskInfo,
      };

      dataF.push(data_);
    }
    return dataF;
    //Previous function
    // Remplazamos por completo el *else*, para evitar que el *map* se ejecute mas rápido
    // Ahora el sitio (place) lo estamos tomando de *data_?.premises?.name*
    // return data.map((data_) => {
    //   const technicals = data_.taskInfo?.users
    //     ? data_.taskInfo?.users?.items?.map((item) => FormatUserName(item.user))
    //     : [];
    //   return {
    //     id: data_.id,
    //     status: data_?.status,
    //     taskID: data_?.taskInfo?.id,
    //     startDate: data_.taskInfo?.startDate
    //       ? `${data_.taskInfo?.startDate} ${data_.taskInfo?.startTime}`
    //       : "Sin Información",
    //     subject: data_.subject,
    //     description: data_.description || data_.impactDetails || "Sin información",
    //     endDate: data_.taskInfo?.endDate ? `${data_.taskInfo?.endDate} ${data_.taskInfo?.endTime}` : "Sin Información",
    //     client: data_.taskInfo?.project?.company ? data_.taskInfo?.project?.company?.name : "Sin Información",
    //     project: data_.taskInfo?.project ? data_.taskInfo?.project?.name : "Sin Información",
    //     typeActivity: data_.taskInfo?.taskType?.name ? data_.taskInfo?.taskType?.name : "Sin Información",
    //     supervisor: data_.taskInfo?.supervisor ? FormatUserName(data_.taskInfo?.supervisor) : "Sin Información",
    //     place: data_.taskInfo?.premises?.name ? data_.taskInfo?.premises?.name : "Sin Información",
    //     technical: technicals.length > 0 ? technicals.toString() : "Sin Información",
    //     timeElapsed: data_.taskInfo,
    //   };
    // });
  }
}

const WEB_TICKET_COLUMNS_OPEN = [
  {
    title: "Folio",
    field: "id",
    filtering: true,
    render: (rowData) => cleanUUID(rowData.id),
  },
  {
    title: "Asunto",
    field: "subject",
  },
  {
    title: "Sitio",
    field: "place",
    filtering: false,
  },
];

const WEB_TICKET_COLUMNS = [
  {
    title: "Folio",
    field: "id",
    filtering: true,
    render: (rowData) => cleanUUID(rowData.id),
  },
  {
    title: "Asunto",
    field: "subject",
  },
  {
    title: "Estatus",
    field: "status",
    filterCellStyle: { maxWidth: "200px" },
  },
  {
    title: "Fecha de inicio",
    field: "startDate",
    filtering: false,
  },
  {
    title: "Fecha de Fin",
    field: "endDate",
    filtering: false,
  },
  {
    title: "Cliente",
    field: "client",
    filtering: false,
  },
  {
    title: "Proyecto",
    field: "project",
    filtering: false,
  },
  {
    title: "Sitio",
    field: "place",
    filtering: false,
  },
  {
    title: "Tipo de actividad",
    field: "typeActivity",
    filtering: false,
  },
  {
    title: "Coordinador",
    field: "supervisor",
    filtering: false,
  },
  {
    title: "Tecnico(s) Responsable(s)",
    field: "technical",
    filtering: false,
  },
  {
    title: "Tiempo Transcurrido",
    field: "timeElapsed",
    filtering: false,
    render: (rowData) => {
      const counterProps = getCounterParams(rowData.timeElapsed);
      function serializeCount(count, mode) {
        return formatCount(count, counterProps.status, rowData?.status, mode, counterProps.totalRangeTime);
      }
      const pauseIcon = counterProps.status === COUNTER_STATUS.PAUSED ? <PauseIcon /> : <></>;
      return <Counter serializeCount={serializeCount} startIcon={pauseIcon} {...counterProps} />;
    },
  },
];

const WEB_COLUMNS = [
  {
    title: "Folio",
    field: "id",
    filtering: true,
    render: (rowData) => cleanUUID(rowData.id),
  },
  {
    title: "Estatus",
    field: "status",
    filterCellStyle: { maxWidth: "200px" },
  },
  {
    title: "Fecha de inicio",
    field: "startDate",
    filtering: false,
  },
  {
    title: "Fecha de Fin",
    field: "endDate",
    filtering: false,
  },
  {
    title: "Cliente",
    field: "client",
    filtering: false,
  },
  {
    title: "Proyecto",
    field: "project",
    filtering: false,
  },
  {
    title: "Sitio",
    field: "place",
    filtering: false,
  },
  {
    title: "Tipo de actividad",
    field: "typeActivity",
    filtering: false,
  },
  {
    title: "Coordinador",
    field: "supervisor",
    filtering: false,
  },
  {
    title: "Tecnico(s) Responsable(s)",
    field: "technical",
    filtering: false,
  },
  {
    title: "Tiempo Transcurrido",
    field: "timeElapsed",
    filtering: false,
    render: (rowData) => {
      const counterProps = getCounterParams(rowData.timeElapsed);
      function serializeCount(count, mode) {
        return formatCount(count, counterProps.status, rowData?.status, mode, counterProps.totalRangeTime);
      }
      const pauseIcon = counterProps.status === COUNTER_STATUS.PAUSED ? <PauseIcon /> : <></>;
      return <Counter serializeCount={serializeCount} startIcon={pauseIcon} {...counterProps} />;
    },
  },
];

const COLUMNSMOBILE = [
  {
    title: "",
    field: "id",
    filterCellStyle: { maxWidth: "280px" },
  },
];

export default {
  styleStatus,
  getStyleForTab,
  getTableColumns,
  getDataTable,
  getPageSize,
};
