import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import AddBoxIcon from "@material-ui/icons/AddBox";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import DetailTitle from "components/DetailTitle";
import DataLabel from "components/DataLabel";
import Section from "components/Section";
import Card from "components/custom/Card";
import MaterialTable from "components/custom/MaterialTable";
import BuildingLocationDialog from "./BuildingLocationDialog";
import useBooleanFlag from "hooks/useBooleanFlag";
import { useActions } from "hooks/useActions";
import * as adminSiteCatalog from "redux/dispatcher/adminSiteCatalog";
import formatCustodianName from "./formatCustodianName";
import { REQUEST_STATUS } from "constant/requestStatus";
import PremiseDialog from "./PremiseDialog";
import EditIcon from "@material-ui/icons/Edit";

const TABLE_COLUMNS = [
  {
    title: "Nombre",
    field: "name",
  },
  {
    title: "Código",
    field: "code",
  },
  {
    title: "Área",
    field: "area",
  },
  {
    title: "Bloque",
    field: "buildingBlock",
  },
  {
    title: "Piso",
    field: "floor",
  },
  {
    title: "Fecha de registro",
    field: "createdAt",
  },
];

function formatCustodianInfo(custodian) {
  if (!custodian) return "Sin información";

  let custodianInfo = formatCustodianName(custodian);
  if (custodian.email || custodian.phone) {
    if (custodian.email) {
      custodianInfo += ` (${custodian.email}`;
      if (custodian.phone) custodianInfo += `, ${custodian.phone})`;
      else custodianInfo += `)`;
    } else custodianInfo += ` (${custodian.phone})`;
  }

  return custodianInfo;
}

function PremisesDetailView() {
  const { id } = useParams();
  const location = useLocation();
  const [showUpdateDialog, setShowUpdateDialog] = useState(false);
  const { rdxPremise, rdxDetailStatus } = useSelector(({ adminPremisesDetail }) => adminPremisesDetail);
  const fetchPremises = useActions(adminSiteCatalog.getPremise);
  const [addLocationDialogOpen, openAddLocConfirmDialog, closeAddLocConfirmDialog] = useBooleanFlag();
  const [activeBuildingLocation, setActiveBuildingLocation] = useState(null);

  const [tableActions] = useState([
    {
      icon: AddBoxIcon,
      tooltip: "Crear una nueva zona",
      isFreeAction: true,
      onClick: openAddLocConfirmDialog,
    },
    {
      icon: EditIcon,
      tooltip: "Actualizar zona",
      onClick: updateBuildingLocation,
    },
  ]);

  useEffect(() => {
    if (id) {
      fetchPremises(id);
    }
  }, [id, fetchPremises]);

  function updateBuildingLocation(event, row) {
    setActiveBuildingLocation({ ...row });
    openAddLocConfirmDialog();
  }

  function hideUpdateDialog() {
    setShowUpdateDialog(false);
  }

  function hideUpdateLocationDialog() {
    setActiveBuildingLocation(null);
    closeAddLocConfirmDialog();
  }

  const loading = rdxDetailStatus === REQUEST_STATUS.PENDING;

  return (
    <>
      <Card
        header={{
          title: (
            <DetailTitle
              to={`${location.pathname.startsWith("/validacion/") ? "/validacion/sitios" : "/admin/sitios"}`}
              title={
                <>
                  Sitio <i>{rdxPremise.name}</i>
                </>
              }
            />
          ),
          actions: [
            {
              text: "Editar",
              onClick: () => setShowUpdateDialog(true),
            },
          ],
        }}
      >
        {rdxDetailStatus === REQUEST_STATUS.FAILED && (
          <Box mb={3}>
            <Alert severity="error">Ha ocurrido un error con la carga del detalle, recarga la página por favor.</Alert>
          </Box>
        )}
        <Box mx={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <DataLabel label="Compañía" data={rdxPremise.company?.name} loading={loading} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <DataLabel label="Responsable" data={formatCustodianInfo(rdxPremise.custodian)} loading={loading} />
            </Grid>
          </Grid>
          <Box mt={3} mb={2}>
            <Typography variant="subtitle2">Dirección</Typography>
            <Divider />
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={2}>
              <DataLabel label="Calle" data={rdxPremise.address?.street} loading={loading} />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <DataLabel label="No. Exterior" data={rdxPremise.address?.number} loading={loading} />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <DataLabel label="Colonia" data={rdxPremise.address?.settlement} loading={loading} />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <DataLabel label="Código Postal" data={rdxPremise.address?.zipcode} loading={loading} />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <DataLabel label="Delegación" data={rdxPremise.address?.locality} loading={loading} />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <DataLabel label="Ciudad" data={rdxPremise.address?.city} loading={loading} />
            </Grid>
          </Grid>
        </Box>
      </Card>
      <Box mt={4}>
        <Section>
          <MaterialTable
            title="Zonas"
            columns={TABLE_COLUMNS}
            data={[...(rdxPremise.buildingLocations?.items || [])]}
            actions={tableActions}
          />
        </Section>
      </Box>
      {addLocationDialogOpen && (
        <BuildingLocationDialog
          premiseId={rdxPremise.id}
          buildingLocation={activeBuildingLocation}
          onClose={hideUpdateLocationDialog}
        />
      )}
      {showUpdateDialog && <PremiseDialog premise={rdxPremise} onClose={hideUpdateDialog} />}
    </>
  );
}

export default PremisesDetailView;
