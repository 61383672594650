import ActionType from "../../action";
import { app } from "../../initialStore";
import themeReducer from "./theme";

function appReducer(state = app, action) {
  switch (action.type) {
    case ActionType.CHANGE_THEME_CONFIG: {
      return {
        ...state,
        theme: themeReducer(state.theme, action),
      };
    }
    case "CHANGE_HEADER_MENU_OPTIONS": {
      return {
        ...state,
        warehouses: action.payload,
      };
    }
    case ActionType.NETWORK_STATUS_CHANGE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case ActionType.DATASTORE_STATUS_CHANGE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case ActionType.DATASTORE_MODELS_SYNCED_CHANGE: {
      const { dataStoreModelSyncedName } = action.payload;
      if (!state.dataStoreModelsSynced.includes(dataStoreModelSyncedName)) {
        return {
          ...state,
          dataStoreModelsSynced: state.dataStoreModelsSynced.concat([dataStoreModelSyncedName]),
        };
      }
      return {
        ...state,
      };
    }
    case ActionType.SHOW_GENERAL_LOADER: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case ActionType.DATASTORE_RESET_SYNCED_MODELS: {
      return {
        ...state,
        dataStoreModelsSynced: [],
      };
    }
    case ActionType.DATASTORE_MODELS_CHANGE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case ActionType.CHANGE_APP_TITLE: {
      return {
        ...state,
        title: action.title,
      };
    }
    case ActionType.PEDING_SYNC_STATUS_CHANGE: {
      return {
        ...state,
        pendingDataToSycn: action.isPendingSync,
      };
    }
    case ActionType.app.UPDATE_APP_VERSION: {
      return {
        ...state,
        version: action.version,
      };
    }

    default:
      return state;
  }
}

export default appReducer;
