import React from "react";
import { Logger } from "aws-amplify";
import { captureException } from "setup/sentry";
import PropTypes from "prop-types";
import { useEnablement, EnablementDialog } from "./CompanyEnablement";
import useProjectOperations from "./useProjectOperations";
import { ENABLEMENT_MODE, ENABLEMENT_TEXT } from "./constant";

const logger = new Logger("ProjectEnablementDialog");

export function ProjectEnablementDialog({ onConfirm, onConfirmError, onCancel, ...props }) {
  const { mode, elementId: projectId, elementVersion: projectVersion, closeDialog } = useEnablement();
  const { updateProject, loading } = useProjectOperations();

  async function handleConfirm() {
    let resultData = null;
    try {
      if (mode === ENABLEMENT_MODE.DISABLED) {
        resultData = await handleDisabledProject(projectId, projectVersion);
      }
      if (mode === ENABLEMENT_MODE.ENABLED) {
        resultData = await handleEnableProject(projectId, projectVersion);
      }
    } catch (error) {
      logger.error("handleConfirm", error);
      captureException(error, "handleConfirm: ProjectEnablementDialog");
      onConfirmError(error, { closeDialog });
    } finally {
      onConfirm(resultData);
      closeDialog();
    }
  }

  function handleCancel() {
    onCancel();
    closeDialog();
  }

  const handleDisabledProject = async (projectId, projectVersion) =>
    updateProject(
      { id: projectId, _version: projectVersion, enabled: false },
      {
        successMsg: "Proyecto deshabilitado satisfactoriamente.",
        errorMsg: "Ocurrio un error deshabilitando el proyecto.",
      }
    );

  const handleEnableProject = async (projectId, projectVersion) =>
    updateProject(
      { id: projectId, _version: projectVersion, enabled: true },
      {
        successMsg: "Proyecto habilitado satisfactoriamente.",
        errorMsg: "Ocurrio un error habilitando el proyecto.",
      }
    );

  return (
    <EnablementDialog handleConfirm={handleConfirm} handleCancel={handleCancel} loading={loading} {...props}>
      ¿Estás seguro que deseas <strong>{ENABLEMENT_TEXT[mode]}</strong> el siguiente proyecto?
    </EnablementDialog>
  );
}

export default ProjectEnablementDialog;

ProjectEnablementDialog.propTypes = {
  onConfirm: PropTypes.func,
  onConfirmError: PropTypes.func,
  onCancel: PropTypes.func,
};

ProjectEnablementDialog.defaultProps = {
  onConfirm: () => {},
  onConfirmError: () => {},
  onCancel: () => {},
};
