import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));

function DetailTitle(props) {
  const classes = useStyles();
  return (
    <div className={classes.titleContainer}>
      <Box mr={2}>
        <IconButton aria-label="return" component={Link} to={props.to} size={props.btnSize}>
          <ArrowBackIcon />
        </IconButton>
      </Box>
      <Typography variant="h6" id="maintenance-title">
        {props.title}
      </Typography>
    </div>
  );
}

DetailTitle.defaultProps = {
  size: "medium",
};

DetailTitle.propTypes = {
  btnSize: PropTypes.oneOf(["small", "medium"]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  to: PropTypes.string,
};

export default DetailTitle;
