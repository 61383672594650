export const TICKET_STATUSES = {
  OPEN: "Abierto",
  SCHEDULED: "Atención asignada",
  IN_PROGRESS: "En progreso",
  RESOLVED: "Por firmar",
  VALIDATED: "Finalizado",
  RATED: "Calificado",
  CANCELLED: "Cancelado por cliente",
};

export const TICKET_IMPACTS = {
  HIGH: "Alto",
  MEDIUM: "Medio",
  LOW: "Bajo",
};

export const TICKET_URGENCY = {
  HIGH: "Alta",
  NORMAL: "Normal",
  LOW: "Baja",
};

export const TICKET_PRIORITY = {
  HIGH: "Alta",
  MEDIUM: "Normal",
  LOW: "Baja",
};

export const TICKET_REQUEST_TYPE = {
  ISSUE: "Incidente",
  SERVICE_REQUEST: "Solicitud de Servicio",
};

export const TICKER_SERVICE_CATEGORY = {
  ELECTRONIC_SECURITY: "Seguridad Electrónica",
};

export const TICKET_SERVICE_SUBCATEGORY = {
  CCTV: "CCTV",
  NETWORK: "Red",
  COMPUTER_EQUIPMENT: "Equipo de cómputo",
  PROPERTY_EQUIPMENT: "Equipo inmobiliario",
  AIR_CODITIONER: "Aire acondicionado",
  ACCESS_CONTROL: "Control de acceso",
  ROUND_CONTROL: "Control de rondas",
  CABINET: "Gabinetes",
  UNDEFINED: "Sin información",
};

export const TICKET_CHANNEL = {
  PHONE: "Télefono",
  EMAIL: "Correo electrónico",
  WEB_PAGE: "Página Web",
};

export const TICKET_SUPPORTTYPE = {
  PRESENTIAL: "Presencial",
  REMOTE: "Remoto",
};

export const TICKET_STATUSES_COLOR = {
  GENERAL: "#000000",
  OPEN: "#a2c4c9",
  SCHEDULED: "#7f6cff",
  IN_PROGRESS: "#105285",
  COMPLETED: "#93c47d",
  RESOLVED: "#f6ce38",
  VALIDATED: "#00695f",
  CANCELLED: "#666666",
  REVIEWED: "#f6ce38",
  FINALIZED: "#00695f",
  WITH_INSPECTION_NOTES: "#ff6c6c",
  CANCELED_BY_CUSTOMER: "#666666",
};

export const TICKET_STATUSES_COLOR_TITLE = {
  OPEN: "#a2c4c9",
  SCHEDULED: "#7f6cff",
  IN_PROGRESS: "#105285",
  RESOLVED: "#f6ce38",
  VALIDATED: "#92c47c",
  RATED: "#00695f",
  CANCELLED: "#666666",
};
export const ASSET_MODE = {
  REPORTED_ASSET: "REPORTED_ASSET",
  TASK_ASSET: "TASK_ASSET",
};
