import React from "react";
import PropTypes from "prop-types";

import { Box, TextField, Typography } from "@material-ui/core";
import { Controller } from "react-hook-form";
import { SUPPORT_STEPS_FORMS_RULES } from "constant/ticketActivity";

const FinalTicketComments = ({ control, errors, ...props }) => {
  return (
    <>
      <Typography>Los siguientes campos son requeridos, completalos para poder finalizar tu actividad.</Typography>
      <Box mt={3}>
        <Controller
          control={control}
          name="issue"
          rules={SUPPORT_STEPS_FORMS_RULES.issue}
          as={
            <TextField
              id="issue"
              type="text"
              label="Problemática Identificada"
              error={!!errors.issue}
              helperText={errors.issue?.message}
              variant="outlined"
              fullWidth
              required
              multiline
              rows={4}
              {...props}
            />
          }
        />
      </Box>
      <Box mt={3}>
        <Controller
          control={control}
          name="analysis"
          rules={SUPPORT_STEPS_FORMS_RULES.analysis}
          as={
            <TextField
              id="analysis"
              type="text"
              label="Análisis"
              error={!!errors.analysis}
              helperText={errors.analysis?.message}
              variant="outlined"
              fullWidth
              required
              multiline
              rows={4}
              {...props}
            />
          }
        />
      </Box>
      <Box mt={3}>
        <Controller
          control={control}
          name="solution"
          rules={SUPPORT_STEPS_FORMS_RULES.solution}
          as={
            <TextField
              id="solution"
              type="text"
              label="Solución"
              error={!!errors.solution}
              helperText={errors.solution?.message}
              variant="outlined"
              fullWidth
              required
              multiline
              rows={4}
              {...props}
            />
          }
        />
      </Box>
    </>
  );
};

FinalTicketComments.propTypes = {
  errors: PropTypes.any,
  control: PropTypes.object,
};

FinalTicketComments.defaultProps = {
  errors: {},
  control: {},
};

export default FinalTicketComments;
