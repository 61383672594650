export function createShipmentRelations(task, comments, newAssets = [], recipient) {
  const taskParams = "$userTaskInput: CreateUserTaskInput!, ";
  const taskParamsObj = {
    userTaskInput: {
      taskID: task.id,
      userID: recipient,
    },
  };
  const taskOperation = `
    cut: createUserTask(input: $userTaskInput) {
      id
      userID
      taskID
      task {
        id
        _version
        _deleted
        _lastChangedAt
      }
      user {
        id
        name
        middlename
        lastnameP
        lastnameM
        email
        phone
        extension  
        _version
        _deleted
        _lastChangedAt
      }
      _version
      _deleted
      _lastChangedAt
    },\n
  `;

  const remarkParams = "$remarkInput: CreateRemarkInput!, ";
  const remarkParamsObj = {
    remarkInput: {
      taskID: task.id,
      remark: comments,
      taskStatus: "SCHEDULED",
    },
  };
  const remarkOperation = `
    cr: createRemark(input: $remarkInput) {
      id
      taskID
      _version
      _deleted
      _lastChangedAt
    },\n
  `;

  let assetsParams = "";
  let assetsParamsObj = {};
  let assetsOperations = "";

  newAssets.forEach(({ id }, index) => {
    assetsParams += `$taskAssetInput${index}: CreateTaskAssetsInput!, `;
    assetsParamsObj[`taskAssetInput${index}`] = {
      taskAssetsTaskId: task.id,
      taskAssetsAssetId: id,
    };
    assetsOperations += `
      cta${index}: createTaskAssets(input: $taskAssetInput${index}) {
        id
        taskAssetsTaskId
        taskAssetsAssetId
        task {
          id
          _version
          _deleted
          _lastChangedAt
        }
        asset {
          id
          code
          serial
          quantity
          type
          enabled
          history
          comment
          material {
            id
            name
            code
            description
            manufacturer
            trackable
            enabled
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          locationCode
          trackingID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      },\n
    `;
  });

  const mutation = `
    mutation ShipmentRelations(${taskParams + remarkParams + assetsParams}) {
      ${taskOperation + remarkOperation + assetsOperations}
    }
  `;
  const params = {
    ...taskParamsObj,
    ...remarkParamsObj,
    ...assetsParamsObj,
  };

  return { mutation, params };
}
