import { useEffect, useState, useRef, useCallback } from "react";
import { BrowserMultiFormatReader } from "@zxing/browser";
import { Logger } from "@aws-amplify/core";

export enum READER_STATUS {
  NOT_STARTED = "NOT_STARTED_STATUS",
  STARTED = "STARTED_STATUS",
  PAUSED = "PAUSED_STATUS",
  FINALIZED = "FINALIZED_STATUS",
}

const logger = new Logger("useCodeReader");
interface useCodeReaderParams {
  videoElement: HTMLVideoElement | null | string;
  onCodeDetected: () => { [key: string]: any };
  onError: () => void;
}

export const ERROR_LISTING_INPUT_DEVICES = 45684;

const codeReader = new BrowserMultiFormatReader(undefined, { delayBetweenScanAttempts: 1000 });

const DEFAULT_PARAMS = {
  videoElement: null,
  onCodeDetected: () => ({}),
  onError: () => {},
};

function useCodeReader({ videoElement, onCodeDetected, onError }: useCodeReaderParams = DEFAULT_PARAMS) {
  const [videoInputDevices, setVideoInputDevices] = useState<MediaDeviceInfo[]>([]);
  const [selectedVideoInput, setSelectedVideoInput] = useState<MediaDeviceInfo | null>(null);

  const compRef = useRef<any>({
    vdSet: new Set(),
  }); // TODO: Change to correct type instead of <any>

  useEffect(() => {
    return () => {
      stopDecoding();
    };
  }, []);

  useEffect(() => {
    compRef.current.onCodeDetected = onCodeDetected;
  }, [onCodeDetected]);

  function validateVideoDevice(videoDevice: any) {
    return compRef.current.vdSet.has(videoDevice);
  }

  const listVideoInputDevices = useCallback(() => {
    logger.debug("listVideoInputDevices: Starting");
    return BrowserMultiFormatReader.listVideoInputDevices().then((videoDevices) => {
      const vdSet = new Set();

      videoDevices.forEach((device) => {
        vdSet.add(device.deviceId);
      });

      compRef.current.vdSet = vdSet;
      setVideoInputDevices(videoDevices);
      return videoDevices;
    });
  }, []);

  const startDecoding = useCallback(
    (videoDevice = selectedVideoInput) => {
      logger.debug("startDecoding: Video device to decode", videoDevice);
      logger.debug("startDecoding: Validating video device...");

      if (!validateVideoDevice(videoDevice)) {
        logger.debug("startDecoding: Device id provided not recognized");
        videoDevice = null;
        setSelectedVideoInput(videoDevice);
      }

      logger.debug("startDecoding: Decodificando cámara...");

      videoElement = <string>videoElement;
      const video: HTMLVideoElement = <HTMLVideoElement>document.getElementById(videoElement);

      try {
        codeReader.decodeFromVideoDevice(videoDevice, video, (result, error, controls) => {
          if (result) {
            const { onCodeDetected } = compRef.current;
            onCodeDetected(result);
            logger.debug(JSON.stringify(result));
          } else if (error) {
            logger.error("startDecoding: Error detectando código ", error);
          }
          compRef.current.controls = controls;
        });
      } catch (error) {
        logger.error("startDecoding: Error decodificando", error);
      }
    },
    [videoElement, onCodeDetected]
  );

  function stopDecoding() {
    if (compRef.current.controls) {
      try {
        compRef.current.controls.stop();
      } catch (error) {
        logger.error("stopDecoding:", error);
      }
    }
  }

  function setSelectedVideoInputDevice(videoDevice: any) {
    if (validateVideoDevice(videoDevice)) {
      setSelectedVideoInput(videoDevice);
    }
  }

  return {
    // videoInputDevices,
    selectedVideoInput,
    setSelectedVideoInputDevice,
    startDecoding,
    stopDecoding,
    listVideoInputDevices,
  };
}

export default useCodeReader;
