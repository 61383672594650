import useGraphQL from "hooks/useGraphQL";
import { getUser } from "graphql-custom/queries/getUser";
import createUserAgreementQuery from "graphql-custom/mutations/createUserAgreement";
import listLegalDocuments from "graphql-custom/queries/listLegalDocuments";
import { Auth } from "aws-amplify";

export default function useAPIHelpers() {
  const { runGraphQLOperation, loading } = useGraphQL();

  async function createUserAgreement(userID, legalDocumentID) {
    return runGraphQLOperation({
      operation: createUserAgreementQuery,
      notification: { errorMsg: "error al aceptar los terminos y condiciones" },
      variables: {
        input: {
          userID,
          legalDocumentID,
          grantedAt: new Date().toISOString(),
          isGranted: true,
        },
      },
    });
  }

  async function getListLegalDocuments() {
    return runGraphQLOperation({
      operation: listLegalDocuments,
      notification: { errorMsg: "error consultando la informacion" },
    });
  }

  async function getUserById(userId) {
    return runGraphQLOperation({
      operation: getUser,
      notification: { errorMsg: "Error consultando la informacion del usuario." },
      variables: { id: userId },
    });
  }

  return {
    createUserAgreement,
    getListLegalDocuments,
    getUserById,
    loading,
  };
}

export async function updateCognitoUserAttribute(attribute, value, { user = false } = {}) {
  if (!user) {
    user = await Auth.currentAuthenticatedUser();
  }
  return Auth.updateUserAttributes(user, {
    [attribute]: value,
  });
}
