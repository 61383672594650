/* eslint-disable react/display-name */
import React, { useEffect } from "react";
import { Switch, Route, useLocation, useHistory, Redirect } from "react-router-dom";
import MainLayout from "layout/main";
import GoodsReceiptsView from "components/GoodsReceiptsView";
import GoodsReceiptsDetailsView from "components/GoodsReceiptsDetailView";
import AssetsListView from "components/warehouse/AssetsListView";
import AssetDetailsView from "components/warehouse/AssetDetailsView";
import QRCodeGeneratorView from "components/assets/QRCodeGeneratorView";
import DeliveriesListView from "components/DeliveriesListView";
import DeliveryDetailsView from "components/DeliveryDetailsView";
import AssetsCSVLoadView from "components/warehouse/AssetsCSVLoadView";

import {
  ROOT,
  ASSET,
  // MATERIAL,
  QR_GENERATOR,
  WAREHOUSE_DELIVERIES,
  WAREHOUSE_DELIVERY_DETAILS,
  WAREHOUSE_ASSET_INCOME,
  WAREHOUSE_ASSET_INCOME_DETAIL,
  WAREHOUSE_ASSETS,
  WAREHOUSE_ASSET_DETAILS_WITH_PARAM,
} from "constant/route/warehouse";
import * as ROLES from "constant/roles";

import { WarehouseRoutesIcons } from "constant/drawerIcons";

const { AssetsIcon, ListIcon, AssetReceptionIcon, ShipmentsIcon, QRGenerationsIcon } = WarehouseRoutesIcons;

const drawerItems = [
  {
    text: "Activos",
    icon: AssetsIcon,
    items: [
      { text: "Listado", to: WAREHOUSE_ASSETS, icon: ListIcon },
      { text: "Ingresos", to: WAREHOUSE_ASSET_INCOME, icon: AssetReceptionIcon },
      { text: "Envios", to: WAREHOUSE_DELIVERIES, icon: ShipmentsIcon },
    ],
  },
  { to: QR_GENERATOR, text: "Generador QRs", icon: QRGenerationsIcon },
];

const ROUTES = [
  {
    exact: true,
    path: WAREHOUSE_ASSET_INCOME,
    component: GoodsReceiptsView,
  },
  {
    exact: true,
    path: WAREHOUSE_ASSET_INCOME_DETAIL,
    component: GoodsReceiptsDetailsView,
  },
  {
    exact: true,
    path: QR_GENERATOR,
    component: QRCodeGeneratorView,
  },
  {
    // This permission should change
    exact: true,
    path: WAREHOUSE_DELIVERY_DETAILS,
    render: () => <DeliveryDetailsView type={ROLES.WAREHOUSE_KEEPERS} />,
  },
  {
    // This permission should change
    exact: true,
    path: WAREHOUSE_DELIVERIES,
    // component: DeliveriesListView,
    render: () => <DeliveriesListView type={ROLES.WAREHOUSE_KEEPERS} />,
  },
  {
    exact: true,
    path: WAREHOUSE_ASSETS,
    component: AssetsListView,
  },
  {
    exact: true,
    path: WAREHOUSE_ASSET_DETAILS_WITH_PARAM,
    component: AssetDetailsView,
  },
  {
    exact: true,
    path: ASSET.NEW_ASSETS,
    component: AssetsCSVLoadView,
  },
];

function WarehouseRoutes() {
  const location = useLocation();
  const history = useHistory();
  // Redirect to one end-route if the current it's a partial one
  useEffect(() => {
    if (location.pathname === ROOT) {
      history.replace(drawerItems[0].to);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  let selectedSection = 0;

  return (
    <MainLayout drawerItems={drawerItems} selectedSection={selectedSection}>
      <Switch>
        {ROUTES.map((route, index) => (
          <Route key={`wh-route-${index}`} {...route} />
        ))}
        <Route path="*" render={() => <Redirect to={drawerItems[0].items[0].to} />} />
      </Switch>
    </MainLayout>
  );
}

export default WarehouseRoutes;
