export const TASK_STATUS_MAP = {
  SCHEDULED: "Atención asignada",
  IN_PROGRESS: "En progreso",
  COMPLETED: "Por firmar",
  WITH_INSPECTION_NOTES: "Con observaciones",
  REVIEWED: "Finalizada",
  FINALIZED: "Validada",
};

export default TASK_STATUS_MAP;
