export const listPremisesOptions = /* GraphQL */ `
  query ListPremisesOptions(
    $filter: ModelPremisesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPremisess(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        address {
          street
          number
          zipcode
          city
        }
        _deleted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;