import PropTypes from "prop-types";
import { useSelector } from "react-redux";

function GroupRestrictedView({ children, ...props }) {
  const reduxData = useSelector((state) => ({ groups: state.session.groups }));

  let allowed = false;

  // Verificación si es un grupo o un arreglo de grupos
  if (typeof props.group === "string") {
    allowed = reduxData.groups.some((group) => group === props.group);
  } else if (props.group.length) {
    const groupsAllowed = new Set([...props.group]);

    for (const group of reduxData.groups) {
      if (groupsAllowed.has(group)) {
        allowed = true;
        break;
      }
    }
  }

  // Retorno del componente para el caso donde children es una función
  if (children.length === 1 && typeof children === "function") {
    return children(allowed);
  } else if (allowed) {
    return children;
  }

  return null;
}

GroupRestrictedView.defaultProps = {
  group: [],
};
GroupRestrictedView.propTypes = {
  // String o arreglo de strings que especifica el/los grupo(s) autorizados
  group: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.node]),
};

export default GroupRestrictedView;
