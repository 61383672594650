import React from "react";
import PropTypes from "prop-types";
import { Logger } from "@aws-amplify/core";
import API, { graphqlOperation } from "@aws-amplify/api";
import useOnlineStatus from "@rehooks/online-status";
import * as queries from "graphql-custom/queries";
// material-ui
import CircularProgress from "@material-ui/core/CircularProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
//custom components
import NetworkTooltip from "components/custom/NetworkTooltip";
// hooks custom
import useLoadingStatus from "hooks/useLoadingStatus";
import useNotifier from "hooks/useNotifier";
import downloadFile from "util/downloadFile";
import { captureException } from "setup/sentry";
// helpers
const logger = new Logger("DownloadQRButton");

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "relative",
    width: "fit-content",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const MODE = {
  ONE: "ONE",
  MULTIPLE: "MULTIPLE",
};

function DownloadQRButton(props) {
  const [downloading, _downloadQr] = useLoadingStatus(downloadQr);
  const classes = useStyles();
  const isOnline = useOnlineStatus();
  const { showError } = useNotifier();
  const mode = typeof props.assetID === "string" ? MODE.ONE : Array.isArray(props.assetID) ? MODE.MULTIPLE : null;

  async function downloadQr() {
    if (!props.assetID) {
      logger.warn("ID de asset no especificado en props");
      return;
    }

    try {
      await props.beforeDownload();
      let input = null;
      if (mode === MODE.ONE) input = { assets: [props.assetID] };
      if (mode === MODE.MULTIPLE) input = { assets: [...props.assetID] };
      if (!input) {
        logger.error("DownloadQRButton: invalid input", { assetID: props.assetID, input });
        return null;
      }

      const { data } = await API.graphql(graphqlOperation(queries.lambdaQrGenerator, { input }), {
        // eslint-disable-next-line no-useless-computed-key
        ["operation-name"]: "lambdaQrGenerator",
      });

      let output = null;
      if (mode === MODE.ONE) output = data.lambdaQrGenerator.ids[0];
      if (mode === MODE.MULTIPLE) output = data.lambdaQrGenerator.ids;
      await props.afterDownload(output);
      downloadFile(data?.lambdaQrGenerator?.fileUrl);
    } catch (error) {
      logger.error("DownloadQRButton", error);
      showError(props.errorMsg);
      captureException(error.message, "DownloadQRButton: Error en descarga de QRs");
    }
  }

  const content = props.content ? props.content : <img src="/img/qrcode.png" width="24px" height="24px" alt="icon" />;

  const isAStringContent = typeof content === "string";
  return (
    <NetworkTooltip onlineTitle={props.onlineTitle} offlineTitle={props.offlineTitle} style={props.tooltipStyle}>
      <div className={classes.wrapper}>
        <Button
          onClick={_downloadQr}
          size={props.size}
          color={props.color}
          variant={props.variant}
          disabled={downloading || !isOnline}
          style={isAStringContent ? { ...props.style } : { minWidth: "auto", ...props.style }}
        >
          {isAStringContent ? content : <div style={{ display: "flex", opacity: isOnline ? 1 : 0.3 }}>{content}</div>}
        </Button>
        {downloading && <CircularProgress size={22} className={classes.buttonProgress} />}
      </div>
    </NetworkTooltip>
  );
}

DownloadQRButton.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  assetID: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onlineTitle: PropTypes.string,
  offlineTitle: PropTypes.string,
  beforeDownload: PropTypes.func,
  afterDownload: PropTypes.func,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  variant: PropTypes.oneOf(["contained", "outlined", "text"]),
  color: PropTypes.oneOf(["default", "inherit", "primary", "secondary"]),
  style: PropTypes.shape(),
  tooltipStyle: PropTypes.shape(),
  errorMsg: PropTypes.string,
};

DownloadQRButton.defaultProps = {
  content: "",
  onlineTitle: "Descargar QR de activo.",
  offlineTitle: "La descarga de QR requiere conexión a internet.",
  size: "small",
  beforeDownload: async () => {},
  afterDownload: async () => {},
  style: {},
  tooltipStyle: {},
  errorMsg: "Ocurrio un error durante la descarga de códigos QR, intentelo más tarde.",
};

export default DownloadQRButton;
