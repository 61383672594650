const createTaskType = /* GraphQL */ `
  mutation CreateTaskType($input: CreateTaskTypeInput!) {
    createTaskType(input: $input) {
      id
      name
      description
      category
      createdAt
      _version
      _deleted
    }
  }
`;

export default createTaskType;
