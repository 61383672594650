import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";


function TicketContents(props) {
    const { size, pSize, renderContent, classeNameContent } = props
    return (<Grid xs={size} item>
        <Box p={pSize} className={classeNameContent ? classeNameContent : ''}>
            {renderContent}
        </Box>
    </Grid>
    )
}

TicketContents.propTypes = {
    pSize: PropTypes.number,
    size: PropTypes.number,
};

TicketContents.defaultProps = {
    size: 4,
    pSize: 1,
};

export default TicketContents;
