import React from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { TaskCategory, TicketStatus, TaskStatus } from "models";

const useImplementationStyles = makeStyles((theme) => ({
  GENERAL: {
    color: "#000000",
  },
  SCHEDULED: {
    color: "#7f6cff",
  },
  IN_PROGRESS: {
    color: "#105285",
  },
  COMPLETED: {
    color: "#f6ce38",
  },
  WITH_INSPECTION_NOTES: {
    color: "#ff6c6c",
  },
  FINALIZED: {
    color: "#93c47d",
  },
  REVIEWED: {
    color: "#00695f",
  },
}));

const useMaintenanceStyles = makeStyles((theme) => ({
  GENERAL: {
    color: "#000000",
  },
  SCHEDULED: {
    color: "#7f6cff",
  },
  IN_PROGRESS: {
    color: "#105285",
  },
  COMPLETED: {
    color: "#f6ce38",
  },
  WITH_INSPECTION_NOTES: {
    color: "#ff6c6c",
  },
  FINALIZED: {
    color: "#00695f",
  },
  REVIEWED: {
    color: "#93c47d",
  },
}));

const useTicketStyles = makeStyles((theme) => ({
  GENERAL: {
    color: "#000000",
  },
  OPEN: {
    color: "#a2c4c9",
  },
  SCHEDULED: {
    color: "#7f6cff",
  },
  IN_PROGRESS: {
    color: "#105285",
  },
  COMPLETED: {
    color: "#93c47d",
  },
  RESOLVED: {
    color: "#f6ce38",
  },
  VALIDATED: {
    color: "#00695f",
  },
  CANCELLED: {
    color: "#666666",
  },
  REVIEWED: {
    color: "#f6ce38",
  },
  WITH_INSPECTION_NOTES: {
    color: "#ff6c6c",
  },
  FINALIZED: {
    color: "#00695f",
  },
}));

function StatusCell(props) {
  const impClasses = useImplementationStyles();
  const mtnClasses = useMaintenanceStyles();
  const ticketClasses = useTicketStyles();

  let classes = impClasses;
  if (props.taskCategory === TaskCategory.IMPLEMENTATION) {
    classes = impClasses;
  } else if (props.taskCategory === TaskCategory.SUPPORT) {
    classes = ticketClasses;
  } else if (props.taskCategory === TaskCategory.MAINTENANCE) {
    classes = mtnClasses;
  }

  return <span className={classes[props.status]}>{props.children}</span>;
}

StatusCell.propTypes = {
  children: PropTypes.node,
  status: PropTypes.oneOf([...Object.keys(TaskStatus), ...Object.keys(TicketStatus)]),
  taskCategory: PropTypes.oneOf([...Object.keys(TaskCategory)]),
};

export default StatusCell;
