import exportCsv from "util/exportCsv";
import { transformAWSDate } from "util/date";
import TableSwitchButton from "components/common/TableSwitchButton";

export const COMPANIES_COLUMNS = ({ onSwitchChange, isOnline }) => [
  {
    title: "Habilitado",
    field: "enabled",
    render: (rowData) => {
      const checked = rowData.enabled;
      const enabledText = checked ? "Deshabilitar" : "Habilitar";
      const onlineTitle = `${enabledText} cliente.`;
      const offlineTitle = `Sin conexión a internet para ${enabledText} cliente.`;

      return TableSwitchButton({
        checked,
        onChange: onSwitchChange,
        disabled: !isOnline,
        onlineTitle,
        offlineTitle,
        rowData,
      });
    },
    tooltip: "Cliente habilitado/deshabilitado.",
    disableClick: true,
  },
  {
    title: "Nombre",
    field: "name",
    type: "string",
  },
  {
    title: "Fecha de creación",
    field: "createdAt",
    type: "datetime",
    defaultSort: "desc",
    render: (rowData) => transformAWSDate(rowData.createdAt),
  },
  {
    title: "Fecha de última actualización",
    field: "updatedAt",
    type: "datetime",
    render: (rowData) => transformAWSDate(rowData.updatedAt),
  },
];

export function handleExportCompanyListTable(columns, companiesData) {
  companiesData = companiesData.map((company) => ({
    ...company,
    enabled: company.enabled ? "Habilitado" : "Deshabilitado",
  }));

  return exportCsv(columns, companiesData, "listado-de-clientes-indika360");
}

export function getMockCompaniesList(forceError = false) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (forceError) {
        return reject("Ocurrio un error");
      }
      resolve(MOCK_DATA);
    }, 3000);
  });
}

export function handleErrorDescription(errorMsg) {
  let errorDescription;
  if (errorMsg?.errors?.[0]?.message === "Network Error") {
    errorDescription = "Sin conexión a internet. No se pudo cargar el listado de clientes.";
  }
  return errorDescription;
}

//testing usability
export const MOCK_DATA = [
  { enabled: true, name: "Nombre de cliente", createdAt: "10/08/22: 13:32", updatedAt: "11/08/22: 14:32" },
  { enabled: true, name: "Nombre de cliente", createdAt: "10/08/22: 13:32", updatedAt: "11/08/22: 14:32" },
  { enabled: true, name: "Nombre de cliente", createdAt: "10/08/22: 13:32", updatedAt: "11/08/22: 14:32" },
  { enabled: true, name: "Nombre de cliente", createdAt: "10/08/22: 13:32", updatedAt: "11/08/22: 14:32" },
  { enabled: true, name: "Nombre de cliente", createdAt: "10/08/22: 13:32", updatedAt: "11/08/22: 14:32" },
  { enabled: false, name: "Nombre de cliente", createdAt: "10/08/22: 13:32", updatedAt: "11/08/22: 14:32" },
  { enabled: false, name: "Nombre de cliente", createdAt: "10/08/22: 13:32", updatedAt: "11/08/22: 14:32" },
  { enabled: false, name: "Nombre de cliente", createdAt: "10/08/22: 13:32", updatedAt: "11/08/22: 14:32" },
  { enabled: false, name: "Nombre de cliente", createdAt: "10/08/22: 13:32", updatedAt: "11/08/22: 14:32" },
  { enabled: false, name: "Nombre de cliente", createdAt: "10/08/22: 13:32", updatedAt: "11/08/22: 14:32" },
];
