import React from "react";
import PropTypes from "prop-types";
import { TextField } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { Controller } from "react-hook-form";
import { DEFAULT_DATE_PICKER_PROPS } from "./constant";

export function ProjectOperationsForm({
  control,
  inputRef,
  nameFieldProps,
  descriptionFieldProps,
  codeFieldProps,
  contractNumberFieldProps,
  startAtFieldProps,
  endAtFieldProps,
}) {
  return (
    <>
      <TextField
        id="project-name-field"
        name="name"
        placeholder="Nombre"
        label="Nombre del proyecto"
        // helperText="Nombre del cliente"
        variant="outlined"
        margin="normal"
        type="text"
        autoFocus
        fullWidth
        required
        inputRef={inputRef({ required: "Este campo es obligatorio." })}
        {...nameFieldProps}
      />
      <TextField
        id="project-description-field"
        name="description"
        placeholder="Descripción"
        label="Descripción del proyecto"
        // helperText="Descripción del cliente"
        variant="outlined"
        fullWidth
        type="text"
        margin="normal"
        multiline
        rows={4}
        inputRef={inputRef}
        {...descriptionFieldProps}
      />
      <TextField
        id="project-code-field"
        name="code"
        placeholder="Código ó Abreviación del proyecto"
        label="Código ó Abreviación"
        // helperText="Descripción del cliente"
        variant="outlined"
        fullWidth
        type="text"
        margin="normal"
        inputRef={inputRef}
        {...codeFieldProps}
      />
      <TextField
        id="project-contractNumber-field"
        name="contractNumber"
        placeholder="Número de contrato"
        label="Número de contrato"
        // helperText="Descripción del cliente"
        variant="outlined"
        fullWidth
        type="text"
        margin="normal"
        inputRef={inputRef}
        {...contractNumberFieldProps}
      />
      <Controller
        control={control}
        name="startAt"
        defaultValue={null}
        as={
          <DatePicker
            id="project-startAt-field"
            label="Fecha de inicio del proyecto"
            inputRef={inputRef}
            {...DEFAULT_DATE_PICKER_PROPS}
            {...startAtFieldProps}
          />
        }
      />

      <Controller
        control={control}
        name="endAt"
        defaultValue={null}
        as={
          <DatePicker
            id="project-endAt-field"
            label="Fecha de fin del proyecto"
            inputRef={inputRef}
            {...DEFAULT_DATE_PICKER_PROPS}
            {...endAtFieldProps}
          />
        }
      />
    </>
  );
}

export default ProjectOperationsForm;

ProjectOperationsForm.propTypes = {
  control: PropTypes.any,
  inputRef: PropTypes.any,
  nameFieldProps: PropTypes.shape(),
  descriptionFieldProps: PropTypes.shape(),
  codeFieldProps: PropTypes.shape(),
  contractNumberFieldProps: PropTypes.shape(),
  startAtFieldProps: PropTypes.shape(),
  endAtFieldProps: PropTypes.shape(),
};
