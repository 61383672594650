import { App as CapacitorApp } from "@capacitor/app";

export function initCapacitor() {
  CapacitorApp.addListener("backButton", ({ canGoBack }) => {
    if (!canGoBack) {
      CapacitorApp.exitApp();
    } else {
      window.history.back();
    }
  });
}
