import React, { useContext } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useSelector } from "react-redux";
import SyncIcon from "@material-ui/icons/Sync";
import Tooltip from "@material-ui/core/Tooltip";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import useDataStoreStatus from "hooks/useDataStoreStatus";
import MenuIcon from "@material-ui/icons/Menu";
import ApplicationContext from "context/ApplicationContext";
import { useHeaderStyles as useStyles } from "./index.jss";

function Header({ isDrawerOpen, openDrawer }) {
  const { dataStoreStatus } = useDataStoreStatus();
  const {
    session: { username },
  } = useContext(ApplicationContext.Context);
  const classes = useStyles();
  const title = useSelector((state) => state.app.title);

  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: isDrawerOpen,
      })}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={openDrawer}
          edge="start"
          className={clsx(classes.menuButton, { [classes.hide]: isDrawerOpen })}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" className={classes.title} noWrap>
          {title}
        </Typography>
        <Hidden xsDown>{username}</Hidden>
        {dataStoreStatus === "IN_PROGRESS" && (
          <Box pl={1}>
            <Tooltip title="Sincronizando" arrow>
              <SyncIcon className={classes.spin} />
            </Tooltip>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  isDrawerOpen: PropTypes.bool,
  openDrawer: PropTypes.func,
};

export default Header;
