import { Logger } from "aws-amplify";
const logger = new Logger("manageResponse");

/**
 * Devuelve la información principal de la respuesta de una consulta de graphQL a través de su queryKey
 *
 * @param {Object} graphQLResponse respuesta que da el servicio de AppSync al realizar una consulta
 * Ejemplo:
 * RespuestaDeGraphQL = { queryKey: { items: [], nextToken: "", startedAt: "" } }
 * @param {String} queryKey referencia al key donde se alojan los datos de una consulta de graphql
 * @returns El objeto principal que contiene la información consultada
 */

export function getDataFromGraphQLResponse(graphQLResponse, queryKey) {
  if (!graphQLResponse.hasOwnProperty(queryKey)) {
    const errorMsg = `getDataFromGraphQLResponse: graphQLResponse not contains the following queryKey: ${queryKey}`;
    logger.error(errorMsg, { graphQLResponse });
    throw new Error(errorMsg);
  }

  return graphQLResponse[queryKey];
}
