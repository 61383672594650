export const updateTask = /* GraphQL */ `
  mutation UpdateTask($input: UpdateTaskInput!, $condition: ModelTaskConditionInput) {
    updateTask(input: $input, condition: $condition) {
      id
      status
      startDate
      endDate
      startTime
      endTime
      startedAt
      startedBy
      endedAt
      endedBy
      category
      _version
      _lastChangedAt
      _deleted
      deleted
      remarks {
        items {
          warehouseKeepers
          updatedAt
          taskStatus
          taskID
          supportEngineers
          remark
          supervisors
          id
          createdAt
          _version
          _lastChangedAt
        }
      }
      buildingLocation {
        id
        name
        code
        buildingBlock
        floor
        area
        premisesID
        premises {
          id
          name
          owner
          custodian {
            id
            name
            middlename
            lastnameP
            lastnameM
            email
            phone
            extension
            createdAt
            updatedAt
          }
          address {
            id
            street
            number
            zipcode
            city
            settlement
            locality
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        owner
        createdAt
        updatedAt
      }
      premises {
        id
        name
        owner
        custodian {
          id
          name
          middlename
          lastnameP
          lastnameM
          email
          phone
          extension
          createdAt
          updatedAt
        }
        address {
          id
          street
          number
          zipcode
          city
          settlement
          locality
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      taskType {
        id
        name
        description
        createdAt
        updatedAt
      }
      supervisor {
        id
        name
        middlename
        lastnameP
        lastnameM
        email
        phone
        extension

        createdAt
        updatedAt
      }
      assets {
        items {
          id
          _version
          _deleted
          asset {
            id
            code
            serial
            quantity
            type
            comment
            _version
            _deleted
            material {
              id
              name
              code
              uom {
                name
                abbreviation
              }
            }
          }
        }
        nextToken
      }
      attachments {
        items {
          id
          status
          file {
            key
          }
        }
        nextToken
      }
      notes {
        items {
          id
          taskID
          comments
          supervisor {
            id
            name
            lastnameP
            email
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      project {
        id
        name
        code
        company {
          id
          name
        }
      }
      owner
      createdAt
      updatedAt
      embeddedEvents {
        _version
        createdAt
        description
        duration
        eventCauseID
        finalized
        id
        taskID
        embeddedCause {
          description
          id
          name
          type
          _version
        }
        timestamp
      }
      effectiveTime
      embeddedLastEvent {
        id
        taskID
        eventCauseID
        description
        timestamp
        duration
        finalized
        embeddedCause {
          description
          id
          name
          type
          _version
        }
        _version
        createdAt
      }
      events {
        items {
          timestamp
          taskID
          id
          finalized
          eventCauseID
          duration
          description
          cause {
            type
            name
            id
            description
            _version
          }
        }
      }
      taskTicketId
      ticket {
        id
        subject
        description
        requestType
        impact
        impactDetails
        status
        mode
        supportType
        _version
        _deleted
        _lastChangedAt
      }
      lastEvent {
        timestamp
        taskID
        id
        finalized
        eventCauseID
        duration
        description
        _version
        cause {
          type
          name
          id
          description
          _version
        }
      }
    }
  }
`;

export default updateTask;
