import React, { useRef, useCallback } from "react";
import PropTypes from "prop-types";

import GridListTile from "@material-ui/core/GridListTile";
import { makeStyles } from "@material-ui/core/styles";

function GridListTileCustom({ scrollOnTap, onClick, ...props }) {
  const classes = useStyles();
  const ref = useRef(null);
  const handleClick = useCallback(() => {
    scrollOnTap &&
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    onClick();
  }, [scrollOnTap, onClick]);
  return <GridListTile {...props} ref={ref} onClick={handleClick} className={classes.ImgLoadItem} />;
}

GridListTileCustom.propTypes = {
  onClick: PropTypes.func,
  scrollOnTap: PropTypes.bool,
};

GridListTileCustom.defaultProps = {
  onClick: () => {},
  scrollOnTap: false,
};

const useStyles = makeStyles(() => ({
  ImgLoadItem: {
    margin: "0 0 20px 0",
    width: "50% !important",
    "& .MuiGridListTile-tile": {
      width: "80%",
      margin: "0 auto",
    },
  },
}));

export default GridListTileCustom;
