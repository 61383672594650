import { Storage } from "@aws-amplify/storage";
import { S3_KEY_PREFIXES } from "constant/attachments";
import { captureException } from "setup/sentry";

export class StorageException extends Error {}

export function uploadFileList(files) {
  if (!Array.isArray(files)) {
    throw new Error("Los archivos deben contenerse en un arreglo");
  }
  const pendingUploads = files.map(({ name, blob }) => {
    return Storage.put(name, blob);
  });

  return Promise.all(pendingUploads);
}

export async function uploadFile(fileName, blob, config = {}) {
  return Storage.put(`${fileName}`, blob, config).catch((error) => {
    captureException(error, "uploadFile");
    console.error("uploadFile: error ", error);
    throw new StorageException();
  });
}

export async function readFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        // convert image file to base64 string
        resolve(reader.result);
      },
      false
    );
    if (file) {
      reader.readAsDataURL(file);
    } else {
      reject(null);
    }
  });
}

export function downloadFile(name, { expirationSeconds = 60, ...config } = {}) {
  return Storage.get(name, { expires: expirationSeconds, ...config });
}

export function downloadFromUrl(url, fileName) {
  const link = document.createElement("a");
  link.setAttribute("href", url);
  link.setAttribute("target", "_blank");
  link.setAttribute("download", fileName);
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function deleteFiles(key = "") {
  if (key.startsWith(S3_KEY_PREFIXES.EVIDENCES_COMPRESSED)) {
    return Promise.all([Storage.remove(key), Storage.remove(key.replace("compressed", "uncompressed"))]);
  } else if (key.startsWith(S3_KEY_PREFIXES.EVIDENCES_UNCOMPRESSED)) {
    return Promise.all([Storage.remove(key), Storage.remove(key.replace("uncompressed", "compressed"))]);
  } else {
    return Storage.remove(key);
  }
}

export default {
  StorageException,
  uploadFile,
  readFile,
};
