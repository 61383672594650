import * as ActionTypes from "redux/action";

const INITIAL_STATE = {
  activated: false,
  content: "",
  container: null,
};

export default function barcodeScannerReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ActionTypes.START_BARCODE_SCANNER:
      return { ...state, activated: true, content: "" };
    case ActionTypes.STOP_BARCODE_SCANNER:
      return { ...state, activated: false };
    case ActionTypes.SET_CONTENT_BARCODE_SCANNER:
      return { ...state, content: action.payload.content };
    case ActionTypes.SET_CONTAINER_BARCODE_SCANNER:
      return { ...state, container: action.payload.ref };
    default:
      return state;
  }
}