import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
  },
  counter: (dynamicStyles) => ({
    fontWeight: "500",
    ...dynamicStyles,
  }),
}));

export default useStyles;
