const customConfig = {
  aws_project_region: process.env.REACT_APP_AWS_REGION,
  aws_cognito_identity_pool_id: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_AWS_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_WEB_CLIENT_ID,
  oauth: {},
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT,
  aws_appsync_region: process.env.REACT_APP_AWS_REGION,
  aws_appsync_authenticationType: process.env.REACT_APP_AWS_APPSYNC_AUTHENTICATION_TYPE,
  aws_appsync_apiKey: process.env.REACT_APP_AWS_APPSYNC_API_KEY,
  aws_user_files_s3_bucket: process.env.REACT_APP_AWS_USER_FILES_S3_BUCKET,
  aws_user_files_s3_bucket_region: process.env.REACT_APP_AWS_REGION,
  aws_mobile_analytics_app_id: process.env.REACT_APP_AWS_MOBILE_ANALYTICS_APP_ID,
  aws_mobile_analytics_app_region: process.env.REACT_APP_AWS_REGION,
};

export default customConfig;
