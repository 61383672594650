import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import LoginAppbar from "./LoginAppbar";
import { amplifyServices } from "./helpers";
import addPropsToChildrenDinamically from "util/addPropsToChildrenDinamically";
import {
  SIGNUP_FORM_FIELDS,
  SIGNUP_ATTRIBUTES,
  LOGIN_MECHANISMS,
  CHILDREN_COMPONENT_NAMES,
  CUSTOM_SESSION_ERRORS,
  AUTHENTICATOR_ROUTES,
} from "./constants";
import useNotifier from "hooks/useNotifier";
import amplifyVocabulary from "util/amplifyVocabulary";

//el orden de los siguientes imports es importante
import "@aws-amplify/ui-react/styles.css"; // default theme
import "./styles/global.css";
import amplifyComponents from "./amplifyComponents";
import { Backdrop, CircularProgress } from "@material-ui/core";

const backdropStyles = {
  zIndex: 1500,
  color: "#fff",
};
function LoginView({ children }) {
  const isFirstRenderRef = React.useRef(true);
  const currentChildrenRef = React.useRef(children);
  const { isPending, error, toSignIn, route } = useAuthenticator();
  const { showError } = useNotifier();

  useEffect(() => {
    //Efecto para mover usuario al inicio de sesión y mostrar alerta de error cuando no tiene un grupo asignado
    if (error === CUSTOM_SESSION_ERRORS.USER_HAS_NO_GROUPS && route !== AUTHENTICATOR_ROUTES.SIGNIN) {
      toSignIn();
      showError(amplifyVocabulary.es[CUSTOM_SESSION_ERRORS.USER_HAS_NO_GROUPS]);
    }
  }, [error, toSignIn, showError, route]);

  function handleRenderChildren(props) {
    if (isFirstRenderRef.current) {
      const childrenWithProps = addPropsToChildrenDinamically(children, props, CHILDREN_COMPONENT_NAMES);
      isFirstRenderRef.current = false;
      currentChildrenRef.current = childrenWithProps;
      return childrenWithProps;
    } else {
      return currentChildrenRef.current;
    }
  }

  return (
    <>
      <Backdrop open={isPending === undefined ? false : isPending} style={backdropStyles}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <LoginAppbar />
      <Authenticator
        signUpAttributes={SIGNUP_ATTRIBUTES}
        formFields={SIGNUP_FORM_FIELDS}
        loginMechanisms={LOGIN_MECHANISMS}
        components={amplifyComponents}
        services={amplifyServices}
      >
        {(props) => handleRenderChildren(props)}
      </Authenticator>
    </>
  );
}
LoginView.propTypes = {
  children: PropTypes.node,
};

export default LoginView;
