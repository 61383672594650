function formatCustodianName(custodian) {
  if (custodian) {
    let custodianName = custodian.name;

    if (custodian.middlename)
      custodianName = `${custodianName} ${custodian.middlename}`;
    if (custodian.lastnameP)
      custodianName = `${custodianName} ${custodian.lastnameP}`;
    if (custodian.lastnameM)
      custodianName = `${custodianName} ${custodian.lastnameM}`;
    return custodianName;
  } else {
    return "Sin información";
  }
}

export default formatCustodianName;
