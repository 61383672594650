export const getSetting = /* GraphQL */ `
  query GetSetting($id: ID!, $key: ID!) {
    getSetting(id: $id, key: $key) {
      id
      key
      value
      status
      _version
      _deleted
    }
  }
`;

export default getSetting;
