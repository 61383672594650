import React from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import BrokenImageIcon from "@material-ui/icons/BrokenImage";

export default function BrokenImageOffline({ showBgColor }) {
  const containerStyles = {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 2,
    bgcolor: showBgColor ? "hsl(0 0% 0% / 0.20)" : "transparent",
  };

  const IconStyles = {
    width: "100%",
    height: "100%",
    color: "rgb(255 255 255 / 80%)",
  };

  return (
    <Box {...containerStyles} title="Error al cargar la evidencia" alt="Error al cargar la evidencia">
      <BrokenImageIcon fontSize="large" style={IconStyles} />
    </Box>
  );
}

BrokenImageOffline.propTypes = {
  showBgColor: PropTypes.bool,
};
BrokenImageOffline.defaultProps = {
  showBgColor: false,
};
