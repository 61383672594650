export function generateRelations(taskID, assetsIdsMap = {}, selectedUserIds = []) {
  const variables = {
    taskID,
    // userID: installatorId,
  };
  let mutation = "";
  let mutationParams = "$taskID: ID!";
  const objectAssetskeys = Object.keys(assetsIdsMap);
  if (objectAssetskeys.length > 0) {
    objectAssetskeys.forEach((assetKey, index) => {
      mutationParams += `, $assetKey${index}: ID!`;
      mutation += `
        createAsignation${index}: createTaskAssets(input: {
          taskAssetsTaskId: $taskID
          taskAssetsAssetId: $assetKey${index}
        }) {
          id
          taskAssetsTaskId,
          taskAssetsAssetId,
          task {
            id
            _version
            _deleted
            _lastChangedAt
          }
          scanned
          asset {
            id
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _lastChangedAt
          _deleted
        },
        `;

      variables[`assetKey${index}`] = assetsIdsMap[assetKey].id;
    });
  }
  if (selectedUserIds.length > 0) {
    selectedUserIds.forEach((userId, index) => {
      mutation += `
      asignUser${index}:
        createUserTask(input: { 
          userID: "${userId}",
          taskID: $taskID
      }) {
        id
        userID
        taskID
        task {
          id
          _version
          _deleted
          _lastChangedAt
        }
        user {
          id
          name
          middlename
          lastnameP
          lastnameM
          email
          phone
          extension
          status
          _version
          _deleted
          _lastChangedAt
        }
        _version
        _deleted
        _lastChangedAt
      }${index < selectedUserIds.length - 1 ? "," : ""}
      `;

      variables[`userKey${index}`] = userId;
    });
  }

  mutation = `mutation createAsignations(${mutationParams}) {
      ${mutation}
    }`;

  return {
    relationsVariables: variables,
    relationsMutation: mutation,
  };
}
