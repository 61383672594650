import { combineReducers } from "redux";
import appReducer from "./app";
import sessionReducer from "./session";
import operationReducer from "./operation";
import configurationsReducer from "./configurations";
import themeReducer from "./theme";
import notificationReducer from "./notificationReducer";
import siteOperationsReducer from "./siteOperationsReducer";
import activityTypesReducer from "./activityTypesReducer";
import materialCatalogReducer from "./materialCatalog";
import uomCatalogReducer from "./uomCatalog";
import materialCategoriesReducer from "./materialCategoriesCatalog";
import userListReducer from "./userListReducer";
import adminPremisesListReducer from "./adminPremisesListReducer";
import adminPremisesCreateReducer from "./adminPremisesCreateReducer";
import adminPremisesDetailReducer from "./adminPremisesDetailReducer";
import activeUserReducer from "./activeUserReducer";
import serviceWorkerReducer from "./serviceWorkerReducer";
import barcodeScannerReducer from "./barcodeScannerReducer";
import deviceInfoReducer from "./deviceInfoReducer";

const rootReducer = combineReducers({
  app: appReducer,
  session: sessionReducer,
  operation: operationReducer,
  systemConfigs: configurationsReducer,
  theme: themeReducer,
  notifications: notificationReducer,
  site: siteOperationsReducer,
  activities: activityTypesReducer,
  materialCatalog: materialCatalogReducer,
  uomCatalog: uomCatalogReducer,
  materialCategoriesCatalog: materialCategoriesReducer,
  users: userListReducer,
  activeUser: activeUserReducer,
  adminPremisesList: adminPremisesListReducer,
  adminPremisesCreate: adminPremisesCreateReducer,
  adminPremisesDetail: adminPremisesDetailReducer,
  serviceWorker: serviceWorkerReducer,
  barcodeScanner: barcodeScannerReducer,
  device: deviceInfoReducer,
});

export default rootReducer;
