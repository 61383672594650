import React, { useState, Fragment, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { Link, useRouteMatch, useLocation } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Collapse from "@material-ui/core/Collapse";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import useUpdateEffect from "hooks/useUpdateEffect";
function NestedList({
  items,
  nested,
  handleDrawerClose,
  openDrawer,
  isDrawerOpen,
  isCloseByHeader,
  setIsCloseByHeader,
  ...rest
}) {
  const theme = useTheme();
  const [selectedItem, setSelectedItem] = useState(-1);
  const [nestedOpen, setNestedOpen] = useState(false);
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useListStyles();
  const location = useLocation();

  const handleSectionItemClick = (index) => () => {
    const hasSubItems = items[index].items && items[index].items.length;
    if (matches && !hasSubItems) {
      handleDrawerClose();
    }
    if (hasSubItems) {
      handleClickItemWithSubItems();
    }
    if (nestedOpen) {
      setSelectedItem(-1);
      return;
    }
    setSelectedItem(index);
  };

  const handleClickItemWithSubItems = () => {
    if (!isDrawerOpen) {
      openDrawer();
      setTimeout(setNestedOpen(!nestedOpen), 100);
    } else {
      setNestedOpen(!nestedOpen);
    }
  };

  const handleCloseOnSubItemClick = (index) => {
    handleDrawerClose();
    setSelectedItem(index);
  };

  useUpdateEffect(() => {
    if (!isDrawerOpen) {
      setNestedOpen(false);
    }
    if (isDrawerOpen) {
      setNestedOpen(true);
    }
  }, [isDrawerOpen]);

  useEffect(() => {
    if (isCloseByHeader) {
      setSelectedItem(-1);
      setIsCloseByHeader(false);
    }
  }, [isCloseByHeader, setIsCloseByHeader]);

  return (
    <List {...rest}>
      {items.map((item, index) => {
        const hasSubItems = item.items && item.items.length;
        const { icon: ItemIcon } = item;
        const isSubItemSelected = hasSubItems && item.items.some((item) => item.to === location.pathname);
        const isSelected = selectedItem === index || isSubItemSelected;
        const hasIcon = item.hasOwnProperty("icon") && ItemIcon;
        if (!hasSubItems)
          return (
            <NavigationListItem
              key={`nl-li-${index}`}
              button
              onClick={handleSectionItemClick(index)}
              className={clsx({ [classes.nested]: nested }, "drawer-nav-link")}
              to={item.to}
            >
              {hasIcon && (
                <ListItemIcon>
                  <ItemIcon />
                </ListItemIcon>
              )}
              <ListItemText primary={item.text} primaryTypographyProps={{ className: "drawer-nav-link" }} />
            </NavigationListItem>
          );
        return (
          <Fragment key={`nli-${index}`}>
            <ListItem
              button
              onClick={handleSectionItemClick(index)}
              className={clsx({ [classes.nested]: nested }, "drawer-nav-link")}
              selected={isSelected}
            >
              {hasIcon && (
                <ListItemIcon>
                  <ItemIcon />
                </ListItemIcon>
              )}
              <ListItemText primary={item.text} primaryTypographyProps={{ className: "drawer-nav-link" }} />
              {nestedOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItem>
            <Collapse in={nestedOpen} timeout="auto" unmountOnExit>
              <NestedList
                items={item.items}
                nested
                component="div"
                disablePadding
                handleDrawerClose={() => handleCloseOnSubItemClick(index)}
              />
            </Collapse>
          </Fragment>
        );
      })}
    </List>
  );
}

NestedList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()),
  nested: PropTypes.bool,
  handleDrawerClose: PropTypes.func,
  openDrawer: PropTypes.func,
  isDrawerOpen: PropTypes.bool,
  isCloseByHeader: PropTypes.bool,
  setIsCloseByHeader: PropTypes.func,
};

function NavigationListItem({ children, to, ...props }) {
  const match = useRouteMatch(to);

  return (
    <ListItem {...props} selected={Boolean(match)} component={Link} to={to}>
      {children}
    </ListItem>
  );
}

NavigationListItem.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
};

const useListStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default NestedList;
