import { useEffect, useRef, useState, useMemo } from "react";
import { useLocation } from "react-router-dom";

const useGetLastAndCurrentPathname = () => {
  let location = useLocation();
  const [currentPathname, setCurrentPathname] = useState(location.pathname);
  const lastPathname = useRef("");

  useEffect(() => {
    setCurrentPathname((currentValue) => {
      lastPathname.current = currentValue;
      return location.pathname;
    });
  }, [location.pathname]);

  return useMemo(() => [currentPathname, lastPathname.current], [currentPathname]);
};

export default useGetLastAndCurrentPathname;
