/* eslint-disable react/display-name */
// Vista para el visualizar el catalogo de ingresos de activos a indika
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import SpeedDial from "components/custom/SpeedDial";
import PostAddIcon from "@material-ui/icons/PostAdd";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";

import useNotifier from "hooks/useNotifier";
import { checkNextItems } from "util/lists";
import useLoadingStatus from "hooks/useLoadingStatus";
import useHeaderTitle from "hooks/useHeaderTitle";
import Section from "components/Section";
import MaterialTable from "components/custom/MaterialTable";
import dayjs from "dayjs";

import API, { graphqlOperation } from "@aws-amplify/api";
import * as queries from "graphql-custom/queries";
import cleanUUID from "util/cleanUUID";
import { ASSET } from "constant/route/warehouse";

const GOODS_RECEIPTS_TYPES = {
  CSV_FILE: "Archivo CSV",
};

const SECTION_TITLE = "Ingresos";
export default function GoodsReceiptsView() {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const { showError } = useNotifier();

  useHeaderTitle(SECTION_TITLE);

  const [fetching, _fetchAssetIncomeList] = useLoadingStatus(fetchAssetIncomeList);
  const [assetIncomeList, setAssetIncomeList] = useState([]);
  const [paginationToken, setPaginationToken] = useState(null);
  const [isRequestError, setIsRequestError] = useState(false);
  const [actions] = useState([
    {
      icon: <PostAddIcon />,
      name: "Ingreso por CSV",
      onClick: () => {
        history.push(ASSET.NEW_ASSETS);
      },
    },
  ]);

  useEffect(() => {
    _fetchAssetIncomeList();
  }, []);

  async function fetchAssetIncomeList(nextToken) {
    setIsRequestError(false);
    try {
      const { data } = await API.graphql(graphqlOperation(queries.listGoodsReceipts, { nextToken }));
      const newAssets = data?.listGoodsReceipts?.items || [];
      if (nextToken) {
        setAssetIncomeList(assetIncomeList.concat(newAssets));
      } else {
        setAssetIncomeList(newAssets);
      }
      setPaginationToken(data?.listGoodsReceipts?.nextToken);
    } catch (error) {
      showError("Ocurrió un error.");

      setPaginationToken(null);
      setIsRequestError(true);
      return [];
    }
  }

  const loadMoreGoodsReceipts = async (page) => {
    const hasMoreItems = checkNextItems(page, assetIncomeList.length);
    if (hasMoreItems && paginationToken) {
      await fetchAssetIncomeList(paginationToken);
    }
  };

  const goToAssetIncomeDetails = (event, row) => {
    history.push(`/almacen/ingresos/${row.id}`);
  };

  return (
    <>
      <SpeedDial actions={actions} backdrop tooltipOpen />
      <Box className={classes.mainContainer}>
        {isRequestError && (
          <Box p={2}>
            <Alert severity="error">Ha ocurrido un error con la carga del catálogo, recarga la página por favor.</Alert>
          </Box>
        )}
        <Section>
          <div className={classes.root}>
            <MaterialTable
              title="Ingreso de Activos"
              options={tableOptions}
              columns={!matches ? TABLE_COLUMNS : TABLE_COLUMNS_MOBILE}
              data={assetIncomeList}
              isLoading={fetching}
              onRowClick={goToAssetIncomeDetails}
              onChangePage={loadMoreGoodsReceipts}
            />
          </div>
        </Section>
      </Box>
    </>
  );
}

const tableOptions = {
  exportButton: false,
  columnsButton: false,
  thirdSortClick: false,
};

const TABLE_COLUMNS = [
  { title: "Folio", field: "id", render: (rowData) => cleanUUID(rowData.id) },
  {
    title: "Fecha/Hora",
    field: "createdAt",
    type: "datetime",
    defaultSort: "desc",
    render: (rowData) => `${dayjs(rowData?.createdAt).format("DD/MM/YYYY HH:mm")}`,
    customFilterAndSearch: (value, rowData) => {
      return dayjs(rowData?.createdAt).format("DD/MM/YYYY HH:mm")?.toLowerCase().includes(value.toLowerCase());
    },
  },
  {
    title: "Tipo",
    field: "type",
    render: (rowData) => GOODS_RECEIPTS_TYPES[rowData.type],
    customFilterAndSearch: (value, rowData) => {
      return GOODS_RECEIPTS_TYPES[rowData.type]?.toLowerCase().includes(value.toLowerCase());
    },
  },
  {
    title: "Archivo CSV",
    field: "fileName",
    render: (rowData) => rowData?.fileName || "Sin información",
  },
  {
    title: "Cantidad",
    field: "quantity",
  },
  {
    title: "Usuario",
    field: "userName",
    render: (rowData) => `${rowData?.user?.name} ${rowData?.user?.lastnameP} (${rowData?.user?.email})`,
    customFilterAndSearch: (value, rowData) => {
      return `${rowData?.user?.name} ${rowData?.user?.lastnameP} (${rowData?.user?.email})`
        ?.toLowerCase()
        .includes(value.toLowerCase());
    },
  },
  {
    title: "Cliente",
    field: "project.company.name",
    render: (rowData) => rowData?.project?.company?.name || "Sin asignación",
  },
  {
    title: "Proyecto",
    field: "project.name",
    render: (rowData) =>
      rowData?.project ? `${rowData?.project?.name} (${rowData?.project?.code})` : "Sin asignación",
  },
];

const TABLE_COLUMNS_MOBILE = [
  {
    title: "",
    field: "asset.id",
    customFilterAndSearch: (value, rowData) => {
      const parsedValue = value.toLowerCase();
      const FULL_NAME = `${rowData?.user?.name} ${rowData?.user?.lastnameP} (${rowData?.user?.email})`;
      return (
        GOODS_RECEIPTS_TYPES[rowData.type].toLowerCase().includes(parsedValue) ||
        FULL_NAME.toLowerCase().includes(parsedValue) ||
        rowData?.id?.toLowerCase().includes(parsedValue) ||
        rowData?.createdAt?.toLowerCase().includes(parsedValue) ||
        rowData?.fileName?.toLowerCase().includes(parsedValue) ||
        rowData?.quantity?.toString().toLowerCase().includes(parsedValue) ||
        rowData?.project?.company?.name.toLowerCase().includes(parsedValue) ||
        rowData?.project?.name.toLowerCase().includes(parsedValue) ||
        rowData?.project?.code.toLowerCase().includes(parsedValue)
      );
    },
    render: (rowData) => {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <strong>Folio: </strong>
            {cleanUUID(rowData.id)}
          </Grid>
          <Grid item xs={12}>
            <strong>Fecha y Hora: </strong>
            {dayjs(rowData?.createdAt).format("DD/MM/YYYY HH:mm")}
          </Grid>
          <Grid item xs={12}>
            <strong>Tipo: </strong>
            {GOODS_RECEIPTS_TYPES[rowData.type]}
          </Grid>
          <Grid item xs={12}>
            <strong>Archivo CSV: </strong>
            {rowData.fileName || "Sin información"}
          </Grid>
          <Grid item xs={12}>
            <strong>Cantidad: </strong>
            {rowData.quantity}
          </Grid>
          <Grid item xs={12}>
            <strong>Usuario: </strong>
            {`${rowData?.user?.name} ${rowData?.user?.lastnameP} (${rowData?.user?.email})`}
          </Grid>
          <Grid item xs={12}>
            <strong>Cliente: </strong>
            {rowData?.project?.company ? rowData?.project?.company?.name : "Sin asignación"}
          </Grid>
          <Grid item xs={12}>
            <strong>Proyecto: </strong>
            {rowData?.project ? `${rowData?.project?.name} (${rowData?.project?.code})` : "Sin asignación"}
          </Grid>
        </Grid>
      );
    },
  },
];

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: "#FFF",
  },
  root: {
    [theme.breakpoints.down("sm")]: {
      "& .MuiTableHead-root": {
        display: "none",
      },
      "& .MuiToolbar-root": {
        flexDirection: "column",
        padding: theme.spacing(2),
      },
      "& div[class*='MTableToolbar-title-']": {
        order: 1,
        textAlign: "left",
        width: "100%",
      },
      "& div[class*='MTableToolbar-searchField-']": {
        width: "100%",
        order: 0,
        marginBottom: theme.spacing(2),
      },
    },
  },
}));
