import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Box, Typography, IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const GoBackButton = ({ title, ...props }) => {
  const history = useHistory();

  function goBack() {
    history.goBack();
  }

  return (
    <Box display="flex" alignItems="center">
      <IconButton onClick={goBack} {...props}>
        <ArrowBackIcon />
      </IconButton>
      {Boolean(title) && (
        <Typography variant="button" color="textSecondary">
          {title}
        </Typography>
      )}
    </Box>
  );
};

export default GoBackButton;

GoBackButton.propTypes = {
  title: PropTypes.string,
};

GoBackButton.defaultProps = {
  title: "Regresar",
};
