/* eslint-disable react/display-name */
import React, { useCallback, useEffect, useState } from "react";
import { Logger } from "@aws-amplify/core";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";

import Button from "components/custom/Button";
import TabContainer from "components/Tabs/TabContainer";
import Title from "components/common/Title";
import ImplementationTable from "components/table/ImplementationTable";
import useHeaderTitle from "hooks/useHeaderTitle";
import useLoadingStatus from "hooks/useLoadingStatus";
import useNotifier from "hooks/useNotifier";
import useFetchTasks from "hooks/useFetchTasks";
import { TaskCategory, TaskStatus } from "models";
import * as ROLES from "constant/roles";
import { checkNextItems } from "util/lists";
import { styleStatus, getStyleForTab, SECTIONS } from "util/homologationOfColumns";
import { ASSETS } from "constant/route/operation";

// CUSTOM STYLES
const useStyles = makeStyles((theme) => styleStatus(SECTIONS.IMPLEMENTATION));

// CUSTOM SELECTED STYLES
const useStylesSelected = makeStyles(getStyleForTab(SECTIONS.IMPLEMENTATION));

const isWeb = window.matchMedia("(min-width: 768px)").matches;
const SECTION_TITLE = !isWeb ? "INSTALACIONES" : "";
const DEFAULT_ROWS_PER_PAGE = 20;

const logger = new Logger("MyAssetsInstallationsView");

function MyAssetsInstallationsView() {
  useHeaderTitle(SECTION_TITLE);
  const history = useHistory();
  const [loadError, setLoadError] = useState(false);
  const [installationList, setInstallationList] = useState([]);
  const [currentPage, setCurrentPage] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [loading, _loadInstallations] = useLoadingStatus(loadInstallations);
  const { fetchTaskListWithDS } = useFetchTasks();
  const userId = useSelector(({ session }) => session.userId);
  const notifier = useNotifier();

  const loadNextInstallations = useCallback((page, pageSize) => {
    const cPage = currentPage || 0;
    const hasRemainingItems = checkNextItems(page, installationList.length, pageSize);
    hasRemainingItems && _loadInstallations({ customPage: cPage + 1 });
  }, []);

  const handleTableRowClick = useCallback((event, item) => {
    history.push(`${ASSETS.INSTALLATION}/${item.id}`);
  }, []);

  const tableProps = {
    data: installationList,
    loading,
    onChangePage: loadNextInstallations,
    onChangeRowsPerPage: setRowsPerPage,
    onRowClick: handleTableRowClick,
  };

  // RENDER COMPONENT TABS
  const listTableGeneral = <ImplementationTable status={TaskStatus.GENERAL} {...tableProps} />;
  const listTableSheduled = <ImplementationTable status={TaskStatus.SCHEDULED} {...tableProps} />;
  const listTableInProgres = <ImplementationTable status={TaskStatus.IN_PROGRESS} {...tableProps} />;
  const listTableWithNotes = <ImplementationTable status={TaskStatus.WITH_INSPECTION_NOTES} {...tableProps} />;
  const listTableComplete = <ImplementationTable status={TaskStatus.COMPLETED} {...tableProps} />;
  const listTableReviewed = <ImplementationTable status={TaskStatus.REVIEWED} {...tableProps} />;
  const listTableFinalized = <ImplementationTable status={TaskStatus.FINALIZED} {...tableProps} />;

  const stylesTabs = useStyles();
  const stylesSelected = useStylesSelected();

  // Obj to define tabs
  const tabData = [
    {
      name: "General",
      index: 0,
      component: listTableGeneral,
      styles: TaskStatus.GENERAL,
    },
    {
      name: "Asignados",
      index: 1,
      component: listTableSheduled,
      styles: TaskStatus.SCHEDULED,
    },
    {
      name: "En progreso",
      index: 2,
      component: listTableInProgres,
      styles: TaskStatus.IN_PROGRESS,
    },
    {
      name: "Por Firmar",
      index: 3,
      component: listTableComplete,
      styles: TaskStatus.COMPLETED,
    },
    {
      name: "Con observaciones",
      index: 4,
      component: listTableWithNotes,
      styles: TaskStatus.WITH_INSPECTION_NOTES,
    },
    {
      name: "Validado",
      index: 5,
      component: listTableFinalized,
      styles: TaskStatus.FINALIZED,
    },
    {
      name: "Finalizado",
      index: 6,
      component: listTableReviewed,
      styles: TaskStatus.REVIEWED,
    },
  ];

  useEffect(() => {
    _loadInstallations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTabIndex, rowsPerPage]);

  async function loadInstallations({ customPage = 0 } = {}) {
    setLoadError(false);
    try {
      const tabStatus = TAB_TASK_STATUSES[activeTabIndex];
      let { items: installations, page } = await fetchTaskListWithDS({
        category: TaskCategory.IMPLEMENTATION,
        page: customPage,
        limit: rowsPerPage * 20,
        status: tabStatus,
        userRole: ROLES.SUPPORT_ENGINEERS,
        userId: userId,
      });
      setInstallationList((installationList) => {
        if (customPage) {
          return [...installationList, ...installations];
        } else {
          return installations;
        }
      });
      setCurrentPage(page);
    } catch (err) {
      logger.error(err);
      notifier.showError("Ocurrió un error al consultar los listados");
      setLoadError(true);
    }
  }

  return (
    <>
      {loadError && (
        <Box mb={2}>
          <Alert severity="error">
            Ocurrió un error al cargar los listados de actividaes. Intente{" "}
            <Button onClick={_loadInstallations} color="secondary" size="small">
              cargar los datos
            </Button>{" "}
            nuevamente.
          </Alert>
        </Box>
      )}
      {isWeb && <Title>INSTALACIONES</Title>}

      <TabContainer
        tabData={tabData}
        stylesTabs={stylesTabs}
        stylesSelected={stylesSelected}
        onChangeTab={setActiveTabIndex}
      />
    </>
  );
}

const TAB_TASK_STATUSES = {
  0: TaskStatus.GENERAL,
  1: TaskStatus.SCHEDULED,
  2: TaskStatus.IN_PROGRESS,
  3: TaskStatus.COMPLETED,
  4: TaskStatus.WITH_INSPECTION_NOTES,
  5: TaskStatus.FINALIZED, // Se utiliza status finalized para instalaciones validadas y firmadas por el cliente.
  6: TaskStatus.REVIEWED, // status final disparado por usuario coordinador
};

export default MyAssetsInstallationsView;
