export function transformHeader(header = "") {
  return header.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export function normalizeTableColumns(tableColumns, config = { useFieldProp: false }) {
  return tableColumns.reduce((columnsObj, column) => {
    let { title, required, field, unique = false, ...rest } = column;
    const header = transformHeader(config.useFieldProp ? field : title);
    if (required) {
      columnsObj.requiredCols.push(header);
      title = title + " *";
    }
    if (unique) {
      columnsObj.uniqueCols.push(header);
    }
    columnsObj.tableCols.push({ ...rest, title, field, header, unique });
    return columnsObj;
  }, { requiredCols: [], uniqueCols: [], tableCols: [] });
}

export function generateTableData(rows = [], tableColumns = [], requiredColumns = [], uniqueColumns = []) {
  // Creación de objeto que mantiene como propiedades los nombres de las columnas requeridas con sus valores respectivos
  const uniqueColumnsMap = createUniqueColumnsMap(uniqueColumns);

  // Generación de registros de la tabla de datos
  return rows.reduce((tableRows, { data: rowData }, rowIndex) => {
    // Validación de si la fila contiene los campos requeridos
    const hasRequiredColumns = requiredColumns.every(column => {
      const value = rowData[column] || ""; 
      return value.trim().length;
    });

    // Extracción de la información usando el "field" del objeto fila y el "header" de las columnas de la tabla
    const data = tableColumns.reduce((rowObj, column) => {
      const { field, header, unique } = column;
      rowObj[field] = rowData[header];
      rowObj.hasRequiredColumns = hasRequiredColumns;
      if (unique) {
        const duplicatedCol = checkColumnDuplication(uniqueColumnsMap, header, rowData, rowIndex);
        rowObj.hasDuplicatedColumns = duplicatedCol;
      }
      return rowObj;
    }, {});

    tableRows.push(data);
    return tableRows;
  }, []);
}

function createUniqueColumnsMap(uniqueColumns) {
  return uniqueColumns.reduce((fields, field) => {
    fields[field] = {};
    return fields;
  }, {});
}

function checkColumnDuplication(uniqueColumnsMap, header, rowData, rowIndex) {
  const uniqueColumn = uniqueColumnsMap[header];
  const value = (rowData[header] || "").trim();
  let isDuplicated = false;
  if (uniqueColumn && isNaN(uniqueColumn[value])) {
    uniqueColumn[value] = rowIndex;
  } else {
    isDuplicated = true;
  }
  return isDuplicated;
}