import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Box from "@material-ui/core/Box";

import Button from "components/custom/Button";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

import makeStyles from "@material-ui/core/styles/makeStyles";

import EvidenceViewV2 from "components/common/EvidenceViewV2";

import { TaskStatus } from "models";
import { TASK_TYPES } from "constant/task/types";
import { ACTIVITY_CATEGORY_BY_TASK_TYPE, S3_KEY_PREFIXES } from "constant/attachments";

function EvidenceViewInstall({
    taskId,
    // ticketId,
    taskType,
    // initialAttachments,
    // initialMaterials,
    // addOptimisticMaterial,
    // deleteOptimisticMaterial,
    // updateOptimisticMaterial,
    // evidenceRef,
    // attachmentsCount,
    // ticketAssetId,
    position,
    loadingPosition,
    positionError,
    ...props
}) {

    const [attachmentsDialogOpen, setAttachmentsDialogOpen] = useState(false);
    const [attachments, setAttachments] = useState([]);
    const [evidenceLoading, setEvidenceLoading] = useState(false);

    const evidenceRef2 = useRef();

    const attachmentPrefix = `${S3_KEY_PREFIXES.EVIDENCES_UNCOMPRESSED}/${ACTIVITY_CATEGORY_BY_TASK_TYPE[taskType]}/${taskId}`;

    function handleAddAttachmentDialogBtnClick() {
        setAttachmentsDialogOpen(true);
    }

    function handleAddAttachmentDialogBtnCancel() {
        setAttachmentsDialogOpen(false);
    }

    function addAttachment(attachment) {
        props.addOptimisticAttachments([attachment]);
    }

    function deleteAttachment(attachmentId) {
        props.deleteOptimisticAttachment(attachmentId);
    }
    return (
        <div>
            <EvidenceViewV2
                taskId={taskId}
                prefix={attachmentPrefix}
                status={TaskStatus.IN_PROGRESS} //...
                min={4}
                ref={evidenceRef2}
                inputName="inProgress_inputFile"
                attachments={attachments}
                loading={evidenceLoading}
                onLoading={setEvidenceLoading}
                onDeleteItem={deleteAttachment}
                onEvidenceChange={addAttachment}
                taskType={taskType}
                position={position}
                loadingPosition={loadingPosition}
                positionError={positionError}
            />
        </div>
    )
}

export default EvidenceViewInstall

EvidenceViewInstall.propTypes = {
    title: PropTypes.string,
    taskId: PropTypes.string,
    taskType: PropTypes.oneOf(Object.values(TASK_TYPES)),
    inputName: PropTypes.string,
    limit: PropTypes.number,
    // Prefijo a utilizar al almacenar las evidencias
    prefix: PropTypes.string,
    status: PropTypes.oneOf([TaskStatus.SCHEDULED, TaskStatus.IN_PROGRESS, TaskStatus.COMPLETED]).isRequired,
    min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onLoading: PropTypes.func,
    // Bandera para mostrar un estado de carga
    loading: PropTypes.bool,
    addOptimisticAttachments: PropTypes.func,
    deleteOptimisticAttachment: PropTypes.func,
    withComments: PropTypes.bool,
    // attachments: PropTypes.array,
    evidenceListHeight: PropTypes.string,
    position: PropTypes.object,
    loadingPosition: PropTypes.bool,
    positionError: PropTypes.object,
    // step
    // handleNext: PropTypes.func,
    handleOpenDialogConfim: PropTypes.func

};

EvidenceViewInstall.defaultProps = {
    title: "",
    taskId: "",
    taskType: TASK_TYPES.SUPPORT,
    prefix: "",
    status: TaskStatus.IN_PROGRESS,
    min: undefined,
    onLoading: () => { },
    loading: false,
    addOptimisticAttachments: () => { },
    deleteOptimisticAttachment: () => { },
    withComments: false,
    // attachments: undefined,
    evidenceListHeight: "auto",
    loadingPosition: false,
    // handleNext: () => { },
    handleOpenDialogConfim: () => { }

};
