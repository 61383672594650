// import listAssets from "./helpers/graphql/queries/listAssets";
import { getAssetsByProject, listAssets } from "graphql-custom/queries";
import useGraphQL from "hooks/useGraphQL";
import useAsync from "hooks/useAsync";
import { AssetType } from "models";
import retriveAllItemsRecursively from "util/graphql/retriveAllItemsRecursively";

const DEFAULT_NOTIFICATIONS = { errorMsg: "Ocurrio un error obteniendo el listado de activos.", successMsg: "" };
const DEFAULT_VARIABLES = {
  limit: 500,
  filter: {
    and: [
      {
        type: {
          ne: AssetType.MATERIAL_ALLOCATED,
        },
      },
      {
        type: {
          ne: AssetType.MATERIAL_USED,
        },
      },
      {
        type: {
          ne: AssetType.TEMPORAL,
        },
      },
    ],
  },
};
const DEFAULT_QUERY_CONFIG = { variables: DEFAULT_VARIABLES, notifications: DEFAULT_NOTIFICATIONS };

const DEFAULT_CONFIG = { queryConfig: DEFAULT_QUERY_CONFIG, listAll: false };

export const FILTER_MODELS = {
  ALL: "ALL",
  PROJECT: "PROJECT",
};

export function useListAssetsBy(initialAssets = []) {
  const { runGraphQLOperation, loading } = useGraphQL();
  const asyncState = useAsync({ data: { items: initialAssets, nextToken: null, startedAt: null } || [] });

  function listAll(config = DEFAULT_CONFIG) {
    const promiseToSolve = getPromiseToSolve(listAssets, "listAssets", runGraphQLOperation, config);

    if (asyncState.data?.items?.length) {
      return promiseToSolve.then((response) =>
        asyncState.setData({ ...response, items: [...response.items, ...asyncState.data.items] })
      );
    }

    asyncState.run(promiseToSolve);
    return promiseToSolve;
  }

  function listByProjectId(projectId = "", config = DEFAULT_CONFIG) {
    if (!projectId) throw new Error("You must specify a projectId when using listByProjectId");
    config = { ...DEFAULT_CONFIG, ...config };
    config.queryConfig.variables.assetProjectId = projectId;
    const promiseToSolve = getPromiseToSolve(getAssetsByProject, "assetsByProject", runGraphQLOperation, config);

    if (asyncState.data?.items?.length) {
      return promiseToSolve.then((response) =>
        asyncState.setData({ ...response, items: [...response.items, ...asyncState.data.items] })
      );
    }

    asyncState.run(promiseToSolve);
    return promiseToSolve;
  }

  function filterByModelAndId(model, id = "", config) {
    switch (model) {
      case FILTER_MODELS.ALL: {
        return listAll(config);
      }
      case FILTER_MODELS.PROJECT: {
        return listByProjectId(id, config);
      }
      default:
        return listAll(config);
    }
  }

  return { ...asyncState, isLoading: asyncState.isLoading || loading, listAll, listByProjectId, filterByModelAndId };
}

export default useListAssetsBy;

function getPromiseToSolve(operation, queryKey, runGraphQLOperation, config = {}) {
  let promiseToSolve = null;
  const variables = { ...DEFAULT_VARIABLES, ...config?.queryConfig?.variables };
  const notifications = { ...DEFAULT_NOTIFICATIONS, ...config?.queryConfig?.notifications };
  const queryParams = {
    operation,
    variables: variables,
    notifications: notifications,
  };

  if (config.listAll) {
    const listAssetsQuery = (nextToken) => {
      queryParams.variables.nextToken = nextToken;
      return runGraphQLOperation(queryParams);
    };

    promiseToSolve = retriveAllItemsRecursively(listAssetsQuery, queryKey);
  } else {
    if (config.queryConfig.variables?.limit >= DEFAULT_VARIABLES.limit) {
      const iterations = Math.ceil(config.queryConfig.variables?.limit / DEFAULT_VARIABLES.limit) + 1;

      const listAssetsQuery = (nextToken) => {
        queryParams.variables.nextToken = nextToken;
        queryParams.variables.limit = DEFAULT_VARIABLES.limit;
        return runGraphQLOperation(queryParams);
      };
      promiseToSolve = retriveAllItemsRecursively(
        listAssetsQuery,
        queryKey,
        queryParams.variables.nextToken || null,
        iterations
      );
    } else {
      promiseToSolve = runGraphQLOperation(queryParams).then((response) => response[queryKey]);
    }
  }

  return promiseToSolve;
}
