import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { CSVReader, jsonToCSV } from "react-papaparse";
import API, { graphqlOperation } from "@aws-amplify/api";
import makeStyles from "@material-ui/core/styles/makeStyles";
import green from "@material-ui/core/colors/green";
import GetAppIcon from "@material-ui/icons/GetApp";
import PublishIcon from "@material-ui/icons/Publish";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Box from "@material-ui/core/Box";
import FloatingButton from "components/FloatingButton";
import Button from "components/custom/Button";
import MaterialTable from "components/custom/MaterialTable";
import ConfirmationDialog from "components/ConfirmationDialog";
import DetailTitle from "components/DetailTitle";
import { uploadFileList, downloadFile, downloadFromUrl } from "util/file";
import { useSelector } from "react-redux";
import useHeaderTitle from "hooks/useHeaderTitle";
import useFlag from "hooks/useBooleanFlag";
import useNotifier from "hooks/useNotifier";
import useLoadingStatus from "hooks/useLoadingStatus";

import * as queries from "graphql-custom/queries";
import * as JOB_STATE from "constant/jobRunState";
import { generateTableData, normalizeTableColumns, transformHeader } from "util/csvLoad";
import { Logger } from "@aws-amplify/core";

const logger = new Logger("DataLoadView");

const getCsvBlob = (data) => {
  const materials = data.map(({ tableData, index, hasRequiredColumns, ...rest }) => ({
    ...rest,
  }));
  const csv = jsonToCSV(materials);
  return new Blob([csv], { type: "text/csv;charset=utf-8;" });
};

const createFileList = (prefix, csvBlob, userId) => {
  const timestamp = Date.now();
  const fileList = [];
  const csvName = `etl/flatfiles/${prefix}/dl-${userId}-${timestamp}.csv`;
  fileList.push({ name: csvName, blob: csvBlob });
  return fileList;
};

const EXECUTION_LIMIT = 30; // Equivale a 5 minutos de espera en procesamiento de archivo
let executionCount = 0;
/**
 * Realiza una consulta del estado de job especificado como parámetro.
 *
 * @param {string} job Nombre de job consultado
 * @returns Alguno de los siguientes valores de JOB_STATE
 */
async function fetchJobStatus(job) {
  return new Promise((resolve, reject) => {
    // Se demora 10 segundos la ejecución de la consulta para evitar muchas
    // consultas en tiempos cortos que producirán un mismo resultado.
    setTimeout(() => {
      if (executionCount === EXECUTION_LIMIT) {
        executionCount = 0;
        return JOB_STATE.TIMEOUT;
      }
      executionCount++;
      return API.graphql(graphqlOperation(queries.getJobRunState, { input: { job } }))
        .then((result) => resolve(result.data.lambdaJobsPolling.jobRunState))
        .catch(reject);
    }, 10000);
  });
}

/**
 * Realiza una consulta del estado de job especificado como parámetro. La función
 * se ejecuta recursivamente hasta obtener alguno de los resultados finales.
 *
 * @param {string} job Nombre de job consultado
 * @returns Alguno de los siguientes valores: JOB_STATE.SUCCEEDED, JOB_STATE.FAILED, JOB_STATE.TIMEOUT,
 */
async function pollJobStatus(job) {
  const status = await fetchJobStatus(job);

  switch (status) {
    // Invocación recursiva hasta tener un resultado final
    case JOB_STATE.RUNNING:
    case JOB_STATE.STOPPING:
    case JOB_STATE.STARTING:
      return pollJobStatus(job);

    default:
      return status;
  }
}

const validate = (tableData) => {
  if (!tableData.length) {
    return "Error al cargar registros. Verifique que se haya realizado la carga de materiales.";
  } else {
    return null;
  }
};

const useStyles = makeStyles((theme) => ({
  downloadButton: {
    backgroundColor: theme.palette.getContrastText(green[900]),
  },
}));

function DataLoadView(props) {
  useHeaderTitle(props.appbarTitle);
  const userId = useSelector(({ session }) => session.userId);
  const classes = useStyles();
  const [tableData, setTableData] = useState([]);
  const uploadButtonRef = useRef(null);
  const { showError, showMessage } = useNotifier();
  const [processing, setProcessing] = useState(false);
  const [removeDialogOpen, openRemoveDialog, closeRemoveDialog] = useFlag();
  const [confirmDialogOpen, openConfirmDialog, closeConfirmDialog] = useFlag();
  const [successDialogOpen, openSuccessDialog, closeSuccessDialog] = useFlag();
  const [uploading, _loadDataFile] = useLoadingStatus(loadDataFile);
  const [tableColumns, setTableColumns] = useState([]);
  const [requiredColumns, setRequiredColumns] = useState([]);

  useEffect(() => {
    if (props.tableColumns?.length) {
      const { requiredCols, tableCols } = normalizeTableColumns(props.tableColumns);
      setRequiredColumns(requiredCols);
      setTableColumns(tableCols);
    }
  }, [props.tableColumns]);

  const initTableData = (rows) => {
    const tableData = generateTableData(rows, tableColumns, requiredColumns);
    setTableData(tableData);

    const excludedRows = rows.length - tableData.length;
    if (excludedRows === 1) {
      showMessage(`Se excluyó ${excludedRows} registro con columnas requeridas incompletas.`);
    } else if (excludedRows > 1) {
      showMessage(`Se excluyeron ${excludedRows} registros con columnas requeridas incompletas.`);
    }
  };

  const openFilePicker = (e) => {
    if (uploadButtonRef.current) {
      uploadButtonRef.current.open(e);
    }
  };

  const confirmRemoveFile = (e) => {
    if (uploadButtonRef.current) {
      uploadButtonRef.current.removeFile(e);
    }
  };

  const removeFile = () => {
    setTableData([]);
    closeRemoveDialog();
  };

  const updateRow = async (newRowData, oldRowData) => {
    const newRows = [...tableData];
    const index = newRows.findIndex((item) => item.index === oldRowData.index);
    newRows[index] = newRowData;
    setTableData(newRows);
  };

  const deleteRow = async (oldRowData) => {
    const newRows = [...tableData];
    const index = newRows.findIndex((item) => item.index === oldRowData.index);
    newRows.splice(index, 1);
    setTableData(newRows);
  };

  const validateData = () => {
    const error = validate(tableData);
    if (error) {
      showError(error);
    } else {
      openConfirmDialog();
    }
  };

  async function loadDataFile() {
    const csvBlob = getCsvBlob(tableData);
    const fileList = createFileList(props.dataLoadPrefix, csvBlob, userId);

    await uploadFileList(fileList);
    setProcessing(true);
    const status = await pollJobStatus(props.job);
    setProcessing(false);

    if (status !== JOB_STATE.SUCCEEDED) throw Error();
  }

  const downloadTemplate = async () => {
    try {
      const url = await downloadFile(props.templatePath);
      downloadFromUrl(url, props.templateName);
    } catch (error) {
      console.error(error);
      showError("Ocurrió un error al descargar la plantilla");
    }
  };

  const confirmNotification = (event) => {
    closeSuccessDialog();
    setTableData([]);
    confirmRemoveFile(event);
  };

  async function handleConfirmBtnClick() {
    try {
      await _loadDataFile();
    } catch (error) {
      logger.error(error);
      showError("Ocurrió un en el procesamiento de tu archivo");
    }
    closeConfirmDialog();
    openSuccessDialog();
  }

  return (
    <>
      <Box mb={2}>
        <DetailTitle title={props.sectionTitle} to={props.goBackTo} btnSize="small" />
      </Box>
      <Typography variant="body2" component="div">
        {props.description}
        <Typography paragraph>
          <strong>Las columnas marcadas con * son requeridas en los registros cargados.</strong>
        </Typography>
      </Typography>
      <Box my={2} display="flex">
        <GrowDiv />
        <Button
          onClick={downloadTemplate}
          className={classes.downloadButton}
          variant="outlined"
          color="primary"
          startIcon={<GetAppIcon />}
        >
          Descargar plantilla
        </Button>
      </Box>
      <Card>
        <MaterialTable
          options={{ exportButton: false, columnsButton: false }}
          columns={tableColumns}
          data={tableData}
          editable={{
            onRowUpdate: updateRow,
            onRowDelete: deleteRow,
          }}
        />
        <CardActions>
          <GrowDiv />
          <Button onClick={openRemoveDialog} size="large">
            Borrar Registros
          </Button>
          <Button onClick={validateData} size="large" color="primary">
            Cargar Registros
          </Button>
        </CardActions>
      </Card>
      <CSVReader
        ref={uploadButtonRef}
        onFileLoad={initTableData}
        onError={showError}
        onRemoveFile={removeFile}
        config={{ header: true, transformHeader, skipEmptyLines: "greedy" }}
        noDrag
        noProgressBar
      >
        {({ file }) => {
          if (file?.name) {
            return (
              <ConfirmationDialog
                open={removeDialogOpen}
                title="Confirmación"
                onConfirm={confirmRemoveFile}
                onCancel={closeRemoveDialog}
              >
                ¿Deseas eliminar la información?
              </ConfirmationDialog>
            );
          }
          return (
            <>
              <FloatingButton
                bottom={15}
                right={15}
                color="primary"
                variant="extended"
                aria-label="Agregar Integrante"
                onClick={openFilePicker}
              >
                <PublishIcon />
                Subir archivo CSV
              </FloatingButton>
            </>
          );
        }}
      </CSVReader>
      <ConfirmationDialog
        open={confirmDialogOpen}
        onConfirm={handleConfirmBtnClick}
        onCancel={closeConfirmDialog}
        loading={uploading}
        title="Confirmación"
      >
        {processing ? (
          <>
            <Typography paragraph>Procesando archivo, esto puede tomar unos minutos.</Typography>
            <Typography paragraph>Espere por favor.</Typography>
          </>
        ) : (
          <>
            <Typography paragraph>Realizará una carga de todos los registros.</Typography>
            <Typography paragraph>¿Desea continuar?</Typography>
          </>
        )}
      </ConfirmationDialog>
      <Dialog open={successDialogOpen} maxWidth="lg">
        <DialogTitle>¡Carga de de registros exitosa!</DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            Se han cargado {tableData.length} registros al sistema. Ahora podrá utilizarlos para las actividades que
            requiera.
          </Typography>
          <br />
          <Typography variant="body2">¡Siga utilizando Indika Tracking!</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={confirmNotification} color="default">
            Regresar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

DataLoadView.propTypes = {
  appbarTitle: PropTypes.string.isRequired,
  sectionTitle: PropTypes.string.isRequired,
  description: PropTypes.element.isRequired,
  templatePath: PropTypes.string.isRequired,
  templateName: PropTypes.string.isRequired,
  job: PropTypes.string.isRequired,
  dataLoadPrefix: PropTypes.string.isRequired,
  tableColumns: PropTypes.arrayOf(PropTypes.shape()),
  goBackTo: PropTypes.string.isRequired,
};
DataLoadView.defaultProps = {};

function GrowDiv() {
  return <div style={{ flexGrow: 1 }}></div>;
}

export default DataLoadView;
