/* eslint-disable no-unused-expressions */
/* eslint-disable react/display-name */
import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Logger } from "@aws-amplify/core";
import Auth from "@aws-amplify/auth";
import { CsvBuilder } from "filefy";
import { useSelector } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import makeStyles from "@material-ui/styles/makeStyles";
import RefreshIcon from "@material-ui/icons/Refresh";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import { PDF_REPORT_BUTTON_STYLES } from "components/site/constants";
import PDFReportButton from "components/general/Buttons/PDFReportButton";
import MaterialTable from "components/custom/MaterialTable";
import useLoadingStatus from "hooks/useLoadingStatus";
import useHeaderTitle from "hooks/useHeaderTitle";
import { checkLastPage } from "util/lists";
import * as ROLES from "constant/roles";
import { TICKET_STATUSES, TICKET_IMPACTS } from "constant/ticketConstants";
import { fetchTicketsList } from "datastore";
import DescriptionPanel from "components/common/DescriptionPanel";
import {
  getTableColumns,
  MODULES,
  SECTIONS,
  getDataTable,
  MAX_BODY_HEIGHT,
  getPageSize,
} from "../../../util/homologationOfColumns";

const logger = new Logger("MyTicketsListView");

export default function TicketListTable(props) {
  const isWeb = useMediaQuery("(min-width: 768px)");
  const sectionTitle = useMemo(() => {
    if (isWeb) return "";
    return props.type === ROLES.SERVICE_USERS ? "Mis Tickets" : "Tickets";
  }, [props.type, isWeb]);
  useHeaderTitle(sectionTitle);

  const activateStatusColumn = props.activateStatusColumn;

  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const matches1024 = useMediaQuery(theme.breakpoints.down(1024));
  const [isFetching, fetchTicketsListWrapped] = useLoadingStatus(fetchTicketsList);
  const supportSettings = useSelector(({ systemConfigs }) => systemConfigs.support);

  const [isRequestError, setIsRequestError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [ticketList, setTicketList] = useState([]);
  const [paginationPage, setPaginationPage] = useState(0);
  const [tableActions, setTableActions] = useState([]);

  useEffect(() => {
    fetchTicketList();
    getTableActions();
  }, []);

  useEffect(() => {
    if (paginationPage > 0) fetchTicketList();
  }, [paginationPage]);

  useEffect(() => {
    if (props?.newTickets?.length) {
      const [firstTicket] = props.newTickets;
      setTicketList([firstTicket, ...ticketList]);
    }
  }, [props.newTickets]);

  async function fetchTicketList() {
    try {
      let _ticketList;
      if (props.type === ROLES.SUPERVISORS) {
        _ticketList = await fetchTicketsListWrapped(props.type, null, paginationPage, props.status);

      } else if (props.type === ROLES.SERVICE_USERS) {
        const {
          attributes: { sub },
        } = await Auth.currentAuthenticatedUser();
        _ticketList = await fetchTicketsListWrapped(props.type, sub, paginationPage, props.status);
      }
      let ticketListCopy = _ticketList.map((tl) => ({ ...tl }));
      if (paginationPage !== 0) {
        ticketListCopy = [...ticketList, ...ticketListCopy];
      }
      setTicketList(ticketListCopy);
    } catch (error) {
      logger.error(error);
      setIsRequestError(true);
    }
  }

  const loadMoreTickets = async (page, pageSize) => {
    setIsLoading(true);
    const hasRemainingItems = checkLastPage(page, ticketList.length, pageSize);
    if (hasRemainingItems) {
      setPaginationPage(paginationPage + 1);
    }
    setIsLoading(false);
  };

  const goToTicketDetails = (event, row) => {
    if (props.type === ROLES.SERVICE_USERS) {
      history.push(`/soporte/ticket/${row.id}`);
    } else if (props.type === ROLES.SUPERVISORS) {
      history.push(`/validacion/ticket/${row.id}`);
    }
  };

  function getTableActions() {
    const _tableActions = [];
    _tableActions.push({
      icon: RefreshIcon,
      tooltip: "Actualizar",
      isFreeAction: true,
      onClick: (event) => fetchTicketList(),
    });
    if (props.type === ROLES.SUPERVISORS) {
      _tableActions.push((rowData) => {
        return {
          CustomComponent: () => (
            <PDFReportButton
              buttonType="icon"
              elementId={rowData.id}
              reportType="support"
              by="ticket"
              elementStatus={rowData.status}
              style={PDF_REPORT_BUTTON_STYLES}
            />
          ),
        };
      });
    }

    setTableActions(_tableActions);
  }
  supportSettings["activateStatusColumn"] = activateStatusColumn;
  const desktopTableColumns = useMemo(
    () => getTableColumns(SECTIONS.SUPPORT, props.status, ticketList, MODULES.FIELDWORK),
    [ticketList]
  );

  const mobileTableColumns = useMemo(
    () => getTableColumns(SECTIONS.SUPPORT, props.status, ticketList, MODULES.FIELDWORK, "mobile"),
    [ticketList]
  );

  const columns = !matches ? desktopTableColumns : mobileTableColumns;

  let options = props.type === ROLES.SUPERVISORS ? { ...tableSupervisionOptions } : { ...tableOptions };
  options = { ...options, exportCsv: (columnList, initialData) => exportCsv(columnList, initialData, sectionTitle) };
  options["maxBodyHeight"] = MAX_BODY_HEIGHT;
  options["pageSize"] = getPageSize(props.status);

  return (
    <>
      {isRequestError && (
        <Box p={2}>
          <Alert severity="error">Ha ocurrido un error con la carga del catálogo, recarga la página por favor.</Alert>
        </Box>
      )}
      <div className={classes.root}>
        <MaterialTable
          options={options}
          columns={columns}
          data={getDataTable(ticketList)}
          isLoading={isLoading || isFetching}
          onRowClick={goToTicketDetails}
          onChangePage={loadMoreTickets}
          detailPanel={DescriptionPanel}
          actions={tableActions}
          localization={{
            header: { actions: matches1024 ? "" : "Reporte" },
          }}
        />
      </div>
    </>
  );
}

TicketListTable.propTypes = {
  type: PropTypes.string,
  status: PropTypes.string,
  newTickets: PropTypes.arrayOf(PropTypes.object),
  activateStatusColumn: PropTypes.bool,
};

TicketListTable.defaultProps = {
  newTickets: [],
  activateStatusColumn: false,
};

const tableOptions = {
  exportButton: false,
  columnsButton: false,
  paginationType: "stepped",
  filtering: true,
  padding: "dense",
};
const tableSupervisionOptions = {
  exportButton: { csv: true },
  columnsButton: false,
  paginationType: "stepped",
  filtering: true,
  padding: "dense",
};

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      "& .MuiTableHead-root": {
        display: "none",
      },
      "& .MuiToolbar-root": {
        flexDirection: "column",
        padding: theme.spacing(2),
      },
      "& div[class*='MTableToolbar-title-']": {
        order: 1,
        textAlign: "left",
        width: "100%",
      },
      "& div[class*='MTableToolbar-searchField-']": {
        width: "100%",
        order: 0,
        marginBottom: theme.spacing(2),
      },
    },
  },
}));

const exportCsv = (columnList, initialData, fileName) => {
  const columns = columnList.filter((columnDef) => {
    return columnDef.field && columnDef.export !== false;
  });

  const data = initialData.map((rowData) =>
    columns.map((columnDef) => {
      if (columnDef.field === "status") {
        return TICKET_STATUSES[rowData[columnDef.field]];
      }
      if (columnDef.field === "impact") {
        return TICKET_IMPACTS[rowData[columnDef.field]];
      }
      if (columnDef.render) {
        return columnDef.render(rowData);
      } else {
        return rowData[columnDef.field];
      }
    })
  );

  const builder = new CsvBuilder(`${fileName}.csv`);
  builder
    .setDelimeter(",")
    .setColumns(columns.map((columnDef) => columnDef.title))
    .addRows(data)
    .exportFile();
};
