export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany($input: UpdateCompanyInput!, $condition: ModelCompanyConditionInput) {
    updateCompany(input: $input, condition: $condition) {
      _deleted
      _lastChangedAt
      _version
      createdAt
      description
      enabled
      id
      name
      owner
      updatedAt
    }
  }
`;

export default updateCompany;
