/**
 * Query GraphQL para obtener las Tasks de un Usuario.
 *
 * @param {string} userId ID del usuario de que se quiere consultar el listado de Tasks.
 */
const getTasksByUser = /* GraphQL */ `
  query GetTasksByUser($userId: ID!) {
    getUser(id: $userId) {
      id
      name
      tasks {
        items {
          task {
            id
            startDate
            startTime
            status
            taskType {
              name
              description
            }
            buildingLocation {
              name
              code
              premises {
                name
                address {
                  street
                  number
                  zipcode
                  city
                  settlement
                  locality
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default getTasksByUser;
