import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from '@material-ui/core/DialogActions';

import { Button } from '@material-ui/core';

function DialogConfirm(props) {
    return (
        <Dialog open={props.open}>
            <DialogTitle id="alert-dialog-title">
                Espera un minuto ...
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    ¿Estás seguro de querer eliminar esta evidencia?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleDialogConfirmSuccess}> Confirmar </Button>
                <Button onClick={props.handleDialogConfirmCancel}> Cancelar </Button>
            </DialogActions>
        </Dialog>
    )
}

DialogConfirm.propTypes = {
    open: PropTypes.bool,
    step: PropTypes.number,
    handleDialogConfirmSuccess: PropTypes.func,
    handleDialogConfirmCancel: PropTypes.func,
}

DialogConfirm.defaultProps = {
    open: false,
    step: 0,
    handleDialogConfirmSuccess: () => { },
    handleDialogConfirmCancel: () => { },
}

export default DialogConfirm