import { getCurrentPosition } from "util/geolocation";
import removeProps from "util/removeProps";
import { Logger } from "@aws-amplify/core";
import useGraphQL from "hooks/useGraphQL";
import useOnlineStatus from "@rehooks/online-status";
import useNotifier from "hooks/useNotifier";
import { TicketStatus, TaskStatus } from "models";
import { startTicketActivity, endCloseActivity } from "./datastore";
import updateTicketGraphQLOperation from "graphql-custom/mutations/updateTicket";
import updateTaskMutation from "graphql-custom/mutations/updateTask";
import { getTask, getTicket } from "graphql-custom/queries";
import getEventCause from "graphql-custom/queries/getEventCause";
import createTimerEventMutation from "graphql-custom/mutations/createTimerEvent";
import useTimerEventGraph from "components/Timer/helpers/useTimerEventGraph";
import useGlobalOperations from "hooks/useGlogalOperations";
import useGenerateOnlineOfflineHandler from "hooks/useGenerateOnlineOfflineHandler";

const logger = new Logger("ApiTimer:useTicketActivityHelpers");

export default function useTicketActivityHelper() {
  const isOnline = useOnlineStatus();
  const { showError } = useNotifier();
  const { runGraphQLOperation } = useGraphQL();
  const { updateLastEvent, newTimerEventGraphQL } = useTimerEventGraph();
  const { updateTask, updateTicket } = useGlobalOperations();
  const generateOnlineOfflineHandler = useGenerateOnlineOfflineHandler();

  const startTicketActivityHandler = (taskId, ticketId, supportType) =>
    generateOnlineOfflineHandler(startTicketActivityGraphQL, startTicketActivity, {
      onlineParams: { taskId, ticketId, supportType },
      offlineParams: { taskId, ticketId, supportType },
      validation: !taskId || !ticketId || !supportType,
      errorMsg: "Ocurrio un error durante el inicio de la actividad.",
    });

  async function startTicketActivityGraphQL({ taskId, ticketId, supportType } = {}) {
    const defaultTimerEventDescription = "La actividad de soporte ha sido iniciada por el usuario Técnico asignado";
    const defaultPropsToDelete = ["_lastChangedAt", "_deleted"];
    const defaultStartEventCauseId = "start-task";
    let [ticket, task, startEventCause, location] = await Promise.all([
      _getTicket(ticketId),
      handlerTask(taskId),
      handlerEventCause(defaultStartEventCauseId),
      getCurrentPosition(),
    ]);

    // Start Timer event creation
    let { createTimerEvent: newTimerEvent } = await runGraphQLOperation({
      operation: createTimerEventMutation,
      variables: {
        input: {
          taskID: taskId,
          eventCauseID: startEventCause.id,
          description: defaultTimerEventDescription,
          timestamp: Date.now(),
          createdAt: new Date().toISOString(),
          duration: 0,
          finalized: false,
        },
      },
    });

    // parseo de objeto embedido
    const embeddedLastEvent = {
      ...removeProps([newTimerEvent], { propsToDelete: defaultPropsToDelete })[0],
      _version: 1,
      embeddedCause: removeProps([startEventCause], { propsToDelete: defaultPropsToDelete })[0],
    };

    //update Ticket
    const { data: updatedTicket } = await updateTicket(
      ticketId,
      {
        status: TicketStatus.IN_PROGRESS,
        supportType,
      },
      ticket._version
    );

    //*** Update Task  */
    const { data: updatedTask } = await updateTask(
      taskId,
      {
        status: TaskStatus.IN_PROGRESS,
        startedAt: new Date().toISOString(),
        startLocation: {
          latitude: location.coords.latitude,
          longitude: location.coords.longitude,
        },
        taskLastEventId: newTimerEvent.id,
        embeddedLastEvent: embeddedLastEvent,
        embeddedEvents: [embeddedLastEvent],
        effectiveTime: 0,
      },
      task._version
    );
    updatedTask.remarks = updatedTask.remarks.items;
    updatedTask.notes = updatedTask.notes.items;
    //*** Update Task  */
    logger.debug("startTicketActivity: información de task y ticket actualizada...", { updatedTicket, updatedTask });

    return { updatedTicket, updatedTask, newTimerEvent };
  }

  async function endTicketActivityHandler(taskId, ticketId, timerDuration, embeddedEvents) {
    let result = { success: false, data: null };
    //const isOnline = false;
    try {
      if (isOnline) {
        result.data = await endTicketActivityGraphQL(taskId, ticketId, timerDuration, embeddedEvents);
      } else {
        result.data = await endCloseActivity(taskId, ticketId, timerDuration, embeddedEvents);
      }
      if (result.data) {
        result.success = true;
      }
    } catch (error) {
      logger.error(error);
      showError("Ocurrió un error al registrar el evento.");
    }
    return result;
  }

  async function endTicketActivityGraphQL(taskId, ticketId, timerDuration, embeddedEvents) {
    const defaultEndEventCauseId = "end-task";
    let [location] = await Promise.all([getCurrentPosition()]);

    //get event Cause
    await handlerEventCause(defaultEndEventCauseId);

    // get task
    const taskData = await handlerTask(taskId);

    //get Ticket
    const lastTicket = await _getTicket(ticketId);

    //actualizar último evento / evento anterior

    const lastEventId = taskData.embeddedLastEvent?.id || null;
    if (lastEventId) {
      const { updatedEmbeddedEvents } = await updateLastEvent(lastEventId, embeddedEvents);
      embeddedEvents = updatedEmbeddedEvents;
    }

    //crear evento de finalización
    const endEventInput = {
      eventCauseID: defaultEndEventCauseId,
      description: "La actividad de soporte ha finalizado",
    };
    const { newTimerEvent, newEmbeddedLastEvent, updatedEmbeddedEvents } = await newTimerEventGraphQL(
      taskId,
      endEventInput,
      embeddedEvents
    );

    //actualizar ticket
    const { updateTicket: ticket } = await runGraphQLOperation({
      operation: updateTicketGraphQLOperation,
      variables: {
        input: {
          id: ticketId,
          status: TicketStatus.RESOLVED,
          _version: lastTicket._version,
        },
      },
      notifications: { errorMsg: "Error al actualizar el ticket." },
    });

    //* Update Task  */
    const { updateTask: task } = await runGraphQLOperation({
      operation: updateTaskMutation,
      variables: {
        input: {
          id: taskId,
          status: TaskStatus.COMPLETED,
          endedAt: new Date().toISOString(),
          endLocation: {
            latitude: location.coords.latitude,
            longitude: location.coords.longitude,
          },
          taskLastEventId: newTimerEvent.id,
          embeddedLastEvent: newEmbeddedLastEvent,
          embeddedEvents: updatedEmbeddedEvents,
          effectiveTime: timerDuration,
          _version: taskData._version,
        },
      },
      notifications: { errorMsg: "Error al actualizar el Task" },
    });
    //* Update Task  */
    task.remarks = task.remarks.items;
    task.notes = task.notes.items;

    return { task, ticket };
  }

  async function handlerEventCause(eventCauseId) {
    const { getEventCause: startEventCause } = await runGraphQLOperation({
      operation: getEventCause,
      variables: {
        id: eventCauseId,
      },
      notifications: { errorMsg: "Error getEventCause" },
    });
    return startEventCause;
  }

  async function handlerTask(taskId) {
    const { getTask: task } = await runGraphQLOperation({
      operation: getTask,
      variables: {
        id: taskId,
        assetsFilter: {
          _deleted: {
            ne: true,
          },
        },
        assetsLimit: 1000,
      },
      notifications: { errorMsg: "Error getTask" },
    });
    return task;
  }

  async function _getTicket(ticketId) {
    const { getTicket: ticket } = await runGraphQLOperation({
      operation: getTicket,
      variables: {
        id: ticketId,
      },
    });

    return ticket;
  }

  return {
    startTicketActivityHandler,
    endTicketActivityHandler,
    startTicketActivityGraphQL,
  };
}
