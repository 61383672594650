import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useCallback } from "react";
import { useActions } from "hooks/useActions"
import { removeNotification } from "redux/dispatcher/notification";

/**
 * @function Notifier
 * 
 * Componente que se encarga de mostrar las notificaciones de toda la aplicación. 
 * Este componente se conecta al store de Redux para consultar las notificaciones 
 * mantiene un estado local de las notificaciones mostradas: De esta forma, solo se
 * generan las notificaciones que no han sido mostradas.
 */
function Notifier() {
  const notifications = useSelector(({ notifications }) => notifications);
  const remove = useActions(removeNotification);
  const [displayed, setDisplayed] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
 
  const storeDisplayed = useCallback (id => {
    setDisplayed([...displayed, id]);
  }, [displayed]);  
  
  const removeDisplayed = useCallback(id => {
    setDisplayed(displayed.filter(key => id !== key));
  }, [displayed]);
  
  useEffect(() => {
    notifications.map(({ key, message, options }) => {
      if (displayed.includes(key)) {
        return undefined;
      } 
      enqueueSnackbar(message, {
        key,
        ...options,
        onExit: (event, notification) => {
          removeDisplayed(notification);
          remove(notification)
        }
      });
      return storeDisplayed(key);
    })
  }, [notifications, displayed, remove, removeDisplayed, storeDisplayed, enqueueSnackbar]);

  return null;
}

export default Notifier;