import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import CircularProgress from "@material-ui/core/CircularProgress";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import makeStyles from "@material-ui/core/styles/makeStyles";
import downloadFile from "util/downloadFile";
import { TaskStatus, TicketStatus } from "models";
import useGraphQLHelpers from "./utils/useGraphQLHelpers";
import { TaskDocumentType, ElementByType, ReportType } from "./utils/types";
import NetworkTooltip from "components/custom/NetworkTooltip";
import useOnlineStatus from "@rehooks/online-status";
import useLoadingStatus from "hooks/useLoadingStatus";

function isValidElementStatusHandler(elementStatus, by, isOnline) {
  if (!isOnline) {
    return false;
  }
  if (by === ElementByType.ticket) {
    if (elementStatus === TicketStatus.RESOLVED || elementStatus === TicketStatus.VALIDATED) return true;
  } else if (by === ElementByType.task) {
    if (
      elementStatus === TaskStatus.COMPLETED ||
      elementStatus === TaskStatus.FINALIZED ||
      elementStatus === TaskStatus.REVIEWED
    ) {
      return true;
    }
  }
  return false;
}

function PDFReportButton({ buttonType, by, elementId, reportType, onLoading, elementStatus, buttonText, ...props }) {
  const theme = useTheme();
  const classes = useStyles();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, _handlePDFSelection] = useLoadingStatus(handlePDFSelection);
  const { fetchSupportDocument } = useGraphQLHelpers();
  const isOnline = useOnlineStatus();
  const isValidStatus = isValidElementStatusHandler(elementStatus, by, isOnline);

  const onLoadingCB = React.useCallback(onLoading, [loading]);
  useEffect(() => {
    onLoadingCB(loading);
  }, [onLoadingCB, loading]);

  async function handlePDFSelection(e) {
    e.stopPropagation();
    try {
      if (isValidStatus) {
        let documentType;
        if (reportType === ReportType.SUPPORT) {
          if (elementStatus === TaskStatus.COMPLETED || elementStatus === TicketStatus.RESOLVED) {
            documentType = TaskDocumentType.SUPPORT_COMPLETED_REPORT;
          } else if (
            elementStatus === TaskStatus.FINALIZED ||
            elementStatus === TaskStatus.REVIEWED ||
            elementStatus === TicketStatus.VALIDATED
          ) {
            documentType = TaskDocumentType.SUPPORT_VALIDATED_REPORT;
          }
        } else if (reportType === ReportType.INSTALLATION) {
          documentType = TaskDocumentType.INSTALLATION_REPORT;
        }

        if (documentType) {
          let pdfReport = await fetchSupportDocument({
            elementBy: by,
            elementId: elementId,
            documentType,
          });

          await downloadFile(pdfReport.url);
        }
      } else {
        throw new Error("Error: durante la generación del pdf, estatus invalido");
      }
    } catch (error) {
      console.log(error);
    }
  }

  const buttonTitle = isValidStatus ? "Descargar PDF" : "PDF no disponible";
  const buttonColor = isValidStatus ? "primary" : "secondary";

  return (
    <NetworkTooltip onlineTitle={buttonTitle} offlineTitle="La descarga de pdf requiere conexión a internet.">
      <div className={classes.wrapper}>
        <Button
          style={props.style}
          variant={props.variant}
          color={buttonColor}
          size={props.size}
          fullWidth={isMobileScreen}
          onClick={_handlePDFSelection}
          disabled={!isValidStatus || loading}
        >
          {buttonType === "icon" ? <PictureAsPdfIcon /> : <>{buttonText}</>}
        </Button>
        {loading && <CircularProgress size={22} className={classes.buttonProgress} />}
      </div>
    </NetworkTooltip>
  );
}

PDFReportButton.propTypes = {
  buttonType: PropTypes.oneOf(["icon", "button"]).isRequired,
  by: PropTypes.oneOf(["ticket", "task"]).isRequired,
  reportType: PropTypes.oneOf(["installation", "support", "maintenance"]).isRequired,
  elementId: PropTypes.string,
  elementStatus: PropTypes.string.isRequired,
  buttonText: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.node.isRequired]),
  title: PropTypes.string,
  onLoading: PropTypes.func,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  variant: PropTypes.oneOf(["contained", "outlined", "text"]),
  color: PropTypes.oneOf(["default", "inherit", "primary", "secondary"]),
  style: PropTypes.shape(),
};

PDFReportButton.defaultProps = {
  buttonText: "Descargar bitácora",
  size: "medium",
  onLoading: () => {},
  style: {
    display: "inline",
  },
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "relative",
    display: "inline",
    width: "100%",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default PDFReportButton;
