import React from "react";

import { Link } from "react-router-dom";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Slide from "@material-ui/core/Slide";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1
  }
}));

function HideOnScrollAppBar({ menu }) {
  const trigger = useScrollTrigger();
  const classes = useStyles();

  return (
    <>
      <Slide appear={false} direction="down" in={!trigger}>
        <AppBar>
          <Toolbar>
            <div className={classes.grow}>
              <Link to="/inicio">
                <img
                  src="/img/indika-bw-logo.png"
                  height="25"
                  alt="Logo Medical Dimegar"
                />
              </Link>
            </div>
            {menu}
          </Toolbar>
        </AppBar>
      </Slide>
      <Toolbar />
    </>
  );
}

export default HideOnScrollAppBar;
