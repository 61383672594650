export const lambdaHandleIntegration = /* GraphQL */ `
  mutation LambdaHandleIntegration($task: tk, $payload: AWSJSON) {
    lambdaHandleIntegration(input: { task: $task, payload: $payload }) {
      statusCode
      body
      rowsAffected
      error
      errorMessage
    }
  }
`;

export default lambdaHandleIntegration;
