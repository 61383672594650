import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import exportCsv from "util/exportCsv";

import useTableMobileStyles from "hooks/useTableMobileStyles";
import useHeaderTitle from "hooks/useHeaderTitle";
import useLoadingStatus from "hooks/useLoadingStatus";
import useNotifier from "hooks/useNotifier";

import TableCellMobileFormat from "components/custom/TableCellMobileFormat";
import SpeedDial from "components/custom/SpeedDial";
import NetworkTooltip from "components/custom/NetworkTooltip";
import { MAINTENANCE } from "constant/route/validation";
import { MAINTENANCE as OPERATION_MAINTENANCE } from "constant/route/operation";
import * as ROLES from "constant/roles";
import useGraphQLHelpers from "../helpers/useAPIHelpers";
import filterRowByStatus from "util/filterRowByStatus";

import dayjs from "dayjs";
import useOnlineStatus from "@rehooks/online-status";
import MaintenanceFormDialog from "../../MaintenanceFormDialog";

import { Box, useTheme, useMediaQuery } from "@material-ui/core";
import Section from "components/Section";
import MaterialTable from "components/custom/MaterialTable";
import PostAddIcon from "@material-ui/icons/PostAdd";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import WarningIcon from "@material-ui/icons/ReportProblemOutlined";
import { yellow } from "@material-ui/core/colors";
import Tooltip from "@material-ui/core/Tooltip";

import cleanUUID from "util/cleanUUID";
import { MAINTENANCE_STATUS } from "constant/Maintenance";
import { TaskStatus } from "models";
import { FormatUserName } from "util/FormatDetails";
// helpers
import RefreshIcon from "@material-ui/icons/Refresh";
import { checkNextItems } from "util/lists";
import downloadFile from "util/downloadFile";
import {
  getTableColumns,
  SECTIONS,
  getDataTable,
  MAX_BODY_HEIGHT,
  getPageSize,
  MODULES,
} from "../../../../util/homologationOfColumns";

const SECTION_TITLE = "Mantenimientos";
const DEFAULT_ROWS_PER_PAGE = 20;

export default function MaintenanceListViewTable(props) {
  const { type, status } = props;
  useHeaderTitle(SECTION_TITLE);
  const userId = useSelector(({ session }) => session.userId);
  const tableMobileStyles = useTableMobileStyles();
  // Sección de estado *********************************************************
  const [maintenanceList, setMaintenanceList] = useState([]);
  const [openMaintenanceDialog, setOpenMaintenanceDialog] = useState(false);

  // Sección de hooks **********************************************************
  const history = useHistory();
  const theme = useTheme();
  const matches1024 = useMediaQuery(theme.breakpoints.down(1024));

  const { loadMaintenanceList, fetchMaintenanceDocument } = useGraphQLHelpers();
  const [loading, _loadMaintenanceList] = useLoadingStatus(loadMaintenanceList);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const [currentPage, setCurrentPage] = useState(null);
  const [nextToken, setNextToken] = useState(null);
  const [isFetchingDocument, _fetchMaintenanceDocument] = useLoadingStatus(fetchMaintenanceDocument);
  const { showError } = useNotifier();
  const isOnline = useOnlineStatus();
  const isOffline = !isOnline;
  const speedDialActions = [
    {
      icon: <PostAddIcon />,
      name: "Nuevo mantenimiento",
      onClick: () => setOpenMaintenanceDialog(true),
    },
  ];

  useEffect(() => {
    handleLoadMaintenanceList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleLoadMaintenanceList({ withUpdatedLimit: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage]);

  useEffect(() => {
    setCurrentPage(null);
    setNextToken(null);
    setRowsPerPage(20);
  }, [isOnline]);

  async function handleLoadMaintenanceList({ customToken = null, customPage = 0, withUpdatedLimit = false } = {}) {
    const pagination = {
      limit: rowsPerPage * 3,
      page: customPage,
      token: customToken,
      updatedLimit: withUpdatedLimit,
      isReloading: true,
    };
    const { list, token, page } = await _loadMaintenanceList(type, userId, status, pagination);
    setMaintenanceList((maintenanceList) => {
      if ((isOnline && customToken) || (!isOnline && customPage)) {
        return [...maintenanceList, ...list];
      } else {
        return list;
      }
    });
    setNextToken(token);
    setCurrentPage(page);
  }

  // Handlers section **********************************************************
  async function loadMoreMaintenance(page, pageSize) {
    const cPage = currentPage || 0;
    const loadingNextPage = page > cPage;
    if (isOnline && loadingNextPage) {
      const hasRemainingItems = checkNextItems(page, maintenanceList.length, pageSize);
      hasRemainingItems && nextToken && handleLoadMaintenanceList({ customToken: nextToken });
      setCurrentPage(page);
    } else if (!isOnline) {
      const hasRemainingItems = checkNextItems(page, maintenanceList.length, pageSize);
      hasRemainingItems && handleLoadMaintenanceList({ customToken: null, customPage: cPage + 1 });
    }
  }

  function handleRowClick(event, rowData) {
    event.preventDefault();
    // console.log({ rowData });
    if (type === ROLES.SUPERVISORS) {
      history.push({
        pathname: `${MAINTENANCE.MAINTENANCE_DETAIL}/${rowData.id}`,
        state: { taskVersion: rowData._version },
      });
    } else {
      history.push({
        pathname: `${OPERATION_MAINTENANCE.MAINTENANCE_DETAIL}/${rowData.id}`,
        state: { taskVersion: rowData._version },
      });
    }
  }

  async function handlePDFSelection(event, row) {
    event.preventDefault();
    try {
      if (
        row?.status !== TaskStatus.FINALIZED &&
        row?.status !== TaskStatus.REVIEWED &&
        row?.status !== TaskStatus.COMPLETED
      )
        throw new Error("Error: durante la generación del pdf, estatus invalido");
      const { data } = await _fetchMaintenanceDocument(row.id);
      downloadFile(data?.lambdaDownloadTaskDocument?.document?.url);
    } catch (error) {
      console.log(error);
      showError("Error durante la descarga del reporte en PDF, por favor, vuelva a intentarlo");
    }
  }

  function handleSuccess(newMaintenance) {
    setMaintenanceList((prevMaintenance) => [newMaintenance, ...prevMaintenance]);
  }

  return (
    <>
      <Box mb={2}>
        <Section>
          <div className={tableMobileStyles.root} id="list-view-asset-table">
            <MaterialTable
              title=""
              columns={
                matches1024
                  ? getTableColumns(SECTIONS.MAINTENANCE, status, maintenanceList, MODULES.FIELDWORK, "mobile")
                  : getTableColumns(SECTIONS.MAINTENANCE, status, maintenanceList)
              }
              actions={[
                (rowData) => ({
                  // eslint-disable-next-line react/display-name
                  icon: () => (
                    <PictureAsPdfIcon
                      color={
                        isOffline ||
                        (rowData?.status !== TaskStatus.FINALIZED &&
                          rowData?.status !== TaskStatus.REVIEWED &&
                          rowData?.status !== TaskStatus.COMPLETED)
                          ? "disabled"
                          : "primary"
                      }
                    />
                  ),
                  onClick: (e) => handlePDFSelection(e, rowData),
                  tooltip: `${
                    isOffline ||
                    (rowData?.status !== TaskStatus.FINALIZED &&
                      rowData?.status !== TaskStatus.REVIEWED &&
                      rowData?.status !== TaskStatus.COMPLETED)
                      ? "PDF no disponible"
                      : "Descargar PDF"
                  }`,
                  disabled:
                    isOffline ||
                    (rowData?.status !== TaskStatus.FINALIZED &&
                      rowData?.status !== TaskStatus.REVIEWED &&
                      rowData?.status !== TaskStatus.COMPLETED),
                }),
                {
                  icon: RefreshIcon,
                  tooltip: "Actualizar",
                  isFreeAction: true,
                  onClick: (event) => handleLoadMaintenanceList(),
                },
              ]}
              data={getDataTable(maintenanceList, SECTIONS.MAINTENANCE)}
              onRowClick={handleRowClick}
              isLoading={loading || isFetchingDocument}
              onChangePage={loadMoreMaintenance}
              onChangeRowsPerPage={setRowsPerPage}
              options={{
                columnsButton: matches1024 ? false : true,
                exportButton: Boolean(maintenanceList?.length),
                paginationType: "stepped",
                maxBodyHeight: MAX_BODY_HEIGHT,
                pageSize: getPageSize(status),
                exportCsv: (columnList, initialData) =>
                  exportCsv(
                    getTableColumns(SECTIONS.MAINTENANCE, status, maintenanceList),
                    initialData,
                    `Tabla_mantenimientos-${dayjs().format("YYYYMM-DD-HH:mm:ssZ")}`
                  ),
                actionsColumnIndex: 0,
                filtering: true,
                rowStyle: (rowData) => {
                  const showWarning = showRowWarning(rowData, type);
                  return showWarning ? { backgroundColor: yellow[100] } : {};
                },
              }}
              localization={{
                header: { actions: matches1024 ? "" : "Reporte" },
              }}
            />
          </div>
        </Section>
      </Box>

      {type === ROLES.SUPERVISORS && (
        <NetworkTooltip offlineTitle="La creación de mantenimientos requiere conexión a internet">
          <SpeedDial
            id="speedDial-mantenimiento"
            actions={isOnline ? speedDialActions : null}
            FabProps={{ disabled: !isOnline }}
            style={{ pointerEvents: "all" }}
            position="fixed"
            backdrop
            tooltipOpen
          />
        </NetworkTooltip>
      )}

      <MaintenanceFormDialog
        isOpen={openMaintenanceDialog}
        onClose={() => setOpenMaintenanceDialog(false)}
        maintenanceData={null}
        onCreate={handleSuccess}
      />
    </>
  );
}

MaintenanceListViewTable.propTypes = {
  type: PropTypes.oneOf([ROLES.SUPERVISORS, ROLES.SUPPORT_ENGINEERS]),
  status: PropTypes.string,
};

MaintenanceListViewTable.defaultProps = {
  type: ROLES.SUPERVISORS,
};

function showRowWarning(rowData, type) {
  const startDate = dayjs(`${rowData.startDate} ${rowData.startTime}`.trim());
  const expiredMaintenance = dayjs().isAfter(startDate);
  return expiredMaintenance && rowData.status === TaskStatus.SCHEDULED && type === ROLES.SUPPORT_ENGINEERS;
}
