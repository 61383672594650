import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import API, { graphqlOperation } from "@aws-amplify/api";
import Typography from "@material-ui/core/Typography";
import useBooleanFlag from "hooks/useBooleanFlag";
import { Controller, useForm } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import ConfirmationDialog from "components/ConfirmationDialog";
import Button from "components/custom/Button";
import useLoadingStatus from "hooks/useLoadingStatus";
import useNotifier from "hooks/useNotifier";
import * as mutations from "graphql-custom/mutations";

const notEmpty = (value) => value?.trim() !== "" || "Campo obligatorio";
const NAME_RULES = {
  required: "Campo obligatorio",
  maxLength: { value: 50, message: "Máximo 50 caracteres" },
  validate: { notEmpty },
};
const ABBR_RULES = {
  required: "Campo obligatorio",
  maxLength: { value: 10, message: "Máximo 10 caracteres" },
  validate: { notEmpty },
};
const DESCRIPTION_RULES = {
  required: "Campo obligatorio",
  maxLength: { value: 500, message: "Máximo 500 caracteres" },
  validate: { notEmpty },
};
export const MODES = {
  CREATE: "CREATE",
  UPDATE: "UPDATE",
};
const USE_FORM_OPTIONS = {
  mode: "onChange",
  defaultValues: {
    name: "",
    description: "",
  },
};

async function updateCategory(values, _version) {
  return API.graphql(graphqlOperation(mutations.updateMaterialCategory, { input: { ...values, _version } })).then(
    (result) => result.data.updateMaterialCategory
  );
}

async function createCategory(values) {
  return API.graphql(graphqlOperation(mutations.createMaterialCategory, { input: values })).then(
    (result) => result.data.createMaterialCategory
  );
}

function CategoryDialog(props) {
  const [mode, setMode] = useState(MODES.CREATE);
  const { control, getValues, trigger, setValue, errors } = useForm(USE_FORM_OPTIONS);
  const [confirmDialogOpen, openConfirmDialog, closeConfirmDialog] = useBooleanFlag();
  const [updating, _updateCategory] = useLoadingStatus(updateCategory);
  const [creating, _createCategory] = useLoadingStatus(createCategory);
  const notifier = useNotifier();

  useEffect(() => {
    if (props.open && props.category) {
      setValue("id", props.category.id);
      setValue("name", props.category.name);
      setValue("description", props.category.description);
      setValue("abbreviation", props.category.abbreviation);
      setMode(MODES.UPDATE);
    } else if (!props.open) {
      setValue("id", undefined);
      setValue("name", "");
      setValue("description", "");
      setValue("abbreviation", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  async function validateForm() {
    const valid = await trigger();
    if (valid) openConfirmDialog();
  }

  async function updateOrCreate() {
    let values = getValues();
    values = {
      ...values,
      name: values.name.trim(),
      description: values.description.trim(),
      abbreviation: values.abbreviation.trim(),
    };
    try {
      let result;
      if (mode === MODES.CREATE) {
        result = await _createCategory(values);
        notifier.showMessage("Categoría Creada");
      } else {
        result = await _updateCategory(values, props.category._version);
        notifier.showMessage("Categoría Actualizada");
      }
      closeConfirmDialog();
      props.onConfirmSuccess(result, mode);
    } catch (error) {
      notifier.showError("Ocurrió un error durante la operación");
    }
  }

  function handleDialogExited() {
    setValue("name", "");
    setValue("description", "");
    setValue("abbreviation", "");
    setMode(MODES.CREATE);
  }

  let texts = {
    title: "Nueva Categoría",
    confirmBtn: "Crear",
    confirmationMsg: "Creará una nueva categoría de material.",
  };

  if (mode === MODES.UPDATE) {
    texts = {
      title: "Actualizar Categoría",
      confirmBtn: "Actualizar",
      confirmationMsg: "Actualizará la categoría de material.",
    };
  }

  return (
    <>
      <Dialog maxWidth="md" fullWidth open={props.open} onExited={handleDialogExited}>
        <DialogTitle>{texts.title}</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <Controller
                control={control}
                as={TextField}
                rules={NAME_RULES}
                name="name"
                type="text"
                label="Nombre"
                fullWidth
                required
                error={!!errors.name}
                helperText={errors.name?.message}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                control={control}
                as={TextField}
                rules={ABBR_RULES}
                name="abbreviation"
                type="text"
                label="Abreviatura"
                fullWidth
                required
                error={!!errors.abbreviation}
                helperText={errors.abbreviation?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                as={TextField}
                rules={DESCRIPTION_RULES}
                name="description"
                type="text"
                label="Descripción"
                rows={5}
                variant="outlined"
                multiline
                fullWidth
                required
                error={!!errors.description}
                helperText={errors.description?.message}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="default" onClick={props.onCancel}>
            Cancelar
          </Button>
          <Button color="primary" onClick={validateForm}>
            {texts.confirmBtn}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        open={confirmDialogOpen}
        title="Confirmación"
        onCancel={closeConfirmDialog}
        onConfirm={updateOrCreate}
        loading={updating || creating}
      >
        <Typography paragraph>{texts.confirmationMsg}</Typography>
        <Typography paragraph>¿Deseas continuar?</Typography>
      </ConfirmationDialog>
    </>
  );
}

CategoryDialog.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirmSuccess: PropTypes.func,
  category: PropTypes.shape(),
};

CategoryDialog.defaultProps = {
  open: false,
  onCancel: (event) => {},
  onConfirmSuccess: (event) => {},
  category: null,
};

export default CategoryDialog;
