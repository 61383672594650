const createTask = /* GraphQL */ `
  mutation CreateTask($input: CreateTaskInput!, $condition: ModelTaskConditionInput) {
    createTask(input: $input, condition: $condition) {
      id
      status
      startDate
      endDate
      startTime
      endTime
      _version
      _lastChangedAt
      _deleted
      premises {
        id
        name
        code
      }
      buildingLocation {
        id
        name
        code
        buildingBlock
        floor
        area
        owner
        createdAt
        updatedAt
      }
      taskType {
        id
        name
        description
        createdAt
        updatedAt
      }
      supervisor {
        id
        name
        middlename
        lastnameP
        lastnameM
        email
        phone
        extension
        createdAt
        updatedAt
      }
      assets {
        items {
          id
          asset {
            id
            code
            serial
            quantity
            type
            material {
              id
              name
              code
              description
              manufacturer
              trackable
              enabled
              createdAt
              updatedAt
            }
            locationCode
            tasks {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      attachments {
        items {
          id
          file {
            bucket
            region
            key
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      project {
        id
        name
        code
        company {
          id
          name
        }
      }
      category
      owner
      createdAt
      updatedAt
    }
  }
`;

export default createTask;
