/* eslint-disable react/display-name */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import useLoadingStatus from "hooks/useLoadingStatus";
import useHeaderTitle from "hooks/useHeaderTitle";
import useScroll from "hooks/useScroll";

import cleanUUID from "util/cleanUUID";

import { fetchInstallationDetail } from "./helpers/datastore";
import AssetsInstallationDetailMaterials from "./AssetsInstallationDetailMaterials";
import AssetsInstallationDetailGeneralInfo from "./AssetsInstallationDetailGeneralInfo";

import TabContainer from "components/Tabs/TabContainer";

const SECTION_TITLE = "Instalación de activos";

// TODO: Manejar error en la consulta del detalle
function AssetsInstallationDetailView(props) {
  const [installationDetails, setInstallationDetails] = useState({});
  const [installationMaterials, setInstallationMaterials] = useState([]);

  const { id } = useParams();
  const scroll = useScroll();

  useHeaderTitle(`${SECTION_TITLE} ${cleanUUID(id)}`);

  const [fetchingDetail, fetchInstallationInfoWrapped] = useLoadingStatus(fetchInstallationInfo);

  useEffect(() => {
    fetchInstallationInfoWrapped();
    scroll.toTop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchInstallationInfo() {
    return fetchInstallationDetail(id)
      .then((result) => {
        installationDataSetup(result);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function installationDataSetup(data) {
    let assets = data?.assets || [];
    setInstallationDetails(data);
    setInstallationMaterials(assets);
  }

  const generalInfo = (
    <AssetsInstallationDetailGeneralInfo
      fetchingDetail={fetchingDetail}
      fetchInstallationInfoWrapped={fetchInstallationInfoWrapped}
      fetchInstallationDetail={fetchInstallationDetail}
      fetchInstallationInfo={fetchInstallationInfo}
      installationDetails={installationDetails}
      setInstallationDetails={setInstallationDetails}
      installationMaterials={installationMaterials}
      setInstallationMaterials={setInstallationMaterials}
    />
  );

  const materials = (
    <AssetsInstallationDetailMaterials
      fetchingDetail={fetchingDetail}
      fetchInstallationInfoWrapped={fetchInstallationInfoWrapped}
      installationDetails={installationDetails}
    />
  );

  // Obj to define tabs
  const tabData = [
    {
      name: "Información general",
      index: 0,
      component: generalInfo,
    },
    {
      name: "Materiales",
      index: 1,
      component: materials,
    },
  ];

  return (
    <div id="back-to-top-anchor">
      <TabContainer tabData={tabData} />
    </div>
  );
}

export default AssetsInstallationDetailView;
