import * as ActionTypes from "redux/action";
import { filterDeletedItems } from "util/lists";

export default function installationMaterialsReducer(state, action) {
  switch (action.type) {
    case ActionTypes.SET_ALL_INSTALLATION_MATERIAL: {
      const materials = action.payload.map((data) => ({
        relationId: data?.id || data?.relationId,
        materialId: data?.asset?.material?.id || data?.materialId,
        assetId: data?.asset?.id || data?.assetId,
        uomId: data?.asset?.uom?.id || data?.uomId,
        code: data?.asset?.material?.code || data?.code,
        name: data?.asset?.material?.name || data?.name,
        quantity: getMaterialQuantity(data?.asset?.quantity || data?.quantity),
        relationVersion: data?._version || data?.relationVersion,
        assetVersion: data?.asset?._version || data?.assetVersion,
        uom: data?.asset?.uom || data?.uom,
        serial: data?.asset?.serial || data?.serial,
        trackingID: data?.asset?.tracking?.id || data?.trackingID,
        type: data?.asset?.type || data?.type,
      }));
      return { ...state, all: materials };
    }

    case ActionTypes.SET_ONLY_ALL_MATERIAL: {
      return {
        all: action.payload.all,
        added: [],
        updated: [],
        deleted: [],
      };
    }

    case ActionTypes.FORMAT_MATERIAL_ADDED: {
      const newMaterial = action.payload;
      return { ...state, all: [...state.all, ...newMaterial] };
    }

    case ActionTypes.SET_KIT_MATERIALS: {
      const { newMaterials } = transformKitMaterials(action.payload);
      return {
        ...state,
        all: newMaterials,
      };
    }

    case ActionTypes.REPLACE_ALL_INSTALLATION_MATERIAL: {
      const { newMaterials, materialIds } = transformKitMaterials(action.payload);
      const replacedMaterials = state.all.map(({ relationId, relationVersion }) => {
        return { id: relationId, _version: relationVersion };
      });
      return {
        ...state,
        all: newMaterials,
        added: materialIds,
        deleted: replacedMaterials,
      };
    }

    case ActionTypes.ADD_INSTALLATION_MATERIAL: {
      const addedMaterial = {
        ...action.payload,
        quantity: getMaterialQuantity(action.payload.quantity),
      };
      return {
        ...state,
        all: [...state.all, addedMaterial],
        added: [...state.added, action.payload.materialId],
      };
    }

    case ActionTypes.SELECT_MATERIALS_ITEMS: {
      if (action.payload.added?.length && action.payload.all?.length) {
        return {
          ...state,
          all: [...state.all, ...action.payload.all],
          added: [...state.added, ...action.payload.added],
        };
      } else {
        const { added, all } = transformSelectedMaterials(action.payload);
        return {
          ...state,
          all: [...state.all, ...all],
          added: [...state.added, ...added],
        };
      }
    }

    case ActionTypes.UPDATE_INSTALLATION_MATERIAL: {
      const newState = { ...state, all: state.all.slice() };
      const updatedIndex = state.all.findIndex(({ materialId }) => {
        return materialId === action.payload.materialId;
      });
      const { updatedProp = "quantity", newValue } = action.payload;
      if (updatedProp === "quantity") {
        newState.all[updatedIndex] = {
          ...action.payload,
          quantity: getMaterialQuantity(newValue),
        };
      } else if (updatedProp === "uom") {
        newState.all[updatedIndex] = {
          ...action.payload,
          uomId: newValue.id,
          uom: {
            ...newState.all[updatedIndex].uom,
            id: newValue.id,
            name: newValue.name,
          },
        };
      }
      if (newState.added.includes(action.payload.materialId)) {
        return newState;
      } else {
        newState.updated = [...newState.updated, action.payload.materialId];
        return newState;
      }
    }

    case ActionTypes.DELETE_INSTALLATION_MATERIAL: {
      const updatedMaterials = state.all.filter(({ materialId }) => {
        return materialId !== action.payload.materialId;
      });
      const deletedMaterial = {
        id: action.payload.relationId,
        _version: action.payload.relationVersion,
        assetId: action.payload.assetId,
        assetVersion: action.payload.assetVersion,
      };
      return {
        ...state,
        all: updatedMaterials,
        deleted: [...state.deleted, deletedMaterial],
      };
    }

    case ActionTypes.RESET_MATERIALS: {
      return {
        all: [],
        added: [],
        updated: [],
        deleted: [],
      };
    }

    default: {
      return state;
    }
  }
}

export function transformMaterials(materialsArray) {
  const materialsParsed = materialsArray.map(({ id, _version, asset }) => ({
    relationId: id,
    materialId: asset.material.id,
    assetId: asset.id,
    uomId: asset.uom?.id,
    code: asset.material.code,
    name: asset.material.name,
    quantity: getMaterialQuantity(asset.quantity),
    relationVersion: _version,
    assetVersion: asset._version,
    uom: asset.uom,
    serial: asset.serial,
    trackingID: asset.tracking?.id,
    type: asset.type,
  }));
  return materialsParsed;
}

export function transformSelectedMaterials(newMaterials = []) {
  return newMaterials.reduce(
    (materials, materialData) => {
      const addedMaterial = {
        ...materialData,
        materialId: materialData.material?.id || materialData.materialId || materialData?.id,
        assetId: materialData.id,
        assetVersion: materialData._version,
        quantity: getMaterialQuantity(materialData.quantity),
        uomId: materialData.uom?.id,
        type: materialData.type || "MATERIAL_ALLOCATED",
        name: materialData.material?.name || materialData.name,
        serial: materialData.serial,
        trackingID: materialData.tracking?.id,
      };
      materials.all.push(addedMaterial);
      materials.added.push(addedMaterial.materialId);
      return materials;
    },
    { added: [], all: [] }
  );
}

export function transformUpdatedMaterials(state, materialToUpdate) {
  console.log({ state, materialToUpdate });
  const newState = { ...state, all: state.all.slice() };
  const updatedIndex = state.all.findIndex(({ materialId }) => {
    return materialId === materialToUpdate.materialId;
  });
  const { propName = "quantity", newValue } = materialToUpdate;
  if (propName === "quantity") {
    newState.all[updatedIndex] = {
      ...materialToUpdate,
      quantity: getMaterialQuantity(newValue),
    };
  } else if (propName === "uom") {
    newState.all[updatedIndex] = {
      ...materialToUpdate,
      uomId: newValue.id,
      uom: {
        ...newState.all[updatedIndex].uom,
        id: newValue.id,
        name: newValue.name,
      },
    };
  }
  newState.all[updatedIndex].assetVersion = materialToUpdate.assetVersion;
  if (newState.added.includes(materialToUpdate.materialId)) {
    return newState;
  } else {
    newState.updated = [...newState.updated, materialToUpdate.materialId];
    return newState;
  }
}

export function transformDeletedMaterials(maintenanceMaterials = {}, materialToDelete = {}) {
  const updatedMaterials = maintenanceMaterials.all.filter(({ materialId }) => {
    return materialId !== materialToDelete.materialId;
  });
  const deletedMaterial = {
    id: materialToDelete.relationId,
    _version: materialToDelete.relationVersion,
  };
  return {
    ...maintenanceMaterials,
    all: updatedMaterials,
    deleted: [deletedMaterial],
  };
}

export function getMaterialQuantity(materialQuantity = 1) {
  if (materialQuantity <= 0) {
    materialQuantity = 1;
  } else if (materialQuantity > 1000) {
    materialQuantity = 1000;
  }
  return materialQuantity;
}

function transformKitMaterials(materials = []) {
  return filterDeletedItems(materials).reduce(
    (result, { id, quantity, material, _version, uom, category }) => {
      result.newMaterials.push({
        materialId: material?.id,
        uomId: uom?.id,
        code: material?.code,
        name: material?.name,
        quantity: quantity,
        relationId: id,
        relationVersion: _version,
        uom,
        category,
      });
      result.materialIds.push(material?.id);
      return result;
    },
    { newMaterials: [], materialIds: [] }
  );
}
