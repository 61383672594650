
import { createStore, applyMiddleware, compose } from "redux";

import rootReducer from "./reducer";
import { loadState, saveState } from "./localStorage";

// Se importa el middleware para el manejo de peticiones
import { graphqlMiddleware } from "./middlewares/graphqlMiddleware";

// Look for a persisted state in local storage
const persistedState = loadState();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  persistedState,
  // Se incluye el middleware en Redux mediante applyMiddleware
  composeEnhancers(applyMiddleware(graphqlMiddleware()))
);

// Add a redux state change listener to save the state on local storage.
store.subscribe(() => {
  saveState(store.getState());
});

export default store;