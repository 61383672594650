import API, { graphqlOperation } from "@aws-amplify/api";
import * as queries from "graphql-custom/queries";

export async function fetchManageSettingsEnabled() {
  const result = await API.graphql(
    graphqlOperation(queries.getSetting, {
      id: "manage_engine_integration",
      key: "integration_enabled",
    })
  );

  return {
    integration_enabled: result.data.getSetting?.value || "false",
    _version: result?.data?.getSetting?._version,
  };
}

export default fetchManageSettingsEnabled;
