// TODO: DOCUMENTAR

import { useState, useCallback } from "react";

function useBooleanFlag() {
  const [open, setOpen] = useState(false);

  const openDialog = useCallback(() => {
    setOpen(true);
    return { open: true };
  }, []);

  const closeDialog = useCallback(() => {
    setOpen(false);
    return { open: false };
  }, []);

  return [open, openDialog, closeDialog];
}

export default useBooleanFlag;
