import React, { useEffect, useState } from "react";
// import dayjs from "dayjs";
import PropTypes from "prop-types";
import { Controller, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Logger } from "@aws-amplify/core";
import { Storage } from "@aws-amplify/storage";
import { DataStore } from "@aws-amplify/datastore";
import { useIndexedDB } from "react-indexed-db";
import GroupRestrictedView from "components/common/GroupRestrictedView";

import Dialog from "@material-ui/core/Dialog";
import { useTheme } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Collapse from "@material-ui/core/Collapse";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "components/custom/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Alert from "@material-ui/lab/Alert";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Select from "components/custom/Select";
import ConfirmationDialog from "components/ConfirmationDialog";
import { FORM_MODES } from "constant/formModes";
import EvidenceWithDropZone from "components/EvidenceWithDropZone/EvidenceWithDropZone";
import useLoadingStatus from "hooks/useLoadingStatus";
import useOnlineStatus from "@rehooks/online-status";
import { getPremises, getServiceUsers, createTicket, updateTicket } from "./helpers/datastore";
import useNotifier from "hooks/useNotifier";
import { AttachmentTicket, RequestChannel, TicketImpact } from "models";
import { uploadFile } from "util/file";
import cleanUUID from "util/cleanUUID";
import { fetchAttachmentTicketById } from "datastore";
import * as ROLES from "constant/roles";
import { EVIDENCE_TYPES } from "constant/evidenceTypes";

import { Device } from "@capacitor/device";
import makeOptionsList from "util/makeOptionsList";
import { ENVIRONMENT } from "constant/environments";
import { ACTIVITY_CATEGORY, ATTACHMENT_METADATA_TYPES, METADATA_KEYS, S3_KEY_PREFIXES } from "constant/attachments";
import imageUtil from "util/image";

const logger = new Logger("SupportTicketDialog");
const region = process.env.REACT_APP_AWS_REGION;
const bucket = process.env.REACT_APP_AWS_USER_FILES_S3_BUCKET;
const TELNYCS_PREMISES_ID = "93f26a05-9020-47ed-800b-cbea0d5efb70";
const SIAYEC_PREMISES_ID = "2190e23d-9cc9-4c6f-aaf6-17a070e3b030";

const validateReportedAsset = (reportedAsset) => {
  if (!reportedAsset || !Object.keys(reportedAsset).length) {
    return null;
  }
  return Boolean(reportedAsset.name) || Boolean(reportedAsset.serial) || Boolean(reportedAsset.location);
};

export default function SupportTicketDialog({ isOpen, onClose, onCreate, onUpdate, ticket }) {
  const theme = useTheme();
  const onlineStatus = useOnlineStatus();
  const imagesDb = useIndexedDB("offline_images");
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const authUserId = useSelector(({ session }) => session.userId);
  const location = useLocation();
  const [formMode, setFormMode] = useState(FORM_MODES.CREATE);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [showAssetFields, setShowAssetFields] = useState(false);
  const [loadingPremises, _getPremises] = useLoadingStatus(getPremises);
  const [loadingUsers, _getServiceUsers] = useLoadingStatus(getServiceUsers);
  const [creating, _createTicket] = useLoadingStatus(createTicket);
  const [updating, _updateTicket] = useLoadingStatus(updateTicket);
  const [newTicketId, setNewTicketId] = useState("");

  const [premises, setPremises] = useState([]);
  const [users, setUsers] = useState([]);
  const [requestError, setRequestError] = useState("");
  const { showError, showMessage } = useNotifier();
  const { control, errors, reset, trigger, getValues, setValue, watch } = useForm({
    mode: "onChange",
    defaultValues: DEFAULT_FORM_VALUES,
  });
  const [isMobile, setIsMobile] = useState(false);
  const [files, setFiles] = useState([]);
  const [isUploadingAttachments, setIsUploadingAttachments] = useState(false);
  const [isUploading, uploadFileWrapped] = useLoadingStatus(uploadFile);
  const [userRole, setUserRole] = useState(ROLES.SERVICE_USERS);
  const selectedRequester = watch("requester");

  useEffect(() => {
    getDeviceDetails();
    if (location.pathname.startsWith("/soporte/ticket")) {
      setUserRole(ROLES.SERVICE_USERS);
    } else if (location.pathname.startsWith("/validacion/ticket")) {
      setUserRole(ROLES.SUPERVISORS);
    }
  }, [location]);

  useEffect(() => {
    if (!showAssetFields) {
      setValue("assetName", "");
      setValue("assetSerial", "");
      setValue("assetLocation", "");
    }
  }, [setValue, showAssetFields]);

  useEffect(() => {
    if (isOpen) {
      fetchPremises();
      fetchUsers();
    }
    if (isOpen && ticket) {
      logger.log(ticket);
      setValue("name", ticket.subject);
      setValue("description", ticket.description);
      setValue("type", ticket.requestType);
      setValue("impact", ticket.impact);
      setValue("service", ticket.serviceSubcategory);
      setValue("premise", ticket.premises?.id);
      setValue("requester", ticket.requester?.id || "other");
      setValue("requestChannel", ticket.requestChannel);
      setValue("requesterName", ticket.externalRequester?.name);
      setValue("requesterLastname", ticket.externalRequester?.lastname);
      setValue("requesterEmail", ticket.externalRequester?.email);
      if (validateReportedAsset(ticket.reportedAsset)) {
        setValue("assetName", ticket.reportedAsset.name || "");
        setValue("assetSerial", ticket.reportedAsset.serial || "");
        setValue("assetLocation", ticket.reportedAsset.location || "");
        setShowAssetFields(true);
      }
      setFormMode(FORM_MODES.UPDATE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, ticket]);

  async function getDeviceDetails() {
    const info = await Device.getInfo();
    if (process.env.REACT_APP_ENV !== ENVIRONMENT.PROD) {
      setIsMobile(true);
    } else if (info.platform !== "web" && info.platform !== "electron") {
      setIsMobile(true);
    }
  }

  async function fetchPremises() {
    try {
      let premiseList = await _getPremises();
      premiseList = excludePremisesWithoutSupport(premiseList);
      premiseList = makeOptionsList(premiseList, {
        label: "name",
        value: "id",
      });
      setPremises(premiseList);
    } catch (error) {
      logger.error(error);
      setRequestError("Ocurrió un error durante la consulta de sitios.");
    }
  }

  function excludePremisesWithoutSupport(premiseList) {
    const isLocal = false // Si esta en true se ven las "sedes" del entorno de desarrollo, dejar en false para producción
    // Se manejo de esta forma ya que no reconocía variables de entorno al agregarlas

    if (process.env.REACT_APP_WHITE_LABEL_APP === "grupo-siayec" && !isLocal) {
      // Se dejan estas "sedes" a petición de seguridad física el 27/04/23
      const premiseAllow = ["CJF10_PACHUCA, HIDALGO.", "CJF10_MATAMOROS, TAMAULIPAS", "CJF10_MEXICALI, BAJA CALIFORNIA", "CJF10_ PALIZA HERMOSILLO, SONORA", "CJF10_ENCINAS HERMOSILLO, SONORA", "CJF10_REVOLUCION, CDMX", "CJF10_QUERETARO, QUERETARO", "CJF10_MORELIA, MICHOACAN", "CJF10_TORREÓN, COAHUILA", "CJF10_TIJUANA, BAJA CALIFORNIA", "CJF05_LA PAZ, BAJA CALIFORNIA SUR", "CJF05_NEZAHUALCÓYOTL, EDO. DE MÉXICO", "CJF05_SAN LAZARO, CDMX", "CJF05_AJUSCO, CDMX", "CJF05_TOLUCA, EDO. DE MÉXICO"]

      let premiseFilter = []
      for (let i = 0; i < premiseAllow.length; i++) {
        for (let j = 0; j < premiseList.length; j++) {
          if (premiseAllow[i] === premiseList[j].name) premiseFilter.push(premiseList[j])
        }
      }
      return premiseFilter
    } else return premiseList.filter((premise) => premise.id !== TELNYCS_PREMISES_ID && premise.id !== SIAYEC_PREMISES_ID);
  }

  async function fetchUsers() {
    try {
      let userList = await _getServiceUsers();
      userList = userList.map(({ id, name, lastnameP, lastnameM, email }) => {
        return { label: `${name} ${lastnameP || ""} ${lastnameM || ""} - ${email}`.trim(), value: id };
      });
      setUsers([{ label: "Usuario externo", value: "other" }, ...userList]);
    } catch (error) {
      logger.error(error);
      setRequestError("Ocurrió un error durante la consulta de usuarios.");
    }
  }

  function closeDialog() {
    reset();
    setShowAssetFields(false);
  }

  const onSwitchAssetChange = (event) => setShowAssetFields(event.target.checked);

  function getAssetLabel() {
    const {
      assetName: name,
      assetSerial: serial,
      assetLocation: location,
    } = getValues(["assetName", "assetSerial", "assetLocation"]);
    if (!validateReportedAsset({ name, serial, location }) || !showAssetFields) {
      return <span>No se reportó ningún activo.</span>;
    }

    return (
      <ul>
        <li>
          <strong>Nombre:</strong>
          <span style={{ marginLeft: "4px" }}>{name || "Sin nombre registrado"}</span>
        </li>
        <li>
          <strong>Número de serie:</strong>
          <span style={{ marginLeft: "4px" }}>{serial || "Sin número de serie registrado"}</span>
        </li>
        <li>
          <strong>Ubicación:</strong>
          <span style={{ marginLeft: "4px" }}>{location || "Sin ubicación en sede registrada"}</span>
        </li>
      </ul>
    );
  }

  async function validateTicketForm() {
    let validationFields = Object.keys(FORM_RULES);
    if (userRole === ROLES.SERVICE_USERS) {
      validationFields = validationFields.filter(
        (field) => !field.startsWith("request") && !field.startsWith("impact")
      );
    } else if (userRole === ROLES.SUPERVISORS && selectedRequester !== "other") {
      validationFields = validationFields.filter((field) => !field.startsWith("requester") || field === "requester");
    }
    const validForm = await trigger(validationFields);
    if (validForm) {
      setShowConfirmation(true);
    }
  }

  async function createOrUpdateTicket() {
    const values = getFormValues();
    try {
      if (formMode === FORM_MODES.CREATE) {
        values.requestChannel = values.requestChannel || RequestChannel.WEB_PAGE;

        const newTicket = await _createTicket({
          ...values,
          impact: values.impact ? values.impact : TicketImpact.UNDEFINED,
        });
        const ticketId = cleanUUID(newTicket.id);
        onCreate({ ...newTicket });
        await loadAttachments(newTicket.id);
        setNewTicketId(ticketId);
      } else if (formMode === FORM_MODES.UPDATE) {
        const updatedTicket = await _updateTicket({ ticketId: ticket.id, ...values });
        const newAttachments = findNewAttachments();
        if (newAttachments.length) {
          await loadAttachments(updatedTicket.id, newAttachments);
        }
        const deletedAttachments = findDeletedAttachments();
        if (deletedAttachments.length) {
          await removeAttachments(deletedAttachments);
        }
        onUpdate({ ...updatedTicket });
        setNewTicketId(cleanUUID(updatedTicket.id));
      }
      setShowConfirmation(false);
      setShowSuccessMsg(true);
      showMessage(DIALOG_TEXT[formMode].successMsg);
    } catch (error) {
      logger.error(error);
      showError(DIALOG_TEXT[formMode].errorMsg);
    }
  }

  function getFormValues() {
    let values = getValues();
    const { assetName: name, assetSerial: serial, assetLocation: location } = values;
    if (validateReportedAsset({ name, serial, location }) && showAssetFields) {
      values.reportedAsset = {
        name: values.assetName,
        serial: values.assetSerial,
        location: values.assetLocation,
      };
    } else {
      values.reportedAsset = null;
    }
    if (userRole === ROLES.SUPERVISORS) {
      if (selectedRequester === "other") {
        values.externalUser = {
          name: values.requesterName,
          lastname: values.requesterLastname,
          email: values.requesterEmail,
        };
      } else {
        values.userId = values.requester;
      }
    } else if (userRole === ROLES.SERVICE_USERS) {
      values.userId = authUserId;
    }
    return values;
  }

  function findNewAttachments() {
    return files.reduce((newAttachments, attachment) => {
      const existingAttachement = ticket.attachments.find(({ id }) => id === attachment.id);
      if (!existingAttachement) {
        newAttachments.push({ ...attachment });
      }
      return newAttachments;
    }, []);
  }

  function findDeletedAttachments() {
    return ticket.attachments.reduce((removed, attachment) => {
      const existingAttachement = files.find(({ id }) => id === attachment.id);
      if (!existingAttachement) {
        removed.push({ ...attachment });
      }
      return removed;
    }, []);
  }

  async function loadAttachments(ticketId, attachments = []) {
    const pendingAttachments = attachments.length ? attachments : files;
    return Promise.all(
      pendingAttachments.map(async (file, index) => {
        // Se crea un objeto AttachmentTicket
        let attachment = new AttachmentTicket({
          ticketID: ticketId,
          file: {
            key: "",
            bucket,
            region,
          },
        });

        const extension = imageUtil.getFileExtension(file.name);
        const prefix = `${S3_KEY_PREFIXES.EVIDENCES_UNCOMPRESSED}/${ACTIVITY_CATEGORY.TICKET}/${ticketId}`;
        let s3Key = attachment.id.slice();

        if (extension) {
          s3Key = `${ATTACHMENT_METADATA_TYPES.ATTACHMENT_TICKET}_${s3Key}.${extension}`;
        }
        s3Key = `${prefix}/${s3Key}`;

        // Actualización de key de AttachmentTicket
        attachment = AttachmentTicket.copyOf(attachment, (updated) => {
          updated.file = {
            key: s3Key,
            bucket,
            region,
          };
        });

        try {
          // Se genera un file con lo necesario para agregarlo al arreglo interno de files
          logger.debug("Se intenta subir");
          let s3KeyFile = {};

          if (onlineStatus) {
            logger.debug("Se subira a S3");
            s3KeyFile = await uploadFileWrapped(s3Key, file.blob, {
              metadata: {
                [METADATA_KEYS.ATTACHMENT_ID]: attachment.id,
                [METADATA_KEYS.ATTACHMENT_TYPE]: ATTACHMENT_METADATA_TYPES.ATTACHMENT_TICKET,
                [METADATA_KEYS.ACTIVITY_ID]: ticketId,
                [METADATA_KEYS.ACTIVITY_CATEGORY]: ACTIVITY_CATEGORY.TICKET,
              },
            });
            await DataStore.save(attachment);
          } else {
            logger.debug("Se guardara de manera local en indexdb");
            // se guardará de manera local
            const attach = {
              id: attachment.id,
              ticketID: ticketId,
              type: "ticket",
              file: {
                region,
                bucket,
                key: s3Key,
              },
            };
            attach.blob = file.blob;
            attach.filename = s3Key;
            await imagesDb.add(attach);
          }

          logger.debug({ s3KeyFile });
        } catch (error) {
          logger.error(error);
          // Remove the loading image
          showError("Error inesperado al cargar la imagen");
        }
      })
    );
  }

  function removeAttachments(attachments = []) {
    return Promise.all(
      attachments.map(async (attachment) => {
        const attachmentData = await fetchAttachmentTicketById(attachment.id);
        if (attachmentData) {
          await Storage.remove(attachmentData?.file?.key);
          await DataStore.delete(attachmentData);
        }
      })
    );
  }

  function closeSuccessMsgDialog() {
    setShowSuccessMsg(false);
    onClose();
  }

  function renderRequestError() {
    if (requestError?.length) {
      return (
        <Box p={2}>
          <Alert severity="error">{requestError}</Alert>
        </Box>
      );
    }
    return null;
  }

  return (
    <>
      <Dialog maxWidth="md" fullWidth fullScreen={isMobileScreen} open={isOpen} onExited={closeDialog}>
        <DialogTitle>{DIALOG_TEXT[formMode].title}</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {renderRequestError()}
              <Typography variant="h6">Información del Ticket</Typography>
              <Typography variant="body2">
                Ingrese la información requerida para el registro de su ticket de soporte.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                as={TextField}
                rules={FORM_RULES.name}
                name="name"
                type="text"
                label="Asunto"
                fullWidth
                required
                error={!!errors.name}
                helperText={errors.name?.message}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Controller
                control={control}
                as={Select}
                label="Tipo de solicitud"
                name="type"
                fullWidth
                required
                rules={FORM_RULES.type}
                options={REQUEST_TYPE_OPTIONS}
                error={!!errors.type}
                helperText={errors.type?.message}
              />
            </Grid>
            {userRole === ROLES.SUPERVISORS && (
              <GroupRestrictedView group={["support-engineers", "supervisors"]}>
                <Grid item xs={12} md={4}>
                  <Controller
                    control={control}
                    as={Select}
                    label="Impacto"
                    name="impact"
                    fullWidth
                    required
                    rules={FORM_RULES.impact}
                    options={IMPACT_OPTIONS}
                    error={!!errors.impact}
                    helperText={errors.impact?.message}
                  />
                </Grid>
              </GroupRestrictedView>
            )}
            <Grid item xs={12} md={4}>
              <Controller
                control={control}
                as={Select}
                label="Servicio"
                name="service"
                fullWidth
                required
                rules={FORM_RULES.service}
                options={SERVICE_OPTIONS}
                error={!!errors.service}
                helperText={errors.service?.message}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={control}
                as={Select}
                label="Sitio de solicitud"
                name="premise"
                fullWidth
                required
                rules={FORM_RULES.premise}
                options={premises}
                error={!!errors.premise}
                helperText={errors.premise?.message}
                disabled={loadingPremises}
                SelectProps={{
                  // eslint-disable-next-line react/display-name
                  IconComponent: () => {
                    return loadingPremises ? <CircularProgress color="inherit" size={20} /> : <ArrowDropDownIcon />;
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                as={TextField}
                rules={FORM_RULES.description}
                name="description"
                label="Descripción"
                variant="outlined"
                rows={5}
                multiline
                fullWidth
                error={!!errors.description}
                helperText={errors.description?.message}
              />
            </Grid>
          </Grid>

          {userRole === ROLES.SUPERVISORS && (
            <Box mt={4}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6">Información del Solicitante</Typography>
                  <Typography variant="body2">
                    Seleccione el usuario que solicita el soporte, o eliga la opción <em>Usuario externo</em> para
                    ingresar los datos de una persona sin cuenta en Indika.
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    control={control}
                    as={Select}
                    label="Usuario solicitante"
                    name="requester"
                    fullWidth
                    required
                    rules={FORM_RULES.requester}
                    options={users}
                    error={!!errors.requester}
                    helperText={errors.requester?.message}
                    disabled={loadingUsers}
                    SelectProps={{
                      // eslint-disable-next-line react/display-name
                      IconComponent: () => {
                        return loadingUsers ? <CircularProgress color="inherit" size={20} /> : <ArrowDropDownIcon />;
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    control={control}
                    as={Select}
                    label="Canal de solicitud"
                    name="requestChannel"
                    fullWidth
                    required
                    rules={FORM_RULES.requestChannel}
                    options={CHANNEL_OPTIONS}
                    error={!!errors.requestChannel}
                    helperText={errors.requestChannel?.message}
                  />
                </Grid>
                {selectedRequester === "other" && (
                  <>
                    <Grid item xs={12} md={4}>
                      <Controller
                        control={control}
                        as={TextField}
                        rules={FORM_RULES.requesterName}
                        name="requesterName"
                        type="text"
                        label="Nombre"
                        fullWidth
                        required
                        error={!!errors.requesterName}
                        helperText={errors.requesterName?.message}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Controller
                        control={control}
                        as={TextField}
                        rules={FORM_RULES.requesterLastname}
                        name="requesterLastname"
                        type="text"
                        label="Apellidos"
                        fullWidth
                        required
                        error={!!errors.requesterLastname}
                        helperText={errors.requesterLastname?.message}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Controller
                        control={control}
                        as={TextField}
                        rules={FORM_RULES.requesterEmail}
                        name="requesterEmail"
                        type="text"
                        label="Correo electrónico"
                        fullWidth
                        required
                        error={!!errors.requesterEmail}
                        helperText={errors.requesterEmail?.message}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>
          )}

          <Box mt={4}>
            <FormControlLabel
              name="enable-asset-registry"
              value={showAssetFields}
              checked={showAssetFields}
              control={<Switch color="primary" inputProps={{ "aria-label": "Habilitar reporte de activo" }} />}
              label="¿Quieres reportar un activo?"
              labelPlacement="end"
              onChange={onSwitchAssetChange}
            />
            <Collapse in={showAssetFields}>
              <Box mt={1}>
                <Typography variant="h6">Reporte de activo</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Controller
                      control={control}
                      as={TextField}
                      rules={FORM_RULES.assetName}
                      name="assetName"
                      type="text"
                      label="Nombre del activo"
                      error={!!errors.assetName}
                      helperText={errors.assetName?.message}
                      fullWidth
                      required={FORM_RULES.assetName.required}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      control={control}
                      as={TextField}
                      name="assetSerial"
                      type="text"
                      label="Número de serie"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      control={control}
                      as={TextField}
                      rules={FORM_RULES.assetLocation}
                      name="assetLocation"
                      type="text"
                      label="Ubicación del activo"
                      error={!!errors.assetLocation}
                      helperText={errors.assetLocation?.message}
                      required={FORM_RULES.assetLocation.required}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Box>
            </Collapse>
          </Box>

          <Box mt={4}>
            <Typography variant="h6">Evidencias</Typography>
            <EvidenceWithDropZone
              type={EVIDENCE_TYPES.TICKET}
              isMobile={isMobile}
              onNewFiles={setFiles}
              onLoading={setIsUploadingAttachments}
              initialAttachments={ticket?.attachments}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="default" onClick={onClose}>
            Cancelar
          </Button>
          <Button color="primary" onClick={validateTicketForm} disabled={isUploadingAttachments}>
            {DIALOG_TEXT[formMode].confirmBtn}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        open={showConfirmation}
        title="Confirmación"
        onCancel={() => setShowConfirmation(false)}
        onConfirm={createOrUpdateTicket}
        loading={creating || updating || isUploading}
      >
        <Typography paragraph>{DIALOG_TEXT[formMode].confirmationMsg}</Typography>
        <Typography>
          <strong>Asunto: </strong>
          <span>{getValues().name}</span>
        </Typography>
        <Typography>
          <strong>Sitio: </strong>
          <span>{premises.find(({ value }) => value === getValues().premise)?.label}</span>
        </Typography>
        <Typography>
          <strong>Activo: </strong>
          {getAssetLabel()}
        </Typography>
        <br />
        <Typography paragraph>¿Deseas continuar?</Typography>
      </ConfirmationDialog>

      <ConfirmationDialog
        open={showSuccessMsg}
        title="Mensaje"
        onCancel={closeSuccessMsgDialog}
        cancelBtnText="Cerrar"
        showOkBtn={false}
      >
        {formMode === FORM_MODES.CREATE && (
          <>
            <Typography paragraph>El ticket se registro exitósamente con el siguiente folio de seguimiento:</Typography>
            <Typography paragraph>
              <strong id="ticketId-label">{newTicketId}</strong>
            </Typography>
          </>
        )}
        {formMode === FORM_MODES.UPDATE && (
          <>
            <Typography paragraph>
              El ticket con el folio de seguimiento: <strong>{newTicketId}</strong> se actualizó exitósamente.
            </Typography>
          </>
        )}
        <Typography paragraph>Estamos trabajando para atenderle.</Typography>
      </ConfirmationDialog>
    </>
  );
}

SupportTicketDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onCreate: PropTypes.func,
  onUpdate: PropTypes.func,
  ticket: PropTypes.object,
};

SupportTicketDialog.defaultProps = {
  isOpen: false,
  onClose: () => { },
  onCreate: () => { },
  onUpdate: () => { },
  ticket: null,
};

const DIALOG_TEXT = {
  [FORM_MODES.CREATE]: {
    title: "Nuevo Ticket",
    confirmBtn: "Crear",
    confirmationMsg: "Estás a punto de crear el siguiente ticket de soporte:",
    successMsg: "Ticket registrado con éxito",
    errorMsg: "Ocurrió un error durante la creación del ticket, intente nuevamente.",
  },
  [FORM_MODES.UPDATE]: {
    title: "Actualizar Ticket",
    confirmBtn: "Actualizar",
    confirmationMsg: "Estás a punto de actualizar el siguiente ticket de soporte:",
    successMsg: "Ticket actualizado con éxito",
    errorMsg: "Ocurrió un error durante la actualización del ticket, intente nuevamente.",
  },
};

const DEFAULT_FORM_VALUES = {
  name: "",
  description: "",
  type: "",
  impact: TicketImpact.UNDEFINED,
  service: "",
  premise: "",
  requester: "",
  requesterName: "",
  requesterLastname: "",
  requesterEmail: "",
  requestChannel: "",
  assetName: "",
  assetSerial: "",
  assetLocation: "",
};

const FORM_RULES = {
  name: {
    required: "Campo obligatorio",
    maxLength: { value: 100, message: "Máximo 100 caracteres" },
    validate: {
      notEmpty: (value) => value?.trim() !== "" || "Campo obligatorio",
    },
  },
  description: {
    maxLength: { value: 500, message: "Máximo 500 caracteres" },
  },
  type: {
    required: "Seleccione una opción",
  },
  impact: {
    required: "Seleccione una opción",
  },
  service: {
    required: "Seleccione una opción",
  },
  premise: {
    required: "Seleccione una opción",
  },
  requester: {
    required: "Seleccione una opción",
  },
  requesterName: {
    required: "Seleccione una opción",
  },
  requesterLastname: {
    required: "Seleccione una opción",
  },
  requesterEmail: {
    required: "Seleccione una opción",
    pattern: {
      // eslint-disable-next-line no-useless-escape
      value: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/,
      message: "Debe ingresar un correo electronico válido",
    },
  },
  requestChannel: {
    required: "Seleccione una opción",
  },
  assetName: {
    maxLength: { value: 70, message: "Máximo 70 caracteres" },
  },
  assetLocation: {
    maxLength: { value: 140, message: "Máximo 140 caracteres" },
  },
};

export const REQUEST_TYPE_OPTIONS = [
  { label: "Incidente", value: "ISSUE" },
  { label: "Solicitud de Servicio", value: "SERVICE_REQUEST" },
];

export const IMPACT_OPTIONS = [
  { label: "Alto", value: "HIGH" },
  { label: "Medio", value: "MEDIUM" },
  { label: "Bajo", value: "LOW" },
];

export const SERVICE_OPTIONS = [
  { label: "CCTV", value: "CCTV" },
  { label: "Red", value: "NETWORK" },
  { label: "Equipo de cómputo", value: "COMPUTER_EQUIPMENT" },
  { label: "Equipo inmobiliario", value: "PROPERTY_EQUIPMENT" },
  { label: "Aire acondicionado", value: "AIR_CODITIONER" },
  { label: "Control de acceso", value: "ACCESS_CONTROL" },
  { label: "Control de rondas", value: "ROUND_CONTROL" },
  { label: "Sistema de Energía Regulada", value: "CABINET" },
];

export const CHANNEL_OPTIONS = [
  { label: "Teléfono", value: "PHONE" },
  { label: "Correo Electrónico", value: "EMAIL" },
  { label: "Página Web", value: "WEB_PAGE" },
];
