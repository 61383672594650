import React from "react";
import PropTypes from "prop-types";
import { Box, Typography, Tooltip } from "@material-ui/core";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

const FeedbackWithIcon = ({ children, description }) => {
  return (
    <Box display="flex" flexWrap="nowrap" alignItems="center" justifyContent="space-between">
      <Typography noWrap style={{ fontSize: "0.925rem" }}>
        {children}
      </Typography>
      <Tooltip title={description} arrow enterTouchDelay={0}>
        <HelpOutlineIcon color="action" />
      </Tooltip>
    </Box>
  );
};

FeedbackWithIcon.propTypes = {
  children: PropTypes.node,
  description: PropTypes.string,
};

export default FeedbackWithIcon;
