export const createCompany = /* GraphQL */ `
  mutation CreateCompany($input: CreateCompanyInput!, $condition: ModelCompanyConditionInput) {
    createCompany(input: $input, condition: $condition) {
      updatedAt
      owner
      name
      id
      description
      enabled
      createdAt
      _version
      _lastChangedAt
      _deleted
    }
  }
`;

export default createCompany;
