export const ROOT = "/soporte";

export const QUICK_FIX = `/soporte-reporte`;
export const MY_TICKET_LIST = `${ROOT}/mis-tickets`;
export const SDP = `${ROOT}/SDP`;
export const TICKET_DETAILS = `${ROOT}/ticket/:id`;

const SUPPORT = {
  ROOT,
  MY_TICKET_LIST,
  SDP,
  TICKET_DETAILS,
  QUICK_FIX,
};

export default SUPPORT;
