// pantalla para agregar una tarea ciega
import React, { useState, useEffect, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import { Logger } from "@aws-amplify/core";
import { FormProvider, useForm } from "react-hook-form";
import EditIcon from "@material-ui/icons/Edit";
import ListIcon from "@material-ui/icons/List";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import MaterialTable from "components/custom/MaterialTable";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

import EvidenceViewV2 from "components/common/EvidenceViewV2";
import AddInstallationFormView from "components/site/AddInstallationFormView";
import AssetAttributesDialog from "components/AssetAttributesDialog";
import Button from "components/custom/Button";
import useLoadingStatus from "hooks/useLoadingStatus";
import useNotifier from "hooks/useNotifier";
import * as datastoreHelpers from "datastore";
import { AssetType, TaskStatus } from "models";
import { TASK_TYPES } from "constant/task/types";
import { FORM_ENTITIES } from "constant/task/formEntities";
import { formatAssetsCode, formatAssetsSeries } from "util/text";
import { ACTIVITY_CATEGORY_BY_TASK_TYPE, S3_KEY_PREFIXES } from "constant/attachments";

const logger = new Logger("TaskProgressTracker");

export default function TaskProgressTracker({
  taskId,
  ticketId,
  taskType,
  initialAttachments,
  initialMaterials,
  addOptimisticMaterial,
  deleteOptimisticMaterial,
  updateOptimisticMaterial,
  evidenceRef,
  attachmentsCount,
  ticketAssetId,
  position,
  loadingPosition,
  positionError,
  ...props
}) {
  const [newMaterialDialogOpen, setNewMaterialDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [attachmentsDialogOpen, setAttachmentsDialogOpen] = useState(false);
  const [openAttributesDialog, setOpenAttributesDialog] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [uomList, setUomList] = useState([]);
  const [materialList, setMaterialList] = useState([]);
  const [loadingMaterials, _getMaterialList] = useLoadingStatus(datastoreHelpers.getAllMaterials);
  const [selectMaterialId, setSelectMateriaId] = useState("");
  const [formError, setFormError] = useState("");
  const [formStatus, setFormStatus] = useState(FORMS_STATUSES.NEW);
  const [updatingAsset, setUpdatingAsset] = useState(null);
  const classes = useStyles();
  const { showMessage, showError } = useNotifier();
  const [evidenceLoading, setEvidenceLoading] = useState(false);
  const [materialsPage, setMaterialsPage] = useState(0);
  const [materialsLimit] = useState(10);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [premisesList, setPremisesList] = useState([]);
  const [formEntity, setFormEntity] = useState([]);

  const taskMaterials = useMemo(() => {
    return initialMaterials?.filter((item) => {
      const mType = item?.asset?.asset?.type;
      return mType === AssetType.USED || mType === AssetType.REUSED || mType === AssetType.MATERIAL_USED;
    });
  }, [initialMaterials]);

  const evidenceRef2 = useRef();

  const formMethods = useForm({
    defaultValues: DEFAULT_FORM_VALUES,
    mode: "onChange",
  });

  useEffect(() => {
    if (taskType === TASK_TYPES.SUPPORT && !taskMaterials.length) {
      setFormEntity(FORM_ENTITIES.ASSET);
    } else {
      setFormEntity(FORM_ENTITIES.MATERIAL);
    }
  }, [taskType, taskMaterials.length]);

  useEffect(() => {
    const attachmentList = [];
    initialAttachments.forEach((attachment) => {
      if (attachment.status === "IN_PROGRESS") {
        attachmentList.push({
          ...attachment.file,
          attachmentId: attachment.id,
        });
      }
    });

    setAttachments(attachmentList);
  }, [initialAttachments]);

  useEffect(() => {
    loadMaterialList(materialsPage, materialsLimit);
    getUomList();
    getPremisesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function loadMaterialList(page, limit) {
    _getMaterialList(page, limit).then((items) => {
      setMaterialList([...materialList, ...items]);
      setMaterialsPage(materialsPage + 1);
      setHasNextPage(items.length === limit);
    });
  }

  function getUomList() {
    datastoreHelpers.getUnitsOfMeasure().then((list) => {
      setUomList(list);
    });
  }

  function getPremisesList() {
    datastoreHelpers.fetchAllPremises().then((premises) => {
      setPremisesList(premises);
    });
  }

  function handleNewMaterialDialogVisibility(status) {
    setNewMaterialDialogOpen(status);
  }

  function handleAddAttachmentDialogBtnClick() {
    setAttachmentsDialogOpen(true);
  }

  function handleAddAttachmentDialogBtnCancel() {
    setAttachmentsDialogOpen(false);
  }

  function handleSelectOnChange(evt) {
    const value = evt?.target?.value;
    if (!value) {
      setSelectMateriaId(evt);
      return;
    }
    setSelectMateriaId(value);
    if (value === "") {
      formMethods.setValue("material_code", "");
      setSelectMateriaId("");
    } else {
      const material = materialList.find((material) => material.id === value);
      formMethods.setValue("material_code", material.code, { shouldValidate: true });
      formMethods.setValue("material_unit", material?.uom?.id || "", { shouldValidate: true });
      formMethods.setValue("type", AssetType.USED);
      formMethods.setValue("material_quantity", material.quantity || 1, { shouldValidate: true });
    }
  }

  async function handleDeleteMaterialTask(assetData) {
    try {
      setIsLoading(true);
      let result;
      if (assetData?.asset?.asset?.type === AssetType.USED) {
        result = await datastoreHelpers.deleteAssetAndTaskAsset(assetData?.taskAssets.id, assetData?.asset?.asset?.id);
      } else if (assetData?.asset?.asset?.type === AssetType.REUSED) {
        result = await datastoreHelpers.deleteTaskAssetRelation(assetData?.taskAssets.id, assetData?.asset?.asset?.id);
      }
      logger.log(result);
      showMessage("Material eliminado con éxito");
      deleteOptimisticMaterial(assetData?.taskAssets.id);
    } catch (error) {
      logger.error(error);
      showError("Ha ocurrido un error, intente de nuevo.");
    } finally {
      setIsLoading(false);
    }
  }

  function handleUpdateMaterialTableClickButton(materialInfo) {
    logger.log(materialInfo);
    setUpdatingAsset(materialInfo);
    setFormStatus(FORMS_STATUSES.UPDATE);
    setNewMaterialDialogOpen(true);
    formMethods.reset();
    setUpdateValues(materialInfo);
  }

  function setUpdateValues({ tableData, ...materialInfo }) {
    const asset = materialInfo?.asset?.asset;
    const isSupportAsset = taskType === TASK_TYPES.SUPPORT && asset?.type === AssetType.MATERIAL_USED;
    let resetValues = [
      { field: "fs_service_number", value: asset?.serial || "" },
      { field: "material_code", value: asset?.material?.code || "" },
      { field: "comments", value: asset?.comment || "" },
    ];

    if (isSupportAsset) {
      const { attributes } = materialInfo;
      resetValues = [
        ...resetValues,
        { field: "type", value: asset?.type },
        { field: "material_name", value: asset?.material.name || "" },
        { field: "location", value: attributes?.find((at) => at.attribute.id === "CONTRACT_BLUEPRINTS")?.value || "" },
        { field: "premises", value: attributes?.find((at) => at.attribute.id === "WH_NAME")?.value || "" },
      ];
      setFormEntity(FORM_ENTITIES.ASSET);
    } else {
      resetValues = [
        ...resetValues,
        { field: "type", value: asset?.type || AssetType.USED },
        { field: "material_unit", value: asset?.uom?.id || asset?.material?.uom?.id },
        { field: "material_quantity", value: asset?.quantity || 1 },
      ];
      setSelectMateriaId(asset?.material?.id);
    }

    resetValues.map(({ field, value }) => formMethods.setValue(field, value, true));
  }

  async function handleUpdateTaskMaterial() {
    const validForm = await validateForm();
    if (!validForm) {
      return;
    } else {
      setIsLoading(true);
      const values = formMethods.getValues();
      try {
        const asset = await datastoreHelpers.updateAsset({
          id: updatingAsset?.asset?.asset?.id,
          serial: values.fs_service_number || "DEFAULT_SERIE",
          quantity: Number(values.material_quantity),
          comment: values.comments,
          materialId: selectMaterialId || updatingAsset.asset?.asset?.material?.id,
          name: values.material_name,
          code: values.material_code,
        });
        let attributes = updatingAsset?.attributes;
        if (values.type === AssetType.MATERIAL_USED) {
          let wnIndex = updatingAsset.attributes.findIndex((at) => at.attribute.id === "WH_NAME");
          let cbIndex = updatingAsset.attributes.findIndex((at) => at.attribute.id === "CONTRACT_BLUEPRINTS");
          let warehouseName = attributes[wnIndex];
          let contractBlueprints = attributes[cbIndex];
          warehouseName = { id: warehouseName.id, value: values.premises };
          contractBlueprints = { id: contractBlueprints.id, value: values.location };
          const [wnRes, cbRes] = await datastoreHelpers.patchLocationAttributes(warehouseName, contractBlueprints);
          attributes = [...attributes];
          attributes[wnIndex] = {
            ...attributes[wnIndex],
            ...wnRes,
          };
          attributes[cbIndex] = {
            ...attributes[cbIndex],
            ...cbRes,
          };
        }
        logger.log(asset);
        updateOptimisticMaterial(asset, attributes);
        setUpdatingAsset(null);
        formMethods.reset(DEFAULT_FORM_VALUES);
        setNewMaterialDialogOpen(false);
        showMessage("Activo Actualizado con éxito");
      } catch (err) {
        logger.error(err);
        showError("Ha ocurrido un error intente de nuevo.");
      } finally {
        setIsLoading(false);
      }
    }
  }

  function handleCancelUpdateTaskMaterial() {
    if (taskMaterials.length) {
      setFormStatus(FORMS_STATUSES.NEW);
      setFormEntity(FORM_ENTITIES.MATERIAL);
    }
    setNewMaterialDialogOpen(false);
    formMethods.reset(DEFAULT_FORM_VALUES);
    setSelectMateriaId("");
    setUpdatingAsset(null);
  }

  async function handleAddTaskToMaterial() {
    const validForm = await validateForm();
    if (!validForm) {
      return;
    } else {
      setIsLoading(true);
      const values = formMethods.getValues();
      let result;
      let assetId;
      const material = materialList.find((material) => material.id === selectMaterialId);
      try {
        if (values.type === AssetType.REUSED) {
          assetId = values.fs_service_number?.asset?.id || values.fs_service_number?.id;
          result = await datastoreHelpers.associateAssetToTask(assetId, taskId);
        } else if (values.type === AssetType.USED) {
          const assetToTask = generateAssetToTask(values, material);
          result = await datastoreHelpers.createAssetToTask(assetToTask);
          assetId = result?.asset?.asset?.id;
        } else if (values.type === AssetType.TEMPORAL) {
          let newMaterial = {
            name: values.material_name,
            code: values.material_code,
            uomName: "PIEZA",
          };
          newMaterial = await datastoreHelpers.createMaterial(newMaterial);
          const assetToTask = generateAssetToTask(
            { ...values, material_unit: newMaterial?.uom?.id, material_quantity: 1 },
            newMaterial
          );
          assetToTask.type = AssetType.MATERIAL_USED;
          result = await datastoreHelpers.createAssetToTask(assetToTask);
          assetId = result?.asset?.asset?.id;
          const attributes = await datastoreHelpers.associateLocationAttributes(
            assetId,
            values.premises,
            values.location
          );
          result.attributes = attributes;
        }
        logger.log(result);
        setSelectMateriaId("");
        formMethods.reset(DEFAULT_FORM_VALUES);
        setFormEntity(FORM_ENTITIES.MATERIAL);
        addOptimisticMaterial(result);
        handleNewMaterialDialogVisibility(false);
        showMessage("Se ha agregado activo con éxito", { variant: "success" });
      } catch (error) {
        logger.error(error);
        showError("Ha ocurrido un error, intentelo de nuevo");
      } finally {
        setIsLoading(false);
      }
    }
  }

  async function validateForm() {
    let isValid = false;
    setFormError("");
    if (formEntity === FORM_ENTITIES.ASSET) {
      isValid = await formMethods.trigger([
        "fs_service_number",
        "material_name",
        "material_code",
        "premises",
        "location",
        "comments",
      ]);
    } else if (formEntity === FORM_ENTITIES.MATERIAL) {
      isValid = await formMethods.trigger(["fs_service_number", "material_code", "material_quantity", "comments"]);
      if (!selectMaterialId.length) {
        isValid = false;
        setFormError("Debes seleccionar algún material para continuar");
      }
    }
    return isValid;
  }

  function generateAssetToTask(values, material) {
    return {
      taskId,
      materialId: material.id,
      materialCode: material.code,
      serial: values.fs_service_number || "DEFAULT_SERIE",
      uomId: values.material_unit,
      quantity: Number(values.material_quantity),
      type: AssetType.USED,
      comment: values.comments,
      trackingID: "unassigned",
    };
  }

  function addAssetOrMaterial() {
    if (formEntity === FORM_ENTITIES.ASSET) {
      let taskAsset = initialMaterials?.find((item) => {
        return item?.asset?.asset?.type === AssetType.TEMPORAL;
      });
      formMethods.reset({
        fs_service_number: taskAsset?.asset?.asset.serial || "",
        material_name: taskAsset?.asset?.asset.name || "",
        material_code: taskAsset?.asset?.asset.code || "",
        location: taskAsset?.attributes?.find((at) => at.attribute?.id === "CONTRACT_BLUEPRINTS")?.value || "",
        premises: "",
        comments: "",
      });
      formMethods.setValue("type", AssetType.TEMPORAL);
    }
    handleNewMaterialDialogVisibility(true);
  }

  function addAttachment(attachment) {
    props.addOptimisticAttachments([attachment]);
  }

  function deleteAttachment(attachmentId) {
    props.deleteOptimisticAttachment(attachmentId);
  }

  function disabledNext() {
    if (taskMaterials.length > 0) return false
    else return true
  }

  const attachmentPrefix = `${S3_KEY_PREFIXES.EVIDENCES_UNCOMPRESSED}/${ACTIVITY_CATEGORY_BY_TASK_TYPE[taskType]}/${taskId}`;
  // Texto auxiliar de cantidad de evidencias. Valor por defecto = Caso donde no se requiere un mínimo
  let attachmentsHelperText = <p>{`Ha registrado ${attachmentsCount} evidencias.`}</p>;

  if (props.minAttachments) {
    attachmentsHelperText = (
      <p>{`Ha registrado ${attachmentsCount} evidencias de ${props.minAttachments} requeridas.`}</p>
    );
  }

  return (
    <>
      <Box mb={2} mt={2}>
        {/* <p className={classes.description}>{TASK_TYPE_TEXT[taskType].instructions(taskMaterials.length)}</p> */}
      </Box>

      {/* <Divider /> */}
      <Box>
        <MaterialTable
          title="Activo"
          columns={columns}
          data={taskMaterials}
          isLoading={isLoading}
          editable={{
            isDeleteHidden: ({ asset }) => {
              if (taskType === TASK_TYPES.SUPPORT) {
                const isSupportAsset =
                  asset?.asset?.type === AssetType.MATERIAL_ALLOCATED || asset?.asset?.type === AssetType.MATERIAL_USED;
                return !isSupportAsset;
              }
              return false;
            },
            onRowDelete: handleDeleteMaterialTask,
          }}
          actions={[
            {
              // eslint-disable-next-line react/display-name
              icon: () => <ListIcon color="action" />,
              tooltip: "Ver Atributos",
              onClick: (event, rowData) => {
                setUpdatingAsset(rowData);
                setOpenAttributesDialog(true);
              },
            },
            {
              // eslint-disable-next-line react/display-name
              icon: () => <EditIcon color="action" />,
              tooltip: "Actualizar",
              onClick: (event, rowData) => handleUpdateMaterialTableClickButton(rowData),
            },
          ]}
          options={{
            exportButton: false,
            columnsButton: false,
          }}
        />
      </Box>
      {props.fullScreen && (
        <Box>
          <Button fullWidth onClick={addAssetOrMaterial} variant="contained" color="primary">
            {TASK_TYPE_TEXT[taskType].materialsBtn(taskMaterials.length)}
          </Button>
          <Button fullWidth onClick={props.handleNext} disabled={disabledNext()}> Siguiente </Button>
        </Box>
      ) || (
          <Box className={classes.groupButtons}>
            <Grid container alignItems="center" justify="center">
              <Button className={classes.buttonStyle} onClick={addAssetOrMaterial} variant="contained" color="primary" disableElevation>
                {TASK_TYPE_TEXT[taskType].materialsBtn(taskMaterials.length)}
              </Button>
            </Grid>
            <Grid container alignItems="center" justify="center">
              <Button className={classes.buttonStyle} onClick={props.handleNext} disabled={disabledNext()}> Siguiente </Button>
            </Grid>
          </Box>
        )}

      <Dialog
        open={newMaterialDialogOpen}
        maxWidth={'md'}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        disableEscapeKeyDown
      >
        <DialogTitle>{`${TASK_TYPE_TEXT[taskType].name} - Activo nuevo`}</DialogTitle>
        <DialogContent dividers>
          <FormProvider>
            <AddInstallationFormView
              {...formMethods}
              selectMaterialId={selectMaterialId}
              handleSelectOnChange={handleSelectOnChange}
              materialList={materialList}
              materialsPage={materialsPage}
              hasNextPage={hasNextPage}
              loadingMaterials={loadingMaterials}
              loadMaterials={loadMaterialList}
              uomList={uomList}
              formStatus={formStatus}
              isLoading={isLoading}
              taskType={taskType}
              selectedAsset={updatingAsset?.asset?.asset}
              ticketAssetId={ticketAssetId}
              formEntity={formEntity}
              premisesList={premisesList}
            />
          </FormProvider>
          {formError && <Alert severity="error">{formError}</Alert>}
        </DialogContent>
        <DialogActions>
          {formStatus === FORMS_STATUSES.NEW && (
            <>
              <Button onClick={handleCancelUpdateTaskMaterial} color="default">
                CERRAR
              </Button>
              <Button onClick={handleAddTaskToMaterial} color="primary">
                AGREGAR
              </Button>
            </>
          )}
          {formStatus === FORMS_STATUSES.UPDATE && (
            <>
              <Button onClick={handleCancelUpdateTaskMaterial} color="default">
                CANCELAR
              </Button>
              <Button
                onClick={handleUpdateTaskMaterial}
                disabled={updatingAsset?.asset?.asset?.type === AssetType.REUSED}
                color="primary"
              >
                ACTUALIZAR
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      {/* {taskType === TASK_TYPES.SUPPORT ||
        (taskType === TASK_TYPES.INSTALLATION && (
          <Dialog fullScreen open={attachmentsDialogOpen} maxWidth="xl">
            <DialogTitle>
              <Typography variant="h6" align="center">
                Añadir evidencia
              </Typography>
              <Box position="absolute" right="6px" top="6px">
                <IconButton disabled={evidenceLoading} onClick={handleAddAttachmentDialogBtnCancel}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </DialogTitle>
            <DialogContent dividers style={{ height: "100%" }}>
              <DialogContentText color="textPrimary">{TASK_TYPE_TEXT[taskType].evidenceDescription}</DialogContentText>
              <EvidenceViewV2
                taskId={taskId}
                prefix={attachmentPrefix}
                status={TaskStatus.IN_PROGRESS}
                min={props.minAttachments}
                ref={evidenceRef2}
                inputName="inProgress_inputFile"
                attachments={attachments}
                loading={evidenceLoading}
                onLoading={setEvidenceLoading}
                onDeleteItem={deleteAttachment}
                onEvidenceChange={addAttachment}
                taskType={taskType}
                position={position}
                loadingPosition={loadingPosition}
                positionError={positionError}
              />
            </DialogContent>
          </Dialog>
        ))} */}
      <AssetAttributesDialog
        isOpen={openAttributesDialog}
        asset={updatingAsset}
        onClose={() => setOpenAttributesDialog(false)}
        readOnly
      />
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

const columns = [
  {
    title: "Nombre Activo",
    field: "Name",
    render: (rowData) => rowData?.asset?.asset?.material?.name || "S/I",
  },
  {
    title: "Código",
    field: "Código de Activo",
    render: (rowData) => formatAssetsCode(rowData?.asset?.asset?.material?.code),
  },
  {
    title: "Unidad",
    field: "Name",
    render: (rowData) => {
      return rowData?.asset?.asset?.uom?.name || "S/I";
    },
  },
  {
    title: "Cantidad",
    field: "Name",
    render: (rowData) => rowData?.asset?.asset?.quantity || "S/I",
  },
  {
    title: "Serie",
    field: "Serie",
    render: (rowData) => formatAssetsSeries(rowData?.asset?.asset?.serial),
  },
];

const MIN_ATTACHMENTS = 4;

const FORMS_STATUSES = {
  NEW: "NEW",
  UPDATE: "UPDATE",
};

const TASK_TYPE_TEXT = {
  [TASK_TYPES.INSTALLATION]: {
    name: "Instalación",
    instructions: () => "Ingrese el activo utilizado en la instalación.",
    materialsBtn: () => "Agregar Activo",
    evidenceDescription:
      "Para continuar con el proceso de instalación deberás adjuntar fotografías del avance de la tarea.",
  },
  [TASK_TYPES.SUPPORT]: {
    name: "Soporte",
    instructions: (length) =>
      length > 0
        ? "Ingrese el activo utilizado en la atención de soporte."
        : "Para continuar es necesario completar el registro del activo que recibió soporte",
    materialsBtn: (length) => (length > 0 ? "Agregar Activo" : "Completar Registro"),
    evidenceDescription: "Ingrese las evidencias de la atención de soporte proporcionada.",
  },
};

TaskProgressTracker.propTypes = {
  taskId: PropTypes.string,
  ticketId: PropTypes.string,
  ticketAssetId: PropTypes.string,
  taskType: PropTypes.oneOf([TASK_TYPES.INSTALLATION, TASK_TYPES.SUPPORT]),
  initialAttachments: PropTypes.array,
  initialMaterials: PropTypes.array,
  addOptimisticMaterial: PropTypes.func,
  deleteOptimisticMaterial: PropTypes.func,
  updateOptimisticMaterial: PropTypes.func,
  addOptimisticAttachments: PropTypes.func,
  deleteOptimisticAttachment: PropTypes.func,
  updateOptimisticAttachment: PropTypes.func,
  evidenceRef: PropTypes.object,
  attachmentsCount: PropTypes.number,
  minAttachments: PropTypes.number,
  position: PropTypes.object,
  loadingPosition: PropTypes.bool,
  fullScreen: PropTypes.bool,
  positionError: PropTypes.object,

  handleNext: PropTypes.func
};

TaskProgressTracker.defaultProps = {
  taskId: "",
  ticketId: "",
  ticketAssetId: "",
  taskType: TASK_TYPES.INSTALLATION,
  initialAttachments: [],
  initialMaterials: [],
  addOptimisticMaterial: () => { },
  deleteOptimisticMaterial: () => { },
  updateOptimisticMaterial: () => { },
  addOptimisticAttachment: () => { },
  deleteOptimisticAttachment: () => { },
  updateOptimisticAttachment: () => { },
  evidenceRef: { current: null },
  attachmentsCount: 0,
  minAttachments: MIN_ATTACHMENTS,

  fullScreen: false,
  handleNext: () => { }
};

const DEFAULT_FORM_VALUES = {
  fs_service_number: "",
  material_code: "",
  material_unit: "",
  material_quantity: "",
  comments: "",
  type: AssetType.USED,
  material_name: "",
  premises: "",
  location: "",
};

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.modal + 2000,
    color: "#fff",
  },
  root: {},
  card: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(5),
  },
  paper: {
    marginTop: theme.spacing(2),
    width: "100%",
    overflowX: "auto",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  sectionTitleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "10px 15px",
  },
  sectionTitleText: {
    margin: 0,
    fontWeight: "bold",
    fontSize: 18,
    marginLeft: 16,
  },
  cursorPointerElement: {
    cursor: "pointer",
  },
  noMarginElement: {
    margin: 0,
  },
  noHorizontalPadding: {
    padding: "auto 0px",
  },
  description: {
    fontSize: "0.875rem",
  },
  groupButtons: {
    display: 'flex',
    alignItems: 'center',
    marginTop: "10px"
  },
  buttonStyle: {
    paddingLeft: "105px",
    paddingRight: "105px"
  }
}));
