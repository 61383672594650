//Componente que bloquea la aplicación hasta que la sincronización incial de datastore termine
import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import useDataStoreStatus from "hooks/useDataStoreStatus";
import useGeneralLoaderStatus from "hooks/useGeneralLoader";

export default function GeneralBackdrop() {
  const { dataStoreModels, dataStoreModelsSynced } = useDataStoreStatus();
  const { showGeneralLoader } = useGeneralLoaderStatus();
  // const showGeneralLoader = false 

  const classes = useStyles();
  let value = 0;
  if (dataStoreModels.length > 0) {
    value = (dataStoreModelsSynced.length * 100) / dataStoreModels.length;
    value = value.toFixed(0);
  }

  const progressVariant = !value || value === "0" ? "indeterminate" : "determinate";
  return (
    <>
      {showGeneralLoader && (
        <Backdrop id="general-backdrop" className={classes.backdrop} open={true} onClick={() => {}}>
          <Box textAlign="center">
            <Box mb="16px">
              <Typography variant="caption" component="div" color="inherit">
                Sincronización inicial en curso...
              </Typography>
            </Box>
            <Box mb="16px">
              <Typography variant="caption" component="div" color="inherit">
                Espere un momento por favor, esto podría demorar unos minutos...
              </Typography>
            </Box>
            <Box position="relative" display="inline-flex">
              <CircularProgress
                id="backdrop-sync-circular-progress"
                variant={progressVariant}
                value={value}
                color="inherit"
              />
              <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography
                  id="backdrop-percentage-text"
                  variant="caption"
                  component="div"
                  color="inherit"
                >{`${value}%`}</Typography>
              </Box>
            </Box>
          </Box>
        </Backdrop>
      )}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "rgba(0,0,0,.9)",
  },
}));
