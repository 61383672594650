/* eslint-disable react/display-name */
// Componente para listado de activos
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import { Logger } from "@aws-amplify/core";
// material-ui
import Alert from "@material-ui/lab/Alert";
import ScheduleIcon from "@material-ui/icons/Schedule";
import SortByAlphaIcon from "@material-ui/icons/SortByAlpha";
import CategoryIcon from "@material-ui/icons/Category";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import makeStyles from "@material-ui/styles/makeStyles";
// componentes custom
import MaterialTable from "components/custom/MaterialTable";
import SpeedDial from "components/custom/SpeedDial";
import Section from "components/Section";
import AssociateQRDialog from "components/AssociateQRDialog";
import DownloadQRButton from "components/common/DownloadQRButton";
// hooks custom
import useHeaderTitle from "hooks/useHeaderTitle";
/**
 * El loading se implementa con ayuda del del useGraphql en el hijo
 */
// import useLoadingStatus from "hooks/useLoadingStatus";
import useNotifier from "hooks/useNotifier";
// helpers
/**
 * Ya no tenemos nextItems, ya que los activos se traen todos desde que se renderiza 
 * */
// import { checkNextItems } from "util/lists";
import cleanUUID from "util/cleanUUID";
import { filterDeletedItems, createWildcardSearchFilter } from "util/lists";
import { WAREHOUSE_ASSET_DETAILS } from "constant/route/warehouse";
import MobileTableCell from "./components/MobileTableCell";
// import * as graphql from "./helpers/graphql";
import { getTrackingID } from "./helpers";
import { PAGE_SIZE_OPTIONS } from "util/homologationOfColumns";
import useListAssetsBy from "hooks/useListAssetsBy";

const SECTION_TITLE = "Activos";
const TABLE_LOCALIZATION = { toolbar: { searchPlaceholder: "Nombre, serie o código del activo" } };

// const logger = new Logger("DataStore helpers");

const SORT_DIRECTION = {
  ASC: "ASC",
  DESC: "DESC",
};

function cleanItems(items) {
  return filterDeletedItems(items).map((asset) => {
    const filteredAttributes = filterDeletedItems(asset.attributes.items);
    const createdAt =
      asset.createdAt !== "Invalid Date" ? dayjs(asset.createdAt).format("DD/MM/YYYY HH:mm") : "Sin fecha registrada";
    return {
      ...asset,
      attributes: { ...asset.attributes, items: filteredAttributes },
      createdTimestamp:
        createdAt !== "Sin fecha registrada" ? new Date(asset.createdAt).getTime() : new Date().getTime(),
      createdAt: createdAt,
    };
  });
}

export default function AssetsListView() {
  useHeaderTitle(SECTION_TITLE);
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const notifier = useNotifier();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  /**
   * El search lo hacemos con ayuda de material table, por lo que ya no usamos este hook
   */
  // const [searching, _searchAssets] = useLoadingStatus(searchAssets);
  // const [searchTerm, setSearchTerm] = useState("");
  const [selectedAsset, setSelectedAsset] = useState();
  /**
   * EL tema del paginado material table lo establece en la página 1
   */
  // const [currentPage, setCurrentPage] = useState(0);
  const [isAssociateQRDialogOpen, setIsAssociateQRDialogOpen] = useState(false);
  const [speedDialActions] = useState([
    {
      icon: <img className={classes.buttonImages} src="/img/qrcode-green.png" alt="icon" />,
      name: "Asociar QR",
      onClick: () => setIsAssociateQRDialogOpen(true),
    },
  ]);
  const [dateSortDirection, setDateSortDirection] = useState(SORT_DIRECTION.ASC);
  const [serialSortDirection, setSerialSortDirection] = useState(SORT_DIRECTION.ASC);
  const [materialSortDirection, setMaterialSortDirection] = useState(SORT_DIRECTION.ASC);
  /**
   * El paginado lo maneja material table y el limit ya no se maneja dependiendo 
   * de las filas que muestra la tabla, queda establecido en 500
   */
  // const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE_OPTIONS[0]);
  // const limit = rowsPerPage * 2;
  let assetsList = [];
  let nextToken = null;
  const {
    listAll,
    data: responseData,
    isError: isRequestError,
    // setError: setIsRequestError,
    setData,
    isLoading: loading,
  } = useListAssetsBy();

  if (responseData?.hasOwnProperty("items")) {
    let assets = responseData.items || [];
    assets = cleanItems(assets);
    assets = assets.sort((a, b) => b.createdTimestamp - a.createdTimestamp);
    assetsList = assets;
  }
  if (responseData?.hasOwnProperty("nextToken")) {
    nextToken = responseData.nextToken;
  }

  useEffect(() => {
    listAll()
    /**
     * Ya no es necesario hacer si se escribio un termino dentro del buscador, ya que muestra toda la 
     * información desde ue se renderiza el componente
     */
    // setCurrentPage(0);
    // handleSearchChange(searchTerm, { withUpdatedLimit: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  function handleRowClick(event, rowData) {
    history.push(`${WAREHOUSE_ASSET_DETAILS}/${rowData.id}`);
  }
  const handleMobileRowClick = (rowData) => () => {
    history.push(`${WAREHOUSE_ASSET_DETAILS}/${rowData.id}`);
  };

  const associateQrCode =
    ({ tableData, ...rowData }) =>
      () => {
        setSelectedAsset(rowData);
        setIsAssociateQRDialogOpen(true);
      };

  function hideAssociateDialog() {
    setSelectedAsset(null);
    setIsAssociateQRDialogOpen(false);
  }

  function updateAssetRow(updatedAsset) {
    const assetIndex = assetsList.findIndex(({ id }) => id === updatedAsset.id);
    const newAssets = [...assetsList];
    newAssets[assetIndex] = {
      ...newAssets[assetIndex],
      tracking: updatedAsset.tracking,
    };
    setData({ ...responseData, items: newAssets });
    // setAssetsList(newAssets);
  }

  function getTableColumns() {
    if (isMobileScreen) {
      return TABLE_COLUMNS_MOBILE(associateQrCode, handleMobileRowClick);
    } else {
      return TABLE_COLUMNS(associateQrCode);
    }
  }

  function handleDateSorterClick() {
    let list = [];
    if (dateSortDirection === SORT_DIRECTION.ASC) {
      notifier.showMessage("Ordenando por fecha descendente", { autoHideDuration: 1500 });
      list = assetsList.sort((a, b) => b.createdTimestamp - a.createdTimestamp);
      setDateSortDirection(SORT_DIRECTION.DESC);
    } else {
      notifier.showMessage("Ordenando por fecha ascendente", { autoHideDuration: 1500 });
      list = assetsList.sort((a, b) => a.createdTimestamp - b.createdTimestamp);
      setDateSortDirection(SORT_DIRECTION.ASC);
    }
    setData({ ...responseData, items: list });
  }

  function handleSerialSorterClick() {
    let list = [];

    if (serialSortDirection === SORT_DIRECTION.ASC) {
      notifier.showMessage("Ordenando por serie descendente", { autoHideDuration: 1500 });
      list = assetsList.sort((a, b) => b?.serial?.toString().localeCompare(a?.serial));
      setSerialSortDirection(SORT_DIRECTION.DESC);
    } else {
      notifier.showMessage("Ordenando por serie ascendente", { autoHideDuration: 1500 });
      list = assetsList.sort((a, b) => a?.serial?.toString().localeCompare(b?.serial));
      setSerialSortDirection(SORT_DIRECTION.ASC);
    }
    setData({ ...responseData, items: list });
  }

  function handleMaterialSorterClick() {
    let list = [];

    if (materialSortDirection === SORT_DIRECTION.ASC) {
      notifier.showMessage("Ordenando por SKU descendente", { autoHideDuration: 1500 });
      list = assetsList.sort((a, b) => b?.code?.toString().localeCompare(a?.code));
      setMaterialSortDirection(SORT_DIRECTION.DESC);
    } else {
      notifier.showMessage("Ordenando por SKU ascendente", { autoHideDuration: 1500 });
      list = assetsList.sort((a, b) => a?.code?.toString().localeCompare(b?.code));
      setMaterialSortDirection(SORT_DIRECTION.ASC);
    }
    setData({ ...responseData, items: list });
  }

  // async function handleSearchChange(term, { withUpdatedLimit } = {}) {
  //   if (term?.length) {
  //     _searchAssets(term);
  //   } else {
  //     fetchAssets();
  //   }
  //   setSearchTerm(term);
  //   setCurrentPage(0);
  // }

  return (
    <>
      <SpeedDial actions={speedDialActions} backdrop tooltipOpen />
      <Section>
        {isRequestError && (
          <Box p={2}>
            <Alert severity="error">Ha ocurrido un error con la carga del listado, recarga la página por favor.</Alert>
          </Box>
        )}
        <div className={classes.root}>
          <MaterialTable
            title={SECTION_TITLE}
            options={TABLE_OPTIONS}
            localization={TABLE_LOCALIZATION}
            columns={getTableColumns()}
            data={assetsList}
            isLoading={loading}
            onRowClick={handleRowClick}
            // onChangePage={loadMoreAssets}
            // onChangeRowsPerPage={changeRowsPerPage}
            // customPagination
            // onSearchChange={handleSearchChange}
            actions={[
              {
                icon: ScheduleIcon,
                tooltip: "Ordenar por fecha",
                isFreeAction: true,
                onClick: handleDateSorterClick,
              },
              {
                // eslint-disable-next-line react/display-name
                icon: SortByAlphaIcon,
                tooltip: "Ordenar por serie",
                isFreeAction: true,
                onClick: handleSerialSorterClick,
              },
              {
                // eslint-disable-next-line react/display-name
                icon: CategoryIcon,
                tooltip: "Ordenar por SKU",
                isFreeAction: true,
                onClick: handleMaterialSorterClick,
              },
            ]}
          />
        </div>
      </Section>
      <AssociateQRDialog
        isOpen={isAssociateQRDialogOpen}
        selectedAsset={selectedAsset}
        onClose={hideAssociateDialog}
        onAssociate={updateAssetRow}
      />
    </>
  );
}

const TABLE_OPTIONS = {
  exportButton: false,
  columnsButton: false,
  search: true,
  paginationType: "stepped",
  pageSizeOptions: [...PAGE_SIZE_OPTIONS, 1000],
  debounceInterval: 500,
};

const TABLE_COLUMNS = (associateQrHandler) => [
  {
    title: "Código/SKU",
    field: "code",
  },
  {
    title: "Nombre",
    field: "material.name",
  },
  {
    title: "Serie",
    field: "serial",
  },
  {
    title: "Fecha de creación",
    field: "createdAt",
  },
  {
    title: "Código QR",
    field: "tracking.id",
    disableClick: true,
    render: (rowData) => {
      const id = getTrackingID(rowData);
      if (id) {
        return <DownloadQRButton assetID={rowData?.id} content={cleanUUID(id)} />;
      } else {
        return (
          <Button color="primary" onClick={associateQrHandler(rowData)}>
            Vincular QR
          </Button>
        );
      }
    },
  },
  {
    title: "Proyecto",
    field: "project.name",
    render: (rowData) => {
      if (rowData.project) {
        return `${rowData.project.name}`;
      } else {
        return "Sin asignación";
      }
    },
  },
  {
    title: "Nomenclatura",
    field: "nomenclature",
  },
];

const TABLE_COLUMNS_MOBILE = (associateQrHandler, handleMobileRowClick) => [
  {
    title: "",
    disableClick: true,
    render: (rowData) => {
      return (
        <MobileTableCell {...rowData} onCellClick={handleMobileRowClick} onQRLinkClick={associateQrHandler(rowData)} />
      );
    },
    customFilterAndSearch: (term, rowData) =>
      // Permite el filtrado utlizando multiples atributos con una sola columna
      `${rowData.code} ${rowData.serial} ${rowData?.material?.name} ${rowData?.tracking?.id}`
        .toLocaleLowerCase()
        .indexOf(term.toLocaleLowerCase()) !== -1,
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      "& .MuiTableHead-root": {
        display: "none",
      },
      "& .MuiToolbar-root": {
        flexDirection: "column",
        padding: theme.spacing(2),
      },
      "& div[class*='MTableToolbar-title-']": {
        order: 1,
        textAlign: "left",
        width: "100%",
      },
      "& div[class*='MTableToolbar-searchField-']": {
        width: "100%",
        order: 0,
        marginBottom: theme.spacing(2),
      },
    },
  },
}));
