import React from "react";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import TicketTimerLog from 'components/general/TicketTimerLog';

import Section from "components/Section";

function TicketTimerLogView(props) {
    const classes = useStyles();
    const { activity } = props;
    return (
        <Box my={3}>
            <Section>
                <CardContent>
                    <Grid container>
                        {/* <Divider orientation="vertical" flexItem className={classes.lineDivider} /> */}
                        <Grid item xs>
                            <Grid item xs={12}>
                                <Box p={3}>
                                    
                                    <Grid container> 
                                    <Grid container item xs={4}>
                                    <Typography variant="h6" gutterBottom>
                                        Registro de actividades
                                    </Typography>
                                    </Grid>
                                        <Grid container item xs={4}>
                                        <div className={classes.pauseSquare}></div>
                                            <Typography className={classes.pause} variant="span" gutterBottom>
                                                <b>Interrupciones en el servicio</b>
                                            </Typography>
                                        </Grid>
                                        <Grid container item xs={4}>
                                        <div className={classes.finishSquare}></div>
                                            <Typography className={classes.finish} variant="span" gutterBottom>
                                                <b>Tiempo efectivo del servicio</b>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {activity.map((event, index) => {
                                            return <TicketTimerLog event={event} key={index} />
                                        })
                                        }
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Section>
        </Box>
    );
}


const useStyles = makeStyles((theme) => ({
    lineDivider: {
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: 'rgb(227, 240, 239)',
        borderWidth: 1
    },
    pause: {
        marginLeft: 10,
    },
    finish: {
        marginLeft: 10,
    },
    pauseSquare: {
        width: '2%',
        maxWidth: '100px',
        height: 0,
        paddingTop: '2%',
        position: 'relative',
        background: 'rgb(255, 220, 186)',
        marginTop: 4
    },
    finishSquare: {
        width: '2%',
        maxWidth: '100px',
        height: 0,
        paddingTop: '2%',
        position: 'relative',
        background: 'rgb(227, 240, 239)',
        marginTop: 4
    }
}));

export default TicketTimerLogView;
