import { Logger } from "aws-amplify";
const logger = new Logger("DOM-Manipulation-util");

/**
 *
 * @param {Array} selectorsKeys arreglo de selectores a añadir classes **Object.keys(CSS_SELECTOR_CLASSNAME)**
 * @param {CssModulesInstance} styles instancia de css modules
 * @param {Object} CSS_SELECTOR_CLASSNAME
 * {
 *  "selector-element-en-el-dom": "nombre-clase-de-css-a-añadir",
 *  "div.amplify-textfield": "textFields"
 *  }
 * @param {Object} config { parenNode, document = default }
 */
export function addClassNameToSelectors(styles, CSS_SELECTOR_CLASSNAME, { parenNode = document } = {}) {
  logger.info("addClassNameToSelectors: añadiendo classes de estilos", {
    styles,
    CSS_SELECTOR_CLASSNAME,
    parenNode,
  });

  const selectorsKeys = Object.keys(CSS_SELECTOR_CLASSNAME);

  //Early exit
  if (!selectorsKeys.length) {
    return null;
  }

  selectorsKeys.forEach((key) => {
    const elements = [...parenNode.querySelectorAll(key)];
    if (!elements.length) {
      return;
    }
    if (elements.length === 0) {
      elements[0].classList.add(styles[CSS_SELECTOR_CLASSNAME[key]]);
    }
    if (elements.length > 0) {
      elements.forEach((element) => element.classList.add(styles[CSS_SELECTOR_CLASSNAME[key]]));
    }
  });
  logger.info("addClassNameToSelectors: estilos añadidos");
}

/**
 *
 * @param {Array} selectorsKeys arreglo de selectores a eliminar classes **Object.keys(CSS_SELECTOR_CLASSNAME)**
 * @param {CssModulesInstance} styles instancia de css modules
 * @param {Object} CSS_SELECTOR_CLASSNAME
 * {
 *  "selector-element-en-el-dom": "nombre-clase-de-css-a-eliminar",
 *  "div.amplify-textfield": "textFields"
 *  }
 * @param {Object} config { parenNode, document = default }
 */
export function removeClassNameToSelectors(styles, CSS_SELECTOR_CLASSNAME, { parenNode = document } = {}) {
  logger.info("removeClassNameToSelectors: removiendo classes de estilos", {
    styles,
    CSS_SELECTOR_CLASSNAME,
    parenNode,
  });
  const selectorsKeys = Object.keys(CSS_SELECTOR_CLASSNAME);

  //Early exit
  if (!selectorsKeys.length) {
    return null;
  }

  selectorsKeys.forEach((key) => {
    const elements = [...parenNode.querySelectorAll(key)];
    if (!elements.length) {
      return;
    }
    if (elements.length === 0) {
      elements[0].classList.remove(styles[CSS_SELECTOR_CLASSNAME[key]]);
    }
    if (elements.length > 0) {
      elements.forEach((element) => element.classList.remove(styles[CSS_SELECTOR_CLASSNAME[key]]));
    }
  });
  logger.info("removeClassNameToSelectors: estilos removidos");
}

export const addClassToElement = (selector, className) => document.querySelector(selector).classList.add(className);

export const removeClassToElement = (selector, className) =>
  document.querySelector(selector).classList.remove(className);

export const addClassToElements = (selectors = [], className = "") => {
  selectors.forEach((selector) => addClassToElement(selector, className));
};
export const removeClassToElements = (selectors = [], className = "") => {
  selectors.forEach((selector) => removeClassToElement(selector, className));
};
