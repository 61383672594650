import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button as MUIButton,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import Button from "components/custom/Button";
import NetworkTooltip from "components/custom/NetworkTooltip";
import FloatingButton from "components/FloatingButton";
import useOnlineStatus from "@rehooks/online-status";
import useBooleanFlag from "hooks/useBooleanFlag";
import useCompanyOperations from "../useCompanyOperations";
import { useForm } from "react-hook-form";
import { trimFormStringValues } from "util/forms";
import { defaultFloatingButtonProps, floatingButtonDisabledStyles, networkTooltipProps } from "../constant";

export const CompanyFormModeContext = React.createContext();

export function useFormOperations() {
  const context = React.useContext(CompanyFormModeContext);
  if (context === undefined) {
    throw new Error("useFormOperations must be used within a <CompanyFormOperations />");
  }
  return context;
}

export default function CompanyFormOperations({ children }) {
  const [isFormOpen, openForm, closeForm] = useBooleanFlag();

  return (
    <CompanyFormModeContext.Provider
      value={{
        isFormOpen,
        openForm,
        closeForm,
      }}
    >
      {children}
    </CompanyFormModeContext.Provider>
  );
}

CompanyFormOperations.propTypes = {
  children: PropTypes.node,
};

export function CreateCompanyFormDialog({ onConfirm, onConfirmError, onCancel, onCancelError }) {
  const { isFormOpen, closeForm } = useFormOperations();
  const { createCompany, loading } = useCompanyOperations();
  const { register, getValues, errors, trigger } = useForm({
    defaultValues: CREATE_COMPANY_DEFAULT_FORM_VALUES,
  });
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  async function handleValidate(values) {
    let isError = false;
    if (!values.name.length) {
      isError = true;
      await trigger();
    }
    return isError;
  }

  async function handleCreateCompany() {
    try {
      let values = trimFormStringValues(getValues());
      const isError = await handleValidate(values);
      if (isError) {
        return;
      }
      const input = { ...values, enabled: true };

      const companyCreate = await createCompany(input, {
        successMsg: "Nuevo cliente creado satisfactoriamente.",
        errorMsg: "Error durante la creación de cliente, intentelo nuevamente.",
      });
      onConfirm(companyCreate);
      closeForm();
    } catch (error) {
      onConfirmError(error, { closeForm });
    }
  }

  function handleClose() {
    try {
      onCancel();
    } catch (error) {
      onCancelError(error);
    } finally {
      closeForm();
    }
  }

  return (
    <Dialog
      open={isFormOpen}
      onClose={closeForm}
      maxWidth="sm"
      aria-labelledby="company-form-dialog"
      fullScreen={fullScreen}
      fullWidth
    >
      <DialogTitle id="company-form-dialog-title">Creación de cliente</DialogTitle>
      <DialogContent dividers>
        <CompanyOperationsForm
          inputRef={register}
          nameFieldProps={{ error: !!errors.name, helperText: errors.name?.message }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="inherit">
          Cancelar
        </Button>
        <Button onClick={handleCreateCompany} color="primary" loading={loading}>
          Crear
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CreateCompanyFormDialog.propTypes = {
  onConfirm: PropTypes.func,
  onConfirmError: PropTypes.func,
  onCancel: PropTypes.func,
  onCancelError: PropTypes.func,
};

CreateCompanyFormDialog.defaultProps = {
  onConfirm: () => {},
  onConfirmError: () => {},
  onCancel: () => {},
  onCancelError: () => {},
};

export function UpdateCompanyFormDialog({
  name,
  description,
  _version,
  id,
  onConfirm,
  onConfirmError,
  onCancel,
  onCancelError,
}) {
  const { isFormOpen, closeForm: closeDialog } = useFormOperations();
  const { updateCompany, loading } = useCompanyOperations();
  const { register, getValues, errors, trigger, setValue } = useForm({
    defaultValues: CREATE_COMPANY_DEFAULT_FORM_VALUES,
  });
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  React.useEffect(() => {
    setValue("name", name);
    setValue("description", description);
  }, [description, name, setValue]);

  async function handleValidate(values) {
    let isError = false;
    if (!values.name.length) {
      isError = true;
      await trigger();
    }
    return isError;
  }

  async function handleUpdateCompany() {
    try {
      let values = trimFormStringValues(getValues());
      const isError = await handleValidate(values);
      if (isError) {
        return;
      }

      const companyUpdated = await updateCompany(
        { id: id, _version: _version, ...values },
        {
          successMsg: "Nuevo cliente creado satisfactoriamente.",
          errorMsg: "Error durante la creación de cliente, intentelo nuevamente.",
        }
      );
      onConfirm(companyUpdated);
      closeDialog();
    } catch (error) {
      onConfirmError(error, { closeDialog });
    }
  }

  function handleClose() {
    try {
      onCancel();
      closeDialog();
    } catch (error) {
      onCancelError(error, { closeDialog });
    }
  }

  return (
    <Dialog
      open={isFormOpen}
      onClose={closeDialog}
      maxWidth="sm"
      aria-labelledby="company-form-dialog"
      fullScreen={fullScreen}
      fullWidth
    >
      <DialogTitle id="company-form-dialog-title">Actualización de cliente</DialogTitle>
      <DialogContent dividers>
        <CompanyOperationsForm
          inputRef={register}
          nameFieldProps={{ error: !!errors.name, helperText: errors.name?.message }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="inherit">
          Cancelar
        </Button>
        <Button onClick={handleUpdateCompany} color="primary" loading={loading}>
          Actualizar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

UpdateCompanyFormDialog.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  _version: PropTypes.number,
  id: PropTypes.string,
  onConfirm: PropTypes.func,
  onConfirmError: PropTypes.func,
  onCancel: PropTypes.func,
  onCancelError: PropTypes.func,
};

UpdateCompanyFormDialog.defaultProps = {
  onConfirm: () => {},
  onConfirmError: () => {},
  onCancel: () => {},
  onCancelError: () => {},
};

const CREATE_COMPANY_DEFAULT_FORM_VALUES = { name: null, description: null };

export function CompanyOperationsForm({ inputRef, nameFieldProps, descriptionFieldProps }) {
  return (
    <>
      <TextField
        id="company-name-field"
        name="name"
        placeholder="Nombre"
        label="Nombre del cliente"
        // helperText="Nombre del cliente"
        variant="outlined"
        margin="normal"
        type="text"
        autoFocus
        fullWidth
        required
        inputRef={inputRef({ required: "Este campo es obligatorio." })}
        {...nameFieldProps}
      />
      <TextField
        id="company-description-field"
        name="description"
        placeholder="Descripción"
        label="Descripción del cliente"
        // helperText="Descripción del cliente"
        variant="outlined"
        fullWidth
        type="text"
        margin="normal"
        multiline
        rows={4}
        inputRef={inputRef}
        {...descriptionFieldProps}
      />
    </>
  );
}

CompanyOperationsForm.propTypes = {
  inputRef: PropTypes.any, //react-hook-form register
  nameFieldProps: PropTypes.shape(),
  descriptionFieldProps: PropTypes.shape(),
};

export function CompanyFormButton({ children }) {
  const isOnline = useOnlineStatus();
  const { openForm } = useFormOperations();

  function handleClick() {
    openForm();
  }

  return (
    <NetworkTooltip {...networkTooltipProps}>
      <FloatingButton
        {...defaultFloatingButtonProps}
        onClick={handleClick}
        disabled={!isOnline}
        style={!isOnline ? floatingButtonDisabledStyles : {}}
      >
        {children}
      </FloatingButton>
    </NetworkTooltip>
  );
}

CompanyFormButton.propTypes = {
  children: PropTypes.node,
};

export function CompanyDetailCardEditButton({ children, ...props }) {
  const isOnline = useOnlineStatus();
  const { openForm } = useFormOperations();

  function handleClick() {
    openForm();
  }

  return (
    <NetworkTooltip
      onlineTitle="Actualizar cliente"
      offlineTitle="Sin conexión a internet para actualizar información de cliente"
      style={{ width: "fit-content" }}
    >
      <MUIButton
        onClick={handleClick}
        disabled={!isOnline}
        size="small"
        color="primary"
        endIcon={<EditIcon />}
        {...props}
      >
        {children}
      </MUIButton>
    </NetworkTooltip>
  );
}

CompanyDetailCardEditButton.propTypes = {
  children: PropTypes.node,
};
