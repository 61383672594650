import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Img } from "react-image";
import { TaskStatus } from "models";

import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import GridList from "@material-ui/core/GridList";
import GridListTile from "components/custom/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import ImageCarouselDialog from "components/common/ImageCarouselDialog";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import CloudDoneIcon from "@material-ui/icons/CloudDone";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import AddPhotoIcon from "@material-ui/icons/AddAPhoto";
import CloudOffIcon from "@material-ui/icons/CloudOff";

import BrokenImageOffline from "components/custom/BrokenImageOffline";

import useNotifier from "hooks/useNotifier";

import { takePicture } from "util/camera";
import { VISUALIZATION_TYPES } from "constant/visualizationTypes";
import { mapPictureErrorMessage, transformImageBySource, parseImageObject } from "util/image";
import PhotoSourceDialog from "components/common/PhotoSourceDialog";

export default function SingleLineGridList({
  attachments,
  attachmentsUploadProgress,
  visualizationType,
  phase,
  onAddCapturedImage,
  onDeleteImage,
  hasEvidencesWithError,
  loadingPosition,
}) {
  const [openAttachmentDialog, setOpenAttachmentDialog] = useState(false);
  const [attachmentIndex, setAttachmentIndex] = useState(0);
  const [attachmentsState, setAttachmentsState] = useState([]);

  const [openPhotoSourceDialog, setOpenPhotoSourceDialog] = useState(false);

  const classes = useStyles({ hasEvidencesWithError });
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { showError, showMessage, closeMessage } = useNotifier();
  const [messageKey, setMessageKey] = useState(null);

  const gridCols = isMobileScreen ? 1 : 0.5;
  const isLoading = attachments[attachmentIndex]?.loading;

  useEffect(() => {
    setAttachmentsState([...attachments]);
  }, [attachments]);

  useEffect(() => {
    if (messageKey && !loadingPosition) {
      closeMessage(messageKey);
    }
  }, [messageKey, loadingPosition, closeMessage]);

  async function onAddEvidenceClick(photoSource) {
    try {
      const images = await takePicture({ source: photoSource });
      const pendingImages = images.map(async (image) => {
        const { newImage, dataUrl } = await transformImageBySource(image, photoSource);
        image = parseImageObject({ ...newImage, dataUrl }, newImage.blob);
        await onAddCapturedImage(image, phase, photoSource);
      });
      await Promise.all(pendingImages);
    } catch (error) {
      console.error("Error en onAddEvidenceClick", error);
      const errorMessage = mapPictureErrorMessage(error);
      if (errorMessage?.length) {
        showError(errorMessage);
      }
    }
  }

  function showAttachmentDialog(index) {
    return () => {
      setAttachmentIndex(index);
      setOpenAttachmentDialog(true);
    };
  }

  function hideAttachmentDialog() {
    setOpenAttachmentDialog(false);
  }

  function handleOpenPhotoSourceDialog() {
    if (loadingPosition && !messageKey) {
      return showMessage(`Cargando la ubicación, espere un momento...`, {
        persist: true,
        onEnter: (element, flag, key) => setMessageKey(key),
        onExit: () => setMessageKey(null),
        // eslint-disable-next-line react/display-name
        action: (key) => {
          return (
            <IconButton size="small" style={{ color: "#fff" }} onClick={() => closeMessage(key)}>
              <CloseIcon />
            </IconButton>
          );
        },
      });
    } else if (!loadingPosition) {
      return setOpenPhotoSourceDialog(true);
    }
  }

  return (
    <Box className={classes.root}>
      {visualizationType === VISUALIZATION_TYPES.ACTIVITY && (
        <Box>
          <Box>Mínimo 1 evidencia</Box>
        </Box>
      )}
      <GridList cellHeight={120} spacing={1} className={classes.gridList}>
        {visualizationType === VISUALIZATION_TYPES.ACTIVITY && (
          <GridListTile cols={gridCols} className={classes.gridListTile}>
            <Box className={classes.addImageContainer} onClick={handleOpenPhotoSourceDialog}>
              <IconButton size="medium" color={hasEvidencesWithError ? "secondary" : "primary"}>
                <AddPhotoIcon fontSize="large" />
              </IconButton>
            </Box>
          </GridListTile>
        )}
        {attachmentsState.length === 0 && (
          <GridListTile cols={gridCols} className={classes.gridListTile}>
            <Box className={classes.noEvidenceTextContainer}>No hay evidencias cargadas</Box>
          </GridListTile>
        )}
        {attachmentsState.map((tile, index) => (
          <GridListTile
            key={tile.id}
            cols={gridCols}
            className={classes.gridListTile}
            onClick={showAttachmentDialog(index)}
          >
            <Img
              src={[tile.urlObjectSource, tile.awsSource, "/img/broken_image.png"]}
              unloader={<BrokenImageOffline showBgColor={true} />}
              loader={
                <Box height="100%" display="flex" justifyContent="center" alignItems="center">
                  <CircularProgress color="primary" size={40} />
                </Box>
              }
              alt={tile.title || "Evidencia del mantenimiento"}
              id={tile.id}
              crossorigin="anonymous"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
            {visualizationType === VISUALIZATION_TYPES.ACTIVITY && (
              <GridListTileBar
                titlePosition="top"
                actionPosition="right"
                classes={{
                  root: classes.deleteIcon,
                }}
                actionIcon={
                  <IconButton
                    className={classes.title}
                    onClick={(e) => {
                      e.stopPropagation();
                      onDeleteImage(attachments[index], phase);
                    }}
                    disabled={
                      (attachmentsUploadProgress[tile.id] === null || !isNaN(attachmentsUploadProgress[tile.id])) &&
                      !tile.uploaded
                    }
                  >
                    <CloseIcon style={{ backgroundColor: "rgba(0,0,0,0.2)" }} />
                  </IconButton>
                }
              />
            )}

            <GridListTileBar
              title={tile.title || `Evidencia ${index + 1}`}
              classes={{
                root: classes.titleBar,
                title: classes.title,
              }}
              actionIcon={
                tile.loading && attachmentsUploadProgress.hasOwnProperty(tile.id) ? (
                  <Box position="relative" display="inline-flex" className={classes.UploadIndicatorBox}>
                    {attachmentsUploadProgress[tile.id] === null ? (
                      <CircularProgress />
                    ) : (
                      <>
                        <CircularProgress variant="determinate" value={attachmentsUploadProgress[tile.id]} />
                        <Box
                          top={0}
                          left={0}
                          bottom={0}
                          right={0}
                          className={classes.uploadIndicatorTextContainer}
                          position="absolute"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          zIndex="10"
                        >
                          <Typography
                            variant="caption"
                            className={classes.uploadIndicatorText}
                            component="div"
                            color="textSecondary"
                          >{`${Math.round(attachmentsUploadProgress[tile.id])}%`}</Typography>
                        </Box>
                      </>
                    )}
                  </Box>
                ) : (
                  <>
                    {tile.uploaded ? (
                      <Box position="relative" display="inline-flex" className={classes.uploadIndicatorBox}>
                        <CloudDoneIcon style={{ color: "#FFF" }} />
                      </Box>
                    ) : (
                      <Box position="relative" display="inline-flex" className={classes.uploadIndicatorBox}>
                        <CloudOffIcon style={{ color: "#FFF" }} />
                      </Box>
                    )}
                  </>
                )
              }
            />
          </GridListTile>
        ))}
      </GridList>
      <PhotoSourceDialog
        open={openPhotoSourceDialog}
        onClose={() => setOpenPhotoSourceDialog(false)}
        onSelectSource={onAddEvidenceClick}
      />
      <ImageCarouselDialog
        open={openAttachmentDialog}
        onClose={hideAttachmentDialog}
        views={attachmentsState}
        currentIndex={attachmentIndex}
        loading={isLoading}
      />
    </Box>
  );
}

SingleLineGridList.propTypes = {
  attachments: PropTypes.array,
  attachmentsUploadProgress: PropTypes.object,
  visualizationType: PropTypes.string,
  phase: PropTypes.oneOf([TaskStatus.SCHEDULED, TaskStatus.IN_PROGRESS, TaskStatus.FINALIZED]),
  onAddCapturedImage: PropTypes.func,
  onDeleteImage: PropTypes.func,
  hasEvidencesWithError: PropTypes.bool,
  loadingPosition: PropTypes.bool,
};

SingleLineGridList.defaultProps = {
  attachments: [],
  attachmentsUploadProgress: {},
  visualizationType: VISUALIZATION_TYPES.VISUALIZATION,
  phase: TaskStatus.SCHEDULED,
  onAddCapturedImage: () => {},
  onDeleteImage: () => {},
  hasEvidencesWithError: false,
  loadingPosition: false,
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    // flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
  },
  gridListTile: {
    position: "relative",
    marginRight: theme.spacing(2),
  },
  title: {
    fontSize: "14px",
    color: "#fff",
  },
  titleBar: {
    background: "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
  deleteIcon: {
    background: "transparent",
    color: "#fff",
  },
  addImageContainer: ({ hasEvidencesWithError }) => ({
    height: "100%",
    border: `1px dashed ${hasEvidencesWithError ? theme.palette.error.main : theme.palette.primary.main}`,
    display: "flex",
    cursor: "pointer",
    justifyContent: "center",
    alignItems: "center",
  }),
  noEvidenceTextContainer: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "32px",
    height: "150px",
  },
  fileInput: {
    display: "none",
  },
  uploadIndicatorBox: {
    alignSelf: "center",
    marginRight: theme.spacing(2),
  },
  uploadIndicatorTextContainer: {
    height: "100%",
  },
  uploadIndicatorText: {
    color: "#FFF",
  },
  uploadIndicatorContainer: {
    background: "none",
  },
}));
