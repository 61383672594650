import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
// material-ui
import { CircularProgress } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
// componentes custom
import Select from "components/custom/Select";
// hooks custom
import useGraphQL from "hooks/useGraphQL";

// utilerias
import listPremises from "graphql-custom/queries/listPremisesCatalog";
import makeOptionsList from "util/makeOptionsList";

const PremisesSelector = ({ control, errors, name, label, emptyMessage, selectMessage, required, ...props }) => {
  const { loading, runGraphQLOperation } = useGraphQL();
  const [premises, setPremises] = useState([]);
  useEffect(() => {
    fetchPremises();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function fetchPremises() {
    runGraphQLOperation({
      operation: listPremises,
      notifications: {
        errorMsg:
          "Error obteniendo las zonas dispoibles para los lugares del mantenimiento, cierra y vuelve a abrir el modal",
      },
    }).then(({ listPremisess: { items } }) => {
      const premisesOptions = makeOptionsList(items, { label: "name", value: "id", disabled: "_deleted" });
      setPremises(premisesOptions);
    });
  }

  return (
    <Controller
      defaultValue={props?.defaultValue}
      render={(renderProps) => {
        // Callback custom para manejar los eventos de change de Controller y el personalizado
        function onChange(event, data, option) {
          // onChange de Controller de use-react-form
          renderProps.onChange(event);
          // onChange customizado del componente
          props.onChange(event, data, option);
        }
        return (
          <Select
            {...props}
            {...renderProps}
            label={label}
            fullWidth
            options={premises}
            emptyMessage={emptyMessage}
            selectMessage={selectMessage}
            error={!!errors.premise}
            helperText={errors.premise?.message ? errors.premise?.message : required ? "Seleccione un sitio" : ""}
            disabled={loading || props.disabled}
            name={name}
            onChange={onChange}
            SelectProps={{
              // eslint-disable-next-line react/display-name
              IconComponent: () => {
                return loading ? <CircularProgress color="inherit" size={20} /> : <ArrowDropDownIcon color="inherit" />;
              },
            }}
          />
        );
      }}
      control={control}
      rules={required ? RULES : {}}
      name={name}
    />
  );
};

const RULES = {
  required: "Este campo es requerido",
};

PremisesSelector.propTypes = {
  errors: PropTypes.object,
  control: PropTypes.object,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  emptyMessage: PropTypes.string,
  selectMessage: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
};

PremisesSelector.defaultProps = {
  label: "Lugar de atención",
  emptyMessage: "Sin lugares disponibles",
  selectMessage: "Selecciona el lugar del matenimiento",
  onChange: () => {},
  required: false,                     
};

export default PremisesSelector;
