import React from "react";
import { useParams } from "react-router-dom";
import CompanyFormOperationsProvider, { UpdateCompanyFormDialog } from "../CompanyFormOperations";
import { EnablementProvider, CompanyEnablementDialog } from "../CompanyEnablement";
import CompanyDetailCard from "../CompanyDetailCard";
import ProjectsList from "../ProjectsList/ProjectsList";
import FetchErrorFallback from "components/common/FetchErrorFallback";
import useAsync from "hooks/useAsync";
import useCompanyOperations from "../useCompanyOperations";
import useHeaderTitle from "hooks/useHeaderTitle";
import { PROJECTS_LIST_HEADER_TITLE } from "constant/headerTitles";
import ProjectEnablementDialog from "../ProjectEnablementDialog";
import { CreateProjectButton, CreateProjectFormDialog } from "../CreateProjectFormDialog";
import UpdateProjectFormDialog from "../UpdateProjectFormDialog";
import { handleErrorDescription } from "../CompaniesListView/helpers";

const DetailViewContext = React.createContext();
DetailViewContext.displayName = "DetailViewContext";

export const CompanyDetailView = () => {
  const [projectSelected, setProjectSelected] = React.useState(null);

  const { getCompanyById } = useCompanyOperations();
  const companyState = useAsync();
  const { run: runCompany, setData: setCompanyData, data: company, isIdle, isLoading, isError, error } = companyState;
  const { id: companyId } = useParams();
  useHeaderTitle(PROJECTS_LIST_HEADER_TITLE);

  const projects = companyState.data?.projects?.items || [];

  React.useEffect(() => {
    runCompany(getCompanyById(companyId));
  }, [runCompany, getCompanyById, companyId]);

  const onConfirmCompanyEnablement = (updatedCompany) => {
    setCompanyData({ ...company, ...updatedCompany });
  };

  const updateCompanyState = (updatedCompany) => setCompanyData({ ...company, ...updatedCompany });
  const onUpdateConfirm = (updatedCompany) => {
    updateCompanyState(updatedCompany);
  };

  const onConfirmProjectEnablement = (updatedProject) => {
    let projectsCopy = [...projects];
    const projectUpdatedIndex = projectsCopy.findIndex((project) => project.id === updatedProject.id);

    projectsCopy[projectUpdatedIndex] = {
      ...projectsCopy[projectUpdatedIndex],
      ...updatedProject,
    };

    const updatedCompany = {
      ...company,
      projects: { items: projectsCopy },
    };

    setCompanyData(updatedCompany);
  };

  function onCreateProjectConfirm(newProject) {
    const updatedCompany = {
      ...company,
      projects: { items: [newProject, ...projects] },
    };

    setCompanyData(updatedCompany);
  }

  function onEditButtonClick(event, rowData) {
    setProjectSelected(rowData);
  }

  function onUpdateProjectConfirm(updatedProject) {
    onConfirmProjectEnablement(updatedProject);
    setProjectSelected(null);
  }

  function onUpdateProjectCancel() {
    setProjectSelected(null);
  }

  function onErrorFallbackClick() {
    runCompany(getCompanyById(companyId));
  }
  const errorFallbackDescription = handleErrorDescription(error);

  return (
    <>
      {!isError && (
        <>
          <EnablementProvider>
            <CompanyEnablementDialog onConfirm={onConfirmCompanyEnablement} />
            <CompanyFormOperationsProvider>
              <CompanyDetailCard data={company} isLoading={isIdle || isLoading} style={{ marginBottom: "32px" }} />
              <UpdateCompanyFormDialog {...companyState.data} onConfirm={onUpdateConfirm} />
            </CompanyFormOperationsProvider>
          </EnablementProvider>
          <EnablementProvider>
            <ProjectEnablementDialog onConfirm={onConfirmProjectEnablement} />

            <CompanyFormOperationsProvider>
              <ProjectsList projects={projects} isLoading={isLoading} onEditButtonClick={onEditButtonClick} />
              {projectSelected !== null && (
                <UpdateProjectFormDialog
                  projectId={projectSelected.id}
                  projectVersion={projectSelected._version}
                  initialValues={{
                    name: projectSelected.name,
                    description: projectSelected.description,
                    code: projectSelected.code,
                    contractNumber: projectSelected.contractNumber,
                    startAt: projectSelected.startAt,
                    endAt: projectSelected.endAt,
                  }}
                  onConfirm={onUpdateProjectConfirm}
                  onCancel={onUpdateProjectCancel}
                />
              )}
            </CompanyFormOperationsProvider>

            <CompanyFormOperationsProvider>
              <CreateProjectFormDialog companyID={companyId} onConfirm={onCreateProjectConfirm} />
              <CreateProjectButton>Crear Proyecto</CreateProjectButton>
            </CompanyFormOperationsProvider>
          </EnablementProvider>
        </>
      )}
      <FetchErrorFallback
        open={isError}
        description={errorFallbackDescription}
        onClick={onErrorFallbackClick}
        limitAttems={0}
      />
    </>
  );
};

export default CompanyDetailView;
