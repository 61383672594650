import { useCallback } from "react";
import useGraphQL from "hooks/useGraphQL";
import useNotifier from "hooks/useNotifier";

import {
  createProject as createProjectMutation,
  updateProject as updateProjectMutation,
} from "graphql-custom/mutations";

function useProjectOperations() {
  const { runGraphQLOperation, loading } = useGraphQL();
  const { showError } = useNotifier();

  const createProject = useCallback(
    async (input, { successMsg, errorMsg } = {}) => {
      if (!input || !input.companyID) {
        showError("Ocurrio un error en la solicitud, intentelo más tarde.");
        throw new Error("Invalid input params.");
      }
      let projectCreated = await runGraphQLOperation({
        operation: createProjectMutation,
        variables: { input },
        notifications: { successMsg, errorMsg },
      });
      projectCreated = projectCreated.createProject;
      return projectCreated;
    },
    [runGraphQLOperation, showError]
  );

  const updateProject = useCallback(
    async (input, { successMsg, errorMsg } = {}) => {
      if (!input || !input.id || !input._version) {
        showError("Ocurrio un error en la solicitud, intentelo más tarde.");
        throw new Error("Invalid input params.");
      }
      let updatedProject = await runGraphQLOperation({
        operation: updateProjectMutation,
        variables: { input },
        notifications: { successMsg, errorMsg },
      });
      updatedProject = updatedProject.updateProject;
      return updatedProject;
    },
    [runGraphQLOperation, showError]
  );

  return { updateProject, createProject, loading };
}

export default useProjectOperations;
