export const SIGNUP_FORM_FIELDS = {
  signIn: {
    username: {
      label: "Correo Electrónico",
      placeholder: "Correo Electrónico",
      isRequired: true,
      labelHidden: false,
      order: 1,
    },
    password: {
      label: "Contraseña",
      placeholder: "Contraseña",
      isRequired: true,
      labelHidden: false,
      order: 2,
    },
  },
  signUp: {
    given_name: {
      label: "Nombre",
      placeholder: "Nombre",
      isRequired: true,
      labelHidden: false,
      order: 1,
    },
    family_name: {
      label: "Apellido",
      placeholder: "Apellido",
      isRequired: true,
      labelHidden: false,
      order: 2,
    },
    phone_number: {
      label: "Teléfono",
      placeholder: "55555555",
      isRequired: true,
      dialCode: "+52",
      labelHidden: false,
      order: 3,
    },
    email: {
      label: "Correo Electrónico",
      placeholder: "Correo Electrónico",
      isRequired: true,
      labelHidden: false,
      order: 4,
    },
    password: {
      label: "Contraseña",
      placeholder: "Contraseña",
      isRequired: true,
      labelHidden: false,
      order: 5,
    },
    confirm_password: {
      label: "Confirmar contraseña",
      placeholder: "Confirmar contraseña",
      isRequired: true,
      labelHidden: false,
      order: 6,
    },
  },
};

export const SIGNUP_ATTRIBUTES = [
  "given_name",
  "family_name",
  "email",
  "password",
  "phone_number",
  /*
  "custom:grntdAgreemt" / los atributos custom se gestionan a través de la 
  propiedad components de authenticator, en el apartado SignUp > FormFields con campos de input
  custom donde su name tiene un valor "custom:nombre-de-la-propiedad"
   */
];

export const LOGIN_MECHANISMS = ["email"];

export const AUTHENTICATOR_ROUTES = {
  IDLE: "idle",
  SET_UP: "setup",
  SIGNIN: "signIn",
  SIGNUP: "signUp",
  CONFIRM_SIGNIN: "confirmSignIn",
  CONFIRM_SIGNUP: "confirmSignUp",
  SETUP_TOTP: "setupTOTP",
  FORCE_NEW_PASSWORD: "forceNewPassword",
  RESET_PASSWORD: "resetPassword",
  CONFIRM_RESET_PASSWORD: "confirmResetPassword",
  VERIFY_USER: "verifyUser",
  CONFIRM_VERIFY_USER: "confirmVerifyUser",
  SIGN_OUT: "signOut",
  AUTHENTICATED: "authenticated",
};

export const AUTH_COMPONENT_CONTENT = {
  [AUTHENTICATOR_ROUTES.SIGNIN]: {
    headerText: "Inicio de sesión",
    mainActionText: "Entrar",
  },
  [AUTHENTICATOR_ROUTES.SIGNUP]: {
    headerText: "Registro de cuenta",
    mainActionText: "Registrar",
  },
  [AUTHENTICATOR_ROUTES.CONFIRM_SIGNUP]: {
    headerText: "Confirmación de registro de cuenta",
  },
  [AUTHENTICATOR_ROUTES.RESET_PASSWORD]: {
    headerText: "Recuperación de contraseña",
  },
  [AUTHENTICATOR_ROUTES.CONFIRM_RESET_PASSWORD]: {
    headerText: "Confirmación de recuperación de contraseña",
  },
  [AUTHENTICATOR_ROUTES.VERIFY_USER]: {
    haederText: "Verificación de usuario",
  },
  [AUTHENTICATOR_ROUTES.CONFIRM_VERIFY_USER]: {
    headerText: "Confirmación de verificación de usuario",
  },
};

export const formId = "sign-in-form";
export const formSelector = "form[data-amplify-form='']";
export const GRANTED_AGREEMENT_COGINITO_CUSTOM_ATTRIBUTE = "custom:grntdAgreemt";
export const CHILDREN_COMPONENT_NAMES = ["Routes"];
export const submitButtonSelector = "button[type='submit']";
export const EMAIL_INPUT = "input[name='email']";
export const PHONE_INPUT = "input[name='phone_number']";
export const PHONE_COUNTRY_CODE = "select[name='country_code'].amplify-select";
export const INPUT_CONTROLLED_CLASS = "input-controlled";
export const INPUT_FIELD_ERROR_CLASS = "inputFieldError";
export const EMAIL_VALIDATION_REGEXP =
  // eslint-disable-next-line no-control-regex
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
export const PHONE_VALIDATION_REGEXP = /^\S?([0-9]{10}$\S?)/;
export const REGISTER_SUBMIT_BUTTON_ID = "session-submit-btn";
//custom classes
export const CSS_SELECTOR_CLASSNAME = {
  "[data-amplify-authenticator] [data-amplify-container]": "authenticator-container",
  "[data-amplify-authenticator]": "authenticator",
  "fieldset > div.amplify-flex": "textFieldsWrapper",
  "div.amplify-flex > div.amplify-textfield": "textFields",
};

export const CUSTOM_SESSION_ERRORS = {
  USER_HAS_NO_GROUPS: "PreAuthentication failed with error User has no groups assigned.",
};

export const SIGNIN_TABINDEX_ELEMENTS = [
  {
    element: () => document.querySelector("div[role='tablist']"),
    tabIndex: -1,
  },
  {
    element: () => document.getElementById(formId),
    tabIndex: -1,
  },
  {
    element: () => document.getElementById("sesion-client-logo"),
    tabIndex: 1,
  },
  {
    element: () => document.getElementById("company-headquaters"),
    tabIndex: 2,
  },
  // {
  //   element: () => document.getElementById("sesion-indika-logo"),
  //   tabIndex: 3,
  // },
  // {
  //   element: () => document.getElementById("sesion-route-text"),
  //   tabIndex: 4,
  // },
  {
    element: () => document.querySelector("input[name='username']"),
    tabIndex: 5,
  },
  {
    element: () => document.querySelector("input[name='password']"),
    tabIndex: 6,
  },
  {
    element: () => document.querySelector("button[aria-label='Show password']"),
    tabIndex: 7,
  },
  {
    element: () => document.getElementById("signin-btn"),
    tabIndex: 8,
  },
  {
    element: () => document.getElementById("password-recovery-btn"),
    tabIndex: 9,
  },
  {
    element: () => document.getElementById("create-account-btn"),
    tabIndex: 10,
  },
  {
    element: () => document.getElementById("privacy-footer-btn"),
    tabIndex: 11,
  },
  {
    element: () => document.getElementById("terms-footer-btn"),
    tabIndex: 12,
  },
];
