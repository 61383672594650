import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
    color: "#fff",
  },
  root: {
    paddingBottom: theme.spacing(6),
  },
  sectionTitleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "10px 15px",
  },
  sectionTitleText: {
    margin: 0,
    fontWeight: "bold",
    fontSize: 18,
    marginLeft: 16,
  },
  card: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(5),
  },
  cardSpacing: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  paper: {
    marginTop: theme.spacing(2),
    width: "100%",
    overflowX: "auto",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));

export default useStyles;
