import React from "react";
import dayjs from "dayjs";

import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

import DataLabel from "components/DataLabel";
import MaterialTable from "components/custom/MaterialTable";
import Button from "components/custom/Button";
import AttachmentsDialog from "components/common/dialog/AttachmentsDialog";
import Card from "components/custom/Card";
import DetailTitle from "components/DetailTitle";
import ScheduleNewInstallationDialog from "components/ScheduleNewInstallationDialog";
import GroupRestrictedView from "components/common/GroupRestrictedView";
import WordConfirmationDialog, { KeyWord } from "components/common/WordConfirmationDialog";

import useStyles from "./ValidatorInstallationDetailsView.jss";
import { INSTALLATIONS_ROOT } from "constant/route/validation";

import cleanUUID from "util/cleanUUID";
import { TaskStatus } from "models";
import ConfirmationDialog from "components/ConfirmationDialog";

function noInfo(data) {
  return data || "S/I";
}

const materialsColumns = [
  {
    title: "CÓDIGO DE MATERIAL",
    render: (rowData) => `${noInfo(rowData?.asset?.material?.code)}`,
  },
  {
    title: "NOMBRE",
    render: (rowData) => `${noInfo(rowData?.asset?.material?.name)}`,
  },
  {
    title: "SERIE",
    render: (rowData) => `${noInfo(rowData?.asset?.serial === "DEFAULT_SERIE" ? "Sin Serie" : rowData?.asset?.serial)}`,
  },
  {
    title: "UNIDAD",
    render: (rowData) => `${noInfo(rowData?.asset?.uom?.name)}`,
  },
  {
    title: "CANTIDAD",
    render: (rowData) => `${noInfo(rowData?.asset?.quantity)}`,
  },
];

const notesColumns = [
  { title: "Mensaje", field: "comments" },
  {
    title: "Fecha",
    field: "createdAt",
    render: (rowData) => dayjs(rowData.createdAt).format("DD/MM/YYYY HH:mm"),
  },
  {
    title: "Usuario",
    field: "supervisor.name",
    render: (rowData) =>
      `${rowData?.supervisor?.name || ""} ${rowData?.supervisor?.lastnameP || ""} ${
        rowData?.supervisor?.lastnameM || ""
      } (${rowData?.supervisor?.email || ""} , ${rowData?.supervisor?.phone || ""})`,
  },
];

const ALLOWED_UPDATE_GROUPS = ["supervisors"];

function ValidatorInstallationDetailsViewUI({
  isLoading,
  installationDetails,
  observations,
  materials,
  validateInstallationDialogOpen,
  handleValidateInstallationDialogVisibility,
  handleValidateInstallationClickButton,
  installationUsers,
  deleteDialogProps,
  ...props
}) {
  const classes = useStyles();

  function getCardActions(allowUpdate) {
    const actions = [];
    if (
      (installationDetails.status === TaskStatus.SCHEDULED ||
        installationDetails.status === TaskStatus.IN_PROGRESS ||
        installationDetails.status === TaskStatus.WITH_INSPECTION_NOTES) &&
      allowUpdate
    ) {
      actions.push({
        icon: EditIcon,
        text: "Editar",
        onClick: props.onEditBtnClick,
      });
    }
    if (installationDetails.status !== TaskStatus.REVIEWED && allowUpdate) {
      actions.push({
        icon: DeleteIcon,
        text: "Eliminar",
        onClick: deleteDialogProps.onOpen,
      });
    }
    return actions;
  }

  const cleanedID = cleanUUID(installationDetails.id);

  return (
    <GroupRestrictedView group={ALLOWED_UPDATE_GROUPS}>
      {(allowUpdate) => (
        <div className={classes.root}>
          <Card
            header={{
              title: <DetailTitle to={INSTALLATIONS_ROOT} title={`INSTALACIÓN ${cleanedID}`} />,
              actions: getCardActions(allowUpdate),
            }}
          >
            {/* Información de la ubicación de la instalación ************************** */}
            <Box className={classes.sectionTitleContainer}>
              <img src="/img/portfolio.png" alt="icon" />
              <p className={classes.sectionTitleText}>Información de la ubicación de instalación</p>
            </Box>
            <Divider variant="middle" />
            <Box className={classes.sectionTitleContainer}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={4}>
                  <DataLabel
                    label="Fecha y hora de inicio:"
                    data={`${installationDetails.startDate} ${installationDetails.startTime}`}
                    loading={isLoading}
                  />
                </Grid>
                <Grid item xs={6} md={4}>
                  <DataLabel
                    label="Fecha y hora de fin:"
                    data={`${installationDetails.endDate} ${installationDetails.endTime}`}
                    loading={isLoading}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box className={classes.sectionTitleContainer}>
              <Grid container spacing={2}>
                <Grid item md={4}>
                  <DataLabel
                    label="Lugar de instalación:"
                    data={`${
                      installationDetails?.buildingLocation
                        ? `${installationDetails?.buildingLocation?.premises?.name}`
                        : ""
                    }`}
                    loading={isLoading}
                  />
                </Grid>
                <Grid item md={4}>
                  <DataLabel
                    label="Zona"
                    data={`${
                      installationDetails.buildingLocation ? `${installationDetails?.buildingLocation?.name}` : ""
                    }`}
                    loading={isLoading}
                  />
                </Grid>
                <Grid item md={4}>
                  <DataLabel
                    label="DIRECCIÓN"
                    data={`${installationDetails?.buildingLocation?.premises?.address?.street || ""} ${
                      installationDetails?.buildingLocation?.premises?.address?.number || ""
                    } C.P. ${installationDetails?.buildingLocation?.premises?.address?.zipCode || ""}, ${
                      installationDetails?.buildingLocation?.premises?.address?.city || ""
                    } `}
                    loading={isLoading}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <DataLabel
                    label="Responsable"
                    data={`${installationDetails?.buildingLocation?.premises?.custodian?.name || ""} ${
                      installationDetails?.buildingLocation?.premises?.custodian?.lastnameP || ""
                    } ${installationDetails?.buildingLocation?.premises?.custodian?.lastnameM || ""} (${
                      installationDetails?.buildingLocation?.premises?.custodian?.email || ""
                    } , ${installationDetails?.buildingLocation?.premises?.custodian?.phone || ""})`}
                    loading={isLoading}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <DataLabel
                    label="Supervisor"
                    data={
                      installationDetails?.supervisor
                        ? `${installationDetails?.supervisor?.name || ""} ${
                            installationDetails?.supervisor?.lastnameP || ""
                          } ${installationDetails?.supervisor?.lastnameM || ""} (${
                            installationDetails?.supervisor?.email || ""
                          } , ${installationDetails?.supervisor?.phone || ""})`
                        : ""
                    }
                    loading={isLoading}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography variant="subtitle2" component="span" color="textSecondary">
                    <b>Instalador(es)</b>
                  </Typography>
                  <ul>
                    {installationUsers.map(({ user }) => (
                      <li>
                        {`${user.name || ""} ${user.lastnameP || ""} ${user.lastnameM || ""} (${user.email || ""} , ${
                          user.phone || ""
                        })`}
                      </li>
                    ))}
                  </ul>
                </Grid>
              </Grid>
            </Box>

            {/* Información de la instalación ****************************************** */}
            <Grid container>
              <Grid item md={12}>
                <Box className={classes.sectionTitleContainer}>
                  <div>
                    <img src="/img/usercheck.png" alt="icon" />
                  </div>
                  <p className={classes.sectionTitleText}>Información de la instalación</p>
                </Box>
              </Grid>
            </Grid>
            <Divider variant="middle" />

            <Box className={classes.sectionTitleContainer}>
              <Grid container spacing={2}>
                <Grid item md={6} sm={12}>
                  <DataLabel
                    label="Tipo:"
                    data={`${installationDetails.taskType ? `${installationDetails.taskType.name}` : ""}`}
                    loading={isLoading}
                  />
                </Grid>
                <Grid item md={6} sm={12}>
                  <DataLabel
                    label="Descripción"
                    data={`${installationDetails.taskType ? `${installationDetails.taskType.description}` : ""}`}
                  />
                </Grid>
                <Grid item md={6} sm={12}>
                  <DataLabel
                    label="Cliente"
                    data={
                      installationDetails.project?.company ? installationDetails.project.company.name : "Sin asignación"
                    }
                  />
                </Grid>
                <Grid item md={6} sm={12}>
                  <DataLabel
                    label="Proyecto"
                    data={
                      installationDetails.project
                        ? `${installationDetails.project.name} (${installationDetails.project.code})`
                        : "Sin asignación"
                    }
                  />
                </Grid>
              </Grid>
            </Box>

            {/* Actividad registrada *************************************************** */}
            {installationDetails.status !== "SCHEDULED" && (
              <>
                <Box className={classes.sectionTitleContainer}>
                  <p className={classes.sectionTitleText}>Actividad registrada</p>
                </Box>
                <Divider variant="middle" />

                <Box className={classes.sectionTitleContainer}>
                  <Grid container spacing={2}>
                    <Grid item xs={6} md={4}>
                      {installationDetails.startedAt && (
                        <DataLabel
                          label="Fecha y hora de inicio"
                          data={dayjs(installationDetails.startedAt).format("DD/MM//YYYY HH:mm")}
                          loading={isLoading}
                        />
                      )}
                    </Grid>
                    <Grid item xs={6} md={4}>
                      {installationDetails.endedAt && (
                        <DataLabel
                          label="Fecha y hora de fin"
                          data={dayjs(installationDetails.endedAt).format("DD/MM//YYYY HH:mm")}
                          loading={isLoading}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} md={4} container justify="flex-end">
                      <Button variant="outlined" visible={!isLoading} onClick={props.onAttachmentBtnClick}>
                        VER EVIDENCIAS
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </>
            )}

            {/* Acciones *************************************************************** */}
            {allowUpdate && (
              <Box className={classes.sectionTitleContainer}>
                <Grid container>
                  <Grid item xs={4}></Grid>
                  <Grid item xs={8}>
                    {Boolean(installationDetails.status === TaskStatus.FINALIZED) && (
                      <Box textAlign="right">
                        <Button
                          color="primary"
                          variant="contained"
                          disableElevation
                          onClick={() => handleValidateInstallationDialogVisibility(true)}
                        >
                          VALIDAR INSTALACIÓN
                        </Button>
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Box>
            )}
          </Card>
          <Paper className={classes.paper}>
            <MaterialTable
              title={`${
                installationDetails?.task?.status === "SCHEDULED" || installationDetails?.task?.status === "IN_PROGRESS"
                  ? "Activos estimados"
                  : "Activos utilizados"
              }`}
              data={materials}
              columns={materialsColumns}
            />
          </Paper>

          <Paper className={classes.paper}>
            <MaterialTable title="Observaciones" columns={notesColumns} data={observations} />
          </Paper>

          <Dialog
            open={validateInstallationDialogOpen}
            onClose={() => handleValidateInstallationDialogVisibility(false)}
            aria-labelledby="alert-dialog-title"
          >
            <DialogTitle id="alert-dialog-title">Validación de Instalación</DialogTitle>
            <DialogContent dividers>
              <DialogContentText>Estás validando la instalación {cleanedID}.</DialogContentText>
              <DialogContentText>¿Es correcta la instalación? </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleValidateInstallationDialogVisibility(false)} color="default">
                CANCELAR
              </Button>
              <Button onClick={() => handleValidateInstallationClickButton()} color="primary" autoFocus>
                ACEPTAR
              </Button>
            </DialogActions>
          </Dialog>
          <WordConfirmationDialog
            open={deleteDialogProps.open}
            okBtnText="Confirmar"
            confirmationWord={cleanedID}
            title="ATENCIÓN"
            onConfirm={deleteDialogProps.onConfirm}
            onCancel={deleteDialogProps.onClose}
          >
            <DialogContentText>
              Está a punto de eliminar una instalación. Todo su detalle y progreso será borrado.
            </DialogContentText>
            <DialogContentText>
              Si desea continuar, ingrese el folio de la actividad{" "}
              <KeyWord bold italic>
                {cleanedID}
              </KeyWord>{" "}
              en el campo de texto debajo y de click en el botón &quot;Continuar&quot;.
            </DialogContentText>
          </WordConfirmationDialog>

          <Backdrop className={classes.backdrop} open={isLoading}>
            <CircularProgress color="inherit" />
          </Backdrop>
          {props.installationDialogProps.open && <ScheduleNewInstallationDialog {...props.installationDialogProps} />}
          <AttachmentsDialog
            {...props.attachmentDialogProps}
            attachments={installationDetails?.attachments?.items}
            withSections={true}
          />
        </div>
      )}
    </GroupRestrictedView>
  );
}

export default ValidatorInstallationDetailsViewUI;
