import React from "react";
import { useAuthenticator, View } from "@aws-amplify/ui-react";
import { TextActionLink, FormFooter, SignInAuthenticatorFooter } from "./MicroComponents";
import { formId, formSelector, SIGNIN_TABINDEX_ELEMENTS } from "./constants";
import { addTabIndex } from "./helpers";
import { Logger } from "aws-amplify";
const logger = new Logger("SignInCustom");

export const SignInCustomFooter = () => {
  const authProps = useAuthenticator((context) => [context.route]);

  React.useLayoutEffect(() => {
    //obtenemos formulario de inicio de sesión para añadirle un atributo i
    logger.info("Añadiendo id a formulario de inicio de sesión");
    const formElement = document.querySelector(formSelector);
    formElement.setAttribute("id", formId);
    logger.info("id añadido:", formId);
    logger.info("Añadiendo tabIndex a elementos");
    addTabIndex(SIGNIN_TABINDEX_ELEMENTS);
    logger.info("Indices añadidos");
  }, []);

  const actionLinkProps = {
    action: authProps.toSignUp,
    children: "Crear una cuenta",
    textDecoration: "none",
    id: "create-account-btn",
    title: "Mover a sección de creación de cuenta",
  };
  const buttonProps = {
    form: formId,
    id: "signin-btn",
  };
  return (
    <View>
      <View padding="0 2rem" marginTop="-1rem">
        <TextActionLink
          text="¿Olvidaste tu contraseña?"
          action={authProps.toResetPassword}
          id="password-recovery-btn"
          title="Mover a sección de recuperación de contraseña"
        >
          Recuperar contraseña
        </TextActionLink>
      </View>
      <View padding="1rem 2rem 2rem 2rem">
        <FormFooter buttonProps={buttonProps} authProps={authProps} textActionLinkProps={actionLinkProps} />
      </View>
      <SignInAuthenticatorFooter />
    </View>
  );
};
