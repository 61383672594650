import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
// componentes custom
import Select from "components/custom/Select";
// utilerias
import listTypes from "graphql-custom/queries/listTaskTypes";
import makeOptionsList from "util/makeOptionsList";
import useGraphQL from "hooks/useGraphQL";

const TypesSelector = ({
  control,
  errors,
  name,
  label,
  emptyMessage,
  selectMessage,
  typeCategory,
  onDataLoaded,
  required,
  ...props
}) => {
  const [types, setTypes] = useState([]);
  const { loading, runGraphQLOperation } = useGraphQL();

  useEffect(() => {
    fetchTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (types.length > 0) {
      onDataLoaded(types);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [types]);

  function fetchTypes() {
    runGraphQLOperation({
      operation: listTypes,
      variables: { filter: typeCategory !== null ? { category: { eq: typeCategory } } : null },
      notifications: { errorMsg: "Error obteniendo los tipos de mantenimiento, cierra y vuelve a abrir la ventana" },
    }).then(({ listTaskTypes: { items } }) => {
      const typesOptions = makeOptionsList(items, { label: "name", value: "id", disabled: "_deleted" });
      setTypes(typesOptions);
    });
  }

  return (
    <Controller
      render={(renderProps) => {
        // Callback custom para manejar los eventos de change de Controller y el personalizado
        function onChange(event, data, option) {
          // onChange de Controller de use-react-form
          renderProps.onChange(event);
          // onChange customizado del componente
          props.onChange(event, data, option);
        }
        return (
          <Select
            {...renderProps}
            {...props}
            label={label}
            fullWidth
            onChange={onChange}
            options={types}
            emptyMessage={emptyMessage}
            selectMessage={selectMessage}
            error={!!errors[name]?.type}
            disabled={loading}
            helperText={errors.type?.message}
            loading={loading}
          />
        );
      }}
      control={control}
      name={name}
      rules={required ? RULES : {}}
    />
  );
};

const RULES = {
  required: "Seleccione un tipo",
};

TypesSelector.propTypes = {
  errors: PropTypes.object,
  control: PropTypes.object,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  emptyMessage: PropTypes.string,
  selectMessage: PropTypes.string,
  typeCategory: PropTypes.oneOf(["MAINTENANCE", "SUPPORT", "IMPLEMENTATION", "SHIPMENT", "UNDEFINED", null]),
  onChange: PropTypes.func,
  onDataLoaded: PropTypes.func,
  required: PropTypes.bool,
};

TypesSelector.defaultProps = {
  label: "Tipo de mantenimiento",
  emptyMessage: "Sin tipos disponibles",
  selectMessage: "Selecciona el tipo de mantenimiento",
  typeCategory: null,
  onChange: () => {},
  onDataLoaded: () => {},
  required: false,
};

export default TypesSelector;
