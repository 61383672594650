import React, { useState, useEffect } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import { useSelector } from "react-redux";
import Section from "components/Section";
import useHeaderTitle from "hooks/useHeaderTitle";
import MaterialTable from "components/custom/MaterialTable";
import FloatingButton from "components/FloatingButton";
import ConfirmationDialog from "components/ConfirmationDialog/ConfirmationDialog";
import NewTeamMember from "./NewTeamMember";
import { useActions } from "hooks/useActions";
import { REQUEST_STATUS } from "constant/requestStatus";
import { getTeamByUser, getTeamInfo, deleteUserFromTeam } from "redux/dispatcher/siteOperations";

/**
 * @component MyTeamView
 *
 * Componente que implementa el manejo centralizado de llamadas a GraphQL con Redux. Para conectarse al store de
 * Redux se usa el hook useSelector y para poder despachar acciones se utliza el hook personalizado useActions, el
 * cual recibe una función o un arreglo de funciones que retornan una acción, y devuelve las mismas funciones unidas
 * a Redux para que sean despachadas.
 */
function MyTeamView() {
  useHeaderTitle("Mi Equipo de Trabajo");
  const authUserId = useSelector(({ session }) => session.userId);
  const { team, teamId, teamStatus, isDeletingUser } = useSelector(({ site }) => site);
  const [fetchTeamByUser, fetchTeamInfo, deleteUserTeam] = useActions([getTeamByUser, getTeamInfo, deleteUserFromTeam]);
  const [showRemoveDialog, setShowRemoveDialog] = useState(false);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [selectedRelation, setSelectedRelation] = useState(null);
  const [tableColumns] = useState([
    { title: "Nombre", field: "fullName" },
    { title: "Correo", field: "email" },
    { title: "Teléfono", field: "phone" },
  ]);

  useEffect(() => {
    if (authUserId) fetchTeamByUser(authUserId);
  }, [fetchTeamByUser, authUserId]);

  useEffect(() => {
    if (teamId) {
      fetchTeamInfo(teamId);
    }
  }, [teamId, fetchTeamInfo]);

  const openConfirmationDialog = (e, row) => {
    const { relationId, _version, _deleted } = row;
    setSelectedRelation({ relationId, _version, _deleted });
    setShowRemoveDialog(true);
  };

  const removeTeamMember = () => {
    deleteUserTeam(selectedRelation);
    setSelectedRelation(null);
    setShowRemoveDialog(false);
  };

  const cancelRemoveTeamMember = () => {
    setSelectedRelation(null);
    setShowRemoveDialog(false);
  };

  const updateTeam = () => {
    fetchTeamInfo(teamId);
  };

  return (
    <Section>
      <MaterialTable
        title="Integrantes del equipo"
        actions={[{ icon: DeleteIcon, onClick: openConfirmationDialog, tooltip: "Eliminar" }]}
        columns={tableColumns}
        data={team}
        isLoading={teamStatus === REQUEST_STATUS.PENDING || isDeletingUser === REQUEST_STATUS.PENDING}
      />
      <FloatingButton
        bottom={15}
        right={15}
        color="primary"
        variant="extended"
        aria-label="Agregar Integrante"
        onClick={() => setShowAddDialog(true)}
      >
        Nuevo Integrante
      </FloatingButton>
      <ConfirmationDialog
        open={showRemoveDialog}
        title="Confirmación"
        onConfirm={removeTeamMember}
        onCancel={cancelRemoveTeamMember}
      >
        ¿Deseas eliminar al miembro del equipo?
      </ConfirmationDialog>
      {showAddDialog && (
        <NewTeamMember
          onCancel={() => setShowAddDialog(false)}
          onUpdateTeam={updateTeam}
          usersWithTeam={team.map(({ id }) => id)}
        />
      )}
    </Section>
  );
}

export default MyTeamView;
