import makeStyles from "@material-ui/styles/makeStyles";

export default function useTableMobileStyles() {
  return useStyles();
}

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      "& .MuiTableHead-root": {
        display: "none",
      },
      "& .MuiToolbar-root": {
        flexDirection: "column",
        padding: theme.spacing(2),
      },
      "& div[class*='MTableToolbar-title-']": {
        order: 1,
        textAlign: "left",
        width: "100%",
      },
      "& div[class*='MTableToolbar-searchField-']": {
        width: "100%",
        order: 0,
        marginBottom: theme.spacing(2),
      },
      "& tbody td div:only-child": {
        flexDirection: "column",
      },
    },
  },
}));
