import { REQUEST_STATUS } from "constant/requestStatus";
import * as ActionTypes from "redux/action";
import dayjs from "dayjs";

const INITIAL_STATE = {
  rdxActivityTypes: [],
  rdxNextToken: null,
  rdxListStatus: REQUEST_STATUS.INACTIVE,
  rdxUpdateStatus: REQUEST_STATUS.INACTIVE,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case ActionTypes.ACTIVITY_FETCH_ALL_PENDING:
      return { ...state, rdxListStatus: REQUEST_STATUS.PENDING };
    case ActionTypes.ACTIVITY_FETCH_ALL_SUCCESS:
      return addActivities(state, action);
    case ActionTypes.ACTIVITY_FETCH_NEXT_SUCCESS:
      const newState = addActivities(state, action);
      newState.rdxActivityTypes = [
        ...state.rdxActivityTypes,
        ...newState.rdxActivityTypes,
      ];
      return newState;
    case ActionTypes.ACTIVITY_FETCH_ALL_ERROR:
      return { ...state, rdxListStatus: REQUEST_STATUS.FAILED };
    case ActionTypes.ACTIVITY_UPDATE_ITEM_PENDING:
    case ActionTypes.ACTIVITY_CREATE_PENDING:
      return { ...state, rdxUpdateStatus: REQUEST_STATUS.PENDING };
    case ActionTypes.ACTIVITY_UPDATE_ITEM_SUCCESS:
      const [updateTaskType] = transformTypesData([
        action.payload.updateTaskType,
      ]);
      const activities = [...state.rdxActivityTypes];
      const index = activities.findIndex((a) => a.id === updateTaskType.id);
      activities[index] = { ...activities[index], ...updateTaskType };
      return {
        ...state,
        rdxUpdateStatus: REQUEST_STATUS.SUCCESSFUL,
        rdxActivityTypes: activities,
      };
    case ActionTypes.ACTIVITY_CREATE_SUCCESS:
      const { createTaskType: activity } = action.payload;
      const [newActivity] = transformTypesData([activity]);
      const newActivities = [...state.rdxActivityTypes, newActivity];
      return {
        ...state,
        rdxUpdateStatus: REQUEST_STATUS.SUCCESSFUL,
        rdxActivityTypes: newActivities,
      };
    case ActionTypes.ACTIVITY_UPDATE_ITEM_ERROR:
    case ActionTypes.ACTIVITY_CREATE_ERROR:
      return { ...state, rdxUpdateStatus: REQUEST_STATUS.FAILED };
    case ActionTypes.ACTIVITY_RESET_STATUS:
      return { ...state, rdxUpdateStatus: INITIAL_STATE.rdxUpdateStatus };
    default:
      return state;
  }
}

const addActivities = (state, action) => {
  const {
    listTaskTypes: { items, nextToken },
  } = action.payload;
  return {
    ...state,
    rdxListStatus: REQUEST_STATUS.SUCCESSFUL,
    rdxActivityTypes: transformTypesData(items),
    rdxNextToken: nextToken,
  };
};

const transformTypesData = (items) => {
  return items.map((item) => {
    const formattedCreatedAtDate = dayjs(item.createdAt).format(
      "DD/MM/YYYY HH:mm"
    );
    const formattedUpdatedAtDate = dayjs(item.updatedAt).format(
      "DD/MM/YYYY HH:mm"
    );
    return {
      ...item,
      createdAt: formattedCreatedAtDate,
      updatedAt: formattedUpdatedAtDate,
    };
  });
};
