import { DataStore } from "@aws-amplify/datastore";
import { Task, TaskStatus, Ticket, TicketStatus } from "models";

export async function reviewTicket(ticketId, reviewComment, rating) {
  let ticket = await DataStore.query(Ticket, ticketId);
  //   let supportTask = await DataStore.query(Task, taskSupportId);

  //   supportTask = Task.copyOf(supportTask, (updated) => {
  //     updated.status = TaskStatus.REVIEWED;
  //     return updated;
  //   });

  ticket = Ticket.copyOf(ticket, (updated) => {
    updated.ratingComment = reviewComment;
    updated.rating = rating;
    updated.status = TicketStatus.RATED;
    return updated;
  });

  //   await DataStore.save(supportTask);

  return DataStore.save(ticket);
}
