/** Componente que pinta la pagina de inicio de la aplicación
 *  en esta vista parecen los diferentes modulos a los cuales tiene acceso al usuario logueado
 */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import { Device } from "@capacitor/device";
import MoreIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Link from "@material-ui/core/Link";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useDataStoreStatus from "hooks/useDataStoreStatus";
import Tooltip from "@material-ui/core/Tooltip";

import GroupRestrictedView from "components/common/GroupRestrictedView";
import WordConfirmationDialog from "components/common/WordConfirmationDialog";
import AboutInfoDialog from "components/common/AboutInfoDialog";
import ApplicationContext from "context/ApplicationContext";

import useSession from "hooks/useSession";
import useBooleanFlag from "hooks/useBooleanFlag";

import AppBar from "./HideOnScrollAppBar";
import { VALIDATION, OPERATION, ADMIN, WAREHOUSE, SUPPORT, SUPPORT_SUPERVISOR } from "constant/route";

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  disabledCard: {
    opacity: 0.5,
  },
}));

const modules = [
  {
    text: "Administración",
    to: ADMIN.ROOT,
    Icon: <span>Icon</span>,
    group: "admins",
  },
  {
    text: "Inventario para Garantías",
    to: WAREHOUSE.ROOT,
    Icon: <span>Icon</span>,
    group: "warehouse-keepers",
  },
  {
    text: "Actividades",
    to: VALIDATION.ROOT,
    Icon: <span>Icon</span>,
    group: ["supervisors", "auditors"],
  },
  {
    text: "Trabajo en Campo",
    to: OPERATION.ROOT,
    Icon: <span>Icon</span>,
    group: ["support-engineers", "auditors"],
  },
  {
    text: "Solicitud de Soporte",
    to: SUPPORT.ROOT,
    group: ["service-users"],
  },
  {
    text: "Reportes",
    to: SUPPORT_SUPERVISOR.ROOT,
    group: ["analyzers"],
  },
];

const DEFAULT_QLIK_URL = "https://qlik-fsm-gs.indika.mx/sessionvp";
const QLIK_DASHBOARD_URL = process.env.REACT_APP_QLIK_DASHBOARD_URL || DEFAULT_QLIK_URL;

function HomeView() {
  const { dataStoreStatus } = useDataStoreStatus();
  const pendingDataToSycn = useSelector((state) => state.app.pendingDataToSycn);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showConfirmLogoutDialog, setShowConfirmLogoutDialog] = useState(false);
  const [aboutDialogOpen, openAboutDialog, closeAboutDialog] = useBooleanFlag();
  const [isMobile, setIsMobile] = useState(false);
  const session = useSession();

  useEffect(() => {
    getDeviceDetails();
  }, []);

  const classes = useStyles();

  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function logout() {
    session.logout();
  }

  function handleLogOut() {
    if (pendingDataToSycn) {
      setShowConfirmLogoutDialog(true);
    } else {
      logout();
    }
  }

  function handleAboutBtnClick() {
    handleClose();
    openAboutDialog();
  }

  async function getDeviceDetails() {
    const info = await Device.getInfo();
    if (info.platform !== "web" && info.platform !== "electron") {
      setIsMobile(true);
    }
  }

  return (
    <>
      <AppBar
        menu={
          <>
            <Typography variant="body2">{session.data.username}</Typography>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
            >
              <MoreIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={open}
              onClose={handleClose}
            >
              {dataStoreStatus === "IN_PROGRESS" ? (
                <Tooltip title="Espera a que termine la sincronización" open={(isMobile && open) || undefined} arrow>
                  <div>
                    <MenuItem disabled>Cerrar sesión</MenuItem>
                  </div>
                </Tooltip>
              ) : (
                <MenuItem onClick={handleLogOut}>Cerrar sesión</MenuItem>
              )}
              <MenuItem onClick={handleAboutBtnClick}>Acerca de</MenuItem>
            </Menu>
          </>
        }
      />
      <Box my={3} style={{ height: "78vh" }}>
        <Container>
          {/* {networkStatus === "OFFLINE" && (
            <Box mb={2}>
              <Alert icon={<WifiOffOutlinedIcon fontSize="inherit" />} variant="filled" severity="warning">
                Estas en modo fuera de línea, conectate a internet para sincronizar la información.
              </Alert>
            </Box>
          )} */}
          <Grid container spacing={2}>
            {modules.map((mod, index) => (
              <GroupRestrictedView key={index} group={mod.group}>
                {(isAllowed) => {
                  return (
                    <Grid key={`mod-card-${index}`} item xs={12} sm={4} md={3} lg={3}>
                      <Card className={clsx(classes.card, !isAllowed && classes.disabledCard)}>
                        <CardActionArea disabled={!isAllowed} component={RouterLink} to={mod.to}>
                          <CardContent className="home-card-content">
                            <Typography gutterBottom variant="h6" component="h2" className="home-card-text">
                              {mod.text}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  );
                }}
              </GroupRestrictedView>
            ))}
          </Grid>
        </Container>
      </Box >
      <WordConfirmationDialog
        open={showConfirmLogoutDialog}
        okBtnText="Salir"
        confirmationWord={"cerrar sesion"}
        title="ATENCIÓN"
        onConfirm={logout}
        onCancel={() => setShowConfirmLogoutDialog(false)}
      >
        <p>
          Tiene cambios que no han sido sincronizados al sevidor, debe conectarse a internet para poder sincronizar.
        </p>
        <p>
          <b>Si sale ahora se perderán los cambios.</b>
        </p>

        <p>Si aun así desea salir escriba la palabra "cerrar sesion" en el recuadro y presione salir.</p>
      </WordConfirmationDialog>
      <GroupRestrictedView group="analyzers">
        <Box bottom={15} ml={8} position="relative">
          <Link href={QLIK_DASHBOARD_URL} target="_blank" rel="noreferrer">
            Acceso a Tableros Analíticos
          </Link>
        </Box>
      </GroupRestrictedView>
      <AboutInfoDialog open={aboutDialogOpen} onClose={closeAboutDialog} />
    </>
  );
}

export default HomeView;
