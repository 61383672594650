const listTaskTypes = /* GraphQL */ `
  query ListTaskTypes($filter: ModelTaskTypeFilterInput, $limit: Int, $nextToken: String) {
    listTaskTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        category
        createdAt
        updatedAt
        _version
        _deleted
        owner
      }
      nextToken
    }
  }
`;

export default listTaskTypes;
