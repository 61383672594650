export const listBuildingLocationOptionsByPremises = /* GraphQL */ `
  query ListBuildingLocationOptionsByPremises(
    $filter: ModelBuildingLocationFilterInput, 
    $limit: Int, 
    $nextToken: String
  ) {
    listBuildingLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        code
        status
        _deleted
      }
      nextToken
    }
  }
`;