import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import * as queries from "graphql-custom/queries";
import { useSelector } from "react-redux";

import { Controller } from "react-hook-form";
import { ListSubheader, MenuItem, Checkbox, ListItemText, TextField, CircularProgress } from "@material-ui/core/";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { FormatUserName } from "util/FormatDetails";
import useGraphQL from "hooks/useGraphQL";
import { FORM_MODES } from "constant/formModes";
import { filterDeletedItems } from "util/lists";

const UsersTeamSelector = ({ formMode, control, errors, name, label, initialUsers, ...props }) => {
  const userId = useSelector((store) => store.session.userId);
  const [team, setTeam] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const { loading, runGraphQLOperation } = useGraphQL();

  const externalUsersTeam = useMemo(() => {
    return initialUsers.filter(user => {
      const userFound = team.find(userTeam => userTeam.id === user.id);
      return userFound === undefined;
    });
  }, [initialUsers, team]);

  useEffect(() => {
    fetchUserTeam();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (initialUsers.length && !selectedUsers.length) {
      setSelectedUsers(initialUsers.map(u => u.id));
    }
  }, [initialUsers, selectedUsers]);

  async function fetchUserTeam() {
    const { listTeams } = await runGraphQLOperation({
      operation: queries.getUserTeamDetails,
      variables: {
        filter: {
          head: {
            eq: userId,
          },
        },
      },
      notifications: { errorMsg: "Error obteniendo los usuarios de tu equipo, cierra y vuelve a abrir el modal" },
    });
    let [firstTeam] = listTeams.items;
    const { getTeam: teamData } = await runGraphQLOperation({
      operation: queries.getTeam,
      variables: { teamId: firstTeam.id },
    });
    let users = filterDeletedItems(teamData?.users?.items).map(({ user }) => user);
    setTeam([...users]);
  }

  function handleSelectedUsersRender(selectedValue) {
    return selectedValue.map(value => {
      if (formMode === FORM_MODES.CREATE) {
        const userFound = team.find(ut => ut.id === value);
        return FormatUserName(userFound, { withEmail: false, withPhone: false });
      } else if (formMode === FORM_MODES.UPDATE) {
        let user = externalUsersTeam.find(({ id }) => id === value);
        if (!user) {
          user = team.find(ut => ut.id === value);
        }
        return FormatUserName(user, { withEmail: false, withPhone: false });
      }
    })
    .join(", ");
  }

  function handleCheck(userId) {
    const result = selectedUsers.includes(userId);
    return result;
  }

  return (
    <Controller
      control={control}
      name={name}
      rules={RULES}
      render={(renderProps) => {
        // Callback custom para manejar los eventos de change de Controller y el personalizado
        function onChange(event) {
          const value = event.target.value;
          // onChange de Controller de use-react-form
          renderProps.onChange(value);
          // onChange customizado del componente
          props.onChange(event, team);
          //almacenar opciones seleccionadas para validación de checkbox
          setSelectedUsers(value);
        }

        return (
          <TextField
            {...renderProps}
            id={name}
            label={label}
            select
            helperText={errors.technician?.message}
            error={!!errors.technician}
            fullWidth
            disabled={loading}
            SelectProps={{
              // eslint-disable-next-line react/display-name
              IconComponent: () => {
                return loading ? <CircularProgress color="inherit" size={20} /> : <ArrowDropDownIcon color="inherit" />;
              },
              multiple: true,
              onChange: onChange,
              renderValue: (selected) => handleSelectedUsersRender(selected),
              value: renderProps.value,
            }}
            {...props}
          >
            <ListSubheader style={{ pointerEvents: "none" }}>Mi equipo</ListSubheader>
            {team.map(user => (
              <MenuItem key={user.id} value={user.id}>
                <Checkbox checked={handleCheck(user.id)} />
                <ListItemText 
                  primary={FormatUserName(user, { withEmail: false, withPhone: false })}
                />
              </MenuItem>
            ))}
            {formMode === FORM_MODES.UPDATE && externalUsersTeam.length && (
              <ListSubheader style={{ pointerEvents: "none" }}>De otro equipo</ListSubheader>
            )}
            {formMode === FORM_MODES.UPDATE && externalUsersTeam.length &&
              externalUsersTeam.map(user => (
                <MenuItem disabled key={user.id} value={user.id}>
                  <Checkbox disabled disableRipple checked={selectedUsers.indexOf(user.id) > -1} />
                  <ListItemText 
                    disableTypography
                    primary={FormatUserName(user, { withEmail: false, withPhone: false })}
                  />
                </MenuItem>
              ))
            }
          </TextField>
        );
      }}
    />
  );
};

const RULES = {
  required: "Seleccione almenos un usuario",
};

UsersTeamSelector.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  formMode: PropTypes.string,
  errors: PropTypes.object,
  control: PropTypes.object,
  onChange: PropTypes.func,
  initialUsers: PropTypes.array,
};

UsersTeamSelector.defaultProps = {
  onChange: () => {},
  label: "Técnico(s)",
  formMode: "",
  initialUsers: [],
};

export default UsersTeamSelector;
