import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Logger } from "@aws-amplify/core";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import Alert from "@material-ui/lab/Alert";
import DetailTitle from "components/DetailTitle";
import makeStyles from "@material-ui/styles/makeStyles";
import GroupRestrictedView from "components/common/GroupRestrictedView";
import AttachmentsDialog from "components/common/dialog/AttachmentsDialog";
import DataLabel from "components/DataLabel";
import { TicketStatus } from "models";
import cleanUUID from "util/cleanUUID";
import ReactReadMoreReadLess from "react-read-more-read-less";
import { Chip } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Button from "@material-ui/core/Button";
import Card from "components/custom/Card";
import Box from "@material-ui/core/Box";
import Counter from "components/Timer/Counter";
import { getCounterParams, formatCount } from "components/Timer/helpers";
import { getTimerLogs, isMobile } from "components/general/TicketTimerLog/helpers";
import useDeviceSettings from "hooks/useDeviceSettings";

import useBooleanFlag from "hooks/useBooleanFlag";
import * as ROLES from "constant/roles";
import {
  TICKET_STATUSES,
  TICKET_REQUEST_TYPE,
  TICKET_SERVICE_SUBCATEGORY,
  TICKET_IMPACTS,
  TICKET_CHANNEL,
} from "constant/ticketConstants";
import { COUNTER_MODE, COUNTER_COLORS } from "components/Timer/constants";
import ExternalDataSection from "./components/ExternalDataSection";

import { WHITE_LABEL_BRANDS } from "constant/whiteLabelBrands";

import TicketTimerLogView from "components/Tickets/TicketsTimerLogView";

const logger = new Logger("TicketDetailsCard");

const ALLOWED_UPDATE_GROUPS = ["supervisors"];
const SECTION_TITLE = "Ticket";

const SJO_EXTERNAL_ATT_MAPPING = [
  [
    "site",
    (data, externalData) => {
      data.premises = {
        name: externalData.value,
      };
      return data;
    },
  ],
  [
    "serviceSubcategory",
    (data, externalData) => {
      data.serviceSubcategory = externalData.value;
      return data;
    },
  ],
  [
    "serviceSubcategory",
    (data, externalData) => {
      data.serviceSubcategory = externalData.value;
      return data;
    },
  ],
  [
    "serviceCategory",
    (data, externalData) => {
      data.project = {
        ...data.project,
        name: externalData.value,
      };
      return data;
    },
  ],
  [
    "account",
    (data, externalData) => {
      data.project = {
        ...data.project,
        company: {
          name: externalData.value,
        },
      };
      return data;
    },
  ],
];

function handleSpecificDataTreatment(ticketData) {
  let td = { ...ticketData };
  try {
    if (process.env.REACT_APP_WHITE_LABEL_APP === WHITE_LABEL_BRANDS.GRUPO_SIAYEC_ACT && td.externalData) {
      const externalData = JSON.parse(td.externalData);

      SJO_EXTERNAL_ATT_MAPPING.forEach((item) => {
        const [key, handler] = item;
        let extData = externalData.filter((data) => data.key === key);

        if (extData.length) {
          td = { ...td };
          td = handler(td, extData[0]);
        }
      });
    }
  } catch (error) {
    logger.error("handleSpecificDataTreatment: ocurrió un error al transformar el detalle del ticket ", error);
  }

  return td;
}

function renderService(serviceSubcategory) {
  const value = TICKET_SERVICE_SUBCATEGORY[serviceSubcategory];

  if (!value && serviceSubcategory) {
    return serviceSubcategory;
  }

  return value;
}

function renderProject(project) {
  if (typeof project === "object") {
    let value = "";

    if (project?.name) {
      value = `${project?.name}`;
    }

    if (project?.contractNumber) {
      value = `${project?.contractNumber} - ${value}`;
    }

    if (project?.code) {
      value = `${value} (${project?.code})`;
    }
    return value;
  } else if (typeof project === "string" && project) {
    return project;
  } else {
    return "Sin asignación";
  }
}

function TicketDetailsCard({
  ticketDetails: _ticketDetails,
  isFetching,
  to,
  isRequestError,
  actions,
  setIsScheduleSupportDialogOpen,
  setIsReviewDialogOpen,
  setShowSignatureDialog,
  children,
  taskInfo,
  type: userType,
  ...props
}) {
  const location = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const [ticketDetails, setTicketDetails] = useState(_ticketDetails);

  const [isWeb, setIsWeb] = useState(false);
  const device = useDeviceSettings();

  useEffect(() => {
    const td = handleSpecificDataTreatment(_ticketDetails);
    setTicketDetails(td);
  }, [_ticketDetails]);

  useEffect(() => {
    const info = device.getInfo();
    setIsWeb(info.platform === "web");
  }, [device]);

  const [attachsDialogOpen, openAttachsDialog, closeAttachsDialog] = useBooleanFlag();
  isFetching =
    isFetching ||
    ((!taskInfo || !Object.keys(taskInfo)?.length) && (!ticketDetails || !Object.keys(ticketDetails)?.length));
  const showAttachmentsBtn = ticketDetails.attachments?.length > 0;
  const showRateSupportBtn =
    location.pathname.startsWith("/soporte/ticket/") && ticketDetails?.status === TicketStatus.VALIDATED;
  const showValidateBtn =
    location.pathname.startsWith("/soporte/ticket/") &&
    ticketDetails.status === TicketStatus.RESOLVED &&
    ticketDetails.supportType === "REMOTE" &&
    !isSmall;
  const showScheduleSupportBtn =
    location.pathname.startsWith("/validacion/ticket/") && ticketDetails.status === TicketStatus.OPEN;
  const counterProps = getCounterParams(taskInfo);
  function serializeCount(count, mode) {
    return formatCount(count, counterProps.status, ticketDetails?.status, mode, counterProps.totalRangeTime);
  }

  return (
    <>
      <Card
        header={{
          title: (
            <Grid container alignItems="center">
              <Grid item sm={6} md={4} lg={3}>
                <Box>
                  {to ? (
                    <DetailTitle title={`${SECTION_TITLE} ${cleanUUID(ticketDetails.id)}`} to={to} />
                  ) : (
                    <Typography variant="h6">{`${SECTION_TITLE} ${cleanUUID(ticketDetails.id)}`}</Typography>
                  )}
                </Box>
              </Grid>
              <Grid item sm={6} md={4} lg={3}>
                {!isFetching && (
                  <Box>
                    <Chip
                      label={`${TICKET_STATUSES[ticketDetails.status]}${
                        ticketDetails.status === TicketStatus.RESOLVED ? " - En espera de validación" : ""
                      }`}
                      color="primary"
                      className={ticketDetails.status === TicketStatus.RESOLVED ? classes.colorPrimary : ""}
                    />
                  </Box>
                )}
              </Grid>
            </Grid>
          ),
          actions: actions,
        }}
      >
        {isRequestError && (
          <Box p={2}>
            <Alert severity="error">Ha ocurrido un error con la carga del catálogo, recarga la página por favor.</Alert>
          </Box>
        )}
        <Grid container>
          <Grid item xs={12}>
            {location.pathname.startsWith("/soporte/ticket/") && ticketDetails?.status === TicketStatus.VALIDATED && (
              <GroupRestrictedView group={["service-users"]}>
                <Box p={2}>
                  <Alert severity="info">
                    Nos gustaría conocer tu opinión del servicio recibido. Por favor evalúa dando click en “Calificar
                    soporte”.
                  </Alert>
                </Box>
              </GroupRestrictedView>
            )}
            {location.pathname.startsWith("/sitio/actividades/") && ticketDetails?.status === TicketStatus.RESOLVED && (
              <GroupRestrictedView group={["support-engineers", "supervisors"]}>
                <Box p={2}>
                  <Alert severity="warning">
                    {ticketDetails?.supportType === "REMOTE"
                      ? "El solicitante del servicio ingresará la validación del soporte"
                      : "Para finalizar el soporte debes recabar la rúbrica del solicitante del servicio"}
                  </Alert>
                </Box>
              </GroupRestrictedView>
            )}
            {location.pathname.startsWith("/soporte/ticket/") && ticketDetails?.status === TicketStatus.RESOLVED && (
              <GroupRestrictedView group={["service-users", "supervisors"]}>
                <Box p={2}>
                  <Alert severity="warning">
                    {ticketDetails?.supportType === "REMOTE"
                      ? "Debes validar el soporte brindado o agregar observaciones si es que no se ha arreglado el ticket"
                      : "En instalador ha terminado el servicio y ahora buscará al supervisor para la validación de la operación."}
                  </Alert>
                </Box>
              </GroupRestrictedView>
            )}
          </Grid>
          <Grid item xs={12} className={classes.detailsContent} container spacing={2}>
            <Grid item xs={6} sm={6} md={4}>
              <div>
                <DataLabel label="Asunto" data={ticketDetails.subject || "Sin asunto"} loading={isFetching} />
              </div>
            </Grid>
            <Grid item xs={6} sm={6} md={4}>
              <div>
                <DataLabel
                  label="Tipo de solicitud"
                  data={TICKET_REQUEST_TYPE[ticketDetails.requestType] || "Sin Tipo de solicitud"}
                  loading={isFetching}
                />
              </div>
            </Grid>
            <Grid item xs={6} sm={6} md={4}>
              <div>
                <DataLabel
                  label="Sitio de solicitud"
                  data={ticketDetails?.premises?.name || "Sin sitio de solicitud"}
                  loading={isFetching}
                />
              </div>
            </Grid>
            <Grid item xs={6} sm={6} md={4}>
              <div>
                <DataLabel
                  label="Fecha de reporte"
                  data={dayjs(ticketDetails.createdAt).format("DD/MM/YYYY HH:mm")}
                  loading={isFetching}
                />
              </div>
            </Grid>
            {ticketDetails?.requester?.name && (
              <Grid item xs={12} sm={6} md={4}>
                <div>
                  <DataLabel
                    label="Reportado por"
                    data={`${ticketDetails?.requester?.name} ${ticketDetails?.requester?.middlename || ""} ${
                      ticketDetails?.requester?.lastnameP || ""
                    } ${ticketDetails?.requester?.lastnameM || ""} ${ticketDetails?.requester?.email || ""} ${
                      ticketDetails?.requester?.phone || ""
                    }`}
                    loading={isFetching}
                  />
                </div>
              </Grid>
            )}
            <Grid item xs={6} sm={6} md={4}>
              <div>
                <DataLabel
                  label="Servicio"
                  data={renderService(ticketDetails.serviceSubcategory) || "Sin categoria de servicio"}
                  loading={isFetching}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div>
                <Typography variant="subtitle2" component="span" color="textSecondary">
                  <b>Descripción</b>
                </Typography>
                <Typography paragraph variant="body2" className={classes.description}>
                  <ReactReadMoreReadLess
                    charLimit={isSmall ? 200 : 300}
                    readMoreText={"Leer más.."}
                    readLessText={"Leer menos"}
                  >
                    {ticketDetails?.description || "Sin descripción"}
                  </ReactReadMoreReadLess>
                </Typography>
              </div>
            </Grid>

            <GroupRestrictedView group={[ROLES.SUPERVISORS, ROLES.SUPPORT_ENGINEERS]}>
              {(isAllowed) => {
                if (!isAllowed || props.type === ROLES.SERVICE_USERS) return null;
                return (
                  <>
                    <Grid item xs={6} sm={6} md={4}>
                      <div>
                        <DataLabel
                          label="Cliente"
                          data={
                            ticketDetails?.project?.company ? ticketDetails?.project?.company?.name : "Sin asignación"
                          }
                          loading={isFetching}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                      <div>
                        <DataLabel
                          label="Proyecto"
                          data={renderProject(ticketDetails.project) || "Sin Proyecto asignado"}
                          loading={isFetching}
                        />
                      </div>
                    </Grid>
                  </>
                );
              }}
            </GroupRestrictedView>
            {location.pathname.startsWith("/validacion/ticket/") && ticketDetails?.impactDetails && (
              <GroupRestrictedView group={["support-engineers", "supervisors"]}>
                <Grid item xs={12} sm={6} md={4}>
                  <div>
                    <DataLabel
                      label="Impacto"
                      data={TICKET_IMPACTS[ticketDetails.impact] || "Sin magnitud impacto"}
                      loading={isFetching}
                    />
                  </div>
                </Grid>
                <Grid item md={12}>
                  <div>
                    <Typography variant="subtitle2" component="span" color="textSecondary">
                      <b>Descripción de Impacto</b>
                    </Typography>
                    <Typography paragraph variant="body2">
                      <ReactReadMoreReadLess
                        charLimit={isSmall ? 200 : 400}
                        readMoreText={"Leer más.."}
                        readLessText={"Leer menos"}
                      >
                        {ticketDetails?.impactDetails || "Sin descripción"}
                      </ReactReadMoreReadLess>
                    </Typography>
                  </div>
                </Grid>
              </GroupRestrictedView>
            )}

            <Grid item md={4} sm={6} xs={12}>
              <div>
                <DataLabel
                  label="Canal de reporte"
                  data={TICKET_CHANNEL[ticketDetails?.requestChannel] || "Sin canal de reporte"}
                  loading={isFetching}
                />
              </div>
            </Grid>
            {userType === ROLES.SUPERVISORS && (
              <>
                <Grid item md={4} sm={6} xs={12}>
                  <div>
                    {counterProps?.initialCount > 0 ? (
                      <DataLabel
                        label="Tiempo Transcurrido"
                        data={<Counter serializeCount={serializeCount} {...counterProps} />}
                        loading={isFetching}
                      />
                    ) : (
                      <>
                        <Typography variant="subtitle2" component="span" color="textSecondary">
                          <b>Tiempo Transcurrido</b>
                        </Typography>
                        <Typography
                          style={{
                            color: COUNTER_COLORS.PAUSED,
                            fontWeight: 400,
                            opacity: 0.7,
                          }}
                        >
                          {serializeCount(0, COUNTER_MODE.COUNTUP)}
                        </Typography>
                      </>
                    )}
                  </div>
                </Grid>
                {props.externalData && (
                  <Grid item xs={12}>
                    <ExternalDataSection data={props.externalData} />
                  </Grid>
                )}
              </>
            )}

            {children}
          </Grid>
        </Grid>

        <CardActions>
          {showAttachmentsBtn && (
            <Button variant="outlined" color="default" fullWidth={isSmall} onClick={openAttachsDialog}>
              EVIDENCIAS DE TICKET
            </Button>
          )}
          <GroupRestrictedView group={"service-users"}>
            {(isAllowed) => {
              if (showRateSupportBtn) {
                return (
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    fullWidth={isSmall}
                    onClick={() => setIsReviewDialogOpen(true)}
                  >
                    CALIFICAR SOPORTE
                  </Button>
                );
              }

              if (showValidateBtn) {
                return (
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={() => setShowSignatureDialog(true)}
                    className={classes.validationBtn}
                    fullWidth={isSmall}
                  >
                    Validación
                  </Button>
                );
              }
              return null;
            }}
          </GroupRestrictedView>
          {showScheduleSupportBtn && (
            <GroupRestrictedView group={ALLOWED_UPDATE_GROUPS}>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                onClick={() => setIsScheduleSupportDialogOpen(true)}
                fullWidth={isSmall}
              >
                PROGRAMAR SOPORTE
              </Button>
            </GroupRestrictedView>
          )}
        </CardActions>
      </Card>

      <AttachmentsDialog
        open={attachsDialogOpen}
        attachments={ticketDetails.attachments}
        withSections={false}
        fullWidth
        onClose={closeAttachsDialog}
      />
      {taskInfo?.status &&
      ![TicketStatus.SCHEDULED, TicketStatus.IN_PROGRESS].includes(taskInfo.status) &&
      isWeb &&
      !isMobile() &&
      getTimerLogs(taskInfo.embeddedEvents, taskInfo).length ? (
        <GroupRestrictedView group={[ROLES.SUPERVISORS]}>
          <TicketTimerLogView activity={getTimerLogs(taskInfo.embeddedEvents, taskInfo)} />
        </GroupRestrictedView>
      ) : (
        ""
      )}
    </>
  );
}

TicketDetailsCard.propTypes = {
  ticketDetails: PropTypes.object,
  taskInfo: PropTypes.object,
  type: PropTypes.string,
  isFetching: PropTypes.bool,
  to: PropTypes.string,
  isRequestError: PropTypes.bool,
  actions: PropTypes.array,
  setIsScheduleSupportDialogOpen: PropTypes.func,
  setIsReviewDialogOpen: PropTypes.func,
  setShowSignatureDialog: PropTypes.func,
  children: PropTypes.node,
  externalData: PropTypes.shape(),
};

TicketDetailsCard.defaultProps = {
  ticketDetails: null,
  taskInfo: null,
  type: "",
  isFetching: false,
  to: "",
  isRequestError: false,
  actions: [],
  setIsScheduleSupportDialogOpen: () => {},
  setIsReviewDialogOpen: () => {},
  setShowSignatureDialog: () => {},
};

export default TicketDetailsCard;

const useStyles = makeStyles((theme) => ({
  description: {
    whiteSpace: "pre-wrap",
  },
  detailsGrid: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  detailsContent: {
    padding: theme.spacing(2),
  },
  username: {
    "& > *": {
      marginRight: theme.spacing(2),
    },
  },
  roleControl: {
    minWidth: 200,
    marginTop: theme.spacing(2),
  },
  root: {
    [theme.breakpoints.down("sm")]: {
      "& .MuiTableHead-root": {
        display: "none",
      },
      "& .MuiToolbar-root": {
        flexDirection: "column",
        padding: theme.spacing(2),
      },
      "& div[class*='MTableToolbar-actions-']": {
        order: 2,
        textAlign: "right",
        width: "100%",
      },
      "& div[class*='MTableToolbar-title-']": {
        order: 1,
        textAlign: "left",
        width: "100%",
      },
      "& div[class*='MTableToolbar-searchField-']": {
        width: "100%",
        order: 0,
        marginBottom: theme.spacing(2),
      },
    },
  },
  codeReaderPanel: {
    backgroundColor: "white",
    overflow: "clip",
    display: "relative",
  },
  colorPrimary: {
    background: "#C99343",
  },
  validationBtn: {
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: 0,
      right: 0,
      width: "100%",
      "z-index": 99,
    },
  },
}));
