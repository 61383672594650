import React from "react";
import { Box, Chip } from "@material-ui/core";
import useStyles from "../MaintenanceStyles";
import PropTypes from "prop-types";

const StatusChip = ({ label, color }, props) => {
  const classes = useStyles();

  return (
    <div id="maintenance-status-chip">
      <Box>
        <Chip label={label} color={color} {...props} className={classes.statusChip} />
      </Box>
    </div>
  );
};

StatusChip.propTypes = {
  label: PropTypes.string,
  color: PropTypes.string,
};

export default StatusChip;
