/* eslint-disable react/display-name */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import { Logger } from "@aws-amplify/core";
import makeStyles from "@material-ui/styles/makeStyles";
import grey from "@material-ui/core/colors/grey";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import PostAddIcon from "@material-ui/icons/PostAdd";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import Typography from "@material-ui/core/Typography";
import MaterialTable from "components/custom/MaterialTable";
import Section from "components/Section";
import SpeedDial from "components/custom/SpeedDial";
import useHeaderTitle from "hooks/useHeaderTitle";
import useBooleanFlag from "hooks/useBooleanFlag";
import useLoadingStatus from "hooks/useLoadingStatus";
import CategoryDialog, { MODES } from "components/admin/CategoryDialog";
import ConfirmationDialog from "components/ConfirmationDialog/ConfirmationDialog";
import { checkNextItems, filterDeletedItems } from "util/lists";
import { MATERIAL_CATEGORY_DATA_LOAD } from "constant/route/admin";
import * as helpers from "./helpers/graphql";

const SECTION_TITLE = "Categorías de Materiales";

const logger = new Logger("MaterialCategoryTypesView");

const TABLE_COLUMNS = [
  { title: "Nombre", field: "name" },
  { title: "Abreviatura", field: "abbreviation" },
  {
    title: "Fecha de registro",
    field: "createdAt",
    render: (rowData) => (rowData.createdAt ? dayjs(rowData.createdAt).format("DD/MM/YYYY HH:mm") : "Sin información"),
  },
  {
    title: "Última actualización",
    field: "updatedAt",
    render: (rowData) => (rowData.updatedAt ? dayjs(rowData.updatedAt).format("DD/MM/YYYY HH:mm") : "Sin información"),
  },
];

const TABLE_OPTIONS = {
  exportButton: false,
  columnsButton: false,
  selection: true,
  showSelectAllCheckbox: false,
};

function MaterialCategoryTypesView() {
  useHeaderTitle(SECTION_TITLE);
  const history = useHistory();
  const userId = useSelector(({ session }) => session.userId);
  const [fetching, _loadCategories] = useLoadingStatus(loadCategories);
  const [removing, _removeCategories] = useLoadingStatus(helpers.removeCategories);
  const [catDialogOpen, openCatDialog, closeCatDialog] = useBooleanFlag();
  const [categories, setCategories] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [speedDialActions] = useState([
    {
      icon: <PostAddIcon />,
      name: "Crear",
      onClick: handleCreateBtnClick,
    },
    {
      icon: <FileCopyIcon />,
      name: "Carga Manual",
      onClick: handleFileDataLoadBtnClick,
    },
  ]);
  const [detailPanel] = useState([
    {
      tooltip: "Ver descripción",
      render: (rowData) => <DetailPanel {...rowData} />,
    },
  ]);
  const [tableActions] = useState([
    {
      icon: () => <EditIcon color="action" />,
      tooltip: "Editar",
      position: "row",
      onClick: handleUpdateBtnClick,
    },
    {
      icon: () => <DeleteIcon color="action" />,
      tooltip: "Eliminar",
      position: "toolbarOnSelect",
      onClick: () => setShowDeleteConfirmation(true),
    },
  ]);

  useEffect(() => {
    _loadCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function loadCategories(nextToken) {
    return helpers.fetchCategories(nextToken).then(({ data }) => {
      const filteredItems = filterDeletedItems(data.listMaterialCategorys.items);
      setCategories(filteredItems);
      setNextToken(data.listMaterialCategorys.nextToken);
    });
  }

  function loadMoreCategories(page) {
    const hasMoreItems = checkNextItems(page, categories.length);
    if (hasMoreItems && nextToken) {
      _loadCategories(nextToken);
    }
  }

  function handleCreateBtnClick() {
    setSelectedCategory(null);
    openCatDialog();
  }

  function handleFileDataLoadBtnClick() {
    history.push(MATERIAL_CATEGORY_DATA_LOAD);
  }

  function handleUpdateBtnClick(event, row) {
    setSelectedCategory(row);
    openCatDialog();
  }

  function handleCategoryDialogSuccess(data, mode) {
    if (mode === MODES.CREATE) {
      setCategories([data, ...categories]);
    } else {
      const _categories = categories.map((item) => {
        if (item.id === data.id) {
          return data;
        }
        return item;
      });
      setCategories(_categories);
    }
    closeCatDialog();
  }

  async function deleteMaterialCategories() {
    try {
      await _removeCategories(selectedCategories, userId);
      setShowDeleteConfirmation(false);
      setSelectedCategories([]);
      const newCategories = categories.filter(({ id }) => {
        const deletedCategory = selectedCategories.find((sc) => sc.id === id);
        return !deletedCategory;
      });
      setCategories(newCategories);
    } catch (error) {
      logger.error(error);
    }
  }

  return (
    <Section>
      <MaterialTable
        title={SECTION_TITLE}
        options={TABLE_OPTIONS}
        columns={TABLE_COLUMNS}
        data={categories}
        onChangePage={loadMoreCategories}
        onSelectionChange={setSelectedCategories}
        detailPanel={detailPanel}
        actions={tableActions}
        isLoading={fetching || removing}
      />
      <CategoryDialog
        open={catDialogOpen}
        onCancel={closeCatDialog}
        onConfirmSuccess={handleCategoryDialogSuccess}
        category={selectedCategory}
      />
      <SpeedDial actions={speedDialActions} backdrop tooltipOpen />

      <ConfirmationDialog
        open={showDeleteConfirmation}
        title="Confirmación"
        onCancel={() => setShowDeleteConfirmation(false)}
        onConfirm={deleteMaterialCategories}
        loading={removing}
      >
        Estás a punto de eliminar <strong>{`${selectedCategories.length} categorías.`}</strong>
        <br />
        <br />
        ¿Deseas continuar?
      </ConfirmationDialog>
    </Section>
  );
}

const useStyles = makeStyles((theme) => ({
  detailsSection: {
    padding: theme.spacing(3),
    backgroundColor: grey[100],
  },
}));

function DetailPanel(props) {
  const classes = useStyles();

  return (
    <div className={classes.detailsSection}>
      <Typography variant="subtitle2">Descripción</Typography>
      <Typography variant="body2">{props.description}</Typography>
    </div>
  );
}

DetailPanel.propTypes = {
  description: PropTypes.string,
};

DetailPanel.defaultProps = {
  description: "Sin información",
};

export default MaterialCategoryTypesView;
