import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import ScrollContext from "./ScrollContext";

const BOTTOM_ANCHOR_ID = "bottom-anchor";
const TOP_ANCHOR_ID = "top-anchor";

const DEFAULT_ARGS = { behavior: "smooth", block: "start", alignToTop: true };

function ScopeContextProvider({ children, ...props }) {
  const { pathname } = useLocation();

  useEffect(() => {
    toTop();
  }, [pathname]);

  function toTop(args = DEFAULT_ARGS) {
    const anchor = document.querySelector(`#${TOP_ANCHOR_ID}`);
    if (anchor) {
      anchor.scrollIntoView(args);
    }
  }
  function toBottom(args = DEFAULT_ARGS) {
    const anchor = document.querySelector(`#${BOTTOM_ANCHOR_ID}`);

    if (anchor) {
      anchor.scrollIntoView(args);
    }
  }

  return (
    <ScrollContext.Provider
      value={{
        toTop,
        toBottom,
      }}
    >
      {children}
      <div id={BOTTOM_ANCHOR_ID}></div>
    </ScrollContext.Provider>
  );
}

ScopeContextProvider.defaultProps = {
  scope: null,
  availableScopes: [],
  allScopes: [],
};

export default ScopeContextProvider;
