import React from "react";
import PropTypes from "prop-types";
import { Box, IconButton, makeStyles, CircularProgress, Typography } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import CustomStepper from "components/common/CustomStepper";
import useLongPress from "hooks/useLongPress";

const TaskStepper = ({
  isLoading,
  isStepCompleted,
  isFirstStep,
  title,
  currentStep,
  steps,
  onNextClick,
  onBackClick,
  onBackLongPressClick,
  isNextStepDisabled,
}) => {
  const classes = useStyles();
  const longPressEvent = useLongPress(onBackLongPressClick, onBackClick);

  function handleNextButtonIcon() {
    if (isLoading) {
      return <CircularProgress color="inherit" size="2rem" />;
    }

    if (isStepCompleted) {
      return <ArrowForwardIosIcon className={classes.iconSize} aria-label="Avanzar al siguiente paso" color="action" />;
    }

    return <CheckIcon className={classes.iconSize} aria-label="Confirmar paso actual" color="inherit" />;
  }

  function handleBackButtonIcon() {
    if (isFirstStep) {
      return <CloseIcon className={classes.iconSize} aria-label="Cerrar modal" color="inherit" />;
    }
    return <ArrowBackIosIcon className={classes.iconSize} aria-label="Regresar al paso anterior" color="inherit" />;
  }

  return (
    <Box className={classes.container}>
      {/* <Typography variant="h6" align="center">
        {title}
      </Typography> */}

      {/* -----backButton----- */}
      {/* <div className={classes.backBtnContainer}>
        <IconButton disabled={isLoading} {...longPressEvent}>
          {handleBackButtonIcon()}
        </IconButton>
      </div> */}

      {/* -----Stepper----- */}
      <CustomStepper activeStep={currentStep.step} steps={steps} />

      {/* -----NextButton----- */}
      {/* <div className={classes.nextBtnContainer}>
        <IconButton onClick={onNextClick} color="primary" name="dialog-primary-button" disabled={isNextStepDisabled}>
          {handleNextButtonIcon()}
        </IconButton>
      </div> */}
    </Box>
  );
};

export default TaskStepper;

TaskStepper.propTypes = {
  isLoading: PropTypes.bool,
  isStepCompleted: PropTypes.bool,
  isFirstStep: PropTypes.bool,
  title: PropTypes.string,
  currentStep: PropTypes.object,
  steps: PropTypes.array,
  onNextClick: PropTypes.func,
  onBackClick: PropTypes.func,
  onBackLongPressClick: PropTypes.func,
  isNextStepDisabled: PropTypes.bool,
};

TaskStepper.defaultProps = {
  isLoading: false,
  isStepCompleted: false,
  isFirstStep: false,
  title: "",
  currentStep: {},
  steps: [],
  onNextClick: () => { },
  onBackClick: () => { },
  onBackLongPressClick: () => { },
  isNextStepDisabled: false,
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    marginTop: "15px"
  },
  backBtnContainer: {
    position: "absolute",
    left: "8px",
    [theme.breakpoints.down(360)]: {
      left: "0px",
    },
  },
  nextBtnContainer: {
    position: "absolute",
    right: "8px",
    [theme.breakpoints.down(360)]: {
      right: "0px",
    },
  },
}));
