/**
 * Query GraphQL para obtener el detalle de un Task.
 *
 * @param {string} id ID del Task que se consulta.
 */
export const getAsset = /* GraphQL */ `
  query GetAsset($id: ID!) {
    getAsset(id: $id) {
      id
      code
      serial
      quantity
      type
      enabled
      comment
      createdAt
      nomenclature
      part
      model
      component
      material {
        name
        code
        description
        manufacturer
        category {
          name
          description
        }
        uom {
          name
          description
        }
      }
      tasks {
        items {
          task {
            category
            status
            startDate
            endDate
            startTime
            endTime
            startedAt
            startedBy
            startLocation {
              longitude
              latitude
            }
            endedAt
            endedBy
            endLocation {
              longitude
              latitude
            }
            buildingLocation {
              name
              code
              premises {
                name
                company {
                  name
                }
              }
            }
            taskType {
              name
              description
            }
            attachments {
              items {
                status
                file {
                  bucket
                  region
                  key
                }
              }
              nextToken
            }
            externalTicketId
            priority
            level
            rating
            targetResolutionTime
            deliveryInstallment
            remarks {
              items {
                taskStatus
                remark
              }
              nextToken
            }
          }
        }
        nextToken
      }
      attributes {
        items {
          attribute {
            name
            description
            id
          }
          id
          value
          _deleted
        }
      }
      tracking {
        id
        status
        _lastChangedAt
      }
      project {
        id
        name
        code
        company {
          id
          name
        }
      }
    }
  }
`;

export default getAsset;
