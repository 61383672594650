import { SIGNUP_ATTRIBUTES, EMAIL_VALIDATION_REGEXP, PHONE_VALIDATION_REGEXP } from "./constants";
import { Logger } from "aws-amplify";
const logger = new Logger("LoginView-helpers");
const ALL_ATTRIBUTES = [...SIGNUP_ATTRIBUTES, "password", "confirm_password"];

export const amplifyServices = {
  async validateCustomSignUp(formData, onTouchData) {
    // logger.info("validateCustomSignUp: formData", formData);
    let validateErrors = {};

    //early exit
    if (!Object.keys(formData).length) {
      return validateErrors;
    }

    const hasAllFieldsEmpty = ALL_ATTRIBUTES.every((attribute) => !Boolean(formData[attribute]));
    const hasEmptyFields = ALL_ATTRIBUTES.some((attribute) => !Boolean(formData[attribute]));
    const hasEmailError = !EMAIL_VALIDATION_REGEXP.test(formData.email || "");
    const hasPhoneError =
      !PHONE_VALIDATION_REGEXP.test(formData.phone_number || "") || formData.phone_number?.length !== 10;

    if (hasAllFieldsEmpty) validateErrors.hasAllFieldsEmpty = hasAllFieldsEmpty;
    if (hasEmptyFields) validateErrors.hasEmptyFields = hasEmptyFields;
    if (hasEmailError && formData.hasOwnProperty("email") && formData.email)
      validateErrors.email = "Formato de correo invalido.";
    if (hasPhoneError && formData.hasOwnProperty("phone_number") && formData.phone_number)
      validateErrors.phone_number = "Formato de teléfono invalido.";
    logger.info("validateCustomSignUp: validateErrors", validateErrors);
    return validateErrors;
  },
};

export function addTabIndex(tabIndexElements) {
  return tabIndexElements.forEach(({ element, tabIndex }) => {
    const _element = element();
    // logger.log("addTabIndex: element | index", { _element, tabIndex });
    _element.setAttribute("tabindex", tabIndex);
  });
}

export function getValidationErros(validationErrors) {
  const validationErrosResult = {
    hasAllFieldsEmpty: true,
    hasEmptyFields: true,
  };

  if (!Object.keys(validationErrors).length) {
    validationErrosResult.hasAllFieldsEmpty = false;
    validationErrosResult.hasEmptyFields = false;
    return validationErrosResult;
  }

  if (validationErrors.hasAllFieldsEmpty === undefined) {
    validationErrosResult.hasAllFieldsEmpty = true;
  }
  if (validationErrors.hasEmptyFields === undefined) {
    validationErrosResult.hasEmptyFields = true;
  }

  return validationErrosResult;
}
