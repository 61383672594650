import React from "react";
import PropTypes from "prop-types";
import { Logger } from "@aws-amplify/core";
import Button from "components/custom/Button";
import { ATTACHMENT_TYPES, ATTACHMENT_ID_NAMES } from "constant/attachmentDownloadTypes";
import useGraphQL from "hooks/useGraphQL";
import * as queries from "graphql-custom/queries";
import useNotifier from "hooks/useNotifier";
import useLoadingStatus from "hooks/useLoadingStatus";
import useOnlineStatus from "@rehooks/online-status";
import downloadFile from "util/downloadFile";
import NetworkTooltip from "components/custom/NetworkTooltip";

const logger = new Logger("AttachmentDownloadButton");

export default function AttachmentDownloadButton({
  content,
  attachmentType,
  id,
  beforeDownload,
  afterDownload,
  disabled,
  onlineTitle,
  offlineTitle,
  ...props
}) {
  const { runGraphQLOperation } = useGraphQL();
  const { showError } = useNotifier();
  const [downloading, _downloadAttachments] = useLoadingStatus(downloadAttachments);
  const isOnline = useOnlineStatus();
  const isOffline = !isOnline;

  async function downloadAttachments() {
    const idName = ATTACHMENT_ID_NAMES[attachmentType];
    const input = { attachmentType, [idName]: id };
    try {
      if (!isOnline) return null;
      await beforeDownload(input);
      const { lambdaDownloadAttachmentFiles: response } = await runGraphQLOperation({
        operation: queries.lambdaDownloadAttachmentFiles,
        variables: { input },
      });
      if (response.statusCode !== 200) {
        throw new Error(`ERROR ${response.statusCode}: ${response.errorMessage}`);
      }
      await downloadFile(response.fileUrl);
      await afterDownload(response);
    } catch (error) {
      logger.error(error);
      showError("Ocurrió un error durante la descarga de evidencias");
    }
  }

  return (
    <NetworkTooltip onlineTitle={onlineTitle} offlineTitle={offlineTitle}>
      <Button
        color="primary"
        variant="contained"
        size="medium"
        onClick={_downloadAttachments}
        disabled={isOffline || downloading || disabled}
        isLoading={downloading}
        {...props}
      >
        {content}
      </Button>
    </NetworkTooltip>
  );
}

AttachmentDownloadButton.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  attachmentType: PropTypes.oneOf(Object.keys(ATTACHMENT_TYPES)).isRequired,
  id: PropTypes.string.isRequired,
  beforeDownload: PropTypes.func,
  afterDownload: PropTypes.func,
  disabled: PropTypes.bool,
  onlineTitle: PropTypes.string,
  offlineTitle: PropTypes.string,
};

AttachmentDownloadButton.defaultProps = {
  content: "Descargar evidencias",
  attachmentType: "",
  id: "",
  beforeDownload: async () => {},
  afterDownload: async () => {},
  disabled: false,
  onlineTitle: "Descarga de evidencias.",
  offlineTitle: "La descarga de evidencias requiere conexión a internet.",
};
