import { useContext } from "react";
import { useSelector } from "react-redux";
import Auth from "@aws-amplify/auth";
import ApplicationContext from "context/ApplicationContext";

function useSession() {
  const session = useSelector((store) => store.session);
  const { setSession, clearSession } = useContext(ApplicationContext.Context);

  function login(username, password) {
    return Auth.signIn(username, password).then((cognitoUser) => {
      if (cognitoUser.challengeName === "NEW_PASSWORD_REQUIRED") return cognitoUser;

      setSession({
        username: cognitoUser.attributes.email,
        userId: cognitoUser.username,
      });
      return cognitoUser;
    });
  }

  function logout() {
    return Auth.signOut().then(() => {
      clearSession();
      return;
    });
  }

  return {
    login,
    logout,
    data: session,
  };
}

export default useSession;
