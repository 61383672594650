import { AssetType } from "models";

export function generateAssetsInstallationMutation(assets = [], type = AssetType.ALLOCATED) {
  const variables = {};
  let mutation = "";
  let mutationsParams = [];
  if (assets.length > 0) {
    assets.forEach((asset, index) => {
      mutationsParams.push(`$assetKey${index}: ID!, $assetUomId${index}: ID!, `);
      mutation += `
        createAssetSchedule${index}: createAsset(input:{
          serial: "DEFAULT_SERIE"
          code: ${asset.code ? JSON.stringify(asset.code) : "DEFAULT_CODE"}
          quantity: ${asset.quantity}
          type: ${type}
          trackingID: "unassigned"
          locationCode: ""
          assetMaterialId: $assetKey${index}
          assetUomId: $assetUomId${index}
        }){
          id
          serial
          code
          quantity
          locationCode
          trackingID
          type
          material {
            id
            code
            name
            _version
            _lastChangedAt
            _deleted
          }
          uom {
            id
            name
            description
          }
          _version
          _lastChangedAt
          _deleted
        }
      `;
      variables[`assetKey${index}`] = asset.materialId;
      variables[`assetUomId${index}`] = asset.uomId || "";
    });

    mutationsParams = mutationsParams.join(",");
    mutation = `
      mutation createAssets(${mutationsParams}) {
        ${mutation}
      }
    `;
  }
  return {
    mutation,
    variables,
  };
}

/**
 *
 *
 * Ej.
 * array = [{ id: "123", name: "John", age: 23}, { id: "456", name: "Diane", age: 27}]
 * propsArray = [{ user_id: "id" }, { user_name: "name" }]
 *
 * result = [{ input: { id: "123", name: "John" } }, { input: { id: "456", name: "Diane" } }]
 *
 * @param {Array<Object>} array Arreglo de objetos del que se generará el arreglo mapeado
 * @param {Array<Object>} propsArray Arreglo de mapas de propiedades
 * @returns Alguno de los siguientes valores de JOB_STATE
 */
export const generateInputs = (array, propsArray) => {
  const result = array.map((element) => {
    return {
      input: element,
    };
  });
  return result;
};
