import React from "react";
import PropTypes from "prop-types";
import { useAuthenticator, View, Image, Text, useTheme, Flex, Button as AmplifyButton } from "@aws-amplify/ui-react";
import LegalBoxModal from "components/LegalBox";

import registerStyles from "./styles/register.module.css";
import signInStyles from "./styles/signIn.module.css";

import { AUTH_COMPONENT_CONTENT } from "./constants";

export function AuthenticatorHeader() {
  const { tokens } = useTheme();
  const { route } = useAuthenticator((context) => [context.route]);
  const content = AUTH_COMPONENT_CONTENT[route];

  if (!content) {
    return null;
  }

  return (
    <View textAlign="center" padding={tokens.space.large}>
      <Image
        alt="Logo de indika360"
        title="Logo de indika360"
        src="/img/indika-color-logo.png"
        maxWidth="150px"
        width="55%"
        id="sesion-indika-logo"
      />
      <Text
        fontSize={tokens.fontSizes.large}
        fontWeight="500"
        color={tokens.colors.brand.primary["80"]}
        margin="8px"
        id="sesion-route-text"
        title={`Título de la sección de ${content.headerText}`}
      >
        {content.headerText}
      </Text>
    </View>
  );
}

export function TextActionLink({ text, children, action, flexProps = {}, ...props }) {
  const flexClassName = flexProps.className
    ? `${registerStyles["checkbox-label"]} ${flexProps.className}`
    : registerStyles["checkbox-label"];

  const buttonClassName = props.className
    ? `${registerStyles["terms-and-policy-link"]} ${props.className}`
    : registerStyles["terms-and-policy-link"];

  return (
    <Flex {...flexProps} className={flexClassName}>
      {text && <span>{text}</span>}
      <AmplifyButton variation="link" onClick={action} {...props} className={buttonClassName}>
        {children}
      </AmplifyButton>
    </Flex>
  );
}

TextActionLink.propTypes = {
  text: PropTypes.string,
  children: PropTypes.node,
  action: PropTypes.func,
  flexProps: PropTypes.object,
  className: PropTypes.string,
};

export function FormSubmitButton({ children, ...props }) {
  return (
    <View className="FormSubmitButton-container">
      <AmplifyButton variation="primary" isFullWidth={true} type="submit" {...props}>
        {children}
      </AmplifyButton>
    </View>
  );
}

FormSubmitButton.propTypes = {
  children: PropTypes.node,
  action: PropTypes.func,
  className: PropTypes.string,
};

export function RegisterPrivacyStripe({ action }) {
  return (
    <View className={registerStyles["privacy-stripe"]}>
      <span>
        Este sitio esta protegido y se aplica la{" "}
        <AmplifyButton
          variantion="link"
          className={registerStyles["terms-and-policy-link"]}
          onClick={action}
          title="Mostrar avisos de privacidad de Indika360"
        >
          política de privacidad.
        </AmplifyButton>
      </span>
    </View>
  );
}

RegisterPrivacyStripe.propTypes = {
  action: PropTypes.func,
};

export function FormFooter({ authProps, buttonProps, textActionLinkProps }) {
  const { route } = authProps;
  const content = AUTH_COMPONENT_CONTENT[route];

  return (
    <View className={registerStyles["registerForm-footer"]}>
      <Flex className="footer-container">
        <TextActionLink flexProps={{ direction: "column" }} {...textActionLinkProps} />
        {content && (
          <FormSubmitButton id="session-submit-btn" type="submit" {...buttonProps}>
            {content.mainActionText}
          </FormSubmitButton>
        )}
      </Flex>
    </View>
  );
}

FormFooter.propTypes = {
  authProps: PropTypes.object,
  buttonProps: PropTypes.object,
  textActionLinkProps: PropTypes.object,
};

const legalBoxDataDefaultState = {
  showModal: false,
  tabToShow: 0,
};
export function SignInAuthenticatorFooter() {
  const [legalBoxModalData, setLegalBoxModalData] = React.useState(legalBoxDataDefaultState);
  const openLegalBoxModal = (tabToShow) => setLegalBoxModalData({ showModal: true, tabToShow });
  const onCloseLegalBoxModal = () => setLegalBoxModalData(legalBoxDataDefaultState);

  return (
    <View padding="1.75rem" backgroundColor="#f7f9ff" borderRadius="0 0 8px 8px">
      <Flex justifyContent="flex-end">
        <TextActionLink
          className={signInStyles["terms-and-policy-link-small"]}
          action={() => openLegalBoxModal(0)}
          id="privacy-footer-btn"
          title="Mostrar avisos de privacidad de Indika360"
        >
          Privacidad
        </TextActionLink>
        <TextActionLink
          className={signInStyles["terms-and-policy-link-small"]}
          action={() => openLegalBoxModal(1)}
          id="terms-footer-btn"
          title="Mostrar términos y condiciones Indika360"
        >
          Términos
        </TextActionLink>
      </Flex>
      <LegalBoxModal {...legalBoxModalData} setShowModalTerms={onCloseLegalBoxModal} showFooter={false} />
    </View>
  );
}

export function ErrorAlert({ children }) {
  return (
    <div className={registerStyles.fieldErrorAlert}>
      <p role="alert">{children}</p>
    </div>
  );
}

ErrorAlert.propTypes = {
  children: PropTypes.node,
};
