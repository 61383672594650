// Modal para programar nuevas instalaciones
import React, { useState, useEffect, useMemo, useReducer } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { TaskCategory } from "models";
import API, { graphqlOperation } from "@aws-amplify/api";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import Input from "@material-ui/core/Input";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import MaterialUiSelect from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import { DatePicker, TimePicker } from "@material-ui/pickers";
import ListSubheader from "@material-ui/core/ListSubheader";
import Typography from "@material-ui/core/Typography";

import MaterialTable from "components/custom/MaterialTable";
import ConfirmationDialog from "components/ConfirmationDialog";
import Select from "components/custom/Select";
import Button from "components/custom/Button";
import MaterialKitDialog from "components/MaterialKitDialog";
import CustomerProjectSelector from "components/CustomerProjectSelector";
import { Controller, useForm } from "react-hook-form";
import useNotifier from "hooks/useNotifier";
import useLoadingStatus from "hooks/useLoadingStatus";
import { filterDeletedItems } from "util/lists";
import * as queries from "graphql-custom/queries";
import * as ActionTypes from "redux/action";
import TypesSelector from "components/FormControls/TypesSelector";
import { FORM_MODES } from "constant/formModes";
import installationMaterialsReducer from "redux/reducer/hook/installationMaterialsReducer";
import * as graphqlHelpers from "./helpers/graphql";

const DIALOG_TEXT = {
  [FORM_MODES.CREATE]: {
    title: "Nueva Instalación",
    confirmBtn: "Guardar",
    successMsg: "Instalación Registrada con éxito",
    errorMsg: "Ocurrió un error durante la creación de instalación, intente de nuevo por favor",
  },
  [FORM_MODES.UPDATE]: {
    title: "Actualizar Instalación",
    confirmBtn: "Actualizar",
    successMsg: "Actualización exitosa",
    errorMsg: "Ocurrió un error durante la actualización de instalación, intente de nuevo por favor",
  },
};

const INITIAL_COLUMNS = [
  { title: "Código", field: "code", editable: "never" },
  { title: "Nombre", field: "materialId", editable: "onAdd" },
  { title: "Unidad", field: "uomId" },
  { title: "Cantidad", field: "quantity", initialEditValue: 1 },
];

const DEFAULT_FORM = {
  responsible: " ",
  phone: " ",
  email: " ",
  address: " ",
  startDate: new Date(),
  finishDate: new Date(),
  startTime: new Date(),
  finishTime: new Date(),
  quantity: 1,
};

function formatAddress(address) {
  return `${address?.street || ""} #${address?.number || ""} ${address?.city || ""} C.P. ${address?.zipcode || ""} `;
}
function formatCustodian(custodian) {
  return `${custodian?.name || ""} ${custodian?.middlename || ""} ${custodian?.lastnameP || ""} ${
    custodian?.lastnameM || ""
  } `;
}

function formatBuildingLocationInfo(buildingInfo) {
  let { address = null, custodian = null } = buildingInfo;

  address = address ? formatAddress(address) : "Sin información";
  let email = "Sin información";
  let phone = "Sin información";

  if (custodian) {
    email = custodian.email;
    phone = custodian.phone;
    custodian = custodian ? formatCustodian(custodian) : "Sin información";
  } else {
    custodian = "Sin información";
  }

  return { address, email, phone, custodian };
}

function ScheduleNewInstallationDialog(props) {
  // Info para el formulario de programación
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [buildingLocationsList, setBuildingLocationsList] = useState([]);
  const [premisesList, setPremisesList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [materialList, setMaterialList] = useState([]);
  const [kitList, setKitList] = useState([]);
  const [uomList, setUomList] = useState([]);
  const userId = useSelector((store) => store.session.userId);
  const [confirmApplyKitDialogOpen, setConfirmApplyKitDialogOpen] = useState(false);
  const [MODE] = useState(props.initialData ? FORM_MODES.UPDATE : FORM_MODES.CREATE);
  const [tableColumns, setTableColumns] = useState(INITIAL_COLUMNS);
  const [activeKit, setActiveKit] = useState(null);
  const [selectedProjectId, setSelectedProjectId] = useState("");
  const [dialogKitMode, setDialogKitMode] = useState(FORM_MODES.CREATE);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // Estado del formulario de programación
  const { control, reset, register, setValue, getValues, watch, errors } = useForm({
    mode: "onChange",
    defaultValues: DEFAULT_FORM,
  });
  const [selectedBuildingLocation, setSelectedBuildingLocation] = useState("");
  const [seletectedPremise, setSelectedPremise] = useState("");
  const [selectedKit, setSelectedKit] = useState("");
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const { showError, showMessage } = useNotifier();
  const [installationMaterials, dispatch] = useReducer(installationMaterialsReducer, {
    all: [],
    added: [],
    updated: [],
    deleted: [],
  });

  const filteredMaterials = useMemo(() => {
    const installedMaterialIds = installationMaterials.all.map(({ materialId }) => materialId);
    const filteredMaterials = materialList.reduce((filtered, { id, name }) => {
      if (!installedMaterialIds.includes(id)) {
        filtered.push({ value: id, label: name });
      }
      return filtered;
    }, []);
    return filteredMaterials;
  }, [installationMaterials.all, materialList]);

  const [isScheduling, _scheduleInstallation] = useLoadingStatus(graphqlHelpers.scheduleInstallation);
  const [isUpdating, _updateInstallation] = useLoadingStatus(updateInstallation);
  const [isApplyingKit, _applyKit] = useLoadingStatus(applyKit);
  const [isLoadingCatalogs, _initializeSelects] = useLoadingStatus(initializeSelects);

  // Inicialización de información de la UI
  useEffect(() => {
    _initializeSelects().then(() => {
      if (props.initialData) {
        const {
          buildingLocation: { premisesID, id: buildingID },
          taskType: { id: taskTypeID },
          project,
          startDate,
          endDate,
          startTime,
          endTime,
          users: { items: users },
          assets: { items: assets },
        } = props.initialData;
        dispatch({
          type: ActionTypes.SET_ALL_INSTALLATION_MATERIAL,
          payload: filterDeletedItems(assets),
        });
        setSelectedPremise(premisesID);
        setSelectedBuildingLocation(buildingID);
        setSelectedUserIds(users.filter((u) => !u._deleted).map(({ userID }) => userID));
        setSelectedProjectId(project?.id || "");
        setValue("startDate", startDate, { shouldDirty: true });
        setValue("finishDate", endDate, { shouldDirty: true });
        setValue("installationType", taskTypeID, { shouldDirty: true });
        setValue("startTime", `${startDate} ${startTime}`, {
          shouldDirty: true,
        });
        setValue("finishTime", `${endDate} ${endTime}`, { shouldDirty: true });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (materialList) {
      const nameColIndex = tableColumns.findIndex((column) => column.title === "Nombre");
      const quantityColIndex = tableColumns.findIndex((column) => column.title === "Unidades");
      const uomColIndex = tableColumns.findIndex((column) => column.title === "Unidad");
      const columsCopy = [...tableColumns];
      const materialMapObject = {};
      materialList.map((material) => {
        return (materialMapObject[material.id] = material.name);
      });
      const uomMapObject = uomList.reduce((lookup, { value, label }) => {
        lookup[value] = label;
        return lookup;
      }, {});
      columsCopy[nameColIndex] = {
        ...tableColumns[nameColIndex],
        lookup: materialMapObject,
        editComponent: renderMaterialNameSelect,
      };
      columsCopy[quantityColIndex] = {
        ...tableColumns[quantityColIndex],
        editComponent: renderMaterialQuantityField,
      };
      columsCopy[uomColIndex] = {
        ...tableColumns[uomColIndex],
        lookup: uomMapObject,
        editComponent: renderMaterialUOMField,
      };
      setTableColumns(columsCopy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [materialList, filteredMaterials, uomList]);

  useEffect(() => {
    if (seletectedPremise !== "") {
      const selectedPremiseInfo = premisesList.filter((premise) => premise.id === seletectedPremise);
      const _buildingLocationList = selectedPremiseInfo[0]?.buildingLocations?.items || [];

      const { address, phone, email, custodian } = formatBuildingLocationInfo(selectedPremiseInfo[0]);

      setValue("address", address);
      setValue("email", email);
      setValue("phone", phone);
      setValue("responsible", custodian);

      setBuildingLocationsList(_buildingLocationList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seletectedPremise]);

  async function initializeSelects() {
    const [_typesList, _premisessList, _userList, _materials, _kits, _uoms] = await graphqlHelpers.fetchCatalogsData(
      userId
    );
    setPremisesList(_premisessList);
    setUserList(_userList);
    setMaterialList(_materials);
    setKitList(_kits);
    setUomList(_uoms);
  }

  function renderMaterialNameSelect(selectProps) {
    return (
      <Select
        name="name"
        fullWidth
        required
        value={selectProps.value || ""}
        onChange={(e) => selectProps.onChange(e.target.value)}
        options={filteredMaterials}
      />
    );
  }

  function renderMaterialQuantityField(fieldProps) {
    let value = fieldProps.value || 1;
    let error;
    if (value <= 0) {
      error = "El valor debe ser positivo";
    } else if (value > 1000) {
      error = "El valor deber ser menor a 1000";
    }
    return (
      <TextField
        name="quantity"
        type="number"
        fullWidth
        value={value}
        onChange={(e) => fieldProps.onChange(e.target.value)}
        error={!!error}
        helperText={error}
      />
    );
  }

  function renderMaterialUOMField(fieldProps) {
    const { value, onChange } = fieldProps;
    return (
      <Select name="quantity" value={value} onChange={(e) => onChange(e.target.value)} options={uomList} fullWidth />
    );
  }

  function handleCancel() {
    props.onCancel();
  }

  async function handleSubmit() {
    const values = getValues();
    if (seletectedPremise === "") {
      showMessage("Debes seleccionar un lugar");
    } else if (selectedUserIds.length === 0) {
      showMessage("Debes seleccionar al menos un instalador");
    } else if (selectedBuildingLocation === "") {
      showMessage("Debes seleccionar una zona");
    } else if (values.installationType === undefined) {
      showMessage("Debes seleccionar un tipo de instalación");
    } else if (validateTime()) {
      showMessage("La hora de finalización debe ser mayor que la hora de inicio");
    } else {
      if (MODE === FORM_MODES.CREATE) {
        try {
          await _scheduleInstallation({
            ...values,
            selectedBuildingLocation,
            selectedUserIds,
            selectedProjectId,
            selectedMaterials: installationMaterials.all,
          });
          showMessage(DIALOG_TEXT[MODE].successMsg);
          handleOnCancelDialog();
        } catch (error) {
          console.log(error);
          showError(DIALOG_TEXT[MODE].errorMsg);
        }
      } else if (MODE === FORM_MODES.UPDATE) {
        try {
          await _updateInstallation();
          showMessage(DIALOG_TEXT[MODE].successMsg);
          handleOnCancelDialog();
        } catch (error) {
          console.error(error);
          showError(DIALOG_TEXT[MODE].errorMsg);
        }
      }
    }
  }

  async function updateInstallation() {
    const values = getValues();
    await graphqlHelpers.updateInstallationDetails(
      {
        id: props.initialData.id,
        version: props.initialData._version,
        selectedUserIds,
        selectedBuildingLocation,
        ...values,
      },
      props.initialData.users
    );
    let materials = await graphqlHelpers.createNewAssets(installationMaterials.all, installationMaterials.added);
    materials = materials ? { ...installationMaterials, all: materials } : installationMaterials;
    await graphqlHelpers.updateInstallationMaterials(props.initialData.id, materials);
  }

  function handleOnCancelDialog() {
    reset();
    setSelectedBuildingLocation("");
    setSelectedPremise("");
    props.onSuccess();
  }

  function handleKitSelectOnChange(event) {
    const selection = event.target.value;
    setSelectedKit(selection);
    if (installationMaterials.all.length) {
      setConfirmApplyKitDialogOpen(true);
    } else {
      _applyKit(selection);
    }
  }

  function confirmKitChange() {
    _applyKit(selectedKit);
  }

  async function applyKit(selection = "") {
    try {
      const kitDetails = await API.graphql(
        graphqlOperation(queries.getKit, {
          kitID: selection !== "" ? selection : selectedKit,
        })
      );
      dispatch({
        type: ActionTypes.REPLACE_ALL_INSTALLATION_MATERIAL,
        payload: kitDetails?.data?.getKit?.materials?.items,
      });
      setActiveKit({ ...kitDetails?.data?.getKit });
      showMessage("Kit agregado");
      setConfirmApplyKitDialogOpen(false);
    } catch (error) {
      console.log(error);
      showError("Ocurrio un error seleccionando el kit, intente de nuevo, por favor.");
    }
  }

  function handleCancelApplyKit() {
    setSelectedKit("");
    setConfirmApplyKitDialogOpen(false);
  }

  function validateTime() {
    const currentStartDate = dayjs(watch("startDate"));
    const currentFinishDate = dayjs(watch("finishDate"));
    const currentStartTime = dayjs(watch("startTime"));
    const currentFinishTime = dayjs(watch("finishTime"));

    return (
      currentStartDate &&
      currentFinishDate &&
      currentStartDate.isSame(currentFinishDate, "date") &&
      currentStartTime.isAfter(currentFinishTime, "seconds")
    );
  }

  function handleCreateDialogSuccess(newKit) {
    // Agregado optimista del nuevo kit en la lista de kits
    setKitList([...kitList, newKit]);
    setCreateDialogOpen(false);
  }

  function handleUpdateKitSucces({ updateKit }) {
    _applyKit(updateKit.id);
    const updatedList = [...kitList];
    const kitIndex = kitList.findIndex(({ id }) => id === updateKit.id);
    updatedList[kitIndex] = updateKit;
    setKitList(updatedList);
  }

  function handleCreateDialogCancel(event) {
    setCreateDialogOpen(false);
  }

  function handleCreateKitBtnOpen(event) {
    setDialogKitMode(FORM_MODES.CREATE);
    setCreateDialogOpen(true);
  }

  function handleOpenUpdateKit() {
    setDialogKitMode(FORM_MODES.UPDATE);
    setCreateDialogOpen(true);
  }

  async function addMaterial(newData) {
    const materialData = materialList.find((material) => material.id === newData.materialId);
    newData.code = materialData.code;
    dispatch({ type: ActionTypes.ADD_INSTALLATION_MATERIAL, payload: newData });
  }

  async function updateMaterial(newData) {
    dispatch({
      type: ActionTypes.UPDATE_INSTALLATION_MATERIAL,
      payload: newData,
    });
  }

  async function deleteMaterial(oldData) {
    dispatch({
      type: ActionTypes.DELETE_INSTALLATION_MATERIAL,
      payload: oldData,
    });
  }

  const currentStartDate = watch("startDate");
  const currentFinishDate = watch("finishDate");
  const currentStartTime = watch("startTime");
  const currentFinishTime = watch("finishTime");

  const invalidTime = useMemo(validateTime, [currentStartDate, currentFinishDate, currentStartTime, currentFinishTime]);

  const isLoading = isLoadingCatalogs || isScheduling || isUpdating || isApplyingKit;

  const handleChange = (event) => {
    setSelectedUserIds(event.target.value);
  };

  return (
    <>
      <Dialog onClose={handleOnCancelDialog} fullScreen={fullScreen} open maxWidth="xl" fullWidth>
        <DialogTitle>{DIALOG_TEXT[MODE].title}</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Ingrese los datos de la actividad a realizar</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
              <Controller
                as={<DatePicker />}
                control={control}
                label="Fecha de inicio"
                size="small"
                name="startDate"
                format="DD/MM/YYYY"
                disablePast={MODE === FORM_MODES.CREATE}
                defaultValue={null}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
              <Controller
                as={<TimePicker />}
                control={control}
                label="Hora de inicio"
                size="small"
                name="startTime"
                format="HH:mm"
                defaultValue={null}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
              <Controller
                as={<DatePicker />}
                control={control}
                label="Fecha de finalización"
                size="small"
                minDate={watch("startDate")}
                name="finishDate"
                format="DD/MM/YYYY"
                defaultValue={null}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
              <Controller
                as={<TimePicker />}
                control={control}
                label="Hora de finalización"
                size="small"
                name="finishTime"
                format="HH:mm"
                error={invalidTime}
                helperText={invalidTime ? "La hora fin debe ser mayor que la hora de inicio" : ""}
                defaultValue={null}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <InputLabel id="demo-mutiple-checkbox-label">Instalador</InputLabel>
              <MaterialUiSelect
                name="technician"
                multiple
                fullWidth
                value={selectedUserIds}
                onChange={(evt) => handleChange(evt)}
                required
                renderValue={(selected) => {
                  return selected
                    .map((value) => {
                      if (MODE === FORM_MODES.CREATE) {
                        const user = userList.find((user) => user.id === value);
                        return `${user?.name || ""} ${user?.middlename || ""} ${user?.lastnameP || ""} ${
                          user?.lastnameM || ""
                        }`;
                      } else {
                        let user = props.initialData.users.items.find(({ user }) => user.id === value);
                        if (!user) {
                          user = userList.find((user) => user.id === value);
                        } else {
                          user = user.user;
                        }
                        return `${user?.name || ""} ${user?.middlename || ""} ${user?.lastnameP || ""} ${
                          user?.lastnameM || ""
                        }`;
                      }
                    })
                    .join(", ");
                }}
                input={<Input />}
              >
                <ListSubheader style={{ pointerEvents: "none" }}>Mi equipo</ListSubheader>
                {MODE === FORM_MODES.CREATE &&
                  userList.map((user) => (
                    <MenuItem key={user.id} value={user.id}>
                      <Checkbox checked={selectedUserIds.indexOf(user.id) > -1} />
                      <ListItemText
                        primary={`${user?.name || ""} ${user?.middlename || ""} ${user?.lastnameP || ""} ${
                          user?.lastnameM || ""
                        }`}
                      />
                    </MenuItem>
                  ))}
                {MODE === FORM_MODES.UPDATE &&
                  userList.map((user) => (
                    <MenuItem key={user.id} value={user.id}>
                      <Checkbox checked={selectedUserIds.indexOf(user.id) > -1} />
                      <ListItemText
                        primary={`${user?.name || ""} ${user?.middlename || ""} ${user?.lastnameP || ""} ${
                          user?.lastnameM || ""
                        }`}
                      />
                    </MenuItem>
                  ))}
                {MODE === FORM_MODES.UPDATE &&
                  props.initialData.users.items
                    .filter((u) => !u._deleted)
                    .filter(({ user }) => userList.find((ul) => ul.id !== user.id)).length > 0 && (
                    <ListSubheader style={{ pointerEvents: "none" }}>De otro equipo</ListSubheader>
                  )}
                {MODE === FORM_MODES.UPDATE &&
                  props.initialData.users.items
                    .filter((u) => !u._deleted)
                    .filter(({ user }) => userList.find((ul) => ul.id !== user.id) === undefined)
                    .map(({ user }) => (
                      <MenuItem key={user.id} value={user.id}>
                        <Checkbox disabled disableRipple checked={selectedUserIds.indexOf(user.id) > -1} />
                        <ListItemText
                          disableTypography
                          primary={`${user?.name || ""} ${user?.middlename || ""} ${user?.lastnameP || ""} ${
                            user?.lastnameM || ""
                          }`}
                        />
                      </MenuItem>
                    ))}
              </MaterialUiSelect>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Select
                label="Lugar"
                name="zone"
                fullWidth
                value={seletectedPremise}
                onChange={(evt) => setSelectedPremise(evt.target.value)}
                required
                options={premisesList.map((premise) => ({
                  value: premise.id,
                  label: premise.name,
                }))}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={8}>
              <TextField
                label="Dirección"
                name="address"
                inputRef={register}
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Select
                label="Zona"
                name="buildingLocation"
                fullWidth
                value={selectedBuildingLocation}
                onChange={(evt) => setSelectedBuildingLocation(evt.target.value)}
                required
                options={buildingLocationsList.map((location) => ({
                  value: location.id,
                  label: location.name,
                }))}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                label="Responsable"
                name="responsible"
                inputRef={register}
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <TextField
                label="Teléfono"
                name="phone"
                inputRef={register}
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <TextField
                label="Correo Electrónico"
                name="email"
                inputRef={register}
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid>
          <CustomerProjectSelector
            customerId={props.initialData?.project?.company?.id}
            projectId={selectedProjectId}
            onChangeProject={setSelectedProjectId}
            boxProps={{ my: 3 }}
          />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Detalle de la instalación</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TypesSelector
                label="Tipo de instalación"
                name="installationType"
                control={control}
                errors={errors}
                typeCategory={TaskCategory.IMPLEMENTATION}
                onDataLoaded={() => {}}
                onChange={() => {}}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Select
                label="Kit de instalación"
                value={selectedKit}
                name="installationKit"
                fullWidth
                onChange={handleKitSelectOnChange}
                options={kitList.map((kit) => ({
                  value: kit.id,
                  label: kit.name,
                }))}
              />
            </Grid>
            <Grid container item spacing={2} xs={12} sm={6} md={4}>
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  onClick={handleOpenUpdateKit}
                  disabled={!activeKit}
                >
                  Actualizar KIT
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleCreateKitBtnOpen}
                  disabled={isLoading}
                >
                  Crear KIT
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <MaterialTable
                isLoading={isLoading}
                title="Materiales instalados"
                options={{
                  exportButton: false,
                  columnsButton: false,
                }}
                editable={{
                  onRowAdd: addMaterial,
                  onRowDelete: deleteMaterial,
                  onRowUpdate: updateMaterial,
                }}
                columns={tableColumns}
                data={[...installationMaterials.all]}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="default" onClick={handleCancel} disabled={isLoading}>
            Cancelar
          </Button>
          <Button
            color="primary"
            onClick={handleSubmit}
            form="installation-form"
            isLoading={isLoading}
            disabled={isLoading}
          >
            {DIALOG_TEXT[MODE].confirmBtn}
          </Button>
        </DialogActions>
      </Dialog>
      <MaterialKitDialog
        open={createDialogOpen}
        formMode={dialogKitMode}
        tableColumns={tableColumns}
        kit={activeKit}
        materialList={materialList}
        onClose={handleCreateDialogCancel}
        onCreate={handleCreateDialogSuccess}
        onUpdate={handleUpdateKitSucces}
      />
      <ConfirmationDialog
        open={confirmApplyKitDialogOpen}
        title="Confirmación"
        onConfirm={confirmKitChange}
        onCancel={handleCancelApplyKit}
        loading={isApplyingKit}
      >
        Estas seguro que deseas aplicar este Kit, los materiales actuales se borrarán.
      </ConfirmationDialog>
    </>
  );
}

ScheduleNewInstallationDialog.propTypes = {
  initialData: PropTypes.shape(),
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
};

ScheduleNewInstallationDialog.defaultProps = {
  initialData: null,
  onCancel: () => {},
  onSuccess: () => {},
};

export default ScheduleNewInstallationDialog;
