import React from "react";
import PropTypes from "prop-types";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import FormSuspensionTimer from "../FormSuspensionTimer";
import FormRestartTimer from "../FormRestartTimer";
import FormStopTimer from "../FormStopTimer";
import Alert from "@material-ui/lab/Alert";

const TimerDialog = ({
  open,
  onClose,
  onConfirm,
  fullWidth,
  maxWidth,
  configTimerDialog,
  loading,
  formProps,
  error,
  errorMessage,
}) => {
  const isXs = useMediaQuery("(max-width:765px)");
  const classes = useStyles();
  const classesBtn = useStylesBtn();
  const classesActions = useStylesActions();
  const classesBtnPrimary = useStylesBtnPrimary();
  const classesTitle = useStylesTitle();

  return (
    <>
      {configTimerDialog && (
        <Dialog
          open={open}
          fullScreen={isXs}
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          classes={{ root: classes.root }}
        >
          <DialogTitle classes={classesTitle}>
            <img src={configTimerDialog.icon} height="30" alt="stop" />
            {configTimerDialog.title}
          </DialogTitle>
          <DialogContent>
            {error && <Alert severity="error">{errorMessage}</Alert>}
            <form id="timer-form-dialog" onSubmit={formProps.onSubmit}>
              {configTimerDialog.id === "PAUSE" && <FormStopTimer {...formProps} />}
              {configTimerDialog.id === "RESUME" && <FormRestartTimer {...formProps} />}
              {configTimerDialog.id === "EDIT_PAUSE" && <FormSuspensionTimer {...formProps} />}
            </form>
          </DialogContent>
          <DialogActions classes={classesActions}>
            <Button disabled={loading} onClick={onClose} color="default" classes={classesBtn}>
              Cerrar
            </Button>

            <Button
              disabled={loading}
              onClick={onConfirm}
              endIcon={loading && <CircularProgress size={20} className={classes.circularProgress} />}
              color="default"
              classes={classesBtnPrimary}
              form="timer-form-dialog"
            >
              {configTimerDialog.btnText}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

TimerDialog.propTypes = {
  open: PropTypes.bool,
  maxWidth: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  fullWidth: PropTypes.bool,
  onClose: PropTypes.func,
  configTimerDialog: PropTypes.object,
  onConfirm: PropTypes.func,
  loading: PropTypes.bool,
  formProps: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object])).isRequired,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
};

TimerDialog.defaultProps = {
  formProps: {
    onSubmit: () => {},
    control: {},
    defaultTimerFormValues: {},
  },
};

export default TimerDialog;

const useStylesBtn = makeStyles((theme) => ({
  root: {
    width: "50%",
    backgroundColor: "white",
    color: "#00838f",
    border: "1px solid #00838f",
    height: "100%",
    borderRadius: "0",
  },
}));

const useStylesBtnPrimary = makeStyles((theme) => ({
  root: {
    color: "white",
    width: "50%",
    border: "1px solid #00838f",
    height: "100%",
    backgroundColor: "#00838f",
    marginLeft: "0px !important",
    borderRadius: "0",
    "&:hover": {
      backgroundColor: "#00838f",
    },
  },
}));

const useStylesActions = makeStyles((theme) => ({
  root: {
    padding: "12px 0 0 0",
    height: "60px",
  },
}));

const useStylesTitle = makeStyles((theme) => ({
  root: {
    height: "65px",
    textAlign: "center",
    marginTop: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  "@global": {
    ".MuiDialogTitle-root > h2 ": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    ".MuiDialogTitle-root > h2 > img": {
      marginRight: "10px",
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    position: "sticky !important",
    borderTopLeftRadius: "40%",
    borderTopRightRadius: "40%",

    "@global": {
      ".MuiDialog-container > div:nth-of-type(1)": {
        borderTopLeftRadius: "15%",
        borderTopRightRadius: "15%",
      },
    },
  },
  circularProgress: {
    color: "#29d8c8",
  },
}));
