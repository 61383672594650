/* eslint-disable */
// Archivo de consultas personalizadas al endpoint de GraphQL

export { default as getTasksByUser } from "./getTasksByUser";
export { default as getUsersByRole } from "./getUsersByRole";
export * from "./getJobRunState";
export { default as getTask } from "./getTask";
export * from "./getPremise";
export * from "./getUser";
export * from "./getGoodsReceipt";
export * from "./getKit";
export * from "./getTasksBySupervisorAndCategory";
export * from "./getAsset";
export * from "./getRole";
export * from "./getSetting";
export * from "./getUserTeam";
export * from "./getUserTeamDetails";
export * from "./getTicket";
export * from "./getCompany";
export * from "./findMaterialsById";
export * from "./lambdaQrGenerator";
export * from "./integrationRequestByIntegrationID";
export { default as listPremisesCatalog } from "./listPremisesCatalog";
export { default as getTaskTypes } from "./listTaskTypes";
export { default as listTaskTypes } from "./listTaskTypes";
export * from "./listMaterialCategories";
export * from "./listIntegrationRequests";
export * from "./listUsers";
export * from "./listGoodsReceipts";
export * from "./listRoles";
export * from "./listKits";
export * from "./listAssets";
export * from "./listTasks";
export * from "./listAdmAlmacenes";
export * from "./listAcceptedDomains";
export * from "./listCompanies";
export * from "./listPremisesOptions";
export * from "./listBuildingLocationOptionsByPremises";
export * from "./listAssetsByType";
export * from "./searchMaterials";
export * from "./searchAssets";
export * from "./listUnitsOfMeasure";
export * from "./listTasksByCategory";
export * from "./getTracking";
export * from "./lambdaDownloadAttachmentFiles";
export * from "./lambdaDownloadTaskDocument";
// export * from "./listEventCause"; // Timer
export * from "./getTimerEvent";
export * from "./getAttachmentAsset";
// export * from "./listTimerEvents";
export * from "./searchTimerEvents";
export * from "./getAssetsByProjectId";

export const getPremises = /* GraphQL */ `
  query GetPremises($id: ID!) {
    getPremises(id: $id) {
      id
      name
      owner
      custodian {
        id
        name
        middlename
        lastnameP
        lastnameM
        email
        phone
        extension
        createdAt
        updatedAt
      }
      address {
        id
        street
        number
        zipcode
        city
        settlement
        locality
        companyName
        createdAt
        updatedAt
      }
      buildingLocations {
        items {
          id
          name
          code
          buildingBlock
          floor
          area
          premisesID
          premises {
            id
            name
            owner
            custodian {
              id
              name
              middlename
              lastnameP
              lastnameM
              email
              phone
              extension
              createdAt
              updatedAt
            }
            address {
              id
              street
              number
              zipcode
              city
              settlement
              locality
              companyName
              createdAt
              updatedAt
            }
            buildingLocations {
              nextToken
            }
            createdAt
            updatedAt
          }
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const getUserTask = /* GraphQL */ `
  query GetUserTasks($id: ID!) {
    getUser(id: $id) {
      id
      tasks {
        items {
          id
          head
          task {
            id
            startDate
            startTime
            status
            taskType {
              name
            }
            buildingLocation {
              name
              code
              buildingBlock
              floor
              area
              premises {
                name
              }
            }
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const listPremisesBasicInfo = /* GraphQL */ `
  query ListPremises {
    listPremisess {
      items {
        id
        name
      }
      nextToken
    }
  }
`;

export const getPremisesBuildingLocations = /* GraphQL */ `
  query GetPremisesBuildingLocations($premisesId: ID!) {
    getPremises(id: $premisesId) {
      id
      name
      buildingLocations {
        items {
          id
          name
          code
        }
        nextToken
      }
    }
  }
`;

export const listUsers = /* GraphQL */ `
  query ListUsers($filter: ModelUserFilterInput, $limit: Int, $nextToken: String) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        middlename
        lastnameP
        lastnameM
        email
        phone
        extension
        roles {
          items {
            id
            user {
              id
              name
              middlename
              lastnameP
              lastnameM
              email
              phone
              extension
              createdAt
              updatedAt
            }
            role {
              id
              name
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        teams {
          items {
            id
            user {
              id
              name
              middlename
              lastnameP
              lastnameM
              email
              phone
              extension
              createdAt
              updatedAt
            }
            team {
              id
              name
              description
              team
              head
              createdAt
              updatedAt
            }
            head
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            userID
            taskID
            user {
              id
              name
              middlename
              lastnameP
              lastnameM
              email
              phone
              extension
              createdAt
              updatedAt
            }
            task {
              id
              status
              startDate
              endDate
              startTime
              endTime
              owner
              createdAt
              updatedAt
            }
            head
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listBuildingLocations = /* GraphQL */ `
  query ListBuildingLocations($filter: ModelBuildingLocationFilterInput, $limit: Int, $nextToken: String) {
    listBuildingLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        code
        buildingBlock
        floor
        area
        premisesID
        premises {
          id
          name
          owner
          company {
            name
          }
          custodian {
            id
            name
            middlename
            lastnameP
            lastnameM
            email
            phone
            extension
            createdAt
            updatedAt
          }
          address {
            id
            street
            number
            zipcode
            city
            settlement
            locality
            createdAt
            updatedAt
          }
          buildingLocations {
            items {
              id
              name
              code
              buildingBlock
              floor
              area
              premisesID
              owner
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getBuildingLocation = /* GraphQL */ `
  query GetBuildingLocation($id: ID!) {
    getBuildingLocation(id: $id) {
      id
      name
      code
      buildingBlock
      floor
      area
      premisesID
      premises {
        id
        name
        owner
        company {
          name
        }
        custodian {
          id
          name
          middlename
          lastnameP
          lastnameM
          email
          phone
          extension
          createdAt
          updatedAt
        }
        address {
          id
          street
          number
          zipcode
          city
          settlement
          locality
          createdAt
          updatedAt
        }
        buildingLocations {
          items {
            id
            name
            code
            buildingBlock
            floor
            area
            premisesID
            premises {
              id
              name
              owner
              company {
                name
              }
              createdAt
              updatedAt
            }
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      owner
      createdAt
      updatedAt
    }
  }
`;

export const listMaterials = /* GraphQL */ `
  query ListMaterials($filter: ModelMaterialFilterInput, $limit: Int, $nextToken: String) {
    listMaterials(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        code
        description
        _deleted
        _lastChangedAt
        deleted
        category {
          id
          name
          abbreviation
          description
          createdAt
          updatedAt
        }
        uom {
          id
          name
          abbreviation
          description
          createdAt
          updatedAt
        }
        manufacturer
        trackable
        enabled
        kits {
          items {
            id
            kit {
              id
              name
              description
              createdAt
              updatedAt
            }
            material {
              id
              name
              code
              description
              manufacturer
              trackable
              enabled
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getTeam = /* GraphQL */ `
  query getTeam($teamId: ID!) {
    getTeam(id: $teamId) {
      name
      description
      id
      head
      users {
        items {
          id
          _version
          _deleted
          user {
            id
            name
            middlename
            lastnameP
            lastnameM
            email
            phone
            status
          }
        }
      }
    }
  }
`;

export const getAllUsers = /* GraphQL */ `
  query ListUsers {
    listUsers {
      items {
        id
        name
        lastnameP
        email
        createdAt
        status
        _version
        _deleted
      }
    }
  }
`;
