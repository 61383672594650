export const LEGAL_DOCUMENT_TYPE = {
  TERMS_AND_CONDITIONS: "TERMS_AND_CONDITIONS",
  NOTICE_OF_PRIVACY: "NOTICE_OF_PRIVACY",
};

export const LEGAL_DOCUMENT_STATUS = {
  PENDING: "PENDING", // --> El documento aún no entra en vigor.
  EFFECTIVE: "EFFECTIVE", // --> El documento se encuentra en vigor.
  EXPIRED: "EXPIRED", // --> El documento esta expirado, su normativa ya no aplica dentro de la plataforma.
  CANCELLED: "CANCELLED", // --> El documento fue cancelado por algún mótivo [en este caso su normativa tampoco aplica dentro de la plataforma].
};
