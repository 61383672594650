import React, { useEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import PostAddIcon from "@material-ui/icons/PostAdd";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Section from "components/Section";
import MaterialTable from "components/custom/MaterialTable";
import SpeedDial from "components/custom/SpeedDial";
import useHeaderTitle from "hooks/useHeaderTitle";
import useBooleanFlag from "hooks/useBooleanFlag";
import { useActions } from "hooks/useActions";
import PremiseDialog from "./PremiseDialog";
import formatCustodianName from "./formatCustodianName";
import * as adminSiteCatalog from "redux/dispatcher/adminSiteCatalog";
import { REQUEST_STATUS } from "constant/requestStatus";
import { PREMISES_CATALOG } from "constant/route/admin";
import { PREMISES_CATALOG as VALIDATION_PREMISES_CATALOG } from "constant/route/validation";
import { checkNextItems } from "util/lists";

const SECTION_TITLE = "Catálogo de Sitios";

const TABLE_COLUMNS = [
  {
    title: "Nombre",
    field: "name",
  },
  {
    title: "Compañía",
    render: (rowData) => rowData?.company?.name || "Sin información",
  },
  {
    title: "Dirección",
    render: (rowData) => formatAddress(rowData?.address),
  },
  {
    title: "Responsable",
    render: (rowData) => formatCustodianName(rowData?.custodian),
  },
  {
    title: "Fecha de registro",
    field: "createdAt",
  },
  {
    title: "Última actualización",
    field: "updatedAt",
  },
];

function formatAddress(address) {
  if (address) {
    return `${address.street} ${address.number}, ${address.zipcode}`;
  } else {
    return "Sin información";
  }
}

function PremisesView(props) {
  const location = useLocation();
  const [premiseDialogOpen, openPremiseDialog, closePremiseDialog] = useBooleanFlag();
  const { rdxPremises, rdxStatus, rdxNextToken } = useSelector(({ adminPremisesList }) => adminPremisesList);
  const listPremises = useActions(adminSiteCatalog.listPremises);

  useHeaderTitle(SECTION_TITLE);
  const history = useHistory();

  useEffect(() => {
    listPremises();
  }, [listPremises]);

  function loadMorePremises(page) {
    const hasMoreItems = checkNextItems(page, rdxPremises.length);
    if (hasMoreItems && rdxNextToken) {
      listPremises(rdxNextToken);
    }
  }

  function loadMorePremises(page) {
    const hasMoreItems = checkNextItems(page, rdxPremises.length);
    if (hasMoreItems && rdxNextToken) {
      listPremises(rdxNextToken);
    }
  }

  async function handlePremiseCreationSuccess(result) {
    console.log("handlePremiseCreationSuccess", result);
    closePremiseDialog();
  }

  function handleRowClick(event, rowData) {
    if (location.pathname.startsWith("/validacion/")) {
      history.push(`${VALIDATION_PREMISES_CATALOG}/${rowData.id}`);
    } else {
      history.push(`${PREMISES_CATALOG}/${rowData.id}`);
    }
  }

  const actions = useMemo(() => {
    if (location.pathname.startsWith("/validacion/")) {
      return [
        {
          icon: <PostAddIcon />,
          name: "Crear",
          onClick: openPremiseDialog,
        },
      ];
    } else {
      return [
        {
          icon: <PostAddIcon />,
          name: "Crear",
          onClick: openPremiseDialog,
        },
      ];
    }
  }, []);

  return (
    <>
      <Section>
        {rdxStatus === REQUEST_STATUS.FAILED && (
          <Box p={2}>
            <Alert severity="error">Ha ocurrido un error con la carga del catálogo, recarga la página por favor.</Alert>
          </Box>
        )}
        <MaterialTable
          isLoading={rdxStatus === REQUEST_STATUS.PENDING}
          columns={TABLE_COLUMNS}
          data={rdxPremises}
          onRowClick={handleRowClick}
          onChangePage={loadMorePremises}
        />
      </Section>
      <SpeedDial actions={actions} backdrop tooltipOpen />
      {premiseDialogOpen && <PremiseDialog onClose={closePremiseDialog} />}
    </>
  );
}

export default PremisesView;
