import { useCallback, useMemo } from "react";
import { TaskCategory } from "models";
import useDataStore from "./useDataStore";
import * as dsTask from "datastore/task";
import * as models from "models";
import * as ROLES from "constant/roles";

export function testForDuplicated(items = []) {
  if (items.length === 0) return false;

  const tasksSet = new Set();

  let hasDuplicated = false;

  for (let i = 0; i < items.length; i++) {
    const task = items[i];

    if (tasksSet.has(task.id)) {
      hasDuplicated = true;
      break;
    } else {
      tasksSet.add(task.id);
    }
  }

  return hasDuplicated;
}

export function removeDuplicated(items = []) {
  const itemsPair = items.map((item) => [item.id, item]);
  const itemsMap = new Map(itemsPair);

  return [...itemsMap.values()];
}

export default function useFetchTasks() {
  const Task = useDataStore(models.Task);
  const Premises = useDataStore(models.Premises);
  const BuildingLocation = useDataStore(models.BuildingLocation);
  const TaskType = useDataStore(models.TaskType);
  const Project = useDataStore(models.Project);
  const User = useDataStore(models.User);
  const UserTask = useDataStore(models.UserTask);

  /**
   * @Func fetchTaskListWithDS
   * @param {*} items
     @param {*} iterationCount 
     @param {*} category 
     @param {*} page 
     @param {*} limit 
     @param {*} userRole 
     @param {*} userId 
     @param {*} status  
   * @returns 
   */
  const fetchTaskListWithDS = useCallback(
    async ({
      items = [],
      iterationCount = 0,
      category = TaskCategory.MAINTENANCE,
      page,
      limit,
      userRole,
      userId,
      status,
    }) => {
      if (items.length > limit || (items.length < limit && iterationCount > 35)) {
        return { items, page };
      }

      let taskList = [];
      iterationCount++;
      if (userRole === ROLES.SUPERVISORS) {
        taskList = await Task.get({
          criteria: (task) => {
            if (Array.isArray(status)) {
              const [firstStatus, secondStatus] = status;
              return task.or((tk) => tk.status("eq", firstStatus).status("eq", secondStatus)).category("eq", category);
            } else {
              if (status == "GENERAL") {
                return task.category("eq", category);
              }
              return task.category("eq", category).status("eq", status);
            }
          },
          paginationProducer: { page, limit },
        });
        taskList = taskList.filter(({ task }) => !Boolean(task.deleted));
      } else if (userRole === ROLES.SUPPORT_ENGINEERS) {
        taskList = await UserTask.get({
          criteria: (ut) => ut.userID("eq", userId),
          paginationProducer: { page, limit },
        });

        taskList = taskList.filter(({ task }) => !Boolean(task.deleted));

        if (status == "GENERAL" || (Array.isArray(status) && status.includes("GENERAL"))) {
          taskList = taskList.reduce((tasks, { task, user }) => {
            if (task.category === category) {
              const newTask = { ...task, user };
              tasks.push(newTask);
            }
            return tasks;
          }, []);
        } else {
          taskList = taskList.reduce((tasks, { task, user }) => {
            const validStatus = (Array.isArray(status) && status.includes(task.status)) || task.status === status;
            if (task.category === category && validStatus) {
              const newTask = { ...task, user };
              tasks.push(newTask);
            }
            return tasks;
          }, []);
        }
      }

      let { premisesIds, buildingLocationIds, taskTypeIds, projectIds, supervisorIds } = taskList.reduce(
        (lists, task) => {
          const premisesId = task?.taskPremisesId;
          const buildingLocationId = task?.taskBuildingLocationId;
          const taskTypeId = task?.taskTaskTypeId;
          const projectId = task?.taskProjectId;
          const supervisorId = task?.taskSupervisorId;
          premisesId && lists.premisesIds.push(premisesId);
          buildingLocationId && lists.buildingLocationIds.push(buildingLocationId);
          taskTypeId && lists.taskTypeIds.push(taskTypeId);
          projectId && lists.projectIds.push(projectId);
          supervisorId && lists.supervisorIds.push(supervisorId);
          return lists;
        },
        {
          premisesIds: [],
          buildingLocationIds: [],
          taskTypeIds: [],
          projectIds: [],
          supervisorIds: [],
        }
      );

      const premisesSet = new Set(premisesIds);
      const buildingLocationSet = new Set(buildingLocationIds);
      const taskTypeSet = new Set(taskTypeIds);
      const projectSet = new Set(projectIds);
      const supervisorSet = new Set(supervisorIds);
      premisesIds = Array.from(premisesSet);
      buildingLocationIds = Array.from(buildingLocationSet);
      taskTypeIds = Array.from(taskTypeSet);
      projectIds = Array.from(projectSet);
      supervisorIds = Array.from(supervisorSet);

      let pendingPremises = premisesIds.map(async (pId) => {
        const premise = await Premises.get({ criteria: pId });
        return [pId, premise];
      });
      pendingPremises = await Promise.all(pendingPremises);

      let pendingBuildingLocations = buildingLocationIds.map(async (bId) => {
        const building = await BuildingLocation.get({ criteria: bId });
        return [bId, building];
      });
      pendingBuildingLocations = await Promise.all(pendingBuildingLocations);

      let pendingTaskTypes = taskTypeIds.map(async (tId) => {
        const taskType = await TaskType.get({ criteria: tId });
        return [tId, taskType];
      });
      pendingTaskTypes = await Promise.all(pendingTaskTypes);

      let pendingProjects = projectIds.map(async (pId) => {
        const project = await Project.get({ criteria: pId });
        return [pId, project];
      });
      pendingProjects = await Promise.all(pendingProjects);

      let pendingSupervisors = supervisorIds.map(async (sId) => {
        const supervisor = await User.get({ criteria: sId });
        return [sId, supervisor];
      });
      pendingSupervisors = await Promise.all(pendingSupervisors);

      const premisesMap = new Map(pendingPremises);
      const buildingLocationMap = new Map(pendingBuildingLocations);
      const taskTypeMap = new Map(pendingTaskTypes);
      const projectsMap = new Map(pendingProjects);
      const supervisorsMap = new Map(pendingSupervisors);

      taskList = taskList.map((t) => {
        const task = { ...t };
        if (t.taskPremisesId) {
          task.premises = premisesMap.get(t.taskPremisesId);
        }
        if (t.taskBuildingLocationId) {
          task.buildingLocation = buildingLocationMap.get(t.taskBuildingLocationId);
        }
        if (t.taskTaskTypeId) {
          task.taskType = taskTypeMap.get(t.taskTaskTypeId);
        }
        if (t.taskProjectId) {
          task.project = projectsMap.get(t.taskProjectId);
        }
        if (t.taskSupervisorId) {
          task.supervisor = supervisorsMap.get(t.taskSupervisorId);
        }
        return task;
      });

      taskList = await dsTask.populateCompanies(taskList);
      taskList = await dsTask.populateUsers(taskList);

      items = [...items, ...taskList];

      if (testForDuplicated(items)) {
        items = removeDuplicated(items);
        return { items, page };
      }

      if (limit > taskList.length) {
        const maintenanceData = await fetchTaskListWithDS({
          page: page + 1,
          items,
          iterationCount,
          category,
          limit,
          userRole,
          userId,
          status,
        });
        return {
          items: maintenanceData.items,
          page: maintenanceData.page,
        };
      } else {
        return { items, page };
      }
    },
    []
  );

  return useMemo(
    () => ({
      fetchTaskListWithDS,
    }),
    [fetchTaskListWithDS]
  );
}
