import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import useHeaderTitle from "hooks/useHeaderTitle";
import MaterialTable from "components/custom/MaterialTable";
import Switch from "@material-ui/core/Switch";
import ConfirmationDialog from "components/ConfirmationDialog/ConfirmationDialog";
import FloatingButton from "components/FloatingButton";
import NewDomainsDialog from "./NewDomainsDialog";
import API, { graphqlOperation } from "@aws-amplify/api";
import * as queries from "graphql-custom/queries";
import * as mutations from "graphql-custom/mutations";
import useNotifier from "hooks/useNotifier";
import { checkNextItems } from "util/lists";

function DomainsView() {
  useHeaderTitle("Dominios");
  const [tableOptions] = useState({
    exportButton: false,
    columnsButton: false,
  });
  const [tableColumns] = useState([
    { title: "Habilitado", field: "status", render: renderSwitch },
    { title: "Compañía", field: "company.name" },
    { title: "Dominio", field: "emailDomain" },
    {
      title: "Fecha de registro",
      field: "createdAt",
      render: (rowData) =>
        rowData.createdAt ? dayjs(rowData.createdAt).format("DD/MM/YYYY HH:mm") : "Sin información",
    },
    {
      title: "Última actualización",
      field: "updatedAt",
      render: (rowData) =>
        rowData.updatedAt ? dayjs(rowData.updatedAt).format("DD/MM/YYYY HH:mm") : "Sin información",
    },
  ]);
  const [showStatusDialog, setShowStatusDialog] = useState(false);
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [domains, setDomains] = useState([]);
  const [paginationToken, setPaginationToken] = useState(null);
  const [loadingDomains, setLoadingDomains] = useState(true);
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const { showError } = useNotifier();

  useEffect(() => {
    (async function () {
      const { items, nextToken } = await getDomains();
      setDomains(items);
      setPaginationToken(nextToken);
      setLoadingDomains(false);
    })();
  }, []);

  const getDomains = async (nextToken) => {
    try {
      const { data } = await API.graphql(graphqlOperation(queries.listAcceptedDomains, { nextToken }));
      return {
        items: data.listAcceptedDomains.items,
        nextToken: data.listAcceptedDomains.nextToken,
      };
    } catch (error) {
      showError("Ocurrió un error");
      return { items: [], nextToken: null };
    }
  };

  const loadMoreDomains = async (page) => {
    setLoadingDomains(true);
    const hasMoreItems = checkNextItems(page, domains.length);
    if (hasMoreItems && paginationToken) {
      const { items, nextToken } = await getDomains(paginationToken);
      setDomains(items);
      setPaginationToken(nextToken);
    }
    setLoadingDomains(false);
  };

  const updateDomains = (domain) => {
    setDomains([...domains, domain]);
  };

  function renderSwitch(row) {
    return (
      <Switch
        checked={row.status === "ENABLED"}
        onChange={() => toggleStatusConfirmation(row)}
        color="primary"
        name="enable"
      />
    );
  }

  const confirmUpdateStatus = async () => {
    setUpdatingStatus(true);
    const newStatus = selectedDomain.status === "ENABLED" ? "DISABLED" : "ENABLED";
    const domain = await changeStatus(selectedDomain.id, newStatus, selectedDomain._version);
    if (domain) {
      const updatedIndex = domains.findIndex(({ id }) => id === domain.id);
      const newDomains = [...domains];
      newDomains[updatedIndex] = {
        ...newDomains[updatedIndex],
        status: newStatus,
        _version: domain._version,
        updatedAt: domain.updatedAt,
      };
      setDomains(newDomains);
    }
    setUpdatingStatus(false);
    setShowStatusDialog(false);
  };

  const changeStatus = async (id, status, _version) => {
    try {
      const { data } = await API.graphql(graphqlOperation(mutations.changeDomainStatus, { id, status, _version }));
      return data.updateAcceptedDomain;
    } catch (error) {
      showError("Ocurrio un error");
      return "";
    }
  };

  const toggleStatusConfirmation = (domain) => {
    setSelectedDomain(domain);
    setShowStatusDialog(true);
  };

  const cancelChangeStatus = () => {
    setShowStatusDialog(false);
    setSelectedDomain(null);
  };

  return (
    <>
      <MaterialTable
        options={tableOptions}
        columns={tableColumns}
        data={domains}
        isLoading={loadingDomains}
        onChangePage={loadMoreDomains}
      />
      <ConfirmationDialog
        open={showStatusDialog}
        title="Confirmación"
        onConfirm={confirmUpdateStatus}
        onCancel={cancelChangeStatus}
        loading={updatingStatus}
      >
        {selectedDomain?.status === "ENABLED"
          ? "Está a punto de deshabilitar el dominio. Se impedirá el registro de nuevos usuarios con este dominio."
          : "Está a punto de habilitar el dominio. Se autorizará el registro de nuevos usuarios con este dominio."}
        <br />
        <br />
        ¿Deseas continuar?
      </ConfirmationDialog>

      <FloatingButton
        bottom={15}
        right={15}
        color="primary"
        variant="extended"
        aria-label="Agregar Dominio"
        onClick={() => setShowAddDialog(true)}
      >
        Agregar Dominio
      </FloatingButton>
      {showAddDialog && <NewDomainsDialog onClose={() => setShowAddDialog(false)} onUpdateDomains={updateDomains} />}
    </>
  );
}

export default DomainsView;
