export const ROOT = "/revision";

export const MY_TICKET_LIST = `${ROOT}/mis-tickets`;
// export const SDP = `${ROOT}/SDP`;
export const TICKET_DETAILS = `${ROOT}/ticket/:id`;

const SUPPORT_SUPERVISOR = {
  ROOT,
  MY_TICKET_LIST,
  TICKET_DETAILS,
};

export default SUPPORT_SUPERVISOR;
