import React from "react";
import PropTypes from "prop-types";
import WifiOffIcon from "@material-ui/icons/WifiOff";
import { makeStyles } from "@material-ui/core/styles";
import useOnlineStatus from "@rehooks/online-status";
import Fade from "@material-ui/core/Fade";

const OfflineStatusIcon = ({ className, transitionProps, ...props }) => {
  const isOnline = useOnlineStatus();
  const classes = useStyles();

  return (
    <Fade in={!isOnline} unmountOnExit {...transitionProps}>
      <WifiOffIcon className={className ? `${classes.icon} ${className}` : classes.icon} {...props} />
    </Fade>
  );
};

export default OfflineStatusIcon;

OfflineStatusIcon.propTypes = {
  className: PropTypes.string,
  transitionProps: PropTypes.object,
};

OfflineStatusIcon.defaultProps = {
  className: "",
  transitionProps: {},
};

const useStyles = makeStyles((theme) => ({
  icon: {
    width: "48px",
    height: "48px",
    position: "fixed",
    right: "16px",
    bottom: "118px",
    zIndex: theme.zIndex.modal + 1,
    padding: "8px",
    borderRadius: "50%",
    color: "#404040",
    backgroundColor: "#E8DD6F",
  },
}));
