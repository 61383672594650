import API, { graphqlOperation } from "@aws-amplify/api";
import * as queries from "graphql-custom/queries";

export async function fetchCategories(nextToken) {
  return API.graphql(graphqlOperation(queries.listMaterialCategoriesFullData, { nextToken }));
}

export async function removeCategories(categories, userId) {
  const { mutation, variables } = generateDeleteCategoriesMutation(categories);
  const { data } = await API.graphql(graphqlOperation(mutation, variables));
  const deletedCategories = Object.keys(data).map(key => data[key]);
  const { mutation: logMutation, variables: logVariables } = generateCreateLogsMutation(deletedCategories, userId);
  await API.graphql(graphqlOperation(logMutation, logVariables));
  return deletedCategories;
}

function generateDeleteCategoriesMutation(categories = []) {
  let mutation = "";
  let mutationParams = "";
  const variables = {};

  categories.forEach((category, index) => {
    mutationParams += `$materialCategoryID${index}: ID!, $_version${index}: Int!, `;
    mutation += `
    updateMaterialCategory${index}: updateMaterialCategory(input: { 
        id: $materialCategoryID${index}, 
        _version: $_version${index}, 
        deleted: true
      }) {
        id
        name
        abbreviation
        description
        createdAt
        updatedAt
        _version
        _deleted
        deleted
      },
    `;
    variables[`materialCategoryID${index}`] = category.id;
    variables[`_version${index}`] = category._version;
  });

  mutation = `mutation deleteMaterialCategories(${mutationParams}) {
    ${mutation}
  }`;

  return { mutation, variables };
}

function generateCreateLogsMutation(deletedCategories = [], userId = "") {
  let mutation = "";
  let mutationParams = "";
  const variables = {};

  deletedCategories.forEach((category, index) => {
    mutationParams += `$createLog${index}: CreateLogInput!, `;
    mutation += `
      createLog${index}: createLog(input: $createLog${index}) {
        id
        userID
        dataJSON  
        dataType
      },
    `;
    variables[`createLog${index}`] = {
      userID: userId,
      dataType: "MATERIAL_CATEGORY",
      dataJSON: JSON.stringify(category),
    };
  });

  mutation = `
    mutation CreateLogs(${mutationParams}) {
      ${mutation}
    }
  `;

  return { mutation, variables };
}