import { TaskStatus, SupportType } from "models";

export const STATUS_THAT_ENABLE_ACTIVITY_PROGRESS = [
  TaskStatus.SCHEDULED,
  TaskStatus.IN_PROGRESS,
  TaskStatus.WITH_INSPECTION_NOTES,
];

export const SUPPORT_TYPE_TRANSLATION = {
  [SupportType.PRESENTIAL]: "Presencial",
  [SupportType.REMOTE]: "Remoto",
  [SupportType.UNDEFINED]: "Sin tipo de soporte seleccionado",
};

export const SUPPORT_ACTIVITY_STEPS_MODE = {
  OBJECT: "OBJECT",
  ARRAY: "ARRAY",
};

export const LOCAL_STORAGE_CURRENT_STEP_KEY = "current-step-support-task";
export const LOCAL_STORAGE_FORM_VALUES_KEY = "form-values-support-task";

/**
 *  The constants object describes the steps of a support ticket
 */

export const DEFAULT_SUPPORT_ACTIVITY_STEPS_KEYNAME = (supportType = SupportType.PRESENTIAL) => {
  if (supportType === SupportType.PRESENTIAL) {
    return {
      SUPPORT_TYPE: "SUPPORT_TYPE",
      INITIAL_EVIDENCE: "INITIAL_EVIDENCE",
      PROGRESS_EVIDENCE: "PROGRESS_EVIDENCE",
      END_EVIDENCE: "END_EVIDENCE",
      ACTIVITY_RETROSPECTIVE: "ACTIVITY_RETROSPECTIVE",
    };
  }

  if (supportType === SupportType.REMOTE) {
    return {
      SUPPORT_TYPE: "SUPPORT_TYPE",
      REMOTE_ACTIVITY: "REMOTE_ACTIVITY",
    };
  }
  return null;
};

export const DEFAULT_SUPPORT_ACTIVITY_STEPS = (supportType = SupportType.PRESENTIAL) => {
  if (supportType === SupportType.PRESENTIAL) {
    return {
      SUPPORT_TYPE: 0,
      INITIAL_EVIDENCE: 1,
      PROGRESS_EVIDENCE: 2,
      END_EVIDENCE: 3,
      ACTIVITY_RETROSPECTIVE: 4,
    };
  }

  if (supportType === SupportType.REMOTE) {
    return {
      SUPPORT_TYPE: 0,
      REMOTE_ACTIVITY: 1,
    };
  }
  return null;
};

export const DEFAULT_ASSET_FORM_KEYS = {
  SERIAL: "serial",
  CODE: "code",
  NAME: "name",
  ASSET_LOCATION: "asset_location",
  COMMENT: "comment",
};

export const ATTACHMENT_LENGTHS = {
  MIN_START: 3,
  MIN_PROGRESS: 4,
  MIN_END: 1,
};

export const DEFAULT_EVIDENCES_BY_STATUS = {
  beginning: [],
  inProgress: [],
  completed: [],
};

export const EVIDENCES_STEPS_KEYNAMES = [
  DEFAULT_SUPPORT_ACTIVITY_STEPS_KEYNAME().INITIAL_EVIDENCE,
  DEFAULT_SUPPORT_ACTIVITY_STEPS_KEYNAME().PROGRESS_EVIDENCE,
  DEFAULT_SUPPORT_ACTIVITY_STEPS_KEYNAME().END_EVIDENCE,
];

export const DIALOG_CONTENT_BY_STEP = {
  [DEFAULT_SUPPORT_ACTIVITY_STEPS_KEYNAME().SUPPORT_TYPE]: {
    title: "Soporte - Confirmación",
    instructions: (hasSupportType) =>
      !hasSupportType
        ? "Si estás seguro de iniciar la atención de soporte, selecciona el tipo de soporte que se brindará."
        : "El siguiente tipo de soporte se ha seleccionado con anterioridad.",
    nextBtnLabel: "Iniciar",
    previousBtnLabel: "Cancelar",
  },
  [DEFAULT_SUPPORT_ACTIVITY_STEPS_KEYNAME(SupportType.REMOTE).REMOTE_ACTIVITY]: {
    title: "DETALLE DE LA ACTIVIDAD",
    instructions:
      "Para finalizar la actividad añade las evidencias necesarias y un comentario a la resolución del problema.",
    nextBtnLabel: "",
    previousBtnLabel: "",
  },
  [DEFAULT_SUPPORT_ACTIVITY_STEPS_KEYNAME().INITIAL_EVIDENCE]: {
    title: "EVIDENCIA INICIAL",
    instructions:
      "Para continuar el proceso de soporte adjunta fotografias del material que recibirán atención de soporte",
    nextBtnLabel: "",
    previousBtnLabel: "",
  },
  [DEFAULT_SUPPORT_ACTIVITY_STEPS_KEYNAME().PROGRESS_EVIDENCE]: {
    title: "EVIDENCIA PROGRESO",
    instructions: "Ingrese las evidencias de la atención de soporte proporcionada.",
    nextBtnLabel: "",
    previousBtnLabel: "",
  },
  [DEFAULT_SUPPORT_ACTIVITY_STEPS_KEYNAME().END_EVIDENCE]: {
    title: "EVIDENCIA FINAL",
    instructions: "Para finalizar el proceso de soporte adjunta fotografías de la atención de soporte proporcionada.",
    nextBtnLabel: "",
    previousBtnLabel: "",
  },
  [DEFAULT_SUPPORT_ACTIVITY_STEPS_KEYNAME().ACTIVITY_RETROSPECTIVE]: {
    title: "DETALLE",
    instructions: "",
    nextBtnLabel: "",
    previousBtnLabel: "",
  },
};

const checkEmptyValue = (message) => (value) => value?.trim() !== "" || message;

export const SUPPORT_STEPS_FORMS_RULES = {
  supportType: {
    required: "Seleccione un tipo",
  },
  resolutionComment: {
    required: "Ingresa la resolución",
    maxLength: { value: 400, message: "Máximo 400 caracteres" },
    validate: { noEmpty: checkEmptyValue("Ingresa la resolución") },
  },
  issue: {
    required: "Ingresa la problemática",
    maxLength: { value: 400, message: "Máximo 400 caracteres" },
    validate: { noEmpty: checkEmptyValue("Ingresa la problemática") },
  },
  analysis: {
    required: "Ingresa el análisis",
    maxLength: { value: 400, message: "Máximo 400 caracteres" },
    validate: { noEmpty: checkEmptyValue("Ingresa el análisis") },
  },
  solution: {
    required: "Ingresa la solución",
    maxLength: { value: 400, message: "Máximo 400 caracteres" },
    validate: { noEmpty: checkEmptyValue("Ingresa la solución") },
  },
};
