/* eslint-disable react/display-name */
// componente para mostrar el detalle de las entradas de activos
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useIndexedDB } from "react-indexed-db";
import { useHistory, useParams } from "react-router-dom";
import { Logger } from "@aws-amplify/core";
import { DataStore } from "@aws-amplify/datastore";
import ReactReadMoreReadLess from "react-read-more-read-less";
import Alert from "@material-ui/lab/Alert";
import makeStyles from "@material-ui/styles/makeStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import DataLabel from "components/DataLabel";
import DetailTitle from "components/DetailTitle";
import FloatingButton from "components/FloatingButton";
import DeliveryDialog from "components/DeliveryDialog";
import MaterialTable from "components/custom/MaterialTable";
import Button from "components/custom/Button";
import AttachmentsDialog from "components/common/dialog/AttachmentsDialog";
import CodeReader from "components/common/CodeReader";
import Card from "components/custom/Card";
import useHeaderTitle from "hooks/useHeaderTitle";
import useNotifier from "hooks/useNotifier";
import { CODE_READER_STATUS } from "constant/codeReaderStatus";
import { Attachment, Company, Project, Task, UserTask } from "models";
import cleanUUID from "util/cleanUUID";
import destinationDetails from "util/destinationDetails";
import { fetchAssetsByTask, updateTask, createRemark, updateAsset, fetchAttachmentsByTask } from "datastore";
import { BuildingLocation } from "models";
import { TaskStatus } from "models";
import DeliveryScheduleDialog from "components/DeliveryScheduleDialog";
import { Remark } from "models";
import { Device } from "@capacitor/device";
import { ENVIRONMENT } from "constant/environments";

const logger = new Logger("Delivery");

const SECTION_TITLE = "Envío";

const TYPES = {
  WAREHOUSE_KEEPERS: "warehouse-keepers",
  SUPERVISORS: "supervisors",
};

const TABLE_STATUSES = {
  SHIPMENT_SCHEDULED: "Programado",
  SHIPMENT_SENT: "Envíado",
  SHIPMENT_PREV_SENT: "En curso de envío",
  SHIPMENT_RECEIVED: "Recibido",
  SHIPMENT_PREV_RECEIVED: "En recepción",
};

export default function DeliveryDetailsView({ type }) {
  let history = useHistory();
  const { id } = useParams();
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const previusRootPath = history.location.pathname.split("/")[1];
  const { update } = useIndexedDB("offline_images");

  useHeaderTitle(`${SECTION_TITLE} ${cleanUUID(id)}`);
  const { showError, showMessage } = useNotifier();

  const [isDeliveryUpdateDialogOpen, setIsDeliveryUpdateDialogOpen] = useState(false);
  const [isDeliveryDialogOpen, setIsDeliveryDialogOpen] = useState(false);
  const [isEvidenceDialogOpen, setIsEvidenceDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [incomeDetailInfo, setIncomeDetailInfo] = useState({});
  const [incomeAssets, setIncomeAssets] = useState([]);
  const [incomeAttachments, setIncomeAttachments] = useState([]);
  const [isRequestError, setIsRequestError] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  // code reader
  const [showCodeDialog, setShowCodeDialog] = useState(false);
  const [codeReaderStatus, setCodeReaderStatus] = useState(CODE_READER_STATUS.STOP);
  const [codeDetected, setCodeDetected] = useState("");

  useEffect(() => {
    fetchIncomeDetailInfo();
    getDeviceDetails();
  }, []);

  function onCloseDeliveryDialog() {
    setIsDeliveryDialogOpen(false);
  }

  async function getDeviceDetails() {
    const info = await Device.getInfo();
    if (process.env.REACT_APP_ENV !== ENVIRONMENT.PROD) {
      setIsMobile(true);
    } else if (info.platform !== "web" && info.platform !== "electron") {
      setIsMobile(true);
    }
  }

  async function fetchIncomeDetailInfo() {
    setIsLoading(true);
    try {
      let _task = await DataStore.query(Task, id);
      let _userTask = await DataStore.query(UserTask, (userTask) => {
        return userTask.taskID("eq", id);
      });
      let _remarkTask = await DataStore.query(Remark, (remark) => remark.taskID("eq", id));
      if (_userTask.length > 0) {
        _userTask = _userTask[0];
      }
      if (_remarkTask.length > 0) {
        _remarkTask = _remarkTask[0];
      }

      const _taskAttachments = await fetchAttachmentsByTask(id);
      let _project = _task.project;
      if (!_project && _task?.taskProjectId) {
        _project = await DataStore.query(Project, _task.taskProjectId);
      }
      let company = null;
      if (_project?.companyID) {
        company = await DataStore.query(Company, _project.companyID);
        _project = { ..._project, company };
      }

      if (_task.buildingLocation) {
        const _buildingLocation = await DataStore.query(BuildingLocation, _task.buildingLocation.id);

        _task = Task.copyOf(_task, (updated) => {
          updated.buildingLocation = _buildingLocation;
          return updated;
        });
      }

      let _taskAssets = await fetchAssetsByTask(id);
      _taskAssets = _taskAssets.map((ta) => ({
        ...ta?.asset?.asset,
        taskAsset: {
          id: ta?.taskAssets?.id,
          _version: ta?.taskAssets?._version,
        },
      }));
      if (_task.status === "SHIPMENT_SENT") {
        _taskAssets = _taskAssets.filter((ta) => ta.type === "SHIPMENT_SENT" || ta.type === "SHIPMENT_PREV_SENT");
      } else if (_task.status === "SHIPMENT_RECEIVED") {
        _taskAssets = _taskAssets.filter((ta) => ta.type === "SHIPMENT_RECEIVED" || ta.type === "SHIPMENT_SENT");
      }
      logger.debug(_task);
      logger.debug({ _userTask });
      logger.debug(_taskAssets);
      logger.debug(_taskAttachments);
      setIncomeDetailInfo({ ..._task, userTask: _userTask, project: _project, remark: _remarkTask });
      setIncomeAssets(_taskAssets);
      setIncomeAttachments(_taskAttachments);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      logger.debug(error);
    }
  }

  function toggleDeliveryDialogStatus() {
    setIsDeliveryDialogOpen(!isDeliveryDialogOpen);
  }

  async function handleOnSubmitDeliveryDialog(status, values, selectedAssets, attachments) {
    const finalStatus = status === "SHIPMENT_SCHEDULED" ? "SHIPMENT_SENT" : "SHIPMENT_RECEIVED";
    setIsLoading(true);
    // Cambio de estatus al task
    updateTask({
      id: incomeDetailInfo?.id,
      type: finalStatus,
    }).then(async (task) => {
      logger.debug(task);
      try {
        // Se agregan los comentarios de la operación a la tabla
        if (values?.comments) {
          await createRemark({
            comment: values.comments,
            taskID: incomeDetailInfo?.id,
            taskStatus: finalStatus,
          });
        }
        // Cambio de estatus a los assets
        const assets = await Promise.all(
          selectedAssets.map((asset) => {
            return updateAsset({ ...asset, type: finalStatus });
          })
        );
        logger.debug(assets);

        // recorremos los attachments para cambiarlos al estatus final
        await Promise.all(
          attachments.map(async (attachment) => {
            if (attachment?.origin === "s3") {
              const _attachment = await DataStore.query(Attachment, attachment.id);

              return await DataStore.save(
                Attachment.copyOf(_attachment, (updated) => {
                  updated.status = finalStatus;
                })
              );
            } else {
              return await update({ ...attachment, id: attachment.id, status: finalStatus });
            }
          })
        );

        showMessage("Se ha realizado la operación con éxito", { variant: "success" });
        handleSuccesfulDelivery();
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        showError(`Ha ocurrido un error, intente más tarde ${error}`);
        logger.error(error);
      }
    });
  }

  async function preSelectAsset(asset, status) {
    logger.debug(asset);
    try {
      let finalStatus;
      if (status === "SHIPMENT_SCHEDULED") {
        if (asset.type === "SHIPMENT_PREV_SCHEDULED") {
          finalStatus = "SHIPMENT_SCHEDULED";
        } else {
          finalStatus = "SHIPMENT_PREV_SCHEDULED";
        }
      } else if (status === "SHIPMENT_SENT") {
        if (asset.type === "SHIPMENT_PREV_SENT") {
          finalStatus = "SHIPMENT_SENT";
        } else {
          finalStatus = "SHIPMENT_PREV_SENT";
        }
      }

      setIsLoading(true);
      const _asset = await updateAsset({ ...asset, type: finalStatus });
      logger.debug(_asset);
      fetchIncomeDetailInfo();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      showError(`Ha ocurrido un error, intente más tarde ${error}`);
      logger.error(error);
    }
  }

  function selectQRCode() {
    setShowCodeDialog(true);
    setCodeReaderStatus(CODE_READER_STATUS.START);
  }

  function handleConfirmCodeDetected() {
    //buscar el assset que hay que cambiar de estatus
    incomeAssets.forEach((asset) => {
      if (asset?.tracking?.id === codeDetected) {
        preSelectAsset(asset, incomeDetailInfo.status);
        handleOnCloseCodeReader();
      }
    });
  }

  function handleCodeDetection(code) {
    const { text } = code;
    incomeAssets.forEach((asset) => {
      if (asset?.tracking?.id === text) {
        setCodeDetected(text);
      }
    });
  }

  function handleSuccesfulDelivery() {
    setIsDeliveryDialogOpen(false);
    fetchIncomeDetailInfo();
  }

  function handleOnCloseCodeReader() {
    setCodeDetected("");
    setCodeReaderStatus(CODE_READER_STATUS.STOP);
    setShowCodeDialog(false);
  }

  function getDeliveryActions() {
    let actions = [];
    if (incomeDetailInfo.status === TaskStatus.SHIPMENT_SCHEDULED) {
      actions.push({
        text: "Editar",
        onClick: () => setIsDeliveryUpdateDialogOpen(true),
      });
    }
    return actions;
  }

  return (
    <>
      <Card
        header={{
          title: (
            <Grid container alignItems="center">
              <Grid item xs={12}>
                {isRequestError && (
                  <Box p={2}>
                    <Alert severity="error">
                      Ha ocurrido un error con la carga del catálogo, recarga la página por favor.
                    </Alert>
                  </Box>
                )}
              </Grid>
              <Grid item xs sm={6} md={4} lg={3}>
                <Box>
                  <DetailTitle
                    title={`${SECTION_TITLE} ${cleanUUID(incomeDetailInfo?.id)}`}
                    to={`/${previusRootPath}/envios`}
                  />
                </Box>
              </Grid>
              <Grid item xs>
                {!isLoading && (
                  <Box>
                    <Chip label={TABLE_STATUSES[incomeDetailInfo?.status]} color="primary" />
                  </Box>
                )}
              </Grid>
            </Grid>
          ),
          actions: getDeliveryActions(),
        }}
      >
        <Grid container>
          <Grid item xs={12} className={classes.detailsContent}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={4}>
                <DataLabel label="Destino" data={destinationDetails(incomeDetailInfo)} loading={isLoading} />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <DataLabel
                  label="Cliente"
                  data={incomeDetailInfo?.project ? incomeDetailInfo.project.company.name : "Sin asignación"}
                  loading={isLoading}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <DataLabel
                  label="Proyecto"
                  data={
                    incomeDetailInfo?.project
                      ? `${incomeDetailInfo.project.name} (${incomeDetailInfo.project.code})`
                      : "Sin asignación"
                  }
                  loading={isLoading}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <DataLabel
                  label="Fecha/Hora"
                  data={`${incomeDetailInfo?.startDate} ${incomeDetailInfo?.startTime} `}
                  loading={isLoading}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <DataLabel
                  label="Programado por:"
                  data={`${incomeDetailInfo?.supervisor?.name || ""} ${incomeDetailInfo?.supervisor?.lastnameP || ""} ${
                    incomeDetailInfo?.supervisor?.email ? `(${incomeDetailInfo?.supervisor?.email})` : ""
                  }`}
                  loading={isLoading}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <DataLabel
                  label="Destinatario"
                  data={`${incomeDetailInfo?.userTask?.user?.name || ""} ${
                    incomeDetailInfo?.userTask?.user?.lastnameP || ""
                  } ${incomeDetailInfo?.userTask?.user?.email ? `(${incomeDetailInfo?.userTask?.user?.email})` : ""}`}
                  loading={isLoading}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" color="textSecondary">
                  <b>Comentarios</b>
                </Typography>
                <ReactReadMoreReadLess
                  charLimit={matches ? 200 : 400}
                  readMoreText="Leer más"
                  readLessText="Leer menos"
                >
                  {incomeDetailInfo?.remark?.remark || "Sin comentarios"}
                </ReactReadMoreReadLess>
              </Grid>
              <Grid item container direction="column" justify="flex-end" sm={12} xs={12}>
                <Box textAlign="right" mt={5}>
                  <Grid container spacing={2} justify="flex-end">
                    {/* <Grid item xs={12} md={2}>
                      <Button variant="outlined" color="primary" fullWidth={matches} visible={!isLoading}>
                        DESCARGAR QR
                      </Button>
                    </Grid> */}
                    {incomeAttachments.length > 0 && (
                      <Grid item xs={12} md={2}>
                        <Button
                          variant="outlined"
                          color="primary"
                          fullWidth={matches}
                          onClick={() => setIsEvidenceDialogOpen(true)}
                        >
                          VER EVIDENCIAS
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* </Section> */}
      </Card>
      <Box my={5} className={classes.root}>
        <MaterialTable
          title={"Activos"}
          options={TABLE_OPTIONS}
          columns={!matches ? TABLE_COLUMNS : TABLE_COLUMNS_MOBILE}
          data={incomeAssets || []}
          isLoading={isLoading}
          // actions={[
          //   {
          //     icon: () => <img src="/img/qrcode.png" alt="icon" />,
          //     tooltip: "Descargar QRs",
          //     isFreeAction: true,
          //     onClick: (event) => alert("You want to add a new row"),
          //   },
          // ]}
        />
      </Box>
      {type === TYPES.WAREHOUSE_KEEPERS && incomeDetailInfo.status === "SHIPMENT_SCHEDULED" && (
        <FloatingButton
          bottom={15}
          right={35}
          color="primary"
          aria-label="add"
          onClick={() => toggleDeliveryDialogStatus()}
          variant="extended"
        >
          DESPACHAR ENVÍO
        </FloatingButton>
      )}
      {type === TYPES.SUPERVISORS && incomeDetailInfo.status === "SHIPMENT_SENT" && (
        <FloatingButton
          bottom={15}
          right={35}
          color="primary"
          aria-label="add"
          onClick={() => toggleDeliveryDialogStatus()}
          variant="extended"
        >
          RECIBIR ENVÍO
        </FloatingButton>
      )}
      <DeliveryScheduleDialog
        open={isDeliveryUpdateDialogOpen}
        delivery={incomeDetailInfo}
        deliveryAssets={incomeAssets}
        onClose={() => setIsDeliveryUpdateDialogOpen(false)}
        onUpdateDelivery={fetchIncomeDetailInfo}
      />
      <DeliveryDialog
        open={isDeliveryDialogOpen}
        status={incomeDetailInfo.status}
        onClose={onCloseDeliveryDialog}
        incomeDetailInfo={incomeDetailInfo}
        handleOnSubmitDeliveryDialog={handleOnSubmitDeliveryDialog}
        assets={incomeAssets}
        isLoading={isLoading}
        isMobile={isMobile}
        preSelectAsset={preSelectAsset}
        selectQRCode={selectQRCode}
      />
      <AttachmentsDialog
        open={isEvidenceDialogOpen}
        attachments={incomeAttachments || []}
        isLoading={isLoading}
        withSections={false}
        fullWidth={true}
        onClose={() => setIsEvidenceDialogOpen(false)}
        maxWidth={"xl"}
      />
      <CodeReader
        open={showCodeDialog}
        status={CODE_READER_STATUS.START}
        onClose={() => handleOnCloseCodeReader()}
        onCodeDetected={handleCodeDetection}
        bottomPanel={
          <>
            <Box p={2} className={classes.codeReaderPanel}>
              {codeDetected && (
                <>
                  <Box mb={2}>
                    <Typography variant="caption" display="inline">
                      CÓDIGO:{" "}
                    </Typography>
                    <Typography variant="body2" display="inline">
                      {cleanUUID(codeDetected)}
                    </Typography>
                  </Box>
                  <Box mb={2}>
                    <Typography variant="caption" display="inline">
                      MATERIAL:{" "}
                    </Typography>
                    <Typography variant="body2" display="inline">
                      {incomeAssets.find((asset) => asset?.tracking?.id === codeDetected)?.material?.name}
                    </Typography>
                  </Box>
                </>
              )}
              {!codeDetected && (
                <Box>
                  <Typography variant="caption">Código no detectado</Typography>
                </Box>
              )}
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleConfirmCodeDetected()}
                disabled={!codeDetected}
                fullWidth
              >
                Confirmar
              </Button>
            </Box>
          </>
        }
      />
    </>
  );
}

const TABLE_OPTIONS = {
  exportButton: false,
  columnsButton: false,
  // selection: true,
};
const TABLE_COLUMNS = [
  { title: "Código Material", field: "material.code" },
  { title: "Nombre", field: "material.name" },
  {
    title: "Serie",
    field: "serial",
    render: (rowData) => (rowData?.serial === "DEFAULT_SERIE" ? "Sin serie" : rowData?.serial || "S/I"),
  },
  {
    title: "Vinculado",
    field: "tracking",
    render: (rowData) => {
      if (rowData?.tracking?.id !== "unassigned") {
        return `${cleanUUID(rowData?.tracking?.id)}`;
      } else {
        return "Sin código";
      }
    },
  },
  {
    title: "Fecha de vinculación",
    field: "asset.qrLinkedAt",
    render: (rowData) => {
      if (rowData?.tracking?._lastChangedAt) {
        return `${dayjs(rowData?.tracking?._lastChangedAt).format("DD/MM/YYYY HH:mm")}`;
      } else {
        return "";
      }
    },
  },
];

const TABLE_COLUMNS_MOBILE = [
  {
    title: "",
    field: "asset.id",
    render: (rowData) => {
      return (
        <Grid container spacing={2}>
          <Grid item sm={12} xs={12}>
            <strong>Código Material: </strong>
            {rowData?.material?.code}
          </Grid>
          <Grid item sm={12} xs={12}>
            <strong>Nombre: </strong>
            {rowData?.material?.name}
          </Grid>
          <Grid item sm={12} xs={12}>
            <strong>Serie: </strong>
            {rowData?.serial === "DEFAULT_SERIE" ? "Sin serie" : rowData?.serial}
          </Grid>
          <Grid item sm={12} xs={12}>
            <strong>Vinculado a QR: </strong>
            {rowData?.tracking ? "Código vinculado" : "Sin código"}
          </Grid>
          <Grid item sm={12} xs={12}>
            <strong>Fecha de vinculación: </strong>
            {rowData?.tracking?._lastChangedAt ? dayjs(rowData?.asset?.qrLinkedAt).format("DD/MM/YYYY HH:mm") : ""}
          </Grid>
        </Grid>
      );
    },
  },
];

const useStyles = makeStyles((theme) => ({
  detailsContent: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  username: {
    "& > *": {
      marginRight: theme.spacing(2),
    },
  },
  roleControl: {
    minWidth: 200,
    marginTop: theme.spacing(2),
  },
  root: {
    [theme.breakpoints.down("sm")]: {
      "& .MuiTableHead-root": {
        display: "none",
      },
      "& .MuiToolbar-root": {
        flexDirection: "column",
        padding: theme.spacing(2),
      },
      "& div[class*='MTableToolbar-actions-']": {
        order: 2,
        textAlign: "right",
        width: "100%",
      },
      "& div[class*='MTableToolbar-title-']": {
        order: 1,
        textAlign: "left",
        width: "100%",
      },
      "& div[class*='MTableToolbar-searchField-']": {
        width: "100%",
        order: 0,
        marginBottom: theme.spacing(2),
      },
    },
  },
  codeReaderPanel: {
    backgroundColor: "white",
    overflow: "clip",
    display: "relative",
  },
}));
