import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import useOnlineStatus from "@rehooks/online-status";
import dayjs from "dayjs";

import MaterialTable from "components/custom/MaterialTable";
import Section from "components/Section";
import TableCellMobileFormat from "components/custom/TableCellMobileFormat";

import cleanUUID from "util/cleanUUID";
import exportCsv from "util/exportCsv";
import { handleGetName, handleGetMaterialCode, handleGetUOMname, handleGetMaterialQuantity } from "util/text";

import useTableMobileStyles from "hooks/useTableMobileStyles";

import maintenanceStyles from "../../MaintenanceStyles";

export default function MaintenanceDetailMaterial(props) {
  /***** estado *****/
  const { maintenanceState, loading } = props;
  const { materials } = maintenanceState;

  const isOnline = useOnlineStatus();
  const { id: taskId } = useParams();
  const cleanedID = cleanUUID(taskId);

  const classes = maintenanceStyles();
  const tableMobileStyles = useTableMobileStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const matches1024 = useMediaQuery(theme.breakpoints.down(1024));

  return (
    <Box mb={2} className={classes.sectionSpacing}>
      <Section>
        <div id="detail-view-materials-table" className={tableMobileStyles.root}>
          <MaterialTable
            title="Materiales"
            columns={matches ? COLUMNS_MATERIALS_MOBILE : COLUMNS_MATERIALS}
            data={materials}
            isLoading={loading}
            options={{
              columnsButton: matches1024 ? false : true,

              exportButton: Boolean(materials?.length) && isOnline,
              exportCsv: (columnList, initialData) =>
                exportCsv(
                  COLUMNS_MATERIALS,
                  initialData,
                  `Tabla_materiales-${cleanedID}-${dayjs().format("YYYY-MM-DD-HH:mm")}`
                ),
            }}
          />
        </div>
      </Section>
    </Box>
  );
}

const COLUMNS_MATERIALS = [
  {
    title: "CÓDIGO DE MATERIAL",
    field: "asset.material.code",
    render: handleGetMaterialCode,
  },
  {
    title: "NOMBRE",
    field: "asset.material.name",
    defaultSort: "desc",
    render: handleGetName,
  },
  {
    title: "UNIDAD DE MEDIDA",
    field: "asset.uom.name",
    defaultSort: "desc",
    render: handleGetUOMname,
  },
  {
    title: "CANTIDAD PROGRAMADA",
    field: "asset.quantity",
    defaultSort: "desc",
    render: handleGetMaterialQuantity,
  },
];

const COLUMNS_MATERIALS_MOBILE = [
  {
    title: "",
    field: "asset.state",
    render: (rowData) => {
      const columns = [
        { title: "Código de material", description: handleGetMaterialCode(rowData) },
        { title: "Nombre", description: handleGetName(rowData) },
        { title: "Unidad de medida", description: handleGetUOMname(rowData) },
        { title: "Cantidad programada", description: handleGetMaterialQuantity(rowData) },
      ];
      return <TableCellMobileFormat columns={columns} xs={6} />;
    },
    customFilterAndSearch: (value, rowData) => {
      const parsedValue = value?.toLowerCase();

      return (
        handleGetMaterialCode(rowData).toLowerCase().includes(parsedValue) ||
        handleGetName(rowData).toLowerCase().includes(parsedValue) ||
        handleGetUOMname(rowData).toLowerCase().includes(parsedValue) ||
        handleGetMaterialQuantity(rowData).toString().toLowerCase().includes(parsedValue)
      );
    },
  },
];

MaintenanceDetailMaterial.propTypes = {
  maintenanceState: PropTypes.object,
  loading: PropTypes.bool,
};
