import { downloadFile } from "util/file";
import { Logger } from "@aws-amplify/core";
const logger = new Logger("getS3EvidencesFiles");

export default async function getS3EvidencesFiles(
  attachments,
  { download = false, withAwsUrl = true, onError = () => {} } = {}
) {
  return Promise.all(
    attachments.map(async (attachment, i) => {
      logger.log("obteniendo evidencia con el key:", attachment.file.key);
      let result = {
        ...attachment,
        awsSource: attachment.awsSource || null,
        sourceBlob: attachment.sourceBlob || null,
        uploaded: true,
      };

      if (withAwsUrl) {
        const awsSource = await downloadFile(attachment.file.key).catch((error) => onError(error, attachment));
        result.awsSource = awsSource;
      }

      if (download) {
        const { Body } = await downloadFile(attachment.file.key, { download }).catch((error) =>
          onError(error, attachment)
        );
        result.sourceBlob = Body;
      }

      return result;
    })
  ).catch((error) => {
    logger.error("Error en getS3Files: Al obtener imágenes de evidencias", error);
  });
}
