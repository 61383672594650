import React from "react";
import PropTypes from "prop-types";
import { Box, IconButton } from "@material-ui/core";
import PlayIcon from "@material-ui/icons/PlayCircleFilled";
import PauseIcon from "@material-ui/icons/PauseCircleOutline";
import useStyles from "./styles";

// import { Logger } from "@aws-amplify/core";
import { COUNTER_STATUS } from "../constants";
import { handleButtonAriaLabel } from "../helpers";

import { useSelector } from "react-redux";

// const logger = new Logger("TimerToggle");

const TimerToggle = ({ status, CountdownComponent, onButtonClick, emailUserTask }) => {
  const classes = useStyles();
  const disabled = status === COUNTER_STATUS.STOPPED;
  const iconButtonAriaLabel = handleButtonAriaLabel(status);

  //Get auth user -> use to validate actions
  const authUser = useSelector(({ session }) => session);

  return (
    <Box className={classes.container}>
      {CountdownComponent}
      {emailUserTask === authUser?.username && (
        <IconButton
          aria-label={iconButtonAriaLabel}
          size="medium"
          color="primary"
          onClick={onButtonClick}
          className={classes.iconButton}
          disabled={disabled}
        >
          {status === COUNTER_STATUS.RUNNING ? (
            <PauseIcon className={classes.icon} />
          ) : (
            <PlayIcon className={classes.icon} />
          )}
        </IconButton>
      )}
    </Box>
  );
};

TimerToggle.propTypes = {
  status: PropTypes.string.isRequired,
  onStart: PropTypes.func,
  onResume: PropTypes.func,
  onPause: PropTypes.func,
  CountdownComponent: PropTypes.element,
  onButtonClick: PropTypes.func,
  emailUserTask: PropTypes.string,
};

TimerToggle.defaultProps = {
  status: COUNTER_STATUS.PAUSED,
  onStart: () => {},
  onResume: () => {},
  onPause: () => {},
  onButtonClick: () => {},
};

export default TimerToggle;
