import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useAuthenticator, Authenticator, CheckboxField } from "@aws-amplify/ui-react";
import LegalBoxModal from "components/LegalBox";
import { FormFooter, RegisterPrivacyStripe, TextActionLink, ErrorAlert } from "./MicroComponents";
import {
  CSS_SELECTOR_CLASSNAME,
  GRANTED_AGREEMENT_COGINITO_CUSTOM_ATTRIBUTE,
  EMAIL_INPUT,
  PHONE_INPUT,
  PHONE_COUNTRY_CODE,
  INPUT_CONTROLLED_CLASS,
  INPUT_FIELD_ERROR_CLASS,
  REGISTER_SUBMIT_BUTTON_ID,
} from "./constants";
import {
  addClassNameToSelectors,
  removeClassNameToSelectors,
  addClassToElements,
  removeClassToElements,
} from "util/DOMManipulation";
import { getValidationErros } from "./helpers";
import useDebounce from "hooks/useDebounce";
import styles from "./styles/register.module.css";
// import { Logger } from "aws-amplify";
// const logger = new Logger("RegisterCustom");
const LEGAL_BOX_DATA_DEFAULT = {
  showModal: false,
  tabToShow: 0,
};

const SHOW_VALIDATION_ERRORS_DEFAULT = { email: false, phone_number: false };
const DEBOUNCE_ERROR_MS = 1000;

const actionLinkProps = {
  text: "¿Ya estás registrado?",
  children: "Inicia sesión",
  textDecoration: "none",
  title: "Mover a la sección de inicio de sesión",
};

const handleSetShowErrors = (currentValues, authProps) => {
  const emailErrosMsg = authProps.validationErrors.email || false;
  const phoneErrosMsg = authProps.validationErrors.phone_number || false;
  if (authProps.hasValidationErrors && (emailErrosMsg || phoneErrosMsg)) {
    if (emailErrosMsg && phoneErrosMsg) {
      addClassToElements([EMAIL_INPUT, PHONE_INPUT, PHONE_COUNTRY_CODE], INPUT_FIELD_ERROR_CLASS);
      return { email: emailErrosMsg, phone_number: phoneErrosMsg };
    }
    if (emailErrosMsg) {
      addClassToElements([EMAIL_INPUT], INPUT_FIELD_ERROR_CLASS);
      return { ...currentValues, email: emailErrosMsg };
    } else {
      removeClassToElements([EMAIL_INPUT], INPUT_FIELD_ERROR_CLASS);
    }
    if (phoneErrosMsg) {
      addClassToElements([PHONE_INPUT, PHONE_COUNTRY_CODE], INPUT_FIELD_ERROR_CLASS);
      return { ...currentValues, phone_number: phoneErrosMsg };
    } else {
      removeClassToElements([PHONE_INPUT, PHONE_COUNTRY_CODE], INPUT_FIELD_ERROR_CLASS);
    }
  } else {
    removeClassToElements([EMAIL_INPUT, PHONE_INPUT, PHONE_COUNTRY_CODE], INPUT_FIELD_ERROR_CLASS);
    return SHOW_VALIDATION_ERRORS_DEFAULT;
  }
};

const RegisterFormFields = () => {
  //---States---
  const [legalBoxModalData, setLegalBoxModalData] = useState(LEGAL_BOX_DATA_DEFAULT);
  const [agreementsChecked, setAgreementsChecked] = useState(false);
  const [isGrantedByModal, setIsGrantedByModal] = useState(false);
  const [showValidationErrors, setShowValidationErrors] = useState(SHOW_VALIDATION_ERRORS_DEFAULT);

  //refs
  const emailFieldRef = useRef(null);
  const phoneNumberFieldRef = useRef(null);

  //constants
  const emailFieldValue = emailFieldRef.current ? emailFieldRef.current.value : null;
  const phoneNumberFieldValue = phoneNumberFieldRef.current ? phoneNumberFieldRef.current.value : null;
  const debounceErrors = emailFieldValue || phoneNumberFieldValue ? DEBOUNCE_ERROR_MS : 0;
  const authProps = useAuthenticator((context) => [context.route, context.error, context.hasValidationErrors]);
  const { hasAllFieldsEmpty, hasEmptyFields } = getValidationErros(authProps.validationErrors);
  const isSubmitDisabled = hasAllFieldsEmpty || hasEmptyFields || !emailFieldValue || authProps.hasValidationErrors;
  const enableShowLegalboxModal =
    !agreementsChecked && !hasEmptyFields && !hasAllFieldsEmpty && emailFieldValue && !authProps.hasValidationErrors;
  const submitButtonProps = {
    onClick: onSubmitAttempt,
    disabled: isSubmitDisabled,
  };
  const checkboxLabel = (
    <TextActionLink
      text="He leído y comprendido los"
      onClick={() => openLegalBoxModal(1)}
      title="Mostrar términos y condiciones de Indika360"
    >
      términos y condiciones
    </TextActionLink>
  );

  //---Effects / hooks---
  const _handleSetShowErrors = () =>
    setShowValidationErrors((currentValues) => handleSetShowErrors(currentValues, authProps));
  useDebounce(_handleSetShowErrors, debounceErrors, [emailFieldValue, phoneNumberFieldValue]);

  useLayoutEffect(() => {
    //añadimos estilos a los siguientes elementos
    addClassNameToSelectors(styles, CSS_SELECTOR_CLASSNAME);
    addClassToElements([EMAIL_INPUT, PHONE_INPUT, PHONE_COUNTRY_CODE], INPUT_CONTROLLED_CLASS);
    //referencias a campos que gestionamos errores
    emailFieldRef.current = document.querySelector(EMAIL_INPUT);
    phoneNumberFieldRef.current = document.querySelector(PHONE_INPUT);
    return () => {
      //removemos los estilos a los siguientes elementos
      removeClassNameToSelectors(styles, CSS_SELECTOR_CLASSNAME);
      removeClassToElements([EMAIL_INPUT, PHONE_INPUT, PHONE_COUNTRY_CODE], INPUT_CONTROLLED_CLASS);
    };
  }, []);

  useEffect(() => {
    if (authProps.error) {
      setIsGrantedByModal(false);
      onCloseLegalBoxModal();
    }
  }, [authProps.error]);

  useEffect(() => {
    if (isGrantedByModal && !hasEmptyFields && agreementsChecked) {
      onCloseLegalBoxModal();
      submitProgramatically();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGrantedByModal]);

  //---Handlers---
  const openLegalBoxModal = (tabToShow, props) => setLegalBoxModalData({ showModal: true, tabToShow, ...props });
  const onCloseLegalBoxModal = () => setLegalBoxModalData(LEGAL_BOX_DATA_DEFAULT);
  const handleCheckboxOnChange = (event) => setAgreementsChecked(event.target.checked);
  const submitProgramatically = () => document.getElementById(REGISTER_SUBMIT_BUTTON_ID).click();
  const onGrantAgreements = () => {
    setAgreementsChecked(true);
    setIsGrantedByModal(true);
  };

  function onSubmitAttempt() {
    if (enableShowLegalboxModal) {
      openLegalBoxModal(0, { showFooter: true, onGrantAgreements });
    } else {
      submitProgramatically();
    }
  }

  return (
    <>
      {/* Reutilizar los fields definidos en las constantes */}
      <Authenticator.SignUp.FormFields />

      {/* Añade campo de términos y condiciones al formulario  */}
      <CheckboxField
        name={GRANTED_AGREEMENT_COGINITO_CUSTOM_ATTRIBUTE}
        id={GRANTED_AGREEMENT_COGINITO_CUSTOM_ATTRIBUTE}
        value={agreementsChecked}
        checked={agreementsChecked}
        onChange={handleCheckboxOnChange}
        label={checkboxLabel}
        size="large"
        className={styles.checkbox}
        isRequired
        onInvalid={(event) => event.preventDefault()} //oculta pop-up de campo requerido
      />
      {showValidationErrors.email && <ErrorAlert>{showValidationErrors.email}</ErrorAlert>}
      {showValidationErrors.phone_number && <ErrorAlert>{showValidationErrors.phone_number}</ErrorAlert>}
      <RegisterPrivacyStripe action={() => openLegalBoxModal(0)} />
      <FormFooter
        authProps={authProps}
        textActionLinkProps={{ action: authProps.toSignIn, ...actionLinkProps }}
        buttonProps={submitButtonProps}
      />
      <LegalBoxModal setShowModalTerms={onCloseLegalBoxModal} showFooter={false} {...legalBoxModalData} />
    </>
  );
};

export default RegisterFormFields;
