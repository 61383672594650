import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { useTheme } from "@material-ui/core/styles";
import { useForm, Controller } from "react-hook-form";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";
import MaterialTable from "components/custom/MaterialTable";
import ConfirmationDialog from "components/ConfirmationDialog";
import useNotifier from "hooks/useNotifier";
import useLoadingStatus from "hooks/useLoadingStatus";
import { filterDeletedItems, filterDeletedOrDisabledItems } from "util/lists";
import * as graphql from "./helpers/graphql";
import CustomerProjectSelector from "components/CustomerProjectSelector";
import { FORM_MODES } from "constant/formModes";
import { resolveDeliveryPremises } from "util/destinationDetails";

function DeliveryScheduleDialog({ open, delivery, deliveryAssets, onClose, onScheduleDelivery, onUpdateDelivery }) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const rdxUserId = useSelector((store) => store.session.userId);
  const [formMode, setFormMode] = useState(FORM_MODES.CREATE);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [premises, setPremises] = useState([]);
  const [users, setUsers] = useState([]);
  const [assets, setAssets] = useState([]);
  const [buildingLocations, setBuildingLocations] = useState([]);
  const { showMessage, showError } = useNotifier();
  const { control, getValues, trigger, errors, reset, watch, setValue } = useForm({
    mode: "onChange",
    defaultValues: FORM_DEFAULT_VALUES,
  });
  const selectedPremiseId = watch("premise");
  const [selectedProjectId, setSelectedProjectId] = useState("");
  const [creatingOrUpdating, _scheduleOrUpdateDelivery] = useLoadingStatus(scheduleOrUpdateDelivery);

  useEffect(() => {
    if (open) {
      fetchAssets();
    }
    if (open && delivery) {
      setFormMode(FORM_MODES.UPDATE);
      setValue("dateTime", `${delivery.startDate}T${delivery.startTime}`);
      setValue("premise", resolveDeliveryPremises(delivery).id);
      setValue("recipient", delivery.userTask?.user?.id);
      setValue("comments", delivery.remark?.remark || "");
      const initSelectedAssets = deliveryAssets.map((asset) => ({ ...asset, tableData: { checked: true } }));
      setSelectedAssets([...initSelectedAssets]);
      setAssets((assets) => [...initSelectedAssets, ...assets]);
    }
  }, [open, delivery, deliveryAssets, setValue]);

  useEffect(() => {
    (async function () {
      const premisesData = await graphql.findPremises();
      const usersData = await graphql.findUsersByRole();
      const filteredPremises = filterDeletedItems(premisesData);
      const filteredUsers = filterDeletedOrDisabledItems(usersData);
      setPremises(filteredPremises);
      setUsers(filteredUsers);
    })();
  }, []);

  useEffect(() => {
    if (selectedPremiseId) {
      (async function () {
        const locationsData = await graphql.findBuildingLocationsByPremise(selectedPremiseId);
        const filteredItems = filterDeletedItems(locationsData);
        setBuildingLocations(filteredItems);
        setValue("location", "");
      })();
    }
  }, [selectedPremiseId, setValue]);

  async function fetchAssets() {
    const assetsData = await graphql.findAssets();
    setAssets((assets) => [...assets, ...assetsData]);
  }

  async function validateDelivery() {
    const validForm = await trigger();
    if (!validForm || !selectedAssets.length) {
      return showError("Verifique se hayan llenado los campos correctamente y que se haya seleccionado algún activo.");
    }
    setShowConfirmation(true);
  }

  async function scheduleOrUpdateDelivery() {
    const formValues = getValues();
    const [date, time] = formValues.dateTime.split("T");
    const comments = formValues.comments.slice();
    const recipient = formValues.recipient;
    formValues.date = date;
    formValues.time = time;
    formValues.supervisorId = rdxUserId;
    delete formValues.dateTime;
    delete formValues.comments;
    delete formValues.recipient;
    try {
      if (formMode === FORM_MODES.CREATE) {
        const {
          data: { createTask, ...createAssets },
        } = await graphql.createShipment(formValues, selectedAssets, selectedProjectId);
        const newAssets = Object.keys(createAssets).map((key) => createAssets[key]);
        await graphql.createShipmentRelations(createTask, comments, newAssets, recipient);
        createTask.user = users.find((u) => u.id === recipient);
        onScheduleDelivery(createTask, comments, newAssets);
      } else if (formMode === FORM_MODES.UPDATE) {
        const newValues = { ...formValues, id: delivery.id, _version: delivery._version };
        const newRecipient = users.find((u) => u.id === recipient);
        const { updateTask } = await graphql.updateShipment(newValues, selectedProjectId);
        const { updateRemark } = await graphql.patchRemark({ ...delivery.remark, remark: comments });
        const patchedRecipient = await graphql.patchShipmentRecipient(newRecipient, delivery.userTask, delivery.id);
        const updatedAssets = await graphql.updateShipmentAssets(deliveryAssets, selectedAssets, delivery.id);
        onUpdateDelivery(updateTask, updateRemark, patchedRecipient, updatedAssets);
      }
      onClose();
      showMessage(DIALOG_TEXT[formMode].successMsg);
      setShowConfirmation(false);
      setSelectedAssets([]);
    } catch (error) {
      console.error(error);
      showError(DIALOG_TEXT[formMode].errorMsg);
    }
  }

  function changeAssetsSelection(rows, ...any) {
    setSelectedAssets([...rows]);
  }

  function cleanFormAndAssets() {
    reset();
    setSelectedAssets([]);
    setAssets([]);
  }

  function renderPremisesOptions() {
    if (premises.length) {
      return premises.map(({ id, name, address = {} }) => {
        const { street = "", number = "", zipcode = "" } = address || {};
        const addressStr = `${street} ${number}, ${zipcode}`;
        return (
          <MenuItem key={id} value={id}>
            <Tooltip title={addressStr} arrow>
              <Typography>{name}</Typography>
            </Tooltip>
          </MenuItem>
        );
      });
    }
    return null;
  }

  function renderBuildingLocationOptions() {
    if (buildingLocations.length) {
      return buildingLocations.map(({ id, name, code }) => (
        <MenuItem key={id} value={id}>
          <Tooltip title={code} arrow>
            <Typography>{name}</Typography>
          </Tooltip>
        </MenuItem>
      ));
    }
    return null;
  }

  function renderUserOptions() {
    if (users.length) {
      return users.map(({ id, name, lastnameP = "", email = "" }) => (
        <MenuItem key={id} value={id}>
          <Tooltip title={email} arrow>
            <Typography>{`${name} ${lastnameP} (${email})`}</Typography>
          </Tooltip>
        </MenuItem>
      ));
    }
    return null;
  }

  return (
    <>
      <Dialog
        fullScreen={isMobileScreen}
        fullWidth={true}
        maxWidth="xl"
        open={open}
        onClose={onClose}
        onExited={cleanFormAndAssets}
      >
        <DialogTitle id="max-width-dialog-title">{DIALOG_TEXT[formMode].title}</DialogTitle>
        <DialogContent>
          <Box>
            <Typography variant="h6">Indicaciones:</Typography>
            <ol>
              <li>Verifique los datos del envío.</li>
              <li>Opcionalmente, selecione un proyecto para asociar el envío.</li>
              <li>Seleccione los activos incluidos en el envío.</li>
              <li>Revise y confirme si la información es correcta.</li>
            </ol>
            <p>
              <strong>Importante: </strong> Sólo los activos seleccionados se registrarán como enviados.
            </p>
          </Box>
          <Box mt={3}>
            <Typography variant="h6">Datos del envío</Typography>
            <Grid container spacing={2} mt={2}>
              <Grid item xs={12} md={3}>
                <Controller
                  id="datetime-delivery"
                  control={control}
                  as={TextField}
                  name="dateTime"
                  type="datetime-local"
                  label="Fecha y hora"
                  rules={FORM_RULES.dateTime}
                  error={!!errors.dateTime}
                  helperText={errors.dateTime?.message}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Controller
                  control={control}
                  as={TextField}
                  name="premise"
                  label="Lugar de destino"
                  rules={FORM_RULES.premise}
                  error={!!errors.premise}
                  helperText={errors.premise?.message}
                  select
                  fullWidth
                  required
                >
                  {renderPremisesOptions()}
                </Controller>
              </Grid>
              {/* <Grid item xs={12} md={3}>
                <Controller
                  control={control}
                  as={TextField}
                  name="location"
                  label="Zona de destino"
                  rules={FORM_RULES.location}
                  error={!!errors.location}
                  helperText={errors.location?.message}
                  select
                  fullWidth
                  required
                >
                  {renderBuildingLocationOptions()}
                </Controller>
              </Grid> */}
              <Grid item xs={12} md={3}>
                <Controller
                  control={control}
                  as={TextField}
                  name="recipient"
                  label="Destinatario"
                  rules={FORM_RULES.recipient}
                  error={!!errors.recipient}
                  helperText={errors.recipient?.message}
                  select
                  fullWidth
                >
                  {renderUserOptions()}
                </Controller>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  as={TextField}
                  name="comments"
                  label="Comentarios"
                  variant="outlined"
                  rows={4}
                  fullWidth
                  multiline
                  rules={FORM_RULES.comments}
                  error={!!errors.comments}
                  helperText={errors.comments?.message}
                />
              </Grid>
            </Grid>
          </Box>
          <CustomerProjectSelector
            customerId={delivery?.project?.company?.id}
            projectId={delivery?.project?.id}
            onChangeProject={setSelectedProjectId}
            boxProps={{ my: 3 }}
          />
          <Box mt={3}>
            <div className={classes.root}>
              <MaterialTable
                title="Activos envíados"
                options={TABLE_OPTIONS}
                columns={isMobileScreen ? TABLE_COLUMNS_MOBILE : TABLE_COLUMNS_DESKTOP}
                onSelectionChange={changeAssetsSelection}
                isLoading={false}
                data={assets}
              />
            </div>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="default">
            Cerrar
          </Button>
          <Button color="primary" onClick={validateDelivery}>
            {DIALOG_TEXT[formMode].confirmBtn}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        open={showConfirmation}
        title="Confirmación"
        onConfirm={_scheduleOrUpdateDelivery}
        onCancel={() => setShowConfirmation(false)}
        loading={creatingOrUpdating}
      >
        <Typography paragraph>{DIALOG_TEXT[formMode].confirmationMsg}</Typography>
        <Typography>¿Desea continuar?.</Typography>
      </ConfirmationDialog>
    </>
  );
}

DeliveryScheduleDialog.propTypes = {
  open: PropTypes.bool,
  delivery: PropTypes.object,
  deliveryAssets: PropTypes.array,
  onClose: PropTypes.func,
  onScheduleDelivery: PropTypes.func,
  onUpdateDelivery: PropTypes.func,
};

DeliveryScheduleDialog.defaultProps = {
  open: false,
  delivery: null,
  deliveryAssets: [],
  onClose: () => {},
  onScheduleDelivery: () => {},
  onUpdateDelivery: () => {},
};

const DIALOG_TEXT = {
  [FORM_MODES.CREATE]: {
    title: "Programación de envío",
    confirmBtn: "Programar",
    confirmationMsg: "Está a punto de programar el envío de activos.",
    successMsg: "Envío programado exitosamente.",
    errorMsg: "Ocurrió un error durante la programación del envío. Intente nuevamente.",
  },
  [FORM_MODES.UPDATE]: {
    title: "Actualización de Envío",
    confirmBtn: "Actualizar",
    confirmationMsg: "Estás a punto de actualizar el envío de activos",
    successMsg: "Envío actualizado con éxito",
    errorMsg: "Ocurrió un error durante la actualización del envío, intente nuevamente.",
  },
};

const TABLE_OPTIONS = {
  exportButton: false,
  columnsButton: false,
  selection: true,
};

const TABLE_COLUMNS_DESKTOP = [
  { title: "Código Material", field: "code" },
  { title: "Nombre", field: "material.name" },
  {
    title: "Serie",
    field: "serial",
    render: (rowData) => (rowData?.serial === "DEFAULT_SERIE" ? "Sin serie" : rowData?.serial || "S/I"),
  },
];

/* eslint-disable react/display-name */
const TABLE_COLUMNS_MOBILE = [
  {
    title: "",
    field: "asset.id",
    render: (rowData) => {
      return (
        <Grid container>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <strong>Código Material: </strong>
                {rowData.code}
              </Grid>
              <Grid item xs={12}>
                <strong>Nombre: </strong>
                {rowData.material.name}
              </Grid>
              <Grid item xs={12}>
                <strong>Serie: </strong>
                {rowData?.serial === "DEFAULT_SERIE" ? "Sin serie" : rowData?.serial}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    },
  },
];

const FORM_RULES = {
  dateTime: {
    required: "Campo obligatorio",
  },
  premise: {
    required: "Campo obligatorio",
  },
  // location: {
  //   required: "Campo obligatorio",
  // },
  recipient: {
    required: "Campo obligatorio",
  },
  comments: {
    maxLength: { value: 300, message: "Máximo 300 caracteres" },
  },
};

const FORM_DEFAULT_VALUES = {
  dateTime: "",
  premise: "",
  location: "",
  recipient: "",
  comments: "",
};

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      "& .MuiTableHead-root": {
        display: "none",
      },
      "& .MuiToolbar-root": {
        flexDirection: "column",
        padding: theme.spacing(2),
      },
      "& div[class*='MTableToolbar-actions-']": {
        order: 2,
        textAlign: "right",
        width: "100%",
      },
      "& div[class*='MTableToolbar-title-']": {
        order: 1,
        textAlign: "left",
        width: "100%",
      },
      "& div[class*='MTableToolbar-searchField-']": {
        width: "100%",
        order: 0,
        marginBottom: theme.spacing(2),
      },
    },
  },
}));

export default DeliveryScheduleDialog;
