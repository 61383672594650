const listLegalDocuments = /* GraphQL */ `
  query ListLegalDocuments {
    listLegalDocuments {
      items {
        id
        effectiveDate
        status
        type
        name
        expirationDate
        createdAt
        description
        updatedAt
        version
        content {
          bucket
          key
          region
        }
        _deleted
        _lastChangedAt
        _version
      }
    }
  }
`;
export default listLegalDocuments;