const updateTaskType = /* GraphQL */ `
  mutation UpdateTaskType($id: ID!, $name: String!, $description: String!, $_version: Int!) {
    updateTaskType(input: { id: $id, name: $name, description: $description, _version: $_version }) {
      id
      name
      description
      createdAt
      category
      updatedAt
      _version
    }
  }
`;

export default updateTaskType;
