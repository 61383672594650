import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Device } from "@capacitor/device";
import { CODE_READER_STATUS } from "constant/codeReaderStatus";
import CodeReader from "components/common/CodeReader";
import BarcodeScanner from "components/common/BarcodeScanner";

export default function CodeScanner({
  open,
  title,
  onCodeDetected,
  onClose,
  status,
  bottomPanel,
  showPreviewPanel,
  onRestartReading,
}) {
  const [devicePlatform, setDevicePlatform] = useState("");

  useEffect(() => {
    Device.getInfo().then((info) => {
      setDevicePlatform(info.platform);
    });
  }, []);

  if (devicePlatform === "web") {
    return (
      <CodeReader
        open={open}
        status={status}
        title={title}
        onClose={onClose}
        onCodeDetected={onCodeDetected}
        bottomPanel={bottomPanel}
      />
    );
  } else if (devicePlatform === "android" || devicePlatform === "ios") {
    return (
      <BarcodeScanner
        open={open}
        title={title}
        onClose={onClose}
        onCodeDetected={onCodeDetected}
        bottomPanel={bottomPanel}
        showPreviewPanel={showPreviewPanel}
        onRestartReading={onRestartReading}
      />
    );
  } else {
    return null;
  }
}

CodeScanner.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  onCodeDetected: PropTypes.func,
  onClose: PropTypes.func,
  status: PropTypes.oneOf([CODE_READER_STATUS.START, CODE_READER_STATUS.STOP]),
  bottomPanel: PropTypes.element,
  showPreviewPanel: PropTypes.bool,
  onRestartReading: PropTypes.func,
};

CodeScanner.defaultProps = {
  open: false,
  title: "",
  onCodeDetected: () => {},
  onClose: () => {},
  status: CODE_READER_STATUS.STOP,
  bottomPanel: null,
  showPreviewPanel: false,
  onRestartReading: () => {},
};
