export const ROOT = "/sitio";

export const SURGERIES_ROOT = `${ROOT}/cirugias`;
export const MY_SURGERIES_ROOT = `${ROOT}/mis-cirugias`;
export const ASSETS_ROOT = `${ROOT}/activos`;
export const INSTALLATIONS_ROOT = `${ROOT}/instalaciones`;
/**
 * Urls para la sección de programación de cirugias
 */
export const PENDING_SURGERIES = `${SURGERIES_ROOT}/pendientes`;
export const PAST_SURGERIES = `${SURGERIES_ROOT}/pasadas`;
export const CANCELED_SURGERIES = `${SURGERIES_ROOT}/canceladas`;
export const FINISHED_SURGERIES = `${SURGERIES_ROOT}/finalizadas`;
export const VALIDATING_SURGERIES = `${SURGERIES_ROOT}/validacion`;
export const SURGERY_DETAIL = `${SURGERIES_ROOT}/:surgeryId`;
/**
 * Urls para la sección de visualización de sirugias por parte de un usuario especifico
 */
export const MY_PENDING_SURGERIES = `${MY_SURGERIES_ROOT}/pendientes`;
export const MY_PAST_SURGERIES = `${MY_SURGERIES_ROOT}/pasadas`;
export const MY_CANCELED_SURGERIES = `${MY_SURGERIES_ROOT}/canceladas`;
export const MY_FINISHED_SURGERIES = `${MY_SURGERIES_ROOT}/finalizadas`;
export const MY_VALIDATING_SURGERIES = `${MY_SURGERIES_ROOT}/validacion`;
export const MY_SURGERY_DETAIL = `${MY_SURGERIES_ROOT}/:surgeryId`;
/**
 * URls para la ...
 */
export const ASSETS_INSTALLATION = `${ASSETS_ROOT}/instalacion`;
export const ASSETS_TEAM = `${ASSETS_ROOT}/equipo`;
export const ASSETS_INSTALLATION_DETAIL = `${ASSETS_ROOT}/instalacion/:id`;

// export const ASSETS_MAINTENANCE_LIST = `${ASSETS_ROOT}/mantenimientos`;
// export const ASSETS_SCHEDULED_MAINTENANCE_LIST = `${ASSETS_ROOT}/mantenimientos/programados`;
// export const ASSETS_ONGOING_MAINTENANCE_LIST = `${ASSETS_ROOT}/mantenimientos/registrados`;
// export const ASSETS_FINALIZED_MAINTENANCE_LIST = `${ASSETS_ROOT}/mantenimientos/finalizados`;
// export const ASSETS_CANCELED_MAINTENANCE_LIST = `${ASSETS_ROOT}/mantenimientos/cancelados`;
// export const ASSETS_MAINTENANCE_DETAIL = `${ASSETS_ROOT}/mantenimiento`;
// export const ASSETS_MAINTENANCE_DETAIL_WITH_PARAM = `${ASSETS_MAINTENANCE_DETAIL}/:actionId`;

export const INSTALLATIONS_SCHEDULE = `${INSTALLATIONS_ROOT}/programacion`;
export const SITE_DELIVERIES = `${ROOT}/envios`;
export const SITE_DELIVERY_DETAILS = `${ROOT}/envio/:id`;
export const TICKET_ACTIVITY_LIST = `${ROOT}/actividades`;
export const TICKET_ACTIVITY_DETAILS = `${ROOT}/actividades/:id`;

export const SUPPORT = `${ROOT}/soporte`;

export const MAINTENANCE_ROOT = `${ROOT}/mantenimientos`;
export const MAINTENANCE_DETAIL = `${MAINTENANCE_ROOT}/mantenimiento`;
export const MAINTENANCE_DETAIL_WITH_PARAM = `${MAINTENANCE_ROOT}/mantenimiento/:id`;
export const FINALIZED_MAINTENANCE = `${MAINTENANCE_ROOT}/finalizados`;
export const CANCELED_MAINTENANCE = `${MAINTENANCE_ROOT}/cancelados`;

export const SURGERIES = {
  ROOT: SURGERIES_ROOT,
  PENDING: PENDING_SURGERIES,
  PAST: PAST_SURGERIES,
  DETAIL: SURGERY_DETAIL,
  CANCELED: CANCELED_SURGERIES,
  FINISHED: FINISHED_SURGERIES,
  VALIDATING: VALIDATING_SURGERIES,
};

export const MY_SURGERIES = {
  ROOT: MY_SURGERIES_ROOT,
  PENDING: MY_PENDING_SURGERIES,
  PAST: MY_PAST_SURGERIES,
  DETAIL: MY_SURGERY_DETAIL,
  CANCELED: MY_CANCELED_SURGERIES,
  FINISHED: MY_FINISHED_SURGERIES,
  VALIDATING: MY_VALIDATING_SURGERIES,
};

export const ASSETS = {
  ROOT: ASSETS_ROOT,
  INSTALLATION: ASSETS_INSTALLATION,
  TEAM: ASSETS_TEAM,
  DETAIL: ASSETS_INSTALLATION_DETAIL,
  // MAINTENANCE_LIST: ASSETS_MAINTENANCE_LIST,
  // MAINTENANCE_SCHEDULED_LIST: ASSETS_SCHEDULED_MAINTENANCE_LIST,
  // MAINTENANCE_ONGOING_LIST: ASSETS_ONGOING_MAINTENANCE_LIST,
  // MAINTENANCE_FINALIZED_LIST: ASSETS_FINALIZED_MAINTENANCE_LIST,
  // MAINTENANCE_CANCELED_LIST: ASSETS_CANCELED_MAINTENANCE_LIST,
  // MAINTENANCE_DETAIL: ASSETS_MAINTENANCE_DETAIL,
  // MAINTENANCE_DETAIL_WITH_PARAM: ASSETS_MAINTENANCE_DETAIL_WITH_PARAM,
};

export const MAINTENANCE = {
  ROOT: MAINTENANCE_ROOT,
  FINALIZED: FINALIZED_MAINTENANCE,
  CANCELED: CANCELED_MAINTENANCE,
  MAINTENANCE_DETAIL: MAINTENANCE_DETAIL,
  MAINTENANCE_DETAIL_WITH_PARAM: MAINTENANCE_DETAIL_WITH_PARAM,
};

export const INSTALLATIONS = {
  ROOT: INSTALLATIONS_ROOT,
  SCHEDULE: INSTALLATIONS_SCHEDULE,
};

const OPERATION = {
  ROOT,
  SURGERIES,
  MAINTENANCE,
  MY_SURGERIES,
  ASSET: ASSETS,
  INSTALLATIONS,
  SITE_DELIVERIES,
  SITE_DELIVERY_DETAILS,
  SUPPORT,
};

export default OPERATION;
