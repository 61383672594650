export const assignRoleToUser = /* GraphQL */ `
  mutation AssignRoleToUser($roleId: ID!, $userId:ID!) {
    createUserRole(input: {
      userID: $userId,
      roleID: $roleId
    }) {
      id
      createdAt
      role {
        id
        name
        cognitoGroup
      }
      _version
      _deleted
    }
  }
`;