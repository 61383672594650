/* eslint-disable react/display-name */
// Componente para detalle de activos
import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { Logger } from "@aws-amplify/core";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
// material-ui
import AddBoxIcon from "@material-ui/icons/AddBox";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import "react-vertical-timeline-component/style.min.css";
// componentes custom
import Card from "components/custom/Card";
import Section from "components/Section";
import DataLabel from "components/DataLabel";
import DownloadQRButton from "components/common/DownloadQRButton";
import DetailTitle from "components/DetailTitle";
import AttachmentsDialog from "components/common/dialog/AttachmentsDialog";
import AssetAttributesDialog from "components/AssetAttributesDialog";
import AssociateQRDialog from "components/AssociateQRDialog";
import MaterialTable from "components/custom/MaterialTable";
// hooks custom
import useHeaderTitle from "hooks/useHeaderTitle";
import useLoadingStatus from "hooks/useLoadingStatus";
import useBooleanFlag from "hooks/useBooleanFlag";
// helpers
import { WAREHOUSE_ASSETS } from "constant/route/warehouse";
import { TASK_STATUS_MAP } from "constant/task/status";
import { TASK_CATEGORY_TRANSLATION } from "constant/task";
import cleanUUID from "util/cleanUUID";
import * as graphql from "./helpers/graphql";

const SECTION_TITLE = "Activo ";
const logger = new Logger("AssetDetailsView");
const whiteLabelApp = process.env.REACT_APP_WHITE_LABEL_APP;

export default function AssetDetailsView() {
  useHeaderTitle(SECTION_TITLE);
  const [assetDetail, setAssetDetail] = useState({});
  const [history, setHistory] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [isRequestError, setIsRequestError] = useState(false);
  const [isAssociateQRDialogOpen, setIsAssociateQRDialogOpen] = useState(false);
  const [attachmentDialogOpen, openDialog, closeDialog] = useBooleanFlag();
  const [attrsDialogOpen, openAttsDialog, closeAttsDialog] = useBooleanFlag();

  const { id } = useParams();
  const [loading, _fetchAssetsList] = useLoadingStatus(fetchAssetDetail);

  const tableActions = useMemo(
    () => [
      {
        icon: AddBoxIcon,
        tooltip: "Modificar atributos",
        isFreeAction: true,
        onClick: openAttsDialog,
      },
    ],
    [openAttsDialog]
  );

  useEffect(() => {
    _fetchAssetsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchAssetDetail() {
    try {
      const result = await graphql.fetchAssetDetail(id);

      if (!result?.tasks?.items?.length) {
        logger.warn("fetchAssetDetail: El activo no tiene información histórica asociada");
      } else {
        setHistory(result?.tasks?.items);
      }

      setAssetDetail(result);
    } catch (error) {
      logger.error(error);
      setIsRequestError(true);
    }
  }

  function handleAttachmentBtnClick(attachments) {
    setAttachments(attachments);
    openDialog();
  }

  function handleAssociateQR() {
    setAssetDetail({ ...assetDetail });
    setIsAssociateQRDialogOpen(true);
  }

  function updateAssetTracking(updatedAsset) {
    setAssetDetail({
      ...assetDetail,
      tracking: {
        ...updatedAsset.tracking,
        viewId: cleanUUID(updatedAsset.tracking.id),
      },
    });
  }

  function handleAssetAttrsComplete(assetAttributes) {
    setAssetDetail({
      ...assetDetail,
      attributes: {
        items: [...assetAttributes],
      },
    });
  }

  return (
    <>
      <Section>
        <Card
          header={{
            title: (
              <DetailTitle
                title={`${SECTION_TITLE} ${assetDetail.serial ? assetDetail.serial : ""}`}
                to={WAREHOUSE_ASSETS}
              />
            ),
          }}
        >
          {isRequestError && (
            <Box py={2}>
              <Alert severity="error">
                Ha ocurrido un error con la carga del listado, recarga la página por favor.
              </Alert>
            </Box>
          )}

          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <DataLabel label="No. Serie" data={assetDetail?.serial} loading={loading} />
            </Grid>
            <Grid item xs={12} sm={3}>
              <DataLabel label="Nombre" data={assetDetail?.material?.name} loading={loading} />
            </Grid>
            <Grid item xs={12} sm={3}>
              <DataLabel label="SKU" data={assetDetail?.code} loading={loading} />
            </Grid>
            <Grid item xs={12} sm={3}>
              <DataLabel label="Fecha de registro" data={assetDetail?.createdAt} loading={loading} />
            </Grid>
            
            {/* 
            Se oculto a petición de Javier DE SFT el 7 de junio de 2023
            <Grid item xs={12} sm={3}>
              <DataLabel
                label="Categoría del artículo"
                data={assetDetail?.material?.category?.name}
                loading={loading}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <DataLabel label="Unidad de Medida" data={assetDetail?.material?.uom?.name} loading={loading} />
            </Grid> */} 
            <Grid item xs={12} sm={3}>
              {assetDetail?.tracking?.viewId || (!assetDetail?.tracking?.viewId && loading) ? (
                <DataLabel
                  label="QR Vinculado"
                  data={<DownloadQRButton assetID={assetDetail?.id} content={assetDetail?.tracking?.viewId} />}
                  loading={loading}
                />
              ) : (
                <>
                  <Typography variant="subtitle2" color="textSecondary">
                    <b>QR Vinculado</b>
                  </Typography>
                  <Button color="primary" onClick={handleAssociateQR}>
                    Vincular QR
                  </Button>
                </>
              )}
            </Grid>
            <Grid item xs={12} sm={3}>
              <DataLabel label="Fecha de vinculación" data={assetDetail?.tracking?.createdAt} loading={loading} />
            </Grid>
            <Grid item xs={12} sm={3}>
              <DataLabel label="Cliente" data={assetDetail?.project?.company?.name} loading={loading} />
            </Grid>
            <Grid item xs={12} sm={3}>
              <DataLabel
                label="Proyecto"
                data={
                  assetDetail?.project
                    ? `${assetDetail?.project?.name} (${assetDetail?.project?.code})`
                    : "Sin asignación"
                }
                loading={loading}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <DataLabel
                label="Nomenclatura"
                data={assetDetail?.nomenclature || 'Sin información'}
                loading={loading}
              />
            </Grid>

            {/* Campos solicitados y condicionados a SCJN */}
            {
              whiteLabelApp === 'scjn'
              &&
              <>
                <Grid item xs={12} sm={3}>
                  <DataLabel
                    label="Parte"
                    data={assetDetail?.part || 'Sin información'}
                    loading={loading}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <DataLabel
                    label="Modelo"
                    data={assetDetail?.model || 'Sin información'}
                    loading={loading}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <DataLabel
                    label="Componente"
                    data={assetDetail?.component || 'Sin información'}
                    loading={loading}
                  />
                </Grid>
              </>
            }
            {/* END Campos solicitados y condicionados a SCJN */}
          </Grid>
        </Card>
      </Section>
      <Box mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Section>
              <CardContent>
                <Typography variant="h6">Atributos</Typography>
              </CardContent>
              <MaterialTable
                columns={TABLE_COLUMNS}
                options={{ ...TABLE_OPTIONS }}
                data={assetDetail?.attributes?.items || []}
                isLoading={loading}
                actions={tableActions}
              />
            </Section>
          </Grid>
          <Grid item xs={12} md={8}>
            {!loading && <AssetHistory history={history} onAttachmentBtnClick={handleAttachmentBtnClick} />}
          </Grid>
        </Grid>
      </Box>
      <AssetAttributesDialog
        isOpen={attrsDialogOpen}
        asset={assetDetail}
        onClose={closeAttsDialog}
        onComplete={handleAssetAttrsComplete}
      />
      <AssociateQRDialog
        isOpen={isAssociateQRDialogOpen}
        onClose={() => setIsAssociateQRDialogOpen(false)}
        selectedAsset={assetDetail}
        onAssociate={updateAssetTracking}
      />
      <AttachmentsDialog open={attachmentDialogOpen} attachments={attachments} onClose={closeDialog} />
    </>
  );
}

const TABLE_OPTIONS = {
  exportButton: false,
  columnsButton: false,
  paging: false,
  maxBodyHeight: "300px",
  minBodyHeight: "300px",
};

const TABLE_COLUMNS = [
  { title: "Id", field: "id", hidden: true },
  { title: "Nombre", field: "attribute.name" },
  { title: "Valor", field: "value" },
];

function getPlaceString(buildingLocation = {}) {
  if (!buildingLocation) buildingLocation = {};
  const { premises = {} } = buildingLocation;
  return `${premises.name} (${buildingLocation?.name})`;
}

function getDate(startedAt, endedAt) {
  if (!startedAt) return null;
  return `${dayjs(startedAt).format("DD/MM/YYYY")} - ${dayjs(endedAt).format("DD/MM/YYYY")}`;
}

function AssetHistory(props) {
  if (props.history.length === 0)
    return (
      <Section>
        <Box p={2}>
          <Alert severity="warning">Este activo no cuenta con historial de eventos.</Alert>
        </Box>
      </Section>
    );

  return (
    <VerticalTimeline layout="1-column">
      {props.history.map((event, index) => {
        const { task = {} } = event;
        const { buildingLocation = {} } = task;
        const status = TASK_STATUS_MAP[task.status];
        const place = getPlaceString(buildingLocation);
        const date = getDate(task.startedAt);

        function handleAttachmentBtnClick() {
          props.onAttachmentBtnClick(task.attachments.items);
        }

        return (
          <VerticalTimelineElement key={index} className="vertical-timeline-element--work" date={date}>
            <Typography variant="subtitle1" className="vertical-timeline-element-title">
              <small>{TASK_CATEGORY_TRANSLATION[task?.category]}</small>
            </Typography>

            <Typography variant="h6" className="vertical-timeline-element-title">
              {task.taskType?.name} <small> - {status}</small>
            </Typography>
            <Grid container>
              {buildingLocation && (
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" className="vertical-timeline-element-subtitle" paragraph>
                    {place}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12} sm={6} container>
                <Button variant="outlined" onClick={handleAttachmentBtnClick}>
                  Evidencias
                </Button>
              </Grid>
            </Grid>
          </VerticalTimelineElement>
        );
      })}
    </VerticalTimeline>
  );
}

AssetHistory.propTypes = {
  history: PropTypes.arrayOf(PropTypes.shape()),
  onAttachmentBtnClick: PropTypes.func,
};
