/**
 * Gestiona estado durante una petición asincrona, gestionar estatus, errores, y la información obtenida.
 * 
 * usage:
 * const {
    data,
    status,
    error,
    run,
    setData,
    setError,
  } = useAsync()

  useEffect(() => {
    run(asyncOperation());
  }, [run]);
 */

import { useReducer, useCallback } from "react";
import useSafeDispatch from "hooks/useSafeDispatch";
import { captureException } from "setup/sentry";

export const ASYNC_STATUS = {
  IDLE: "idle",
  PENDING: "pending",
  REJECTED: "rejected",
  RESOLVED: "resolved",
};

export function asyncReducer(state, action) {
  switch (action.type) {
    case ASYNC_STATUS.PENDING: {
      return { status: ASYNC_STATUS.PENDING, data: null, error: null };
    }
    case ASYNC_STATUS.RESOLVED: {
      return { status: ASYNC_STATUS.RESOLVED, data: action.data, error: null };
    }
    case ASYNC_STATUS.REJECTED: {
      return { status: ASYNC_STATUS.REJECTED, data: null, error: action.error };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export default function useAsync(initialState) {
  const [state, unsafeDispatch] = useReducer(asyncReducer, {
    status: ASYNC_STATUS.IDLE,
    data: null,
    error: null,
    ...initialState,
  });

  const dispatch = useSafeDispatch(unsafeDispatch);

  const { data, error, status } = state;

  const run = useCallback(
    (promise) => {
      dispatch({ type: ASYNC_STATUS.PENDING });
      promise.then(
        (data) => {
          dispatch({ type: ASYNC_STATUS.RESOLVED, data });
        },
        (error) => {
          dispatch({ type: ASYNC_STATUS.REJECTED, error });
          captureException(error.message, "hooks/useAsync");
        }
      );
    },
    [dispatch]
  );

  const setData = useCallback((data) => dispatch({ type: ASYNC_STATUS.RESOLVED, data }), [dispatch]);
  const setError = useCallback((error) => dispatch({ type: ASYNC_STATUS.REJECTED, error }), [dispatch]);

  return {
    setData,
    setError,
    error,
    status,
    data,
    run,
    isIdle: status === "idle",
    isLoading: status === "pending",
    isError: status === "rejected",
    isSuccess: status === "resolved",
  };
}
