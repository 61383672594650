import React, { useState } from "react";
import PropTypes from "prop-types";
import { Controller, useForm } from "react-hook-form";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import Checkbox from "@material-ui/core/Checkbox";
import MaterialTable from "components/custom/MaterialTable";
import useLoadingStatus from "hooks/useLoadingStatus";
import { fetchAssetsBySerialOrCode } from "datastore";
import { TrackingStatus } from "models";

function AssetSelectorDialog({ isOpen, onClose, onSelect }) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedAsset, setSelectedAsset] = useState(null);
  const { control, trigger, errors, watch } = useForm({
    mode: "onChange",
    defaultValues: { serial: "", code: "" },
  });
  const [loadingAssets, _fetchAssets] = useLoadingStatus(fetchAssetsBySerialOrCode);
  const [assets, setAssets] = useState([]);
  const serial = watch("serial");
  const code = watch("code");

  async function searchAsset() {
    const validForm = await trigger();
    if (!validForm) {
      return;
    }
    let assetList = await getAssets();
    setAssets(assetList);
  }

  async function getAssets() {
    try {
      const assetsData = await _fetchAssets(serial, code);
      return filterAvailableAssets(assetsData);
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  function filterAvailableAssets(assetList) {
    return assetList.reduce((availableAssets, asset) => {
      if (
        !asset.tracking ||
        asset.tracking.status === TrackingStatus.UNASSIGNED ||
        asset.tracking.id === TrackingStatus.UNASSIGNED
      ) {
        availableAssets.push({ ...asset });
      }
      return availableAssets;
    }, []);
  }

  function changeSelectedAsset(event, row) {
    if (selectedAsset) {
      selectedAsset.selected = false;
    }
    row.selected = true;
    setSelectedAsset(row);
  }

  function confirmSelectedAsset() {
    onSelect({ ...selectedAsset });
    setSelectedAsset(null);
  }

  return (
    <Dialog open={isOpen} fullScreen fullWidth>
      <DialogTitle>Búsqueda de Activo</DialogTitle>
      <DialogContent dividers>
        <Box>
          <DialogContentText>
            <strong>Indicaciones:</strong>
          </DialogContentText>
          <ol>
            <li>Ingrese alguno de los valores buscados y presione el botón buscar.</li>
            <li>Seleccione el material correcto y presione &ldquo;Continuar&rdquo;</li>
          </ol>
        </Box>

        <Box>
          <Controller
            control={control}
            as={TextField}
            rules={FORM_RULES.serial}
            name="serial"
            type="text"
            label="Serie de activo"
            placeholder="Ingrese la serie buscada"
            fullWidth
            error={!!errors.serial}
            helperText={errors.serial?.message}
          />
          <Controller
            control={control}
            as={TextField}
            rules={FORM_RULES.code}
            name="code"
            type="text"
            label="Código de producto"
            placeholder="Ingrese el código del producto buscado"
            fullWidth
            error={!!errors.code}
            helperText={errors.code?.message}
          />
        </Box>
        <Box mt={3}>
          <Button onClick={searchAsset} variant="contained" color="primary" disabled={!serial && !code} fullWidth>
            Buscar
          </Button>
        </Box>
        <Box mt={3}>
          <MaterialTable
            options={TABLE_OPTIONS}
            columns={isMobileScreen ? TABLE_COLUMNS_MOBILE : TABLE_COLUMNS_DESKTOP}
            onRowClick={changeSelectedAsset}
            data={assets}
            isLoading={loadingAssets}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="default">
          CANCELAR
        </Button>
        <Button onClick={confirmSelectedAsset} color="primary" disabled={!selectedAsset}>
          CONTINUAR
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AssetSelectorDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSelect: PropTypes.func,
};

AssetSelectorDialog.defaultProps = {
  isOpen: false,
  onClose: () => {},
  onSelect: () => {},
};

const FORM_RULES = {
  serial: {
    maxLength: { value: 50, message: "Máximo 50 caracteres" },
  },
  code: {
    maxLength: { value: 50, message: "Máximo 50 caracteres" },
  },
};

const TABLE_OPTIONS = {
  exportButton: false,
  columnsButton: false,
  search: false,
};

const TABLE_COLUMNS_DESKTOP = [
  {
    title: "Selección",
    field: "selected",
    /* eslint-disable react/prop-types */
    // eslint-disable-next-line react/display-name
    render: ({ selected }) => <Checkbox checked={selected} inputProps={{ "aria-label": "primary checkbox" }} />,
  },
  {
    title: "Código de material",
    field: "code",
  },
  {
    title: "Nombre",
    field: "material.name",
  },
  {
    title: "Serie",
    field: "serial",
    render: (rowData) => (rowData?.serial === "DEFAULT_SERIE" ? "Sin serie" : rowData?.serial || "S/I"),
  },
];

const TABLE_COLUMNS_MOBILE = [
  {
    title: "Activos Actuales",
    /* eslint-disable react/prop-types */
    // eslint-disable-next-line react/display-name
    render: ({ selected, code, material, serial }) => (
      <Box display="flex">
        <Box mr={2}>
          <Checkbox checked={selected} inputProps={{ "aria-label": "primary checkbox" }} />
        </Box>
        <Box>
          <Typography paragraph>
            <strong>Código de Material: </strong>
            <span>{code}</span>
          </Typography>
          <Typography paragraph>
            <strong>Nombre: </strong>
            <span>{material.name}</span>
          </Typography>
          <Typography paragraph>
            <strong>Serie: </strong>
            <span>{serial === "DEFAULT_SERIE" ? "Sin serie" : serial}</span>
          </Typography>
        </Box>
      </Box>
    ),
  },
];

export default AssetSelectorDialog;
