export const getRoleByCognitoGroup = /* GraphQL */ `
  query GetRoleByCognitoGroup($filter: ModelRoleFilterInput, $limit: Int) {
    listRoles(filter: $filter, limit: $limit) {
      items {
        id
        name
        cognitoGroup
        _deleted
        users {
          items {
            _deleted
            user {
              id
              name
              lastnameP
              email
              status
              _deleted
            }
          }
          nextToken
        }
      }
    }
  }
`;
