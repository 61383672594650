export const schema = {
  models: {
    Asset: {
      name: "Asset",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        // Propiedad customizada. NO REMOVER
        trackingID: {
          name: "trackingID",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
        code: {
          name: "code",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        serial: {
          name: "serial",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        quantity: {
          name: "quantity",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        type: {
          name: "type",
          isArray: false,
          type: {
            enum: "AssetType",
          },
          isRequired: true,
          attributes: [],
        },
        enabled: {
          name: "enabled",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        history: {
          name: "history",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        name: {
          name: "name",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        comment: {
          name: "comment",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        embeddedAttributes: {
          name: "embeddedAttributes",
          isArray: true,
          type: {
            nonModel: "EmbeddedAttribute",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        material: {
          name: "material",
          isArray: false,
          type: {
            model: "Material",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetName: "assetMaterialId",
          },
        },
        locationCode: {
          name: "locationCode",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        tasks: {
          name: "tasks",
          isArray: true,
          type: {
            model: "TaskAssets",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "asset",
          },
        },
        tracking: {
          name: "tracking",
          isArray: false,
          type: {
            model: "Tracking",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetName: "trackingID",
          },
        },
        attributes: {
          name: "attributes",
          isArray: true,
          type: {
            model: "AssetAttribute",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "asset",
          },
        },
        uom: {
          name: "uom",
          isArray: false,
          type: {
            model: "UnitOfMeassure",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetName: "assetUomId",
          },
        },
        project: {
          name: "project",
          isArray: false,
          type: {
            model: "Project",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetName: "assetProjectId",
          },
        },
        supportEngineers: {
          name: "supportEngineers",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        supervisors: {
          name: "supervisors",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        warehouseKeepers: {
          name: "warehouseKeepers",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
      },
      syncable: true,
      pluralName: "Assets",
      attributes: [
        {
          type: "searchable",
          properties: {},
        },
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "assetsByType",
            fields: ["type"],
            queryField: "assetsByType",
          },
        },
        {
          type: "key",
          properties: {
            name: "assetByTracking",
            fields: ["trackingID"],
          },
        },
        {
          type: "key",
          properties: {
            name: "assetByCodeSerial",
            fields: ["code", "serial"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "public",
                operations: ["read", "update"],
              },
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    Material: {
      name: "Material",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        name: {
          name: "name",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        code: {
          name: "code",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        description: {
          name: "description",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        category: {
          name: "category",
          isArray: false,
          type: {
            model: "MaterialCategory",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetName: "materialCategoryId",
          },
        },
        uom: {
          name: "uom",
          isArray: false,
          type: {
            model: "UnitOfMeassure",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetName: "materialUomId",
          },
        },
        manufacturer: {
          name: "manufacturer",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        trackable: {
          name: "trackable",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        enabled: {
          name: "enabled",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        kits: {
          name: "kits",
          isArray: true,
          type: {
            model: "KitMaterials",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "material",
          },
        },
        deleted: {
          name: "deleted",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
      },
      syncable: true,
      pluralName: "Materials",
      attributes: [
        {
          type: "searchable",
          properties: {},
        },
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "materialByCode",
            fields: ["code"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "public",
                operations: ["read"],
              },
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    MaterialCategory: {
      name: "MaterialCategory",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        name: {
          name: "name",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        abbreviation: {
          name: "abbreviation",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        description: {
          name: "description",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        deleted: {
          name: "deleted",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
      },
      syncable: true,
      pluralName: "MaterialCategories",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "public",
                operations: ["read"],
              },
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    UnitOfMeassure: {
      name: "UnitOfMeassure",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        name: {
          name: "name",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        abbreviation: {
          name: "abbreviation",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        description: {
          name: "description",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
      },
      syncable: true,
      pluralName: "UnitOfMeassures",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                groupClaim: "cognito:groups",
                provider: "userPools",
                allow: "groups",
                groups: ["admins", "supervisors", "auditors"],
                operations: ["read", "create", "update"],
              },
              {
                allow: "public",
                operations: ["read"],
              },
              {
                allow: "private",
                operations: ["read"],
              },
            ],
          },
        },
      ],
    },
    KitMaterials: {
      name: "KitMaterials",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        kit: {
          name: "kit",
          isArray: false,
          type: {
            model: "Kit",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetName: "kitMaterialsKitId",
          },
        },
        material: {
          name: "material",
          isArray: false,
          type: {
            model: "Material",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetName: "kitMaterialsMaterialId",
          },
        },
        uom: {
          name: "uom",
          isArray: false,
          type: {
            model: "UnitOfMeassure",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetName: "kitMaterialsUomId",
          },
        },
        quantity: {
          name: "quantity",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
      },
      syncable: false,
      pluralName: "KitMaterials",
      attributes: [
        {
          type: "model",
          properties: {
            subscriptions: null,
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    Kit: {
      name: "Kit",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        name: {
          name: "name",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        description: {
          name: "description",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        materials: {
          name: "materials",
          isArray: true,
          type: {
            model: "KitMaterials",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "kit",
          },
        },
      },
      syncable: false,
      pluralName: "Kits",
      attributes: [
        {
          type: "model",
          properties: {
            subscriptions: null,
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    TaskAssets: {
      name: "TaskAssets",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        // Propiedad customizada. NO REMOVER
        taskAssetsTaskId: {
          name: "taskAssetsTaskId",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
        task: {
          name: "task",
          isArray: false,
          type: {
            model: "Task",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetName: "taskAssetsTaskId",
          },
        },
        // Propiedad customizada. NO REMOVER
        taskAssetsAssetId: {
          name: "taskAssetsAssetId",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
        asset: {
          name: "asset",
          isArray: false,
          type: {
            model: "Asset",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetName: "taskAssetsAssetId",
          },
        },
        attachments: {
          name: "attachments",
          isArray: true,
          type: {
            model: "AttachmentAsset",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "taskAssetID",
          },
        },
        scanned: {
          name: "scanned",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        supportEngineers: {
          name: "supportEngineers",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        supervisors: {
          name: "supervisors",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        warehouseKeepers: {
          name: "warehouseKeepers",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
      },
      syncable: true,
      pluralName: "TaskAssets",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "public",
                operations: ["read", "update", "delete"],
              },
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    Task: {
      name: "Task",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        status: {
          name: "status",
          isArray: false,
          type: {
            enum: "TaskStatus",
          },
          isRequired: true,
          attributes: [],
        },
        category: {
          name: "category",
          isArray: false,
          type: {
            enum: "TaskCategory",
          },
          isRequired: true,
          attributes: [],
        },
        startDate: {
          name: "startDate",
          isArray: false,
          type: "AWSDate",
          isRequired: false,
          attributes: [],
        },
        endDate: {
          name: "endDate",
          isArray: false,
          type: "AWSDate",
          isRequired: false,
          attributes: [],
        },
        startTime: {
          name: "startTime",
          isArray: false,
          type: "AWSTime",
          isRequired: false,
          attributes: [],
        },
        endTime: {
          name: "endTime",
          isArray: false,
          type: "AWSTime",
          isRequired: false,
          attributes: [],
        },
        startedAt: {
          name: "startedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
        },
        startedBy: {
          name: "startedBy",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
        startLocation: {
          name: "startLocation",
          isArray: false,
          type: {
            nonModel: "Location",
          },
          isRequired: false,
          attributes: [],
        },
        endedAt: {
          name: "endedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
        },
        endedBy: {
          name: "endedBy",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
        endLocation: {
          name: "endLocation",
          isArray: false,
          type: {
            nonModel: "Location",
          },
          isRequired: false,
          attributes: [],
        },
        users: {
          name: "users",
          isArray: true,
          type: {
            model: "UserTask",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "task",
          },
        },
        premises: {
          name: "premises",
          isArray: false,
          type: {
            model: "Premises",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetName: "taskPremisesId",
          },
        },
        buildingLocation: {
          name: "buildingLocation",
          isArray: false,
          type: {
            model: "BuildingLocation",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetName: "taskBuildingLocationId",
          },
        },
        taskType: {
          name: "taskType",
          isArray: false,
          type: {
            model: "TaskType",
          },
          isRequired: true,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetName: "taskTaskTypeId",
          },
        },
        supervisor: {
          name: "supervisor",
          isArray: false,
          type: {
            model: "User",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetName: "taskSupervisorId",
          },
        },
        assets: {
          name: "assets",
          isArray: true,
          type: {
            model: "TaskAssets",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "task",
          },
        },
        attachments: {
          name: "attachments",
          isArray: true,
          type: {
            model: "Attachment",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "taskID",
          },
        },
        notes: {
          name: "notes",
          isArray: true,
          type: {
            model: "InspectionNote",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "taskID",
          },
        },
        remarks: {
          name: "remarks",
          isArray: true,
          type: {
            model: "Remark",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "taskID",
          },
        },
        ticket: {
          name: "ticket",
          isArray: false,
          type: {
            model: "Ticket",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetName: "taskTicketId",
          },
        },
        project: {
          name: "project",
          isArray: false,
          type: {
            model: "Project",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetName: "taskProjectId",
          },
        },
        externalTicketId: {
          name: "externalTicketId",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        // Propiedad customizada. NO REMOVER
        taskTicketId: {
          name: "taskTicketId",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        priority: {
          name: "priority",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        level: {
          name: "level",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        rating: {
          name: "rating",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        targetResolutionTime: {
          name: "targetResolutionTime",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        deliveryInstallment: {
          name: "deliveryInstallment",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        owner: {
          name: "owner",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        deleted: {
          name: "deleted",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        events: {
          name: "events",
          isArray: true,
          type: {
            model: "TimerEvent",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "taskID",
          },
        },
        lastEvent: {
          name: "lastEvent",
          isArray: false,
          type: {
            model: "TimerEvent",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetName: "taskLastEventId",
          },
        },
        embeddedEvents: {
          name: "embeddedEvents",
          isArray: true,
          type: {
            nonModel: "EmbeddedTimerEvent",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        embeddedLastEvent: {
          name: "embeddedLastEvent",
          isArray: false,
          type: {
            nonModel: "EmbeddedTimerEvent",
          },
          isRequired: false,
          attributes: [],
        },
        effectiveTime: {
          name: "effectiveTime",
          isArray: false,
          type: "AWSTimestamp",
          isRequired: false,
          attributes: [],
        },
        verificatorName: {
          name: "verificatorName",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        verificatorEmail: {
          name: "verificatorEmail",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        validationAttachment: {
          name: "validationAttachment",
          isArray: false,
          type: {
            nonModel: "S3Object",
          },
          isRequired: false,
          attributes: [],
        },
        validationSignatureFile: {
          name: "validationSignatureFile",
          isArray: false,
          type: {
            nonModel: "S3Object",
          },
          isRequired: false,
          attributes: [],
        },
        supportEngineers: {
          name: "supportEngineers",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        supervisors: {
          name: "supervisors",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        warehouseKeepers: {
          name: "warehouseKeepers",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
      },
      syncable: true,
      pluralName: "Tasks",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "tasksByStatus",
            fields: ["status"],
            queryField: "tasksByStatus",
          },
        },
        {
          type: "key",
          properties: {
            name: "tasksByCategory",
            fields: ["category"],
            queryField: "tasksByCategory",
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "public",
                operations: ["read", "update", "delete"],
              },
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    UserTask: {
      name: "UserTask",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        // Propiedad customizada. NO REMOVER
        userID: {
          name: "userID",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
        user: {
          name: "user",
          isArray: false,
          type: {
            model: "User",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetName: "userID",
          },
        },
        // Propiedad customizada. NO REMOVER
        taskID: {
          name: "taskID",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
        task: {
          name: "task",
          isArray: false,
          type: {
            model: "Task",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetName: "taskID",
          },
        },
        head: {
          name: "head",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        supportEngineers: {
          name: "supportEngineers",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        supervisors: {
          name: "supervisors",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        warehouseKeepers: {
          name: "warehouseKeepers",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
      },
      syncable: true,
      pluralName: "UserTasks",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "byUser",
            fields: ["userID", "taskID"],
          },
        },
        {
          type: "key",
          properties: {
            name: "byTask",
            fields: ["taskID", "userID"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "public",
                operations: ["read", "update", "delete"],
              },
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    User: {
      name: "User",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        name: {
          name: "name",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        middlename: {
          name: "middlename",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        lastnameP: {
          name: "lastnameP",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        lastnameM: {
          name: "lastnameM",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        email: {
          name: "email",
          isArray: false,
          type: "AWSEmail",
          isRequired: true,
          attributes: [],
        },
        phone: {
          name: "phone",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        extension: {
          name: "extension",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        status: {
          name: "status",
          isArray: false,
          type: {
            enum: "UserStatus",
          },
          isRequired: false,
          attributes: [],
        },
        roles: {
          name: "roles",
          isArray: true,
          type: {
            model: "UserRole",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "user",
          },
        },
        teams: {
          name: "teams",
          isArray: true,
          type: {
            model: "UserTeams",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "user",
          },
        },
        tasks: {
          name: "tasks",
          isArray: true,
          type: {
            model: "UserTask",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "user",
          },
        },
        tasksSupervised: {
          name: "tasksSupervised",
          isArray: true,
          type: {
            model: "Task",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "supervisor",
          },
        },
        logs: {
          name: "logs",
          isArray: true,
          type: {
            model: "Log",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "user",
          },
        },
        supportEngineers: {
          name: "supportEngineers",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        supervisors: {
          name: "supervisors",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        warehouseKeepers: {
          name: "warehouseKeepers",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        legalAgreements: {
          name: "legalAgreements",
          isArray: true,
          type: {
            model: "UserAgreement",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "userID",
          },
        },
      },
      syncable: true,
      pluralName: "Users",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "public",
                operations: ["read", "update", "create"],
              },
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    UserRole: {
      name: "UserRole",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        user: {
          name: "user",
          isArray: false,
          type: {
            model: "User",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetName: "userID",
          },
        },
        role: {
          name: "role",
          isArray: false,
          type: {
            model: "Role",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetName: "roleID",
          },
        },
      },
      syncable: true,
      pluralName: "UserRoles",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "usersByRole",
            fields: ["roleID", "userID"],
          },
        },
        {
          type: "key",
          properties: {
            name: "rolesByUser",
            fields: ["userID", "roleID"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "public",
                operations: ["read"],
              },
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    Role: {
      name: "Role",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        name: {
          name: "name",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        cognitoGroup: {
          name: "cognitoGroup",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        users: {
          name: "users",
          isArray: true,
          type: {
            model: "UserRole",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "role",
          },
        },
      },
      syncable: true,
      pluralName: "Roles",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "public",
                operations: ["read", "create"],
              },
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    UserTeams: {
      name: "UserTeams",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        user: {
          name: "user",
          isArray: false,
          type: {
            model: "User",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetName: "userTeamsUserId",
          },
        },
        team: {
          name: "team",
          isArray: false,
          type: {
            model: "Team",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetName: "userTeamsTeamId",
          },
        },
        head: {
          name: "head",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
      },
      syncable: true,
      pluralName: "UserTeams",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    Team: {
      name: "Team",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        name: {
          name: "name",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        description: {
          name: "description",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        head: {
          name: "head",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        users: {
          name: "users",
          isArray: true,
          type: {
            model: "UserTeams",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "team",
          },
        },
      },
      syncable: true,
      pluralName: "Teams",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "public",
                operations: ["create"],
              },
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    Log: {
      name: "Log",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        user: {
          name: "user",
          isArray: false,
          type: {
            model: "User",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetName: "userID",
          },
        },
        dataJSON: {
          name: "dataJSON",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        dataType: {
          name: "dataType",
          isArray: false,
          type: {
            enum: "LogDataType",
          },
          isRequired: true,
          attributes: [],
        },
      },
      syncable: false,
      pluralName: "Logs",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "byUser",
            fields: ["userID"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                provider: "userPools",
                ownerField: "owner",
                allow: "owner",
                identityClaim: "cognito:username",
                operations: ["create", "update", "delete", "read"],
              },
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    UserAgreement: {
      name: "UserAgreement",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        userID: {
          name: "userID",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        legalDocumentID: {
          name: "legalDocumentID",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        agreement: {
          name: "agreement",
          isArray: false,
          type: {
            model: "LegalDocument",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "HAS_ONE",
            associatedWith: "id",
            targetName: "legalDocumentID",
          },
        },
        grantedAt: {
          name: "grantedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: true,
          attributes: [],
        },
        isGranted: {
          name: "isGranted",
          isArray: false,
          type: "Boolean",
          isRequired: true,
          attributes: [],
        },
      },
      syncable: true,
      pluralName: "UserAgreements",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "userAgreementByUserId",
            fields: ["userID"],
          },
        },
        {
          type: "key",
          properties: {
            name: "userAgreementByLegalDocumentId",
            fields: ["legalDocumentID"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    LegalDocument: {
      name: "LegalDocument",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        name: {
          name: "name",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        description: {
          name: "description",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        type: {
          name: "type",
          isArray: false,
          type: {
            enum: "LegalDocumentType",
          },
          isRequired: true,
          attributes: [],
        },
        content: {
          name: "content",
          isArray: false,
          type: {
            nonModel: "S3Object",
          },
          isRequired: true,
          attributes: [],
        },
        effectiveDate: {
          name: "effectiveDate",
          isArray: false,
          type: "AWSDateTime",
          isRequired: true,
          attributes: [],
        },
        expirationDate: {
          name: "expirationDate",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
        },
        status: {
          name: "status",
          isArray: false,
          type: {
            enum: "LegalDocumentStatus",
          },
          isRequired: true,
          attributes: [],
        },
        version: {
          name: "version",
          isArray: false,
          type: "Int",
          isRequired: true,
          attributes: [],
        },
      },
      syncable: true,
      pluralName: "LegalDocuments",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "legalDocumentByTypeAndStatus",
            fields: ["type", "status"],
            queryField: "legalDocumentByTypeAndStatus",
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    Premises: {
      name: "Premises",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        name: {
          name: "name",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        code: {
          name: "code",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        owner: {
          name: "owner",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        company: {
          name: "company",
          isArray: false,
          type: {
            model: "Company",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetName: "premisesCompanyId",
          },
        },
        custodian: {
          name: "custodian",
          isArray: false,
          type: {
            model: "Custodian",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetName: "premisesCustodianId",
          },
        },
        address: {
          name: "address",
          isArray: false,
          type: {
            model: "Address",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetName: "premisesAddressId",
          },
        },
        buildingLocations: {
          name: "buildingLocations",
          isArray: true,
          type: {
            model: "BuildingLocation",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "premises",
          },
        },
      },
      syncable: true,
      pluralName: "Premises",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "premisesByCode",
            fields: ["code"],
            queryField: "premisesByCode",
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "public",
                operations: ["read"],
              },
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    Company: {
      name: "Company",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        name: {
          name: "name",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        description: {
          name: "description",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        enabled: {
          name: "enabled",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        owner: {
          name: "owner",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
        projects: {
          name: "projects",
          isArray: true,
          type: {
            model: "Project",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "company",
          },
        },
      },
      syncable: true,
      pluralName: "Companies",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
              {
                allow: "public",
                operations: ["read"],
              },
            ],
          },
        },
      ],
    },
    Project: {
      name: "Project",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        code: {
          name: "code",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        name: {
          name: "name",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        contractNumber: {
          name: "contractNumber",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        description: {
          name: "description",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        company: {
          name: "company",
          isArray: false,
          type: {
            model: "Company",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetName: "companyID",
          },
        },
        enabled: {
          name: "enabled",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        startAt: {
          name: "startAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
        },
        endAt: {
          name: "endAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
        },
      },
      syncable: true,
      pluralName: "Projects",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "byCompany",
            fields: ["companyID"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "public",
                operations: ["read"],
              },
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    Custodian: {
      name: "Custodian",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        name: {
          name: "name",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        middlename: {
          name: "middlename",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        lastnameP: {
          name: "lastnameP",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        lastnameM: {
          name: "lastnameM",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        email: {
          name: "email",
          isArray: false,
          type: "AWSEmail",
          isRequired: false,
          attributes: [],
        },
        phone: {
          name: "phone",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        extension: {
          name: "extension",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
      },
      syncable: true,
      pluralName: "Custodians",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "public",
                operations: ["read"],
              },
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    Address: {
      name: "Address",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        street: {
          name: "street",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        number: {
          name: "number",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        zipcode: {
          name: "zipcode",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        city: {
          name: "city",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        settlement: {
          name: "settlement",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        locality: {
          name: "locality",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
      },
      syncable: true,
      pluralName: "Addresses",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "public",
                operations: ["read"],
              },
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    BuildingLocation: {
      name: "BuildingLocation",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        name: {
          name: "name",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        code: {
          name: "code",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        buildingBlock: {
          name: "buildingBlock",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        floor: {
          name: "floor",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        area: {
          name: "area",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        status: {
          name: "status",
          isArray: false,
          type: {
            enum: "BuildingLocationStatus",
          },
          isRequired: false,
          attributes: [],
        },
        premises: {
          name: "premises",
          isArray: false,
          type: {
            model: "Premises",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetName: "premisesID",
          },
        },
        owner: {
          name: "owner",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
      },
      syncable: true,
      pluralName: "BuildingLocations",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "byPremises",
            fields: ["premisesID"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "public",
                operations: ["read"],
              },
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    TaskType: {
      name: "TaskType",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        category: {
          name: "category",
          isArray: false,
          type: {
            enum: "TaskCategory",
          },
          isRequired: true,
          attributes: [],
        },
        name: {
          name: "name",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        description: {
          name: "description",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        owner: {
          name: "owner",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
      },
      syncable: true,
      pluralName: "TaskTypes",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "taskTypeByCategory",
            fields: ["category"],
            queryField: "taskTypeByCategory",
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "public",
                operations: ["read", "create"],
              },
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    Attachment: {
      name: "Attachment",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        taskID: {
          name: "taskID",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        owner: {
          name: "owner",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        status: {
          name: "status",
          isArray: false,
          type: {
            enum: "TaskStatus",
          },
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
        },
        file: {
          name: "file",
          isArray: false,
          type: {
            nonModel: "S3Object",
          },
          isRequired: false,
          attributes: [],
        },
        supportEngineers: {
          name: "supportEngineers",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        supervisors: {
          name: "supervisors",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        warehouseKeepers: {
          name: "warehouseKeepers",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
      },
      syncable: true,
      pluralName: "Attachments",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "attachmentByTask",
            fields: ["taskID", "id"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "public",
                operations: ["read", "update", "delete"],
              },
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    InspectionNote: {
      name: "InspectionNote",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        taskID: {
          name: "taskID",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        comments: {
          name: "comments",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        supervisor: {
          name: "supervisor",
          isArray: false,
          type: {
            model: "User",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetName: "inspectionNoteSupervisorId",
          },
        },
        supportEngineers: {
          name: "supportEngineers",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        supervisors: {
          name: "supervisors",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        warehouseKeepers: {
          name: "warehouseKeepers",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
      },
      syncable: true,
      pluralName: "InspectionNotes",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "byTask",
            fields: ["taskID", "id"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "public",
                operations: ["read", "update", "delete"],
              },
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    Remark: {
      name: "Remark",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        taskID: {
          name: "taskID",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        taskStatus: {
          name: "taskStatus",
          isArray: false,
          type: {
            enum: "TaskStatus",
          },
          isRequired: false,
          attributes: [],
        },
        remark: {
          name: "remark",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        supportEngineers: {
          name: "supportEngineers",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        supervisors: {
          name: "supervisors",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        warehouseKeepers: {
          name: "warehouseKeepers",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
      },
      syncable: true,
      pluralName: "Remarks",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "remarkByTask",
            fields: ["taskID"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "public",
                operations: ["read", "update", "delete"],
              },
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    Ticket: {
      name: "Ticket",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        subject: {
          name: "subject",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        description: {
          name: "description",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        externalData: {
          name: "externalData",
          isArray: false,
          type: "AWSJSON",
          isRequired: false,
          attributes: [],
        },
        requestType: {
          name: "requestType",
          isArray: false,
          type: {
            enum: "RequestType",
          },
          isRequired: true,
          attributes: [],
        },
        impact: {
          name: "impact",
          isArray: false,
          type: {
            enum: "TicketImpact",
          },
          isRequired: true,
          attributes: [],
        },
        impactDetails: {
          name: "impactDetails",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        status: {
          name: "status",
          isArray: false,
          type: {
            enum: "TicketStatus",
          },
          isRequired: true,
          attributes: [],
        },
        mode: {
          name: "mode",
          isArray: false,
          type: {
            enum: "RequestChannel",
          },
          isRequired: true,
          attributes: [],
        },
        supportType: {
          name: "supportType",
          isArray: false,
          type: {
            enum: "SupportType",
          },
          isRequired: false,
          attributes: [],
        },
        urgency: {
          name: "urgency",
          isArray: false,
          type: {
            enum: "TicketUrgency",
          },
          isRequired: true,
          attributes: [],
        },
        priority: {
          name: "priority",
          isArray: false,
          type: {
            enum: "TicketPriority",
          },
          isRequired: true,
          attributes: [],
        },
        serviceCategory: {
          name: "serviceCategory",
          isArray: false,
          type: {
            enum: "ServiceCategory",
          },
          isRequired: true,
          attributes: [],
        },
        serviceSubcategory: {
          name: "serviceSubcategory",
          isArray: false,
          type: {
            enum: "ServiceSubcategory",
          },
          isRequired: true,
          attributes: [],
        },
        rating: {
          name: "rating",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        // Propiedad customizada. NO REMOVER
        ticketPremisesId: {
          name: "ticketPremisesId",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
        // Propiedad customizada. NO REMOVER
        ticketRequesterId: {
          name: "ticketRequesterId",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
        ratingComment: {
          name: "ratingComment",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        verificatorName: {
          name: "verificatorName",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        validationSignatureFile: {
          name: "validationSignatureFile",
          isArray: false,
          type: {
            nonModel: "S3Object",
          },
          isRequired: false,
          attributes: [],
        },
        externalTicketId: {
          name: "externalTicketId",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        externalRequester: {
          name: "externalRequester",
          isArray: false,
          type: {
            nonModel: "ExternalUser",
          },
          isRequired: false,
          attributes: [],
        },
        externalData: {
          name: "externalData",
          isArray: false,
          type: "AWSJSON",
          isRequired: false,
          attributes: [],
        },
        remarks: {
          name: "remarks",
          isArray: true,
          type: {
            nonModel: "TicketRemark",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
        },
        requestChannel: {
          name: "requestChannel",
          isArray: false,
          type: {
            enum: "RequestChannel",
          },
          isRequired: false,
          attributes: [],
        },
        asset: {
          name: "asset",
          isArray: false,
          type: {
            model: "Asset",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetName: "ticketAssetId",
          },
        },
        requester: {
          name: "requester",
          isArray: false,
          type: {
            model: "User",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetName: "ticketRequesterId",
          },
        },
        premises: {
          name: "premises",
          isArray: false,
          type: {
            model: "Premises",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetName: "ticketPremisesId",
          },
        },
        project: {
          name: "project",
          isArray: false,
          type: {
            model: "Project",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetName: "ticketProjectId",
          },
        },
        tasks: {
          name: "tasks",
          isArray: true,
          type: {
            model: "Task",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "ticket",
          },
        },
        attachments: {
          name: "attachments",
          isArray: true,
          type: {
            model: "AttachmentTicket",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: false,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "ticketID",
          },
        },
        supportEngineers: {
          name: "supportEngineers",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        supervisors: {
          name: "supervisors",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        warehouseKeepers: {
          name: "warehouseKeepers",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        reportedAsset: {
          name: "reportedAsset",
          isArray: false,
          type: {
            nonModel: "ReportedAsset",
          },
          isRequired: false,
          attributes: [],
        },
      },
      syncable: true,
      pluralName: "Tickets",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "ticketByExternalId",
            fields: ["externalTicketId"],
            queryField: "ticketByExternalId",
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "public",
                operations: ["read", "update", "delete", "create"],
              },
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    AttachmentTicket: {
      name: "AttachmentTicket",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        ticketID: {
          name: "ticketID",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        file: {
          name: "file",
          isArray: false,
          type: {
            nonModel: "S3Object",
          },
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
        },
        supportEngineers: {
          name: "supportEngineers",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        supervisors: {
          name: "supervisors",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        warehouseKeepers: {
          name: "warehouseKeepers",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
      },
      syncable: true,
      pluralName: "AttachmentTickets",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "attachmentByTicket",
            fields: ["ticketID", "id"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "public",
                operations: ["read", "update", "delete"],
              },
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    TimerEvent: {
      name: "TimerEvent",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        taskID: {
          name: "taskID",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        eventCauseID: {
          name: "eventCauseID",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        cause: {
          name: "cause",
          isArray: false,
          type: {
            model: "EventCause",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "HAS_ONE",
            associatedWith: "id",
            targetName: "eventCauseID",
          },
        },
        description: {
          name: "description",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        timestamp: {
          name: "timestamp",
          isArray: false,
          type: "AWSTimestamp",
          isRequired: true,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: true,
          attributes: [],
        },
        duration: {
          name: "duration",
          isArray: false,
          type: "AWSTimestamp",
          isRequired: true,
          attributes: [],
        },
        finalized: {
          name: "finalized",
          isArray: false,
          type: "Boolean",
          isRequired: true,
          attributes: [],
        },
      },
      syncable: true,
      pluralName: "TimerEvents",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "timerEventByTask",
            fields: ["taskID", "timestamp"],
          },
        },
        {
          type: "key",
          properties: {
            name: "timerEventByCause",
            fields: ["eventCauseID", "timestamp"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
              {
                allow: "public",
                operations: ["read"],
              },
            ],
          },
        },
      ],
    },
    EventCause: {
      name: "EventCause",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        name: {
          name: "name",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        description: {
          name: "description",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        type: {
          name: "type",
          isArray: false,
          type: {
            enum: "TimerEventType",
          },
          isRequired: true,
          attributes: [],
        },
      },
      syncable: true,
      pluralName: "EventCauses",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "eventCauseByType",
            fields: ["type"],
            queryField: "eventCauseByType",
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
              {
                allow: "public",
                operations: ["read", "create"],
              },
            ],
          },
        },
      ],
    },
    AttachmentAsset: {
      name: "AttachmentAsset",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        taskAssetID: {
          name: "taskAssetID",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        status: {
          name: "status",
          isArray: false,
          type: {
            enum: "TaskStatus",
          },
          isRequired: true,
          attributes: [],
        },
        file: {
          name: "file",
          isArray: false,
          type: {
            nonModel: "S3Object",
          },
          isRequired: true,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
        },
        platform: {
          name: "platform",
          isArray: false,
          type: {
            enum: "AttachmentPlatform",
          },
          isRequired: false,
          attributes: [],
        },
        source: {
          name: "source",
          isArray: false,
          type: {
            enum: "AttachmentSource",
          },
          isRequired: false,
          attributes: [],
        },
        loadType: {
          name: "loadType",
          isArray: false,
          type: {
            enum: "AttachmentLoadType",
          },
          isRequired: false,
          attributes: [],
        },
        supportEngineers: {
          name: "supportEngineers",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        supervisors: {
          name: "supervisors",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        warehouseKeepers: {
          name: "warehouseKeepers",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
      },
      syncable: true,
      pluralName: "AttachmentAssets",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "attachmentByTaskAsset",
            fields: ["taskAssetID", "id"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "public",
                operations: ["read", "update", "delete"],
              },
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    Tracking: {
      name: "Tracking",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        status: {
          name: "status",
          isArray: false,
          type: {
            enum: "TrackingStatus",
          },
          isRequired: true,
          attributes: [],
        },
        ikaID: {
          name: "ikaID",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        assets: {
          name: "assets",
          isArray: true,
          type: {
            model: "Asset",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "tracking",
          },
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
        },
      },
      syncable: true,
      pluralName: "Trackings",
      attributes: [
        {
          type: "model",
          properties: {
            timestamps: {
              createdAt: "createdAt",
              updatedAt: "updatedAt",
            },
          },
        },
        {
          type: "key",
          properties: {
            name: "trackingByIkaID",
            fields: ["ikaID"],
            queryField: "trackingByIkaID",
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
              {
                allow: "public",
                operations: ["read", "create", "update"],
              },
            ],
          },
        },
      ],
    },
    AssetAttribute: {
      name: "AssetAttribute",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        asset: {
          name: "asset",
          isArray: false,
          type: {
            model: "Asset",
          },
          isRequired: true,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetName: "assetID",
          },
        },
        // Propiedad customizada. NO REMOVER
        assetID: {
          name: "assetID",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
        attribute: {
          name: "attribute",
          isArray: false,
          type: {
            model: "Attribute",
          },
          isRequired: true,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetName: "attributeID",
          },
        },
        // Propiedad customizada. NO REMOVER
        attributeID: {
          name: "attributeID",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
        value: {
          name: "value",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        supportEngineers: {
          name: "supportEngineers",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        supervisors: {
          name: "supervisors",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        warehouseKeepers: {
          name: "warehouseKeepers",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
      },
      syncable: true,
      pluralName: "AssetAttributes",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "byAttribute",
            fields: ["attributeID", "assetID"],
          },
        },
        {
          type: "key",
          properties: {
            name: "byAsset",
            fields: ["assetID", "attributeID"],
          },
        },
        {
          type: "key",
          properties: {
            name: "byAttributeAndValue",
            fields: ["attributeID", "value"],
            queryField: "assetAttributeByAttributeAndValue",
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "public",
                operations: ["read", "create", "update"],
              },
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    Attribute: {
      name: "Attribute",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        name: {
          name: "name",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        visible: {
          name: "visible",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        editable: {
          name: "editable",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        description: {
          name: "description",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        assets: {
          name: "assets",
          isArray: true,
          type: {
            model: "AssetAttribute",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "attribute",
          },
        },
      },
      syncable: true,
      pluralName: "Attributes",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "searchable",
          properties: {},
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
              {
                allow: "public",
                operations: ["read", "create"],
              },
            ],
          },
        },
      ],
    },
    Setting: {
      name: "Setting",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        key: {
          name: "key",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        value: {
          name: "value",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        status: {
          name: "status",
          isArray: false,
          type: {
            enum: "SettingsStatus",
          },
          isRequired: true,
          attributes: [],
        },
        name: {
          name: "name",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        description: {
          name: "description",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
      },
      syncable: false,
      pluralName: "Settings",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            fields: ["id", "key"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "public",
                operations: ["read", "create"],
              },
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    IntegrationRequest: {
      name: "IntegrationRequest",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        integrationID: {
          name: "integrationID",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        status: {
          name: "status",
          isArray: false,
          type: {
            enum: "IntegrationRequestStatus",
          },
          isRequired: true,
          attributes: [],
        },
        email: {
          name: "email",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        firstname: {
          name: "firstname",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        middlename: {
          name: "middlename",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        lastnamep: {
          name: "lastnamep",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        lastnamem: {
          name: "lastnamem",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        organization: {
          name: "organization",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        comments: {
          name: "comments",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
      },
      syncable: false,
      pluralName: "IntegrationRequests",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "byIntegrationID",
            fields: ["integrationID"],
            queryField: "integrationRequestByIntegrationID",
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    AcceptedDomain: {
      name: "AcceptedDomain",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        company: {
          name: "company",
          isArray: false,
          type: {
            model: "Company",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetName: "acceptedDomainCompanyId",
          },
        },
        emailDomain: {
          name: "emailDomain",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        status: {
          name: "status",
          isArray: false,
          type: {
            enum: "AcceptedDomainStatus",
          },
          isRequired: false,
          attributes: [],
        },
      },
      syncable: false,
      pluralName: "AcceptedDomains",
      attributes: [
        {
          type: "model",
          properties: {
            subscriptions: null,
          },
        },
        {
          type: "key",
          properties: {
            name: "emailDomainsByDomain",
            fields: ["emailDomain"],
            queryField: "byDomains",
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                groupClaim: "cognito:groups",
                provider: "userPools",
                allow: "groups",
                groups: ["admins"],
                operations: ["read", "create", "update"],
              },
              {
                allow: "private",
                operations: ["read"],
              },
            ],
          },
        },
      ],
    },
    GoodsReceipt: {
      name: "GoodsReceipt",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        quantity: {
          name: "quantity",
          isArray: false,
          type: "Int",
          isRequired: true,
          attributes: [],
        },
        user: {
          name: "user",
          isArray: false,
          type: {
            model: "User",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetName: "goodsReceiptUserId",
          },
        },
        type: {
          name: "type",
          isArray: false,
          type: {
            enum: "GoodsReceiptType",
          },
          isRequired: true,
          attributes: [],
        },
        comments: {
          name: "comments",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        qrAssociated: {
          name: "qrAssociated",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        assets: {
          name: "assets",
          isArray: true,
          type: {
            model: "GoodsReceiptAsset",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: false,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "goodsReceipt",
          },
        },
        attachments: {
          name: "attachments",
          isArray: true,
          type: {
            model: "AttachmentGoodsReceipt",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: false,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "goodsReceiptID",
          },
        },
        project: {
          name: "project",
          isArray: false,
          type: {
            model: "Project",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetName: "goodsReceiptProjectId",
          },
        },
        fileName: {
          name: "fileName",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        file: {
          name: "file",
          isArray: false,
          type: {
            nonModel: "S3Object",
          },
          isRequired: false,
          attributes: [],
        },
      },
      syncable: false,
      pluralName: "GoodsReceipts",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                groupClaim: "cognito:groups",
                provider: "userPools",
                allow: "groups",
                groups: ["admins", "warehouse-keepers"],
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    GoodsReceiptAsset: {
      name: "GoodsReceiptAsset",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        goodsReceipt: {
          name: "goodsReceipt",
          isArray: false,
          type: {
            model: "Task",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetName: "goodsReceiptID",
          },
        },
        asset: {
          name: "asset",
          isArray: false,
          type: {
            model: "Asset",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetName: "assetID",
          },
        },
      },
      syncable: false,
      pluralName: "GoodsReceiptAssets",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "byAsset",
            fields: ["assetID", "goodsReceiptID"],
          },
        },
        {
          type: "key",
          properties: {
            name: "byGoodsReceipt",
            fields: ["goodsReceiptID", "assetID"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                groupClaim: "cognito:groups",
                provider: "userPools",
                allow: "groups",
                groups: ["admins", "warehouse-keepers"],
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    AttachmentGoodsReceipt: {
      name: "AttachmentGoodsReceipt",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        goodsReceiptID: {
          name: "goodsReceiptID",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        file: {
          name: "file",
          isArray: false,
          type: {
            nonModel: "S3Object",
          },
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
        },
      },
      syncable: false,
      pluralName: "AttachmentGoodsReceipts",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "attachmentByGoodsReceipt",
            fields: ["goodsReceiptID", "id"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                groupClaim: "cognito:groups",
                provider: "userPools",
                allow: "groups",
                groups: ["admins", "warehouse-keepers"],
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
  },
  enums: {
    TaskDocumentType: {
      name: "TaskDocumentType",
      values: ["MAINTENANCE_REPORT", "SUPPORT_COMPLETED_REPORT", "SUPPORT_VALIDATED_REPORT", "INSTALLATION_REPORT"],
    },
    AssetType: {
      name: "AssetType",
      values: [
        "ALLOCATED",
        "USED",
        "REUSED",
        "RECEIVED",
        "SHIPMENT_SCHEDULED",
        "SHIPMENT_SENT",
        "SHIPMENT_PREV_SENT",
        "SHIPMENT_RECEIVED",
        "SHIPMENT_PREV_RECEIVED",
        "TEMPORAL",
        "MATERIAL_ALLOCATED",
        "MATERIAL_USED",
      ],
    },
    TaskStatus: {
      name: "TaskStatus",
      values: [
        "SCHEDULED",
        "IN_PROGRESS",
        "COMPLETED",
        "REVIEWED",
        "WITH_INSPECTION_NOTES",
        "FINALIZED",
        "SHIPMENT_SCHEDULED",
        "SHIPMENT_PREV_SENT",
        "SHIPMENT_SENT",
        "SHIPMENT_PREV_RECEIVED",
        "SHIPMENT_RECEIVED",
      ],
    },
    TaskCategory: {
      name: "TaskCategory",
      values: ["IMPLEMENTATION", "SHIPMENT", "SUPPORT", "MAINTENANCE", "UNDEFINED"],
    },
    UserStatus: {
      name: "UserStatus",
      values: ["ENABLED", "DISABLED"],
    },
    LogDataType: {
      name: "LogDataType",
      values: ["MATERIAL", "MATERIAL_CATEGORY", "TASK", "TICKET"],
    },
    LegalDocumentType: {
      name: "LegalDocumentType",
      values: ["TERMS_AND_CONDITIONS", "NOTICE_OF_PRIVACY"],
    },
    LegalDocumentStatus: {
      name: "LegalDocumentStatus",
      values: ["PENDING", "EFFECTIVE", "EXPIRED", "CANCELLED"],
    },
    BuildingLocationStatus: {
      name: "BuildingLocationStatus",
      values: ["ENABLED", "DISABLED"],
    },
    RequestType: {
      name: "RequestType",
      values: ["ISSUE", "SERVICE_REQUEST", "UNDEFINED"],
    },
    TicketImpact: {
      name: "TicketImpact",
      values: ["HIGH", "MEDIUM", "LOW", "UNDEFINED"],
    },
    TicketStatus: {
      name: "TicketStatus",
      values: ["OPEN", "SCHEDULED", "IN_PROGRESS", "RESOLVED", "VALIDATED", "RATED", "CANCELLED"],
    },
    RequestChannel: {
      name: "RequestChannel",
      values: ["PHONE", "EMAIL", "WEB_PAGE", "UNDEFINED"],
    },
    SupportType: {
      name: "SupportType",
      values: ["PRESENTIAL", "REMOTE", "UNDEFINED"],
    },
    TicketUrgency: {
      name: "TicketUrgency",
      values: ["HIGH", "NORMAL", "LOW", "UNDEFINED"],
    },
    TicketPriority: {
      name: "TicketPriority",
      values: ["HIGH", "MEDIUM", "LOW", "UNDEFINED"],
    },
    ServiceCategory: {
      name: "ServiceCategory",
      values: ["ELECTRONIC_SECURITY", "UNDEFINED"],
    },
    ServiceSubcategory: {
      name: "ServiceSubcategory",
      values: [
        "CCTV",
        "NETWORK",
        "COMPUTER_EQUIPMENT",
        "PROPERTY_EQUIPMENT",
        "AIR_CODITIONER",
        "ACCESS_CONTROL",
        "ROUND_CONTROL",
        "CABINET",
        "UNDEFINED",
      ],
    },
    TimerEventType: {
      name: "TimerEventType",
      values: ["START", "PAUSE", "RESUME", "END"],
    },
    AttachmentPlatform: {
      name: "AttachmentPlatform",
      values: ["WEB", "ANDROID", "IOS"],
    },
    AttachmentSource: {
      name: "AttachmentSource",
      values: ["CAMERA", "ALBUM"],
    },
    AttachmentLoadType: {
      name: "AttachmentLoadType",
      values: ["ONLINE", "OFFLINE"],
    },
    TrackingStatus: {
      name: "TrackingStatus",
      values: ["enabled", "disabled", "assigned", "unassigned"],
    },
    Job: {
      name: "Job",
      values: ["UOM", "MATERIAL", "ASSET", "CATEGORY"],
    },
    JobRunState: {
      name: "JobRunState",
      values: ["STARTING", "RUNNING", "STOPPING", "STOPPED", "SUCCEEDED", "FAILED", "TIMEOUT"],
    },
    DownloadAttachmentType: {
      name: "DownloadAttachmentType",
      values: ["ATTACHMENT_ASSET"],
    },
    Actions: {
      name: "Actions",
      values: [
        "subscribeRequest",
        "getToken",
        "createRequest",
        "getRequestDetails",
        "getRequests",
        "getRequestFieldsInfo",
      ],
    },
    SettingsStatus: {
      name: "SettingsStatus",
      values: ["ENABLED", "DISABLED"],
    },
    IntegrationRequestStatus: {
      name: "IntegrationRequestStatus",
      values: ["REQUESTED", "APPROVED", "CANCELED"],
    },
    ModelAttributeTypes: {
      name: "ModelAttributeTypes",
      values: ["binary", "binarySet", "bool", "list", "map", "number", "numberSet", "string", "stringSet", "_null"],
    },
    AcceptedDomainStatus: {
      name: "AcceptedDomainStatus",
      values: ["ENABLED", "DISABLED"],
    },
    GoodsReceiptType: {
      name: "GoodsReceiptType",
      values: ["CSV_FILE", "CONTPAQi"],
    },
  },
  nonModels: {
    PDFinfo: {
      name: "PDFinfo",
      fields: {
        name: {
          name: "name",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        url: {
          name: "url",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
      },
    },
    LambdaDownloadTaskDocumentResult: {
      name: "LambdaDownloadTaskDocumentResult",
      fields: {
        statusCode: {
          name: "statusCode",
          isArray: false,
          type: "Int",
          isRequired: true,
          attributes: [],
        },
        error: {
          name: "error",
          isArray: false,
          type: "Boolean",
          isRequired: true,
          attributes: [],
        },
        errorMessage: {
          name: "errorMessage",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        document: {
          name: "document",
          isArray: false,
          type: {
            nonModel: "PDFinfo",
          },
          isRequired: false,
          attributes: [],
        },
      },
    },
    LambdaAdvancedAssetSearchResult: {
      name: "LambdaAdvancedAssetSearchResult",
      fields: {
        statusCode: {
          name: "statusCode",
          isArray: false,
          type: "Int",
          isRequired: true,
          attributes: [],
        },
        error: {
          name: "error",
          isArray: false,
          type: "Boolean",
          isRequired: true,
          attributes: [],
        },
        errorMessage: {
          name: "errorMessage",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        assets: {
          name: "assets",
          isArray: true,
          type: {
            model: "Asset",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        results: {
          name: "results",
          isArray: true,
          type: {
            nonModel: "LambdaAdvancedAssetSearchItem",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
      },
    },
    EmbeddedAttribute: {
      name: "EmbeddedAttribute",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        attributeID: {
          name: "attributeID",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        value: {
          name: "value",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        name: {
          name: "name",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
      },
    },
    Location: {
      name: "Location",
      fields: {
        longitude: {
          name: "longitude",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        latitude: {
          name: "latitude",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
      },
    },
    S3Object: {
      name: "S3Object",
      fields: {
        bucket: {
          name: "bucket",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        region: {
          name: "region",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        key: {
          name: "key",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
      },
    },
    ExternalUser: {
      name: "ExternalUser",
      fields: {
        name: {
          name: "name",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        lastname: {
          name: "lastname",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        email: {
          name: "email",
          isArray: false,
          type: "AWSEmail",
          isRequired: true,
          attributes: [],
        },
      },
    },
    TicketRemark: {
      name: "TicketRemark",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        ticketStatus: {
          name: "ticketStatus",
          isArray: false,
          type: {
            enum: "TicketStatus",
          },
          isRequired: false,
          attributes: [],
        },
        remark: {
          name: "remark",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: true,
          attributes: [],
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: true,
          attributes: [],
        },
      },
    },
    ReportedAsset: {
      name: "ReportedAsset",
      fields: {
        name: {
          name: "name",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        serial: {
          name: "serial",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        location: {
          name: "location",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
      },
    },
    EmbeddedTimerEvent: {
      name: "EmbeddedTimerEvent",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        taskID: {
          name: "taskID",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        eventCauseID: {
          name: "eventCauseID",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        embeddedCause: {
          name: "embeddedCause",
          isArray: false,
          type: {
            nonModel: "EmbeddedEventCause",
          },
          isRequired: false,
          attributes: [],
        },
        description: {
          name: "description",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        timestamp: {
          name: "timestamp",
          isArray: false,
          type: "AWSTimestamp",
          isRequired: true,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: true,
          attributes: [],
        },
        duration: {
          name: "duration",
          isArray: false,
          type: "AWSTimestamp",
          isRequired: true,
          attributes: [],
        },
        finalized: {
          name: "finalized",
          isArray: false,
          type: "Boolean",
          isRequired: true,
          attributes: [],
        },
        _version: {
          name: "_version",
          isArray: false,
          type: "Int",
          isRequired: true,
          attributes: [],
        },
      },
    },
    EmbeddedEventCause: {
      name: "EmbeddedEventCause",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        name: {
          name: "name",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        description: {
          name: "description",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        type: {
          name: "type",
          isArray: false,
          type: {
            enum: "TimerEventType",
          },
          isRequired: true,
          attributes: [],
        },
        _version: {
          name: "_version",
          isArray: false,
          type: "Int",
          isRequired: true,
          attributes: [],
        },
      },
    },
    LambdaAdvancedAssetSearchItem: {
      name: "LambdaAdvancedAssetSearchItem",
      fields: {
        asset: {
          name: "asset",
          isArray: false,
          type: {
            model: "Asset",
          },
          isRequired: false,
          attributes: [],
        },
        premises: {
          name: "premises",
          isArray: false,
          type: {
            model: "Premises",
          },
          isRequired: false,
          attributes: [],
        },
        category: {
          name: "category",
          isArray: false,
          type: {
            model: "MaterialCategory",
          },
          isRequired: false,
          attributes: [],
        },
      },
    },
    LambdaSupportNotificationsResult: {
      name: "LambdaSupportNotificationsResult",
      fields: {
        statusCode: {
          name: "statusCode",
          isArray: false,
          type: "Int",
          isRequired: true,
          attributes: [],
        },
        error: {
          name: "error",
          isArray: false,
          type: "Boolean",
          isRequired: true,
          attributes: [],
        },
        errorMessage: {
          name: "errorMessage",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        notificationSent: {
          name: "notificationSent",
          isArray: true,
          type: {
            nonModel: "NotificationSent",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
      },
    },
    NotificationSent: {
      name: "NotificationSent",
      fields: {
        toRole: {
          name: "toRole",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        succeded: {
          name: "succeded",
          isArray: false,
          type: "Boolean",
          isRequired: true,
          attributes: [],
        },
      },
    },
    lambdaQrGeneratorResponse: {
      name: "lambdaQrGeneratorResponse",
      fields: {
        assets: {
          name: "assets",
          isArray: true,
          type: {
            nonModel: "QrAsset",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        ids: {
          name: "ids",
          isArray: true,
          type: "String",
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        fileUrl: {
          name: "fileUrl",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
      },
    },
    QrAsset: {
      name: "QrAsset",
      fields: {
        assetID: {
          name: "assetID",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        trackingID: {
          name: "trackingID",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        incosistent: {
          name: "incosistent",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
      },
    },
    lambdaJobsPollingResponse: {
      name: "lambdaJobsPollingResponse",
      fields: {
        statusCode: {
          name: "statusCode",
          isArray: false,
          type: "Int",
          isRequired: true,
          attributes: [],
        },
        error: {
          name: "error",
          isArray: false,
          type: "Boolean",
          isRequired: true,
          attributes: [],
        },
        jobName: {
          name: "jobName",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        jobRunState: {
          name: "jobRunState",
          isArray: false,
          type: {
            enum: "JobRunState",
          },
          isRequired: false,
          attributes: [],
        },
        errorMessage: {
          name: "errorMessage",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
      },
    },
    DownloadAttachmentFilesResponse: {
      name: "DownloadAttachmentFilesResponse",
      fields: {
        statusCode: {
          name: "statusCode",
          isArray: false,
          type: "Int",
          isRequired: true,
          attributes: [],
        },
        error: {
          name: "error",
          isArray: false,
          type: "Boolean",
          isRequired: true,
          attributes: [],
        },
        errorMessage: {
          name: "errorMessage",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        fileUrl: {
          name: "fileUrl",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
      },
    },
    lambdaCreateGroupResponse: {
      name: "lambdaCreateGroupResponse",
      fields: {
        statusCode: {
          name: "statusCode",
          isArray: false,
          type: "Int",
          isRequired: true,
          attributes: [],
        },
        error: {
          name: "error",
          isArray: false,
          type: "Boolean",
          isRequired: true,
          attributes: [],
        },
        name: {
          name: "name",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        description: {
          name: "description",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        body: {
          name: "body",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        errorMessage: {
          name: "errorMessage",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
      },
    },
    lambdaDeleteGroupResponse: {
      name: "lambdaDeleteGroupResponse",
      fields: {
        statusCode: {
          name: "statusCode",
          isArray: false,
          type: "Int",
          isRequired: true,
          attributes: [],
        },
        error: {
          name: "error",
          isArray: false,
          type: "Boolean",
          isRequired: true,
          attributes: [],
        },
        name: {
          name: "name",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        body: {
          name: "body",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        errorMessage: {
          name: "errorMessage",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
      },
    },
    lambdaAddUserToGroupResponse: {
      name: "lambdaAddUserToGroupResponse",
      fields: {
        statusCode: {
          name: "statusCode",
          isArray: false,
          type: "Int",
          isRequired: true,
          attributes: [],
        },
        error: {
          name: "error",
          isArray: false,
          type: "Boolean",
          isRequired: true,
          attributes: [],
        },
        body: {
          name: "body",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        errorMessage: {
          name: "errorMessage",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
      },
    },
    lambdaRemoveUserFromGroupResponse: {
      name: "lambdaRemoveUserFromGroupResponse",
      fields: {
        statusCode: {
          name: "statusCode",
          isArray: false,
          type: "Int",
          isRequired: true,
          attributes: [],
        },
        error: {
          name: "error",
          isArray: false,
          type: "Boolean",
          isRequired: true,
          attributes: [],
        },
        body: {
          name: "body",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        errorMessage: {
          name: "errorMessage",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
      },
    },
    lambdaDeleteUserResponse: {
      name: "lambdaDeleteUserResponse",
      fields: {
        statusCode: {
          name: "statusCode",
          isArray: false,
          type: "Int",
          isRequired: true,
          attributes: [],
        },
        error: {
          name: "error",
          isArray: false,
          type: "Boolean",
          isRequired: true,
          attributes: [],
        },
        body: {
          name: "body",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        errorMessage: {
          name: "errorMessage",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
      },
    },
    lambdaCSVDataLoadResponse: {
      name: "lambdaCSVDataLoadResponse",
      fields: {
        statusCode: {
          name: "statusCode",
          isArray: false,
          type: "Int",
          isRequired: true,
          attributes: [],
        },
        error: {
          name: "error",
          isArray: false,
          type: "Boolean",
          isRequired: true,
          attributes: [],
        },
        errorMessage: {
          name: "errorMessage",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        body: {
          name: "body",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
      },
    },
    lambdaHandleIntegrationResponse: {
      name: "lambdaHandleIntegrationResponse",
      fields: {
        statusCode: {
          name: "statusCode",
          isArray: false,
          type: "Int",
          isRequired: true,
          attributes: [],
        },
        error: {
          name: "error",
          isArray: false,
          type: "Boolean",
          isRequired: true,
          attributes: [],
        },
        body: {
          name: "body",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        rowsAffected: {
          name: "rowsAffected",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        errorMessage: {
          name: "errorMessage",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
      },
    },
    PDF: {
      name: "PDF",
      fields: {
        name: {
          name: "name",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        url: {
          name: "url",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        file: {
          name: "file",
          isArray: false,
          type: {
            nonModel: "S3Object",
          },
          isRequired: false,
          attributes: [],
        },
      },
    },
  },
  version: "a92c76fe1804cc315c0cc15ebda26ae3",
};
