export const createPremise = /* GraphQL */ `
  mutation CreatePremises(
    $premiseName: String!
    $premiseCode: String!
    $premisesCompanyId: ID!
    $premisesAddressId: ID!
    $premisesCustodianId: ID!
  ) {
    createPremises(
      input: {
        name: $premiseName
        code: $premiseCode
        premisesCompanyId: $premisesCompanyId
        premisesAddressId: $premisesAddressId
        premisesCustodianId: $premisesCustodianId
      }
    ) {
      id
      name
      code
      _version
      _deleted
      _lastChangedAt
      company {
        name
        _version
        _deleted
      }
      custodian {
        name
        middlename
        lastnameP
        lastnameM
        _version
        _deleted
      }
      address {
        street
        number
        zipcode
        city
        settlement
        locality
        _version
        _deleted
      }
      createdAt
      updatedAt
    }
  }
`;

export default createPremise;
