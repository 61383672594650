import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

function TermsAndConditions(props) {
  const { section } = props;
  const classes = useStyles();
  const [sectionShow, setSectionShow] = useState(section);

  useEffect(() => {
    setSectionShow(section);
  }, [section]);

  return (
    <div className={classes.content}>
      {sectionShow === 0 && (
        <div>
          <Typography className={classes.title} variant="h4" gutterBottom component="div">
            Términos y Condiciones Generales de Servicio para Usuarios del Software Indika360
          </Typography>
          <p>
            <b>PRIMERA.</b>
            <u>Declaraciones Generales.</u>
          </p>
          <p>
            El presente documento es un acuerdo de términos y condiciones generales de servicio (en lo sucesivo, el{" "}
            <u>“Acuerdo”</u> o los <u>“Términos y Condiciones Generales”</u>) que rigen la prestación de los Servicios,
            el acceso y uso de la Plataforma (con las respectivas actualizaciones, modificaciones o cambios que pueda
            sufrir dicha Plataforma y/o las disposiciones de prestación de los Servicios) puesta a disposición por
            Indika360 (“La Plataforma”) es propiedad de Grupo Siayec S.A de C.V. (el “Prestador” o “Indika360”), una
            sociedad debidamente constituida de conformidad con las leyes de México. Los presentes Términos y
            Condiciones Generales regirán la prestación de los servicios descritos más adelante. Por lo tanto, al hacer
            el registro correspondiente se están aceptando expresamente los presentes Términos y Condiciones Generales,
            teniendo carácter obligatorio y vinculante entre Indika360 y el Usuario. En caso de no aceptarlos, deberá
            abstenerse de utilizar la plataforma.
          </p>
          <p>
            En caso de existir contradicciones o conflictos entre los presentes Términos y Condiciones Generales y
            cualquier otro Contrato de Servicios Particular celebrado posteriormente con el Usuario, las disposiciones
            establecidas en dicho contrato particular prevalecerán para todos los efectos legales a que haya lugar. Por
            lo tanto, en tanto no haya un Contrato de Prestación de Servicios Particular celebrado entre Indika360 y el
            Usuario, los presentes términos y condiciones regirán y serán el soporte jurídico de la relación entre los
            mismos.
          </p>
          <p>
            La finalidad de los presentes Términos y Condiciones es la de detallar y especificar los Servicios prestados
            por Indika360, la cual es una plataforma de automatización inteligente que ayuda en la toma de decisiones de
            forma oportuna. Indika360 llevará a cabo la ejecución de los Servicios de forma virtual, de tiempo en tiempo
            y mediante personal debidamente capacitado. La prestación de Servicios por parte de Indika360 siempre estará
            sujeta a los presentes Términos y Condiciones Generales.
          </p>
        </div>
      )}
      {sectionShow === 1 && (
        <div>
          <p>
            <b>SEGUNDA.</b>
            <u>Definiciones.</u>
          </p>
          <p>Los siguientes conceptos tendrán las definiciones siguientes:</p>
          <ol type="a">
            <li>
              <u>Usuario:</u>Cualquier persona registrada que cuente con usuario y contraseña para acceder a la
              Plataforma Digital, así como cualquier persona que utilice y/o navegue por la Plataforma Digital o turista
              Cibernético.
            </li>
            <li>
              <u>Cliente:</u>Cualquier organización dada de alta en la plataforma digital que cuente con usuarios
              registrados.
            </li>
            <li>
              <u>Licencia:</u>Licencia no exclusiva respecto al uso de La Plataforma o Software
            </li>
            <li>
              <u>Plataforma Digital o Software:</u>Se refiere al sistema de Indika360 por medio de los módulos de
              administración, captura, análisis, aplicaciones móviles y toda tecnología vinculada propiedad de
              indika360.
            </li>
            <li>
              <u>Servicio:</u>Servicio ofrecido mediante la Plataforma Digital, el cual permite a los clientes o
              usuarios registrados tener acceso a diferentes módulos y hacer uso de cada una de sus funcionalidades.
            </li>
          </ol>
        </div>
      )}
      {sectionShow === 2 && (
        <div>
          <p>
            <b>TERCERA.</b>
            <u>Los Servicios.</u>
          </p>
          <p>
            El Prestador otorga al Usuario una licencia no exclusiva de uso respecto a la plataforma, misma que podrá
            utilizar exclusivamente para los fines o roles que se le han previsto.
          </p>
          <p>
            El Software a su vez, se divide en distintos módulos disponibles y uso serán totalmente bajo la
            responsabilidad y discrecionalidad del Usuario, a través de los diferentes dispositivos destinados para
            ello.
          </p>
          <p>
            Así mismo, y derivado de dicha Licencia, el Prestador se obliga a prestar los servicios siguientes de manera
            profesional y sin subordinación alguna frente al Usuario (los “Servicios”):
          </p>
          <ul>
            <li>
              Activación de la plataforma y del Administrador mediante la creación de un subdominio o enlace único;
            </li>
            <li>Material de capacitación para el entendimiento de la plataforma</li>
            <li>Mantenimiento de la plataforma</li>
            <li>Soporte de forma remota telefónica, videoconferencia y/o por correo electrónico</li>
            <li>Actualización de los indicadores y plataformas</li>
            <li>
              Envío de material de apoyo y capacitación virtual (videos y guías) para el correcto uso de la plataforma
            </li>
            <li>Revisión preventiva de respaldos periódicos y bases de datos</li>
          </ul>
          <p>
            El Prestador se compromete a prestar los Servicios necesarios siempre dentro del alcance de los mismos,
            estando el Usuario consiente que por tratarse de tecnologías, siempre pueden ocurrir errores que están fuera
            del alcance o previsión del Prestador, mismo que en este acto se compromete a llevar a cabo sus mejores
            esfuerzos para ejecutar las correcciones necesarias en el menor tiempo que le sea razonablemente posible. El
            Usuario reconoce y acepta que no todo el desarrollo del Software pertenece al Prestador, por lo tanto, en
            caso de que la infraestructura de un tercero tuviera alguna falla, no será responsabilidad del Prestador.
          </p>
        </div>
      )}
      {sectionShow === 3 && (
        <div>
          <p>
            <b>CUARTA.</b>
            <u>Vigencia y Terminación.</u>
          </p>
          <p>
            Los presentes Términos y Condiciones Generales estarán vigentes a partir de su aceptación, respectivo pago y
            hasta que el Usuario deje de utilizar la Plataforma. Cualquier usuario que acceda y/o use el Software,
            estará sujeto automáticamente, ya sea de forma tácita o expresa, a los presentes Términos y Condiciones
            Generales, junto con las demás políticas y principios que rigen a Indika360 y que son incorporados al
            presente por referencia, o en su caso, en algún anexo. Cualquier persona que no acepte estos Términos y
            Condiciones Generales, los cuales tienen carácter obligatorio y vinculante, deberá abstenerse de utilizar el
            Software.
          </p>
          <p>
            Sin perjuicio de lo anterior, Indika360 podrá dar por terminada inmediatamente la prestación de Servicios en
            cualquier momento, así como suspender o limitar la prestación de los mismos, mediante una simple
            notificación por escrito, con al menos 5 (cinco) días naturales de anticipación en caso de que el Usuario:
          </p>
          <ol type="i">
            <li>
              Incumpla con cualquiera de sus obligaciones aquí establecidas, o en su caso, establecidas en algún anexo.
            </li>
            <br />
            <li>
              No haga el pago de las tarifas correspondiente en un plazo máximo de noventa (_90 _) días hábiles contados
              a partir de la fecha en la que se haya vencido el plazo para realizar el pago.
            </li>
            <br />
            <li>
              El usuario haga uso indebido del Software y/o la plataforma, incluyendo sin limitar, falsedad de datos,
              actos dolosos, fraudulentos o ilícitos.
            </li>
          </ol>
        </div>
      )}
      {sectionShow === 4 && (
        <div>
          <p>
            <b>QUINTA.</b>
            <u> Documentación del Usuario.</u>
          </p>
          <p>
            Los documentos y/o información necesaria para un correcto funcionamiento de La Plataforma Indika360.com son
            los siguientes:
          </p>
          <ul>
            <li>Catálogos</li>
            <li>Subdominios (en caso de ser necesario) </li>
            <li>Configuraciones para acceso a cuentas de terceros</li>
            <li>Los necesarios que vinculen la relación comercial para efectos de pago por uso de servicio</li>
            <li>Los necesarios que la plataforma digital requiera en su momento derivado de algún cambio</li>
          </ul>
          <p>No obstante lo anterior, cuando Indika360 lo requiera podrá pedir más información relativa al Usuario.</p>
        </div>
      )}
      {sectionShow === 5 && (
        <div>
          <p>
            <b>SEXTA.</b>
            <u>Pago y Forma de Pago.</u>
          </p>
          <p>
            El Usuario deberá pagar las tarifas correspondientes, monto especificado en la negociación comercial y
            confirmada mediante la orden de pago o similar.
          </p>
          <ul>
            <li>
              La facturación será de forma única por el pago anual correspondiente por los Servicios, o de manera
              mensual
            </li>
            <br />
            <li>
              El Usuario deberá pagar las tarifas por concepto de la prestación de los Servicios dentro de los 5 (cinco)
              días hábiles siguientes a haber recibido la factura correspondiente.
            </li>
            <br />
            <li>
              El pago de las tarifas se realizará a través de las formas de pago que Indika360 ponga a disposición del
              Usuario.
            </li>
            <br />
            <li>Los pagos realizados por los Servicios conforme a las tarifas establecidas no son reembolsables</li>
            <br />
            <li>
              Sin perjuicio de cualquier disposición en contrario, Indika360 se reserva el derecho de cambiar las
              tarifas de sus Servicios en cualquier momento. Para ello, se notificará al Usuario de cualquier cambio en
              las tarifas existentes con al menos 30 (treinta) días naturales previos a que el cambio de tarifa entre en
              vigor. En caso de que el Usuario no esté de acuerdo con el cambio de tarifa deberá manifestarlo por
              escrito a Indika360 con la finalidad de cancelar los Servicios que tenga contratados dentro del plazo que
              estipule el aviso respectivo. En caso de no manifestar su negativa dentro de dicho plazo o el hecho de que
              el Usuario, a través de su Administrador, continúe usando el Software y/o la Plataforma en general una vez
              que la nueva tarifa entre en vigor, se entenderá que está de acuerdo con el cambio de tarifa.
            </li>
          </ul>
        </div>
      )}
      {sectionShow === 6 && (
        <div>
          <p>
            <b>SÉPTIMA.</b>
            <u>Elegibilidad.</u>
          </p>
          <p>
            El Software únicamente podrá ser utilizado por personas mayores de edad, cualquier uso realizado por un
            menor de edad queda estrictamente prohibido. Los Usuarios al acceder a la Plataforma afirman y garantizan
            tener edad legal para contratar y estar en pleno uso de sus facultades y capacidades. Por el simple hecho de
            ser un usuario del Software se presumirá la mayoría de edad y capacidad para acceder o contratar.
          </p>
          <p>
            Asimismo, cualquier persona que acceda al Software y/o Plataforma, ya sea directamente o en representación
            de otra persona, incluyendo representantes legales del usuario, deberán hacerlo sujetándose a los Términos y
            Condiciones Generales aquí establecidos, así como la legislación aplicable y todos los demás avisos y
            políticas de Indika360.
          </p>
        </div>
      )}
      {sectionShow === 7 && (
        <div>
          <p>
            <b>OCTAVA.</b>
            <u>Territorio.</u>
          </p>
          <p>
            Los Servicios deberán entenderse prestados dentro de la República Mexicana sin importar en dónde fueron
            originados o solicitados. El Usuario acuerda indemnizar y mantener a Indika360 en paz y a salvo de cualquier
            responsabilidad, daño o perjuicio derivado de cualquier deuda, obligación, perjurio, reclamo, demanda,
            procedimiento o sentencia de cualquier naturaleza (incluyendo honorarios legales) que deriven, directa o
            indirectamente de las actividades del Usuario previstas en el presente Acuerdo, incluyendo a aquellos que
            deriven directa o indirectamente de cualquier reclamo respecto al uso del Software o la Marca “Indika360”
            por parte del Usuario conforme a los términos del presente Acuerdo.
          </p>
        </div>
      )}
      {sectionShow === 8 && (
        <div>
          <p>
            <b>NOVENA.</b>
            <u> Modificaciones y Cambios.</u>
          </p>
          <p>
            Los presentes Términos y Condiciones Generales podrán ser modificados, adicionados o actualizados en
            cualquier momento por Indika360, sin previo aviso y/o autorización del Usuario, mismos que se dan por
            aceptados automáticamente.
          </p>
        </div>
      )}
      {sectionShow === 9 && (
        <div>
          <p>
            <b>DÉCIMA.</b>
            <u> Modificaciones y Cambios.</u>
          </p>
          <p>
            El Usuario reconoce que el uso de la Plataforma Digital no le otorga ningún derecho de propiedad sobre el
            mismo. El mismo es de exclusiva propiedad de Indika360. El Usuario se obliga a no utilizar técnicas de
            ingeniería inversa, descompilación o desensamblaje, ni podrá replicar o modificar el Software. Indika360 se
            reserva todos sus derechos y acciones legales derivados de la propiedad intelectual del Software, por lo
            tanto, el Usuario deberá pagar las sanciones que correspondan por ley. El Usuario se obliga a no utilizar
            ningún robot, araña, aparato automático, programación especial o procesamiento manual para monitorear o
            copiar el Software o sus contenidos. El Usuario acepta no utilizar ningún tipo de aparato, existente o por
            inventar, que interfiera o entorpezca el funcionamiento adecuado del Software.
          </p>
        </div>
      )}
      {sectionShow === 10 && (
        <div>
          <p>
            <b>DÉCIMA PRIMERA.</b>
            <u>Propiedad de la Información en la Plataforma Digital.</u>
          </p>
          <p>
            El Usuario reconoce que toda la información capturada, almacenada y registrada en el Software es de su
            propiedad, por lo que libera a Indika360 de cualquier mal uso, transferencia, negligencia, reproducción o
            publicación en cualquier medio que se haga de la misma. El Usuario declara haber llenado el registro de
            usuarios de Indika360 de forma veraz, debiendo mantener su información actualizada y completa. Por lo tanto,
            el Usuario tiene derecho a pedir la eliminación total de los datos recabados en el Software. no utilizar
            ningún tipo de aparato, existente o por inventar, que interfiera o entorpezca el funcionamiento adecuado del
            Software.
          </p>

          <p>
            Cada Usuario cuenta con un subdominio o enlace único que le dará acceso al Software. Los datos capturados e
            ingresados al Software son propiedad del Usuario, por lo que será su responsabilidad dar el tratamiento
            correspondiente de conformidad con la Ley Federal de Protección de Datos Personales en Posesión de los
            Particulares y su Reglamento, así como lo establecido en el Contrato.
          </p>
        </div>
      )}
      {sectionShow === 11 && (
        <div>
          <p>
            <b>DÉCIMA SEGUNDA.</b>
            <u>Soporte.</u>
          </p>
          <p>
            Indika360 brindará el soporte siendo las vías de información válidas: correo electrónico y/o teléfono
            oficial. En caso de que el Usuario no tome en consideración las recomendaciones y/u observaciones hechas por
            Indika360 respecto del uso del Software, Indika360 no será responsable de ningún tipo de daño, perjuicio o
            falla en el Software. Los Servicios no incluyen captura, administración y gestión de la herramienta, diseño
            de reportes, modificaciones de fondo, cálculos, ni interpretación de resultados.
          </p>
        </div>
      )}
      {sectionShow === 12 && (
        <div>
          <p>
            <b>DÉCIMA TERCERA.</b>
            <u>Información ingresada al Software.</u>
          </p>
          <p>
            Para registrarse como Usuario se deberá proporcionar: (i) nombre completo, (ii) teléfono, (iii) fecha de
            nacimiento; y (iv) correo electrónico Indika360 enviará un correo electrónico proporcionando la verificación
            de identidad y se deberán seguir las instrucciones en él contenidas. Indika360 proporcionará un Usuario y
            contraseña de identificación en el sistema para autentificar la identidad del Usuario, misma que será
            responsable de todos los accesos al Software con su Clave de Usuario. En caso de que el Usuario tenga
            conocimiento o crea que ha habido cualquier violación de seguridad, tal como el robo o uso no autorizado de
            su Clave de Usuario y Contraseña o de cualquier información de su cuenta en Indika360 deberá de notificarlo
            inmediatamente a Indika360.
          </p>
        </div>
      )}
      {sectionShow === 13 && (
        <div>
          <p>
            <b>DÉCIMA CUARTA.</b>
            <u> Registro como Usuario.</u>
          </p>
          <p>
            Queda estrictamente prohibido acosar a cualquier Usuario, mediante spam, mensajes con contenido ofensivo,
            amenazas, sexismo o discriminación ya sea por cuestión de raza, orientación sexual, género o lugar de
            procedencia. Se prohíbe la difusión de información falsa, o personificar o hacerse pasar por una persona
            distinta a la cual efectuó el registro de Usuario.
          </p>
          <p>
            La información ingresada por un Usuario no es responsabilidad de Indika360, siendo estos los únicos
            responsables acerca de la veracidad de la misma. Queda prohibido enviar, introducir o ejecutar cualquier
            información, programa o secuencia que contenga virus, caballos de Troya, gusanos, bombas de tiempo, huevos
            de pascua u otras rutinas de programación informática que puedan dañar, interferir negativamente,
            interceptar o expropiar cualquier sistema, dato o información personal; inyectar o introducir un enlace
            directo o indirectamente a cualquier otro sitio web salvo que sea explícitamente solicitado por algún
            formulario de Indika360
          </p>
        </div>
      )}
      {sectionShow === 14 && (
        <div>
          <p>
            <b>DÉCIMA QUINTA.</b>
            <u>Obligaciones del Usuario.</u>
          </p>
          <p>
            Queda prohibido interferir la Plataforma, los servidores o redes conectadas a los Servicios, transmitir o
            introducir cualquier material que contenga virus de software o cualquier otro código informático, archivos o
            programas diseñados para interrumpir, destruir o limitar la funcionalidad de cualquier software, hardware o
            equipo de telecomunicaciones. Realizar una copia &quot;marco&quot; o &quot;espejo&quot; de cualquier parte
            del Servicio, o código sin previa autorización por escrito; modificar, adaptar, sublicenciar, traducir,
            vender, realizar ingeniería inversa, descifrar, descompilar cualquier parte del Software.{" "}
          </p>
          <p>
            En general, los Usuarios no pueden utilizar ningún tipo de aparato, software o rutina existente o por
            inventar, que interfiera o entorpezca con el funcionamiento adecuado de la Plataforma y se obligan a no
            utilizar técnicas de ingeniería inversa, descompilación, y desensamblaje, ni a modificar o traducir el sitio
            web, toda vez que el mismo goza de la protección que las leyes y tratados de propiedad industrial e
            intelectual establecen.
          </p>
        </div>
      )}
      {sectionShow === 15 && (
        <div>
          <p>
            <b>DÉCIMA SEXTA.</b>
            <u>Obligaciones del Prestador.</u>
          </p>
          <p>
            El Contenido de la Plataforma no podrá ser copiado, reproducido, republicado, enviado, transmitido,
            transcrito, traducido, almacenado, alterado, descargado o distribuido en ningún medio inventado o por
            inventar, ni por ningún motivo, a excepción del material que Indika360 expresamente provea al Usuario para
            este fin. Dicha información es propiedad de Indika360 por lo que el Usuario deberá eliminarla de cualquier
            medio físico o electrónico una vez que haya cumplido con su uso. Modificación, Actualizaciones y Suspensión
            del Servicio.
          </p>
        </div>
      )}
      {sectionShow === 16 && (
        <div>
          <p>
            <b>DÉCIMA SÉPTIMA.</b>
            <u>Mantenimiento al Software y Plataforma.</u>
          </p>
          <p>
            Indika360 sin previo aviso podrá suspender el Servicio al Usuario para dar mantenimiento a la Plataforma. El
            acceso al Software no implica ningún tipo de garantía por parte de Indika360 respecto a la calidad,
            veracidad, exactitud, licitud, actualidad o vigencia, así como la utilidad o adecuación a finalidad alguna
            de quien acceda a ella.
          </p>
        </div>
      )}
      {sectionShow === 17 && (
        <div>
          <p>
            <b>DÉCIMA OCTAVA.</b>
            <u>Excluyentes de Responsabilidad.</u>
          </p>
          <p>
            Tanto el acceso a la Plataforma, como su uso se efectúan bajo la exclusiva responsabilidad del Usuario. La
            Plataforma podrá contener enlaces a páginas que se encuentran bajo responsabilidad de terceros. Por lo
            tanto, Indika360 no puede controlar o supervisar en modo alguno dicho contenido. Indika360 excluye, con toda
            la extensión permitida por la Ley, cualquier responsabilidad de cualquier clase, derivada de la existencia o
            posibilidad de acceso a dichos contenidos.
          </p>
          <p>
            Indika360 emplea razonablemente los medios a su alcance para proveer de sistemas de seguridad que protejan
            de forma razonable sus sistemas y los datos contenidos en los mismos contra ataques deliberados, software
            maligno, etc. No obstante, el Usuario entiende y acepta los aspectos que implica la prestación de servicios
            a través de Internet dado el carácter abierto, descentralizado y global de esta red de comunicaciones. Por
            este motivo Indika360 NO garantiza la inexpugnabilidad de sus sistemas de seguridad ni la privacidad de la
            información alojada en los mismos. Indika360 excluye, con toda la extensión permitida por la Ley, cualquier
            responsabilidad de cualquier clase por fallos de seguridad en el Software y las consecuencias que de ellos
            pudieran derivarse. La Plataforma de Indika360 puede llegar a emplear ‘cookies de sesión’ con finalidades de
            estadísticas –propios o ajenos-, empleados en la Plataforma Digital de Indika360 podrán utilizar ‘cookies’
            con la única finalidad de establecer métricas y patrones de uso de la Plataforma. En ningún caso Indika360
            utiliza o accede a las ‘cookies’ para finalidades distintas de las enunciadas
          </p>
          <p>
            Con carácter general los diferentes servicios estarán disponibles ininterrumpidamente en Internet, sin
            embargo, el Usuario queda informado de que Indika360 NO garantiza en modo alguno esta continuidad debido a
            la naturaleza del medio a través del que se presta. El Usuario entiende y acepta los aspectos que implica la
            prestación de servicios a través de Internet dado el carácter abierto, descentralizado y global de la red.
            Así, por ejemplo, a título enunciativo no exhaustivo, a continuación, se recogen algunas de las situaciones
            que pueden interrumpir temporalmente la prestación de dichos servicios: i. Tareas de mantenimiento en los
            servidores y/o líneas de datos ii. Averías en los servidores y/o líneas de datos iii. Ataques deliberados
            contra la seguridad e integridad de la Plataforma Digital iv. Virus informáticos o presencia de código
            maligno en las páginas que conforman o son accesibles desde la Plataforma Digital. Indika360 excluye, con
            toda la extensión permitida por la Ley, cualquier responsabilidad de cualquier clase derivada de la
            interrupción temporal en la disponibilidad de la Plataforma Digital en la red Internet. El Usuario queda
            informado de que Indika360 podrá en cualquier momento, a su solo criterio y sin obligación alguna de aviso
            previo, suspender con carácter definitivo la presencia de la Plataforma Digital en la red Internet y por
            tanto de los servicios prestados desde, o a través del mismo, sin perjuicio de lo que se hubiere dispuesto
            al respecto en las correspondientes Condiciones Particulares. Indika360 queda eximida de cualquier tipo de
            responsabilidad de cualquier clase derivada de las consecuencias que pudieran producirse por la suspensión
            definitiva en la prestación de dichos servicios.
          </p>
        </div>
      )}
      {sectionShow === 18 && (
        <div>
          <p>
            <b>DÉCIMA NOVENA.</b>
            <u>Administradores y usuarios.</u>
          </p>
          <p>
            Se deberá nombrar a uno o varios usuario Administradores, que será quien gestione las altas y bajas de
            usuarios, así como el principal responsable de gestionar y promover le correcto uso de la herramienta.
          </p>
          <p>
            Cada usuario contará con contraseña para su acceso a la Plataforma. Dichos usuarios serán responsables de
            coordinar internamente todo lo relacionado con la Plataforma y su correcto uso, por lo tanto, serán
            responsables de los accesos y uso que se le dé a la información recabada, capturada, almacenada y
            administrada, liberando en este acto al Prestador de cualquier responsabilidad legal derivada de la
            transferencia, reproducción, recreación, publicación por cualquier medio, fuga de información o en general
            mal uso que se le dé a la misma.
          </p>
        </div>
      )}
      {sectionShow === 19 && (
        <div>
          <p>
            <b>VIGÉSIMA.</b>
            <u>Relación Comercial.</u>
          </p>
          <p>
            Ninguna disposición contemplada en los presentes Términos y Condiciones Generales constituye de manera
            alguna cualquier tipo de sociedad, asociación, comisión mercantil o relación laboral entre Indika360 y los
            usuarios.
          </p>
        </div>
      )}
      {sectionShow === 20 && (
        <div>
          <p>
            <b>VIGÉSIMA PRIMERA.</b>
            <u>Domicilio y Notificaciones.</u>
          </p>
          <p>
            Para cualquier tipo de notificación, ya sea judicial o extrajudicial, el Prestador establece como su
            domicilio el ubicado en Xola 1012 Narvarte Poniente, Benito Juárez, 03020 Ciudad de México, CDMX. O en esta
            dirección de correo electrónico general: contacto@indika360.com
          </p>
        </div>
      )}
      {sectionShow === 21 && (
        <div>
          <p>
            <b>VIGÉSIMA SEGUNDA.</b>
            <u>Ley y Jurisdicción Aplicables.</u>
          </p>
          <p>
            Los presentes Términos y Condiciones Generales se regirán por la ley aplicable en México y se someten a la
            jurisdicción y competencia de los tribunales locales de la Ciudad de México para efecto de la interpretación
            y cumplimiento de estos.
          </p>
        </div>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  title: {
    color: "#084d81",
    margin: "0.5em 0",
  },
  content: {
    fontSize: "1rem",
    textAlign: "justify",

    [theme.breakpoints.down("xs")]: {
      fontSize: "0.875rem",
    },
  },
  list: {
    "ol > li::marker": {
      content: '"(" counter(list, lower-roman) ")a0"',
    },
  },
}));

TermsAndConditions.propTypes = {
  section: PropTypes.number,
};

TermsAndConditions.defaultProps = {
  section: 0,
};

export default TermsAndConditions;
