/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const lambdaCreateGroup = /* GraphQL */ `
  mutation LambdaCreateGroup($input: lambdaCreateGroupInput) {
    lambdaCreateGroup(input: $input) {
      statusCode
      error
      name
      description
      body
      errorMessage
    }
  }
`;
export const lambdaDeleteGroup = /* GraphQL */ `
  mutation LambdaDeleteGroup($input: lambdaDeleteGroupInput) {
    lambdaDeleteGroup(input: $input) {
      statusCode
      error
      name
      body
      errorMessage
    }
  }
`;
export const lambdaAddUserToGroup = /* GraphQL */ `
  mutation LambdaAddUserToGroup($input: lambdaAddUserToGroupInput) {
    lambdaAddUserToGroup(input: $input) {
      statusCode
      error
      body
      errorMessage
    }
  }
`;
export const lambdaRemoveUserFromGroup = /* GraphQL */ `
  mutation LambdaRemoveUserFromGroup($input: lambdaRemoveUserFromGroupInput) {
    lambdaRemoveUserFromGroup(input: $input) {
      statusCode
      error
      body
      errorMessage
    }
  }
`;
export const lambdaDeleteUser = /* GraphQL */ `
  mutation LambdaDeleteUser($input: lambdaDeleteUserInput) {
    lambdaDeleteUser(input: $input) {
      statusCode
      error
      body
      errorMessage
    }
  }
`;
export const lambdaSyncContpaQi = /* GraphQL */ `
  mutation LambdaSyncContpaQi($input: lambdaSyncCONTPAQiInput) {
    lambdaSyncCONTPAQi(input: $input) {
      statusCode
      error
      body
      rowsAffected
      errorMessage
    }
  }
`;
export const lambdaCsvDataLoad = /* GraphQL */ `
  mutation LambdaCsvDataLoad($input: lambdaCSVDataLoadInput) {
    lambdaCSVDataLoad(input: $input) {
      statusCode
      error
      errorMessage
      body
    }
  }
`;
export const lambdaHandleIntegration = /* GraphQL */ `
  mutation LambdaHandleIntegration($input: lambdaHandleIntegrationInput) {
    lambdaHandleIntegration(input: $input) {
      statusCode
      error
      body
      rowsAffected
      errorMessage
    }
  }
`;
export const createSetting = /* GraphQL */ `
  mutation CreateSetting(
    $input: CreateSettingInput!
    $condition: ModelSettingConditionInput
  ) {
    createSetting(input: $input, condition: $condition) {
      id
      key
      value
      status
      name
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateSetting = /* GraphQL */ `
  mutation UpdateSetting(
    $input: UpdateSettingInput!
    $condition: ModelSettingConditionInput
  ) {
    updateSetting(input: $input, condition: $condition) {
      id
      key
      value
      status
      name
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteSetting = /* GraphQL */ `
  mutation DeleteSetting(
    $input: DeleteSettingInput!
    $condition: ModelSettingConditionInput
  ) {
    deleteSetting(input: $input, condition: $condition) {
      id
      key
      value
      status
      name
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createIntegrationRequest = /* GraphQL */ `
  mutation CreateIntegrationRequest(
    $input: CreateIntegrationRequestInput!
    $condition: ModelIntegrationRequestConditionInput
  ) {
    createIntegrationRequest(input: $input, condition: $condition) {
      id
      integrationID
      status
      email
      firstname
      middlename
      lastnamep
      lastnamem
      organization
      comments
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateIntegrationRequest = /* GraphQL */ `
  mutation UpdateIntegrationRequest(
    $input: UpdateIntegrationRequestInput!
    $condition: ModelIntegrationRequestConditionInput
  ) {
    updateIntegrationRequest(input: $input, condition: $condition) {
      id
      integrationID
      status
      email
      firstname
      middlename
      lastnamep
      lastnamem
      organization
      comments
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteIntegrationRequest = /* GraphQL */ `
  mutation DeleteIntegrationRequest(
    $input: DeleteIntegrationRequestInput!
    $condition: ModelIntegrationRequestConditionInput
  ) {
    deleteIntegrationRequest(input: $input, condition: $condition) {
      id
      integrationID
      status
      email
      firstname
      middlename
      lastnamep
      lastnamem
      organization
      comments
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    createCompany(input: $input, condition: $condition) {
      id
      name
      description
      enabled
      owner
      projects {
        items {
          id
          code
          name
          description
          companyID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      id
      name
      description
      enabled
      owner
      projects {
        items {
          id
          code
          name
          description
          companyID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany(
    $input: DeleteCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    deleteCompany(input: $input, condition: $condition) {
      id
      name
      description
      enabled
      owner
      projects {
        items {
          id
          code
          name
          description
          companyID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createTeam = /* GraphQL */ `
  mutation CreateTeam(
    $input: CreateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    createTeam(input: $input, condition: $condition) {
      id
      name
      description
      head
      users {
        items {
          id
          head
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateTeam = /* GraphQL */ `
  mutation UpdateTeam(
    $input: UpdateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    updateTeam(input: $input, condition: $condition) {
      id
      name
      description
      head
      users {
        items {
          id
          head
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteTeam = /* GraphQL */ `
  mutation DeleteTeam(
    $input: DeleteTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    deleteTeam(input: $input, condition: $condition) {
      id
      name
      description
      head
      users {
        items {
          id
          head
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createRole = /* GraphQL */ `
  mutation CreateRole(
    $input: CreateRoleInput!
    $condition: ModelRoleConditionInput
  ) {
    createRole(input: $input, condition: $condition) {
      id
      name
      cognitoGroup
      users {
        items {
          id
          userID
          roleID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateRole = /* GraphQL */ `
  mutation UpdateRole(
    $input: UpdateRoleInput!
    $condition: ModelRoleConditionInput
  ) {
    updateRole(input: $input, condition: $condition) {
      id
      name
      cognitoGroup
      users {
        items {
          id
          userID
          roleID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteRole = /* GraphQL */ `
  mutation DeleteRole(
    $input: DeleteRoleInput!
    $condition: ModelRoleConditionInput
  ) {
    deleteRole(input: $input, condition: $condition) {
      id
      name
      cognitoGroup
      users {
        items {
          id
          userID
          roleID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createUserRole = /* GraphQL */ `
  mutation CreateUserRole(
    $input: CreateUserRoleInput!
    $condition: ModelUserRoleConditionInput
  ) {
    createUserRole(input: $input, condition: $condition) {
      id
      userID
      roleID
      user {
        id
        name
        middlename
        lastnameP
        lastnameM
        email
        phone
        extension
        status
        roles {
          nextToken
          startedAt
        }
        teams {
          nextToken
          startedAt
        }
        tasks {
          nextToken
          startedAt
        }
        tasksSupervised {
          nextToken
          startedAt
        }
        logs {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      role {
        id
        name
        cognitoGroup
        users {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateUserRole = /* GraphQL */ `
  mutation UpdateUserRole(
    $input: UpdateUserRoleInput!
    $condition: ModelUserRoleConditionInput
  ) {
    updateUserRole(input: $input, condition: $condition) {
      id
      userID
      roleID
      user {
        id
        name
        middlename
        lastnameP
        lastnameM
        email
        phone
        extension
        status
        roles {
          nextToken
          startedAt
        }
        teams {
          nextToken
          startedAt
        }
        tasks {
          nextToken
          startedAt
        }
        tasksSupervised {
          nextToken
          startedAt
        }
        logs {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      role {
        id
        name
        cognitoGroup
        users {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserRole = /* GraphQL */ `
  mutation DeleteUserRole(
    $input: DeleteUserRoleInput!
    $condition: ModelUserRoleConditionInput
  ) {
    deleteUserRole(input: $input, condition: $condition) {
      id
      userID
      roleID
      user {
        id
        name
        middlename
        lastnameP
        lastnameM
        email
        phone
        extension
        status
        roles {
          nextToken
          startedAt
        }
        teams {
          nextToken
          startedAt
        }
        tasks {
          nextToken
          startedAt
        }
        tasksSupervised {
          nextToken
          startedAt
        }
        logs {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      role {
        id
        name
        cognitoGroup
        users {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createUserTeams = /* GraphQL */ `
  mutation CreateUserTeams(
    $input: CreateUserTeamsInput!
    $condition: ModelUserTeamsConditionInput
  ) {
    createUserTeams(input: $input, condition: $condition) {
      id
      user {
        id
        name
        middlename
        lastnameP
        lastnameM
        email
        phone
        extension
        status
        roles {
          nextToken
          startedAt
        }
        teams {
          nextToken
          startedAt
        }
        tasks {
          nextToken
          startedAt
        }
        tasksSupervised {
          nextToken
          startedAt
        }
        logs {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      team {
        id
        name
        description
        head
        users {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      head
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateUserTeams = /* GraphQL */ `
  mutation UpdateUserTeams(
    $input: UpdateUserTeamsInput!
    $condition: ModelUserTeamsConditionInput
  ) {
    updateUserTeams(input: $input, condition: $condition) {
      id
      user {
        id
        name
        middlename
        lastnameP
        lastnameM
        email
        phone
        extension
        status
        roles {
          nextToken
          startedAt
        }
        teams {
          nextToken
          startedAt
        }
        tasks {
          nextToken
          startedAt
        }
        tasksSupervised {
          nextToken
          startedAt
        }
        logs {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      team {
        id
        name
        description
        head
        users {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      head
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserTeams = /* GraphQL */ `
  mutation DeleteUserTeams(
    $input: DeleteUserTeamsInput!
    $condition: ModelUserTeamsConditionInput
  ) {
    deleteUserTeams(input: $input, condition: $condition) {
      id
      user {
        id
        name
        middlename
        lastnameP
        lastnameM
        email
        phone
        extension
        status
        roles {
          nextToken
          startedAt
        }
        teams {
          nextToken
          startedAt
        }
        tasks {
          nextToken
          startedAt
        }
        tasksSupervised {
          nextToken
          startedAt
        }
        logs {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      team {
        id
        name
        description
        head
        users {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      head
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createUserTask = /* GraphQL */ `
  mutation CreateUserTask(
    $input: CreateUserTaskInput!
    $condition: ModelUserTaskConditionInput
  ) {
    createUserTask(input: $input, condition: $condition) {
      id
      userID
      taskID
      user {
        id
        name
        middlename
        lastnameP
        lastnameM
        email
        phone
        extension
        status
        roles {
          nextToken
          startedAt
        }
        teams {
          nextToken
          startedAt
        }
        tasks {
          nextToken
          startedAt
        }
        tasksSupervised {
          nextToken
          startedAt
        }
        logs {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      task {
        id
        status
        startDate
        endDate
        startTime
        endTime
        startedAt
        startedBy
        startLocation {
          longitude
          latitude
        }
        endedAt
        endedBy
        endLocation {
          longitude
          latitude
        }
        users {
          nextToken
          startedAt
        }
        buildingLocation {
          id
          name
          code
          buildingBlock
          floor
          area
          status
          premisesID
          owner
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        taskType {
          id
          name
          description
          owner
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        supervisor {
          id
          name
          middlename
          lastnameP
          lastnameM
          email
          phone
          extension
          status
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        assets {
          nextToken
          startedAt
        }
        attachments {
          nextToken
          startedAt
        }
        notes {
          nextToken
          startedAt
        }
        externalTicketId
        priority
        level
        rating
        targetResolutionTime
        deliveryInstallment
        remarks {
          nextToken
          startedAt
        }
        taskTicketId
        ticket {
          id
          subject
          description
          requestType
          impact
          impactDetails
          status
          mode
          supportType
          urgency
          priority
          serviceCategory
          serviceSubcategory
          rating
          ratingComment
          verificatorName
          ticketPremisesId
          ticketRequesterId
          ticketAssetId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        owner
        deleted
        project {
          id
          code
          name
          description
          companyID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      head
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateUserTask = /* GraphQL */ `
  mutation UpdateUserTask(
    $input: UpdateUserTaskInput!
    $condition: ModelUserTaskConditionInput
  ) {
    updateUserTask(input: $input, condition: $condition) {
      id
      userID
      taskID
      user {
        id
        name
        middlename
        lastnameP
        lastnameM
        email
        phone
        extension
        status
        roles {
          nextToken
          startedAt
        }
        teams {
          nextToken
          startedAt
        }
        tasks {
          nextToken
          startedAt
        }
        tasksSupervised {
          nextToken
          startedAt
        }
        logs {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      task {
        id
        status
        startDate
        endDate
        startTime
        endTime
        startedAt
        startedBy
        startLocation {
          longitude
          latitude
        }
        endedAt
        endedBy
        endLocation {
          longitude
          latitude
        }
        users {
          nextToken
          startedAt
        }
        buildingLocation {
          id
          name
          code
          buildingBlock
          floor
          area
          status
          premisesID
          owner
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        taskType {
          id
          name
          description
          owner
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        supervisor {
          id
          name
          middlename
          lastnameP
          lastnameM
          email
          phone
          extension
          status
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        assets {
          nextToken
          startedAt
        }
        attachments {
          nextToken
          startedAt
        }
        notes {
          nextToken
          startedAt
        }
        externalTicketId
        priority
        level
        rating
        targetResolutionTime
        deliveryInstallment
        remarks {
          nextToken
          startedAt
        }
        taskTicketId
        ticket {
          id
          subject
          description
          requestType
          impact
          impactDetails
          status
          mode
          supportType
          urgency
          priority
          serviceCategory
          serviceSubcategory
          rating
          ratingComment
          verificatorName
          ticketPremisesId
          ticketRequesterId
          ticketAssetId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        owner
        deleted
        project {
          id
          code
          name
          description
          companyID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      head
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserTask = /* GraphQL */ `
  mutation DeleteUserTask(
    $input: DeleteUserTaskInput!
    $condition: ModelUserTaskConditionInput
  ) {
    deleteUserTask(input: $input, condition: $condition) {
      id
      userID
      taskID
      user {
        id
        name
        middlename
        lastnameP
        lastnameM
        email
        phone
        extension
        status
        roles {
          nextToken
          startedAt
        }
        teams {
          nextToken
          startedAt
        }
        tasks {
          nextToken
          startedAt
        }
        tasksSupervised {
          nextToken
          startedAt
        }
        logs {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      task {
        id
        status
        startDate
        endDate
        startTime
        endTime
        startedAt
        startedBy
        startLocation {
          longitude
          latitude
        }
        endedAt
        endedBy
        endLocation {
          longitude
          latitude
        }
        users {
          nextToken
          startedAt
        }
        buildingLocation {
          id
          name
          code
          buildingBlock
          floor
          area
          status
          premisesID
          owner
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        taskType {
          id
          name
          description
          owner
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        supervisor {
          id
          name
          middlename
          lastnameP
          lastnameM
          email
          phone
          extension
          status
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        assets {
          nextToken
          startedAt
        }
        attachments {
          nextToken
          startedAt
        }
        notes {
          nextToken
          startedAt
        }
        externalTicketId
        priority
        level
        rating
        targetResolutionTime
        deliveryInstallment
        remarks {
          nextToken
          startedAt
        }
        taskTicketId
        ticket {
          id
          subject
          description
          requestType
          impact
          impactDetails
          status
          mode
          supportType
          urgency
          priority
          serviceCategory
          serviceSubcategory
          rating
          ratingComment
          verificatorName
          ticketPremisesId
          ticketRequesterId
          ticketAssetId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        owner
        deleted
        project {
          id
          code
          name
          description
          companyID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      head
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      name
      middlename
      lastnameP
      lastnameM
      email
      phone
      extension
      status
      roles {
        items {
          id
          userID
          roleID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      teams {
        items {
          id
          head
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      tasks {
        items {
          id
          userID
          taskID
          head
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      tasksSupervised {
        items {
          id
          status
          startDate
          endDate
          startTime
          endTime
          startedAt
          startedBy
          endedAt
          endedBy
          externalTicketId
          priority
          level
          rating
          targetResolutionTime
          deliveryInstallment
          taskTicketId
          owner
          deleted
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      logs {
        items {
          id
          userID
          dataJSON
          dataType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      name
      middlename
      lastnameP
      lastnameM
      email
      phone
      extension
      status
      roles {
        items {
          id
          userID
          roleID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      teams {
        items {
          id
          head
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      tasks {
        items {
          id
          userID
          taskID
          head
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      tasksSupervised {
        items {
          id
          status
          startDate
          endDate
          startTime
          endTime
          startedAt
          startedBy
          endedAt
          endedBy
          externalTicketId
          priority
          level
          rating
          targetResolutionTime
          deliveryInstallment
          taskTicketId
          owner
          deleted
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      logs {
        items {
          id
          userID
          dataJSON
          dataType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      name
      middlename
      lastnameP
      lastnameM
      email
      phone
      extension
      status
      roles {
        items {
          id
          userID
          roleID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      teams {
        items {
          id
          head
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      tasks {
        items {
          id
          userID
          taskID
          head
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      tasksSupervised {
        items {
          id
          status
          startDate
          endDate
          startTime
          endTime
          startedAt
          startedBy
          endedAt
          endedBy
          externalTicketId
          priority
          level
          rating
          targetResolutionTime
          deliveryInstallment
          taskTicketId
          owner
          deleted
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      logs {
        items {
          id
          userID
          dataJSON
          dataType
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createTaskType = /* GraphQL */ `
  mutation CreateTaskType(
    $input: CreateTaskTypeInput!
    $condition: ModelTaskTypeConditionInput
  ) {
    createTaskType(input: $input, condition: $condition) {
      id
      name
      description
      owner
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateTaskType = /* GraphQL */ `
  mutation UpdateTaskType(
    $input: UpdateTaskTypeInput!
    $condition: ModelTaskTypeConditionInput
  ) {
    updateTaskType(input: $input, condition: $condition) {
      id
      name
      description
      owner
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteTaskType = /* GraphQL */ `
  mutation DeleteTaskType(
    $input: DeleteTaskTypeInput!
    $condition: ModelTaskTypeConditionInput
  ) {
    deleteTaskType(input: $input, condition: $condition) {
      id
      name
      description
      owner
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createPremises = /* GraphQL */ `
  mutation CreatePremises(
    $input: CreatePremisesInput!
    $condition: ModelPremisesConditionInput
  ) {
    createPremises(input: $input, condition: $condition) {
      id
      name
      owner
      company {
        id
        name
        description
        enabled
        owner
        projects {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      custodian {
        id
        name
        middlename
        lastnameP
        lastnameM
        email
        phone
        extension
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      address {
        id
        street
        number
        zipcode
        city
        settlement
        locality
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      buildingLocations {
        items {
          id
          name
          code
          buildingBlock
          floor
          area
          status
          premisesID
          owner
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updatePremises = /* GraphQL */ `
  mutation UpdatePremises(
    $input: UpdatePremisesInput!
    $condition: ModelPremisesConditionInput
  ) {
    updatePremises(input: $input, condition: $condition) {
      id
      name
      owner
      company {
        id
        name
        description
        enabled
        owner
        projects {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      custodian {
        id
        name
        middlename
        lastnameP
        lastnameM
        email
        phone
        extension
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      address {
        id
        street
        number
        zipcode
        city
        settlement
        locality
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      buildingLocations {
        items {
          id
          name
          code
          buildingBlock
          floor
          area
          status
          premisesID
          owner
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deletePremises = /* GraphQL */ `
  mutation DeletePremises(
    $input: DeletePremisesInput!
    $condition: ModelPremisesConditionInput
  ) {
    deletePremises(input: $input, condition: $condition) {
      id
      name
      owner
      company {
        id
        name
        description
        enabled
        owner
        projects {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      custodian {
        id
        name
        middlename
        lastnameP
        lastnameM
        email
        phone
        extension
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      address {
        id
        street
        number
        zipcode
        city
        settlement
        locality
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      buildingLocations {
        items {
          id
          name
          code
          buildingBlock
          floor
          area
          status
          premisesID
          owner
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createBuildingLocation = /* GraphQL */ `
  mutation CreateBuildingLocation(
    $input: CreateBuildingLocationInput!
    $condition: ModelBuildingLocationConditionInput
  ) {
    createBuildingLocation(input: $input, condition: $condition) {
      id
      name
      code
      buildingBlock
      floor
      area
      status
      premisesID
      premises {
        id
        name
        owner
        company {
          id
          name
          description
          enabled
          owner
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        custodian {
          id
          name
          middlename
          lastnameP
          lastnameM
          email
          phone
          extension
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        address {
          id
          street
          number
          zipcode
          city
          settlement
          locality
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        buildingLocations {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      owner
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateBuildingLocation = /* GraphQL */ `
  mutation UpdateBuildingLocation(
    $input: UpdateBuildingLocationInput!
    $condition: ModelBuildingLocationConditionInput
  ) {
    updateBuildingLocation(input: $input, condition: $condition) {
      id
      name
      code
      buildingBlock
      floor
      area
      status
      premisesID
      premises {
        id
        name
        owner
        company {
          id
          name
          description
          enabled
          owner
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        custodian {
          id
          name
          middlename
          lastnameP
          lastnameM
          email
          phone
          extension
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        address {
          id
          street
          number
          zipcode
          city
          settlement
          locality
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        buildingLocations {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      owner
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteBuildingLocation = /* GraphQL */ `
  mutation DeleteBuildingLocation(
    $input: DeleteBuildingLocationInput!
    $condition: ModelBuildingLocationConditionInput
  ) {
    deleteBuildingLocation(input: $input, condition: $condition) {
      id
      name
      code
      buildingBlock
      floor
      area
      status
      premisesID
      premises {
        id
        name
        owner
        company {
          id
          name
          description
          enabled
          owner
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        custodian {
          id
          name
          middlename
          lastnameP
          lastnameM
          email
          phone
          extension
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        address {
          id
          street
          number
          zipcode
          city
          settlement
          locality
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        buildingLocations {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      owner
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createProject = /* GraphQL */ `
  mutation CreateProject(
    $input: CreateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    createProject(input: $input, condition: $condition) {
      id
      code
      name
      description
      companyID
      company {
        id
        name
        description
        enabled
        owner
        projects {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateProject = /* GraphQL */ `
  mutation UpdateProject(
    $input: UpdateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    updateProject(input: $input, condition: $condition) {
      id
      code
      name
      description
      companyID
      company {
        id
        name
        description
        enabled
        owner
        projects {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteProject = /* GraphQL */ `
  mutation DeleteProject(
    $input: DeleteProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    deleteProject(input: $input, condition: $condition) {
      id
      code
      name
      description
      companyID
      company {
        id
        name
        description
        enabled
        owner
        projects {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createAddress = /* GraphQL */ `
  mutation CreateAddress(
    $input: CreateAddressInput!
    $condition: ModelAddressConditionInput
  ) {
    createAddress(input: $input, condition: $condition) {
      id
      street
      number
      zipcode
      city
      settlement
      locality
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateAddress = /* GraphQL */ `
  mutation UpdateAddress(
    $input: UpdateAddressInput!
    $condition: ModelAddressConditionInput
  ) {
    updateAddress(input: $input, condition: $condition) {
      id
      street
      number
      zipcode
      city
      settlement
      locality
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteAddress = /* GraphQL */ `
  mutation DeleteAddress(
    $input: DeleteAddressInput!
    $condition: ModelAddressConditionInput
  ) {
    deleteAddress(input: $input, condition: $condition) {
      id
      street
      number
      zipcode
      city
      settlement
      locality
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createCustodian = /* GraphQL */ `
  mutation CreateCustodian(
    $input: CreateCustodianInput!
    $condition: ModelCustodianConditionInput
  ) {
    createCustodian(input: $input, condition: $condition) {
      id
      name
      middlename
      lastnameP
      lastnameM
      email
      phone
      extension
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateCustodian = /* GraphQL */ `
  mutation UpdateCustodian(
    $input: UpdateCustodianInput!
    $condition: ModelCustodianConditionInput
  ) {
    updateCustodian(input: $input, condition: $condition) {
      id
      name
      middlename
      lastnameP
      lastnameM
      email
      phone
      extension
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustodian = /* GraphQL */ `
  mutation DeleteCustodian(
    $input: DeleteCustodianInput!
    $condition: ModelCustodianConditionInput
  ) {
    deleteCustodian(input: $input, condition: $condition) {
      id
      name
      middlename
      lastnameP
      lastnameM
      email
      phone
      extension
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createKit = /* GraphQL */ `
  mutation CreateKit(
    $input: CreateKitInput!
    $condition: ModelKitConditionInput
  ) {
    createKit(input: $input, condition: $condition) {
      id
      name
      description
      materials {
        items {
          id
          quantity
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateKit = /* GraphQL */ `
  mutation UpdateKit(
    $input: UpdateKitInput!
    $condition: ModelKitConditionInput
  ) {
    updateKit(input: $input, condition: $condition) {
      id
      name
      description
      materials {
        items {
          id
          quantity
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteKit = /* GraphQL */ `
  mutation DeleteKit(
    $input: DeleteKitInput!
    $condition: ModelKitConditionInput
  ) {
    deleteKit(input: $input, condition: $condition) {
      id
      name
      description
      materials {
        items {
          id
          quantity
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createKitMaterials = /* GraphQL */ `
  mutation CreateKitMaterials(
    $input: CreateKitMaterialsInput!
    $condition: ModelKitMaterialsConditionInput
  ) {
    createKitMaterials(input: $input, condition: $condition) {
      id
      kit {
        id
        name
        description
        materials {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      material {
        id
        name
        code
        description
        category {
          id
          name
          abbreviation
          description
          deleted
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        uom {
          id
          name
          abbreviation
          description
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        manufacturer
        trackable
        enabled
        kits {
          nextToken
          startedAt
        }
        deleted
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      uom {
        id
        name
        abbreviation
        description
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      quantity
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateKitMaterials = /* GraphQL */ `
  mutation UpdateKitMaterials(
    $input: UpdateKitMaterialsInput!
    $condition: ModelKitMaterialsConditionInput
  ) {
    updateKitMaterials(input: $input, condition: $condition) {
      id
      kit {
        id
        name
        description
        materials {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      material {
        id
        name
        code
        description
        category {
          id
          name
          abbreviation
          description
          deleted
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        uom {
          id
          name
          abbreviation
          description
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        manufacturer
        trackable
        enabled
        kits {
          nextToken
          startedAt
        }
        deleted
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      uom {
        id
        name
        abbreviation
        description
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      quantity
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteKitMaterials = /* GraphQL */ `
  mutation DeleteKitMaterials(
    $input: DeleteKitMaterialsInput!
    $condition: ModelKitMaterialsConditionInput
  ) {
    deleteKitMaterials(input: $input, condition: $condition) {
      id
      kit {
        id
        name
        description
        materials {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      material {
        id
        name
        code
        description
        category {
          id
          name
          abbreviation
          description
          deleted
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        uom {
          id
          name
          abbreviation
          description
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        manufacturer
        trackable
        enabled
        kits {
          nextToken
          startedAt
        }
        deleted
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      uom {
        id
        name
        abbreviation
        description
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      quantity
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createMaterial = /* GraphQL */ `
  mutation CreateMaterial(
    $input: CreateMaterialInput!
    $condition: ModelMaterialConditionInput
  ) {
    createMaterial(input: $input, condition: $condition) {
      id
      name
      code
      description
      category {
        id
        name
        abbreviation
        description
        deleted
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      uom {
        id
        name
        abbreviation
        description
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      manufacturer
      trackable
      enabled
      kits {
        items {
          id
          quantity
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      deleted
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateMaterial = /* GraphQL */ `
  mutation UpdateMaterial(
    $input: UpdateMaterialInput!
    $condition: ModelMaterialConditionInput
  ) {
    updateMaterial(input: $input, condition: $condition) {
      id
      name
      code
      description
      category {
        id
        name
        abbreviation
        description
        deleted
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      uom {
        id
        name
        abbreviation
        description
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      manufacturer
      trackable
      enabled
      kits {
        items {
          id
          quantity
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      deleted
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteMaterial = /* GraphQL */ `
  mutation DeleteMaterial(
    $input: DeleteMaterialInput!
    $condition: ModelMaterialConditionInput
  ) {
    deleteMaterial(input: $input, condition: $condition) {
      id
      name
      code
      description
      category {
        id
        name
        abbreviation
        description
        deleted
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      uom {
        id
        name
        abbreviation
        description
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      manufacturer
      trackable
      enabled
      kits {
        items {
          id
          quantity
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      deleted
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createUnitOfMeassure = /* GraphQL */ `
  mutation CreateUnitOfMeassure(
    $input: CreateUnitOfMeassureInput!
    $condition: ModelUnitOfMeassureConditionInput
  ) {
    createUnitOfMeassure(input: $input, condition: $condition) {
      id
      name
      abbreviation
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateUnitOfMeassure = /* GraphQL */ `
  mutation UpdateUnitOfMeassure(
    $input: UpdateUnitOfMeassureInput!
    $condition: ModelUnitOfMeassureConditionInput
  ) {
    updateUnitOfMeassure(input: $input, condition: $condition) {
      id
      name
      abbreviation
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteUnitOfMeassure = /* GraphQL */ `
  mutation DeleteUnitOfMeassure(
    $input: DeleteUnitOfMeassureInput!
    $condition: ModelUnitOfMeassureConditionInput
  ) {
    deleteUnitOfMeassure(input: $input, condition: $condition) {
      id
      name
      abbreviation
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createMaterialCategory = /* GraphQL */ `
  mutation CreateMaterialCategory(
    $input: CreateMaterialCategoryInput!
    $condition: ModelMaterialCategoryConditionInput
  ) {
    createMaterialCategory(input: $input, condition: $condition) {
      id
      name
      abbreviation
      description
      deleted
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateMaterialCategory = /* GraphQL */ `
  mutation UpdateMaterialCategory(
    $input: UpdateMaterialCategoryInput!
    $condition: ModelMaterialCategoryConditionInput
  ) {
    updateMaterialCategory(input: $input, condition: $condition) {
      id
      name
      abbreviation
      description
      deleted
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteMaterialCategory = /* GraphQL */ `
  mutation DeleteMaterialCategory(
    $input: DeleteMaterialCategoryInput!
    $condition: ModelMaterialCategoryConditionInput
  ) {
    deleteMaterialCategory(input: $input, condition: $condition) {
      id
      name
      abbreviation
      description
      deleted
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createAttribute = /* GraphQL */ `
  mutation CreateAttribute(
    $input: CreateAttributeInput!
    $condition: ModelAttributeConditionInput
  ) {
    createAttribute(input: $input, condition: $condition) {
      id
      name
      description
      assets {
        items {
          id
          assetID
          attributeID
          value
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateAttribute = /* GraphQL */ `
  mutation UpdateAttribute(
    $input: UpdateAttributeInput!
    $condition: ModelAttributeConditionInput
  ) {
    updateAttribute(input: $input, condition: $condition) {
      id
      name
      description
      assets {
        items {
          id
          assetID
          attributeID
          value
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteAttribute = /* GraphQL */ `
  mutation DeleteAttribute(
    $input: DeleteAttributeInput!
    $condition: ModelAttributeConditionInput
  ) {
    deleteAttribute(input: $input, condition: $condition) {
      id
      name
      description
      assets {
        items {
          id
          assetID
          attributeID
          value
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createAssetAttribute = /* GraphQL */ `
  mutation CreateAssetAttribute(
    $input: CreateAssetAttributeInput!
    $condition: ModelAssetAttributeConditionInput
  ) {
    createAssetAttribute(input: $input, condition: $condition) {
      id
      assetID
      attributeID
      asset {
        id
        code
        serial
        quantity
        type
        enabled
        history
        comment
        material {
          id
          name
          code
          description
          manufacturer
          trackable
          enabled
          deleted
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        locationCode
        tasks {
          nextToken
          startedAt
        }
        trackingID
        tracking {
          id
          status
          ikaID
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        attributes {
          nextToken
          startedAt
        }
        uom {
          id
          name
          abbreviation
          description
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        project {
          id
          code
          name
          description
          companyID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      attribute {
        id
        name
        description
        assets {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      value
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateAssetAttribute = /* GraphQL */ `
  mutation UpdateAssetAttribute(
    $input: UpdateAssetAttributeInput!
    $condition: ModelAssetAttributeConditionInput
  ) {
    updateAssetAttribute(input: $input, condition: $condition) {
      id
      assetID
      attributeID
      asset {
        id
        code
        serial
        quantity
        type
        enabled
        history
        comment
        material {
          id
          name
          code
          description
          manufacturer
          trackable
          enabled
          deleted
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        locationCode
        tasks {
          nextToken
          startedAt
        }
        trackingID
        tracking {
          id
          status
          ikaID
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        attributes {
          nextToken
          startedAt
        }
        uom {
          id
          name
          abbreviation
          description
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        project {
          id
          code
          name
          description
          companyID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      attribute {
        id
        name
        description
        assets {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      value
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteAssetAttribute = /* GraphQL */ `
  mutation DeleteAssetAttribute(
    $input: DeleteAssetAttributeInput!
    $condition: ModelAssetAttributeConditionInput
  ) {
    deleteAssetAttribute(input: $input, condition: $condition) {
      id
      assetID
      attributeID
      asset {
        id
        code
        serial
        quantity
        type
        enabled
        history
        comment
        material {
          id
          name
          code
          description
          manufacturer
          trackable
          enabled
          deleted
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        locationCode
        tasks {
          nextToken
          startedAt
        }
        trackingID
        tracking {
          id
          status
          ikaID
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        attributes {
          nextToken
          startedAt
        }
        uom {
          id
          name
          abbreviation
          description
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        project {
          id
          code
          name
          description
          companyID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      attribute {
        id
        name
        description
        assets {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      value
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createAsset = /* GraphQL */ `
  mutation CreateAsset(
    $input: CreateAssetInput!
    $condition: ModelAssetConditionInput
  ) {
    createAsset(input: $input, condition: $condition) {
      id
      code
      serial
      quantity
      type
      enabled
      history
      comment
      material {
        id
        name
        code
        description
        category {
          id
          name
          abbreviation
          description
          deleted
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        uom {
          id
          name
          abbreviation
          description
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        manufacturer
        trackable
        enabled
        kits {
          nextToken
          startedAt
        }
        deleted
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      locationCode
      tasks {
        items {
          id
          taskAssetsTaskId
          taskAssetsAssetId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      trackingID
      tracking {
        id
        status
        ikaID
        assets {
          nextToken
          startedAt
        }
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
      }
      attributes {
        items {
          id
          assetID
          attributeID
          value
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      uom {
        id
        name
        abbreviation
        description
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      project {
        id
        code
        name
        description
        companyID
        company {
          id
          name
          description
          enabled
          owner
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateAsset = /* GraphQL */ `
  mutation UpdateAsset(
    $input: UpdateAssetInput!
    $condition: ModelAssetConditionInput
  ) {
    updateAsset(input: $input, condition: $condition) {
      id
      code
      serial
      quantity
      type
      enabled
      history
      comment
      material {
        id
        name
        code
        description
        category {
          id
          name
          abbreviation
          description
          deleted
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        uom {
          id
          name
          abbreviation
          description
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        manufacturer
        trackable
        enabled
        kits {
          nextToken
          startedAt
        }
        deleted
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      locationCode
      tasks {
        items {
          id
          taskAssetsTaskId
          taskAssetsAssetId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      trackingID
      tracking {
        id
        status
        ikaID
        assets {
          nextToken
          startedAt
        }
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
      }
      attributes {
        items {
          id
          assetID
          attributeID
          value
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      uom {
        id
        name
        abbreviation
        description
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      project {
        id
        code
        name
        description
        companyID
        company {
          id
          name
          description
          enabled
          owner
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteAsset = /* GraphQL */ `
  mutation DeleteAsset(
    $input: DeleteAssetInput!
    $condition: ModelAssetConditionInput
  ) {
    deleteAsset(input: $input, condition: $condition) {
      id
      code
      serial
      quantity
      type
      enabled
      history
      comment
      material {
        id
        name
        code
        description
        category {
          id
          name
          abbreviation
          description
          deleted
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        uom {
          id
          name
          abbreviation
          description
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        manufacturer
        trackable
        enabled
        kits {
          nextToken
          startedAt
        }
        deleted
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      locationCode
      tasks {
        items {
          id
          taskAssetsTaskId
          taskAssetsAssetId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      trackingID
      tracking {
        id
        status
        ikaID
        assets {
          nextToken
          startedAt
        }
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
      }
      attributes {
        items {
          id
          assetID
          attributeID
          value
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      uom {
        id
        name
        abbreviation
        description
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      project {
        id
        code
        name
        description
        companyID
        company {
          id
          name
          description
          enabled
          owner
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createTaskAssets = /* GraphQL */ `
  mutation CreateTaskAssets(
    $input: CreateTaskAssetsInput!
    $condition: ModelTaskAssetsConditionInput
  ) {
    createTaskAssets(input: $input, condition: $condition) {
      id
      taskAssetsTaskId
      taskAssetsAssetId
      task {
        id
        status
        startDate
        endDate
        startTime
        endTime
        startedAt
        startedBy
        startLocation {
          longitude
          latitude
        }
        endedAt
        endedBy
        endLocation {
          longitude
          latitude
        }
        users {
          nextToken
          startedAt
        }
        buildingLocation {
          id
          name
          code
          buildingBlock
          floor
          area
          status
          premisesID
          owner
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        taskType {
          id
          name
          description
          owner
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        supervisor {
          id
          name
          middlename
          lastnameP
          lastnameM
          email
          phone
          extension
          status
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        assets {
          nextToken
          startedAt
        }
        attachments {
          nextToken
          startedAt
        }
        notes {
          nextToken
          startedAt
        }
        externalTicketId
        priority
        level
        rating
        targetResolutionTime
        deliveryInstallment
        remarks {
          nextToken
          startedAt
        }
        taskTicketId
        ticket {
          id
          subject
          description
          requestType
          impact
          impactDetails
          status
          mode
          supportType
          urgency
          priority
          serviceCategory
          serviceSubcategory
          rating
          ratingComment
          verificatorName
          ticketPremisesId
          ticketRequesterId
          ticketAssetId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        owner
        deleted
        project {
          id
          code
          name
          description
          companyID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      asset {
        id
        code
        serial
        quantity
        type
        enabled
        history
        comment
        material {
          id
          name
          code
          description
          manufacturer
          trackable
          enabled
          deleted
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        locationCode
        tasks {
          nextToken
          startedAt
        }
        trackingID
        tracking {
          id
          status
          ikaID
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        attributes {
          nextToken
          startedAt
        }
        uom {
          id
          name
          abbreviation
          description
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        project {
          id
          code
          name
          description
          companyID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateTaskAssets = /* GraphQL */ `
  mutation UpdateTaskAssets(
    $input: UpdateTaskAssetsInput!
    $condition: ModelTaskAssetsConditionInput
  ) {
    updateTaskAssets(input: $input, condition: $condition) {
      id
      taskAssetsTaskId
      taskAssetsAssetId
      task {
        id
        status
        startDate
        endDate
        startTime
        endTime
        startedAt
        startedBy
        startLocation {
          longitude
          latitude
        }
        endedAt
        endedBy
        endLocation {
          longitude
          latitude
        }
        users {
          nextToken
          startedAt
        }
        buildingLocation {
          id
          name
          code
          buildingBlock
          floor
          area
          status
          premisesID
          owner
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        taskType {
          id
          name
          description
          owner
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        supervisor {
          id
          name
          middlename
          lastnameP
          lastnameM
          email
          phone
          extension
          status
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        assets {
          nextToken
          startedAt
        }
        attachments {
          nextToken
          startedAt
        }
        notes {
          nextToken
          startedAt
        }
        externalTicketId
        priority
        level
        rating
        targetResolutionTime
        deliveryInstallment
        remarks {
          nextToken
          startedAt
        }
        taskTicketId
        ticket {
          id
          subject
          description
          requestType
          impact
          impactDetails
          status
          mode
          supportType
          urgency
          priority
          serviceCategory
          serviceSubcategory
          rating
          ratingComment
          verificatorName
          ticketPremisesId
          ticketRequesterId
          ticketAssetId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        owner
        deleted
        project {
          id
          code
          name
          description
          companyID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      asset {
        id
        code
        serial
        quantity
        type
        enabled
        history
        comment
        material {
          id
          name
          code
          description
          manufacturer
          trackable
          enabled
          deleted
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        locationCode
        tasks {
          nextToken
          startedAt
        }
        trackingID
        tracking {
          id
          status
          ikaID
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        attributes {
          nextToken
          startedAt
        }
        uom {
          id
          name
          abbreviation
          description
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        project {
          id
          code
          name
          description
          companyID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteTaskAssets = /* GraphQL */ `
  mutation DeleteTaskAssets(
    $input: DeleteTaskAssetsInput!
    $condition: ModelTaskAssetsConditionInput
  ) {
    deleteTaskAssets(input: $input, condition: $condition) {
      id
      taskAssetsTaskId
      taskAssetsAssetId
      task {
        id
        status
        startDate
        endDate
        startTime
        endTime
        startedAt
        startedBy
        startLocation {
          longitude
          latitude
        }
        endedAt
        endedBy
        endLocation {
          longitude
          latitude
        }
        users {
          nextToken
          startedAt
        }
        buildingLocation {
          id
          name
          code
          buildingBlock
          floor
          area
          status
          premisesID
          owner
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        taskType {
          id
          name
          description
          owner
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        supervisor {
          id
          name
          middlename
          lastnameP
          lastnameM
          email
          phone
          extension
          status
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        assets {
          nextToken
          startedAt
        }
        attachments {
          nextToken
          startedAt
        }
        notes {
          nextToken
          startedAt
        }
        externalTicketId
        priority
        level
        rating
        targetResolutionTime
        deliveryInstallment
        remarks {
          nextToken
          startedAt
        }
        taskTicketId
        ticket {
          id
          subject
          description
          requestType
          impact
          impactDetails
          status
          mode
          supportType
          urgency
          priority
          serviceCategory
          serviceSubcategory
          rating
          ratingComment
          verificatorName
          ticketPremisesId
          ticketRequesterId
          ticketAssetId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        owner
        deleted
        project {
          id
          code
          name
          description
          companyID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      asset {
        id
        code
        serial
        quantity
        type
        enabled
        history
        comment
        material {
          id
          name
          code
          description
          manufacturer
          trackable
          enabled
          deleted
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        locationCode
        tasks {
          nextToken
          startedAt
        }
        trackingID
        tracking {
          id
          status
          ikaID
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        attributes {
          nextToken
          startedAt
        }
        uom {
          id
          name
          abbreviation
          description
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        project {
          id
          code
          name
          description
          companyID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createTask = /* GraphQL */ `
  mutation CreateTask(
    $input: CreateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    createTask(input: $input, condition: $condition) {
      id
      status
      startDate
      endDate
      startTime
      endTime
      startedAt
      startedBy
      startLocation {
        longitude
        latitude
      }
      endedAt
      endedBy
      endLocation {
        longitude
        latitude
      }
      users {
        items {
          id
          userID
          taskID
          head
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      buildingLocation {
        id
        name
        code
        buildingBlock
        floor
        area
        status
        premisesID
        premises {
          id
          name
          owner
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        owner
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      taskType {
        id
        name
        description
        owner
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      supervisor {
        id
        name
        middlename
        lastnameP
        lastnameM
        email
        phone
        extension
        status
        roles {
          nextToken
          startedAt
        }
        teams {
          nextToken
          startedAt
        }
        tasks {
          nextToken
          startedAt
        }
        tasksSupervised {
          nextToken
          startedAt
        }
        logs {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      assets {
        items {
          id
          taskAssetsTaskId
          taskAssetsAssetId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      attachments {
        items {
          id
          taskID
          owner
          status
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      notes {
        items {
          id
          taskID
          comments
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      externalTicketId
      priority
      level
      rating
      targetResolutionTime
      deliveryInstallment
      remarks {
        items {
          id
          taskID
          taskStatus
          remark
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      taskTicketId
      ticket {
        id
        subject
        description
        requestType
        impact
        impactDetails
        status
        mode
        supportType
        urgency
        priority
        serviceCategory
        serviceSubcategory
        rating
        ratingComment
        verificatorName
        validationSignatureFile {
          bucket
          region
          key
        }
        ticketPremisesId
        premises {
          id
          name
          owner
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        ticketRequesterId
        requester {
          id
          name
          middlename
          lastnameP
          lastnameM
          email
          phone
          extension
          status
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        tasks {
          nextToken
          startedAt
        }
        ticketAssetId
        asset {
          id
          code
          serial
          quantity
          type
          enabled
          history
          comment
          locationCode
          trackingID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        attachments {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        project {
          id
          code
          name
          description
          companyID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      owner
      deleted
      project {
        id
        code
        name
        description
        companyID
        company {
          id
          name
          description
          enabled
          owner
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateTask = /* GraphQL */ `
  mutation UpdateTask(
    $input: UpdateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    updateTask(input: $input, condition: $condition) {
      id
      status
      startDate
      endDate
      startTime
      endTime
      startedAt
      startedBy
      startLocation {
        longitude
        latitude
      }
      endedAt
      endedBy
      endLocation {
        longitude
        latitude
      }
      users {
        items {
          id
          userID
          taskID
          head
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      buildingLocation {
        id
        name
        code
        buildingBlock
        floor
        area
        status
        premisesID
        premises {
          id
          name
          owner
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        owner
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      taskType {
        id
        name
        description
        owner
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      supervisor {
        id
        name
        middlename
        lastnameP
        lastnameM
        email
        phone
        extension
        status
        roles {
          nextToken
          startedAt
        }
        teams {
          nextToken
          startedAt
        }
        tasks {
          nextToken
          startedAt
        }
        tasksSupervised {
          nextToken
          startedAt
        }
        logs {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      assets {
        items {
          id
          taskAssetsTaskId
          taskAssetsAssetId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      attachments {
        items {
          id
          taskID
          owner
          status
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      notes {
        items {
          id
          taskID
          comments
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      externalTicketId
      priority
      level
      rating
      targetResolutionTime
      deliveryInstallment
      remarks {
        items {
          id
          taskID
          taskStatus
          remark
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      taskTicketId
      ticket {
        id
        subject
        description
        requestType
        impact
        impactDetails
        status
        mode
        supportType
        urgency
        priority
        serviceCategory
        serviceSubcategory
        rating
        ratingComment
        verificatorName
        validationSignatureFile {
          bucket
          region
          key
        }
        ticketPremisesId
        premises {
          id
          name
          owner
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        ticketRequesterId
        requester {
          id
          name
          middlename
          lastnameP
          lastnameM
          email
          phone
          extension
          status
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        tasks {
          nextToken
          startedAt
        }
        ticketAssetId
        asset {
          id
          code
          serial
          quantity
          type
          enabled
          history
          comment
          locationCode
          trackingID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        attachments {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        project {
          id
          code
          name
          description
          companyID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      owner
      deleted
      project {
        id
        code
        name
        description
        companyID
        company {
          id
          name
          description
          enabled
          owner
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteTask = /* GraphQL */ `
  mutation DeleteTask(
    $input: DeleteTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    deleteTask(input: $input, condition: $condition) {
      id
      status
      startDate
      endDate
      startTime
      endTime
      startedAt
      startedBy
      startLocation {
        longitude
        latitude
      }
      endedAt
      endedBy
      endLocation {
        longitude
        latitude
      }
      users {
        items {
          id
          userID
          taskID
          head
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      buildingLocation {
        id
        name
        code
        buildingBlock
        floor
        area
        status
        premisesID
        premises {
          id
          name
          owner
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        owner
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      taskType {
        id
        name
        description
        owner
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      supervisor {
        id
        name
        middlename
        lastnameP
        lastnameM
        email
        phone
        extension
        status
        roles {
          nextToken
          startedAt
        }
        teams {
          nextToken
          startedAt
        }
        tasks {
          nextToken
          startedAt
        }
        tasksSupervised {
          nextToken
          startedAt
        }
        logs {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      assets {
        items {
          id
          taskAssetsTaskId
          taskAssetsAssetId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      attachments {
        items {
          id
          taskID
          owner
          status
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      notes {
        items {
          id
          taskID
          comments
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      externalTicketId
      priority
      level
      rating
      targetResolutionTime
      deliveryInstallment
      remarks {
        items {
          id
          taskID
          taskStatus
          remark
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      taskTicketId
      ticket {
        id
        subject
        description
        requestType
        impact
        impactDetails
        status
        mode
        supportType
        urgency
        priority
        serviceCategory
        serviceSubcategory
        rating
        ratingComment
        verificatorName
        validationSignatureFile {
          bucket
          region
          key
        }
        ticketPremisesId
        premises {
          id
          name
          owner
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        ticketRequesterId
        requester {
          id
          name
          middlename
          lastnameP
          lastnameM
          email
          phone
          extension
          status
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        tasks {
          nextToken
          startedAt
        }
        ticketAssetId
        asset {
          id
          code
          serial
          quantity
          type
          enabled
          history
          comment
          locationCode
          trackingID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        attachments {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        project {
          id
          code
          name
          description
          companyID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      owner
      deleted
      project {
        id
        code
        name
        description
        companyID
        company {
          id
          name
          description
          enabled
          owner
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createRemark = /* GraphQL */ `
  mutation CreateRemark(
    $input: CreateRemarkInput!
    $condition: ModelRemarkConditionInput
  ) {
    createRemark(input: $input, condition: $condition) {
      id
      taskID
      taskStatus
      remark
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateRemark = /* GraphQL */ `
  mutation UpdateRemark(
    $input: UpdateRemarkInput!
    $condition: ModelRemarkConditionInput
  ) {
    updateRemark(input: $input, condition: $condition) {
      id
      taskID
      taskStatus
      remark
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteRemark = /* GraphQL */ `
  mutation DeleteRemark(
    $input: DeleteRemarkInput!
    $condition: ModelRemarkConditionInput
  ) {
    deleteRemark(input: $input, condition: $condition) {
      id
      taskID
      taskStatus
      remark
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createAttachment = /* GraphQL */ `
  mutation CreateAttachment(
    $input: CreateAttachmentInput!
    $condition: ModelAttachmentConditionInput
  ) {
    createAttachment(input: $input, condition: $condition) {
      id
      taskID
      owner
      status
      file {
        bucket
        region
        key
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateAttachment = /* GraphQL */ `
  mutation UpdateAttachment(
    $input: UpdateAttachmentInput!
    $condition: ModelAttachmentConditionInput
  ) {
    updateAttachment(input: $input, condition: $condition) {
      id
      taskID
      owner
      status
      file {
        bucket
        region
        key
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteAttachment = /* GraphQL */ `
  mutation DeleteAttachment(
    $input: DeleteAttachmentInput!
    $condition: ModelAttachmentConditionInput
  ) {
    deleteAttachment(input: $input, condition: $condition) {
      id
      taskID
      owner
      status
      file {
        bucket
        region
        key
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createInspectionNote = /* GraphQL */ `
  mutation CreateInspectionNote(
    $input: CreateInspectionNoteInput!
    $condition: ModelInspectionNoteConditionInput
  ) {
    createInspectionNote(input: $input, condition: $condition) {
      id
      taskID
      comments
      supervisor {
        id
        name
        middlename
        lastnameP
        lastnameM
        email
        phone
        extension
        status
        roles {
          nextToken
          startedAt
        }
        teams {
          nextToken
          startedAt
        }
        tasks {
          nextToken
          startedAt
        }
        tasksSupervised {
          nextToken
          startedAt
        }
        logs {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateInspectionNote = /* GraphQL */ `
  mutation UpdateInspectionNote(
    $input: UpdateInspectionNoteInput!
    $condition: ModelInspectionNoteConditionInput
  ) {
    updateInspectionNote(input: $input, condition: $condition) {
      id
      taskID
      comments
      supervisor {
        id
        name
        middlename
        lastnameP
        lastnameM
        email
        phone
        extension
        status
        roles {
          nextToken
          startedAt
        }
        teams {
          nextToken
          startedAt
        }
        tasks {
          nextToken
          startedAt
        }
        tasksSupervised {
          nextToken
          startedAt
        }
        logs {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteInspectionNote = /* GraphQL */ `
  mutation DeleteInspectionNote(
    $input: DeleteInspectionNoteInput!
    $condition: ModelInspectionNoteConditionInput
  ) {
    deleteInspectionNote(input: $input, condition: $condition) {
      id
      taskID
      comments
      supervisor {
        id
        name
        middlename
        lastnameP
        lastnameM
        email
        phone
        extension
        status
        roles {
          nextToken
          startedAt
        }
        teams {
          nextToken
          startedAt
        }
        tasks {
          nextToken
          startedAt
        }
        tasksSupervised {
          nextToken
          startedAt
        }
        logs {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createAttachmentTicket = /* GraphQL */ `
  mutation CreateAttachmentTicket(
    $input: CreateAttachmentTicketInput!
    $condition: ModelAttachmentTicketConditionInput
  ) {
    createAttachmentTicket(input: $input, condition: $condition) {
      id
      ticketID
      file {
        bucket
        region
        key
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateAttachmentTicket = /* GraphQL */ `
  mutation UpdateAttachmentTicket(
    $input: UpdateAttachmentTicketInput!
    $condition: ModelAttachmentTicketConditionInput
  ) {
    updateAttachmentTicket(input: $input, condition: $condition) {
      id
      ticketID
      file {
        bucket
        region
        key
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteAttachmentTicket = /* GraphQL */ `
  mutation DeleteAttachmentTicket(
    $input: DeleteAttachmentTicketInput!
    $condition: ModelAttachmentTicketConditionInput
  ) {
    deleteAttachmentTicket(input: $input, condition: $condition) {
      id
      ticketID
      file {
        bucket
        region
        key
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createTicket = /* GraphQL */ `
  mutation CreateTicket(
    $input: CreateTicketInput!
    $condition: ModelTicketConditionInput
  ) {
    createTicket(input: $input, condition: $condition) {
      id
      subject
      description
      requestType
      impact
      impactDetails
      status
      mode
      supportType
      urgency
      priority
      serviceCategory
      serviceSubcategory
      rating
      ratingComment
      verificatorName
      validationSignatureFile {
        bucket
        region
        key
      }
      ticketPremisesId
      premises {
        id
        name
        owner
        company {
          id
          name
          description
          enabled
          owner
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        custodian {
          id
          name
          middlename
          lastnameP
          lastnameM
          email
          phone
          extension
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        address {
          id
          street
          number
          zipcode
          city
          settlement
          locality
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        buildingLocations {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      ticketRequesterId
      requester {
        id
        name
        middlename
        lastnameP
        lastnameM
        email
        phone
        extension
        status
        roles {
          nextToken
          startedAt
        }
        teams {
          nextToken
          startedAt
        }
        tasks {
          nextToken
          startedAt
        }
        tasksSupervised {
          nextToken
          startedAt
        }
        logs {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      tasks {
        items {
          id
          status
          startDate
          endDate
          startTime
          endTime
          startedAt
          startedBy
          endedAt
          endedBy
          externalTicketId
          priority
          level
          rating
          targetResolutionTime
          deliveryInstallment
          taskTicketId
          owner
          deleted
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      ticketAssetId
      asset {
        id
        code
        serial
        quantity
        type
        enabled
        history
        comment
        material {
          id
          name
          code
          description
          manufacturer
          trackable
          enabled
          deleted
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        locationCode
        tasks {
          nextToken
          startedAt
        }
        trackingID
        tracking {
          id
          status
          ikaID
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        attributes {
          nextToken
          startedAt
        }
        uom {
          id
          name
          abbreviation
          description
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        project {
          id
          code
          name
          description
          companyID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      attachments {
        items {
          id
          ticketID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      project {
        id
        code
        name
        description
        companyID
        company {
          id
          name
          description
          enabled
          owner
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateTicket = /* GraphQL */ `
  mutation UpdateTicket(
    $input: UpdateTicketInput!
    $condition: ModelTicketConditionInput
  ) {
    updateTicket(input: $input, condition: $condition) {
      id
      subject
      description
      requestType
      impact
      impactDetails
      status
      mode
      supportType
      urgency
      priority
      serviceCategory
      serviceSubcategory
      rating
      ratingComment
      verificatorName
      validationSignatureFile {
        bucket
        region
        key
      }
      ticketPremisesId
      premises {
        id
        name
        owner
        company {
          id
          name
          description
          enabled
          owner
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        custodian {
          id
          name
          middlename
          lastnameP
          lastnameM
          email
          phone
          extension
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        address {
          id
          street
          number
          zipcode
          city
          settlement
          locality
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        buildingLocations {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      ticketRequesterId
      requester {
        id
        name
        middlename
        lastnameP
        lastnameM
        email
        phone
        extension
        status
        roles {
          nextToken
          startedAt
        }
        teams {
          nextToken
          startedAt
        }
        tasks {
          nextToken
          startedAt
        }
        tasksSupervised {
          nextToken
          startedAt
        }
        logs {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      tasks {
        items {
          id
          status
          startDate
          endDate
          startTime
          endTime
          startedAt
          startedBy
          endedAt
          endedBy
          externalTicketId
          priority
          level
          rating
          targetResolutionTime
          deliveryInstallment
          taskTicketId
          owner
          deleted
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      ticketAssetId
      asset {
        id
        code
        serial
        quantity
        type
        enabled
        history
        comment
        material {
          id
          name
          code
          description
          manufacturer
          trackable
          enabled
          deleted
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        locationCode
        tasks {
          nextToken
          startedAt
        }
        trackingID
        tracking {
          id
          status
          ikaID
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        attributes {
          nextToken
          startedAt
        }
        uom {
          id
          name
          abbreviation
          description
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        project {
          id
          code
          name
          description
          companyID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      attachments {
        items {
          id
          ticketID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      project {
        id
        code
        name
        description
        companyID
        company {
          id
          name
          description
          enabled
          owner
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteTicket = /* GraphQL */ `
  mutation DeleteTicket(
    $input: DeleteTicketInput!
    $condition: ModelTicketConditionInput
  ) {
    deleteTicket(input: $input, condition: $condition) {
      id
      subject
      description
      requestType
      impact
      impactDetails
      status
      mode
      supportType
      urgency
      priority
      serviceCategory
      serviceSubcategory
      rating
      ratingComment
      verificatorName
      validationSignatureFile {
        bucket
        region
        key
      }
      ticketPremisesId
      premises {
        id
        name
        owner
        company {
          id
          name
          description
          enabled
          owner
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        custodian {
          id
          name
          middlename
          lastnameP
          lastnameM
          email
          phone
          extension
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        address {
          id
          street
          number
          zipcode
          city
          settlement
          locality
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        buildingLocations {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      ticketRequesterId
      requester {
        id
        name
        middlename
        lastnameP
        lastnameM
        email
        phone
        extension
        status
        roles {
          nextToken
          startedAt
        }
        teams {
          nextToken
          startedAt
        }
        tasks {
          nextToken
          startedAt
        }
        tasksSupervised {
          nextToken
          startedAt
        }
        logs {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      tasks {
        items {
          id
          status
          startDate
          endDate
          startTime
          endTime
          startedAt
          startedBy
          endedAt
          endedBy
          externalTicketId
          priority
          level
          rating
          targetResolutionTime
          deliveryInstallment
          taskTicketId
          owner
          deleted
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      ticketAssetId
      asset {
        id
        code
        serial
        quantity
        type
        enabled
        history
        comment
        material {
          id
          name
          code
          description
          manufacturer
          trackable
          enabled
          deleted
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        locationCode
        tasks {
          nextToken
          startedAt
        }
        trackingID
        tracking {
          id
          status
          ikaID
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        attributes {
          nextToken
          startedAt
        }
        uom {
          id
          name
          abbreviation
          description
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        project {
          id
          code
          name
          description
          companyID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      attachments {
        items {
          id
          ticketID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      project {
        id
        code
        name
        description
        companyID
        company {
          id
          name
          description
          enabled
          owner
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createAcceptedDomain = /* GraphQL */ `
  mutation CreateAcceptedDomain(
    $input: CreateAcceptedDomainInput!
    $condition: ModelAcceptedDomainConditionInput
  ) {
    createAcceptedDomain(input: $input, condition: $condition) {
      id
      company {
        id
        name
        description
        enabled
        owner
        projects {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      emailDomain
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateAcceptedDomain = /* GraphQL */ `
  mutation UpdateAcceptedDomain(
    $input: UpdateAcceptedDomainInput!
    $condition: ModelAcceptedDomainConditionInput
  ) {
    updateAcceptedDomain(input: $input, condition: $condition) {
      id
      company {
        id
        name
        description
        enabled
        owner
        projects {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      emailDomain
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteAcceptedDomain = /* GraphQL */ `
  mutation DeleteAcceptedDomain(
    $input: DeleteAcceptedDomainInput!
    $condition: ModelAcceptedDomainConditionInput
  ) {
    deleteAcceptedDomain(input: $input, condition: $condition) {
      id
      company {
        id
        name
        description
        enabled
        owner
        projects {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      emailDomain
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createTracking = /* GraphQL */ `
  mutation CreateTracking(
    $input: CreateTrackingInput!
    $condition: ModelTrackingConditionInput
  ) {
    createTracking(input: $input, condition: $condition) {
      id
      status
      ikaID
      assets {
        items {
          id
          code
          serial
          quantity
          type
          enabled
          history
          comment
          locationCode
          trackingID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const updateTracking = /* GraphQL */ `
  mutation UpdateTracking(
    $input: UpdateTrackingInput!
    $condition: ModelTrackingConditionInput
  ) {
    updateTracking(input: $input, condition: $condition) {
      id
      status
      ikaID
      assets {
        items {
          id
          code
          serial
          quantity
          type
          enabled
          history
          comment
          locationCode
          trackingID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const deleteTracking = /* GraphQL */ `
  mutation DeleteTracking(
    $input: DeleteTrackingInput!
    $condition: ModelTrackingConditionInput
  ) {
    deleteTracking(input: $input, condition: $condition) {
      id
      status
      ikaID
      assets {
        items {
          id
          code
          serial
          quantity
          type
          enabled
          history
          comment
          locationCode
          trackingID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const createGoodsReceipt = /* GraphQL */ `
  mutation CreateGoodsReceipt(
    $input: CreateGoodsReceiptInput!
    $condition: ModelGoodsReceiptConditionInput
  ) {
    createGoodsReceipt(input: $input, condition: $condition) {
      id
      quantity
      user {
        id
        name
        middlename
        lastnameP
        lastnameM
        email
        phone
        extension
        status
        roles {
          nextToken
          startedAt
        }
        teams {
          nextToken
          startedAt
        }
        tasks {
          nextToken
          startedAt
        }
        tasksSupervised {
          nextToken
          startedAt
        }
        logs {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      type
      comments
      qrAssociated
      assets {
        items {
          id
          goodsReceiptID
          assetID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      attachments {
        items {
          id
          goodsReceiptID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateGoodsReceipt = /* GraphQL */ `
  mutation UpdateGoodsReceipt(
    $input: UpdateGoodsReceiptInput!
    $condition: ModelGoodsReceiptConditionInput
  ) {
    updateGoodsReceipt(input: $input, condition: $condition) {
      id
      quantity
      user {
        id
        name
        middlename
        lastnameP
        lastnameM
        email
        phone
        extension
        status
        roles {
          nextToken
          startedAt
        }
        teams {
          nextToken
          startedAt
        }
        tasks {
          nextToken
          startedAt
        }
        tasksSupervised {
          nextToken
          startedAt
        }
        logs {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      type
      comments
      qrAssociated
      assets {
        items {
          id
          goodsReceiptID
          assetID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      attachments {
        items {
          id
          goodsReceiptID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteGoodsReceipt = /* GraphQL */ `
  mutation DeleteGoodsReceipt(
    $input: DeleteGoodsReceiptInput!
    $condition: ModelGoodsReceiptConditionInput
  ) {
    deleteGoodsReceipt(input: $input, condition: $condition) {
      id
      quantity
      user {
        id
        name
        middlename
        lastnameP
        lastnameM
        email
        phone
        extension
        status
        roles {
          nextToken
          startedAt
        }
        teams {
          nextToken
          startedAt
        }
        tasks {
          nextToken
          startedAt
        }
        tasksSupervised {
          nextToken
          startedAt
        }
        logs {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      type
      comments
      qrAssociated
      assets {
        items {
          id
          goodsReceiptID
          assetID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      attachments {
        items {
          id
          goodsReceiptID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createGoodsReceiptAsset = /* GraphQL */ `
  mutation CreateGoodsReceiptAsset(
    $input: CreateGoodsReceiptAssetInput!
    $condition: ModelGoodsReceiptAssetConditionInput
  ) {
    createGoodsReceiptAsset(input: $input, condition: $condition) {
      id
      goodsReceiptID
      assetID
      goodsReceipt {
        id
        status
        startDate
        endDate
        startTime
        endTime
        startedAt
        startedBy
        startLocation {
          longitude
          latitude
        }
        endedAt
        endedBy
        endLocation {
          longitude
          latitude
        }
        users {
          nextToken
          startedAt
        }
        buildingLocation {
          id
          name
          code
          buildingBlock
          floor
          area
          status
          premisesID
          owner
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        taskType {
          id
          name
          description
          owner
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        supervisor {
          id
          name
          middlename
          lastnameP
          lastnameM
          email
          phone
          extension
          status
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        assets {
          nextToken
          startedAt
        }
        attachments {
          nextToken
          startedAt
        }
        notes {
          nextToken
          startedAt
        }
        externalTicketId
        priority
        level
        rating
        targetResolutionTime
        deliveryInstallment
        remarks {
          nextToken
          startedAt
        }
        taskTicketId
        ticket {
          id
          subject
          description
          requestType
          impact
          impactDetails
          status
          mode
          supportType
          urgency
          priority
          serviceCategory
          serviceSubcategory
          rating
          ratingComment
          verificatorName
          ticketPremisesId
          ticketRequesterId
          ticketAssetId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        owner
        deleted
        project {
          id
          code
          name
          description
          companyID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      asset {
        id
        code
        serial
        quantity
        type
        enabled
        history
        comment
        material {
          id
          name
          code
          description
          manufacturer
          trackable
          enabled
          deleted
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        locationCode
        tasks {
          nextToken
          startedAt
        }
        trackingID
        tracking {
          id
          status
          ikaID
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        attributes {
          nextToken
          startedAt
        }
        uom {
          id
          name
          abbreviation
          description
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        project {
          id
          code
          name
          description
          companyID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateGoodsReceiptAsset = /* GraphQL */ `
  mutation UpdateGoodsReceiptAsset(
    $input: UpdateGoodsReceiptAssetInput!
    $condition: ModelGoodsReceiptAssetConditionInput
  ) {
    updateGoodsReceiptAsset(input: $input, condition: $condition) {
      id
      goodsReceiptID
      assetID
      goodsReceipt {
        id
        status
        startDate
        endDate
        startTime
        endTime
        startedAt
        startedBy
        startLocation {
          longitude
          latitude
        }
        endedAt
        endedBy
        endLocation {
          longitude
          latitude
        }
        users {
          nextToken
          startedAt
        }
        buildingLocation {
          id
          name
          code
          buildingBlock
          floor
          area
          status
          premisesID
          owner
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        taskType {
          id
          name
          description
          owner
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        supervisor {
          id
          name
          middlename
          lastnameP
          lastnameM
          email
          phone
          extension
          status
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        assets {
          nextToken
          startedAt
        }
        attachments {
          nextToken
          startedAt
        }
        notes {
          nextToken
          startedAt
        }
        externalTicketId
        priority
        level
        rating
        targetResolutionTime
        deliveryInstallment
        remarks {
          nextToken
          startedAt
        }
        taskTicketId
        ticket {
          id
          subject
          description
          requestType
          impact
          impactDetails
          status
          mode
          supportType
          urgency
          priority
          serviceCategory
          serviceSubcategory
          rating
          ratingComment
          verificatorName
          ticketPremisesId
          ticketRequesterId
          ticketAssetId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        owner
        deleted
        project {
          id
          code
          name
          description
          companyID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      asset {
        id
        code
        serial
        quantity
        type
        enabled
        history
        comment
        material {
          id
          name
          code
          description
          manufacturer
          trackable
          enabled
          deleted
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        locationCode
        tasks {
          nextToken
          startedAt
        }
        trackingID
        tracking {
          id
          status
          ikaID
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        attributes {
          nextToken
          startedAt
        }
        uom {
          id
          name
          abbreviation
          description
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        project {
          id
          code
          name
          description
          companyID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteGoodsReceiptAsset = /* GraphQL */ `
  mutation DeleteGoodsReceiptAsset(
    $input: DeleteGoodsReceiptAssetInput!
    $condition: ModelGoodsReceiptAssetConditionInput
  ) {
    deleteGoodsReceiptAsset(input: $input, condition: $condition) {
      id
      goodsReceiptID
      assetID
      goodsReceipt {
        id
        status
        startDate
        endDate
        startTime
        endTime
        startedAt
        startedBy
        startLocation {
          longitude
          latitude
        }
        endedAt
        endedBy
        endLocation {
          longitude
          latitude
        }
        users {
          nextToken
          startedAt
        }
        buildingLocation {
          id
          name
          code
          buildingBlock
          floor
          area
          status
          premisesID
          owner
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        taskType {
          id
          name
          description
          owner
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        supervisor {
          id
          name
          middlename
          lastnameP
          lastnameM
          email
          phone
          extension
          status
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        assets {
          nextToken
          startedAt
        }
        attachments {
          nextToken
          startedAt
        }
        notes {
          nextToken
          startedAt
        }
        externalTicketId
        priority
        level
        rating
        targetResolutionTime
        deliveryInstallment
        remarks {
          nextToken
          startedAt
        }
        taskTicketId
        ticket {
          id
          subject
          description
          requestType
          impact
          impactDetails
          status
          mode
          supportType
          urgency
          priority
          serviceCategory
          serviceSubcategory
          rating
          ratingComment
          verificatorName
          ticketPremisesId
          ticketRequesterId
          ticketAssetId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        owner
        deleted
        project {
          id
          code
          name
          description
          companyID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      asset {
        id
        code
        serial
        quantity
        type
        enabled
        history
        comment
        material {
          id
          name
          code
          description
          manufacturer
          trackable
          enabled
          deleted
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        locationCode
        tasks {
          nextToken
          startedAt
        }
        trackingID
        tracking {
          id
          status
          ikaID
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        attributes {
          nextToken
          startedAt
        }
        uom {
          id
          name
          abbreviation
          description
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        project {
          id
          code
          name
          description
          companyID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createAttachmentGoodsReceipt = /* GraphQL */ `
  mutation CreateAttachmentGoodsReceipt(
    $input: CreateAttachmentGoodsReceiptInput!
    $condition: ModelAttachmentGoodsReceiptConditionInput
  ) {
    createAttachmentGoodsReceipt(input: $input, condition: $condition) {
      id
      goodsReceiptID
      file {
        bucket
        region
        key
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateAttachmentGoodsReceipt = /* GraphQL */ `
  mutation UpdateAttachmentGoodsReceipt(
    $input: UpdateAttachmentGoodsReceiptInput!
    $condition: ModelAttachmentGoodsReceiptConditionInput
  ) {
    updateAttachmentGoodsReceipt(input: $input, condition: $condition) {
      id
      goodsReceiptID
      file {
        bucket
        region
        key
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteAttachmentGoodsReceipt = /* GraphQL */ `
  mutation DeleteAttachmentGoodsReceipt(
    $input: DeleteAttachmentGoodsReceiptInput!
    $condition: ModelAttachmentGoodsReceiptConditionInput
  ) {
    deleteAttachmentGoodsReceipt(input: $input, condition: $condition) {
      id
      goodsReceiptID
      file {
        bucket
        region
        key
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createLog = /* GraphQL */ `
  mutation CreateLog(
    $input: CreateLogInput!
    $condition: ModelLogConditionInput
  ) {
    createLog(input: $input, condition: $condition) {
      id
      userID
      user {
        id
        name
        middlename
        lastnameP
        lastnameM
        email
        phone
        extension
        status
        roles {
          nextToken
          startedAt
        }
        teams {
          nextToken
          startedAt
        }
        tasks {
          nextToken
          startedAt
        }
        tasksSupervised {
          nextToken
          startedAt
        }
        logs {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      dataJSON
      dataType
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateLog = /* GraphQL */ `
  mutation UpdateLog(
    $input: UpdateLogInput!
    $condition: ModelLogConditionInput
  ) {
    updateLog(input: $input, condition: $condition) {
      id
      userID
      user {
        id
        name
        middlename
        lastnameP
        lastnameM
        email
        phone
        extension
        status
        roles {
          nextToken
          startedAt
        }
        teams {
          nextToken
          startedAt
        }
        tasks {
          nextToken
          startedAt
        }
        tasksSupervised {
          nextToken
          startedAt
        }
        logs {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      dataJSON
      dataType
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteLog = /* GraphQL */ `
  mutation DeleteLog(
    $input: DeleteLogInput!
    $condition: ModelLogConditionInput
  ) {
    deleteLog(input: $input, condition: $condition) {
      id
      userID
      user {
        id
        name
        middlename
        lastnameP
        lastnameM
        email
        phone
        extension
        status
        roles {
          nextToken
          startedAt
        }
        teams {
          nextToken
          startedAt
        }
        tasks {
          nextToken
          startedAt
        }
        tasksSupervised {
          nextToken
          startedAt
        }
        logs {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      dataJSON
      dataType
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createAdmDocumentos = /* GraphQL */ `
  mutation CreateAdmDocumentos(
    $input: CreateAdmDocumentosInput!
    $condition: ModelAdmDocumentosConditionInput
  ) {
    createAdmDocumentos(input: $input, condition: $condition) {
      id
      movimientos {
        items {
          id
          idDocumento
          idProducto
          idAlmacen
          numeroMovimiento
          unidades
          unidadesCapturadas
          precio
          precioCapturado
          costoCapturado
          costoEspecifico
          neto
          impuesto1
          porcentajeImpuesto1
          total
          observacionMovimiento
          movtooculto
          afectaExistencia
          afectadosSaldo
          afectadoInventario
          fecha
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      idDocumentoOrigen
      conceptoDocumento {
        id
        codigoConcepto
        nombreConcepto
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      idConcepto
      idSerieDocumento
      clienteProveedor {
        id
        codigoCliente
        razonSocial
        fechaAltaCliente
        fechaBajaCliente
        rfc
        curp
        denComercial
        estatus
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      idCliente
      proyecto {
        id
        codigoProyecto
        nombreProyecto
        fechaAltaProyecto
        fechaBajaProyecto
        estatus
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      idProyecto
      moneda {
        id
        nombreMoneda
        plural
        singular
        claveSat
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      idMoneda
      folio
      fecha
      razonSocial
      rfc
      referencia
      observaciones
      usaCliente
      usaProveedor
      cancelado
      devuelto
      neto
      impuesto1
      total
      pendiente
      totalUnidades
      porcentajeImpuesto1
      unidadesPenientes
      usuarioCONTPAQi
      timestamp
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateAdmDocumentos = /* GraphQL */ `
  mutation UpdateAdmDocumentos(
    $input: UpdateAdmDocumentosInput!
    $condition: ModelAdmDocumentosConditionInput
  ) {
    updateAdmDocumentos(input: $input, condition: $condition) {
      id
      movimientos {
        items {
          id
          idDocumento
          idProducto
          idAlmacen
          numeroMovimiento
          unidades
          unidadesCapturadas
          precio
          precioCapturado
          costoCapturado
          costoEspecifico
          neto
          impuesto1
          porcentajeImpuesto1
          total
          observacionMovimiento
          movtooculto
          afectaExistencia
          afectadosSaldo
          afectadoInventario
          fecha
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      idDocumentoOrigen
      conceptoDocumento {
        id
        codigoConcepto
        nombreConcepto
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      idConcepto
      idSerieDocumento
      clienteProveedor {
        id
        codigoCliente
        razonSocial
        fechaAltaCliente
        fechaBajaCliente
        rfc
        curp
        denComercial
        estatus
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      idCliente
      proyecto {
        id
        codigoProyecto
        nombreProyecto
        fechaAltaProyecto
        fechaBajaProyecto
        estatus
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      idProyecto
      moneda {
        id
        nombreMoneda
        plural
        singular
        claveSat
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      idMoneda
      folio
      fecha
      razonSocial
      rfc
      referencia
      observaciones
      usaCliente
      usaProveedor
      cancelado
      devuelto
      neto
      impuesto1
      total
      pendiente
      totalUnidades
      porcentajeImpuesto1
      unidadesPenientes
      usuarioCONTPAQi
      timestamp
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteAdmDocumentos = /* GraphQL */ `
  mutation DeleteAdmDocumentos(
    $input: DeleteAdmDocumentosInput!
    $condition: ModelAdmDocumentosConditionInput
  ) {
    deleteAdmDocumentos(input: $input, condition: $condition) {
      id
      movimientos {
        items {
          id
          idDocumento
          idProducto
          idAlmacen
          numeroMovimiento
          unidades
          unidadesCapturadas
          precio
          precioCapturado
          costoCapturado
          costoEspecifico
          neto
          impuesto1
          porcentajeImpuesto1
          total
          observacionMovimiento
          movtooculto
          afectaExistencia
          afectadosSaldo
          afectadoInventario
          fecha
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      idDocumentoOrigen
      conceptoDocumento {
        id
        codigoConcepto
        nombreConcepto
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      idConcepto
      idSerieDocumento
      clienteProveedor {
        id
        codigoCliente
        razonSocial
        fechaAltaCliente
        fechaBajaCliente
        rfc
        curp
        denComercial
        estatus
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      idCliente
      proyecto {
        id
        codigoProyecto
        nombreProyecto
        fechaAltaProyecto
        fechaBajaProyecto
        estatus
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      idProyecto
      moneda {
        id
        nombreMoneda
        plural
        singular
        claveSat
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      idMoneda
      folio
      fecha
      razonSocial
      rfc
      referencia
      observaciones
      usaCliente
      usaProveedor
      cancelado
      devuelto
      neto
      impuesto1
      total
      pendiente
      totalUnidades
      porcentajeImpuesto1
      unidadesPenientes
      usuarioCONTPAQi
      timestamp
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createAdmConceptos = /* GraphQL */ `
  mutation CreateAdmConceptos(
    $input: CreateAdmConceptosInput!
    $condition: ModelAdmConceptosConditionInput
  ) {
    createAdmConceptos(input: $input, condition: $condition) {
      id
      codigoConcepto
      nombreConcepto
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateAdmConceptos = /* GraphQL */ `
  mutation UpdateAdmConceptos(
    $input: UpdateAdmConceptosInput!
    $condition: ModelAdmConceptosConditionInput
  ) {
    updateAdmConceptos(input: $input, condition: $condition) {
      id
      codigoConcepto
      nombreConcepto
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteAdmConceptos = /* GraphQL */ `
  mutation DeleteAdmConceptos(
    $input: DeleteAdmConceptosInput!
    $condition: ModelAdmConceptosConditionInput
  ) {
    deleteAdmConceptos(input: $input, condition: $condition) {
      id
      codigoConcepto
      nombreConcepto
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createAdmClientes = /* GraphQL */ `
  mutation CreateAdmClientes(
    $input: CreateAdmClientesInput!
    $condition: ModelAdmClientesConditionInput
  ) {
    createAdmClientes(input: $input, condition: $condition) {
      id
      codigoCliente
      razonSocial
      fechaAltaCliente
      fechaBajaCliente
      rfc
      curp
      denComercial
      estatus
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateAdmClientes = /* GraphQL */ `
  mutation UpdateAdmClientes(
    $input: UpdateAdmClientesInput!
    $condition: ModelAdmClientesConditionInput
  ) {
    updateAdmClientes(input: $input, condition: $condition) {
      id
      codigoCliente
      razonSocial
      fechaAltaCliente
      fechaBajaCliente
      rfc
      curp
      denComercial
      estatus
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteAdmClientes = /* GraphQL */ `
  mutation DeleteAdmClientes(
    $input: DeleteAdmClientesInput!
    $condition: ModelAdmClientesConditionInput
  ) {
    deleteAdmClientes(input: $input, condition: $condition) {
      id
      codigoCliente
      razonSocial
      fechaAltaCliente
      fechaBajaCliente
      rfc
      curp
      denComercial
      estatus
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createAdmProyectos = /* GraphQL */ `
  mutation CreateAdmProyectos(
    $input: CreateAdmProyectosInput!
    $condition: ModelAdmProyectosConditionInput
  ) {
    createAdmProyectos(input: $input, condition: $condition) {
      id
      codigoProyecto
      nombreProyecto
      fechaAltaProyecto
      fechaBajaProyecto
      estatus
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateAdmProyectos = /* GraphQL */ `
  mutation UpdateAdmProyectos(
    $input: UpdateAdmProyectosInput!
    $condition: ModelAdmProyectosConditionInput
  ) {
    updateAdmProyectos(input: $input, condition: $condition) {
      id
      codigoProyecto
      nombreProyecto
      fechaAltaProyecto
      fechaBajaProyecto
      estatus
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteAdmProyectos = /* GraphQL */ `
  mutation DeleteAdmProyectos(
    $input: DeleteAdmProyectosInput!
    $condition: ModelAdmProyectosConditionInput
  ) {
    deleteAdmProyectos(input: $input, condition: $condition) {
      id
      codigoProyecto
      nombreProyecto
      fechaAltaProyecto
      fechaBajaProyecto
      estatus
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createAdmMonedas = /* GraphQL */ `
  mutation CreateAdmMonedas(
    $input: CreateAdmMonedasInput!
    $condition: ModelAdmMonedasConditionInput
  ) {
    createAdmMonedas(input: $input, condition: $condition) {
      id
      nombreMoneda
      plural
      singular
      claveSat
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateAdmMonedas = /* GraphQL */ `
  mutation UpdateAdmMonedas(
    $input: UpdateAdmMonedasInput!
    $condition: ModelAdmMonedasConditionInput
  ) {
    updateAdmMonedas(input: $input, condition: $condition) {
      id
      nombreMoneda
      plural
      singular
      claveSat
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteAdmMonedas = /* GraphQL */ `
  mutation DeleteAdmMonedas(
    $input: DeleteAdmMonedasInput!
    $condition: ModelAdmMonedasConditionInput
  ) {
    deleteAdmMonedas(input: $input, condition: $condition) {
      id
      nombreMoneda
      plural
      singular
      claveSat
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createAdmMovimientos = /* GraphQL */ `
  mutation CreateAdmMovimientos(
    $input: CreateAdmMovimientosInput!
    $condition: ModelAdmMovimientosConditionInput
  ) {
    createAdmMovimientos(input: $input, condition: $condition) {
      id
      documento {
        id
        movimientos {
          nextToken
          startedAt
        }
        idDocumentoOrigen
        conceptoDocumento {
          id
          codigoConcepto
          nombreConcepto
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        idConcepto
        idSerieDocumento
        clienteProveedor {
          id
          codigoCliente
          razonSocial
          fechaAltaCliente
          fechaBajaCliente
          rfc
          curp
          denComercial
          estatus
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        idCliente
        proyecto {
          id
          codigoProyecto
          nombreProyecto
          fechaAltaProyecto
          fechaBajaProyecto
          estatus
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        idProyecto
        moneda {
          id
          nombreMoneda
          plural
          singular
          claveSat
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        idMoneda
        folio
        fecha
        razonSocial
        rfc
        referencia
        observaciones
        usaCliente
        usaProveedor
        cancelado
        devuelto
        neto
        impuesto1
        total
        pendiente
        totalUnidades
        porcentajeImpuesto1
        unidadesPenientes
        usuarioCONTPAQi
        timestamp
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      idDocumento
      numerosSerie {
        items {
          id
          movimientoID
          serieID
          fecha
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      producto {
        id
        excluded {
          id
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        idUnidadBase
        codigoProducto
        nombreProducto
        tipoProducto
        fechaAltaProducto
        fechaBajaProducto
        controlExistencia
        descripcionProducto
        metodoCosteo
        statusProducto
        status
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      idProducto
      almacen {
        id
        codigoAlmacen
        nombreAlmacen
        fechaAltaAlmacen
        status
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      idAlmacen
      unidad {
        id
        nombreUnidad
        abreviatura
        claveInt
        claveSat
        status
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      numeroMovimiento
      unidades
      unidadesCapturadas
      precio
      precioCapturado
      costoCapturado
      costoEspecifico
      neto
      impuesto1
      porcentajeImpuesto1
      total
      observacionMovimiento
      movtooculto
      afectaExistencia
      afectadosSaldo
      afectadoInventario
      fecha
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateAdmMovimientos = /* GraphQL */ `
  mutation UpdateAdmMovimientos(
    $input: UpdateAdmMovimientosInput!
    $condition: ModelAdmMovimientosConditionInput
  ) {
    updateAdmMovimientos(input: $input, condition: $condition) {
      id
      documento {
        id
        movimientos {
          nextToken
          startedAt
        }
        idDocumentoOrigen
        conceptoDocumento {
          id
          codigoConcepto
          nombreConcepto
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        idConcepto
        idSerieDocumento
        clienteProveedor {
          id
          codigoCliente
          razonSocial
          fechaAltaCliente
          fechaBajaCliente
          rfc
          curp
          denComercial
          estatus
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        idCliente
        proyecto {
          id
          codigoProyecto
          nombreProyecto
          fechaAltaProyecto
          fechaBajaProyecto
          estatus
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        idProyecto
        moneda {
          id
          nombreMoneda
          plural
          singular
          claveSat
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        idMoneda
        folio
        fecha
        razonSocial
        rfc
        referencia
        observaciones
        usaCliente
        usaProveedor
        cancelado
        devuelto
        neto
        impuesto1
        total
        pendiente
        totalUnidades
        porcentajeImpuesto1
        unidadesPenientes
        usuarioCONTPAQi
        timestamp
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      idDocumento
      numerosSerie {
        items {
          id
          movimientoID
          serieID
          fecha
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      producto {
        id
        excluded {
          id
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        idUnidadBase
        codigoProducto
        nombreProducto
        tipoProducto
        fechaAltaProducto
        fechaBajaProducto
        controlExistencia
        descripcionProducto
        metodoCosteo
        statusProducto
        status
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      idProducto
      almacen {
        id
        codigoAlmacen
        nombreAlmacen
        fechaAltaAlmacen
        status
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      idAlmacen
      unidad {
        id
        nombreUnidad
        abreviatura
        claveInt
        claveSat
        status
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      numeroMovimiento
      unidades
      unidadesCapturadas
      precio
      precioCapturado
      costoCapturado
      costoEspecifico
      neto
      impuesto1
      porcentajeImpuesto1
      total
      observacionMovimiento
      movtooculto
      afectaExistencia
      afectadosSaldo
      afectadoInventario
      fecha
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteAdmMovimientos = /* GraphQL */ `
  mutation DeleteAdmMovimientos(
    $input: DeleteAdmMovimientosInput!
    $condition: ModelAdmMovimientosConditionInput
  ) {
    deleteAdmMovimientos(input: $input, condition: $condition) {
      id
      documento {
        id
        movimientos {
          nextToken
          startedAt
        }
        idDocumentoOrigen
        conceptoDocumento {
          id
          codigoConcepto
          nombreConcepto
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        idConcepto
        idSerieDocumento
        clienteProveedor {
          id
          codigoCliente
          razonSocial
          fechaAltaCliente
          fechaBajaCliente
          rfc
          curp
          denComercial
          estatus
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        idCliente
        proyecto {
          id
          codigoProyecto
          nombreProyecto
          fechaAltaProyecto
          fechaBajaProyecto
          estatus
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        idProyecto
        moneda {
          id
          nombreMoneda
          plural
          singular
          claveSat
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        idMoneda
        folio
        fecha
        razonSocial
        rfc
        referencia
        observaciones
        usaCliente
        usaProveedor
        cancelado
        devuelto
        neto
        impuesto1
        total
        pendiente
        totalUnidades
        porcentajeImpuesto1
        unidadesPenientes
        usuarioCONTPAQi
        timestamp
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      idDocumento
      numerosSerie {
        items {
          id
          movimientoID
          serieID
          fecha
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      producto {
        id
        excluded {
          id
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        idUnidadBase
        codigoProducto
        nombreProducto
        tipoProducto
        fechaAltaProducto
        fechaBajaProducto
        controlExistencia
        descripcionProducto
        metodoCosteo
        statusProducto
        status
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      idProducto
      almacen {
        id
        codigoAlmacen
        nombreAlmacen
        fechaAltaAlmacen
        status
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      idAlmacen
      unidad {
        id
        nombreUnidad
        abreviatura
        claveInt
        claveSat
        status
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      numeroMovimiento
      unidades
      unidadesCapturadas
      precio
      precioCapturado
      costoCapturado
      costoEspecifico
      neto
      impuesto1
      porcentajeImpuesto1
      total
      observacionMovimiento
      movtooculto
      afectaExistencia
      afectadosSaldo
      afectadoInventario
      fecha
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createAdmProductos = /* GraphQL */ `
  mutation CreateAdmProductos(
    $input: CreateAdmProductosInput!
    $condition: ModelAdmProductosConditionInput
  ) {
    createAdmProductos(input: $input, condition: $condition) {
      id
      excluded {
        id
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      idUnidadBase
      codigoProducto
      nombreProducto
      tipoProducto
      fechaAltaProducto
      fechaBajaProducto
      controlExistencia
      descripcionProducto
      metodoCosteo
      statusProducto
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateAdmProductos = /* GraphQL */ `
  mutation UpdateAdmProductos(
    $input: UpdateAdmProductosInput!
    $condition: ModelAdmProductosConditionInput
  ) {
    updateAdmProductos(input: $input, condition: $condition) {
      id
      excluded {
        id
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      idUnidadBase
      codigoProducto
      nombreProducto
      tipoProducto
      fechaAltaProducto
      fechaBajaProducto
      controlExistencia
      descripcionProducto
      metodoCosteo
      statusProducto
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteAdmProductos = /* GraphQL */ `
  mutation DeleteAdmProductos(
    $input: DeleteAdmProductosInput!
    $condition: ModelAdmProductosConditionInput
  ) {
    deleteAdmProductos(input: $input, condition: $condition) {
      id
      excluded {
        id
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      idUnidadBase
      codigoProducto
      nombreProducto
      tipoProducto
      fechaAltaProducto
      fechaBajaProducto
      controlExistencia
      descripcionProducto
      metodoCosteo
      statusProducto
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createAdmAlmacenes = /* GraphQL */ `
  mutation CreateAdmAlmacenes(
    $input: CreateAdmAlmacenesInput!
    $condition: ModelAdmAlmacenesConditionInput
  ) {
    createAdmAlmacenes(input: $input, condition: $condition) {
      id
      codigoAlmacen
      nombreAlmacen
      fechaAltaAlmacen
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateAdmAlmacenes = /* GraphQL */ `
  mutation UpdateAdmAlmacenes(
    $input: UpdateAdmAlmacenesInput!
    $condition: ModelAdmAlmacenesConditionInput
  ) {
    updateAdmAlmacenes(input: $input, condition: $condition) {
      id
      codigoAlmacen
      nombreAlmacen
      fechaAltaAlmacen
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteAdmAlmacenes = /* GraphQL */ `
  mutation DeleteAdmAlmacenes(
    $input: DeleteAdmAlmacenesInput!
    $condition: ModelAdmAlmacenesConditionInput
  ) {
    deleteAdmAlmacenes(input: $input, condition: $condition) {
      id
      codigoAlmacen
      nombreAlmacen
      fechaAltaAlmacen
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createAdmUnidadesMedidaPeso = /* GraphQL */ `
  mutation CreateAdmUnidadesMedidaPeso(
    $input: CreateAdmUnidadesMedidaPesoInput!
    $condition: ModelAdmUnidadesMedidaPesoConditionInput
  ) {
    createAdmUnidadesMedidaPeso(input: $input, condition: $condition) {
      id
      nombreUnidad
      abreviatura
      claveInt
      claveSat
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateAdmUnidadesMedidaPeso = /* GraphQL */ `
  mutation UpdateAdmUnidadesMedidaPeso(
    $input: UpdateAdmUnidadesMedidaPesoInput!
    $condition: ModelAdmUnidadesMedidaPesoConditionInput
  ) {
    updateAdmUnidadesMedidaPeso(input: $input, condition: $condition) {
      id
      nombreUnidad
      abreviatura
      claveInt
      claveSat
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteAdmUnidadesMedidaPeso = /* GraphQL */ `
  mutation DeleteAdmUnidadesMedidaPeso(
    $input: DeleteAdmUnidadesMedidaPesoInput!
    $condition: ModelAdmUnidadesMedidaPesoConditionInput
  ) {
    deleteAdmUnidadesMedidaPeso(input: $input, condition: $condition) {
      id
      nombreUnidad
      abreviatura
      claveInt
      claveSat
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createAdmMovimientosSerie = /* GraphQL */ `
  mutation CreateAdmMovimientosSerie(
    $input: CreateAdmMovimientosSerieInput!
    $condition: ModelAdmMovimientosSerieConditionInput
  ) {
    createAdmMovimientosSerie(input: $input, condition: $condition) {
      id
      movimientoID
      serieID
      movimiento {
        id
        documento {
          id
          idDocumentoOrigen
          idConcepto
          idSerieDocumento
          idCliente
          idProyecto
          idMoneda
          folio
          fecha
          razonSocial
          rfc
          referencia
          observaciones
          usaCliente
          usaProveedor
          cancelado
          devuelto
          neto
          impuesto1
          total
          pendiente
          totalUnidades
          porcentajeImpuesto1
          unidadesPenientes
          usuarioCONTPAQi
          timestamp
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        idDocumento
        numerosSerie {
          nextToken
          startedAt
        }
        producto {
          id
          idUnidadBase
          codigoProducto
          nombreProducto
          tipoProducto
          fechaAltaProducto
          fechaBajaProducto
          controlExistencia
          descripcionProducto
          metodoCosteo
          statusProducto
          status
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        idProducto
        almacen {
          id
          codigoAlmacen
          nombreAlmacen
          fechaAltaAlmacen
          status
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        idAlmacen
        unidad {
          id
          nombreUnidad
          abreviatura
          claveInt
          claveSat
          status
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        numeroMovimiento
        unidades
        unidadesCapturadas
        precio
        precioCapturado
        costoCapturado
        costoEspecifico
        neto
        impuesto1
        porcentajeImpuesto1
        total
        observacionMovimiento
        movtooculto
        afectaExistencia
        afectadosSaldo
        afectadoInventario
        fecha
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      serie {
        id
        excluded {
          id
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        movimientos {
          nextToken
          startedAt
        }
        producto {
          id
          idUnidadBase
          codigoProducto
          nombreProducto
          tipoProducto
          fechaAltaProducto
          fechaBajaProducto
          controlExistencia
          descripcionProducto
          metodoCosteo
          statusProducto
          status
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        idProducto
        numeroSerie
        almacen {
          id
          codigoAlmacen
          nombreAlmacen
          fechaAltaAlmacen
          status
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        idAlmacen
        estado
        numeroLote
        nombreConcepto
        ordenDeCompra
        ordenDeCompraDetalle
        bookingOrdenCompra
        descripcionDeArticulo
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      fecha
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateAdmMovimientosSerie = /* GraphQL */ `
  mutation UpdateAdmMovimientosSerie(
    $input: UpdateAdmMovimientosSerieInput!
    $condition: ModelAdmMovimientosSerieConditionInput
  ) {
    updateAdmMovimientosSerie(input: $input, condition: $condition) {
      id
      movimientoID
      serieID
      movimiento {
        id
        documento {
          id
          idDocumentoOrigen
          idConcepto
          idSerieDocumento
          idCliente
          idProyecto
          idMoneda
          folio
          fecha
          razonSocial
          rfc
          referencia
          observaciones
          usaCliente
          usaProveedor
          cancelado
          devuelto
          neto
          impuesto1
          total
          pendiente
          totalUnidades
          porcentajeImpuesto1
          unidadesPenientes
          usuarioCONTPAQi
          timestamp
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        idDocumento
        numerosSerie {
          nextToken
          startedAt
        }
        producto {
          id
          idUnidadBase
          codigoProducto
          nombreProducto
          tipoProducto
          fechaAltaProducto
          fechaBajaProducto
          controlExistencia
          descripcionProducto
          metodoCosteo
          statusProducto
          status
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        idProducto
        almacen {
          id
          codigoAlmacen
          nombreAlmacen
          fechaAltaAlmacen
          status
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        idAlmacen
        unidad {
          id
          nombreUnidad
          abreviatura
          claveInt
          claveSat
          status
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        numeroMovimiento
        unidades
        unidadesCapturadas
        precio
        precioCapturado
        costoCapturado
        costoEspecifico
        neto
        impuesto1
        porcentajeImpuesto1
        total
        observacionMovimiento
        movtooculto
        afectaExistencia
        afectadosSaldo
        afectadoInventario
        fecha
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      serie {
        id
        excluded {
          id
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        movimientos {
          nextToken
          startedAt
        }
        producto {
          id
          idUnidadBase
          codigoProducto
          nombreProducto
          tipoProducto
          fechaAltaProducto
          fechaBajaProducto
          controlExistencia
          descripcionProducto
          metodoCosteo
          statusProducto
          status
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        idProducto
        numeroSerie
        almacen {
          id
          codigoAlmacen
          nombreAlmacen
          fechaAltaAlmacen
          status
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        idAlmacen
        estado
        numeroLote
        nombreConcepto
        ordenDeCompra
        ordenDeCompraDetalle
        bookingOrdenCompra
        descripcionDeArticulo
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      fecha
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteAdmMovimientosSerie = /* GraphQL */ `
  mutation DeleteAdmMovimientosSerie(
    $input: DeleteAdmMovimientosSerieInput!
    $condition: ModelAdmMovimientosSerieConditionInput
  ) {
    deleteAdmMovimientosSerie(input: $input, condition: $condition) {
      id
      movimientoID
      serieID
      movimiento {
        id
        documento {
          id
          idDocumentoOrigen
          idConcepto
          idSerieDocumento
          idCliente
          idProyecto
          idMoneda
          folio
          fecha
          razonSocial
          rfc
          referencia
          observaciones
          usaCliente
          usaProveedor
          cancelado
          devuelto
          neto
          impuesto1
          total
          pendiente
          totalUnidades
          porcentajeImpuesto1
          unidadesPenientes
          usuarioCONTPAQi
          timestamp
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        idDocumento
        numerosSerie {
          nextToken
          startedAt
        }
        producto {
          id
          idUnidadBase
          codigoProducto
          nombreProducto
          tipoProducto
          fechaAltaProducto
          fechaBajaProducto
          controlExistencia
          descripcionProducto
          metodoCosteo
          statusProducto
          status
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        idProducto
        almacen {
          id
          codigoAlmacen
          nombreAlmacen
          fechaAltaAlmacen
          status
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        idAlmacen
        unidad {
          id
          nombreUnidad
          abreviatura
          claveInt
          claveSat
          status
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        numeroMovimiento
        unidades
        unidadesCapturadas
        precio
        precioCapturado
        costoCapturado
        costoEspecifico
        neto
        impuesto1
        porcentajeImpuesto1
        total
        observacionMovimiento
        movtooculto
        afectaExistencia
        afectadosSaldo
        afectadoInventario
        fecha
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      serie {
        id
        excluded {
          id
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        movimientos {
          nextToken
          startedAt
        }
        producto {
          id
          idUnidadBase
          codigoProducto
          nombreProducto
          tipoProducto
          fechaAltaProducto
          fechaBajaProducto
          controlExistencia
          descripcionProducto
          metodoCosteo
          statusProducto
          status
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        idProducto
        numeroSerie
        almacen {
          id
          codigoAlmacen
          nombreAlmacen
          fechaAltaAlmacen
          status
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        idAlmacen
        estado
        numeroLote
        nombreConcepto
        ordenDeCompra
        ordenDeCompraDetalle
        bookingOrdenCompra
        descripcionDeArticulo
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      fecha
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createAdmNumerosSerie = /* GraphQL */ `
  mutation CreateAdmNumerosSerie(
    $input: CreateAdmNumerosSerieInput!
    $condition: ModelAdmNumerosSerieConditionInput
  ) {
    createAdmNumerosSerie(input: $input, condition: $condition) {
      id
      excluded {
        id
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      movimientos {
        items {
          id
          movimientoID
          serieID
          fecha
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      producto {
        id
        excluded {
          id
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        idUnidadBase
        codigoProducto
        nombreProducto
        tipoProducto
        fechaAltaProducto
        fechaBajaProducto
        controlExistencia
        descripcionProducto
        metodoCosteo
        statusProducto
        status
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      idProducto
      numeroSerie
      almacen {
        id
        codigoAlmacen
        nombreAlmacen
        fechaAltaAlmacen
        status
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      idAlmacen
      estado
      numeroLote
      nombreConcepto
      ordenDeCompra
      ordenDeCompraDetalle
      bookingOrdenCompra
      descripcionDeArticulo
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateAdmNumerosSerie = /* GraphQL */ `
  mutation UpdateAdmNumerosSerie(
    $input: UpdateAdmNumerosSerieInput!
    $condition: ModelAdmNumerosSerieConditionInput
  ) {
    updateAdmNumerosSerie(input: $input, condition: $condition) {
      id
      excluded {
        id
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      movimientos {
        items {
          id
          movimientoID
          serieID
          fecha
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      producto {
        id
        excluded {
          id
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        idUnidadBase
        codigoProducto
        nombreProducto
        tipoProducto
        fechaAltaProducto
        fechaBajaProducto
        controlExistencia
        descripcionProducto
        metodoCosteo
        statusProducto
        status
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      idProducto
      numeroSerie
      almacen {
        id
        codigoAlmacen
        nombreAlmacen
        fechaAltaAlmacen
        status
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      idAlmacen
      estado
      numeroLote
      nombreConcepto
      ordenDeCompra
      ordenDeCompraDetalle
      bookingOrdenCompra
      descripcionDeArticulo
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteAdmNumerosSerie = /* GraphQL */ `
  mutation DeleteAdmNumerosSerie(
    $input: DeleteAdmNumerosSerieInput!
    $condition: ModelAdmNumerosSerieConditionInput
  ) {
    deleteAdmNumerosSerie(input: $input, condition: $condition) {
      id
      excluded {
        id
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      movimientos {
        items {
          id
          movimientoID
          serieID
          fecha
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      producto {
        id
        excluded {
          id
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        idUnidadBase
        codigoProducto
        nombreProducto
        tipoProducto
        fechaAltaProducto
        fechaBajaProducto
        controlExistencia
        descripcionProducto
        metodoCosteo
        statusProducto
        status
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      idProducto
      numeroSerie
      almacen {
        id
        codigoAlmacen
        nombreAlmacen
        fechaAltaAlmacen
        status
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      idAlmacen
      estado
      numeroLote
      nombreConcepto
      ordenDeCompra
      ordenDeCompraDetalle
      bookingOrdenCompra
      descripcionDeArticulo
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createAdmNumerosSerieExcluded = /* GraphQL */ `
  mutation CreateAdmNumerosSerieExcluded(
    $input: CreateAdmNumerosSerieExcludedInput!
    $condition: ModelAdmNumerosSerieExcludedConditionInput
  ) {
    createAdmNumerosSerieExcluded(input: $input, condition: $condition) {
      id
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateAdmNumerosSerieExcluded = /* GraphQL */ `
  mutation UpdateAdmNumerosSerieExcluded(
    $input: UpdateAdmNumerosSerieExcludedInput!
    $condition: ModelAdmNumerosSerieExcludedConditionInput
  ) {
    updateAdmNumerosSerieExcluded(input: $input, condition: $condition) {
      id
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteAdmNumerosSerieExcluded = /* GraphQL */ `
  mutation DeleteAdmNumerosSerieExcluded(
    $input: DeleteAdmNumerosSerieExcludedInput!
    $condition: ModelAdmNumerosSerieExcludedConditionInput
  ) {
    deleteAdmNumerosSerieExcluded(input: $input, condition: $condition) {
      id
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createAdmProductosExcluded = /* GraphQL */ `
  mutation CreateAdmProductosExcluded(
    $input: CreateAdmProductosExcludedInput!
    $condition: ModelAdmProductosExcludedConditionInput
  ) {
    createAdmProductosExcluded(input: $input, condition: $condition) {
      id
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateAdmProductosExcluded = /* GraphQL */ `
  mutation UpdateAdmProductosExcluded(
    $input: UpdateAdmProductosExcludedInput!
    $condition: ModelAdmProductosExcludedConditionInput
  ) {
    updateAdmProductosExcluded(input: $input, condition: $condition) {
      id
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteAdmProductosExcluded = /* GraphQL */ `
  mutation DeleteAdmProductosExcluded(
    $input: DeleteAdmProductosExcludedInput!
    $condition: ModelAdmProductosExcludedConditionInput
  ) {
    deleteAdmProductosExcluded(input: $input, condition: $condition) {
      id
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createContpaQiControlTable = /* GraphQL */ `
  mutation CreateContpaQiControlTable(
    $input: CreateCONTPAQiControlTableInput!
    $condition: ModelCONTPAQiControlTableConditionInput
  ) {
    createCONTPAQiControlTable(input: $input, condition: $condition) {
      id
      sqlLastReg
      sqlRegs
      dynamoLastReg
      dynamoRegs
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateContpaQiControlTable = /* GraphQL */ `
  mutation UpdateContpaQiControlTable(
    $input: UpdateCONTPAQiControlTableInput!
    $condition: ModelCONTPAQiControlTableConditionInput
  ) {
    updateCONTPAQiControlTable(input: $input, condition: $condition) {
      id
      sqlLastReg
      sqlRegs
      dynamoLastReg
      dynamoRegs
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteContpaQiControlTable = /* GraphQL */ `
  mutation DeleteContpaQiControlTable(
    $input: DeleteCONTPAQiControlTableInput!
    $condition: ModelCONTPAQiControlTableConditionInput
  ) {
    deleteCONTPAQiControlTable(input: $input, condition: $condition) {
      id
      sqlLastReg
      sqlRegs
      dynamoLastReg
      dynamoRegs
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
