/**
 * Este reducer maneja el estado para todas las peticiones de la aplicación
 */

import { SHOW_NOTIFICATION, REMOVE_NOTIFICATION } from "redux/action";

const INITIAL_STATE = [];

export default function notificationReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SHOW_NOTIFICATION:
      const { message, options = {} } = action.payload;
      const newKey = new Date().getTime() + getRandomInt(1, 100);
      return [...state, { key: newKey, message, options }];
    case REMOVE_NOTIFICATION:
      const { key } = action.payload;
      const newNotifications = state.filter(n => n.key !== key);
      return newNotifications;
    default:
      return state;
  }
};

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}