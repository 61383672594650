import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const IconCamera = () => {
  const classes = useStyles();
  return (
    <>
      <div>
        <span className={classes.img} role="img" aria-label="camera-icon"></span>
      </div>
    </>
  );
};
const useStyles = makeStyles(() => ({
  img: {
    backgroundImage: `url("/img/add_photo.png")`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "22px",
    height: "22px",
    display: "block",
  },
}));

export default IconCamera;
