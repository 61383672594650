/**
 * Query GraphQL para obtener el detalle de un Task.
 *
 * @param {string} id ID del Task que se consulta.
 */
const getTask = /* GraphQL */ `
  query GetTask($id: ID!, $assetsFilter: ModelTaskAssetsFilterInput, $assetsLimit: Int) {
    getTask(id: $id) {
      id
      status
      startDate
      endDate
      startTime
      endTime
      startedAt
      startedBy
      endedAt
      endedBy
      _version
      _deleted
      deleted
      premises {
        id
        name
        code
        address {
          id
          street
          number
          zipcode
          city
          settlement
          locality
        }
      }
      users {
        items {
          id
          userID
          taskID
          _version
          _deleted
          user {
            id
            name
            middlename
            lastnameP
            lastnameM
            email
            phone
            extension
            status
          }
          head
          createdAt
          updatedAt
        }
        nextToken
      }
      buildingLocation {
        id
        name
        code
        buildingBlock
        floor
        area
        premisesID
        premises {
          id
          name
          owner
          address {
            id
            street
            number
            zipcode
            city
          }
          custodian {
            name
            lastnameP
            email
            phone
          }
        }
      }
      taskType {
        id
        name
        description
      }
      supervisor {
        id
        name
        middlename
        lastnameP
        lastnameM
        email
        phone
        extension
      }
      assets(filter: $assetsFilter, limit: $assetsLimit) {
        items {
          scanned
          id
          _version
          _deleted
          scanned
          attachments {
            items {
              id
              status
              platform
              source
              loadType
              _version
              _deleted
              _lastChangedAt
              file {
                bucket
                key
                region
              }
            }
          }
          asset {
            id
            code
            serial
            quantity
            type
            comment
            _version
            _deleted
            material {
              id
              name
              code
              uom {
                name
                abbreviation
              }
            }
            uom {
              id
              name
              description
            }
            trackingID
            tracking {
              id
              status
              ikaID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            embeddedAttributes {
              attributeID
              id
              name
              value
            }
          }
        }
        nextToken
      }
      attachments {
        items {
          id
          status
          file {
            key
          }
        }
        nextToken
      }
      notes {
        items {
          id
          taskID
          comments
          supervisor {
            name
            middlename
            lastnameP
            lastnameM
            phone
            email
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      project {
        id
        name
        code
        company {
          id
          name
        }
      }
      remarks {
        items {
          id
          taskID
          taskStatus
          remark
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      effectiveTime
      embeddedEvents {
        _version
        createdAt
        description
        duration
        embeddedCause {
          description
          _version
          id
          name
          type
        }
        eventCauseID
        finalized
        id
        taskID
        timestamp
      }
      embeddedLastEvent {
        _version
        createdAt
        description
        duration
        eventCauseID
        finalized
        id
        taskID
        timestamp
        embeddedCause {
          type
          name
          id
          description
          _version
        }
      }
      events {
        items {
          updatedAt
          timestamp
          taskID
          id
          finalized
          eventCauseID
          duration
          description
          createdAt
          _version
          _lastChangedAt
          cause {
            _lastChangedAt
            _version
            createdAt
            description
            id
            name
            type
            updatedAt
          }
        }
      }
      lastEvent {
        _lastChangedAt
        _version
        createdAt
        cause {
          _lastChangedAt
          _version
          createdAt
          description
          id
          name
          type
          updatedAt
        }
        description
        duration
        eventCauseID
        finalized
        id
        taskID
        timestamp
        updatedAt
      }
      owner
      createdAt
      updatedAt
    }
  }
`;

export default getTask;
