import React from "react";
import PropTypes from "prop-types";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import makeStyles from "@material-ui/core/styles/makeStyles";
import AddIcon from "@material-ui/icons/Add";
import Box from "@material-ui/core/Box";
import Fab from "@material-ui/core/Fab";

import ScheduleNewInstallationDialog from "components/ScheduleNewInstallationDialog";
import TabContainer from "components/Tabs/TabContainer";
import ImplementationTable from "components/table/ImplementationTable";
import GroupRestrictedView from "components/common/GroupRestrictedView";
import Title from "components/common/Title";
import { Task, TaskStatus } from "models";
import { styleStatus, getStyleForTab, SECTIONS } from "util/homologationOfColumns";

const ALLOWED_UPDATE_GROUPS = ["supervisors"];
const useTableStyles = makeStyles((theme) => styleStatus(SECTIONS.IMPLEMENTATION));
const useStylesSelected = makeStyles(getStyleForTab(SECTIONS.IMPLEMENTATION));

function ValidatorInstallationsViewUI(props) {
  const isWeb = useMediaQuery("(min-width:600px)");
  const stylesTabs = useTableStyles();
  const stylesSelected = useStylesSelected();

  const tableProps = {
    data: props.tasks,
    loading: props.isLoading,
    onRowClick: props.onRowClick,
    onChangePage: props.onChangePage,
    onChangeRowsPerPage: props.onChangeRowsPerPage,
  };

  // RENDER COMPONENT TABS
  const listTableGeneral = <ImplementationTable data={props.tasks} status={TaskStatus.GENERAL} {...tableProps} />;
  const listTableSheduled = <ImplementationTable status={TaskStatus.SCHEDULED} {...tableProps} />;
  const listTableInProgres = <ImplementationTable status={TaskStatus.IN_PROGRESS} {...tableProps} />;
  const listTableWithNotes = <ImplementationTable status={TaskStatus.WITH_INSPECTION_NOTES} {...tableProps} />;
  const listTableComplete = <ImplementationTable status={TaskStatus.COMPLETED} {...tableProps} />;
  const listTableReviewed = <ImplementationTable status={TaskStatus.REVIEWED} {...tableProps} />;
  const listTableFinalized = <ImplementationTable status={TaskStatus.FINALIZED} {...tableProps} />;
  // Obj to define tabs
  const tabData = [
    {
      name: "General",
      index: 0,
      component: listTableGeneral,
      styles: TaskStatus.GENERAL,
    },
    {
      name: "Asignados",
      index: 1,
      component: listTableSheduled,
      styles: TaskStatus.SCHEDULED,
    },
    {
      name: "En progreso",
      index: 2,
      component: listTableInProgres,
      styles: TaskStatus.IN_PROGRESS,
    },
    {
      name: "Por Firmar",
      index: 3,
      component: listTableComplete,
      styles: TaskStatus.COMPLETED,
    },
    {
      name: "Con observaciones",
      index: 4,
      component: listTableWithNotes,
      styles: TaskStatus.WITH_INSPECTION_NOTES,
    },
    {
      name: "Validado",
      index: 5,
      component: listTableFinalized,
      styles: TaskStatus.FINALIZED,
    },
    {
      name: "Finalizado",
      index: 6,
      component: listTableReviewed,
      styles: TaskStatus.REVIEWED,
    },
  ];

  return (
    <>
      {isWeb && <Title>INSTALACIONES</Title>}

      <TabContainer
        tabData={tabData}
        stylesTabs={stylesTabs}
        stylesSelected={stylesSelected}
        onChangeTab={props.onChangeTab}
      />
      <GroupRestrictedView group={ALLOWED_UPDATE_GROUPS}>
        {(allowUpdate) =>
          allowUpdate ? (
            <Box position="fixed" zIndex="modal" bottom={15} right={15}>
              <Fab color="primary" onClick={props.onFloatingButtonClick}>
                <AddIcon />
              </Fab>
            </Box>
          ) : null
        }
      </GroupRestrictedView>
      {props.installationDialogProps.open && <ScheduleNewInstallationDialog {...props.installationDialogProps} />}
    </>
  );
}

ValidatorInstallationsViewUI.propTypes = {
  onFloatingButtonClick: PropTypes.func,
  onRowClick: PropTypes.func,
  onChangeTab: PropTypes.func,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
  tasks: PropTypes.shape(Task),
  installationDialogProps: PropTypes.object,
  isLoading: PropTypes.bool,
};

ValidatorInstallationsViewUI.defaultProps = {
  onFloatingButtonClick: () => {},
  onRowClick: () => {},
  onChangeTab: () => {},
  onChangePage: () => {},
  onChangeRowsPerPage: () => {},
  tasks: [],
  installationDialogProps: {},
  isLoading: false,
};

export default ValidatorInstallationsViewUI;
