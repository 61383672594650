export const listAssets = /* GraphQL */ `
  query ListAssets($filter: ModelAssetFilterInput, $limit: Int, $nextToken: String) {
    listAssets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        serial
        quantity
        type
        enabled
        _version
        _deleted
        createdAt
        updatedAt
        nomenclature
        project {
          id
          name
        }
        material {
          id
          name
          code
        }
        tracking {
          id
          status
          _lastChangedAt
        }
        attributes {
          items {
            id
            _version
            _deleted
            value
            attribute {
              id
              name
              description
            }
          }
        }
        embeddedAttributes {
          attributeID
          id
          value
          name
        }
      }
      nextToken
    }
  }
`;

export default listAssets;
