export const deleteAttachmentAsset = /* GraphQL */ `
  mutation DeleteAttachmentAsset($input: DeleteAttachmentAssetInput!) {
    deleteAttachmentAsset(input: $input) {
      id
      status
      updatedAt
      createdAt
      taskAssetID
      _version
      _lastChangedAt
      _deleted
      file {
        bucket
        key
        region
      }
    }
  }
`;