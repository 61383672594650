/**
 * Componente Fallback encargado de ejecutar un callback con un límite de intentos definidos.
 */

import React, { useRef } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { captureException } from "setup/sentry";

import { Box, Typography, Button, useTheme, useMediaQuery, Zoom } from "@material-ui/core";

const LIMIT_ATTEMPS_REACHED_ERROR_MSG = "Número de intentos de ejecución de callback alcanzado.";

const FetchErrorFallback = ({ open, onClick, limitAttems, onLimitAttempsReached, description, buttonContent }) => {
  const fetchAttemps = useRef(0);
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(767));

  const _description =
    fetchAttemps.current > limitAttems ? "Lo sentimos, no pudimos procesar su solicitud." : description;
  const _buttonContent = fetchAttemps.current > limitAttems ? "Volver al Menu" : buttonContent;

  const onTryAgain = () => {
    if (fetchAttemps.current > limitAttems) {
      fetchAttemps.current = 0;
      captureException(LIMIT_ATTEMPS_REACHED_ERROR_MSG, "FetchErrorFallback:onTryAgain");
      onLimitAttempsReached();
      history.goBack();
    }
    onClick();
    fetchAttemps.current = fetchAttemps.current + 1;
  };

  return (
    <Zoom in={open} unmountOnExit={true}>
      <Box width="100%" height="100vh" position="relative" margin="0 auto">
        <img
          src="/img/fallback.png"
          width="100%"
          alt="Ilustración de error"
          style={{ width: "100%", height: "100%", objectFit: "contain" }}
        />
        <Box
          maxWidth="400px"
          width="90%"
          position="absolute"
          top={isMobile ? "75%" : "50%"}
          left="50%"
          bgcolor="#fff"
          padding={isMobile ? "16px" : "24px"}
          borderRadius="6px"
          style={{ transform: isMobile ? "translateX(-50%)" : "translate(-50%, -50%)" }}
        >
          <Typography align="center" gutterBottom>
            {_description}
          </Typography>
          <Button variant="contained" color="primary" fullWidth={true} onClick={onTryAgain}>
            {_buttonContent}
          </Button>
        </Box>
      </Box>
    </Zoom>
  );
};

FetchErrorFallback.propTypes = {
  open: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  description: PropTypes.node,
  buttonContent: PropTypes.node,
  limitAttems: PropTypes.number,
  onLimitAttempsReached: PropTypes.func,
};

FetchErrorFallback.defaultProps = {
  description: "Ocurrio un error durante la carga de los datos.",
  buttonContent: "Intentar Nuevamente",
  limitAttems: 3,
  onLimitAttempsReached: () => {},
};

export default FetchErrorFallback;
