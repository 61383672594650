export const listUoms = /* GraphQL */ `
  query ListUnitsOfMeasures($filter: ModelUnitOfMeassureFilterInput, $limit: Int, $nextToken: String) {
    listUnitOfMeassures(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        abbreviation
        description
        createdAt
        updatedAt
        _version
        _deleted
      }
      nextToken
    }
  }
`;
