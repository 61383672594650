import { useEffect, useMemo, useState } from "react";
import { Geolocation } from "@capacitor/geolocation";
import { Logger } from "aws-amplify";
import { mapGeolocationError } from "util/geolocation";

const logger = new Logger("useWatchPosition");

function useWatchPosition(
  positionOptions = {
    enableHighAccuracy: false,
    timeout: 10000,
    maximumAge: 0,
  }
) {
  const [position, setPosition] = useState(null);
  const [loading, setLoading] = useState(false);
  const [watch, setWatch] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    initWatcher();
  }, []);

  useEffect(() => {
    return () => {
      watch && Geolocation.clearWatch({ id: watch });
    };
  }, [watch]);

  async function initWatcher() {
    setLoading(true);
    const watchId = await Geolocation.watchPosition(positionOptions, (posInfo, error) => {
      setLoading(false);
      logger.log("Información de la ubicación: ", { posInfo, error });
      if (error) {
        const gError = mapGeolocationError(error, "watchPosition");
        return setError(gError);
      }
      setPosition(posInfo);
      setError(null);
    });
    setWatch(watchId);
  }

  return useMemo(() => ({ position, loading, error }), [position, loading, error]);
}

export default useWatchPosition;
