import API, { graphqlOperation } from "@aws-amplify/api";
import * as mutations from "graphql-custom/mutations";

export async function createKit({ kitName, kitDescription, materials }) {
  const { data } = await API.graphql(
    graphqlOperation(mutations.createKit, { name: kitName, description: kitDescription })
  );

  const { variables, mutation } = mutations.generateKitMaterialsRelations(data.createKit.id, materials);
  const createAssignmentsKitRequest = await API.graphql(graphqlOperation(mutation, variables));

  return {
    ...data,
    ...createAssignmentsKitRequest.data,
  };
}

export async function updateKit({ id, name, description, _version, materials }) {
  const { data: kitDetails } = await API.graphql(
    graphqlOperation(mutations.updateKit, { id, name, description, _version })
  );
  const { mutation, params } = mutations.generateKitMaterialsMutation(id, materials);
  if (!mutation || !params) {
    return { ...kitDetails } ;
  }
  const { data: materialsDetails } = await API.graphql(graphqlOperation(mutation, params));
  return {
    ...kitDetails,
    ...materialsDetails,
  };
}
