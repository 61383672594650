/* eslint-disable react/display-name */
/**
 * El dialog de este componente servirá para registrar dos eventos sobre un envío. El primero es el despacho de un envío, el segundo su recepción.
 * La diferencia dentro del contenido dependerá del valor del estado del envío que le sea proporcionado como propiedad.
 * Este dialog se encargará de recibir la lista de activos que realmente terminaron enviandose/recibiendose y las evidencias registradas con ello.
 * El dialog se encontrará dentro del componente de detalle de envío.
 * */
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import QRCode from "qrcode.react";

import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/styles/makeStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import MaterialTable from "components/custom/MaterialTable";
import ConfirmationDialog from "components/ConfirmationDialog";
import useNotifier from "hooks/useNotifier";

import Dialog from "@material-ui/core/Dialog";
import EvidenceWithDropZone from "components/EvidenceWithDropZone/EvidenceWithDropZone";
import destinationDetails from "util/destinationDetails";

const TYPES = {
  WAREHOUSE_KEEPERS: "warehouse-keepers",
  SUPERVISORS: "supervisors",
};

const TABLE_STATUSES = {
  SHIPMENT_SCHEDULED: "SHIPMENT_SCHEDULED",
  SHIPMENT_SENT: "SHIPMENT_SENT",
  SHIPMENT_RECEIVED: "SHIPMENT_RECEIVED",
  // 28: "Retrasado",
};

export default function DeliveryDialog({
  status,
  open,
  onClose,
  incomeDetailInfo,
  assets,
  isLoading,
  handleOnSubmitDeliveryDialog,
  isMobile,
  preSelectAsset,
  selectQRCode,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const { showError } = useNotifier();
  const { register, getValues, error } = useForm();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const [selectedAssets, setSelectedAssets] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isUploadingAttachments, setIsUploadingAttachments] = useState(false);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (!open && showConfirmation) {
      setShowConfirmation(false);
    }
    if (open) {
      if (isMobile && matches) {
        const preselectedAssets = [];
        assets.forEach((a) => {
          if (status === TABLE_STATUSES.SHIPMENT_SENT && a.type === "SHIPMENT_PREV_SENT") {
            preselectedAssets.push(a);
          } else if (status === TABLE_STATUSES.SHIPMENT_SCHEDULED && a.type === "SHIPMENT_PREV_SCHEDULED") {
            preselectedAssets.push(a);
          }
        });
        setSelectedAssets(preselectedAssets);
      }
    }
  }, [open, assets]);

  function setConfirmationDialogStatus(status) {
    if (!showConfirmation && selectedAssets?.length === 0) {
      showError("Debe seleccionar al menos un activo");
    } else {
      setShowConfirmation(status);
    }
  }

  function onSubmit() {
    const values = getValues();
    handleOnSubmitDeliveryDialog(status, values, selectedAssets, files);
  }

  return (
    <>
      <Dialog
        fullScreen={matches}
        fullWidth={true}
        maxWidth="xl"
        open={open}
        onClose={onClose}
        aria-labelledby="delivery-dialog"
      >
        <DialogTitle id="max-width-dialog-title">
          {status === TABLE_STATUSES.SHIPMENT_SENT
            ? "Recepción de envío"
            : status === TABLE_STATUSES.SHIPMENT_SCHEDULED
            ? "Despacho de envío"
            : ""}
        </DialogTitle>
        <DialogContent>
          <Box>
            <p>
              <strong>Indicaciones:</strong>
            </p>
            {status === TABLE_STATUSES.SHIPMENT_SENT ? (
              <>
                <ol>
                  <li>Verifique la información del envío.</li>
                  <li>Seleccione los activos que se están recibiendo.</li>
                  <li>Ingrese las evidencias de la recepción.</li>
                </ol>
                <p>
                  <strong>Importante: </strong> Sólo los activos seleccionados se registrarán como recibidos.
                </p>
              </>
            ) : (
              <>
                <ol>
                  <li>Verifique la información del envío.</li>
                  <li>Seleccione los activos que se están enviando.</li>
                  <li>Ingrese las evidencias del despacho.</li>
                </ol>
                <p>
                  <strong>Importante: </strong> Sólo los activos seleccionados se registrarán como enviados.
                </p>
              </>
            )}
          </Box>
          <Box>
            <p>
              <strong>Datos del envío</strong>
            </p>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="standard-read-only-input"
                  label="Fecha y hora"
                  defaultValue={`${incomeDetailInfo?.startDate} ${incomeDetailInfo?.startTime} `}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="standard-read-only-input"
                  label="Destino"
                  defaultValue={destinationDetails(incomeDetailInfo)}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="standard-read-only-input"
                  label="Remitente"
                  defaultValue={`${incomeDetailInfo?.supervisor?.name || ""} ${
                    incomeDetailInfo?.supervisor?.lastnameP || ""
                  } ${incomeDetailInfo?.supervisor?.email ? `(${incomeDetailInfo?.supervisor?.email})` : ""}`}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          <Box>
            <div className={classes.root}>
              <MaterialTable
                title={`${status === TABLE_STATUSES.SHIPMENT_SENT ? "Activos recibidos" : "Activos envíados"}`}
                options={{
                  ...TABLE_OPTIONS,
                  selection: !matches || !isMobile,
                  rowStyle: (rowData) => ({
                    backgroundColor:
                      matches && isMobile
                        ? rowData.type === "SHIPMENT_PREV_SENT" || rowData.type === "SHIPMENT_PREV_SCHEDULED"
                          ? "#EBFFEC"
                          : "#FFF"
                        : "#FFF",
                  }),
                }}
                columns={!matches ? TABLE_COLUMNS : TABLE_COLUMNS_MOBILE}
                data={assets || []}
                onSelectionChange={(rows) => setSelectedAssets(rows)}
                onRowClick={
                  isMobile && matches
                    ? (evt, rowData) => {
                        console.log(rowData);
                        if (rowData.type === "SHIPMENT_PREV_SENT" || rowData.type === "SHIPMENT_PREV_SCHEDULED") {
                          preSelectAsset(rowData, status);
                          setSelectedAssets(selectedAssets.filter((sa) => sa.id !== rowData.id));
                        } else {
                          if (rowData?.tracking && rowData?.tracking?.id !== "unassigned") {
                            selectQRCode();
                          } else {
                            preSelectAsset(rowData, status);
                          }
                        }
                      }
                    : () => {}
                }
                isLoading={isLoading}
              />
            </div>
          </Box>
          <Box>
            <p>
              <strong>Evidencias</strong>
            </p>
            <EvidenceWithDropZone
              onLoading={setIsUploadingAttachments}
              taskId={incomeDetailInfo?.id}
              status={status}
              isMobile={isMobile}
              onNewFiles={setFiles}
            />
          </Box>
          <Box mt={2}>
            <TextField
              name="comments"
              inputRef={register}
              multiline
              fullWidth
              variant="outlined"
              label="Comentarios"
              rows={3}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="default" disabled={isLoading || isUploadingAttachments}>
            Cerrar
          </Button>
          <Button
            color="primary"
            onClick={() => setConfirmationDialogStatus(true)}
            disabled={isLoading || isUploadingAttachments}
          >
            Continuar
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        open={showConfirmation}
        title="Confirmación"
        onConfirm={() => onSubmit()}
        onCancel={() => setConfirmationDialogStatus(false)}
        loading={false}
      >
        {status === TABLE_STATUSES.SHIPMENT_SCHEDULED
          ? "Está apunto de registrar el despacho de los activos a su destino. Sólo los activos seleccionados serán registrados como enviados.¿Desea continuar?"
          : "Está apunto de registrar la recepción de los activos en su destino. Sólo los activos seleccionados se registrarán como recibidos.¿Desea continuar?"}
      </ConfirmationDialog>
    </>
  );
}

const TABLE_OPTIONS = {
  exportButton: false,
  columnsButton: false,
  selection: true,
};
const TABLE_COLUMNS = [
  { title: "Código Material", field: "material.code" },
  { title: "Nombre", field: "material.name" },
  {
    title: "Serie",
    field: "serial",
    render: (rowData) => (rowData?.serial === "DEFAULT_SERIE" ? "Sin serie" : rowData?.serial || "S/I"),
  },
];

const TABLE_COLUMNS_MOBILE = [
  {
    title: "",
    field: "asset.id",
    render: (rowData) => {
      return (
        <Grid container>
          <Grid item xs={2}>
            {rowData?.tracking?.id && rowData?.tracking?.id !== "unassigned" ? (
              <QRCode size={30} value={rowData?.tracking?.id} />
            ) : (
              <>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={rowData.type === "SHIPMENT_PREV_SENT" || rowData.type === "SHIPMENT_PREV_SCHEDULED"}
                      name="checkedB"
                      color="primary"
                    />
                  }
                />
              </>
            )}
          </Grid>
          <Grid item xs={9}>
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12}>
                <strong>Código Material: </strong>
                {rowData?.material?.code}
              </Grid>
              <Grid item sm={12} xs={12}>
                <strong>Nombre: </strong>
                {rowData?.material?.name}
              </Grid>
              <Grid item sm={12} xs={12}>
                <strong>Serie: </strong>
                {rowData?.serial === "DEFAULT_SERIE" ? "Sin serie" : rowData?.serial}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    },
  },
];

const useStyles = makeStyles((theme) => ({
  detailsGrid: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  detailsContent: {
    padding: theme.spacing(2),
  },
  username: {
    "& > *": {
      marginRight: theme.spacing(2),
    },
  },
  roleControl: {
    minWidth: 200,
    marginTop: theme.spacing(2),
  },
  root: {
    [theme.breakpoints.down("sm")]: {
      "& .MuiTableHead-root": {
        display: "none",
      },
      "& .MuiToolbar-root": {
        flexDirection: "column",
        padding: theme.spacing(2),
      },
      "& div[class*='MTableToolbar-actions-']": {
        order: 2,
        textAlign: "right",
        width: "100%",
      },
      "& div[class*='MTableToolbar-title-']": {
        order: 1,
        textAlign: "left",
        width: "100%",
      },
      "& div[class*='MTableToolbar-searchField-']": {
        width: "100%",
        order: 0,
        marginBottom: theme.spacing(2),
      },
    },
  },
}));
