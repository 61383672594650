import React from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import { Controller } from "react-hook-form";

const FormSuspensionTimer = ({ name, control, defaultValues }) => {
  const _name = name ? name : "description";
  let _defaultValue = typeof defaultValues === "object" ? defaultValues[_name] : defaultValues;
  if (!_defaultValue) {
    _defaultValue = "";
  }

  return (
    <Controller
      name={_name}
      control={control}
      defaultValue={_defaultValue}
      as={
        <TextField
          name={_name}
          type="text"
          label="Descripción de suspensión"
          variant="outlined"
          fullWidth
          required
          multiline
          rows={4}
        />
      }
    />
  );
};

export default FormSuspensionTimer;

FormSuspensionTimer.propTypes = {
  name: PropTypes.string,
  control: PropTypes.object,
  defaultValues: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

FormSuspensionTimer.defaultProps = {
  defaultValues: "",
  name: "description",
};
