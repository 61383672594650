import React, { useEffect } from "react";
import { Route, Switch, useLocation, useHistory } from "react-router-dom";
import MainLayout from "../layout/main";
import MyAssetsInstallationsView from "components/site/MyAssetsInstallationsView";
import AssetsInstallationDetailView from "components/site/AssetsInstallationDetailView";
import MaintenanceListView from "components/validation/MaintenanceListView";
import MaintenanceDetailView from "components/validation/MaintenanceDetailView";
import DeliveriesListView from "components/DeliveriesListView/DeliveriesListView";
import DeliveryDetailsView from "components/DeliveryDetailsView/DeliveryDetailsView";
import TicketActivityListView from "components/Tickets/TicketActivityListView";
import TicketActivityDetailsView from "components/Tickets/TicketActivityDetailsView";
import OfflineStatusIcon from "components/custom/OfflineStatusIcon";
import * as ROLES from "constant/roles";

import {
  ROOT,
  ASSETS,
  MAINTENANCE,
  SITE_DELIVERIES,
  SITE_DELIVERY_DETAILS,
  TICKET_ACTIVITY_LIST,
  TICKET_ACTIVITY_DETAILS,
} from "constant/route/operation";

import { OperationRoutesIcons } from "constant/drawerIcons";

const { InstallationTasksIcon, SupportTasksIcon, MaintenanceTasksIcon } = OperationRoutesIcons;

const drawerItems = [
  {
    text: "Instalaciones",
    to: ASSETS.INSTALLATION,
    disabled: false,
    icon: InstallationTasksIcon,
  },
  {
    text: "Soporte",
    to: TICKET_ACTIVITY_LIST,
    disabled: false,
    icon: SupportTasksIcon,
  },
  {
    text: "Mantenimientos",
    to: MAINTENANCE.ROOT,
    disabled: false,
    icon: MaintenanceTasksIcon,
  },
];

const ROUTES = [
  {
    exact: true,
    path: ASSETS.INSTALLATION,
    component: MyAssetsInstallationsView,
  },
  {
    exact: true,
    path: ASSETS.DETAIL,
    component: AssetsInstallationDetailView,
  },
  {
    exact: true,
    path: SITE_DELIVERIES,
    component: DeliveriesListView,
  },
  {
    exact: true,
    path: SITE_DELIVERY_DETAILS,
    component: DeliveryDetailsView,
  },
  {
    exact: true,
    path: MAINTENANCE.ROOT,
    // eslint-disable-next-line react/display-name
    render: () => <MaintenanceListView type={ROLES.SUPPORT_ENGINEERS} />,
  },
  {
    exact: true,
    path: MAINTENANCE.MAINTENANCE_DETAIL_WITH_PARAM,
    // eslint-disable-next-line react/display-name
    render: () => <MaintenanceDetailView type={ROLES.SUPPORT_ENGINEERS} />,
  },
  {
    exact: true,
    path: TICKET_ACTIVITY_LIST,
    render: () => <TicketActivityListView type={ROLES.SUPPORT_ENGINEERS} />,
  },
  {
    exact: true,
    path: TICKET_ACTIVITY_DETAILS,
    // eslint-disable-next-line react/display-name
    render: () => <TicketActivityDetailsView type={ROLES.SUPPORT_ENGINEERS} />,
  },
];

function OperationsRoutes() {
  const location = useLocation();
  const history = useHistory();

  // Redirect to one end route if the current it's a partial one
  useEffect(() => {
    if (location.pathname === ROOT) {
      history.replace(drawerItems[0].to);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <MainLayout drawerItems={drawerItems}>
      <Switch>
        {ROUTES.map((route, index) => (
          <Route {...route} key={`route_${index}`} />
        ))}
      </Switch>
      <OfflineStatusIcon />
    </MainLayout>
  );
}

export default OperationsRoutes;
