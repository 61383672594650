import { Logger } from "@aws-amplify/core";
import { DataStore } from "@aws-amplify/datastore";
import { Task, UserTask } from "models";
import { filterNull } from "util/lists";
import { handleUpdateTaskTimerEvent } from "datastore";
import * as dsTask from "datastore/task";

export async function getUserSupportTickets(userId, page, limit, status) {
  let usertasks = await DataStore.query(UserTask, (ut) => ut.userID("eq", userId), {
    page,
    limit: 10000,
  });
  usertasks = usertasks.filter((ut) => !!ut?.task?.taskTicketId);

  let tasks = await Promise.all(
    usertasks.map(async (ut) => {
      let task = await DataStore.query(Task, ut?.task?.id);
      if (task?.lastEvent) {
        task = await handleUpdateTaskTimerEvent(task);
      }
      return { ...task };
    })
  );

  if (status && status !== "GENERAL") {
    tasks = tasks.filter((dataTask) => dataTask.status === status);
  }

  tasks = filterNull(tasks);
  tasks = await dsTask.populatePremises(tasks);
  tasks = await dsTask.populateCompanies(tasks);
  tasks = await dsTask.populateUsers(tasks);

  tasks = tasks.map((t) => ({ ...t }));
  const tickets = tasks.map((task) => {
    return { ...task.ticket, taskInfo: task };
  });

  return tickets;
}
