import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { Controller, useForm } from "react-hook-form";
import Select from "components/custom/Select";
import { DateTimePicker } from "@material-ui/pickers";
import { Logger } from "@aws-amplify/core";
import { Auth } from "@aws-amplify/auth";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";

import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import DialogContentText from "@material-ui/core/DialogContentText";

import CustomerProjectSelector from "components/CustomerProjectSelector";
import ConfirmationDialog from "components/ConfirmationDialog";
import {
  fetchBuildingLocationsByPremiseId,
  getUserTeam,
  createScheduleSupport,
  updateSupportActivity,
} from "datastore";
import { FORM_MODES } from "constant/formModes";
import useLoadingStatus from "hooks/useLoadingStatus";
import useNotifier from "hooks/useNotifier";

const logger = new Logger("SupportScheduleDialog");

export default function SupportScheduleDialog({
  matches,
  open,
  onClose,
  onScheduleSupport,
  onUpdateSupport,
  ticketDetails,
  supportDetails,
}) {
  const classes = useStyles();
  const { register, control, errors, trigger, getValues, reset, watch, setValue } = useForm({
    mode: "onChange",
    defaultValues: { ...DEFAULT_FORM_VALUES, premise: ticketDetails?.premises?.name },
  });
  const [formMode, setFormMode] = useState(FORM_MODES.CREATE);
  const [isCreating, createScheduleSupportWrapper] = useLoadingStatus(createScheduleSupport);
  const [isUpdating, _updateSupportActivity] = useLoadingStatus(updateSupportActivity);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [buildingLocationList, setBuildingLocationList] = useState([]);
  const [usersTeam, setUsersTeam] = useState([]);
  const [activeRemark, setActiveRemark] = useState(null);
  const [selectedTechnician, setSelectedTechnician] = useState({});
  const [selectedBuildingLocation, setSelectedBuildingLocation] = useState({});
  const [selectedProjectId, setSelectedProjectId] = useState("");
  const { showMessage, showError } = useNotifier();

  useEffect(() => {
    if (open && supportDetails && Object.keys(supportDetails).length) {
      setFormMode(FORM_MODES.UPDATE);
      setValue("startDate", `${supportDetails.startDate} ${supportDetails.startTime}`);
      setValue("endDate", `${supportDetails.endDate} ${supportDetails.endTime}`);
      setValue("premise", supportDetails.premise?.name);
      setValue("buildingLocation", supportDetails.buildingLocation?.id);
      setValue("technician", supportDetails.userTask?.user?.id);
      setValue("impact", ticketDetails.impact);
      setValue("taskType", "general-support");
      const remarks = supportDetails.remarks || [];
      const supportRemark = remarks.find(({ remark }) => !remark.includes("<***>"));
      setActiveRemark(supportRemark || null);
      setValue("remarks", supportRemark?.remark || "");
    } else {
      setFormMode(FORM_MODES.CREATE);
      setValue("taskType", "general-support");
    }
  }, [open, supportDetails, setValue]);

  useEffect(() => {
    if (ticketDetails && Object.keys(ticketDetails).length) {
      fetchPremiseBuildingLoccationList();
      fetchUserTeam();
      setValue("premise", ticketDetails?.premises?.name);
    }
  }, [ticketDetails]);

  useEffect(() => {
    const _selected = watch("technician");
    if (_selected) {
      const user = usersTeam.find((u) => u.id === watch("technician"));
      setSelectedTechnician(user);
    }
  }, [watch("technician")]);

  useEffect(() => {
    const _selected = watch("buildingLocation");
    if (_selected) {
      const blocation = buildingLocationList.find((bl) => bl.id === watch("buildingLocation"));
      setSelectedBuildingLocation(blocation);
    }
  }, [watch("buildingLocation")]);

  async function fetchUserTeam() {
    const currentUser = await Auth.currentAuthenticatedUser();
    getUserTeam(currentUser.attributes.sub).then((results) => {
      // Filtrado de valores nulos para mitigar inconsistencia en relación Equipo - Usuarios en BD
      // TODO: Identificar el origen de la inconsistencia
      results = results.filter((user) => !!user);
      setUsersTeam(results || []);
    });
  }

  function fetchPremiseBuildingLoccationList() {
    const premisesId = ticketDetails?.premises?.id || ticketDetails?.ticketPremisesId;
    fetchBuildingLocationsByPremiseId(premisesId).then((results) => {
      setBuildingLocationList(results);
      setValue("buildingLocation", supportDetails?.buildingLocation?.id || "");
    });
  }

  async function scheduleOrUpdateSupportActivity() {
    const {
      attributes: { sub: userId },
    } = await Auth.currentAuthenticatedUser();
    let values = getValues();
    logger.debug(values);
    try {
      if (formMode === FORM_MODES.CREATE) {
        await createScheduleSupportWrapper(
          values,
          ticketDetails.id,
          userId,
          ticketDetails?.asset?.id,
          selectedProjectId
        );
        onScheduleSupport();
      } else if (formMode === FORM_MODES.UPDATE) {
        const updatedActivity = await _updateSupportActivity(
          values,
          activeRemark,
          supportDetails.userTask.id,
          ticketDetails.id,
          selectedProjectId
        );
        onUpdateSupport(updatedActivity);
      }
      showMessage(DIALOG_TEXT[formMode].successMsg);
      setShowConfirmation(false);
      onClose();
    } catch (error) {
      logger.error(error);
      showError(DIALOG_TEXT[formMode].errorMsg);
    }
  }

  async function validateSupportForm() {
    const validForm = await trigger();
    if (validForm) {
      setShowConfirmation(true);
    }
  }

  function handleCloseDialog() {
    reset();
    setValue("premise", ticketDetails?.premises?.name);
    setActiveRemark(null);
    setFormMode(FORM_MODES.UPDATE);
  }

  let endDate = watch("endDate");
  let startDate = watch("startDate");

  if (endDate && dayjs(startDate).isSameOrAfter(endDate)) {
    setValue("endDate", dayjs(startDate).add(1, "days"));
  }

  return (
    <>
      <Dialog
        aria-labelledby="delivery-dialog"
        fullScreen={matches}
        fullWidth={true}
        maxWidth="xl"
        open={open}
        onClose={onClose}
        onExited={handleCloseDialog}
      >
        <DialogTitle id="max-width-dialog-title">{DIALOG_TEXT[formMode].title}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText color="primaryText">
            Ingrese los datos del soporte, revise la información y confirme si es correcta.
          </DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Datos del Soporte</Typography>
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
              <Controller
                control={control}
                as={DateTimePicker}
                rules={FORM_RULES.startDate}
                name="startDate"
                label="Fecha de inicio"
                fullWidth
                disablePast
                required
                format="DD/MM/YYYY HH:mm"
                inputVariant="standard"
                error={!!errors.startDate}
                helperText={errors.startDate?.message}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
              <Controller
                control={control}
                as={DateTimePicker}
                rules={FORM_RULES.endDate}
                minDate={watch("startDate")}
                disablePast
                name="endDate"
                label="Fecha de finalización"
                fullWidth
                format="DD/MM/YYYY HH:mm"
                inputVariant="standard"
                error={!!errors.endDate}
                helperText={errors.endDate?.message || "Fecha estimada"}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                inputRef={register({ ...FORM_RULES.premise })}
                label="Lugar de atención"
                name="premise"
                fullWidth
                inputProps={READ_ONLY_INPUT}
                error={!!errors.premise}
                helperText={errors.premise?.message}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={control}
                as={Select}
                label="Zona de atención"
                name="buildingLocation"
                fullWidth
                required
                rules={FORM_RULES.buildingLocation}
                options={buildingLocationList.map((option) => ({
                  label: `${option?.code || ""} ${option?.name || ""} ${option?.buildingBlock || ""} ${
                    option.floor ? `Piso: ${option.floor}` : ""
                  } ${option.area ? `Área: ${option.area}` : ""}`,
                  value: option.id,
                }))}
                error={!!errors.buildingLocation}
                helperText={errors.buildingLocation?.message}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Controller
                control={control}
                as={Select}
                label="Técnico"
                name="technician"
                fullWidth
                required
                rules={FORM_RULES.technician}
                options={usersTeam.map((option) => ({
                  value: option.id,
                  label: `${option?.name || ""} ${option?.middlename || ""} ${option?.lastnameP || ""} ${
                    option?.lastnameM || ""
                  } (${option.email || "S/I"}, ${option.phone || "S/I"})`,
                }))}
                error={!!errors.technician}
                helperText={errors.technician?.message}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Controller
                control={control}
                as={Select}
                label="Impacto"
                name="impact"
                fullWidth
                required
                rules={FORM_RULES.impact}
                options={IMPACT_OPTIONS}
                error={!!errors.impact}
                helperText={errors.impact?.message}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                as={TextField}
                rules={FORM_RULES.remarks}
                name="remarks"
                label="Comentarios"
                variant="outlined"
                size="small"
                rows={2}
                multiline
                fullWidth
                error={!!errors.remarks}
                helperText={errors.remarks?.message}
              />
            </Grid>

            <Grid item xs={12}>
              <CustomerProjectSelector
                customerId={ticketDetails?.project?.company?.id}
                projectId={ticketDetails?.project?.id}
                onChangeProject={setSelectedProjectId}
                boxProps={{ my: 3 }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="default" onClick={onClose} disabled={isCreating || isUpdating}>
            Cancelar
          </Button>
          <Button color="primary" onClick={validateSupportForm} disabled={isCreating || isUpdating}>
            {DIALOG_TEXT[formMode].confirmBtn}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        open={showConfirmation}
        title="Confirmación"
        onCancel={() => setShowConfirmation(false)}
        onConfirm={scheduleOrUpdateSupportActivity}
        loading={false}
      >
        <Typography paragraph>{DIALOG_TEXT[formMode].confirmationMsg}</Typography>
        <Typography>
          <strong>Fecha de inicio: </strong>
          <span>{dayjs(watch("startDate")).format("DD/MM/YYYY HH:mm")}</span>
        </Typography>
        <Typography>
          <strong>Fecha de finalización estimada: </strong>
          <span>{dayjs(watch("endDate")).format("DD/MM/YYYY HH:mm")}</span>
        </Typography>
        <Typography>
          <strong>Lugar: </strong>
          <span>{ticketDetails?.premises?.name}</span>
        </Typography>
        <Typography>
          <strong>Zona: </strong>
          <span>{`${selectedBuildingLocation?.code || ""} ${selectedBuildingLocation?.name || ""} ${
            selectedBuildingLocation?.buildingBlock || ""
          } ${selectedBuildingLocation?.floor ? `Piso: ${selectedBuildingLocation?.floor}` : ""} ${
            selectedBuildingLocation?.area ? `Área: ${selectedBuildingLocation?.area}` : ""
          }`}</span>
        </Typography>
        <Typography>
          <strong>Técnico: </strong>
          <span>
            {`${selectedTechnician?.name || ""} ${selectedTechnician?.middlename || ""} ${
              selectedTechnician?.lastnameP || ""
            } ${selectedTechnician?.lastnameM || ""} (${selectedTechnician?.email || "S/I"}, ${
              selectedTechnician?.phone || "S/I"
            })`}
          </span>
        </Typography>
        {watch("remarks") && (
          <Typography>
            <strong>Comentarios: </strong>
            <span>{watch("remarks")}</span>
          </Typography>
        )}
        <br />
        <Typography paragraph>¿Deseas continuar?</Typography>
      </ConfirmationDialog>
      <Backdrop className={classes.backdrop} open={isCreating || isUpdating}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

SupportScheduleDialog.propTypes = {
  matches: PropTypes.bool,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onScheduleSupport: PropTypes.func,
  onUpdateSupport: PropTypes.func,
  ticketDetails: PropTypes.object,
  supportDetails: PropTypes.object,
  isLoading: PropTypes.bool,
};

SupportScheduleDialog.defaultProps = {
  matches: false,
  open: false,
  onClose: () => {},
  onScheduleSupport: () => {},
  onUpdateSupport: () => {},
  ticketDetails: null,
  supportDetails: null,
  isLoading: false,
};

const DIALOG_TEXT = {
  [FORM_MODES.CREATE]: {
    title: "Programación de Soporte",
    confirmBtn: "Programar",
    confirmationMsg: "Está a punto de programar la siguiente actividad de Soporte:",
    successMsg: "La actividad de Soporte se programó con éxito.",
    errorMsg: "Ocurrió un error programando la actividad, intente de nuevo por favor.",
  },
  [FORM_MODES.UPDATE]: {
    title: "Actualización de Soporte",
    confirmBtn: "Actualizar",
    confirmationMsg: "Está a punto de actualizar la siguiente actividad de Soporte:",
    successMsg: "La actividad de Soporte se actualizó de forma exitosa.",
    errorMsg: "Ocurrió un error durante la actualización de la actividad, intente de nuevo por favor.",
  },
};

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
    color: "#fff",
  },
}));

const DEFAULT_FORM_VALUES = {
  startDate: dayjs(),
  endDate: dayjs().add(1, "days"),
  premise: "",
  impact: "",
  buildingLocation: "",
  technician: "",
  taskType: "",
  remarks: "",
};

const IMPACT_OPTIONS = [
  { label: "Alto", value: "HIGH" },
  { label: "Medio", value: "MEDIUM" },
  { label: "Bajo", value: "LOW" },
];

const FORM_RULES = {
  startDate: {
    required: "Campo obligatorio",
  },
  endDate: {
    required: "Campo obligatorio",
  },
  premise: {
    required: "Seleccione una opción",
  },
  impact: {
    required: "Seleccione una opción",
  },
  buildingLocation: {
    required: "Seleccione una opción",
  },
  technician: {
    required: "Seleccione una opción",
  },
  taskType: {
    required: "Seleccione una opción",
  },
  remarks: {
    maxLength: { value: 500, message: "Máximo 500 caracteres" },
  },
};

const READ_ONLY_INPUT = { readOnly: true };
