export const updateSetting = /* GraphQL */ `
  mutation UpdateSetting($input: UpdateSettingInput!) {
    updateSetting(input: $input) {
      id
      key
      value
      status
      _version
      _deleted
    }
  }
`;

export default updateSetting;
