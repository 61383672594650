import { Logger } from "@aws-amplify/core";
import { TaskCategory } from "models";
import { DataStore, Predicates, SortDirection } from "@aws-amplify/datastore";
import {
  Task,
  Premises,
  UserTask,
  InspectionNote,
  Attachment,
  Asset,
  TaskAssets,
  UnitOfMeassure,
  MaterialCategory,
  Material,
  Tracking,
  Remark,
  TrackingStatus,
  User,
  Ticket,
  AssetAttribute,
  AttachmentTicket,
  Project,
  AssetType,
  TaskStatus,
  BuildingLocation,
  TicketStatus,
  TaskType,
  UserTeams,
  Team,
  Company,
  Attribute,
  TicketRemark,
  EventCause,
} from "models";

import * as ROLES from "constant/roles";
import dayjs from "dayjs";
import { TimerEvent } from "models";
import removeProps from "util/removeProps";
import { filterNull } from "util/lists";
import * as dsTask from "datastore/task";

const logger = new Logger("DataStore helpers");

/**
 * Consulta de relaciones UserTask de un user ID
 *
 * @param {string} userId ID del Task a consultar
 * @returns {UserTask} Arreglo con los registros de relacion del userId
 */
export async function fetchUserTaskByUserId(userId) {
  return DataStore.query(UserTask, (ut) => ut.userID("eq", userId));
}

/**
 * Consulta de un premises por su ID
 *
 * @param {string} premisesId ID del Premises a consultar
 * @returns {Premises} Premises correspondiente al ID o null si no existe un Premises relacionado
 */
export async function fetchPremisesById(premisesId) {
  const premises = await DataStore.query(Premises, premisesId);
  return premises;
}

/**
 * Consulta de todos los premises (sitios)
 *
 * @returns {Premises} Arreglo de todos los premises registrados
 */
export async function fetchAllPremises() {
  return DataStore.query(Premises);
}

/**
 * Consulta el detalle de un Task
 *
 * @param {string} taskId ID del Task a consultar
 * @returns {object} Objeto que contiene el Task y Premises vinculado al BuildingLocation del Task
 * @returns {object.task} Task correspondiente al taskId
 * @returns {object.premises} Premises relacionado al BuildingLocation del Task
 */
export async function fetchTaskById(taskId) {
  let task = await DataStore.query(Task, taskId);
  if (task?.project?.companyID) {
    const company = await DataStore.query(Company, task?.project?.companyID);
    task = Task.copyOf(task, (taskUpdated) => {
      const _project = Project.copyOf(task.project, (projectUpdated) => {
        projectUpdated.company = company;
        return projectUpdated;
      });

      taskUpdated.project = _project;
      return taskUpdated;
    });
  }
  logger.debug("fetchTaskById", task);
  //añadimos eventCause al último Evento
  if (task?.lastEvent) {
    task = await handleUpdateTaskTimerEvent(task);
  }
  const userTask = await DataStore.query(UserTask, (ut) => ut.taskID("eq", taskId));
  const assets = await fetchAssetsByTask(taskId);
  const remarks = await fetchRemarksByTaskId(taskId);

  let premises = {};

  if (task?.buildingLocation) {
    premises = await fetchPremisesById(task.buildingLocation.premisesID);
  }

  logger.debug("fetchTaskById: taskData", { task, premises, assets, userTask, remarks });

  return { task, premises, assets, userTask, remarks };
}

async function getLastEventCause(eventCauseId) {
  return DataStore.query(EventCause, eventCauseId);
}

export async function handleUpdateTaskTimerEvent(taskModel) {
  const eventCause = await getLastEventCause(taskModel?.lastEvent?.eventCauseID);
  const timerEvents = await DataStore.query(TimerEvent, (taskTimerEvent) => taskTimerEvent.taskID("eq", taskModel.id));
  taskModel = Task.copyOf(taskModel, (taskUpdated) => {
    const _lastEvent = TimerEvent.copyOf(taskModel.lastEvent, (lastEventUpdated) => {
      lastEventUpdated.eventCause = eventCause;
      return lastEventUpdated;
    });
    if (!taskModel.embeddedLastEvent?.embeddedCause) {
      const [embeddedCause] = removeProps([eventCause], { propsToDelete: ["_deleted, _lastChangedAt"] });
      const embeddedLastEvent = { ...taskModel.embeddedLastEvent, embeddedCause };
      taskUpdated.embeddedLastEvent = embeddedLastEvent;
      const embeddedEventIndex = taskModel.embeddedEvents.findIndex(
        (event) => event.id === taskModel.embeddedLastEvent.id
      );
      taskModel.embeddedEvents[embeddedEventIndex] = embeddedLastEvent;
    }

    taskUpdated.lastEvent = _lastEvent;
    taskUpdated.events = timerEvents;
    return taskUpdated;
  });
  return taskModel;
}

/**
 * Consulta los InspectionNote asociados a un Task por su ID
 *
 * @param {string} taskId ID del Task del que se buscan sus InspectionNote
 * @returns {InspectionNote} Arreglo de InspectionNote relacionados al Task ID ofrecidos
 */
export async function fetchInspectionNotesByTask(taskId) {
  return DataStore.query(InspectionNote, (n) => n.taskID("eq", taskId));
}

/**
 * Consulta los Attachment asociados a un Task por su ID
 *
 * @param {string} taskId ID del Task del que se buscan sus Attachment
 * @returns {Attachment} Arreglo de Attachment relacionados al Task ID ofrecidos
 */
export async function fetchAttachmentsByTask(taskId) {
  return DataStore.query(Attachment, (a) => a.taskID("eq", taskId));
}

export async function fetchAttachmentById(attachmentId) {
  return DataStore.query(Attachment, attachmentId);
}

export function fetchAttachmentTicketById(attachmentId) {
  return DataStore.query(AttachmentTicket, attachmentId);
}

export async function deleteAttachmentById(id) {
  const attachment = await DataStore.query(Attachment, id);
  return DataStore.delete(attachment);
}

/**
 * Consulta de un UnitOfMeassure por su ID
 *
 * @param {string} uomId ID del UnitOfMeassure a consultar
 * @returns {UnitOfMeassure} UnitOfMeassure correspondiente al ID o null si no existe un UnitOfMeassure relacionado
 */
export async function fetchUnitOfMeasureById(uomId) {
  if (uomId) {
    const uom = await DataStore.query(UnitOfMeassure, (uom) => uom.id("eq", uomId));
    if (uom.length === 0) return null;
    return uom[0];
  }
  logger.warn(`El material no tiene un UOM asociado`);
  return null;
}

export async function fetchMaterialCategoryById(materialCatId) {
  if (materialCatId) {
    const mc = await DataStore.query(MaterialCategory, (mc) => mc.id("eq", materialCatId));
    if (mc.length === 0) return null;
    return mc[0];
  }
  logger.warn(`El material no tiene una categoría asociada`);
  return null;
}

/**
 * Consulta el detalle de un Asset
 *
 * @param {string} assetId ID del Task a consultar
 * @returns {object} Objeto que contiene el Asset y Material vinculado al Asset
 * @returns {object.asset} Asset correspondiente al assetId
 * @returns {object.uom} UnitOfMeasure relacionado al Material del Asset
 * @returns {object.category} MaterialCategory relacionado al Material del Asset
 */
export async function fetchAssetsById(assetId) {
  let asset = await DataStore.query(Asset, assetId);
  return { asset };
}

/**
 * Consulta Assets por serie o código
 *
 * @param {string} serial Número de serie del Asset
 * @param {string} code Código del Asset
 * @returns {Asset[]} Arreglo con objetos Asset
 */
export async function fetchAssetsBySerialOrCode(serial, code) {
  const assets = await DataStore.query(Asset, (asset) =>
    asset.or((a) => {
      let predicate = a;
      if (serial?.length) {
        predicate = predicate.serial("eq", serial);
      }
      if (code.length) {
        predicate = predicate.code("eq", code);
      }
      return predicate;
    })
  );
  return assets;
}

/**
 * Consulta Assets por serie o código
 *
 * @param {string} serial Número de serie del Asset
 * @returns {Asset[]} Arreglo con objetos Asset
 */
export async function fetchAssetsBySerial(serial) {
  const assets = await DataStore.query(Asset, (a) => a.serial("contains", serial));
  return Promise.all(
    assets.map(async (asset) => {
      const updatedAsset = { asset: { ...asset }, uom: null };
      if (asset?.material?.materialUomId) {
        const uom = await fetchUnitOfMeasureById(asset.material.materialUomId);
        updatedAsset.uom = uom;
      }
      return updatedAsset;
    })
  );
}

/**
 * Consulta los Asset asociados a un Task por su ID
 *
 * @param {string} taskId ID del Task del que se buscan sus Asset
 * @returns {Array} Arreglo de Asset relacionados al Task ID ofrecido
 */
export async function fetchAssetsByTask(taskId) {
  const taskAssets = await DataStore.query(TaskAssets, (ta) => ta.taskAssetsTaskId("eq", taskId));
  const assetsPromises = taskAssets.map(async (ta) => {
    let asset = null;
    let attributes = [];
    // Validacion para evitar referencias a assets que no existen
    if (ta?.asset?.id) {
      asset = await fetchAssetsById(ta.asset.id);
      attributes = await fetchAttributesByAssetId(ta.asset.id);
    } else {
      logger.warn(`Relación TaskAsset (${ta.id}) incompleta, no existe Asset asociado`);
    }
    return { taskAssets: ta, asset, attributes };
  });

  return Promise.all(assetsPromises);
}

export async function fetchAssetByTrackingId(trackingId) {
  const [firstAsset] = await DataStore.query(Asset, (a) => a.trackingID("eq", trackingId));
  if (!firstAsset) {
    logger.warn(`No existe un Asset asociado al Tracking ID ${trackingId}`);
    return {};
  }
  let uom = null;
  if (firstAsset?.material?.materialUomId) {
    uom = await fetchUnitOfMeasureById(firstAsset.material.materialUomId);
  }
  return { asset: firstAsset, uom };
}

export async function updateTask({ id, type }) {
  let task = await fetchTaskById(id);

  task = Task.copyOf(task.task, (updated) => {
    updated.status = type;
    return updated;
  });

  return DataStore.save(task);
}

export async function updateAsset({ id, serial, quantity, comment, type, materialId, material, uomId, name, code }) {
  let asset = await fetchAssetsById(id);
  const _materialId = materialId || material?.id;
  let _material = _materialId ? await fetchMaterialById(materialId || material.id) : null;
  let uom;
  if (asset.asset.uom?.id !== uomId) {
    uom = await fetchUnitOfMeasureById(uomId);
  }

  if (_material) {
    _material = Material.copyOf(_material, (updated) => {
      if (name) {
        updated.name = name;
      }
      if (code) {
        updated.code = code;
      }
    });
    _material = await DataStore.save(_material);
  }

  asset = Asset.copyOf(asset.asset, (updated) => {
    updated.serial = serial;
    updated.quantity = quantity ? quantity : updated.quantity;
    updated.type = type ? type : updated.type;
    if (_material) {
      updated.material = _material;
    }
    if (uom) {
      updated.uom = uom;
      updated.assetUomId = uomId;
    }
    if (comment) {
      updated.comment = comment;
    }
    if (name) {
      updated.name = name;
    }
    if (code) {
      updated.code = code;
    }
    return updated;
  });

  return DataStore.save(asset);
}

export async function deleteAssetAndTaskAsset(taskAssetId, assetId) {
  const deleteResult = await Promise.all([DataStore.delete(TaskAssets, taskAssetId), DataStore.delete(Asset, assetId)]);
  return deleteResult;
}

export async function deleteTaskAssetRelation(taskAssetId, assetId) {
  let { asset } = await fetchAssetsById(assetId);
  asset = Asset.copyOf(asset, (updated) => {
    updated.type = AssetType.SHIPMENT_RECEIVED;
    return updated;
  });
  asset = await DataStore.save(asset);

  const taskAsset = await DataStore.query(TaskAssets, taskAssetId);
  await DataStore.delete(taskAsset);
  return asset;
}

export async function deleteTicketAssetRelation(ticketId) {
  let ticket = await DataStore.query(Ticket, ticketId);
  ticket = Ticket.copyOf(ticket, (updated) => {
    updated.asset = null;
    return updated;
  });
  return DataStore.save(ticket);
}

export async function createTicketAssetRelation(ticketId, assetId) {
  const { asset } = await fetchAssetsById(assetId);

  let ticket = await DataStore.query(Ticket, ticketId);
  ticket = Ticket.copyOf(ticket, (updated) => {
    updated.asset = asset;
    return updated;
  });

  return DataStore.save(ticket);
}

export async function fetchMaterialById(materialId) {
  let material = await DataStore.query(Material, (m) => m.id("eq", materialId));
  material = material[0];
  return material;
}

export async function createMaterial({ name, code, uomId, uomName }) {
  let uom = null;
  if (uomId) {
    uom = await fetchUnitOfMeasureById(uomId);
  } else if (uomName) {
    uom = await DataStore.query(UnitOfMeassure, (uom) => uom.name("eq", uomName));
    uom = Array.isArray(uom) ? uom[0] : null;
  }
  let newMaterial = new Material({ name, code, uom });
  newMaterial = await DataStore.save(newMaterial);
  return { ...newMaterial, uom };
}

export async function associateAssetToTask(assetId, taskId) {
  const { task } = await fetchTaskById(taskId);
  let { asset } = await fetchAssetsById(assetId);
  asset = Asset.copyOf(asset, (updatedAsset) => {
    updatedAsset.type = AssetType.REUSED;
    return updatedAsset;
  });
  asset = await DataStore.save(asset);

  let taskAssets = new TaskAssets({ task, asset });
  taskAssets = await DataStore.save(taskAssets);
  return { taskAssets, asset: { asset } };
}

export async function createAssetToTask({
  taskId,
  materialId,
  uomId,
  type,
  materialCode,
  serial,
  quantity,
  comment,
  trackingID,
}) {
  // Consultar material
  const [task, material, tracking, uom] = await Promise.all([
    fetchTaskById(taskId),
    fetchMaterialById(materialId),
    DataStore.query(Tracking, trackingID),
    fetchUnitOfMeasureById(uomId),
  ]);

  // Crear Asset
  let asset = {
    type,
    comment,
    quantity,
    code: materialCode,
    serial,
    material,
    tracking,
    uom,
  };

  if (comment) asset.comment = comment;

  asset = new Asset(asset);
  asset = await DataStore.save(asset);
  // Relacionarlo con el Task
  let taskAssets = new TaskAssets({
    task: task.task,
    asset,
  });
  taskAssets = await DataStore.save(taskAssets);
  return { taskAssets, asset: { asset } };
}

export async function associateLocationAttributes(assetId, whName, contractBlueprint) {
  const asset = await DataStore.query(Asset, assetId);
  const wnAttribute = await DataStore.query(Attribute, "WH_NAME");
  const cbAttribute = await DataStore.query(Attribute, "CONTRACT_BLUEPRINTS");
  const attributes = [];
  let wnAssetAttribute = null;
  let cbAssetAttribute = null;

  if (asset && wnAttribute) {
    wnAssetAttribute = new AssetAttribute({ asset, attribute: wnAttribute, value: whName });
    wnAssetAttribute = await DataStore.save(wnAssetAttribute);
    attributes.push(wnAssetAttribute);
  }
  if (asset && cbAttribute) {
    cbAssetAttribute = new AssetAttribute({ asset, attribute: cbAttribute, value: contractBlueprint });
    cbAssetAttribute = await DataStore.save(cbAssetAttribute);
    attributes.push(cbAssetAttribute);
  }

  return attributes;
}

export async function patchLocationAttributes(warehouseName, contractBlueprints) {
  let attributes = [];

  if (warehouseName?.id && warehouseName?.value) {
    let whAssetAttribute = await DataStore.query(AssetAttribute, warehouseName.id);
    whAssetAttribute = AssetAttribute.copyOf(whAssetAttribute, (updated) => {
      updated.value = warehouseName.value;
    });
    whAssetAttribute = await DataStore.save(whAssetAttribute);
    attributes.push(whAssetAttribute);
  }

  if (contractBlueprints?.id && contractBlueprints?.value) {
    let cbAssetAttribute = await DataStore.query(AssetAttribute, contractBlueprints.id);
    cbAssetAttribute = AssetAttribute.copyOf(cbAssetAttribute, (updated) => {
      updated.value = contractBlueprints.value;
    });
    cbAssetAttribute = await DataStore.save(cbAssetAttribute);
    attributes.push(cbAssetAttribute);
  }

  return attributes;
}

export function fetchRemarksByTaskId(taskId) {
  return DataStore.query(Remark, (r) => r.taskID("eq", taskId));
}

export async function createRemark({ comment, taskID, taskStatus }) {
  let remark = {
    remark: comment?.trim(),
    taskID,
    taskStatus,
  };
  remark = new Remark(remark);
  remark = await DataStore.save(remark);
  return { remark };
}
export async function fetchUnassignedTracking(trackingId) {
  const trackings = await DataStore.query(Tracking, (t) =>
    t.id("eq", trackingId).status("eq", TrackingStatus.UNASSIGNED)
  );
  if (!trackings.length) {
    return null;
  }
  return trackings[0];
}

export function fetchTrackingById(id) {
  return DataStore.query(Tracking, id);
}

export async function associateTrackingWithAsset(trackingId, assetId) {
  let tracking = await DataStore.query(Tracking, trackingId);
  tracking = await DataStore.save(
    Tracking.copyOf(tracking, (updatedTracking) => {
      updatedTracking.status = TrackingStatus.ASSIGNED;
    })
  );
  const asset = await DataStore.query(Asset, assetId);
  await DataStore.save(
    Asset.copyOf(asset, (updatedAsset) => {
      updatedAsset.tracking = tracking;
    })
  );
  return { ...asset, tracking };
}

export async function loadTasksPrechargedFiles(taskId, userId) {
  try {
    let assets = (
      await DataStore.query(Attachment, (a) =>
        a.or((a) => a.status("eq", "SHIPMENT_PREV_SENT").status("eq", "SHIPMENT_PREV_RECEIVED"))
      )
    ).filter((a) => a.owner === userId && a.taskID === taskId);
    logger.debug(assets);
    return assets;
  } catch (error) {
    logger.error(error);
    return [];
  }
}

export async function fetchWarehouseKeepersTasks() {
  let tasks = await DataStore.query(Task, (task) => {
    return task.category("eq", TaskCategory.SHIPMENT);
  });
  if (!tasks.length) {
    return tasks;
  }

  const userTasks = tasks.map(async (task) => {
    let userTask = await DataStore.query(UserTask, (userTask) => {
      return userTask.taskID("eq", task.id);
    });
    if (!userTask || userTask.length === 0) {
      logger.warn(`UserTask no encontrado con el Task ID: ${task.id}`);
      return { ...task };
    }

    userTask = userTask[0];

    const user = await DataStore.query(User, userTask.user.id);
    if (!user) {
      logger.warn(`User no encontrado con el ID: ${userTask.user.id}`);
      return { ...task };
    }

    let project = null;
    if (userTask.task.taskProjectId) {
      project = await DataStore.query(Project, userTask.task.taskProjectId);
    }

    if (task.buildingLocation) {
      const _buildingLocation = await DataStore.query(BuildingLocation, task.buildingLocation.id);

      task = Task.copyOf(task, (updated) => {
        updated.buildingLocation = _buildingLocation;
        return updated;
      });
    }

    return { ...task, user: { ...user }, project };
  });

  return Promise.all(userTasks);
}

// status
// OPEN
// SCHEDULED
// IN_PROGRESS
// RESOLVED
// VALIDATED
// RATED
// CANCELLED

async function fetchTickets({ predicate = Predicates.ALL, page }) {
  return DataStore.query(Ticket, predicate, {
    sort: (s) => s.createdAt(SortDirection.DESCENDING),
    page,
    limit: 400,
  });
}

async function fetchTicketsByStatus(status, page) {
  let predicate = Predicates.ALL;

  if (status && status !== TicketStatus.GENERAL) {
    predicate = (ticket) => {
      return ticket.status("eq", status);
    };
  }

  return fetchTickets({
    predicate,
    page,
  });
}

async function fetchTicketsByStatusAndRequester(status, requesterID, page) {
  let predicate = (ticket) => {
    return ticket.ticketRequesterId("eq", requesterID);
  };

  if (status && status !== TicketStatus.GENERAL) {
    predicate = (ticket) => {
      return ticket.status("eq", status).ticketRequesterId("eq", requesterID);
    };
  }

  return fetchTickets({
    predicate,
    page,
  });
}

export async function fetchTicketsList(type, userId = null, page, status) {
  let tickets = [];

  console.time("tiempo-obteniendo-tasks");
  if (type === ROLES.SUPERVISORS) {
    tickets = await fetchTicketsByStatus(status, page);
  }

  if (type === ROLES.SERVICE_USERS) {
    tickets = await fetchTicketsByStatusAndRequester(status, userId, page);
  }

  console.log("holas", tickets);
  let ticketsWithTasks = tickets;
  if (status !== TicketStatus.GENERAL) {
    ticketsWithTasks = ticketsWithTasks.filter(
      ({ status }) => status !== TicketStatus.OPEN && status !== TicketStatus.CANCELLED
    );
  }

  const taskPromises = ticketsWithTasks.map(async (ticket) => {
    const task = await DataStore.query(Task, (task) => task.taskTicketId("eq", ticket?.id));
    if (task.length > 0) return { ...task[0] };
    else return null;
  });

  let tasks = await Promise.all(taskPromises);
  tasks = filterNull(tasks);

  tasks = await dsTask.populateCompanies(tasks);
  tasks = await dsTask.populateUsers(tasks);

  tasks.forEach((taskInfo) => {
    const tickeIndex = tickets.findIndex((ticket) => ticket.id === taskInfo.ticket.id);
    if (tickeIndex !== -1) {
      tickets[tickeIndex] = {
        ...tickets[tickeIndex],
        taskInfo: taskInfo,
      };
    }
  });

  console.timeEnd("tiempo-obteniendo-tasks");

  logger.debug("tickets obtenidos", { tickets });
  return tickets;
}

export async function fetchTicketById(ticketId) {
  let ticket = await DataStore.query(Ticket, ticketId);
  let _material;
  let _attributes;
  let _attachments;
  let project;
  let company;

  if (ticket.asset?.assetMaterialId) {
    _material = await DataStore.query(Material, ticket.asset?.assetMaterialId);
  }

  _attributes = await DataStore.query(AssetAttribute, (aa) => {
    return aa.assetID("eq", ticket.asset?.id);
  });
  _attachments = await DataStore.query(AttachmentTicket, (at) => {
    return at.ticketID("eq", ticketId);
  });
  if (ticket?.project?.companyID) {
    company = await DataStore.query(Company, ticket?.project?.companyID);
    project = { ...ticket.project, company };
  }

  logger.debug("fetchTicketById", {
    ...ticket,
    asset: { ...ticket?.asset, material: _material, attributes: _attributes },
    attachments: _attachments,
    project,
  });

  ticket = {
    ...ticket,
    requester: { ...ticket.requester },
    asset: { ...ticket?.asset, material: { ..._material }, attributes: _attributes },
    attachments: _attachments,
    project,
  };
  logger.debug("fetchTicketById", ticket);
  return ticket;
}
export async function fetchSupervisorTasks(supervisorId) {
  let tasks = await DataStore.query(Task);
  tasks = tasks.filter((t) => t?.taskType?.id === "assets-shipment" && t?.supervisor?.id === supervisorId);
  if (!tasks.length) {
    return tasks;
  }

  const userTasks = tasks.map(async (task) => {
    const userTask = (await DataStore.query(UserTask)).find((ut) => ut.task.id === task.id);
    if (!userTask) {
      logger.warn(`UserTask no encontrado con el Task ID: ${task.id}`);
      return task;
    }

    const user = await DataStore.query(User, userTask.user.id);
    if (!user) {
      logger.warn(`User no encontrado con el ID: ${userTask.user.id}`);
      return task;
    }

    return { ...task, user: { ...user } };
  });

  return Promise.all(userTasks);
}

export async function cancelTicket(ticketId, cancellationRemark) {
  let ticket = await DataStore.query(Ticket, ticketId);

  const dsCancellationRemark = new TicketRemark({
    id: "cancellation-remark",
    remark: cancellationRemark,
    ticketStatus: TicketStatus.CANCELLED,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  });

  ticket = await DataStore.save(
    Ticket.copyOf(ticket, (updated) => {
      updated.status = TicketStatus.CANCELLED;
      updated.remarks = [dsCancellationRemark];
    })
  );
  return ticket;
}

export async function createScheduleSupport(values, ticketId, supervisorId, assetId, projectId) {
  try {
    const currentTicket = await DataStore.query(Ticket, ticketId);
    const supervisorUser = await DataStore.query(User, supervisorId);
    const supportUser = await DataStore.query(User, values.technician);
    const buildingLocation = await DataStore.query(BuildingLocation, values.buildingLocation);
    const taskType = await DataStore.query(TaskType, values.taskType);
    let project = null;
    if (projectId) {
      project = await DataStore.query(Project, projectId);
    }
    const newTask = new Task({
      status: TaskStatus.SCHEDULED,
      startDate: dayjs(values.startDate).format("YYYY-MM-DD"),
      startTime: dayjs(values.startDate).format("HH:mm"),
      endDate: dayjs(values.endDate).format("YYYY-MM-DD"),
      endTime: dayjs(values.endDate).format("HH:mm"),
      buildingLocation: buildingLocation,
      premises: buildingLocation.premises,
      taskType: taskType,
      category: TaskCategory.SUPPORT,
      supervisor: supervisorUser,
      ticket: currentTicket,
      project,
    });

    const task = await DataStore.save(newTask);
    logger.debug({ task });
    if (values.remarks && values.remarks !== "") {
      const remark = new Remark({
        taskID: task.id,
        remark: values.remarks,
        taskStatus: TaskStatus.SCHEDULED,
      });
      await DataStore.save(remark);
      logger.debug({ remark });
    }

    const userTaks = new UserTask({
      user: supportUser,
      task,
    });

    const newUserTask = await DataStore.save(userTaks);
    logger.debug({ newUserTask });

    if (assetId) {
      const currentAsset = await DataStore.query(Asset, assetId);
      const taskAsset = new TaskAssets({
        asset: currentAsset,
        task: task,
      });
      await DataStore.save(taskAsset);
      logger.debug({ taskAsset });
    }

    return DataStore.save(
      Ticket.copyOf(currentTicket, (updated) => {
        updated.status = TicketStatus.SCHEDULED;
        updated.impact = values.impact;
        if (project) {
          updated.project = project;
        }
      })
    );
  } catch (error) {
    logger.error(error);
    return error;
  }
}

export async function updateSupportActivity(values, remark, userTaskId, ticketId, projectId) {
  const ticket = await DataStore.query(Ticket, ticketId);
  let userTask = await DataStore.query(UserTask, userTaskId);
  const buildingLocation = await DataStore.query(BuildingLocation, values.buildingLocation);
  const taskType = await DataStore.query(TaskType, values.taskType);

  let project = null;
  if (projectId) {
    project = await DataStore.query(Project, projectId);
  }

  let { task } = await fetchTaskById(userTask.task.id);

  if (remark?.id) {
    let remarkData = await DataStore.query(Remark, remark.id);
    remarkData = Remark.copyOf(remarkData, (updated) => {
      updated.remark = values.remarks;
    });
    await DataStore.save(remarkData);
  } else {
    const remark = new Remark({
      taskID: task.id,
      remark: values.remarks,
      taskStatus: task.status,
    });
    await DataStore.save(remark);
  }

  //si hay modificación de técnico en la actividad
  if (userTask.user.id !== values.technician) {
    //eliminación de userTask previo
    await DataStore.delete(userTask);

    //creación de nuevo userTask
    const supportUser = await DataStore.query(User, values.technician);
    userTask = await DataStore.save(
      new UserTask({
        user: supportUser,
        task,
      })
    );
  }

  //actualización de task
  task = Task.copyOf(task, (updated) => {
    updated.startDate = dayjs(values.startDate).format("YYYY-MM-DD");
    updated.startTime = dayjs(values.startDate).format("HH:mm");
    updated.endDate = dayjs(values.endDate).format("YYYY-MM-DD");
    updated.endTime = dayjs(values.endDate).format("HH:mm");
    updated.buildingLocation = buildingLocation;
    updated.premises = buildingLocation.premises;
    updated.taskType = taskType;
    updated.project = project;
  });
  task = await DataStore.save(task);

  await DataStore.save(
    Ticket.copyOf(ticket, (updated) => {
      updated.impact = values.impact;
      updated.project = project;
    })
  );
  return userTask.task;
}

export async function getUserTeam(userId) {
  const teams = await DataStore.query(Team, (t) => t.head("eq", userId));
  let userTeams = (await DataStore.query(UserTeams, Predicates.All)).filter((ut) => ut?.team?.id === teams[0]?.id);
  userTeams = userTeams.map((u) => {
    if (u.user.status === "ENABLED") {
      return { ...u.user };
    } else {
      return null;
    }
  });
  logger.debug(teams);
  logger.debug(userTeams);
  return userTeams;
}

export async function fetchBuildingLocationsByPremiseId(premiseId) {
  const buildingLocations = (await DataStore.query(BuildingLocation, Predicates.ALL)).filter(
    (bl) => bl?.premises?.id === premiseId
  );
  return buildingLocations;
}

export async function fetchTicketTaskTypes() {
  const taskTypes = await DataStore.query(TaskType, Predicates.ALL);
  logger.debug({ taskTypes });
  return taskTypes;
}
/**
 * Consulta los Task ids asociados a un usuario
 *
 * @param {string} userId ID del usuario a consultar
 * @returns {Array} Arreglo con los IDs de los Tasks vinculados al userId
 */
export async function fetchShipmentTasksIds(userId) {
  const userTasks = await fetchUserTaskByUserId(userId);
  const tasksIds = userTasks.filter((rel) => rel.task.taskTaskTypeId === "assets-shipment").map((rel) => rel);
  return tasksIds;
}

export async function fetchShipmments(userId) {
  let tasks = await fetchShipmentTasksIds(userId).then((rels) => {
    return Promise.all(
      rels.map(async (rel) => {
        let task = await DataStore.query(Task, rel.task.id);

        if (task.buildingLocation) {
          const _buildingLocation = await DataStore.query(BuildingLocation, task.buildingLocation.id);

          task = Task.copyOf(task, (updated) => {
            updated.buildingLocation = _buildingLocation;
            return updated;
          });
        }

        return { ...task, user: { ...rel.user } };
      })
    );
  });
  return tasks;
}

export async function getSupportByTicketId(ticketId) {
  let supportInfo = {};
  let premise = {};
  let remarks = [];
  let userTask = {};
  let attachments = [];
  let taskAssets = [];
  let support = await DataStore.query(Task, (t) => t.taskTicketId("eq", ticketId));
  if (support.length > 0) {
    supportInfo = support[0];
    premise = await DataStore.query(Premises, supportInfo?.buildingLocation?.premisesID);
    remarks = await DataStore.query(Remark, (r) => r.taskID("eq", supportInfo.id));
    // userTask = await DataStore.query(UserTask, Predicates.All);
    userTask = await DataStore.query(UserTask, (ut) => ut.taskID("eq", supportInfo.id));
    if (userTask.length > 0) {
      userTask = userTask[0];
    }
    attachments = await DataStore.query(Attachment, (a) => a.taskID("eq", supportInfo.id));
    if (supportInfo.status !== TaskStatus.SCHEDULED) {
      taskAssets = await fetchAssetsByTask(supportInfo.id);
    }
  }
  logger.debug({ supportInfo, premise, remarks, userTask, attachments, taskAssets });
  return { ...supportInfo, premise, remarks, userTask, attachments, taskAssets };
}

export function fetchAttributesByAssetId(assetId) {
  return DataStore.query(AssetAttribute, (at) => at.assetID("eq", assetId));
}

export function getAllMaterials(page = 0, limit = 10000) {
  return DataStore.query(Material, Predicates.ALL, { page, limit });
}

export function getUnitsOfMeasure() {
  return DataStore.query(UnitOfMeassure);
}
