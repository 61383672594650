import React, { useState, useRef, forwardRef, useEffect, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import { Logger } from "@aws-amplify/core";

import { useIndexedDB } from "react-indexed-db";
import useNotifier from "hooks/useNotifier";
import { useSelector } from "react-redux";

import CloseIcon from "@material-ui/icons/Close";

import CircularProgress from "@material-ui/core/CircularProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import GridList from "@material-ui/core/GridList";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";

import Button from "components/custom/Button";
import GridListTile from "components/custom/GridListTile";
import ImageCarouselDialog from "components/common/ImageCarouselDialog";
import PhotoSourceDialog from "components/common/PhotoSourceDialog";
import BottomButtom from "components/custom/BottomButtom";

import useOnlineStatus from "@rehooks/online-status";
import useForm from "hooks/useForm";
import useLoadingStatus from "hooks/useLoadingStatus";

import {
    takePicture,
    CameraPermissionDeniedException,
    CameraCanceledException,
    GalleryAccessPermissionDeniedException,
    GaleryCanceledException,
} from "util/camera";

import { Geolocation, GeolocationPermissionDeniedException, TimeoutExpiredException } from "util/geolocation";

import { deleteFiles, uploadFile } from "util/file";

import { Storage } from "@aws-amplify/storage";
import { DataStore } from "@aws-amplify/datastore";
import { Attachment, TaskStatus } from "models";
import { fetchAttachmentById } from "datastore";

import imageUtil from "util/image";

import { captureException } from "setup/sentry";
import IconCamera from "components/Tickets/IconCamera";
import { METADATA_KEYS, ATTACHMENT_METADATA_TYPES, ACTIVITY_CATEGORY_BY_TASK_TYPE } from "constant/attachments";
import { TASK_TYPES } from "constant/task/types";

const logger = new Logger("EvidenceViewV2");

function readS3File(key) {
    return Storage.get(key);
}

class StorageException extends Error { }

const INITIAL_FORM = { comments: "" };
const region = process.env.REACT_APP_AWS_REGION;
const bucket = process.env.REACT_APP_AWS_USER_FILES_S3_BUCKET;

const EvidenceView = forwardRef((props, ref) => {
    const [attachments, setAttachments] = useState([]);
    const [attachmentsUploadTrackers, setAttachmentsUploadTrackers] = useState({});
    const [attachmentDialogOpen, setAttachmentDialogOpen] = useState(false);
    const [openPhotoSourceDialog, setOpenPhotoSourceDialog] = useState(false);
    const [attachmentDialogIndex, setAttachmentDialogIndex] = useState(0);
    const onlineStatus = useOnlineStatus();
    const imagesDb = useIndexedDB("offline_images");
    const { showError, showMessage, closeMessage } = useNotifier();
    const [messageKey, setMessageKey] = useState(null);

    const classes = useStyles({ evidenceListHeight: props.evidenceListHeight });

    const gridListTopRef = useRef(null);

    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.down("sm"));

    const { values, handleChange } = useForm(INITIAL_FORM);

    const [isTakingPicture, takePictureWrapped] = useLoadingStatus(takePicture);
    const [isUploading, handleCameraBtnClickWrapper] = useLoadingStatus(handleCameraBtnClick);
    const userId = useSelector(({ session }) => session.userId);

    const classesIcons = useStylesIcons();
    const attachmentsLimitReached = props.limit < 1 ? false : props.limit === attachments.length;

    useEffect(() => {
        props.onLoading(isUploading || isTakingPicture);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUploading, isTakingPicture]);

    useEffect(() => {
        if (props.attachments) {
            const baseId = Date.now();
            const promises = props.attachments.map(async (attachment, index) => {
                const thumbnail = await readS3File(attachment.key);

                return {
                    id: baseId + index,
                    attachmentId: attachment.attachmentId,
                    thumbnail,
                    noNew: true,
                    uploaded: true,
                    s3KeyFile: attachment.key,
                    source: thumbnail,
                };
            });

            Promise.all(promises).then((results) => {
                setAttachments((value) => results);
            });
        }
    }, []);

    useEffect(() => {
        if (messageKey && !props.loadingPosition) {
            closeMessage(messageKey);
        }
    }, [messageKey, props.loadingPosition, closeMessage]);

    const ActionButton = React.useMemo(() => {
        if (xs) {
            return (
                <BottomButtom
                    networkTooltipProps={{ onlineTtitle: "Agregar evidencias" }}
                    buttonProps={{
                        onClick: handleOpenPhotoSourceDialog,
                        className: classes.btnLoadEvidence,
                        startIcon: <IconCamera />,
                        disabled: attachmentsLimitReached || isUploading,
                    }}
                >
                    Agregar Evidencia
                </BottomButtom>
            );
        } else {
            return (
                <Box position="absolute" bottom="0" left="0" width="100%" style={{ backgroundColor: "white" }}>
                    <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        disableElevation
                        disabled={attachmentsLimitReached || isUploading}
                        startIcon={<IconCamera />}
                        onClick={handleOpenPhotoSourceDialog}
                        className={classes.btnLoadEvidence}
                    >
                        Agregar Evidencia
                    </Button>
                </Box>
            );
        }
    }, [attachmentsLimitReached, classes.btnLoadEvidence, isUploading, props.loadingPosition, xs]);

    // EvidenceGridList handlers *************************************************
    async function handleDeleteItem(item) {
        logger.debug(`Se eliminará archivo con id ${item.attachmentId}`);
        // Se busca archivo en datastore
        const attachment = await fetchAttachmentById(item.attachmentId);
        logger.debug(attachment);

        // se borra de s3
        if (attachment) {
            if (onlineStatus) {
                deleteFiles(attachment?.file?.key)
                    .then((result) => logger.debug(result))
                    .catch((err) => logger.debug(err));
            } else {
                const attach = {
                    id: attachment.id,
                    taskID: props.taskId,
                    pendingDelete: true,
                    file: {
                        ...attachment.file,
                    },
                };
                imagesDb
                    .add(attach)
                    .then((result) => {
                        logger.debug(result);
                    })
                    .catch((error) => {
                        logger.error(error);
                    });
            }
            await DataStore.delete(attachment);
        } else {
            // se busco archivo en la bdlocal
            await imagesDb.deleteRecord(item.attachmentId);
        }

        // se elimina archivo de alguno de los estados del componente
        setAttachments((value) => {
            // const index = value.findIndex((a) => a.attachmentId === item.attachmentId);
            // if (index === -1) return value;
            // const first = value.slice(0, index);
            // const last = value.slice(index + 1);
            return value.filter((a) => a.attachmentId !== item.attachmentId);
        });
        props.onDeleteItem(item.attachmentId);
        // se muestra mensaje de confirmación
        showMessage("Se ha eliminado el archivo");
    }

    async function handleCameraBtnClick(photoSource) {
        try {
            const images = await takePictureWrapped({ source: photoSource });
            const pendingImages = images.map(async (image) => {
                const { newImage, dataUrl } = await imageUtil.transformImageBySource(image, photoSource);
                await saveAttachment({ ...newImage, filename: newImage.filename, dataUrl });
            });
            await Promise.all(pendingImages);
        } catch (error) {
            logger.error(error);
            if (error instanceof CameraCanceledException || error instanceof GaleryCanceledException) {
                logger.debug("Toma de fotografía cancelada por el usuario.");
            } else if (error instanceof CameraPermissionDeniedException) {
                showError("Uso de cámara no autorizado.");
            } else if (error instanceof GalleryAccessPermissionDeniedException) {
                showMessage("Acceso a la galería de fotos no autorizado.");
            } else if (error instanceof GeolocationPermissionDeniedException) {
                showError("Uso de geolocalización no autorizado. Por favor, permita el uso de la geolocalización.");
            } else {
                showError("Ocurrió un error durante la carga de evidencia");
                captureException(error, "handleCameraBtnClick");
            }
            logger.error(error);
            return;
        }
    }

    const handleAttachmentClick = (attachmentIndex) => () => {
        setAttachmentDialogIndex(attachmentIndex);
        setAttachmentDialogOpen(true);
    };

    function handleAttachmentViewCloseClick() {
        setAttachmentDialogOpen(false);
    }

    function handleOpenPhotoSourceDialog() {
        if (props.loadingPosition && !messageKey) {
            return showMessage(`Cargando la ubicación, espere un momento...`, {
                persist: true,
                onEnter: (element, flag, key) => setMessageKey(key),
                onExit: () => setMessageKey(null),
                // eslint-disable-next-line react/display-name
                action: (key) => {
                    return (
                        <IconButton size="small" style={{ color: "#fff" }} onClick={() => closeMessage(key)}>
                            <CloseIcon />
                        </IconButton>
                    );
                },
            });
        } else if (!props.loadingPosition) {
            return setOpenPhotoSourceDialog(true);
        }
    }

    async function saveAttachment(image) {
        const { position, positionError } = props;
        if (positionError) {
            throw positionError;
        }

        // Creación de objeto de DataStore *******************************
        const attachment = {
            taskID: props.taskId,
            status: props.status,
            file: {
                key: "",
                bucket,
                region,
            },
        };
        let dsAttachment = new Attachment(attachment);

        // Formateo de key de imagen ************************************
        const extension = imageUtil.getFileExtension(image.filename);
        let s3KeyObject = dsAttachment.id.slice();

        if (extension) {
            s3KeyObject = `${ATTACHMENT_METADATA_TYPES.ATTACHMENT}_${s3KeyObject}.${extension}`;
        }
        if (props.prefix) {
            s3KeyObject = `${props.prefix}/${s3KeyObject}`;
        }

        // Actualización de key de Attachment ************************************
        dsAttachment = Attachment.copyOf(dsAttachment, (updated) => {
            updated.file = {
                ...attachment.file,
                key: s3KeyObject,
            };
            return updated;
        });

        const newFile = {
            id: dsAttachment.id,
            attachmentId: dsAttachment.id,
            loading: true,
            thumbnail: image.dataUrl,
        };

        setAttachments((value) => [newFile, ...value]);
        setAttachmentsUploadTrackers((value) => ({ ...value, [dsAttachment.id]: null }));

        try {
            let uploaded = false;
            let s3KeyFile = {};
            let watermarkedImage = await imageUtil.writeTextOnImage(image.dataUrl, userId, position);
            image = await imageUtil.toImageObject(watermarkedImage);

            if (onlineStatus) {
                await DataStore.save(dsAttachment);

                s3KeyFile = await uploadFile(dsAttachment.file.key, image.blob, {
                    progressCallback(progress) {
                        const { loaded, total } = progress;
                        let percentage = (loaded * 100) / total;
                        percentage = percentage.toFixed(0);
                        logger.debug(`Uploaded: ${percentage}`);
                        setAttachmentsUploadTrackers((value) => ({ ...value, [dsAttachment.id]: percentage }));
                    },
                    metadata: {
                        [METADATA_KEYS.ATTACHMENT_ID]: dsAttachment.id,
                        [METADATA_KEYS.ATTACHMENT_TYPE]: ATTACHMENT_METADATA_TYPES.ATTACHMENT,
                        [METADATA_KEYS.ACTIVITY_ID]: props.taskId,
                        [METADATA_KEYS.ACTIVITY_CATEGORY]: ACTIVITY_CATEGORY_BY_TASK_TYPE[props.taskType],
                    },
                });

                uploaded = true;
            } else {
                logger.debug("Se guardara de manera local en indexdb");
                // se guardará de manera local
                const attach = {
                    ...attachment,
                    id: dsAttachment.id,
                    blob: image?.blob,
                    filename: dsAttachment.file.key,
                    file: {
                        key: dsAttachment.file.key,
                        bucket,
                        region,
                    },
                    metadata: {
                        [METADATA_KEYS.ATTACHMENT_ID]: dsAttachment.id,
                        [METADATA_KEYS.ATTACHMENT_TYPE]: ATTACHMENT_METADATA_TYPES.ATTACHMENT,
                        [METADATA_KEYS.ACTIVITY_ID]: props.taskId,
                        [METADATA_KEYS.ACTIVITY_CATEGORY]: ACTIVITY_CATEGORY_BY_TASK_TYPE[props.taskType],
                    },
                };
                await imagesDb.add(attach);
            }

            let file = {
                ...newFile,
                id: dsAttachment.id,
                filename: image.name,
                type: image.type,
                loading: false,
                thumbnail: image.dataUrl,
                source: image.dataUrl,
                blob: image.blob,
                dataUrl: image.dataUrl,
                uploaded,
                s3KeyFile: s3KeyFile?.key,
            };

            // Replace the loading image for the actual image
            setAttachments((value = []) => {
                const index = value.findIndex((a) => a.id === dsAttachment.id);
                if (index === -1) return value;
                const copy = [...value];
                copy[index] = { ...file };
                return copy;
            });

            props.onEvidenceChange(dsAttachment);
        } catch (error) {
            // Remove the loading image
            setAttachments((value = []) => {
                const index = value.findIndex((a) => a.id === dsAttachment.id);
                if (index === -1) return value;
                const first = value.slice(0, index);
                const last = value.slice(index + 1);
                return [...first, ...last];
            });
            if (error instanceof CameraCanceledException || error instanceof GaleryCanceledException) {
                logger.debug("Toma de fotografía cancelada por el usuario.");
            } else if (error instanceof CameraPermissionDeniedException) {
                showError("Uso de cámara no autorizado");
            } else if (error instanceof GeolocationPermissionDeniedException) {
                showError("Uso de geolocalización no autorizado. Por favor, permita el uso de la geolocalización.");
            } else if (error instanceof TimeoutExpiredException) {
                showError("Se agotó el tiempo para obtener la ubicación. Verifique que la geolocalización esté activa.");
            } else if (error instanceof StorageException) {
                showError("Error al cargar la imagen");
            } else {
                showError("Error inesperado al cargar la imagen");
            }
            logger.error(error);
            return;
        }
    }

    useImperativeHandle(
        ref,
        () => ({
            getEvidence: async () => {
                const { coords } = await Geolocation.getCurrentPosition();
                const _attachments = [];

                attachments.forEach((a) => {
                    _attachments.push({
                        name: a.filename,
                        filename: a.filename,
                        uploaded: a.uploaded,
                        dataUrl: a.dataUrl,
                        blob: a.blob,
                        s3KeyFile: a.s3KeyFile,
                        geo_reference: `${(a.coords || coords).latitude},${(a.coords || coords).longitude}`,
                    });
                });

                return {
                    location: `${coords.latitude},${coords.longitude}`,
                    comments: values.comments,
                    attachments: _attachments,
                };
            },
        }),
        [values.comments, attachments]
    );

    // Obtenemos las imagenes que se han terminado de cargar
    const loadedAttachments = attachments.filter((a) => !a.loading && a.source);

    // Calculamos el indice real de la imagen a mostrar restando las imagenes que
    // se filtraron por estar en carga
    const clickedAttachmentIndex = attachmentDialogIndex - (attachments.length - loadedAttachments.length);

    return (
        <>
            <Grid container spacing={2} className={classes.evidenceCounter}>
                <Grid item sm>
                    <Typography variant="subtitle2" align="center" className={classes.evidenceCounterText}>
                        <span className={classes.evidenceCounterCount}>{attachments.length}</span> EVIDENCIAS DE{" "}
                        <span className={classes.evidenceCounterCount}>{props.min} </span>
                        REQUERIDAS
                    </Typography>
                </Grid>
            </Grid>

            <PhotoSourceDialog
                open={openPhotoSourceDialog}
                onClose={() => setOpenPhotoSourceDialog(false)}
                onSelectSource={handleCameraBtnClickWrapper}
            />

            <ImageCarouselDialog
                open={attachmentDialogOpen}
                onClose={handleAttachmentViewCloseClick}
                views={loadedAttachments}
                currentIndex={clickedAttachmentIndex}
            />

            {props.title && (
                <Typography variant="body2" paragraph>
                    <b>{props.title}</b>
                </Typography>
            )}

            {attachmentsLimitReached && <>Límite de evidencias alcanzado</>}

            <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ flexGrow: 1 }}>
                    <div id="anchor-element" ref={gridListTopRef} />

                    {/* diaplay evidence  */}
                    {attachments.length !== 0 && (
                        <GridList cellHeight={120} spacing={1} className={classes.gridList}>
                            {attachments.map((a, index) => {
                                return (
                                    <GridListTile
                                        key={`preview-${index}`}
                                        cols={!xs ? 1 : 2}
                                        className={classes.liContainer}
                                        scrollOnTap
                                        onClick={handleAttachmentClick(index)}
                                    >
                                        <img src={a.thumbnail} alt={a.title} />

                                        <>
                                            <GridListTileBar
                                                title={``}
                                                className={classes.uploadIndicatorContainer}
                                                titlePosition="bottom"
                                                actionPosition="right"
                                                actionIcon={
                                                    a.loading && attachmentsUploadTrackers.hasOwnProperty(a.id) ? (
                                                        <Box position="relative" display="inline-flex" className={classes.UploadIndicatorBox}>
                                                            {attachmentsUploadTrackers[a.id] === null ? (
                                                                <CircularProgress />
                                                            ) : (
                                                                <>
                                                                    <CircularProgress
                                                                        variant="determinate"
                                                                        value={attachmentsUploadTrackers[a.id]}
                                                                        {...props}
                                                                    />
                                                                    <Box
                                                                        top={0}
                                                                        left={0}
                                                                        bottom={10}
                                                                        right={0}
                                                                        className={classes.uploadIndicatorTextContainer}
                                                                        position="absolute"
                                                                        display="flex"
                                                                        alignItems="center"
                                                                        justifyContent="center"
                                                                        zIndex="10"
                                                                    >
                                                                        <Typography
                                                                            variant="caption"
                                                                            className={classes.uploadIndicatorText}
                                                                            component="div"
                                                                            color="textSecondary"
                                                                        >{`${Math.round(attachmentsUploadTrackers[a.id])}%`}</Typography>
                                                                    </Box>
                                                                </>
                                                            )}
                                                        </Box>
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                            ></GridListTileBar>
                                        </>

                                        <GridListTileBar
                                            title={`Evidencia ${attachments.length - index}`}
                                            titlePosition="top"
                                            actionPosition="right"
                                            actionIcon={
                                                <IconButton
                                                    aria-label={`star ${a.title}`}
                                                    className={classes.icon}
                                                    disabled={
                                                        (attachmentsUploadTrackers[a.id] === null || !isNaN(attachmentsUploadTrackers[a.id])) &&
                                                        !a.uploaded
                                                    }
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleDeleteItem(attachments[index]);
                                                    }}
                                                >
                                                    <CloseIcon />
                                                </IconButton>
                                            }
                                            className={classes.titleBar}
                                        />
                                    </GridListTile>
                                );
                            })}
                        </GridList>
                    )}
                    {/* This validation display when there is no evidence */}
                    {attachments.length === 0 && (
                        <Box mb={12}>
                            <div className={classesIcons.containerImgLoad}>
                                <span className={classesIcons.imgLoad} role="img" aria-label="img_load"></span>
                            </div>
                            <div className={classesIcons.label}>Las fotos aparecerán aquí cuando las agregues</div>
                        </Box>
                    )}
                    {/* END This validation display when there is no evidence */}
                </div>

                {/* WithComments  */}
                {props.withComments && (
                    <div style={{ marginTop: "8px" }}>
                        <Divider />
                        <Box my={1}>
                            <TextField
                                id="outlined-multiline-static"
                                label="Comentarios"
                                name="comments"
                                value={values.comments}
                                onChange={handleChange}
                                multiline
                                fullWidth
                                rows="2"
                                variant="outlined"
                            />
                        </Box>
                    </div>
                )}
                {/* End WithComments  */}
            </div>
            {/* This button opens the modal to choose whether to take a photo or choose from the gallery  */}
            {ActionButton}
        </>
    );
});

EvidenceView.propTypes = {
    title: PropTypes.string,
    taskId: PropTypes.string,
    taskType: PropTypes.oneOf(Object.values(TASK_TYPES)),
    inputName: PropTypes.string,
    limit: PropTypes.number,
    // Prefijo a utilizar al almacenar las evidencias
    prefix: PropTypes.string,
    status: PropTypes.oneOf([TaskStatus.SCHEDULED, TaskStatus.IN_PROGRESS, TaskStatus.COMPLETED]).isRequired,
    min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onLoading: PropTypes.func,
    // Bandera para mostrar un estado de carga
    loading: PropTypes.bool,
    onEvidenceChange: PropTypes.func,
    onDeleteItem: PropTypes.func,
    withComments: PropTypes.bool,
    attachments: PropTypes.array,
    evidenceListHeight: PropTypes.string,
    position: PropTypes.object,
    loadingPosition: PropTypes.bool,
    positionError: PropTypes.object,
};

EvidenceView.defaultProps = {
    title: "",
    taskId: "",
    taskType: TASK_TYPES.SUPPORT,
    prefix: "",
    status: TaskStatus.SCHEDULED,
    min: undefined,
    onLoading: () => { },
    loading: false,
    onEvidenceChange: () => { },
    onDeleteItem: () => { },
    withComments: false,
    attachments: undefined,
    evidenceListHeight: "auto",
    loadingPosition: false,
};

export default EvidenceView;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        overflow: "hidden",
        backgroundColor: theme.palette.background.paper,
    },
    gridList: ({ evidenceListHeight }) => ({
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: "translateZ(0)",
        height: evidenceListHeight,
    }),
    titleBar: {
        background: "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " + "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
    },
    icon: {
        color: "white",
    },
    liContainer: {
        position: "relative",
        margin: "0 0 20px 0",
    },
    UploadIndicatorBox: {
        position: "absolute",
        bottom: 5,
        right: 10,
    },
    uploadIndicatorTextContainer: {
        height: "100%",
    },
    uploadIndicatorText: {
        color: "#FFF",
    },
    uploadIndicatorContainer: {
        background: "none",
    },
    btnLoadEvidence: {
        backgroundColor: "rgba(16, 82, 133, 0.2)",
        height: "48px",
        color: "#105285",
        "&:hover": {
            backgroundColor: "rgba(16, 82, 133, 0.2)",
        },
    },
    evidenceCounter: {
        borderTop: "solid 2px #cfdce7a6",
        borderBottom: "solid 4px #cfdce7",
        margin: "10px 0 20px 0",
        justifyContent: "center",
        // width: "100%",
        width: "100vw",
        marginInlineStart: "50%",
        transform: "translateX(-50%)",
    },
    evidenceCounterText: {
        color: "hsl(199deg 83% 56%)",
    },
    evidenceCounterCount: {
        color: "#105285",
    },
}));

const useStylesIcons = makeStyles(() => ({
    containerImgLoad: {
        display: "flex",
        justifyContent: "center",
        margin: "53px 0 53px 0",
    },
    imgLoad: {
        backgroundImage: `url("/img/img_load.svg")`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width: "90px",
        height: "90px",
        display: "block",
    },
    label: {
        width: "219px",
        fontSize: "14px",
        lineHeight: "1.21",
        textAlign: "center",
        color: "rgba(31, 31, 31, 0.67);",
        margin: "0 auto 100px auto",
    },
}));
