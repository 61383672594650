/**
 * Este componente funciona como un wrapper para los elementos que se deshabilitan cuando se pierde la conexión a internet y se requiere dar retroalimentación al usuario.
 * Ejemplo
 * @example {
 * <NetworkTooltip offlineTitle="TooltipsitoOffline" onlineTitle="TooltipsitoOnline" >
 *  <MyComponent/>
 * </NetworkTooltip>
 * }
 */

import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import useOnlineStatus from "@rehooks/online-status";

import { Box, Tooltip, ClickAwayListener, useMediaQuery, useTheme } from "@material-ui/core";

const NetworkTooltip = (props) => {
  const { children, offlineTitle, onlineTitle, ...tooltipProps } = props;

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const timeoutIDRef = useRef(null);
  const isOnline = useOnlineStatus();
  const theme = useTheme();
  const matches1024 = useMediaQuery(theme.breakpoints.down(1024));

  const isOffline = !isOnline;
  const title = Boolean(offlineTitle) && isOffline ? offlineTitle : onlineTitle;
  const shouldBeControlled = isOffline && matches1024 && Boolean(offlineTitle);

  useEffect(() => {
    let timeoutId = timeoutIDRef.current;
    if (timeoutId) {
      clearTimeout(timeoutId);
      timeoutIDRef.current = null;
    }
    if (isTooltipOpen && shouldBeControlled) {
      timeoutIDRef.current = setTimeout(() => {
        closeTooltip();
        timeoutIDRef.current = null;
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTooltipOpen]);

  const closeTooltip = () => setIsTooltipOpen(false);
  const onTooltipClick = () => setIsTooltipOpen((currentValue) => !currentValue);

  const controlledProps = {
    //mobile devices when offline
    open: isTooltipOpen,
    title,
    arrow: true,
    disableHoverListener: true,
    disableTouchListener: true,
    disableFocusListener: true,
    PopperProps: {
      disablePortal: true,
    },
    onClose: closeTooltip,
    onClick: onTooltipClick,
  };

  const childElement = (
    <Box component="span" display="flex" width="100%">
      {children}
    </Box>
  );

  if (isOnline && !Boolean(onlineTitle)) {
    return children;
  }

  if (shouldBeControlled) {
    //Tooltip controlado
    return (
      <ClickAwayListener onClickAway={closeTooltip}>
        <Tooltip {...controlledProps} {...tooltipProps}>
          {childElement}
        </Tooltip>
      </ClickAwayListener>
    );
  } else {
    //Tooltip no controlado
    return (
      <Tooltip title={title} leaveDelay={300} {...tooltipProps}>
        {childElement}
      </Tooltip>
    );
  }
};

NetworkTooltip.propTypes = {
  children: PropTypes.node,
  offlineTitle: PropTypes.string.isRequired,
  onlineTitle: PropTypes.string,
};

NetworkTooltip.defaultProps = {
  children: null,
  offlineTitle: "Esta acción requiere conexión a internet.",
  onlineTitle: "",
};

export default NetworkTooltip;
