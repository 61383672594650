import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Controller, useForm } from "react-hook-form";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import ConfirmationDialog from "components/ConfirmationDialog";
import { FORM_MODES } from "constant/formModes";
import { createAttribute, createAssetAttribute, updateAssetAttribute, fetchAttributes } from "./datastore";
import useNotifier from "hooks/useNotifier";
import useLoadingStatus from "hooks/useLoadingStatus";
import AutocompleteField from "components/common/AutocompleteField";
import debounce from "lodash/debounce";

export default function AttributeDialog({ isOpen, attribute, asset, onCreate, onUpdate, onClose, ...props }) {
  const [formMode, setFormMode] = useState(attribute ? FORM_MODES.UPDATE : FORM_MODES.CREATE);
  const [dialogText, setDialogText] = useState(formMode === FORM_MODES.CREATE ? CREATE_TEXT : UPDATE_TEXT);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [attributesOptions, setAttributesOptions] = useState([]);
  const [loadingOptions, _getAttributes] = useLoadingStatus(getAttributes);
  const [creatingOrUpdating, _createOrUpdate] = useLoadingStatus(createOrUpdateAttribute);
  const { control, getValues, trigger, setValue, errors, reset } = useForm(USE_FORM_OPTIONS);
  const { showMessage, showError } = useNotifier();

  useEffect(() => {
    if (isOpen && attribute) {
      setValue("key", attribute.attribute);
      setValue("value", attribute.value);
      setFormMode(FORM_MODES.UPDATE);
      setDialogText(UPDATE_TEXT);
      setAttributesOptions([]);
    }
  }, [isOpen, attribute, setValue]);

  async function getAttributes(name) {
    try {
      const attributes = await fetchAttributes(name);
      return attributes;
    } catch (error) {
      showError("Ocurrió un error al buscar las etiquetas de atributos");
      return [];
    }
  }

  function cleanForm() {
    reset();
    setFormMode(FORM_MODES.CREATE);
    setDialogText(CREATE_TEXT);
  }

  async function validateForm() {
    const validForm = await trigger();
    if (validForm) {
      setShowConfirmation(true);
    }
  }

  async function createOrUpdateAttribute() {
    const { key, value } = getValues();
    try {
      if (formMode === FORM_MODES.CREATE) {
        let attributeData;
        if (key.id && key.name) {
          attributeData = key;
        } else {
          attributeData = await createAttribute(key.name);
        }
        const assetAttribute = await createAssetAttribute(asset, attributeData, value);
        onCreate({ ...assetAttribute });
      } else if (formMode === FORM_MODES.UPDATE) {
        const updatedAttribute = await updateAssetAttribute(attribute.id, value);
        onUpdate({ ...updatedAttribute });
      }
      setShowConfirmation(false);
      onClose();
      showMessage(dialogText.successMsg);
    } catch (error) {
      console.log(error);
      showError(dialogText.errorMsg);
    }
  }

  function searchAttributes(event, value = "") {
    value = value.trim();
    if (!value) {
      return;
    }
    _getAttributes(value).then((foundAttributes) => {
      if (foundAttributes.length) {
        foundAttributes = foundAttributes.reduce((allowed, attribute) => {
          let attributes = asset?.attributes?.items || asset?.attributes;
          attributes = attributes || [];
          const index = attributes.findIndex((a) => a.attribute.id === attribute.id);
          if (index > -1) {
            return allowed;
          }
          allowed.push({ ...attribute });
          return allowed;
        }, []);
      } else {
        foundAttributes.push({
          inputValue: value,
          name: `Agregar "${value}"`,
        });
      }
      setAttributesOptions(foundAttributes);
    });
  }

  function setKeyField(event, newValue) {
    if (typeof newValue === "string") {
      return {
        name: newValue.trim(),
      };
    } else if (newValue && newValue.inputValue) {
      return {
        name: newValue.inputValue.trim(),
      };
    } else {
      return newValue;
    }
  }

  return (
    <>
      <Dialog open={isOpen} onExit={cleanForm} fullWidth>
        <DialogTitle>{dialogText.title}</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <AutocompleteField
                name="key"
                control={control}
                rules={TEXT_FIELD_RULES}
                options={attributesOptions}
                disabled={formMode === FORM_MODES.UPDATE}
                getOptionLabel={(option) => option.name}
                onInputChange={debounce(searchAttributes, 300)}
                onSelectionChange={setKeyField}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Etiqueta"
                    error={!!errors.key}
                    helperText={errors.key?.message}
                    required
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loadingOptions ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                as={TextField}
                rules={TEXT_FIELD_RULES}
                name="value"
                type="text"
                label="Valor"
                fullWidth
                required
                error={!!errors.value}
                helperText={errors.value?.message}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="default">
            CANCELAR
          </Button>
          <Button onClick={validateForm} color="primary">
            {dialogText.confirmBtn}
          </Button>
        </DialogActions>
      </Dialog>

      <ConfirmationDialog
        open={showConfirmation}
        title="Confirmación"
        onCancel={() => setShowConfirmation(false)}
        onConfirm={_createOrUpdate}
        loading={creatingOrUpdating}
      >
        <Typography paragraph>{dialogText.confirmationMsg}</Typography>
        <Typography paragraph>¿Deseas continuar?</Typography>
      </ConfirmationDialog>
    </>
  );
}

AttributeDialog.propTypes = {
  isOpen: PropTypes.bool,
  attribute: PropTypes.object,
  asset: PropTypes.object,
  onClose: PropTypes.func,
  onCreate: PropTypes.func,
  onUpdate: PropTypes.func,
};

AttributeDialog.defaultProps = {
  isOpen: false,
  attribute: null,
  asset: null,
  onClose: () => { },
  onCreate: () => { },
  onUpdate: () => { },
};

const USE_FORM_OPTIONS = {
  mode: "onChange",
  defaultValues: {
    key: "",
    value: "",
  },
};

const TEXT_FIELD_RULES = {
  required: "Campo obligatorio",
  maxLength: { value: 60, message: "Máximo 60 caracteres" },
  validate: {
    notEmpty: (value) => {
      return (
        (value && ((value?.trim && value?.trim() !== "") || (value?.name && value?.name.trim() !== ""))) ||
        "Campo obligatorio"
      );
    },
  },
};

const CREATE_TEXT = {
  title: "Nuevo Atributo",
  confirmBtn: "Crear",
  confirmationMsg: "Se creará un nuevo atributo en el activo.",
  successMsg: "Creación exitosa",
  errorMsg: "Ocurrió un error durante la creación del atributo",
};

const UPDATE_TEXT = {
  title: "Actualizar Atributo",
  confirmBtn: "Actualizar",
  confirmationMsg: "Se actualizará el atributo en el activo.",
  successMsg: "Actualización exitosa",
  errorMsg: "Ocurrió un error durante la actualización del atributo",
};
