import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Controller, useForm } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import ConfirmationDialog from "components/ConfirmationDialog";

const DEFAULT_FORM = { confirmationWord: "" };
function WordConfirmationDialog({ confirmationWord, ...props }) {
  const form = useForm({
    mode: "onChange",
    defaultValues: DEFAULT_FORM,
  });

  function handleExited() {
    form.reset(DEFAULT_FORM);
  }

  const wordRules = useMemo(
    () => ({
      required: "Campo obligatorio",
      validate: { confirmationWord: (value) => value === confirmationWord || "Ingrese la palabra correcta" },
    }),
    [confirmationWord]
  );
  const formValues = form.getValues();

  return (
    <ConfirmationDialog
      {...props}
      onExited={handleExited}
      okDisabled={!formValues.confirmationWord || form.errors.confirmationWord || props.okDisabled}
    >
      {props.children}
      <Controller
        control={form.control}
        as={TextField}
        variant="outlined"
        name="confirmationWord"
        size="small"
        fullWidth
        required
        value={formValues.confirmationWord}
        rules={wordRules}
        error={!!form.errors.confirmationWord}
        helperText={form.errors.confirmationWord?.message}
      />
    </ConfirmationDialog>
  );
}

WordConfirmationDialog.defaultProps = {
  okDisabled: false,
  confirmationWord: "",
};

WordConfirmationDialog.propTypes = {
  okDisabled: PropTypes.bool,
  confirmationWord: PropTypes.string.isRequired,
  children: PropTypes.node,
};

const SPAN_STYLE = {
  "-moz-user-select": "none",
  "-webkit-user-select": "none",
  "-ms-user-select": "none",
  "user-select": "none",
  "-o-user-select": "none",
};

export function KeyWord(props) {
  let keyWord = <span style={SPAN_STYLE}>{props.children}</span>;
  if (props.bold) {
    keyWord = <strong>{keyWord}</strong>;
  }
  if (props.italic) {
    keyWord = <i>{keyWord}</i>;
  }

  return keyWord;
}

export default WordConfirmationDialog;
