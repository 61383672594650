import API, { graphqlOperation } from "@aws-amplify/api";

export async function removeMaterials(materials, userId) {
  const { mutation, variables } = generateDeleteMaterialsMutation(materials);
  const { data } = await API.graphql(graphqlOperation(mutation, variables));
  const deletedMaterials = Object.keys(data).map(key => data[key]);
  const { mutation: logMutation, variables: logVariables } = generateCreateLogsMutation(deletedMaterials, userId);
  await API.graphql(graphqlOperation(logMutation, logVariables));
  return deletedMaterials;
}

export function generateDeleteMaterialsMutation(materials) {
  let mutation = "";
  let mutationParams = "";
  const variables = {};

  materials.forEach((material, index) => {
    mutationParams += `$updateMaterial${index}: UpdateMaterialInput!, `;
    mutation += `
      updateMaterial${index}: updateMaterial(input: $updateMaterial${index}) {
        id
        name
        code
        description
        category {
          id
          name
          abbreviation
          description
          createdAt
          updatedAt
        }
        uom {
          id
          name
          abbreviation
          description
          createdAt
          updatedAt
        }
        manufacturer
        trackable
        enabled
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        deleted
      },
    `;
    variables[`updateMaterial${index}`] = {
      id: material.id,
      _version: material._version,
      deleted: true,
    };
  });

  mutation = `
    mutation DeleteMaterials(${mutationParams}) {
      ${mutation}
    }
  `;

  return { mutation, variables };
} 

export function generateCreateLogsMutation(deletedMaterials = [], userId = "") {
  let mutation = "";
  let mutationParams = "";
  const variables = {};

  deletedMaterials.forEach((material, index) => {
    mutationParams += `$createLog${index}: CreateLogInput!, `;
    mutation += `
      createLog${index}: createLog(input: $createLog${index}) {
        id
        userID
        dataJSON  
        dataType
      },
    `;
    variables[`createLog${index}`] = {
      userID: userId,
      dataType: "MATERIAL",
      dataJSON: JSON.stringify(material),
    };
  });

  mutation = `
    mutation CreateLogs(${mutationParams}) {
      ${mutation}
    }
  `;

  return { mutation, variables };
}