import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

//hooks
import useSession from "hooks/useSession";
import useBooleanFlag from "hooks/useBooleanFlag";
import useDataStoreStatus from "hooks/useDataStoreStatus";
import { useDrawerStyles } from "./index.jss";

//material-ui
import useTheme from "@material-ui/core/styles/useTheme";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import Tooltip from "@material-ui/core/Tooltip";

//components
import WordConfirmationDialog from "components/common/WordConfirmationDialog";
import AboutInfoDialog from "components/common/AboutInfoDialog";
import NestedList from "./components/NestedList";

function AppDrawer({ isOpen, drawerItems, closeDrawer, openDrawer, isMobile }) {
  const classes = useDrawerStyles();
  const theme = useTheme();
  const session = useSession();
  const { dataStoreStatus } = useDataStoreStatus();
  const pendingDataToSycn = useSelector((state) => state.app.pendingDataToSycn);
  const [confirmatonDialogOpen, openConfirmatonDialog, closeConfirmatonDialog] = useBooleanFlag();
  const [aboutDialogOpen, openAboutDialog, closeAboutDialog] = useBooleanFlag();
  const [isCloseByHeader, setIsCloseByHeader] = React.useState(false);

  async function logout() {
    return session.logout();
  }

  async function handleLogOut() {
    if (pendingDataToSycn) {
      openConfirmatonDialog(true);
    } else {
      await logout();
    }
  }

  return (
    <>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: isOpen,
          [classes.drawerClose]: !isOpen,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: isOpen,
            [classes.drawerClose]: !isOpen,
          }),
        }}
      >
        <div className={classes.drawerHeader}>
          <Box mt={1}>
            <Link to="/inicio" className="drawer-nav-link">
              <img src={`/img/indika-color-logo.png`} height="30" alt="Indika Logo" />
            </Link>
          </Box>
          <div className={classes.toolbar}>
            <IconButton
              onClick={(event) => {
                closeDrawer(event);
                setIsCloseByHeader(true);
              }}
            >
              {theme.direction === "ltr" ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>
        </div>
        <Hidden smUp>
          {isOpen && (
            <div className={classes.drawerUsername}>
              <Typography variant="caption" noWrap>
                {session.data.username}
              </Typography>
            </div>
          )}
        </Hidden>
        <Divider />
        <List>
          <ListItem button component={Link} to="/inicio" className="drawer-nav-link">
            <ListItemIcon>
              <HomeOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Inicio" primaryTypographyProps={{ className: "drawer-nav-link" }} />
          </ListItem>
        </List>
        <Divider />
        <NestedList
          items={drawerItems}
          handleDrawerClose={closeDrawer}
          openDrawer={openDrawer}
          isDrawerOpen={isOpen}
          isCloseByHeader={isCloseByHeader}
          setIsCloseByHeader={setIsCloseByHeader}
        />
        <div id="drawer-filler" className={classes.drawerFiller}></div>
        <Divider />
        <List>
          {dataStoreStatus === "IN_PROGRESS" ? (
            <Tooltip title="Espera a que termine la sincronización" arrow placement="top" open={isMobile}>
              <div>
                <ListItem button divider disabled>
                  <ListItemIcon>
                    <ExitToAppIcon />
                  </ListItemIcon>
                  <ListItemText primary="Cerrar sesión" />
                </ListItem>
              </div>
            </Tooltip>
          ) : (
            <ListItem button divider onClick={handleLogOut}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary="Cerrar sesión" />
            </ListItem>
          )}
          <ListItem button divider onClick={openAboutDialog}>
            <ListItemIcon>
              <InfoOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Acerca de" />
          </ListItem>
        </List>
      </Drawer>

      <WordConfirmationDialog
        open={confirmatonDialogOpen}
        okBtnText="Salir"
        confirmationWord={"cerrar sesion"}
        title="ATENCIÓN"
        onConfirm={logout}
        onCancel={() => closeConfirmatonDialog(false)}
      >
        <p>
          Tiene cambios que no han sido sincronizados al sevidor, debe conectarse a internet para poder sincronizar.
        </p>
        <p>
          <b>Si sale ahora se perderán los cambios.</b>
        </p>

        <p>Si aun así desea salir escriba la palabra `&quot;`cerrar sesion`&quot;` en el recuadro y presione salir.</p>
      </WordConfirmationDialog>
      <AboutInfoDialog open={aboutDialogOpen} onClose={closeAboutDialog} />
    </>
  );
}

AppDrawer.propTypes = {
  isOpen: PropTypes.bool,
  drawerItems: PropTypes.array,
  openDrawer: PropTypes.func,
  closeDrawer: PropTypes.func,
  isMobile: PropTypes.bool,
};

export default AppDrawer;
