import { DataStore } from "@aws-amplify/datastore";
import { Logger } from "aws-amplify";
import { InspectionNote, Task, TaskStatus, Ticket, TicketStatus, User } from "models";
import { Auth } from "aws-amplify";

const logger = new Logger("SignatureDialog-helpers");

export async function handleTaskSignature(
  ticketId,
  taskId,
  validatorName,
  validatorEmail,
  signatureObject,
  taskStatusToUpdate
) {
  let result = {
    ticketData: null,
    taskData: null,
  };

  if (!taskId || !validatorName || !taskStatusToUpdate || !signatureObject || !Object.keys(signatureObject).length) {
    const ERROR_MSG = "validateTicket: Invalid function parameter values";
    logger.error(ERROR_MSG);
    throw new Error(ERROR_MSG);
  }

  let task = await DataStore.query(Task, taskId);
  task = Task.copyOf(task, (updated) => {
    updated.status = taskStatusToUpdate;
    updated.verificatorName = validatorName;
    updated.verificatorEmail = validatorEmail;
    updated.validationSignatureFile = signatureObject;
    return updated;
  });
  result.taskData = await DataStore.save(task);

  if (Boolean(ticketId)) {
    let ticket = await DataStore.query(Ticket, ticketId);
    ticket = Ticket.copyOf(ticket, (updated) => {
      updated.verificatorName = validatorName;
      updated.validationSignatureFile = signatureObject;
      updated.status = TicketStatus.VALIDATED;
      return updated;
    });
    result.ticketData = await DataStore.save(ticket);
  }

  return result;
}

export async function handleAddInspectionNote(taskId, ticketId, comments) {
  let result = {
    newInspectionNote: null,
    taskData: null,
    ticketData: null,
  };
  let promises = [];
  const currentUser = await Auth.currentAuthenticatedUser();
  logger.debug("handleAddInspectionNote: añadiendo observacion - usuario que crea la observación...", { currentUser });

  let [task, userCreator] = await Promise.all([
    DataStore.query(Task, taskId),
    DataStore.query(User, currentUser.username),
  ]);

  logger.debug("handleAddInspectionNote: añadiendo observacion - información del task y usuario creador...", {
    task,
    userCreator,
  });
  task = Task.copyOf(task, (updated) => {
    updated.status = TaskStatus.WITH_INSPECTION_NOTES;
    return updated;
  });

  if (Boolean(ticketId)) {
    let ticket = await DataStore.query(Ticket, ticketId);
    ticket = Ticket.copyOf(ticket, (updated) => {
      updated.status = TicketStatus.IN_PROGRESS;
      return updated;
    });
    promises.push(DataStore.save(ticket));
  }

  const inspectionNote = new InspectionNote({
    taskID: taskId,
    comments,
    supervisor: userCreator,
  });

  const [newInspectionNote, updatedTask, updatedTicket = null] = await Promise.all([
    DataStore.save(inspectionNote),
    DataStore.save(task),
    ...promises,
  ]);

  result.newInspectionNote = newInspectionNote;
  result.taskData = updatedTask;
  result.ticketData = updatedTicket;

  logger.debug("handleAddInspectionNote: observación añadida - modelos actualizados...", {
    result,
  });

  return result;
}
