import { Logger } from "@aws-amplify/core";
import API, { graphqlOperation } from "@aws-amplify/api";
import * as queries from "graphql-custom/queries";
import dayjs from "dayjs";
import cleanUUID from "util/cleanUUID";
import { getTrackingID } from "components/warehouse/AssetsListView/helpers";
import { filterDeletedItems } from "util/lists";

const logger = new Logger("AssetDetailsView/helpers/graphql");

export async function fetchAssetDetail(assetID) {
  return API.graphql(
    graphqlOperation(queries.getAsset, {
      id: assetID,
    })
  ).then((result) => {
    result = result.data.getAsset;
    result.createdAt = result.createdAt ? dayjs(result.createdAt).format("DD/MM/YYYY HH:mm") : "Sin fecha";
    result.attributes.items = filterDeletedItems(result.attributes.items);

    // Formateo de tracking ID y fecha de asociación
    const id = getTrackingID(result);
    if (id) {
      result.tracking.viewId = cleanUUID(result?.tracking?.id);
      result.tracking.createdAt = dayjs(new Date(result?.tracking?._lastChangedAt)).format("DD/MM/YYYY HH:mm");
    } else {
      logger.debug("fetchAssetDetail: El activo no tiene Tracking asociado");
    }
    return result;
  });
}
