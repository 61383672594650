import TableSwitchButton from "components/common/TableSwitchButton";
import { transformToDate } from "util/date";

export const PROJECTS_COLUMNS = ({ onSwitchChange, isOnline }) => [
  {
    title: "Habilitado",
    field: "enabled",
    render: (rowData) => {
      const checked = Boolean(rowData.enabled) || rowData.enabled === null ? true : rowData.enabled;
      const enabledText = checked ? "Deshabilitar" : "Habilitar";
      const onlineTitle = `${enabledText} proyecto.`;
      const offlineTitle = `Sin conexión a internet para ${enabledText} proyecto.`;

      return TableSwitchButton({
        checked,
        onChange: onSwitchChange,
        disabled: !isOnline,
        onlineTitle,
        offlineTitle,
        rowData,
      });
    },
    tooltip: "Proyecto habilitado/deshabilitado.",
    disableClick: true,
    width: 104,
  },
  {
    title: "Nombre",
    field: "name",
    type: "string",
  },
  {
    title: "Código/Abreviación",
    field: "code",
    type: "string",
  },
  {
    title: "Código de contrato",
    field: "contractNumber",
    type: "string",
  },
  {
    title: "Fecha de inicio",
    field: "startAt",
    type: "date",
    render: (rowData) => (rowData.startAt ? transformToDate(rowData.startAt) : "Sin fecha definida"),
  },
  {
    title: "Fecha de fin",
    field: "endAt",
    type: "date",
    render: (rowData) => (rowData.endAt ? transformToDate(rowData.endAt) : "Sin fecha definida"),
  },
  {
    title: "Fecha de creación",
    field: "createdAt",
    type: "datetime",
    defaultSort: "desc",
    hidden: true,
  },
];
