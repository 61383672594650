import { Logger } from "@aws-amplify/core";
import {
  CameraPermissionDeniedException,
  CameraCanceledException,
  GalleryAccessPermissionDeniedException,
  GaleryCanceledException,
} from "./camera";
import { GeolocationPermissionDeniedException, getCurrentPosition, TimeoutExpiredException } from "./geolocation";
import wait from "util/wait";
import { captureException } from "setup/sentry";
import { CameraSource } from "@capacitor/camera";
import { Device } from "@capacitor/device";
import { APP_PLATFORM } from "constant/appPlatform";

const logger = new Logger("util/image");

export async function toImageObject(imageData) {
  return toBlobObject(imageData.dataUrl)
    .then((blob) => {
      return parseImageObject(imageData, blob);
    })
    .catch((error) => {
      throw error;
    });
}

export async function toImageObjectOffline(imageData) {
  const imageBlob = dataURItoBlob(imageData.dataUrl);
  return parseImageObject(imageData, imageBlob);
}

export async function toBlobObject(dataUrl) {
  return fetch(dataUrl).then((file) => file.blob());
}

export function dataURItoBlob(dataURI) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(",")[1]);

  // separate out the mime component
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  var ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  var blob = new Blob([ab], { type: mimeString });
  return blob;
}

export function base64ToBlob(b64Data, contentType = "image/jpeg", sliceSize = 512) {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

export function blobToDataURL(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (_e) => resolve(reader.result);
    reader.onerror = (_e) => reject(reader.error);
    reader.onabort = (_e) => reject(new Error("Read aborted"));
    reader.readAsDataURL(blob);
  });
}

export async function transformImageBySource(image, photoSource) {
  let newImage = { ...image };
  let dataUrl;
  const info = await Device.getInfo();
  if (photoSource === CameraSource.Camera || info.platform === APP_PLATFORM.WEB) {
    newImage = await toImageObject(image); // Transform the data url to an object containing the blob data
    dataUrl = newImage.dataUrl; // Obtaining the data url
  } else if (photoSource === CameraSource.Photos) {
    newImage.blob = base64ToBlob(newImage.data, `image/${newImage.format}`); // Transform the base64 data to blob
    dataUrl = await blobToDataURL(newImage.blob); // Transform the blob to data url
  }
  return { newImage, dataUrl };
}

export function parseImageObject(imageData, blob) {
  const name = `ain_${Date.now()}`;
  const ext = getExtension(blob.type);
  return {
    ...imageData,
    blob,
    ext,
    name,
    filename: `${name}${ext}`,
  };
}

/**
 * Reference https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Complete_list_of_MIME_types
 * @param {*} imageType
 */
function getExtension(imageType) {
  switch (imageType) {
    case "image/png":
      return ".png";
    case "image/bmp":
      return ".bmp";
    case "image/gif":
      return ".gif";
    case "image/jpeg":
      return ".jpg";
    case "image/jpg":
      return ".jpg";
    case "image/svg+xml":
      return ".svg";
    case "image/tiff":
      return ".tiff";
    case "image/webp":
      return ".webp";

    default:
      return ".jpg";
  }
}

export async function loadImage(image) {
  return new Promise((resolve, reject) => {
    const imageObj = new Image();
    imageObj.onload = function () {
      resolve(imageObj);
    };
    imageObj.src = image;
  });
}

export async function writeTextOnImage(image, userId, coords) {
  let position;

  // Paso 1: Obtención de coordenadas
  if (coords) {
    position = coords;
  } else {
    logger.debug("writeTextOnImage: Obteniendo ubicación");
    position = await getCurrentPosition();
    logger.debug(`writeTextOnImage: Ubicación obtenida ${position}`);
  }

  // Paso 2: Escribir datos en la imagen
  const texts = [
    userId,
    // Texto de coordenadas actuales
    `${position.coords.latitude}, ${position.coords.longitude}`, // Texto de fecha actual
    `${new Date().toLocaleDateString("es-MX", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      minute: "numeric",
      hour: "numeric",
    })}`,
  ];

  try {
    var canvas = document.createElement("canvas");
    const heightAtt = document.createAttribute("height");
    const widthAtt = document.createAttribute("width");
    const loadedImage = await loadImage(image);
    const context = canvas.getContext("2d", { alpha: false });
    const textPosition = { x: 20, y: 30 };
    const height = Math.abs(loadedImage.height);
    const width = Math.abs(loadedImage.width);
    heightAtt.value = height;
    widthAtt.value = width;

    canvas.setAttributeNode(heightAtt);
    canvas.setAttributeNode(widthAtt);

    await wait(500);

    context.drawImage(loadedImage, 0, 0, width, height);
    context.font = "15px Arial";
    context.fillStyle = "aqua";
    context.strokeStyle = "black";

    let increment = 20;
    texts.forEach((text) => {
      context.fillText(text, textPosition.x, textPosition.y);
      textPosition.y += increment;
    });
    context.restore();

    return { dataUrl: canvas.toDataURL() };
  } catch (error) {
    logger.error("writeTextOnImage: ", error);
    captureException(error, "writeTextOnImage");
    throw error;
  }
}

export function mapPictureErrorMessage(error) {
  if (error instanceof CameraCanceledException || error instanceof GaleryCanceledException) {
    logger.debug("Toma de fotografía cancelada por el usuario.");
    return "";
  } else if (error instanceof CameraPermissionDeniedException) {
    return "Uso de cámara no autorizado.";
  } else if (error instanceof GalleryAccessPermissionDeniedException) {
    return "Acceso a la galería de fotos no autorizado.";
  } else if (error instanceof GeolocationPermissionDeniedException) {
    return "Uso de geolocalización no autorizado. Por favor, permita el uso de la geolocalización.";
  } else if (error instanceof TimeoutExpiredException) {
    return "Se agotó el tiempo para obtener la ubicación. Verifique que la geolocalización esté activa.";
  } else {
    return "Error inesperado al capturar la imagen.";
  }
}

export function getFileExtension(filename) {
  const segments = filename.split(".");
  let extension;

  if (segments.length > 1) {
    extension = segments[segments.length - 1];
  }

  return extension;
}

export default {
  toBlobObject,
  getExtension,
  toImageObject,
  loadImage,
  writeTextOnImage,
  mapPictureErrorMessage,
  getFileExtension,
  base64ToBlob,
  blobToDataURL,
  transformImageBySource,
};
