import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import useBooleanFlag from "hooks/useBooleanFlag";
import WordConfirmationDialog from "components/common/WordConfirmationDialog";
import Button from "components/custom/Button";
import useLoadingStatus from "hooks/useLoadingStatus";
import * as graphql from "../helpers/graphql";

function GeneralSection({ classes, onIntegrationEnabledChange }) {
  const [confirmDialogOpen, openConfirmDialog, closeConfirmDialog] = useBooleanFlag();
  const [successDialogOpen, openSuccessDialog, closeSuccessDialog] = useBooleanFlag();
  const [savingSetting, _saveSetting] = useLoadingStatus(saveSettings);
  const [loadingSetting, _loadSettings] = useLoadingStatus(loadSettings);
  const [integrationEnabled, setIntegrationEnabled] = useState(false);
  const [integrationEnabledTmp, setIntegrationEnabledTmp] = useState(false);
  const [succededAction, setSuccededAction] = useState(null);
  const [settingVersion, setSettingVersion] = useState(null);

  useEffect(() => {
    _loadSettings();
  }, []);

  async function loadSettings() {
    const result = await graphql.fetchManageSettingsEnabled();
    const integration_enabled = result.integration_enabled === "true";
    setSettingVersion(result._version);
    setIntegrationEnabled(integration_enabled);
    onIntegrationEnabledChange(integration_enabled);
  }

  async function saveSettings() {
    const result = await graphql.saveIntegrationSettingsEnabled({
      integration_enabled: integrationEnabledTmp.toString(),
      _version: settingVersion,
    });

    setSettingVersion(result._version);
    setSuccededAction(integrationEnabledTmp ? ACTION.ENABLE_INTEGRATION : ACTION.DISABLE_INTEGRATION);
    setIntegrationEnabled(integrationEnabledTmp);
    onIntegrationEnabledChange(integrationEnabledTmp);
    closeConfirmDialog();
    openSuccessDialog();
  }

  function handleCheckedChange(event) {
    const { checked } = event.target;
    setIntegrationEnabledTmp(checked);
    openConfirmDialog();
  }

  return (
    <>
      <AccordionDetails className={classes.accordionDetails}>
        <Typography variant="body2" paragraph>
          La integración con Manage Engine permite reflejar los tickets creados en Indika360 FSM en su sistema de
          gestión de tickets Manage Engine. Seleccione la casilla para activar las funciones de integración con el
          sistema.
        </Typography>
        <FormGroup row>
          <FormControlLabel
            disabled={loadingSetting}
            control={<Checkbox checked={integrationEnabled} onChange={handleCheckedChange} name="checkedA" />}
            label="Integración activada"
          />
        </FormGroup>
      </AccordionDetails>
      <WordConfirmationDialog
        open={confirmDialogOpen}
        title="Confirmación"
        onCancel={closeConfirmDialog}
        onConfirm={_saveSetting}
        loading={savingSetting}
        confirmationWord="Confirmo"
      >
        <DialogContentText>
          {!integrationEnabled && "Activará las funcionalidades de integración con Manage Engine."}
          {integrationEnabled &&
            "Desactivará las funcionalidades de integración con Manage Engine. Esto puede implicar la interrupción del servicio que tenga configurado actualmente."}
        </DialogContentText>
        <DialogContentText>
          Si desea continuar, ingrese la palabra{" "}
          <b>
            <i>Confirmo</i>
          </b>{" "}
          en el campo de texto debajo y de click en el botón &quot;Continuar&quot;.
        </DialogContentText>
      </WordConfirmationDialog>
      <Dialog open={successDialogOpen}>
        <DialogTitle>
          {succededAction === ACTION.DISABLE_INTEGRATION && "Integración Desactivada"}
          {succededAction === ACTION.ENABLE_INTEGRATION && "Integración Activada"}
        </DialogTitle>
        <DialogContent dividers>
          {succededAction === ACTION.DISABLE_INTEGRATION && (
            <>
              <DialogContentText>
                Se ha desactivado la integración con Manage Engine. Los nuevos tickets creados en Indika FSM no se
                reflejarán en su sistema de Manage Engine.
              </DialogContentText>
            </>
          )}
          {succededAction === ACTION.ENABLE_INTEGRATION && (
            <DialogContentText> Se ha activado la integración con Manage Engine. </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button color="default" onClick={closeSuccessDialog}>
            CERRAR
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

GeneralSection.propTypes = {
  onIntegrationEnabledChange: PropTypes.func,
  classes: PropTypes.shape(),
};

GeneralSection.defaultProps = {
  onIntegrationEnabledChange: () => {},
};

const ACTION = {
  DISABLE_INTEGRATION: "DISABLE_INTEGRATION",
  ENABLE_INTEGRATION: "ENABLE_INTEGRATION",
};

export default GeneralSection;
