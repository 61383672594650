/* eslint-disable react/display-name */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

//hooks
import useOnlineStatus from "@rehooks/online-status";
import useLoadingStatus from "hooks/useLoadingStatus";
import useAPIHelpers from "components/Timer/helpers/useApiTimerHelpers";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";

//material ui
import {
  useTheme,
  makeStyles,
  useMediaQuery,
  Dialog,
  DialogTitle,
  Divider,
  Typography,
  Button,
  Chip,
  Box,
  Grid,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

//components
import AttachmentsDialog from "components/common/dialog/AttachmentsDialog";
import ConfirmationDialog from "components/ConfirmationDialog";
import PDFReportButton from "components/general/Buttons/PDFReportButton";
import Counter from "components/Timer/Counter";
import TimerToggle from "components/Timer/TimerToggle";
import TimerDialog from "components/Timer/TimerDialog";
import TimerAlert from "components/Timer/TimerAlert";
import SignatureDialog from "components/common/SignatureDialog";
import TicketActivityTrackingDialog from "../../TicketActivityTrackingDialog";
import Section from "components/Section";
import DetailTitle from "components/DetailTitle";
import DataLabel from "components/DataLabel";

//handlers / helpers / utils
import { formatCount, getCounterParams, getInitialCount } from "components/Timer/helpers";

//constants
import { COUNTER_STATUS } from "components/Timer/constants";
import { CONFIG_RESTART_TIMER, CONFIG_STOP_TIMER, CONFIG_SUSPENSION_TIMER } from "components/Timer/constants";
import { SUPPORT_ENGINEERS } from "constant/roles";
import { TASK_STATUS_MAP } from "constant/task/status";

import { TaskStatus, TimerEventType } from "models";

// import { Logger } from "aws-amplify";
// const logger = new Logger("TicketsActivityDetailGeneralInfo");

export default function TicketsActivityDetailGeneralInfo(props) {
  const {
    cleanedTicketID,
    ticketData,
    taskData,
    setTicketData,
    setTaskData,
    assets,
    setAssets,
    taskAttachments,
    setTaskAttachments,
    fetchAndSetTaskData,
    isFetching,
    userType,
  } = props;

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();

  const [observationsModalOpen, setObservationsModalOpen] = useState(false);

  const [openInspectionDialog, setOpenInspectionDialog] = useState(false);

  const [openTrackingDialog, setOpenTrackingDialog] = useState(false);
  const [isEvidenceDialogOpen, setIsEvidenceDialogOpen] = useState(false);
  const [isEvidenceDialogWithSections, setIsEvidenceDialogWithSections] = useState(false);
  const [showSignatureDialog, setShowSignatureDialog] = useState(false);
  const [activeAttachments, setActiveAttachments] = useState([]);

  const [isTimerDialogOpen, setIsTimerDialogOpen] = useState(false);
  const [configTimerDialog, setConfigTimerDialog] = useState();
  const [stopping, _handleStopTimer] = useLoadingStatus(handleStopTimer);
  const [resumming, _handleResumeTimer] = useLoadingStatus(handleResumeTimer);
  const [editing, _handleUpdateTimerEvent] = useLoadingStatus(handleUpdateTimerEvent);

  const isOnline = useOnlineStatus();
  const APIHelpers = useAPIHelpers(isOnline);
  const loading = stopping || resumming || editing || isFetching || !Object.keys(taskData).length;
  const { initialCount, status: initialTimerStatus, fromStartToNow, totalRangeTime } = getCounterParams(taskData);
  const [timerStatus, setTimerStatus] = useState(initialTimerStatus);
  const ticketID = ticketData.id;
  const SignatureS3PrefixKey = `ticket/${ticketID}`;
  const serializeCount = (count, mode) => {
    return formatCount(count, timerStatus, taskData?.status, mode, totalRangeTime);
  };
  const embeddedLastTimerEvent = taskData?.embeddedLastEvent;
  const showTimerAlert =
    embeddedLastTimerEvent?.embeddedCause?.type === TimerEventType.PAUSE && userType === SUPPORT_ENGINEERS;
  let defaultTimerFormValues = configTimerDialog?.defaultFormValues;
  if (configTimerDialog?.id === "EDIT_PAUSE") {
    defaultTimerFormValues = {
      description: embeddedLastTimerEvent?.description || "",
    };
  } else {
    defaultTimerFormValues = {
      description: "",
      eventCauseID: "",
    };
  }
  const { getValues, control, setValue, reset } = useForm({
    defaultValues: defaultTimerFormValues,
  });

  //Modal errors
  const [modalError, setModalError] = useState(false);

  //Get auth user -> use to validate actions
  const authUser = useSelector(({ session }) => session);
  let emailUserTask;
  if (taskData?.userTask) {
    emailUserTask = taskData?.userTask[0]?.user?.email;
  }

  useEffect(() => {
    setTimerStatus(initialTimerStatus);
  }, [initialTimerStatus]);

  function handleObservationsModalVisibility(status) {
    setObservationsModalOpen(status);
  }

  function selectActiveAttachments(attachments, isWithSections = false) {
    setActiveAttachments(attachments);
    setIsEvidenceDialogWithSections(isWithSections);
    setIsEvidenceDialogOpen(true);
  }

  function handleOpenTrackingDialog() {
    if (taskData?.status === TaskStatus.WITH_INSPECTION_NOTES) {
      return setOpenInspectionDialog(true);
    }
    setOpenTrackingDialog(true);
  }

  function onCloseTicketSignatureDialog() {
    setShowSignatureDialog(false);
  }

  function handleOpenInspectionDialog() {
    setOpenTrackingDialog(true);
    setOpenInspectionDialog(false);
  }

  function updateActivityOrTicket(updatedTicket, updatedActivity) {
    if (updatedTicket) {
      setTicketData({ ...ticketData, ...updatedTicket });
    }
    if (updatedActivity) {
      setTaskData({ ...taskData, ...updatedActivity });
    }
  }

  function deleteAttachment(attachmentId) {
    setTaskAttachments((v) => {
      const attachments = v.filter((item) => item.id !== attachmentId);
      return attachments;
    });
  }

  function getTrackingButtonText() {
    if (taskData?.status === TaskStatus.IN_PROGRESS) {
      return "Continuar Actividad";
    } else if (taskData?.status === TaskStatus.WITH_INSPECTION_NOTES) {
      return "Realizar modificaciones";
    } else {
      return "Iniciar actividad";
    }
  }

  async function onSignedTicket(updatedData) {
    const { taskData, ticketData } = updatedData;
    onCloseTicketSignatureDialog();
    return updateActivityOrTicket(ticketData, taskData);
  }

  async function onAddInspectionNoteSuccess(updatedData) {
    const { taskData: updatedTaskData, ticketData, newInspectionNote } = updatedData;
    onCloseTicketSignatureDialog();
    setTaskData({ ...taskData, ...updatedTaskData, notes: [newInspectionNote, ...taskData.notes] });
    return updateActivityOrTicket(ticketData);
  }

  function addAttachments(attachment) {
    setTaskAttachments((v) => {
      let newAttachments;
      if (Array.isArray(attachment)) {
        newAttachments = [...attachment, ...v];
      } else {
        newAttachments = [attachment, ...v];
      }
      return newAttachments;
    });
  }

  const handlerOpenModalTimer = ({ isEditing = false } = {}) => {
    let configTimerDialog = null;
    if (timerStatus === COUNTER_STATUS.PAUSED) {
      configTimerDialog = CONFIG_RESTART_TIMER;
    }
    if (timerStatus === COUNTER_STATUS.RUNNING) {
      configTimerDialog = CONFIG_STOP_TIMER;
    }
    if (isEditing) {
      configTimerDialog = CONFIG_SUSPENSION_TIMER;
    }

    setIsTimerDialogOpen(true);
    setConfigTimerDialog(configTimerDialog);
  };

  function handleCloseModalTimer() {
    setIsTimerDialogOpen(false);
    setConfigTimerDialog(null);
  }

  async function handleStopTimer(count) {
    let success = false;
    const formValues = getValues();
    console.log("Counter: Realizando operación de pausado", { count, formValues });
    try {
      const { success: successQuery, data } = await APIHelpers.stopTimerEventHelper({
        taskId: taskData.id,
        embeddedEvents: taskData.embeddedEvents || [],
        newValues: { eventCauseID: formValues.eventCauseID, description: formValues.descriptionS },
        effectiveTime: count,
        lastEventId: taskData.lastEvent?.id || null,
      });

      setTaskData({ ...taskData, ...data.updatedTask });
      setModalError(false);
      success = successQuery;
      handleCloseModalTimer();
      reset({
        descriptionS: "",
        eventCauseID: "",
      });
    } catch (error) {
      //setTimerStatus(COUNTER_STATUS.RUNNING);
      success = false;
    }
    return success;
  }

  async function handleResumeTimer(count) {
    let success = false;
    const formValues = getValues();
    console.log("Counter: Realizando operación de reanudación", { count, formValues });
    try {
      const { success: successQuery, data } = await APIHelpers.restartTimerEventHelper({
        taskId: taskData.id,
        embeddedEvents: taskData.embeddedEvents || [],
        newValues: { eventCauseID: "resume-task", description: formValues.descriptionR },
        effectiveTime: count,
        lastEventId: taskData.lastEvent?.id || null,
      });
      setTaskData({ ...taskData, ...data.updatedTask });
      setModalError(false);
      success = successQuery;
      handleCloseModalTimer();
      reset({
        descriptionR: "",
        eventCauseID: "",
      });
    } catch (error) {
      // setTimerStatus(COUNTER_STATUS.PAUSED);
      success = false;
    }

    return success;
  }

  async function handleUpdateTimerEvent() {
    let success = false;

    try {
      const formValues = getValues();
      const { success: successQuery, data } = await APIHelpers.updateTimerEvent({
        taskId: taskData.id,
        newValues: formValues,
        lastEventId: taskData.lastEvent?.id || null,
        embeddedLastEvent: embeddedLastTimerEvent,
        embeddedEvents: taskData.embeddedEvents || [],
      });
      // update frontend task state
      if (successQuery) {
        setTaskData({
          ...taskData,
          ...data.updatedValues,
        });
        success = successQuery;
        handleCloseModalTimer();
      }
    } catch (error) {
      console.error("handleUpdateTimerEvent", error);
      success = false;
    }

    return success;
  }

  const handlerDialogConfirmation = async () => {
    if (configTimerDialog === CONFIG_SUSPENSION_TIMER) {
      //es una edición
      const formValues = getValues();
      if (formValues?.description === undefined || formValues?.description.trim() === "") {
        setModalError(true);
      } else {
        return _handleUpdateTimerEvent();
      }
    }
    if (timerStatus === COUNTER_STATUS.RUNNING) {
      //stop task
      const formValues = getValues();

      if (
        formValues?.descriptionS === undefined ||
        formValues?.descriptionS.trim() === "" ||
        formValues?.eventCauseID === "" ||
        formValues?.eventCauseID === null
      ) {
        setModalError(true);
      } else {
        setTimerStatus(COUNTER_STATUS.PAUSED);
      }
    }
    if (timerStatus === COUNTER_STATUS.PAUSED) {
      //restart task
      const formValues = getValues();
      if (formValues?.descriptionR === undefined || formValues?.descriptionR.trim() === "") {
        setModalError(true);
      } else {
        setTimerStatus(COUNTER_STATUS.RUNNING);
      }
    }
  };

  const getEffectiveTime = () => {
    const efectiveTime = getInitialCount(
      taskData.effectiveTime,
      timerStatus,
      taskData.status,
      taskData.startedAt,
      taskData.endedAt,
      embeddedLastTimerEvent
    );
    return efectiveTime;
  };

  return (
    <>
      {showTimerAlert && (
        <TimerAlert userType={userType} onEdit={() => handlerOpenModalTimer({ isEditing: true })}>
          {embeddedLastTimerEvent?.description || "Actividad en pausa"}
        </TimerAlert>
      )}
      <Section>
        <Grid container className={classes.detailsGrid}>
          <Grid item xs={12}>
            <Grid container alignItems="center">
              <Grid item xs sm={6} md={4} lg={3}>
                <Box>
                  <DetailTitle title={`Soporte ${cleanedTicketID}`} to="/sitio/actividades" />
                </Box>
              </Grid>
              <Grid item xs>
                {!isFetching && (
                  <Box>
                    <Chip label={TASK_STATUS_MAP[taskData?.status] || "Sin status"} color="primary" />
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {taskData?.status === "WITH_INSPECTION_NOTES" && taskData?.notes?.length > 0 && (
              <Box mb={2} mt={2}>
                <Alert severity={COLOR_MAPPING.WITH_INSPECTION_NOTES}>
                  {/* <div> */}
                  <p className={classes.noMarginElement}>
                    Tu actividad de Soporte tiene observaciones -
                    <span
                      onClick={() => handleObservationsModalVisibility(true)}
                      className={classes.cursorPointerElement}
                    >
                      <strong> Ver Observaciones </strong>
                    </span>
                  </p>
                  {/* </div> */}
                </Alert>
              </Box>
            )}
          </Grid>

          <Grid item xs={12} className={classes.detailsContent}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <div>
                  <DataLabel
                    label="Fecha de inicio"
                    data={`${taskData?.startDate} ${taskData?.startTime}`}
                    loading={isFetching}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div>
                  <DataLabel
                    label="Fecha de finalización"
                    data={`${taskData?.endDate} ${taskData?.endTime}`}
                    loading={isFetching}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div>
                  <DataLabel label="Lugar de atención" data={taskData?.premises?.name} loading={isFetching} />
                </div>
              </Grid>

              <Grid item xs={12} md={4}>
                <div>
                  <DataLabel label="Zona de atención" data={taskData?.buildingLocation?.name} loading={isFetching} />
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div>
                  <DataLabel
                    label="Técnico"
                    data={
                      taskData?.userTask
                        ? `${taskData?.userTask[0]?.user?.name} ${taskData?.userTask[0]?.user?.middlename || ""} ${taskData?.userTask[0]?.user?.lastnameP || ""
                        } ${taskData?.userTask[0]?.user?.lastnameM || ""} ${taskData?.userTask[0]?.user?.email || ""}`
                        : ""
                    }
                    loading={isFetching}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div>
                  <DataLabel label="Tipo de soporte" data={taskData?.taskType?.name} loading={isFetching} />
                </div>
              </Grid>

              <Grid item xs={12} md={4}>
                <div>
                  <DataLabel
                    label="Cliente"
                    data={taskData?.project?.company ? taskData?.project?.company?.name : "Sin asignación"}
                    loading={isFetching}
                  />
                </div>
              </Grid>

              <Grid item xs={12} md={4}>
                <div>
                  <DataLabel
                    label="Proyecto"
                    data={
                      taskData?.project ? `${taskData?.project?.name} (${taskData?.project?.code})` : "Sin asignación"
                    }
                    loading={isFetching}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div>
                  <DataLabel
                    label="Tiempo Transcurrido"
                    data={
                      <TimerToggle
                        status={timerStatus}
                        onButtonClick={handlerOpenModalTimer}
                        emailUserTask={emailUserTask}
                        CountdownComponent={
                          <Counter
                            status={timerStatus}
                            initialCount={initialCount}
                            totalRangeTime={totalRangeTime}
                            fromStartToNow={fromStartToNow}
                            onStopTimer={_handleStopTimer}
                            onResumeTimer={_handleResumeTimer}
                            serializeCount={serializeCount}
                          />
                        }
                      />
                    }
                    loading={isFetching}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={8}>
                <div>
                  <Typography variant="subtitle2" component="span" color="textSecondary">
                    <b>Comentarios</b>
                  </Typography>
                  {taskData?.remarks?.length > 0 ? (
                    <>
                      {taskData.remarks.map((remark) => {
                        let r = remark.remark.includes("<***>") ? remark.remark.split("<***>")[1] : remark.remark;
                        if (r) {
                          return (
                            <div key={remark.id}>
                              <p>
                                <strong>{REMARK_TYPES[remark.remark.split("<***>")[0]]}</strong>
                              </p>
                              <p>{r}</p>
                            </div>
                          );
                        }
                        return null;
                      })}
                    </>
                  ) : (
                    <Typography variant="body2">Sin información</Typography>
                  )}
                </div>
              </Grid>

              <Grid container item justify="flex-end" direction={isMobileScreen ? "column" : "row"} xs={12}>
                {taskAttachments?.length > 0 && (
                  <Box ml={2} mb={2}>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="large"
                      onClick={() => selectActiveAttachments(taskAttachments, true)}
                      fullWidth={isMobileScreen}
                    >
                      Ver evidencias
                    </Button>
                  </Box>
                )}
                {(taskData?.status === TaskStatus.SCHEDULED ||
                  taskData?.status === TaskStatus.IN_PROGRESS ||
                  taskData?.status === TaskStatus.WITH_INSPECTION_NOTES) && (
                    <>
                      <Box ml={2} flex={isMobileScreen ? "1" : ""}>
                        <Button
                          variant="outlined"
                          color="primary"
                          size="large"
                          fullWidth={isMobileScreen}
                          href={process.env.REACT_APP_GUIDES_REPOSITORY_URL}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Repositorio de manuales
                        </Button>
                      </Box>
                      {authUser?.username === taskData?.userTask[0]?.user?.email && (
                        <Box ml={2}>
                          <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={handleOpenTrackingDialog}
                            className={classes.validationBtn}
                            fullWidth={isMobileScreen}
                          >
                            {getTrackingButtonText()}
                          </Button>
                        </Box>
                      )}
                    </>
                  )}
                {(taskData?.status === TaskStatus.COMPLETED ||
                  taskData?.status === TaskStatus.REVIEWED ||
                  taskData?.status === TaskStatus.FINALIZED) && (
                    <Box ml={2} flex={isMobileScreen ? 1 : ""}>
                      <PDFReportButton
                        variant="outlined"
                        buttonType={"button"}
                        by={"task"}
                        elementId={taskData.id}
                        elementStatus={taskData.status}
                        reportType={"support"}
                        size="large"
                      />
                    </Box>
                  )}
                {taskData?.status === TaskStatus.COMPLETED && ticketData.supportType !== "REMOTE" && (
                  <Box ml={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={() => setShowSignatureDialog(true)}
                      className={classes.validationBtn}
                      fullWidth={isMobileScreen}
                    >
                      Validar Soporte
                    </Button>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Section>

      <AttachmentsDialog
        open={isEvidenceDialogOpen}
        attachments={activeAttachments}
        withSections={isEvidenceDialogWithSections}
        fullWidth={true}
        onClose={() => setIsEvidenceDialogOpen(false)}
        maxWidth={"xl"}
      />

      <SignatureDialog
        isOpen={showSignatureDialog}
        onClose={() => onCloseTicketSignatureDialog()}
        ticketId={ticketData.id}
        onSigned={onSignedTicket}
        onAddInspectionNote={onAddInspectionNoteSuccess}
        taskId={taskData?.id}
        S3PrefixKey={SignatureS3PrefixKey}
        taskStatusToUpdate={TaskStatus.FINALIZED}
      />

      <TicketActivityTrackingDialog
        isOpen={openTrackingDialog}
        ticketData={ticketData}
        taskData={taskData}
        assets={assets}
        setAssets={setAssets}
        taskAttachments={taskAttachments}
        onUpdate={updateActivityOrTicket}
        onClose={() => setOpenTrackingDialog(false)}
        onAddOptimisticAttachments={addAttachments}
        onDeleteOptimisticAttachment={deleteAttachment}
        getEffectiveTime={getEffectiveTime}
        reloadData={fetchAndSetTaskData}
      />

      <ConfirmationDialog
        open={openInspectionDialog}
        title="Confirmación"
        onConfirm={handleOpenInspectionDialog}
        onCancel={() => setOpenInspectionDialog(false)}
      >
        Estás a punto de modificar los datos de la atención de soporte que se capturaron previamente.
        <br />
        <br />
        ¿Deseas continuar?
      </ConfirmationDialog>

      <Dialog
        maxWidth="md"
        fullWidth
        onClose={() => handleObservationsModalVisibility(false)}
        aria-labelledby="simple-dialog-title"
        open={observationsModalOpen}
      >
        <DialogTitle id="simple-dialog-title">Observaciones</DialogTitle>
        <Divider variant="middle" />
        <Box p={2} ml={2} mr={2}>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <DataLabel
                label="SUPERVISOR"
                data={`${taskData?.supervisor?.name || ""} ${taskData?.supervisor?.lastnameP || ""} ${taskData?.supervisor?.lastnameM || ""
                  } (${taskData?.supervisor?.email || ""} , ${taskData?.supervisor?.phone || ""})`}
                loading={isFetching}
              />
            </Grid>
            <Grid item xs={12}>
              <Box display="block" mt={1}>
                <strong>Comentarios</strong>
              </Box>
            </Grid>
            {taskData?.notes &&
              taskData?.notes?.map((observation) => (
                <Grid key={observation.id} item xs={12}>
                  {observation.comments}
                </Grid>
              ))}
          </Grid>
          <Box textAlign="right" mt={2}>
            <Button variant="contained" color="primary" onClick={() => handleObservationsModalVisibility(false)}>
              Continuar
            </Button>
          </Box>
        </Box>
      </Dialog>

      {/* TIMER DIALOG */}
      <TimerDialog
        open={isTimerDialogOpen}
        onClose={() => setIsTimerDialogOpen(false)}
        onConfirm={handlerDialogConfirmation}
        fullWidth={false}
        maxWidth={false}
        configTimerDialog={configTimerDialog}
        loading={loading}
        formProps={{ control, defaultValues: defaultTimerFormValues, setValue }}
        error={modalError}
        errorMessage={TIMER_LOG_ERROR_MESSAGE}
      ></TimerDialog>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  detailsGrid: {
    padding: theme.spacing(2),
    // marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  detailsContent: {
    padding: theme.spacing(2),
  },
  validationBtn: {
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: 0,
      right: 0,
      width: "100%",
      "z-index": 99,
    },
  },
  cursorPointerElement: {
    cursor: "pointer",
    textDecoration: "underline",
  },
  noMarginElement: {
    margin: 0,
  },
}));

const TIMER_LOG_ERROR_MESSAGE = "Completa el formulario para continuar";

const COLOR_MAPPING = {
  SCHEDULED: "info",
  IN_PROGRESS: "info",
  COMPLETED: "info",
  WITH_INSPECTION_NOTES: "warning",
  FINALIZED: "info",
};

const REMARK_TYPES = {
  issue: "Problemática",
  analysis: "Análisis",
  solution: "Solución",
  resolutionComment: "Resolución",
};

TicketsActivityDetailGeneralInfo.propTypes = {
  cleanedTicketID: PropTypes.string,
  taskData: PropTypes.object,
  ticketData: PropTypes.object,
  setTicketData: PropTypes.func,
  setTaskData: PropTypes.func,
  assets: PropTypes.array,
  setAssets: PropTypes.func,
  taskAttachments: PropTypes.array,
  setTaskAttachments: PropTypes.func,
  fetchAndSetTaskData: PropTypes.func,
  isFetching: PropTypes.bool,
  userType: PropTypes.string,
};

TicketsActivityDetailGeneralInfo.defaultProps = {
  cleanedTicketID: "",
  taskData: {},
  ticketData: {},
  setTicketData: () => { },
  setTaskData: () => { },
  assets: [],
  setAssets: () => { },
  taskAttachments: [],
  setTaskAttachments: () => { },
  fetchAndSetTaskData: async () => { },
  isFetching: false,
  userType: "",
};
