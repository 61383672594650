export const listMaterials = /* GraphQL */ `
  query ListMaterials($filter: ModelMaterialFilterInput, $limit: Int, $nextToken: String) {
    listMaterials(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        code
        description
        category {
          id
          name
          abbreviation
          description
          createdAt
          updatedAt
        }
        uom {
          id
          name
          abbreviation
          description
          createdAt
          updatedAt
        }
        manufacturer
        trackable
        enabled
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        deleted
      }
      nextToken
    }
  }
`;
