import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import ActionTypes from "redux/action";

function useAppVersion() {
  const version = useSelector((storage) => storage.app.version);
  const dispatch = useDispatch();

  const updateVersion = useCallback((version) => {
    dispatch({ type: ActionTypes.app.UPDATE_APP_VERSION, version });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const result = useMemo(
    () => ({
      version,
      updateVersion,
    }),
    [version, updateVersion]
  );

  return result;
}

export default useAppVersion;
