import React from "react";
import PropTypes from "prop-types";

import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";

function DescriptionPanel(props) {
  return (
    <Paper elevation={0}>
      <Box px={6} py={3}>
        {props.description}
      </Box>
    </Paper>
  );
}

DescriptionPanel.propTypes = {
  description: PropTypes.string,
};

export default DescriptionPanel;
