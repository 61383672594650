import dayjs from "dayjs";

export function transformToISO_8601(datetimeLocal) {
  return dayjs(datetimeLocal).toISOString();
}

export function transformAWSDate(awsDate) {
  return dayjs(awsDate).format("DD/MM/YYYY HH:mm");
}

export function transformToDate(date, format = "DD/MM/YYYY") {
  return dayjs(date).format(format);
}

export function parseDate(date) {
  return new Date(date).toLocaleString("es-mx", {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
  });
}

export function toLocalString(date) {
  return date.toLocaleString("es-mx", {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
  });
}
export function toLocaleString(date = new Date()) {
  if (date === null) date = new Date();
  return date.toLocaleString("es-mx", {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
  });
}

/**
 * Check if a date string in the form YYMMDD is valid
 * @param {*} dateString
 */
export function validateGS1Date(dateString) {
  let validDateFormat = /^\d{2}(0?[1-9]|1[012])(0?[1-9]|[12][0-9]|3[01])$/;

  if (!validDateFormat.test(dateString)) return false;

  try {
    const { year, month, day } = getGS1DateComponents(dateString);

    if (year < 19) return false;
    if (month < 1 || month > 12) return false;
    if (month === 2 && (day < 1 || day > 29)) return false;
    if (day < 1 || day > 31) return false;
    return true;
  } catch (error) {
    return false;
  }
}

/**
 * Transforms a GS1 date (YYMMDD) to a JS Date
 */
export function fromGS1DateToDate(dateString) {
  try {
    const { year, month, day } = getGS1DateComponents(dateString);
    const date = new Date(2000 + year, month - 1, day);
    return date;
  } catch (error) {
    return null;
  }
}

function getGS1DateComponents(dateString) {
  const yearString = dateString.substring(0, 2);
  const monthString = dateString.substring(2, 4);
  const dayString = dateString.substring(4);

  const year = Number(yearString);
  const month = Number(monthString);
  const day = Number(dayString);

  return {
    year,
    month,
    day,
    yearString,
    monthString,
    dayString,
  };
}

export default {
  parseDate,
  toLocalString,
  toLocaleString,
  validateGS1Date,
  fromGS1DateToDate,
};
