/**
 * Componente encargado de simular la pantalla de carga de una vista de detalle.
 */

import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Box, useTheme, useMediaQuery } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

function generateArrayByNumber(number = 0) {
  let array = [];
  if (!number || isNaN(number)) {
    return array;
  }

  while (number > array.length) {
    array.push(array.length + 1);
  }
  return array;
}

export default function DetailViewLoadingSkeleton({ NUMBER_OF_SKELETON_ROWS = 8 }) {
  const theme = useTheme();
  const isDownXS = useMediaQuery(theme.breakpoints.down("xs"));
  const SKELETON_ROWS = useMemo(() => generateArrayByNumber(NUMBER_OF_SKELETON_ROWS), [NUMBER_OF_SKELETON_ROWS]);

  return (
    <Box padding="32px">
      <Box display="flex" gridGap="12px" maxWidth="480px">
        <Skeleton variant="circle" height="40px" width="40px" />
        <Skeleton variant="rect" height="58px" width="45%" style={{ borderRadius: "4px" }} />
        <Skeleton variant="rect" height="32px" width="35%" style={{ borderRadius: "16px" }} />
      </Box>

      {SKELETON_ROWS.map((item) => (
        <Box key={item} display="flex" flexDirection="column" gridGap="4px" maxWidth="90%" margin="16px">
          <Skeleton height="24px" width="100%" />
          <Skeleton height="24px" width="100%" />
        </Box>
      ))}

      {isDownXS && (
        <Box width="100%" margin="0 auto" height="42px" position="fixed" right="0" left="0" bottom="0">
          <Skeleton variant="rect" height="42px" animation={false} style={{ backgroundColor: "grey" }} />
        </Box>
      )}
    </Box>
  );
}

DetailViewLoadingSkeleton.propTypes = {
  NUMBER_OF_SKELETON_ROWS: PropTypes.number,
};
