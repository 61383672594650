import { Logger } from "@aws-amplify/core";

const logger = new Logger("util/ticket");

export function parseExternalData(externalDataText) {
  try {
    return JSON.parse(externalDataText);
  } catch (error) {
    logger.error("Ocurrió un error al parsear texto de información externa");
    return null;
  }
}

export default {
  parseExternalData,
};
