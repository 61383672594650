import { REQUEST_STATUS } from "constant/requestStatus";
import * as ActionTypes from "redux/action";

const INITIAL_STATE = {
  rdxBeforePremise: {},
  rdxBeforeCreateStatus: REQUEST_STATUS.INACTIVE,
  rdxCreateStatus: REQUEST_STATUS.INACTIVE,
  rdxUpdateStatus: REQUEST_STATUS.INACTIVE,
};

export default function adminPremisesCreateReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case ActionTypes.PREMISES_CREATE_BEFORE_PENDING:
      return { ...state, rdxBeforeCreateStatus: REQUEST_STATUS.PENDING };
    case ActionTypes.PREMISES_CREATE_PENDING:
      return { ...state, rdxCreateStatus: REQUEST_STATUS.PENDING };
    case ActionTypes.PREMISES_UPDATE_ITEM_PENDING:
      return { ...state, rdxUpdateStatus: REQUEST_STATUS.PENDING };
    case ActionTypes.PREMISES_CREATE_BEFORE_SUCCESS:
      const beforePremiseResult = action.payload;
      return {
        ...state,
        rdxBeforeCreateStatus: REQUEST_STATUS.SUCCESSFUL,
        rdxBeforePremise: beforePremiseResult,
      };
    case ActionTypes.PREMISES_CREATE_SUCCESS:
      return { ...state, rdxCreateStatus: REQUEST_STATUS.SUCCESSFUL };
    case ActionTypes.PREMISES_UPDATE_ITEM_SUCCESS:
      return { ...state, rdxUpdateStatus: REQUEST_STATUS.SUCCESSFUL };
    case ActionTypes.PREMISES_CREATE_BEFORE_ERROR:
      return { ...state, rdxCreateStatus: REQUEST_STATUS.FAILED };
    case ActionTypes.PREMISES_UPDATE_ITEM_ERROR: 
      return { ...state, rdxUpdateStatus: REQUEST_STATUS.FAILED };
    case ActionTypes.PREMISES_CREATE_BEFORE_CLEAR:
      return { ...state, rdxBeforeCreateStatus: REQUEST_STATUS.INACTIVE };
    case ActionTypes.PREMISES_CREATE_ERROR:
      return { ...state, rdxCreateStatus: REQUEST_STATUS.FAILED };
    case ActionTypes.PREMISES_CREATE_CLEAR:
      return INITIAL_STATE;

    default:
      return state;
  }
}
