import React from "react";

import { validateExistentAssetsDifferences } from "./lib";
import { handleGetName, handleElementId, handleGetUOMname, handleGetUomId, handleGetMaterialQuantity } from "util/text";
import compareTwoArraysValues from "util/compareTwoArraysValues";
import { validateQRScanned } from "./lib";
import { filterDeletedItems } from "util/lists";

const HandleMaintenanceValidation = () => {
  function getMaterialsAndAssetsChanges({
    assetsToValidate = [],
    materialsToValidate = [],
    assetsToEvaluate = [],
    materialsToEvaluate = [],
    getAssetsPreviouslyAdded = true,
    getAssetsPreviouslyDeleted = true,
    getMaterialsPreviouslyAdded = true,
    getMaterialsPreviouslyDeleted = true,
    addingElements = false,
  } = {}) {
    // console.log("===getMaterialsAndAssetsChanges: ENTRANDO A LA VALIDACIÓN====", {
    //   assetsToValidate,
    //   materialsToValidate,
    //   assetsToEvaluate,
    //   materialsToEvaluate,
    //   getAssetsPreviouslyAdded,
    //   getAssetsPreviouslyDeleted,
    //   getMaterialsPreviouslyAdded,
    //   getMaterialsPreviouslyDeleted,
    //   addingElements,
    // });
    let resultObject = {
      assetsPreviouslyAdded: null,
      assetsPreviouslyDeleted: null,
      materialsPreviouslyAdded: null,
      materialsPreviouslyDeleted: null,
      showMessageDialog: false,
      content: null,
    };
    const withDefaultAssetsToEvaluate = assetsToEvaluate && assetsToEvaluate.length;
    const withDefaultMaterialsToEvaluate = materialsToEvaluate && materialsToEvaluate.length;
    let currentAssetsToEvaluate = withDefaultAssetsToEvaluate ? assetsToEvaluate : null;
    let currentMaterialsToEvaluate = withDefaultMaterialsToEvaluate ? materialsToEvaluate : null;

    if (!currentAssetsToEvaluate && !currentMaterialsToEvaluate) {
      return resultObject;
    }

    // console.log("===getMaterialsAndAssetsChanges materiales y activos en DB====", {
    //   currentAssetsToEvaluate,
    //   currentMaterialsToEvaluate,
    // });

    let validateAssetsResult;
    let validateMaterialsResult;

    validateAssetsResult = validateExistentAssetsDifferences({
      elementsToCompare: assetsToValidate,
      currentElements: currentAssetsToEvaluate,
      validateElementsToAdd: false,
    });

    validateMaterialsResult = validateExistentAssetsDifferences({
      elementsToCompare: materialsToValidate,
      currentElements: currentMaterialsToEvaluate,
      validateElementsToAdd: false,
      isMaterialsFlow: true,
    });
    // console.log("===getMaterialsAndAssetsChanges: diferencias de activos====", {
    //   validateAssetsResult,
    //   validateMaterialsResult,
    // });
    const assetsPreviouslyAdded = validateAssetsResult?.elementsPreviouslyAdded;
    const assetsPreviouslyDeleted = validateAssetsResult?.elementsPreviouslyDeleted;
    const materialsPreviouslyAdded = validateMaterialsResult?.elementsPreviouslyAdded;
    const materialsPreviouslyDeleted = validateMaterialsResult?.elementsPreviouslyDeleted;

    const showMessageDialog =
      assetsPreviouslyAdded?.length ||
      assetsPreviouslyDeleted?.length ||
      materialsPreviouslyAdded?.length ||
      materialsPreviouslyDeleted?.length;

    resultObject.assetsPreviouslyAdded = assetsPreviouslyAdded;
    resultObject.assetsPreviouslyDeleted = assetsPreviouslyDeleted;
    resultObject.materialsPreviouslyAdded = materialsPreviouslyAdded;
    resultObject.materialsPreviouslyDeleted = materialsPreviouslyDeleted;
    resultObject.showMessageDialog = Boolean(showMessageDialog);

    if (!showMessageDialog) {
      resultObject.content = [];
      // console.log("getMaterialsAndAssetsChanges: FINALIZANDO FUNCIÓN ABRIENDO MODAL DE MENSAJE", resultObject);
      return resultObject;
    }

    let content = [];

    //activos
    if (assetsPreviouslyDeleted?.length && getAssetsPreviouslyDeleted) {
      content.push("Los siguientes activos han sido eliminados:");
      assetsPreviouslyDeleted.forEach((asset) => {
        const assetName = handleGetName(asset);
        const nameElement = <li>{assetName}.</li>;
        content.push(nameElement);
      });
    }
    if (assetsPreviouslyAdded?.length && getAssetsPreviouslyAdded) {
      content.push("Los siguientes activos han sido añadidos:");
      assetsPreviouslyAdded.forEach((asset) => {
        const assetName = handleGetName(asset);
        const nameElement = <li>{assetName}.</li>;
        content.push(nameElement);
      });
    }

    //materiales
    if (materialsPreviouslyDeleted?.length && getMaterialsPreviouslyDeleted) {
      content.push("Los siguientes materiales han sido eliminados:");
      materialsPreviouslyDeleted.forEach((asset) => {
        const assetName = handleGetName(asset);
        const nameElement = <li>{assetName}.</li>;
        content.push(nameElement);
      });
    }
    if (materialsPreviouslyAdded?.length && getMaterialsPreviouslyAdded) {
      content.push("Los siguientes materiales han sido añadidos:");
      materialsPreviouslyAdded.forEach((asset) => {
        const assetName = handleGetName(asset);
        const nameElement = <li>{assetName}.</li>;
        content.push(nameElement);
      });
    }

    // console.log("getMaterialsAndAssetsChanges: FINALIZANDO FUNCIÓN ABRIENDO MODAL DE MENSAJE", resultObject);
    resultObject.content = content;
    return resultObject;
  }

  function getElementsPreviouslyAddedOnAdding({
    elementsToValidate = [],
    elementsToEvaluate = [],
    isMaterialsFlow = false,
  } = {}) {
    // console.log("===getElementsPreviouslyAddedOnAdding: ENTRANDO A LA VALIDACIÓN====", {
    //   elementsToValidate,
    //   elementsToEvaluate,
    //   isMaterialsFlow,
    // });
    const elementWordPlural = isMaterialsFlow ? "materiales" : "activos";

    let resultObject = {
      validCurrentElements: elementsToEvaluate,
      elementsPreviouslyAdded: [],
      elementsPreviouslyDeleted: [],
      elementsToAdd: [],
      content: null,
      showMessageDialog: false,
    };
    // console.log("===getElementsPreviouslyAddedOnAdding: ACTIVOS ACTUALES EN DB====", { elementsToEvaluate });
    if (!elementsToEvaluate.length && elementsToValidate?.length) {
      resultObject.elementsToAdd = elementsToValidate;
      resultObject.content = [];
      return resultObject;
    }

    const { elementsPreviouslyAdded, elementsToAdd, elementsPreviouslyDeleted } = validateExistentAssetsDifferences({
      elementsToCompare: elementsToValidate,
      currentElements: elementsToEvaluate,
      validateDeleted: false,
      addingElements: true,
      isMaterialsFlow,
    });
    // console.log("===getElementsPreviouslyAddedOnAdding: diferencias de activos====", {
    //   elementsPreviouslyAdded,
    //   elementsToAdd,
    //   elementsPreviouslyDeleted,
    // });

    resultObject.elementsPreviouslyAdded = elementsPreviouslyAdded;
    resultObject.elementsPreviouslyDeleted = elementsPreviouslyDeleted;
    resultObject.elementsToAdd = elementsToAdd;
    resultObject.showMessageDialog = Boolean(elementsPreviouslyAdded?.length);

    if (!resultObject?.showMessageDialog) {
      resultObject.content = [];
      // console.log("Finalizando getElementsPreviouslyAddedOnAdding", resultObject);
      return resultObject;
    }

    let content = [];
    if (elementsPreviouslyAdded?.length) {
      content.push(
        `Los siguientes ${elementWordPlural} ya habían sido previamente añadidos, por lo que no se agregarán:`
      );
      const previoslyAddedContent = buildElementsContent(elementsPreviouslyAdded);
      content = [...content, ...previoslyAddedContent];

      content.push("Los siguientes activos se añadirán al cerrar este cuadro de menstaje:");
      const elementsToAddContent = buildElementsContent(elementsToAdd);

      content = [...content, ...elementsToAddContent];
    }
    // console.log("Finalizando getElementsPreviouslyAddedOnAdding", resultObject);
    resultObject.content = content;
    return resultObject;
  }

  function buildElementsContent(elementPreviouslyAdded, withDefaultValue = true) {
    let content = [];
    elementPreviouslyAdded.forEach((asset) => {
      const assetName = handleGetName(asset);
      const nameElement = <li>{assetName}.</li>;
      content.push(nameElement);
    });
    if (!content?.length && withDefaultValue) content.push(<li>Ninguno</li>);
    return content;
  }

  function getOnMaterialEditContent({ newValue, materialToEdit, column, isMaterialsFlow, elementsToEvalute } = {}) {
    // console.log("===ENTRANDO A validateBeforeEditMaterial===", {
    //   newValue,
    //   materialToEdit,
    //   column,
    //   isMaterialsFlow,
    // });
    let propToUpdate = null;
    let propKeyword = null;
    let propToUpdateCurrentDBValue = null;
    let propToUpdateCurrentFrontendValue = null;
    let propToUpdateNewValue = null;

    if (column.field === "quantity") {
      propToUpdate = "quantity";
      propKeyword = "cantidad";
    }
    if (column.field === "uom.name") {
      propToUpdate = "uom";
      propKeyword = "unidad de medida";
    }

    // console.log("===validateBeforeEditMaterial: materiales actuales en DB", { elementsToEvalute });

    let resultObject = {
      isDeleted: false,
      isEdited: false,
      isSameValue: false,
      materialToEdit,
      materialToUpdateOnDB: null,
      showMessageDialog: false,
      content: null,
      title: "",
    };

    const elementActuallyExist = elementsToEvalute.find(
      (material) => handleElementId(material) === handleElementId(materialToEdit)
    );

    if (elementActuallyExist && Object.keys(elementActuallyExist)?.length) {
      if (propToUpdate === "quantity") {
        propToUpdateCurrentDBValue = elementActuallyExist?.asset?.quantity;
        propToUpdateNewValue = newValue;
        propToUpdateCurrentFrontendValue = materialToEdit?.quantity;
      }
      if (propToUpdate === "uom") {
        propToUpdateCurrentDBValue = handleGetUOMname(elementActuallyExist) || "unassigned";
        propToUpdateNewValue = column?.lookup?.[newValue] || "unassigned";
        propToUpdateCurrentFrontendValue = handleGetUOMname(materialToEdit) || "unassigned";
      }
    }

    // console.log("validateBeforeEditMaterial: before build notification content", {
    //   propToUpdate,
    //   propToUpdateCurrentDBValue,
    //   elementActuallyExist,
    //   propToUpdateNewValue,
    //   propToUpdateCurrentFrontendValue,
    // });

    //Si el element no existe en db entonces ya fue eliminado previamente
    if (!elementActuallyExist) {
      resultObject.isDeleted = true;
      resultObject.showMessageDialog = true;
      resultObject.title = `Actualización de cambios.\n Material ${handleGetName(materialToEdit).toUpperCase()}`;

      const content = [
        "El material que deseas editar fue previamente eliminado por alguno de los demás técnicos asignados a este mantenimiento.",
      ];
      resultObject.content = content;

      return resultObject;
    }

    if (propToUpdateCurrentDBValue === "unassigned") return resultObject;
    if (propToUpdateCurrentDBValue !== propToUpdateCurrentFrontendValue) {
      resultObject.isEdited = true;
      resultObject.showMessageDialog = true;
      resultObject.title = `Actualización de cambios.\n La ${propKeyword} del material ${handleGetName(
        elementActuallyExist
      ).toUpperCase()} fue actualizada.`;
      resultObject.materialToUpdateOnDB = elementActuallyExist;

      const content = [];

      if (propToUpdateCurrentDBValue !== propToUpdateNewValue) {
        // console.log("El propToUpdateCurrentDBValue es distinto a propToUpdateNewValue");

        propToUpdateCurrentDBValue =
          propToUpdateCurrentDBValue === "unassigned" ? "Sin valor asignado" : propToUpdateCurrentDBValue;
        propToUpdateNewValue = propToUpdateNewValue === "unassigned" ? "Sin valor seleccionado" : propToUpdateNewValue;
        content.push(ELEMENT_EDITED_VARIANT_MESSAGE[ELEMENT_VALIDATING.MATERIAL][0]);

        content.push(
          <li>
            Tu valor actual: <strong>{propToUpdateCurrentFrontendValue}</strong>.
          </li>
        );
        content.push(
          <li>
            El valor añadido por otro usuario: <strong>{propToUpdateCurrentDBValue}</strong>.
          </li>
        );
        content.push(
          <li>
            Valor al que deseas actualizar: <strong>{propToUpdateNewValue}</strong>.
          </li>
        );
        content.push(
          `Si no es el caso, al cerrar este cuadro de mensaje se actualizará la ${propKeyword} al valor '${propToUpdateCurrentDBValue}'.`
        );
      } else {
        resultObject.isSameValue = true;
        // console.log("El propToUpdateCurrentDBValue es igual a propToUpdateNewValue");
        content.push(
          "El valor al que deseas actualizar ya ha sido previamente añadido. Por lo que no hace falta que vuelvas a realizar esta operación."
        );
        content.push(
          <li>
            Valor: <strong>{propToUpdateCurrentDBValue}</strong>.
          </li>
        );
        content.push(`Al cerrar este cuadro de mensaje, se actualizará la ${propKeyword} al valor deseado.`);
      }

      resultObject.content = content;

      return resultObject;
    }
    // console.log("SALIENDO DE validateBeforeEditMaterial: Resultado", {
    //   resultObject,
    // });
    return resultObject;
  }

  function getEvidencesChangesContent({ rowData, currentAssets } = {}) {
    // console.log("getEvidencesChangesContent: INICIANDO VALIDACIÓN", {
    //   assetToValidate: rowData,
    //   currentAssets,
    // });
    let resultObject = {
      showMessageDialog: false,
      content: null,
      isUpdated: false,
      isDeleted: false,
      assetEvaluatedId: handleElementId(rowData),
      currentAssetData: rowData,
      title: "",
    };

    const assetExist = currentAssets.find(({ asset }) => handleElementId(asset) === handleElementId(rowData));
    // console.log("getEvidencesChangesContent: ¿EL ACTIVO HA SIDO PREVIAMENTE ELIMINADO?", !assetExist ? true : false);

    if (!assetExist) {
      resultObject.title = `Activo ${handleGetName(rowData)}.`;
      resultObject.showMessageDialog = true;
      resultObject.isDeleted = true;

      const content = getElementPreviouslyDeletedContent({ elementValidating: ELEMENT_VALIDATING.EVIDENCE });
      resultObject.content = content;
      return resultObject;
    }

    const parsedCurrentEvidences = filterDeletedItems(rowData.attachments.items);
    const parsedEvidencesToEvaluate = filterDeletedItems(assetExist.attachments.items);
    const currentEvidencesIds = parsedCurrentEvidences.map((evidence) => evidence.id);
    const evidencesToEvaluateIds = parsedEvidencesToEvaluate.map((evidence) => evidence.id);
    const evidencesAreEqual = compareTwoArraysValues(currentEvidencesIds, evidencesToEvaluateIds);
    // console.log("getEvidencesChangesContent: ¿Hay evidencias previamente añadidas?", evidencesAreEqual ? false : true);
    const currentAssetData = evidencesAreEqual ? rowData : assetExist;
    if (!evidencesAreEqual) {
      resultObject.title = `Activo ${handleGetName(assetExist)}.`;
      resultObject.showMessageDialog = true;
      resultObject.isUpdated = true;
      resultObject.assetEvaluatedId = handleElementId(assetExist);
      resultObject.currentAssetData = currentAssetData;
      const content = ELEMENT_EDITED_VARIANT_MESSAGE[ELEMENT_VALIDATING.EVIDENCE];
      resultObject.content = content;
      return resultObject;
    }
    return resultObject;
  }

  function getElementPreviouslyDeletedContent({ elementValidating = null, isMaterialsFlow = false } = {}) {
    const elementWord = isMaterialsFlow ? "material" : "activo";

    let content = [];
    content.push(ELEMENT_DELETED_VARIANT_MESSAGE[elementValidating]);
    content.push(
      <>
        Si deseas volver a añadir este {elementWord}, haz click en <strong>añadir nuevamente.</strong>
      </>
    );
    return content;
  }

  function getScanChangesContent({ rowData, currentAssets } = {}) {
    // console.log("====getScanChangesContent: ENTRANDO A LA VALIDACIÓN", { assetToValidate: rowData, currentAssets });
    let resultObject = {
      showMessageDialog: false,
      content: null,
      isScanned: false,
      isDeleted: false,
      assetEvaluatedId: null,
      title: "",
    };

    const assetExist = currentAssets.find(({ asset }) => handleElementId(asset) === handleElementId(rowData));

    // console.log("====getScanChangesContent: ¿EL ACTIVO A VALIDAR FUE ELIMINADO?", !assetExist ? true : false);
    // console.log(
    //   "====getScanChangesContent: ¿EL ACTIVO YA FUE ESCANEADO PREVIAMENTE?",
    //   assetExist?.scanned ? true : false
    // );

    if (!assetExist || assetExist?.scanned) {
      resultObject.showMessageDialog = true;
    }

    if (!assetExist) {
      resultObject.title = `Activo ${handleGetName(rowData)}.`;
      resultObject.isDeleted = true;
      resultObject.assetEvaluatedId = handleElementId(rowData);

      const content = getElementPreviouslyDeletedContent({ elementValidating: ELEMENT_VALIDATING.SCAN });
      resultObject.content = content;
      return resultObject;
    }

    if (assetExist?.scanned) {
      resultObject.title = `Activo ${handleGetName(assetExist)}.`;
      resultObject.isScanned = true;
      resultObject.assetEvaluatedId = handleElementId(assetExist);
      const content = ELEMENT_EDITED_VARIANT_MESSAGE[ELEMENT_VALIDATING.SCAN];
      resultObject.content = content;
      return resultObject;
    }

    return resultObject;
  }

  function getContentOfUOMOrQuantityChanges({ materialsInFrontend, materialsInDB } = {}) {
    // console.log({ materialsInFrontend, materialsInDB });
    let content = [];
    let elementsUpdated = [];
    if (!materialsInFrontend.length || !materialsInDB.length) {
      return { content, isUpdated: false };
    }
    //valiar si hay cambias entre los materiales del front con los del back
    const materialsHasBeenUpdated = materialsInFrontend.some((materialFrontend) => {
      //hallamos el material en DB mediante el id del material en el frontend
      const materialInDBFound = materialsInDB.find((materialInDB) => {
        // console.log({ materialInDB, materialFrontend });
        return handleElementId(materialInDB) === handleElementId(materialFrontend);
      });

      // console.log({ materialInDBFound });
      if (!materialInDBFound) return false;
      //validamos si hay actualizaciónes en sus unidades de medida o cantidad
      const isUpdated =
        handleGetUomId(materialFrontend) !== handleGetUomId(materialInDBFound) ||
        handleGetMaterialQuantity(materialFrontend) !== handleGetMaterialQuantity(materialInDBFound);
      // console.log({ isUpdated });
      //si hay actualizaciones añadimos el material con cambios a un arreglo
      if (isUpdated) elementsUpdated.push(materialInDBFound);

      return isUpdated;
    });
    if (materialsHasBeenUpdated) {
      content.push("Se han a actualizado las unidades de medida o cantidad de los siguientes materiales.");
    }
    const elementsUpdatedContentName = buildElementsContent(elementsUpdated, false);
    // console.log({ elementsUpdated, elementsUpdatedContentName });
    content = [...content, ...elementsUpdatedContentName];
    // console.log({ content, materialsHasBeenUpdated });
    return { content, isUpdated: materialsHasBeenUpdated };
  }

  function getContentOfEvidencesChanges({ assetsInFrontend, assetsInDB } = {}) {
    let content = [];
    let assetsInFrontendEvidencesIds = [];
    let assetsInDBEvidencesIds = [];

    //obtenemos todos los ids de evidencias en nuestros activos en el frontend
    assetsInFrontend.forEach((assetInFrontend) => {
      // console.log({ assetInFrontend });
      const pasersedAssetFrontendEvidences = filterDeletedItems(assetInFrontend.attachments.items);
      // console.log({ pasersedAssetFrontendEvidences });
      const currentAssetEvidencesIds = pasersedAssetFrontendEvidences.map((evidence) => evidence.id);
      assetsInFrontendEvidencesIds = [...assetsInFrontendEvidencesIds, ...currentAssetEvidencesIds];
    });

    //obtenemos todos los ids de evidencias en nuestros activos en DB
    assetsInDB.forEach((assetInDB) => {
      // console.log({ assetInDB });
      //remover evidencias _deleted
      const pasersedAssetDBEvidences = filterDeletedItems(assetInDB.attachments.items);
      // console.log({ pasersedAssetDBEvidences });
      const currentAssetDBEvidencesIds = pasersedAssetDBEvidences.map((evidence) => evidence.id);
      assetsInDBEvidencesIds = [...assetsInDBEvidencesIds, ...currentAssetDBEvidencesIds];
    });
    // console.log({ assetsInFrontendEvidencesIds, assetsInDBEvidencesIds });

    //obtenemos true o false dependiendo si hay diferencias
    const areEvidencesEqual = compareTwoArraysValues(assetsInFrontendEvidencesIds, assetsInDBEvidencesIds);
    // console.log({ areEvidencesEqual });

    if (!areEvidencesEqual) {
      content.push(
        "Se han añadido o eliminado evidencias en algunos de los activos de tu listado, por lo que el progreso de la actividad ha sido modificado."
      );
    }
    return { content, isUpdated: !areEvidencesEqual };
  }

  function getContentOfScanedChanges({ assetsInFrontend, assetsInDB } = {}) {
    let content = [];
    if (!assetsInFrontend.length || !assetsInDB.length) {
      return { content, isUpdated: false };
    }

    const isFrontendScanValid = validateQRScanned(assetsInFrontend, false);
    const isDBScanValid = validateQRScanned(assetsInDB, false);
    const isScanValid = isFrontendScanValid === isDBScanValid;
    // console.log({ isScanValid });
    if (!isScanValid) {
      content.push(
        "Algunos activos han sido escaneados por los demás técnicos, por lo que el progreso de la actividad ha avanzado."
      );
    }
    return { content, isUpdated: !isScanValid };
  }

  return {
    getMaterialsAndAssetsChanges,
    getElementsPreviouslyAddedOnAdding,
    getElementPreviouslyDeletedContent,
    getOnMaterialEditContent,
    getEvidencesChangesContent,
    getScanChangesContent,
    getContentOfUOMOrQuantityChanges,
    getContentOfEvidencesChanges,
    getContentOfScanedChanges,
  };
};

export default HandleMaintenanceValidation;

const ELEMENT_VALIDATING = {
  NULL: null,
  EVIDENCE: "EVIDENCE",
  SCAN: "SCAN",
  ASSET: "ASSET",
  MATERIAL: "MATERIAL",
};

const ELEMENT_DELETED_VARIANT_MESSAGE = {
  [ELEMENT_VALIDATING.NULL]: [
    "Este elemento fue previamente eliminado por alguno de los demás técnicos asignados a este mantenimiento.",
  ],
  [ELEMENT_VALIDATING.EVIDENCE]: [
    "El activo al que intentas visualizar o añadir evidencias ha sido eliminado previamente por alguno de los demás técnicos asignados a este mantenimiento.",
  ],
  [ELEMENT_VALIDATING.SCAN]: [
    "El activo que intentas escanear ha sido eliminado previamente por alguno de los demás técnicos asignados a este mantenimiento.",
  ],
  [ELEMENT_VALIDATING.MATERIAL]: [
    "El material que deseas eliminar fue previamente eliminado por alguno de los demás técnicos asignados a este mantenimiento.",
  ],
  [ELEMENT_VALIDATING.ASSET]: [
    "El activo que deseas eliminar fue previamente eliminado por alguno de los demás técnicos asignados a este mantenimiento.",
  ],
};

const ELEMENT_EDITED_VARIANT_MESSAGE = {
  [ELEMENT_VALIDATING.NULL]:
    "Este elemento fue previamente modificado por ti o por alguno de los demás técnicos asignados a este mantenimiento.",
  [ELEMENT_VALIDATING.EVIDENCE]: ["Uno de los demás técnicos a añadido o eliminado evidencias a este activo."],
  [ELEMENT_VALIDATING.SCAN]: [
    "Este activo fue escaneado previamente por alguno de los demás técnicos asignados a este mantenimiento. Por lo que no hace falta que vuelvas a escanearlo.",
  ],
  [ELEMENT_VALIDATING.MATERIAL]: [
    "Este material fue previamente editado por alguno de los demás técnicos asignados a este mantenimiento.",
  ],
};
