/* eslint-disable react/display-name */
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";

import Card from "@material-ui/core/Card";

import MaterialTable from "components/custom/MaterialTable";
import { AssetType } from "models";

const columns = [
  { title: "ID", field: "id", hidden: true },
  {
    title: "Código",
    field: "code",
    render: (rowData) => <p>{rowData?.asset?.asset?.material?.code || "S/I"}</p>,
  },
  {
    title: "Nombre",
    field: "name",
    render: (rowData) => <p>{rowData?.asset?.asset?.material?.name || "S/I"}</p>,
  },
  {
    title: "Unidad",
    render: (rowData) => <p>{rowData?.asset?.asset?.uom?.name || "S/I"}</p>,
  },
  {
    title: "Cantidad",
    field: "asset.asset.quantity",
  },
];

export default function AssetsInstallationDetailMaterials(props) {
  const { fetchingDetail, installationDetails } = props;

  let tableCols = columns;

  //LA TABLA debe de mostrat todos los materiales ?????
  const materialsTableRows = useMemo(() => {
    const { assets = [] } = installationDetails;
    let materials;
    if (installationDetails?.task?.status === "IN_PROGRESS" || installationDetails?.task?.status === "SCHEDULED") {
      // Filtrado de los assets que fueron programados
      materials = assets.filter(
        (item) =>
          item.asset?.asset?.type === AssetType.ALLOCATED || item.asset?.asset?.type === AssetType.MATERIAL_ALLOCATED
      );
    } else {
      // Filtrado de los assets que fueron utilizados
      materials = assets.filter(
        (item) => item.asset?.asset?.type === AssetType.USED || item.asset?.asset?.type === AssetType.REUSED
      );
    }
    return materials;
  }, [installationDetails]);

  return (
    <Box>
      <Card>
        <MaterialTable
          title={`${
            installationDetails?.task?.status === "SCHEDULED" || installationDetails?.task?.status === "IN_PROGRESS"
              ? "Materiales estimados"
              : "Materiales utilizados"
          }`}
          columns={tableCols}
          isLoading={fetchingDetail}
          data={materialsTableRows}
          options={{
            exportButton: false,
            columnsButton: false,
          }}
        />
      </Card>
    </Box>
  );
}

AssetsInstallationDetailMaterials.propTypes = {
  fetchingDetail: PropTypes.any,
  installationDetails: PropTypes.object,
};
