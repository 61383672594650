import React from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/styles/makeStyles";
import Section from "components/Section";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import DataLabel from "components/DataLabel";
import cleanUUID from "util/cleanUUID";
import Rating from "@material-ui/lab/Rating";

function TicketReviewCard({ reviewRating, reviewComment }) {
  const classes = useStyles();

  return (
    <Section>
      <Grid container className={classes.detailsGrid} spacing={2}>
        <Grid item xs={12}>
          <Box>
            <h3>Revisión</h3>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <p>
              <b>Calificación</b>
            </p>
            <Rating name="simple-controlled" size="large" disabled value={reviewRating} />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <p>
              <b>Comentarios</b>
            </p>
            <p>{reviewComment}</p>
          </Box>
        </Grid>
      </Grid>
    </Section>
  );
}

TicketReviewCard.propTypes = {};

export default TicketReviewCard;

const useStyles = makeStyles((theme) => ({
  detailsGrid: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  detailsContent: {
    padding: theme.spacing(2),
  },
  username: {
    "& > *": {
      marginRight: theme.spacing(2),
    },
  },
  roleControl: {
    minWidth: 200,
    marginTop: theme.spacing(2),
  },
  root: {
    [theme.breakpoints.down("sm")]: {
      "& .MuiTableHead-root": {
        display: "none",
      },
      "& .MuiToolbar-root": {
        flexDirection: "column",
        padding: theme.spacing(2),
      },
      "& div[class*='MTableToolbar-actions-']": {
        order: 2,
        textAlign: "right",
        width: "100%",
      },
      "& div[class*='MTableToolbar-title-']": {
        order: 1,
        textAlign: "left",
        width: "100%",
      },
      "& div[class*='MTableToolbar-searchField-']": {
        width: "100%",
        order: 0,
        marginBottom: theme.spacing(2),
      },
    },
  },
  codeReaderPanel: {
    backgroundColor: "white",
    overflow: "clip",
    display: "relative",
  },
}));
