import API, { graphqlOperation } from '@aws-amplify/api';
import ActionTypes from "redux/action";

/**
 * @middleware graphqlMiddleware
 * 
 * Este middleware se encarga de manejar todas las consultas y mutaciones de la API de GraphQL.
 * Cualquier acción despachada causa la ejecución de este middleware. Sin embargo, el middleware
 * solo maneja las acciones que incluyan las propiedades "filter" y "operation", las cuales deben
 * cumplir ciertas condiciones.
 * 
 */
export const graphqlMiddleware = () => api => next => action => {
  if (typeof action === 'function') {
    return action(api.dispatch, api.getState);
  }

  // Se extraen las propiedades de cada acción que puede manejar este middleware 
  const { filter, types, operation, operationParams, options, ...rest } = action;

  /**
   * Condicional que evalua si la acción debe ser manejada por el middleware. Si la
   * acción NO tiene una propiedad llamada "filter" con el valor "graphql" o tampoco
   * contiene una propiedad "operation" que es un string de una consulta o mutación,
   * el middleware despacha la acción y termina su ejecución. 
   */
  if (filter !== 'graphql' || !operation) {
    return next(action);
  }

  /**
   * Las acciones manejadas por este middleware también pueden contener un arreglo de
   * strings llamado "types", que representan las acciones que serán despachadas por 
   * cada estado de la petición. El orden de los string de "types" es esencial para 
   * despachar acciones de forma correcta. 
   */
  const [PENDING, SUCCESS, ERROR] = types;

  /**
   * Primero se despacha la primer acción de "types", la cual representa el estado
   * de solicitud pendiente
   */
  next({ ...rest, type: PENDING });

  /**
   * Una única instancia de API.graphql se encarga de ejecutar todas las llamadas a
   * la API de GraphQL. También, se ejecuta graphqlOperation con los argumentos "operation"
   * y "operation" que se obtuvieron de "action".
   */
  return API.graphql(graphqlOperation(operation, operationParams))
    /**
     * En caso de que la solicitud a la API sea exitosa, se despacha la segunda acción de "types"
     * que maneja el estado exitoso de solictud. Además, en la acción se incluye la propiedad
     * "payload" con los datos de la respuesta a la solicitud.
     */
    .then(res => {
      return next({ ...rest, payload: res.data, type: SUCCESS })
    })
    /**
     * En caso de que la solictud sea erronea, se despachan dos acciones diferentes. La primer
     * acción es para generar una notificación que muestra un mensaje de error personalizado o
     * predeterminado. La segunda acción es para manejar el estado de error en la solicitud. 
     */
    .catch(e => {
      console.error(e);
      let error = options?.errorMessage ? options.errorMessage : "Ocurrió un error";
      next({ type: ActionTypes.SHOW_NOTIFICATION, payload: { message: error, options: { variant: "error" } } });
      return next({ ...rest, type: ERROR });
    })
};