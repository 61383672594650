import React, { useState } from "react";
import PropTypes from "prop-types";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "components/custom/Button";
import ConfirmationDialog from "components/ConfirmationDialog";
import useBooleanFlag from "hooks/useBooleanFlag";
import useNotifier from "hooks/useNotifier";
import { useActions } from "hooks/useActions";
import * as adminSiteCatalog from "redux/dispatcher/adminSiteCatalog";
import { REQUEST_STATUS } from "constant/requestStatus";
import { useEffect } from "react";
import { FORM_MODES } from "constant/formModes";

const NAME_FORM_RULES = {
  required: "Campo obligatorio",
  maxLength: { value: 50, message: "Máximo 50 caracteres" },
};

const DEFAULT_FORM = {
  name: "",
  code: "",
  buildingBlock: "",
  floor: "",
  area: "",
};

const CREATE_TEXT = {
  title: "Nueva Zona",
  confirmBtn: "Crear",
  confirmationMsg: "Se creará una nueva zona en el sistema.",
  successMsg: "Creación exitosa"
};

const UPDATE_TEXT = {
  title: "Actualizar Zona",
  confirmBtn: "Actualizar",
  confirmationMsg: "Se actualizará la zona en el sistema.",
  successMsg: "Actualización exitosa"
};

function getDialogText(formMode) {
  if (formMode === FORM_MODES.CREATE) {
    return CREATE_TEXT;
  } else if (formMode === FORM_MODES.UPDATE) {
    return UPDATE_TEXT;
  }
}

function BuildingLocationDialog(props) {
  const {
    rdxCreateBuildingLocationStatus,
    rdxUpdateBuildingLocationStatus,
  } = useSelector(({ adminPremisesDetail }) => adminPremisesDetail);
  const [
    createBuildingLocation, 
    createBuildingLocationClear,
    rdxPatchBuildingLocation
  ] = useActions([
    adminSiteCatalog.createBuildingLocation,
    adminSiteCatalog.createBuildingLocationClear,
    adminSiteCatalog.updateBuildingLocation
  ]);
  const [
    confirmDialogOpen,
    openConfirmDialog,
    closeConfirmDialog,
  ] = useBooleanFlag();
  const [formMode] = useState(props.buildingLocation ? FORM_MODES.UPDATE : FORM_MODES.CREATE);
  const { control, getValues, trigger, errors } = useForm({
    mode: "onChange",
    defaultValues: formMode === FORM_MODES.UPDATE ? props.buildingLocation : DEFAULT_FORM
  });
  const [dialogText] = useState(getDialogText(formMode));
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const notifier = useNotifier();

  useEffect(() => {
    if (
        rdxCreateBuildingLocationStatus === REQUEST_STATUS.SUCCESSFUL ||
        rdxUpdateBuildingLocationStatus === REQUEST_STATUS.SUCCESSFUL
      ) {
        closeConfirmDialog();
        notifier.showMessage(dialogText.successMsg);
        props.onCreationSuccess();
        props.onClose();
      }
    else if (
      rdxCreateBuildingLocationStatus === REQUEST_STATUS.FAILURE ||
      rdxUpdateBuildingLocationStatus === REQUEST_STATUS.FAILURE
    ) {
      props.onCreationFailure();
    }

    return () => {
      createBuildingLocationClear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rdxCreateBuildingLocationStatus, rdxUpdateBuildingLocationStatus]);

  async function handleSaveBtnClick(event) {
    const values = getValues();
    if (formMode === FORM_MODES.CREATE) {
      createBuildingLocation({
        ...values,
        premisesID: props.premiseId,
      });
    } else if (formMode === FORM_MODES.UPDATE) {
      rdxPatchBuildingLocation({ 
        ...values, 
        id: props.buildingLocation.id,
        _version: props.buildingLocation._version
      });
    }
  }

  async function validateForm() {
    const isValid = await trigger();
    if (!isValid) {
      return
    };
    openConfirmDialog();
  }

  return (
    <>
      <Dialog
        open
        fullScreen={fullScreen}
        fullWidth
        scroll="paper"
        maxWidth="md"
      >
        <DialogTitle>{dialogText.title}</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <Controller
                control={control}
                as={TextField}
                label="Nombre"
                name="name"
                type="text"
                fullWidth
                required
                rules={NAME_FORM_RULES}
                error={errors.name ? true : false}
                helperText={errors.name?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Controller
                control={control}
                as={TextField}
                label="Código"
                name="code"
                type="text"
                fullWidth
                required
                rules={{
                  required: "Campo obligatorio",
                  maxLength: { value: 10, message: "Máximo 10 caracteres" },
                  minLength: { value: 3, message: "Mínimo 3 caracteres" },
                }}
                error={errors.code ? true : false}
                helperText={errors.code?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Controller
                control={control}
                as={TextField}
                label="Bloque"
                name="buildingBlock"
                type="text"
                fullWidth
                required
                rules={NAME_FORM_RULES}
                error={errors.buildingBlock ? true : false}
                helperText={errors.buildingBlock?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Controller
                control={control}
                as={TextField}
                label="Piso/Nivel"
                name="floor"
                type="number"
                fullWidth
                required
                rules={NAME_FORM_RULES}
                error={errors.floor ? true : false}
                helperText={errors.floor?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Controller
                control={control}
                as={TextField}
                label="Área"
                name="area"
                type="text"
                fullWidth
                required
                rules={NAME_FORM_RULES}
                error={errors.area ? true : false}
                helperText={errors.area?.message}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="default" onClick={props.onClose}>
            CANCELAR
          </Button>
          <Button onClick={validateForm} color="primary" type="submit">
            {dialogText.confirmBtn}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        open={confirmDialogOpen}
        title="Confirmación"
        onConfirm={handleSaveBtnClick}
        onCancel={closeConfirmDialog}
        loading={
          rdxUpdateBuildingLocationStatus === REQUEST_STATUS.PENDING ||
          rdxCreateBuildingLocationStatus === REQUEST_STATUS.PENDING
        }
      >
        <Typography paragraph>
          {dialogText.confirmationMsg}
        </Typography>
        <Typography>¿Desea continuar?.</Typography>
      </ConfirmationDialog>
    </>
  );
}

BuildingLocationDialog.propTypes = {
  // Maneja el evento onClick del botón "Cancelar"
  onClose: PropTypes.func,
  // Permite a componente padre manejar el caso exitóso de la operación de creación o actualización
  onCreationSuccess: PropTypes.func,
  // Permite a componente padre manejar el caso fallido de la operación de creación o actualización
  onCreationFailure: PropTypes.func,
  // ID del sitio al que se añadirá la nueva zona
  premiseId: PropTypes.string,
  // Valores del formulario para determinar si se crea o modfica una zona
  buildingLocation: PropTypes.object
};

BuildingLocationDialog.defaultProps = {
  onClose: () => {},
  onCreationSuccess: (event) => {},
  onCreationFailure: (event) => {},
  premiseId: null,
  buildingLocation: null
};

export default BuildingLocationDialog;
