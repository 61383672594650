/*
  Custom Hook para retornar un estado con el objeto completo de un item
  seleccionado en alguno de los selectores
*/

import { useState } from "react";

const useCompleteFormValues = (DEFAULT_FORM_VALUES) => {
  const [formValues, setFormValues] = useState(DEFAULT_FORM_VALUES);

  function onSelectsChange(event, data, option) {
    const { name } = event.target;

    setFormValues((formValues) => {
      formValues = {
        ...formValues,
        [name]: data,
      };
      return formValues;
    });
  }

  function onMultipleSelectChange(event, completeArrayData) {
    const { name } = event.target;

    //los selectores multiples retornan un array de los ids de las opciones seleccionadas
    const IdsArray = event.target.value;

    //filtrar los objetos completos del array principal a partir de un array de ids
    const completeData = completeArrayData.filter((data) => IdsArray.indexOf(data.id) > -1);

    setFormValues((formValues) => {
      formValues = {
        ...formValues,
        [name]: completeData,
      };
      return formValues;
    });
  }

  function cleanFormValues(options = { cleanValues: [] }) {
    // const { cleanValues } = options;
    // //cleanValues, arreglo de "names" de los input a limpiar
    // if (cleanValues.length) {
    //   console.log("cleanValues", cleanValues);
    //   const formValuesCopy = formValues;
    //   console.log("formValuesCopy", formValuesCopy);
    //   cleanValues.forEach((value) => {
    //     console.log("value", value);
    //     return (formValuesCopy[value] = DEFAULT_FORM_VALUES[value]);

    //     // console.log("formValuesCopyUpdate", formValuesCopy);
    //   });

    //   console.log("cleanedValues", formValuesCopy);
    //   return setFormValues(formValuesCopy);
    // }
    setFormValues(DEFAULT_FORM_VALUES);
  }

  return {
    formValues,
    onSelectsChange,
    onMultipleSelectChange,
    cleanFormValues,
  };
};

export default useCompleteFormValues;
