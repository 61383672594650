import API, { graphqlOperation } from "@aws-amplify/api";
import * as mutations from "graphql-custom/mutations";
import { Logger } from "@aws-amplify/core";

const logger = new Logger("SupportTicketDialog/helpers/graphql");

const RequestType = {
  ISSUE: "Incidente",
  SERVICE_REQUEST: "Solicitud de servicio",
};

const ServiceCategory = {
  ELECTRONIC_SECURITY: "Seguridad Electronica",
};

const ServiceSubcategory = {
  CCTV: "CCTV",
  NETWORK: "RED",
  COMPUTER_EQUIPMENT: "EQUIPO DE COMPUTO",
  PROPERTY_EQUIPMENT: "EQUIPO INMOBILIARIO",
  AIR_CODITIONER: "AIRE ACONDICIONADO",
  ACCESS_CONTROL: "CONTROL DE ACCESSO",
  ROUND_CONTROL: "CONTROL DE RONDAS",
  CABINET: "GABINETES",
};

const TicketPriority = {
  HIGH: "Alto",
  MEDIUM: "Media",
  LOW: "Bajo",
};

const TicketMode = {
  WEB_PAGE: "Página Web",
};

const TicketUrgency = {
  HIGH: "Alto",
  NORMAL: "Normal",
  LOW: "Bajo",
};

const TicketImpact = {
  HIGH: "Alto",
  MEDIUM: "Media",
  LOW: "Bajo",
};

function convertToFriendlyValues(ticket, asset, premises) {
  const data = { ...ticket };
  data.description = `${ticket.description} \n--- Sede: ${premises?.name}\n--- Teléfono del Solicitante: ${ticket?.requester?.phone} \n--- Activo: ${asset?.name} Serie: ${asset?.serial}) Ubicación: ${asset?.location}`;
  data.requestType = RequestType[ticket.requestType];
  data.serviceCategory = ServiceCategory[ticket.serviceCategory];
  data.serviceSubcategory = ServiceSubcategory[ticket.serviceSubcategory];
  data.priority = TicketPriority[ticket.priority];
  data.urgency = TicketUrgency[ticket.urgency];
  data.impact = TicketImpact[ticket.impact];
  data.mode = TicketMode[ticket.mode];
  data.group = ServiceCategory[ticket.serviceCategory];
  data.requester = `${ticket.requester.name} ${ticket.requester.lastnameP} (${ticket.requester.email})`;
  return data;
}

export async function createManageEngineTicket(ticket, asset, premises) {
  logger.debug("Creando ticket en Manage Engine");
  ticket = convertToFriendlyValues(ticket, asset, premises);

  let requestResult = await API.graphql(
    graphqlOperation(mutations.lambdaHandleIntegration, {
      task: { action: "createRequest", source: "String", destination: "String", stage: "String" },
      payload: JSON.stringify({
        requestType: ticket.requestType,
        impact: ticket.impact,
        impactdetails: ticket.impactDetails,
        mode: ticket.mode,
        urgency: ticket.urgency,
        priority: ticket.priority,
        category: ticket.serviceCategory,
        subcategory: ticket.serviceSubcategory,
        subject: ticket.subject,
        description: ticket.description,
        requester: ticket.requester,
      }),
    })
  ).then((result) => result.data.lambdaHandleIntegration);

  if (requestResult.error) {
    logger.error("Fallo en creación de ticket con la integración de ME");
    logger.error(requestResult.errorMessage);
    throw new Error("Fallo en creación de ticket con la integración de ME");
  }

  return requestResult;
}
