import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import API, { graphqlOperation } from "@aws-amplify/api";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import makeStyles from "@material-ui/styles/makeStyles";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/AddBox";
import MoreIcon from "@material-ui/icons/MoreVertRounded";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import Section from "components/Section";
import MaterialTable from "components/custom/MaterialTable";
import ConfirmationDialog from "components/ConfirmationDialog";
import useHeaderTitle from "hooks/useHeaderTitle";
import useNotifier from "hooks/useNotifier";
import { useActions } from "hooks/useActions";
import * as userActions from "redux/dispatcher/usersManagement";
import * as mutations from "graphql-custom/mutations";
import { REQUEST_STATUS } from "constant/requestStatus";

const useStyles = makeStyles((theme) => ({
  detailsGrid: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  detailsContent: {
    padding: theme.spacing(4),
  },
  username: {
    "& > *": {
      marginRight: theme.spacing(2),
    },
  },
  roleControl: {
    minWidth: 200,
    marginTop: theme.spacing(2),
  },
}));

async function addUserToCognitoGroup(userId, group) {
  return API.graphql(
    graphqlOperation(mutations.addUserToCognitoGroup, {
      userId,
      group,
    })
  );
}
async function removeUserFromCognitoGroup(userId, group) {
  return API.graphql(
    graphqlOperation(mutations.removeUserFromCognitoGroup, {
      userId,
      group,
    })
  );
}

const TABLE_COLUMNS = [
  { title: "Nombre", field: "role.name" },
  { title: "Fecha de asignación", field: "createdAt", defaultSort: "desc" },
];
const TABLE_OPTIONS = { exportButton: false, columnsButton: false };

const ACTIONS = {
  ASSIGN_ROL: "ASSIGN_ROL",
  REMOVE_ROL: "REMOVE_ROL",
};

function UserDetailsView() {
  const classes = useStyles();
  const { id } = useParams();
  const { rdxUser, rdxStatus, rdxRoles, rdxAssignStatus, rdxStatusChange } = useSelector(
    ({ activeUser }) => activeUser
  );
  const { userId: rdxUserId } = useSelector(({ session }) => session);
  const [fetchUserById, fetchRoles, assignRole, deleteRole, updateStatus] = useActions([
    userActions.getUserById,
    userActions.getRoles,
    userActions.addRoleToUser,
    userActions.deleteRoleFromUser,
    userActions.updateUserStatus,
  ]);
  useHeaderTitle(`Usuario ${rdxUser.email || ""}`);
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  const notifier = useNotifier();
  const [currentOp, setCurrentOp] = useState(null);
  const [showStatusDialog, setShowStatusDialog] = useState(false);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [groupToRemove, setGroupToRemove] = useState("");
  const [selectedRoleId, setSelectedRoleId] = useState("");
  const [showAddConfirm, setShowAddConfirm] = useState(false);

  useEffect(() => {
    fetchUserById(id);
  }, [fetchUserById, id]);

  useEffect(() => {
    if (rdxStatusChange === REQUEST_STATUS.SUCCESSFUL) {
      setShowStatusDialog(false);
      toggleMenu();
    }
  }, [rdxStatusChange]);

  useEffect(() => {
    if (rdxAssignStatus === REQUEST_STATUS.SUCCESSFUL) {
      modifyCognitoGroup();
      setShowAddConfirm(false);
      setShowAddDialog(false);
      cleanRoleState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rdxAssignStatus]);

  async function modifyCognitoGroup() {
    try {
      if (currentOp === ACTIONS.ASSIGN_ROL) {
        const { cognitoGroup } = rdxRoles.find((role) => role.id === selectedRoleId);
        await addUserToCognitoGroup(rdxUser.id, cognitoGroup);
      } else if (currentOp === ACTIONS.REMOVE_ROL) {
        await removeUserFromCognitoGroup(rdxUser.id, groupToRemove);
        setGroupToRemove("");
      }
    } catch (error) {
      console.error("Error al eliminar grupo de cognito");
      notifier.showError("Error al modificar rol del usuario");
    } finally {
      setCurrentOp("");
    }
  }

  const goToPreviousPage = () => {
    history.goBack();
  };

  const toggleMenu = (event) => {
    if (event?.currentTarget) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };

  const changeUserStatus = () => {
    const newStatus = rdxUser.status === "ENABLED" ? "DISABLED" : "ENABLED";
    updateStatus(rdxUser.id, newStatus, rdxUser._version);
  };

  const changeRole = (event) => {
    setSelectedRoleId(event.target.value);
  };

  const addRole = async () => {
    setShowAddDialog(true);
    fetchRoles();
  };

  const confirmAddRole = () => {
    assignRole(selectedRoleId, rdxUser.id);
    setCurrentOp(ACTIONS.ASSIGN_ROL);
  };

  const removeRole = async (event, row) => {
    const { id: relationId, role } = row;
    setGroupToRemove(role.id);
    setSelectedRoleId(relationId);
    setShowDeleteDialog(true);
  };

  const confirmRemoveRole = () => {
    const { _version } = rdxUser.roles.find(({ id }) => id === selectedRoleId);
    deleteRole(selectedRoleId, _version);
    setCurrentOp(ACTIONS.REMOVE_ROL);
  };

  const cleanRoleState = () => {
    setSelectedRoleId("");
    setShowDeleteDialog(false);
  };

  const renderErrorAlert = () => {
    if (rdxStatus === REQUEST_STATUS.FAILED) {
      return (
        <Alert severity="error">
          Ocurrió un error al cargar los datos del usuario. Intente
          <Button onClick={() => fetchUserById(id)} color="secondary" size="small">
            cargar los datos
          </Button>{" "}
          nuevamente.
        </Alert>
      );
    }
    return null;
  };

  const renderRoles = () => {
    if (!rdxRoles.length) {
      return null;
    }
    return rdxRoles
      .sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        // a must be equal to b
        return 0;
      })
      .map(({ id, name }) => (
        <MenuItem key={id} value={id}>
          {name}
        </MenuItem>
      ));
  };

  if (rdxStatus === REQUEST_STATUS.PENDING) {
    return (
      <Grid container justify="center">
        <CircularProgress color="primary" />
      </Grid>
    );
  }

  const canUserEdit = rdxUserId !== id;
  const tableActions = canUserEdit
    ? [
      { icon: DeleteIcon, onClick: removeRole },
      {
        icon: AddIcon,
        tooltip: "Agregar rol",
        isFreeAction: true,
        onClick: addRole,
      },
    ]
    : [];

  return (
    <>
      {renderErrorAlert()}
      <Section>
        <Grid container className={classes.detailsGrid}>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="space-between">
              <Box display="flex" className={classes.username}>
                <IconButton onClick={goToPreviousPage} color="inherit" component="span" size="small">
                  <ArrowBackIcon />
                </IconButton>
                <Typography variant="h6">USUARIO {rdxUser.email}</Typography>
                {rdxUser.status === "DISABLED" && <Chip label="Deshabilitado" />}
              </Box>
              {canUserEdit && (
                <Box>
                  <IconButton onClick={toggleMenu} color="inherit" component="span" size="small">
                    <MoreIcon />
                  </IconButton>
                  <Menu
                    id="user-menu"
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    anchorEl={anchorEl}
                    keepMounted
                    open={!!anchorEl}
                    onClose={() => toggleMenu()}
                  >
                    <MenuItem onClick={() => setShowStatusDialog(true)}>
                      {rdxUser.status === "ENABLED" ? "Deshabilitar" : "Habilitar"} Usuario
                    </MenuItem>
                  </Menu>
                </Box>
              )}
            </Box>
          </Grid>

          <Grid item xs={12} className={classes.detailsContent}>
            <Grid container>
              <Grid item sm={4} xs={12}>
                <div>
                  <Typography variant="subtitle2">Nombre completo</Typography>
                  <Typography variant="body2">
                    {`${rdxUser.name} ${rdxUser.lastnameP || ""} ${rdxUser.lastnameM || ""}`}
                  </Typography>
                  <br />
                </div>
                <div>
                  <Typography variant="subtitle2">Correo electrónico</Typography>
                  <Typography variant="body2">{rdxUser.email}</Typography>
                </div>
              </Grid>
              <Grid item sm={4} xs={12}>
                <div>
                  <Typography variant="subtitle2">Fecha de ingreso</Typography>
                  <Typography variant="body2">
                    {rdxUser.createdAt ? dayjs(rdxUser.createdAt).format("DD/MM/YYYY HH:mm") : "-"}
                  </Typography>
                  <br />
                </div>
                <div>
                  <Typography variant="subtitle2">Teléfono</Typography>
                  <Typography variant="body2">{rdxUser.phone || "-"}</Typography>
                </div>
              </Grid>
              <Grid item container direction="column" justify="flex-end" sm={4} xs={12}>
                <div>
                  <Typography variant="subtitle2">Extensión</Typography>
                  <Typography variant="body2">{rdxUser.extension || "-"}</Typography>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Section>

      <MaterialTable
        title="ROLES DE USUARIO"
        options={TABLE_OPTIONS}
        actions={tableActions}
        columns={TABLE_COLUMNS}
        data={rdxUser.roles}
      />

      <ConfirmationDialog
        open={showStatusDialog}
        title="Confirmación"
        onConfirm={changeUserStatus}
        onCancel={() => setShowStatusDialog(false)}
        loading={rdxStatusChange === REQUEST_STATUS.PENDING}
      >
        Estás a punto de {rdxUser.status === "ENABLED" ? "deshabilitar" : "habilitar"} al usuario
        <br />
        <br />
        ¿Deseas continuar?
      </ConfirmationDialog>

      <ConfirmationDialog
        open={showDeleteDialog}
        title="Eliminar Grupo"
        onConfirm={confirmRemoveRole}
        onCancel={cleanRoleState}
        loading={rdxAssignStatus === REQUEST_STATUS.PENDING}
      >
        ¿Estás seguro que deseas continuar con la eliminación del grupo?
      </ConfirmationDialog>

      <ConfirmationDialog
        open={showAddDialog}
        title="Agregar Rol"
        onConfirm={() => setShowAddConfirm(true)}
        onCancel={() => setShowAddDialog(false)}
      >
        <Typography variant="body2">Seleccione el rol a agregar</Typography>
        <FormControl className={classes.roleControl}>
          <InputLabel id="select-role-label">Rol</InputLabel>
          <Select labelId="select-role-label" id="select-role" value={selectedRoleId} onChange={changeRole}>
            <MenuItem value="">Ninguno</MenuItem>
            {renderRoles()}
          </Select>
        </FormControl>
      </ConfirmationDialog>

      <ConfirmationDialog
        open={showAddConfirm}
        title="Confirmación"
        onConfirm={confirmAddRole}
        onCancel={() => setShowAddConfirm(false)}
        loading={rdxAssignStatus === REQUEST_STATUS.PENDING}
      >
        <Typography paragraph>Estás a punto de agregar un rol al usuario</Typography>
        <Typography paragraph>¿Deseas continuar?</Typography>
      </ConfirmationDialog>
    </>
  );
}

export default UserDetailsView;
