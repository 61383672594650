import React from "react";

import Fab from "@material-ui/core/Fab";
import Box from "@material-ui/core/Box";
import Slide from "@material-ui/core/Slide";
import Zoom from "@material-ui/core/Zoom";
import Button from "@material-ui/core/Button";

import useMediaQuery from "@material-ui/core/useMediaQuery";

const transitionDuration = {
  enter: 200,
  exit: 200,
};
const boxStyle = { zIndex: 100 };
const boxContainerStyle = { width: "100vw" };
const mobileBtnStyle = { borderRadius: 0 };
function FloatingButton({ top, bottom, right, left, visible, mobile, desktop, transitionProps, variant, btnProps, fabProps, children, ...props }) {
  const isXs = useMediaQuery("(max-width:600px)");

  if (mobile && isXs) {
    return (
      <Box position="fixed" bottom={0} left={0} style={boxStyle}>
        <div style={boxContainerStyle}>
          <Slide direction="up" in={visible} mountOnEnter unmountOnExit {...transitionProps}>
            <Button fullWidth variant="contained" color="primary" size="large" style={mobileBtnStyle} {...btnProps} {...props} >
            {children}
            </Button>
          </Slide>
        </div>
      </Box>
    );
  }

  if (desktop && !isXs)
    return (
      <Zoom
        in={visible}
        timeout={transitionDuration}
        style={{
          transitionDelay: `${visible ? transitionDuration.exit : 0}ms`,
        }}
        unmountOnExit
      >
        <Box position="fixed" zIndex="modal" bottom={bottom} right={right} top={top} left={left}>
          <Fab {...fabProps} {...props} variant={variant}>{children}</Fab>
        </Box>
      </Zoom>
    );

  return null;
}

FloatingButton.defaultProps = {
  visible: true,
  desktop: true,
  mobile: true,
};

export default FloatingButton;
