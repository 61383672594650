import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Storage } from "@aws-amplify/storage";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import { Img } from "react-image";
import ImageCarouselDialog from "components/common/ImageCarouselDialog";
import LoadingPanel from "components/common/LoadingPanel";
import useBooleanFlag from "hooks/useBooleanFlag";
import useLoadingStatus from "hooks/useLoadingStatus";

// Componente auxiliar CustomGridList ******************************************

const NO_ATTACHMENTS_DIV_STYLE = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "120px",
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    height: "100%",
    width: "100%",
  },
}));

/**
 * Genera URLs prefirmadas para las evidencias proporcionadas.
 *
 * @param {Array} attachments Arreglo de evidencias de un task
 */
async function signFileURLs(attachments = []) {
  if (attachments === null) attachments = [];
  return Promise.all(
    attachments.map(async (a, i) => {
      const signedURL = await Storage.get(a.file.key);
      return {
        ...a,
        source: signedURL,
        title: a.title || `Evidencia ${i + 1}`,
      };
    })
  );
}

/**
 * Componente customizado que encapsula la visualización de fotografías de evidencia
 * de un task.
 *
 * @param {Object} props Propiedades del componente. Refierete al propTypes para más info.
 */
function CustomGridList(props) {
  const isSm = useMediaQuery("(max-width:600px)");
  const isXs = useMediaQuery("(max-width:320px)");
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {props.attachments.length > 0 && (
        <GridList className={classes.gridList} cellHeight={160} cols={isXs ? 1 : isSm ? 2 : 5}>
          {props.attachments.map((tile, index) => (
            <GridListTile key={tile.source} cols={1}>
              <Img
                src={[tile.source, "/img/broken_image.png"]}
                alt={tile.title}
                onClick={props.onAttachmentClick(index)}
              />
            </GridListTile>
          ))}
        </GridList>
      )}
      {props.attachments.length === 0 && <div style={NO_ATTACHMENTS_DIV_STYLE}>Sin evidencias registradas</div>}
    </div>
  );
}

// *****************************************************************************

// Componente principal AttachmentsDialog **************************************

const TASK_STAGE = {
  START: "START",
  PROGRESS: "PROGRESS",
  END: "END",
};

/**
 * Dialog que muestra las evidencias agrupandolas por el status en el fueron registradas en el sistema.
 * Existen tres grupos posibles: Evidencias iniciales, Evidencias de progreso y Evidencias finales
 *
 * @param {Object} props Propiedades del componente. Refierete al propTypes para más info.
 */
function AttachmentsDialog({ open, onClose, attachments, withSections, fullWidth, maxWidth }) {
  const [carrouselAttachments, setcarrouselAttachments] = useState([]);
  const [startAttachments, setStartAttachments] = useState([]);
  const [progressAttachments, setProgressAttachments] = useState([]);
  const [endAttachments, setEndAttachments] = useState([]);
  const [attachmentClicked, setAttachmentClicked] = useState(0);
  const [carrouselOpen, openCarrousel, closeCarrousel] = useBooleanFlag();

  const [loading, _loadAttachments] = useLoadingStatus(loadAttachments);
  const isXs = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    if (open) {
      _loadAttachments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  async function loadAttachments() {
    const startAttachments = [];
    const progressAttachments = [];
    const endAttachments = [];

    const _attachments = await signFileURLs(attachments);

    if (withSections) {
      // Agrupamiendo te las evidencias en las tres etapas posibles de un Task
      _attachments.forEach((item) => {
        if (item.status === "COMPLETED") {
          endAttachments.push(item);
        } else if (item.status === "IN_PROGRESS") {
          progressAttachments.push(item);
        } else {
          startAttachments.push(item);
        }
      });
      setStartAttachments(startAttachments);
      setProgressAttachments(progressAttachments);
      setEndAttachments(endAttachments);
    } else {
      setStartAttachments(_attachments);
    }
  }

  const handleAttachmentClick = (stage) => (attachmentIndex) => (event) => {
    setAttachmentClicked(attachmentIndex);
    if (withSections) {
      if (stage === TASK_STAGE.START) {
        setcarrouselAttachments(startAttachments);
      } else if (stage === TASK_STAGE.PROGRESS) {
        setcarrouselAttachments(progressAttachments);
      } else {
        setcarrouselAttachments(endAttachments);
      }
    } else {
      setcarrouselAttachments(startAttachments);
    }
    openCarrousel();
  };

  return (
    <>
      <ImageCarouselDialog
        open={carrouselOpen}
        onClose={closeCarrousel}
        views={carrouselAttachments}
        currentIndex={attachmentClicked}
      />
      <Dialog open={open} fullScreen={isXs} fullWidth={fullWidth} maxWidth={maxWidth}>
        <DialogTitle>Evidencias</DialogTitle>
        <DialogContent dividers>
          {withSections && (
            <LoadingPanel show={loading} text="Cargando Evidencias, espere un momento...">
              <Typography variant="h6" color="textSecondary" paragraph>
                Inicio de Actividad
              </Typography>
              <CustomGridList
                attachments={startAttachments}
                onAttachmentClick={handleAttachmentClick(TASK_STAGE.START)}
              />
              <Box my={2}>
                <Divider />
              </Box>

              <Typography variant="h6" color="textSecondary" paragraph>
                Desarrollo de Actividad
              </Typography>
              <CustomGridList
                attachments={progressAttachments}
                onAttachmentClick={handleAttachmentClick(TASK_STAGE.PROGRESS)}
              />
              <Box my={2}>
                <Divider />
              </Box>

              <Typography variant="h6" color="textSecondary" paragraph>
                Finalización de Actividad
              </Typography>
              <CustomGridList attachments={endAttachments} onAttachmentClick={handleAttachmentClick(TASK_STAGE.END)} />
            </LoadingPanel>
          )}
          {!withSections && (
            <LoadingPanel show={loading} text="Cargando Evidencias, espere un momento...">
              <CustomGridList
                attachments={startAttachments}
                onAttachmentClick={handleAttachmentClick(TASK_STAGE.START)}
              />
            </LoadingPanel>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="default">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

AttachmentsDialog.defaultProps = {
  attachments: [],
  open: false,
  maxWidth: "md",
  fullWidth: true,
  onClose: () => {},
};

CustomGridList.propTypes = {
  attachments: PropTypes.array,
  onAttachmentClick: PropTypes.func,
};

AttachmentsDialog.propTypes = {
  withSections: PropTypes.bool,
  // Arreglo de evidencias que se mostrarán en el dialog
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      // Estado en que fue almacenada la evidencia. Se usa para agrupar.
      status: PropTypes.string,
      // Información de la evidencia en el bucket de S3
      file: PropTypes.shape({
        key: PropTypes.string,
      }),
    })
  ),
  open: PropTypes.bool,
  maxWidth: PropTypes.string,
  fullWidth: PropTypes.bool,
  onClose: PropTypes.func,
};

export default AttachmentsDialog;
