// Componente para asociar qrs a activos
import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import CheckIcon from "@material-ui/icons/Check";
import CircularProgress from "@material-ui/core/CircularProgress";
import cleanUUID from "util/cleanUUID";
import STATUSES from "../constants/status";

function QRPanel(props) {
  const variant =
    props.status === STATUSES.EMPTY || props.status === STATUSES.NOT_FOUND || props.status === STATUSES.SUCCESS
      ? "outlined"
      : props.status === STATUSES.NOT_AVAILABLE
      ? "outlined"
      : props.status === STATUSES.SELECTED
      ? "contained"
      : "";

  const buttonIcon =
    props.status === STATUSES.EMPTY ||
    props.status === STATUSES.NOT_AVAILABLE ||
    props.status === STATUSES.SUCCESS ||
    props.status === STATUSES.NOT_FOUND ? (
      <img className={props.classes.buttonImages} src="/img/qrcode-green.png" alt="icon" />
    ) : props.status === STATUSES.SELECTED ? (
      <CheckIcon />
    ) : null;

  const buttonText =
    props.status === STATUSES.EMPTY || props.status === STATUSES.NOT_FOUND || props.status === STATUSES.SUCCESS ? (
      " LECTURA DE CÓDIGO"
    ) : props.status === STATUSES.LOADING ? (
      <CircularProgress />
    ) : props.status === STATUSES.NOT_AVAILABLE ? (
      " LECTURA DE CÓDIGO"
    ) : props.status === STATUSES.SELECTED ? (
      " CÓDIGO SELECCIONADO"
    ) : (
      ""
    );

  const alertSeverity =
    props.status === STATUSES.EMPTY || props.status === STATUSES.LOADING || props.status === STATUSES.SUCCESS
      ? "info"
      : props.status === STATUSES.NOT_AVAILABLE || props.status === STATUSES.NOT_FOUND
      ? "warning"
      : props.status === STATUSES.SELECTED
      ? "success"
      : "";

  const alertContet =
    props.status === STATUSES.EMPTY || props.status === STATUSES.SUCCESS
      ? "Aún no ha ingresado un código"
      : props.status === STATUSES.LOADING
      ? "Verificando código QR"
      : props.status === STATUSES.NOT_AVAILABLE
      ? props.auxAsset
        ? `El QR ya esta vinculado al activo ${props.auxAsset?.material?.name}${
            props.auxAsset?.material?.code ? `, código: ${props.auxAsset?.material?.code}` : ""
          }${
            props.auxAsset?.material?.serial && props.auxAsset?.material?.serial !== "DEFAULT_SERIE"
              ? `, código: ${props.auxAsset?.material?.code}`
              : ""
          }, intenta con un código diferente. `
        : "El QR no existe en la plataforma"
      : props.status === STATUSES.SELECTED
      ? `Código: ${cleanUUID(props.qrCode?.id)}`
      : props.status === STATUSES.NOT_FOUND
      ? "No se ha encontrado codigo QR en la imagen, por favor vuelva a intentarlo."
      : "";

  return (
    <>
      <Button
        className={props.classes.buttons}
        onClick={props.selectQRCode}
        color="primary"
        variant={variant}
        fullWidth
      >
        {buttonIcon}
        {buttonText}
      </Button>
      <Alert severity={alertSeverity}>{alertContet}</Alert>
    </>
  );
}

QRPanel.propTypes = {
  attributesBtnClick: PropTypes.func,
  selectQRCode: PropTypes.func,
  status: PropTypes.oneOf([Object.values(STATUSES)]),
  qrCode: PropTypes.shape(),
  classes: PropTypes.shape(),
};

QRPanel.defaultProps = {};

export default QRPanel;
