export const updateIntegrationRequest = /* GraphQL */ `
  mutation UpdateIntegrationRequest($input: UpdateIntegrationRequestInput!) {
    updateIntegrationRequest(input: $input) {
      id
      status
      email
      firstname
      middlename
      lastnamep
      lastnamem
      organization
      comments
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
