import { AssetType } from "models";

export function generateAssetTypeUpdateMutation(assets = [], added = [], deleted = []) {
  if (!added.length && !deleted.length) {
    return null;
  }

  const assetsInputs = getUpdateAssetInputs(assets, added, deleted);
  let mutation = "";
  let updateParams = {};
  let mutationsParams = [];

  assetsInputs.forEach((input, index) => {
    updateParams[`input${index}`] = input;
    mutationsParams.push(`$input${index}: UpdateAssetInput!, `);
    mutation += `
      updateAsset${index}: updateAsset(input: $input${index}){
        code
        id
        material {
          id
          code
          name
          _version
          _lastChangedAt
          _deleted
        }
        embeddedAttributes {
          attributeID
          id
          name
          value
        }
        tracking {
          id
          status
          ikaID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        quantity
        serial
        locationCode
        trackingID
        type
        _version
        _lastChangedAt
        _deleted
      }
    `;
  });

  mutationsParams = mutationsParams.join(",");
  mutation = `mutation UpdateAssets(${mutationsParams}) {
      ${mutation}
    }
  `;

  return {
    mutation,
    params: updateParams,
  };
}

function getUpdateAssetInputs(assets, added, deleted) {
  let inputs = [];

  added.forEach((materialId) => {
    const asset = assets.find((a) => a.materialId === materialId);
    inputs.push({
      id: asset.assetId,
      _version: asset.assetVersion,
      type: AssetType.ALLOCATED,
    });
  });

  deleted.forEach((asset) => {
    inputs.push({
      id: asset.assetId,
      _version: asset.assetVersion,
      type: AssetType.RECEIVED,
    });
  });

  return inputs;
}
