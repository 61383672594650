import React from "react";
import PropTypes from "prop-types";
import EvidenceViewV2Support from "components/common/EvidenceViewV2Support";
import { TaskStatus } from "models";
import { TASK_TYPES } from "constant/task/types";

const InitialEvidence = ({
  taskId,
  attachmentPrefix,
  evidenceStatus,
  evidencesMinRules,
  evidenceRef,
  initialEvidences,
  evidenceLoading,
  setEvidenceLoading,
  onAddOptimisticAttachments,
  onDeleteOptimisticAttachment,
  taskType,
  evidenceListHeight,
  position,
  loadingPosition,
  positionError,
}) => {
  return (
    <>
      <div>
        Para iniciar el proceso de soporte adjunta evidencias fotográficas del activo que recibirá el soporte o de la
        atención brindada.
      </div>

      <EvidenceViewV2Support
        taskId={taskId}
        prefix={attachmentPrefix}
        status={evidenceStatus}
        min={evidencesMinRules}
        inputName="begin_inputFile"
        ref={evidenceRef}
        attachments={initialEvidences}
        loading={evidenceLoading}
        onLoading={setEvidenceLoading}
        onEvidenceChange={onAddOptimisticAttachments}
        onDeleteItem={onDeleteOptimisticAttachment}
        taskType={taskType}
        evidenceListHeight={evidenceListHeight}
        position={position}
        loadingPosition={loadingPosition}
        positionError={positionError}
      />
    </>
  );
};

InitialEvidence.propTypes = {
  taskId: PropTypes.string.isRequired,
  attachmentPrefix: PropTypes.string.isRequired,
  evidenceStatus: PropTypes.oneOf([TaskStatus.SCHEDULED]).isRequired,
  evidencesMinRules: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  evidenceRef: PropTypes.any,
  initialEvidences: PropTypes.array,
  evidenceLoading: PropTypes.bool,
  setEvidenceLoading: PropTypes.func,
  onAddOptimisticAttachments: PropTypes.func,
  onDeleteOptimisticAttachment: PropTypes.func,
  taskType: PropTypes.oneOf(Object.values(TASK_TYPES)),
  evidenceListHeight: PropTypes.string,
  position: PropTypes.object,
  loadingPosition: PropTypes.bool,
  positionError: PropTypes.object,
};

InitialEvidence.defaultProps = {
  taskId: "",
  attachmentPrefix: "",
  evidenceStatus: TaskStatus.SCHEDULED,
  evidencesMinRules: 1,
  evidenceRef: null,
  initialEvidences: [],
  evidenceLoading: false,
  setEvidenceLoading: () => { },
  onAddOptimisticAttachments: () => { },
  deleonDeleteOptimisticAttachmenteAttachment: () => { },
  taskType: TASK_TYPES.SUPPORT,
  evidenceListHeight: "auto",
};

export default InitialEvidence;
