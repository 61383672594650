import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  alert: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    // padding: 0,
  },
  message: {
    display: "flex",
    alignItems: "center",
    wordBreak: "break-word",
  },
  icon: {
    fontSize: "2rem",
  },
}));

export default useStyles;
