import React, { useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import useHeaderTitle from "hooks/useHeaderTitle";
import GeneralSection from "./components/GeneralSection";
import RequestSection from "./components/RequestSection";
import SettingsSection from "./components/SettingsSection";

function ManageEngineSettingsCard() {
  const classes = useStyles();
  useHeaderTitle("Configuraciones de Integración");
  const [integrationEnabled, setIntegrationEnabled] = useState(false);

  function handleIntegrationEnabledChange(integrationEnabled) {
    setIntegrationEnabled(integrationEnabled);
  }

  return (
    <>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography className={classes.heading} variant="h6">
            Manage Engine
          </Typography>
          <Typography className={classes.secondaryHeading}>
            Integración con sistema de seguimiento de Tickets de Manage Engine
          </Typography>
        </AccordionSummary>
        <GeneralSection classes={classes} onIntegrationEnabledChange={handleIntegrationEnabledChange} />
        <RequestSection classes={classes} integrationEnabled={integrationEnabled} />
        <SettingsSection classes={classes} integrationEnabled={integrationEnabled} />
      </Accordion>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  accordionDetails: { display: "flex", flexDirection: "column" },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

export default ManageEngineSettingsCard;
