import useAPIHelpers from "./useAPIHelpers";
import { Logger } from "@aws-amplify/core";
import { UPDATE_TYPES } from "constant/Maintenance";
import * as ROLES from "constant/roles";
import * as SETTINGS from "constant/settings";

const logger = new Logger("Maintenance-detail-view-helpers");

export { useAPIHelpers };

export function maintenanceReducer(state, action) {
  let newState;
  logger.debug("maintenanceReducer", { state, action });
  if (typeof action === "function") {
    action = action(state);
  }

  const { type, payload } = action;

  switch (type) {
    case UPDATE_TYPES.ALL:
      newState = { ...state, ...payload };
      break;
    case UPDATE_TYPES.INFO:
      newState = { ...state, maintenanceInfo: payload };
      break;
    case UPDATE_TYPES.ASSETS:
      newState = { ...state, assets: payload };
      break;
    case UPDATE_TYPES.MATERIALS:
      newState = { ...state, materials: payload };
      break;
    case UPDATE_TYPES.OBSERVATIONS:
      newState = { ...state, observations: payload };
      break;
    case UPDATE_TYPES.CUSTOM_REDUCER:
      if (typeof payload === "function") {
        newState = payload(state);
      }
      break;
    default:
      throw new Error(`Unsupported action type: ${type}`);
  }

  handleValidateState(newState);
  return newState;
}

function handleValidateState(newState) {
  const validMaintenaceInfo = newState.hasOwnProperty("maintenanceInfo");
  const validAssets = newState.hasOwnProperty("assets");
  const validMaterials = newState.hasOwnProperty("materials");
  const validObservations = newState.hasOwnProperty("observations");
  const isValid = validMaintenaceInfo && validAssets && validMaterials && validObservations;

  if (!isValid) {
    const error = "Información invalida en maintenanceReducer";
    logger.error(error, newState);
    throw new Error(error);
  }
  return isValid;
}

/**
 * Función auxiliar para buscar la existencia del ID de un usuario en un arreglo de usuarios autorizados. *
 *
 * @param {string} userId Id del usuario a buscar dentro del arreglo
 * @param {Array<string>} usersAllowed Arreglo de IDs de usuarios autorizados
 * @returns
 */


function isUserAllowed(userId = "", usersAllowed = []) {
  return usersAllowed.includes(userId)
}

/**
 * Función para validar la regla del negocio que limita el flujo de progreso de mateniento en el caso Web.
 * La regla establece que:
 *
 *  Si un usuario es Técnico (support-engineer)
 *    Si la plataforma es web y
 *    Si no se encuentra autorizado en la configuración de sistema para el uso del flujo web
 *
 *  Entonces no podrá utilizar el flujo de inicio y continuación del flujo de mantenimiento.
 */
export function checkProgressFlowAllowed(userRole = "", userId = "", usersAllowed = [], platform = "", isSupportEngineer) {

  if (usersAllowed.includes(SETTINGS.MAINTENANCE.OPTIONS.ENABLE_ALL)) {
    return true;
  }

  if (userRole === ROLES.SUPPORT_ENGINEERS && platform === "web" && !isUserAllowed(userId, usersAllowed) && !isSupportEngineer) {
    return false;
  }

  return true;
}
