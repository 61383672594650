import React from "react";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";

import FindInPageIcon from "@material-ui/icons/FindInPage";

import AppBar from "../HideOnScrollAppBar";

function NotFoundView() {
  return (
    <>
      <AppBar />
      <Box my={3}>
        <Container>
          <Typography variant="h1" align="center">
            404
          </Typography>
          <Typography variant="h4" paragraph align="center">
            Nos hemos extraviado
          </Typography>
          <br />
          <Typography
            variant="subtitle1"
            component="p"
            paragraph
            align="center"
          >
            Lo sentimos, la ruta especificada no existe.
          </Typography>
          <Typography align="center">
            <FindInPageIcon style={{ fontSize: 90 }} />
          </Typography>
        </Container>
      </Box>
    </>
  );
}

export default NotFoundView;
