import { getTimeFromMS } from 'components/Timer/helpers';
import { TimerEventType, } from "models";


import dayjs from "dayjs";
 
export const now = dayjs();

export function getDate(date) {
    return dayjs(date);
};

export function getDiffDay(date, dateOld, unit) {
    return date.diff(dateOld, unit);
}

export function getDateForUnid(date, dateOld) {
    const minute = getDiffDay(dayjs(date), dayjs(dateOld), 'minute');
    const hour = getDiffDay(dayjs(date), dayjs(dateOld), 'hour');
    const day = getDiffDay(dayjs(date), dayjs(dateOld), 'day');
    const second = getDiffDay(dayjs(date), dayjs(dateOld), 'second');

    return {
        second: formatShowTime(second),
        minute: formatShowTime(minute),
        hour: formatShowTime(hour),
        day: formatShowTime(day),
    }
}

export function formatShowTime(time){
    return time < 10 ? `0${time}` : time;
}

export function getDurationForUnid(ms) {
    const dataTime  = getTimeFromMS(ms);
    return {
        second: dataTime.seconds,
        minute: dataTime.minutes,
        hour: dataTime.hours,
        day: dataTime.days, 
    }
}

export function getTimeToShow(times) {
    const timeUnits = [
        { unit: 'day', label: 'día' },
        { unit: 'hour', label: 'hr' },
        { unit: 'minute', label: 'min' },
        { unit: 'second', label: 'seg' },
    ]

    for (const timeUnit of timeUnits) {
        if (times[timeUnit.unit] && times[timeUnit.unit] > 0) {
            return `${times[timeUnit.unit]}${timeUnit.label === 'día' && times[timeUnit.unit] > 0 ? timeUnit.label + 's' : timeUnit.label} `;
        }
    }

    return 'Sin información';
}

export async function getDeviceDetails(device) {
    let isWeb = false;
    const info = device.getInfo();

    if (info.platform === "web") {
        isWeb = true;
    }

    return isWeb;
}

export function getTimerLogs(logs, taskInfo) {

    let logsData = logs ? logs.filter(log => [TimerEventType.PAUSE, TimerEventType.END].includes(log.embeddedCause.type)): []
    
    logsData = logsData.map((log) => {
        const data = {
            createdAt: '',
            finishAt: '',
            type: '',
            description: '',
            timeShow: '',
            times: '',
        }

        if (log.embeddedCause.type === TimerEventType.END) {
            data.createdAt = getDate(taskInfo.startedAt);
            data.finishAt = getDate(taskInfo.endedAt);
            data.times = getDurationForUnid(taskInfo.effectiveTime);
        } else {
            data.createdAt = getDate(log.createdAt);
            data.finishAt = data.createdAt.add(log.duration, 'millisecond');
            data.times = getDateForUnid(data.finishAt, data.createdAt);
        }

        data.createdAt = data.createdAt.format('DD/MMMM/YYYY  hh:mm a', 'es')
        data.finishAt = data.finishAt.format('DD/MMMM/YYYY  hh:mm a', 'es')
        data.type = log.embeddedCause.type;
        data.description = log.description;
        data.timeShow = getTimeToShow(data.times)

        return data
    })

    return logsData.filter(log => log.timeShow != 'Sin información')
}

export function isMobile ()
{   let isMobile = false;
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        isMobile = true;
    }
    return isMobile;
}