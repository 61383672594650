import React, { useState, useRef, useEffect } from "react";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { Logger } from "@aws-amplify/core";
import { green } from "@material-ui/core/colors";
import { CSVReader, jsonToCSV } from "react-papaparse";
import GetAppIcon from "@material-ui/icons/GetApp";
import PublishIcon from "@material-ui/icons/Publish";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import WarningIcon from "@material-ui/icons/ReportProblemOutlined";
import DoneIcon from "@material-ui/icons/Done";
import { yellow } from "@material-ui/core/colors";

import DownloadQRButton from "components/common/DownloadQRButton";
import FloatingButton from "components/FloatingButton";
import Button from "components/custom/Button";
import MaterialTable from "components/custom/MaterialTable";
import ConfirmationDialog from "components/ConfirmationDialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DetailTitle from "components/DetailTitle";
import useLoadingStatus from "hooks/useLoadingStatus";
import useBooleanFlag from "hooks/useBooleanFlag";
import useNotifier from "hooks/useNotifier";
import useHeaderTitle from "hooks/useHeaderTitle";

import { uploadFileList } from "util/file";
import { WAREHOUSE_ASSET_INCOME } from "constant/route/warehouse";
import { generateTableData, normalizeTableColumns, transformHeader } from "util/csvLoad";
import * as graphql from "./helpers/graphql";
import CustomerProjectSelector from "components/CustomerProjectSelector";
import AssetsDialogLoadingError from "../AssetsDialogLoadingError";
import * as XLSX from 'xlsx';

const logger = new Logger("AssetsCSVLoadView");
const whiteLabelApp = process.env.REACT_APP_WHITE_LABEL_APP

export default function AssetsCSVLoadView() {
  useHeaderTitle("Activos - Nuevo Ingreso");
  const classes = useStyles();
  const userId = useSelector(({ session }) => session.userId);
  const [assetsList, setAssetsList] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [requiredColumns, setRequiredColumns] = useState([]);
  const [uniqueColumns, setUniqueColumns] = useState([]);
  const uploadButtonRef = useRef(null);
  const { showError, showMessage } = useNotifier();
  const [showNotification, setShowNotification] = useState(false);
  const [uploading, _uploadAssets] = useLoadingStatus(uploadAssets);
  const [confirmDialogOpen, openConfirmDialog, closeConfirmDialog] = useBooleanFlag();
  const [showRemoveDialog, openRemoveDialog, closeRemoveDialog] = useBooleanFlag();
  const [selectedProjectId, setSelectedProjectId] = useState("");
  const [assetsCreatedIds, setAssetsCreatedIds] = useState([]);

  const [isAssetsDialogLoadingErrorOpen, setIsAssetsDialogLoadingErrorOpen] = useState(false);

  useEffect(() => {
    const { requiredCols, uniqueCols, tableCols } = normalizeTableColumns(TABLE_COLUMNS, { useFieldProp: true });
    setRequiredColumns(requiredCols);
    setUniqueColumns(uniqueCols);
    setTableColumns(tableCols);
  }, []);

  const openFilePicker = (e) => {
    if (uploadButtonRef.current) {
      uploadButtonRef.current.open(e);
    }
  };

  const initTableData = (rows) => {
    if (rows.length <= 500) {
      let tableData = generateTableData(rows, tableColumns, requiredColumns, uniqueColumns);
      tableData = sortRowsByValidationProps(tableData);
      setAssetsList(tableData);

      const excludedRows = rows.length - tableData.length;
      if (excludedRows === 1) {
        showMessage(`Se excluyó ${excludedRows} registro con columnas requeridas incompletas.`);
      } else if (excludedRows > 1) {
        showMessage(`Se excluyeron ${excludedRows} registros con columnas requeridas incompletas.`);
      }
    } else {
      setIsAssetsDialogLoadingErrorOpen(true);
      confirmRemoveFile();
    }
  };

  // Showing error
  const showFileError = (error) => {
    showError(error);
  };

  const confirmRemoveFile = (e) => {
    if (uploadButtonRef.current) {
      uploadButtonRef.current.removeFile(e);
    }
  };

  const removeFile = () => {
    setAssetsList([]);
    closeRemoveDialog();
  };

  const updateMaterial = async (newMaterialData, oldMaterialData) => {
    const newMaterials = [...assetsList];
    const index = newMaterials.findIndex(({ tableData }) => tableData.id === oldMaterialData.tableData.id);
    newMaterials[index] = newMaterialData;
    const rows = transformRowsForValidation(newMaterials);
    const validatedRows = generateTableData(rows, tableColumns, requiredColumns, uniqueColumns);
    setAssetsList(validatedRows);
  };

  const deleteMaterial = async (oldMaterialData) => {
    const newMaterials = [...assetsList];
    const index = newMaterials.findIndex(({ tableData }) => tableData.id === oldMaterialData.tableData.id);
    newMaterials.splice(index, 1);
    setAssetsList(newMaterials);

    if (newMaterials.length === 0) {
      confirmRemoveFile();
    }
  };

  const transformRowsForValidation = (rows) => {
    return rows.map(({ hasRequiredColumns, hasDuplicatedColumns, ...row }) => {
      const newData = Object.keys(row).reduce((rowData, key) => {
        const newKey = transformHeader(key);
        rowData[newKey] = row[key];
        return rowData;
      }, {});
      newData.hasRequiredColumns = hasRequiredColumns;
      newData.hasDuplicatedColumns = hasDuplicatedColumns;
      return { data: newData };
    });
  };

  const sortRowsByValidationProps = (rows) => {
    return rows.sort((a, b) => {
      const { hasRequiredColumns: aRequired, hasDuplicatedColumns: aDuplicated } = a;
      const { hasRequiredColumns: bRequired, hasDuplicatedColumns: bDuplicated } = b;
      const aInvalid = !aRequired || aDuplicated;
      const bInvalid = !bRequired || bDuplicated;
      return aInvalid === bInvalid ? 0 : bInvalid ? 1 : -1;
    });
  };

  const sendMaterials = () => {
    const error = validateMaterials();
    if (error) {
      showError(error);
    } else {
      openConfirmDialog();
    }
  };

  const validateMaterials = () => {
    if (!assetsList.length) {
      return "Error al cargar registros. Cargue un archivo CSV con los activos a ingresar.";
    }
    const withInvalidRows = assetsList.some(({ hasRequiredColumns, hasDuplicatedColumns }) => {
      return !hasRequiredColumns || hasDuplicatedColumns;
    });
    if (withInvalidRows) {
      return "Verifique que los registros tengan las columnas requeridas y que no existan series duplicadas.";
    }
  };

  async function uploadAssets(files, comments) {
    try {
      const csvBlob = getCsvBlob();
      const [csvFile] = createFileList([], csvBlob, userId);
      const [uploadedFile] = await uploadFileList([csvFile]);
      const [originalFile] = uploadButtonRef.current.inputFileRef.current.files;
      const uploadResult = await graphql.loadCSVData({
        objectKey: uploadedFile.key,
        projectId: selectedProjectId,
        fileName: originalFile.name,
      });
      logger.debug("upload result ", uploadResult);
      setAssetsCreatedIds(uploadResult.newAssets);
      setShowNotification(true);
    } catch (error) {
      showError("Ocurrió un error al cargar los activos");
      console.error(error);
    } finally {
      closeConfirmDialog();
    }
  }

  const getCsvBlob = () => {
    const assets = assetsList.map(({ tableData, ...rest }) => ({ ...rest }));
    const csv = jsonToCSV(assets);
    return new Blob([csv], { type: "text/csv;charset=utf-8;" });
  };

  // Creating csv assets template with custom fields
  const downloadTemplate = () => {

    let headersTitles = [
      'NOMBRE', 
      'CODIGO_MATERIAL', 
      'DESCRIPCION', 
      'FABRICANTE', 
      'NO_SERIE', 
      'COMENTARIOS',
      'NOMENCLATURA',
    ]

    //Conditional to show in CSV assets template, GS custom fields if white label react app is equal to 'grupo-siayec';
    if(whiteLabelApp === 'scjn' ||  whiteLabelApp === 'demo'){
      headersTitles.push('COMPONENTE', 'PARTE', 'MODELO')
    }

    let newBook = XLSX.utils.book_new();
    let sheet = XLSX.utils.json_to_sheet([], { 
      header: headersTitles
    })

    XLSX.utils.book_append_sheet(newBook, sheet, 'assets_template');
    XLSX.writeFile(newBook,"assets_template.csv" );
  }

  const handleCloseNotification = (e) => {
    setShowNotification(false);
    setAssetsList([]);
    setAssetsCreatedIds([]);
    confirmRemoveFile(e);
  };

  const hasData = assetsList.length > 0;

  return (
    <>
      <DetailTitle title="Carga de activos por archivo" to={WAREHOUSE_ASSET_INCOME} />
      <Typography variant="body2" paragraph>
        <br />
        En este apartado puede realizar la carga de activos para las actividades mediante un archivo en formato CSV. De
        forma opcional, seleccione un cliente y un proyecto al que será asociada los activos cargados.
        <br />
        <br />
        Cargue un archivo con el formato especificado y revise los registros ingresados. Puede modificar los registros
        cargados en caso de ser necesario. Una vez revisados los datos puede confirmar su ingreso al sistema.
      </Typography>
      <Typography paragraph>
        <strong>
          Las columnas marcadas con * son requeridas en los registros cargados. Los registros con columnas requeridas
          incompletas y con series duplicadas deben ser corregidos o eliminados para proceder con la carga.
        </strong>
      </Typography>
      <div className={classes.tableTopActions}>
        <Button
          onClick={downloadTemplate}
          className={classes.downloadButton}
          variant="outlined"
          color="primary"
          startIcon={<GetAppIcon />}
        >
          Descargar plantilla
        </Button>
      </div>
      <Paper className={classes.mainSection}>
        <CustomerProjectSelector onChangeProject={setSelectedProjectId} />
      </Paper>
      <Paper className={classes.mainSection}>
        <MaterialTable
          title="Activos cargados"
          options={{
            exportButton: false,
            columnsButton: false,
            actionsColumnIndex: 1,
            rowStyle: ({ hasDuplicatedColumns, hasRequiredColumns }) => {
              return hasDuplicatedColumns || !hasRequiredColumns ? { backgroundColor: yellow[100] } : {};
            },
          }}
          columns={tableColumns}
          data={assetsList}
          editable={{ onRowUpdate: updateMaterial, onRowDelete: deleteMaterial }}
        />
        <Grid item container xs={12} justify="flex-end" className={classes.tableBottomGrid}>
          <Button onClick={openRemoveDialog} size="large" className={classes.tableBottomAction} disabled={!hasData}>
            Borrar Registros
          </Button>
          <Button
            onClick={sendMaterials}
            size="large"
            color="primary"
            className={classes.tableBottomAction}
            disabled={!hasData}
          >
            Cargar Registros
          </Button>
        </Grid>
      </Paper>
      <CSVReader
        ref={uploadButtonRef}
        onFileLoad={initTableData}
        onError={showFileError}
        onRemoveFile={removeFile}
        config={{ header: true, skipEmptyLines: "greedy", transformHeader }}
        noDrag
        noProgressBar
      >
        {({ file }) => {
          if (file?.name) {
            return (
              <ConfirmationDialog
                open={showRemoveDialog}
                title="Confirmación"
                onConfirm={confirmRemoveFile}
                onCancel={closeRemoveDialog}
              >
                ¿Deseas eliminar la información de los activos y el lugar?
              </ConfirmationDialog>
            );
          }
          return (
            <>
              <FloatingButton
                bottom={15}
                right={15}
                color="primary"
                variant="extended"
                aria-label="Agregar Integrante"
                onClick={openFilePicker}
              >
                <PublishIcon />
                Subir archivo CSV
              </FloatingButton>
            </>
          );
        }}
      </CSVReader>
      <ConfirmationDialog
        open={confirmDialogOpen}
        title="Confirmación"
        onConfirm={_uploadAssets}
        onCancel={closeConfirmDialog}
        loading={uploading}
      >
        {!uploading && "Esta a punto de ingresar activos al sistema, ¿Desea continuar?."}
        {uploading && "Cargando información. Espere un momento..."}
      </ConfirmationDialog>
      <Dialog open={showNotification} maxWidth="lg">
        <DialogTitle>¡Carga de activos exitosa!</DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            {`Se han cargado ${assetsCreatedIds.length} activos al sistema. Ahora podrá utilizarlos 
            para las actividades que requiera.`}
          </Typography>
          <Typography variant="body2">
            Descarga los QR de los activos añadidos haciendo click en <strong>DESCARGAR QRS</strong>.
          </Typography>
          <br />
          <Typography variant="body2">¡Siga utilizando Indika FSM!</Typography>
        </DialogContent>
        <DialogActions>
          <DownloadQRButton
            assetID={assetsCreatedIds}
            content="Descargar QRs"
            onlineTitle="Descargar QRs de activos"
            offlineTitle="La descarga de QRs require conexión a internet"
            color="primary"
            size="medium"
            tooltipStyle={{ width: "auto" }}
            afterDownload={handleCloseNotification}
          />
          <Button onClick={handleCloseNotification} color="default">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      {/* AssetsDialogLoadingError DIALOG */}
      <AssetsDialogLoadingError
        open={isAssetsDialogLoadingErrorOpen}
        onClose={() => setIsAssetsDialogLoadingErrorOpen(false)}
        fullWidth={false}
        maxWidth={false}
      ></AssetsDialogLoadingError>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  mainSection: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  tableTopActions: {
    width: "100%",
    // margin: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  downloadButton: {
    marginLeft: "auto",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: theme.palette.getContrastText(green[900]),
  },
  tableBottomGrid: {
    paddingTop: theme.spacing(4),
  },
  tableBottomAction: {
    marginLeft: theme.spacing(3),
  },
}));

const createFileList = (files, csvBlob, userId) => {
  const timestamp = dayjs().unix();
  const fileList = files.map(({ name, preview }, index) => {
    const pointIndex = name.lastIndexOf(".");
    const extension = name.substr(pointIndex);
    const fileName = `uploads/${userId}/at_${dayjs().format("YYYY-MM-DD")}_${timestamp}_${index}${extension}`;
    return { name: fileName, blob: preview };
  });
  const csvName = `etl/flatfiles/csvload/dl-${timestamp}.csv`;
  fileList.push({ name: csvName, blob: csvBlob });
  return fileList;
};

let TABLE_COLUMNS = [
  {
    title: "Estatus",
    field: "CODIGO_MATERIAL",
    editable: "never",
    render: ({ hasDuplicatedColumns, hasRequiredColumns }) => {
      if (hasDuplicatedColumns || !hasRequiredColumns) {
        return (
          <Tooltip title="Registro inválido">
            <WarningIcon style={{ color: yellow[700] }} />
          </Tooltip>
        );
      } else {
        return (
          <Tooltip title="Registro válido">
            <DoneIcon color="primary" />
          </Tooltip>
        );
      }
    },
  },
  { title: "Código", field: "CODIGO_MATERIAL", required: true },
  { title: "Nombre", field: "NOMBRE", required: true },
  { title: "Fabricante", field: "FABRICANTE" },
  { title: "Serie", field: "NO_SERIE", required: true, unique: true },
  { title: "Descripción", field: "DESCRIPCION" },
  { title: "Comentarios", field: "COMENTARIOS" },
  { title: "Nomenclatura", field: "NOMENCLATURA"},
];

// Conditional to show in assets table headers, GS custom fields if white label react app is equal to 'grupo-siayec';
if(whiteLabelApp === 'scjn' || whiteLabelApp === 'demo'){
  TABLE_COLUMNS.push(
    { title: "Componente", field: "COMPONENTE"},
    { title: "Parte", field: "PARTE"},
    { title: "Modelo", field: "MODELO"},
  )
}