export enum TaskDocumentType {
  MAINTENANCE_REPORT = "MAINTENANCE_REPORT",
  SUPPORT_COMPLETED_REPORT = "SUPPORT_COMPLETED_REPORT",
  SUPPORT_VALIDATED_REPORT = "SUPPORT_VALIDATED_REPORT",
  INSTALLATION_REPORT = "INSTALLATION_REPORT"
}

export enum ElementByType {
  task = "task",
  ticket = "ticket",
}

export enum ReportType {
  INSTALLATION = "installation",
  SUPPORT = "support",
  MAINTENANCE = "maintenance",
} 

export interface DocumentPDFInput {
  elementBy: ElementByType;
  elementId: string;
  documentType: TaskDocumentType;
}
