import API, { graphqlOperation } from "@aws-amplify/api";
import * as mutations from "graphql-custom/mutations";
import { Logger } from "@aws-amplify/core";

const logger = new Logger("helpers/graphql");

async function updateAssets(assets = []) {
  let args = ``;
  let operations = ``;
  let params = {};

  assets.forEach((id, index) => {
    args = `${args} $input${index}: UpdateAssetInput!,`;
    operations = `${operations} up${index}: updateAsset(input: $input${index}) {
          id
          code
          serial
          quantity
          type
          enabled
          material {
            id
            name
            code
            _version
            _deleted
            _lastChangedAt
          }
          trackingID
          _version
          _deleted
          _lastChangedAt
        },`;
    params[`input${index}`] = { id };
  });
  args = args.slice(0, -1);
  operations = operations.slice(0, -1);

  let mutation = `mutation UpdateAssets(${args}){
    ${operations}
  }`;

  return API.graphql(graphqlOperation(mutation, params));
}

async function updateMaterials(materials = []) {
  let args = ``;
  let operations = ``;
  let params = {};

  materials.forEach((id, index) => {
    args = `${args} $input${index}: UpdateMaterialInput!,`;
    operations = `${operations} up${index}: updateMaterial(input: $input${index}) {
          id
          name
          code
          description
          manufacturer
          trackable
          enabled
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        },`;
    params[`input${index}`] = { id };
  });
  args = args.slice(0, -1);
  operations = operations.slice(0, -1);

  let mutation = `mutation UpdateMaterials(${args}){
    ${operations}
  }`;

  return API.graphql(graphqlOperation(mutation, params));
}

export async function loadCSVData({ objectKey, projectId, fileName }) {
  logger.debug("loadCSVData: Iniciando procesado de CSV activos...");
  const loadResult = await API.graphql(
    graphqlOperation(mutations.lambdaCSVDataLoad, {
      input: { objectKey, projectId, fileName, dataType: "ASSETS" },
    })
  );
  logger.debug("loadCSVData: Procesado de CSV activos finalizado...");

  const { newAssets, newMaterials, newTrackings } = JSON.parse(loadResult.data.lambdaCSVDataLoad.body);

  if (newAssets.length > 0) {
    logger.debug("loadCSVData: Actualizando activos nuevos para disparar sincronización...");
    const updateAssetsResult = await updateAssets(newAssets);
    logger.debug("loadCSVData: Activos actualizados...");
  }

  if (newMaterials.length > 0) {
    logger.debug("loadCSVData: Actualizando materiales nuevos para disparar sincronización...");
    const updateAssetsResult = await updateMaterials(newMaterials);
    logger.debug("loadCSVData: Materiales actualizados...");
  }

  return { newAssets, newMaterials, newTrackings };
}
