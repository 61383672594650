export const getAttachmentAsset = /* GraphQL */ `
  query GetAttachmentAsset($id: ID!) {
    getAttachmentAsset(id: $id) {
      id
      _version
      _lastChangedAt
      _deleted
      createdAt
      updatedAt
      loadType
      platform
      source
      status
      file {
        key
        bucket
        region
      }
    }
  }
`;
