import React, { useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import MaterialTable from "components/custom/MaterialTable";
import Section from "components/Section";
import TableCellMobileFormat from "components/custom/TableCellMobileFormat";
import DownloadQRButton from "components/common/DownloadQRButton";
import { Box, useMediaQuery, useTheme, Typography } from "@material-ui/core";
import maintenanceStyles from "../../MaintenanceStyles";
import ListIcon from "@material-ui/icons/List";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import useTableMobileStyles from "hooks/useTableMobileStyles";
import cleanUUID from "util/cleanUUID";
import { VISUALIZATION_TYPES } from "constant/visualizationTypes";
import { TaskStatus } from "models";
import dayjs from "dayjs";
import useOnlineStatus from "@rehooks/online-status";
import exportCsv from "util/exportCsv";
import { validateOneAssetEvidences } from "../../MaintenanceProgressDialog/helpers/lib";

import { formatAssetsSeries, handleGetName, handleGetMaterialCode } from "util/text";

import { filterDeletedItems } from "util/lists";

import AssetAttributesDialog from "components/AssetAttributesDialog";
import MaintenanceEvidenceDialog from "../../MaintenanceEvidenceDialog";

export default function MaintanceDetailAssets(props) {
  const { maintenanceState, loading } = props;

  const [assetRowData, setAssetRowData] = useState(null);
  const { maintenanceInfo, assets } = maintenanceState;
  const { id: taskId } = useParams();
  const cleanedID = cleanUUID(taskId);

  const isOnline = useOnlineStatus();
  const classes = maintenanceStyles();
  const tableMobileStyles = useTableMobileStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const matches1024 = useMediaQuery(theme.breakpoints.down(1024));

  const status = maintenanceInfo?.status;
  //dialogs
  const [showAssetEvidenceDialog, setShowAssetEvidenceDialog] = useState(false);
  const [showAssetAttributesDialog, setShowAssetAttributesDialog] = useState(false);

  function handleOnAssetEvidenceDialogOpen({ id, attachments, ...rest }) {
    attachments = attachments?.items || [];
    const beginEvidences = attachments.filter((evidence) => evidence.status === TaskStatus.SCHEDULED);
    const progressEvidences = attachments.filter((evidence) => evidence.status === TaskStatus.IN_PROGRESS);
    const endEvidences = attachments.filter((evidence) => evidence.status === TaskStatus.FINALIZED);

    setAssetRowData({
      id,
      attachments: { begin: beginEvidences, progress: progressEvidences, end: endEvidences },
      ...rest,
    });
    setShowAssetEvidenceDialog(true);
  }

  function handleOnAssetAttributesDialogOpen({ asset }) {
    setAssetRowData(asset);
    setShowAssetAttributesDialog(true);
  }

  function resetEvidenceDialog() {
    setShowAssetEvidenceDialog(false);
    setAssetRowData(null);
  }

  function resetAttributesDialog() {
    setAssetRowData(null);
    setShowAssetAttributesDialog(false);
  }

  return (
    <>
      <Box mb={2} className={classes.sectionSpacing}>
        <Section>
          <div id="detail-view-asset-table" className={tableMobileStyles.root}>
            <MaterialTable
              title="Activos"
              columns={matches ? COLUMNS_ASSETS_MOBILE : COLUMNS_ASSETS}
              data={assets}
              actions={[
                {
                  icon: PhotoLibraryIcon,
                  onClick: (event, rowData) => handleOnAssetEvidenceDialogOpen(rowData),
                  tooltip: "Evidencias",
                  hidden: status === TaskStatus.SCHEDULED,
                },
                (rowData) => ({
                  // eslint-disable-next-line react/display-name
                  CustomComponent: () => (
                    <DownloadQRButton assetID={rowData.asset.id} style={{ padding: "12px", borderRadius: "50%" }} />
                  ),
                }),
                {
                  icon: ListIcon,
                  onClick: (event, rowData) => handleOnAssetAttributesDialogOpen(rowData),
                  tooltip: "Atributos del activo",
                },
              ]}
              options={{
                columnsButton: matches1024 ? false : true,

                exportButton: Boolean(assets?.length) && isOnline,
                exportCsv: (columnList, initialData) =>
                  exportCsv(
                    COLUMNS_ASSETS,
                    initialData,
                    `activos_detalle-${cleanedID}-${dayjs().format("YYYY-MM-DD-HH:mm")}`
                  ),
                actionsCellStyle: { paddingLeft: "calc(4px + 16px)", paddingRight: "4px" },
                rowStyle: (rowData) => {
                  const { areEvidencesValid, withEvidences } = validateOneAssetEvidences(rowData.attachments?.items);
                  const isScannedValid = Boolean(rowData.scanned);

                  function handleAssetRowBackgroundColor(areEvidencesValid, isScannedValid, withEvidences) {
                    if (!areEvidencesValid && !isScannedValid && !withEvidences) return "#fff";
                    if (withEvidences && !areEvidencesValid && !isScannedValid) return "#FFF4E5";
                    if ((!areEvidencesValid && isScannedValid) || (areEvidencesValid && !isScannedValid))
                      return "#e8f4fd";
                    return "#EDF7ED";
                  }

                  const rowBgColor = handleAssetRowBackgroundColor(areEvidencesValid, isScannedValid, withEvidences);
                  return {
                    backgroundColor: rowBgColor,
                  };
                },
              }}
              isLoading={loading}
            />
          </div>
        </Section>
      </Box>

      {/* Dialog de atributos de activos */}
      <AssetAttributesDialog
        isOpen={showAssetAttributesDialog}
        onClose={resetAttributesDialog}
        asset={assetRowData}
        onComplete={() => {}}
        readOnly={true}
      />

      <MaintenanceEvidenceDialog
        isOpen={showAssetEvidenceDialog}
        onClose={resetEvidenceDialog}
        visualizationType={VISUALIZATION_TYPES.VISUALIZATION}
        taskAsset={assetRowData}
        assetAttachments={assetRowData?.attachments}
      />
    </>
  );
}

const COLUMNS_ASSETS = [
  { title: "CÓDIGO DE MATERIAL", field: "asset.code" || "asset.material.code" },
  { title: "NOMBRE", field: "asset.material.name", defaultSort: "desc" },
  {
    title: "SERIE",
    field: "asset.serial",
    defaultSort: "desc",
    render: (rowData) => formatAssetsSeries(rowData.asset.serial),
  },
  {
    title: "# DE EVIDENCIAS CARGADAS",
    field: "attachments.items",

    // eslint-disable-next-line react/display-name
    render: (rowData) => (
      <Typography display="inline" align="center">
        {filterDeletedItems(rowData?.attachments?.items).length || 0}
      </Typography>
    ),
  },
];

const COLUMNS_ASSETS_MOBILE = [
  {
    title: "Detalles",
    field: "id",
    render: (rowData) => {
      const columns = [
        {
          title: "Código de material",
          description: handleGetMaterialCode(rowData),
        },
        { title: "Nombre", description: handleGetName(rowData) },
        { title: "Serie", description: formatAssetsSeries(rowData.asset.serial) },
        {
          title: "# de Evidencias",
          description: (
            <Typography display="inline" align="center">
              {filterDeletedItems(rowData?.attachments?.items).length || 0}
            </Typography>
          ),
        },
      ];
      return <TableCellMobileFormat columns={columns} sm={6} xs={12} />;
    },
    customFilterAndSearch: (value, rowData) => {
      const parsedValue = value?.toLowerCase();
      return (
        handleGetMaterialCode(rowData).toLowerCase().includes(parsedValue) ||
        handleGetName(rowData).toLowerCase().includes(parsedValue) ||
        formatAssetsSeries(rowData?.asset?.serial).toLowerCase().includes(parsedValue) ||
        filterDeletedItems(rowData?.attachments?.items)?.length.toString().toLowerCase().includes(parsedValue)
      );
    },
  },
];

MaintanceDetailAssets.propTypes = {
  maintenanceState: PropTypes.object,
  loading: PropTypes.bool,
};
