import { REQUEST_STATUS } from "constant/requestStatus";
import * as ActionTypes from "redux/action";
import dayjs from "dayjs";

const INITIAL_STATE = {
  rdxPremise: {},
  rdxDetailStatus: REQUEST_STATUS.INACTIVE,
  rdxStateChangeStatus: REQUEST_STATUS.INACTIVE,
  rdxCreateBuildingLocationStatus: REQUEST_STATUS.INACTIVE,
  rdxUpdateBuildingLocationStatus: REQUEST_STATUS.INACTIVE
};

export default function adminPremisesDetailReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case ActionTypes.PREMISES_FETCH_DETAILS_PENDING:
      return { ...state, rdxDetailStatus: REQUEST_STATUS.PENDING };
    case ActionTypes.PREMISES_FETCH_DETAILS_SUCCESS:
      const { getPremises } = action.payload;
      const premiseDetail = transformPremiseData(getPremises);
      return {
        ...state,
        rdxDetailStatus: REQUEST_STATUS.SUCCESSFUL,
        rdxPremise: premiseDetail,
      };
    case ActionTypes.PREMISES_FETCH_DETAILS_ERROR:
      return { ...state, rdxDetailStatus: REQUEST_STATUS.FAILED };

    case ActionTypes.BUILDING_LOCATION_CREATE_PENDING:
      return {
        ...state,
        rdxCreateBuildingLocationStatus: REQUEST_STATUS.PENDING,
      };
    case ActionTypes.BUILDING_LOCATION_CREATE_SUCCESS:
      const { createBuildingLocation } = action.payload;
      const newBuildingLocation = transformBuildingLocationData(createBuildingLocation);
      return {
        ...state,
        rdxCreateBuildingLocationStatus: REQUEST_STATUS.SUCCESSFUL,
        rdxPremise: { 
          ...state.rdxPremise, 
          buildingLocations: { 
            ...state.rdxPremise.buildingLocations, 
            items: [...state.rdxPremise.buildingLocations.items, newBuildingLocation] 
          } 
        }
      };
    case ActionTypes.BUILDING_LOCATION_CREATE_ERROR:
      return {
        ...state,
        rdxCreateBuildingLocationStatus: REQUEST_STATUS.FAILED,
      };
    case ActionTypes.PREMISES_UPDATE_ITEM_SUCCESS:
      const { updatePremises, updateCompany, updateAddress, updateCustodian } = action.payload;
      const updatedPremise = { 
        ...state.rdxPremise, 
        ...updatePremises, 
        company: updateCompany,
        address: updateAddress,
        custodian: updateCustodian 
      };
      return { ...state, rdxPremise: updatedPremise };
    case ActionTypes.BUILDING_LOCATION_UPDATE_PENDING:
      return { ...state, rdxUpdateBuildingLocationStatus: REQUEST_STATUS.PENDING };
    case ActionTypes.BUILDING_LOCATION_UPDATE_SUCCESS:
      const newState = { ...state };
      const buildingLocation = transformBuildingLocationData(action.payload.updateBuildingLocation);
      const index = newState.rdxPremise.buildingLocations.items.findIndex(({ id }) => id === buildingLocation.id);
      newState.rdxPremise.buildingLocations.items[index] = buildingLocation;
      return { ...newState, rdxUpdateBuildingLocationStatus: REQUEST_STATUS.SUCCESSFUL };
    case ActionTypes.BUILDING_LOCATION_UPDATE_ERROR:
      return { ...state, rdxUpdateBuildingLocationStatus: REQUEST_STATUS.FAILED };
    case ActionTypes.BUILDING_LOCATION_CREATE_CLEAR:
      return {
        ...state,
        rdxCreateBuildingLocationStatus: REQUEST_STATUS.INACTIVE,
        rdxUpdateBuildingLocationStatus: REQUEST_STATUS.INACTIVE
      };

    default:
      return state;
  }
}

const transformPremiseData = (premise) => {
  const buildingLocations = premise?.buildingLocations?.items || [];
  return {
    ...premise,
    buildingLocations: {
      items: buildingLocations.map(transformBuildingLocationData),
      nextToken: premise.buildingLocations.nextToken,
    },
    createdAt: dayjs(premise.createdAt).format("DD/MM/YYYY HH:mm"),
  };
};

const transformBuildingLocationData = (buildingLocation) => {
  return {
    ...buildingLocation,
    createdAt: dayjs(buildingLocation.createdAt).format("DD/MM/YYYY HH:mm"),
  };
};
