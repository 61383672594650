import React from "react";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";

const FormRestartTimer = ({ control, setValue }) => {
  return (
    <>
      <Controller
        name="descriptionR"
        control={control}
        rules={{ required: true }}
        as={
          <TextField
            name="descriptionR"
            type="text"
            label="Descripción de reanudación"
            variant="outlined"
            fullWidth
            required
            multiline
            rows={4}
          />
        }
      />
    </>
  );
};
export default FormRestartTimer;

FormRestartTimer.propTypes = {
  control: PropTypes.object,
  setValue: PropTypes.any,
};
