export function generateTaskAssetMutations(newAssets, deletedAssets, taskId) {
  const createVariables = {};
  let createMutations = "";
  let createParams = "";

  const deleteVariables = {};
  let deleteMutations = "";
  let deleteParams = "";

  const mutationResponse = `
    id
    taskAssetsTaskId
    taskAssetsAssetId
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    scanned
    task {
      id
      _version
      _deleted
      _lastChangedAt
    }
    asset {
      id
      code
      serial
      quantity
      type
      enabled
      material {
        id
        name
        code
        description
        manufacturer
        trackable
        enabled
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      trackingID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  `;

  newAssets.forEach(({ id, scanned }, index) => {
    createParams += `$createTaskAsset${index}: CreateTaskAssetsInput!, `;
    createVariables[`createTaskAsset${index}`] = {
      taskAssetsTaskId: taskId,
      taskAssetsAssetId: id,
      scanned,
    };
    createMutations += `
      cta${index}: createTaskAssets(input: $createTaskAsset${index}) {
        ${mutationResponse}
      },\n
    `;
  });

  deletedAssets.forEach(({ taskAsset }, index) => {
    deleteParams += `$deleteTaskAsset${index}: DeleteTaskAssetsInput!, `;
    deleteVariables[`deleteTaskAsset${index}`] = {
      id: taskAsset.id,
      _version: taskAsset._version,
    };
    deleteMutations += `
      dta${index}: deleteTaskAssets(input: $deleteTaskAsset${index}) {
        ${mutationResponse}
      },\n
    `;
  });

  const mutation = `
    mutation UpdateTaskAssetsRelations(${createParams + deleteParams}) {
      ${createMutations + deleteMutations}
    }
  `;

  return { mutation, variables: { ...createVariables, ...deleteVariables } };
}
