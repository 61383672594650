import { fetchAttachmentsByTask, fetchInspectionNotesByTask, fetchTaskById } from "datastore";
import { TaskStatus } from "models";
import {
  DEFAULT_SUPPORT_ACTIVITY_STEPS,
  DEFAULT_SUPPORT_ACTIVITY_STEPS_KEYNAME,
  STATUS_THAT_ENABLE_ACTIVITY_PROGRESS,
} from "constant/ticketActivity";
import { SupportType } from "models";

// import { Logger } from "aws-amplify";
// const logger = new Logger("TicketActivityDetailsView:helpers");

const STEPS_KEYNAME_ARRAY_PRESENTIAL = Object.values(DEFAULT_SUPPORT_ACTIVITY_STEPS_KEYNAME(SupportType.PRESENTIAL));
const STEPS_KEYNAME_ARRAY_REMOTE = Object.values(DEFAULT_SUPPORT_ACTIVITY_STEPS_KEYNAME(SupportType.REMOTE));
const STEPS_PRESENTIAL = DEFAULT_SUPPORT_ACTIVITY_STEPS(SupportType.PRESENTIAL);
const STEPS_REMOTE = DEFAULT_SUPPORT_ACTIVITY_STEPS(SupportType.REMOTE);

const defaultStepState = {
  step: null,
  stepKeyname: null,
  isStepCompleted: false,
  isLastStep: false,
};

/**
 * Calcula el paso correspondiente de la atención del soporte conforme al estado de la actividad de soporte y
 * el tipo de soporte asignado al ticket.
 *
 * @param {TaskStatus} activityStatus Valor de estado de la actividad (Task)
 * @param {SupportType} supportType Valor de tipo de soporte asignado a un ticket
 * @param {evidencesByStatus} evidencesByStatus Objecto que continene las evidencias de un task segmentadas por estatus { beginning, inProgress, completed }
 * @param {evidencesMinRules} evidencesMinRules Objecto que continene la configuración de evidencias minimas por status { MIN_START, MIN_PROGRESS, MIN_END }
 * @param {evidencesMinRules} issueValue valor de formulario del campo issue
 * @param {evidencesMinRules} analysisValue valor de formulario del campo analysisValue
 * @param {evidencesMinRules} solutionValue valor de formulario del campo solutionValue
 * @param {evidencesMinRules} resolutionCommentValue valor de formulario del campo solutionValue
 * @returns { {stepsState currentStepState} } Estado global inicial de los pasos completados y del paso actual en una actividad de soporte.
 */
export function getInitialActivityStepsState(
  activityStatus,
  supportType,
  { evidencesByStatus, evidencesMinRules, issueValue, analysisValue, solutionValue, resolutionCommentValue } = {}
) {
  if (!STATUS_THAT_ENABLE_ACTIVITY_PROGRESS.includes(activityStatus)) {
    return;
  }

  if (!supportType || supportType === SupportType.UNDEFINED) {
    const currentStepState = {
      step: STEPS_PRESENTIAL.SUPPORT_TYPE,
      stepKeyname: DEFAULT_SUPPORT_ACTIVITY_STEPS_KEYNAME().SUPPORT_TYPE,
      isStepCompleted: false,
      isLastStep: true,
    };
    return { currentStepState, stepsState: [currentStepState] };
  }

  if (supportType === SupportType.PRESENTIAL) {
    return handleGetPresencialSteps(activityStatus, supportType, {
      evidencesByStatus,
      evidencesMinRules,
      issueValue,
      analysisValue,
      solutionValue,
    });
  }

  if (supportType === SupportType.REMOTE) {
    return handleGetRemoteSteps();
  }
}

function handleGetPresencialSteps(
  activityStatus,
  supportType,
  { evidencesByStatus, evidencesMinRules, issueValue, analysisValue, solutionValue } = {}
) {
  const { beginning, inProgress, completed } = evidencesByStatus;
  const { MIN_START, MIN_PROGRESS, MIN_END } = evidencesMinRules;

  const IS_START_ATTACHMENTS_VALID = beginning.length >= MIN_START;
  const IS_PROGRESS_ATTACHMENTS_VALID = inProgress.length >= MIN_PROGRESS;
  const IS_END_ATTACHMENTS_VALID = completed.length >= MIN_END;

  let currentStepState = { ...defaultStepState };

  let stepsState = STEPS_KEYNAME_ARRAY_PRESENTIAL.map((keyName, index) => ({
    ...currentStepState,
    step: index,
    stepKeyname: keyName,
  }));

  switch (activityStatus) {
    case TaskStatus.SCHEDULED: {
      currentStepState.step = STEPS_PRESENTIAL.SUPPORT_TYPE;
      currentStepState.stepKeyname = STEPS_KEYNAME_ARRAY_PRESENTIAL[STEPS_PRESENTIAL.SUPPORT_TYPE];
      currentStepState.isStepCompleted = false;
      currentStepState.isLastStep = true;
      break;
    }
    case TaskStatus.IN_PROGRESS: {
      if (
        IS_START_ATTACHMENTS_VALID &&
        IS_PROGRESS_ATTACHMENTS_VALID &&
        IS_END_ATTACHMENTS_VALID &&
        (issueValue || analysisValue || solutionValue)
      ) {
        currentStepState.step = STEPS_PRESENTIAL.ACTIVITY_RETROSPECTIVE;
        currentStepState.stepKeyname = STEPS_KEYNAME_ARRAY_PRESENTIAL[STEPS_PRESENTIAL.ACTIVITY_RETROSPECTIVE];
        currentStepState.isLastStep = true;
        currentStepState.isStepCompleted = false;
        break;
      }

      if (IS_END_ATTACHMENTS_VALID) {
        currentStepState.step = STEPS_PRESENTIAL.END_EVIDENCE;
        currentStepState.stepKeyname = STEPS_KEYNAME_ARRAY_PRESENTIAL[STEPS_PRESENTIAL.END_EVIDENCE];
        currentStepState.isStepCompleted = true;
        currentStepState.isLastStep = false;
        break;
      }

      if (IS_PROGRESS_ATTACHMENTS_VALID) {
        currentStepState.step = STEPS_PRESENTIAL.PROGRESS_EVIDENCE;
        currentStepState.stepKeyname = STEPS_KEYNAME_ARRAY_PRESENTIAL[STEPS_PRESENTIAL.PROGRESS_EVIDENCE];
        currentStepState.isStepCompleted = true;
        currentStepState.isLastStep = false;
      }

      if (IS_START_ATTACHMENTS_VALID) {
        currentStepState.step = STEPS_PRESENTIAL.INITIAL_EVIDENCE;
        currentStepState.stepKeyname = STEPS_KEYNAME_ARRAY_PRESENTIAL[STEPS_PRESENTIAL.INITIAL_EVIDENCE];
        currentStepState.isStepCompleted = true;
        currentStepState.isLastStep = false;
        break;
      }

      if (supportType) {
        currentStepState.step = STEPS_PRESENTIAL.SUPPORT_TYPE;
        currentStepState.stepKeyname = STEPS_KEYNAME_ARRAY_PRESENTIAL[STEPS_PRESENTIAL.SUPPORT_TYPE];
        currentStepState.isStepCompleted = true;
        currentStepState.isLastStep = false;
        break;
      }
      break;
    }
    default:
      stepsState = stepsState.map((stepState, index) => ({
        ...stepState,
        isStepCompleted: true,
        isLastStep: index === stepsState.length - 1,
      }));
      currentStepState = stepsState[STEPS_PRESENTIAL.ACTIVITY_RETROSPECTIVE];
      break;
  }

  if ([TaskStatus.SCHEDULED, TaskStatus.IN_PROGRESS].includes(activityStatus)) {
    //-----¡este orden de ejecución es importante!-----
    stepsState = handleSetStepsState(stepsState, currentStepState);
    currentStepState = getLastStep(stepsState);
  }

  return { stepsState, currentStepState };
}

function handleGetRemoteSteps() {
  let stepsState = STEPS_KEYNAME_ARRAY_REMOTE.map((keyName, index) => ({
    step: index,
    stepKeyname: keyName,
    isStepCompleted: index === STEPS_REMOTE.SUPPORT_TYPE,
    isLastStep: index === STEPS_REMOTE.REMOTE_ACTIVITY,
  }));

  let currentStepState = getLastStep(stepsState);

  return { stepsState, currentStepState };
}

function handleSetStepsState(stepsState, currentStepState) {
  return stepsState.map((stepState) => {
    const isLastStep = stepState.step === currentStepState.step + 1;
    const isCurrentStep = stepState.step === currentStepState.step;

    if (isCurrentStep) {
      stepState = { ...currentStepState };
    }
    if (stepState.step < currentStepState.step) {
      stepState.isStepCompleted = true;
    }

    if (isLastStep && !currentStepState.isLastStep) {
      stepState.isLastStep = true;
    }

    return { ...stepState };
  });
}

function getLastStep(stepsState) {
  return stepsState.find((stepState) => stepState.isLastStep);
}

//util si se desea condicionar los pasos de evidencias de una actividad
export function getRealSupportActivityStepsReducer(
  activitySteps,
  stepKeyName,
  { IS_START_ATTACHMENTS_STEP_ENABLE, IS_PROGRESS_ATTACHMENTS_STEP_ENABLE, IS_END_ATTACHMENTS_STEP_ENABLE } = {}
) {
  const IS_INITIAL_EVIDENCE_STEP = stepKeyName === STEPS_KEYNAME_ARRAY_PRESENTIAL[STEPS_PRESENTIAL.INITIAL_EVIDENCE];
  const IS_PROGRESS_EVIDENCE_STEP = stepKeyName === STEPS_KEYNAME_ARRAY_PRESENTIAL[STEPS_PRESENTIAL.PROGRESS_EVIDENCE];
  const IS_END_EVIDENCE_STEP = stepKeyName === STEPS_KEYNAME_ARRAY_PRESENTIAL[STEPS_PRESENTIAL.END_EVIDENCE];

  if (IS_INITIAL_EVIDENCE_STEP) {
    if (IS_START_ATTACHMENTS_STEP_ENABLE) {
      activitySteps.push(stepKeyName);
      return activitySteps;
    }
    return activitySteps;
  }
  if (IS_PROGRESS_EVIDENCE_STEP) {
    if (IS_PROGRESS_ATTACHMENTS_STEP_ENABLE) {
      activitySteps.push(stepKeyName);
      return activitySteps;
    }
    return activitySteps;
  }
  if (IS_END_EVIDENCE_STEP) {
    if (IS_END_ATTACHMENTS_STEP_ENABLE) {
      activitySteps.push(stepKeyName);
      return activitySteps;
    }
    return activitySteps;
  }

  activitySteps.push(stepKeyName);
  return activitySteps;
}

export async function findTicketActivityById(taskId) {
  const [{ task, premises, assets, userTask, remarks }, notes, attachments] = await Promise.all([
    fetchTaskById(taskId),
    fetchInspectionNotesByTask(taskId),
    fetchAttachmentsByTask(taskId),
  ]);
  return { ...task, premises, assets, notes, userTask, attachments, remarks };
}
