/**
 * Disponibiliza handler para generar una consulta/operación que se ejecuta de manera condicionada dependiendo si el dispositivo cuenta con conectividad a internet.  
 *
 * usage:
 * const generateOnlineOfflineHandler = useGenerateOnlineOfflineHandler();
 *  const queryOfflineAndOnline = generateOnlineOfflineHandler(onlineHandler, offlineHandler, {
      onlineParams: { params },
      offlineParams: { params },
      onValidationFail,
      validation: !taskId,
      errorMsg: "Error al realizarConsulta",
    });
 * 
 */

import { Logger } from "@aws-amplify/core";
import useOnlineStatus from "@rehooks/online-status";
import useNotifier from "hooks/useNotifier";
import { captureException } from "setup/sentry";

const logger = new Logger("useGenerateOnlineOfflineHandler");

function useGenerateOnlineOfflineHandler() {
  const isOnline = useOnlineStatus();
  const { showError } = useNotifier();

  async function generateOnlineOfflineHandler(
    onlineHandler,
    offlineHandler,
    { onlineParams = {}, offlineParams = {}, validation = true, onValidationFail = () => {}, errorMsg = "" } = {}
  ) {
    let result = { success: false, data: null };

    // validation must be/return a boolean value
    const isNotValid = typeof validation === "function" ? Boolean(validation()) : Boolean(validation);

    try {
      if (isNotValid) {
        onValidationFail();
        logger.error("generateOnlineOfflineHandler: parametros invalidos", isOnline ? onlineParams : offlineParams);
        throw new Error("generateOnlineOfflineHandler: parametros invalidos.");
      }

      if (isOnline) {
        result.data = await onlineHandler(onlineParams);
      } else {
        result.data = await offlineHandler(offlineParams);
      }
      if (result.data) {
        result.success = true;
      }
    } catch (error) {
      logger.error(error);
      captureException(error.message, "hooks/useGenerateOnlineOfflineHandler");
      if (Boolean(errorMsg)) {
        showError(errorMsg);
      }
    }
    return result;
  }

  return generateOnlineOfflineHandler;
}

export default useGenerateOnlineOfflineHandler;
