export const lambdaCSVDataLoad = /* GraphQL */ `
  mutation LambdaCSVDataLoad($input: lambdaCSVDataLoadInput) {
    lambdaCSVDataLoad(input: $input) {
      statusCode
      error
      errorMessage
      body
    }
  }
`;

export default lambdaCSVDataLoad;
