export const ROOT = "/validacion";

export const REQUIREMENTS_ROOT = `${ROOT}/requerimientos`;
export const SURGERIES_ROOT = `${ROOT}/cirugias`;

export const PENDING_REQUIREMENTS = `${REQUIREMENTS_ROOT}/pendientes`;
export const APPROVED_REQUIREMENTS = `${REQUIREMENTS_ROOT}/aprobados`;
export const FINALIZED_REQUIREMENTS = `${REQUIREMENTS_ROOT}/finalizados`;
export const REJECTED_REQUIREMENTS = `${REQUIREMENTS_ROOT}/rechazados`;
export const REQUIREMENT_DETAIL = `${REQUIREMENTS_ROOT}`;
export const REQUIREMENT_DETAIL_WITH_PARAM = `${REQUIREMENTS_ROOT}/:reqId`;
export const SURGERY_DETAIL = `${SURGERIES_ROOT}/:entryId`;

export const MAINTENANCE_ROOT = `${ROOT}/mantenimientos`;
export const MAINTENANCE_DETAIL = `${ROOT}/mantenimiento`;
export const MAINTENANCE_DETAIL_WITH_PARAM = `${ROOT}/mantenimiento/:id`;
export const FINALIZED_MAINTENANCE = `${MAINTENANCE_ROOT}/finalizados`;
export const CANCELED_MAINTENANCE = `${MAINTENANCE_ROOT}/cancelados`;

export const INSTALLATIONS_ROOT = `${ROOT}/instalaciones`;
export const INSTALLATION_DETAIL = `${ROOT}/instalacion`;
export const INSTALLATION_DETAIL_WITH_PARAM = `${ROOT}/instalacion/:id`;
export const INSTALLATIONS_SCHEDULE = `${INSTALLATIONS_ROOT}/programacion`;
export const TEAM = `${ROOT}/equipo`;

export const VALIDATION_DELIVERIES = `${ROOT}/envios`;
export const VALIDATION_DELIVERY_DETAILS = `${ROOT}/envio/:id`;
export const TICKET_LIST = `${ROOT}/tickets`;
export const TICKET_DETAILS = `${ROOT}/ticket/:id`;

export const MATERIAL_CATALOG = `${ROOT}/materiales`;
export const PREMISES_CATALOG = `${ROOT}/sitios`;
export const PREMISES_DETAILS = `${PREMISES_CATALOG}/:id`;
export const ACTIVITIES = `${ROOT}/tipo-actividad`;

export const REQUIREMENT = {
  ROOT: REQUIREMENTS_ROOT,
  PENDING: PENDING_REQUIREMENTS,
  APPROVED: APPROVED_REQUIREMENTS,
  FINALIZED: FINALIZED_REQUIREMENTS,
  REJECTED: REJECTED_REQUIREMENTS,
  DETAIL: REQUIREMENT_DETAIL,
  DETAIL_WITH_PARAM: REQUIREMENT_DETAIL_WITH_PARAM,
};

export const SURGERY = {
  ROOT: SURGERIES_ROOT,
  DETAIL: SURGERY_DETAIL,
};

export const MAINTENANCE = {
  ROOT: MAINTENANCE_ROOT,
  FINALIZED: FINALIZED_MAINTENANCE,
  CANCELED: CANCELED_MAINTENANCE,
  MAINTENANCE_DETAIL: MAINTENANCE_DETAIL,
  MAINTENANCE_DETAIL_WITH_PARAM: MAINTENANCE_DETAIL_WITH_PARAM,
};

export const INSTALLATION = {
  ROOT: INSTALLATIONS_ROOT,
  INSTALLATION_DETAIL,
  INSTALLATION_DETAIL_WITH_PARAM,
};

const VALIDATION = {
  ROOT,
  SURGERY,
  REQUIREMENT,
  MAINTENANCE,
  INSTALLATION,
  TEAM,
  VALIDATION_DELIVERIES,
  VALIDATION_DELIVERY_DETAILS,
  TICKET_LIST,
  TICKET_DETAILS,
  MATERIAL_CATALOG,
  PREMISES_CATALOG,
  PREMISES_DETAILS,
  ACTIVITIES,
};

export default VALIDATION;
