import { DataStore } from "@aws-amplify/datastore";
import { Task, Attachment, TaskStatus } from "models";
import { fetchTaskById } from "datastore";
import { getCurrentPosition } from "util/geolocation";

export async function startInstallation(taskID) {
  const startedAt = new Date();
  let [task, location] = await Promise.all([fetchTaskById(taskID), getCurrentPosition()]);

  task = Task.copyOf(task.task, (updated) => {
    updated.status = TaskStatus.IN_PROGRESS;
    updated.startedAt = startedAt.toISOString();
    updated.startLocation = {
      latitude: location.coords.latitude,
      longitude: location.coords.longitude,
    };

    return updated;
  });

  return DataStore.save(task);
}

export async function endInstallation(taskID) {
  const endedAt = new Date();
  let [task, location] = await Promise.all([fetchTaskById(taskID), getCurrentPosition()]);

  task = Task.copyOf(task.task, (updated) => {
    updated.status = TaskStatus.COMPLETED;
    updated.endedAt = endedAt.toISOString();
    updated.endLocation = {
      latitude: location.coords.latitude,
      longitude: location.coords.longitude,
    };

    return updated;
  });

  return DataStore.save(task);
}

export async function addAttachmentsToTask(taskId, attachments, indexDb) {
  if (!attachments?.length) {
    return [];
  }
  const region = process.env.REACT_APP_AWS_REGION;
  const bucket = process.env.REACT_APP_AWS_USER_FILES_S3_BUCKET;
  let promises = attachments.map((a) => {
    const attach = {
      taskID: taskId,
      status: a.status,
      file: {
        region,
        bucket,
      },
    };

    if (a.uploaded) {
      attach.file.key = a.s3KeyFile;
      const attachment = new Attachment(attach);
      return DataStore.save(attachment);
    } else {
      attach.blob = a.blob;
      attach.filename = a.filename;
      return indexDb.add(attach);
    }
  });

  return Promise.all(promises);
}

export async function deleteAttachment(attachmentID) {
  const attachment = await DataStore.query(Attachment, attachmentID);
  await DataStore.delete(attachment);
  return attachment;
}
