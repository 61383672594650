import React from "react";
import PropTypes from "prop-types";
import { FixedSizeList as List } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";

export default function VirtualizedListLoader({
  layout,
  hasNextPage,
  isNextPageLoading,
  items,
  renderItem,
  renderLoader,
  parentElement,
  loadNextPage,
  className,
  width,
  height,
  itemHeight,
}) {
  const itemCount = hasNextPage ? items.length + 1 : items.length;
  const loadMoreItems = isNextPageLoading ? () => {} : loadNextPage;

  function isItemLoaded(index) {
    return !hasNextPage || index < items.length;
  }

  function ItemGenerator({ index, style }) {
    const isIndicatorRow = !isItemLoaded(index);
    if (isIndicatorRow) {
      return renderLoader(style, index);
    } else {
      return renderItem(items[index], style, index);
    }
  }

  return (
    <InfiniteLoader isItemLoaded={isItemLoaded} itemCount={itemCount} loadMoreItems={loadMoreItems}>
      {({ onItemsRendered, ref }) => (
        <List
          ref={ref}
          layout={layout}
          className={className}
          itemCount={itemCount}
          onItemsRendered={onItemsRendered}
          outerElementType={parentElement}
          itemSize={itemHeight}
          width={width}
          height={height}
        >
          {ItemGenerator}
        </List>
      )}
    </InfiniteLoader>
  );
}

VirtualizedListLoader.propTypes = {
  layout: PropTypes.oneOf(["vertical", "horizontal"]),
  hasNextPage: PropTypes.bool,
  isNextPageLoading: PropTypes.bool,
  items: PropTypes.array,
  renderItem: PropTypes.func,
  renderLoader: PropTypes.func,
  loadNextPage: PropTypes.func,
  className: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  itemHeight: PropTypes.number,
};

VirtualizedListLoader.defaultProps = {
  layout: "vertical",
  hasNextPage: false,
  isNextPageLoading: false,
  items: [],
  renderItem: () => null,
  renderLoader: () => null,
  loadNextPage: () => {},
  className: "",
  width: "100%",
  height: 300,
  itemHeight: 45,
};
