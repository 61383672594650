import {
  SITE_GET_TEAM_SUCCESS,
  SITE_GET_USER_TEAM_SUCCESS,
  SITE_FETCH_USERS_PENDING,
  SITE_FETCH_USERS_SUCCESS,
  SITE_FETCH_USERS_ERROR,
  SITE_ASSIGN_USERS_PENDING,
  SITE_ASSIGN_USERS_SUCCESS,
  SITE_ASSIGN_USERS_ERROR,
  SITE_GET_USER_TEAM_PENDING,
  SITE_GET_USER_TEAM_ERROR,
  SITE_DELETE_USER_SUCCESS,
  SITE_DELETE_USER_PENDING,
  SITE_DELETE_USER_ERROR,
  SITE_GET_TEAM_ERROR,
  SITE_RESET_STATE,
} from "redux/action";
import { getAllUsers, getUserTeam, getTeam } from "graphql-custom/queries";
import { assignUserTeam, deleteUserTeam } from "graphql-custom/mutations";

/**
 * @function getTeamByUser
 *
 * Ejemplo de una función que retorna una acción manejada por graphqlMiddleware. Cada
 * acción manejada por el middleware debe contener las propiedades "filter" y "operation".
 * De forma opcional se incluyen las propiedades "operationParams", "types" y "options".
 */
export const getTeamByUser = (userId) => ({
  filter: "graphql",
  operation: getUserTeam,
  operationParams: {
    filter: {
      head: {
        eq: userId,
      },
    },
    limit: 500,
  },
  types: [SITE_GET_USER_TEAM_PENDING, SITE_GET_USER_TEAM_SUCCESS, SITE_GET_USER_TEAM_ERROR],
  options: { errorMessage: "Ocurrió un error al cargar los integrantes de tu equipo" },
});

export const getTeamInfo = (teamId) => ({
  filter: "graphql",
  operation: getTeam,
  operationParams: { teamId },
  types: [null, SITE_GET_TEAM_SUCCESS, SITE_GET_TEAM_ERROR],
});

export const deleteUserFromTeam = ({ relationId, _version }) => ({
  filter: "graphql",
  operation: deleteUserTeam,
  operationParams: { userTeamId: relationId, _version },
  types: [SITE_DELETE_USER_PENDING, SITE_DELETE_USER_SUCCESS, SITE_DELETE_USER_ERROR],
});

export const getUsers = () => ({
  filter: "graphql",
  operation: getAllUsers,
  types: [SITE_FETCH_USERS_PENDING, SITE_FETCH_USERS_SUCCESS, SITE_FETCH_USERS_ERROR],
});

export const assignUsers = (selectedCandidates, userIds) => ({
  filter: "graphql",
  operation: assignUserTeam(selectedCandidates),
  operationParams: userIds,
  types: [SITE_ASSIGN_USERS_PENDING, SITE_ASSIGN_USERS_SUCCESS, SITE_ASSIGN_USERS_ERROR],
});

export const cleanSiteData = () => ({
  type: SITE_RESET_STATE,
});
