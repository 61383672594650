import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  toolbar: {
    background: "#FFF",
    color: "#000",
  },
  stripe: {
    height: "20px",
    backgroundColor: theme.palette.primary.main,
  },
}));

export default useStyles;
