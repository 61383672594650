export const changeUserStatus = /* GraphQL */ `
  mutation ChangeUserStatus($id: ID!, $status: UserStatus!, $_version: Int!) {
    updateUser(input: {
      id: $id,
      status: $status
      _version: $_version
    }) {
      id
      name
      middlename
      lastnameP
      lastnameM
      email
      phone
      extension
      status
      _version
      _deleted
    }
  }
`;