import React from "react";
import PropTypes from "prop-types";
import { useTheme, useMediaQuery, Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import CustomButton from "components/custom/Button";
import NetworkTooltip from "components/custom/NetworkTooltip";
import FloatingButton from "components/FloatingButton";
import ProjectOperationsForm from "./ProjectOperationsForm";
import { useForm } from "react-hook-form";
import { useFormOperations } from "./CompanyFormOperations";
import useProjectOperations from "./useProjectOperations";
import useOnlineStatus from "@rehooks/online-status";
import { trimFormStringValues } from "util/forms";
import { transformToISO_8601 } from "util/date";
import {
  CREATE_PROJECT_DEFAULT_FORM_VALUES,
  DEFAULT_TIME,
  defaultFloatingButtonProps,
  floatingButtonDisabledStyles,
  projectNetworkTooltipProps,
} from "./constant";
import useLocalStorageState from "hooks/useLocalStorageState";

export const CreateProjectFormDialog = ({ companyID, onConfirm, onConfirmError, onCancel, onCancelError }) => {
  const { isFormOpen, closeForm } = useFormOperations();
  const { createProject, loading } = useProjectOperations();
  const projectFormKey = React.useRef(`create-project-form-${companyID}`);
  const [formValuesInLS, setFormValuesInLS] = useLocalStorageState(projectFormKey.current, null, {
    storeFalsyValues: false,
  });
  const { register, getValues, errors, trigger, control, reset, setValue } = useForm({
    defaultValues: formValuesInLS || CREATE_PROJECT_DEFAULT_FORM_VALUES,
  });
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  React.useEffect(() => {
    if (isFormOpen && formValuesInLS) {
      Object.keys(CREATE_PROJECT_DEFAULT_FORM_VALUES).forEach((fieldName) =>
        setValue(fieldName, formValuesInLS[fieldName])
      );
    }
  }, [formValuesInLS, isFormOpen, setValue]);

  async function handleValidate(values) {
    let isError = false;
    if (!values.name.length) {
      isError = true;
      await trigger("name");
    }
    return isError;
  }

  async function handleCreateCompany() {
    try {
      let values = trimFormStringValues(getValues());
      const isError = await handleValidate(values);
      if (isError) {
        return;
      }
      if (values.startAt) {
        values.startAt = transformToISO_8601(`${values.startAt} ${DEFAULT_TIME}`);
      } else {
        delete values.startAt;
      }
      if (values.endAt) {
        values.endAt = transformToISO_8601(`${values.endAt} ${DEFAULT_TIME}`);
      } else {
        delete values.endAt;
      }
      const input = { companyID, enabled: true, ...values };
      const companyCreate = await createProject(input, {
        successMsg: "Nuevo proyecto creado satisfactoriamente.",
        errorMsg: "Error durante la creación del proyecto, intentelo nuevamente.",
      });
      onConfirm(companyCreate);
      closeForm();
      handleClearAndResetForm();
    } catch (error) {
      onConfirmError(error, { closeForm });
    }
  }

  function handleClose() {
    try {
      onCancel();
    } catch (error) {
      onCancelError(error);
    } finally {
      closeForm();
    }
  }

  function handleSetValuesInLs() {
    let values = trimFormStringValues(getValues());
    const hasValues = Object.values(values).some((value) => Boolean(value));
    if (!values.startAt) values.startAt = null;
    if (!values.endAt) values.endAt = null;
    if (hasValues) {
      setFormValuesInLS(values);
    }
    if (formValuesInLS && !hasValues) {
      handleClearAndResetForm();
    }
  }

  function handleClearAndResetForm() {
    setFormValuesInLS(null);
    window.localStorage.removeItem(projectFormKey.current);
    reset();
  }

  return (
    <Dialog
      open={isFormOpen}
      onClose={closeForm}
      onExit={handleSetValuesInLs}
      maxWidth="sm"
      aria-labelledby="company-form-dialog"
      fullWidth
      fullScreen={fullScreen}
      keepMounted={false}
    >
      <DialogTitle id="company-form-dialog-title">Creación de Proyecto</DialogTitle>
      <DialogContent dividers>
        <ProjectOperationsForm
          inputRef={register}
          control={control}
          nameFieldProps={{ error: !!errors.name, helperText: errors.name?.message }}
          startAtFieldProps={{ loadingIndicator: loading }}
          endAtFieldProps={{ loadingIndicator: loading }}
        />
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={handleClose} color="inherit">
          Cancelar
        </CustomButton>
        <CustomButton onClick={handleCreateCompany} color="primary" loading={loading}>
          Crear
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateProjectFormDialog;

CreateProjectFormDialog.propTypes = {
  companyID: PropTypes.string.isRequired,
  onConfirm: PropTypes.func,
  onConfirmError: PropTypes.func,
  onCancel: PropTypes.func,
  onCancelError: PropTypes.func,
};

CreateProjectFormDialog.defaultProps = {
  onConfirm: () => {},
  onConfirmError: () => {},
  onCancel: () => {},
  onCancelError: () => {},
};

export function CreateProjectButton({ children }) {
  const isOnline = useOnlineStatus();
  const { openForm } = useFormOperations();

  function handleClick() {
    openForm();
  }

  return (
    <NetworkTooltip {...projectNetworkTooltipProps}>
      <FloatingButton
        {...defaultFloatingButtonProps}
        onClick={handleClick}
        disabled={!isOnline}
        style={!isOnline ? floatingButtonDisabledStyles : {}}
      >
        {children}
      </FloatingButton>
    </NetworkTooltip>
  );
}

CreateProjectButton.propTypes = {
  children: PropTypes.node,
};
