const updateTimerEvent = /* GraphQL */ `
  mutation UpdateTimerEvent($input: UpdateTimerEventInput!) {
    updateTimerEvent(input: $input) {
      taskID
      id
      finalized
      eventCauseID
      duration
      description
      timestamp
      _version
      createdAt
      _lastChangedAt
    }
  }
`;

export default updateTimerEvent;
