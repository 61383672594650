export const DATASTORE_EVENTS = {
  NETWORK_STATUS: "networkStatus",
  SUBSCRIPTION_ESTABLISHED: "subscriptionsEstablished",
  SYNC_QUERIES_STARTED: "syncQueriesStarted",
  MODEL_SYNCED: "modelSynced",
  SYNC_QUERIES_READY: "syncQueriesReady",
  READY: "ready",
  OUTBOX_MUTATION_ENQUEUED: "outboxMutationEnqueued",
  OUTBOX_MUTATION_PROCESSED: "outboxMutationProcessed",
  OUTBOX_STATUS: "outboxStatus",
};
