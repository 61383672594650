import React from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles(() => ({
  iconButton: { color: "white" },
}));

function MenuButton({ ButtonIcon, options, onChange }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (value) => (event) => {
    onChange(value);
    handleClose();
  };

  return (
    <>
      <IconButton className={classes.iconButton} onClick={handleClick}>
        <ButtonIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {options.length === 0 && (
          <MenuItem onClick={handleClose} value="">
            Sin opciones
          </MenuItem>
        )}
        {options.length &&
          options.map((option, index) => (
            <MenuItem key={index} onClick={handleChange(option.value)}>
              {option.label}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
}

MenuButton.propTypes = {
  ButtonIcon: PropTypes.elementType,
  options: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
};

MenuButton.defaultProps = {
  ButtonIcon: null,
  options: [],
  onChange: () => {},
};

export default MenuButton;
