// @ts-check
import { initSchema } from "@aws-amplify/datastore";
import { schema } from "./schema";

const TaskDocumentType = {
  MAINTENANCE_REPORT: "MAINTENANCE_REPORT",
  SUPPORT_COMPLETED_REPORT: "SUPPORT_COMPLETED_REPORT",
  SUPPORT_VALIDATED_REPORT: "SUPPORT_VALIDATED_REPORT",
  INSTALLATION_REPORT: "INSTALLATION_REPORT",
};

const AssetType = {
  ALLOCATED: "ALLOCATED",
  USED: "USED",
  REUSED: "REUSED",
  RECEIVED: "RECEIVED",
  SHIPMENT_SCHEDULED: "SHIPMENT_SCHEDULED",
  SHIPMENT_SENT: "SHIPMENT_SENT",
  SHIPMENT_PREV_SENT: "SHIPMENT_PREV_SENT",
  SHIPMENT_RECEIVED: "SHIPMENT_RECEIVED",
  SHIPMENT_PREV_RECEIVED: "SHIPMENT_PREV_RECEIVED",
  TEMPORAL: "TEMPORAL",
  MATERIAL_ALLOCATED: "MATERIAL_ALLOCATED",
  MATERIAL_USED: "MATERIAL_USED",
};

const TaskStatus = {
  GENERAL: "GENERAL",
  SCHEDULED: "SCHEDULED",
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
  REVIEWED: "REVIEWED",
  WITH_INSPECTION_NOTES: "WITH_INSPECTION_NOTES",
  FINALIZED: "FINALIZED",
  SHIPMENT_SCHEDULED: "SHIPMENT_SCHEDULED",
  SHIPMENT_PREV_SENT: "SHIPMENT_PREV_SENT",
  SHIPMENT_SENT: "SHIPMENT_SENT",
  SHIPMENT_PREV_RECEIVED: "SHIPMENT_PREV_RECEIVED",
  SHIPMENT_RECEIVED: "SHIPMENT_RECEIVED",
};

const TaskCategory = {
  IMPLEMENTATION: "IMPLEMENTATION",
  SHIPMENT: "SHIPMENT",
  SUPPORT: "SUPPORT",
  MAINTENANCE: "MAINTENANCE",
  UNDEFINED: "UNDEFINED",
};

const UserStatus = {
  ENABLED: "ENABLED",
  DISABLED: "DISABLED",
};

const LogDataType = {
  MATERIAL: "MATERIAL",
  MATERIAL_CATEGORY: "MATERIAL_CATEGORY",
  TASK: "TASK",
  TICKET: "TICKET",
};

const LegalDocumentType = {
  TERMS_AND_CONDITIONS: "TERMS_AND_CONDITIONS",
  NOTICE_OF_PRIVACY: "NOTICE_OF_PRIVACY",
};

const LegalDocumentStatus = {
  PENDING: "PENDING",
  EFFECTIVE: "EFFECTIVE",
  EXPIRED: "EXPIRED",
  CANCELLED: "CANCELLED",
};

const BuildingLocationStatus = {
  ENABLED: "ENABLED",
  DISABLED: "DISABLED",
};

const RequestType = {
  ISSUE: "ISSUE",
  SERVICE_REQUEST: "SERVICE_REQUEST",
  UNDEFINED: "UNDEFINED",
};

const TicketImpact = {
  HIGH: "HIGH",
  MEDIUM: "MEDIUM",
  LOW: "LOW",
  UNDEFINED: "UNDEFINED",
};

const TicketStatus = {
  OPEN: "OPEN",
  SCHEDULED: "SCHEDULED",
  IN_PROGRESS: "IN_PROGRESS",
  RESOLVED: "RESOLVED",
  VALIDATED: "VALIDATED",
  RATED: "RATED",
  CANCELLED: "CANCELLED",
  GENERAL: "GENERAL",
};

const RequestChannel = {
  PHONE: "PHONE",
  EMAIL: "EMAIL",
  WEB_PAGE: "WEB_PAGE",
  UNDEFINED: "UNDEFINED",
};

const SupportType = {
  PRESENTIAL: "PRESENTIAL",
  REMOTE: "REMOTE",
  UNDEFINED: "UNDEFINED",
};

const TicketUrgency = {
  HIGH: "HIGH",
  NORMAL: "NORMAL",
  LOW: "LOW",
  UNDEFINED: "UNDEFINED",
};

const TicketPriority = {
  HIGH: "HIGH",
  MEDIUM: "MEDIUM",
  LOW: "LOW",
  UNDEFINED: "UNDEFINED",
};

const ServiceCategory = {
  ELECTRONIC_SECURITY: "ELECTRONIC_SECURITY",
  UNDEFINED: "UNDEFINED",
};

const ServiceSubcategory = {
  CCTV: "CCTV",
  NETWORK: "NETWORK",
  COMPUTER_EQUIPMENT: "COMPUTER_EQUIPMENT",
  PROPERTY_EQUIPMENT: "PROPERTY_EQUIPMENT",
  AIR_CODITIONER: "AIR_CODITIONER",
  ACCESS_CONTROL: "ACCESS_CONTROL",
  ROUND_CONTROL: "ROUND_CONTROL",
  CABINET: "CABINET",
  UNDEFINED: "UNDEFINED",
};

const TimerEventType = {
  START: "START",
  PAUSE: "PAUSE",
  RESUME: "RESUME",
  END: "END",
};

const AttachmentPlatform = {
  WEB: "WEB",
  ANDROID: "ANDROID",
  IOS: "IOS",
};

const AttachmentSource = {
  CAMERA: "CAMERA",
  ALBUM: "ALBUM",
};

const AttachmentLoadType = {
  ONLINE: "ONLINE",
  OFFLINE: "OFFLINE",
};

const TrackingStatus = {
  ENABLED: "enabled",
  DISABLED: "disabled",
  ASSIGNED: "assigned",
  UNASSIGNED: "unassigned",
};

const Job = {
  UOM: "UOM",
  MATERIAL: "MATERIAL",
  ASSET: "ASSET",
  CATEGORY: "CATEGORY",
};

const JobRunState = {
  STARTING: "STARTING",
  RUNNING: "RUNNING",
  STOPPING: "STOPPING",
  STOPPED: "STOPPED",
  SUCCEEDED: "SUCCEEDED",
  FAILED: "FAILED",
  TIMEOUT: "TIMEOUT",
};

const DownloadAttachmentType = {
  ATTACHMENT_ASSET: "ATTACHMENT_ASSET",
};

const Actions = {
  SUBSCRIBE_REQUEST: "subscribeRequest",
  GET_TOKEN: "getToken",
  CREATE_REQUEST: "createRequest",
  GET_REQUEST_DETAILS: "getRequestDetails",
  GET_REQUESTS: "getRequests",
  GET_REQUEST_FIELDS_INFO: "getRequestFieldsInfo",
};

const SettingsStatus = {
  ENABLED: "ENABLED",
  DISABLED: "DISABLED",
};

const IntegrationRequestStatus = {
  REQUESTED: "REQUESTED",
  APPROVED: "APPROVED",
  CANCELED: "CANCELED",
};

const ModelAttributeTypes = {
  BINARY: "binary",
  BINARY_SET: "binarySet",
  BOOL: "bool",
  LIST: "list",
  MAP: "map",
  NUMBER: "number",
  NUMBER_SET: "numberSet",
  STRING: "string",
  STRING_SET: "stringSet",
  NULL: "_null",
};

const AcceptedDomainStatus = {
  ENABLED: "ENABLED",
  DISABLED: "DISABLED",
};

const GoodsReceiptType = {
  CSV_FILE: "CSV_FILE",
  CONTPA_QI: "CONTPAQi",
};

const TaskStatusColor = {
  GENERAL: "#000000",
  SCHEDULED: "#7f6cff",
  IN_PROGRESS: "#105285",
  COMPLETED: "#f6ce38",
  REVIEWED: "#93c47c",
  WITH_INSPECTION_NOTES: "#ff6c6c",
  FINALIZED: "#00695f",
  CANCELED_BY_CUSTOMER: "#666666",
};
const {
  Asset,
  Material,
  MaterialCategory,
  UnitOfMeassure,
  KitMaterials,
  Kit,
  TaskAssets,
  Task,
  UserTask,
  User,
  UserRole,
  Role,
  UserTeams,
  Team,
  Log,
  UserAgreement,
  LegalDocument,
  Premises,
  Company,
  Project,
  Custodian,
  Address,
  BuildingLocation,
  TaskType,
  Attachment,
  InspectionNote,
  Remark,
  Ticket,
  AttachmentTicket,
  TimerEvent,
  EventCause,
  AttachmentAsset,
  Tracking,
  AssetAttribute,
  Attribute,
  Setting,
  IntegrationRequest,
  AcceptedDomain,
  GoodsReceipt,
  GoodsReceiptAsset,
  AttachmentGoodsReceipt,
  PDFinfo,
  LambdaDownloadTaskDocumentResult,
  LambdaAdvancedAssetSearchResult,
  EmbeddedAttribute,
  Location,
  S3Object,
  ExternalUser,
  TicketRemark,
  ReportedAsset,
  EmbeddedTimerEvent,
  EmbeddedEventCause,
  LambdaAdvancedAssetSearchItem,
  LambdaSupportNotificationsResult,
  NotificationSent,
  lambdaQrGeneratorResponse,
  QrAsset,
  lambdaJobsPollingResponse,
  DownloadAttachmentFilesResponse,
  lambdaCreateGroupResponse,
  lambdaDeleteGroupResponse,
  lambdaAddUserToGroupResponse,
  lambdaRemoveUserFromGroupResponse,
  lambdaDeleteUserResponse,
  lambdaCSVDataLoadResponse,
  lambdaHandleIntegrationResponse,
  PDF,
} = initSchema(schema);

export {
  Asset,
  Material,
  MaterialCategory,
  UnitOfMeassure,
  KitMaterials,
  Kit,
  TaskAssets,
  Task,
  UserTask,
  User,
  UserRole,
  Role,
  UserTeams,
  Team,
  Log,
  UserAgreement,
  LegalDocument,
  Premises,
  Company,
  Project,
  Custodian,
  Address,
  BuildingLocation,
  TaskType,
  Attachment,
  InspectionNote,
  Remark,
  Ticket,
  AttachmentTicket,
  TimerEvent,
  EventCause,
  AttachmentAsset,
  Tracking,
  AssetAttribute,
  Attribute,
  Setting,
  IntegrationRequest,
  AcceptedDomain,
  GoodsReceipt,
  GoodsReceiptAsset,
  AttachmentGoodsReceipt,
  TaskDocumentType,
  AssetType,
  TaskStatus,
  TaskStatusColor,
  TaskCategory,
  UserStatus,
  LogDataType,
  LegalDocumentType,
  LegalDocumentStatus,
  BuildingLocationStatus,
  RequestType,
  TicketImpact,
  TicketStatus,
  RequestChannel,
  SupportType,
  TicketUrgency,
  TicketPriority,
  ServiceCategory,
  ServiceSubcategory,
  TimerEventType,
  AttachmentPlatform,
  AttachmentSource,
  AttachmentLoadType,
  TrackingStatus,
  Job,
  JobRunState,
  DownloadAttachmentType,
  Actions,
  SettingsStatus,
  IntegrationRequestStatus,
  ModelAttributeTypes,
  AcceptedDomainStatus,
  GoodsReceiptType,
  PDFinfo,
  LambdaDownloadTaskDocumentResult,
  LambdaAdvancedAssetSearchResult,
  EmbeddedAttribute,
  Location,
  S3Object,
  ExternalUser,
  TicketRemark,
  ReportedAsset,
  EmbeddedTimerEvent,
  EmbeddedEventCause,
  LambdaAdvancedAssetSearchItem,
  LambdaSupportNotificationsResult,
  NotificationSent,
  lambdaQrGeneratorResponse,
  QrAsset,
  lambdaJobsPollingResponse,
  DownloadAttachmentFilesResponse,
  lambdaCreateGroupResponse,
  lambdaDeleteGroupResponse,
  lambdaAddUserToGroupResponse,
  lambdaRemoveUserFromGroupResponse,
  lambdaDeleteUserResponse,
  lambdaCSVDataLoadResponse,
  lambdaHandleIntegrationResponse,
  PDF,
};
