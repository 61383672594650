import React from "react";
import PropTypes from "prop-types";
import Alert from "@material-ui/lab/Alert";
import { IconButton, Typography } from "@material-ui/core";
import PauseIcon from "@material-ui/icons/PauseCircleOutline";
import EditIcon from "@material-ui/icons/EditOutlined";
import useStyles from "./styles";
import { SUPPORT_ENGINEERS } from "constant/roles";

const TimerAlert = ({ userType, children, onEdit }) => {
  const classes = useStyles();

  const action =
    userType === SUPPORT_ENGINEERS ? (
      <IconButton aria-label="Editar" size="medium" color="default" onClick={onEdit} className={classes.iconButton}>
        <EditIcon className={classes.icon} />
      </IconButton>
    ) : null;
  const message =
    userType === SUPPORT_ENGINEERS ? (
      <>
        <b>Suspendido:</b> {children}
      </>
    ) : (
      children
    );
  return (
    <Alert
      severity="warning"
      icon={<PauseIcon className={classes.icon} />}
      action={action}
      className={classes.alert}
      classes={{ message: classes.message }}
    >
      <Typography variant="body2">{message}</Typography>
    </Alert>
  );
};

TimerAlert.propTypes = {
  userType: PropTypes.string,
  children: PropTypes.node,
  onEdit: PropTypes.func,
};

TimerAlert.defaultProps = {
  userType: SUPPORT_ENGINEERS,
  onEdit: () => {},
};

export default TimerAlert;
