import dayjs from "dayjs";
import API, { graphqlOperation } from "@aws-amplify/api";
import uniqBy from "lodash/uniqBy";
import * as mutations from "graphql-custom/mutations";
import { AssetType, TaskCategory } from "models";
import { Auth } from "@aws-amplify/auth";
import * as queries from "graphql-custom/queries";
import { filterDeletedItems, filterDeletedOrDisabledItems, excludeSystemItems } from "util/lists";

export async function scheduleInstallation(data) {
  // primero creamos la instalación
  const currentUser = await Auth.currentAuthenticatedUser();
  const taskMutation = await API.graphql(
    graphqlOperation(mutations.createTask, {
      input: {
        taskSupervisorId: currentUser.attributes.sub,
        taskTaskTypeId: data.installationType,
        taskBuildingLocationId: data.selectedBuildingLocation,
        taskProjectId: data.selectedProjectId,
        startDate: dayjs(data.startDate).format("YYYY-MM-DD"),
        endDate: dayjs(data.finishDate).format("YYYY-MM-DD"),
        startTime: dayjs(data.startTime).format("HH:mm:ss"),
        endTime: dayjs(data.finishTime).format("HH:mm:ss"),
        status: "SCHEDULED",
        category: TaskCategory.IMPLEMENTATION,
      },
    })
  );

  let assetsMutation = {};

  if (data.selectedMaterials?.length) {
    const { variables, mutation } = mutations.generateAssetsInstallationMutation(
      data.selectedMaterials,
      AssetType.MATERIAL_ALLOCATED
    );
    assetsMutation = await API.graphql(graphqlOperation(mutation, variables));
  }

  const { relationsVariables, relationsMutation } = mutations.generateRelations(
    taskMutation.data.createTask.id,
    assetsMutation.data,
    data.selectedUserIds
  );

  const relationsMutationRequest = await API.graphql(graphqlOperation(relationsMutation, relationsVariables));

  return {
    ...taskMutation,
    ...assetsMutation,
    ...relationsMutationRequest,
  };
}

export async function updateInstallationDetails(data, originalUsers) {
  let addedUsers = [];
  let deletedUsers = [];
  data.selectedUserIds.forEach((su) => {
    const isNew = originalUsers.items.filter((u) => !u._deleted).find((ou) => ou.userID === su);
    if (isNew === undefined) {
      addedUsers.push(su);
    }
  });

  originalUsers.items.forEach((ou) => {
    const isDelete = data.selectedUserIds.find((su) => su === ou.userID);
    if (isDelete === undefined) {
      deletedUsers.push(ou);
    }
  });

  const taskMutation = await API.graphql(
    graphqlOperation(mutations.updateTask, {
      input: {
        id: data.id,
        _version: data.version,
        taskTaskTypeId: data.installationType,
        taskBuildingLocationId: data.selectedBuildingLocation,
        startDate: dayjs(data.startDate).format("YYYY-MM-DD"),
        endDate: dayjs(data.finishDate).format("YYYY-MM-DD"),
        startTime: dayjs(data.startTime).format("hh:mm:ss"),
        endTime: dayjs(data.finishTime).format("hh:mm:ss"),
      },
    })
  );

  if (addedUsers.length > 0) {
    const { relationsVariables, relationsMutation } = mutations.generateRelations(data.id, {}, addedUsers);

    const relationsMutationRequest = await API.graphql(graphqlOperation(relationsMutation, relationsVariables));
  }

  if (deletedUsers.length > 0) {
    const userTaskDeletedIds = deletedUsers.map((du) => ({ id: du.id, version: du._version }));
    const { relationsVariables, relationsMutation } = mutations.generateDeletedUserTaskRelations(userTaskDeletedIds);

    const relationsMutationRequest = await API.graphql(graphqlOperation(relationsMutation, relationsVariables));
  }

  return {
    ...taskMutation,
  };
}

export async function createNewAssets(materials, addedIds) {
  if (!addedIds.length) {
    return null;
  }
  const { mutation, params } = mutations.generateNewAssetsMutation(materials, addedIds, AssetType.MATERIAL_ALLOCATED);
  const { data } = await API.graphql(graphqlOperation(mutation, params));
  const newMaterials = [...materials];
  addedIds.forEach((id, index) => {
    const mIndex = newMaterials.findIndex((m) => m.materialId === id);
    newMaterials[mIndex] = {
      ...newMaterials[mIndex],
      assetId: data[`createAsset${index}`].id,
      assetVersion: data[`createAsset${index}`]._version,
    };
  });
  return newMaterials;
}

export async function updateInstallationMaterials(installationId, { all, added, updated, deleted }) {
  const mutationInfo = mutations.generateMaterialsUpdate(installationId, all, added, updated, deleted);

  if (!mutationInfo) return null;

  const { mutation, params } = mutationInfo;
  return API.graphql(graphqlOperation(mutation, params));
}

export async function fetchCatalogsData(userId) {
  const promises = [
    API.graphql(graphqlOperation(queries.listTaskTypes)).then((result) => {
      const items = result?.data?.listTaskTypes?.items || [];
      return filterDeletedItems(excludeSystemItems(items));
    }),
    API.graphql(graphqlOperation(queries.listPremisesCatalog)).then((result) => {
      let items = result?.data?.listPremisess?.items || [];
      items = filterDeletedItems(items);
      items.forEach((premise) => {
        let buildingLocations = premise.buildingLocations?.items;
        if (buildingLocations) {
          buildingLocations = filterDeletedItems(buildingLocations);
        }
        return premise;
      });
      return items;
    }),
    API.graphql(
      graphqlOperation(queries.getUserTeamDetails, {
        filter: {
          head: {
            eq: userId,
          },
        },
      })
    ).then((result) => {
      let [team] = result?.data?.listTeams?.items;
      let teamUsers = (team?.users?.items || []).filter((rel) => !rel._deleted).map(({ user }) => user);
      teamUsers = uniqBy(teamUsers, "id");
      return filterDeletedOrDisabledItems(teamUsers);
    }),
    API.graphql(graphqlOperation(queries.listMaterials)).then((result) => {
      let materials = result?.data?.listMaterials?.items || [];
      materials = materials.map((material) => ({ ...material, quantity: 0 }));
      return filterDeletedItems(materials);
    }),
    API.graphql(graphqlOperation(queries.listKits)).then((result) => {
      return filterDeletedItems(result?.data?.listKits?.items || []);
    }),
    API.graphql(graphqlOperation(queries.listUoms)).then(({ data }) => {
      return data.listUnitOfMeassures.items.map(({ id, name }) => ({ value: id, label: name }));
    }),
  ];

  return Promise.all(promises);
}
