import { useEffect, useRef } from "react";
import { Logger, Hub } from "@aws-amplify/core";
import useOnlineStatus from "@rehooks/online-status";

const logger = new Logger("useCallbackAfterDSEvent");

const useCallbackAfterDSEvent = ({ event, callback, timeout = 3000, customHandler = null }) => {
  const isOnline = useOnlineStatus();
  const timeoutIDRef = useRef(null);

  useEffect(() => {
    if (!isOnline) return;

    const datastoreListener = Hub.listen("datastore", function (data) {
      if (customHandler && typeof customHandler === "function") {
        logger.debug("Ejecutando customHandler");
        customHandler(data);
        return;
      }
      const DSEvent = data.payload.event;
      if (DSEvent === event) {
        logger.debug(`Se detecto el evento buscado, ejecutando callback en ${timeout}ms...`, DSEvent);
        let timeoutId = timeoutIDRef.current;
        if (timeoutId) {
          clearTimeout(timeoutId);
          timeoutIDRef.current = null;
        }
        timeoutIDRef.current = setTimeout(callback, timeout);
      }
    });
    return () => {
      datastoreListener();
    };
  }, [event, callback, isOnline, timeout, customHandler]);
};

export default useCallbackAfterDSEvent;
