import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Portal from "@material-ui/core/Portal";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import BackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useSelector } from "react-redux";
import useBarcodeScanner from "hooks/useBarcodeScanner";
import cleanUUID from "util/cleanUUID";
import { Button } from "@material-ui/core";
import { Logger } from "@aws-amplify/core";
import useNotifier from "hooks/useNotifier";

const logger = new Logger("BarcodeScanner");

export default function BarcodeScanner({
  open,
  title,
  onCodeDetected,
  onClose,
  bottomPanel,
  showPreviewPanel,
  onRestartReading,
}) {
  const classes = useStyles();
  const { activated, content, container } = useSelector(({ barcodeScanner }) => barcodeScanner);
  const { start, stop } = useBarcodeScanner();
  const { showError } = useNotifier();

  useEffect(() => {
    if (open) {
      start()
        .catch(error => {
          logger.error(error);
          showError(error.message);
          onClose();
        });
    }
    return () => {
      stop();
    };
  }, [open, start, stop]);

  useEffect(() => {
    if (content) {
      onCodeDetected(content);
    }
  }, [content]);

  async function handleClose() {
    await stop();
    onClose();
  }

  if (!activated) {
    return null;
  }

  function restartReading() {
    onRestartReading();
    start();
  }

  return (
    <Portal container={container}>
      <Box position="fixed" top={0} left={0} width="100%" style={{ backgroundColor: "rgba(0, 0, 0, 0.65)" }}>
        <Box display="flex" alignItems="center" flexGrow={1} className={classes.iconButton}>
          <IconButton color="inherit" onClick={handleClose}>
            <BackspaceIcon />
          </IconButton>
          {title && <Typography color="inherit">{title}</Typography>}
        </Box>
      </Box>

      {showPreviewPanel && activated && content && (
        <Box className={classes.previewRoot}>
          <Box className={classes.previewContainer}>
            <img src="/img/qr_icon_lg.svg" alt="icon" className={classes.previewImg} />
            <Typography variant="h5" align="center">
              {cleanUUID(content)}
            </Typography>
            <Button color="primary" onClick={restartReading}>
              Leer Código QR
            </Button>
          </Box>
        </Box>
      )}

      <Box position="fixed" bottom={0} width="100%">
        {bottomPanel}
      </Box>
    </Portal>
  );
}

BarcodeScanner.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  onCodeDetected: PropTypes.func,
  onClose: PropTypes.func,
  bottomPanel: PropTypes.element,
  showPreviewPanel: PropTypes.bool,
  onRestartReading: PropTypes.func,
};

BarcodeScanner.defaultProps = {
  open: false,
  title: "",
  onCodeDetected: () => {},
  onClose: () => {},
  bottomPanel: null,
  showPreviewPanel: false,
  onRestartReading: () => {},
};

const useStyles = makeStyles(() => ({
  iconButton: { color: "white" },
  previewRoot: {
    position: "relative",
    width: "100%",
    height: "100%",
    background: "#fff",
  },
  previewContainer: {
    position: "absolute",
    top: "30%",
    left: "50%",
    background: "#fff",
    transform: "translate(-50%, 30%)",
    width: "20rem",
    height: "20rem",
    textAlign: "center",
  },
  previewImg: {
    width: "100%",
    height: "100%",
  },
}));
