import React from "react";
import { Button, Box } from "@material-ui/core";
import useStyles from "../MaintenanceStyles";
import PropTypes from "prop-types";
import NetworkTooltip from "components/custom/NetworkTooltip";

const DetailViewButtonAction = (props) => {
  const { children, networkTooltipProps, buttonProps, ...restProps } = props;
  //hooks
  const classes = useStyles();

  return (
    <Box className={classes.actionButtonContainer} {...restProps}>
      <NetworkTooltip {...networkTooltipProps}>
        <Button
          id="detail-view-button-action"
          {...buttonProps}
          className={buttonProps.className ? `${classes.actionButton} ${buttonProps.className}` : classes.actionButton}
        >
          {children}
        </Button>
      </NetworkTooltip>
    </Box>
  );
};

DetailViewButtonAction.propTypes = {
  children: PropTypes.elementType,
  networkTooltipProps: PropTypes.object,
  buttonProps: PropTypes.object,
};

DetailViewButtonAction.defaultProps = {
  networkTooltipProps: {},
  buttonProps: {},
};

export default DetailViewButtonAction;
