import React from "react";
import { AuthenticatorHeader } from "./MicroComponents";
import RegisterFormFields from "./RegisterCustom";
import { SignInCustomFooter } from "./SignInCustom";

const components = {
  Header() {
    return <AuthenticatorHeader />;
  },

  SignUp: {
    FormFields() {
      return <RegisterFormFields />;
    },
  },

  SignIn: {
    Footer() {
      return <SignInCustomFooter />;
    },
  },
};

export default components;
