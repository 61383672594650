import React from "react";
import PropTypes from "prop-types";

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import { makeStyles } from "@material-ui/core/styles";

const ACTIONS_LIMIT = 0;

function CustomCard({ children, header, activityFlow, ...props }) {
  const classes = useStyles({ activityFlow });
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const isMenuOpen = Boolean(anchorEl);
  const menuId = "card-menu";

  let { actions = [], menuActions = [] } = header;
  const contextMenuEnabled = actions.length > ACTIONS_LIMIT;

  if (contextMenuEnabled) {
    menuActions = actions.slice(ACTIONS_LIMIT);
    actions = actions.slice(0, ACTIONS_LIMIT);
    menuActions = menuActions.map((action) => ({
      ...action,
      onClick: (event) => {
        setAnchorEl(null);
        action.onClick(event);
      },
    }));
    actions.push({
      icon: MoreVertIcon,
      onClick: handleMenuOpen,
      "aria-label": "Mostrar más",
      "aria-controls": menuId,
      "aria-haspopup": "true",
    });
  }

  return (
    <>
      <Card {...props}>
        <CardHeader
          title={header.title}
          avatar={header.avatar}
          action={actions.map((action, i) => {
            const { icon, ...restProps } = action;
            return (
              <IconButton key={i} {...restProps} onClick={handleMenuOpen}>
                <action.icon />
              </IconButton>
            );
          })}
          classes={{ root: classes.root, avatar: classes.avatar, content: classes.content, action: classes.action }}
        />
        <CardContent>{children}</CardContent>
        <CardActions>{props.actions}</CardActions>
      </Card>
      {contextMenuEnabled && (
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          id={menuId}
          keepMounted
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          open={isMenuOpen}
          onClose={handleMenuClose}
        >
          {menuActions.map((action, index) => (
            <MenuItem key={`mi-${index}`} onClick={action.onClick}>
              {action.text}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: (props) => {
    let baseStyles = {
      width: "100%",
    };

    if (props.activityFlow === "MAINTENANCE") {
      const maintenanceStyles = {
        width: "auto",
        display: "grid",
        gridTemplateColumns: "min-content min-content 1fr",
        gap: "1em",
        [theme.breakpoints.down("767")]: {
          gridTemplateColumns: "100%",
        },
      };
      baseStyles = { ...baseStyles, ...maintenanceStyles };
    }
    return baseStyles;
  },
  content: (props) => {
    let baseStyles = {
      width: "100%",
    };
    if (props.activityFlow === "MAINTENANCE") {
      const maintenanceStyles = {
        gridColumn: "1 / 2",
        gridRow: "1 / 2",
        flex: "none",
        whiteSpace: "nowrap",
        [theme.breakpoints.down("767")]: {
          gridRow: "2/3",
        },
      };
      baseStyles = { ...baseStyles, ...maintenanceStyles };
    }
    return baseStyles;
  },
  avatar: {
    gridColumn: "2 / 3",
    gridRow: "1 / 2",
    flex: "none",
    margin: "0",
    [theme.breakpoints.down("767")]: {
      gridColumn: "1 / -1",
      gridRow: "1/2",
      justifySelf: "flex-start",
      minWidth: "200px",
    },
  },
  action: {
    flex: "none",
    gridColumn: "3 / -1",
    gridRow: "1/2",
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("767")]: {
      gridColumn: "1 / -1",
    },
  },
}));

CustomCard.propTypes = {
  children: PropTypes.node,
  header: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.bool, PropTypes.elementType, PropTypes.array, PropTypes.node])
  ),
  activityFlow: PropTypes.string,
};

CustomCard.defaultProps = {
  activityFlow: "",
};

export default CustomCard;
