import React, { useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import { Box } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { Logger } from "@aws-amplify/core";

import maintenanceStyles from "../MaintenanceStyles";
import MaintenanceDetailAssets from "./MaintenanceDetailAssets";
import MaintenanceDetailMaterials from "./MaintenanceDetailMaterials";
import MaintenanceDetailObservations from "./MaintenanceDetailObservations";
import MaintenanceDetailGeneralInfo from "./MaintenanceDetailGeneralInfo";
import TabContainer from "components/Tabs/TabContainer";

import { useAPIHelpers, maintenanceReducer } from "./helpers";

import useLoadingStatus from "hooks/useLoadingStatus";
import useCallbackAfterDSEvent from "hooks/useCallbackAfterDSEvent";
import useStoreTaskEvidences from "hooks/useStoreTaskEvidences";

import { DATASTORE_EVENTS } from "constant/datastoreEvents";
import { UPDATE_TYPES } from "constant/Maintenance";

const logger = new Logger("Maintenance-detail-view");

const MaintenanceDetailView = ({ type, location }) => {
  const classes = maintenanceStyles();
  const [maintenanceState, maintenanceDispatcher] = useReducer(maintenanceReducer, {
    maintenanceInfo: null,
    assets: [],
    materials: [],
    observations: [],
  });

  const [fetching, _fetchAndSetMaintenanceStatesData] = useLoadingStatus(fetchAndSetMaintenanceStatesData);
  const { id: taskId } = useParams();
  const { loading, fetchMaintenanceData } = useAPIHelpers();
  const { storeTaskEvidences } = useStoreTaskEvidences();

  const generalInfo = (
    <MaintenanceDetailGeneralInfo
      type={type}
      location={location}
      maintenanceState={maintenanceState}
      maintenanceDispatcher={maintenanceDispatcher}
      loading={loading || fetching}
      _fetchAndSetMaintenanceStatesData={_fetchAndSetMaintenanceStatesData}
      setMaintenanceStates={setMaintenanceStates}
    />
  );

  const assets = <MaintenanceDetailAssets maintenanceState={maintenanceState} loading={loading || fetching} />;

  const materials = <MaintenanceDetailMaterials maintenanceState={maintenanceState} loading={loading || fetching} />;
  const observations = (
    <MaintenanceDetailObservations maintenanceState={maintenanceState} loading={loading || fetching} />
  );

  // Obj to define tabs
  const tabData = [
    {
      name: "Información general",
      index: 0,
      component: generalInfo,
    },
    {
      name: "Activos",
      index: 1,
      component: assets,
    },
    {
      name: "Materiales",
      index: 2,
      component: materials,
    },
    {
      name: "Observaciones",
      index: 3,
      component: observations,
    },
  ];

  useCallbackAfterDSEvent({
    event: DATASTORE_EVENTS.OUTBOX_MUTATION_PROCESSED,
    callback: () => _fetchAndSetMaintenanceStatesData(taskId),
  });
  // get details data
  useEffect(() => {
    _fetchAndSetMaintenanceStatesData(taskId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskId]);

  async function fetchAndSetMaintenanceStatesData(taskId) {
    try {
      console.time("detailviewTimeLoading");
      const taskData = await fetchMaintenanceData(taskId);

      logger.debug("===Datos del mantenimiento===", taskData);
      setMaintenanceStates(taskData);

      console.time("StoringEvidences");
      await storeTaskEvidences(taskData.allTaskAttachments, taskId, {
        limit: 30,
        showSuccessLog: true,
      });
      console.timeEnd("StoringEvidences");

      console.timeEnd("detailviewTimeLoading");
      return taskData;
    } catch (error) {
      logger.error("Error en fetchAndSetMaintenanceStatesData", error);
    }
  }

  function setMaintenanceStates({ maintenanceInfo = null, assets = null, materials = null, observations = null } = {}) {
    const stateToSet = { maintenanceInfo, assets, materials, observations };
    if (!maintenanceInfo || !assets || !materials || !observations) {
      const error = "Información incompleta al consultar información del mantenimiento";
      logger.error(error, stateToSet);
      throw new Error(error);
    }
    maintenanceDispatcher({ type: UPDATE_TYPES.ALL, payload: stateToSet });
  }

  return (
    <>
      <div>
        {/* Tab Component */}
        <TabContainer tabData={tabData} />

        <Box className={classes.mobileActionsSpacer} component="hr" height="64px" border="none" />
      </div>
    </>
  );
};

MaintenanceDetailView.propTypes = {
  type: PropTypes.string,
  history: PropTypes.object,
  location: PropTypes.object,
};

export default withRouter(MaintenanceDetailView);
