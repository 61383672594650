export const listKits = /* GraphQL */ `
  query ListKits {
    listKits {
      items {
        id
        name
        description
        _deleted
        _version
      }
      nextToken
    }
  }
`;

export default listKits;
