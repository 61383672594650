import React from "react";
import MyTeamWorkIcon from "@material-ui/icons/GroupOutlined"; //
import TicketsIcon from "@material-ui/icons/ConfirmationNumberOutlined";
import PersonalVideoOutlinedIcon from "@material-ui/icons/PersonalVideoOutlined";
import InstallationTasksIconOriginal from "@material-ui/icons/PowerOutlined";
import SupportTasksIcon from "@material-ui/icons/HeadsetMicOutlined";
import MaintenanceTasksIcon from "@material-ui/icons/BuildOutlined";
import ReceiptAssetTasksIcon from "@material-ui/icons/LocalShippingOutlined";
import CatalogueIcon from "@material-ui/icons/SubjectOutlined";
import UsersIcon from "@material-ui/icons/PeopleAltOutlined";
import DomainsIcon from "@material-ui/icons/DomainOutlined";
import IntegrationsIcon from "@material-ui/icons/ExtensionOutlined";
import AssetsIcon from "@material-ui/icons/CategoryOutlined";
import QRGenerationsIcon from "@material-ui/icons/CropFreeOutlined";
import { HandshakeIcon as ClientsIcon } from "components/custom/CustomSvgIcons";

//subIcons
import ListIcon from "@material-ui/icons/ListAltOutlined";
import MaterialsIcon from "@material-ui/icons/WorkOutlineOutlined";
import SitesIcon from "@material-ui/icons/LocationOnOutlined";
import ActivityTypesIcon from "@material-ui/icons/AssignmentOutlined";
import MaterialCategoriesIcon from "@material-ui/icons/DashboardOutlined";
import UOMIcon from "@material-ui/icons/SquareFootOutlined";
import AssetReceptionIcon from "@material-ui/icons/CallReceivedOutlined";
import ShipmentsIcon from "@material-ui/icons/SendOutlined";

//Installation Icon is rotated by default
const InstallationTasksIcon = (props) => (
  <InstallationTasksIconOriginal style={{ transform: "rotate(90deg)" }} {...props} />
);

const AdminRoutesIcons = {
  ClientsIcon,
  UsersIcon,
  DomainsIcon,
  CatalogueIcon,
  MaterialCategoriesIcon,
  UOMIcon,
  MaterialsIcon,
  SitesIcon,
  ActivityTypesIcon,
  IntegrationsIcon,
};

const WarehouseRoutesIcons = {
  AssetsIcon,
  ListIcon,
  AssetReceptionIcon,
  ShipmentsIcon,
  QRGenerationsIcon,
};

const SupportRoutesIcons = {
  TicketsIcon,
  PersonalVideoOutlinedIcon,
};

const OperationRoutesIcons = {
  InstallationTasksIcon,
  SupportTasksIcon,
  MaintenanceTasksIcon,
};

const ValidationRoutesIcons = {
  MyTeamWorkIcon,
  InstallationTasksIcon,
  SupportTasksIcon,
  MaintenanceTasksIcon,
  ReceiptAssetTasksIcon,
  CatalogueIcon,
  MaterialsIcon,
  SitesIcon,
  ActivityTypesIcon,
};

export { AdminRoutesIcons, WarehouseRoutesIcons, SupportRoutesIcons, ValidationRoutesIcons, OperationRoutesIcons };
