import React from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

function PremisesDialog(props) {
  return (
    <Dialog
      fullWidth
      maxWidth={"md"}
      scroll={"paper"}
      onClose={props.onClose}
      aria-labelledby="simple-dialog-title"
      open={props.open}
    >
      <DialogTitle id="simple-dialog-title">Sedes</DialogTitle>
      <DialogContent>
        <p>
          Por contrato, se ha puesto en marcha la plataforma Indika360 FSM, para que sea el medio necesario en caso que
          requiera atención por medio de plataforma web{" "}
        </p>
        <p>
          <strong>N°CJF/SEA/DGRM LPN/023/2020 el cual incluye las siguientes subpartidas:</strong>
        </p>
        <ul>
          <li>Pachuca,Hidalgo</li>
          <li>Campeche, Campeche</li>
          <li>Ciudad del Carmen, Campeche</li>
          <li>Durango, Durango</li>
          <li>Naucalpan, Estado de México</li>
          <li>Villahermosa, Tabasco</li>
          <li>Tuxtla Gutiérrez, Chiapas</li>
        </ul>
        <p>
          <strong>N°CJF/SEA/DGRM/LPN/026/2020el cual incluye las siguiente subpartida:</strong>
        </p>
        <ul>
          <li>Francisco Leyva. Cuernavaca, Mor.</li>
        </ul>
        <p>
          <strong>N°CJF/SEA/DGRM/LPN/029/2020el cual incluye las siguientes subpartidas:</strong>
        </p>
        <ul>
          <li>Piedras Negras, Coahuila</li>
          <li>Santa Engracia en Monterrey, Nuevo León. </li>
          <li>Constitución en Monterrey, Nuevo León. </li>
          <li>Culiacán, Sinaloa</li>
          <li>Los Mochis, Sinaloa</li>
          <li>Mazatlán, Sinaloa</li>
          <li>San Andrés Cholula, Puebla</li>
          <li>Cancún, Quintana Roo</li>
          <li>Coatzacoalcos, Veracruz.</li>
          <li>Xalapa, Veracruz. </li>
          <li>Cuernavaca, Morelos</li>
        </ul>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="default">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

PremisesDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

PremisesDialog.defaultProps = {
  open: false,
  onClose: () => {},
};

export default PremisesDialog;
