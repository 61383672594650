export function generateKitMaterialsRelations(kitID, materials = []) {
  const variables = {
    kitID,
  };
  let mutation = "";
  let mutationParams = "$kitID: ID!";

  if (materials.length > 0) {
    materials.forEach((asset, index) => {
      mutationParams += `$materialKey${index}: ID!, $uomId${index}: ID,`;
      mutation += `
          createKitMaterials${index}: createKitMaterials(input: {
            kitMaterialsKitId: $kitID,
            kitMaterialsMaterialId: $materialKey${index},
            kitMaterialsUomId: $uomId${index},
            quantity: ${asset.quantity}
            }){
              id
              _version
              _deleted
            }
            `;
      variables[`materialKey${index}`] = asset.materialId;
      variables[`uomId${index}`] = asset.uomId;
    });

    mutation = `mutation createAsignations(${mutationParams}) {
          ${mutation}
      }`;

    return {
      variables,
      mutation,
    };
  }
}
