import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import makeStyles from "@material-ui/core/styles/makeStyles";
import BackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import FlipCameraAndroidOutlinedIcon from "@material-ui/icons/FlipCameraAndroidOutlined";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import useCodeReader from "hooks/useCodeReader";
import MenuButton from "./MenuButton";
import { CODE_READER_STATUS } from "constant/codeReaderStatus";
import Typography from "@material-ui/core/Typography";
import { Device } from "@capacitor/device";
import { playAudio } from "./codeReaderAudio";

function CodeReader({
  matchedItemCode,
  matchedItemName,
  onCodeDetected,
  actions,
  status,
  bottomPanel,
  onOpen,
  ...props
}) {
  const [videoDevicesOpts, setVideoDevicesOpts] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);

  const classes = useStyles();
  const { listVideoInputDevices, startDecoding, stopDecoding } = useCodeReader({
    videoElement: "code-view",
    onCodeDetected: handleCodeDetected,
  });

  useEffect(() => {
    if (status === CODE_READER_STATUS.STOP) {
      stopDecoding();
    }
  }, [status]);

  useEffect(() => {
    handleDialogOpen();
  }, [props.open]);

  async function handleDialogOpen() {
    setDialogOpen(props.open);
  }

  function _startDecoding() {
    function handleListDevicesSuccess(videoDevices) {
      console.log("useEffect: videoInputDevices", videoDevices);

      let vdOpts = [];
      let selected = null;
      for (let i = 0; i < videoDevices.length; i++) {
        const device = videoDevices[i];
        vdOpts.push({
          value: device.deviceId,
          label: device.label,
        });
        // alert(device.deviceId);
        if (/back|rear|environment/gi.test(device.label)) {
          selected = device.deviceId;
        }
      }
      console.log("List input devices ", videoDevices);

      if (videoDevices.length === 0) {
        console.error("Your device doesn't have video devices to read the code");
        alert("Your device doesn't have video devices to read the code");
        return;
      }

      setVideoDevicesOpts(vdOpts);
      startDecoding(selected);
    }

    listVideoInputDevices().then(handleListDevicesSuccess, (error) => console.error(error));
  }

  function handleClose() {
    stopDecoding();
    props.onClose();
  }

  async function handleCodeDetected(code) {
    playAudio();
    const info = await Device.getInfo();
    onCodeDetected(code, info);
  }

  return (
    <Dialog open={dialogOpen} fullScreen fullWidth onEnter={_startDecoding} onClose={handleClose} onExit={props.onExit}>
      <video id="code-view" style={{ objectFit: "cover", height: "inherit" }}></video>
      <Box position="fixed" top={0} left={0} width="inherit">
        <div
          style={{
            display: "flex",
            width: "inherit",
            backgroundColor: "rgba(0, 0, 0, 0.65)",
          }}
        >
          <Box display="flex" alignItems="center" flexGrow={1} className={classes.iconButton}>
            <IconButton color="inherit" onClick={handleClose}>
              <BackspaceIcon />
            </IconButton>
            {props.title && <Typography color="inherit">{props.title}</Typography>}
          </Box>
          {videoDevicesOpts.length > 1 && (
            <IconButton
              className={classes.infoBtn}
              // onClick={() => props.onCameraChange()}
            >
              <FlipCameraAndroidOutlinedIcon />
            </IconButton>
          )}
          {actions.map((action, index) => (
            <IconButton key={`cr-a-${index}`} className={classes.iconButton} onClick={action.onClick}>
              <action.icon />
            </IconButton>
          ))}

          {props.menuOptions.length > 0 && (
            <MenuButton
              ButtonIcon={MoreVertIcon}
              classes={classes}
              options={props.videoDevicesOpts}
              onChange={props.onCameraChange}
            />
          )}
        </div>
      </Box>

      <Box position="fixed" bottom={0} width="inherit">
        {bottomPanel}
      </Box>
    </Dialog>
  );
}

CodeReader.propTypes = {
  open: PropTypes.bool,
  status: PropTypes.oneOf([CODE_READER_STATUS.START, CODE_READER_STATUS.STOP]),
  onClose: PropTypes.func,
  onExit: PropTypes.func,
  actions: PropTypes.arrayOf(PropTypes.object),
  onCodeDetected: PropTypes.func,
  menuOptions: PropTypes.arrayOf(PropTypes.shape()),
  matchedItemCode: PropTypes.string,
  matchedItemName: PropTypes.string,
  videoDevicesOpts: PropTypes.arrayOf(PropTypes.object),
  onCameraChange: PropTypes.func,
  bottomPanel: PropTypes.element,
  title: PropTypes.string,
  onOpen: PropTypes.func,
};

CodeReader.defaultProps = {
  open: false,
  status: CODE_READER_STATUS.STOP,
  onClose: () => {},
  onOpen: () => {},
  onExit: () => {},
  actions: [],
  onCodeDetected: () => {},
  menuOptions: [],
  matchedItemName: "",
  matchedItemCode: "",
  videoDevicesOpts: [],
  onCameraChange: () => {},
  bottomPanel: null,
  title: "",
};

const useStyles = makeStyles(() => ({
  iconButton: { color: "white" },
}));

export default CodeReader;
