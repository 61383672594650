/* eslint-disable react/display-name */
import React, { useEffect, useState } from "react";
import { Switch, Route, useLocation, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import MyTeamView from "components/site/MyTeamView";
import ValidatorInstallationsView from "components/ValidatorInstallationsView";
import ValidatorInstallationDetailsView from "components/ValidatorInstallationDetailsView";
import MaintenanceListView from "components/validation/MaintenanceListView";
import MaintenanceDetailView from "components/validation/MaintenanceDetailView";

import MainLayout from "../layout/main";
import {
  ROOT,
  INSTALLATION,
  INSTALLATIONS_ROOT,
  INSTALLATION_DETAIL_WITH_PARAM,
  MAINTENANCE,
  TEAM,
  VALIDATION_DELIVERIES,
  VALIDATION_DELIVERY_DETAILS,
  TICKET_LIST,
  TICKET_DETAILS,
  MATERIAL_CATALOG,
  PREMISES_CATALOG,
  ACTIVITIES,
  PREMISES_DETAILS,
} from "constant/route/validation";
import DeliveriesListView from "components/DeliveriesListView/DeliveriesListView";
import DeliveryDetailsView from "components/DeliveryDetailsView/DeliveryDetailsView";
import TicketsListView from "components/Tickets/TicketsListView";
import TicketDetailsView from "components/Tickets/TicketDetailsView";
import * as ROLES from "constant/roles";
import MaterialListView from "components/Materials/MaterialListView";
import PremisesView from "components/admin/PremisesView";
import PremisesDetailView from "components/admin/PremisesDetailView";
import ActivityTypesView from "components/ActivityTypes/ActivityTypesView";

import { ValidationRoutesIcons } from "constant/drawerIcons";
const {
  MyTeamWorkIcon,
  InstallationTasksIcon,
  SupportTasksIcon,
  MaintenanceTasksIcon,
  ReceiptAssetTasksIcon,
  CatalogueIcon,
  MaterialsIcon,
  SitesIcon,
  ActivityTypesIcon,
} = ValidationRoutesIcons;

const drawerItems = (cognitoGroups) => [
  {
    text: "Mi Equipo de Trabajo",
    to: TEAM,
    disabled: cognitoGroups.includes("auditors"),
    icon: MyTeamWorkIcon,
  },
  {
    text: "Instalaciones",
    to: INSTALLATION.ROOT,
    disabled: false,
    icon: InstallationTasksIcon,
  },
  {
    text: "Soporte",
    to: TICKET_LIST,
    disabled: false,
    icon: SupportTasksIcon,
  },
  {
    text: "Mantenimientos",
    to: MAINTENANCE.ROOT,
    disabled: false,
    icon: MaintenanceTasksIcon,
  },
  {
    to: VALIDATION_DELIVERIES,
    text: "Recepciones de activos",
    disabled: false,
    icon: ReceiptAssetTasksIcon,
  },
  {
    text: "Catálogos",
    icon: CatalogueIcon,
    items: [
      {
        text: "Materiales",
        to: MATERIAL_CATALOG,
        disabled: false,
        icon: MaterialsIcon,
      },
      {
        text: "Sitios",
        to: PREMISES_CATALOG,
        disabled: false,
        icon: SitesIcon,
      },
      { text: "Tipo de actividades", to: ACTIVITIES, disabled: false, icon: ActivityTypesIcon },
    ],
  },
];

const ROUTES = [
  {
    exact: true,
    path: INSTALLATIONS_ROOT,
    component: ValidatorInstallationsView,
  },
  {
    exact: true,
    path: INSTALLATION_DETAIL_WITH_PARAM,
    component: ValidatorInstallationDetailsView,
  },
  {
    exact: true,
    path: MAINTENANCE.ROOT,
    render: () => <MaintenanceListView type={ROLES.SUPERVISORS} />,
  },
  {
    exact: true,
    path: MAINTENANCE.MAINTENANCE_DETAIL_WITH_PARAM,
    render: () => <MaintenanceDetailView type={ROLES.SUPERVISORS} />,
  },
  { exact: true, path: TEAM, component: MyTeamView },
  {
    exact: true,
    path: VALIDATION_DELIVERIES,
    render: () => <DeliveriesListView type={ROLES.SUPERVISORS} />,
  },
  {
    exact: true,
    path: VALIDATION_DELIVERY_DETAILS,
    render: () => <DeliveryDetailsView type={ROLES.SUPERVISORS} />,
  },
  {
    exact: true,
    path: TICKET_LIST,
    render: () => <TicketsListView type={ROLES.SUPERVISORS} />,
  },
  {
    exact: true,
    path: TICKET_DETAILS,
    component: () => <TicketDetailsView type={ROLES.SUPERVISORS} />,
  },
  {
    exact: true,
    path: MATERIAL_CATALOG,
    component: MaterialListView,
  },
  {
    exact: true,
    path: PREMISES_CATALOG,
    component: PremisesView,
  },
  {
    exact: true,
    path: PREMISES_DETAILS,
    component: PremisesDetailView,
  },
  {
    exact: true,
    path: ACTIVITIES,
    component: ActivityTypesView,
  },
];

function ValidationRoutes() {
  const location = useLocation();
  const history = useHistory();
  const cognitoGroups = useSelector((state) => state.session.groups);
  const [drawerOptions] = useState(drawerItems(cognitoGroups));

  // Redirect to one end route if the current it's a partial one
  useEffect(() => {
    if (location.pathname === ROOT) {
      history.replace(drawerOptions[0].to);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <MainLayout drawerItems={drawerOptions}>
      <Switch>
        {ROUTES.map((route, index) => (
          <Route key={index} {...route} />
        ))}
      </Switch>
    </MainLayout>
  );
}

export default ValidationRoutes;
