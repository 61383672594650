export const listTasksByCategory = /* GraphQL */ `
  query ListTasksByCategory($category: TaskCategory, $limit: Int, $nextToken: String, $filter: ModelTaskFilterInput) {
    tasksByCategory(category: $category, limit: $limit, nextToken: $nextToken, filter: $filter) {
      items {
        id
        category
        status
        startDate
        endDate
        startTime
        endTime
        users {
          items {
            user {
              id
              name
              middlename
              lastnameP
              lastnameM
              email
              phone
              extension
              createdAt
              updatedAt
            }
            head
            createdAt
            updatedAt
          }
          nextToken
        }
        supervisor {
          id
          name
          middlename
          lastnameP
          lastnameM
          email
          phone
          extension
          roles {
            items {
              id
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        premises {
          id
          name
        }
        buildingLocation {
          id
          name
          code
          buildingBlock
          floor
          area
          premisesID
          premises {
            id
            name
            owner
          }
          owner
          createdAt
          updatedAt
        }
        taskType {
          id
          name
        }
        project {
          id
          name
          code
          company {
            id
            name
          }
        }
        owner
        createdAt
        updatedAt
        _version
        _deleted
        deleted
      }
      nextToken
    }
  }
`;
