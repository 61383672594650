import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";

import useApiTimerHelpers from "../helpers/useApiTimerHelpers";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { Controller } from "react-hook-form";
import PropTypes from "prop-types";

/**
 *
 * @param {*} setValue func to set custom value
 * @returns
 */
const FormStopTimer = ({ name, control, defaultValues = "", setValue }) => {
  const [eventCauseCatalog, setEventCauseCatalog] = useState([]);
  const { fetchCatalogEventCauseDS } = useApiTimerHelpers();

  const [eventCauseID, setEventCauseID] = React.useState("");
  const classesToggle = useStylesToggle();
  const classesToggleGroup = useToggleGroup();
  const _name = name ? name : "descriptionS";

  const handleEventCauseID = (event, newEventCauseID) => {
    setValue("eventCauseID", newEventCauseID);
    setEventCauseID(newEventCauseID);
  };

  useEffect(() => {
    handleLoadEventCauseCatalog();
    return () => {};
  }, []);

  async function handleLoadEventCauseCatalog() {
    const { eventCause } = await fetchCatalogEventCauseDS();
    setEventCauseCatalog(() => {
      return eventCause;
    });
  }
  const renderToggleButton = (eventCauseCatalog) => {
    const tabButton = eventCauseCatalog.map((element) => {
      return (
        <ToggleButton key={element.id} value={element.id} aria-label="left aligned" classes={classesToggle}>
          {element.name}
        </ToggleButton>
      );
    });
    return tabButton;
  };

  return (
    <>
      <Controller
        name="eventCauseID"
        control={control}
        rules={{ required: true }}
        defaultValue={eventCauseID}
        as={
          <>
            <InputLabel htmlFor="input-with-icon-adornment">Motivo por el que detienes el servicio</InputLabel>
            <ToggleButtonGroup
              value={eventCauseID}
              exclusive
              onChange={handleEventCauseID}
              aria-label="text alignment"
              classes={classesToggleGroup}
            >
              {renderToggleButton(eventCauseCatalog)}
            </ToggleButtonGroup>
          </>
        }
      />
      <Controller
        name={_name}
        control={control}
        rules={{ required: true }}
        defaultValue={defaultValues[_name]}
        as={
          <TextField
            name={_name}
            type="text"
            label="Descripcion del incidente"
            variant="outlined"
            fullWidth
            required
            multiline
            rows={4}
          />
        }
      />
    </>
  );
};

export default FormStopTimer;

FormStopTimer.propTypes = {
  name: PropTypes.string,
  control: PropTypes.object,
  defaultValues: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  setValue: PropTypes.any,
};

const useStylesToggle = makeStyles((theme) => ({
  root: {
    fontSize: "12px",
    textTransform: "unset",
    width: "50%",
  },
}));

const useToggleGroup = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: "10px",
    marginTop: "10px",
  },
}));
