import { 
  SERVICE_WORKER_UPDATE, 
  SERVICE_WORKER_CLEAN, 
  SERVICE_WORKER_ACTIVE, 
  SERVICE_WORKER_UPDATE_PENDING,
} from "redux/action";

const INITIAL_STATE = {
  swUpdated: false,
  swLoading: false,
  swRegistration: null,
  swActivated: false,
};

export default function serviceWorkerReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SERVICE_WORKER_UPDATE:
      return {
        ...state,
        swUpdated: true,
        swRegistration: action.payload,
      };
    case SERVICE_WORKER_UPDATE_PENDING:
      return {
        ...state,
        swLoading: true,
      };
    case SERVICE_WORKER_ACTIVE:
      return {
        ...state,
        swUpdated: false,
        swLoading: false,
        swRegistration: null,
        swActivated: true,
      };
    case SERVICE_WORKER_CLEAN:
      return INITIAL_STATE;
    default:
      return state;
  }
}
