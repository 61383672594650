import React from "react";
import PropTypes from "prop-types";
import { useTheme, useMediaQuery, Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import CustomButton from "components/custom/Button";
import ProjectOperationsForm from "./ProjectOperationsForm";
import { useForm } from "react-hook-form";
import { useFormOperations } from "./CompanyFormOperations";
import useProjectOperations from "./useProjectOperations";
import { trimFormStringValues } from "util/forms";
import { transformToISO_8601, transformToDate } from "util/date";
import { CREATE_PROJECT_DEFAULT_FORM_VALUES, DEFAULT_TIME } from "./constant";

export const UpdateProjectFormDialog = ({
  initialValues,
  projectId,
  projectVersion,
  onConfirm,
  onConfirmError,
  onCancel,
  onCancelError,
}) => {
  const { isFormOpen, closeForm } = useFormOperations();
  const { updateProject, loading } = useProjectOperations();

  const { register, getValues, errors, trigger, control, reset, setValue } = useForm({
    defaultValues: CREATE_PROJECT_DEFAULT_FORM_VALUES,
  });
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  React.useEffect(() => {
    const initialValuesKeys = Object.keys(initialValues);
    if (isFormOpen && initialValuesKeys.length) {
      Object.keys(CREATE_PROJECT_DEFAULT_FORM_VALUES).forEach((key) => {
        const value = initialValues[key];
        let valueToSet = value || null;

        if (key === "startAt" && value) {
          valueToSet = transformToDate(value, "MM/DD/YYYY"); //formato de fecha para datePicker
        }
        if (key === "endAt" && value) {
          valueToSet = transformToDate(value, "MM/DD/YYYY");
        }
        setValue(key, valueToSet);
      });
    }
  }, [initialValues, isFormOpen, setValue]);

  async function handleValidate(values) {
    let isError = false;
    if (!values.name.length) {
      isError = true;
      await trigger("name");
    }
    return isError;
  }

  async function handleCreateCompany() {
    try {
      let values = trimFormStringValues(getValues());
      const isError = await handleValidate(values);
      if (isError) {
        return;
      }
      if (values.startAt) {
        values.startAt = transformToISO_8601(`${values.startAt} ${DEFAULT_TIME}`);
      } else {
        values.startAt = null;
      }
      if (values.endAt) {
        values.endAt = transformToISO_8601(`${values.endAt} ${DEFAULT_TIME}`);
      } else {
        values.endAt = null;
      }
      const input = { id: projectId, _version: projectVersion, ...values };
      const updatedProject = await updateProject(input, {
        successMsg: "Proyecto actualizado satisfactoriamente.",
        errorMsg: "Error durante la actualización del proyecto, intentelo nuevamente.",
      });
      onConfirm(updatedProject);
      closeForm();
    } catch (error) {
      onConfirmError(error, { closeForm });
    }
  }

  function handleClose() {
    try {
      onCancel();
    } catch (error) {
      onCancelError(error);
    } finally {
      closeForm();
    }
  }

  function onDialogExit() {
    reset();
  }

  return (
    <Dialog
      open={isFormOpen}
      onClose={closeForm}
      onExit={onDialogExit}
      maxWidth="sm"
      aria-labelledby="project-update-form-dialog"
      fullWidth
      fullScreen={fullScreen}
      keepMounted={false}
    >
      <DialogTitle id="project-update-form-dialog-title">Actualización de Proyecto</DialogTitle>
      <DialogContent dividers>
        <ProjectOperationsForm
          inputRef={register}
          control={control}
          nameFieldProps={{ error: !!errors.name, helperText: errors.name?.message }}
          startAtFieldProps={{ loadingIndicator: loading }}
          endAtFieldProps={{ loadingIndicator: loading }}
        />
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={handleClose} color="inherit">
          Cancelar
        </CustomButton>
        <CustomButton onClick={handleCreateCompany} color="primary" loading={loading}>
          Actualizar
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateProjectFormDialog;

UpdateProjectFormDialog.propTypes = {
  onConfirm: PropTypes.func,
  onConfirmError: PropTypes.func,
  onCancel: PropTypes.func,
  onCancelError: PropTypes.func,
  initialValues: PropTypes.shape(),
  projectId: PropTypes.string.isRequired,
  projectVersion: PropTypes.number.isRequired,
};

UpdateProjectFormDialog.defaultProps = {
  onConfirm: () => {},
  onConfirmError: () => {},
  onCancel: () => {},
  onCancelError: () => {},
  initialValues: {},
};
