import React from "react";
import PropTypes from "prop-types";
import { Box, Grid, Divider } from "@material-ui/core";
import useStyles from "../MaintenanceStyles";
import DataLabel from "components/DataLabel";
import { FormatUserName, FormatDirection } from "util/FormatDetails";
import { filterDeletedItems } from "util/lists";
import dayjs from "dayjs";

const GeneralInformation = ({ data, isLoading }) => {
  const classes = useStyles();

  function handleTechnicianUsersRender() {
    if (!data || !Object.keys(data).length) return false;
    const filteredTechnicians = filterDeletedItems(data?.users?.items);

    return filteredTechnicians.map(({ user, id }) => (
      <li key={id} style={{ wordBreak: "break-word" }}>
        {FormatUserName(user)}
      </li>
    ));
  }

  return (
    <>
      <Box className={classes.sectionTitleContainer}>
        <img src="/img/portfolio.png" alt="icon" />
        <p className={classes.sectionTitleText}>Información general</p>
      </Box>
      <Divider variant="middle" />
      <Box className={classes.sectionTitleContainer}>
        <Grid container spacing={2}>
          <Grid item md={4} xs={6}>
            <DataLabel
              label="Fecha y hora de inicio:"
              data={dayjs(`${data?.startDate} ${data?.startTime}` || "").format("DD/MM/YYYY HH:mm")}
              loading={isLoading}
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <DataLabel
              label="Fecha y hora de fin:"
              data={dayjs(`${data?.endDate} ${data?.endTime}` || "").format("DD/MM/YYYY HH:mm")}
              loading={isLoading}
            />
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.sectionTitleContainer}>
        <Grid container spacing={2}>
          <Grid item md={4} xs={6}>
            <DataLabel
              label="Tipo de mantenimiento:"
              data={data?.taskType?.name || "Cargando..."}
              loading={isLoading}
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <DataLabel label="Lugar de mantenimiento:" data={data?.premises?.name || ""} loading={isLoading} />
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.sectionTitleContainer}>
        <Grid container spacing={2}>
          <Grid item md={4} xs={12}>
            <DataLabel label="Dirección" data={FormatDirection(data?.premises?.address) || ""} loading={isLoading} />
          </Grid>
          <Grid item md={4} xs={6}>
            <DataLabel
              label="Técnico responsable"
              data={handleTechnicianUsersRender(data?.users?.items) || ""}
              loading={isLoading}
            />
          </Grid>
          <Grid item md={4} xs={6}>
            <DataLabel
              label="Coordinador"
              data={<li style={{ wordBreak: "break-word" }}>{FormatUserName(data?.supervisor) || ""} </li>}
              loading={isLoading}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

GeneralInformation.propTypes = {
  data: PropTypes.object,
  isLoading: PropTypes.bool,
};

GeneralInformation.defaultProps = {
  data: { users: { items: [] } },
  isLoading: false,
};

export default GeneralInformation;
