import { Logger } from "aws-amplify";
import useCompanyOperations from "../useCompanyOperations";
import { captureException } from "setup/sentry";
import { ENABLEMENT_MODE } from "../constant";
import { useEnablement } from "./CompanyEnablement";

const logger = new Logger("CompanyEnablementDialog-");

export default function useCompanyEnablementHelpers() {
  const { updateCompany, loading } = useCompanyOperations();
  const { elementId: clientId, elementVersion: clientVersion, mode, closeDialog } = useEnablement();

  async function handleConfirm({ onConfirm, onConfirmError } = {}) {
    let resultData = null;
    try {
      if (mode === ENABLEMENT_MODE.DISABLED) {
        resultData = await handleDisabledCompany(clientId, clientVersion);
      }
      if (mode === ENABLEMENT_MODE.ENABLED) {
        resultData = await handleEnableCompany(clientId, clientVersion);
      }
    } catch (error) {
      logger.error("_onConfirm", error);
      captureException(error, "_onConfirm: CompanyEnablementDialog");
      onConfirmError(error, { closeDialog });
    } finally {
      onConfirm(resultData);
      closeDialog();
    }
  }

  function handleCancel({ onCancel } = {}) {
    onCancel();
    closeDialog();
  }

  const handleDisabledCompany = async (clientId, clientVersion) =>
    updateCompany(
      { id: clientId, _version: clientVersion, enabled: false },
      {
        successMsg: "Cliente deshabilitado satisfactoriamente.",
        errorMsg: "Ocurrio un error deshabilitando al cliente.",
      }
    );

  const handleEnableCompany = async (clientId, clientVersion) =>
    updateCompany(
      { id: clientId, _version: clientVersion, enabled: true },
      {
        successMsg: "Cliente habilitado satisfactoriamente.",
        errorMsg: "Ocurrio un error habilitando al cliente.",
      }
    );

  return { handleConfirm, handleCancel, loading };
}
