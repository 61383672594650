export function generateKitMaterialsMutation(kitID, { all = [], added = [], updated = [], deleted = [] }) {
  if (!added.length && !updated.length && !deleted.length) {
    return {};
  }

  let createParams = "";
  let createParamsObj = {};
  let createMutations = "";

  let updateParams = "";
  let updateParamsObj = {};
  let updateMutations = "";

  let deleteParams = "";
  let deleteParamsObj = {};
  let deleteMutations = "";

  all.forEach(({ materialId, quantity, uomId, relationVersion, relationId }, index) => {
    if (added.includes(materialId)) {
      createParams += `$createKitMaterial${index}: CreateKitMaterialsInput!, `;
      createParamsObj[`createKitMaterial${index}`] = {
        kitMaterialsKitId: kitID,
        kitMaterialsMaterialId: materialId,
        kitMaterialsUomId: uomId,
        quantity,
      };
      createMutations += `
        createKitMaterial${index}: createKitMaterials(input: $createKitMaterial${index}) {
          id
          quantity
          uom {
            id
          }
          _version
          _deleted
        },\n
      `;
    } else if (updated.includes(materialId)) {
      updateParams += `$updateKitMaterial${index}: UpdateKitMaterialsInput!, `;
      updateParamsObj[`updateKitMaterial${index}`] = {
        id: relationId,
        _version: relationVersion,
        kitMaterialsUomId: uomId,
        quantity,
      };
      updateMutations += `
        updateKitMaterial${index}: updateKitMaterials(input: $updateKitMaterial${index}) {
          id
          quantity
          uom {
            id
          }
          _version
          _lastChangedAt
          _deleted
        },\n
      `;
    }
  });

  deleted.forEach(({ id, _version }, index) => {
    deleteParams += `$deleteKitMaterial${index}: DeleteKitMaterialsInput!, `;
    deleteParamsObj[`deleteKitMaterial${index}`] = { id, _version };
    deleteMutations += `
      deleteKitMaterial${index}: deleteKitMaterials(input: $deleteKitMaterial${index}) {
        id
        _version
        _lastChangedAt
        _deleted
      },\n
    `;
  });

  const materialUpdateMutation = `
    mutation UpdateKitMaterials(
      ${createParams + updateParams + deleteParams}
      ) {
      ${createMutations + updateMutations + deleteMutations}
    }
  `;
  const materialUpdateParams = {
    ...createParamsObj,
    ...updateParamsObj,
    ...deleteParamsObj,
  };

  return { mutation: materialUpdateMutation, params: materialUpdateParams };
}