import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Logger } from "@aws-amplify/core";
import useOnlineStatus from "@rehooks/online-status";
import { useIndexedDB } from "react-indexed-db";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "components/custom/Button";
import Alert from "@material-ui/lab/Alert";

import { makeStyles } from "@material-ui/core/styles";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import LoadingPanel from "components/common/LoadingPanel";
import useLoadingStatus from "hooks/useLoadingStatus";
import useNotifier from "hooks/useNotifier";
import SingleLineGridList from "./SingleLineGridList";
import { deleteFiles, StorageException, uploadFile } from "util/file";
import { TaskStatus } from "models";
import { VISUALIZATION_TYPES } from "constant/visualizationTypes";
import useGraphQL from "hooks/useGraphQL";
import * as mutations from "graphql-custom/mutations";
import * as queries from "graphql-custom/queries";
import { writeTextOnImage, mapPictureErrorMessage, toImageObject, toImageObjectOffline } from "util/image";
import { useSelector } from "react-redux";
import cleanUUID from "util/cleanUUID";
import AttachmentDownloadButton from "components/common/AttachmentDownloadButton";
import { ATTACHMENT_TYPES } from "constant/attachmentDownloadTypes";
import { handleElementId, handleGetName } from "util/text";
import useDataStore from "hooks/useDataStore";
import * as models from "models";
import { EVIDENCE_TYPES } from "constant/evidenceTypes";
import { useParams } from "react-router-dom";
import imageUtil from "util/image";
import { v4 as uuidv4 } from "uuid";
import getS3EvidencesFiles from "util/getS3EvidencesFiles";
import useStoreTaskEvidences from "hooks/useStoreTaskEvidences";
import useDeviceSettings from "hooks/useDeviceSettings";
import { ATTACHMENT_PLATFORM_MAP, ATTACHMENT_SOURCE_MAP } from "constant/attachmentAsset";
import { AttachmentLoadType } from "models";
import { ACTIVITY_CATEGORY, METADATA_KEYS, ATTACHMENT_METADATA_TYPES } from "constant/attachments";
import useWatchPosition from "hooks/useWatchPosition";

const logger = new Logger("MaintenanceEvidenceDialog");
const AWS_REGION = process.env.REACT_APP_AWS_REGION;
const AMAZON_S3_BUCKET = process.env.REACT_APP_AWS_USER_FILES_S3_BUCKET;
const noCredentialsError = "Cannot read properties of undefined (reading 'byteLength')";
export default function MaintenanceEvidenceDialog({
  isOpen,
  onClose,
  taskAsset,
  assetAttachments,
  visualizationType,
  ...props
}) {
  const [taskAssetId, setTaskAssetId] = useState(taskAsset?.id);
  const [startAttachments, setStartAttachments] = useState([]);
  const [progressAttachments, setProgressAttachments] = useState([]);
  const [endAttachments, setEndAttachments] = useState([]);
  const [attachmentsWithError, setAttachmentsWithError] = useState({
    SCHEDULED: [],
    IN_PROGRESS: [],
    FINALIZED: [],
  });
  const [attachmentsUploadProgress, setAttachmentsUploadProgress] = useState({});
  const [isDownloadable, setIsDownloadable] = useState(false);

  const { id: taskId } = useParams();
  const classes = useStyles();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const currentUserId = useSelector(({ session }) => session.userId);
  const { showError, showMessage } = useNotifier();
  const { methods: evidenceMethods } = useStoreTaskEvidences();

  const [loading, _loadAttachments] = useLoadingStatus(loadAttachments);
  const [addingNewEvidence, _addEvidenceWithCamera] = useLoadingStatus(addEvidenceWithCamera);
  const [removingEvidence, _removeSelectedimage] = useLoadingStatus(removeSelectedimage);

  const AttachmentAsset = useDataStore(models.AttachmentAsset);
  const offlineImagesDB = useIndexedDB("offline_images");
  const { runGraphQLOperation } = useGraphQL();
  const isOnline = useOnlineStatus();
  const device = useDeviceSettings();
  const { position, loading: loadingPosition, error: positionError } = useWatchPosition();
  const {
    hasEvidencesWithErrors,
    evidencesWithErrorsCount,
    hasScheduledErrors,
    hasInProgressErrors,
    hasFinalizedErrors,
  } = React.useMemo(() => {
    const hasScheduledErrors = Boolean(attachmentsWithError.SCHEDULED.length);
    const hasInProgressErrors = Boolean(attachmentsWithError.IN_PROGRESS.length);
    const hasFinalizedErrors = Boolean(attachmentsWithError.FINALIZED.length);
    const hasEvidencesWithErrors = hasScheduledErrors || hasInProgressErrors || hasFinalizedErrors;
    const evidencesWithErrorsCount =
      attachmentsWithError.SCHEDULED.length +
      attachmentsWithError.IN_PROGRESS.length +
      attachmentsWithError.FINALIZED.length;
    return {
      hasScheduledErrors,
      hasInProgressErrors,
      hasFinalizedErrors,
      hasEvidencesWithErrors,
      evidencesWithErrorsCount,
    };
  }, [
    attachmentsWithError.FINALIZED.length,
    attachmentsWithError.IN_PROGRESS.length,
    attachmentsWithError.SCHEDULED.length,
  ]);

  useEffect(() => {
    if (isOpen) {
      setTaskAssetId(taskAsset.id);
      _loadAttachments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  async function loadAttachments(attemps = 0) {
    let currentAttemps = attemps;
    const allEvidences = [...assetAttachments.begin, ...assetAttachments.progress, ...assetAttachments.end];

    try {
      if (!allEvidences || Boolean(!allEvidences?.length)) return null;

      const IDB_EVIDENCES = await evidenceMethods.getAllTaskEvidences(taskId);
      const { fulfilled } = await updateEvidencesData(allEvidences, IDB_EVIDENCES);

      setAllAttachments(fulfilled);
      setIsDownloadable(Boolean(fulfilled?.length));
      // setAttachmentsWithError(rejected);
    } catch (error) {
      logger.error("Error en maintenanceEvidenceDialog: loadAttachments", {
        error,
        assetAttachments,
      });

      /**
       * Error cuando las credenciales de usuario por alguna razón no se encuentran disponibles
       * accessKeyId: undefined, authenticated: undefined, identityId: undefined, secretAccessKey: undefined, sessionToken: undefined
        por lo que s3 es incapaz de retornar la url de la evidencia firmada
       */
      if (error.message === noCredentialsError) {
        const parseEvidenceData = allEvidences.map((evidence) => ({
          taskAssetId: taskAsset?.id || taskAssetId,
          awsSource: null,
          urlObjectSource: null,
          uploaded: evidence.hasOwnProperty("uploaded") ? evidence.uploaded : true,
          ...evidence,
        }));
        return setAllAttachments(parseEvidenceData);
      }

      //En caso de error se intentará cargar las evidencias un máximo de 3 intentos
      if (currentAttemps < 3) {
        currentAttemps += 1;
        _loadAttachments(currentAttemps);
      } else {
        showError(`Error cargando las evidencias del activo ${handleGetName(taskAsset)}. Intentalo en otro momento.`);
        onClose(taskAsset?.id, { startAttachments, progressAttachments, endAttachments }, false);
      }
    }
  }

  async function updateEvidencesData(evidences = [], IDB_EVIDENCES = []) {
    const updatedEvidencesPromises = evidences.map(async (evidence) => {
      let result = {
        ...evidence,
        taskAssetId: taskAsset?.id,
        awsSource: null,
        urlObjectSource: null,
        uploaded: false,
      };
      const evidenceInIDB = IDB_EVIDENCES.find((idb_evidence) => evidence.id === idb_evidence.id);
      const evidenceExist = Boolean(evidenceInIDB);
      const { urlObjectSource, awsSource, sourceBlob } = await handleGetEvidenceSources(evidence, evidenceInIDB);
      result.urlObjectSource = urlObjectSource;
      result.awsSource = awsSource;
      if (evidenceExist) {
        const updateInput = { ...evidenceInIDB, awsSource, sourceBlob };
        await evidenceMethods.updateEvidenceInIDB(updateInput, { showSuccessLog: true });
      } else {
        logger.debug("===La siguiente evidencia no existe en indexedDB===", { evidenceId: evidence.id });
        const storeInput = {
          id: evidence.id,
          taskAssetId: taskAsset?.id,
          taskId,
          sourceBlob,
          awsSource: result.awsSource,
        };
        await evidenceMethods.storeOneEvidenceInIDB(storeInput, { showSuccessLog: true });
      }
      result.uploaded = evidence.hasOwnProperty("uploaded") ? evidence.uploaded : true;
      return result;
    });
    let updatedEvidences = await Promise.allSettled(updatedEvidencesPromises).catch((error) => logger.error(error));
    updatedEvidences = updatedEvidences.reduce(
      (promises, currentPromise, index) => {
        promises[currentPromise.status].push(currentPromise.value || currentPromise.reason.message);
        return promises;
      },
      { fulfilled: [], rejected: [] }
    );

    return updatedEvidences;
  }

  async function getOnlineEvidenceSources(
    assetEvidence,
    evidenceCached,
    { initialAwsSource, initialSourceBlob, initialUrlObjectSource, allowDownloadBlob = false } = {}
  ) {
    let result = {
      urlObjectSource: initialUrlObjectSource || assetEvidence.urlObjectSource || null,
      awsSource: initialAwsSource || evidenceCached?.awsSource || assetEvidence.awsSource || null,
      sourceBlob: initialSourceBlob || evidenceCached?.sourceBlob || null,
    };

    const [{ awsSource, sourceBlob }] = await getS3EvidencesFiles(
      [{ ...assetEvidence, awsSource: result.awsSource, sourceBlob: result.sourceBlob }],
      {
        download: allowDownloadBlob, //si no hay sourceBlob se habilita la descarga
        onError: onGetSourcesError,
      }
    );

    if (!Boolean(result.urlObjectSource)) {
      result.urlObjectSource = URL.createObjectURL(result.sourceBlob || sourceBlob);
    }
    result.awsSource = awsSource;

    return result;
  }

  async function handleGetEvidenceSources(assetEvidence, evidenceCached) {
    let result = {
      urlObjectSource: assetEvidence.urlObjectSource || null,
      awsSource: evidenceCached?.awsSource
        ? evidenceCached?.awsSource
        : assetEvidence.awsSource
        ? assetEvidence.awsSource
        : null,
      sourceBlob: evidenceCached?.sourceBlob
        ? evidenceCached?.sourceBlob
        : assetEvidence.sourceBlob
        ? assetEvidence.sourceBlob
        : null,
    };

    if (isOnline) {
      const { urlObjectSource, awsSource, sourceBlob } = await getOnlineEvidenceSources(assetEvidence, evidenceCached, {
        allowDownloadBlob: !result.sourceBlob,
        initialAwsSource: result.awsSource,
        initialUrlObjectSource: result.urlObjectSource,
        initialSourceBlob: result.sourceBlob,
      });
      result.urlObjectSource = urlObjectSource;
      result.awsSource = awsSource;
      result.sourceBlob = result.sourceBlob ? result.sourceBlob : sourceBlob;
    }
    if (result.sourceBlob) {
      result.urlObjectSource = URL.createObjectURL(result.sourceBlob);
    }

    return result;
  }

  function onGetSourcesError(error, assetEvidence) {
    setAttachmentsWithError((attachmentsWithError) => {
      attachmentsWithError[assetEvidence.status] = [
        ...attachmentsWithError[assetEvidence.status],
        { ...assetEvidence, error },
      ];
      return attachmentsWithError;
    });
  }

  async function addEvidenceWithCamera(image, phase, photoSource) {
    if (positionError) {
      throw positionError;
    }
    const extension = imageUtil.getFileExtension(image.filename);
    const attachmentAssetId = uuidv4();
    let s3KeyObject = attachmentAssetId.slice();
    let createAttachmentAsset = null;

    if (extension) {
      s3KeyObject = `${ATTACHMENT_METADATA_TYPES.ATTACHMENT_ASSET}_${s3KeyObject}.${extension}`;
    }

    if (props.attachmentPrefix) {
      s3KeyObject = `${props.attachmentPrefix}/${s3KeyObject}`;
    }

    let attachment = {
      id: new Date().getTime().toString(),
      taskAssetId: taskAssetId || taskAsset?.id,
      awsSource: null,
      sourceBlob: null,
      taskId,
      //---

      urlObjectSource: null,
      uploaded: false,
      status: phase,
      file: {
        key: s3KeyObject,
        bucket: AMAZON_S3_BUCKET,
        region: AWS_REGION,
      },
    };

    const initialUrlObjectSource = URL.createObjectURL(image.blob);
    try {
      setAttachmentByStatus(
        {
          updateCallback: (attachments) => [
            { ...attachment, urlObjectSource: initialUrlObjectSource, loading: true },
            ...attachments,
          ],
        },
        phase
      );

      setAttachmentsUploadProgress((value) => ({ ...value, [attachment.id]: null }));

      let watermarkedImage = await writeTextOnImage(image.dataUrl, currentUserId, position);
      let updatedImage;
      if (isOnline) {
        updatedImage = await toImageObject(watermarkedImage);
      } else {
        updatedImage = await toImageObjectOffline(watermarkedImage);
      }

      const deviceInfo = device.getInfo();
      const urlObjectSource = URL.createObjectURL(updatedImage.blob);
      attachment.sourceBlob = updatedImage.blob;
      attachment.urlObjectSource = urlObjectSource;

      if (isOnline) {
        const createAttachmentAssetResponse = await runGraphQLOperation({
          operation: mutations.createAttachmentAsset,
          variables: {
            input: {
              id: attachmentAssetId,
              file: attachment.file,
              taskAssetID: attachment.taskAssetId,
              status: phase,
              platform: ATTACHMENT_PLATFORM_MAP[deviceInfo.platform],
              source: ATTACHMENT_SOURCE_MAP[photoSource],
              loadType: AttachmentLoadType.ONLINE,
            },
          },
          notifications: { errorMsg: "Ocurrió un error al cargar la evidencia, intenta nuevamente" },
        });
        createAttachmentAsset = createAttachmentAssetResponse.createAttachmentAsset;
        await uploadFile(attachment.file.key, updatedImage.blob, {
          progressCallback(progress) {
            const { loaded, total } = progress;
            let percentage = (loaded * 100) / total;
            percentage = parseInt(percentage.toFixed(0));
            setAttachmentsUploadProgress((value) => ({ ...value, [attachment.id]: percentage }));
          },
          metadata: {
            [METADATA_KEYS.ATTACHMENT_ID]: createAttachmentAsset.id,
            [METADATA_KEYS.ATTACHMENT_TYPE]: ATTACHMENT_METADATA_TYPES.ATTACHMENT_ASSET,
            [METADATA_KEYS.ACTIVITY_ID]: taskId,
            [METADATA_KEYS.ACTIVITY_CATEGORY]: ACTIVITY_CATEGORY.MAINTENANCE,
          },
        });

        logger.log("addEvidenceWithCamera: ", { createAttachmentAsset });
        const [{ awsSource }] = await getS3EvidencesFiles([{ file: createAttachmentAsset.file.key }]);
        attachment.awsSource = awsSource;

        const { file, status, urlObjectSource, uploaded, ...IDBInput } = attachment;
        await evidenceMethods.storeOneEvidenceInIDB(
          { ...IDBInput, id: createAttachmentAsset.id },
          {
            showSuccessLog: true,
          }
        );

        setAttachmentByStatus(
          {
            updateCallback: (attachments) => {
              let attachmentsCopy = [...attachments];

              const evidenceIndex = attachmentsCopy.findIndex((evidence) => evidence.id === attachment.id);

              if (evidenceIndex === -1) {
                return attachmentsCopy;
              }

              //definir propiedades / consistencia de los datos
              const { _version, _deleted } = createAttachmentAsset;
              attachment.id = createAttachmentAsset.id;
              attachment.uploaded = true;
              delete attachment.taskId;
              delete attachment.sourceBlob;
              attachmentsCopy[evidenceIndex] = {
                ...attachment,
                _version,
                _deleted,
                _lastChangedAt: createAttachmentAsset._lastChangedAt || null,
              };
              return attachmentsCopy;
            },
          },
          phase
        );
      } else {
        //const newId = uuidv4(); //forzar renderizado del componente mediante la prop key
        const { file, status, urlObjectSource, uploaded, ...IDBInput } = attachment;
        await evidenceMethods.storeOneEvidenceInIDB(
          { ...IDBInput, id: attachmentAssetId },
          {
            showSuccessLog: true,
          }
        );

        const offlineImagesInput = {
          id: attachmentAssetId,
          type: EVIDENCE_TYPES.ATTACHMENT_ASSET,
          blob: updatedImage.blob,
          taskAssetID: attachment.taskAssetId,
          platform: ATTACHMENT_PLATFORM_MAP[deviceInfo.platform],
          source: ATTACHMENT_SOURCE_MAP[photoSource],
          loadType: AttachmentLoadType.OFFLINE,
          file,
          status,
          taskId,
          metadata: {
            [METADATA_KEYS.ATTACHMENT_ID]: attachmentAssetId,
            [METADATA_KEYS.ATTACHMENT_TYPE]: ATTACHMENT_METADATA_TYPES.ATTACHMENT_ASSET,
            [METADATA_KEYS.ACTIVITY_ID]: taskId,
            [METADATA_KEYS.ACTIVITY_CATEGORY]: ACTIVITY_CATEGORY.MAINTENANCE,
          },
        };
        await offlineImagesDB.add(offlineImagesInput);

        setAttachmentByStatus(
          {
            updateCallback: (attachments) => {
              let attachmentsCopy = [...attachments];
              const index = attachmentsCopy.findIndex((a) => a.id === attachment.id);
              if (index === -1) {
                return attachmentsCopy;
              }

              delete attachment.taskId;
              delete attachment.sourceBlob;

              attachmentsCopy[index] = { ...attachment, id: attachmentAssetId };

              return attachmentsCopy;
            },
          },
          phase
        );
      }
      setAttachmentsUploadProgress((value) => ({ ...value, [attachment.id]: undefined }));
      URL.revokeObjectURL(initialUrlObjectSource);
    } catch (error) {
      logger.error(error);
      handleAddEvidenceMessageError(error);
      // Remove the loading image
      removeEvidenceFromStateById(attachment.id, phase);
      if (error instanceof StorageException && isOnline) {
        // Ocurrio un error durante la carga a s3 de la evidencia, sin embargo se alcanzó a crear la imagen en Dynamo
        // Por lo que procedemos a eliminar la evidencia de Dynamo
        await handleRemoveOnlineEvidence(createAttachmentAsset.id, attachment.file.key);
      }
      URL.revokeObjectURL(initialUrlObjectSource);
    }
  }

  function handleAddEvidenceMessageError(error) {
    const errorMessage = mapPictureErrorMessage(error);
    if (error.message === noCredentialsError) {
      showError("Ocurrió un error durante la carga de evidencia");
    } else {
      showError(errorMessage);
    }
  }

  function removeEvidenceFromStateById(attachmentId, status) {
    setAttachmentByStatus(
      {
        updateCallback: (attachments) => {
          const attachmentsCopy = [...attachments];
          return attachmentsCopy.filter((attachement) => attachement.id !== attachmentId);
        },
      },
      status
    );
  }

  async function removeSelectedimage(attachment, status) {
    const { id, file } = attachment;

    logger.debug("Evidencia por eliminar", { evidenceId: id });
    try {
      setAttachmentByStatus(
        {
          updateCallback: (evidences = []) => {
            const evidencesCopy = [...evidences];
            const evidenceIndex = evidencesCopy.findIndex((evidence) => evidence.id === id);
            evidencesCopy[evidenceIndex] = { ...attachment, loading: true };
            return evidencesCopy;
          },
        },
        status
      );
      setAttachmentsUploadProgress((value) => ({ ...value, [attachment.id]: null }));

      if (isOnline) {
        await handleRemoveOnlineEvidence(id, file.key);
      } else {
        await handleRemoveOfflineEvidence(id, file);
      }
      // await wait(700);
      setAttachmentsUploadProgress((value) => ({ ...value, [attachment.id]: undefined }));
      removeEvidenceFromStateById(id, status);
    } catch (error) {
      logger.error("Error eliminando evidencia", error);
      handleRemoveEvidenceError(id, status);
    }
  }

  async function handleRemoveOnlineEvidence(evidenceId, keyFile) {
    let response = {
      evidenceDeleted: null,
      s3FileDeleted: null,
      evidenceRemovedInIDB: null,
    };
    const { getAttachmentAsset } = await runGraphQLOperation({
      operation: queries.getAttachmentAsset,
      variables: { id: evidenceId },
    });
    response.evidenceDeleted = await runGraphQLOperation({
      operation: mutations.deleteAttachmentAsset,
      variables: { input: { id: evidenceId, _version: getAttachmentAsset._version } },
    });
    if (Boolean(keyFile)) {
      response.s3FileDeleted = await deleteFiles(keyFile);
    }
    response.evidenceRemovedInIDB = await evidenceMethods.removeOneEvidenceInIDB(evidenceId, { showSuccessLog: true });
    return response;
  }

  async function handleRemoveOfflineEvidence(evidenceId, file) {
    const notifications = {
      successMsg: "Evidencia elminada correctamente",
      errorMsg: "Ocurrió un error al eliminar la evidencia, intenta nuevamente",
    };
    //conocer si la evidencia fue añadadida en modo offline
    const isInCloud = await isEvidenceLoadedInCloud(evidenceId);

    if (!isInCloud) {
      // eliminamos unicamente del bucket de evidencias que se despachan al volver online
      await offlineImagesDB.deleteRecord(evidenceId);
      showMessage("Evidencia elminada correctamente");
    } else {
      const localAttachment = {
        id: evidenceId,
        taskId,
        pendingDelete: true,
        file: { ...file },
      };
      //removemos la relación,
      await AttachmentAsset.remove({
        condition: evidenceId,
        notifications,
      });
      //removemos la evidencia pendiente por cargar
      await offlineImagesDB.add(localAttachment);
    }
    //removemos la evidencia cacheada en IDB
    await evidenceMethods.removeOneEvidenceInIDB(evidenceId, { showSuccessLog: true });
  }

  async function isEvidenceLoadedInCloud(evidenceId) {
    let isInCloud;
    //conocer si la evidencia fue añadadida en modo offline
    const offlineEvidences = await offlineImagesDB.getAll();
    if (offlineEvidences && Boolean(offlineEvidences?.length)) {
      const IDBEvidenceIndex = offlineEvidences.findIndex((evidencia) => evidencia.id === evidenceId);
      isInCloud = IDBEvidenceIndex !== -1 ? false : true;
    } else {
      isInCloud = true;
    }
    return isInCloud;
  }

  function handleRemoveEvidenceError(evidenceId, status) {
    setAttachmentByStatus(
      {
        updateCallback: (evidences = []) => {
          let evidencesCopy = [...evidences];
          const evidenceIndex = evidencesCopy.findIndex((evidence) => evidence.id === evidenceId);
          const isEvidenceInFrontend = evidenceIndex !== -1;
          const isEvidenceLoading = evidencesCopy[evidenceIndex].hasOwnProperty("loading");

          if (isEvidenceInFrontend && isEvidenceLoading) {
            //la evidencia no fue removida del front
            delete evidencesCopy[evidenceIndex].loading; //removemos icono de loading
          }
          return evidencesCopy;
        },
      },
      status
    );
  }

  function setAllAttachments(assetAttachments) {
    const startEvidences = assetAttachments.filter(({ status }) => status === TaskStatus.SCHEDULED);
    const progressEvidences = assetAttachments.filter(({ status }) => status === TaskStatus.IN_PROGRESS);
    const endEvidences = assetAttachments.filter(({ status }) => status === TaskStatus.FINALIZED);

    setStartAttachments(startEvidences);
    setProgressAttachments(progressEvidences);
    setEndAttachments(endEvidences);
  }

  function setAttachmentByStatus({ attachment, updateCallback }, status) {
    const updateParam = updateCallback ? updateCallback : [attachment, ...assetAttachments];
    switch (status) {
      case TaskStatus.SCHEDULED: {
        return setStartAttachments(updateParam);
      }
      case TaskStatus.IN_PROGRESS: {
        return setProgressAttachments(updateParam);
      }
      case TaskStatus.FINALIZED: {
        return setEndAttachments(updateParam);
      }
      default:
        break;
    }
  }

  function handleOnClose() {
    onClose(taskAsset?.id, { startAttachments, progressAttachments, endAttachments }, true);
    evidenceMethods.cleanUpTaskEvidences([...startAttachments, ...progressAttachments, ...endAttachments]);
    clearStates();
  }

  function clearStates() {
    setStartAttachments([]);
    setProgressAttachments([]);
    setEndAttachments([]);
    setTaskAssetId(null);
    setAttachmentsUploadProgress({});
    setIsDownloadable(false);
  }

  return (
    <Dialog
      fullWidth
      maxWidth={"md"}
      scroll={"paper"}
      aria-labelledby="simple-dialog-title"
      open={isOpen}
      fullScreen={isMobileScreen}
    >
      <DialogTitle>Evidencias de activo {cleanUUID(handleElementId(taskAsset))}</DialogTitle>
      <DialogContent dividers>
        <LoadingPanel show={loading} text="Cargando Evidencias, espere un momento...">
          {hasEvidencesWithErrors && visualizationType === VISUALIZATION_TYPES.ACTIVITY ? (
            <Box p={2}>
              <Alert severity="error">
                Ocurrió un error al cargar {evidencesWithErrorsCount} evidencias. Por favor, reinicie la aplicación o
                vuelva a añadir las fotografías.
              </Alert>
            </Box>
          ) : null}
          <Grid container spacing={2}>
            {visualizationType === VISUALIZATION_TYPES.VISUALIZATION && (
              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end">
                  <AttachmentDownloadButton
                    attachmentType={ATTACHMENT_TYPES.ATTACHMENT_ASSET}
                    id={taskAsset?.id}
                    disabled={!isDownloadable}
                  />
                </Box>
              </Grid>
            )}
            <Grid item xs={12}>
              <p className={classes.sectionTitle}>Inicio</p>
              <Box>
                <SingleLineGridList
                  attachments={startAttachments}
                  visualizationType={visualizationType}
                  phase={TaskStatus.SCHEDULED}
                  onAddCapturedImage={_addEvidenceWithCamera}
                  attachmentsUploadProgress={attachmentsUploadProgress}
                  onDeleteImage={_removeSelectedimage}
                  hasEvidencesWithError={hasScheduledErrors}
                  loadingPosition={loadingPosition}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <p className={classes.sectionTitle}>Progreso</p>
              <Box>
                <SingleLineGridList
                  attachments={progressAttachments}
                  visualizationType={visualizationType}
                  phase={TaskStatus.IN_PROGRESS}
                  onAddCapturedImage={_addEvidenceWithCamera}
                  attachmentsUploadProgress={attachmentsUploadProgress}
                  onDeleteImage={_removeSelectedimage}
                  hasEvidencesWithError={hasInProgressErrors}
                  loadingPosition={loadingPosition}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <p className={classes.sectionTitle}>Fin</p>
              <Box>
                <SingleLineGridList
                  attachments={endAttachments}
                  visualizationType={visualizationType}
                  phase={TaskStatus.FINALIZED}
                  onAddCapturedImage={_addEvidenceWithCamera}
                  attachmentsUploadProgress={attachmentsUploadProgress}
                  onDeleteImage={_removeSelectedimage}
                  hasEvidencesWithError={hasFinalizedErrors}
                  loadingPosition={loadingPosition}
                />
              </Box>
            </Grid>
          </Grid>
        </LoadingPanel>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnClose} disabled={addingNewEvidence || removingEvidence} color="default">
          CERRAR
        </Button>
      </DialogActions>
    </Dialog>
  );
}

MaintenanceEvidenceDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  attachmentPrefix: PropTypes.string,
  taskAsset: PropTypes.object,
  assetAttachments: PropTypes.objectOf(PropTypes.array),
  visualizationType: PropTypes.string,
};

MaintenanceEvidenceDialog.defaultProps = {
  isOpen: false,
  attachmentPrefix: "",
  onClose: () => {},
  taskAsset: null,
  assetAttachments: { begin: [], progress: [], end: [] },
  visualizationType: VISUALIZATION_TYPES.VISUALIZATION,
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: "nowrap",
    transform: "translateZ(0)",
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background: "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
  addImageContainer: {
    width: "150px",
    height: "150px",
    border: "1px solid #C4C4C4",
    display: "flex",
    cursor: "pointer",
    justifyContent: "center",
    alignItems: "center",
  },
  sectionTitle: {
    fontSize: "18px",
    fontWeight: "bold",
    color: "#4B4B4B",
    margin: 0,
  },
}));
