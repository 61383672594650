import React, { useRef, useCallback } from "react";
import PropTypes from "prop-types";

import { Box } from "@material-ui/core";
import useStyles from "./styles";

// import { Logger } from "@aws-amplify/core";
import useCounter from "hooks/useCounter";
import Countdown from "./Countdown";
import Countup from "./Countup";
import { COUNTER_STATUS, COUNTER_MODE } from "../constants";
import { handleCounterColor, formatCount, getCounterStyles } from "../helpers";
// const logger = new Logger("Counter");

const Counter = (props) => {
  const {
    status,
    mode,
    initialCount,
    totalRangeTime,
    // fromStartToNow,
    serializeCount,
    loopTime,
    updateTime,
    startIcon,
    endIcon,
    onResumeTimer,
    onStopTimer,
  } = props;
  const [count, { increaseCount, decreaseCount, stopTimer }] = useCounter(initialCount);
  const statusRef = useRef(null);
  // const updatedStartToNow = fromStartToNow + (count - initialCount);
  const counterColor = handleCounterColor(mode, status, count, totalRangeTime);

  const formatedCount =
    serializeCount && typeof serializeCount === "function"
      ? serializeCount(count, mode)
      : formatCount(count, status, null, mode, totalRangeTime);
  const counterStyles = getCounterStyles(formatedCount, counterColor);
  const classes = useStyles(counterStyles);

  const _onResumeTimer = useCallback(
    async (count) => {
      statusRef.current = status;
      const success = await onResumeTimer(count);
      if (!success) {
        statusRef.current = COUNTER_STATUS.PAUSED;
      }
    },
    [onResumeTimer, status]
  );
  const _onStopTimer = useCallback(
    async (count) => {
      statusRef.current = status;
      stopTimer();
      const success = await onStopTimer(count);
      if (!success) {
        statusRef.current = COUNTER_STATUS.RUNNING;
      }
    },
    [onStopTimer, status, stopTimer]
  );

  return (
    <Box className={classes.container}>
      {startIcon}
      {mode === COUNTER_MODE.COUNTUP && (
        <Countup
          {...props}
          count={count}
          statusRef={statusRef}
          renderCount={formatedCount}
          increaseCount={() => increaseCount(loopTime, updateTime)}
          onStopTimer={() => _onStopTimer(count)}
          onResumeTimer={() => _onResumeTimer(count)}
          className={classes.counter}
        />
      )}
      {mode === COUNTER_MODE.COUNTDOWN && (
        <Countdown
          {...props}
          count={count}
          statusRef={statusRef}
          renderCount={formatedCount}
          decreaseCount={() => decreaseCount(loopTime, updateTime)}
          onStopTimer={() => _onStopTimer(count)}
          onResumeTimer={() => _onResumeTimer(count)}
          className={classes.counter}
        />
      )}
      {endIcon}
    </Box>
  );
};

Counter.propTypes = {
  status: PropTypes.string,
  mode: PropTypes.string,
  initialCount: PropTypes.number,
  totalRangeTime: PropTypes.number,
  fromStartToNow: PropTypes.number,
  loopTime: PropTypes.number,
  updateTime: PropTypes.number,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  serializeCount: PropTypes.func,
  onResumeTimer: PropTypes.func,
  onStopTimer: PropTypes.func,
  onAutoStart: PropTypes.func,
  onTimeExpired: PropTypes.func,
};

Counter.defaultProps = {
  status: COUNTER_STATUS.STOPPED,
  mode: COUNTER_MODE.COUNTUP,
  initialCount: 0,
  totalRangeTime: 0,
  fromStartToNow: 0,
  loopTime: 1000,
  updateTime: 1000,
  onResumeTimer: () => {},
  onStopTimer: () => {},
  onAutoStart: () => {},
  onTimeExpired: () => {},
};

export default Counter;
