import ActionType from "../../action";
import initialStore from "../../initialStore";

function themeReducer(state = initialStore.system.theme, action) {
  switch (action.type) {
    case ActionType.CHANGE_THEME_CONFIG: {
      const {
        backgroundImg = state.backgroundImg,
        primaryColor = state.primaryColor,
        secondaryColor = state.secondaryColor,
        orgLogo = state.orgLogo,
        topImg = state.topImg
      } = action.payload;

      // return {
      //   backgroundImg: "bkg_atiz.jpg",
      //   primaryColor: "#A5313C",
      //   secondaryColor: "#AB6433",
      //   orgLogo: "logo_atiz.png",
      //   topImg
      // };
      return {
        backgroundImg,
        primaryColor,
        secondaryColor,
        orgLogo,
        topImg
      };
    }
    default:
      return state;
  }
}

export default themeReducer;
