import cyan from "@material-ui/core/colors/cyan";
import teal from "@material-ui/core/colors/teal";

const app = {
  name: "INDIKA360 FSM",
  session: {
    uuid: "9f9495ba-dbde-4c79-a643-baedd13bc732",
    // Real user name (John Doe)
    name: null,
    // Cognito username (john@doe.com)
    username: null,
    // Cognito user identifier (078cc515-d071-4b4b-b903-541028d69f18)
    userId: null,
    permissions: {
      activities: {},
    },

    // ID of the current scope
    scope: null,

    // ID of the current module
    module: null,
  },

  // Application theme,
  theme: {
    primaryColor: cyan[800],
    secondaryColor: "#ff0000",
    amplifyPrimaryTint: teal[500],
    amplifyPrimaryShade: teal[700],
    logoSrc: "/img/logo-org.png",
    logoAlt: "Logo Organización"
  },
  
  systemConfigs: {
    drawerLogo: "",
    appBarLogo: "",
    safetyExpirationDate: null,
  },
};

export default app;
