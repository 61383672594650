import React from "react";
import { makeStyles } from "@material-ui/core";

function ArrowsDivider() {
  const classes = useStyles();

  return (
    <div className={classes.containerArrow}>
      <hr className={classes.arrowDivider} />
      <div className={classes.arrow} role="img" aria-label="arrows" />
      <hr className={classes.arrowDivider} />
    </div>
  );
}

export default ArrowsDivider;

const useStyles = makeStyles((theme) => ({
  containerArrow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  arrowDivider: {
    height: "1px",
    "flex-basis": "45%",
    margin: "0",
  },
  arrow: {
    backgroundImage: `url("/img/arrows_bg.png")`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "48px",
    height: "25px",
    display: "block",
  },
}));
