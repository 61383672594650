import React, { useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { COUNTER_STATUS } from "../constants";
import { Logger } from "@aws-amplify/core";
const logger = new Logger("Countup");

const Countup = ({
  status,
  statusRef,
  renderCount,
  onStopTimer,
  onResumeTimer,
  decreaseCount,
  onAutoStart,
  onTimeExpired,
  fromStartToNow,
  className,
}) => {
  const _onTimeExpired = useCallback(onTimeExpired, []);
  const autoStart = useCallback(() => {
    setTimeout(() => {
      logger.debug(`El temporizador se iniciara automaticamente en ${fromStartToNow} ms`);
      onAutoStart();
      decreaseCount({ onTimeExpired: _onTimeExpired });
    }, fromStartToNow);
    return;
  }, [_onTimeExpired, decreaseCount, onAutoStart, fromStartToNow]);

  useEffect(() => {
    const allowAutoStart = status === COUNTER_STATUS.STOPPED && fromStartToNow > 0;
    if (statusRef.current === COUNTER_STATUS.PAUSED && status === COUNTER_STATUS.RUNNING) {
      logger.debug("Reanudando temporizador");
      onResumeTimer();

      return;
    }
    if (statusRef.current === COUNTER_STATUS.RUNNING && status === COUNTER_STATUS.PAUSED) {
      logger.debug("Deteniendo temporizador");
      onStopTimer();

      return;
    }
    if (statusRef.current !== status) {
      statusRef.current = status;
    }
    if (allowAutoStart) {
      autoStart();
      return;
    }

    if (status === COUNTER_STATUS.RUNNING) {
      decreaseCount();
    }
  }, [renderCount, status, decreaseCount, onResumeTimer, onStopTimer, statusRef, autoStart, fromStartToNow]);

  return <Typography className={className}>{renderCount}</Typography>;
};

Countup.propTypes = {
  statusRef: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
  renderCount: PropTypes.string,
  decreaseCount: PropTypes.func.isRequired,
  onStopTimer: PropTypes.func,
  onResumeTimer: PropTypes.func,
  onAutoStart: PropTypes.func,
  onTimeExpired: PropTypes.func,
  fromStartToNow: PropTypes.number,
  className: PropTypes.string,
};
Countup.defaultProps = {
  statusRef: {},
  status: COUNTER_STATUS.PAUSED,
  renderCount: "00:00:00",
  fromStartToNow: 0,
  decreaseCount: () => {},
  onStopTimer: () => {},
  onResumeTimer: () => {},
  onAutoStart: () => {},
  onTimeExpired: () => {},
};
export default Countup;
