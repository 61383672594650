export const FormatUserName = (user, { withEmail = true, withPhone = true } = {}) => {
  const lastnameP = user?.lastnameP + "," || "";
  const phone = user?.phone || "";
  let name = user?.name || "";
  let email = user?.email || "";

  if (user?.name && !user?.lastnameP && withEmail) {
    name = name + ",";
  }

  if (user?.email && user?.phone && withPhone) {
    email = email + ",";
  }

  let result = `${name} ${lastnameP} ${email} ${phone}`;
  if (!withEmail) result = `${name} ${lastnameP} ${phone}`;
  if (!withPhone) result = `${name} ${lastnameP} ${email}`;
  if (!withEmail && !withPhone) result = `${name} ${lastnameP}`;

  return result;
};

export function FormatDirection(address) {
  if (!address || !address.hasOwnProperty("id")) return null;
  return `${address?.street || ""} ${address?.number || ""} ${
    address?.settlement ? "Col. " + address?.settlement : ""
  } ${address?.locality ? "Del. " + address?.locality : ""} ${address?.zipcode ? "C.P " + address?.zipcode : ""} ${
    address?.city ? ", " + address?.city : ""
  }`;
}
