/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/display-name */
import React, { useEffect } from "react";

import { Route, Switch, useLocation, useHistory } from "react-router-dom";
import MainLayout from "../layout/main";
import { ROOT, MY_TICKET_LIST, SDP, TICKET_DETAILS } from "constant/route/support";

import TicketDetailsView from "components/Tickets/TicketDetailsView";
import TicketsListView from "components/Tickets/TicketsListView";
import SdpComponent from "components/SDP/SdpComponent";
import * as ROLES from "constant/roles";

import { SupportRoutesIcons } from "constant/drawerIcons";

const { TicketsIcon, PersonalVideoOutlinedIcon } = SupportRoutesIcons;

const drawerItems = [
  {
    text: "Mis tickets",
    to: MY_TICKET_LIST,
    disabled: false,
    icon: TicketsIcon,
  },
];
const ROUTES = [
  {
    exact: true,
    path: MY_TICKET_LIST,
    component: () => <TicketsListView type={ROLES.SERVICE_USERS} />,
  },
  {
    exact: true,
    path: TICKET_DETAILS,
    component: () => <TicketDetailsView type={ROLES.SERVICE_USERS} />,
  },
];

if (process.env.REACT_APP_WHITE_LABEL_APP === "thinksafe" || process.env.REACT_APP_WHITE_LABEL_APP === "grupo-siayec") {
  drawerItems.push({
    text: "SDP",
    to: SDP,
    disabled: false,
    icon: PersonalVideoOutlinedIcon,
  })

  ROUTES.push({
    exact: true,
    path: SDP,
    component: () => <SdpComponent type={ROLES.SERVICE_USERS} />
  })
}

function SupportRoutes() {

  const location = useLocation();
  const history = useHistory();

  // Redirect to one end route if the current it's a partial one
  useEffect(() => {
    if (location.pathname === ROOT) {
      history.replace(drawerItems[0].to);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <MainLayout drawerItems={drawerItems}>
      <Switch>
        {ROUTES.map((route, index) => (
          <Route {...route} key={`route_${index}`} />
        ))}
      </Switch>
    </MainLayout>
  );
}

export default SupportRoutes;
