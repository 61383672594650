import API, { graphqlOperation } from "@aws-amplify/api";
import * as queries from "graphql-custom/queries";
import * as mutations from "graphql-custom/mutations";
import { Logger } from "@aws-amplify/core";
import { fetchManageSettingsEnabled } from "util/graphql/fetchManageSettingsEnabled";

const logger = new Logger("ManageEngineSettingsCard/helpers/graphql");

export async function fetchUser(userId) {
  return API.graphql(graphqlOperation(queries.getUser, { id: userId })).then((result) => result.data.getUser);
}

// Funciones de IntegrationRequests ********************************************

async function _fetchOpenIntegrationRequest(nextToken) {
  const { items: results, nextToken: nt } = await API.graphql(
    graphqlOperation(queries.integrationRequestByIntegrationID, {
      integrationID: "manage-engine",
      filter: {
        status: {
          eq: "REQUESTED",
        },
      },
      nextToken,
    })
  ).then((result) => result.data.integrationRequestByIntegrationID);

  if (!nt) return results;

  const nextResults = _fetchOpenIntegrationRequest(nt);
  return results.concat(nextResults);
}

function sortByCreatedAt(a, b) {
  const dateA = new Date(a.createdAt).getTime();
  const dateB = new Date(b.createdAt).getTime();

  return dateA - dateB;
}
export async function fetchOpenIntegrationRequest() {
  let results = await _fetchOpenIntegrationRequest();

  if (results.length === 0) return null;

  const [firstRequest] = results.sort(sortByCreatedAt);

  return firstRequest;
}

export async function cancelIntegrationRequest(id, _version) {
  const result = await API.graphql(
    graphqlOperation(mutations.updateIntegrationRequest, {
      input: {
        id,
        _version,
        status: "CANCELED",
      },
    })
  );
  return result.data.updateIntegrationRequest;
}

// Funciones de Settings *******************************************************

export { fetchManageSettingsEnabled };
export async function fetchCurrentIntegrationSettings() {
  const promises = [
    API.graphql(
      graphqlOperation(queries.getSetting, {
        id: "manage_engine_integration",
        key: "client_id_perm",
      })
    ).then((result) => ({
      client_id_perm: result.data.getSetting?.value || "",
      clientIdVersion: result.data.getSetting?._version,
    })),
    API.graphql(
      graphqlOperation(queries.getSetting, {
        id: "manage_engine_integration",
        key: "client_auth_perm",
      })
    ).then((result) => ({
      client_auth_perm: result.data.getSetting?.value || "",
      tokenVersion: result.data.getSetting?._version,
    })),
    API.graphql(
      graphqlOperation(queries.getSetting, {
        id: "manage_engine_integration",
        key: "endpoint_perm",
      })
    ).then((result) => ({
      endpoint_perm: result.data.getSetting?.value || "",
      serviceUrlVersion: result.data.getSetting?._version,
    })),
  ];

  const [client_id_perm, client_auth_perm, endpoint_perm] = await Promise.all(promises);
  return { ...client_id_perm, ...client_auth_perm, ...endpoint_perm };
}

export async function sendIntegrationRequest({
  email,
  firstname,
  middlename = "",
  lastnamep,
  lastnamem = "",
  organization,
  organizationunit,
  comments,
}) {
  const requestPayload = {
    email,
    firstname,
    middlename,
    lastnamep,
    lastnamem,
    organization,
    comments,
  };

  logger.debug("Realizando solicitud a ME...");

  let requestResult = await API.graphql(
    graphqlOperation(mutations.lambdaHandleIntegration, {
      task: { action: "subscribeRequest", source: "String", destination: "String", stage: "String" },
      payload: JSON.stringify({
        ...requestPayload,
      }),
    })
  ).then((result) => result.data.lambdaHandleIntegration);

  if (requestResult.error) {
    logger.error("Fallo en creación de petición de integración de ME");
    logger.error(requestResult.errorMessage);
    throw new Error("Fallo en creación de petición de integración de ME");
  }

  logger.debug("Registrando solicitud en BD...");
  requestResult = await API.graphql(
    graphqlOperation(mutations.createIntegrationRequest, {
      input: {
        integrationID: "manage-engine",
        status: "REQUESTED", // TODO: Reemplazar esto por la constante de DataStore
        ...requestPayload,
      },
    })
  );

  return requestResult.data.createIntegrationRequest;
}

export async function saveIntegrationSettings({
  clientId,
  token,
  serviceUrl,
  clientIdVersion,
  tokenVersion,
  serviceUrlVersion,
}) {
  const promises = [
    API.graphql(
      graphqlOperation(mutations.updateSetting, {
        input: { id: "manage_engine_integration", key: "client_id_perm", value: clientId, _version: clientIdVersion },
      })
    ).then((result) => ({
      client_id_perm: result.data.updateSetting.value,
      clientIdVersion: result.data.updateSetting._version,
    })),
    API.graphql(
      graphqlOperation(mutations.updateSetting, {
        input: { id: "manage_engine_integration", key: "client_auth_perm", value: token, _version: tokenVersion },
      })
    ).then((result) => ({
      client_auth_perm: result.data.updateSetting.value,
      tokenVersion: result.data.updateSetting._version,
    })),
    API.graphql(
      graphqlOperation(mutations.updateSetting, {
        input: {
          id: "manage_engine_integration",
          key: "endpoint_perm",
          value: serviceUrl,
          _version: serviceUrlVersion,
        },
      })
    ).then((result) => ({
      endpoint_perm: result.data.updateSetting.value,
      serviceUrlVersion: result.data.updateSetting._version,
    })),
  ];
  const [client_id_perm, client_auth_perm, endpoint_perm] = await Promise.all(promises);
  return { ...client_id_perm, ...client_auth_perm, ...endpoint_perm };
}

export async function saveIntegrationSettingsEnabled({ integration_enabled, _version }) {
  const result = await API.graphql(
    graphqlOperation(mutations.updateSetting, {
      input: {
        id: "manage_engine_integration",
        key: "integration_enabled",
        value: integration_enabled,
        _version,
      },
    })
  );

  return {
    integration_enabled: result.data.updateSetting?.value,
    _version: result.data.updateSetting._version,
  };
}

// TODO: Implementar funcionalidad para probar una configuración.
export async function testIntegrationSettings({ clientId, token, serviceUrl }) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      // return resolve(null);
      resolve({
        result: "SUCCESS",
        // result: "FAILURE",
      });
    }, 1500);
  });
}
