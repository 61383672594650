import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

function Select({ options, emptyMessage, selectMessage, required, loading, updateForm, ...props }) {
  const [optionsMap] = useState(new Map());
  const [optionsMap2] = useState(new Map());
  useEffect(() => {
    optionsMap.clear();

    options.forEach((o) => {
      optionsMap.set(o.value, o.data);
      optionsMap2.set(o.value, o);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const handleChange = (event) => {
    const { value } = event.target;
    const data = optionsMap.get(value);
    const option = optionsMap2.get(value);

    props.onChange(event, data, option);
    if (updateForm)
      updateForm((prevValues) => {
        return { ...prevValues, [props["name"]]: data };
      });
  };
  const DEFAULT_SELECT_PROPS = {
    // eslint-disable-next-line react/display-name
    IconComponent: () => {
      return loading ? <CircularProgress color="inherit" size={20} /> : <ArrowDropDownIcon color="inherit" />;
    },
  };
  return (
    <TextField
      {...props}
      select
      required={required}
      onChange={handleChange}
      SelectProps={props.SelectProps ? props.SelectProps : DEFAULT_SELECT_PROPS}
    >
      {options.length === 0 && (
        <MenuItem value="" disabled>
          {emptyMessage}
        </MenuItem>
      )}
      {options.length > 0 && (
        <MenuItem value="" disabled={required}>
          {selectMessage}
        </MenuItem>
      )}
      {options.length > 0 &&
        options.map((o, index) => (
          <MenuItem key={`so-${index}`} id={o.label} value={o.value} disabled={o.disabled}>
            {o.label}
          </MenuItem>
        ))}
    </TextField>
  );
}

Select.propTypes = {
  options: PropTypes.array,
  emptyMessage: PropTypes.string,
  selectMessage: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  loading: PropTypes.bool,
  updateForm: PropTypes.func,
  name: PropTypes.string,
};

Select.defaultProps = {
  options: [],
  emptyMessage: "Sin opciones disponibles",
  selectMessage: "Seleccione una opción",
  onChange: () => {},
  required: false,
  loading: false,
};

export default React.memo(Select);
