export const updateBuildingLocation = /* GraphQL */ `
  mutation UpdateBuildingLocation($input: UpdateBuildingLocationInput!) {
    updateBuildingLocation(input:$input) {
      id
      name
      code
      buildingBlock
      floor
      area
      status
      createdAt
      _version
    }
  }
`;