import { useEffect, useRef } from "react";
import PropTypes from "prop-types";

export default function useUpdateEffect(callback, dependencies) {
  const firstRenderRef = useRef(true);

  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
      return;
    }
    return callback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
}

useUpdateEffect.PropTypes = {
  callback: PropTypes.func,
  dependencies: PropTypes.array,
};

useUpdateEffect.defaultProps = {
  callback: () => {},
  dependencies: [],
};
