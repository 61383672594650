import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";

import Carousel, { Modal, ModalGateway } from "react-images";
import useOnlineStatus from "@rehooks/online-status";

const modalStyles = {
  blanket: (base, state) => ({ ...base, zIndex: 1500 }),
  positioner: (base, state) => ({ ...base, zIndex: 1510 }),
  dialog: (base, state) => ({ ...base, zIndex: 1520 }),
};
function ImageCarouselDialog(props) {
  const [index, setIndex] = useState(null);
  const [views, setViews] = useState(props.views);
  const isOnline = useOnlineStatus();

  useEffect(() => {
    if (props.open) {
      const currentIndex = getCurrentIndex();
      if (props.views[currentIndex]?.loading) {
        handleOnClose();
        return;
      }

      const updatedViews = handleViewsSource(props.views);

      setIndex(currentIndex);
      setViews(updatedViews);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  function getCurrentIndex() {
    let currentIndex;
    if (props.currentIndex < 0) {
      currentIndex = 0;
    } else if (props.currentIndex >= props.views.length) {
      currentIndex = props.views.length - 1;
    } else {
      currentIndex = props.currentIndex;
    }
    return currentIndex;
  }

  function handleViewsSource(views = []) {
    return views.map((view) => {
      const hasUrlObjectSource = Boolean(view.urlObjectSource);
      const hasAwsSource = Boolean(view.awsSource);

      //hacemos reset de la propiedad source provenienete del evidence -condicionado a la propiedad urlObjectSource-
      // view.source = "/img/broken_image.png";
      view.alt = "Evidencia de la actividad.";
      if (hasUrlObjectSource) {
        view.source = view.urlObjectSource;
      }

      if (!hasUrlObjectSource && hasAwsSource && isOnline) {
        view.source = view.awsSource;
      }

      if (view.source === "/img/broken_image.png") {
        view.alt = "Error durante la carga de la evidencia.";
      }

      return view;
    });
  }

  function handleOnClose() {
    props.onClose();
    setIndex(null);
    setViews([]);
  }

  return (
    <ModalGateway>
      {props.open && props.views.length > 0 && !props.loading && (
        <Modal onClose={handleOnClose} styles={modalStyles}>
          <Carousel views={views} currentIndex={index} />
        </Modal>
      )}
    </ModalGateway>
  );
}

ImageCarouselDialog.propTypes = {
  open: PropTypes.bool,
  views: PropTypes.arrayOf(PropTypes.shape()),
  onClose: PropTypes.func,
  currentIndex: PropTypes.number,
  loading: PropTypes.bool,
};

ImageCarouselDialog.defaultProps = {
  open: false,
  views: [],
  onClose: () => {},
  currentIndex: 0,
  loading: false,
};

export default ImageCarouselDialog;
