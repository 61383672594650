export const listRoles = /* GraphQL */ `
  query ListRoles {
    listRoles {
      items {
        id
        name
        cognitoGroup
        createdAt
      }
      nextToken
    }
  }
`;