export const listGoodsReceipts = /* GraphQL */ `
  query ListGoodsReceipts(
    $filter: ModelGoodsReceiptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGoodsReceipts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        id
        quantity
        type
        fileName
        createdAt
        user {
          name
          lastnameP
          email
        }
        project {
          id
          name
          code
          company {
            id
            name
          }
        }
        file {
          bucket
          region
          key
        }
      }
    }
  }
`;
