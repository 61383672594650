/**
 * Devuele true o false dependiendo si los valores dentro de dos arrays son iguales o no (sin importar su order)
 * Estos arrays deben contener solo un nivel es decir que sean arrays de strings o números
 * Ejemplo:
 * compareTwoArraysValues(["1", "2"], ["2", "1"]) // true
 * compareTwoArraysValues(["1", "2"], ["1", "3"]) // false
 * compareTwoArraysValues({name:"sasha"}, {name:"sasha"}) // Error
 * @param {Array} array1
 * @param {Aray} array2
 * @returns {Boolean}
 */

export default function compareTwoArraysValues(array1, array2) {
  const array2Sorted = array2.slice().sort();
  return (
    array1.length === array2.length &&
    array1
      .slice()
      .sort()
      .every((value, index) => value === array2Sorted[index])
  );
}
