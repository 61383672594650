import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useAppVersion from "hooks/useAppVersion";
import { Box, Link } from "@material-ui/core";
import SuspenseFallback from "./SuspenseFallback";
const LegalBox = React.lazy(() => import(/* webpackChunkName: "LegalBox" */ "components/LegalBox"));

const LegalBoxDefaultState = {
  showModal: false,
  showFooter: false,
  tabToShow: 0,
};
function AboutInfoDialog(props) {
  const { version } = useAppVersion();
  const [legalBoxState, setLegalBoxState] = React.useState(LegalBoxDefaultState);

  const toggleLegalBoxModal = (showModal, tabToShow = 0) => {
    setLegalBoxState({ ...LegalBoxDefaultState, showModal: showModal, tabToShow });
  };

  return (
    <>
      <Dialog open={props.open} onClose={props.onClose}>
        <DialogTitle id="about-dialog-title">INDIKA360 Field Service Management</DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="about-dialog-description">
            Software para seguimiento de actividades de servicio en campo.
          </DialogContentText>
          <DialogContentText>Versión {version}.</DialogContentText>
          <Box display="flex" flexDirection="column" alignItems="flex-start" gridGap="8px">
            <Link component="button" onClick={() => toggleLegalBoxModal(true, 1)}>
              Terminos y Condiciones
            </Link>
            <Link component="button" onClick={() => toggleLegalBoxModal(true, 0)}>
              Aviso de Privacidad
            </Link>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose} color="default" autoFocus>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      <React.Suspense fallback={<SuspenseFallback />}>
        {legalBoxState.showModal ? (
          <LegalBox {...legalBoxState} setShowModalTerms={() => setLegalBoxState(LegalBoxDefaultState)} />
        ) : null}
      </React.Suspense>
    </>
  );
}

AboutInfoDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

AboutInfoDialog.defaultProps = {
  open: false,
  onClose: () => {},
};

export default AboutInfoDialog;
