import { useSelector, useDispatch } from "react-redux";
import ActionTypes from "../redux/action";

function useGeneralLoaderStatus(callback = () => {}) {
  const showGeneralLoader = useSelector((state) => state.app.showGeneralLoader);

  const dispatcher = useDispatch();

  function setGeneralLoaderStatus(status = false) {
    dispatcher({
      type: ActionTypes.SHOW_GENERAL_LOADER,
      payload: { showGeneralLoader: status },
    });
  }

  return {
    showGeneralLoader,
    setGeneralLoaderStatus,
  };
}

export default useGeneralLoaderStatus;
