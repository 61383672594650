import { filterDeletedItems } from "util/lists";

import { handleElementId, handleGetMaterialId } from "util/text";
export function validateEvidences(currentAssets, throwError = true) {
  const validEvidences = currentAssets.every(({ attachments }) => {
    const filterDeletedEvidences = filterDeletedItems(attachments.items);
    let scheduled = [];
    let inProgress = [];
    let finalized = [];
    filterDeletedEvidences.forEach((evidence) => {
      if (evidence.status === "SCHEDULED" && !scheduled.length) return scheduled.push(evidence.status);
      if (evidence.status === "IN_PROGRESS" && !inProgress.length) return inProgress.push(evidence.status);
      if (evidence.status === "FINALIZED" && !finalized.length) return finalized.push(evidence.status);
    });

    const isValid = Boolean(scheduled.length) && Boolean(inProgress.length) && Boolean(finalized.length);
    return isValid;
  });

  if (!validEvidences && throwError)
    throw new Error(
      "Error: Es necesario que añadas al menos una evidencia en cada una de las etapas del mantenimiento de un activo"
    );

  return validEvidences;
}

export function validateOneAssetEvidences(assetAttachments = []) {
  const filterDeletedEvidences = filterDeletedItems(assetAttachments);

  let scheduled = [];
  let inProgress = [];
  let finalized = [];
  filterDeletedEvidences.forEach((evidence) => {
    if (evidence.status === "SCHEDULED" && !scheduled.length) return scheduled.push(evidence.status);
    if (evidence.status === "IN_PROGRESS" && !inProgress.length) return inProgress.push(evidence.status);
    if (evidence.status === "FINALIZED" && !finalized.length) return finalized.push(evidence.status);
  });

  const areEvidencesValid = Boolean(scheduled.length) && Boolean(inProgress.length) && Boolean(finalized.length);
  const withEvidences = Boolean(scheduled.length) || Boolean(inProgress.length) || Boolean(finalized.length);
  return { areEvidencesValid, withEvidences };
}

export function validateQRScanned(currentAssets, throwError = true) {
  const validTracking = currentAssets.every(({ scanned, asset }) => {
    return Boolean(scanned) && asset.trackingID !== "unassigned";
  });
  if (!validTracking && throwError)
    throw new Error("Error: Es necesario que valides cada uno de los activos mediante el escaneo de su código QR");
  return validTracking;
}

export function downloadBlob(blob, filename) {
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename || "download";
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener("click", clickHandler);
    }, 150);
  };
  a.addEventListener("click", clickHandler, false);
  a.click();
  return a;
}

export function validateExistentAssetsDifferences({
  elementsToCompare = [],
  currentElements = [],
  validateDeleted = true,
  validateAdded = true,
  validateElementsToAdd = true,
  addingElements = false,
  isMaterialsFlow = false,
} = {}) {
  // console.log("===validateExistentAssetsDifferences: validando differencias entre===", {
  //   elementsToCompare,
  //   currentElements,
  //   validateDeleted,
  //   validateAdded,
  //   validateElementsToAdd,
  //   addingElements,
  //   isMaterialsFlow,
  // });
  if (!elementsToCompare?.length && !currentElements?.length) {
    return {
      elementsPreviouslyAdded: [],
      elementsToAdd: [],
      elementsPreviouslyDeleted: [],
      error: "Sin elementos que comparar",
    };
  }
  if (!elementsToCompare?.length && currentElements?.length) {
    return {
      elementsPreviouslyAdded: validateAdded ? currentElements : [],
      elementsToAdd: validateElementsToAdd ? currentElements : [],
      elementsPreviouslyDeleted: [],
      error: false,
    };
  }
  if (elementsToCompare?.length && !currentElements?.length) {
    return {
      elementsPreviouslyAdded: [],
      elementsToAdd: [],
      elementsPreviouslyDeleted: validateDeleted && !addingElements ? elementsToCompare : [],
      error: false,
    };
  }

  let elementsToAdd = [];
  let elementsPreviouslyDeleted = [];
  let elementsPreviouslyAdded = [];

  if (validateDeleted) {
    elementsPreviouslyDeleted = getElementsPreviouslyDeleted(elementsToCompare, currentElements, isMaterialsFlow);
  }
  if (validateAdded) {
    elementsPreviouslyAdded = getElementsPrevioslyAdded(
      elementsToCompare,
      currentElements,
      addingElements,
      isMaterialsFlow
    );
  }
  if (validateElementsToAdd) {
    elementsToAdd = getElementsToAdd(elementsToCompare, currentElements, isMaterialsFlow);
  }

  // console.log("validateExistentAssetsDifferences: resultado de diferencias", {
  //   elementsPreviouslyDeleted,
  //   elementsPreviouslyAdded,
  //   elementsToAdd,
  // });
  return { elementsPreviouslyAdded, elementsToAdd, elementsPreviouslyDeleted };
}

function getElementsPrevioslyAdded(myAssetList = [], currentAssets = [], addingElements = false, isMaterialsFlow) {
  // console.log("getElementsPrevioslyAdded: Entrando a la función");
  let assetsPreviouslyAdded = [];

  currentAssets.forEach((currentAsset) => {
    const currentAssetId = isMaterialsFlow ? handleGetMaterialId(currentAsset) : handleElementId(currentAsset);

    const assetCurrentlyExist = myAssetList.some((assetOfMyList) => {
      const assetOfMyListId = isMaterialsFlow ? handleGetMaterialId(assetOfMyList) : handleElementId(assetOfMyList);

      // console.log("getElementsPrevioslyAdded: ids de activos a comparar", {
      //   currentAssetId,
      //   assetOfMyListId,
      // });
      return currentAssetId === assetOfMyListId;
    });
    console.log("getElementsPrevioslyAdded: resultado de la validación, assetCurrentlyExist?", assetCurrentlyExist);
    // si el elemento en DB existe en los activos a añadir, quiere decir que ese activo fue previamente añadido
    if (assetCurrentlyExist && addingElements) {
      // console.log("getElementsPrevioslyAdded: activo previamente añadido", { asset: currentAsset.asset });
      return assetsPreviouslyAdded.push(currentAsset.asset);
    }
    // si el elemento en DB no existe en mi lista, quiere decir que ese activo fue previamente añadido
    if (!assetCurrentlyExist) {
      if (addingElements) return false;
      // console.log("getElementsPrevioslyAdded: activo previamente añadido", { asset: currentAsset.asset });
      assetsPreviouslyAdded.push(currentAsset.asset);
    }
  });
  // console.log("getElementsPrevioslyAdded: resulado assets previamente añadidos", {
  //   assetsPreviouslyAdded,
  // });
  return assetsPreviouslyAdded;
}

function getElementsPreviouslyDeleted(myAssetList = [], currentAssets = []) {
  // console.log("getElementsPreviouslyDeleted: Entrando a la función");

  let assetsPreviouslyDeleted = [];

  myAssetList.forEach((assetOfMyList) => {
    const assetOfMyListId = handleElementId(assetOfMyList);

    const assetCurrentlyExist = currentAssets.some((currentAsset) => {
      const currentAssetId = handleElementId(currentAsset);

      // console.log("getElementsPreviouslyDeleted: ids de activos a comparar", {
      //   currentAssetId,
      //   assetOfMyListId,
      // });
      return currentAssetId === assetOfMyListId;
    });
    // si el activo en mi lista no se encuentra en la lista de activos en db, quiere decir que fue eliminado
    if (!assetCurrentlyExist) {
      // console.log("getElementsPreviouslyDeleted: activo previamente eliminado", { assetOfMyList });
      const formatAsset = assetOfMyList.hasOwnProperty("asset") ? assetOfMyList.asset : assetOfMyList;
      assetsPreviouslyDeleted.push(formatAsset);
    }
  });
  // console.log("getElementsPrevioslyAdded: resulado assets previamente añadidos", {
  //   assetsPreviouslyDeleted,
  // });
  return assetsPreviouslyDeleted;
}

function getElementsToAdd(assetsToCompare = [], currentAssets = [], isMaterialsFlow) {
  // console.log("===ENTRANDO A getElementsToAdd:", { assetsToCompare, currentAssets });
  let assetsToAdd = [];
  assetsToCompare.forEach((asset) => {
    const assetId = isMaterialsFlow ? handleGetMaterialId(asset) : handleElementId(asset);
    const isPreviouslyAdded = currentAssets.find((currentAsset) => {
      const currentAssetId = isMaterialsFlow ? handleGetMaterialId(currentAsset) : handleElementId(currentAsset);
      // console.log("getElementsToAdd: ids de activos a comparar", {
      //   assetId,
      //   currentAssetId,
      // });

      return assetId === currentAssetId;
    });
    if (!isPreviouslyAdded) {
      const assetFormat = asset?.hasOwnProperty("asset") ? asset.asset : asset;
      // console.log("getElementsToAdd: activo a añadir", assetFormat);
      assetsToAdd.push(assetFormat);
    }
  });
  // console.log("FINALIZANDO getElementsToAdd, resultado:", { assetsToAdd });
  return assetsToAdd;
}
