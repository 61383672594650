export { default as startInstallation } from "./startInstallation";
export { default as endInstallation } from "./endInstallation";
export { default as startAfterCreateInstallation } from "./startAfterCreateInstallation";
export * from "./createMaterialCategory";
export * from "./createIntegrationRequest";
export * from "./updateMaterialCategory";
export * from "./createInspectionNote";
export { default as createTask } from "./createTask";
export * from "./copyAdmAlmacenesToBuildingLocations";
export * from "./createBeforePremise";
export * from "./createPremise";
export * from "./createBuildingLocation";
export { default as createTaskType } from "./createTaskType";
export { default as updateTaskType } from "./updateTaskType";
export * from "./changeUserStatus";
export * from "./assignRoleToUser";
export * from "./addUserToCognitoGroup";
export * from "./removeUserFromCognitoGroup";
export * from "./removeRoleFromUser";
export * from "./changeDomainStatus";
export * from "./createAcceptedDomain";
export * from "./createAssets";
export * from "./createNewAsset";
export * from "./createAssignations";
export * from "./createKitMaterialsRelations";
export * from "./updateInstallationMaterials";
export * from "./updatePremise";
export * from "./updateBuildingLocation";
export * from "./scheduleAssetsSend";
export * from "./createShipmentRelations";
export * from "./disableShipmentAssets";
export * from "./createGoodsReceiptMaterials";
export * from "./createGoodsReceiptAsset";
export * from "./createGoodsReceiptsAttachments";
export * from "./createKit";
export * from "./updateTask";
export * from "./updateSetting";
export * from "./updateIntegrationRequest";
export * from "./lambdaCSVDataLoad";
export * from "./lambdaHandleIntegration";
export * from "./generateDeletedUserTaskRelations";
export * from "./updateKit";
export * from "./updateKitMaterials";
export * from "./generateDeletedUserTaskRelations";
export * from "./updateTaskAsset";
export * from "./createAttachmentAsset";
export * from "./deleteAttachmentAsset";
export * from "./createRemark";
export * from "./updateRemark";
export * from "./generateAssetTypeUpdateMutation";
export * from "./generateDeleteTaskAsset";
export * from "./updateRemark";
export * from "./createUserTask";
export * from "./generateUpdateAssetsMutations";
export * from "./generateTaskAssetMutations";
export * from "./updateTimerEvent";
export * from "./createTimerEvent";
export * from "./updateTicket";
export * from "./updateCompany";
export * from "./createCompany";
export * from "./createProject";
export * from "./updateProject";

export const AddInspectionNoteToTask = /* GraphQL */ `
  mutation AddInspectionNoteToTask(
    $taskId: ID!
    $comments: String!
    $taskVersion: Int
    $inspectionNoteSupervisorId: ID
  ) {
    createInspectionNote: createInspectionNote(
      input: { comments: $comments, taskID: $taskId, inspectionNoteSupervisorId: $inspectionNoteSupervisorId }
    ) {
      id
      taskID
      supervisor {
        id
        name
        lastnameP
        email
        phone
        _version
        _deleted
        _lastChangedAt
      }
      comments
      _version
      _deleted
      _lastChangedAt
    }
    updateTask: updateTask(input: { id: $taskId, _version: $taskVersion, status: WITH_INSPECTION_NOTES }) {
      id
      category
      startDate
      endDate
      startTime
      endTime
      status
      _version
      _deleted
      _lastChangedAt
      taskType {
        id
        name
        description
      }
      supervisor {
        id
        name
        lastnameP
        email
        phone
      }
      buildingLocation {
        id
        name
        code
        buildingBlock
        floor
        area
        premises {
          name
          address {
            street
            number
            zipcode
            city
            settlement
            locality
          }
          custodian {
            name
            middlename
            lastnameP
            lastnameM
            email
            phone
          }
        }
      }
      attachments {
        items {
          file {
            key
          }
        }
      }
      notes {
        items {
          supervisor {
            id
            name
            lastnameP
            email
          }
          comments
          createdAt
        }
      }
      assets {
        items {
          id
          asset {
            id
            serial
            type
            quantity
            code
          }
        }
      }
    }
  }
`;

export const StartInstallations = /* GraphQL */ `
  mutation StartInstallation($taskID: ID!, $startedAt: AWSDateTime!) {
    updateTask(input: { id: $taskID, status: IN_PROGRESS, startedAt: $startedAt }) {
      id
    }
  }
`;

export const createAsset = /* GraphQL */ `
  mutation CreateAsset(
    $assetMaterialId: ID!
    $materialCode: String!
    $serial: String!
    $quantity: Float!
    $comment: String
  ) {
    createAsset(
      input: {
        assetMaterialId: $assetMaterialId
        code: $materialCode
        serial: $serial
        quantity: $quantity
        comment: $comment
        type: USED
      }
    ) {
      id
      code
      serial
      quantity
      type
      material {
        id
        name
        code
        uom {
          name
          abbreviation
        }
      }
    }
  }
`;

export const assignAssetToTask = /* GraphQL */ `
  mutation AssignAssetToTask($taskID: ID!, $assetID: ID!) {
    createTaskAssets(input: { taskAssetsTaskId: $taskID, taskAssetsAssetId: $assetID }) {
      id
    }
  }
`;

export const deleteAsset = /* GraphQL */ `
  mutation DeleteTaskAsset($taskAssetID: ID!, $assetID: ID!) {
    deleteTaskAssets: deleteTaskAssets(input: { id: $taskAssetID }) {
      id
    }
    deleteAsset: deleteAsset(input: { id: $assetID }) {
      id
    }
  }
`;

export const updateTaskAsset = /* GraphQL */ `
  mutation UpdateAsset($assetID: ID!, $serial: String, $quantity: Float, $comment: String) {
    updateAsset(input: { id: $assetID, serial: $serial, quantity: $quantity, comment: $comment }) {
      id
      code
      serial
      quantity
      type
      comment
      material {
        name
        code
        uom {
          name
          abbreviation
        }
      }
    }
  }
`;

export const deleteAttachment = /* GraphQL */ `
  mutation DeleteAttachment($attachmentID: ID!, $version: Int) {
    deleteAttachment(input: { id: $attachmentID, _version: $version }) {
      id
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const createAssetSchedule = /* GraphQL */ `
  mutation CreateAsset($material_id: ID!, $quantity: string) {
    createAsset(
      input: {
        serial: "1234578"
        quantity: $quantity
        type: ALLOCATED
        locationCode: "CODE"
        assetMaterialId: $material_id
      }
    ) {
      id
    }
  }
`;

export const createTaskScheduleAssets = /* GraphQL */ `
  mutation CreateTaskScheduledAssets($taskId: ID!, $assetId: ID!) {
    createTaskAssets(input: { taskAssetsTaskId: $taskId, taskAssetsAssetId: $assetId }) {
      id
    }
  }
`;

export const assignTaskToUser = /* GraphQL */ `
  mutation AssignTaskToUser($userID: ID!, $taskID: ID!) {
    createUserTask(input: { userID: $userID, taskID: $taskID }) {
      id
    }
  }
`;

export const deleteUserTeam = /* GraphQL */ `
  mutation deleteUserTeam($userTeamId: ID!, $_version: Int!) {
    deleteUserTeams(input: { id: $userTeamId, _version: $_version }) {
      id
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const assignUserTeam = (userIds) => {
  const mutationParams = userIds.reduce((params, currentId, index) => {
    return (params += `, $memberId${index}: ID!`);
  }, "");
  const mutationOperations = userIds.reduce((operations, currentId, index) => {
    return (operations += `createUserTeams${index}: createUserTeams(input: {userTeamsUserId:$memberId${index}, userTeamsTeamId: $teamId}) {
      id
      user {
        id
        _version
        _deleted
        _lastChangedAt
      }
      team {
        id
        _version
        _deleted
        _lastChangedAt
      }
      _version
      _deleted
      _lastChangedAt
    },`);
  }, "");

  return `
    mutation assignUserToTeam($teamId: ID!${mutationParams}) {
      ${mutationOperations}
    }
  `;
};

export const createLog = /* GraphQL */ `
  mutation CreateLog($dataType: LogDataType!, $data: String!, $userID: ID!) {
    createLog(input: { dataType: $dataType, dataJSON: $data, userID: $userID }) {
      id
      userID
      dataJSON
      dataType
    }
  }
`;
