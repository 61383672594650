import React from "react";
import PropTypes from "prop-types";
import { MTableAction } from "material-table";

const CustomAction = (props) => {
  let action = props.action;
  const data = props.data;

  if (typeof action === "function") {
    action = action(props.data);
  }

  if (action.action) {
    action = action.action(props.data);
  }

  if (!action) {
    return null;
  }

  const isCustomComponent = Boolean(action?.CustomComponent) && typeof action?.CustomComponent === "function";

  const hasWrapperComponent = Boolean(action?.WrapperComponent) && typeof action?.WrapperComponent === "function";

  if (isCustomComponent) {
    return <action.CustomComponent {...data} />;
  } else if (hasWrapperComponent) {
    return (
      <action.WrapperComponent>
        <MTableAction {...props} />
      </action.WrapperComponent>
    );
  } else {
    return <MTableAction {...props} />;
  }
};

CustomAction.propTypes = {
  action: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
  disabled: PropTypes.bool,
  size: PropTypes.string,
};

CustomAction.defaultProps = {
  action: {},
  data: {},
};

export default CustomAction;
