import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/styles";
import Paper from "@material-ui/core/Paper";
import TicketContents from 'components/general/TicketContents';
import TicketTimerLogDescription from 'components/general/TicketTimerLogDescription';


function TicketTimerLog(props) {
    const { event } = props


    const classes = useStyles();
    const createdAt = event.createdAt;
    const finishAt = event.finishAt;
    const timeShow = event.timeShow;
    const classeContainerTimer = event.type == 'PAUSE' ? classes.containerTimerWarning : classes.containerTimerInfo;
    const classePaper = event.type == 'PAUSE' ? classes.paperWarnig : classes.paperInfo;
    
    const descriptionComponent = (<TicketTimerLogDescription
        variantT="subtitle2"
        componentT="div"
        classeNameContent={classes.textDescription}
        description={event.description} />);

    const TimeInitComponent = (<TicketTimerLogDescription
        variantT="subtitle2"
        componentT="span"
        description={createdAt} />)

    const TimeEndComponent = (<TicketTimerLogDescription
        variantT="subtitle2"
        componentT="span"
        description={finishAt} />)


    const dateComponent = (
        <><TicketTimerLogDescription
            variantT="body2"
            componentT="p"
            description="Hora de inicio"
            renderContent={TimeInitComponent} /><TicketTimerLogDescription
                variantT="body2"
                componentT="p"
                description="Hora de finalización"
                renderContent={TimeEndComponent} /></>)

    const TimeCoponent = (<TicketTimerLogDescription
        variantT="h6"
        componentT="p"
        description={timeShow}/>)

    return (
        <Paper variant="outlined" className={classePaper}>
            <Grid container
                direction="row"
                alignItems="center">
                <TicketContents pSize={1} size={4} renderContent={descriptionComponent} />
                <TicketContents size={6} renderContent={dateComponent} />
                <TicketContents pSize={2} size={2} renderContent={TimeCoponent} classeNameContent={classeContainerTimer} />
            </Grid>
        </Paper>
    );
}

const useStyles = makeStyles(() => ({
    containerTimerInfo: {
        textAlign: "center",
        background: 'rgb(201, 227, 225)',
        color: 'rgb(63, 162, 156)'
    },
    containerTimerWarning: {
        textAlign: "center",
        background: 'rgb(255, 190, 129)',
        color: 'black'
    },
    paperInfo: {
        background: 'rgb(227, 240, 239)',
        borderColor: 'rgb(227, 240, 239)',
        marginBottom: '12px'
    },
    paperWarnig: {
        background: 'rgb(255, 220, 186)',
        borderColor: 'rgb(255, 220, 186)',
        marginBottom: '12px'
    },
    textDescriptionInfo: {
        color: 'rgb(54, 125, 163)'
    },
    textDescriptionWarnig: {
        color: 'black'
    }

}));
export default TicketTimerLog;
