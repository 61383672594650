export const lambdaDownloadTaskDocument = /* GraphQL */ `
  query lambdaDownloadTaskDocument($input: LambdaDownloadTaskDocumentInput) {
    lambdaDownloadTaskDocument(input: $input) {
      error
      errorMessage
      statusCode
      document {
        name
        url
      }
    }
  }
`;
