// Componente para asociar qrs a activos
import React from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import CheckIcon from "@material-ui/icons/Check";
import ListIcon from "@material-ui/icons/List";
import STATUSES from "../constants/status";

function AssetPanel(props) {
  const buttonVariant =
    props.status === STATUSES.EMPTY
      ? "outlined"
      : props.status === STATUSES.NOT_AVAILABLE
      ? "outlined"
      : props.status === STATUSES.SELECTED
      ? "contained"
      : "";
  const buttonIcon =
    props.status === STATUSES.EMPTY || props.status === STATUSES.NOT_AVAILABLE ? (
      <img className={props.classes.buttonImages} src={"/img/assets-green.png"} alt="icon" />
    ) : (
      <CheckIcon />
    );

  const buttonContent =
    props.status === STATUSES.EMPTY
      ? "SELECCIÓN DE ACTIVO"
      : props.status === STATUSES.NOT_AVAILABLE
      ? "SELECCIÓN DE ACTIVO"
      : props.status === STATUSES.SELECTED
      ? "ACTIVO SELECCIONADO"
      : "";

  const alertSeverity =
    props.status === STATUSES.EMPTY
      ? "info"
      : props.status === STATUSES.NOT_AVAILABLE
      ? "warning"
      : props.status === STATUSES.SELECTED
      ? "success"
      : "";

  const alertContent =
    props.status === STATUSES.EMPTY
      ? "Aún no ha ingresado un activo"
      : props.status === STATUSES.NOT_AVAILABLE
      ? "El activo ingresado no está disponible"
      : props.status === STATUSES.SELECTED
      ? `Material: ${props.asset?.code} - ${props.asset?.material?.name} Serie: ${props.asset?.serial}`
      : "";

  return (
    <>
      <Button
        className={props.classes.buttons}
        onClick={props.selectAsset}
        color="primary"
        variant={buttonVariant}
        fullWidth
      >
        {buttonIcon}
        {buttonContent}
      </Button>
      <Box mb={2}>
        <Alert severity={alertSeverity}>{alertContent}</Alert>
      </Box>
      <Button
        onClick={props.attributesBtnClick}
        variant="contained"
        color="primary"
        disabled={!props.asset}
        startIcon={<ListIcon />}
        fullWidth
      >
        Gestión de Atributos
      </Button>
    </>
  );
}

AssetPanel.propTypes = {
  attributesBtnClick: PropTypes.func,
  selectAsset: PropTypes.func,
  status: PropTypes.oneOf(Object.values(STATUSES)),
  asset: PropTypes.shape(),
  classes: PropTypes.shape(),
};

AssetPanel.defaultProps = {};

export default AssetPanel;
