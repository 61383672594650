import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import useHeaderTitle from "hooks/useHeaderTitle";
import PropTypes from "prop-types";

import TicketsListViewTable from "../TicketsListViewTable";
import TabContainer from "components/Tabs/StyledTabContainer";
import Title from "components/common/Title";
import * as ROLES from "constant/roles";
import { styleStatus, getStyleForTab, SECTIONS } from "../../../util/homologationOfColumns";

// CUSTOM STYLES
const useStyles = makeStyles((theme) => styleStatus(SECTIONS.SUPPORT));

// CUSTOM SELECTED STYLES
const useStylesSelected = makeStyles(getStyleForTab(SECTIONS.SUPPORT));

let SECTION_TITLE = "";
const isWeb = window.matchMedia("(min-width: 768px)").matches;
if (!isWeb) {
  SECTION_TITLE = "SOPORTE";
}

// status
// "SCHEDULED",
// "IN_PROGRESS",
// "COMPLETED",
// "REVIEWED",
// "WITH_INSPECTION_NOTES",
// "FINALIZED",

export default function TicketActivityListView({ type }) {
  useHeaderTitle(SECTION_TITLE);

  const stylesTabs = useStyles();
  const stylesSelected = useStylesSelected();

  const _tabData = React.useMemo(() => {
    // RENDER COMPONENT TABS
    const listTableGeneral = <TicketsListViewTable status="GENERAL" />;
    const listTableSheduled = <TicketsListViewTable status="SCHEDULED" />;
    const listTableInProgres = <TicketsListViewTable status="IN_PROGRESS" />;
    const listTableComplete = <TicketsListViewTable status="COMPLETED" />;
    const listTableReviewed = <TicketsListViewTable status="REVIEWED" />;
    const listTableNotes = <TicketsListViewTable status="WITH_INSPECTION_NOTES" />;
    const listTableFinalized = <TicketsListViewTable status="FINALIZED" />;

    // Obj to define tabs
    const tabData = [
      {
        name: "General",
        index: 0,
        component: listTableGeneral,
        styles: "GENERAL",
      },
      {
        name: "Asignados",
        index: 1,
        component: listTableSheduled,
        styles: "SCHEDULED",
      },
      {
        name: "En progreso",
        index: 2,
        component: listTableInProgres,
        styles: "IN_PROGRESS",
      },
      {
        name: "Por Firmar",
        index: 3,
        component: listTableComplete,
        styles: "REVIEWED",
      },
      {
        name: "Con observaciones",
        index: 4,
        component: listTableNotes,
        styles: "WITH_INSPECTION_NOTES",
      },
      {
        name: "Validado",
        index: 5,
        component: listTableReviewed,
        styles: "COMPLETED",
      },
      {
        name: "Finalizado",
        index: 6,
        component: listTableFinalized,
        styles: "FINALIZED",
      },
    ];

    return tabData;
  }, []);

  if (![ROLES.SUPPORT_ENGINEERS, ROLES.SUPERVISORS].includes(type)) {
    _tabData.splice(0, 1);
  }

  return (
    <>
      {isWeb && <Title>SOPORTE</Title>}
      <TabContainer tabData={_tabData} stylesTabs={stylesTabs} stylesSelected={stylesSelected} />
    </>
  );
}

TicketActivityListView.propTypes = {
  type: PropTypes.string,
};
