/**
 * Este reducer maneja el estado para los componentes MyTeamView y NewTeamMember
 */

import {
  SITE_GET_USER_TEAM_PENDING,
  SITE_GET_USER_TEAM_SUCCESS,
  SITE_GET_USER_TEAM_ERROR,
  SITE_GET_TEAM_SUCCESS,
  SITE_FETCH_USERS_SUCCESS,
  SITE_FETCH_USERS_ERROR,
  SITE_DELETE_USER_SUCCESS,
  SITE_DELETE_USER_PENDING,
  SITE_DELETE_USER_ERROR,
  SITE_GET_TEAM_ERROR,
  SITE_ASSIGN_USERS_PENDING,
  SITE_ASSIGN_USERS_SUCCESS,
  SITE_ASSIGN_USERS_ERROR,
  SITE_RESET_STATE,
} from "redux/action";
import { REQUEST_STATUS } from "constant/requestStatus";
import dayjs from "dayjs";
import { filterDeletedOrDisabledItems } from "util/lists";

const INITIAL_STATE = {
  users: [],
  fetchStatus: REQUEST_STATUS.INACTIVE,
  teamId: "",
  team: [],
  teamStatus: REQUEST_STATUS.INACTIVE,
  assignStatus: REQUEST_STATUS.INACTIVE,
  isDeletingUser: REQUEST_STATUS.INACTIVE,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SITE_GET_USER_TEAM_PENDING:
      return { ...state, teamStatus: REQUEST_STATUS.PENDING };
    case SITE_GET_USER_TEAM_SUCCESS:
      const {
        listTeams: {
          items: [firstTeam],
        },
      } = action.payload;
      return { ...state, teamId: firstTeam?.id, teamStatus: REQUEST_STATUS.SUCCESSFUL };
    case SITE_GET_USER_TEAM_ERROR:
      return { ...state, teamStatus: REQUEST_STATUS.FAILED, teamId: "" };
    case SITE_GET_TEAM_SUCCESS:
      const {
        getTeam: {
          users: { items },
        },
      } = action.payload;
      const team = transformTeamData(items);
      return { ...state, team, teamStatus: REQUEST_STATUS.SUCCESSFUL };
    case SITE_GET_TEAM_ERROR:
      return { ...state, teamStatus: REQUEST_STATUS.FAILED };
    case SITE_DELETE_USER_SUCCESS:
      const {
        deleteUserTeams: { id },
      } = action.payload;
      const newTeam = state.team.filter(({ relationId }) => relationId !== id);
      return { ...state, team: newTeam, isDeletingUser: REQUEST_STATUS.SUCCESSFUL };
    case SITE_DELETE_USER_PENDING:
      return { ...state, isDeletingUser: REQUEST_STATUS.PENDING };
    case SITE_DELETE_USER_ERROR:
      return { ...state, isDeletingUser: REQUEST_STATUS.FAILED };
    case SITE_FETCH_USERS_SUCCESS:
      const users = transformUsersData(action.payload.listUsers.items);
      return { ...state, users, fetchStatus: REQUEST_STATUS.SUCCESSFUL };
    case SITE_FETCH_USERS_ERROR:
      return { ...state, users: [], fetchStatus: REQUEST_STATUS.FAILED };
    case SITE_ASSIGN_USERS_PENDING:
      return { ...state, assignStatus: REQUEST_STATUS.PENDING };
    case SITE_ASSIGN_USERS_SUCCESS:
      return { ...state, assignStatus: REQUEST_STATUS.SUCCESSFUL };
    case SITE_ASSIGN_USERS_ERROR:
      return { ...state, assignStatus: REQUEST_STATUS.FAILED };
    case SITE_RESET_STATE:
      return { ...state, assignStatus: INITIAL_STATE.assignStatus };
    default:
      return state;
  }
}

const transformUsersData = (users) => {
  const activeUsers = filterDeletedOrDisabledItems(users);
  return activeUsers.map((user) => {
    const { id, name, lastnameP, lastnameM, email, createdAt, status, _version, _deleted } = user;
    const fullName = `${name} ${lastnameP || ""} ${lastnameM || ""}`;
    const formattedDate = dayjs(createdAt).format("DD/MM/YYYY");
    return { id, fullName, email, createdAt: formattedDate, status, _version, _deleted };
  });
};

const transformTeamData = (team) => {
  const activeMembers = team.filter(({ _deleted }) => !_deleted);
  return activeMembers.map((member) => {
    const { _version, _deleted } = member;
    const { id, name, lastnameP, lastnameM, phone, extension, email, status } = member.user;
    const fullName = `${name} ${lastnameP || ""} ${lastnameM || ""}`;
    return { id, fullName, phone, extension, email, relationId: member.id, status, _version, _deleted };
  });
};
