/* eslint-disable no-unused-expressions */
/* eslint-disable react/display-name */
import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AddIcon from "@material-ui/icons/Add";

import GroupRestrictedView from "components/common/GroupRestrictedView";
import Title from "components/common/Title";
import SpeedDial from "components/custom/SpeedDial";
import TabContainer from "components/Tabs/StyledTabContainer";
import SupportTicketDialog from "components/SupportTicketDialog";
import TicketListTable from "components/Tickets/TicketsListView/TicketListTable";
import Section from "components/Section";
import useHeaderTitle from "hooks/useHeaderTitle";
import * as ROLES from "constant/roles";

const ALLOWED_UPDATE_GROUPS = ["service-users", "supervisors"];

export default function TicketListView(props) {
  const isWeb = useMediaQuery("(min-width: 768px)");
  const sectionTitle = useMemo(() => {
    if (isWeb) return "";
    return props.type === ROLES.SERVICE_USERS ? "Mis Tickets" : "Tickets";
  }, [props.type, isWeb]);
  useHeaderTitle(sectionTitle);

  const [ticketList, setTicketList] = useState([]);
  const [openCreateTicketDialog, setOpenCreateTicketDialog] = useState(false);

  const [speedDialActions] = useState([
    {
      icon: <AddIcon />,
      name: "Agregar Ticket",
      onClick: () => setOpenCreateTicketDialog(true),
    },
  ]);

  const addNewTicket = (newTicket) => {
    setTicketList([newTicket, ...ticketList]);
  };

  const _tabData = React.useMemo(() => {
    // RENDER COMPONENT TABS
    const listTableGeneral = (
      <TicketListTable status="GENERAL" type={props.type} activateStatusColumn={true} newTickets={ticketList} />
    );
    const listTableOpen = <TicketListTable status="OPEN" type={props.type} newTickets={ticketList} />;
    const listTableScheduled = <TicketListTable status="SCHEDULED" type={props.type} />;
    const listTableInProgress = <TicketListTable status="IN_PROGRESS" type={props.type} />;
    const listTableResolved = <TicketListTable status="RESOLVED" type={props.type} />;
    const listTableValidated = <TicketListTable status="VALIDATED" type={props.type} />;
    const listTableRated = <TicketListTable status="RATED" type={props.type} />;
    const listTableCancelled = <TicketListTable status="CANCELLED" type={props.type} />;

    // Obj to define tabs
    const tabData = [
      {
        name: "General",
        index: 0,
        component: listTableGeneral,
        styles: "GENERAL",
      },
      {
        name: "Abiertos",
        index: 1,
        component: listTableOpen,
        styles: "OPEN",
      },
      {
        name: "Asignados",
        index: 2,
        component: listTableScheduled,
        styles: "SCHEDULED",
      },
      {
        name: "En Progreso",
        index: 3,
        component: listTableInProgress,
        styles: "IN_PROGRESS",
      },
      {
        name: "Por Firmar",
        index: 4,
        component: listTableResolved,
        styles: "RESOLVED",
      },
      {
        name: "Finalizado",
        index: 5,
        component: listTableValidated,
        styles: "FINALIZED",
      },
      {
        name: "Calificado",
        index: 6,
        component: listTableRated,
        styles: "FINALIZED",
      },
      {
        name: "Cancelado por cliente",
        index: 7,
        component: listTableCancelled,
        styles: "CANCELED_BY_CUSTOMER",
      },
    ];
    return tabData;
  }, [props.type, ticketList]);

  return (
    <>
      {isWeb && <Title>TICKETS</Title>}
      <Section>
        <TabContainer tabData={_tabData} />
      </Section>

      <GroupRestrictedView group={ALLOWED_UPDATE_GROUPS}>
        <SpeedDial actions={speedDialActions} backdrop tooltipOpen />
      </GroupRestrictedView>
      <SupportTicketDialog
        isOpen={openCreateTicketDialog}
        onClose={() => setOpenCreateTicketDialog(false)}
        onCreate={addNewTicket}
      />
    </>
  );
}

TicketListView.propTypes = {
  type: PropTypes.string,
};
