import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Auth } from "@aws-amplify/auth";
import { Logger } from "@aws-amplify/core";
import { useHistory } from "react-router-dom";
import useTheme from "@material-ui/core/styles/useTheme";
import makeStyles from "@material-ui/styles/makeStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "@material-ui/core/Box";
import MaterialTable from "components/custom/MaterialTable";
import useHeaderTitle from "hooks/useHeaderTitle";
import useLoadingStatus from "hooks/useLoadingStatus";
import { TICKET_ACTIVITY_LIST } from "constant/route/operation";
import { getUserSupportTickets } from "../TicketActivityListView/helpers/datastores";
import { checkLastPage } from "util/lists";
import RefreshIcon from "@material-ui/icons/Refresh";
import PDFReportButton from "components/general/Buttons/PDFReportButton";
import { PDF_REPORT_BUTTON_STYLES } from "components/site/constants";
import DescriptionPanel from "components/common/DescriptionPanel";

import {
  getTableColumns,
  getDataTable,
  SECTIONS,
  MAX_BODY_HEIGHT,
  getPageSize,
  MODULES,
} from "../../../util/homologationOfColumns";

const logger = new Logger("TicketsListViewTable");

export default function TicketsListViewTable(props) {
  const { status } = props;
  useHeaderTitle("Atención de Tickets");
  const classes = useStyles();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const [loading, getUserSupportTicketsWrapper] = useLoadingStatus(getUserSupportTickets);
  const [activityList, setActivityList] = useState([]);
  const [paginationPage, setPaginationPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    fetchActivities();
  }, []);

  useEffect(() => {
    if (paginationPage > 0) {
      fetchActivities();
    }
  }, [paginationPage]);

  async function fetchActivities() {
    const currentUser = await Auth.currentAuthenticatedUser();

    getUserSupportTicketsWrapper(currentUser.username, paginationPage, rowsPerPage, status).then((results) => {
      logger.debug(results);
      let activityListCopy = results.map((tl) => ({ ...tl }));

      if (paginationPage !== 0) {
        activityListCopy = [...activityList, ...activityListCopy];
      }
      setActivityList(activityListCopy);
    });
  }

  function goToActivityDetails(event, row) {
    history.push(`${TICKET_ACTIVITY_LIST}/${row.taskID}`);
  }

  function changeRowsPerPage(pageSize) {
    setRowsPerPage(pageSize);
  }

  async function loadMoreGoodsReceipts(page, pageSize) {
    const hasRemainingItems = checkLastPage(page, activityList.length, pageSize);
    if (hasRemainingItems && rowsPerPage === pageSize) {
      setPaginationPage(paginationPage + 1);
    }
  }

  const TABLE_COLUMNS_DESKTOP = getTableColumns(SECTIONS.SUPPORT, status, activityList, MODULES.FIELDWORK);
  const TABLE_COLUMNS_MOBILE = getTableColumns(SECTIONS.SUPPORT, status, activityList, MODULES.FIELDWORK, "mobile");

  return (
    <>
      <Box className={classes.root}>
        <MaterialTable
          title=""
          options={{
            ...TABLE_OPTIONS,
            filtering: true,
            pageSizeOptions: MAX_BODY_HEIGHT,
            pageSize: getPageSize(status),
          }}
          columns={isMobileScreen ? TABLE_COLUMNS_MOBILE : TABLE_COLUMNS_DESKTOP}
          data={getDataTable(activityList)}
          onRowClick={goToActivityDetails}
          isLoading={loading}
          onChangePage={loadMoreGoodsReceipts}
          onChangeRowsPerPage={changeRowsPerPage}
          detailPanel={DescriptionPanel}
          actions={[
            (rowData) => {
              return {
                // eslint-disable-next-line react/display-name
                CustomComponent: () => (
                  <PDFReportButton
                    buttonType="icon"
                    elementId={rowData.id}
                    reportType="support"
                    by="ticket"
                    elementStatus={rowData.status}
                    style={PDF_REPORT_BUTTON_STYLES}
                  />
                ),
              };
            },
            {
              icon: RefreshIcon,
              tooltip: "Actualizar",
              isFreeAction: true,
              onClick: (event) => fetchActivities(),
            },
          ]}
          localization={{
            header: { actions: isMobileScreen ? "" : "Reporte" },
          }}
        />
      </Box>
    </>
  );
}

TicketsListViewTable.propTypes = {
  status: PropTypes.string,
};

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: "#FFF",
  },
  root: {
    [theme.breakpoints.down("sm")]: {
      "& .MuiTableHead-root": {
        display: "none",
      },
      "& .MuiToolbar-root": {
        flexDirection: "column",
        padding: theme.spacing(2),
      },
      "& div[class*='MTableToolbar-title-']": {
        order: 1,
        textAlign: "left",
        width: "100%",
      },
      "& div[class*='MTableToolbar-searchField-']": {
        width: "100%",
        order: 0,
        marginBottom: theme.spacing(2),
      },
    },
  },
}));

const TABLE_OPTIONS = {
  exportButton: false,
  columnsButton: false,
  paginationType: "stepped",
  filtering: false,
};
