import { Logger } from "@aws-amplify/core";
import useOnlineStatus from "@rehooks/online-status";
import useGraphQL from "hooks/useGraphQL";
import useDataStore from "hooks/useDataStore";
import useNotifier from "hooks/useNotifier";
import * as queries from "graphql-custom/queries";
import * as models from "models";
import { filterDeletedItems, createSearchFilter } from "util/lists";
import { Predicates } from "@aws-amplify/datastore";

const logger = new Logger("MaterialListDialog:useAPIHelpers");

export default function useAPIHelpers() {
  const isOnline = useOnlineStatus();
  const { runGraphQLOperation } = useGraphQL();
  const Material = useDataStore(models.Material);
  const { showError } = useNotifier();

  async function getMaterials({ page = 0, limit = 100, token = null } = {}) {
    const queryResult = {
      list: [],
      token: null,
    };
    try {
      if (isOnline) {
        let {
          listMaterials: { items, nextToken },
        } = await runGraphQLOperation({
          operation: queries.listMaterials,
          variables: { limit, nextToken: token },
        });
        items = filterDeletedItems(items);
        queryResult.list = items;
        queryResult.token = nextToken;
      } else {
        const materials = await Material.get({
          criteria: Predicates.ALL,
          paginationProducer: { page, limit },
        });
        queryResult.list = materials;
      }
      return queryResult;
    } catch (error) {
      logger.error(error);
      showError("Ocurrio un error al consultar los materiales del cátalogo.");
      return queryResult;
    }
  }

  async function findMaterials({ limit = 100, token = null, term = "" } = {}) {
    const queryResult = {
      list: [],
      token: null,
    };
    try {
      const filter = createSearchFilter(["name", "code", "description"], term);
      const {
        searchMaterials: { items, nextToken },
      } = await runGraphQLOperation({
        operation: queries.searchMaterials,
        variables: { filter, limit, nextToken: token },
      });
      queryResult.list = items;
      queryResult.token = nextToken;
      return queryResult;
    } catch (error) {
      logger.error(error);
      showError("Ocurrio un error durante la búsqueda de materiales.");
      return queryResult;
    }
  }

  return {
    getMaterials,
    findMaterials,
  };
}
