export const changeDomainStatus = /* GraphQL */ `
  mutation ChangeAcceptedDomainStatus($id: ID!, $status: AcceptedDomainStatus!, $_version: Int!) {
    updateAcceptedDomain(input: {
      id: $id,
      status: $status
      _version: $_version
    }) {
      id
      _version
      updatedAt
    }
  }
`;