import { useEffect } from "react";
import { useDispatch } from "react-redux";
import ActionTypes from "../redux/action";

function useHeaderTitle(title: string) {
  const dispatcher = useDispatch();

  useEffect(() => {
    dispatcher({ type: ActionTypes.CHANGE_APP_TITLE, title });
  }, [dispatcher, title]);
}

export default useHeaderTitle;
