import React from "react";
import PropTypes from "prop-types";
import DetailViewButtonAction from "components/validation/MaintenanceDetailView/DetailViewButtonAction";
import { makeStyles } from "@material-ui/core";

const BottomButtom = ({ width, height, networkTooltipProps, buttonProps, ...props }) => {
  const classes = useStyles({ width, height });

  return (
    <DetailViewButtonAction
      className={classes.container}
      buttonProps={{
        ...buttonProps,
        className: buttonProps.className ? `${classes.button} ${buttonProps.className}` : classes.button,
      }}
      {...props}
    />
  );
};

export default BottomButtom;

BottomButtom.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  networkTooltipProps: PropTypes.object,
  buttonProps: PropTypes.object,
};

BottomButtom.defaultProps = {
  width: "100vw",
  height: "48px",
  networkTooltipProps: {},
  buttonProps: {},
};

const useStyles = makeStyles((theme) => ({
  container: ({ width }) => ({
    width,
    position: "fixed",
    bottom: 0,
    left: 0,
    "border-radius": 0,
    "z-index": 10,
  }),
  button: ({ height }) => ({
    width: "inherit",
    height,
    borderRadius: 0,
    border: "none",
  }),
}));
