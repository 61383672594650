export const createBeforePremise = /* GraphQL */ `
  mutation CreateBeforePremise(
    # Address data
    $street: String!
    $number: String!
    $zipcode: String
    $city: String
    $settlement: String
    $locality: String
    # Custodian data
    $custodianName: String!
    $middlename: String
    $lastnameP: String!
    $lastnameM: String
    $email: AWSEmail
    $phone: String
    $extension: String
  ) {
    createAddress(
      input: {
        street: $street
        number: $number
        zipcode: $zipcode
        city: $city
        settlement: $settlement
        locality: $locality
      }
    ) {
      id
    }
    createCustodian(
      input: {
        name: $custodianName
        middlename: $middlename
        lastnameP: $lastnameP
        lastnameM: $lastnameM
        email: $email
        phone: $phone
        extension: $extension
      }
    ) {
      id
    }
  }
`;

export default createBeforePremise;
