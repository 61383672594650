import React from "react";
import PropTypes from "prop-types";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import CustomButton from "components/custom/Button";

import { SupportType } from "models";

import { SUPPORT_TYPE_TRANSLATION } from "constant/ticketActivity";

const dataModal = {
  title: "Confirmación.",
  content: "Estas a punto de comenzar tu servicio en modo",
  question: "¿Seguro que deseas continuar?",
  btnClose: "Cerrar",
  btnAcept: "Continuar",
};

const TicketActivityConfirmDailog = ({ open, onClose, fullWidth, maxWidth, onSuccess, supportType, isLoading }) => {
  const isXs = useMediaQuery("(max-width:765px)");

  const handleOnSuccess = async () => {
    await onSuccess();
    onClose();
  };

  return (
    <>
      <Dialog open={open} fullScreen={isXs} fullWidth={fullWidth} maxWidth={maxWidth}>
        <DialogTitle> {dataModal.title} </DialogTitle>
        <DialogContent dividers>
          {dataModal.content} <b> {SUPPORT_TYPE_TRANSLATION[supportType]} </b>
          <br /> <br />
          {dataModal.question}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="default">
            {dataModal.btnClose}
          </Button>

          <CustomButton onClick={handleOnSuccess} loading={isLoading} color="primary" form="timer-form-dialog">
            {dataModal.btnAcept}
          </CustomButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

TicketActivityConfirmDailog.propTypes = {
  open: PropTypes.bool.isRequired,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  fullWidth: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  supportType: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
};

TicketActivityConfirmDailog.defaultProps = {
  open: false,
  maxWidth: false,
  fullWidth: false,
  onClose: () => {},
  onSuccess: async () => {},
  supportType: SUPPORT_TYPE_TRANSLATION[SupportType.UNDEFINED],
  isLoading: false,
};

export default TicketActivityConfirmDailog;
