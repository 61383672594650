/* eslint-disable react/display-name */
// Modal para programar nuevas instalaciones
import React, { useState, useEffect, useReducer, useMemo } from "react";
import PropTypes from "prop-types";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/AddBox";

import MaterialTable from "components/custom/MaterialTable";
import Button from "components/custom/Button";
import ConfirmationDialog from "components/ConfirmationDialog";
import { FORM_MODES } from "constant/formModes";
import * as ActionTypes from "redux/action";
import materialsReducer from "redux/reducer/hook/installationMaterialsReducer";

import { useForm } from "react-hook-form";
import useNotifier from "hooks/useNotifier";
import useLoadingStatus from "hooks/useLoadingStatus";
import * as graphqlHelpers from "./helpers/graphql";
import MaterialListDialog from "components/Materials/MaterialListDialog";

export default function MaterialKitDialog(props) {
  const { reset, register, handleSubmit, errors, setValue } = useForm();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const notifier = useNotifier();
  const [isCreatingKit, _createKit] = useLoadingStatus(graphqlHelpers.createKit);
  const [isUpdatingKit, _updateKit] = useLoadingStatus(graphqlHelpers.updateKit);
  const [showMaterialSelectorDialog, setShowMaterialSelectorDialog] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [kitMaterials, dispatch] = useReducer(materialsReducer, {
    all: [],
    added: [],
    updated: [],
    deleted: [],
  });

  const kitMaterialIds = useMemo(() => {
    return kitMaterials.all.map(({ materialId }) => materialId);
  }, [kitMaterials.all]);

  useEffect(() => {
    if (props.open && props.formMode === FORM_MODES.UPDATE) {
      setValue("kitName", props.kit?.name);
      setValue("kitDescription", props.kit?.description);
      dispatch({
        type: ActionTypes.SET_KIT_MATERIALS,
        payload: props.kit?.materials?.items,
      });
    }
  }, [props.open, props.formMode, props.kit, setValue]);

  async function handleKitCreationSubmit(values) {
    if (!kitMaterials.all.length) {
      return notifier.showMessage("Debes seleccionar al menos un material.");
    }
    try {
      if (props.formMode === FORM_MODES.CREATE) {
        const { createKit: kitData } = await _createKit({ ...values, materials: kitMaterials.all });
        props.onCreate(kitData);
      } else if (props.formMode === FORM_MODES.UPDATE) {
        const updatedKit = await _updateKit({ 
          id: props.kit?.id,
          _version: props.kit?._version,
          name: values.kitName,
          description: values.kitDescription,
          materials: kitMaterials,
        });
        props.onClose();
        props.onUpdate(updatedKit);
      }
      setShowConfirmation(false);
      notifier.showMessage(DIALOG_TEXT[props.formMode].successMsg);
    } catch (error) {
      console.log(error);
      notifier.showError(DIALOG_TEXT[props.formMode].errorMsg);
    }
  }

  function handleExit() {
    reset();
    dispatch({ type: ActionTypes.RESET_MATERIALS });
  }

  function addSelectedMaterials(newMaterials) {
    dispatch({ type: ActionTypes.SELECT_MATERIALS_ITEMS, payload: newMaterials });
  }

  async function updateMaterial(newValue, oldValue, rowData, column) {
    const action = {
      type: ActionTypes.UPDATE_INSTALLATION_MATERIAL,
      payload: { ...rowData },
    };
    if (typeof newValue === "number") {
      action.payload.updatedProp = "quantity";
      action.payload.newValue = newValue;
    } else if (typeof newValue === "string") {
      action.payload.updatedProp = "uom";
      action.payload.newValue = { id: newValue, name: column.lookup[newValue] };
    }
    dispatch(action);
  }

  async function deleteMaterial(oldData) {
    dispatch({
      type: ActionTypes.DELETE_INSTALLATION_MATERIAL,
      payload: oldData,
    });
  }

  return (
    <>
    <Dialog
      onExited={handleExit}
      open={props.open}
      fullScreen={fullScreen}
      maxWidth="md"
    >
      <DialogTitle>
        {DIALOG_TEXT[props.formMode].title}
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            Ingrese los materiales y las cantidades que conformarán el kit de
            instalación
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl
                  error={errors?.kitName ? true : false}
                  fullWidth
                >
                  <TextField
                    label="Nombre del Kit"
                    inputRef={register({
                      required: "Este campo es obligatorio",
                    })}
                    name="kitName"
                    required
                    error={errors?.kitName ? true : false}
                    fullWidth
                  />
                  <FormHelperText>
                    {errors?.kitName?.message || ""}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  error={errors?.kitDescription ? true : false}
                  fullWidth
                >
                  <TextField
                    inputRef={register({
                      required: "Este campo es obligatorio",
                    })}
                    label="Descripción"
                    name="kitDescription"
                    required
                    error={errors?.kitDescription ? true : false}
                    fullWidth
                  />
                  <FormHelperText>
                    {errors?.kitDescription?.message || ""}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12}>
            <MaterialTable
              options={{
                exportButton: false,
                columnsButton: false,
              }}
              editable={{ onRowDelete: deleteMaterial }}
              cellEditable={{ onCellEditApproved: updateMaterial }}
              localization={{ body: { deleteTooltip: "Eliminar" } }}
              columns={props.tableColumns}
              data={kitMaterials.all}
              actions={[
                {
                  icon: () => <AddIcon color="action" />,
                  tooltip: "Agregar Materiales",
                  position: "toolbar",
                  onClick: () => setShowMaterialSelectorDialog(true),
                },
              ]}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="default" onClick={props.onClose} disabled={isCreatingKit}>
          Cancelar
        </Button>
        <Button
          color="primary"
          onClick={() => setShowConfirmation(true)}
        >
          {DIALOG_TEXT[props.formMode].confirmBtn}
        </Button>
      </DialogActions>
    </Dialog>

    <MaterialListDialog 
      open={showMaterialSelectorDialog}
      onClose={() => setShowMaterialSelectorDialog(false)}
      onCompleteSelection={addSelectedMaterials}
      excludedMaterialIds={kitMaterialIds}
    />

    <ConfirmationDialog
      open={showConfirmation}
      title="Confirmación"
      onConfirm={handleSubmit(handleKitCreationSubmit)}
      onCancel={() => setShowConfirmation(false)}
      loading={isCreatingKit || isUpdatingKit}
    >
      <Typography paragraph>
        {DIALOG_TEXT[props.formMode].confirmationMsg}
      </Typography>
      <Typography>¿Deseas continuar?</Typography>
    </ConfirmationDialog>
    </>
  );
}

const DIALOG_TEXT = {
  [FORM_MODES.CREATE]: {
    title: "Crear Kit",
    confirmBtn: "Crear",
    confirmationMsg: "Se creará un nuevo Kit en el sistema.",
    successMsg: "Nuevo Kit registrado con éxito",
    errorMsg: "Ocurrió un error registrando el kit, intente de nuevo por favor.",
  },
  [FORM_MODES.UPDATE]: {
    title: "Actualizar Kit",
    confirmBtn: "Actualizar",
    confirmationMsg: "Se actualizará el Kit en el sistema.",
    successMsg: "El Kit se actualizó de forma exitosa",
    errorMsg: "Ocurrió un error durante la actualización del Kit, intente de nuevo por favor.",
  },
};

MaterialKitDialog.propTypes = {
  open: PropTypes.bool,
  formMode: PropTypes.oneOf(Object.values(FORM_MODES)),
  kit: PropTypes.object,
  materialList: PropTypes.arrayOf(PropTypes.object),
  uomList: PropTypes.arrayOf(PropTypes.object),
  tableColumns: PropTypes.arrayOf(PropTypes.object),
  onClose: PropTypes.func,
  onCreate: PropTypes.func,
  onUpdate: PropTypes.func,
};

MaterialKitDialog.defaultProps = {
  open: false,
  formMode: FORM_MODES.CREATE,
  kit: null,
  materialList: [],
  uomList: [],
  tableColumns: [],
  onClose: () => {},
  onCreate: () => {},
  onUpdate: () => {},
};
