export const createKit = /* GraphQL */ `
  mutation CreateKit($name: String!, $description: String) {
    createKit: createKit(input: { name: $name, description: $description }) {
      id
      name
      description
      _version
      _deleted
    }
  }
`;

export default createKit;
