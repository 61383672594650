import * as queries from "graphql-custom/queries";
import * as mutations from "graphql-custom/mutations";
import * as ActionTypes from "redux/action";

export const getActivityTypes = (nextToken) => {
  const SUCCESS_TYPE = nextToken ? ActionTypes.ACTIVITY_FETCH_NEXT_SUCCESS : ActionTypes.ACTIVITY_FETCH_ALL_SUCCESS;
  return {
    filter: "graphql",
    operation: queries.getTaskTypes,
    operationParams: { nextToken },
    types: [ActionTypes.ACTIVITY_FETCH_ALL_PENDING, SUCCESS_TYPE, ActionTypes.ACTIVITY_FETCH_ALL_ERROR],
  };
};

export const updateActivityById = (id, { name, description, category, _version }) => ({
  filter: "graphql",
  operation: mutations.updateTaskType,
  operationParams: { id, name, description, category, _version },
  types: [
    ActionTypes.ACTIVITY_UPDATE_ITEM_PENDING,
    ActionTypes.ACTIVITY_UPDATE_ITEM_SUCCESS,
    ActionTypes.ACTIVITY_UPDATE_ITEM_ERROR,
  ],
});

export const createActivityType = ({ name, description, category }) => ({
  filter: "graphql",
  operation: mutations.createTaskType,
  operationParams: { input: { name, description, category } },
  types: [ActionTypes.ACTIVITY_CREATE_PENDING, ActionTypes.ACTIVITY_CREATE_SUCCESS, ActionTypes.ACTIVITY_CREATE_ERROR],
});

export const resetStatus = () => ({
  type: ActionTypes.ACTIVITY_RESET_STATUS,
});
