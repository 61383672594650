import ActionType from "redux//action";
import initialStore from "redux/initialStore";

function themeReducer(state = initialStore.theme, action) {
  switch (action.type) {
    case ActionType.CHANGE_THEME_CONFIG: {
      return {
        ...state,
        ...action.payload
      };
    }
    default:
      return state;
  }
}

export default themeReducer;
