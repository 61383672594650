export const ENABLEMENT_MODE = {
  ENABLED: "ENABLED",
  DISABLED: "DISABLED",
};

export const ENABLEMENT_TEXT = {
  [ENABLEMENT_MODE.ENABLED]: "Habilitar",
  [ENABLEMENT_MODE.DISABLED]: "Deshabilitar",
};

export const COMPANY_FORM_MODE = {
  CREATE: "CREATE",
  UPDATE: "UPDATE",
};

export const dialogTransitionDuration = 100;

export const enablementDialogDefaultState = {
  isOpen: false,
  mode: ENABLEMENT_MODE.ENABLED,
};

export const defaultFloatingButtonProps = {
  bottom: 15,
  right: 15,
  color: "primary",
  "aria-label": "Crear Cliente",
  variant: "extended",
};

export const rowStyleCompanyDisabled = { backgroundColor: "rgba(0,0,0,0.1)", opacity: 0.8 };

export const floatingButtonDisabledStyles = { backgroundColor: "rgb(0,0,0, 0.5)", color: "rgb(255,255,255)" };

export const networkTooltipStyles = {
  position: "absolute",
  bottom: 100,
  right: 15,
  width: "154px",
};

export const networkTooltipProps = {
  onlineTitle: "Crear un nuevo cliente.",
  offlineTitle: "Sin conexión a internet para crear un nuevo cliente.",
  style: networkTooltipStyles,
};

export const projectNetworkTooltipProps = {
  onlineTitle: "Crear un nuevo projecto.",
  offlineTitle: "Sin conexión a internet para crear un nuevo projecto.",
  style: networkTooltipStyles,
};

export const CREATE_PROJECT_DEFAULT_FORM_VALUES = {
  name: null,
  description: null,
  code: null,
  contractNumber: null,
  startAt: null,
  endAt: null,
};

export const DEFAULT_DATE_PICKER_PROPS = {
  clearable: true,
  clearLabel: "Limpiar",
  cancelLabel: "Cancelar",
  okLabel: "Aceptar",
  inputVariant: "outlined",
  format: "MM/DD/YYYY",
  placeholder: "MM/DD/YYYY",
  fullWidth: true,
  margin: "normal",
  InputLabelProps: {
    shrink: true,
  },
};

export const DEFAULT_TIME = "08:00";
