export const updateUOM = /* GraphQL */ `
  mutation UpdateUOM($id: ID!, $name: String!, $abbreviation: String!, $description: String!, $_version: Int!) {
    updateUnitOfMeassure(input: { 
      id: $id, 
      name: $name, 
      abbreviation: $abbreviation, 
      description: $description, 
      _version: $_version 
    }) {
      id
    }
  }
`;
