import { Logger } from "@aws-amplify/core";
import API, { graphqlOperation } from "@aws-amplify/api";
import * as queries from "graphql-custom/queries";
import * as mutations from "graphql-custom/mutations";
import * as defaultMutations from "graphql/mutations";
import { BuildingLocationStatus } from "models";
import { AssetType } from "models";

const logger = new Logger("DeliveryScheduleDialog:helpers:graphql");

async function customGraphqlClient(operation, params) {
  try {
    const result = await API.graphql(graphqlOperation(operation, params));
    return result;
  } catch (error) {
    logger.error(error);
    return [];
  }
}

export async function findPremises() {
  return customGraphqlClient(queries.listPremisesOptions).then((result) => result.data.listPremisess.items);
}

export async function findBuildingLocationsByPremise(premiseId) {
  const filter = {
    premisesID: { eq: premiseId },
    and: [{ status: { ne: BuildingLocationStatus.DISABLED } }],
  };
  return customGraphqlClient(queries.listBuildingLocationOptionsByPremises, { filter }).then(
    (result) => result.data.listBuildingLocations.items
  );
}

export async function findUsersByRole() {
  const filter = {
    cognitoGroup: {
      eq: "supervisors",
    },
  };
  return customGraphqlClient(queries.getRoleByCognitoGroup, { filter }).then((result) => {
    let { data } = result;
    data = data.listRoles.items[0]?.users?.items || [];
    return data.map(({ user, _deleted }) => ({
      ...user,
      // Se agrega la bandera de eliminado de la relación. En caso de que alguna sea true el item será excluido
      _deleted: user._deleted || _deleted,
    }));
  });
}

export async function findAssets() {
  const type = "RECEIVED";
  const filter = {
    enabled: { ne: false },
  };
  return customGraphqlClient(queries.listAssetsByType, { type, filter, limit: 2000 }).then(
    (result) => result.data.assetsByType.items
  );
}

export function createShipment(deliveryData, assets, projecId) {
  const { mutation, params } = mutations.scheduleAssetsSend(deliveryData, assets, projecId);
  return API.graphql(graphqlOperation(mutation, params));
}

export function createShipmentRelations(task, comments, newAssets, recipient) {
  const { mutation, params } = mutations.createShipmentRelations(task, comments, newAssets, recipient);
  return API.graphql(graphqlOperation(mutation, params));
}

export function updateShipment(deliveryData, projectId) {
  const input = {
    id: deliveryData.id,
    _version: deliveryData._version,
    startDate: deliveryData.date,
    startTime: deliveryData.time,
    taskPremisesId: deliveryData.premise,
    taskProjectId: projectId,
  };
  return API.graphql(graphqlOperation(mutations.updateTask, { input }));
}

export function patchShipmentRecipient(selectedRecipient, oldRecipient, taskId) {
  if (selectedRecipient.id === oldRecipient.user.id) {
    return [];
  }
  const deleteInput = { id: oldRecipient.id, _version: oldRecipient._version };
  const createInput = { userID: selectedRecipient.id, taskID: taskId };
  return Promise.all([
    API.graphql(graphqlOperation(defaultMutations.deleteUserTask, { input: deleteInput })),
    API.graphql(graphqlOperation(mutations.createUserTask, { input: createInput })),
  ]);
}

export function patchRemark(remark) {
  const input = { 
    id: remark.id,
    _version: remark._version,
    remark: remark.remark
  };
  return API.graphql(graphqlOperation(mutations.updateRemark, { input }));
}

export function updateShipmentAssets(originalAssets, selectedAssets, taskId) {
  let newAssets = [];
  let deletedAssets = [];

  selectedAssets.forEach(selected => {
    const originalAsset = originalAssets.find(oa => oa.id === selected.id);
    if (!originalAsset) {
      newAssets.push({ 
        id: selected.id, 
        _version: selected._version,
        type: AssetType.SHIPMENT_SCHEDULED,
        enabled: false,
      });
    }
  });

  originalAssets.forEach(original => {
    const selectedAsset = selectedAssets.find(sa => sa.id === original.id);
    if (!selectedAsset) {
      deletedAssets.push({
        id: original.id,
        _version: original._version,
        type: AssetType.RECEIVED,
        enabled: true,
        taskAsset: original.taskAsset,
      });
    } 
  });

  if (!newAssets.length && !deletedAssets.length) {
    return;
  }

  const { 
    mutation: upaMuatation, 
    variables: upaVariables
  } = mutations.generateUpdateAssetsMutations(newAssets.concat(deletedAssets));
  const { 
    mutation: taMutation,
    variables: taVariables,
  } = mutations.generateTaskAssetMutations(newAssets, deletedAssets, taskId);
  return Promise.all([
    API.graphql(graphqlOperation(upaMuatation, upaVariables)),
    API.graphql(graphqlOperation(taMutation, taVariables)),
  ]);
}
