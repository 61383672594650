import { Camera, CameraResultType, CameraDirection, CameraSource } from "@capacitor/camera";
import { Logger } from "@aws-amplify/core";
import { captureException } from "setup/sentry";
import { Filesystem } from "@capacitor/filesystem";
import { Device } from "@capacitor/device";
import { APP_PLATFORM } from "constant/appPlatform";

const logger = new Logger("util/camera");
const PERMISSION_DENIED_MESSAGES = {
  "Permission Denied": "Permission Denied",
  "User denied access to camera": "User denied access to camera",
};

export class CameraPermissionDeniedException extends Error {}
export class GalleryAccessPermissionDeniedException extends Error {}
export class CameraCanceledException extends Error {}
export class GaleryCanceledException extends Error {}
export class UnknownCameraException extends Error {}

export async function takePicture(getPhotoProps) {
  let images;
  const { source } = getPhotoProps;

  try {
    const info = await Device.getInfo();
    if (source === CameraSource.Camera || info.platform === APP_PLATFORM.WEB) {
      images = await Camera.getPhoto({
        quality: 100,
        preserveAspectRatio: true,
        allowEditing: false,
        direction: CameraDirection.Rear,
        promptLabelHeader: "Evidencias",
        promptLabelPicture: "Tomar foto",
        promptLabelPhoto: "Añadir desde la galeria",
        promptLabelCancel: "Cancelar",
        resultType: CameraResultType.DataUrl,
        source,
        ...getPhotoProps,
      });
      images = [images];
    } else if (source === CameraSource.Photos) {
      images = await Camera.pickImages({
        quality: 100,
      });
      images = await Promise.all(
        images.photos.map(async ({ path, ...image }) => {
          const { data } = await Filesystem.readFile({ path });
          let [filename] = path.split("/").slice(-1);
          return { ...image, data, filename };
        })
      );
    }
  } catch (error) {
    logger.error("takePicture: ", error);
    const errorMsg = error?.message || error;
    if (errorMsg === "User cancelled photos app") {
      throw new CameraCanceledException();
    } else if (errorMsg === "No images picked") {
      throw new GaleryCanceledException();
    }
    captureException(errorMsg, "takePicture");
    if (PERMISSION_DENIED_MESSAGES.hasOwnProperty(errorMsg)) {
      throw new CameraPermissionDeniedException();
    } else if (errorMsg === "User denied access to photos") {
      throw new GalleryAccessPermissionDeniedException();
    } else {
      throw new UnknownCameraException();
    }
  }
  return images;
}

export default {
  takePicture,
  CameraPermissionDeniedException,
  CameraCanceledException,
  GaleryCanceledException,
  UnknownCameraException,
  GalleryAccessPermissionDeniedException,
};
