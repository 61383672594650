import { Logger } from "aws-amplify";
const logger = new Logger("util/localStorage");

export function addTaskIdToLocalStorageKey(LSKey, taskId) {
  if (!LSKey || !taskId) {
    const ERROR_MSG = "Error: error obteniendo key de localStorage de activo en campo";
    logger.error(ERROR_MSG, { LSKey, taskId });
    throw new Error(ERROR_MSG);
  }

  return `${LSKey}-${taskId}`;
}

export const clearLocalStorage = (key) => window.localStorage.removeItem(key);
