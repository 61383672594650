import React, { useState, useEffect } from "react";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import CloseIcon from "@material-ui/icons/Close";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Modal from "@material-ui/core/Modal";
import TabPanel from "components/Tabs/TabPanel";
import MenuIcon from "@material-ui/icons/Menu";
import ListDrawer from "components/LegalBox/ListDrawer";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import NoticeOfPrivacy from "./NoticeOfPrivacy";
import TermsAndConditions from "./TermsAndConditions";
import { CircularProgress, Backdrop } from "@material-ui/core";
import useLoadingStatus from "hooks/useLoadingStatus";
import Slide from "@material-ui/core/Slide";

const drawerWidth = 300;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    background: "#ffffff",
    marginBottom: "-17px",
    color: "rgb(196, 196, 196)",
    borderBottom: "1px solid #e0e0e0",
  },
  menuButton: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
    color: theme.palette.grey[500],
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    background: "#f2f6f9",
    [`${theme.breakpoints.up("xs")} and (orientation: landscape)`]: {
      height: "81%",
    },
    [theme.breakpoints.up("xs")]: {
      height: "100%",
    },
    [`${theme.breakpoints.up("sm")} and (orientation: landscape)`]: {
      height: "81%",
    },
    [theme.breakpoints.up("sm")]: {
      height: "93%",
    },
    [theme.breakpoints.up("md")]: {
      height: "93%",
    },
    [theme.breakpoints.up("lg")]: {
      height: "93%",
    },
  },
  drawerPaperComplete: {
    width: drawerWidth,
    background: "#f2f6f9",
    height: "100%",
  },
  content: (props) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    background: "#ffffff",
    height: props.showFooter ? "93vh" : "100vh",
    overflowY: "scroll",
    overflowX: "hidden",
    [theme.breakpoints.down("xs")]: {
      height: props.showFooter ? "88vh" : "100vh",
    },
  }),
  contentTap: {
    flexGrow: 1,
    [`${theme.breakpoints.down("sm")} and (orientation: landscape)`]: {
      maxWidth: "90%",
    },
    [theme.breakpoints.up("xs")]: {
      marginBottom: "-14px",
    },
    [theme.breakpoints.up("sm")]: {
      marginBottom: "-17px",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "-17px",
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: "-17px",
    },
    "& .MuiTab-wrapper": {
      textTransform: "none",
    },
    "& .Mui-selected": {
      color: "black",
    },
    "& .MuiTab-root": {
      padding: "0px 38px 12px 19px",
    },
  },
  smToolbar: {
    justifyContent: "space-between",
    gap: "0.5em",
  },
  smToolbarContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexBasis: "50%",
    gap: "0.5em",
  },
  contentTapM: {
    "& .MuiTab-wrapper": {
      textTransform: "none",
    },
    "& .Mui-selected": {
      color: "black",
    },
  },
  logo: {
    flexGrow: 1,
    maxWidth: 160,
    marginTop: "9px",
  },
  padlock: {
    maxWidth: 44,
  },
  footer: {
    position: "fixed",
    background: "#084d81",
    width: "100%",
    zIndex: 1301,
    height: "8vh",
    bottom: 0,
    [theme.breakpoints.down("xs")]: {
      padding: "0.5em",
      maxHeight: "21vh",
      height: "auto",
    },
    [`${theme.breakpoints.down("sm")} and (orientation: landscape)`]: {
      maxHeight: "none",
      height: "auto",
    },
  },
  footerContainer: {
    height: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-around",
    },
  },
  acceptTerms: {
    height: "100%",
  },
  acceptTermsBtn: {
    background: "#2098cf",
    height: "100%",
    width: "100%",
    color: "#ffffff",
    "&:hover": {
      color: "black",
    },
    borderRadius: "0px",

    [theme.breakpoints.down("xs")]: {
      height: "auto",
    },
  },
  footerDescript: {
    color: "#e0e0e0",
    padding: "10px 0px",
    [`${theme.breakpoints.down("sm")} and (orientation: landscape)`]: {
      fontSize: "0.675rem",
    },
    [`@media only screen and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2)`]: {
      fontSize: "0.675rem",
    },
    [`@media only screen and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 3)`]: {
      fontSize: "0.675rem",
    },
  },
  terms: {
    color: "#1980b4",
    marginTop: "24px",
  },
  footerDescriptMob: {
    color: "#e0e0e0",
    fontSize: "0.60rem",
    marginTop: "4px",
  },
  title: {
    color: "#084d81",
  },
  indicator: {
    background: "#084d81",
    height: "5px",
  },
  backdrop: {
    zIndex: 1302,
    color: "#fff",
  },
}));

const modules = {
  tabs: ["Politicas de Privacidad", "Terminos y Condiciones"],
  listTabs: [
    {
      subtitle: [
        "Aviso de privacidad",
        "I. ¿Para qué fines utilizaremos sus datos personales?",
        "II. ¿Qué datos personales recabamos y utilizamos sobre usted?",
        "III. ¿Con quién compartimos su información? .",
        "IV. ¿Cómo puede acceder, rectificar o cancelar sus datos personales, u oponerse a su uso?",
        "V. ¿Cómo puede revocar su consentimiento para el uso de sus datos personales?",
        "VI. ¿Cómo puede limitar el uso o divulgación de su información personal?",
        "VII. Uso de las tecnologías de rastreo en nuestro portal de internet.",
        "VIII. ¿Cómo puede conocer los cambios a este aviso de privacidad?",
        "Consentimiento para el trato personal de sus datos personales",
      ],
    },
    {
      subtitle: [
        "PRIMERA. Declaraciones Generale",
        "SEGUNDA. Definiciones.",
        "TERCERA. Los Servicios.",
        "CUARTA. Vigencia y Terminación.",
        "QUINTA. Documentación del Usuario.",
        "SEXTA. Pago y Forma de Pago.",
        "SÉPTIMA. Elegibilidad.",
        "OCTAVA. Territorio.",
        "NOVENA. Modificaciones y Cambios.",
        "DÉCIMA. Propiedad Industrial.",
        "DÉCIMA PRIMERA. Propiedad de la Información en la Plataforma Digital.",
        "DÉCIMA SEGUNDA. Soporte.",
        "DÉCIMA TERCERA. Registro como Usuario.",
        "DÉCIMA CUARTA. Información ingresada al Software.",
        "DÉCIMA QUINTA. Obligaciones del Usuario.",
        "DÉCIMA SEXTA. Obligaciones del Prestador.",
        "DÉCIMA SÉPTIMA. Mantenimiento al Software y Plataforma.",
        "DÉCIMA OCTAVA. Excluyentes de Responsabilidad.",
        "DÉCIMA NOVENA. Administradores y usuarios.",
        "VIGÉSIMA. Relación Comercial. ",
        "VIGÉSIMA PRIMERA. Domicilio y Notificaciones.",
        "VIGÉSIMA SEGUNDA. Ley y Jurisdicción Aplicables.",
      ],
    },
  ],
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tabpanel-${index}`,
    "aria-controls": `scrollable-auto-tab-${index}`,
  };
}
function LegalBox(props) {
  const {
    showFooter,
    logo,
    showModal,
    setShowModalTerms,
    tabToShow,
    onGrantAgreements,
    onCloseByIcon,
    showBackdropOnClose,
  } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const [isClosedByIcon, setIsClosedByIcon] = useState(false);
  const [value, setValue] = useState(0);
  const [valueModule, setValueModule] = useState(0);
  const handleOnGrantAgreements = async (event) => {
    const success = await onGrantAgreements(event);
    if (success) {
      handleClose();
    }
  };
  const [agreeing, _handleOnGrantAgreements] = useLoadingStatus(handleOnGrantAgreements);
  const classes = useStyles({ showFooter });

  const [open, setOpen] = useState(showModal);
  const theme = useTheme();

  useEffect(() => {
    setOpen(showModal);
  }, [showModal]);

  useEffect(() => {
    if (isClosedByIcon && !open) {
      if (showBackdropOnClose) {
        setTimeout(() => {
          onCloseByIcon();
          setIsClosedByIcon(false);
          setShowModalTerms(false);
        }, 1000);
      } else {
        onCloseByIcon();
        setIsClosedByIcon(false);
        setShowModalTerms(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClosedByIcon, open]);

  useEffect(() => {
    setValueModule(tabToShow);
  }, [tabToShow]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handle = (newValue) => {
    setValue(newValue);
  };

  const handleChangeModule = (event, newValue) => {
    setValue(0);
    setValueModule(newValue);
  };

  const handleClose = () => {
    setOpen(false);
    setShowModalTerms(false);
  };

  const _onCloseByIcon = () => {
    setOpen(false);
    setValueModule(0);
    setValue(0);
    setIsClosedByIcon(true);
  };

  return (
    <div>
      <Backdrop open={agreeing || isClosedByIcon} className={classes.backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Slide in={open} timeout={500} direction="up">
          <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar} elevation={0}>
              <Hidden xsDown implementation="css">
                <Toolbar>
                  <div className={classes.contentTap}>
                    <Tabs
                      variant="scrollable"
                      TabIndicatorProps={{ className: classes.indicator }}
                      value={valueModule}
                      onChange={handleChangeModule}
                    >
                      {modules.tabs.map((text, index) => (
                        <Tab key={`tab-up-${index}`} label={text} {...a11yProps(index)} tabIndex={0} />
                      ))}
                    </Tabs>
                  </div>
                  <IconButton aria-label="close" edge="end" onClick={_onCloseByIcon}>
                    <CloseIcon />
                  </IconButton>
                </Toolbar>
              </Hidden>
              <Hidden smUp implementation="css">
                <Toolbar className={classes.smToolbar}>
                  <div className={classes.smToolbarContainer}>
                    <IconButton
                      aria-label="open drawer"
                      edge="start"
                      onClick={handleDrawerToggle}
                      className={classes.menuButton}
                    >
                      <MenuIcon />
                    </IconButton>
                    <img src={`/img/${logo}`} alt="logo" className={classes.logo} />
                  </div>
                  <IconButton aria-label="close" edge="end" onClick={_onCloseByIcon}>
                    <CloseIcon />
                  </IconButton>
                </Toolbar>
                <div className={classes.contentTapM}>
                  <Tabs
                    TabIndicatorProps={{ className: classes.indicator }}
                    value={valueModule}
                    onChange={handleChangeModule}
                  >
                    {modules.tabs.map((text, index) => (
                      <Tab key={index} label={text} {...a11yProps(index)} tabIndex={0} />
                    ))}
                  </Tabs>
                </div>{" "}
              </Hidden>
            </AppBar>
            <nav className={classes.drawer}>
              <Hidden smUp implementation="css">
                <Drawer
                  variant="temporary"
                  anchor={theme.direction === "rtl" ? "right" : "left"}
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  classes={{
                    paper: showFooter ? classes.drawerPaper : classes.drawerPaperComplete,
                  }}
                  ModalProps={{
                    keepMounted: true,
                  }}
                >
                  {<ListDrawer indexModule={valueModule} tapsObjet={modules.listTabs[valueModule]} handle={handle} />}
                </Drawer>
              </Hidden>
              <Hidden xsDown implementation="css">
                <Drawer
                  classes={{
                    paper: showFooter ? classes.drawerPaper : classes.drawerPaperComplete,
                  }}
                  variant="permanent"
                  open
                >
                  {<ListDrawer indexModule={valueModule} tapsObjet={modules.listTabs[valueModule]} handle={handle} />}
                </Drawer>
              </Hidden>
            </nav>
            <div className={classes.content}>
              <div className={classes.toolbar} />
              {modules.listTabs[valueModule].subtitle.map((text, index) => (
                <TabPanel key={index} value={value} index={index}>
                  {valueModule === 0 && <NoticeOfPrivacy section={index} />}
                  {valueModule === 1 && <TermsAndConditions section={index} />}
                </TabPanel>
              ))}
            </div>
            {showFooter && (
              <Box sx={{ flexGrow: 1 }} className={classes.footer}>
                <Grid container alignItems="center" className={classes.footerContainer}>
                  <Grid item xs={1} sm={1}>
                    <Hidden xsDown implementation="css">
                      <Grid container justify="center">
                        <img src="/img/padlock.png" alt="padlock" className={classes.padlock} />
                      </Grid>
                    </Hidden>
                    <Hidden smUp implementation="css">
                      <Grid container justify="center">
                        <img src="/img/padlock.png" alt="padlock" className={classes.padlock} />
                      </Grid>
                    </Hidden>
                  </Grid>
                  <Grid item xs={10} sm={9}>
                    <Hidden xsDown implementation="css">
                      <Typography variant="subtitle2" component="div" className={classes.footerDescript}>
                        Para tener una cuenta de Indika360 debes aceptar las{" "}
                        <span className={classes.terms}>Condiciones de Servicios</span>. Ademas, cuando creas una
                        cuenta, tratamos tus datos de acuerdo con lo estipulado en nuestra{" "}
                        <span className={classes.terms}>Política de Privacidad.</span>
                      </Typography>
                    </Hidden>
                    <Hidden smUp implementation="css">
                      <Typography variant="caption" display="block" className={classes.footerDescriptMob}>
                        Para tener una cuenta de Indika360 debes aceptar las{" "}
                        <span className={classes.terms}>Condiciones de Servicios</span>. Ademas, cuando creas una
                        cuenta, tratamos tus datos de acuerdo con lo estipulado en nuestra{" "}
                        <span className={classes.terms}>Política de Privacidad.</span>
                      </Typography>
                    </Hidden>
                  </Grid>
                  <Grid item xs={12} sm={2} className={classes.acceptTerms}>
                    <Button
                      variant="contained"
                      className={classes.acceptTermsBtn}
                      onClick={_handleOnGrantAgreements}
                      disabled={agreeing}
                    >
                      ACEPTAR TÉRMINOS
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            )}
          </div>
        </Slide>
      </Modal>
    </div>
  );
}

LegalBox.propTypes = {
  showFooter: PropTypes.bool,
  logo: PropTypes.string,
  showModal: PropTypes.bool,
  setShowModalTerms: PropTypes.func,
  tabToShow: PropTypes.number,
  onGrantAgreements: PropTypes.func,
  loading: PropTypes.bool,
  onCloseByIcon: PropTypes.func,
  showBackdropOnClose: PropTypes.bool,
};

LegalBox.defaultProps = {
  showFooter: true,
  logo: "indika-color-logo.png",
  showModal: true,
  setShowModalTerms: () => {},
  tabToShow: 0,
  onGrantAgreements: () => {},
  onCloseByIcon: () => {},
  showBackdropOnClose: false,
};

export default LegalBox;
