import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  //vista de detalle
  sectionSpacing: {
    marginBottom: "2em",
  },
  sectionTitleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "10px 15px",
  },
  sectionTitleText: {
    margin: 0,
    fontWeight: "bold",
    fontSize: 18,
    marginLeft: 16,
  },
  statusChip: {
    width: "100%",
  },
  actionsContainer: {
    [theme.breakpoints.down(480)]: {
      position: "fixed",
      bottom: 0,
      left: 0,
      "border-radius": 0,
      "z-index": 10,
      "min-width": "100vw",
      backgroundColor: "#fff",
    },
  },
  actionButtonContainer: {
    maxWidth: "300px",
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down(1024)]: {
      width: "100%",
    },
    [theme.breakpoints.down(480)]: {
      "min-width": "100vw",
    },
  },
  actionButton: {
    width: "inherit",
    [theme.breakpoints.down(480)]: {
      minHeight: "46px",
      borderRadius: 0,
      border: "none",
    },
  },
  observationsButton: {
    color: theme.palette.error.main,
    backgroundColor: "inherit",
    "&:hover": {
      color: "#fff",
      backgroundColor: theme.palette.error.main,
    },
    [theme.breakpoints.down(480)]: {
      backgroundColor: theme.palette.error.main,
      color: "#fff",
      border: "2px solid",
      borderColor: "#ad003d",
      "&:hover": {
        boxShadow: "none",
        backgroundColor: theme.palette.error.main,
      },
    },
  },
  manualsButton: {
    [theme.breakpoints.down(480)]: {
      backgroundColor: "#677374",
      color: "#fff",
    },
  },
  mobileActionsSpacer: {
    display: "none",
    [theme.breakpoints.down(480)]: {
      display: "block",
    },
  },
  buttonErrorColor: {
    color: theme.palette.error.main,
  },
  observationDialog: {
    minWidth: "auto",
    [theme.breakpoints.up(600)]: {
      minWidth: "600px",
    },
  },

  //progreso
  assetsHeaderContainer: {
    marginBottom: "-40px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2),
    },
  },
  searchAssetByQrContainer: {
    position: "relative",
    bottom: "-56px",
    "z-index": 1,
    maxWidth: "70%",
    [theme.breakpoints.down(1224)]: {
      maxWidth: "60%",
    },
    [theme.breakpoints.down("sm")]: {
      position: "static",
      maxWidth: "none",
    },
  },
  searchAssetByQrTextField: {
    color: theme.palette.text.primary,
    cursor: "auto",
    "&:hover:not(.Mui-disabled)": {
      "&:before": {
        "border-bottom": "1px solid rgba(0, 0, 0, 0.42)",
      },
    },
  },
  "@keyframes flicker": {
    from: {
      opacity: 0.9,
    },
    to: {
      opacity: 0.4,
    },
  },

  dialogActions: {
    marginLeft: "-6px",
    marginTop: "-2px",
  },
  flicker: {
    animationName: "$flicker",
    animationDuration: "1000ms",
    animationIterationCount: "infinite",
    animationDirection: "alternate",
    animationTimingFunction: theme.transitions.easing.easeInOut,
  },
  withAnimation: ({ completed, validating, status }) => ({
    // animationPlayState: completed === PROGRESS_STATUS.completed || validating ? "paused" : "running",
    animation: completed === status?.completed ? "none" : "auto",
  }),
}));

export default useStyles;
