export const ROOT = "/";
export const HOME = "/inicio";


const _HOME = {
  ROOT,
  HOME
};

export default _HOME;
