import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Logger } from "@aws-amplify/core";

import AppVersionContext from "./AppVersionContext";
import useAppVersion from "hooks/useAppVersion";

const logger = new Logger("SystemVersionContextProvider");

function SystemVersionContextProvider(props) {
  const [version, setCurrentVersion] = useState("0.0.0");
  const [nextVersion, setNextVersion] = useState("0.0.0");
  const { updateVersion } = useAppVersion();

  useEffect(() => {
    checkSystemVersion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.versionUrl]);

  async function checkSystemVersion(versionUrl = props.versionUrl) {
    if (versionUrl) {
      logger.info("Consultando versión actual del sistema...");
      try {
        const { version } = await axios(versionUrl).then((response) => response.data);
        logger.info(`Versión obtenida ${version}...`);
        setCurrentVersion(version);
        setNextVersion();
        updateVersion(version);
      } catch (error) {
        logger.error(error);
      }
    }
  }

  const value = useMemo(
    () => ({
      version,
      nextVersion,
      checkSystemVersion,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.versionUrl, version, nextVersion]
  );

  return <AppVersionContext.Provider value={value}>{props.children}</AppVersionContext.Provider>;
}

SystemVersionContextProvider.propTypes = {
  versionUrl: PropTypes.string,
  children: PropTypes.element,
};

export default SystemVersionContextProvider;
