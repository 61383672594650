import React, { useState, useEffect, useImperativeHandle } from "react";
import PropTypes from "prop-types";

import Select from "components/custom/Select";
import useLoadingStatus from "hooks/useLoadingStatus";
import useNotifier from "hooks/useNotifier";
import * as graphql from "./helpers/graphql";

const MaterialKitSelect = React.forwardRef(function KitSelect({ label, onChangeKit }, ref) {
  const [selectedKit, setSelectedKit] = useState("");
  const [loadingKits, _fetchMaterialKits] = useLoadingStatus(graphql.fetchMaterialKits);
  const [kitList, setKitList] = useState([]);
  const { showError } = useNotifier();

  useEffect(() => {
    (async function() {
      try {
        const kits = await _fetchMaterialKits();
        setKitList(kits);
      } catch (error) {
        showError("Ocurrió un error al cargar el catálogo de Kits.")
      }
    })();
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      addKit(kit) {
        const newKit = { value: kit.id, label: kit.name };
        setKitList([newKit, ...kitList]);
      },
      updateKit(updatedKit) {
        const updatedList = [...kitList];
        const kitIndex = kitList.findIndex(({ id }) => id === updatedKit.id);
        updatedList[kitIndex] = updatedKit;
        setKitList(updatedList);
      }
    }),
    [kitList],
  )

  function updatedSelectedKit(event, data, option) {
    const selection = event.target.value;
    if (!selection) {
      return;
    }
    setSelectedKit(selection);
    onChangeKit(selection, data, option);
  }

  return (
    <Select
      label={label}
      value={selectedKit}
      onChange={updatedSelectedKit}
      options={kitList}
      loading={loadingKits}
      fullWidth
    />
  );
})

MaterialKitSelect.propTypes = {
  label: PropTypes.string,
  onChangeKit: PropTypes.func,
};

MaterialKitSelect.defaultProps = {
  label: "Kit de materiales",
  onChangeKit: () => {},
};

export default MaterialKitSelect;