/* eslint-disable react/display-name */
import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import makeStyles from "@material-ui/styles/makeStyles";
import Typography from "@material-ui/core/Typography";

import MaterialTable from "components/custom/MaterialTable";
import Section from "components/Section";

import { handleGetMaterialCode, formatAssetsSeries, handleGetName, handleGetMaterialQuantity } from "util/text";

export default function TicketActivityDetailMaterials(props) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();

  const { usedActivityMaterials } = props;

  return (
    <Section>
      <Grid container className={classes.detailsGrid}>
        <Grid item xs={12}>
          <Box>
            <Typography variant="h6">Materiales</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <MaterialTable
            columns={isMobileScreen ? TABLE_COLUMNS_MOBILE : TABLE_COLUMNS}
            data={usedActivityMaterials}
            options={TABLE_OPTIONS}
          />
        </Grid>
      </Grid>
    </Section>
  );
}

const useStyles = makeStyles((theme) => ({
  detailsGrid: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  detailsContent: {
    padding: theme.spacing(2),
  },
  validationBtn: {
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: 0,
      right: 0,
      width: "100%",
      "z-index": 99,
    },
  },
}));

const TABLE_COLUMNS_MOBILE = [
  {
    field: "asset.asset.serial",

    render: (taskMaterialData) => {
      return (
        <Box display="flex">
          <Box>
            <Typography paragraph>
              <strong>Código de Material: </strong>
              <span>{handleGetMaterialCode(taskMaterialData)}</span>
            </Typography>
            <Typography paragraph>
              <strong>Nombre: </strong>
              <span>{handleGetName(taskMaterialData)}</span>
            </Typography>
            <Typography paragraph>
              <strong>Serie: </strong>
              <span>{formatAssetsSeries(taskMaterialData?.asset?.serial)}</span>
            </Typography>
            <Typography paragraph>
              <strong>Cantidad: </strong>
              <span>{handleGetMaterialQuantity(taskMaterialData)}</span>
            </Typography>
          </Box>
        </Box>
      );
    },
  },
];

const TABLE_COLUMNS = [
  { title: "ID", field: "id", hidden: true },
  {
    title: "Serie",
    field: "serial",
    render: (rowData) => <p>{formatAssetsSeries(rowData?.asset?.serial)}</p>,
  },
  {
    title: "Código",
    field: "code",
    render: (rowData) => <p>{handleGetMaterialCode(rowData)}</p>,
  },
  {
    title: "Nombre",
    field: "name",
    render: (rowData) => <p>{handleGetName(rowData)}</p>,
  },
  {
    title: "Cantidad",
    field: "asset.asset.quantity",
    render: (rowData) => <p>{handleGetMaterialQuantity(rowData)}</p>,
  },
];

const TABLE_OPTIONS = {
  exportButton: false,
  columnsButton: false,
};

TicketActivityDetailMaterials.propTypes = {
  usedActivityMaterials: PropTypes.array,
};
