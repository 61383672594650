const getTimerEvent = `
  query GetTimerEvent($id: ID!) {
    getTimerEvent(id: $id) {
      _deleted
      _version
      description
      cause {
        _version
        description
        id
        name
        type
      }
      duration
      eventCauseID
      finalized
      id
      taskID
      timestamp
    }
  }

`;

export default getTimerEvent;
