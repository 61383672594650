export const createRemark = /* GraphQL */ `
  mutation CreateRemark($input: CreateRemarkInput!) {
    createRemark(input: $input) {
      id
      taskID
      taskStatus
      remark
      createdAt
      updatedAt
      _version
      _lastChangedAt
      _deleted
    }
  }
`;