export const updatePremise = /* GraphQL */ `
  mutation UpdatePremise(
    $premisesId: ID!
    $premiseName: String
    $premiseCode: String
    $premiseVersion: Int!
    # Company data
    $companyId: ID!
    $companyVersion: Int!
    # Address data
    $addressId: ID!
    $street: String!
    $number: String!
    $zipcode: String
    $city: String
    $settlement: String
    $locality: String
    $addressVersion: Int!
    # Custodian data
    $custodianId: ID!
    $custodianName: String!
    $middlename: String
    $lastnameP: String!
    $lastnameM: String
    $email: AWSEmail
    $phone: String
    $extension: String
    $custodianVersion: Int!
  ) {
    updatePremises(input: { id: $premisesId, name: $premiseName, code: $premiseCode, _version: $premiseVersion }) {
      id
      name
      code
      _lastChangedAt
      _version
    }
    updateCompany(input: { id: $companyId, _version: $companyVersion }) {
      id
      name
      description
      _lastChangedAt
      _version
    }
    updateAddress(
      input: {
        id: $addressId
        street: $street
        number: $number
        zipcode: $zipcode
        city: $city
        settlement: $settlement
        locality: $locality
        _version: $addressVersion
      }
    ) {
      id
      street
      number
      zipcode
      city
      settlement
      locality
      _lastChangedAt
      _version
    }
    updateCustodian(
      input: {
        id: $custodianId
        name: $custodianName
        middlename: $middlename
        lastnameP: $lastnameP
        lastnameM: $lastnameM
        email: $email
        phone: $phone
        extension: $extension
        _version: $custodianVersion
      }
    ) {
      id
      name
      middlename
      lastnameP
      lastnameM
      email
      phone
      extension
      _lastChangedAt
      _version
    }
  }
`;
