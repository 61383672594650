/** Componente que maneja las rutas internas del componente de administración de la aplicación */
import React, { useEffect } from "react";
import { Route, Redirect, Switch, useHistory, useLocation } from "react-router-dom";
import {
  ROOT,
  USERS,
  COMPANY,
  COMPANY_DETAIL,
  USER_DETAILS,
  PREMISES_CATALOG,
  PREMISES_DETAILS,
  ACTIVITIES,
  MATERIAL_CATALOG,
  DOMAINS,
  UOM_CATALOG,
  MATERIAL_CATEGORY_CATALOG,
  UOM_DATA_LOAD,
  MATERIAL_DATA_LOAD,
  MATERIAL_CATEGORY_DATA_LOAD,
  INTEGRATIONS,
} from "../constant/route/admin";

import MainLayout from "../layout/main";
import UserListView from "components/Users/UserListView";
import UserDetailsView from "components/Users/UserDetailsView";
import PremisesView from "components/admin/PremisesView";
import PremisesDetailView from "components/admin/PremisesDetailView";
import ActivityTypesView from "components/ActivityTypes/ActivityTypesView";
import MaterialListView from "../components/Materials/MaterialListView";
import MaterialCategoryTypesView from "../components/admin/MaterialCategoryTypesView";
import DomainsView from "components/Domains/DomainsView";
import UOMDataLoadView from "components/admin/UOMDataLoadView";
import MaterialDataLoadView from "components/admin/MaterialDataLoadView";
import MaterialCategoryDataLoadView from "components/admin/MaterialCategoryDataLoadView";
import UOMListView from "../components/UOMs/UOMListView";
import IntegrationsView from "components/admin/integrations/IntegrationsView";
import { CompaniesListView, CompanyDetailView } from "components/admin/Companies";

import { AdminRoutesIcons } from "constant/drawerIcons";

const {
  ClientsIcon,
  UsersIcon,
  DomainsIcon,
  CatalogueIcon,
  MaterialCategoriesIcon,
  UOMIcon,
  MaterialsIcon,
  SitesIcon,
  ActivityTypesIcon,
  // IntegrationsIcon,
} = AdminRoutesIcons;

/** Opciones del menu izquierdo */
const drawerItems = [
  {
    text: "Clientes",
    to: COMPANY,
    disabled: false,
    icon: ClientsIcon,
  },
  {
    text: "Sitios",
    to: PREMISES_CATALOG,
    disabled: false,
    icon: SitesIcon,
  },
  {
    text: "Catálogos",
    icon: CatalogueIcon,
    items: [
      {
        text: "Categorías de Materiales",
        to: MATERIAL_CATEGORY_CATALOG,
        disabled: false,
        icon: MaterialCategoriesIcon,
      },
      {
        text: "Unidades de medida",
        to: UOM_CATALOG,
        disabled: false,
        icon: UOMIcon,
      },
      {
        text: "Materiales para actividades",
        to: MATERIAL_CATALOG,
        disabled: false,
        icon: MaterialsIcon,
      },
      { text: "Tipo de actividades", to: ACTIVITIES, disabled: false, icon: ActivityTypesIcon },
    ],
  },
  {
    text: "Usuarios",
    to: USERS,
    disabled: false,
    icon: UsersIcon,
  },
  {
    text: "Dominios",
    to: DOMAINS,
    disabled: false,
    icon: DomainsIcon,
  },
  // {
  //   text: "Integraciones",
  //   to: INTEGRATIONS,
  //   disabled: false,
  //   icon: IntegrationsIcon,
  // },
];

/** Manejador de rutas dentro del modulo */
const ROUTES = [
  {
    exact: true,
    path: COMPANY,
    component: CompaniesListView,
  },
  {
    exact: true,
    path: COMPANY_DETAIL,
    component: CompanyDetailView,
  },
  {
    exact: true,
    path: ACTIVITIES,
    component: ActivityTypesView,
  },
  {
    exact: true,
    path: USERS,
    component: UserListView,
  },
  {
    exact: true,
    path: MATERIAL_CATALOG,
    component: MaterialListView,
  },
  {
    exact: true,
    path: MATERIAL_DATA_LOAD,
    component: MaterialDataLoadView,
  },
  {
    exact: true,
    path: USER_DETAILS,
    component: UserDetailsView,
  },
  {
    exact: true,
    path: PREMISES_CATALOG,
    component: PremisesView,
  },
  {
    exact: true,
    path: PREMISES_DETAILS,
    component: PremisesDetailView,
  },
  {
    exact: true,
    path: DOMAINS,
    component: DomainsView,
  },
  {
    exact: true,
    path: UOM_CATALOG,
    component: UOMListView,
  },
  {
    exact: true,
    path: UOM_DATA_LOAD,
    component: UOMDataLoadView,
  },
  {
    exact: true,
    path: MATERIAL_CATEGORY_DATA_LOAD,
    component: MaterialCategoryDataLoadView,
  },
  {
    exact: true,
    path: MATERIAL_CATEGORY_CATALOG,
    component: MaterialCategoryTypesView,
  },
  {
    exact: true,
    path: INTEGRATIONS,
    component: IntegrationsView,
  },
];

function AdminRoutes() {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location.pathname === ROOT || location.pathname === `${ROOT}/`) {
      if (drawerItems[0].to) {
        history.replace(drawerItems[0].to);
      } else {
        history.replace(drawerItems[0].items[0].to);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <MainLayout drawerItems={drawerItems}>
      <Switch>
        {ROUTES.map((route, index) => (
          <Route key={`ar-${index}`} {...route} />
        ))}
        {/* Ruta por defecto */}
        <Route path="*" render={() => <Redirect to={drawerItems[0].to} />} />
      </Switch>
    </MainLayout>
  );
}

export default AdminRoutes;
