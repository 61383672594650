import React from "react";
import PropTypes from "prop-types";
import { Skeleton } from "@material-ui/lab";
import GoBackButton from "components/common/GoBackButton";
import { useEnablement } from "../CompanyEnablement";
import { Box, Card, CardHeader, CardContent, Typography, CardActions } from "@material-ui/core";
import TableSwitchButton from "components/common/TableSwitchButton";
import { CompanyDetailCardEditButton } from "../CompanyFormOperations";
import { ENABLEMENT_MODE, ENABLEMENT_TEXT } from "../constant";
import useOnlineStatus from "@rehooks/online-status";

export const CompanyDetailCard = ({ data: companyData, isLoading, children, ...props }) => {
  const { onSwitchChange } = useEnablement();
  const isOnline = useOnlineStatus();

  if (isLoading) {
    return <CompanyDetailCardLoading />;
  }
  const EnablementText = ENABLEMENT_TEXT[companyData.enabled ? ENABLEMENT_MODE.DISABLED : ENABLEMENT_MODE.ENABLED];
  const subHeaderText = companyData.enabled ? "Habilitado" : "Deshabilitado";

  return (
    <>
      <GoBackButton />
      <Card {...props}>
        <CardHeader
          title={companyData.name}
          subheader={subHeaderText}
          subheaderTypographyProps={{ color: "primary" }}
        />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {companyData.description}
          </Typography>
          {children}
        </CardContent>
        <CardActions>
          <TableSwitchButton
            checked={companyData.enabled}
            rowData={companyData}
            onChange={onSwitchChange}
            onlineTitle={`${EnablementText} cliente.`}
            offlineTitle={`Sin conexión a internet para ${EnablementText} cliente.`}
            disabled={!isOnline}
          />
          <CompanyDetailCardEditButton>Editar</CompanyDetailCardEditButton>
        </CardActions>
      </Card>
    </>
  );
};

export default CompanyDetailCard;

CompanyDetailCard.propTypes = {
  data: PropTypes.shape(),
  isLoading: PropTypes.bool,
  children: PropTypes.node,
};

export const CompanyDetailCardLoading = () => {
  return (
    <Box marginBottom={8}>
      <GoBackButton />
      <Box height={194} width="100%">
        <Skeleton variant="text" width={320} height={60} />
        <Skeleton variant="text" width={80} height={30} />
        <Skeleton variant="text" height={100} />
        <Box display="flex" gridGap={16}>
          <Skeleton variant="rect" width={64} height={38} />
          <Skeleton variant="rect" width={64} height={38} />
        </Box>
      </Box>
    </Box>
  );
};
