export const updateRemark = /* GraphQL */ `
  mutation UpdateRemark($input: UpdateRemarkInput!) {
    updateRemark(input: $input) {
      id
      remark
      taskID
      taskStatus
      supervisors
      supportEngineers
      warehouseKeepers
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
