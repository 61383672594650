export const searchMaterials = /* GraphQL */ `
  query SearchMaterials(
    $filter: SearchableMaterialFilterInput
    $sort: SearchableMaterialSortInput
    $limit: Int
    $nextToken: String
  ) {
    searchMaterials(filter: $filter, sort: $sort, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        code
        description
        category {
          id
          name
          abbreviation
          description
          createdAt
          updatedAt
        }
        uom {
          id
          name
          abbreviation
          description
          createdAt
          updatedAt
        }
        manufacturer
        trackable
        enabled
        createdAt
        updatedAt
        _version
        _deleted
        deleted
      }
      nextToken
      total
    }
  }
`;
