import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import useOnlineStatus from "@rehooks/online-status";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import MaterialTable from "components/custom/MaterialTable";
import useNotifier from "hooks/useNotifier";
import { checkNextItems } from "util/lists";
import useLoadingStatus from "hooks/useLoadingStatus";
import useAPIHelpers from "./helpers/useAPIHelpers";

const ROWS_PER_PAGE = 5;

export default function MaterialListDialog({ open, onClose, onCompleteSelection, excludedMaterialIds }) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedMaterials, setSelectedMaterials] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const { getMaterials, findMaterials } = useAPIHelpers();
  const [loading, _getMaterials] = useLoadingStatus(getMaterials);
  const [searching, _findMaterials] = useLoadingStatus(findMaterials);
  const [addingMaterials, _completeSelection] = useLoadingStatus(completeSelection);
  const [materialList, setMaterialList] = useState([]);
  const [nextToken, setNextToken] = useState("");
  const [currentPage, setCurrentPage] = useState(null);
  const { showError } = useNotifier();
  const isOnline = useOnlineStatus();

  const filteredMaterials = useMemo(() => {
    if (excludedMaterialIds.length) {
      return materialList.reduce((filtered, { id, ...materialData }) => {
        if (!excludedMaterialIds.includes(id)) {
          filtered.push({ id, ...materialData });
        }
        return filtered;
      }, []);
    } else {
      return materialList;
    }
  }, [excludedMaterialIds, materialList]);

  useEffect(() => {
    if (open) {
      fetchMaterials();
    }
  }, [open]);

  useEffect(() => {
    if (!isOnline && currentPage !== null) {
      fetchMaterials();
    }
  }, [currentPage]);

  useEffect(() => {
    if (!addingMaterials) {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addingMaterials]);

  async function fetchMaterials(customToken) {
    const pagination = {
      limit: ROWS_PER_PAGE * 4,
      page: currentPage || 0,
      token: customToken,
    };
    const { list, token } = await _getMaterials(pagination);
    setMaterialList((materials) => {
      if ((isOnline && customToken) || (!isOnline && currentPage)) {
        return [...materials, ...list];
      } else {
        return list;
      }
    });
    setNextToken(token);
  }

  async function searchMaterials(term = "", customToken = null) {
    setSearchTerm(term);
    if (!isOnline) {
      return;
    }
    if (!term?.length) {
      return fetchMaterials();
    }
    const params = {
      limit: ROWS_PER_PAGE * 4,
      token: customToken,
      term,
    };
    const { list, token } = await _findMaterials(params);
    setMaterialList((materials) => {
      if (isOnline && customToken) {
        return [...materials, ...list];
      } else {
        return list;
      }
    });
    setNextToken(token);
  }

  function loadNextMaterials(page, pageSize) {
    const hasNextPage = checkNextItems(page, filteredMaterials.length, pageSize);
    if (isOnline) {
      if (hasNextPage && nextToken && searchTerm.length) {
        searchMaterials(searchTerm, nextToken);
      } else if (hasNextPage && nextToken && !searchTerm.length) {
        fetchMaterials(nextToken);
      }
    } else {
      if (hasNextPage) {
        const cPage = currentPage || 0;
        setCurrentPage(cPage + 1);
      }
    }
  }

  function updateSelection(rows) {
    const updatedRows = rows.map(({ tableData, ...row }) => ({ ...row }));
    setSelectedMaterials(updatedRows);
  }

  async function completeSelection() {
    if (!selectedMaterials.length) {
      return showError("Debes seleccionar un material para continuar.");
    }
    await onCompleteSelection(selectedMaterials);
    // onClose();
  }

  function handleDialogExited() {
    setSelectedMaterials([]);
  }

  return (
    <Dialog maxWidth="md" fullWidth open={open} fullScreen={isMobileScreen} onExited={handleDialogExited}>
      <DialogTitle>Selección de Materiales</DialogTitle>
      <DialogContent dividers>
        <MaterialTable
          options={TABLE_OPTIONS}
          columns={isMobileScreen ? TABLE_COLUMNS_MOBILE : TABLE_COLUMNS_DESKTOP}
          onSelectionChange={updateSelection}
          onChangePage={loadNextMaterials}
          onSearchChange={searchMaterials}
          data={filteredMaterials}
          isLoading={loading || searching || addingMaterials}
        />
      </DialogContent>
      <DialogActions>
        <Button color="default" onClick={onClose}>
          Cancelar
        </Button>
        <Button color="primary" onClick={_completeSelection} disabled={addingMaterials}>
          Continuar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

MaterialListDialog.propTypes = {
  open: PropTypes.bool,
  excludedMaterialIds: PropTypes.array,
  onClose: PropTypes.func,
  onCompleteSelection: PropTypes.func,
};

MaterialListDialog.defaultProps = {
  open: false,
  excludedMaterialIds: [],
  onClose: () => {},
  onCompleteSelection: () => {},
};

const TABLE_OPTIONS = {
  exportButton: false,
  columnsButton: false,
  selection: true,
  showSelectAllCheckbox: false,
  debounceInterval: 500,
  paginationType: "stepped",
  pageSizeOptions: [],
};

const TABLE_COLUMNS_DESKTOP = [
  { title: "Código", field: "code" },
  { title: "Nombre", field: "name" },
  { title: "Unidad de medida", field: "uom.name", emptyValue: "Sin unidad" },
  { title: "Descripción", field: "description", hidden: true, searchable: true },
];

const TABLE_COLUMNS_MOBILE = [
  {
    field: "name",
    customFilterAndSearch: (value, rowData) => {
      const parsedValue = value.toLowerCase();
      return (
        rowData?.name?.toLowerCase().includes(parsedValue) ||
        rowData?.code?.toLowerCase().includes(parsedValue) ||
        rowData?.uom?.name?.toLowerCase().includes(parsedValue) ||
        rowData?.description?.toLowerCase().includes(parsedValue)
      );
    },
    render: function renderMobileColumns(rowData) {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <strong>Código Material: </strong>
            {rowData.code}
          </Grid>
          <Grid item xs={12}>
            <strong>Nombre: </strong>
            {rowData.name}
          </Grid>
          <Grid item xs={12}>
            <strong>Unidad: </strong>
            {rowData.uom?.name || "Sin unidad"}
          </Grid>
        </Grid>
      );
    },
  },
];
