import React from "react";
import Box from "@material-ui/core/Box";
import { Button, Grid, Typography } from "@material-ui/core";

export default function GeneralErrorBoundary() {
  function navigateToHome() {
    const tempLink = document.createElement("a");
    tempLink.style.display = "none";
    tempLink.href = "/";
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
  }

  return (
    <Grid container justify="center">
      <Grid item xs={12}>
        <Box mt={1}>
          <a href="/">
            <img src="/img/indika-color-logo.png" height="30" alt="Indika Logo" />
          </a>
        </Box>
      </Grid>

      <Grid item xs={9} sm={8} md={6} lg={3}>
        <Box mt={8} width="100%" height="auto" mx="auto">
          <img src="/img/fallback.png" width="100%" alt="Ilustración de error" />
          <Box my={2}>
            <Typography variant="h3" align="center">
              ¡Oops!
            </Typography>
            <Typography variant="body1" align="center">
              Un error impidió la ejecución de la aplicación.
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center">
            <Button variant="contained" color="primary" onClick={navigateToHome} style={{ background: "#00838f" }}>
              Recargar aplicación
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
