import { Children, cloneElement } from "react";
import { Logger } from "aws-amplify";
const logger = new Logger("addPropsToChildrenDinamically:");

/**
 * Añade propiedades a los componentes hijo de manera dinámica.
 *
 * Las propiedades unicamente se añaden a los componentes definidos en childrenComponentNames
 * @param {ReactChildren} children
 * @param {Object} props
 * @param {Array} childrenComponentNames  childrenComponentNames Es un arreglo de NOMBRES de componentes
 * @returns {ReactChildren}
 */
export default function addPropsToChildrenDinamically(children, props, childrenComponentNames = []) {
  //children: hijos recibidos
  //props: props a añadir
  //childName: arreglo de los nombres de los componentes a los que agregar los props
  logger.debug("---Añadiendo propidades a los siguientes hijos...---", {
    props,
    componentes: childrenComponentNames,
  });

  let childrenElements = Children.toArray(children);

  //early exit
  if (!childrenElements.length) {
    return childrenElements;
  }

  childrenComponentNames.forEach((compontentName) => {
    const childrenIndex = childrenElements.findIndex((child) => child.type.name === compontentName);
    if (childrenIndex !== -1) {
      childrenElements[childrenIndex] = cloneElement(childrenElements[childrenIndex], props);
    }
  });
  logger.debug("---Propiedades añadidas...---", childrenElements);
  return childrenElements;
}
