import { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import useOnlineStatus from "@rehooks/online-status";
import { Logger } from "@aws-amplify/core";
import API, { graphqlOperation } from "@aws-amplify/api";
// Imports locales
import * as queries from "graphql-custom/queries";
import ApplicationContext from "context/ApplicationContext";
import useDeviceSettings from "hooks/useDeviceSettings";
import * as SETTINGS from "constant/settings";

const logger = new Logger("setup/systemSettings");

/**
 * Consulta una configuracion del sistema acorde a los valores proporcionados como id y key
 * @param {
 * } param0
 * @returns
 */
async function fetchSetting({ id, key }) {
  return API.graphql(
    graphqlOperation(queries.getSetting, {
      id,
      key,
    })
  ).then((result) => (result.data.getSetting === null ? undefined : { ...result.data.getSetting }));
}

// CONFIGURACIONES POR DEFECTO *****************************************************************************************

/**
 * Configura los valores requeridos en el formulario de alta de activo en el dialog de ticket
 */
const ASSET_CREATION_REQUIRED_FIELDS = {
  id: SETTINGS.SUPPORT.ID,
  key: SETTINGS.SUPPORT.KEYS.ASSET_CREATION_REQUIRED_FIELDS,
  value: '{ "name": true, "serial": true, "code": false, "location": false }',
};

/**
 * Configura la disponibilidad del formulario de alta de activo en el dialog de ticket
 */
const ASSET_CREATION = {
  id: SETTINGS.SUPPORT.ID,
  key: SETTINGS.SUPPORT.KEYS.ASSET_CREATION,
  value: "false",
};

/**
 * Configura la opción de cancelación y el filtrado por este estado en Lista de Tickets y Detalle de Ticket
 */
const TICKET_CANCELLATION = {
  id: SETTINGS.SUPPORT.ID,
  key: SETTINGS.SUPPORT.KEYS.TICKET_CANCELLATION,
  value: "false",
};

/**
 * Configura la opción de cancelación y el filtrado por este estado en Lista de Tickets y Detalle de Ticket
 */
const REMOTE_SUPPORT = {
  id: SETTINGS.SUPPORT.ID,
  key: SETTINGS.SUPPORT.KEYS.REMOTE_SUPPORT,
  value: "false",
};

/**
 * Configura la opción de cancelación y el filtrado por este estado en Lista de Tickets y Detalle de Ticket
 */
const ALLOWED_USERS_TASK_WEB_FLOW = {
  id: SETTINGS.MAINTENANCE.ID,
  key: SETTINGS.MAINTENANCE.KEYS.ALLOWED_USERS_TASK_WEB_FLOW,
  value: "[]",
};

const REQUIRED_SUPPORT_EVIDENCE = {
  id: SETTINGS.SUPPORT.ID,
  key: SETTINGS.SUPPORT.KEYS.REQUIRED_EVIDENCE,
  value: '{ "start": 3, "progress": 4, "end": 1 }',
};

// *********************************************************************************************************************

/**
 * Consulta las configuraciones del sistema
 * @returns
 */
export async function fetchSystemSettings() {
  const promises = [
    fetchSetting({
      id: SETTINGS.SUPPORT.ID,
      key: SETTINGS.SUPPORT.KEYS.ASSET_CREATION,
    }).then((setting = ASSET_CREATION) => ({ ...setting, value: JSON.parse(setting.value) })),
    fetchSetting({
      id: SETTINGS.SUPPORT.ID,
      key: SETTINGS.SUPPORT.KEYS.ASSET_CREATION_REQUIRED_FIELDS,
    }).then((setting = ASSET_CREATION_REQUIRED_FIELDS) => ({
      ...setting,
      value: JSON.parse(setting.value),
    })),
    fetchSetting({
      id: SETTINGS.SUPPORT.ID,
      key: SETTINGS.SUPPORT.KEYS.TICKET_CANCELLATION,
    }).then((setting = TICKET_CANCELLATION) => ({ ...setting, value: JSON.parse(setting.value) })),
    fetchSetting({
      id: SETTINGS.SUPPORT.ID,
      key: SETTINGS.SUPPORT.KEYS.REMOTE_SUPPORT,
    }).then((setting = REMOTE_SUPPORT) => ({ ...setting, value: JSON.parse(setting.value) })),
    fetchSetting({
      id: SETTINGS.MAINTENANCE.ID,
      key: SETTINGS.MAINTENANCE.KEYS.ALLOWED_USERS_TASK_WEB_FLOW,
    }).then((setting = ALLOWED_USERS_TASK_WEB_FLOW) => ({ ...setting, value: JSON.parse(setting.value) })),
    fetchSetting({
      id: SETTINGS.SUPPORT.ID,
      key: SETTINGS.SUPPORT.KEYS.REQUIRED_EVIDENCE,
    }).then((setting = REQUIRED_SUPPORT_EVIDENCE) => {
      return { ...setting, value: JSON.parse(setting.value) };
    }),
  ];

  const settings = await Promise.all(promises);
  return settings;
}

/**
 * Funcion auxiliar en el armado de objeto/mapa de configuraciones
 * @param {Array} settings Arreglo de configuraciones
 */
function buildSettingsMap(settings) {
  const settingsToStore = {};

  const settingsMap = new Map();
  settings.forEach((setting) => {
    if (settingsMap.has(setting.id)) {
      const settingsGroup = settingsMap.get(setting.id);
      settingsGroup[setting.key] = setting;
    } else {
      const settingsGroup = {
        [setting.key]: setting,
      };
      settingsMap.set(setting.id, settingsGroup);
    }
  });

  settingsMap.forEach((value, key) => {
    settingsToStore[key] = value;
  });

  return settingsToStore;
}

/**
 * Hook que consulta configuraciones del sistema y las almacena en redux.
 * Este hook depende del contexto de Redux, debe invocarse en un componente interno al contexto de redux o fallara.
 */
function useSystemSettingsSetup() {
  const authState = useSelector((state) => state.session.state);
  const onlineStatus = useOnlineStatus();
  const system = useContext(ApplicationContext.Context);
  const device = useDeviceSettings();

  useEffect(() => {
    device.setInfo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    checkSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState, onlineStatus]);

  async function checkSettings() {
    // Verificacion de precondiciones de consulta configuraciones
    if (onlineStatus && authState === "signIn") {
      logger.debug("Consultando settings del sistema...");
      const settings = await fetchSystemSettings();
      const settingsToStore = buildSettingsMap(settings);
      logger.debug("Registrando suscripciones en memora de aplicacion...");
      system.setSystemConfigurations(settingsToStore);
    } else {
      logger.debug("No se cumple con los parametros para realizar consulta de configuraciones...");
    }
  }
}

export default useSystemSettingsSetup;
