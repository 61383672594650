import { fetchTaskById, fetchAttachmentsByTask, fetchInspectionNotesByTask } from "datastore";

export async function fetchInstallationDetail(taskId) {
  const [task, notes, attachments] = await Promise.all([
    fetchTaskById(taskId),
    fetchInspectionNotesByTask(taskId),
    fetchAttachmentsByTask(taskId),
  ]);

  return { ...task, notes, attachments };
}
