/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/display-name */
import React, { useEffect } from "react";

import { Route, Switch, useLocation, useHistory } from "react-router-dom";
import MainLayout from "../layout/main";
import { ROOT, MY_TICKET_LIST, TICKET_DETAILS } from "constant/route/support_supervisor";

import TicketDetailsView from "components/Tickets/TicketDetailsView";
import TicketsListView from "components/Tickets/TicketsListView";
import * as ROLES from "constant/roles";

import { SupportRoutesIcons } from "constant/drawerIcons";

const { TicketsIcon, PersonalVideoOutlinedIcon } = SupportRoutesIcons;

const drawerItems = [
  {
    text: "Total de tickets",
    to: MY_TICKET_LIST,
    disabled: false,
    icon: TicketsIcon,
  },
];
const ROUTES = [
  {
    exact: true,
    path: MY_TICKET_LIST,
    component: () => <TicketsListView type={ROLES.SUPERVISORS} />,
  },
  {
    exact: true,
    path: TICKET_DETAILS,
    component: () => <TicketDetailsView type={ROLES.SUPERVISORS} />,
  },
];

function RevisionRoutes() {

  const location = useLocation();
  const history = useHistory();

  // Redirect to one end route if the current it's a partial one
  useEffect(() => {
    if (location.pathname === ROOT) {
      history.replace(drawerItems[0].to);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <MainLayout drawerItems={drawerItems}>
      <Switch>
        {ROUTES.map((route, index) => (
          <Route {...route} key={`route_${index}`} />
        ))}
      </Switch>
    </MainLayout>
  );
}

export default RevisionRoutes;
