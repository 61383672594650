export const removeUserFromCognitoGroup = /* GraphQL */ `
  mutation RemoveUserFromCognitoGroup($userId: String!, $group: String!) {
    lambdaRemoveUserFromGroup(input: { username: $userId, groupName: $group }) {
      statusCode
      error
      body
      errorMessage
    }
  }
`;

export default removeUserFromCognitoGroup;
