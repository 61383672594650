import { useCallback } from "react";
import useGraphQL from "hooks/useGraphQL";
import useNotifier from "hooks/useNotifier";

import { listCompanies as listCompaniesQuery, getCompany as getCompanyQuery } from "graphql-custom/queries";
import {
  updateCompany as updateCompanyMutation,
  createCompany as createCompanyMutation,
} from "graphql-custom/mutations";

function useCompanyOperations() {
  const { runGraphQLOperation, loading } = useGraphQL();
  const { showError } = useNotifier();

  const listCompanies = useCallback(async () => {
    let companies = await runGraphQLOperation({
      operation: listCompaniesQuery,
      notifications: { errorMsg: "Ocurrio un error consultando el listado de clientes." },
    });
    companies = companies.listCompanys.items;
    return companies;
  }, [runGraphQLOperation]);

  const getCompanyById = useCallback(
    async (companyId, { successMsg, errorMsg } = {}) => {
      if (!companyId) {
        showError("Ocurrio un error en la solicitud, intentelo más tarde.");
        throw new Error("Invalid input params.");
      }
      let company = await runGraphQLOperation({
        operation: getCompanyQuery,
        variables: { id: companyId },
        notifications: { successMsg, errorMsg: errorMsg || "Ocurrio un error consultando la información del cliente." },
      });
      company = company.getCompany;
      return company;
    },
    [runGraphQLOperation, showError]
  );

  const createCompany = useCallback(
    async (input, { successMsg, errorMsg } = {}) => {
      if (!input) {
        showError("Ocurrio un error en la solicitud, intentelo más tarde.");
        throw new Error("Invalid input params.");
      }
      let companyCreated = await runGraphQLOperation({
        operation: createCompanyMutation,
        variables: { input },
        notifications: { successMsg, errorMsg },
      });
      companyCreated = companyCreated.createCompany;
      return companyCreated;
    },
    [runGraphQLOperation, showError]
  );

  const updateCompany = useCallback(
    async (input, { successMsg, errorMsg } = {}) => {
      if (!input || !input.id || !input._version) {
        showError("Ocurrio un error en la solicitud, intentelo más tarde.");
        throw new Error("Invalid input params.");
      }
      let updatedCompany = await runGraphQLOperation({
        operation: updateCompanyMutation,
        variables: { input },
        notifications: { successMsg, errorMsg },
      });
      updatedCompany = updatedCompany.updateCompany;
      return updatedCompany;
    },
    [runGraphQLOperation, showError]
  );

  return { listCompanies, getCompanyById, updateCompany, createCompany, loading };
}

export default useCompanyOperations;
