// Pantalla del formulario para agregar un nuevo material
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { Logger } from "@aws-amplify/core";

//hooks
import useNotifier from "hooks/useNotifier";

//material-ui
import { makeStyles, Grid, Typography, Box, TextField } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

//components
import CodeScanner from "components/common/CodeScanner";
import BottomPanel from "../CodeReader/BottomPanel";
import QRButtonIcon from "components/custom/QRButtonIcon";

//helpers / handlers / utils
import { fetchAssetByTrackingId } from "datastore";
import useGlobalOperations from "hooks/useGlogalOperations";
import { captureException } from "setup/sentry";

import cleanUUID from "util/cleanUUID";
import { getAssetTrackingId } from "util/text";

//constants
import { CODE_READER_STATUS } from "constant/codeReaderStatus";
import { DEFAULT_ASSET_FORM_KEYS } from "constant/ticketActivity";

const logger = new Logger("IdentifyAssetForm");

const QR_ERRORS_MSG = {
  CODE_DETECTED: "Ocurrio un error detectando el código QR.",
  INVALID_ASSET: "El código QR escaneado no corresponde a ningún activo registrado en el sistema.",
  CREATE_TASK_ASSET: "Ocurrio un error al relacionar el activo con la actividad de soporte.",
  DELETE_TASK_ASSET: "Ocurrio un error al eliminar el activo previamente añadido.",
};

function handleAssetLocation(asset, taskPremiseName) {
  let assetLocation =
    asset.embeddedAttributes?.items?.find((attribute) => attribute.id === "CONTRACT_BLUEPRINTS") || "";

  if (assetLocation && taskPremiseName) {
    assetLocation = `${taskPremiseName}, ${assetLocation}`;
  } else {
    assetLocation = taskPremiseName;
  }

  return assetLocation;
}

export default function IdentifyAssetForm({
  formMethods,
  realAssetInField,
  taskPremiseName,
  assets,
  setAssets,
  taskId,
}) {
  const { control, setValue } = formMethods;

  const classes = useStyles();
  const [showQrCodeDialog, setShowQrCodeDialog] = useState(false);
  const [codeReaderStatus, setCodeReaderStatus] = useState(CODE_READER_STATUS.STOP);
  const [codeDetected, setCodeDetected] = useState("");
  const { showError } = useNotifier();
  const { createTaskAssets, deleteTaskAssets } = useGlobalOperations();
  const realAssetTrackingId = getAssetTrackingId(realAssetInField?.asset);
  const ASSET_QR_CODE = realAssetTrackingId !== "unassigned" ? cleanUUID(realAssetTrackingId) : "QR";

  const codeReaderTitle = codeDetected ? `Activo ${cleanUUID(codeDetected)}` : "";
  const codeReaderAlertContent = !codeDetected ? "Aún no se ha detectado un QR" : "QR escaneado con éxito";
  const ASSET_ALERT_SEVERITY = !codeDetected ? "info" : "success";

  function handleCodeDetection(code, deviceInfo) {
    //en web la libreria retorna el valor de code como un objecto con la propiedad "text", pero en móvil la libreria retorna unicamente el valor escaneado.
    logger.log("handleCodeDetection: código detectado", { code, deviceInfo });
    const _codeDetected = code.hasOwnProperty("text") ? code.text : code;

    if (!_codeDetected.trim().length) {
      logger.error("fetchQrCode - Error: ocurrio un error obteniendo el código detectado", { _codeDetected });
      showError(QR_ERRORS_MSG.CODE_DETECTED);
    }

    setCodeDetected(_codeDetected);
  }

  async function fetchQrCode() {
    try {
      if (!codeDetected.trim().length) {
        logger.error("fetchQrCode - Error: ocurrio un error obteniendo el código detectado", { codeDetected });
        throw new Error(QR_ERRORS_MSG.CODE_DETECTED);
      }

      //1. obtenemos asset por qr
      const { asset, uom } = await fetchAssetByTrackingId(codeDetected);
      if (!asset || !Object.keys(asset).length) {
        logger.error("fetchQrCode - Error: el activo obtenido es invalido", { asset, uom });
        throw new Error(QR_ERRORS_MSG.INVALID_ASSET);
      }

      const assetFormated = { ...asset, uom, assetLocation: handleAssetLocation(asset, taskPremiseName) };
      //2. actualizar información en campos de formulario de presentación
      handleSetFormValues(assetFormated);

      //3. creamos relación taskAsset del activo escaneado
      const { success: succesCreated, data: newAssets } = await createTaskAssets([assetFormated.id], taskId);
      if (!succesCreated) {
        logger.error("fetchQrCode - Error: durante la creación de activo escaneado", {
          assetFormated,
          taskId,
          newAssets,
        });
        throw new Error(QR_ERRORS_MSG.CREATE_TASK_ASSET);
      }

      //4. eliminamos relación taskAsset de los activos existentes
      if (assets && assets.length) {
        const { success: successDeleted } = await deleteTaskAssets(assets, taskId);

        if (!successDeleted) {
          logger.error("fetchQrCode - Error: durante la eliminación de los activos previamente escaneados escaneado", {
            assets,
            taskId,
          });
          throw new Error(QR_ERRORS_MSG.DELETE_TASK_ASSET);
        }
      }

      setAssets(newAssets);

      //onClose codeScanner Callback it seems not to be working, so we force cleanup
      handleCloseCodeReader();
    } catch (error) {
      const ERROR_MSG = error.message;
      logger.error(error);
      setShowQrCodeDialog(false);
      showError(ERROR_MSG);
      captureException(ERROR_MSG, "IdentifyAssetForm:fetchQrCode");
      if (
        [QR_ERRORS_MSG.CODE_DETECTED, QR_ERRORS_MSG.INVALID_ASSET, QR_ERRORS_MSG.CREATE_TASK_ASSET].includes(ERROR_MSG)
      ) {
        handleCloseCodeReader();
      }
    }
  }

  function handleSetFormValues(asset = {}) {
    if (!asset || !Object.keys(asset).length) {
      logger.error("Error: el activo obtenido es invalido", { asset });
      throw new Error(QR_ERRORS_MSG.INVALID_ASSET);
    }

    setValue(DEFAULT_ASSET_FORM_KEYS.SERIAL, asset.serial || "Sin número de serie");
    setValue(DEFAULT_ASSET_FORM_KEYS.CODE, asset.code || asset.material?.code || "Sin SKU");
    setValue(DEFAULT_ASSET_FORM_KEYS.NAME, asset.name || asset.material?.name || "Sin nombre de activo");
    setValue(DEFAULT_ASSET_FORM_KEYS.ASSET_LOCATION, asset.assetLocation || "Sin ubicación registrada");
    setValue(DEFAULT_ASSET_FORM_KEYS.COMMENT, asset.comment || "Sin comentarios registrados.");
  }

  function handleOpenQrScanner() {
    setCodeReaderStatus(CODE_READER_STATUS.START);
    setShowQrCodeDialog(true);
  }

  //onClose codeScanner Callback its seems not to be working
  function handleCloseCodeReader() {
    setCodeDetected("");
    setCodeReaderStatus(CODE_READER_STATUS.STOP);
    setShowQrCodeDialog(false);
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid container item className={classes.inputAndQrContainer}>
          <Grid item md={10} xs={10} className={classes.fullWidth}>
            <Controller
              name={DEFAULT_ASSET_FORM_KEYS.SERIAL}
              control={control}
              as={
                <TextField
                  id={DEFAULT_ASSET_FORM_KEYS.SERIAL}
                  label="Número de serie"
                  placeholder="Número de serie del activo"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    readOnly: true,
                    "aria-readonly": true,
                  }}
                  className={classes.textFields}
                />
              }
            />
          </Grid>
          <Grid item md={2} xs={2} style={{ alignSelf: "flex-end" }}>
            <div className={classes.QRWrapper}>
              <Typography variant="caption">{ASSET_QR_CODE}</Typography>
              <div className={classes.readerQr}>
                <QRButtonIcon variant={"OUTLINED"} iconSize={"1.75rem"} onClick={handleOpenQrScanner} />
              </div>
            </div>
          </Grid>
        </Grid>

        <Grid item md={12} className={classes.fullWidth}>
          <Controller
            name={DEFAULT_ASSET_FORM_KEYS.CODE}
            control={control}
            as={
              <TextField
                id={DEFAULT_ASSET_FORM_KEYS.CODE}
                label="SKU"
                placeholder="SKU/Código del activo"
                variant="outlined"
                fullWidth
                InputProps={{
                  readOnly: true,
                  "aria-readonly": true,
                }}
                className={classes.textFields}
              />
            }
          />
        </Grid>

        <Grid item md={12} className={classes.fullWidth}>
          <Controller
            name={DEFAULT_ASSET_FORM_KEYS.NAME}
            control={control}
            as={
              <TextField
                id={DEFAULT_ASSET_FORM_KEYS.NAME}
                label="Nombre del activo"
                placeholder="Nombre del activo"
                variant="outlined"
                fullWidth
                InputProps={{
                  readOnly: true,
                  "aria-readonly": true,
                }}
                className={classes.textFields}
              />
            }
          />
        </Grid>

        <Grid item md={12} className={classes.fullWidth}>
          <Controller
            name={DEFAULT_ASSET_FORM_KEYS.ASSET_LOCATION}
            control={control}
            as={
              <TextField
                id={DEFAULT_ASSET_FORM_KEYS.ASSET_LOCATION}
                label="Zona"
                placeholder="Ubicación del activo"
                variant="outlined"
                InputProps={{
                  readOnly: true,
                  "aria-readonly": true,
                }}
                fullWidth
                className={classes.textFields}
              />
            }
          />
        </Grid>

        <Grid item md={12} className={classes.fullWidth}>
          <Controller
            name={DEFAULT_ASSET_FORM_KEYS.COMMENT}
            control={control}
            as={
              <TextField
                id={DEFAULT_ASSET_FORM_KEYS.COMMENT}
                label="Comentarios"
                placeholder="Comentarios"
                rows={4}
                variant="outlined"
                multiline
                fullWidth
                InputProps={{
                  readOnly: true,
                  "aria-readonly": true,
                }}
                className={classes.textFields}
              />
            }
          />
        </Grid>
      </Grid>
      <CodeScanner
        open={showQrCodeDialog}
        title={codeReaderTitle}
        showPreviewPanel
        status={codeReaderStatus}
        onClose={handleCloseCodeReader}
        onCodeDetected={handleCodeDetection}
        bottomPanel={
          <BottomPanel
            emptyCodeMsg="En espera de escaneo"
            codeDetected={codeDetected}
            disabledBtn={!codeDetected}
            fetchQrCode={fetchQrCode}
          >
            {
              <Box mb={2}>
                <Alert severity={ASSET_ALERT_SEVERITY}>{codeReaderAlertContent}</Alert>
              </Box>
            }
          </BottomPanel>
        }
      />
    </>
  );
}

IdentifyAssetForm.propTypes = {
  formMethods: PropTypes.object,
  realAssetInField: PropTypes.object,
  taskPremiseName: PropTypes.string,
  assets: PropTypes.array,
  setAssets: PropTypes.func,
  taskId: PropTypes.string,
};

IdentifyAssetForm.defaultProps = {
  formMethods: {},
  realAssetInField: {},
  taskPremiseName: "",
  assets: [],
  setAssets: () => {},
  taskId: "",
};

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: "100%",
  },
  codeReaderPanel: {
    backgroundColor: "white",
    overflow: "clip",
    display: "relative",
  },
  inputAndQrContainer: {
    "justify-content": "space-between",
    flexWrap: "nowrap",
  },
  QRWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "4px",
    marginTop: "-16px",
  },
  readerQr: {
    display: "flex",
    justifyContent: "center",
    border: "solid 1px #727070",
    height: "48px",
    maxWidth: "48px",
    borderRadius: "50px",
  },
  readerQrBtn: {
    height: "48px",
    width: "48px",
    "border-radius": "50%",
    "min-width": "auto",
  },
  disabled: {
    opacity: 0.3,
  },
  linkAsset: {
    margin: "28px 0 55px 0",
    textAlign: "center",
    fontSize: "14px",
    "& a": {
      color: "#002cca",
    },
  },
  textFields: {
    pointerEvents: "none",
  },
}));
