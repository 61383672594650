const ID = "maintenance";

const KEYS = {
  ALLOWED_USERS_TASK_WEB_FLOW: "allowed_users_task_web_flow",
};

const ENABLE_ALL = "ENABLE_ALL";

export default {
  ID,
  KEYS,
  OPTIONS: {
    ENABLE_ALL,
  },
};
