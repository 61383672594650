import ActionType from "../../action";
import initialStore from "../../initialStore";

function sessionReducer(state = initialStore.session, action) {
  switch (action.type) {
    case ActionType.SET_SESSION_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case ActionType.SIGN_IN: {
      return {
        ...state,
        ...action.payload,
        state: "signIn",
      };
    }
    case ActionType.SIGN_OUT: {
      return {
        state: "signOut",
        groups: [],
      };
    }
    default:
      return state;
  }
}

export default sessionReducer;
