import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import useOnlineStatus from "@rehooks/online-status";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import ConfirmationDialog from "components/ConfirmationDialog/ConfirmationDialog";
import { SERVICE_WORKER_ACTIVE, SERVICE_WORKER_CLEAN, SERVICE_WORKER_UPDATE_PENDING } from "redux/action";
import AppVersionContext from "context/AppVersionContext";
import useAppVersion from "hooks/useAppVersion";
import { Device } from "@capacitor/device";

export default function DialogUpdateNotification() {
  const classes = useStyles();
  const onlineStatus = useOnlineStatus();
  const serviceWorker = useSelector(({ serviceWorker }) => serviceWorker);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const { version } = useContext(AppVersionContext);
  const { updateVersion } = useAppVersion();

  useEffect(() => {
    Device.getInfo().then(({ platform }) => {
      const { swUpdated } = serviceWorker;
      if (platform === "web" && onlineStatus && swUpdated) {
        setIsOpen(true);
      }
    });
  }, [onlineStatus, serviceWorker]);

  useEffect(() => {
    if (serviceWorker.swActivated) {
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceWorker.swActivated]);

  function updateServiceWorker() {
    const registrationWaiting = serviceWorker.swRegistration?.waiting;
    if (registrationWaiting) {
      dispatch({ type: SERVICE_WORKER_UPDATE_PENDING });
      registrationWaiting.postMessage({ type: "SKIP_WAITING" });
      registrationWaiting.addEventListener("statechange", ({ target }) => {
        if (target.state === "activated") {
          dispatch({ type: SERVICE_WORKER_ACTIVE });
          updateVersion(version);
        }
      });
    } else {
      dispatch({ type: SERVICE_WORKER_CLEAN });
      setIsOpen(false);
    }
  }

  return (
    <ConfirmationDialog
      title="Actualización"
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      onConfirm={updateServiceWorker}
      loading={serviceWorker.swLoading}
      okBtnText="Actualizar"
      cancelBtnText="Omitir"
      classes={{ scrollPaper: classes.scrollPaper }}
    >
      <Box my={1}>
        <Typography variant="body1">La actualización {version} de Indika FSM se encuentra disponible.</Typography>
      </Box>
      <Box my={1}>
        <Typography variant="body1">¿Deseas actualizar en este momento?</Typography>
      </Box>
      <Box my={1}>
        <Typography variant="body1">
          <strong>Cualquier operación en curso que no haya sido guardada se perderá.</strong>
        </Typography>
      </Box>
    </ConfirmationDialog>
  );
}

const useStyles = makeStyles(() => ({
  scrollPaper: {
    alignItems: "baseline",
  },
}));
