import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";

function DataLabel({ label, data, loading, strong, inOneLine }) {
  const isText = typeof data === "string" || typeof data === "number" || typeof data === "undefined";

  if (loading) {
    return (
      <>
        <Skeleton variant="text" width={40} />
        <Skeleton variant="text" width={120} />
      </>
    );
  }

  const handleRenderData = () => {
    const fontWeight = strong ? "fontWeightBold" : "";

    if (isText) {
      if (inOneLine) {
        return (
          <>
            {" "}
            <Typography component="span" variant="body2">
              {data ? data : "Sin información"}
            </Typography>
          </>
        );
      } else {
        return (
          <Typography variant="body2">
            <Box component="span" fontWeight={fontWeight}>
              {data ? data : "Sin información"}
            </Box>
          </Typography>
        );
      }
    } else {
      return data;
    }
  };

  return (
    <>
      <Typography variant="subtitle2" component="span" color="textSecondary">
        <b>{label}</b>
      </Typography>
      {handleRenderData()}
    </>
  );
}

DataLabel.propTypes = {
  label: PropTypes.string.isRequired,
  data: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  strong: PropTypes.bool,
  inOneLine: PropTypes.bool,
};

DataLabel.defaultProps = {
  loading: false,
  strong: false,
  inOneLine: false,
};

export default DataLabel;
