import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import useGraphQL from "hooks/useGraphQL";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
// import Typography from "@material-ui/core/Typography";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Button from "components/custom/Button";
import MaterialTable from "components/custom/MaterialTable";
// import MaterialCategoriesSelector from "components/FormControls/MaterialCategoriesSelector";
// import PremisesSelector from "components/FormControls/PremisesSelector";
// import MaterialsSelector from "components/FormControls/MaterialsSelector";
import TableCellMobileFormat from "components/custom/TableCellMobileFormat";
import useTableMobileStyles from "hooks/useTableMobileStyles";

import listAssets from "./helpers/graphql/queries/listAssets";
import useLoadingStatus from "hooks/useLoadingStatus";
import useNotifier from "hooks/useNotifier";
import { formatAssetsSeries } from "util/text";
import lambdaAdvancedAssetSearch from "graphql-custom/queries/lambdaAdvancedAssetSearch";
import { WHITE_LABEL_BRANDS } from "constant/whiteLabelBrands";
import { AssetType } from "models";

const DEFAULT_FORM_VALUES = {
  premises: "",
  materialCategory: "",
  material: "",
};
export default function AssetSearchDialog({ open, onClose, updateAssetsList, excludedAssetIds, initialData }) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  // const isXSMobileScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const tableMobileStyles = useTableMobileStyles();
  const { showError, showMessage } = useNotifier();
  const { loading, runGraphQLOperation } = useGraphQL();

  const [updating, _onUpdateMyAssetList] = useLoadingStatus(onUpdateMyAssetList);
  const [cleaning /*_cleanSearch*/] = useLoadingStatus(cleanSearch);

  const [assetList, setAssetList] = useState([]);
  const [assetsSelected, setAssetsSelected] = useState([]);
  const { /*control, errors, handleSubmit,*/ reset, getValues, setValue } = useForm({
    defaultValues: DEFAULT_FORM_VALUES,
  });

  // TODO: Completar filtrado
  const availableAssets = useMemo(() => {
    if (excludedAssetIds.length) {
      return assetList.reduce((filtered, { id, ...assetData }) => {
        if (!excludedAssetIds.includes(id)) {
          filtered.push({ id, ...assetData });
        }
        return filtered;
      }, []);
    } else {
      return assetList;
    }
  }, [excludedAssetIds, assetList]);

  useEffect(() => {
    if (!updating && open) {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updating]);

  //handlers
  //fetch para listar activos
  function fetchAssets(data = initialData) {
    // filtrado mediante
    const initialDataExist = Object.keys(data).length;
    const brand = process.env.REACT_APP_WHITE_LABEL_APP;
    if (initialDataExist && data?.premise?.id && brand !== WHITE_LABEL_BRANDS.THINKSAFE) {
      setValue("premises", data?.premise?.id);
      const values = getValues();
      return runGraphQLOperation({
        operation: lambdaAdvancedAssetSearch,
        variables: { input: { premises: values.premises } },
        notifications: { errorMsg: "Error relizando el filtrado de activos. Vuelve a intentarlo" },
      }).then((result) => {
        const assetsFound = result?.lambdaAdvancedAssetSearch?.assets;
        setAssetList(assetsFound);
      });
    } else {
      return runGraphQLOperation({
        operation: listAssets,
        variables: {
          limit: 5000,
          filter: {
            and: [
              {
                type: {
                  ne: AssetType.MATERIAL_ALLOCATED,
                },
              },
              {
                type: {
                  ne: AssetType.MATERIAL_USED,
                },
              },
              {
                type: {
                  ne: AssetType.TEMPORAL,
                },
              },
            ],
          },
        },
        notifications: { errorMsg: "Error obteniendo el listado de activos, cierra y vuelve a abrir el modal" },
      }).then(({ listAssets: { items } }) => setAssetList(items));
    }
  }

  // function handleAdvancedSearch(values, event) {
  //   if ((!values.premises || initialData.premise?.id) && !values.material && !values.materialCategory)
  //     return showMessage("Selecciona por lo menos un valor para poder filtrar", {
  //       variant: "info",
  //     });
  //   runGraphQLOperation({
  //     operation: lambdaAdvancedAssetSearch,
  //     variables: { input: values },
  //     notifications: { errorMsg: "Error relizando el filtrado de activos. Vuelve a intentarlo" },
  //   }).then((result) => {
  //     const assetsFound = result?.lambdaAdvancedAssetSearch?.assets;
  //     setAssetList(assetsFound);
  //   });
  //   //realizar consulta avanzada con los valores de los filtros seleccionados
  // }

  function onAssetsSelection(assets, event) {
    setAssetsSelected(assets);
  }

  async function onUpdateMyAssetList() {
    if (!assetsSelected.length) return showError("Selecciona por lo menos un activo para continuar");

    /*remover porpiedad tableData en cada asset para evitar
     errores en la tabla principal de activos seleccionados */
    const AssetsCleaned = assetsSelected.map(({ tableData, ...asset }) => asset);
    await updateAssetsList(AssetsCleaned);
    // onClose();
  }

  async function cleanSearch() {
    const values = getValues();
    if ((!values.premises || initialData.premise?.id) && !values.material && !values.materialCategory) {
      return showMessage("No hay valores por limpar", {
        variant: "info",
      });
    }
    resetValues();
    fetchAssets(initialData);
  }

  function resetValues() {
    const values = getValues();
    if (values.premises)
      return reset({
        materialCategory: "",
        material: "",
      });
    reset({
      premises: "",
      materialCategory: "",
      material: "",
    });
  }

  return (
    <>
      <Dialog
        maxWidth="md"
        fullWidth
        fullScreen={isMobileScreen}
        open={open}
        onExited={() => {
          resetValues();
          setAssetsSelected([]);
        }}
        onEnter={() => fetchAssets(initialData)}
      >
        <DialogTitle>
          Selección de activos en: <strong>{initialData?.premise?.name}</strong>
        </DialogTitle>

        <DialogContent dividers>
          {/* <Grid container spacing={2} justify={isXSMobileScreen ? "center" : "flex-end"}>
           <Grid item xs={12}>
              <Typography variant="h6">Filtros de búsqueda</Typography>
            </Grid>
            {/* <Grid item xs={12} md={4} lg={3}>
              <PremisesSelector
                name="premises"
                control={control}
                errors={errors}
                defaultValue={initialData?.premise?.id ? initialData?.premise?.id : ""}
                disabled={initialData?.premise?.id ? true : false}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <MaterialsSelector
                initialAssetsList={availableAssets.map((asset) => ({ name: asset?.material?.name, ...asset }))}
                name="material"
                label="Nombre del activo"
                control={control}
                errors={errors}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <MaterialCategoriesSelector
                name="materialCategory"
                label="Categoría del activo"
                control={control}
                errors={errors}
              />
            </Grid>
            <Grid container spacing={2} alignItems="flex-end" justify="flex-end" item lg={4} md={4} sm={6} xs={12}>
              <Grid item xs={6}>
                <Button
                  id="filter-advanced-asset-search-btn"
                  color="primary"
                  onClick={handleSubmit(handleAdvancedSearch)}
                  variant="contained"
                  disabled={loading || cleaning || updating}
                  style={{ width: "100%" }}
                >
                  Filtrar
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  color="default"
                  onClick={_cleanSearch}
                  variant="outlined"
                  disabled={cleaning || loading || updating}
                  style={{ width: "100%" }}
                  id="clean-search-btn"
                >
                  Limpiar
                </Button>
              </Grid>
            </Grid>
          </Grid>  */}

          <Box /*mt={3}*/>
            <div id="advanced-asset-search-table" className={tableMobileStyles.root}>
              <MaterialTable
                title="Activos"
                options={TABLE_OPTIONS}
                columns={isMobileScreen ? TABLE_COLUMNS_MOBILE : TABLE_COLUMNS_DESKTOP}
                onSelectionChange={onAssetsSelection}
                data={availableAssets}
                isLoading={loading || updating}
              />
            </div>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            id="close-advanced-search-btn"
            color="default"
            onClick={onClose}
            disabled={updating || loading || cleaning}
          >
            Cancelar
          </Button>
          <Button
            id="advanced-search-continue-btn"
            color="primary"
            onClick={_onUpdateMyAssetList}
            disabled={updating || loading || cleaning}
          >
            Agregar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

AssetSearchDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  updateAssetsList: PropTypes.func,
  excludedAssetIds: PropTypes.array,
  initialData: PropTypes.object,
};

AssetSearchDialog.defaultProps = {
  open: false,
  updateAssetsList: () => { },
  onClose: () => { },
  excludedAssetIds: [],
  initialData: {},
};

const TABLE_OPTIONS = {
  exportButton: false,
  columnsButton: false,
  selection: true,
};

const TABLE_COLUMNS_DESKTOP = [
  { title: "Código", field: "material.code" },
  { title: "Nombre", field: "material.name" },
  { title: "Serie", field: "serial", render: (rowData) => formatAssetsSeries(rowData.serial) },
  // { title: "Lugar", field: "locationCode" },
  // { title: "Categoría", field: "material.category.name" },
];

/* eslint-disable react/display-name */
const TABLE_COLUMNS_MOBILE = [
  {
    title: "Detalles",
    field: "id",
    render: (rowData) => {
      const columns = [
        {
          title: "Código",
          description: rowData?.material?.code,
        },
        {
          title: "Nombre",
          description: rowData?.material?.name,
        },
        {
          title: "Serie",
          description: formatAssetsSeries(rowData?.serial),
        },
      ];

      return <TableCellMobileFormat columns={columns} sm={6} xs={12} />;
    },
    customFilterAndSearch: (value, rowData) => {
      const parsedValue = value.toLowerCase();
      return (
        rowData?.material?.code?.toLowerCase().includes(parsedValue) ||
        rowData?.material?.name?.toLowerCase().includes(parsedValue) ||
        rowData?.serial?.toLowerCase().includes(parsedValue)
      );
    },
  },
];
