const createTimerEvent = /* GraphQL */ `
  mutation CreateTimerEvent($input: CreateTimerEventInput!) {
    createTimerEvent(input: $input) {
      taskID
      id
      finalized
      eventCauseID
      duration
      description
      timestamp
      createdAt
      _version
      _lastChangedAt
      _deleted
    }
  }
`;

export default createTimerEvent;
