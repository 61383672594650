import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
// Imports locales
import GroupRestrictedView from "components/common/GroupRestrictedView";
import Section from "components/Section";
import DataLabel from "components/DataLabel";
import DownloadQRButton from "components/common/DownloadQRButton";

import { formatAssetsSeries } from "util/text";
import cleanUUID from "util/cleanUUID";
import { handleGetName } from "util/text";
import * as ROLES from "constant/roles";
import { ASSET_MODE } from "constant/ticketConstants";

/**
 * Verifica si un activo tiene asignado un código QR
 * @returns
 */
function hasQRAssigned(asset) {
  return asset?.trackingID && asset?.trackingID !== "unassigned";
}

const CARD_TITLE = {
  [ASSET_MODE.TASK_ASSET]: "Activo Validado en Campo",
  [ASSET_MODE.REPORTED_ASSET]: "Activo reportado",
};

function TicketAssetCard({
  asset,
  assetWarranty,
  assetClientWarranty,
  userType,
  isFetching,
  onGenerateOrDownloadAssetQR,
  mode,
}) {
  const qrDownloadBtn = hasQRAssigned(asset) ? cleanUUID(asset?.trackingID) : "Vincular QR";
  const handleRenderContent = () => {
    if (!asset || !Object.keys(asset).length) {
      return (
        <Box padding="8px">
          <Typography>
            {mode === ASSET_MODE.REPORTED_ASSET
              ? "Sin información. No se reporto ningún activo."
              : "Sin información. No se ha validado algún activo en campo."}
          </Typography>
        </Box>
      );
    }

    //renderiza información del activo escaneado en campo
    if (mode === ASSET_MODE.TASK_ASSET) {
      return (
        <>
          <Grid item xs={12} sm={6} md={3}>
            <DataLabel label="Nombre del activo" data={handleGetName(asset)} loading={isFetching} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <DataLabel label="Número de serie" data={formatAssetsSeries(asset?.serial)} loading={isFetching} />
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <DataLabel
              label="QR Vinculado"
              data={
                <DownloadQRButton
                  assetID={asset?.id}
                  content={qrDownloadBtn}
                  afterDownload={onGenerateOrDownloadAssetQR}
                />
              }
              loading={isFetching}
            />
          </Grid>
          <GroupRestrictedView group={[ROLES.SUPERVISORS, ROLES.SUPPORT_ENGINEERS]}>
            {(isAllowed) => {
              if (!isAllowed || userType === ROLES.SERVICE_USERS) return null;
              return (
                <Grid item xs={6} sm={6} md={3}>
                  <DataLabel
                    label="Fecha límite de garantía (interna)"
                    data={assetWarranty || "Sin asignación"}
                    loading={isFetching}
                  />
                </Grid>
              );
            }}
          </GroupRestrictedView>
          <Grid item xs={6} md={3}>
            <DataLabel
              label="Fecha límite de garantía"
              data={assetClientWarranty || "Sin asignación"}
              loading={isFetching}
            />
          </Grid>
        </>
      );
    }

    //renderiza información del activo reportado
    if (mode === ASSET_MODE.REPORTED_ASSET) {
      return (
        <>
          <Grid item xs={12} sm={6} md={3}>
            <DataLabel label="Nombre" data={asset?.name || "Sin nombre asignado"} loading={isFetching} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <DataLabel label="Serial" data={asset?.serial || "Sin número de serie asignado"} loading={isFetching} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <DataLabel label="Ubicación" data={asset?.location || "Sin ubicación registrada"} loading={isFetching} />
          </Grid>
        </>
      );
    }
  };

  return (
    <Box mt={3}>
      <Section>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">{CARD_TITLE[mode]}</Typography>
            </Grid>
            {handleRenderContent()}
          </Grid>
        </CardContent>
      </Section>
    </Box>
  );
}

TicketAssetCard.propTypes = {
  asset: PropTypes.shape(),
  assetClientWarranty: PropTypes.string,
  assetWarranty: PropTypes.string,
  userType: PropTypes.oneOf(Object.values(ROLES)).isRequired,
  isFetching: PropTypes.bool,
  onGenerateOrDownloadAssetQR: PropTypes.func,
  mode: PropTypes.oneOf(Object.values(ASSET_MODE)),
};

TicketAssetCard.defaultProps = {
  asset: null,
  assetClientWarranty: null,
  assetWarranty: null,
  isFetching: false,
  onGenerateOrDownloadAssetQR: () => {},
  mode: ASSET_MODE.REPORTED_ASSET,
};

export default TicketAssetCard;
