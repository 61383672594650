/**
 * Este reducer maneja el estado para los componentes del catalogo de categorias de materiales
 */

import {
  FETCH_MATERIAL_CATEGORY_CATALAOG_PENDING,
  FETCH_MATERIAL_CATEGORY_CATALAOG_ERROR,
  FETCH_MATERIAL_CATEGORY_CATALAOG_SUCCESS,
} from "redux/action";

import { REQUEST_STATUS } from "constant/requestStatus";
import { filterDeletedItems } from "util/lists";

const INITIAL_STATE = {
  materialCategories: [],
  fetchStatus: REQUEST_STATUS.INACTIVE,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_MATERIAL_CATEGORY_CATALAOG_PENDING:
      return { ...state, fetchStatus: REQUEST_STATUS.PENDING };
    case FETCH_MATERIAL_CATEGORY_CATALAOG_SUCCESS:
      const { items = [] } = action.payload?.listMaterialCategorys;
      const categoryList = filterDeletedItems(items);
      return { ...state, materialCategories: categoryList, fetchStatus: REQUEST_STATUS.SUCCESSFUL };
    case FETCH_MATERIAL_CATEGORY_CATALAOG_ERROR:
      return { ...state, fetchStatus: REQUEST_STATUS.FAILED, materialCategories: [] };
    default:
      return state;
  }
}
