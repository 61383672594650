// TODO: Remove this legacy structure
export const app = {
  theme: {
    backgroundImg: "",
    primaryColor: "#000000",
    secondaryColor: "#ff0000",
    orgLogo: "/img/indika-color-logo.png",
    drawerLogo: "",
    appBarLogo: "",
  },
  title: "INDIKA FSM",
  warehouses: [],
  networkStatus: "",
  pendingDataToSycn: false,
  showGeneralLoader: false,
  dataStoreStatus: "UNSYNCED", // "UNSYNCED"  "IN_PROGRESS" "SYNCED"
  dataStoreModels: [],
  dataStoreModelsSynced: [],
  version: "0.0.0",
};

export const session = {
  wh: "",
  userId: "",
  username: "",
  orgId: "",
  groups: [],
};

export const operation = {
  queue: [],
  actions: {},
};

export const systemConfigs = {
  drawerLogo: "",
  appBarLogo: "",
  safetyExpirationDate: null,
  support: {
    asset_creation_required_fields: {
      id: "support",
      key: "asset_creation_required_fields",
      value: "{ name: true, serial: true, code: false, location: false }",
    },
    asset_creation: {
      id: "support",
      key: "asset_creation",
      value: false,
    },
    ticket_cancellation: {
      id: "support",
      key: "ticket_cancellation",
      value: true,
    },
    allowed_users_task_web_flow: {
      id: "maintenance",
      key: "allowed_users_task_web_flow",
      value: [],
    },
  },
};

export const theme = {
  primaryColor: "#000000",
  secondaryColor: "#ff0000",
};

export const device = {
  diskFree: 0,
  appVersion: "",
  appBuild: "",
  appId: "",
  appName: "",
  operatingSystem: "",
  osVersion: "",
  platform: "",
  memUsed: 0,
  diskTotal: 0,
  model: "",
  manufacturer: "",
  uuid: "",
  isVirtual: false,
};

export default {
  app,
  session,
  operation,
  theme,
  systemConfigs,
  device,
};
