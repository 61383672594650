import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Logger } from "@aws-amplify/core";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionActions from "@material-ui/core/AccordionActions";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import DialogContentText from "@material-ui/core/DialogContentText";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/custom/Button";
import ConfirmationDialog from "components/ConfirmationDialog";
import useLoadingStatus from "hooks/useLoadingStatus";
import useBooleanFlag from "hooks/useBooleanFlag";
import useNotifier from "hooks/useNotifier";
import LoadingPanel from "components/common/LoadingPanel";
import * as graphql from "../helpers/graphql";
const logger = new Logger("RequestSection");

const DEFAULT_REQUEST_DATA = {
  id: "",
  email: "",
  firstname: "",
  middlename: "",
  lastnamep: "",
  lastnamem: "",
  _version: "",
};

const DEFAULT_REQUEST_FORM_DATA = { organization: "", comments: "" };

const notEmpty = (value) => value?.trim() !== "" || "Campo obligatorio";
const INPUT_PROPS = { readOnly: true };
const TEXT_RULES = {
  required: "Campo obligatorio",
  maxLength: { value: 50, message: "Máximo 50 caracteres" },
  validate: { notEmpty },
};
const COMMENTS_RULES = { maxLength: { value: 500, message: "Máximo 500 caracteres" } };

const ACTION = { SEND_REQUEST: "SEND_REQUEST", CANCEL_REQUEST: "CANCEL_REQUEST" };

function RequestSection({ classes, integrationEnabled, ...props }) {
  const userId = useSelector(({ session }) => session.userId);
  const [loadingSettings, _loadRequest] = useLoadingStatus(loadRequest);
  const [sendingRequest, _sendRequest] = useLoadingStatus(sendRequest);
  const [cancelingRequest, _cancelRequest] = useLoadingStatus(cancelRequest);
  const [cancelRequestBtnVisible, setCancelRequestBtnVisible] = useState(false);
  const notifier = useNotifier();
  const requestForm = useForm({ mode: "onChange", defaultValues: DEFAULT_REQUEST_FORM_DATA });
  const [reqConfirmOpen, openRequestDialog, closeRequestDialog] = useBooleanFlag();
  const [successDialogOpen, openSuccessDialog, closeSuccessDialog] = useBooleanFlag();
  const [cancelDialogOpen, openCancelDialog, closeCancelDialog] = useBooleanFlag();
  const requestFormData = requestForm.getValues();
  const [succededAction, setSuccededAction] = useState(null);
  const [requestData, setRequestData] = useState(DEFAULT_REQUEST_DATA);

  useEffect(() => {
    _loadRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function loadRequest() {
    try {
      logger.debug("loadRequest: Consultando por solicitudes existentes...");
      const currentRequest = await graphql.fetchOpenIntegrationRequest();
      logger.debug("loadRequest: Consultando consulta terminada...");

      if (currentRequest) {
        logger.debug("loadRequest: Se cuenta con una solicitud pendiente...");
        setRequestData({
          id: currentRequest.id,
          _version: currentRequest._version,
          email: currentRequest.email || "",
          firstname: currentRequest.firstname || "",
          middlename: currentRequest.middlename || "",
          lastnamep: currentRequest.lastnamep || "",
          lastnamem: currentRequest.lastnamem || "",
        });
        requestForm.setValue("organization", currentRequest.organization);
        requestForm.setValue("comments", currentRequest.comments);
        logger.debug("loadRequest: Consultando configuración actual...");

        setCancelRequestBtnVisible(true);
      } else {
        await fetchUserData();
      }
    } catch (error) {
      logger.error("loadRequest: Error al consultar solicitud ", error);
      notifier.showError("Ocurrió un error inesperado. Revisar consola.");
      // TODO: Cubrir errores típicos de los servicios de esta integración.
    }
  }

  async function fetchUserData() {
    logger.debug("fetchUserData: No se cuenta con una solicitud pendiente...");
    logger.debug("fetchUserData: Obteniendo información de usuario...");
    const user = await graphql.fetchUser(userId);
    logger.debug("fetchUserData: Información de usuario obtenida...");
    setCancelRequestBtnVisible(false);
    setRequestData({
      email: user.email || "",
      firstname: user.name || "",
      middlename: user.middlename || "",
      lastnamep: user.lastnameP || "",
      lastnamem: user.lastnameM || "",
    });
  }

  async function sendRequest() {
    const newRequest = {
      ...requestData,
      ...requestForm.getValues(),
    };

    logger.debug("sendRequest: Información a enviar...", newRequest);

    try {
      logger.debug("sendRequest: Enviando solicitud...");
      const requestResult = await graphql.sendIntegrationRequest(newRequest);
      logger.debug("sendRequest: Solicitud enviada exitósamente...");
      setRequestData({
        ...requestData,
        id: requestResult.id,
        _version: requestResult._version,
      });
      closeRequestDialog();
      openSuccessDialog();
      setCancelRequestBtnVisible(true);
      setSuccededAction(ACTION.SEND_REQUEST);
    } catch (error) {
      logger.error("sendRequest: Error enviando solicitud ", error);
      notifier.showError("Ocurrió un error inesperado. Revisar consola.");
      // TODO: Cubrir errores típicos de los servicios de esta integración.
    }
  }

  async function cancelRequest() {
    try {
      logger.debug("cancelRequest: Cancelando solicitud...");
      await graphql.cancelIntegrationRequest(requestData.id, requestData._version);
      logger.debug("cancelRequest: Solicitud cancelada exitósamente...");
      await fetchUserData();
      // TODO: Agregar cancelación en ME
      requestForm.reset(DEFAULT_REQUEST_FORM_DATA);
      closeCancelDialog();
      openSuccessDialog();
      setCancelRequestBtnVisible(false);
      setSuccededAction(ACTION.CANCEL_REQUEST);
    } catch (error) {
      logger.error("cancelRequest: Error cancelando solicitud ", error);
      notifier.showError("Ocurrió un error inesperado. Revisar consola.");
      // TODO: Cubrir errores típicos de los servicios de esta integración.
    }

    return;
  }

  async function handleRequestBtnClick() {
    const valid = await requestForm.trigger();
    if (valid) openRequestDialog();
  }

  async function handleCancelBtnClick() {
    openCancelDialog();
  }

  const editableInputProps = useMemo(() => ({ readOnly: cancelRequestBtnVisible }), [cancelRequestBtnVisible]);

  return (
    <LoadingPanel show={loadingSettings}>
      <AccordionDetails className={classes.accordionDetails}>
        <Typography variant="h6" paragraph>
          Solicitud de Integración
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              label="Correo de solicitud"
              fullWidth
              variant="outlined"
              size="small"
              helperText="Este correo recibirá la información para la integración"
              disabled={!integrationEnabled}
              value={requestData.email}
              inputProps={INPUT_PROPS}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={requestForm.control}
              as={TextField}
              label="Organización"
              variant="outlined"
              name="organization"
              size="small"
              fullWidth
              required
              disabled={!integrationEnabled}
              value={requestFormData.organization}
              rules={TEXT_RULES}
              error={!!requestForm.errors.organization}
              helperText={requestForm.errors.organization?.message}
              inputProps={editableInputProps}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              label="Nombre"
              variant="outlined"
              size="small"
              fullWidth
              disabled={!integrationEnabled}
              value={requestData.firstname}
              inputProps={INPUT_PROPS}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              label="Segundo Nombre"
              variant="outlined"
              size="small"
              fullWidth
              disabled={!integrationEnabled}
              value={requestData.middlename}
              inputProps={INPUT_PROPS}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              label="Apellido Paterno"
              variant="outlined"
              size="small"
              fullWidth
              disabled={!integrationEnabled}
              value={requestData.lastnamep}
              inputProps={INPUT_PROPS}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              label="Apellido Materno"
              variant="outlined"
              size="small"
              fullWidth
              disabled={!integrationEnabled}
              value={requestData.lastnamem}
              inputProps={INPUT_PROPS}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={requestForm.control}
              as={TextField}
              label="Comentarios"
              name="comments"
              variant="outlined"
              size="small"
              fullWidth
              multiline
              rows={4}
              disabled={!integrationEnabled}
              value={requestFormData.comments}
              rules={COMMENTS_RULES}
              error={!!requestForm.errors.comments}
              helperText={requestForm.errors.comments?.message}
              inputProps={editableInputProps}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
      <AccordionActions>
        <Button
          color={"secondary"}
          variant="contained"
          disableElevation
          disabled={!integrationEnabled || loadingSettings || sendingRequest}
          visible={cancelRequestBtnVisible}
          onClick={handleCancelBtnClick}
        >
          CANCELAR SOLICITUD
        </Button>
        <Button
          color="primary"
          variant="contained"
          disableElevation
          disabled={!integrationEnabled || loadingSettings || sendingRequest}
          visible={!cancelRequestBtnVisible}
          onClick={handleRequestBtnClick}
        >
          SOLICITAR SERVICIO
        </Button>
      </AccordionActions>
      <ConfirmationDialog
        open={reqConfirmOpen}
        title="Confirmación"
        onCancel={closeRequestDialog}
        onConfirm={_sendRequest}
        loading={sendingRequest}
      >
        <DialogContentText>
          Se enviará su solicitud de integración al servicio de Manage Engine. ¿Desea continuar?
        </DialogContentText>
      </ConfirmationDialog>
      <ConfirmationDialog
        open={cancelDialogOpen}
        title="Cancelar Solicitud"
        onCancel={closeCancelDialog}
        onConfirm={_cancelRequest}
        loading={cancelingRequest}
      >
        <DialogContentText>
          Se cancelará su solicitud de integración al servicio de Manage Engine. ¿Desea continuar?
        </DialogContentText>
      </ConfirmationDialog>
      <Dialog open={successDialogOpen}>
        <DialogTitle>
          {succededAction === ACTION.SEND_REQUEST && "¡Solicitud Enviada!"}
          {succededAction === ACTION.CANCEL_REQUEST && "Solicitud Cancelada"}
        </DialogTitle>
        <DialogContent dividers>
          {succededAction === ACTION.SEND_REQUEST && (
            <>
              <DialogContentText>Su solicitud se ha enviado al equipo Indika360.</DialogContentText>
              <DialogContentText>
                Al aprobarse se enviarán por correo electrónico los datos requeridos para la integración.
              </DialogContentText>
            </>
          )}
          {succededAction === ACTION.CANCEL_REQUEST && (
            <DialogContentText>Su solicitud se ha cancelado exitósamente.</DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button color="default" onClick={closeSuccessDialog}>
            CERRAR
          </Button>
        </DialogActions>
      </Dialog>
    </LoadingPanel>
  );
}

RequestSection.propTypes = {
  classes: PropTypes.shape(),
  integrationEnabled: PropTypes.bool,
};

export default RequestSection;
