import ActionType from "../../action";
import initialStore from "../../initialStore";

function operationReducer(state = initialStore.operation, action) {
  switch (action.type) {
    case ActionType.ENQUEUE_OPERATION: {
      return {
        ...state,
        ...action.payload
      };
    }
    case ActionType.DEQUEUE_OPERATION: {
      return {
        ...state,
        ...action.payload
      };
    }
    default:
      return state;
  }
}

export default operationReducer;
