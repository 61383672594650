import { useState, useCallback } from "react";

const STEP_INTERFACE = {
  step: 0,
  stepKeyname: "UNDEFINED",
  isStepCompleted: false,
  isLastStep: false,
};

const DEFAULT_INITIAL_STATE_INTERFACE = {
  stepsState: [STEP_INTERFACE],
  currentStepState: STEP_INTERFACE,
};

const useStepper = (initialState = DEFAULT_INITIAL_STATE_INTERFACE) => {
  const [stepsState, setStepsState] = useState(initialState);

  const handleNext = useCallback(() => {
    setStepsState(({ stepsState, currentStepState }) => {
      let nextStep = stepsState[currentStepState.step + 1];
      let isNextStepCompleted = nextStep.isStepCompleted;

      //si el siguiente paso no esta completado, entonces ahora es el último paso
      if (!isNextStepCompleted) {
        nextStep.isLastStep = true;
      }

      //actualizamos el valor del último paso en el arreglo "stepsState"
      stepsState[nextStep.step] = nextStep;

      //actualizamos el valor actual para que ya no sea el último paso
      stepsState[currentStepState.step] = {
        ...stepsState[currentStepState.step],
        isLastStep: false,
        isStepCompleted: true,
      };
      return {
        stepsState,
        currentStepState: nextStep,
      };
    });
  }, []);

  const handleBack = useCallback(() => {
    setStepsState(({ stepsState, currentStepState }) => {
      currentStepState = stepsState[currentStepState.step - 1];
      return {
        stepsState,
        currentStepState,
      };
    });
  }, []);

  return {
    stepsState,
    setStepsState,
    handleNext,
    handleBack,
  };
};

export default useStepper;
