import { useCallback, useMemo } from "react";
import { Device, DeviceInfo } from "@capacitor/device";
import { useDispatch, useSelector } from "react-redux";
import * as ActionTypes from "redux/action";

export default function useDeviceSettings() {
  const dispatch = useDispatch();
  const deviceInfo = useSelector((state: { device: DeviceInfo }) => state.device);

  const setInfo = useCallback(async () => {
    const info = await Device.getInfo();
    dispatch({
      type: ActionTypes.SET_DEVICE_INFO,
      payload: { device: info },
    });
  }, [dispatch]);

  const resetInfo = useCallback(() => {
    dispatch({
      type: ActionTypes.RESET_DEVICE_INFO,
    });
  }, [dispatch]);

  const getInfo = useCallback(() => {
    return deviceInfo;
  }, [deviceInfo]);

  return useMemo(
    () => ({
      setInfo,
      resetInfo,
      getInfo,
    }),
    [setInfo, resetInfo, getInfo]
  );
}
