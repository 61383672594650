export const getUserTeam = /* GraphQL */ `
  query GetUserTeam($filter: ModelTeamFilterInput, $limit: Int, $nextToken: String) {
    listTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        _version
        _deleted
      }
      nextToken
    }
  }
`;
