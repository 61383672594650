/* eslint-disable react/display-name */
import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import CheckIcon from "@material-ui/icons/Check";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import FilterListIcon from "@material-ui/icons/FilterList";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import SearchIcon from "@material-ui/icons/Search";
import RemoveIcon from "@material-ui/icons/Remove";
import ViewColumnIcon from "@material-ui/icons/ViewColumn";
import EditIcon from "@material-ui/icons/Edit";
import ClearIcon from "@material-ui/icons/Clear";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import DeleteIcon from "@material-ui/icons/Delete";
import AddBoxIcon from "@material-ui/icons/AddBox";
import AttachmentIcon from "@material-ui/icons/Attachment";

import Paper from "@material-ui/core/Paper";

import MaterialTable from "material-table";
import CustomAction from "./CustomAction";
import Box from "@material-ui/core/Box";
import TablePagination from "@material-ui/core/TablePagination";
import { makeStyles } from "@material-ui/core";
import { checkLastPage } from "util/lists";
import { PAGE_SIZE_OPTIONS } from "../../../util/homologationOfColumns";

const icons = {
  Add: AddBoxIcon,
  Check: CheckIcon,
  Clear: ClearIcon,
  ResetSearch: ClearIcon,
  Delete: () => <DeleteIcon color="action" />,
  Export: SaveAltIcon,
  Filter: FilterListIcon,
  Edit: () => <EditIcon color="action" />,
  FirstPage: FirstPageIcon,
  LastPage: LastPageIcon,
  NextPage: ChevronRightIcon,
  PreviousPage: ChevronLeftIcon,
  Search: SearchIcon,
  ThirdStateCheck: RemoveIcon,
  ViewColumn: ViewColumnIcon,
  DetailPanel: ChevronRightIcon,
  SortArrow: ArrowUpwardIcon,
  Attachment: AttachmentIcon,
};

const localizationData = {
  header: { actions: "Acciones" },
  body: {
    addTooltip: "Agregar material",
    filterRow: {},
    editRow: {
      deleteText: "¿Seguro que desea eliminar esta fila?",
      saveTooltip: "Guardar",
      cancelTooltip: "Cancelar",
    },
    emptyDataSourceMessage: "Sin elementos que mostrar",
  },
  toolbar: {
    searchTooltip: "Buscar",
    searchPlaceholder: "Buscar",
    nRowsSelected: "{0} filas seleccionadas",
    showColumnsTitle: "Mostrar columnas",
    showColumnsAriaLabel: "Mostrar columnas",
    exportTitle: "Exportar",
    exportAriaLabel: "Exportar",
    exportName: "Exportar a CSV",
  },
  pagination: {
    previousAriaLabel: "Anterior",
    previousTooltip: "Anterior",
    nextAriaLabel: "Siguiente",
    nextTooltip: "Siguiente",
    firstAriaLabel: "Primera Página",
    firstTooltip: "Primera Página",
    lastAriaLabel: "Última Página",
    lastTooltip: "Última Página",
    labelDisplayedRows: "{from}-{to} de {count}",
    labelRowsSelect: "filas",
  },
};

const staticMaterialTableOptions = {
  columnsButton: true,
  exportButton: true,
};

function MaterialTableCustom({ actions, localization, customPagination, ...props }) {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [firstLoad, setFirstLoad] = useState(true);

  let actionsC = actions.map((action) => {
    let a = action;
    if (a.icon === "attachment")
      a = {
        tooltip: "Archivos adjuntos",
        ...a,
        icon: AttachmentIcon,
      };

    if (typeof a.visible === "function")
      return (rowData) => {
        if (a.visible(rowData)) return a;
      };

    return a;
  });

  const localizationMemoized = useMemo(
    () => ({
      ...localizationData,
      ...localization,
      body: {
        ...localizationData.body,
        ...localization.body,
        filterRow: {
          ...localizationData.body.filterRow,
          ...(localization.body || {}).filterRow,
        },
        editRow: {
          ...localizationData.body.editRow,
          ...(localization.body || {}).editRow,
        },
        grouping: {
          ...localizationData.grouping,
          ...localization.grouping,
        },
        header: {
          ...localizationData.header,
          ...localization.header,
        },
        pagination: {
          ...localizationData.pagination,
          ...localization.pagination,
        },
        toolbar: {
          ...localizationData.toolbar,
          ...localization.toolbar,
        },
      },
    }),
    [localization]
  );

  const componentesMemoized = useMemo(() => {
    const components = {
      Container: (props) => <Paper {...props} elevation={0} />,
      Action: (props) => <CustomAction {...props} />,
    };
    if (customPagination) {
      components.Pagination = ({ ...pagProps }) => {
        const currentPage = firstLoad ? page : page - 1;
        const hasRemainingItems = checkLastPage(currentPage, props.data.length, pagProps.rowsPerPage);
        return (
          <TablePagination
            {...pagProps}
            localization={localizationMemoized.pagination}
            count={hasRemainingItems ? (page + 2) * pagProps.rowsPerPage : props.data.length}
            page={currentPage}
            onChangePage={(e, newPage) => {
              if (newPage > 0 && firstLoad) {
                setFirstLoad(false);
              }
              pagProps.onChangePage(e, newPage);
              setPage(newPage + 1);
            }}
          />
        );
      };
    }
    return components;
  }, [customPagination, localizationMemoized.pagination, firstLoad, page, props.data.length]);

  return (
    <Box className={customPagination ? classes.root : ""}>
      <MaterialTable
        {...props}
        icons={icons}
        localization={localizationMemoized}
        components={componentesMemoized}
        options={{
          showTitle: props.title ? true : false,
          exportAllData: true,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
          pageSize: PAGE_SIZE_OPTIONS[0],
          maxBodyHeight: "calc(100vh - 160px - 118px)",
          ...staticMaterialTableOptions,
          ...props.options,
          ...(customPagination ? { showFirstLastPageButtons: false } : {}),
        }}
        actions={actionsC}
      />
    </Box>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiTypography-caption": {
      display: "none",
    },
  },
}));

MaterialTableCustom.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  actions: PropTypes.array,
  options: PropTypes.object,
  localization: PropTypes.object,
  customPagination: PropTypes.bool,
};

MaterialTableCustom.defaultProps = {
  title: undefined,
  data: [],
  actions: [],
  options: {},
  localization: {},
  customPagination: false,
};

export default React.memo(MaterialTableCustom);
