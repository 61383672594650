import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import TabContainer from "components/Tabs/TabContainer";
import { styleStatus, getStyleForTab, MODULES, SECTIONS } from "../../../util/homologationOfColumns";
function StyledTabContainer(props) {
  const DEFAULT_TAB_STYLES = useStyles();
  const DEFAULT_SELECTED_TAB_STYLES = useStylesSelected();
  const { tabData, stylesTabs = DEFAULT_TAB_STYLES, stylesSelected = DEFAULT_SELECTED_TAB_STYLES } = props;

  return <TabContainer tabData={tabData} stylesTabs={stylesTabs} stylesSelected={stylesSelected} />;
}

StyledTabContainer.propTypes = {
  tabData: PropTypes.array,
  stylesTabs: PropTypes.func,
  stylesSelected: PropTypes.func,
  onChangeTab: PropTypes.func,
};

StyledTabContainer.defaultProps = {
  onChangeTab: () => {},
};

// CUSTOM STYLES
const useStyles = makeStyles((theme) => styleStatus(SECTIONS.SUPPORT, MODULES.FIELDWORK));

// CUSTOM SELECTED STYLES
const useStylesSelected = makeStyles(getStyleForTab(SECTIONS.SUPPORT, MODULES.FIELDWORK));

export default StyledTabContainer;
