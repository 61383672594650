export const integrationRequestByIntegrationID = /* GraphQL */ `
  query integrationRequestByIntegrationID(
    $integrationID: ID!
    $filter: ModelIntegrationRequestFilterInput
    $nextToken: String
  ) {
    integrationRequestByIntegrationID(integrationID: $integrationID, filter: $filter, nextToken: $nextToken) {
      items {
        id
        integrationID
        status
        email
        firstname
        middlename
        lastnamep
        lastnamem
        organization
        comments
        createdAt
        _version
        _deleted
      }
    }
  }
`;
