// Componente container para la vista de instalaciones del modulo de validación
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import useNotifier from "hooks/useNotifier";

import { API, graphqlOperation } from "@aws-amplify/api";
import { Logger } from "@aws-amplify/core";
import * as queries from "graphql-custom/queries";
import * as mutations from "graphql-custom/mutations";

import useHeaderTitle from "hooks/useHeaderTitle";
import useBooleanFlag from "hooks/useBooleanFlag";

import { FORM_MODES } from "constant/formModes";
import ValidatorInstallationDetailsViewUI from "./ValidatorInstallationDetailsView.ui";
import { filterDeletedItems } from "util/lists";
import { AssetType } from "models";

const SECTION_TITLE = "Validación de Instalaciones";

const logger = new Logger("ValidatorInstallationDetailsView");

function filterNullAssets(list = []) {
  return list.reduce((taskAssets, current) => {
    if (!!current.asset) {
      taskAssets.push({ ...current });
    } else {
      logger.warn(`Relación TaskAsset (${current.id}) incompleta, no existe Asset asociado`);
    }
    return taskAssets;
  }, []);
}

function ValidatorInstallationDetailsView() {
  const userId = useSelector(({ session }) => session.userId);
  const [installationDetails, setInstallationDetails] = useState([]);
  const [installationUsers, setInstallationUsers] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [observations, setObservations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [installationDialogOpen, setInstallationDialogOpen] = useState(false);
  const [validateInstallationDialogOpen, setValidateInstallationDialogOpen] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const { id } = useParams();
  const history = useHistory();
  const { showError, showMessage } = useNotifier();
  useHeaderTitle(SECTION_TITLE);
  useEffect(() => {
    fetchInstallation();
  }, []);

  const [attachmentsDialogOpen, openAttachmentDialog, closeAttachmentDialog] = useBooleanFlag();

  function fetchInstallation() {
    setIsLoading(true);
    API.graphql(graphqlOperation(queries.getTask, { id }))
      .then((res) => {
        const task = res.data.getTask;
        let materialsArray = filterNullAssets(task.assets?.items);
        materialsArray = filterDeletedItems(materialsArray);
        if (task.status !== "SCHEDULED" && task.status !== "IN_PROGRESS") {
          materialsArray = materialsArray.filter(
            (m) => m?.asset?.type !== AssetType.ALLOCATED || m?.asset?.type !== AssetType.MATERIAL_ALLOCATED
          );
        }
        task.assets.items = materialsArray;
        const observationsArray = task.notes ? task.notes.items : [];
        if (task?.users?.items.length > 0) {
          setInstallationUsers(task?.users?.items.filter((u) => !u._deleted));
        }
        setInstallationDetails(task);
        setObservations(observationsArray);
        setMaterials(materialsArray);
        setIsLoading(false);
      })
      .catch((err) => {
        console.debug(err);
        setIsLoading(false);
      });
  }

  function handleValidateInstallationDialogVisibility(status) {
    setValidateInstallationDialogOpen(status);
  }

  async function handleValidateInstallationClickButton() {
    setIsLoading(true);
    API.graphql(
      graphqlOperation(mutations.updateTask, {
        input: {
          id: id,
          status: "REVIEWED",
          _version: installationDetails._version,
        },
      })
    )
      .then((res) => {
        const task = res.data.updateTask;
        const materialsArray = task.assets ? task.assets.items : [];
        const observationsArray = task.notes ? task.notes.items : [];
        setInstallationDetails(task);
        setObservations(observationsArray);
        setMaterials(materialsArray);
        setIsLoading(false);
        setValidateInstallationDialogOpen(false);
        showMessage("Instalación validada con éxito.");
      })
      .catch((err) => {
        console.debug(err);
        showError("Ocurrió un error durante la operación");
        setIsLoading(false);
      });
  }

  async function deleteInstallation() {
    try {
      setIsLoading(true);
      const taskVariables = {
        id: id,
        deleted: true,
        _version: installationDetails._version,
      };
      const task = await API.graphql(graphqlOperation(mutations.updateTask, { input: taskVariables }));
      const logVariables = {
        dataType: "TASK",
        data: JSON.stringify(task),
        userID: userId,
      };
      await API.graphql(graphqlOperation(mutations.createLog, logVariables));
      showMessage("La instalación se eliminó de forma correcta.");
      history.goBack();
    } catch (error) {
      console.error(error);
      showError("Ocurrió un error durante la eliminación de la instalación.");
    } finally {
      setIsLoading(false);
    }
  }

  function handleEditBtnClick(event) {
    setInstallationDialogOpen(true);
  }

  function handleCancelation() {
    setInstallationDialogOpen(false);
  }

  function handleOpenDeleteDialog() {
    setShowDeleteConfirmation(true);
  }

  function handleCloseDeleteDialog() {
    setShowDeleteConfirmation(false);
  }

  function handleInstalationUpdateSuccess() {
    setInstallationDialogOpen(false);
    fetchInstallation();
  }

  return (
    <ValidatorInstallationDetailsViewUI
      installationDetails={installationDetails}
      installationUsers={installationUsers}
      materials={materials}
      observations={observations}
      isLoading={isLoading}
      validateInstallationDialogOpen={validateInstallationDialogOpen}
      handleValidateInstallationDialogVisibility={handleValidateInstallationDialogVisibility}
      handleValidateInstallationClickButton={handleValidateInstallationClickButton}
      installationDialogProps={{
        open: installationDialogOpen,
        initialData: installationDetails,
        mode: FORM_MODES.UPDATE,
        onCancel: handleCancelation,
        onSuccess: handleInstalationUpdateSuccess,
      }}
      attachmentDialogProps={{
        open: attachmentsDialogOpen,
        onClose: closeAttachmentDialog,
        fullWidth: true,
        maxWidth: "md",
      }}
      deleteDialogProps={{
        open: showDeleteConfirmation,
        onOpen: handleOpenDeleteDialog,
        onClose: handleCloseDeleteDialog,
        onConfirm: deleteInstallation,
        loading: isLoading,
      }}
      onAttachmentBtnClick={openAttachmentDialog}
      onEditBtnClick={handleEditBtnClick}
    />
  );
}

export default ValidatorInstallationDetailsView;
