export const updateMaterial = /* GraphQL */ `
  mutation UpdateMaterial(
    $id: ID!, 
    $name: String!, 
    $manufacturer: String!, 
    $description: String, 
    $categoryId: ID!, 
    $uomId: ID!,
    $_version: Int!
  ) {
    updateMaterial(
      input: {
        id: $id
        name: $name
        enabled: true
        trackable: true
        description: $description
        manufacturer: $manufacturer
        materialCategoryId: $categoryId
        materialUomId: $uomId
        _version: $_version
      }
    ) {
      id
    }
  }
`;
