/**
 * Se encarga de ejecutar un callback {dispatch} únicamente cuando el componente donde es invokado esta montado.
 *
 * Se encarga de evitar el siguiente error: "Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application. To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function.""
 *
 * usage:
 * const [state, unsafeDispatch] = useState()/useReducer();
 * const dispatch = useSafeDispatch(unsafeDispatch);
 *
 * dispatch(newState)
 */

import { useLayoutEffect, useCallback, useRef } from "react";
// import { Logger } from "@aws-amplify/core";
// const logger = new Logger("useSafeDispatch");

function useSafeDispatch(dispatch) {
  const mountedRef = useRef(false);
  useLayoutEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return useCallback(
    (...args) => {
      if (mountedRef.current) {
        dispatch(...args);
      }
    },
    [dispatch]
  );
}

export default useSafeDispatch;
