import React from "react";
import useCompanyOperations from "../useCompanyOperations";
import useAsync from "hooks/useAsync";
import CompaniesList from "../CompaniesList";
import CompanyFormOperations, { CreateCompanyFormDialog, CompanyFormButton } from "../CompanyFormOperations";
import { EnablementProvider, CompanyEnablementDialog } from "../CompanyEnablement";
import { handleErrorDescription } from "./helpers";

export const CompaniesListView = () => {
  const companyState = useAsync({ data: [] });
  const { run, setData: setCompanyData, data: companies, isLoading, isError, error } = companyState;
  const { listCompanies } = useCompanyOperations();

  React.useEffect(() => {
    run(listCompanies());
  }, [run, listCompanies]);

  const onConfirmEnablement = (updatedCompany) => {
    let companiesCopy = [...companies];
    const companyToUpdateIndex = companiesCopy.findIndex((company) => company.id === updatedCompany.id);
    companiesCopy[companyToUpdateIndex] = {
      ...companiesCopy[companyToUpdateIndex],
      ...updatedCompany,
    };
    setCompanyData(companiesCopy);
  };

  const addNewCompany = (newCompany) => setCompanyData([newCompany, ...companies]);
  const onCreateConfirm = (newCompany) => {
    addNewCompany(newCompany);
  };

  function onErrorFallbackClick() {
    run(listCompanies());
  }

  const errorFallbackDescription = handleErrorDescription(error);

  return (
    <>
      <EnablementProvider>
        <CompaniesList
          companies={companies}
          isLoading={isLoading}
          openErrorFallback={isError}
          errorFallbackDescription={errorFallbackDescription}
          onErrorFallbackClick={onErrorFallbackClick}
        />
        <CompanyEnablementDialog onConfirm={onConfirmEnablement} />
      </EnablementProvider>
      <CompanyFormOperations>
        <CreateCompanyFormDialog onConfirm={onCreateConfirm} />
        <CompanyFormButton>Crear Cliente</CompanyFormButton>
      </CompanyFormOperations>
    </>
  );
};

export default CompaniesListView;
