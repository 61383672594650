import { CsvBuilder } from "filefy";

const exportCsv = (columnList, initialData, fileName) => {
  const columns = columnList.filter((columnDef) => {
    return columnDef.field && columnDef.export !== false && !columnDef.hidden;
  });

  const data = initialData.map((rowData) =>
    columns.map((columnDef) => {
      const columnField = columnDef.field.split(".");
      let fieldResult;

      const renderResult = columnDef.hasOwnProperty("render") ? columnDef.render(rowData) : null;
      // console.log("renderResult", renderResult);

      if (renderResult && typeof renderResult === "object") {
        if (renderResult.props.children.length > 1 && typeof renderResult.props.children === "object")
          return renderResult.props.csvValue;
        if (typeof renderResult.props.children === "string" || typeof renderResult.props.children === "number") {
          return renderResult.props.children;
        }
      }

      if (renderResult && typeof renderResult === "string") return renderResult;
      if (columnField.length > 1) {
        fieldResult = columnField.reduce((o, i) => {
          if (!o) return "Sin información";
          if (o.hasOwnProperty(i) && o[i]) return o[i];
          return "Sin información";
        }, rowData);
      } else {
        fieldResult = rowData[columnDef.field];
      }
      return fieldResult;
    })
  );

  const builder = new CsvBuilder(`${fileName}.csv`);
  builder
    .setDelimeter(",")
    .setColumns(columns.map((columnDef) => columnDef.title))
    .addRows(data)
    .exportFile();
};

export default exportCsv;
