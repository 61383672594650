import { useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';

/**
 * @hook useActions
 * 
 * Hook personalizado para unir a Redux las funciones que crean acciones. Este hook
 * se implementó para evitar la unión manual de funciones que crean acciones mediante
 * el hook useDispatch.
 * 
 * @param {Function | Function[]} actions Funciones que se unen a Redux
 * @param {any} deps Dependencias opcionales para volver a unir las funciones a redux
 */
export function useActions(actions, deps) {
  const dispatch = useDispatch();
  
  return useMemo(
    () => {
      if (Array.isArray(actions)) {
        return actions.map(a => bindActionCreators(a, dispatch))
      }
      return bindActionCreators(actions, dispatch)
    },
    deps ? [dispatch, ...deps] : [dispatch]
  );
}