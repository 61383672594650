import React from "react";
import PropTypes from "prop-types";
import EvidenceViewV2Support from "components/common/EvidenceViewV2Support";
import { TaskStatus } from "models";
import { TASK_TYPES } from "constant/task/types";

const EndEvidence = ({
  taskId,
  attachmentPrefix,
  evidenceStatus,
  evidenceRef,
  evidencesMinRules,
  endEvidences,
  evidenceLoading,
  setEvidenceLoading,
  onAddOptimisticAttachments,
  onDeleteOptimisticAttachment,
  taskType,
  evidenceListHeight,
  position,
  loadingPosition,
  positionError,
}) => {
  return (
    <>
      <div>Para concluir con el proceso de soporte deberás adjuntar la fotografía final de la tarea.</div>
      <EvidenceViewV2Support
        taskId={taskId}
        prefix={attachmentPrefix}
        status={evidenceStatus}
        title="Evidencias"
        ref={evidenceRef}
        inputName="end_inputFile"
        min={evidencesMinRules}
        attachments={endEvidences}
        loading={evidenceLoading}
        onLoading={(isLoading) => setEvidenceLoading(isLoading)}
        onEvidenceChange={onAddOptimisticAttachments}
        onDeleteItem={onDeleteOptimisticAttachment}
        taskType={taskType}
        evidenceListHeight={evidenceListHeight}
        position={position}
        loadingPosition={loadingPosition}
        positionError={positionError}
      />
    </>
  );
};

EndEvidence.propTypes = {
  taskId: PropTypes.string.isRequired,
  attachmentPrefix: PropTypes.string.isRequired,
  evidenceStatus: PropTypes.oneOf([TaskStatus.COMPLETED]).isRequired,
  evidencesMinRules: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  evidenceRef: PropTypes.any,
  endEvidences: PropTypes.array,
  evidenceLoading: PropTypes.bool,
  setEvidenceLoading: PropTypes.func,
  onAddOptimisticAttachments: PropTypes.func,
  onDeleteOptimisticAttachment: PropTypes.func,
  taskType: PropTypes.oneOf(Object.values(TASK_TYPES)),
  evidenceListHeight: PropTypes.string,
  position: PropTypes.object,
  loadingPosition: PropTypes.bool,
  positionError: PropTypes.object,
};

EndEvidence.defaultProps = {
  taskId: "",
  attachmentPrefix: "",
  evidenceStatus: TaskStatus.COMPLETED,
  evidencesMinRules: 1,
  evidenceRef: null,
  endEvidences: [],
  evidenceLoading: false,
  setEvidenceLoading: () => { },
  onAddOptimisticAttachments: () => { },
  deleonDeleteOptimisticAttachmenteAttachment: () => { },
  taskType: TASK_TYPES.SUPPORT,
  evidenceListHeight: "auto",
};

export default EndEvidence;
