import React from "react";
import PropTypes from "prop-types";
import ListView from "components/custom/ListView";
import FetchErrorFallback from "components/common/FetchErrorFallback";
import DescriptionDetailPanel from "components/admin/DescriptionDetailPanel";
import { useEnablement } from "../CompanyEnablement";
import { COMPANIES_COLUMNS, handleExportCompanyListTable } from "../CompaniesListView/helpers";
import useOnlineStatus from "@rehooks/online-status";
import { useHistory } from "react-router-dom";
import { COMPANIES_LIST_HEADER_TITLE } from "constant/headerTitles";
import { networkTooltipProps, rowStyleCompanyDisabled } from "../constant";
import { COMPANY } from "constant/route/admin";

export default function CompaniesList({
  companies,
  isLoading,
  openErrorFallback,
  errorFallbackDescription,
  onErrorFallbackClick,
}) {
  const isOnline = useOnlineStatus();
  const { onSwitchChange } = useEnablement();
  const history = useHistory();

  const MEMO_COMPANIES_COLUMNS = React.useMemo(
    () => COMPANIES_COLUMNS({ onSwitchChange, isOnline }),
    [isOnline, onSwitchChange]
  );

  const handleRowClick = React.useCallback(
    (event, rowData) => {
      history.push({
        pathname: `${COMPANY}/${rowData.id}`,
      });
    },
    [history]
  );

  const MEMO_MATERIAL_TABLE_PROPS = React.useMemo(() => {
    return {
      title: "Listado de clientes",
      data: companies || [],
      columns: MEMO_COMPANIES_COLUMNS,
      isLoading,
      options: {
        rowStyle: ({ enabled }) => (enabled ? {} : rowStyleCompanyDisabled),
        exportCsv: handleExportCompanyListTable,
        thirdSortClick: false,
      },
      style: openErrorFallback ? { display: "none" } : {},
      onRowClick: handleRowClick,
      detailPanel: DescriptionDetailPanel,
    };
  }, [MEMO_COMPANIES_COLUMNS, companies, handleRowClick, openErrorFallback, isLoading]);

  return (
    <>
      <ListView
        headerTitle={COMPANIES_LIST_HEADER_TITLE}
        materialTableProps={MEMO_MATERIAL_TABLE_PROPS}
        networkTooltipProps={networkTooltipProps}
        showFloatingButton={false}
      />
      <FetchErrorFallback
        open={openErrorFallback}
        description={errorFallbackDescription}
        onClick={onErrorFallbackClick}
        limitAttems={1}
      />
    </>
  );
}

CompaniesList.propTypes = {
  companies: PropTypes.array,
  isLoading: PropTypes.bool,
  openErrorFallback: PropTypes.bool,
  errorFallbackDescription: PropTypes.string,
  onErrorFallbackClick: PropTypes.func,
};

CompaniesList.defaultProps = {
  companies: [],
  isLoading: false,
  openErrorFallback: false,
  onErrorFallbackClick: () => {},
};
