// Componente para mostrar la lista de materiales
import React, { useEffect, useState, useMemo } from "react";
import { useHistory } from "react-router-dom";

import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import EditIcon from "@material-ui/icons/Edit";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Alert from "@material-ui/lab/Alert";
import DialogTitle from "@material-ui/core/DialogTitle";
import PostAddIcon from "@material-ui/icons/PostAdd";

import Section from "components/Section";
import MaterialTable from "components/custom/MaterialTable";
import ConfirmationDialog from "components/ConfirmationDialog";
import SpeedDial from "components/custom/SpeedDial";

import useHeaderTitle from "hooks/useHeaderTitle";
import useNotifier from "hooks/useNotifier";

import { UOM_DATA_LOAD } from "constant/route/admin";
import { useActions } from "hooks/useActions";
import { REQUEST_STATUS } from "constant/requestStatus";
import { fetchUomCatalog, createUOMRequest, updateUOMRequest, cleanUOMReducer } from "redux/dispatcher/uomCatalog";
import { checkNextItems } from "util/lists";

const SECTION_TITLE = "Unidades de medida";

function UOMListView() {
  const history = useHistory();
  const classes = useStyles();
  const { showError, showMessage } = useNotifier();

  useHeaderTitle(SECTION_TITLE);

  const {
    uoms: rdxUoms,
    uomsToken: rdxUomsToken,
    fetchStatus: rdxFetchStatus,
    fetchCreationStatus: rdxFetchCreationStatus,
    fetchUpdateStatus: rdxFetchUpdateStatus,
  } = useSelector(({ uomCatalog }) => uomCatalog);
  const [fetchUomCatalogRequest, createUOM, updateUOM, cleanUOMReducerRequest] = useActions([
    fetchUomCatalog,
    createUOMRequest,
    updateUOMRequest,
    cleanUOMReducer,
  ]);
  const { register, setValue, reset, getValues, handleSubmit, errors } = useForm({
    defaultValues: {
      UOMName: "",
      UOMAbbreviation: "",
      UOMDescription: "",
    },
  });

  const [UOMList, setUOMList] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [selectedUOM, setSelectedUOM] = useState("");
  const [UOMDialogOpen, setUOMDialogOpen] = useState(false);
  const [confirmAddDialogOpen, setConfirmAddDialogOpen] = useState(false);
  const [confirmEditDialogOpen, setConfirmEditDialogOpen] = useState(false);

  useEffect(() => {
    fetchUomCatalogRequest();

    return () => {
      cleanUOMReducerRequest();
    };
  }, []);

  useEffect(() => {
    setUOMList(rdxUoms);
  }, [rdxUoms]);

  useEffect(() => {
    if (rdxFetchCreationStatus === REQUEST_STATUS.SUCCESSFUL) {
      handleSuccessfulUOMAdding();
    } else if (rdxFetchCreationStatus === REQUEST_STATUS.FAILED) {
      handleErrorUOMAdding();
    }
  }, [rdxFetchCreationStatus]);
  useEffect(() => {
    if (rdxFetchUpdateStatus === REQUEST_STATUS.SUCCESSFUL) {
      handleSuccessfulUOMUpdate();
    } else if (rdxFetchUpdateStatus === REQUEST_STATUS.FAILED) {
      handleErrorUOMUpdate();
    }
  }, [rdxFetchUpdateStatus]);

  const actions = useMemo(
    () => [
      {
        icon: <PostAddIcon />,
        name: "Nueva UOM",
        onClick: () => handleUOMDialogVisibilityStatus(true),
      },
      {
        icon: <FileCopyIcon />,
        name: "Carga Manual",
        onClick: () => {
          history.push(UOM_DATA_LOAD);
        },
      },
    ],
    []
  );

  function loadMoreUOM(page) {
    const hasMoreItems = checkNextItems(page, UOMList.length);
    if (hasMoreItems && rdxUomsToken) {
      fetchUomCatalogRequest(rdxUomsToken);
    }
  }

  function handleUOMDialogVisibilityStatus(status) {
    setUOMDialogOpen(status);
  }

  function updateUOMDialogOpen(uomSelected) {
    const uomSelectedObject = UOMList.filter((uom) => uom.id === uomSelected.id);
    setSelectedUOM(uomSelected.id);
    setValue("UOMName", uomSelected.name);
    setValue("UOMAbbreviation", uomSelected.abbreviation);
    setValue("UOMDescription", uomSelectedObject[0]?.description || "");
    handleUOMDialogVisibilityStatus(true);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  function handleCloseUOMDialog() {
    setSelectedUOM("");
    reset();
    handleUOMDialogVisibilityStatus(false);
  }

  function handleSuccessfulUOMAdding() {
    showMessage("Unidad de medida agregado con éxito");
    reset();
    setConfirmAddDialogOpen(false);
    setUOMDialogOpen(false);
    cleanUOMReducerRequest();
    fetchUomCatalogRequest();
  }

  function handleErrorUOMAdding() {
    showError("Ha ocurrido un error, intente de nuevo por favor.");
    setConfirmAddDialogOpen(false);
  }

  function handleSuccessfulUOMUpdate() {
    showMessage("Unidad de medida actualizado con éxito");
    reset();
    setConfirmEditDialogOpen(false);
    setUOMDialogOpen(false);
    setSelectedUOM("");
    cleanUOMReducerRequest();
    fetchUomCatalogRequest();
  }

  function handleErrorUOMUpdate() {
    showError("Ha ocurrido un error, intente de nuevo por favor.");
    setConfirmEditDialogOpen(false);
  }

  function onConfirmUpdate() {
    const values = getValues();
    const uom = UOMList.find(({ id }) => id === selectedUOM);
    updateUOM({
      id: selectedUOM,
      name: values.UOMName?.trim(),
      abbreviation: values.UOMAbbreviation?.trim(),
      description: values.UOMDescription?.trim(),
      _version: uom._version,
    });
  }

  function onCancelUpdate() {
    setConfirmEditDialogOpen(false);
  }

  function handleOnSendAddAction() {
    if (selectedUOM === "") {
      setConfirmAddDialogOpen(true);
    } else {
      setConfirmEditDialogOpen(true);
    }
  }

  function onConfirmAdd() {
    const values = getValues();
    createUOM({
      name: values.UOMName?.trim(),
      description: values.UOMDescription?.trim(),
      abbreviation: values.UOMAbbreviation?.trim(),
    });
  }

  function onCancelAdd() {
    setConfirmAddDialogOpen(false);
  }

  return (
    <>
      <Box mb={2} mt={2}>
        <SpeedDial actions={actions} backdrop tooltipOpen />
        <Section>
          {rdxFetchStatus === REQUEST_STATUS.FAILED && (
            <Box p={2}>
              <Alert severity="error">
                Ha ocurrido un error con la carga del catálogo, recarga la página por favor.
              </Alert>
            </Box>
          )}
          <MaterialTable
            title={SECTION_TITLE}
            columns={columns}
            data={UOMList}
            onChangePage={loadMoreUOM}
            detailPanel={(rowData) => (
              <div className={classes.detailPanelContainer}>
                <p className={classes.detailPanelTitle}>Descripción</p>
                <p>{rowData?.description || "Sin información"}</p>
              </div>
            )}
            actions={[
              {
                icon: EditIcon,
                tooltip: "Editar material",
                onClick: (event, rowData) => {
                  updateUOMDialogOpen(rowData);
                },
              },
            ]}
            isLoading={rdxFetchStatus === REQUEST_STATUS.PENDING}
          />
        </Section>

        <Dialog
          fullWidth
          maxWidth="md"
          open={UOMDialogOpen}
          onClose={handleCloseUOMDialog}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Unidad de Medida</DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item md={6} xs={6}>
                <TextField
                  autoFocus
                  inputRef={register({
                    required: "Este campo es obligatorio",
                    validate: {
                      notEmpty: (value) => value.trim() !== "" || "El campo no debe estar vacio",
                    },
                  })}
                  required
                  margin="dense"
                  name="UOMName"
                  label="Nombre"
                  type="text"
                  fullWidth
                  error={errors?.UOMName?.message}
                  helperText={errors.UOMName?.message || ""}
                />
              </Grid>
              <Grid item md={6} xs={6}>
                <TextField
                  margin="dense"
                  inputRef={register({
                    required: "Estecampo es obligatorio",
                    validate: {
                      notEmpty: (value) => value.trim() !== "" || "El campo no debe estar vacio",
                    },
                  })}
                  name="UOMAbbreviation"
                  label="Abreviación"
                  type="text"
                  fullWidth
                  required
                  error={errors?.UOMAbbreviation?.message}
                  helperText={errors.UOMAbbreviation?.message || ""}
                />
              </Grid>

              <Grid item md={12} xs={12}>
                <TextField
                  variant="outlined"
                  inputRef={register}
                  multiline
                  rows={6}
                  margin="dense"
                  name="UOMDescription"
                  label="Descripción"
                  type="text"
                  fullWidth
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseUOMDialog} color="primary">
              Cancelar
            </Button>
            <Button onClick={handleSubmit(handleOnSendAddAction)} color="primary">
              {selectedUOM === "" ? "Agregar" : "Actualizar"}
            </Button>
          </DialogActions>
        </Dialog>

        <ConfirmationDialog
          open={confirmEditDialogOpen}
          title="Confirmación"
          onConfirm={onConfirmUpdate}
          onCancel={onCancelUpdate}
          loading={rdxFetchCreationStatus === REQUEST_STATUS.PENDING || rdxFetchUpdateStatus === REQUEST_STATUS.PENDING}
        >
          Está a punto de modificar esta unidad de medida. ¿Desea continuar?
        </ConfirmationDialog>
        <ConfirmationDialog
          open={confirmAddDialogOpen}
          title="Confirmación"
          onConfirm={onConfirmAdd}
          onCancel={onCancelAdd}
          loading={rdxFetchCreationStatus === REQUEST_STATUS.PENDING || rdxFetchUpdateStatus === REQUEST_STATUS.PENDING}
        >
          Está a punto de registrar una unidad de medida.
        </ConfirmationDialog>
      </Box>
    </>
  );
}

export default UOMListView;

const useStyles = makeStyles((theme) => ({
  sectionTitleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "10px 15px",
  },
  sectionTitleText: {
    margin: 0,
    fontWeight: "bold",
    fontSize: 18,
    marginLeft: 16,
  },
  detailPanelContainer: {
    background: "#F2F2F2",
    padding: "16px",
  },
  detailPanelTitle: {
    fontWeight: "bold",
  },
}));

const columns = [
  { title: "ID", field: "id", hidden: true },
  {
    title: "Nombre",
    field: "name",
  },
  {
    title: "Abreviación",
    field: "abbreviation",
  },
  {
    title: "Fecha de registro",
    field: "createdAt",
  },
  {
    title: "Última actualización",
    field: "updatedAt",
  },
];
