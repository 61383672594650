import React from "react";
import PropTypes from "prop-types";
import ConfirmationDialog from "components/ConfirmationDialog";
import { ENABLEMENT_TEXT, dialogTransitionDuration, enablementDialogDefaultState, ENABLEMENT_MODE } from "../constant";
import useCompanyEnablementHelpers from "./useEnableCompanyHelpers";

const EnablementContext = React.createContext();

export function EnablementProvider({ children }) {
  const [itemSelected, setItemSelected] = React.useState(null);
  const [enablementDialogState, setEnablementDialogState] = React.useState(enablementDialogDefaultState);

  const openCompanyEnablementDialog = (mode) => setEnablementDialogState({ isOpen: true, mode });
  const closeCompanyEnablementDialog = () =>
    setEnablementDialogState((currentState) => {
      setTimeout(() => setEnablementDialogState(enablementDialogDefaultState), dialogTransitionDuration);
      return {
        ...currentState,
        isOpen: false,
      };
    });

  const onSwitchChange = React.useCallback((checked, rowData, event) => {
    event.stopPropagation();
    const mode = checked ? ENABLEMENT_MODE.DISABLED : ENABLEMENT_MODE.ENABLED;
    openCompanyEnablementDialog(mode);
    setItemSelected(rowData);
  }, []);

  return (
    <EnablementContext.Provider
      value={{
        onSwitchChange,
        closeDialog: closeCompanyEnablementDialog,
        elementId: itemSelected?.id,
        elementVersion: itemSelected?._version,
        mode: enablementDialogState.mode,
        isOpen: enablementDialogState.isOpen,
        setItemSelected,
      }}
    >
      {children}
    </EnablementContext.Provider>
  );
}

EnablementProvider.propTypes = {
  children: PropTypes.node,
};

export default EnablementProvider;

export function useEnablement() {
  const context = React.useContext(EnablementContext);
  if (context === undefined) {
    throw new Error("useEnablement must be used within a <EnablementProvider />");
  }
  return context;
}

export const EnablementDialog = ({ children, handleConfirm, handleCancel, loading, ...props }) => {
  const { isOpen } = useEnablement();
  return (
    <ConfirmationDialog
      open={isOpen}
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      title="Confirmación"
      okBtnText="Confirmar"
      transitionDuration={dialogTransitionDuration}
      loading={loading}
      {...props}
    >
      {children}
    </ConfirmationDialog>
  );
};

EnablementDialog.propTypes = {
  children: PropTypes.node,
  handleConfirm: PropTypes.func,
  handleCancel: PropTypes.func,
  loading: PropTypes.bool,
};

EnablementDialog.defaultProps = {
  handleConfirm: () => {},
  handleCancel: () => {},
  loading: false,
};

export function CompanyEnablementDialog({ onConfirm, onConfirmError, onCancel, ...props }) {
  const { mode } = useEnablement();
  const { handleConfirm, handleCancel, loading } = useCompanyEnablementHelpers();

  return (
    <EnablementDialog
      handleConfirm={() => handleConfirm({ onConfirm, onConfirmError })}
      handleCancel={() => handleCancel({ onCancel })}
      loading={loading}
      {...props}
    >
      ¿Estás seguro que deseas <strong>{ENABLEMENT_TEXT[mode]}</strong> al siguiente cliente?
    </EnablementDialog>
  );
}

CompanyEnablementDialog.propTypes = {
  onConfirm: PropTypes.func,
  onConfirmError: PropTypes.func,
  onCancel: PropTypes.func,
};

CompanyEnablementDialog.defaultProps = {
  onConfirm: () => {},
  onConfirmError: () => {},
  onCancel: () => {},
};
