export const createMaterialCategory = /* GraphQL */ `
  mutation CreateMaterialCategory($input: CreateMaterialCategoryInput!) {
    createMaterialCategory(input: $input) {
      id
      name
      abbreviation
      description
      createdAt
      updatedAt
      _version
      _deleted
    }
  }
`;

export default createMaterialCategory;
