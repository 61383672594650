import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../TabPanel";

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

function TabContainer(props) {
  const { tabData, stylesTabs, stylesSelected } = props;
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const renderTabs = (tabsIndex) => {
    // Generate Tab item.
    const tab = tabsIndex.map((element) => {
      return (
        <Tab
          key={element.index}
          label={element.name}
          {...a11yProps(element.index)}
          className={stylesTabs ? stylesTabs[element.styles] : ""}
          classes={stylesSelected ? { selected: stylesSelected[element.styles] } : {}}
        />
      );
    });
    return tab;
  };

  const renderTabPanel = (tabData) => {
    const tabPanel = tabData.map((element) => {
      return (
        <TabPanel key={element.index} value={value} index={element.index}>
          {element.component}
        </TabPanel>
      );
    });
    return tabPanel;
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    props.onChangeTab(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {renderTabs(tabData)}
        </Tabs>
      </AppBar>
      {renderTabPanel(tabData)}
    </div>
  );
}

TabContainer.propTypes = {
  tabData: PropTypes.array,
  stylesTabs: PropTypes.func,
  stylesSelected: PropTypes.func,
  onChangeTab: PropTypes.func,
};

TabContainer.defaultProps = {
  onChangeTab: () => {},
};

export default React.memo(TabContainer);
