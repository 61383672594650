import API, { graphqlOperation } from "@aws-amplify/api";
import * as queries from "graphql-custom/queries";
import * as mutations from "graphql-custom/mutations";
import dayjs from "dayjs";
import { Auth } from "@aws-amplify/auth";
import { AssetType } from "models";
import useGraphQL from "hooks/useGraphQL";

export default function useGraphQLHelpers() {
  const { loading, runGraphQLOperation } = useGraphQL();

  async function scheduleMaintenance(data) {
    // console.log({ data });
    const currentUser = await Auth.currentAuthenticatedUser();
    const selectedUserIds = data.technician;

    //1. Actualizamos type de los activos a "Allocated"
    // const updateAssetsInputs = mutations.generateInputs(assetsToUpdate);
    const { all, added, deleted } = data.assetsList;
    const updatedAssetsType = await updateAssetsType({ all, added, deleted });

    //2. generar asset por cada material
    let assetsMutation = { data: {} };

    if (data.selectedMaterials?.length) {
      const { variables, mutation } = mutations.generateAssetsInstallationMutation(
        data.selectedMaterials,
        AssetType.MATERIAL_ALLOCATED
      );
      assetsMutation = await API.graphql(graphqlOperation(mutation, variables));
    }

    if (!updatedAssetsType || !assetsMutation) throw new Error();

    //3. hacemos merge de los materiales con los assets y mutamos su estructura
    let myAssets = [...Object.values(updatedAssetsType), ...Object.values(assetsMutation.data)];

    const arrayToObject = (array) =>
      array.reduce((obj, item, index) => {
        obj[`createAssetSchedule${index}`] = item;
        return obj;
      }, {});

    myAssets = arrayToObject(myAssets);

    if (!myAssets) throw new Error();

    // 4. Crear el mantenimiento
    let taskMutation = await API.graphql(
      graphqlOperation(mutations.createTask, {
        input: {
          taskSupervisorId: currentUser.attributes.sub,
          taskTaskTypeId: data.type,
          taskPremisesId: data.premise,
          taskProjectId: data.projectId,
          startDate: dayjs(data.startDate).format("YYYY-MM-DD"),
          endDate: dayjs(data.endDate).format("YYYY-MM-DD"),
          startTime: dayjs(data.startDate).format("HH:mm:ss"),
          endTime: dayjs(data.endDate).format("HH:mm:ss"),
          status: "SCHEDULED",
          category: "MAINTENANCE",
        },
      })
    );

    //5. relacionar materiales, activos y usuarios con el mantenimiento
    const { relationsVariables, relationsMutation } = mutations.generateRelations(
      taskMutation.data.createTask.id,
      myAssets,
      selectedUserIds
    );
    const relationsMutationRequest = await API.graphql(graphqlOperation(relationsMutation, relationsVariables));

    const userTask = Object.keys(relationsMutationRequest?.data)
      .filter((key) => key.includes("asignUser"))
      .map((key) => relationsMutationRequest.data[key]);

    taskMutation.data.createTask = { ...taskMutation.data.createTask, users: { items: userTask } };
    return {
      ...taskMutation.data,
      ...assetsMutation.data,
      ...relationsMutationRequest.data,
      ...userTask,
      id: taskMutation.data.createTask.id,
    };
  }

  async function getKitById(id) {
    // return API.graphql(graphqlOperation(queries.getKit, { kitID: id }));
    return runGraphQLOperation({
      operation: queries.getKit,
      variables: { kitID: id },
    });
  }

  async function getAllUOM() {
    // return API.graphql(graphqlOperation(queries.listUoms));
    return runGraphQLOperation({
      operation: queries.listUoms,
    });
  }

  async function updateAssetsType({ all = [], added = [], deleted = [] }) {
    //filtramos los activos que requieren actualizar su type
    const assetsToUpdate = all.filter((asset) => asset.type !== AssetType.ALLOCATED);

    // console.log("assetsToUpdate", assetsToUpdate);
    if (!assetsToUpdate.length) {
      const keys = all.map((asset, index) => `updateAsset${index}`);
      return convertArrayToObject(all, keys);
    }
    //filtramos los activos que requieren unicamente ser parseados
    const assetsToParse = all.filter((asset) => asset.type === AssetType.ALLOCATED);
    const assetsIds = assetsToUpdate.map((asset) => asset.id);

    const { mutation, params } = mutations.generateAssetTypeUpdateMutation(assetsToUpdate, assetsIds, []);
    const updatedAssetsType = await runGraphQLOperation({
      operation: mutation,
      variables: params,
    });
    // console.log("updatedAssetsType", updatedAssetsType);
    const assetsToParseKeys = assetsToParse.map(
      (asset, index) => `updateAsset${Object.keys(updatedAssetsType).length + index}`
    );

    const parsedAssets = convertArrayToObject(assetsToParse, assetsToParseKeys);
    // console.log("parsedAssets", parsedAssets);

    return { ...updatedAssetsType, ...parsedAssets };
  }

  return {
    scheduleMaintenance,
    getKitById,
    getAllUOM,
    loading,
  };
}

const convertArrayToObject = (array = [], keys) => {
  const initialValue = {};
  return array.reduce((obj, item, index) => {
    return {
      ...obj,
      [keys[index]]: item,
    };
  }, initialValue);
};
