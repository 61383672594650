import React from "react";
import PropTypes from "prop-types";

import { Box, Typography, Button } from "@material-ui/core";
import makeStyles from "@material-ui/styles/makeStyles";

import cleanUUID from "util/cleanUUID";

export default function BottomPanel({ codeDetected, fetchQrCode, disabledBtn, btnLabel, emptyCodeMsg, children }) {
  const classes = useStyles();

  return (
    <Box p={2} className={classes.codeReaderPanel}>
      <Box mb={2}>
        {codeDetected && (
          <>
            <Typography variant="caption" display="inline">
              <strong>CÓDIGO: </strong>
            </Typography>
            <Typography variant="body2" display="inline">
              {cleanUUID(codeDetected)}
            </Typography>
          </>
        )}
        {!codeDetected && (
          <>
            <Typography variant="caption">{emptyCodeMsg}</Typography>
          </>
        )}
      </Box>
      {children}
      <Button
        id="confirm-asset-scanned"
        variant="contained"
        color="primary"
        onClick={() => fetchQrCode(codeDetected)}
        disabled={disabledBtn || !codeDetected}
        fullWidth
      >
        {btnLabel}
      </Button>
    </Box>
  );
}

BottomPanel.propTypes = {
  codeDetected: PropTypes.string,
  fetchQrCode: PropTypes.func,
  btnLabel: PropTypes.string,
  children: PropTypes.node,
  emptyCodeMsg: PropTypes.string,
  disabledBtn: PropTypes.bool,
};

BottomPanel.defaultProps = {
  codeDetected: "",
  fetchQrCode: () => {},
  btnLabel: "Confirmar",
  emptyCodeMsg: "Código no detectado",
  disabledBtn: false,
};

const useStyles = makeStyles((theme) => ({
  codeReaderPanel: {
    backgroundColor: "white",
    overflow: "clip",
    display: "relative",
  },
}));
