/**
 * Este reducer maneja el estado para los componentes del catalogo de materiales
 */

import {
  FETCH_MATERIAL_CATALOG_PENDING,
  FETCH_MATERIAL_CATALOG_SUCCESS,
  FETCH_NEXT_MATERIAL_CATALOG_SUCCESS,
  FETCH_MATERIAL_CATALOG_ERROR,
  CREATE_MATERIAL_SUCCESS,
  CREATE_MATERIAL_PENDING,
  CREATE_MATERIAL_ERROR,
  UPDATE_MATERIAL_SUCCESS,
  UPDATE_MATERIAL_PENDING,
  UPDATE_MATERIAL_ERROR,
  CLEAN_MATERIAL_REDUCER,
  SEARCH_MATERIAL_CATALOG_PENDING,
  SEARCH_MATERIAL_CATALOG_ERROR,
  SEARCH_MATERIAL_CATALOG_SUCCESS,
  SEARCH_NEXT_MATERIAL_CATALOG_SUCCESS,
} from "redux/action";

import { REQUEST_STATUS } from "constant/requestStatus";
import { filterDeletedItems } from "util/lists";

const INITIAL_STATE = {
  materials: [],
  materialsToken: null,
  fetchStatus: REQUEST_STATUS.INACTIVE,
  fetchCreationStatus: REQUEST_STATUS.INACTIVE,
  fetchUpdateStatus: REQUEST_STATUS.INACTIVE,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SEARCH_MATERIAL_CATALOG_PENDING:
    case FETCH_MATERIAL_CATALOG_PENDING:
      return { ...state, fetchStatus: REQUEST_STATUS.PENDING };

    case FETCH_MATERIAL_CATALOG_SUCCESS:
      return addMaterials(state, action);

    case FETCH_NEXT_MATERIAL_CATALOG_SUCCESS:
      const newState = addMaterials(state, action);
      newState.materials = [...state.materials, ...newState.materials];
      return newState;

    case SEARCH_MATERIAL_CATALOG_SUCCESS:
      return addMaterialsSearch(state, action);

    case SEARCH_NEXT_MATERIAL_CATALOG_SUCCESS:
      const searchState = addMaterialsSearch(state, action);
      searchState.materials = [...state.materials, ...searchState.materials];
      return searchState;

    case SEARCH_MATERIAL_CATALOG_ERROR:
    case FETCH_MATERIAL_CATALOG_ERROR:
      return { ...state, fetchStatus: REQUEST_STATUS.FAILED, materials: [] };

    case CREATE_MATERIAL_SUCCESS:
      return { ...state, fetchCreationStatus: REQUEST_STATUS.SUCCESSFUL };

    case CREATE_MATERIAL_PENDING:
      return { ...state, fetchCreationStatus: REQUEST_STATUS.PENDING };

    case CREATE_MATERIAL_ERROR:
      return { ...state, fetchCreationStatus: REQUEST_STATUS.FAILED };

    case UPDATE_MATERIAL_SUCCESS:
      return { ...state, fetchUpdateStatus: REQUEST_STATUS.SUCCESSFUL };

    case UPDATE_MATERIAL_PENDING:
      return { ...state, fetchUpdateStatus: REQUEST_STATUS.PENDING };

    case UPDATE_MATERIAL_ERROR:
      return { ...state, fetchUpdateStatus: REQUEST_STATUS.FAILED };

    case CLEAN_MATERIAL_REDUCER:
      return INITIAL_STATE;

    default:
      return state;
  }
}

const addMaterials = (state, action) => {
  let {
    listMaterials: { items = [], nextToken },
  } = action.payload;
  items = filterDeletedItems(items);
  return {
    ...state,
    materials: items,
    fetchStatus: REQUEST_STATUS.SUCCESSFUL,
    materialsToken: nextToken,
  };
};

const addMaterialsSearch = (state, action) => {
  let {
    searchMaterials: { items = [], nextToken },
  } = action.payload;
  items = filterDeletedItems(items);
  return {
    ...state,
    materials: items,
    fetchStatus: REQUEST_STATUS.SUCCESSFUL,
    materialsToken: nextToken,
  };
};
