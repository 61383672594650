export const EMAIL_REGEX =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

export function trimFormStringValues(values = {}) {
  values = Object.keys(values).reduce((valuesTrimmed, value) => {
    const textValue = values[value];
    if (typeof textValue !== "string") {
      throw new Error("trimFormStringValues: Error type value");
    }
    valuesTrimmed[value] = textValue.trim();
    return valuesTrimmed;
  }, {});
  return values;
}
