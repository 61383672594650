export const ROOT = "/activos";

export const TRANSFERS = `${ROOT}/transferencias`;
export const QR_GENERATOR = `${ROOT}/generador-qr`;
export const ASSET_GOOD_RECEIPT = `${ROOT}/registros`;
export const ASSET_IN_SYSTEM = `${ROOT}/listado`;
export const ASSET_DETAILS_VIEW = `${ROOT}/activo/:ika_wh_tracking_id`;
export const ASSETS_RECEIPTS = `${ROOT}/entradas`;
export const ASSETS_RECEIPT = `${ROOT}/entrada`;
export const ASSETS_RECEIPT_WITH_PARAM = `${ASSETS_RECEIPT}/:actionId`;
export const ASSETS_MAINTENANCE = `${ROOT}/mantenimientos`;
export const SCHEDULED_ASSETS_MAINTENANCE = `${ASSETS_MAINTENANCE}/programados`;
export const FINALIZED_ASSETS_MAINTENANCE = `${ASSETS_MAINTENANCE}/finalizados`;
export const CANCELED_ASSETS_MAINTENANCE = `${ASSETS_MAINTENANCE}/cancelados`;
export const ASSETS_MAINTENANCE_DETAIL = `${ROOT}/mantenimiento`;
export const ASSETS_MAINTENANCE_DETAIL_WITH_PARAM = `${ASSETS_MAINTENANCE_DETAIL}/:actionId`;
export const GOODS_RECEIPT = `${ROOT}/entrada/material`;
export const GOODS_RECEIPT_WITH_PARAM = `${GOODS_RECEIPT}/:actionId`;

const ASSET = {
  ROOT,
  TRANSFERS,
  QR_GENERATOR,
  ASSET_GOOD_RECEIPT,
  ASSET_IN_SYSTEM,
  ASSET_DETAILS_VIEW,
  RECEIPTS: ASSETS_RECEIPTS,
  RECEIPT: ASSETS_RECEIPT,
  RECEIPT_WITH_PARAM: ASSETS_RECEIPT_WITH_PARAM,
  MANTEINANCES: ASSETS_MAINTENANCE,
  SCHEDULED_MAINTENANCE: SCHEDULED_ASSETS_MAINTENANCE,
  FINALIZED_MAINTENANCE: FINALIZED_ASSETS_MAINTENANCE,
  CANCELED_MAINTENANCE: CANCELED_ASSETS_MAINTENANCE,
  MANTEINANCE: ASSETS_MAINTENANCE,
  MAINTENANCE_DETAIL: ASSETS_MAINTENANCE_DETAIL,
  MAINTENANCE_DETAIL_WITH_PARAM: ASSETS_MAINTENANCE_DETAIL_WITH_PARAM,
  GOODS_RECEIPT,
  GOODS_RECEIPT_WITH_PARAM
};

export default ASSET;
