/**
 * Query GraphQL para obtener las Tasks de un Usuario.
 *
 * @param {string} userId ID del usuario de que se quiere consultar el listado de Tasks.
 */
export const getTasksBySupervisorAndCategory = /* GraphQL */ `
  query GetTasksBySupervisorAndCategory($userId: ID!, $filter: ModelTaskFilterInput, $limit: Int) {
    getUser(id: $userId) {
      id
      name
      tasksSupervised(limit: $limit, filter: $filter) {
        items {
          id
          startDate
          startTime
          status
          taskTicketId
          category
          supervisor {
            id
            name
            middlename
            lastnameP
            lastnameM
            email
          }
          taskType {
            name
            description
          }
          buildingLocation {
            name
            code
            premises {
              name
              address {
                street
                number
                zipcode
                city
                settlement
                locality
              }
            }
          }
          project {
            id
            name
            code
            company {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export default getTasksBySupervisorAndCategory;
