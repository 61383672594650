import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import NotFoundView from "../components/NotFoundView";
import HomeView from "../components/HomeView";
import AdminRoutes from "./AdminRoutes";
import WarehouseRoutes from "./WarehouseRoutes";
import ValidationRoutes from "./ValidationRoutes";
import OperationsRoutes from "./OperationsRoutes";
import SupportRoutes from "./SupportRoutes";
import RevisionRoutes from "./RevisionRoutes";
import GroupRestrictedRoute from "./GroupRestrictedRoute";
// import SupportView from "components/SupportView";
import { ADMIN, HOME, WAREHOUSE, VALIDATION, OPERATION, SUPPORT, SUPPORT_SUPERVISOR } from "../constant/route";
import * as ROLES from "constant/roles";

function Routes() {
  return (
    <Switch>
      <Route exact path={[HOME.ROOT, HOME.HOME]} component={HomeView} />
      <GroupRestrictedRoute path={ADMIN.ROOT} group={ROLES.ADMINS} component={AdminRoutes} />
      <GroupRestrictedRoute path={WAREHOUSE.ROOT} group={ROLES.WAREHOUSE_KEEPERS} component={WarehouseRoutes} />
      <GroupRestrictedRoute
        path={VALIDATION.ROOT}
        group={[ROLES.ADMINS, ROLES.SUPERVISORS, ROLES.AUDITORS]}
        component={ValidationRoutes}
      />
      <GroupRestrictedRoute
        path={OPERATION.ROOT}
        group={[ROLES.SUPPORT_ENGINEERS, ROLES.ADMINS, ROLES.SUPERVISORS, ROLES.AUDITORS]}
        component={OperationsRoutes}
      />
      <GroupRestrictedRoute path={SUPPORT.ROOT} group={[ROLES.SERVICE_USERS]} component={SupportRoutes} />
      <GroupRestrictedRoute path={SUPPORT_SUPERVISOR.ROOT} group={[ROLES.SUPERVISORS]} component={RevisionRoutes} />
      <Route path="*" component={NotFoundView} />
    </Switch>
  );
}

export default Routes;
