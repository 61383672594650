import { DataStore } from "@aws-amplify/datastore";
import { Logger } from "@aws-amplify/core";
import { AssetAttribute, Attribute } from "models";

const logger = new Logger("AttributeDialog");

export function fetchAttributes(name) {
  return DataStore.query(Attribute, (a) => a.name("beginsWith", name));
}

export function createAttribute(name) {
  return DataStore.save(new Attribute({ id: name, name }));
}

export function createAssetAttribute(asset, attribute, value) {
  return DataStore.save(
    new AssetAttribute({
      asset,
      attribute,
      value,
    })
  );
}

export async function updateAssetAttribute(id, newValue) {
  let assetAttribute = await DataStore.query(AssetAttribute, id);
  if (!assetAttribute) {
    logger.warn(`AssetAttribute no encontrado con el ID: ${id}`);
    return assetAttribute;
  }

  assetAttribute = AssetAttribute.copyOf(assetAttribute, (updated) => {
    updated.value = newValue;
    return updated;
  });
  return DataStore.save(assetAttribute);
}

export async function fetchAssetAttribute(assetID) {
  // return await DataStore.query(AssetAttribute, a => a.assetID("eq", assetID));
  const results = await DataStore.query(AssetAttribute, (a) =>
    a.and((a) => [a.assetID("eq", assetID), a.attributeID("eq", "WH_ID")])
  );
  return results;
}
