import getTimerEvent from "graphql-custom/queries/getTimerEvent";
import useGraphQL from "hooks/useGraphQL";
import dayjs from "dayjs";
import updateTimerEventMutation from "graphql-custom/mutations/updateTimerEvent";
import createTimerEventMutation from "graphql-custom/mutations/createTimerEvent";

import getEventCause from "graphql-custom/queries/getEventCause";
import removeProps from "util/removeProps";

export default function useTimerEventGraph() {
  const { runGraphQLOperation } = useGraphQL();

  async function updateLastEvent(lastEventId, embeddedEvents) {
    embeddedEvents = embeddedEvents ? embeddedEvents : [];

    let { getTimerEvent: lastEvent } = await runGraphQLOperation({
      operation: getTimerEvent,
      variables: {
        id: lastEventId,
      },
      notifications: { errorMsg: "Error getTimerEvent" },
    });

    const updatedDuration = Math.abs(dayjs(lastEvent.timestamp).diff());

    //* Update TimerEvent  */
    const { updateTimerEvent } = await runGraphQLOperation({
      operation: updateTimerEventMutation,
      variables: {
        input: {
          id: lastEventId,
          duration: updatedDuration,
          finalized: true,
        },
      },
      notifications: { errorMsg: "Error al actualizar el evento del timer" },
    });

    let updatedEmbeddedEvents = [...embeddedEvents];
    const embeddedEventIndex = updatedEmbeddedEvents.findIndex((event) => event.id === lastEventId);
    const updatedVersion = isNaN(updateTimerEvent._version) ? 1 : updateTimerEvent._version + 1;
    updatedEmbeddedEvents[embeddedEventIndex] = {
      ...updatedEmbeddedEvents[embeddedEventIndex],
      _version: updatedVersion,
      duration: updatedDuration,
      finalized: true,
    };

    return { updateTimerEvent, updatedEmbeddedEvents };
  }

  async function newTimerEventGraphQL(taskId, newValues, embeddedEvents) {
    embeddedEvents = embeddedEvents ? embeddedEvents : [];
    const data = {
      taskID: taskId,
      ...newValues,
      finalized: false,
      timestamp: Date.now(),
      createdAt: new Date().toISOString(),
      duration: 0,
    };

    //**  NewTimerEvent GraphQL */
    const notificactionsNew = { errorMsg: "Error al pausar el temporizador." };
    const { createTimerEvent: newTimerEvent } = await runGraphQLOperation({
      operation: createTimerEventMutation,
      variables: {
        input: {
          ...data,
        },
      },
      notifications: notificactionsNew,
    });
    //**  NewTimerEvent GraphQL */

    const eventCause = await handlerEventCause(newTimerEvent.eventCauseID);
    const [embeddedEventCause] = removeProps([eventCause], {
      propsToDelete: ["_lastChangedAt", "_deleted", "updatedAt"],
    });

    const newEmbeddedLastEvent = {
      ...removeProps([newTimerEvent], {
        propsToDelete: ["_lastChangedAt", "_deleted", "updatedAt", "cause"],
      })[0],
      _version: 1,
      embeddedCause: embeddedEventCause,
    };

    const updatedEmbeddedEvents = [...embeddedEvents, newEmbeddedLastEvent];

    return {
      newTimerEvent,
      newEmbeddedLastEvent,
      updatedEmbeddedEvents,
    };
  }

  async function handlerEventCause(eventCauseId) {
    const { getEventCause: startEventCause } = await runGraphQLOperation({
      operation: getEventCause,
      variables: {
        id: eventCauseId,
      },
      notifications: { errorMsg: "Error getEventCause" },
    });
    return startEventCause;
  }

  return {
    updateLastEvent,
    newTimerEventGraphQL,
  };
}
