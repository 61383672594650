export const updateMaterialCategory = /* GraphQL */ `
  mutation UpdateMaterialCategory($input: UpdateMaterialCategoryInput!) {
    updateMaterialCategory(input: $input) {
      id
      name
      abbreviation
      description
      createdAt
      updatedAt
      _version
      _deleted
    }
  }
`;

export default updateMaterialCategory;
