export const listCompanies = /* GraphQL */ `
  query ListCompanies($filter: ModelCompanyFilterInput, $limit: Int, $nextToken: String) {
    listCompanys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        enabled
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        projects {
          items {
            id
            name
            code
            contractNumber
            description
            enabled
            startAt
            endAt
            _deleted
            _lastChangedAt
            _version
          }
        }
      }
      nextToken
    }
  }
`;
