import * as Sentry from "@sentry/capacitor";
import { RewriteFrames } from "@sentry/integrations";
import { BrowserTracing } from "@sentry/tracing";
import { Auth } from "@aws-amplify/auth";
import axios from "axios";
import { APP_PLATFORM } from "constant/appPlatform";

const rewriteFramesIntegration = new RewriteFrames({
  iteratee: (frame) => {
    if (frame.filename) {
      frame.filename = frame.filename
        .replace(/^file\:\/\//, "")
        .replace(/^address at /, "")
        .replace(/^.*\/[^\.]+(\.app|CodePush|.*(?=\/))/, "");
      // Add additional regex here to fix the `at` issue

      const appPrefix = "app:///";
      frame.filename =
        frame.filename.indexOf("/") === 0
          ? `${appPrefix}static/js${frame.filename}`
          : `${appPrefix}static/js/${frame.filename}`;
    }
    return frame;
  },
});

function initSentry(userId) {
  return axios("/manifest.json").then((manifest) => {
    const sentryDsn = process.env.REACT_APP_SENTRY_DSN;
    const env = process.env.REACT_APP_ENV;
    const brand = process.env.REACT_APP_WHITE_LABEL_APP;
    const isMobilePlatform = process.env.REACT_APP_APP_PLATFORM === APP_PLATFORM.MOBILE;

    Sentry.init({
      dsn: sentryDsn,
      release: `indika-fsm@${manifest.data.version}`,
      dist: `${manifest.data.distribution}`,
      environment: env,
      integrations: [new BrowserTracing(), rewriteFramesIntegration],
      tracesSampleRate: 0.2,
      enableNative: isMobilePlatform,
      enableNativeCrashHandling: isMobilePlatform,
    });
    Sentry.setTag("brand", brand);

    if (userId) {
      Sentry.setTag("userId", userId);
    }
  });
}

export function getUserAndInitSentry() {
  return Auth.currentAuthenticatedUser()
    .then((auth) => initSentry(auth.username))
    .catch(() => initSentry());
}

export async function captureException(error, context = "captureException", tags = {}) {
  if (!error) {
    return;
  }
  let message = error.message || error.errorMessage || JSON.stringify(error);
  // NOTE: Elimina caracter U+2019 debido a que no es soportado por Sentry.
  message = message.replace(/\u2019/g, "");
  const brand = process.env.REACT_APP_WHITE_LABEL_APP;
  const { username: userId } = await Auth.currentAuthenticatedUser();
  Sentry.captureException(new Error(`${context}: ${message}`), (scope) => {
    scope.clear();
    scope.setTag("brand", brand);
    scope.setTag("userId", userId);
    scope.setTags(tags);
    return scope;
  });
}
