import * as ActionTypes from "redux/action";
import * as queries from "graphql-custom/queries";
import * as mutations from "graphql-custom/mutations";

export const getUsers = (nextToken) => {
  const SUCCESS_TYPE = nextToken ? ActionTypes.USERS_FETCH_NEXT_SUCCESS : ActionTypes.USERS_FETCH_ALL_SUCCESS;
  return {
    filter: "graphql",
    operation: queries.listAllUsers,
    operationParams: { nextToken, limit: 500 },
    types: [ActionTypes.USERS_FETCH_ALL_PENDING, SUCCESS_TYPE, ActionTypes.USERS_FETCH_ALL_ERROR],
  };
};

export const getUserById = (userId) => ({
  filter: "graphql",
  operation: queries.getUser,
  operationParams: { id: userId },
  types: [
    ActionTypes.USERS_FETCH_DETAILS_PENDING,
    ActionTypes.USERS_FETCH_DETAILS_SUCCESS,
    ActionTypes.USERS_FETCH_DETAILS_ERROR,
  ],
});

export const getRoles = () => ({
  filter: "graphql",
  operation: queries.listRoles,
  types: [null, ActionTypes.USERS_FETCH_ROLES_SUCCESS, null],
});

export const addRoleToUser = (roleId, userId) => ({
  filter: "graphql",
  operation: mutations.assignRoleToUser,
  operationParams: { roleId, userId },
  types: [
    ActionTypes.USERS_ASSIGN_ROLE_PENDING,
    ActionTypes.USERS_ASSIGN_ROLE_SUCCESS,
    ActionTypes.USERS_ASSIGN_ROLE_ERROR,
  ],
});

export const deleteRoleFromUser = (relationId, _version) => ({
  filter: "graphql",
  operation: mutations.removeRoleFromUser,
  operationParams: { userRoleId: relationId, _version },
  types: [
    ActionTypes.USERS_REMOVE_ROLE_PENDING,
    ActionTypes.USERS_REMOVE_ROLE_SUCCESS,
    ActionTypes.USERS_REMOVE_ROLE_ERROR,
  ],
});

export const updateUserStatus = (id, status, _version) => ({
  filter: "graphql",
  operation: mutations.changeUserStatus,
  operationParams: { id, status, _version },
  types: [
    ActionTypes.USERS_SET_STATUS_PENDING,
    ActionTypes.USERS_SET_STATUS_SUCCESS,
    ActionTypes.USERS_SET_STATUS_ERROR,
  ],
});
