export function downloadBlob(blob, filename) {
  let url;
  const isObjectUrl = typeof blob === "string" && blob.includes("blob:");
  if (!isObjectUrl) {
    url = URL.createObjectURL(blob);
  } else {
    url = blob;
  }
  const a = document.createElement("a");
  a.href = url;
  a.download = filename || "download";
  a.addEventListener("click", cleanURLAndListener(a, url), false);
  a.click();
  return a;
}

function cleanURLAndListener(a, url) {
  return () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener("click", cleanURLAndListener);
    }, 150);
  };
}
