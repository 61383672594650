export function formatAssetsSeries(serie = "") {
  if (!serie?.length || serie === "DEFAULT_SERIE") {
    return "Sin serie";
  }
  return serie;
}

export function formatAssetsCode(code = "") {
  if (!code?.length || code === "DEFAULT_CODE") {
    return "Sin código";
  }
  return code;
}

export function handleGetName(assetOrMaterial) {
  let elementName = null;
  if (assetOrMaterial?.hasOwnProperty("asset")) {
    elementName = assetOrMaterial?.asset?.material?.name;
  }
  if (assetOrMaterial?.hasOwnProperty("material")) {
    elementName = assetOrMaterial?.material?.name;
  }
  if (assetOrMaterial?.hasOwnProperty("name")) {
    elementName = assetOrMaterial?.name;
  }

  if (!elementName) return "Sin nombre asignado";
  return elementName;
}

export function handleGetMaterialId(material) {
  let elementId = null;

  elementId = material?.id;
  if (material?.hasOwnProperty("asset")) {
    elementId = material?.asset?.material?.id;
  } else {
    elementId = material?.material?.id;
  }
  if (material?.hasOwnProperty("materialId")) {
    elementId = material?.materialId;
  }
  if (!elementId) return "Sin identificador ID";
  return elementId;
}

export function handleElementId(assetOrMaterial) {
  let elementId = null;
  if (assetOrMaterial?.hasOwnProperty("asset")) {
    elementId = assetOrMaterial?.asset?.id;
  } else {
    elementId = assetOrMaterial?.id;
  }

  if (assetOrMaterial?.hasOwnProperty("assetId")) {
    elementId = assetOrMaterial?.assetId;
  }
  if (!elementId) return "Sin identificador ID";
  return elementId;
}

export function handleGetUOMname(material) {
  let uomName = null;
  if (material?.hasOwnProperty("uom")) {
    if (material?.uom?.hasOwnProperty("name")) uomName = material.uom?.name;
  }

  if (material?.hasOwnProperty("asset")) {
    if (material?.asset?.hasOwnProperty("uom")) {
      if (material?.asset?.uom?.hasOwnProperty("name")) uomName = material.asset.uom?.name;
    }
  }
  if (!uomName) return "Sin unidad";
  return uomName;
}

export function handleGetUomId(material) {
  let uomId = null;
  if (material.hasOwnProperty("asset")) {
    if (material.asset.hasOwnProperty("uom")) {
      uomId = material.asset.uom?.id;
    }
    if (material.asset.hasOwnProperty("uomId")) {
      uomId = material.asset?.uomId;
    }
  }
  if (material.hasOwnProperty("uom")) {
    uomId = material.uom?.id;
  }
  if (material.hasOwnProperty("uomId")) {
    uomId = material?.uomId;
  }
  return uomId;
}

export function handleGetMaterialCode(assetOrMaterial) {
  let code = null;
  if (assetOrMaterial.hasOwnProperty("asset")) {
    code = assetOrMaterial.asset.code || assetOrMaterial.asset.material?.code;
  }
  if (assetOrMaterial.hasOwnProperty("material")) {
    code = assetOrMaterial.material?.code;
  }
  if (assetOrMaterial.hasOwnProperty("code")) {
    code = assetOrMaterial?.code;
  }
  if (!code) code = "Sin código";
  return code;
}

export function handleGetMaterialQuantity(assetOrMaterial) {
  let quantity = null;
  if (assetOrMaterial.hasOwnProperty("asset")) {
    quantity = assetOrMaterial.asset?.quantity;
  }
  if (assetOrMaterial.hasOwnProperty("quantity")) {
    quantity = assetOrMaterial?.quantity;
  }
  if (!quantity) quantity = "Sin cantidad";
  return quantity;
}

export function handleGetLocationByAttributes(asset, { withName = true, withCode = false, withId = false } = {}) {
  let resultObject = {
    locationName: null,
    locationCode: null,
    locationId: null,
  };

  // console.log({ asset });

  if (asset.hasOwnProperty("asset")) {
    if (!asset?.asset?.embeddedAttributes || !asset?.asset?.embeddedAttributes?.length) {
      return resultObject;
    }

    if (withName) {
      resultObject.locationName = asset.asset.embeddedAttributes?.find(
        ({ attributeID }) => attributeID === "WH_NAME"
      )?.value;
    }
    if (withCode) {
      resultObject.locationCode = asset.asset.embeddedAttributes?.find(
        ({ attributeID }) => attributeID === "WH_CODE"
      )?.value;
    }
    if (withId) {
      resultObject.locationId = asset.asset.embeddedAttributes?.find(
        ({ attributeID }) => attributeID === "WH_ID"
      )?.value;
    }
  }

  if (asset.hasOwnProperty("embeddedAttributes")) {
    if (!asset.embeddedAttributes || !asset.embeddedAttributes?.length) {
      return resultObject;
    }

    if (withName) {
      resultObject.locationName = asset.embeddedAttributes?.find(({ attributeID }) => attributeID === "WH_NAME")?.value;
    }
    if (withCode) {
      resultObject.locationCode = asset.embeddedAttributes?.find(({ attributeID }) => attributeID === "WH_CODE")?.value;
    }
    if (withId) {
      resultObject.locationId = asset.embeddedAttributes?.find(({ attributeID }) => attributeID === "WH_ID")?.value;
    }
  }

  // if (!resultObject.locationName) resultObject.locationName = "Sin locación";

  return resultObject;
}

export const FormatUserName = (user, { withEmail = true, withPhone = true } = {}) => {
  const lastnameP = user?.lastnameP + "," || "";
  const phone = user?.phone || "";
  let name = user?.name || "";
  let email = user?.email || "";

  if (user?.name && !user?.lastnameP && withEmail) {
    name = name + ",";
  }

  if (user?.email && user?.phone && withPhone) {
    email = email + ",";
  }

  let result = `${name} ${lastnameP} ${email} ${phone}`;
  if (!withEmail) result = `${name} ${lastnameP} ${phone}`;
  if (!withPhone) result = `${name} ${lastnameP} ${email}`;
  if (!withEmail && !withPhone) result = `${name} ${lastnameP}`;

  return result;
};

export function FormatDirection(address) {
  if (!address || !address.hasOwnProperty("id")) return null;
  return `${address?.street || ""} ${address?.number || ""} ${
    address?.settlement ? "Col. " + address?.settlement : ""
  } ${address?.locality ? "Del. " + address?.locality : ""} ${address?.zipcode ? "C.P " + address?.zipcode : ""} ${
    address?.city ? ", " + address?.city : ""
  }`;
}

export function getAssetTrackingId(asset = null) {
  let trackingId = "unassigned";
  if (!asset || !Object.keys(asset).length) {
    return trackingId;
  }

  if (asset.hasOwnProperty("trackingID") && asset.trackingID && asset.trackingID !== "unassigned") {
    trackingId = asset.trackingID;
    return trackingId;
  }

  if (
    asset.hasOwnProperty("tracking") &&
    asset.tracking &&
    Object.keys(asset.tracking).length &&
    asset.tracking.id !== "unassigned"
  ) {
    trackingId = asset.tracking.id;
    return trackingId;
  }

  return trackingId;
}
