export const listAllUsers = /* GraphQL */ `
  query ListUsers($filter: ModelUserFilterInput, $limit: Int, $nextToken: String) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        middlename
        lastnameP
        lastnameM
        email
        phone
        extension
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
