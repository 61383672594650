import * as ActionTypes from "redux/action";
import * as queries from "graphql-custom/queries";
import * as mutations from "graphql-custom/mutations";

/**
 * Action dispatcher que realiza consulta de listado de sitios (premises).
 *
 */
export const listPremises = (nextToken) => {
  const SUCCESS_TYPE = nextToken ? ActionTypes.PREMISES_FETCH_NEXT_SUCCESS : ActionTypes.PREMISES_FETCH_ALL_SUCCESS;
  return {
    filter: "graphql",
    operation: queries.listPremisesCatalog,
    operationParams: { nextToken },
    types: [ActionTypes.PREMISES_FETCH_ALL_PENDING, SUCCESS_TYPE, ActionTypes.PREMISES_FETCH_ALL_ERROR],
  };
};

/**
 * Action dispatcher que realiza la creación de registros (address, custodian, company) relacionados a un
 * premise.
 *
 */
export const createBeforePremise = (data) => ({
  filter: "graphql",
  operation: mutations.createBeforePremise,
  operationParams: data,
  types: [
    ActionTypes.PREMISES_CREATE_BEFORE_PENDING,
    ActionTypes.PREMISES_CREATE_BEFORE_SUCCESS,
    ActionTypes.PREMISES_CREATE_BEFORE_ERROR,
  ],
});

export const createBeforePremiseClear = (data) => ({
  type: ActionTypes.PREMISES_CREATE_BEFORE_CLEAR,
});

/**
 * Action dispatcher que realiza la creación de un premise.
 *
 */
export const createPremise = (data) => ({
  filter: "graphql",
  operation: mutations.createPremise,
  operationParams: data,
  types: [ActionTypes.PREMISES_CREATE_PENDING, ActionTypes.PREMISES_CREATE_SUCCESS, ActionTypes.PREMISES_CREATE_ERROR],
});

/**
 * Action dispatcher para la limpieza del store de redux que almacena la infor-
 * mación generada durante la creación de un premise.
 *
 */
export const createPremiseClear = () => ({
  type: ActionTypes.PREMISES_CREATE_CLEAR,
});

/**
 * Action dispatcher que realiza la creación de registros (address, custodian, company) relacionados a un
 * premise.
 *
 */
export const getPremise = (premisesId) => ({
  filter: "graphql",
  operation: queries.getPremise,
  operationParams: { premisesId },
  types: [
    ActionTypes.PREMISES_FETCH_DETAILS_PENDING,
    ActionTypes.PREMISES_FETCH_DETAILS_SUCCESS,
    ActionTypes.PREMISES_FETCH_DETAILS_ERROR,
  ],
});

/*
 * Action dispatch para modificar los datos de un sitio (premise, address, custodian, company)
 *
 */
export const updatePremise = (newPromiseData) => ({
  filter: "graphql",
  operation: mutations.updatePremise,
  operationParams: newPromiseData,
  types: [
    ActionTypes.PREMISES_UPDATE_ITEM_PENDING,
    ActionTypes.PREMISES_UPDATE_ITEM_SUCCESS,
    ActionTypes.PREMISES_UPDATE_ITEM_ERROR,
  ],
});

/**
 * Action dispatcher que realiza la creación de un building location.
 *
 */
export const createBuildingLocation = (data) => ({
  filter: "graphql",
  operation: mutations.createBuildingLocation,
  operationParams: { input: data },
  types: [
    ActionTypes.BUILDING_LOCATION_CREATE_PENDING,
    ActionTypes.BUILDING_LOCATION_CREATE_SUCCESS,
    ActionTypes.BUILDING_LOCATION_CREATE_ERROR,
  ],
});

export const updateBuildingLocation = (newLocationData) => ({
  filter: "graphql",
  operation: mutations.updateBuildingLocation,
  operationParams: { input: { ...newLocationData } },
  types: [
    ActionTypes.BUILDING_LOCATION_UPDATE_PENDING,
    ActionTypes.BUILDING_LOCATION_UPDATE_SUCCESS,
    ActionTypes.BUILDING_LOCATION_UPDATE_ERROR,
  ],
});

/**
 * Action dispatcher para la limpieza del store de redux que almacena la infor-
 * mación generada durante la creación de un building location.
 *
 */
export const createBuildingLocationClear = () => ({
  type: ActionTypes.BUILDING_LOCATION_CREATE_CLEAR,
});
