export const getUserTeamDetails = /* GraphQL */ `
  query GetUserTeamDetails($filter: ModelTeamFilterInput, $limit: Int, $nextToken: String) {
    listTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        users {
          items {
            user {
              id
              name
              lastnameP
              email
              status
              roles {
                items {
                  role {
                    id
                    _deleted
                  }
                  _deleted
                }
              }
              _version
              _deleted
            }
            _version
            _deleted
          }
          nextToken
        }
      }
    }
  }
`;
