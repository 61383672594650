import { TASK_TYPES } from "./task/types";

export const S3_KEY_PREFIXES = {
  EVIDENCES_UNCOMPRESSED: "evidences/uncompressed",
  EVIDENCES_COMPRESSED: "evidences/compressed",
};

export const METADATA_KEYS = {
  ATTACHMENT_ID: "attachment_id",
  ATTACHMENT_TYPE: "attachment_type",
  ACTIVITY_ID: "activity_id",
  ACTIVITY_CATEGORY: "activity_category",
};

export const ATTACHMENT_METADATA_TYPES = {
  ATTACHMENT: "attachment",
  ATTACHMENT_ASSET: "attachment_asset",
  ATTACHMENT_TICKET: "attachment_ticket",
};

export const ACTIVITY_CATEGORY = {
  IMPLEMENTATION: "task_implementation",
  MAINTENANCE: "task_maintenance",
  SUPPORT: "task_support",
  TICKET: "ticket",
};

export const ACTIVITY_CATEGORY_BY_TASK_TYPE = {
  [TASK_TYPES.INSTALLATION]: ACTIVITY_CATEGORY.IMPLEMENTATION,
  [TASK_TYPES.SUPPORT]: ACTIVITY_CATEGORY.SUPPORT,
};
