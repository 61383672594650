import useGraphQL from "hooks/useGraphQL";
import { getTask } from "graphql-custom/queries";
import { updateTask } from "graphql-custom/mutations";
import { Logger } from "@aws-amplify/core";
import useOnlineStatus from "@rehooks/online-status";
import useDataStore from "hooks/useDataStore";
import useStoreTaskEvidences from "hooks/useStoreTaskEvidences";

import { AssetType, TaskStatus } from "models";
import * as DS_MODELS from "models";
import removeProps from "util/removeProps";

import dayjs from "dayjs";
import { filterDeletedItems } from "util/lists";
const logger = new Logger("Maintenance-detail-view-api-helpers");

function filterNullAssets(list = []) {
  return list.reduce((taskAssets, current) => {
    if (!!current.asset) {
      taskAssets.push({ ...current });
    } else {
      logger.warn(`Relación TaskAsset (${current.id}) incompleta, no existe Asset asociado`);
    }
    return taskAssets;
  }, []);
}

const useAPIHelpers = () => {
  const { loading, runGraphQLOperation } = useGraphQL();
  const isOnline = useOnlineStatus();
  const DataStoreTask = useDataStore(DS_MODELS.Task);
  const DataStoreTaskAssets = useDataStore(DS_MODELS.TaskAssets);
  const DataStoreInspectionNote = useDataStore(DS_MODELS.InspectionNote);
  const DataStoreRemark = useDataStore(DS_MODELS.Remark);
  const DataStoreUserTask = useDataStore(DS_MODELS.UserTask);
  const DataStoreMaterial = useDataStore(DS_MODELS.Material);
  const DataStoreUnitOfMeassure = useDataStore(DS_MODELS.UnitOfMeassure);
  const DataStoreTracking = useDataStore(DS_MODELS.Tracking);
  const DataStoreAttachmentAsset = useDataStore(DS_MODELS.AttachmentAsset);
  const DataStoreAddress = useDataStore(DS_MODELS.Address);
  const {
    methods: { getAllOfflineTaskEvidences },
  } = useStoreTaskEvidences();

  const isOfflineLoading =
    DataStoreTask.loading ||
    DataStoreTaskAssets.loading ||
    DataStoreInspectionNote.loading ||
    DataStoreRemark.loading ||
    DataStoreUserTask.loading ||
    DataStoreMaterial.loading ||
    DataStoreUnitOfMeassure.loading ||
    DataStoreTracking.loading ||
    DataStoreAttachmentAsset.loading ||
    DataStoreAddress.loading;

  async function fetchMaintenanceData(taskId) {
    let taskData = null;

    if (isOnline) {
      taskData = await fetchDataOnline(taskId);
    } else {
      const result = await fetchDataOffline(taskId);
      taskData = result;
    }

    const offlineTaskEvidences = await getAllOfflineTaskEvidences(taskId);

    const parsedTaskData = formatTaskData(taskData, offlineTaskEvidences);
    return parsedTaskData;
  }

  async function fetchDataOnline(taskId) {
    const data = await runGraphQLOperation({
      operation: getTask,
      variables: {
        id: taskId,
        assetsFilter: {
          _deleted: {
            ne: true,
          },
        },
        assetsLimit: 1000,
      },
      notifications: { errorMsg: "Error cargando la información del mantenimiento, por favor recarge la página" },
    });
    return data.getTask;
  }

  async function fetchDataOffline(taskId) {
    // ===SETEO DE LA INFORMACIÓN PRINCIPAL===
    let taskData = {
      task: null,
      users: null,
      assets: null,
      notes: null,
      remarks: null,
      uoms: null,
    };
    let uoms = null;

    const taskDataKeys = Object.keys(taskData);

    const InitialPromisesKeyPar = {
      task: DataStoreTask.get({ criteria: taskId }),
      users: DataStoreUserTask.get({
        criteria: (taskAsset) => taskAsset.taskID("eq", taskId),
      }),
      assets: DataStoreTaskAssets.get({
        criteria: (taskAsset) => taskAsset.taskAssetsTaskId("eq", taskId),
      }),
      notes: DataStoreInspectionNote.get({
        criteria: (inspectionNote) => inspectionNote.taskID("eq", taskId),
      }),
      remarks: DataStoreRemark.get({ criteria: (remarkData) => remarkData.taskID("eq", taskId) }),
      uoms: DataStoreUnitOfMeassure.get({ criteria: null }),
    };

    //build de array de promesas
    const InitialPromises = taskDataKeys.map((prop) => InitialPromisesKeyPar[prop]);

    await Promise.all(InitialPromises).then((results) => {
      //asignar valor de objeto a las propiedades que son arreglos
      taskDataKeys.reduce((acc, prop, index) => {
        if (acc[prop] !== "task") acc[prop] = {};
        return acc;
      }, taskData);

      //Asignar resultado de promesa
      results.forEach(async (result, index) => {
        const prop = taskDataKeys[index];
        if (prop === "task") taskData.task = removeProps([result], { propsToDelete: defaultPropsToDelete })[0];
        if (prop === "users") taskData.users.items = removeProps(result, { propsToDelete: defaultPropsToDelete });
        if (prop === "assets") taskData.assets.items = removeProps(result, { propsToDelete: defaultPropsToDelete });
        if (prop === "notes")
          taskData.notes.items = removeProps(result, { propsToDelete: defaultPropsToDelete }).map((note) => ({
            ...note,
            createdAt: dayjs(note._lastChangedAt).toISOString(),
          }));
        if (prop === "remarks") taskData.remarks.items = removeProps(result, { propsToDelete: defaultPropsToDelete });
        if (prop === "uoms") uoms = result.map((data) => ({ ...data }));
      });
    });

    // ===2. AÑADIR EXTRA INFO POR ACTIVO===
    const assetsWithExtraData = await Promise.all(
      taskData.assets.items.map(async ({ asset, ...restData }, index) => {
        const parsedAsset = removeProps([asset], { propsToDelete: defaultPropsToDelete })[0];
        const assetUomId = parsedAsset?.assetUomId;
        let uom = null;
        if (assetUomId) {
          uom = uoms.find((uom) => uom.id === assetUomId);
        }
        let assetExtraData = {
          evidences: null,
          material: null,
          tracking: null,
        };

        const assetExtraDataKeys = Object.keys(assetExtraData);

        const extraInfoPromisesObject = getAssetsExtraDataPromises({ asset: parsedAsset, ...restData });
        const promisesArray = assetExtraDataKeys.map((prop) => extraInfoPromisesObject[prop]);
        await Promise.all(promisesArray).then((results) => {
          results.forEach((result, index) => {
            if (!result) return;
            const prop = assetExtraDataKeys[index];
            if (prop === "evidences") {
              assetExtraData.evidences = removeProps(result, { propsToDelete: defaultPropsToDelete });
            }
            if (prop === "material") assetExtraData.material = { ...result };
            if (prop === "tracking") assetExtraData.tracking = { ...result };
          });
        });

        if (!assetExtraData.evidences) assetExtraData.evidences = [];
        if (!assetExtraData.material) assetExtraData.material = {};
        if (!assetExtraData.tracking) assetExtraData.tracking = {};
        const resultObject = {
          asset: { ...parsedAsset, material: assetExtraData.material, tracking: assetExtraData.tracking, uom },
          attachments: { items: assetExtraData.evidences },
          ...restData,
        };

        return resultObject;
      })
    );

    if (assetsWithExtraData && assetsWithExtraData?.length) {
      taskData.assets.items = [...assetsWithExtraData];
    }

    // console.log("===3. AÑADIR INFO DE DIRECCIÓN===");
    const taskPremiseAddressId = taskData.task?.premises?.premisesAddressId;
    if (taskPremiseAddressId) {
      const premiseCopy = { ...taskData.task.premises };
      const taskPremiseAddressData = await DataStoreAddress.get({ criteria: taskPremiseAddressId });
      taskData.task.premises = { ...premiseCopy, address: taskPremiseAddressData };
    }

    delete taskData.uoms;
    const { task, ...restTaskData } = taskData;
    return {
      ...task,
      ...restTaskData,
    };
  }

  function getAssetsExtraDataPromises(assetData) {
    const { asset, ...taskAssetData } = assetData;
    const assetCopy = asset;
    const assetMaterialId = assetCopy?.assetMaterialId;
    const trackingID = assetCopy?.trackingID;
    const isAnAsset = assetCopy?.type === AssetType.ALLOCATED;

    let assetPromises = {};

    if (isAnAsset) {
      assetPromises.evidences = DataStoreAttachmentAsset.get({
        criteria: (attachmentAsset) => attachmentAsset.taskAssetID("eq", taskAssetData.id),
      });
    }
    if (assetMaterialId) assetPromises.material = DataStoreMaterial.get({ criteria: assetMaterialId });
    if (trackingID) assetPromises.tracking = DataStoreTracking.get({ criteria: trackingID });
    return assetPromises;
  }

  async function startMaintenance({ taskId, userId, _version } = {}) {
    let isUpdated = false;
    let response = null;
    if (isOnline) {
      response = await startMaintenanceOnline(taskId, userId, _version);
      if (response?.updateTask && Object.keys(response?.updateTask)?.length) {
        isUpdated = true;
      }
    } else {
      // console.log("Is offline");
      response = await startMaintenanceOffline(taskId, userId);
      if (response && Object.keys(response)?.length) {
        isUpdated = true;
      }
    }
    // console.log("startMaintenance", { isUpdated, response });
    return { isUpdated, response };
  }

  async function startMaintenanceOnline(taskId, userId, _version) {
    return runGraphQLOperation({
      operation: updateTask,
      variables: {
        input: {
          id: taskId,
          status: TaskStatus.IN_PROGRESS,
          _version,
          startedAt: dayjs().toISOString(),
          startedBy: userId,
        },
      },
      notifications: { errorMsg: "Error iniciando el mantenimiento, recargue la página y vuelva a intentarlo." },
    });
  }

  async function startMaintenanceOffline(taskId, userId) {
    // console.log({ taskId, userId });
    return DataStoreTask.update({
      id: taskId,
      values: {
        status: TaskStatus.IN_PROGRESS,
        startedAt: dayjs().toISOString(),
        startedBy: userId,
      },
      notifications: {
        errorMsg: "Error al comenzar el mantenimiento, recargue la página y vuelva a intentarlo.",
      },
    });
  }

  return {
    fetchMaintenanceData,
    loading: loading || isOfflineLoading,
    startMaintenance,
  };
};

export default useAPIHelpers;

export function formatTaskData(taskData, offlineTaskEvidences = []) {
  const { assets, notes, ...maintenanceInfo } = taskData;

  let materialsAndAssets = filterNullAssets(assets?.items);
  materialsAndAssets = filterDeletedItems(materialsAndAssets);

  const filteredAssetsAndMaterials = materialsAndAssets.reduce(
    (accumulator, currentValue, index, array) => {
      if (currentValue.asset.type === "ALLOCATED") {
        accumulator.assets.push(currentValue);
      }
      if (currentValue.asset.type === "MATERIAL_ALLOCATED") {
        accumulator.materials.push(currentValue);
      }
      return accumulator;
    },
    { assets: [], materials: [] }
  );

  const observations = notes.items;
  const { allTaskAttachments, assetsAttachmentsUpdated } = filteredAssetsAndMaterials?.assets?.reduce(
    ({ allTaskAttachments, assetsAttachmentsUpdated }, asset) => {
      const assetAttachments = asset.attachments.items;
      const taskAssetOfflineEvidences = offlineTaskEvidences.filter((evidence) => evidence.taskAssetId === asset.id);
      let filteredAttachments = filterDeletedItems(assetAttachments);
      filteredAttachments = [...taskAssetOfflineEvidences, ...filteredAttachments];

      assetsAttachmentsUpdated.push({ ...asset, attachments: { items: filteredAttachments } });
      filteredAttachments.forEach((attachment) => allTaskAttachments.push({ taskAssetId: asset.id, ...attachment }));
      return { allTaskAttachments, assetsAttachmentsUpdated };
    },
    { allTaskAttachments: [], assetsAttachmentsUpdated: [] }
  );

  return {
    assets: assetsAttachmentsUpdated,
    materials: filteredAssetsAndMaterials.materials,
    observations,
    maintenanceInfo,
    allTaskAttachments,
  };
}

const defaultPropsToDelete = ["warehouseKeepers", "supervisors", "supportEngineers", "task"];
