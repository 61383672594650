import { REQUEST_STATUS } from "constant/requestStatus";
import * as ActionTypes from "redux/action";
import dayjs from "dayjs";
import { filterDeletedItems } from "util/lists";

const INITIAL_STATE = {
  rdxUser: {
    id: "",
    name: "",
    middlename: null,
    lastnameP: null,
    lastnameM: null,
    email: "",
    phone: null,
    extension: null,
    roles: []
  },
  rdxStatus: REQUEST_STATUS.INACTIVE,
  rdxAssignStatus: REQUEST_STATUS.INACTIVE,
  rdxStatusChange: REQUEST_STATUS.INACTIVE,
  rdxRoles: [] 
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case ActionTypes.USERS_FETCH_DETAILS_PENDING:
      return { ...state, rdxStatus: REQUEST_STATUS.PENDING };
    case ActionTypes.USERS_FETCH_DETAILS_SUCCESS:
      let { getUser: user } = action.payload;
      user.roles = transformRolesData(user.roles.items);
      return { ...state, rdxStatus: REQUEST_STATUS.SUCCESSFUL, rdxUser: user };
    case ActionTypes.USERS_FETCH_DETAILS_ERROR:
      return { ...state, rdxStatus: REQUEST_STATUS.FAILED, rdxUser: INITIAL_STATE.rdxUser }; 
    case ActionTypes.USERS_FETCH_ROLES_SUCCESS:
      const { listRoles: { items } } = action.payload;
      const filteredRoles = filterRoles(items, state.rdxUser.roles);
      return { ...state, rdxRoles: filteredRoles };
    case ActionTypes.USERS_ASSIGN_ROLE_PENDING:
    case ActionTypes.USERS_REMOVE_ROLE_PENDING:
      return { ...state, rdxAssignStatus: REQUEST_STATUS.PENDING }; 
    case ActionTypes.USERS_ASSIGN_ROLE_SUCCESS:
      const { rdxUser: { roles } } = state;
      const { payload: { createUserRole: newRole } } = action;
      newRole.createdAt = dayjs(newRole.createdAt).format("DD/MM/YYYY");
      const rdxUser = { ...state.rdxUser, roles: [...roles, newRole] };
      return { ...state, rdxUser, rdxAssignStatus: REQUEST_STATUS.SUCCESSFUL };
    case ActionTypes.USERS_ASSIGN_ROLE_ERROR:
    case ActionTypes.USERS_REMOVE_ROLE_ERROR:
      return { ...state, rdxAssignStatus: REQUEST_STATUS.FAILED };  
    case ActionTypes.USERS_REMOVE_ROLE_SUCCESS:
      const { deleteUserRole: { id } } = action.payload;
      const updatedRoles = state.rdxUser.roles.filter(role => role.id !== id);
      const updatedUser = { ...state.rdxUser, roles: updatedRoles };
      return { ...state, rdxUser: updatedUser, rdxAssignStatus: REQUEST_STATUS.SUCCESSFUL };
    case ActionTypes.USERS_SET_STATUS_PENDING:
      return { ...state, rdxStatusChange: REQUEST_STATUS.PENDING };
    case ActionTypes.USERS_SET_STATUS_SUCCESS:
      const { updateUser: { status, _version } } = action.payload;
      return { ...state, rdxUser: { ...state.rdxUser, status, _version }, rdxStatusChange: REQUEST_STATUS.SUCCESSFUL };
    case ActionTypes.USERS_SET_STATUS_ERROR:
      return { ...state, rdxStatusChange: REQUEST_STATUS.FAILED };  
    default:
      return state;
  }
};

const transformRolesData = roles => {
  return filterDeletedItems(roles).map(({ createdAt, ...rest }) => {
    return { ...rest, createdAt: dayjs(createdAt).format("DD/MM/YYYY") }; 
  });
};

const filterRoles = (roles, userRoles) => {
  const userRoleIds = userRoles.map(({ role }) => role.id);
  return roles.filter(({ id }) => !userRoleIds.includes(id));
};