import API, { graphqlOperation } from "@aws-amplify/api";
import { GraphQLResult } from "@aws-amplify/api";
import { Logger } from "@aws-amplify/core";
import { useCallback } from "react";
import { GraphQLParams, GraphQLOutput } from "util/types/GraphQLHook";
import useLoadingStatus from "./useLoadingStatus";
import useNotifier from "./useNotifier";

const logger = new Logger("useGraphQL");

export default function useGraphQL() {
  const { showMessage, showError } = useNotifier();
  
  const showSuccessMsg = useCallback(successMsg => {
    if (successMsg?.length) {
      showMessage(successMsg);
    }
  }, [showMessage]);

  const showErrorMsg = useCallback(errorMsg => {
    if (errorMsg?.length) {
      showError(errorMsg);
    }
  }, [showError]);

  const _runGraphQLOperation = useCallback(
    async ({ operation, variables, notifications }: GraphQLParams): Promise<any> => {
      try {
        const result = await API.graphql(graphqlOperation(operation, variables)) as GraphQLResult<{ data: Object }>;
        showSuccessMsg(notifications?.successMsg);
        return result.data;
      } catch (error) {
        logger.error(error);
        showErrorMsg(notifications?.errorMsg);
        throw error;
      }
    }, 
    [showSuccessMsg, showErrorMsg]
  );

  const [loading, runGraphQLOperation] = useLoadingStatus<any>(_runGraphQLOperation);
  return { loading, runGraphQLOperation } as GraphQLOutput;
}
