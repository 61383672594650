export const lambdaAdvancedAssetSearch = /* GraphQL */ `
  query AdvancedSearch($input: LambdaAdvancedAssetSearchInput) {
    lambdaAdvancedAssetSearch(input: $input) {
      assets {
        id
        code
        serial
        quantity
        type
        enabled
        _version
        embeddedAttributes {
          attributeID
          id
          name
          value
        }
        material {
          id
          name
          code
          description
        }
        uom {
          id
          name
          abbreviation
          description
          createdAt
          updatedAt
        }
        tracking {
          id
          status
          _lastChangedAt
        }
      }
    }
  }
`;

export default lambdaAdvancedAssetSearch;
