function makeOptionsList(
  items,
  { value, label, secondaryLabel, disabled = false }
) {
  return items.map(i => {
    let oValue, oLabel, oSecondaryLabel, oDisabled;
    if (typeof label === "function") {
      oLabel = label(i);
    } else {
      oLabel = i[label];
    }
    if (typeof value === "function") {
      oValue = value(i);
    } else {
      oValue = i[value];
    }
    if (typeof secondaryLabel === "function") {
      oSecondaryLabel = secondaryLabel(i);
    } else {
      oSecondaryLabel = i[secondaryLabel];
    }
    if (typeof disabled === "function") {
      oDisabled = disabled(i);
    } else {
      oDisabled = i[disabled];
    }

    return {
      value: oValue,
      label: oLabel,
      secondaryLabel: oSecondaryLabel,
      disabled: oDisabled,
      data: i
    };
  });
}

export default makeOptionsList;
