import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ActionTypes from "../redux/action";

function useDataStoreStatus(callback = () => {}) {
  const dataStoreStatus = useSelector((state) => state.app.dataStoreStatus);
  const dataStoreModels = useSelector((state) => state.app.dataStoreModels || []);
  const dataStoreModelsSynced = useSelector((state) => state.app.dataStoreModelsSynced || []);

  const dispatcher = useDispatch();
  const [status, setStatus] = useState({
    current: dataStoreStatus,
    old: dataStoreStatus,
  });

  useEffect(() => {
    if (dataStoreStatus !== status.current) {
      setStatus({
        current: dataStoreStatus,
        old: status.current,
      });
      callback(dataStoreStatus, status.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataStoreStatus]);

  function updateDataStoreStatus(status = "UNSYNCED") {
    dispatcher({
      type: ActionTypes.DATASTORE_STATUS_CHANGE,
      payload: { dataStoreStatus: status },
    });
  }

  function updateDataStoreModels(models = []) {
    dispatcher({
      type: ActionTypes.DATASTORE_MODELS_CHANGE,
      payload: { dataStoreModels: models },
    });
  }

  function updateDataStoreSyncedModels(syncedModelName = []) {
    dispatcher({
      type: ActionTypes.DATASTORE_MODELS_SYNCED_CHANGE,
      payload: { dataStoreModelSyncedName: syncedModelName },
    });
  }

  function resetDataStoreSyncedModels() {
    dispatcher({
      type: ActionTypes.DATASTORE_RESET_SYNCED_MODELS,
    });
  }
  return {
    dataStoreStatus: status.current,
    olddataStoreStatus: status.old,
    dataStoreModels,
    dataStoreModelsSynced,
    updateDataStoreStatus,
    updateDataStoreModels,
    updateDataStoreSyncedModels,
    resetDataStoreSyncedModels,
  };
}

export default useDataStoreStatus;
