import { Logger } from "@aws-amplify/core";
import { DataStore } from "@aws-amplify/datastore";
import { Premises, Company, UserTask } from "models";

const logger = new Logger("DataStore helpers");

async function fetchUserTasksByTask(taskID, page = 0, retry = 0) {
  let userTasks = await DataStore.query(UserTask, (ut) => ut.taskID("eq", taskID), {
    page,
    limit: 500,
  });

  // if (userTasks.length === 5000 && retry < 5) {
  //   const nextResults = await fetchUserTasksByTask(taskID, page + 1, retry + 1);
  //   userTasks = [...userTasks, ...nextResults];
  // }

  return userTasks;
}

export async function populatePremises(tasks = []) {
  let premisesIds = tasks.map((t) => {
    return t?.buildingLocation?.premisesID;
  });

  const premiseSet = new Set(premisesIds);

  premisesIds = Array.from(premiseSet);
  let premisesPromises = premisesIds.map(async (pi) => {
    let premise = await DataStore.query(Premises, pi);
    // Tupla para el mapa del premises
    return [premise.id, premise];
  });

  premisesPromises = await Promise.all(premisesPromises);

  const premisesMap = new Map(premisesPromises);

  tasks = tasks.map((t) => {
    let premiseId = t?.buildingLocation?.premisesID;
    if (premiseId) {
      return { ...t, premise: premisesMap.get(premiseId) };
    }
    return { ...t };
  });

  return tasks;
}

export async function populateCompanies(tasks = []) {
  let companiesIDs = tasks.map((t) => {
    return t?.project?.companyID;
  });

  const companySet = new Set(companiesIDs);
  companiesIDs = Array.from(companySet);

  const queriesPromises = companiesIDs.map(async (companyID) => {
    let company = await DataStore.query(Company, companyID);
    // Tupla para el mapa
    return [company.id, company];
  });

  const companies = await Promise.all(queriesPromises);
  const companiesMap = new Map(companies);

  tasks = tasks.map((t) => {
    let companyID = t?.project?.companyID;
    if (companyID) {
      return {
        ...t,
        project: {
          ...t.project,
          company: companiesMap.get(companyID),
        },
      };
    }
    return { ...t };
  });

  return tasks;
}

export async function populateUsers(tasks = []) {
  tasks = await Promise.all(
    tasks.map(async (task) => {
      const userTasks = await fetchUserTasksByTask(task.id);
      return {
        ...task,
        users: {
          items: userTasks,
        },
      };
    })
  );

  return tasks;
}
