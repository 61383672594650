import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { COUNTER_STATUS } from "../constants";
import { Logger } from "@aws-amplify/core";
const logger = new Logger("Countup");

const Countup = ({ status, statusRef, renderCount, onStopTimer, onResumeTimer, increaseCount, className }) => {
  useEffect(() => {
    if (statusRef.current === COUNTER_STATUS.PAUSED && status === COUNTER_STATUS.RUNNING) {
      logger.debug("Counter: Reanudando temporizador");
      onResumeTimer();
      return;
    }
    if (statusRef.current === COUNTER_STATUS.RUNNING && status === COUNTER_STATUS.PAUSED) {
      logger.debug("Counter: Deteniendo temporizador");
      onStopTimer();

      return;
    }
    if (statusRef.current !== status) {
      statusRef.current = status;
    }

    if (status === COUNTER_STATUS.RUNNING) {
      if (
        renderCount !== "Actividad aún no programada" &&
        renderCount !== "Actividad no iniciada" &&
        renderCount !== "Actividad cancelada"
      ) {
        increaseCount();
      }
    }
  }, [renderCount, status, increaseCount, onResumeTimer, onStopTimer, statusRef]);

  return <Typography className={className}>{renderCount}</Typography>;
};

Countup.propTypes = {
  statusRef: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
  renderCount: PropTypes.string,
  increaseCount: PropTypes.func.isRequired,
  onStopTimer: PropTypes.func,
  onResumeTimer: PropTypes.func,
  className: PropTypes.string,
};
Countup.defaultProps = {
  statusRef: {},
  status: COUNTER_STATUS.PAUSED,
  renderCount: "00:00:00",
  increaseCount: () => {},
  onStopTimer: () => {},
  onResumeTimer: () => {},
};
export default Countup;
