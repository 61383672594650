// Componente para mostrar un listado de QRS al usuario
import React from "react";
import PropTypes from "prop-types";

import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTheme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Box from "@material-ui/core/Box";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import QrContainer from "./QrContainer";

export default function QrsDialog({ open, handleClose, assets }) {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      fullScreen={matches}
      fullWidth={true}
      maxWidth="xl"
      open={open}
      onClose={handleClose}
      aria-labelledby="qrs-dialog"
    >
      <DialogTitle id="max-width-dialog-title">Códigos QR</DialogTitle>
      <DialogContent>
        <div className={classes.mainDialogContainer}>
          <DialogContentText className="advanced-config">
            <a onClick={() => {}} className={classes.actionText}>
              Configuraciones avanzadas
            </a>
          </DialogContentText>
          <Box className="">
            <p>Se descargarán los siguientes QRs</p>
            <div className={classes.QrsContainer}>
              {assets.map((asset) => (
                <QrContainer key={asset.id} asset={asset} />
              ))}
            </div>
          </Box>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="default">
          Cerrar
        </Button>
        <Button onClick={handleClose} color="primary">
          Descargar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  mainDialogContainer: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      "& .advanced-config": {
        order: 0,
      },
    },
    [theme.breakpoints.up("sm")]: {
      "& .advanced-config": {
        order: 1,
      },
    },
  },
  QrsContainer: {
    display: "grid",
    gridGap: "1.5rem",
    gridTemplateColumns: "repeat(auto-fit, minmax(110px, 170px))",
  },
  QrContainer: {
    textAlign: "left",
    lineHeight: "10px",
  },
  actionText: {
    textDecoration: "underline",
    color: "link",
    cursor: "pointer",
  },
}));

const mock = [
  {
    qr: {
      id: "id-que-va-dentro-del-qr",
    },
    code: "test",
    serial: "1234579",
    quantity: 1,
    type: "RECEIVED",
    comment: null,
    material: {
      name: "test",
      code: "test",
    },
  },
  {
    qr: {
      id: "id-que-va-dentro-del-qr",
    },
    code: "test",
    serial: "1234579",
    quantity: 1,
    type: "RECEIVED",
    comment: null,
    material: {
      name: "test",
      code: "test",
    },
  },
  {
    qr: {
      id: "id-que-va-dentro-del-qr",
    },
    code: "test",
    serial: "1234579",
    quantity: 1,
    type: "RECEIVED",
    comment: null,
    material: {
      name: "test",
      code: "test",
    },
  },
  {
    qr: {
      id: "id-que-va-dentro-del-qr",
    },
    code: "test",
    serial: "1234579",
    quantity: 1,
    type: "RECEIVED",
    comment: null,
    material: {
      name: "test",
      code: "test",
    },
  },
  {
    qr: {
      id: "id-que-va-dentro-del-qr",
    },
    code: "test",
    serial: "1234579",
    quantity: 1,
    type: "RECEIVED",
    comment: null,
    material: {
      name: "test",
      code: "test",
    },
  },
];
QrsDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  assets: PropTypes.arrayOf(PropTypes.object),
};

QrsDialog.defaultProps = {
  open: false,
  handleClose: () => {},
  assets: mock,
};
