/**
 * Gestiona el uso de eventos del DOM en React
 *
 * usage:
 * useEventListener("beforeunload", () => console.log("Saliendo de la app"));
 */

import { useEffect, useRef } from "react";

export default function useEventListener(eventType, callback, { element = window, eventOptions = undefined } = {}) {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    if (element == null) return;
    const handler = (e) => callbackRef.current(e);
    element.addEventListener(eventType, handler, eventOptions);

    return () => element.removeEventListener(eventType, handler);
  }, [eventType, element, eventOptions]);
}
