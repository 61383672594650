import React from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";

//material-ui
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

//components
import IdentifyAssetForm from "../IdentifyAssetForm";
import ArrowsDivider from "./ArrowsDivider";
import DataLabel from "components/DataLabel";

//constants
import { DEFAULT_ASSET_FORM_KEYS } from "constant/ticketActivity";

const IdentifyAssetView = ({ reportedAsset, realAssetInField, taskData, assets, setAssets }) => {
  const classes = useStyles();
  const formMethods = useForm({
    defaultValues: DEFAULT_ASSET_FORM_VALUES(realAssetInField.asset),
  });
  const hasNoReportedAsset = !reportedAsset || !Object.keys(reportedAsset).length;
  const hasNoRealAssetInField = !realAssetInField || !Object.keys(realAssetInField).length;
  const assetInFieldSubtitle = hasNoRealAssetInField
    ? "Escanea el activo reportado en campo para validarlo."
    : "Visualiza los datos del activo escaneado.";

  function handleRenderReportedAsset() {
    if (hasNoReportedAsset) {
      return (
        <Typography variant="body1" align="center" style={{ marginTop: "0" }}>
          No se reportó ningún activo.
        </Typography>
      );
    } else {
      return (
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <DataLabel label="Nombre:" data={reportedAsset.name} inOneLine={true} />
          </Grid>
          <Grid item xs={6}>
            <DataLabel label="Serial:" data={reportedAsset.serial} inOneLine={true} />
          </Grid>
          <Grid item xs={12}>
            <DataLabel label="Ubicación:" data={reportedAsset.location} inOneLine={true} />
          </Grid>
        </Grid>
      );
    }
  }

  return (
    <>
      <div className={classes.container}>
        <Typography variant="h6" align="center" style={{ marginTop: "0" }}>
          Activo reportado
        </Typography>

        {handleRenderReportedAsset()}
        <ArrowsDivider />
        <Typography variant="h6" align="center" gutterBottom>
          Activo en campo
          <Typography align="center">{assetInFieldSubtitle}</Typography>
        </Typography>

        <IdentifyAssetForm
          formMethods={formMethods}
          realAssetInField={assets[0]}
          taskPremiseName={taskData.premises?.name}
          assets={assets}
          setAssets={setAssets}
          taskId={taskData.id}
        />
      </div>
    </>
  );
};

IdentifyAssetView.propTypes = {
  reportedAsset: PropTypes.object,
  realAssetInField: PropTypes.object,
  taskData: PropTypes.object,
  assets: PropTypes.array,
  setAssets: PropTypes.func,
};
IdentifyAssetView.defaultProps = {
  reportedAsset: {},
  realAssetInField: {},
  taskData: {},
  assets: [],
  setAssets: () => {},
};

const DEFAULT_ASSET_FORM_VALUES = (realAssetInField) => {
  const formValues = {
    [DEFAULT_ASSET_FORM_KEYS.SERIAL]: "",
    [DEFAULT_ASSET_FORM_KEYS.CODE]: "",
    [DEFAULT_ASSET_FORM_KEYS.NAME]: "",
    [DEFAULT_ASSET_FORM_KEYS.ASSET_LOCATION]: "",
    [DEFAULT_ASSET_FORM_KEYS.COMMENT]: "",
  };

  if (!realAssetInField || !Object.keys(realAssetInField).length) {
    return formValues;
  }
  formValues.serial = realAssetInField.serial || "Sin número de serie";
  formValues.code = realAssetInField.code || realAssetInField.material?.code || "Sin SKU";
  formValues.name = realAssetInField.name || realAssetInField.material?.name || "Sin nombre de activo";
  formValues.asset_location = realAssetInField.assetLocation || "Sin ubicación registrada";
  formValues.comment = realAssetInField.comment || "Sin comentarios registrados.";
  return formValues;
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    gap: "16px",
    flexDirection: "column",
  },
}));

export default IdentifyAssetView;
