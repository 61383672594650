import { REQUEST_STATUS } from "constant/requestStatus";
import * as ActionTypes from "redux/action";
import dayjs from "dayjs";

const INITIAL_STATE = {
  rdxPremises: [],
  rdxNextToken: null,
  rdxStatus: REQUEST_STATUS.INACTIVE,
};

export default function adminPremisesListReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case ActionTypes.PREMISES_FETCH_ALL_PENDING:
      return { ...state, rdxStatus: REQUEST_STATUS.PENDING };
    case ActionTypes.PREMISES_FETCH_ALL_SUCCESS:
      return addPremises(state, action);
    case ActionTypes.PREMISES_FETCH_NEXT_SUCCESS:
      const newState = addPremises(state, action);
      newState.rdxPremises = [...state.rdxPremises, ...newState.rdxPremises];
      return newState;
    case ActionTypes.PREMISES_CREATE_SUCCESS:
      const { createPremises } = action.payload;
      const [newPremise] = transformPremisesData([createPremises]);
      return { ...state, rdxPremises: [...state.rdxPremises, newPremise] };
    case ActionTypes.PREMISES_FETCH_ALL_ERROR:
      return { ...state, rdxStatus: REQUEST_STATUS.FAILED };
    default:
      return state;
  }
}

const addPremises = (state, action) => {
  const {
    listPremisess: { items, nextToken },
  } = action.payload;
  const premises = transformPremisesData(items);
  return {
    ...state,
    rdxStatus: REQUEST_STATUS.SUCCESSFUL,
    rdxPremises: premises,
    rdxNextToken: nextToken,
  };
};

const transformPremisesData = (premises) => {
  return premises.map((premise) => {
    const createdAt = dayjs(premise.createdAt).format("DD/MM/YYYY HH:mm");
    const updatedAt = dayjs(premise.updatedAt).format("DD/MM/YYYY HH:mm");
    return {
      ...premise,
      createdAt,
      updatedAt,
    };
  });
};
