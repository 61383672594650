/* eslint-disable react/display-name */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useParams, Link } from "react-router-dom";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import BackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import FloatingButton from "components/FloatingButton";
import DetailViewButtonAction from "components/validation/MaintenanceDetailView/DetailViewButtonAction";
import SignatureDialog from "components/common/SignatureDialog";
import AttachmentsDialog from "components/common/dialog/AttachmentsDialog";
import useBooleanFlag from "hooks/useBooleanFlag";
import maintenanceStyles from "components/validation/MaintenanceStyles";

import InstallationDialog, { STEPS as INSTALLATION_STEPS } from "components/site/InstallationDialog";
import NewInstallationDialog from "components/site/InstallationDialog/NewInstallationDialog";
import { TASK_STATUS_MAP } from "constant/task/status";

import { ASSETS } from "constant/route/operation";

import DataLabel from "components/DataLabel";

import useHeaderTitle from "hooks/useHeaderTitle";

import cleanUUID from "util/cleanUUID";
import { TaskStatus } from "models";

const SECTION_TITLE = "Instalación de activos";

export default function AssetsInstallationDetailGeneralInfo(props) {
  const {
    fetchingDetail,
    fetchInstallationInfoWrapped,
    fetchInstallationInfo,
    installationDetails,
    setInstallationDetails,
    installationMaterials,
    setInstallationMaterials,
  } = props;

  const [observationsModalOpen, setObservationsModalOpen] = useState(false);
  const [instDialogOpen, setInstDialogOpen] = useState(false);
  const [attachmentsDialogOpen, openAttachmentDialog, closeAttachmentDialog] = useBooleanFlag();
  const [signatureDialogOpen, openSignatureDialog, closeSignatureDialog] = useBooleanFlag();

  const classes = useStyles();
  const maintenanceClases = maintenanceStyles();
  const { id } = useParams();

  useHeaderTitle(`${SECTION_TITLE} ${cleanUUID(id)}`);
  const SignatureS3PrefixKey = `task/${id}`;

  function installationDataSetup(data) {
    let assets = data?.assets || [];
    setInstallationDetails(data);
    setInstallationMaterials(assets);
  }

  function handleObservationsModalVisibility(status) {
    setObservationsModalOpen(status);
  }

  const handleCancelation = () => {
    fetchInstallationInfoWrapped();
    setInstDialogOpen(false);
  };

  function handleInstalationSuccess(newData) {
    setInstDialogOpen(false);
    // Este es un fix que corrije la incidencia que impide visualizar la evidencia de finalización y el material de
    // agregado a la instalación al concluir la actividad
    // TODO: Se debe implementar una solución que incluya la información faltante en el parámetro newData. Este cambio se debe aplicar en las funciones helper de InstallationDialog
    // installationDataSetup(newData);
    fetchInstallationInfo();
  }

  function addOptimisticMaterial(material) {
    // agrega material de manera optimista al ui
    setInstallationMaterials(installationMaterials.concat([material]));
  }

  /**
   * elimina del estado del componente el material con el id indicado
   * @param {string} materialId Id del material que se quiere eliminar del estado
   */
  function deleteOptimisticMaterial(materialId) {
    const filteredMaterials = installationMaterials.filter((material) => material.taskAssets.id !== materialId);
    setInstallationMaterials(filteredMaterials);
  }

  function updateOptimisticMaterial(material) {
    const installationMaterialsCopy = [...installationMaterials];
    const indexToChange = installationMaterials.findIndex((mat) => material.id === mat?.asset?.asset?.id);
    installationMaterialsCopy[indexToChange].asset.asset = material;
    setInstallationMaterials(installationMaterialsCopy);
  }

  function addOptimisticAttachments(attachments) {
    setInstallationDetails((installationDetails) => {
      let newAttachments = [...installationDetails?.attachments];
      if (Array.isArray(attachments)) {
        newAttachments = [...attachments, ...newAttachments];
      } else {
        newAttachments = [attachments, ...newAttachments];
      }
      return {
        ...installationDetails,
        attachments: newAttachments,
      };
    });
  }

  function deleteOptimisticAttachment(attachmentId) {
    const attachments = installationDetails?.attachments?.filter((item) => item.id !== attachmentId);

    setInstallationDetails({
      ...installationDetails,
      attachments,
    });
  }
  function updateOptimisticAttachment(attachment) { }

  function handleStartOrContinueButtonClickButton() {
    setInstDialogOpen(true);
  }

  function handleInstallationUpdate(newData) {
    installationDataSetup(newData);
    fetchInstallationInfo();
  }

  const handleSignatureSuccess = (updtedData) => {
    const { taskData } = updtedData;
    setInstallationDetails((currentStatus) => ({ ...currentStatus, task: { ...currentStatus.task, ...taskData } }));
  };

  const handleAddInspectionNoteSuccess = (updatedData) => {
    const { taskData, newInspectionNote } = updatedData;
    setInstallationDetails((currentStatus) => ({
      ...currentStatus,
      notes: [newInspectionNote, ...currentStatus.notes],
      task: { ...currentStatus.task, ...taskData },
    }));
  };

  let installationStep = null;
  let tableCols = columns;

  switch (installationDetails?.task?.status) {
    case "SCHEDULED":
      installationStep = INSTALLATION_STEPS.BEGIN_INSTALLATION;
      break;
    case "IN_PROGRESS":
    case "WITH_INSPECTION_NOTES":
      installationStep = INSTALLATION_STEPS.INSTALLATION_IN_PROGRESS;
      break;

    default:
      tableCols = completedStatusCols;
  }
  return (
    <>
      <Box mb={2} mt={2}>
        <Card className={classes.card}>
          <CardContent>
            <div className={classes.titleContainer}>
              <IconButton className={classes.button} aria-label="return" component={Link} to={ASSETS.INSTALLATION}>
                <BackspaceIcon />
              </IconButton>
              <Typography variant="h6">INSTALACIÓN {cleanUUID(id)}</Typography>
            </div>
            {showStatusAlert(installationDetails?.task?.status) && (
              <Box mb={2} mt={2}>
                <Alert
                  severity={COLOR_MAPPING[installationDetails?.task?.status]}
                  className={classes.noHorizontalPadding}
                >
                  <div>
                    <p className={classes.noMarginElement}>
                      Instalación
                      <span>
                        <strong>
                          {" "}
                          {installationDetails?.task?.status === TaskStatus.REVIEWED
                            ? "Finalizada"
                            : installationDetails?.task?.status === TaskStatus.FINALIZED
                              ? "Validada"
                              : TASK_STATUS_MAP[installationDetails?.task?.status]}
                        </strong>
                      </span>
                    </p>
                  </div>
                </Alert>
              </Box>
            )}
            {installationDetails?.task?.status === TaskStatus.WITH_INSPECTION_NOTES &&
              installationDetails?.notes?.length > 0 && (
                <Box mb={2} mt={2}>
                  <Alert severity={COLOR_MAPPING.WITH_INSPECTION_NOTES}>
                    <div>
                      <p className={classes.noMarginElement}>
                        Tu instalación tiene observaciones -
                        <span
                          onClick={() => handleObservationsModalVisibility(true)}
                          className={classes.cursorPointerElement}
                        >
                          <strong>Ver Observaciones </strong>
                        </span>
                      </p>
                    </div>
                  </Alert>
                </Box>
              )}
            <Grid container>
              <Grid item md={12}>
                <Box className={classes.sectionTitleContainer}>
                  <div>
                    <img src="/img/location.png" alt="icon" />
                  </div>
                  <p className={classes.sectionTitleText}>Información de la ubicación de instalación</p>
                </Box>
              </Grid>
            </Grid>
            <Divider variant="middle" />

            <Box mb={2} mt={2} pr={2} pl={2}>
              <Grid container spacing={3}>
                <Grid item md={6}>
                  <DataLabel
                    label="FECHA Y HORA DE INICIO"
                    data={`${installationDetails?.task?.startDate} ${installationDetails?.task?.startTime}`}
                    loading={fetchingDetail}
                  />
                </Grid>
                <Grid item md={6}>
                  <DataLabel
                    label="FECHA Y HORA DE FIN"
                    data={`${installationDetails?.task?.endDate} ${installationDetails?.task?.endTime}`}
                    loading={fetchingDetail}
                  />
                </Grid>
                <Grid item md={6}>
                  <DataLabel
                    label="LUGAR DE INSTALACIÓN"
                    data={installationDetails?.premises?.name || "Sin información"}
                    loading={fetchingDetail}
                  />
                </Grid>
                <Grid item md={6}>
                  <DataLabel
                    label="ZONA"
                    data={installationDetails?.task?.buildingLocation?.name || "Sin información"}
                    loading={fetchingDetail}
                  />
                </Grid>

                <Grid item md={12}>
                  <DataLabel
                    label="DIRECCIÓN"
                    data={`${installationDetails?.premises?.address?.street || ""} ${installationDetails?.premises?.address?.number || ""
                      } C.P. ${installationDetails?.premises?.address?.zipCode || ""}, ${installationDetails?.premises?.address?.city || ""
                      } `}
                    loading={fetchingDetail}
                  />
                </Grid>
                <Grid item md={6}>
                  <DataLabel
                    label="RESPONSABLE"
                    data={`${installationDetails?.premises?.custodian?.name || ""} ${installationDetails?.premises?.custodian?.lastnameP || ""
                      } ${installationDetails?.premises?.custodian?.lastnameM || ""} (${installationDetails?.premises?.custodian?.email || ""
                      } , ${installationDetails?.premises?.custodian?.phone || ""})`}
                    loading={fetchingDetail}
                  />
                </Grid>
                <Grid item md={6}>
                  <DataLabel
                    label="SUPERVISOR"
                    data={`${installationDetails?.task?.supervisor?.name || ""} ${installationDetails?.task?.supervisor?.lastnameP || ""
                      } ${installationDetails?.task?.supervisor?.lastnameM || ""} (${installationDetails?.task?.supervisor?.email || ""
                      } , ${installationDetails?.task?.supervisor?.phone || ""})`}
                    loading={fetchingDetail}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2" component="span" color="textSecondary">
                    <b>Instalador(es)</b>
                  </Typography>
                  <ul>
                    {installationDetails?.userTask?.map(({ user }) => (
                      <li key={user.email}>
                        {`${user.name || ""} ${user.lastnameP || ""} ${user.lastnameM || ""} (${user.email || ""} , ${user.phone || ""
                          })`}
                      </li>
                    ))}
                  </ul>
                </Grid>
              </Grid>
            </Box>

            <Grid container>
              <Grid item md={12}>
                <Box className={classes.sectionTitleContainer}>
                  <div>
                    <img src="/img/location.png" alt="icon" />
                  </div>
                  <p className={classes.sectionTitleText}>Información de la instalación</p>
                </Box>
              </Grid>
            </Grid>
            <Divider variant="middle" />
            <Box mb={2} mt={2} pr={2} pl={2}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <DataLabel
                    label="TIPO"
                    data={installationDetails?.task?.taskType?.name || "Sin información"}
                    loading={fetchingDetail}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DataLabel
                    label="DESCRIPCIÓN"
                    data={installationDetails?.task?.taskType?.description || "Sin información"}
                    loading={fetchingDetail}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DataLabel
                    label="CLIENTE"
                    data={
                      installationDetails?.task?.project?.company
                        ? installationDetails.task.project.company.name
                        : "Sin asignación"
                    }
                    loading={fetchingDetail}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DataLabel
                    label="PROYECTO"
                    data={
                      installationDetails?.task?.project
                        ? `${installationDetails.task.project.name} (${installationDetails.task.project.code})`
                        : "Sin asignación"
                    }
                    loading={fetchingDetail}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box textAlign="center">
              {!fetchingDetail &&
                installationDetails?.task?.status !== "FINALIZED" &&
                installationDetails?.task?.status !== "REVIEWED" &&
                installationDetails?.task?.status !== "COMPLETED" && (
                  <FloatingButton
                    bottom={15}
                    right={15}
                    variant="extended"
                    onClick={() => handleStartOrContinueButtonClickButton()}
                    color="primary"
                    btnProps={{ disableElevation: true }}
                  >
                    {(installationDetails?.task?.status === "IN_PROGRESS" ||
                      installationDetails?.task?.status === "WITH_INSPECTION_NOTES") && (
                        <span>CONTINUAR INSTALACIÓN</span>
                      )}
                    {installationDetails?.task?.status === "SCHEDULED" && <span>INICIAR INSTALACIÓN</span>}
                  </FloatingButton>
                )}
            </Box>
            <Box display="flex" justifyContent="flex-end">
              {installationDetails?.task?.status === TaskStatus.COMPLETED && (
                <Box className={maintenanceClases.actionsContainer} marginRight="6px">
                  <DetailViewButtonAction
                    networkTooltipProps={{
                      onlineTitle: "Validar instalación",
                      offlineTitle: "",
                    }}
                    buttonProps={{
                      onClick: openSignatureDialog,
                      color: "primary",
                      variant: "contained",
                      id: "main-action-detail-view-btn",
                      disabled: fetchingDetail,
                    }}
                  >
                    VALIDAR INSTALACIÓN
                  </DetailViewButtonAction>
                </Box>
              )}
              <Button variant="outlined" onClick={openAttachmentDialog} disabled={fetchingDetail}>
                VER EVIDENCIAS
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Box>

      <Dialog
        maxWidth="md"
        fullWidth
        onClose={() => handleObservationsModalVisibility(false)}
        aria-labelledby="simple-dialog-title"
        open={observationsModalOpen}
      >
        <DialogTitle id="simple-dialog-title">Observaciones</DialogTitle>
        <Divider variant="middle" />
        <Box p={2} ml={2} mr={2}>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <DataLabel
                label="SUPERVISOR"
                data={`${installationDetails?.task?.supervisor?.name || ""} ${installationDetails?.task?.supervisor?.lastnameP || ""
                  } ${installationDetails?.task?.supervisor?.lastnameM || ""} (${installationDetails?.task?.supervisor?.email || ""
                  } , ${installationDetails?.task?.supervisor?.phone || ""})`}
                loading={fetchingDetail}
              />
            </Grid>
            <strong>Comentarios</strong>
            {installationDetails?.notes &&
              installationDetails?.notes?.map((observation) => (
                <Grid key={observation.id} item md={12}>
                  {observation.comments}
                </Grid>
              ))}
          </Grid>
          <Box textAlign="right">
            <Button variant="contained" color="primary" onClick={() => handleObservationsModalVisibility(false)}>
              Continuar
            </Button>
          </Box>
        </Box>
      </Dialog>
      {/* <InstallationDialog
        open={instDialogOpen}
        step={installationStep}
        onCancel={handleCancelation}
        onSuccess={handleInstalationSuccess}
        onInstallationUpdate={handleInstallationUpdate}
        installationDetails={installationDetails}
        installationMaterials={installationMaterials}
        addOptimisticMaterial={addOptimisticMaterial}
        deleteOptimisticMaterial={deleteOptimisticMaterial}
        updateOptimisticMaterial={updateOptimisticMaterial}
        addOptimisticAttachments={addOptimisticAttachments}
        deleteOptimisticAttachment={deleteOptimisticAttachment}
        updateOptimisticAttachment={updateOptimisticAttachment}
        taskId={installationDetails?.task?.id}
      /> */}
      <NewInstallationDialog
        open={instDialogOpen}
        step={installationStep}
        taskId={installationDetails?.task?.id}
        installationDetails={installationDetails}
        installationMaterials={installationMaterials}
        onCancel={handleCancelation}
        onSuccess={handleInstalationSuccess}

        onInstallationUpdate={handleInstallationUpdate}

        addOptimisticAttachments={addOptimisticAttachments}
        addOptimisticMaterial={addOptimisticMaterial}
        deleteOptimisticAttachment={deleteOptimisticAttachment}
        deleteOptimisticMaterial={deleteOptimisticMaterial}
        updateOptimisticAttachment={updateOptimisticAttachment}
        updateOptimisticMaterial={updateOptimisticMaterial}
      />
      <AttachmentsDialog
        attachments={installationDetails?.attachments}
        withSections={true}
        open={attachmentsDialogOpen}
        onClose={closeAttachmentDialog}
        fullWidth={true}
        maxWidth="md"
      />

      <SignatureDialog
        isOpen={signatureDialogOpen}
        onClose={closeSignatureDialog}
        taskId={id}
        onSigned={handleSignatureSuccess}
        onAddInspectionNote={handleAddInspectionNoteSuccess}
        S3PrefixKey={SignatureS3PrefixKey}
        taskStatusToUpdate={TaskStatus.FINALIZED}
      />
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {},
  card: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(5),
  },
  paper: {
    marginTop: theme.spacing(2),
    width: "100%",
    overflowX: "auto",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  sectionTitleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "10px 15px",
  },
  sectionTitleText: {
    margin: 0,
    fontWeight: "bold",
    fontSize: 18,
    marginLeft: 16,
  },
  cursorPointerElement: {
    cursor: "pointer",
  },
  noMarginElement: {
    margin: 0,
  },
  noHorizontalPadding: {
    padding: "auto 0px",
  },
}));

// pinta la severidad del alert de materialUI
const COLOR_MAPPING = {
  SCHEDULED: "info",
  IN_PROGRESS: "info",
  COMPLETED: "info",
  WITH_INSPECTION_NOTES: "warning",
  FINALIZED: "info",
  REVIEWED: "info",
};

const columns = [
  { title: "ID", field: "id", hidden: true },
  {
    title: "Código",
    field: "code",
    render: (rowData) => <p>{rowData?.asset?.asset?.material?.code || "S/I"}</p>,
  },
  {
    title: "Nombre",
    field: "name",
    render: (rowData) => <p>{rowData?.asset?.asset?.material?.name || "S/I"}</p>,
  },
  {
    title: "Unidad",
    render: (rowData) => <p>{rowData?.asset?.asset?.uom?.name || "S/I"}</p>,
  },
  {
    title: "Cantidad",
    field: "asset.asset.quantity",
  },
];

const completedStatusCols = [
  { title: "ID", field: "id", hidden: true },
  {
    title: "Serie",
    field: "serial",
    render: (rowData) => (
      <p>{rowData?.asset?.asset?.serial === "DEFAULT_SERIE" ? "Sin Serie" : rowData?.asset?.asset?.serial || "S/I"}</p>
    ),
  },
  {
    title: "Código",
    field: "code",
    render: (rowData) => <p>{rowData?.asset?.asset?.material?.code || "S/I"}</p>,
  },
  {
    title: "Nombre",
    field: "name",
    render: (rowData) => <p>{rowData?.asset?.asset?.material?.name || "S/I"}</p>,
  },
  {
    title: "Unidad",
    render: (rowData) => <p>{rowData?.asset?.asset?.uom?.name || "S/I"}</p>,
  },
  {
    title: "Cantidad",
    field: "asset.asset.quantity",
  },
];

const showStatusAlert = (status) =>
  [
    TaskStatus.SCHEDULED,
    TaskStatus.IN_PROGRESS,
    TaskStatus.COMPLETED,
    TaskStatus.FINALIZED,
    TaskStatus.REVIEWED,
  ].includes(status);

AssetsInstallationDetailGeneralInfo.propTypes = {
  fetchingDetail: PropTypes.any,
  installationDetails: PropTypes.object,
  fetchInstallationInfoWrapped: PropTypes.bool,
  fetchInstallationInfo: PropTypes.func,
  setInstallationDetails: PropTypes.func,
  installationMaterials: PropTypes.object,
  setInstallationMaterials: PropTypes.func,
};
