import { useState, useCallback } from "react";

function useForm(initialData = {}, callback) {
  const [values, _setValues] = useState(initialData);

  const handleSubmit = useCallback(
    (event) => {
      if (event) event.preventDefault();
      callback();
    },
    [callback]
  );

  const setValue = useCallback(
    (name, value) => {
      _setValues((values) => ({
        ...values,
        [name]: value,
      }));
    },
    [_setValues]
  );

  const setValues = useCallback(
    (values) => {
      _setValues(values);
    },
    [_setValues]
  );

  const handleChange = useCallback(
    (event) => {
      event.persist();
      _setValues((values) => ({
        ...values,
        [event.target.name]: event.target.value,
      }));
    },
    [_setValues]
  );

  return {
    handleChange,
    handleSubmit,
    setValue,
    setValues,
    values,
  };
}

export default useForm;
