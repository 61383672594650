import React, { useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import useOnlineStatus from "@rehooks/online-status";
import { Logger } from "@aws-amplify/core";
import { Grid, useMediaQuery, useTheme, DialogContentText } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import Card from "components/custom/Card";

import DetailTitle from "components/DetailTitle";

import MessageDialog from "components/ConfirmationDialog";

import useLoadingStatus from "hooks/useLoadingStatus";
import useBooleanFlag from "hooks/useBooleanFlag";
import useNotifier from "hooks/useNotifier";
import useDeviceSettings from "hooks/useDeviceSettings";

import cleanUUID from "util/cleanUUID";
import { MAINTENANCE_ROOT } from "constant/route/validation";
import { MAINTENANCE_ROOT as OPERATION_MANTENANCE_ROOT } from "constant/route/operation";
import { MAINTENANCE_STATUS, UPDATE_TYPES } from "constant/Maintenance";
import * as ROLES from "constant/roles";
import MAINTENANCE_SETTINGS from "constant/settings/maintenance";

import { TaskStatus } from "models";
import dayjs from "dayjs";
import { FormatUserName } from "util/FormatDetails";

import MaintenanceProgressDialog from "../../MaintenanceProgressDialog";
import maintenanceStyles from "../../MaintenanceStyles";
import StatusChip from "../StatusChip";
import GeneralInformation from "../GeneralInformation";
import ActivityDetails from "../ActivityDetails";
import ActivityLog from "../ActivityLog";
import DetailViewButtonAction from "../DetailViewButtonAction";
import MaintenanceFormDialog from "../../MaintenanceFormDialog";
import SignatureDialog from "components/common/SignatureDialog";
import { useAPIHelpers, checkProgressFlowAllowed } from "../helpers";
// import MaintenanceEvidenceDialog from "../../MaintenanceEvidenceDialog";

import TaskValidationDialog from "components/common/TaskValidationDialog";
import { ACTIVITY_CATEGORY, S3_KEY_PREFIXES } from "constant/attachments";
const logger = new Logger("Maintenance-detail-generalInfo");

// eslint-disable-next-line react/prop-types
export default function MaintenanceDetailGeneralInfo({
  type,
  location,
  maintenanceState,
  maintenanceDispatcher,
  loading,
  _fetchAndSetMaintenanceStatesData,
  setMaintenanceStates,
}) {
  const { fetchMaintenanceData, startMaintenance } = useAPIHelpers();
  const [validationDialogOpen, openValidationDialog, closeValidationDialog] = useBooleanFlag();
  const [signatureDialogOpen, openSignatureDialog, closeSignatureDialog] = useBooleanFlag();
  const userId = useSelector(({ session }) => session.userId);
  const maintenanceSettings = useSelector(({ systemConfigs }) => systemConfigs[MAINTENANCE_SETTINGS.ID]);
  const device = useDeviceSettings();

  // Destruc from props
  const { maintenanceInfo, assets, materials, observations } = maintenanceState;

  const [messageDialogState, setMessageDialogState] = useState({
    props: null,
    isOpen: false,
  });
  const { isOpen: messageDialogOpen, props: messageDialogProps } = messageDialogState;

  const { id: taskId } = useParams();
  const cleanedID = cleanUUID(taskId);

  const isOnline = useOnlineStatus();
  const [startingMaintenance, _handleConfirmStartMaintenance] = useLoadingStatus(handleConfirmStartMaintenance);
  const classes = maintenanceStyles();

  const theme = useTheme();
  const attachmentPrefix = `${S3_KEY_PREFIXES.EVIDENCES_UNCOMPRESSED}/${ACTIVITY_CATEGORY.MAINTENANCE}/${taskId}`;
  const matches1024 = useMediaQuery(theme.breakpoints.down(1024));
  const matches480 = useMediaQuery(theme.breakpoints.down(480));
  const { showMessage } = useNotifier();
  // const [fetching, _fetchAndSetMaintenanceStatesData] = useLoadingStatus(fetchAndSetMaintenanceStatesData);

  /***** valores calculados *****/
  const status = maintenanceInfo?.status;
  const startDate = maintenanceInfo?.startDate;
  const startTime = maintenanceInfo?.startTime;

  const taskVersion = maintenanceInfo?._version || location?.state?.taskVersion;
  const isMaintenanceEditable = status === TaskStatus.SCHEDULED && type === "supervisors";
  const primaryButton = getPrimaryButtonOptions(status, type);
  const secondaryButton = getSecondaryButton(status, type);
  const multipleTaskTechnicians = Boolean(maintenanceInfo?.users.items?.length > 1);
  const enableSimultaneousUpdate = isOnline && multipleTaskTechnicians;

  // Getting user data from redux state
  const { rdxUser } = useSelector(
    ({ activeUser }) => activeUser
  );

  // Function to check if you have support-engineers role to allow maintenance initialization
  const isSupportEngineer = rdxUser => {
    const roleNames = rdxUser.roles.map(function(role){
      return role.role.id
    })

    return roleNames.includes('support-engineers')
  }

  // Function to allow initialization of maintenance flow
  const isProgressFlowAllowed = checkProgressFlowAllowed(
    type,
    userId,
    maintenanceSettings[MAINTENANCE_SETTINGS.KEYS.ALLOWED_USERS_TASK_WEB_FLOW]?.value,
    device.getInfo().platform,
    isSupportEngineer(rdxUser)
  );
  const SignatureS3PrefixKey = `task/${taskId}`;

  /** Dialogs */
  const [openMaintenanceDialog, setOpenMaintenanceDialog] = useState(false);
  const [openActivityProgressDialog, setOpenActivityProgressDialog] = useState(false);
  // const [showAssetEvidenceDialog, setShowAssetEvidenceDialog] = useState(false);

  /***** Valores calculados *****/
  const PRIMARY_BUTTON_OPTIONS = {
    INICIAR: {
      text: "INICIAR MANTENIMIENTO",
      action: handleStartMaintenance,
    },
    CONTINUAR: {
      text: "CONTINUAR MANTENIMIENTO",
      action: () => setOpenActivityProgressDialog(true),
    },
    "ACTUALIZAR-PROGRESO": {
      text: "ACTUALIZAR MANTENIMIENTO",
      action: () => setOpenActivityProgressDialog(true),
    },
    VALIDAR_TECNICO: {
      text: "VALIDAR MANTENIMIENTO",
      action: openSignatureDialog,
    },
    FINALIZAR_COORDINADOR: {
      text: "FINALIZAR MANTENIMIENTO",
      action: openValidationDialog,
    },
  };

  async function handleConfirmStartMaintenance() {
    try {
      const { isUpdated } = await startMaintenance({ taskId, _version: taskVersion, userId });
      if (isUpdated) {
        closeMessageDialog();
        //actualizar vista de detalle
        await _fetchAndSetMaintenanceStatesData(taskId);

        //abrir dialog de progreso al confirmar
        setOpenActivityProgressDialog(true);

        showMessage("Actividad comenzada satisfactoriamente", {
          variant: "success",
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  function showDateExpirationWarning() {
    const expiredMaintenance = dayjs().isAfter(`${startDate} ${startTime}`);
    if (expiredMaintenance && status === TaskStatus.SCHEDULED && type === ROLES.SUPPORT_ENGINEERS) {
      return (
        <Alert severity="warning">
          La fecha programada para el inicio del mantenimiento ha sido excedida. Te sugerimos iniciar el mantenimiento.
        </Alert>
      );
    }
    return null;
  }

  function getPrimaryButtonOptions(status, currentUser) {
    if (status === TaskStatus.SCHEDULED && currentUser === ROLES.SUPPORT_ENGINEERS) return "INICIAR";

    if (status === TaskStatus.IN_PROGRESS && currentUser === ROLES.SUPPORT_ENGINEERS) return "CONTINUAR";

    if (status === TaskStatus.WITH_INSPECTION_NOTES && currentUser === ROLES.SUPPORT_ENGINEERS)
      return "ACTUALIZAR-PROGRESO";

    if (status === TaskStatus.COMPLETED && currentUser === ROLES.SUPPORT_ENGINEERS) return "VALIDAR_TECNICO";
    if (status === TaskStatus.REVIEWED && currentUser === ROLES.SUPERVISORS) return "FINALIZAR_COORDINADOR";

    return null;
  }

  function getSecondaryButton(status, currentUser) {
    if (status === TaskStatus.SCHEDULED && currentUser === ROLES.SUPPORT_ENGINEERS) return "MANUALES";
    return null;
  }
  function handleSignatureSuccess(updtedData) {
    const { taskData } = updtedData;
    maintenanceDispatcher({ type: UPDATE_TYPES.INFO, payload: { ...maintenanceInfo, ...taskData } });
  }
  function handleAddInspectionNoteSuccess(updtedData) {
    const { taskData, newInspectionNote } = updtedData;

    const newMaintenaneInfo = { ...maintenanceInfo, ...taskData };
    const newObservations = [newInspectionNote, ...observations];
    maintenanceDispatcher({
      type: UPDATE_TYPES.CUSTOM_REDUCER,
      payload: (state) => ({
        ...state,
        maintenanceInfo: newMaintenaneInfo,
        observations: newObservations,
      }),
    });
  }

  function hideMaintenanceForm() {
    setOpenMaintenanceDialog(false);
  }
  async function validateBeforeStartActivity() {
    const taskData = await fetchMaintenanceData(taskId);
    const isValid = taskData.maintenanceInfo.status === TaskStatus.SCHEDULED;

    if (!isValid) {
      showTaskAlreadyStartedDialog(taskData);
    }
    return isValid;
  }
  function showTaskAlreadyStartedDialog(taskData) {
    let startedBy = taskData.maintenanceInfo.users.items.find(
      ({ userID }) => userID === taskData.maintenanceInfo.startedBy
    );
    startedBy = FormatUserName(startedBy.user);
    const onCancel = () => {
      closeMessageDialog();
      setMaintenanceStates(taskData);
      setOpenActivityProgressDialog(true);
    };

    const props = MESSAGE_PROPS.TASK_ALREADY_STARTED({ startedBy, onCancel, loading: startingMaintenance });

    openMessageDialog(props);
  }
  async function updateDetailViewLocalState({ reducerParams, updateAll = false } = {}) {
    logger.log(`===Actualizando la vista de detalle===`, { updateAll, reducerParams });
    if (updateAll) {
      return _fetchAndSetMaintenanceStatesData(taskId);
    } else {
      maintenanceDispatcher(reducerParams);
    }
  }

  async function handleStartMaintenance() {
    try {
      if (enableSimultaneousUpdate) {
        const isValid = await validateBeforeStartActivity();
        if (!isValid) return;
      }
      const props = MESSAGE_PROPS.START_ACTIVITY_CONFIRMATION({ onConfirm: _handleConfirmStartMaintenance });
      openMessageDialog(props);
    } catch (error) {
      logger.error("handleStartMaintenance", error);
    }
  }
  function closeMessageDialog() {
    setMessageDialogState({
      isOpen: false,
      props: null,
    });
  }

  function openMessageDialog(props) {
    setMessageDialogState({
      isOpen: true,
      props,
    });
  }

  function handleValidationSuccess(data) {
    maintenanceDispatcher({ type: UPDATE_TYPES.INFO, payload: { ...maintenanceInfo, status: data.status } });
    closeValidationDialog();
    openMessageDialog(MESSAGE_PROPS.VALIDATED_TASK_SUCCESSFULLY());
  }

  return (
    <>
      <Card
        header={{
          title: (
            <DetailTitle
              to={type === ROLES.SUPPORT_ENGINEERS ? OPERATION_MANTENANCE_ROOT : MAINTENANCE_ROOT}
              title={`Mantenimiento ${cleanedID}`}
            />
          ),
          //si el status es distinto de SCHEDULED, entonces no se podrá editar
          actions: isMaintenanceEditable ? [{ text: "Editar", onClick: () => setOpenMaintenanceDialog(true) }] : [],
          avatar: (
            <StatusChip
              label={loading ? "Cargando.." : status === TaskStatus.REVIEWED ? "Validado" : MAINTENANCE_STATUS[status]}
              color="primary"
            />
          ),
        }}
        className={classes.sectionSpacing}
        activityFlow={"MAINTENANCE"}
      >
        {showDateExpirationWarning()}

        {/*Detalles del mantenimiento */}
        <GeneralInformation data={maintenanceInfo} isLoading={loading} />

        <ActivityDetails data={maintenanceInfo} isLoading={loading} />

        <ActivityLog data={maintenanceInfo} isLoading={loading} />

        {/*Accions de la vista de detalle */}
        <Grid
          className={classes.actionsContainer}
          container
          justify={matches1024 ? "center" : "flex-end"}
          spacing={matches480 ? 0 : 2}
        >
          {/* *****Botones con acciones secundarios*****/}
          {secondaryButton === "MANUALES" && (
            <Grid item>
              <DetailViewButtonAction
                networkTooltipProps={{
                  onlineTitle: `Repositorio de manuales ${process.env.REACT_APP_GUIDES_REPOSITORY_URL}`,
                }}
                buttonProps={{
                  id: "manuals-repository-btn",
                  onClick: () =>
                    //open drive folder in a new tab
                    window.open(process.env.REACT_APP_GUIDES_REPOSITORY_URL, "REPOSITORIO_DE_MANUALES"),
                  color: matches480 ? "inherit" : "primary",
                  variant: matches480 ? "contained" : "outlined",
                  disabled: loading || !isOnline,
                  className: classes.manualsButton,
                }}
              >
                REPOSITORIO DE MANUALES
              </DetailViewButtonAction>
            </Grid>
          )}

          {/* *****Botón con la acción principal*****/}
          {primaryButton && isProgressFlowAllowed && (
            <Grid item>
              <DetailViewButtonAction
                networkTooltipProps={{
                  onlineTitle: PRIMARY_BUTTON_OPTIONS[primaryButton].text.toLowerCase(),
                  offlineTitle: "",
                }}
                buttonProps={{
                  onClick: PRIMARY_BUTTON_OPTIONS[primaryButton].action,
                  color: "primary",
                  variant: "contained",
                  id: "main-action-detail-view-btn",
                  disabled: loading,
                }}
              >
                {PRIMARY_BUTTON_OPTIONS[primaryButton].text}
              </DetailViewButtonAction>
            </Grid>
          )}
        </Grid>
      </Card>

      {/* Dialog para añadir mantenimiento */}
      <MaintenanceFormDialog
        isOpen={openMaintenanceDialog}
        onClose={hideMaintenanceForm}
        onUpdate={() => _fetchAndSetMaintenanceStatesData(taskId)}
        maintenanceData={maintenanceInfo}
        assets={assets}
        materials={materials}
      />

      <MessageDialog
        open={messageDialogOpen}
        onCancel={closeMessageDialog}
        title="Mensaje."
        loading={loading}
        {...messageDialogProps}
      >
        {messageDialogProps?.content.map((contentText) => (
          <DialogContentText
            key={typeof text === "string" ? contentText : JSON.stringify(contentText).replace(/ /g, "")}
            color="textPrimary"
          >
            {contentText}
          </DialogContentText>
        ))}
      </MessageDialog>

      {/* dialog para continuar con el progreso del matenimiento */}
      <MaintenanceProgressDialog
        isOpen={openActivityProgressDialog}
        attachmentPrefix={attachmentPrefix}
        onClose={() => setOpenActivityProgressDialog(false)}
        maintenanceData={maintenanceState}
        updateDetailViewLocalState={updateDetailViewLocalState}
        loadingMaintenanceData={loading}
        enableSimultaneousUpdate={enableSimultaneousUpdate}
      />

      {/* Dialog para cargar el documento de valiación */}
      <TaskValidationDialog
        taskID={taskId}
        taskVersion={taskVersion}
        validationStatus={TaskStatus.FINALIZED}
        title="Validación de mantenimiento"
        open={validationDialogOpen}
        attachmentRequired
        attachment
        onClose={closeValidationDialog}
        onSuccess={handleValidationSuccess}
      />

      <SignatureDialog
        isOpen={signatureDialogOpen}
        onClose={closeSignatureDialog}
        taskId={taskId}
        onSigned={handleSignatureSuccess}
        onAddInspectionNote={handleAddInspectionNoteSuccess}
        S3PrefixKey={SignatureS3PrefixKey}
        taskStatusToUpdate={TaskStatus.REVIEWED}
      />
    </>
  );
}
const DEFAULT_MESSAGE_PROPS = {
  showOkBtn: false,
  cancelBtnText: "Continuar",
};
const DEFAULT_CONFIRMATION_PROPS = {
  title: "Confirmación.",
};

const MESSAGE_PROPS = {
  TASK_ALREADY_STARTED: ({ startedBy, ...props }) => ({
    title: "Actualización de cambios.",
    content: [
      <span key={startedBy}>
        La actividad ya ha sido iniciada por el usuario: <strong>{startedBy}</strong>.
      </span>,
      "Por lo que no se realizará esta acción.",
      "Al cerrar este cuadro de mensaje el mantenimiento se actualizará automaticamente.",
    ],
    ...DEFAULT_MESSAGE_PROPS,
    ...props,
  }),

  START_ACTIVITY_CONFIRMATION: (props) => ({
    content: ["Estás a punto de comenzar con la atención del mantenimiento.", "¿Seguro que deseas continuar?"],
    ...DEFAULT_CONFIRMATION_PROPS,
    ...props,
  }),

  VALIDATED_TASK_SUCCESSFULLY: (props) => ({
    content: ["El mantenimiento se ha validado correctamente."],
    ...DEFAULT_MESSAGE_PROPS,
    ...props,
  }),

  OBSERVATION_ADDED_SUCCESSFULLY: (props) => ({
    content: ["Se ha agregado la observación correctamente."],
    ...DEFAULT_MESSAGE_PROPS,
    ...props,
  }),
};

MaintenanceDetailGeneralInfo.propTypes = {
  type: PropTypes.string,
  history: PropTypes.object,
  location: PropTypes.object,
  maintenanceState: PropTypes.object,
  loading: PropTypes.bool,
  maintenanceDispatcher: PropTypes.func,
  _fetchAndSetMaintenanceStatesData: PropTypes.func,
  setMaintenanceStates: PropTypes.func,
};
