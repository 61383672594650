// Componente para asociar qrs a activos
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

//material-ui
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import makeStyles from "@material-ui/styles/makeStyles";

//components
import CodeScanner from "components/common/CodeScanner";
import ConfirmationDialog from "components/ConfirmationDialog";
import AssetSelectorDialog from "components/AssetSelectorDialog";
import AssetPanel from "./components/AssetPanel";
import QRPanel from "./components/QRPanel";
import AssetAttributesDialog from "components/AssetAttributesDialog";
import BottomPanel from "../common/CodeReader/BottomPanel";

//custom-hooks
import { fetchTrackingById, associateTrackingWithAsset, fetchAssetByTrackingId } from "datastore";
import useLoadingStatus from "hooks/useLoadingStatus";
import useNotifier from "hooks/useNotifier";

import { CODE_READER_STATUS } from "constant/codeReaderStatus";
import { DataStore } from "@aws-amplify/datastore";
import { Asset } from "models";
import STATUSES from "./constants/status";
import cleanUUID from "util/cleanUUID";

export default function AssociateQRDialog({ isOpen, onClose, onAssociate, selectedAsset }) {
  const classes = useStyles();
  const [qrCodeStatus, setQrCodeStatus] = useState(STATUSES.EMPTY);
  const [assetStatus, setAssetStatus] = useState(STATUSES.EMPTY);
  const [showAssetDialog, setShowAssetDialog] = useState(false); // mostrar modal de activos
  const [showCodeDialog, setShowCodeDialog] = useState(false); // mostrar modal de lectura de QR
  const [showAttributesDialog, setShowAttributesDialog] = useState(false); // mostrar modal de atributos
  const [codeReaderStatus, setCodeReaderStatus] = useState(CODE_READER_STATUS.STOP);
  const [codeDetected, setCodeDetected] = useState("");
  const [qrCode, setQrCode] = useState(null);
  const [auxAsset, setAuxAsset] = useState(null); // aquí se guarda iformación del asset si es que el qr previamente ya tiene una asignación
  const [asset, setAsset] = useState(null);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false); // mostrar modal de confirmación
  const [associating, _associateTrackingWithAsset] = useLoadingStatus(associateTrackingWithAsset);
  const { showMessage } = useNotifier();

  //actualizar estado local del activo y su estatus mediante query
  useEffect(() => {
    (async () => {
      if (selectedAsset) {
        await DataStore.query(Asset, selectedAsset.id);
        setAsset(selectedAsset);
        setAssetStatus(STATUSES.SELECTED);
      } else {
        setAsset(null);
        setAssetStatus(STATUSES.EMPTY);
      }
    })();
  }, [selectedAsset]);

  // funcion que se ejecuta al confirmar la lectura de un QR
  async function fetchQrCode(QR = codeDetected) {
    setCodeReaderStatus(CODE_READER_STATUS.STOP); //terminar lectura de QR
    setShowCodeDialog(false); //cerrar modal
    if (!QR.length) {
      return;
    }
    setQrCodeStatus(STATUSES.LOADING);
    try {
      const qrCodeData = await fetchTrackingById(QR);
      if (!qrCode) {
        const { asset } = await fetchAssetByTrackingId(QR);
        if (asset) {
          setAuxAsset(asset);
          setQrCodeStatus(STATUSES.NOT_AVAILABLE);
          return;
        }
      }
      setQrCode(qrCodeData);
      setQrCodeStatus(qrCodeData ? STATUSES.SELECTED : STATUSES.NOT_AVAILABLE);
    } catch (error) {
      console.error(error);
      setQrCodeStatus(STATUSES.NOT_AVAILABLE);
    }
  }
  // funcion que se ejecuta al iniciar la lectura de un QR
  async function selectQRCode() {
    if (qrCode) {
      return;
    }
    setShowCodeDialog(true);
    setCodeReaderStatus(CODE_READER_STATUS.START);
    setQrCodeStatus(STATUSES.EMPTY);
    setCodeDetected("");
    setAuxAsset(null);
  }

  //mostrar modal al seleccionar activo
  function selectAsset() {
    if (asset) {
      return;
    }
    setShowAssetDialog(true);
  }

  //imprimir texto al decoficar QR
  function handleCodeDetection(code) {
    code = code.text || code;
    setQrCodeStatus("SUCCESS");
    setCodeDetected(code);
  }

  function onRestartReading() {
    setQrCodeStatus(STATUSES.EMPTY);
    setCodeDetected("");
  }

  //actualizar estado y cerrar modal al seleccionar activo
  function handleAssetSelection(assetData) {
    setAsset(assetData);
    setAssetStatus(STATUSES.SELECTED);
    setShowAssetDialog(false);
  }

  //volver estado inicial cuando se cierra el modal
  function handleDialogExited() {
    setCodeReaderStatus(CODE_READER_STATUS.STOP);
    setAsset(null);
    setAssetStatus(STATUSES.EMPTY);
    setQrCode(null);
    setQrCodeStatus(STATUSES.EMPTY);
  }

  //verificar qrCode y asset y mostrar modal de confirmación
  function validateSelectedItems() {
    if (!qrCode || !asset) {
      return;
    }
    setShowConfirmationDialog(true);
  }

  //asociar activo con QR, reset de estado local y mostrar modal de confirmación
  async function associateQrCodeWithAsset() {
    try {
      const updatedData = await _associateTrackingWithAsset(qrCode.id, asset.id);
      showMessage("El código QR se asoció con el activo de forma exitosa.");
      onAssociate({ ...updatedData });
      setCodeDetected("");
      setQrCode(null);
      setAsset(null);
      setAuxAsset(null);
      onClose();
    } catch (error) {
      console.error(error);
    } finally {
      setShowConfirmationDialog(false);
    }
  }

  function patchAssetAttributes(newAttributes) {
    const updated = { ...asset, attributes: { ...asset.attributes } };
    updated.attributes.items = newAttributes;
    setAsset(updated);
  }

  function renderCodeReaderAlertIcon() {
    return qrCodeStatus === STATUSES.LOADING ? <CircularProgress color="inherit" size={20} /> : null;
  }

  return (
    <>
      <Dialog open={isOpen} fullScreen={true} maxWidth="xl" fullWidth onExited={handleDialogExited} disablePortal>
        <DialogTitle>Asociación de QR</DialogTitle>
        <DialogContent dividers>
          <DialogContentText className={classes.text}>
            <strong>Indicaciones:</strong>
          </DialogContentText>
          <Instrucctions />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <QRPanel
                status={qrCodeStatus}
                selectQRCode={selectQRCode}
                qrCode={qrCode}
                classes={classes}
                auxAsset={auxAsset}
              />
            </Grid>
            <Grid item xs={12}>
              <AssetPanel
                status={assetStatus}
                selectAsset={selectAsset}
                classes={classes}
                asset={asset}
                attributesBtnClick={() => setShowAttributesDialog(true)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="default">
            CANCELAR
          </Button>
          <Button onClick={validateSelectedItems} color="primary" disabled={!qrCode || !asset}>
            CONTINUAR
          </Button>
        </DialogActions>
      </Dialog>

      <AssetSelectorDialog
        isOpen={showAssetDialog}
        onClose={() => setShowAssetDialog(false)}
        onSelect={handleAssetSelection}
      />

      <CodeScanner
        open={showCodeDialog}
        title={asset ? `Activo ${cleanUUID(asset.id)}` : ""}
        status={codeReaderStatus}
        onClose={() => setShowCodeDialog(false)}
        onCodeDetected={handleCodeDetection}
        onRestartReading={onRestartReading}
        showPreviewPanel
        bottomPanel={
          <BottomPanel
            emptyCodeMsg="En espera de escaneo"
            codeDetected={codeDetected}
            disabledBtn={qrCodeStatus !== STATUSES.SUCCESS}
            fetchQrCode={fetchQrCode}
          >
            {
              <Box mb={2}>
                <Alert icon={renderCodeReaderAlertIcon()} severity={ASSET_ALERT_SEVERITY[qrCodeStatus]}>
                  {ASSET_ALERT_CONTENT[qrCodeStatus]}
                </Alert>
              </Box>
            }
          </BottomPanel>
        }
      />

      <AssetAttributesDialog
        isOpen={showAttributesDialog}
        asset={asset}
        onClose={() => setShowAttributesDialog(false)}
        onComplete={patchAssetAttributes}
      />

      <ConfirmationDialog
        open={showConfirmationDialog}
        title="Confirmación"
        onConfirm={associateQrCodeWithAsset}
        onCancel={() => setShowConfirmationDialog(false)}
        loading={associating}
      >
        {`¿Confirma que desea vincular el código ${cleanUUID(codeDetected)} con el material ${
          asset?.material?.name
        } con la serie ${asset?.serial}?`}
      </ConfirmationDialog>
    </>
  );
}

//componente de instrucciones
function Instrucctions() {
  return (
    <Box>
      <ol>
        <li>Realize la lectura del código QR (sin vincular).</li>
        <li>Realize la búsqueda del activo que desea vincular.</li>
        <li>Gestione los atributos del activo que desee.</li>
        <li>Revise la información y confirme.</li>
      </ol>
      <p>
        <strong>Importante:</strong>
        La vinculación del código QR y el activo será permanente. Asegure que la correspondencia es correcta.
      </p>
    </Box>
  );
}

AssociateQRDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onAssociate: PropTypes.func,
  selectedAsset: PropTypes.object,
};

AssociateQRDialog.defaultProps = {
  isOpen: false,
  onClose: () => {},
  onAssociate: () => {},
  selectedAsset: null,
};

const ASSET_ALERT_SEVERITY = {
  [STATUSES.EMPTY]: "info",
  [STATUSES.LOADING]: "info",
  [STATUSES.NOT_AVAILABLE]: "warning",
  [STATUSES.SUCCESS]: "success",
};

const ASSET_ALERT_CONTENT = {
  [STATUSES.EMPTY]: "Aún no se ha detectado un QR",
  [STATUSES.LOADING]: "Cargando información del código QR",
  [STATUSES.NOT_AVAILABLE]: "Código QR no encontrado",
  [STATUSES.SUCCESS]: "QR escaneado con éxito",
};

const useStyles = makeStyles((theme) => ({
  buttons: {
    marginBottom: "8px",
  },
  buttonImages: {
    marginRight: "8px",
  },
  text: {
    textAlign: "left",
    color: "#000",
  },
}));
