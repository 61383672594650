export function removeAssetFromTask(inputsArray) {
  // console.log({ inputsArray });
  let mutation = "";
  let updateParams = {};
  let mutationsParams = [];
  if (inputsArray.length > 0) {
    inputsArray.forEach(({ input }, index) => {
      mutationsParams.push(`$input${index}: DeleteTaskAssetsInput!, `);
      mutation += `
        deleteTaskAsset${index}: deleteTaskAssets(input: $input${index}){
          id
          taskAssetsTaskId
          taskAssetsAssetId
          _version
          _lastChangedAt
          _deleted
          asset {
            id
            _version
            _deleted
          }
        }
        `;
      updateParams[`input${index}`] = input;
    });

    mutationsParams = mutationsParams.join(",");
    mutation = `mutation removeAssetFromTaks(${mutationsParams}) {
        ${mutation}
      }
      `;
  }
  // console.log({ mutation, params: updateParams });

  return {
    mutation,
    params: updateParams,
  };
}
