import {
  FETCH_MATERIAL_CATALOG_ERROR,
  FETCH_MATERIAL_CATALOG_PENDING,
  FETCH_MATERIAL_CATALOG_SUCCESS,
  FETCH_NEXT_MATERIAL_CATALOG_SUCCESS,
  CREATE_MATERIAL_SUCCESS,
  CREATE_MATERIAL_PENDING,
  CREATE_MATERIAL_ERROR,
  UPDATE_MATERIAL_SUCCESS,
  UPDATE_MATERIAL_PENDING,
  UPDATE_MATERIAL_ERROR,
  CLEAN_MATERIAL_REDUCER,
  SEARCH_MATERIAL_CATALOG_PENDING,
  SEARCH_MATERIAL_CATALOG_SUCCESS,
  SEARCH_MATERIAL_CATALOG_ERROR,
  SEARCH_NEXT_MATERIAL_CATALOG_SUCCESS,
} from "redux/action";

import { listMaterials } from "graphql-custom/queries/listMaterials";
import { createMaterial } from "graphql-custom/mutations/createMaterial";
import { updateMaterial } from "graphql-custom/mutations/updateMaterial";
import { searchMaterials } from "graphql-custom/queries";

export const fetchMaterialCatalog = (nextToken) => {
  const SUCCESS_TYPE = nextToken ? FETCH_NEXT_MATERIAL_CATALOG_SUCCESS : FETCH_MATERIAL_CATALOG_SUCCESS;
  return {
    filter: "graphql",
    operation: listMaterials,
    operationParams: { nextToken },
    types: [FETCH_MATERIAL_CATALOG_PENDING, SUCCESS_TYPE, FETCH_MATERIAL_CATALOG_ERROR],
    options: { errorMessage: "Error al consultar el catalogo" },
  };
};

export const searchMaterialCatalog = (filter, nextToken) => {
  const SUCCESS_TYPE = nextToken ? SEARCH_NEXT_MATERIAL_CATALOG_SUCCESS : SEARCH_MATERIAL_CATALOG_SUCCESS;
  return {
    filter: "graphql",
    operation: searchMaterials,
    operationParams: { filter, nextToken },
    types: [SEARCH_MATERIAL_CATALOG_PENDING, SUCCESS_TYPE, SEARCH_MATERIAL_CATALOG_ERROR],
    options: { errorMessage: "Error al buscar materiales" },
  };
};

export const createMaterialRequest = ({ name, code, manufacturer, description, categoryId, uomId }) => ({
  filter: "graphql",
  operation: createMaterial,
  operationParams: { name, code, manufacturer, description, categoryId, uomId },
  types: [CREATE_MATERIAL_PENDING, CREATE_MATERIAL_SUCCESS, CREATE_MATERIAL_ERROR],
});

export const updateMaterialRequest = ({ id, name, code, manufacturer, description, categoryId, uomId, _version }) => ({
  filter: "graphql",
  operation: updateMaterial,
  operationParams: {
    id,
    name,
    code,
    manufacturer,
    description,
    categoryId,
    uomId,
    _version,
  },
  types: [UPDATE_MATERIAL_PENDING, UPDATE_MATERIAL_SUCCESS, UPDATE_MATERIAL_ERROR],
});

export const cleanMaterialReducer = () => ({ type: CLEAN_MATERIAL_REDUCER });
