import useDataStore from "hooks/useDataStore";
import useNotifier from "hooks/useNotifier";
import * as models from "models";
import { Logger } from "@aws-amplify/core";
import useOnlineStatus from "@rehooks/online-status";
import { DataStore } from "@aws-amplify/datastore";
import removeProps from "util/removeProps";
import dayjs from "dayjs";

import useGraphQL from "hooks/useGraphQL";
import useTimerEventGraph from "./useTimerEventGraph";
import updateTimerEventMutation from "graphql-custom/mutations/updateTimerEvent";
import updateTaskMutation from "graphql-custom/mutations/updateTask";
import searchTimerEvents from "graphql-custom/queries/searchTimerEvents";
import { getTask } from "graphql-custom/queries";
const logger = new Logger("ApiTimer:useAPIHelpers");

/**
 * * useApiTimerHelpers -> Helper  / Queries for the timer
 * fetchCatalogEventCauseDS -> Catalog list  1.0
 *
 *
 * @Docs https://docs.amplify.aws/lib/q/platform/js/
 */
export default function useApiTimerHelpers(isOnline) {
  const EventCause = useDataStore(models.EventCause);
  const DSTimerEvent = useDataStore(models.TimerEvent);
  const DSTask = useDataStore(models.Task);
  const { showError } = useNotifier();
  const isOnlineA = useOnlineStatus();

  const { runGraphQLOperation } = useGraphQL();
  const { updateLastEvent, newTimerEventGraphQL } = useTimerEventGraph();

  async function updateTimerEvent({
    taskId = null,
    lastEventId = null,
    embeddedLastEvent = null,
    embeddedEvents = null,
    newValues = null,
  } = {}) {
    logger.debug("updateTimerEvent: props input obtenidos", { taskId, lastEventId, newValues });
    //isOnline = false; //remove when online mode be available
    if (!taskId || !lastEventId || !embeddedLastEvent || !embeddedEvents || !newValues) {
      const error = "updateTimerEvent: invalid function input";
      logger.error(error, { taskId, lastEventId, embeddedLastEvent, embeddedEvents, newValues });
      throw new Error(error);
    }

    let result = { success: false, data: null };
    try {
      if (isOnline) {
        result.data = await updateTimerEventOnline(taskId, lastEventId, embeddedLastEvent, embeddedEvents, newValues);
      } else {
        result.data = await updateTimerEventOffline(taskId, lastEventId, embeddedLastEvent, embeddedEvents, newValues);
      }
      if (result.data) {
        result.success = true;
      }
    } catch (error) {
      logger.error("updateTimerEvent", error);
    }
    logger.debug("updateTimerEvent: result", result);
    return result;
  }

  async function updateTimerEventOnline(taskId, lastEventId, embeddedLastEvent, embeddedEvents, newValues) {
    const notificactions = { errorMsg: "Error al actualizar motivo de pausa del temporizador." };

    // get task
    const task = await handlerTask(taskId);

    const newTaskValues = {
      embeddedEvents: [...embeddedEvents],
      embeddedLastEvent,
    };

    let { updateTimerEvent: updatedTimerEvent } = await runGraphQLOperation({
      operation: updateTimerEventMutation,
      variables: {
        input: {
          id: lastEventId,
          ...newValues,
        },
      },
      notifications: notificactions,
    });
    const updatedVersion = isNaN(updatedTimerEvent._version) ? 1 : updatedTimerEvent._version + 1;

    //2. parseo de updatedTimerEvent para obtener lastEvent y embeddedLastEvent
    //newTaskValues.lastEvent = updatedTimerEvent;
    newTaskValues.embeddedLastEvent = {
      ...embeddedLastEvent,
      ...newValues,
      _version: updatedVersion,
    };

    //4.actualizar arreglo embeddedEvents
    const embeddedEventIndex = newTaskValues.embeddedEvents.findIndex(
      (timerEvent) => timerEvent.id === updatedTimerEvent.id
    );
    newTaskValues.embeddedEvents[embeddedEventIndex] = newTaskValues.embeddedLastEvent;

    //5. actualizar modelo Task
    const { updateTask: updatedTask } = await runGraphQLOperation({
      operation: updateTaskMutation,
      variables: {
        input: {
          id: taskId,
          ...newTaskValues,
          _version: task._version,
        },
      },
      notifications: { errorMsg: "Error al actualizar el Task." },
    });

    return {
      updatedTimerEvent,
      updatedTask: updatedTask,
      updatedValues: newTaskValues,
    };
  }
  async function updateTimerEventOffline(taskId, lastEventId, embeddedLastEvent, embeddedEvents, newValues) {
    const notificactions = { errorMsg: "Error al actualizar motivo de pausa del temporizador." };

    const newTaskValues = {
      events: [],
      embeddedEvents: [...embeddedEvents],
      lastEvent: null,
      embeddedLastEvent,
    };

    //1. Actualizar modelo TimerEvent
    logger.debug("updateTimerEventOffline: actualizando timerEvent...");

    let updatedTimerEvent = await DSTimerEvent.update({
      id: lastEventId,
      values: newValues,
      notifications: notificactions,
    });
    const updatedVersion = isNaN(updatedTimerEvent._version) ? 1 : updatedTimerEvent._version + 1;
    logger.debug("updateTimerEventOffline: updatedTimerEvent output", updatedTimerEvent);

    //2. parseo de updatedTimerEvent para obtener lastEvent y embeddedLastEvent
    newTaskValues.lastEvent = updatedTimerEvent;
    newTaskValues.embeddedLastEvent = {
      ...embeddedLastEvent,
      ...newValues,
      _version: updatedVersion,
    };

    //3. actualizar arreglo de events
    newTaskValues.events = await DSTimerEvent.get({ criteria: (event) => event.taskID("eq", taskId) });

    //4.actualizar arreglo embeddedEvents
    const embeddedEventIndex = newTaskValues.embeddedEvents.findIndex(
      (timerEvent) => timerEvent.id === updatedTimerEvent.id
    );
    newTaskValues.embeddedEvents[embeddedEventIndex] = newTaskValues.embeddedLastEvent;

    //5. actualizar modelo Task
    logger.debug("updateTimerEventOffline: actualizando modelo Task...", { newTaskValues });
    const updatedTask = await DSTask.update({
      id: taskId,
      values: newTaskValues,
      notifications: notificactions,
    });
    logger.debug("updateTimerEventOffline: updatedTask output", updatedTask);

    return {
      updatedTimerEvent,
      updatedTask: updatedTask,
      updatedValues: newTaskValues,
    };
  }

  async function handlerUpdateTask(taskId, updatedEmbeddedEvents, newTimerEvent, newEmbeddedLastEvent, effectiveTime) {
    const notificactions = { errorMsg: "Error al actualizar el modelo DSTask, en temporizador." };

    const newTimerEvents = await DSTimerEvent.get({
      criteria: (taskEvents) => taskEvents.taskID("eq", taskId),
    });

    const newTaskValues = {
      events: newTimerEvents,
      embeddedEvents: updatedEmbeddedEvents,
      lastEvent: newTimerEvent,
      embeddedLastEvent: newEmbeddedLastEvent,
      effectiveTime: effectiveTime,
    };

    // update model Task
    const updatedTask = await DSTask.update({
      id: taskId,
      values: newTaskValues,
      notifications: notificactions,
    });
    logger.debug("handlerUpdateTask: data", { updatedTask, newTaskValues });
    return { updatedTask, newTaskValues };
  }

  async function handlerTask(taskId) {
    const { getTask: task } = await runGraphQLOperation({
      operation: getTask,
      variables: {
        id: taskId,
        assetsFilter: {
          _deleted: {
            ne: true,
          },
        },
        assetsLimit: 1000,
      },
      notifications: { errorMsg: "Error getTask" },
    });
    return task;
  }

  /**
   * * Update Task with Internet connection
   * @param {*} taskId
   * @param {*} updatedEmbeddedEvents
   * @param {*} newTimerEvent
   * @param {*} newEmbeddedLastEvent
   * @param {*} effectiveTime
   * @returns
   */
  async function handlerUpdateTaskGraphQL(
    taskId,
    updatedEmbeddedEvents,
    newTimerEvent,
    newEmbeddedLastEvent,
    effectiveTime
  ) {
    const notificactions = { errorMsg: "Error al actualizar el modelo Task Graphql, en temporizador." };

    // get task
    const task = await handlerTask(taskId);

    const newTaskValues = {
      embeddedEvents: updatedEmbeddedEvents,
      embeddedLastEvent: newEmbeddedLastEvent,
      taskLastEventId: newTimerEvent.id,
      effectiveTime: effectiveTime,
      _version: task._version,
    };

    //*** Update Task  */
    const { updateTask: updatedTask } = await runGraphQLOperation({
      operation: updateTaskMutation,
      variables: {
        input: {
          id: taskId,
          ...newTaskValues,
        },
      },
      notifications: notificactions,
    });
    updatedTask.notes = updatedTask.notes.items;

    logger.debug("handlerUpdateTask: data", { updatedTask, newTaskValues });
    return { updatedTask, newTaskValues };
  }

  /**

   * *  Return false if there is no errors or the properties are missing
   * @returns boolean 
   */
  async function handlerErrorValid(taskId, newValues) {
    if (!taskId || !newValues) {
      const error = "updateTimerEvent: invalid function input";
      logger.error(error, { taskId, newValues });
      throw new Error(error);
    } else {
      return false;
    }
  }

  /**
   * *  Get Catalos Events Causes
   * @returns eventCause []
   */
  async function fetchCatalogEventCauseDS() {
    try {
      let eventCausesM = await EventCause.get({
        criteria: (eventType) => eventType.type("eq", "PAUSE"),
      });

      const eventCause = await Promise.all(
        eventCausesM.map(async (ut) => {
          return await { ...ut };
        })
      );

      return { eventCause };
    } catch (error) {
      logger.error(error);
      showError("Ocurrió un error al consultar el listado de causas para detener actividad.");
    }
  }

  /**
   * * This method is the initial call stop timer and validate online status
   * @param {*} taskId string
   * @param {*} taskEvents[]
   * @param {*} newValues obj
   * @param {*} effectiveTime number
   * @param {*} lastEventId string
   * @returns
   */
  async function stopTimerEventHelper(data) {
    const { taskId, embeddedEvents = [], newValues, effectiveTime, lastEventId } = data;
    handlerErrorValid(taskId, newValues);
    let result = { success: false, data: null };
    //const isOnlineA = false;
    try {
      if (isOnlineA) {
        result.data = await stopTimerEventGraphQL(taskId, embeddedEvents, newValues, effectiveTime, lastEventId);
      } else {
        result.data = await stopTimerEventDS(taskId, embeddedEvents, newValues, effectiveTime, lastEventId);
      }
      if (result.data) {
        result.success = true;
      }
    } catch (error) {
      logger.error(error);
      showError("Ocurrió un error al registrar el evento.");
    }
    result.success = result?.data ? true : false;
    return result;
  }

  /**
   *
   * @param {*} taskId
   * @param {*} taskEvents[]
   * @param {*} newValues
   * @returns
   */
  async function stopTimerEventDS(taskId, embeddedEvents, newValues, effectiveTime, lastEventId) {
    if (lastEventId) {
      const { updatedEmbeddedEvents } = await handleUpdateLastEvent(lastEventId, embeddedEvents);
      embeddedEvents = updatedEmbeddedEvents;
    }

    //crear nuevo evento de pausa
    const { newTimerEvent, newEmbeddedLastEvent, updatedEmbeddedEvents } = await handleNewEventCreation(
      taskId,
      newValues,
      embeddedEvents
    );

    //actualizar task
    const { updatedTask, newTaskValues } = await handlerUpdateTask(
      taskId,
      updatedEmbeddedEvents,
      newTimerEvent,
      newEmbeddedLastEvent,
      effectiveTime
    );

    return { newTimerEvent, updatedTask, newTaskValues };
  }

  async function stopTimerEventGraphQL(taskId, embeddedEvents, newValues, effectiveTime, lastEventId) {
    if (lastEventId) {
      const { updatedEmbeddedEvents } = await updateLastEvent(lastEventId, embeddedEvents);
      embeddedEvents = updatedEmbeddedEvents;
    }

    //crear nuevo evento de pausa
    const { newTimerEvent, newEmbeddedLastEvent, updatedEmbeddedEvents } = await newTimerEventGraphQL(
      taskId,
      newValues,
      embeddedEvents
    );

    //actualizar task
    const { updatedTask, newTaskValues } = await handlerUpdateTaskGraphQL(
      taskId,
      updatedEmbeddedEvents,
      newTimerEvent,
      newEmbeddedLastEvent,
      effectiveTime
    );

    return { newTimerEvent, updatedTask, newTaskValues };
  }

  /**
   * * This method is the initial call restart timer and validate online status
   * @param {*} taskId string
   * @param {*} taskEvents[]
   * @param {*} newValues obj
   * @param {*} effectiveTime number
   * @param {*} lastEventId string
   * @returns
   */
  async function restartTimerEventHelper(data) {
    //const { taskId, taskEvents, newValues, effectiveTime, lastEventId } = data;
    const { taskId, embeddedEvents, newValues, effectiveTime, lastEventId } = data;
    handlerErrorValid(taskId, newValues);
    let result = { success: false, data: null };
    //const isOnlineA = false;
    try {
      if (isOnlineA) {
        result.data = await restartTimerEventGraphQL(taskId, embeddedEvents, newValues, effectiveTime, lastEventId);
      } else {
        result.data = await restartTimerEventDS(taskId, embeddedEvents, newValues, effectiveTime, lastEventId);
      }
      if (result.data) {
        result.success = true;
      }
    } catch (error) {
      logger.error(error);
      showError("Ocurrió un error al registrar el evento.");
    }
    return result;
  }

  /**
   * *  The function is called when the timer restart and there is an internet connection.
   * @param {*} taskId string
   * @param {*} taskEvents[]
   * @param {*} newValues obj
   * @param {*} effectiveTime number
   * @param {*} lastEventId string
   */
  async function restartTimerEventGraphQL(taskId, embeddedEvents, newValues, effectiveTime, lastEventId) {
    // actualizar último evento / evento anterior
    if (lastEventId) {
      const { updatedEmbeddedEvents } = await updateLastEvent(lastEventId, embeddedEvents);
      embeddedEvents = updatedEmbeddedEvents;
    }

    //crear nuevo evento de reanudación
    const { newTimerEvent, newEmbeddedLastEvent, updatedEmbeddedEvents } = await newTimerEventGraphQL(
      taskId,
      newValues,
      embeddedEvents
    );

    //actualizar task
    const { updatedTask, newTaskValues } = await handlerUpdateTaskGraphQL(
      taskId,
      updatedEmbeddedEvents,
      newTimerEvent,
      newEmbeddedLastEvent,
      effectiveTime
    );

    return { newTimerEvent, updatedTask, newTaskValues };
  }

  /**
   * * the function is called when there is no internet connection
   * @param {*} taskId string
   * @param {*} taskEvents[]
   * @param {*} newValues obj
   * @param {*} effectiveTime number
   * @param {*} lastEventId string
   */
  async function restartTimerEventDS(taskId, embeddedEvents, newValues, effectiveTime, lastEventId) {
    // actualizar último evento / evento anterior
    if (lastEventId) {
      const { updatedEmbeddedEvents } = await handleUpdateLastEvent(lastEventId, embeddedEvents);
      embeddedEvents = updatedEmbeddedEvents;
    }

    //crear nuevo evento de reanudación
    const { newTimerEvent, newEmbeddedLastEvent, updatedEmbeddedEvents } = await handleNewEventCreation(
      taskId,
      newValues,
      embeddedEvents
    );

    //actualizar task
    const { updatedTask, newTaskValues } = await handlerUpdateTask(
      taskId,
      updatedEmbeddedEvents,
      newTimerEvent,
      newEmbeddedLastEvent,
      effectiveTime
    );

    return { newTimerEvent, updatedTask, newTaskValues };
  }
  return {
    fetchCatalogEventCauseDS,
    stopTimerEventHelper,
    updateTimerEvent,
    restartTimerEventHelper,
  };
}

export async function handleUpdateLastEvent(lastEventId, embeddedEvents) {
  embeddedEvents = embeddedEvents ? embeddedEvents : [];

  let lastEvent = await DataStore.query(models.TimerEvent, lastEventId);
  const updatedDuration = Math.abs(dayjs(lastEvent.timestamp).diff());
  lastEvent = models.TimerEvent.copyOf(lastEvent, (updated) => {
    updated.duration = updatedDuration;
    updated.finalized = true;
  });
  const updateTimerEvent = await DataStore.save(lastEvent);

  let updatedEmbeddedEvents = [...embeddedEvents];
  const embeddedEventIndex = updatedEmbeddedEvents.findIndex((event) => event.id === lastEventId);
  const updatedVersion = isNaN(updateTimerEvent._version) ? 1 : updateTimerEvent._version + 1;
  updatedEmbeddedEvents[embeddedEventIndex] = {
    ...updatedEmbeddedEvents[embeddedEventIndex],
    _version: updatedVersion,
    duration: updatedDuration,
    finalized: true,
  };

  return { updateTimerEvent, updatedEmbeddedEvents };
}

export async function handleNewEventCreation(taskId, newValues, embeddedEvents) {
  embeddedEvents = embeddedEvents ? embeddedEvents : [];
  const data = {
    taskID: taskId,
    ...newValues,
    finalized: false,
    timestamp: Date.now(),
    createdAt: new Date().toISOString(),
    duration: 0,
  };

  const newTimerEvent = await DataStore.save(new models.TimerEvent(data));
  const eventCause = await DataStore.query(models.EventCause, newTimerEvent.eventCauseID);
  const [embeddedEventCause] = removeProps([eventCause], {
    propsToDelete: ["_lastChangedAt", "_deleted"],
  });
  const newEmbeddedLastEvent = {
    ...removeProps([newTimerEvent], {
      propsToDelete: ["_lastChangedAt", "_deleted"],
    })[0],
    _version: 1,
    embeddedCause: embeddedEventCause,
  };
  const updatedEmbeddedEvents = [...embeddedEvents, newEmbeddedLastEvent];

  return {
    newTimerEvent,
    newEmbeddedLastEvent,
    updatedEmbeddedEvents,
  };
}
