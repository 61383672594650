import React from "react";
import Routes from "routes";
import Notifier from "components/custom/Notifier";
import useAmplifySetup from "setup/amplify";
import useSystemSettingsSetup from "setup/systemSettings";
import LoginView from "components/LoginView/index.js";
import GeneralBackdrop from "components/common/GeneralBackdrop";
import useClearIDBTaskEvidences from "hooks/useClearIdbTaskEvidences";
import LegalBox from "components/LegalBox";

export default function Root() {
  const { legalBoxProps, onCloseLegalBoxModal } = useAmplifySetup();
  useSystemSettingsSetup();
  useClearIDBTaskEvidences();

  return (
    <>
      <LegalBox setShowModalTerms={() => onCloseLegalBoxModal(false)} {...legalBoxProps} />
      <LoginView>
        <Routes />
        <Notifier />
        <GeneralBackdrop />
      </LoginView>
    </>
  );
}
