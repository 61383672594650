import React from "react";
import { Box, Grid, Divider } from "@material-ui/core";
import useStyles from "../MaintenanceStyles";
import DataLabel from "components/DataLabel";
import PropTypes from "prop-types";
import dayjs from "dayjs";

const ActivityLog = ({ data, isLoading }) => {
  const classes = useStyles();

  function handleDisplayDate(date) {
    if (!date) return "Por definir";

    return dayjs(date).format("DD/MM/YYYY HH:mm");
  }
  return (
    <>
      <Box className={classes.sectionTitleContainer}>
        <p className={classes.sectionTitleText}>Registro de la actividad</p>
      </Box>
      <Divider variant="middle" />

      <Box className={classes.sectionTitleContainer}>
        <Grid container spacing={2}>
          <Grid item xs={6} md={4}>
            <DataLabel
              loading={isLoading}
              label="Fecha y hora de inicio"
              data={handleDisplayDate(data?.startedAt) || ""}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <DataLabel loading={isLoading} label="Fecha y hora de fin" data={handleDisplayDate(data?.endedAt) || ""} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

ActivityLog.propTypes = {
  data: PropTypes.object,
  isLoading: PropTypes.bool,
};

export default ActivityLog;
