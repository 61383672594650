export const createProject = /* GraphQL */ `
  mutation CreateProject($input: CreateProjectInput!, $condition: ModelProjectConditionInput) {
    createProject(input: $input, condition: $condition) {
      _deleted
      _lastChangedAt
      _version
      code
      companyID
      contractNumber
      createdAt
      description
      id
      name
      updatedAt
      company {
        _deleted
        _lastChangedAt
        _version
        createdAt
        description
        enabled
        id
        name
        owner
        updatedAt
      }
      enabled
      startAt
      endAt
    }
  }
`;

export default createProject;
