import React from "react";

import makeStyles from "@material-ui/core/styles/makeStyles";
import ManageEngineSettingsCard from "./ManageEngineSettingsCard";

function IntegrationsView() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ManageEngineSettingsCard />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

export default IntegrationsView;
