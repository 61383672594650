/* eslint-disable react/display-name */
import React, { useState, useEffect, useReducer, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { Controller, useForm } from "react-hook-form";
import { Logger } from "@aws-amplify/core";
import dayjs from "dayjs";

import useNotifier from "hooks/useNotifier";
import useTableMobileStyles from "hooks/useTableMobileStyles";
import TableCellMobileFormat from "components/custom/TableCellMobileFormat";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { DialogContentText } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import { DateTimePicker } from "@material-ui/pickers";
import AddIcon from "@material-ui/icons/AddBox";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";

import { FORM_MODES } from "constant/formModes";
import MaterialTable from "components/custom/MaterialTable";
import Select from "components/custom/Select";
import ConfirmationDialog, { MessageDialog } from "components/ConfirmationDialog";
import MaterialListDialog from "components/Materials/MaterialListDialog";
import AssetSearchDialog from "components/AssetSearchDialog";
import MaterialKitDialog from "components/MaterialKitDialog";
import DownloadQRButton from "components/common/DownloadQRButton";
import PremisesSelector from "components/FormControls/PremisesSelector";
import TypesSelector from "components/FormControls/TypesSelector";
import UsersTeamSelector from "components/FormControls/UsersTeamSelector";

import useLoadingStatus from "hooks/useLoadingStatus";
import taskMaterialsReducer from "redux/reducer/hook/installationMaterialsReducer";
import MaterialKitSelect from "components/common/MaterialKitSelect/MaterialKitSelect";
import CustomerProjectSelector from "components/CustomerProjectSelector";
import * as ActionTypes from "redux/action";
import useCompleteFormValues from "hooks/useCompleteFormValues";
import useBooleanFlag from "hooks/useBooleanFlag";

import cleanUUID from "util/cleanUUID";
import { formatAssetsSeries } from "util/text";
import { FormatUserName } from "util/FormatDetails";
import useGraphQLHelpers from "./helpers/useGraphQLHelpers";
import graphql from "./helpers/graphql";
import { TaskStatus } from "models";
import { filterDeletedItems } from "util/lists";
import { WHITE_LABEL_BRANDS } from "constant/whiteLabelBrands";

const logger = new Logger("MaintenanceFormDialog");
const brand = process.env.REACT_APP_WHITE_LABEL_APP;

export default function MaintenanceFormDialog({
  isOpen,
  maintenanceData,
  assets,
  materials,
  onClose,
  onCreate,
  onUpdate,
}) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const tableMobileStyles = useTableMobileStyles();
  const { showMessage, showError } = useNotifier();

  // consultas core
  const helpers = graphql();
  const graphqlHelpers = useGraphQLHelpers();
  const [isScheduling, _handleScheduleAndUpdateMaintenance] = useLoadingStatus(handleScheduleAndUpdateMaintenance);
  const [loadingKit, _getKitById] = useLoadingStatus(helpers.getKitById);
  const [loadingUOM, _getUOM] = useLoadingStatus(helpers.getAllUOM);

  //kits states
  const [materialKitMode, setMaterialKitMode] = useState(FORM_MODES.CREATE);
  const [selectedKit, setSelectedKit] = useState("");
  const [activeKit, setActiveKit] = useState(null);
  const [activeRemark, setActiveRemark] = useState(null);
  const [uomList, setUomList] = useState([]);
  const kitSelectRef = useRef();

  //form states
  const [formMode, setFormMode] = useState(FORM_MODES.CREATE);
  const [selectedProjectId, setSelectedProjectId] = useState("");
  const [newMaintenanceId, setNewMaintenanceId] = useState("");
  const [originalTechnicians, setOriginalTechnicians] = useState([]);
  const { formValues, onSelectsChange, onMultipleSelectChange, cleanFormValues } =
    useCompleteFormValues(DEFAULT_FORM_VALUES);
  const [validateDateDialogMessage, setValidateDateDialogMessage] = useState([]);
  const [maintenanceMaterials, dispatch] = useReducer(taskMaterialsReducer, {
    all: [],
    added: [],
    updated: [],
    deleted: [],
  });
  const [maintenanceAssets, dispatchAssets] = useReducer(taskMaterialsReducer, {
    all: [],
    added: [],
    updated: [],
    deleted: [],
  });
  const { control, errors, getValues, setValue, reset, handleSubmit, setError, watch, clearErrors } = useForm({
    mode: "onChange",
    defaultValues: DEFAULT_FORM_VALUES,
  });
  //dialogs
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [showMaterialSelectorDialog, setShowMaterialSelectorDialog] = useState(false);
  const [showMaterialKitDialog, setShowMaterialKitDialog] = useState(false);
  const [showAssetSelectorDialog, setShowAssetSelectorDialog] = useState(false);
  const [showKitConfirmation, setShowKitConfirmation] = useState(false);
  const [assetRequiredDialogOpen, openAssetRequiredDialog, closeAssetRequiredDialog] = useBooleanFlag();
  const [validateDateDialogOpen, openValidateDateDialog, closeValidateDateDialog] = useBooleanFlag();

  const maintenanceAssetIds = useMemo(() => {
    return maintenanceAssets.all.map(({ assetId }) => assetId);
  }, [maintenanceAssets.all]);

  const maintenanceMaterialIds = useMemo(() => {
    return maintenanceMaterials.all.map(({ materialId }) => materialId);
  }, [maintenanceMaterials.all]);

  const materialsColumns = useMemo(() => {
    const uomColIndex = MATERIALS_COLUMNS_DESKTOP.findIndex((column) => column.title === "Unidad");
    const columns = [...MATERIALS_COLUMNS_DESKTOP];
    const uomLookup = uomList.reduce((uomLookup, { id, name }) => {
      uomLookup[id] = name;
      return uomLookup;
    }, {});
    columns[uomColIndex] = {
      ...columns[uomColIndex],
      lookup: uomLookup,
      editComponent: (fieldProps) => {
        const { value, onChange } = fieldProps;
        return (
          <Select
            name="quantity"
            value={value}
            onChange={(e) => onChange(e.target.value)}
            options={uomList}
            fullWidth
          />
        );
      },
    };
    return columns;
  }, [uomList]);

  useEffect(() => {
    if (isOpen && maintenanceData) {
      setFormMode(FORM_MODES.UPDATE);
      const { taskType, premises, startDate, startTime, endDate, endTime, users } = maintenanceData;
      const { items: remarks = [] } = maintenanceData.remarks;
      const maintenanceRemark = remarks.find((r) => r.taskStatus === TaskStatus.SCHEDULED);
      const technicians = filterDeletedItems(users.items);
      const techniciansIds = technicians.map((tu) => tu.user.id);
      setValue("type", taskType?.id || "");
      setValue("premise", premises?.id || "");
      setValue("startDate", `${startDate} ${startTime}`);
      setValue("endDate", `${endDate} ${endTime}`);
      setValue("technician", techniciansIds);
      setValue("remarks", maintenanceRemark?.remark || "");
      setOriginalTechnicians(technicians);
      setActiveRemark(maintenanceRemark);
      onSelectsChange({ target: { name: "type" } }, taskType);
      onSelectsChange({ target: { name: "premise" } }, premises);
      onMultipleSelectChange(
        { target: { name: "technician", value: techniciansIds } },
        technicians.map((t) => t.user)
      );
      dispatchAssets({
        type: ActionTypes.SET_ALL_INSTALLATION_MATERIAL,
        payload: assets.map((asset) => ({ ...asset, material: { ...asset.material, id: asset.id } })),
      });
      dispatch({
        type: ActionTypes.SET_ALL_INSTALLATION_MATERIAL,
        payload: materials,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, setValue, maintenanceData, assets, materials]);

  useEffect(() => {
    if (isOpen) {
      loadUOM();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const currentStartDate = watch("startDate");
  const currentEndDate = watch("endDate");

  function validateDateRange({ startDate, endDate }) {
    // if (currentStartDate === DEFAULT_FORM_VALUES.startDate && currentEndDate === DEFAULT_FORM_VALUES.endDate)
    //   return true;
    if (startDate && dayjs(startDate).isAfter(currentEndDate)) {
      if (currentEndDate === DEFAULT_FORM_VALUES.endDate) {
        return setError("endDate", {
          type: "validate",
          message: "Selecciona una fecha que sea posterior a la de inicio",
        });
      }
      return "Selecciona una fecha que sea previa a la de fin";
    } else if (endDate && dayjs(endDate).isBefore(currentStartDate)) {
      if (currentStartDate === DEFAULT_FORM_VALUES.startDate) {
        return setError("startDate", {
          type: "validate",
          message: "Selecciona una fecha que sea previa a la de fin",
        });
      }
      return "Selecciona una fecha que sea posterior a la de inicio";
    }
    if (currentStartDate === currentEndDate) {
      setError("startDate", {
        type: "validate",
        message: "Las no pueden ser iguales",
      });
      setError("endDate", {
        type: "validate",
        message: "Las no pueden ser iguales",
      });
      return false;
    }
    if (dayjs(startDate).isBefore(dayjs().startOf("day"))) {
      return "la fecha de inicio debe ser posterior a la fecha actual";
    }
    clearErrors(["startDate", "endDate"]);
  }

  // function handleShowValidateDateDialog() {
  //   const MESSAGES = VALIDATION_DATES_DIALOG_MESSAGES(currentEndDate);
  //   if (currentEndDate === DEFAULT_FORM_VALUES.endDate) {
  //     setValidateDateDialogMessage(MESSAGES.END_DATE_NOT_SELECTED);
  //     openValidateDateDialog();
  //     return { isValid: false };
  //   }
  //   return {
  //     isValid: true,
  //   };
  // }

  // ---- Handlers ------
  async function handleScheduleAndUpdateMaintenance() {
    try {
      if (formMode === FORM_MODES.CREATE) {
        await scheduleMaintenace();
      } else if (formMode === FORM_MODES.UPDATE) {
        const values = getValues();
        // Actualizar los detalles del mantenimiento con updateTask
        await graphqlHelpers.updateMaintenanceDetails({
          newValues: { ...values, projectId: selectedProjectId },
          oldValues: { ...maintenanceData, projectId: maintenanceData?.project?.id },
        });

        // Actualizar o creak el remark de comentarios del mantenimiento
        if (activeRemark) {
          await graphqlHelpers.updateRemark({ remark: activeRemark, content: values.remarks });
        } else if (!activeRemark && values.remarks) {
          await graphqlHelpers.createRemark({ taskId: maintenanceData.id, content: values.remarks });
        }

        // Actualizar el type de los activos agregados y eliminados
        await graphqlHelpers.updateAssetTypes(maintenanceAssets);

        // Agregar y eliminar los activos correspondientes
        await graphqlHelpers.updateTaskAssets({ taskId: maintenanceData.id, assets: maintenanceAssets });

        // Crear y asociar un activo por cada material nuevo con createAsset
        let materialAssets = await graphqlHelpers.createNewAssets({
          materials: maintenanceMaterials.all,
          addedIds: maintenanceMaterials.added,
        });
        materialAssets = materialAssets ? { ...maintenanceMaterials, all: materialAssets } : maintenanceMaterials;

        // Agregar, modificar y eliminar los materiales correspondientes
        await graphqlHelpers.updateTaskAssets({ taskId: maintenanceData.id, assets: materialAssets });

        // Relacionar y eliminar a los usuarios técnicos con el task
        await graphqlHelpers.addTechnicians({
          taskId: maintenanceData.id,
          selectedUsers: values.technician,
          originalUsers: originalTechnicians,
        });
        await graphqlHelpers.deleteTechnicians({
          selectedUsers: values.technician,
          originalUsers: originalTechnicians,
        });

        onUpdate();
        setShowConfirmation(false);
        showMessage("El mantenimiento ha sido actualizado correctamente.");
        onClose();
      }
    } catch (error) {
      logger.error(error);
      showError(DIALOG_TEXT[formMode].errorMsg);
    }
  }

  async function scheduleMaintenace() {
    const values = getValues();
    const newMaintenance = await helpers.scheduleMaintenance({
      ...values,
      selectedMaterials: maintenanceMaterials.all,
      projectId: selectedProjectId,
      assetsList: maintenanceAssets,
    });
    if (values.remarks.length) {
      await graphqlHelpers.createRemark({ taskId: newMaintenance.id, content: values.remarks });
    }
    onCreate(newMaintenance.createTask);
    const maintenanceId = cleanUUID(newMaintenance.id);
    setNewMaintenanceId(maintenanceId);
    setShowConfirmation(false);
    setShowNotification(true);
    onClose();
  }

  function handleOpenConfirmation(values, evento) {
    if (values.technician.length === 0) {
      showError("Selecciona al usuario o a los múltiples usuarios técnicos que realizarán este mantenimiento");
      return setError("technician", {
        type: "required",
        message: "Selecciona por lo menos a un usuario",
      });
    }
    if (maintenanceAssets.all.length === 0) {
      return showError("Selecciona por lo menos un activo al que dar mantenimiento");
    }

    if (dayjs(currentStartDate).isBefore(dayjs().startOf("day"))) {
      showError("la fecha de inicio debe ser posterior a la fecha actual");
      return setError("startDate", {
        type: "validate",
        message: "la fecha de inicio debe ser posterior a la fecha actual",
      });
    }
    // if (currentEndDate === DEFAULT_FORM_VALUES.endDate) {
    //   return handleShowValidateDateDialog();
    // }

    setShowConfirmation(true);
  }

  function handleOnSubmitError(errors, event) {
    showError("Error: Información incompleta. Completa todos los campos requeridos");
  }

  function concatAssetsSelected(newAssets) {
    dispatchAssets({
      type: ActionTypes.SELECT_MATERIALS_ITEMS,
      payload: newAssets.map((asset) => ({ ...asset, material: { ...asset.material, id: asset.id } })),
    });
  }

  async function deleteSelectedAsset(oldAsset) {
    dispatchAssets({
      type: ActionTypes.DELETE_INSTALLATION_MATERIAL,
      payload: oldAsset,
    });
  }

  function cleanForm() {
    reset();
    setSelectedProjectId("");
    cleanFormValues();
    dispatch({ type: ActionTypes.RESET_MATERIALS });
    dispatchAssets({ type: ActionTypes.RESET_MATERIALS });
  }

  // kits / materiales
  async function loadUOM() {
    try {
      const { listUnitOfMeassures } = await _getUOM();
      setUomList(listUnitOfMeassures.items);
    } catch (error) {
      logger.error(error);
      showError("Ocurrio un error al consultar las unidades de medida.");
    }
  }

  function addSelectedMaterials(newMaterials) {
    dispatch({ type: ActionTypes.SELECT_MATERIALS_ITEMS, payload: newMaterials });
  }

  async function updateMaterial(newValue, oldValue, rowData, column) {
    const action = {
      type: ActionTypes.UPDATE_INSTALLATION_MATERIAL,
      payload: { ...rowData },
    };
    if (typeof newValue === "number") {
      action.payload.updatedProp = "quantity";
      action.payload.newValue = newValue;
    } else if (typeof newValue === "string") {
      action.payload.updatedProp = "uom";
      action.payload.newValue = { id: newValue, name: column.lookup[newValue] };
    }
    dispatch(action);
  }

  async function deleteMaterial(oldData) {
    dispatch({
      type: ActionTypes.DELETE_INSTALLATION_MATERIAL,
      payload: oldData,
    });
  }

  function putSelectedKit(kitId) {
    const hasSelectedMaterials = maintenanceMaterials.all.length;
    if (!kitId) {
      return;
    }
    setSelectedKit(kitId);
    if (hasSelectedMaterials) {
      return setShowKitConfirmation(true);
    } else {
      applyMaterialKit(kitId);
    }
  }

  async function applyMaterialKit(kitId = selectedKit, { openMessage = true } = {}) {
    try {
      const kitData = await _getKitById(kitId);
      dispatch({
        type: ActionTypes.REPLACE_ALL_INSTALLATION_MATERIAL,
        payload: kitData?.getKit?.materials?.items,
      });
      setActiveKit({ ...kitData?.getKit });
      if (openMessage) {
        showMessage("Kit agregado correctamente.");
      }
      setShowKitConfirmation(false);
    } catch (error) {
      logger.error(error);
      showError("Ocurrio un error al seleccionar el kit. Intente de nuevo, por favor.");
    }
  }

  function updateMaterialKit() {
    setMaterialKitMode(FORM_MODES.UPDATE);
    setShowMaterialKitDialog(true);
  }

  function createMaterialKit() {
    setMaterialKitMode(FORM_MODES.CREATE);
    setShowMaterialKitDialog(true);
  }

  function pushNewKit(newKit) {
    kitSelectRef.current.addKit(newKit);
    setShowMaterialKitDialog(false);
  }

  function updateActiveKit({ updateKit }) {
    kitSelectRef.current.updateKit(updateKit);
    setShowMaterialKitDialog(false);
    applyMaterialKit(updateKit.id, { openMessage: false });
  }

  function showNotPremiseSelectedAlert() {
    const { premise } = formValues;

    if (!premise?.name && !premise?.id && brand !== WHITE_LABEL_BRANDS.THINKSAFE) {
      return (
        <Box>
          <Alert severity="warning">
            Selecciona el lugar de atención del mantenimiento, previo a la selección de activos
          </Alert>
        </Box>
      );
    }
    return null;
  }
  return (
    <>
      <Dialog maxWidth="md" fullWidth fullScreen={isMobileScreen} open={isOpen} onExited={cleanForm}>
        <DialogTitle>{DIALOG_TEXT[formMode].title}</DialogTitle>
        <DialogContent dividers>
          <Box mb={3}>
            <Typography paragraph>
              <strong>Indicaciones:</strong>
            </Typography>
            <ol>
              <li>Ingrese los datos del mantenimiento.</li>
              <li>Revise los datos ingresados.</li>
              <li>Confirme si los datos son correctos.</li>
            </ol>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Datos del Mantenimiento</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={control}
                as={DateTimePicker}
                name="startDate"
                label="Fecha de inicio"
                fullWidth
                disablePast
                format="DD/MM/YYYY HH:mm"
                inputVariant="standard"
                error={!!errors.startDate}
                helperText={errors.startDate?.message}
                rules={{
                  ...FORM_RULES.startDate,
                  validate: (startDate) => validateDateRange({ startDate }),
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={control}
                as={DateTimePicker}
                disablePast
                name="endDate"
                label="Fecha de finalización"
                fullWidth
                format="DD/MM/YYYY HH:mm"
                inputVariant="standard"
                error={!!errors.endDate}
                helperText={errors.endDate?.message}
                rules={{
                  ...FORM_RULES.endDate,
                  validate: (endDate) => validateDateRange({ endDate }),
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <PremisesSelector name="premise" control={control} errors={errors} onChange={onSelectsChange} required />
            </Grid>
            <Grid item xs={12} md={4}>
              <TypesSelector
                name="type"
                typeCategory="MAINTENANCE"
                control={control}
                errors={errors}
                onChange={onSelectsChange}
                required
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <UsersTeamSelector
                control={control}
                errors={errors}
                onChange={onMultipleSelectChange}
                formMode={formMode}
                name="technician"
                initialUsers={originalTechnicians.map((tu) => tu.user)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                as={TextField}
                rules={FORM_RULES.remarks}
                name="remarks"
                label="Comentarios"
                variant="outlined"
                rows={5}
                multiline
                fullWidth
                error={!!errors.remarks}
                helperText={errors.remarks?.message}
              />
            </Grid>

            <Grid item xs={12}>
              <CustomerProjectSelector
                customerId={maintenanceData?.project?.company?.id}
                projectId={maintenanceData?.project?.id}
                onChangeProject={setSelectedProjectId}
                boxProps={{ mt: 5 }}
              />
            </Grid>

            <Grid item xs={12}>
              <Box mt={5}>
                <Typography variant="h6">Activos</Typography>
              </Box>
              {showNotPremiseSelectedAlert()}
              <div id="new-maintenance-asset-table" className={tableMobileStyles.root}>
                <MaterialTable
                  columns={isMobileScreen ? ASSETS_COLUMNS_MOBILE : ASSETS_COLUMNS_DESKTOP}
                  options={TABLE_OPTIONS}
                  data={maintenanceAssets.all}
                  editable={{ onRowDelete: deleteSelectedAsset }}
                  localization={{ body: { deleteTooltip: "Eliminar" } }}
                  actions={[
                    // {
                    //   icon: () => <ListIcon color="action" />,
                    //   tooltip: "Ver Atributos",
                    //   position: "row",
                    //   onClick: () => setShowAssetAttributesDialog(true),
                    // },
                    {
                      icon: () => <AddIcon id="select-assets" color="action" />,
                      tooltip: "Agregar Activos",
                      position: "toolbar",
                      onClick: () => {
                        const { premise } = formValues;
                        if (!premise?.name && !premise?.id && brand !== WHITE_LABEL_BRANDS.THINKSAFE) {
                          return openAssetRequiredDialog();
                        }
                        setShowAssetSelectorDialog(true);
                      },
                    },
                  ]}
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <Box mt={5} mb={3}>
                <Typography variant="h6">Materiales</Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <MaterialKitSelect onChangeKit={putSelectedKit} ref={kitSelectRef} />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box mt={1}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={updateMaterialKit}
                      disabled={!activeKit}
                      fullWidth
                    >
                      Actualizar Kit
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box mt={1}>
                    <Button variant="contained" color="primary" onClick={createMaterialKit} fullWidth>
                      Crear Kit
                    </Button>
                  </Box>
                </Grid>
              </Grid>
              <MaterialTable
                columns={materialsColumns}
                options={TABLE_OPTIONS}
                data={maintenanceMaterials.all}
                editable={{ onRowDelete: deleteMaterial }}
                cellEditable={{ onCellEditApproved: updateMaterial }}
                localization={{ body: { deleteTooltip: "Eliminar" } }}
                isLoading={loadingUOM}
                actions={[
                  {
                    icon: () => <AddIcon color="action" />,
                    tooltip: "Agregar Materiales",
                    position: "toolbar",
                    onClick: () => setShowMaterialSelectorDialog(true),
                  },
                ]}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="default" onClick={onClose}>
            Cancelar
          </Button>
          <Button color="primary" onClick={handleSubmit(handleOpenConfirmation, handleOnSubmitError)} disabled={false}>
            {DIALOG_TEXT[formMode].confirmBtn}
          </Button>
        </DialogActions>
      </Dialog>

      <AssetSearchDialog
        open={showAssetSelectorDialog}
        onClose={() => setShowAssetSelectorDialog(false)}
        updateAssetsList={concatAssetsSelected}
        excludedAssetIds={maintenanceAssetIds}
        initialData={formValues}
      />

      {/* <AssetAttributesDialog
        isOpen={showAssetAttributesDialog}
        onClose={() => setShowAssetAttributesDialog(false)}
        asset={{ attributes: { items: [] } }}
        onComplete={() => setShowAssetAttributesDialog(false)}
        readOnly
      /> */}

      <MaterialListDialog
        open={showMaterialSelectorDialog}
        onClose={() => setShowMaterialSelectorDialog(false)}
        onCompleteSelection={addSelectedMaterials}
        excludedMaterialIds={maintenanceMaterialIds}
      />

      <MaterialKitDialog
        open={showMaterialKitDialog}
        formMode={materialKitMode}
        tableColumns={materialsColumns}
        kit={activeKit}
        uomList={uomList}
        onClose={() => setShowMaterialKitDialog(false)}
        onCreate={pushNewKit}
        onUpdate={updateActiveKit}
      />

      <ConfirmationDialog
        open={showKitConfirmation}
        title="Confirmación"
        onConfirm={() => applyMaterialKit()}
        onCancel={() => setShowKitConfirmation(false)}
        loading={loadingKit}
      >
        <Typography paragraph>¿Confirmas que deseas aplicar el kit?</Typography>
        <Typography paragraph>Si confirmas la operación, los materiales seleccionados serán borrarán.</Typography>
      </ConfirmationDialog>

      <ConfirmationDialog
        open={showConfirmation}
        title="Confirmación"
        okBtnText="Continuar"
        cancelBtnText="Cancelar"
        onCancel={() => setShowConfirmation(false)}
        onConfirm={_handleScheduleAndUpdateMaintenance}
        loading={isScheduling}
      >
        <Typography paragraph>{DIALOG_TEXT[formMode].confirmationMsg}</Typography>
        <Typography>
          <strong>Fecha de inicio: </strong>
          <span>{dayjs(watch("startDate")).format("DD/MM/YYYY HH:mm")}</span>
        </Typography>
        <Typography>
          <strong>Fecha de finalización: </strong>
          <span>{dayjs(watch("endDate")).format("DD/MM/YYYY HH:mm")}</span>
        </Typography>
        <Typography>
          {/* TODO: Buscar sitio por ID para colocar nombre */}
          <strong>Lugar del mantenimiento: </strong>
          <span>{formValues?.premise?.name || "Error: sin sitio"}</span>
        </Typography>
        <Typography>
          {/* TODO: Buscar tipo por ID para colocar nombre */}
          <strong>Tipo de mantenimiento: </strong>
          <span>{formValues?.type?.name || "Error: sin tipo"}</span>
        </Typography>
        <Typography>
          <strong>Técnicos: </strong>
          {formValues?.technician?.map((user, index) => (
            <li key={user.id}>
              <span>{FormatUserName(user)}</span>
            </li>
          ))}
        </Typography>
        <br />
        <Typography paragraph>¿Deseas continuar?</Typography>
      </ConfirmationDialog>

      <ConfirmationDialog
        open={showNotification}
        title="Mensaje"
        onCancel={() => {
          setShowNotification(false);
          onClose();
        }}
        cancelBtnText="Cerrar"
        showOkBtn={false}
      >
        <Typography paragraph>El mantenimiento se registro exitósamente con el siguiente folio:</Typography>
        <Typography paragraph>
          <strong>{newMaintenanceId}</strong>
        </Typography>
        <Typography paragraph>Puedes consultar la información del mantenimiento con el folio anterior.</Typography>
      </ConfirmationDialog>

      <MessageDialog
        open={assetRequiredDialogOpen}
        title="Mensaje"
        // onCancel={() => {
        //   closeAssetRequiredDialog();
        //   reset({ premise: DEFAULT_FORM_VALUES.premise, ...formValues });
        //   // cleanFormValues({ cleanValues: ["premise"] });
        // }}
        // cancelBtnText="Cerrar"
        cancelBtnText=""
        cancelDisabled={true}
        okBtnText="Continuar"
        onConfirm={() => {
          setShowAssetSelectorDialog(true);
          closeAssetRequiredDialog();
        }}
        okDisabled={!formValues?.premise?.name || !formValues?.premise?.id}
      >
        <DialogContentText color="textPrimary">
          Es necesario que primero selecciones el lugar de atención para el mantenimiento. De esta forma es posible
          filtrar unicamente los activos que se encuentran en dicho lugar de atención
        </DialogContentText>
        <Box>
          <PremisesSelector name="premise" control={control} errors={errors} onChange={onSelectsChange} required />
        </Box>
      </MessageDialog>

      <MessageDialog
        open={validateDateDialogOpen}
        title="Fechas no seleccionadas"
        onConfirm={() => {
          setShowConfirmation(true);
          closeValidateDateDialog();
          setValidateDateDialogMessage([]);
        }}
        onCancel={() => {
          closeValidateDateDialog();
          setTimeout(() => {
            setValidateDateDialogMessage([]);
          }, 100);
        }}
        cancelBtnText="Volver"
      >
        {validateDateDialogMessage?.map((message, index) => (
          <DialogContentText key={`validateDateMessage${index}`} color="textPrimary">
            {message}
          </DialogContentText>
        ))}
      </MessageDialog>
    </>
  );
}

MaintenanceFormDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onCreate: PropTypes.func,
  onUpdate: PropTypes.func,
  maintenanceData: PropTypes.object,
  assets: PropTypes.array,
  materials: PropTypes.array,
};

MaintenanceFormDialog.defaultProps = {
  isOpen: false,
  onClose: () => {},
  onCreate: () => {},
  onUpdate: () => {},
  maintenanceData: null,
  assets: [],
  materials: [],
};

const DIALOG_TEXT = {
  [FORM_MODES.CREATE]: {
    title: "Creación de Mantenimiento",
    confirmBtn: "Crear",
    confirmationMsg: "Estás a punto de programar el siguiente mantenimiento:",
    successMsg: "Mantenimiento programado con éxito",
    errorMsg: "Ocurrió un error durante la programación del mantenimiento, intente nuevamente.",
  },
  [FORM_MODES.UPDATE]: {
    title: "Actualizar Mantenimiento",
    confirmBtn: "Actualizar",
    confirmationMsg: "Estás a punto de actualizar el siguiente mantenimiento:",
    successMsg: "Mantenimiento actualizado con éxito",
    errorMsg: "Ocurrió un error durante la actualización del mantenimiento, intente nuevamente.",
  },
};

const DEFAULT_FORM_VALUES = {
  startDate: dayjs(),
  endDate: dayjs().add(1, "days"),
  premise: "",
  technician: [],
  type: "",
  remarks: "",
};

const FORM_RULES = {
  startDate: {
    required: "Fecha obligatoria",
  },
  endDate: {
    required: "Fecha obligatorio",
  },
  premise: {
    required: "Seleccione un sitio",
  },
  type: {
    required: "Seleccione un tipo",
  },
  technician: {
    required: "Seleccione un técnico",
  },
  remarks: {
    maxLength: { value: 500, message: "Se permite un máximo 500 caracteres" },
  },
};

const TABLE_OPTIONS = {
  exportButton: false,
  columnsButton: false,
};

const ASSETS_COLUMNS_DESKTOP = [
  { title: "Nombre", field: "name" },
  { title: "Código Material", field: "code" },
  { title: "Serie", field: "serial", render: (rowData) => formatAssetsSeries(rowData.serial) },
  {
    title: "Código QR",
    field: "trackingID",
    render: (rowData) => {
      if (rowData.trackingID !== "unassigned" && rowData.tracking?.status !== "unassigned") {
        return (
          <DownloadQRButton
            variant="contained"
            color="primary"
            style={{ marginTop: "0.5em" }}
            id={`downloadQR-${
              rowData.trackingID !== "unassigned"
                ? cleanUUID(rowData.trackingID)
                : "unassigned-" + cleanUUID(rowData.id)
            }-btn`}
            assetID={rowData.assetId}
            content={cleanUUID(rowData?.trackingID || rowData?.tracking?.id)}
          />
        );
      }
      return "Sin asignación";
    },
  },
];

/* eslint-disable react/display-name */
const ASSETS_COLUMNS_MOBILE = [
  {
    title: "Detalles",
    field: "id",
    render: (rowData) => {
      const columns = [
        {
          title: "Nombre",
          description: rowData?.name,
        },
        {
          title: "Código de material",
          description: rowData?.code,
        },
        {
          title: "Serie",
          description: formatAssetsSeries(rowData?.serial),
        },
        {
          title: "Código QR",
          description: (() => {
            if (rowData.trackingID !== "unassigned" && rowData.tracking?.status !== "unassigned") {
              return (
                <DownloadQRButton
                  variant="contained"
                  color="primary"
                  style={{ marginTop: "0.5em" }}
                  id={`downloadQR-${
                    rowData.trackingID !== "unassigned"
                      ? cleanUUID(rowData.trackingID)
                      : "unassigned-" + cleanUUID(rowData.id)
                  }-btn`}
                  assetID={rowData.assetId}
                  content={cleanUUID(rowData?.trackingID || rowData?.tracking?.id)}
                />
              );
            }
            return "Sin asignación";
          })(),
        },
      ];

      return <TableCellMobileFormat columns={columns} sm={6} xs={12} />;
    },
    customFilterAndSearch: (value, rowData) => {
      const parsedValue = value.toLowerCase();
      return (
        rowData?.name?.toLowerCase().includes(parsedValue) ||
        rowData?.code?.toLowerCase().includes(parsedValue) ||
        rowData?.serial?.toLowerCase().includes(parsedValue) ||
        rowData?.trackingID?.toLowerCase().includes(parsedValue)
      );
    },
  },
];

const MATERIALS_COLUMNS_DESKTOP = [
  {
    title: "Nombre",
    field: "name",
    editable: "never",
  },
  {
    title: "Código",
    field: "code",
    editable: "never",
  },
  {
    title: "Unidad",
    field: "uom.name",
    render: ({ uom }) => uom?.name || "Sin unidad",
  },
  {
    title: "Cantidad",
    field: "quantity",
    type: "numeric",
    initialEditValue: 1,
    align: "left",
    cellStyle: { minWidth: 150 },
  },
  {
    title: "Categoría",
    field: "category.name",
    editable: "never",
    render: ({ category }) => category?.name || "Sin categoría",
  },
];

const VALIDATION_DATES_DIALOG_MESSAGES = (currentEndDate) => ({
  END_DATE_NOT_SELECTED: [
    "No realizaste modificaciones a la fecha programada de fin del mantenimiento.",
    <>
      Fecha programada de fin: <strong>{dayjs(`${currentEndDate}`).format("DD/MM/YYYY HH:mm")}</strong>
    </>,
    "¿Seguro que deseas continuar?",
  ],
});
