/**
 * Query GraphQL para obtener los activos asocidados a un proyecto.
 *
 * @param {string} assetProjectId id del proyecto por el que se filtraran los activos.
 */
export const getAssetsByProject = /* GraphQL */ `
  query GetAssetsByProject($assetProjectId: ID!, $filter: ModelAssetFilterInput, $limit: Int, $nextToken: String) {
    assetsByProject(assetProjectId: $assetProjectId, filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        warehouseKeepers
        updatedAt
        _deleted
        _lastChangedAt
        _version
        assetProjectId
        code
        comment
        createdAt
        enabled
        history
        id
        locationCode
        name
        premiseID
        quantity
        serial
        supervisors
        supportEngineers
        trackingID
        type
        uom {
          _deleted
          _lastChangedAt
          _version
          abbreviation
          createdAt
          description
          id
          name
          updatedAt
        }
        tracking {
          _deleted
          _lastChangedAt
          _version
          createdAt
          id
          ikaID
          status
          updatedAt
        }
        project {
          _deleted
          _lastChangedAt
          _version
          code
          companyID
          contractNumber
          createdAt
          description
          enabled
          endAt
          id
          name
          startAt
          updatedAt
          company {
            updatedAt
            owner
            name
            id
            enabled
            description
            createdAt
            _version
            _lastChangedAt
            _deleted
          }
        }
        premise {
          _deleted
          _lastChangedAt
          _version
          code
          createdAt
          id
          name
          owner
          updatedAt
          custodian {
            _deleted
            _lastChangedAt
            _version
            createdAt
            email
            extension
            id
            lastnameM
            lastnameP
            middlename
            name
            phone
            updatedAt
          }
          company {
            _deleted
            _lastChangedAt
            _version
            createdAt
            description
            enabled
            id
            name
            updatedAt
            owner
          }
          address {
            _deleted
            _lastChangedAt
            _version
            city
            createdAt
            id
            locality
            number
            settlement
            street
            updatedAt
            zipcode
          }
        }
        material {
          _deleted
          _lastChangedAt
          _version
          code
          createdAt
          deleted
          description
          enabled
          id
          manufacturer
          name
          trackable
          updatedAt
          uom {
            updatedAt
            name
            id
            description
            createdAt
            abbreviation
            _version
            _lastChangedAt
            _deleted
          }
          category {
            _deleted
            _lastChangedAt
            _version
            abbreviation
            createdAt
            deleted
            description
            id
            name
            updatedAt
          }
        }
        embeddedAttributes {
          attributeID
          id
          value
          name
        }
        attributes {
          items {
            warehouseKeepers
            _deleted
            _version
            _lastChangedAt
            assetID
            attributeID
            createdAt
            id
            supervisors
            supportEngineers
            updatedAt
            value
            attribute {
              _deleted
              _lastChangedAt
              _version
              createdAt
              editable
              description
              id
              name
              updatedAt
              visible
            }
          }
          startedAt
          nextToken
        }
      }
      nextToken
      startedAt
    }
  }
`;

export default getAssetsByProject;
