import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";

function NoticeOfPrivacy(props) {
  const { section } = props;
  const classes = useStyles();
  const [sectionShow, setSectionShow] = useState(section);

  useEffect(() => {
    setSectionShow(section);
  }, [section]);

  return (
    <div className={classes.content}>
      {sectionShow === 0 && (
        <div>
          <Typography className={classes.title} variant="h4" component="div">
            AVISO DE PRIVACIDAD
          </Typography>
          <p>
            En cumplimiento con lo establecido por la Ley Federal de Protección de Datos Personales en Posesión de los
            Particulares, <b>GRUPO SIAYEC, S.A. DE C.V.</b> (en lo sucesivo, “INDIKA360”), con domicilio en Avenida Xola
            número 1012, colonia Narvarte, delegación Benito Juárez, C.P. 03020, México, Ciudad de México y portal de
            internet{" "}
            <Link target="_blank" rel="noreferrer" href="https://www.indika360.com/">
              https://www.indika360.com/
            </Link>
            , pone a su disposición el presente aviso de privacidad a fin de dar a conocer sus prácticas y compromisos
            al obtener, usar, almacenar y, en general, tratar sus datos personales.
          </p>
          <p>
            Este documento es parte integrante de los Términos y Condiciones para Usuarios del Software Indika360. Al
            momento de utilizar el software y/o registrarse, los usuarios automáticamente aceptan los Términos y
            Condiciones Generales así como las disposiciones aquí contenidas.
          </p>
        </div>
      )}
      {sectionShow === 1 && (
        <div>
          <Typography className={classes.title} variant="h4" gutterBottom component="div">
            I.¿Para qué fines utilizaremos sus datos personales?
          </Typography>
          <p>
            Los datos personales que recabamos de usted, los utilizaremos para las siguientes finalidades que son
            necesarias para el servicio que se presta:
          </p>
          <ul>
            <li>
              Para identificarlo como Usuario Registrado y otorgarle acceso a los servicios de software (aplicación
              móvil, plataforma u otras tecnologías) que ofrece INDIKA360.
            </li>
            <li>
              Para asegurarnos de que nuestros servicios funcionan según lo previsto, como el seguimiento de
              interrupciones o la solución de problemas que nos informa.
            </li>
            <li>Para informarle sobre los cambios que pueda haber en los servicios que ofrecemos.</li>
            <li>
              Para comunicarnos con usted para temas relacionados con la prestación de nuestros servicios de software.
            </li>
            <li>
              Para garantizar la seguridad y confiabilidad de nuestros servicios. Esto incluye detectar, prevenir y
              responder a fraudes, abusos, riesgos de seguridad y problemas técnicos que pudieran afectar a INDIKA360, a
              nuestros usuarios o al público.{" "}
            </li>
          </ul>
          <p>
            De manera adicional, utilizaremos su información personal para las siguientes finalidades secundarias que{" "}
            <b>no son necesarias</b> para el servicio solicitado, pero que nos permiten y facilitan brindarle una mejor
            atención:
          </p>
          <ul>
            <li>Evaluar la calidad de los productos y servicios que le brindamos</li>
            <li>Mantenimiento de registros para prestación de servicios en el futuro.</li>
            <li>Análisis estadísticos y de mercado</li>
            <li>Promoción y mercadeo de servicios.</li>
            <li>Mercadotecnia o publicitaria.</li>
          </ul>
          <p>
            En caso de que no desee que sus datos personales sean tratados para estos fines secundarios, desde este
            momento usted nos puede comunicar lo anterior enviando un correo a avisodeprivacidad@grupo-siayec.com.mx
          </p>
          <p>
            La negativa para el uso de sus datos personales para estas finalidades no podrá ser un motivo para que le
            neguemos los servicios que solicita o contrata con nosotros.
          </p>
        </div>
      )}
      {sectionShow === 2 && (
        <div>
          <Typography className={classes.title} variant="h4" gutterBottom component="div">
            II.¿Qué datos personales recabamos y utilizamos sobre usted?
          </Typography>
          <p>
            Los datos personales que recabamos de usted, los utilizaremos para las siguientes finalidades que son
            necesarias para el servicio que se presta:
          </p>
          <ul>
            <li>Nombre completo</li>
            <li>Edad</li>
            <li>Domicilio</li>
            <li>Teléfono particular</li>
            <li>Teléfono celular</li>
            <li>Correo electrónico</li>
            <li>Ubicación</li>
          </ul>
          <p>Es importante mencionar que INDIKA360 no recabará ni tratará datos personales sensibles.</p>
        </div>
      )}
      {sectionShow === 3 && (
        <div>
          <Typography className={classes.title} variant="h4" gutterBottom component="div">
            III.¿Con quién compartimos su información?
          </Typography>
          <p>En ningún caso serán transferidos sus datos personales a personas o empresas ajenas a INDIKA360.</p>
        </div>
      )}
      {sectionShow === 4 && (
        <div>
          <Typography className={classes.title} variant="h4" gutterBottom component="div">
            IV.¿Cómo puede acceder, rectificar o cancelar sus datos personales, u oponerse a su uso?
          </Typography>
          <p>
            Usted tiene derecho a conocer qué datos personales tenemos de usted, para qué los utilizamos y las
            condiciones del uso que les damos <b>(Acceso)</b>. Asimismo, es su derecho solicitar la corrección de su
            información personal en caso de que esté desactualizada, sea inexacta o incompleta <b>(Rectificación)</b>;
            que la eliminemos de nuestros registros o bases de datos cuando considere que la misma no está siendo
            utilizada adecuadamente <b>(Cancelación)</b>; así como oponerse al uso de sus datos personales para fines
            específicos <b>(Oposición)</b>. Estos derechos se conocen como derechos <b>ARCO</b>.
          </p>
          <p>
            Para el ejercicio de cualquiera de los derechos ARCO, deberá enviar una solicitud en los términos y
            procedimiento que se señalan a continuación
          </p>
          <ol type="A">
            <li>
              Deberá enviar un correo electrónico a la dirección avisodeprivacidad@grupo-siayec.com.mx acompañando la
              siguiente información:
            </li>
            <ol>
              <li>
                Identificación con la que acredite su personalidad (credencial del INE, pasaporte vigente, cédula
                profesional o documento migratorio).{" "}
              </li>
              <li>
                Nombre, domicilio completo (calle, número interior y/o exterior, colonia, código postal, ciudad y
                estado);
              </li>
              <li>
                En caso de no ser el titular quien presente la solicitud, el documento que acredite la existencia de la
                representación, es decir, instrumento público o carta poder firmada ante dos testigos, junto con
                identificación del titular y del representante (credencial del INE, pasaporte vigente, cédula
                profesional o documento migratorio).{" "}
              </li>
              <li>
                Una descripción clara y precisa de los datos personales respecto de los cuales busca ejercer alguno de
                los Derechos ARCO, cuál es el derecho a ejercer y las razones por las cuales desea ejercitarlo.
              </li>
            </ol>
            <li>
              A partir de la recepción, el área correspondiente, tendrá un plazo máximo de 20 días hábiles para
              responder a su solicitud, sin embargo, dicho plazo podrá ser ampliado por un periodo de tiempo igual, lo
              que le será notificado a través del medio que usted nos haya indicado para tales efectos, o en su caso por
              la vía contactada, señalando las razones de dicha ampliación en conformidad con el artículo 32 de la Ley.
            </li>
            <li>
              Los datos de contacto de la persona o departamento de datos personales, que esté a cargo de dar trámite a
              las solicitudes de derechos ARCO, son los siguientes:
              <ol type="a">
                <li>Nombre de la persona encargada de datos personales: Miguel Luis González Camarena</li>
                <li>
                  Domicilio: Avenida Xola número 1012, colonia Narvarte, delegación Benito Juárez, C.P. 03020, México,
                  Ciudad de México
                </li>
                <li>Correo electrónico: avisodeprivacidad@grupo-siayec.com.mx</li>
              </ol>
            </li>
          </ol>
        </div>
      )}
      {sectionShow === 5 && (
        <div>
          <Typography className={classes.title} variant="h4" gutterBottom component="div">
            V.¿Cómo puede revocar su consentimiento para el uso de sus datos personales?
          </Typography>
          <p>
            Usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos
            personales. Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos atender su
            solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna obligación legal requíramos
            seguir tratando sus datos personales. Asimismo, usted deberá considerar que para ciertos fines, la
            revocación de su consentimiento implica que no le podamos seguir prestando el servicio que nos solicitó, o
            la conclusión de su relación con nosotros.
          </p>
          <p>Para revocar su consentimiento deberá presentar su solicitud a través del siguiente medio:</p>
          <p>
            Enviar correo electrónico a: avisodeprivacidad@grupo-siayec.com.mx en la que se detalle claramente los datos
            respecto a los que revoca su consentimiento.
          </p>
        </div>
      )}
      {sectionShow === 6 && (
        <div>
          <Typography className={classes.title} variant="h4" gutterBottom component="div">
            VI.¿Cómo puede limitar el uso o divulgación de su información personal?
          </Typography>
          <p>
            Con objeto de que usted pueda limitar el uso y divulgación de su información personal, le ofrecemos los
            siguientes medios: A través de correo electrónico dirigido a la persona encargada de sus datos personales y
            señalada ya con anterioridad.
          </p>
          <p>
            Asimismo, usted se podrá inscribir a los siguientes registros, en caso de que no desee obtener publicidad de
            nuestra parte: Registro Público para Evitar Publicidad, para mayor información contacte a, o consulte el
            portal de internet de, la Procuraduría Federal del Consumidor (PROFECO).
          </p>
        </div>
      )}
      {sectionShow === 7 && (
        <div>
          <Typography className={classes.title} variant="h4" gutterBottom component="div">
            VII.Uso de las tecnologías de rastreo en nuestro portal de internet
          </Typography>
          <p>
            Le informamos que en nuestra página de internet utilizamos cookies, web beacons u otras tecnologías, a
            través de las cuales es posible monitorear su comportamiento como usuario de internet, así como brindarle un
            mejor servicio y experiencia al navegar en nuestra página
          </p>
          <p>
            Asimismo, usted se podrá inscribir a los siguientes registros, en caso de que no desee obtener publicidad de
            nuestra parte: Registro Público para Evitar Publicidad, para mayor información contacte a, o consulte el
            portal de internet de, la Procuraduría Federal del Consumidor (PROFECO).
          </p>
          <p>
            Los datos personales que recabamos a través de estas tecnologías, los utilizaremos para los siguientes
            fines:
          </p>
          <ul>
            <li>Identificar el mercado, así como las preferencias que nuestros clientes mantienen.</li>
            <li>A identificar el comportamiento de nuestros usuarios en los diferentes canales.</li>
          </ul>
          <p>Los datos personales que obtenemos de estas tecnologías de rastreo son los siguientes:</p>
          <ul>
            <li>Región en la que se encuentra el usuario</li>
            <li>Tipo de navegador y sistema operativo del usuario</li>
            <li>Secciones web visitadas por un usuario</li>
            <li>Publicidad revisada por un usuario</li>
            <li>Listas y hábitos de consumo de compra en nuestro sitio</li>
            <li>Reconocer a los usuario</li>
            <li>Medición de parámetros de tráfico</li>
            <li>Dirección IP</li>
          </ul>
        </div>
      )}
      {sectionShow === 8 && (
        <div>
          <Typography className={classes.title} variant="h4" gutterBottom component="div">
            ¿Cómo puede conocer los cambios a este aviso de privacidad?
          </Typography>
          <p>
            El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos
            requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos; de
            nuestras prácticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas
          </p>
          <p>
            Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad,
            las notificaciones dichos cambios o actualizaciones al presente aviso de privacidad será a través de nuestra
            página de internet{" "}
            <Link target="_blank" rel="noreferrer" href="https://www.indika360.com/">
              https://www.indika360.com/
            </Link>
            y/o boletín electrónico a la dirección de correo que sea proporcionada.
          </p>
        </div>
      )}
      {sectionShow === 9 && (
        <div>
          <Typography className={classes.title} variant="h4" gutterBottom component="div">
            CONSENTIMIENTO PARA EL TRATO DE SUS DATOS PERSONALES
          </Typography>
          <p>
            Declaro bajo protesta de decir verdad que he leído en su totalidad este aviso de privacidad y entiendo
            plenamente su alcance y contenido. Por medio del presente otorgo mi consentimiento para que se traten mis
            datos personales de acuerdo a este aviso de privacidad.
          </p>
          <ul>
            <li type="square">Última actualización: 1 de Enero de 2022</li>
          </ul>
        </div>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  title: {
    margin: "0.5em 0",
    color: "#084d81",
  },
  content: {
    fontSize: "1rem",
    textAlign: "justify",
    lineHeight: 1.5,
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.875rem",
    },
  },
}));

NoticeOfPrivacy.propTypes = {
  section: PropTypes.number,
};

NoticeOfPrivacy.defaultProps = {
  section: 0,
};

export default NoticeOfPrivacy;
