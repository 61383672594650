/* eslint-disable react/style-prop-object */
/* eslint-disable react/display-name */
// componente para mostrar el detalle de las entradas de activos
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";

import API, { graphqlOperation } from "@aws-amplify/api";
import Storage from "@aws-amplify/storage";
import Alert from "@material-ui/lab/Alert";
import Tooltip from '@material-ui/core';

import makeStyles from "@material-ui/core/styles/makeStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import useNotifier from "hooks/useNotifier";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import DownloadQRButton from "components/common/DownloadQRButton";
import DetailTitle from "components/DetailTitle";
import Section from "components/Section";
import MaterialTable from "components/custom/MaterialTable";
import useLoadingStatus from "hooks/useLoadingStatus";
import useHeaderTitle from "hooks/useHeaderTitle";
import { WAREHOUSE_ASSET_INCOME } from "constant/route/warehouse";

import * as queries from "graphql-custom/queries";
import cleanUUID from "util/cleanUUID";
import QrsDialog from "components/QrsDialog";
import DataLabel from "components/DataLabel";
import { downloadBlob } from "util/downloadBlob";
import { getAssetTrackingId } from "util/text";

//Create asset attribute
import useCompleteFormValues from "hooks/useCompleteFormValues";
import { createAssetAttribute } from "../../components/AttributeDialog/datastore"
import * as graphql from "../warehouse/AssetDetailsView/helpers/graphql";
import PremisesSelector from "components/FormControls/PremisesSelector";

const SECTION_TITLE = "Ingreso";

export default function AssetIncomeDetailView() {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const { incomeId } = useParams();

  useHeaderTitle(`${SECTION_TITLE} ${cleanUUID(incomeId)}`);
  const { showMessage, showError } = useNotifier();
  const [fetching, _fetchIncomeDetailInfo] = useLoadingStatus(fetchIncomeDetailInfo);
  const [downloading, _downloadCSVFile] = useLoadingStatus(downloadCSVFile);
  const [incomeDetailInfo, setIncomeDetailInfo] = useState({});
  const [isRequestError, setIsRequestError] = useState(false);
  const [openQrsDialog, setQrsDialog] = useState(false);
  const [downloadDataFileEnabled, setDownloadDataFileEnabled] = useState(false);

  const assets = React.useMemo(() => incomeDetailInfo?.assets?.items || [], [incomeDetailInfo]);
  const assetsIds = assets.map((asset) => asset?.asset?.id);

  const [isLoadingL, setIsLoadingL] = useState(false)
  const [openDialogPremise, setOpenDialogPremise] = useState(false)
  const { control, errors } = useForm({
    mode: "onChange",
    defaultValues: DEFAULT_FORM_VALUES,
  });
  const { formValues, onSelectsChange } =
    useCompleteFormValues(DEFAULT_FORM_VALUES);

  useEffect(() => {
    _fetchIncomeDetailInfo();
  }, []);

  async function fetchIncomeDetailInfo() {
    setIsRequestError(false);
    try {
      const { data } = await API.graphql(graphqlOperation(queries.getGoodsReceipt, { id: incomeId }));
      setIncomeDetailInfo(data?.getGoodsReceipt || {});
      setDownloadDataFileEnabled(data?.getGoodsReceipt?.file?.key);
    } catch (error) {
      showError("Ocurrió un error.");

      setIsRequestError(true);
      return [];
    }
  }

  async function downloadCSVFile() {
    try {
      const originalFileName = incomeDetailInfo.fileName || "activos.csv";
      const blob = await Storage.get(incomeDetailInfo?.file?.key, { download: true });
      downloadBlob(blob.Body, originalFileName);
    } catch (error) {
      showError("Ocurrió un error durante la descarga del archivo CSV.");
    }
  }

  const afterAsociateAssetQR = React.useCallback(
    (assetId, newTrackingId) => {
      const assetsCopy = [...assets];
      const assetToUpdateIndex = assetsCopy.findIndex(({ asset }) => asset.id === assetId);
      if (assetToUpdateIndex !== -1) {
        assetsCopy[assetToUpdateIndex] = {
          ...assetsCopy[assetToUpdateIndex],
          asset: {
            ...assetsCopy[assetToUpdateIndex].asset,
            trackingID: newTrackingId,
            tracking: {
              ...assetsCopy[assetToUpdateIndex].asset.tracking,
              updatedAt: dayjs().toISOString(),
              id: newTrackingId,
            },
          },
        };
        setIncomeDetailInfo((prevData) => ({ ...prevData, assets: { items: assetsCopy } }));
      }
    },
    [assets]
  );

  const afterAssociateMultipleAssetQR = (trackingIds) => {
    const hasAssetsWithNoTracking = assets.some(
      (assetIncome) => !assetIncome?.asset.trackingID || assetIncome?.asset.trackingID === "unassigned"
    );
    if (hasAssetsWithNoTracking) {
      _fetchIncomeDetailInfo();
    }
  };

  const MEMO_COLUMNS = React.useMemo(() => {
    if (matches) {
      return TABLE_COLUMNS_MOBILE(afterAsociateAssetQR);
    }
    return TABLE_COLUMNS(afterAsociateAssetQR);
  }, [afterAsociateAssetQR, matches]);


  const fetchAssetDetail = async (id) => {
    let findAttributePremise

    setIsLoadingL(true)
    try {
      const result = await graphql.fetchAssetDetail(id);
      if (result.attributes?.items) {
        let attributes = result.attributes?.items
        findAttributePremise = attributes.find((attribute) => attribute.attribute.id === "WH_ID")
      }
      return findAttributePremise
    } catch (error) {
      console.log(error)
    }
  }

  const assetAtributePremise = () => {
    const { premise } = formValues;
    // Estructura del ID de sitio
    const key = {
      id: "WH_ID",
      _version: 1,
      description: "",
      editable: false,
      name: "ID de Sitio",
      visible: true,
    }
    let count = 0
    assets.map(async asset => {

      const findAttribute = await fetchAssetDetail(asset.asset.id);

      if (!findAttribute) {
        try {
          await createAssetAttribute(asset.asset, key, premise.id)
          count += 1
        } catch (err) {
          console.log(err)
        }
      } else {
        count += 1
      }

      if (count === assets.length) {
        setOpenDialogPremise(false)
        setIsLoadingL(false)
        showMessage("Carga lista")
      }
    })
  }

  const handleOpen = () => {
    setOpenDialogPremise(true)
  }

  const handleClose = () => {
    setOpenDialogPremise(false)
  }

  return (
    <>
      <Section>
        {isRequestError && (
          <Box p={2}>
            <Alert severity="error">Ha ocurrido un error con la carga del catálogo, recarga la página por favor.</Alert>
          </Box>
        )}
        <Grid container className={classes.detailsGrid}>
          <DetailTitle title={`${SECTION_TITLE} ${cleanUUID(incomeId)}`} to={WAREHOUSE_ASSET_INCOME} />
          <Grid item xs={12} className={classes.detailsContent}>
            <Grid container spacing={2}>
              <Grid item sm={4} xs={12}>
                <DataLabel
                  label="Fecha y hora"
                  data={dayjs(incomeDetailInfo?.createdAt).format("DD/MM/YYYY HH:mm")}
                  loading={fetching}
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <DataLabel label="Tipo" data={GOODS_RECEIPTS_TYPES[incomeDetailInfo?.type]} loading={fetching} />
              </Grid>
              <Grid item sm={4} xs={12}>
                <DataLabel label="Cantidad" data={incomeDetailInfo?.quantity} loading={fetching} />
              </Grid>
              <Grid item sm={4} xs={12}>
                <DataLabel
                  label="Usuario"
                  data={`${incomeDetailInfo?.user?.name || ""} ${incomeDetailInfo?.user?.lastnameP || ""} ${incomeDetailInfo?.user?.email ? `(${incomeDetailInfo?.user?.email})` : ""
                    }`}
                  loading={fetching}
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <DataLabel label="Cliente" data={incomeDetailInfo?.project?.company.name} loading={fetching} />
              </Grid>
              <Grid item sm={4} xs={12}>
                <DataLabel label="Proyecto" data={incomeDetailInfo?.project?.name} loading={fetching} />
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end" gridGap="8px">
                  {downloadDataFileEnabled && (
                    <Button
                      variant="outlined"
                      disabled={downloading}
                      endIcon={downloading ? <CircularProgress size={22} /> : null}
                      fullWidth={matches}
                      onClick={_downloadCSVFile}
                    >
                      DESCARGAR CSV
                    </Button>
                  )}
                  <Button variant="outlined" onClick={handleOpen}>Asociar Sede</Button>
                  <DownloadQRButton
                    assetID={assetsIds}
                    content="Descargar QRs"
                    onlineTitle="Descargar QRs de activos"
                    offlineTitle="La descarga de QRs require conexión a internet"
                    color="primary"
                    size="medium"
                    variant="contained"
                    tooltipStyle={{ width: "auto" }}
                    afterDownload={afterAssociateMultipleAssetQR}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Section>
      <Section>
        <div className={classes.root}>
          <MaterialTable
            title={"Activos"}
            options={TABLE_OPTIONS}
            columns={MEMO_COLUMNS}
            data={assets}
            isLoading={fetching}
          />
        </div>
      </Section>

      <QrsDialog open={openQrsDialog} handleClose={() => setQrsDialog(false)} />

      <Dialog open={openDialogPremise}>
        <DialogTitle> Selecciona un Sitio</DialogTitle>
        <DialogContent>
          <PremisesSelector name="premise" control={control} errors={errors} onChange={onSelectsChange} required />
        </DialogContent>
        <DialogActions>
          <Button color="default" onClick={handleClose} disabled={isLoadingL}>
            Cancelar
          </Button>
          <Button color="primary" onClick={assetAtributePremise}>
            {isLoadingL ? <CircularProgress size={24} /> : 'Continuar'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const DEFAULT_FORM_VALUES = {
  premise: "",
};

const GOODS_RECEIPTS_TYPES = {
  CSV_FILE: "Archivo CSV",
};

const TABLE_OPTIONS = {
  exportButton: false,
  columnsButton: false,
  showSelectAllCheckbox: false,
  thirdSortClick: false,
};

const TABLE_COLUMNS = (afterAsociateAssetQR) => [
  { title: "Código/SKU", field: "asset.code" },
  { title: "Nombre", field: "asset.material.name" },
  {
    title: "Serie",
    field: "asset.serial",
    render: (rowData) => (rowData?.asset?.serial === "DEFAULT_SERIE" ? "Sin serie" : rowData?.asset?.serial || "S/I"),
  },
  {
    title: "QR vinculado",
    field: "asset.trackingID",
    render: (rowData) => {
      const asset = rowData?.asset;
      const trackingId = getAssetTrackingId(asset);
      const hasTrackingId = trackingId && trackingId !== "unassigned";
      const buttonContent = hasTrackingId ? cleanUUID(trackingId) : "Vincular QR";
      const onlineTitle = hasTrackingId ? "Descargar QR de activo" : "Vincular activo a un QR";
      const offlineTitle = hasTrackingId
        ? "La descarga de QR require conexión a internet"
        : "La vinculación a un QR require conexión a internet";
      return (
        <DownloadQRButton
          assetID={asset.id}
          content={buttonContent}
          onlineTitle={onlineTitle}
          offlineTitle={offlineTitle}
          color="primary"
          size="small"
          variant="contained"
          tooltipStyle={{ width: "auto" }}
          afterDownload={!hasTrackingId ? (trackingID) => afterAsociateAssetQR(asset.id, trackingID) : undefined}
        />
      );
    },
  },
  {
    title: "Fecha de vinculación",
    field: "asset.tracking.updatedAt",
    type: "datetime",
    defaultSort: "desc",
    render: (rowData) => dayjs(rowData?.asset?.tracking?.updatedAt).format("DD/MM/YYYY HH:mm") || "Sin fecha",
  },
];

const TABLE_COLUMNS_MOBILE = (afterAsociateAssetQR) => [
  {
    title: "",
    field: "asset.id",
    render: (rowData) => {
      const asset = rowData?.asset;
      const trackingId = getAssetTrackingId(asset);
      const hasTrackingId = trackingId && trackingId !== "unassigned";
      const buttonContent = hasTrackingId ? cleanUUID(trackingId) : "Vincular QR";
      const onlineTitle = hasTrackingId ? "Descargar QR de activo" : "Vincular activo a un QR";
      const offlineTitle = hasTrackingId
        ? "La descarga de QR require conexión a internet"
        : "La vinculación a un QR require conexión a internet";

      return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <strong>Código/SKU: </strong>
            {asset?.code}
          </Grid>
          <Grid item xs={12}>
            <strong>Nombre: </strong>
            {asset?.material?.name}
          </Grid>
          <Grid item xs={12}>
            <strong>Serie: </strong>
            {asset?.serial === "DEFAULT_SERIE" ? "Sin serie" : asset?.serial}
          </Grid>
          <Grid item xs={12}>
            <strong>QR vinculado: </strong>
            <DownloadQRButton
              assetID={asset.id}
              content={buttonContent}
              onlineTitle={onlineTitle}
              offlineTitle={offlineTitle}
              color="primary"
              size="small"
              variant="contained"
              tooltipStyle={{ width: "auto" }}
              afterDownload={!hasTrackingId ? (trackingId) => afterAsociateAssetQR(asset.id, trackingId) : undefined}
            />
          </Grid>
          <Grid item xs={12}>
            <strong>Fecha de vinculación: </strong>
            {dayjs(asset?.tracking?.updatedAt).format("DD/MM/YYYY HH:mm") || "Sin fecha"}
          </Grid>
        </Grid>
      );
    },
  },
];

const useStyles = makeStyles((theme) => ({
  detailsGrid: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  detailsContent: {
    padding: theme.spacing(2),
  },
  username: {
    "& > *": {
      marginRight: theme.spacing(2),
    },
  },
  roleControl: {
    minWidth: 200,
    marginTop: theme.spacing(2),
  },
  root: {
    [theme.breakpoints.down("sm")]: {
      "& .MuiTableHead-root": {
        display: "none",
      },
      "& .MuiToolbar-root": {
        flexDirection: "column",
        padding: theme.spacing(2),
      },
      "& div[class*='MTableToolbar-actions-']": {
        order: 2,
        textAlign: "right",
        width: "100%",
      },
      "& div[class*='MTableToolbar-title-']": {
        order: 1,
        textAlign: "left",
        width: "100%",
      },
      "& div[class*='MTableToolbar-searchField-']": {
        width: "100%",
        order: 0,
        marginBottom: theme.spacing(2),
      },
    },
  },
}));
