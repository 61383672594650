import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Controller, useForm } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import ConfirmationDialog from "components/ConfirmationDialog";

const DEFAULT_FORM = { remark: "" };

function RemarkDialog({ ...props }) {
  const form = useForm({
    mode: "onChange",
    defaultValues: DEFAULT_FORM,
  });

  function handleExited() {
    form.reset(DEFAULT_FORM);
  }
  const formValues = form.getValues();

  function _onConfirm() {
    const { remark } = form.getValues();
    props.onConfirm(remark);
  }

  return (
    <ConfirmationDialog
      {...props}
      onConfirm={_onConfirm}
      onExited={handleExited}
      okDisabled={!formValues.remark || form.errors.remark || props.okDisabled}
    >
      {props.children}
      <Controller
        control={form.control}
        as={TextField}
        variant="outlined"
        name="remark"
        size="small"
        rows={5}
        fullWidth
        multiline
        required
        value={formValues.remark}
        rules={wordRules}
        error={!!form.errors.remark}
        helperText={form.errors.remark?.message}
      />
    </ConfirmationDialog>
  );
}

RemarkDialog.defaultProps = {
  okDisabled: false,
  remark: "",
};

RemarkDialog.propTypes = {
  okDisabled: PropTypes.bool,
  remark: PropTypes.string.isRequired,
  children: PropTypes.element,
};
const wordRules = {
  required: "Campo obligatorio",
};

export default RemarkDialog;
