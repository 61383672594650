/**
 * Query GraphQL para obtener el detalle de un Task.
 *
 * @param {string} id ID del Task que se consulta.
 */
export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      _deleted
      _lastChangedAt
      _version
      description
      createdAt
      enabled
      id
      name
      owner
      updatedAt
      projects {
        nextToken
        startedAt
        items {
          _deleted
          _lastChangedAt
          _version
          code
          companyID
          contractNumber
          createdAt
          description
          id
          name
          updatedAt
          enabled
          startAt
          endAt
        }
      }
    }
  }
`;

export default getCompany;
