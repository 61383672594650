import {
  FETCH_UOM_CATALAOG_PENDING,
  FETCH_UOM_CATALAOG_SUCCESS,
  FETCH_NEXT_UOM_CATALOG_SUCCESS,
  FETCH_UOM_CATALAOG_ERROR,
  CREATE_UOM_SUCCESS,
  CREATE_UOM_PENDING,
  CREATE_UOM_ERROR,
  UPDATE_UOM_SUCCESS,
  UPDATE_UOM_PENDING,
  UPDATE_UOM_ERROR,
  CLEAN_UOM_REDUCER,
} from "redux/action";

import { listUoms } from "graphql-custom/queries/listUnitsOfMeasure";
import { createUOM } from "graphql-custom/mutations/createUnitOfMeasure";
import { updateUOM } from "graphql-custom/mutations/updateUnitOfMeasure";

export const fetchUomCatalog = (nextToken) => {
  const SUCCESS_TYPE = nextToken ? FETCH_NEXT_UOM_CATALOG_SUCCESS : FETCH_UOM_CATALAOG_SUCCESS;
  return {
    filter: "graphql",
    operation: listUoms,
    operationParams: { nextToken },
    types: [FETCH_UOM_CATALAOG_PENDING, SUCCESS_TYPE, FETCH_UOM_CATALAOG_ERROR],
    options: { errorMessage: "Error al consultar el catalogo" },
  };
};

export const createUOMRequest = ({ name, abbreviation, description }) => ({
  filter: "graphql",
  operation: createUOM,
  operationParams: { name, abbreviation, description },
  types: [CREATE_UOM_PENDING, CREATE_UOM_SUCCESS, CREATE_UOM_ERROR],
});

export const updateUOMRequest = ({ id, name, abbreviation, description, _version }) => ({
  filter: "graphql",
  operation: updateUOM,
  operationParams: { id, name, abbreviation, description, _version },
  types: [UPDATE_UOM_PENDING, UPDATE_UOM_SUCCESS, UPDATE_UOM_ERROR],
});

export const cleanUOMReducer = () => ({ type: CLEAN_UOM_REDUCER });
