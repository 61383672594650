import React, { useCallback } from "react";
import PDFReportButton from "components/general/Buttons/PDFReportButton";
import { PDF_REPORT_BUTTON_STYLES } from "components/site/constants";
import { CsvBuilder } from "filefy";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import MaterialTable from "components/custom/MaterialTable";
import Section from "components/Section";

import { SECTIONS, getTableColumns, getDataTable, MAX_BODY_HEIGHT, getPageSize } from "util/homologationOfColumns";

const TABLE_LOCALIZATION = {
  header: { actions: "Reporte" },
};

const exportCsv = (columnList, initialData, fileName) => {
  const columns = columnList.filter((columnDef) => {
    return columnDef.field && columnDef.export !== false;
  });

  const data = initialData.map((rowData) =>
    columns.map((columnDef) => {
      return columnDef.render ? columnDef.render(rowData) : rowData[columnDef.field];
    })
  );

  const builder = new CsvBuilder(`${fileName}.csv`);
  builder
    .setDelimeter(",")
    .setColumns(columns.map((columnDef) => columnDef.title))
    .addRows(data)
    .exportFile();
};

export const downloadInstallationReportAction = (rowData) => ({
  // eslint-disable-next-line react/display-name
  CustomComponent: () => (
    <PDFReportButton
      buttonType="icon"
      elementId={rowData.id}
      reportType="installation"
      by="task"
      elementStatus={rowData.status}
      style={PDF_REPORT_BUTTON_STYLES}
    />
  ),
});

const actionsRowResolver = () => [downloadInstallationReportAction];

function ImplementationTable(props) {
  const [tableActions, setTableActions] = React.useState([]);

  React.useEffect(() => setTableActions(actionsRowResolver), []);

  const handleRowClick = useCallback(props.onRowClick, []);
  return (
    <Box mb={{ xs: 0, sm: 2 }}>
      <Section>
        <MaterialTable
          columns={getTableColumns(SECTIONS.IMPLEMENTATION, props.status, props.data)}
          data={getDataTable(props.data, SECTIONS.IMPLEMENTATION)}
          onRowClick={handleRowClick}
          isLoading={props.loading}
          onChangePage={props.onChangePage}
          onChangeRowsPerPage={props.onChangeRowsPerPage}
          actions={tableActions}
          options={{
            pageSize: getPageSize(props.status),
            maxBodyHeight: MAX_BODY_HEIGHT,
            filtering: true,
            exportButton: true,
            exportCsv: (columnList, initialData) => exportCsv(columnList, initialData, props.title),
          }}
          localization={TABLE_LOCALIZATION}
        />
      </Section>
    </Box>
  );
}

ImplementationTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  loading: PropTypes.bool,
  title: PropTypes.string,
  src: PropTypes.string,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
  onRowClick: PropTypes.func,
  status: PropTypes.string,
};

ImplementationTable.defaultProps = {
  onRowClick: () => {},
};

export default React.memo(ImplementationTable);
