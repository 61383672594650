export function destinationDetails(task) {
  if (task.premises) {
    return `${task?.premises?.name}`;
  } else if (task.buildingLocation) {
    return `${task?.buildingLocation?.premises?.name}`;
  } else {
    return "Sin información";
  }
}

export function resolveDeliveryPremises(task) {
  if (task.premises) {
    return { ...task.premises };
  } else if (task.buildingLocation?.premises) {
    return { ...task.buildingLocation.premises };
  } else {
    return null;
  }
}

export default destinationDetails;
