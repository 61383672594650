export const listAssets = /* GraphQL */ `
  query ListAssets($filter: ModelAssetFilterInput, $limit: Int, $nextToken: String) {
    listAssets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        serial
        quantity
        type
        enabled
        _version
        material {
          id
          name
          code
        }
        tracking {
          id
          status
          _lastChangedAt
        }
        embeddedAttributes {
          attributeID
          id
          value
          name
        }
      }
      nextToken
    }
  }
`;

export default listAssets;
