import initialStore from "./initialStore";

const REDUX_STATE_KEY = process.env.REACT_APP_REDUX_STATE_NAME;

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem(REDUX_STATE_KEY);
    if (serializedState === null) {
      return initialStore;
    }
    let state = JSON.parse(serializedState);
    state = initServiceWorkerState(state);
    return state;
  } catch (error) {
    console.error(
      "Ocurrió un error durante la lectura del estado de redux desde localStorage",
      error
    );
    return initialStore;
  }
};

function initServiceWorkerState(state) {
  const newState = { ...state };
  if (newState.serviceWorker) {
    newState.serviceWorker.swActivated = false;
  } else {
    newState.serviceWorker = { swActivated: false };
  }
  return newState;
}

export const saveState = ({ barcodeScanner, ...state }) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(REDUX_STATE_KEY, serializedState);
  } catch (error) {
    console.error("An error happens saving the redux state on local storage.", error);
  }
};

export default {
  saveState,
  loadState,
};
