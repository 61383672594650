import React from "react";
import PropTypes from "prop-types";
import ListView from "components/custom/ListView";
import useOnlineStatus from "@rehooks/online-status";
import DescriptionDetailPanel from "components/admin/DescriptionDetailPanel";
import { useEnablement } from "../CompanyEnablement";
import { useFormOperations } from "../CompanyFormOperations";
import { handleExportCompanyListTable as handleExportProjectListTable } from "../CompaniesListView/helpers";
import { PROJECTS_COLUMNS } from "./helpers";
import { rowStyleCompanyDisabled as rowStyleProjectDisabled } from "../constant";
import EditIcon from "@material-ui/icons/Edit";

const ProjectsList = ({ projects = [], isLoading, onEditButtonClick }) => {
  const { onSwitchChange } = useEnablement();
  const { openForm } = useFormOperations();
  const isOnline = useOnlineStatus();

  const MEMO_PROJECTS_COLUMNS = React.useMemo(
    () => PROJECTS_COLUMNS({ onSwitchChange, isOnline }),
    [isOnline, onSwitchChange]
  );

  const handleEditClick = React.useCallback(
    (event, rowData) => {
      onEditButtonClick(event, rowData);
      openForm();
    },
    [openForm, onEditButtonClick]
  );

  const MEMO_MATERIAL_TABLE_PROPS = React.useMemo(() => {
    return {
      title: "Listado de proyectos",
      data: projects || [],
      columns: MEMO_PROJECTS_COLUMNS,
      isLoading,
      options: {
        rowStyle: ({ enabled }) => (enabled || enabled === null ? {} : rowStyleProjectDisabled),
        exportCsv: handleExportProjectListTable,
        thirdSortClick: false,
        actionsColumnIndex: 1,
        actionsCellStyle: { width: 104 },
      },
      // style: isError ? { display: "none" } : {},
      actions: [
        {
          // eslint-disable-next-line react/display-name, react/prop-types
          WrapperComponent: ({ children }) => <div style={{ margin: "0 auto" }}>{children}</div>,
          // eslint-disable-next-line react/display-name
          icon: () => <EditIcon color={isOnline ? "primary" : "disabled"} />,
          disabled: !isOnline,
          tooltip: isOnline ? "Editar Proyecto" : "Sin conexión para editar proyecto",
          onClick: handleEditClick,
          position: "row",
        },
      ],
      localization: {
        header: { actions: "Editar Proyecto" },
      },
      detailPanel: DescriptionDetailPanel,
    };
  }, [projects, MEMO_PROJECTS_COLUMNS, isLoading, isOnline, handleEditClick]);

  return <ListView materialTableProps={MEMO_MATERIAL_TABLE_PROPS} showFloatingButton={false} />;
};

export default ProjectsList;

ProjectsList.propTypes = {
  projects: PropTypes.array,
  isLoading: PropTypes.bool,
  onEditButtonClick: PropTypes.func,
};

ProjectsList.defaultProps = {
  projects: [],
  isLoading: false,
  onEditButtonClick: () => { },
};
