import { useCallback, useMemo } from "react";
import { DataStore, ModelInit, PersistentModel } from "@aws-amplify/datastore";
import { Logger } from "@aws-amplify/core";
import { DataStoreModel, GetParams, CreateParams, UpdateParams, RemoveParams } from "util/types/DataStoreHook";
import useLoadingStatus from "./useLoadingStatus";
import useNotifier from "./useNotifier";

const logger = new Logger("useDataStore");

export default function useDataStore<T extends PersistentModel>(Model: DataStoreModel<T>) {
  const { showMessage, showError } = useNotifier();

  const showSuccessMsg = useCallback(
    (successMsg) => {
      if (successMsg?.length) {
        showMessage(successMsg);
      }
    },
    [showMessage]
  );

  const showErrorMsg = useCallback(
    (errorMsg) => {
      if (errorMsg?.length) {
        showError(errorMsg);
      }
    },
    [showError]
  );

  const get = useCallback(
    async ({ criteria, paginationProducer, notifications }: GetParams<T>): Promise<T | T[] | undefined> => {
      try {
        const results = await DataStore.query(Model, criteria, paginationProducer);
        showSuccessMsg(notifications?.successMsg);
        return results;
      } catch (error) {
        logger.error("Error al realizar la consulta con DataStore: ", error);
        showErrorMsg(notifications?.errorMsg);
        throw error;
      }
    },
    [Model, showSuccessMsg, showErrorMsg]
  );

  const create = useCallback(
    async ({ values, notifications }: CreateParams) => {
      try {
        const resolvedValues = await resolvePromiseValues<T>(values);
        let model = new Model(resolvedValues);
        model = await DataStore.save(model);
        showSuccessMsg(notifications?.successMsg);
        return model;
      } catch (error) {
        logger.error("Error al realizar la creación con DataStore", error);
        showErrorMsg(notifications?.errorMsg);
        throw error;
      }
    },
    [Model, showSuccessMsg, showErrorMsg]
  );

  const update = useCallback(
    async ({ id, values, notifications }: UpdateParams): Promise<T> => {
      try {
        const originalModel = await DataStore.query(Model, id);
        if (!originalModel) {
          const error = new Error("Actualización omitida. No se encontró ningún registro con el ID proporcionado.");
          logger.error("Error al realizar la actualización con DataStore", error);
          showErrorMsg(notifications?.errorMsg);
          throw error;
        }
        const resolvedValues = await resolvePromiseValues<T>(values);
        let updatedModel = Model.copyOf(originalModel, (updated: { [key: string]: any }) => {
          console.log({ originalModel, updated });
          Object.keys(resolvedValues).map((key: string) => (updated[key] = resolvedValues[key]));
        });
        updatedModel = await DataStore.save(updatedModel);
        showSuccessMsg(notifications?.successMsg);
        return updatedModel;
      } catch (error) {
        logger.error("Error al realizar la actualización con DataStore", error);
        showErrorMsg(notifications?.errorMsg);
        throw error;
      }
    },
    [Model, showSuccessMsg, showErrorMsg]
  );

  const remove = useCallback(
    async ({ condition, notifications }: RemoveParams<T>): Promise<T | T[] | undefined> => {
      try {
        let toDelete;
        if (typeof condition === "string") {
          toDelete = await DataStore.query(Model, condition);
          if (!toDelete) {
            const error = new Error("Eliminación omitida. No se encontró ningún registro con el ID proporcionado.");
            logger.error("Error al realizar la eliminación con DataStore: ", error);
            throw error;
          }
          await DataStore.delete(toDelete!);
        } else {
          toDelete = await DataStore.delete(Model, condition);
        }
        showSuccessMsg(notifications?.successMsg);
        return toDelete;
      } catch (error) {
        logger.error("Error al ejecutar la eliminación con DataStore: ", error);
        showErrorMsg(notifications?.errorMsg);
        throw error;
      }
    },
    [Model, showSuccessMsg, showErrorMsg]
  );

  const [fetching, _get] = useLoadingStatus(get);
  const [creating, _create] = useLoadingStatus(create);
  const [updating, _update] = useLoadingStatus(update);
  const [removing, _remove] = useLoadingStatus(remove);
  const loading = fetching || creating || updating || removing;

  return useMemo(() => {
    return {
      get: _get,
      create: _create,
      update: _update,
      remove: _remove,
      fetching,
      creating,
      updating,
      removing,
      loading,
    };
  }, [_get, _create, _update, _remove, fetching, creating, updating, removing, loading]);
}

async function resolvePromiseValues<T>(values: { [key: string]: any | Promise<any> }) {
  const newValues = { ...values };
  await Promise.all(
    Object.keys(newValues).map(async (key) => {
      if (typeof newValues[key] === "object" && typeof newValues[key].then === "function") {
        const promise = newValues[key];
        const res = await promise;
        newValues[key] = res;
      }
    })
  );
  return newValues as ModelInit<T>;
}
