/**
 * Componente de botón custom que contiene el icono de código QR.
 */

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import CustomButton from "components/custom/Button";
import CropFreeRoundedIcon from "@material-ui/icons/CropFreeRounded";

const VARIANT = {
  FILLED: "FILLED",
  OUTLINED: "OUTLINED",
};

function QRButtonIcon({ onClick, width, height, variant, iconSize, iconProps, ...props }) {
  const { className: buttonClassName, ...restProps } = props;
  const { className: iconClassName, ...restIconProps } = iconProps;
  const classes = useStyles({ width, height, variant, iconSize });

  return (
    <CustomButton onClick={onClick} className={`${classes.readerQrBtn} ${buttonClassName}`} {...restProps}>
      {/* <img src="/img/qrcode-green.png" alt="icon" />
       */}
      <CropFreeRoundedIcon className={`${classes.QRIcon} ${iconClassName}`} {...restIconProps} />
    </CustomButton>
  );
}

QRButtonIcon.propTypes = {
  onClick: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  variant: PropTypes.oneOf(Object.values(VARIANT)),
  className: PropTypes.string,
  iconSize: PropTypes.string,
  iconProps: PropTypes.object,
};

QRButtonIcon.defaultProps = {
  onClick: () => {},
  width: "48px",
  height: "48px",
  variant: VARIANT.FILLED,
  className: "",
  iconSize: "2rem",
  iconProps: { className: "" },
};

const useStyles = makeStyles((theme) => ({
  readerQrBtn: ({ width, height, variant }) => {
    let variantStyles = {};
    if (variant === VARIANT.FILLED) {
      variantStyles = {
        backgroundColor: theme.palette.primary.main,
      };
    }
    if (variant === VARIANT.OUTLINED) {
      variantStyles = {
        border: `1px solid ${theme.palette.primary.main}`,
        backgroundColor: "transparent",
      };
    }

    return {
      ...variantStyles,
      height: height,
      width: width,
      "min-width": "auto",
      borderRadius: "50%",

      "&:hover": {
        boxShadow: `0px 0px 10px ${theme.palette.primary.main}`,
        backgroundColor: theme.palette.primary.main,
      },
    };
  },

  QRIcon: ({ iconSize, variant }) => ({
    color: variant === VARIANT.FILLED ? "#fff" : theme.palette.primary.main,
    fontSize: iconSize,
  }),
}));

export default QRButtonIcon;
