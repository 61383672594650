// Componente para mostrar la lista de materiales
import React, { useEffect, useState, useMemo } from "react";
import dayjs from "dayjs";
import { useHistory, useLocation } from "react-router-dom";

import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Alert from "@material-ui/lab/Alert";
import DialogTitle from "@material-ui/core/DialogTitle";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import PostAddIcon from "@material-ui/icons/PostAdd";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { Select } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";

import Section from "components/Section";
import MaterialTable from "components/custom/MaterialTable";
import ConfirmationDialog from "components/ConfirmationDialog";

import useHeaderTitle from "hooks/useHeaderTitle";
import useNotifier from "hooks/useNotifier";
import useLoadingStatus from "hooks/useLoadingStatus";

import { MATERIAL_DATA_LOAD } from "constant/route/admin";
import { useActions } from "hooks/useActions";
import { REQUEST_STATUS } from "constant/requestStatus";
import {
  fetchMaterialCatalog,
  searchMaterialCatalog,
  createMaterialRequest,
  updateMaterialRequest,
  cleanMaterialReducer,
} from "redux/dispatcher/materialCatalog";
import { fetchUomCatalog } from "redux/dispatcher/uomCatalog";
import { fetchMaterialCategoriesCatalog } from "redux/dispatcher/materialCategoriesCatalog";
import { checkNextItems, createSearchFilter } from "util/lists";
import * as helpers from "./helpers/graphql";

const FORM_STATUS = {
  ADD: "ADD",
  UPDATE: "UPDATE",
};

const SECTION_TITLE = "Materiales";

function MaterialListView() {
  const history = useHistory();
  const classes = useStyles();
  const { showError, showMessage } = useNotifier();
  const location = useLocation();

  useHeaderTitle(SECTION_TITLE);
  const {
    materials: rdxMaterials,
    materialsToken: rdxMaterialsToken,
    fetchStatus: rdxFetchStatus,
    fetchCreationStatus: rdxFetchCreationStatus,
    fetchUpdateStatus: rdxFetchUpdateStatus,
  } = useSelector(({ materialCatalog }) => materialCatalog);
  const userId = useSelector(({ session }) => session.userId);
  const { uoms: rdxUoms, fetchStatus: rdxUomFetchStatus } = useSelector(({ uomCatalog }) => uomCatalog);
  const { materialCategories: rdxMaterialCategories, fetchStatus: rdxMaterialCategoriesFetchStatus } = useSelector(
    ({ materialCategoriesCatalog }) => materialCategoriesCatalog
  );
  const [
    fetchMaterialCatalogRequest,
    findMaterials,
    fetchUomCatalogRequest,
    fetchMaterialCategoriesCatalogRequest,
    createMaterial,
    updateMaterial,
    cleanMatReducer,
  ] = useActions([
    fetchMaterialCatalog,
    searchMaterialCatalog,
    fetchUomCatalog,
    fetchMaterialCategoriesCatalog,
    createMaterialRequest,
    updateMaterialRequest,
    cleanMaterialReducer,
  ]);
  const { register, setValue, reset, getValues, handleSubmit, errors } = useForm({
    defaultValues: {
      materialName: "",
      materialCode: "",
      materialManufacturer: "",
      materialDescription: "",
    },
  });
  const [materialFormStatus, setMaterialFormStatus] = useState(FORM_STATUS.ADD);
  const [materialList, setMaterialList] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [UOMList, setUOMList] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [selectedMaterial, setSelectedMaterial] = useState("");
  const [materialDialogOpen, setMaterialDialogOpen] = useState(false);
  const [confirmAddDialogOpen, setConfirmAddDialogOpen] = useState(false);
  const [confirmEditDialogOpen, setConfirmEditDialogOpen] = useState(false);
  const [selectedUOMId, setSelectedUOMId] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [activeMaterialVersion, setActiveMaterialVersion] = useState(null);
  const [removing, _removeMaterials] = useLoadingStatus(helpers.removeMaterials);
  const [selectedMaterialList, setSelectedMaterialList] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  useEffect(() => {
    fetchMaterialCatalogRequest();

    return () => {
      cleanMatReducer();
    };
  }, []);

  useEffect(() => {
    setMaterialList(rdxMaterials);
  }, [rdxMaterials]);
  useEffect(() => {
    setUOMList(rdxUoms);
  }, [rdxUoms]);
  useEffect(() => {
    setCategoryList(rdxMaterialCategories);
  }, [rdxMaterialCategories]);

  useEffect(() => {
    if (rdxFetchCreationStatus === REQUEST_STATUS.SUCCESSFUL) {
      handleSuccessfulMaterialAdding();
    } else if (rdxFetchCreationStatus === REQUEST_STATUS.FAILED) {
      handleErrorMaterialAdding();
    }
  }, [rdxFetchCreationStatus]);
  useEffect(() => {
    if (rdxFetchUpdateStatus === REQUEST_STATUS.SUCCESSFUL) {
      handleSuccessfulMaterialUpdate();
    } else if (rdxFetchUpdateStatus === REQUEST_STATUS.FAILED) {
      handleErrorMaterialUpdate();
    }
  }, [rdxFetchUpdateStatus]);

  const actions = useMemo(() => {
    if (location.pathname.startsWith("/validacion/")) {
      return [
        {
          icon: <PostAddIcon />,
          name: "Nuevo Material",
          action: () => handleMaterialDialogVisibilityStatus(true),
        },
      ];
    } else {
      return [
        {
          icon: <PostAddIcon />,
          name: "Nuevo Material",
          action: () => handleMaterialDialogVisibilityStatus(true),
        },
        {
          icon: <FileCopyIcon />,
          name: "Carga Manual",
          action: () => {
            history.push(MATERIAL_DATA_LOAD);
          },
        },
      ];
    }
  }, []);

  function loadNextMaterials(page) {
    const hasMoreItems = checkNextItems(page, materialList.length);
    if (hasMoreItems && rdxMaterialsToken && searchTerm.length) {
      const filter = createSearchFilter(["name", "code", "description"], searchTerm);
      findMaterials(filter, rdxMaterialsToken);
    } else if (hasMoreItems && rdxMaterialsToken && !searchTerm.length) {
      fetchMaterialCatalogRequest(rdxMaterialsToken);
    }
  }

  function searchMaterials(term) {
    if (term?.length) {
      const filter = createSearchFilter(["name", "code", "description"], term);
      findMaterials(filter);
    } else {
      fetchMaterialCatalogRequest();
    }
    setSearchTerm(term);
  }

  function handleUOMSelectChange(value) {
    setSelectedUOMId(value);
  }

  function handleCategorySelectChange(value) {
    setSelectedCategoryId(value);
  }

  function handleMaterialDialogVisibilityStatus(status) {
    if (status && (categoryList.length === 0 || UOMList.length === 0)) {
      // Cargar los catalogos si no están cargados.
      fetchUomCatalogRequest();
      fetchMaterialCategoriesCatalogRequest();
    }
    setMaterialDialogOpen(status);
  }

  function updateMaterialDialogOpen(material) {
    const materialObject = materialList.find((mat) => mat.id === material.id);
    setSelectedMaterial(material.id);
    setSelectedCategoryId(materialObject?.category?.id || "");
    setSelectedUOMId(materialObject?.uom?.id || "");
    setActiveMaterialVersion(materialObject?._version);
    setValue("materialName", material.name);
    setValue("materialCode", material.code);
    setValue("materialManufacturer", material.manufacturer);
    setValue("materialDescription", materialObject?.description || "");
    setMaterialFormStatus(FORM_STATUS.UPDATE);
    handleMaterialDialogVisibilityStatus(true);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  function handleCloseMaterialDialog() {
    setSelectedMaterial("");
    reset();
    setSelectedCategoryId("");
    setSelectedUOMId("");
    setActiveMaterialVersion(null);
    setMaterialFormStatus(FORM_STATUS.ADD);
    handleMaterialDialogVisibilityStatus(false);
  }

  function handleSuccessfulMaterialAdding() {
    showMessage("Material agregado con éxito");
    reset();
    setSelectedCategoryId("");
    setSelectedUOMId("");
    setSelectedMaterial("");
    setActiveMaterialVersion(null);
    setConfirmAddDialogOpen(false);
    setMaterialDialogOpen(false);
    cleanMatReducer();
    fetchMaterialCatalogRequest();
  }

  function handleErrorMaterialAdding() {
    showError("Ha ocurrido un error, intente de nuevo por favor.");
    setConfirmAddDialogOpen(false);
  }

  function handleSuccessfulMaterialUpdate() {
    showMessage("Material actualizado con éxito");
    reset();
    setSelectedMaterial("");
    setConfirmEditDialogOpen(false);
    setMaterialDialogOpen(false);
    setSelectedCategoryId("");
    setActiveMaterialVersion(null);
    setMaterialFormStatus(FORM_STATUS.ADD);
    setSelectedUOMId("");
    cleanMatReducer();
    fetchMaterialCatalogRequest();
  }

  function handleErrorMaterialUpdate() {
    showError("Ha ocurrido un error, intente de nuevo por favor.");
    setConfirmEditDialogOpen(false);
  }

  function onConfirmUpdate() {
    const values = getValues();
    updateMaterial({
      id: selectedMaterial,
      name: values.materialName?.trim(),
      code: values.materialCode?.trim(),
      manufacturer: values.materialManufacturer?.trim(),
      description: values.materialDescription?.trim(),
      categoryId: selectedCategoryId,
      uomId: selectedUOMId,
      _version: activeMaterialVersion,
    });
  }

  function onCancelUpdate() {
    setConfirmEditDialogOpen(false);
  }

  function handleOnSendAddAction() {
    if (selectedCategoryId === "") {
      showError("Debes seleccionar una categoría de producto");
    } else {
      if (selectedMaterial === "") {
        setConfirmAddDialogOpen(true);
      } else {
        setConfirmEditDialogOpen(true);
      }
    }
  }

  function onConfirmAdd() {
    const values = getValues();
    createMaterial({
      name: values.materialName?.trim(),
      code: values.materialCode?.trim(),
      manufacturer: values.materialManufacturer?.trim(),
      description: values.materialDescription?.trim(),
      categoryId: selectedCategoryId,
      uomId: selectedUOMId,
    });
  }

  function onCancelAdd() {
    setConfirmAddDialogOpen(false);
  }

  async function deleteMaterials() {
    try {
      await _removeMaterials(selectedMaterialList, userId);
      setShowDeleteConfirmation(false);
      setSelectedMaterialList([]);
      fetchMaterialCatalogRequest();
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <Box mb={2} mt={2}>
        <div className={classes.exampleWrapper}>
          <Backdrop open={open} className={classes.dialBackdrop} />
          <SpeedDial
            ariaLabel="SpeedDial example"
            className={classes.speedDial}
            icon={<SpeedDialIcon />}
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
            direction="up"
          >
            {actions.map((action) => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipOpen
                tooltipTitle={action.name}
                onClick={action.action}
              />
            ))}
          </SpeedDial>
        </div>
        <Section>
          {rdxFetchStatus === REQUEST_STATUS.FAILED && (
            <Box p={2}>
              <Alert severity="error">
                Ha ocurrido un error con la carga del catálogo, recarga la página por favor.
              </Alert>
            </Box>
          )}
          <MaterialTable
            title={SECTION_TITLE}
            columns={columns}
            data={materialList}
            onChangePage={loadNextMaterials}
            onSearchChange={searchMaterials}
            onSelectionChange={setSelectedMaterialList}
            options={TABLE_OPTIONS}
            detailPanel={(rowData) => (
              <div className={classes.detailPanelContainer}>
                <p className={classes.detailPanelTitle}>Descripción</p>
                <p>{rowData?.description || "Sin información"}</p>
              </div>
            )}
            actions={[
              {
                icon: EditIcon,
                tooltip: "Editar material",
                position: "row",
                onClick: (event, rowData) => {
                  updateMaterialDialogOpen(rowData);
                },
              },
              {
                icon: () => <DeleteIcon color="action" />,
                tooltip: "Eliminar",
                position: "toolbarOnSelect",
                onClick: () => setShowDeleteConfirmation(true),
              },
            ]}
            isLoading={rdxFetchStatus === REQUEST_STATUS.PENDING}
          />
        </Section>

        <Dialog
          fullWidth
          maxWidth="md"
          open={materialDialogOpen}
          onClose={handleCloseMaterialDialog}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Material</DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <TextField
                  autoFocus
                  inputRef={register({
                    required: "Este campo es obligatorio",
                    validate: {
                      notEmpty: (value) => value.trim() !== "" || "El campo no debe estar vacio",
                    },
                  })}
                  required
                  margin="dense"
                  name="materialName"
                  label="Nombre"
                  type="text"
                  fullWidth
                  error={errors?.materialName?.message}
                  helperText={errors.materialName?.message || ""}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  margin="dense"
                  inputRef={register({
                    required: "Este campo es obligatorio",
                    validate:
                      materialFormStatus === FORM_STATUS.ADD
                        ? {
                            notEmpty: (value) => value.trim() !== "" || "El campo no debe estar vacio",
                          }
                        : {},
                  })}
                  name="materialCode"
                  label="Codigo de material"
                  type="text"
                  fullWidth
                  required
                  disabled={materialFormStatus === FORM_STATUS.UPDATE}
                  error={errors?.materialCode?.message}
                  helperText={errors.materialCode?.message || ""}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  margin="dense"
                  inputRef={register}
                  name="materialManufacturer"
                  label="Fabricante"
                  type="text"
                  fullWidth
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InputLabel id="demo-mutiple-checkbox-label">Categoría</InputLabel>
                <Select
                  name="materialCategory"
                  fullWidth
                  value={selectedCategoryId}
                  onChange={(evt) => handleCategorySelectChange(evt.target.value)}
                  required
                >
                  {categoryList.map((category) => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item md={6} xs={12}>
                <InputLabel id="demo-mutiple-checkbox-label">Unidad de medida </InputLabel>
                <Select
                  name="materialUOM"
                  fullWidth
                  value={selectedUOMId}
                  onChange={(evt) => handleUOMSelectChange(evt.target.value)}
                  required
                >
                  {UOMList.map((uom) => (
                    <MenuItem key={uom.id} value={uom.id}>
                      {uom.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  variant="outlined"
                  inputRef={register}
                  multiline
                  rows={6}
                  margin="dense"
                  name="materialDescription"
                  label="Descripción"
                  type="text"
                  fullWidth
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseMaterialDialog} color="primary">
              Cancelar
            </Button>
            <Button onClick={handleSubmit(handleOnSendAddAction)} color="primary">
              {selectedMaterial === "" ? "Agregar" : "Actualizar"}
            </Button>
          </DialogActions>
        </Dialog>

        <ConfirmationDialog
          open={confirmEditDialogOpen}
          title="Confirmación"
          onConfirm={onConfirmUpdate}
          onCancel={onCancelUpdate}
          loading={rdxFetchCreationStatus === REQUEST_STATUS.PENDING || rdxFetchUpdateStatus === REQUEST_STATUS.PENDING}
        >
          Está a punto de modificar este material. ¿Desea continuar?
        </ConfirmationDialog>

        <ConfirmationDialog
          open={confirmAddDialogOpen}
          title="Confirmación"
          onConfirm={onConfirmAdd}
          onCancel={onCancelAdd}
          loading={rdxFetchCreationStatus === REQUEST_STATUS.PENDING || rdxFetchUpdateStatus === REQUEST_STATUS.PENDING}
        >
          Está a punto de registrar un material.
        </ConfirmationDialog>

        <ConfirmationDialog
          open={showDeleteConfirmation}
          title="Confirmación"
          onConfirm={deleteMaterials}
          onCancel={() => setShowDeleteConfirmation(false)}
          loading={removing}
        >
          Estás a punto de eliminar <strong>{`${selectedMaterialList.length} materiales.`}</strong>
          <br />
          <br />
          ¿Deseas continuar?
        </ConfirmationDialog>
      </Box>
      <Backdrop
        className={classes.backdrop}
        open={
          rdxUomFetchStatus === REQUEST_STATUS.PENDING || rdxMaterialCategoriesFetchStatus === REQUEST_STATUS.PENDING
        }
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default MaterialListView;

const useStyles = makeStyles((theme) => ({
  dialBackdrop: {
    zIndex: 1000,
    color: "#fff",
  },
  backdrop: {
    zIndex: 2000,
    color: "#fff",
  },
  sectionTitleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "10px 15px",
  },
  sectionTitleText: {
    margin: 0,
    fontWeight: "bold",
    fontSize: 18,
    marginLeft: 16,
  },
  detailPanelContainer: {
    background: "#F2F2F2",
    padding: "16px",
  },
  detailPanelTitle: {
    fontWeight: "bold",
  },
  speedDial: {
    position: "fixed",
    "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
      bottom: theme.spacing(2),
      right: theme.spacing(3),
    },
    "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
      top: theme.spacing(2),
      left: theme.spacing(3),
    },
  },
}));

const columns = [
  { title: "ID", field: "id", hidden: true },
  {
    title: "Código",
    field: "code",
  },
  {
    title: "Nombre",
    field: "name",
  },
  {
    title: "Categoría",
    field: "category.name",
    render: (rowData) => <p>{rowData?.category?.name || "Sin información"}</p>,
  },
  {
    title: "Unidad de medida",
    field: "uom.name",
    render: (rowData) => <p>{rowData?.uom?.name || "Sin información"}</p>,
  },
  {
    title: "Fabricante",
    field: "manufacturer",
    render: (rowData) => <p>{rowData?.manufacturer || "Sin información"}</p>,
  },
  {
    title: "Fecha de registro",
    field: "createdAt",
    render: (rowData) => (rowData.createdAt ? dayjs(rowData.createdAt).format("DD/MM/YYYY HH:mm") : "Sin información"),
  },
  {
    title: "Última actualización",
    field: "updatedAt",
    render: (rowData) => (rowData.updatedAt ? dayjs(rowData.updatedAt).format("DD/MM/YYYY HH:mm") : "Sin información"),
  },
];

const TABLE_OPTIONS = { debounceInterval: 400, selection: true, showSelectAllCheckbox: false };
