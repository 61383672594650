export const createAttachmentAsset = /* GraphQL */ `
  mutation CreateAttachmentAsset($input: CreateAttachmentAssetInput!) {
    createAttachmentAsset(input: $input) {
      id
      taskAssetID
      status
      platform
      source
      loadType
      createdAt
      updatedAt
      _version
      _lastChangedAt
      _deleted
      file {
        bucket
        key
        region
      }
    }
  }
`;
