export const ROOT = "/ingreso";

export const GOODS_RECEIPT_ROOT = `${ROOT}/entrada`;
export const GOODS_RECEIPT_DETAIL = `${GOODS_RECEIPT_ROOT}/:actionId`;

export const GOODS_RECEIPT = {
  ROOT: GOODS_RECEIPT_ROOT,
  DETAIL: GOODS_RECEIPT_DETAIL
};

export const MATERIAL_ENTRY = {
  ROOT,
  GOODS_RECEIPT
};

export default MATERIAL_ENTRY;
