export const listAssetsByType = /* GraphQL */ `
  query ListAssetsByType($type: AssetType, $filter: ModelAssetFilterInput, $limit: Int) {
    assetsByType(type: $type, filter: $filter, limit: $limit) {
      items {
        id
        code
        serial
        quantity
        type
        material {
          id
          name
        }
        _version
      }
      nextToken
    }
  }
`;
