export const addUserToCognitoGroup = /* GraphQL */ `
  mutation AddUserToCognitoGroup($userId: String!, $group: String!) {
    lambdaAddUserToGroup(input: { username: $userId, groupName: $group }) {
      statusCode
      error
      body
      errorMessage
    }
  }
`;

export default addUserToCognitoGroup;
