import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Toolbar, AppBar, Button } from "@material-ui/core";
import useStyles from "./useStyles";
import PremisesDialog from "components/brand/PremisesDialog";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { AUTHENTICATOR_ROUTES } from "./constants";

export default function LoginAppbar() {
  const [isSedesDialogOpen, setIsSedesDialogOpen] = useState(false);
  const theme = useSelector((state) => state.theme);
  const classes = useStyles();
  const { route } = useAuthenticator((context) => [context.route]);

  //Este componente no se renderiza dentro de la aplicación, unicamente en la vista de login
  if (route === AUTHENTICATOR_ROUTES.AUTHENTICATED) {
    return null;
  }

  return (
    <>
      <AppBar position="static">
        <Toolbar classes={{ root: classes.toolbar }}>
          <div className={classes.title}>
            <img
              src={theme.logoSrc}
              height="25"
              alt={theme.logoAlt}
              id="sesion-client-logo"
              title={`Logo del cliente`}
            />
          </div>
          <Button
            color="inherit"
            onClick={() => setIsSedesDialogOpen(true)}
            id="company-headquaters"
            title={`Mostrar las sedes del cliente`}
          >
            Ver sedes
          </Button>
        </Toolbar>
      </AppBar>
      <div className={classes.stripe} />
      <PremisesDialog open={isSedesDialogOpen} onClose={() => setIsSedesDialogOpen(false)} />
    </>
  );
}
