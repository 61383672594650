import React from "react";
import { Backdrop, Typography } from "@material-ui/core";

const SuspenseFallback = (props) => {
  return (
    <Backdrop open={true} style={{ zIndex: 1301, color: "#fff" }} {...props}>
      <Typography color="inherit">Cargando...</Typography>
    </Backdrop>
  );
};

export default SuspenseFallback;
