/**
 * This function generate the mutation string to delete several UserTask rows in Dynamo
 * @param {Array} userTaskDeletedIds an object array containing the UserTask rows to be deleted
 */
export function generateDeletedUserTaskRelations(userTaskDeletedIds) {
  const variables = {};
  let mutation = "";
  let mutationParams = "";
  if (userTaskDeletedIds.length > 0) {
    userTaskDeletedIds.forEach((userTask, index) => {
      mutationParams += `$userTaskId${index}: ID!, $userTaskVersion${index}: Int!${
        index + 1 === userTaskDeletedIds.length ? "" : ", "
      }`;
      mutation += `
      DeleteUserTask${index}: deleteUserTask(input: {
            id: $userTaskId${index}
            _version:$userTaskVersion${index}
          }) {
            id
          }
          ${index + 1 === userTaskDeletedIds.length ? "" : ","}
          `;

      variables[`userTaskId${index}`] = userTask.id;
      variables[`userTaskVersion${index}`] = userTask.version;
    });
  }
  mutation = `mutation deleteAssignations(${mutationParams}) {
        ${mutation}
      }`;

  return {
    relationsVariables: variables,
    relationsMutation: mutation,
  };
}
