import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import "typeface-roboto";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import { defineCustomElements } from "@ionic/pwa-elements/loader";

import "styles/base.css";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import store from "redux/store";
import { SERVICE_WORKER_UPDATE } from "redux/action";
import { getUserAndInitSentry } from "setup/sentry";
import { APP_PLATFORM } from "constant/appPlatform";
import { ENVIRONMENT } from "constant/environments";

if (process.env.REACT_APP_ENV === ENVIRONMENT.PROD) {
  getUserAndInitSentry();
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

defineCustomElements(window);

function handleUpdate(registration) {
  store.dispatch({ type: SERVICE_WORKER_UPDATE, payload: registration });
}

if (process.env.REACT_APP_APP_PLATFORM === APP_PLATFORM.WEB) {
  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.register({
    onUpdate: handleUpdate,
  });
}
