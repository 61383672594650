/* eslint-disable react/display-name */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

//hooks
import useHeaderTitle from "hooks/useHeaderTitle";
import useDeviceSettings from "hooks/useDeviceSettings";
import useAsync, { ASYNC_STATUS } from "hooks/useAsync";
import useLoadingStatus from "hooks/useLoadingStatus";

//components
import TicketDetailsCard from "components/general/TicketDetailsCard";
import TicketAssetCard from "components/general/TicketAssetCard";
import TicketActivityDetailMaterials from "./TicketActivityDetailMaterials";
import TicketsActivityDetailGeneralInfo from "./TicketsActivityDetailGeneralInfo";
import TabContainer from "components/Tabs/TabContainer";
import TicketTimerLogView from "components/Tickets/TicketsTimerLogView";
import DetailViewLoadingSkeleton from "components/common/DetailViewLoadingSkeleton";
import FetchErrorFallback from "components/common/FetchErrorFallback";

//handlers / helpers / utils
import { fetchTicketById } from "datastore";
import useGlogalOperations from "hooks/useGlogalOperations";
import { findTicketActivityById } from "./helpers";
import { getTimerLogs, isMobile } from "components/general/TicketTimerLog/helpers";
import { addTaskIdToLocalStorageKey, clearLocalStorage } from "util/localStorage";
import { divideAssetsAndMaterials, getAssetGarrantyByAsset, getAssetClientGarrantyByAsset } from "util/assets";
import cleanUUID from "util/cleanUUID";

//constants
import { TaskStatus, SupportType } from "models";
import { LOCAL_STORAGE_CURRENT_STEP_KEY, LOCAL_STORAGE_FORM_VALUES_KEY } from "constant/ticketActivity";
import { ASSET_MODE } from "constant/ticketConstants";

import { Logger } from "aws-amplify";
const logger = new Logger("TicketActivityDetailsView");

export default function TicketActivityDetailsView(props) {
  //---- estados de información ----
  const [taskAttachments, setTaskAttachments] = useState([]);
  const [isWeb, setIsWeb] = useState(false);
  const [materials, setMaterials] = useState([]);
  const [assets, setAssets] = useState([]);

  const { id: taskId } = useParams();
  const device = useDeviceSettings();

  // ---- consultas ----
  const { updateTicket: updateTicketOperation, updateTask: updateTaskOperation } = useGlogalOperations();
  const [updatingLegacyTask, _updateLegacyTask] = useLoadingStatus(updateLegacyTask);
  const {
    data: taskData,
    status: taskRequestStatus,
    error: taskError,
    run: runTaskFetch,
    setData: setTaskData,
  } = useAsync({
    status: taskId ? ASYNC_STATUS.PENDING : ASYNC_STATUS.IDLE,
  });
  const {
    data: ticketData,
    status: ticketRequestStatus,
    error: ticketError,
    run: runTicketFetch,
    setData: setTicketData,
  } = useAsync();

  // ---- valores calculados ----
  const isFetching =
    taskRequestStatus === ASYNC_STATUS.PENDING || ticketRequestStatus === ASYNC_STATUS.PENDING || updatingLegacyTask;
  const cleanedTicketID = cleanUUID(ticketData?.id, "");
  const isUpdateLegacyTaskEnabled = React.useRef(false);

  useHeaderTitle(`Soporte ${cleanedTicketID}`);
  useEffect(() => {
    const info = device.getInfo();
    setIsWeb(info.platform === "web");
  }, [device]);

  // Se encarga de actualizar los status del task y ticket en caso de ser una actividad una actividad inciada con el viejo flujo de soporte.
  useEffect(() => {
    //este flujo unicamente se puede disparar la primera vez que cargan los datos completamente de la vista de detalle, posteriormente ya no.
    if (taskRequestStatus === ASYNC_STATUS.RESOLVED && ticketRequestStatus === ASYNC_STATUS.RESOLVED) {
      if (!isUpdateLegacyTaskEnabled.current) {
        isUpdateLegacyTaskEnabled.current = true;
      } else {
        return;
      }
    } else {
      return;
    }

    const isATaskLegacy =
      taskData.status === TaskStatus.SCHEDULED &&
      ticketData.supportType &&
      [SupportType.REMOTE, SupportType.PRESENTIAL].includes(ticketData.supportType);
    if (isATaskLegacy && isUpdateLegacyTaskEnabled.current) {
      clearLocalStorage(addTaskIdToLocalStorageKey(LOCAL_STORAGE_CURRENT_STEP_KEY, taskId));
      clearLocalStorage(addTaskIdToLocalStorageKey(LOCAL_STORAGE_FORM_VALUES_KEY, taskId));
      _updateLegacyTask();
    }
  }, [_updateLegacyTask, taskData, taskId, taskRequestStatus, ticketData, ticketRequestStatus]);

  useEffect(() => {
    if (!taskId) return;
    runTaskFetch(fetchAndSetTaskData(taskId, { updateTicket: true }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskId, runTaskFetch]);

  async function fetchAndSetTaskData(taskId, { updateTicket = false } = {}) {
    const taskData = await findTicketActivityById(taskId);

    if (!taskData || !Object.keys(taskData).length) {
      throw new Error("Ocurrio un error durante la carga de información del soporte");
    }

    const parsedTaskAssets = taskData.assets.map((taskAsset) => ({
      ...taskAsset.taskAssets,
    }));
    const materialsAndAssets = divideAssetsAndMaterials(parsedTaskAssets);
    setAssets(materialsAndAssets.assets);
    setMaterials(materialsAndAssets.materials);
    setTaskAttachments(taskData.attachments);

    if (updateTicket) {
      runTicketFetch(fetchTicketById(taskData.ticket.id));
    }

    logger.log("fetchAndSetTaskData", { materialsAndAssets, taskData });
    return taskData;
  }

  async function updateLegacyTask() {
    const shouldUpdateTicket = ticketData.status === TaskStatus.SCHEDULED;
    await updateTaskOperation(taskData.id, { status: TaskStatus.IN_PROGRESS }, taskData._version);

    if (shouldUpdateTicket) {
      await updateTicketOperation(ticketData.id, { status: TaskStatus.IN_PROGRESS }, ticketData._version);
    }

    runTaskFetch(fetchAndSetTaskData(taskId, { updateTicket: shouldUpdateTicket }));
  }

  function onGenerateOrDownloadAssetQR(trackingID) {
    const _ticketDetails = { ...ticketData };
    _ticketDetails.asset.trackingID = trackingID;
    setTicketData(_ticketDetails);
  }

  if (taskRequestStatus === ASYNC_STATUS.IDLE || ticketRequestStatus === ASYNC_STATUS.IDLE || isFetching) {
    return <DetailViewLoadingSkeleton />;
  }

  // if error while fetching
  if (taskRequestStatus === ASYNC_STATUS.REJECTED || ticketRequestStatus === ASYNC_STATUS.REJECTED) {
    logger.error({ taskError, ticketError });
    return (
      <FetchErrorFallback
        open={true}
        onClick={() => runTaskFetch(fetchAndSetTaskData(taskId, { updateTicket: true }))}
      />
    );
  }

  //if queries are succeed, then render content
  if (taskRequestStatus === ASYNC_STATUS.RESOLVED && ticketRequestStatus === ASYNC_STATUS.RESOLVED) {
    const generalInfo = (
      <TicketsActivityDetailGeneralInfo
        cleanedTicketID={cleanedTicketID}
        ticketData={ticketData}
        taskData={taskData}
        setTicketData={setTicketData}
        setTaskData={setTaskData}
        assets={assets}
        setAssets={setAssets}
        taskAttachments={taskAttachments}
        setTaskAttachments={setTaskAttachments}
        fetchAndSetTaskData={() => runTaskFetch(fetchAndSetTaskData(taskId))}
        isFetching={isFetching}
        userType={props.type}
      />
    );

    const ticketMaterial = <TicketActivityDetailMaterials usedActivityMaterials={materials} />;

    const ticket = <TicketDetailsCard ticketDetails={ticketData || {}} isFetching={isFetching} />;

    const ticketAssetCard = (
      <>
        <TicketAssetCard
          asset={ticketData.reportedAsset}
          isFetching={isFetching}
          userType={props.type}
          mode={ASSET_MODE.REPORTED_ASSET}
        />

        {Boolean(assets) &&
          Boolean(assets.length) &&
          assets.map(({ id, asset }) => (
            <TicketAssetCard
              key={id}
              asset={asset}
              isFetching={isFetching}
              assetWarranty={getAssetGarrantyByAsset(asset)}
              assetClientWarranty={getAssetClientGarrantyByAsset(asset)}
              onGenerateOrDownloadAssetQR={onGenerateOrDownloadAssetQR}
              userType={props.type}
              mode={ASSET_MODE.TASK_ASSET}
            />
          ))}
      </>
    );

    // Obj to define tabs
    const tabData = [
      {
        name: "Información general",
        index: 0,
        component: generalInfo,
      },

      {
        name: "Información del ticket",
        index: 1,
        component: ticket,
      },
      {
        name: "Activos",
        index: 2,
        component: ticketAssetCard,
      },
      {
        name: "Materiales",
        index: 3,
        component: ticketMaterial,
      },
    ];

    const SHOW_TIMER_LOG_TAB =
      isWeb &&
      !isMobile() &&
      taskData.status &&
      ![TaskStatus.SCHEDULED, TaskStatus.IN_PROGRESS].includes(taskData.status) &&
      Boolean(getTimerLogs(taskData.embeddedEvents, taskData).length);

    if (SHOW_TIMER_LOG_TAB) {
      tabData.push({
        name: "Tiempo de soporte",
        index: 4,
        component: <TicketTimerLogView activity={getTimerLogs(taskData.embeddedEvents, taskData)} />,
      });
    }

    return <TabContainer tabData={tabData} />;
  }

  return null;
}

TicketActivityDetailsView.propTypes = {
  type: PropTypes.string,
};

TicketActivityDetailsView.defaultProps = {
  type: "",
};
