export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      middlename
      lastnameP
      lastnameM
      email
      phone
      extension
      status
      _version
      _deleted
      roles {
        items {
          id
          _version
          _deleted
          role {
            id
            name
          }
          createdAt
        }
        nextToken
      }
      legalAgreements {
        items {
          userID
          legalDocumentID
          isGranted
          id
          grantedAt
          agreement {
            type
            status
            name
            id
            expirationDate
            effectiveDate
            description
            createdAt
            version
            _version
          }
          createdAt
          _version
        }
      }
    }
  }
`;