/* eslint-disable react/prop-types */
import React from "react";
import QRCode from "qrcode.react";
import makeStyles from "@material-ui/core/styles/makeStyles";

export default function QrContainer({ asset }) {
  const classes = useStyles();

  const qrId = asset?.qr?.id || "";
  let qrFolio = "";
  if (qrId.length > 6) {
    qrFolio = qrId.substring(qrId.length - 6, qrId.length);
  }
  return (
    <div key={`qr_${asset.id}`} className={classes.QrContainer}>
      <div className={classes.QrImageContainer}>
        <QRCode value={qrId} size={128} level={"H"} />
      </div>
      <p>
        <strong>FOLIO: {qrFolio}</strong>
      </p>
      <hr />
      <p>Serie: {asset.serial === "DEFAULT_SERIE" ? "Sin serie" : asset.serial}</p>
      <p>
        {asset.code}-{asset?.material?.name}
      </p>
    </div>
  );
}

const useStyles = makeStyles(() => ({
  QrImageContainer: {
    textAlign: "center",
  },
  QrContainer: {
    textAlign: "left",
    lineHeight: "10px",
  },
}));
