import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { AmplifyProvider, Authenticator } from "@aws-amplify/ui-react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { SnackbarProvider } from "notistack";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import es from "dayjs/locale/es";
import * as Sentry from "@sentry/react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ApplicationContext from "context/ApplicationContext";
import Box from "@material-ui/core/Box";
import dBConfig from "config/indexDB";
import { initDB } from "react-indexed-db";
import DialogUpdateNotification from "components/DialogUpdateNotification";
import AppVersionContextProvider from "context/AppVersionContextProvider";
import Root from "components/Root";
import GeneralErrorBoundary from "exception/GeneralErrorBoundary";
import useBarcodeScanner from "hooks/useBarcodeScanner";
import { initCapacitor } from "setup/capacitor";

initCapacitor();

dayjs.extend(isSameOrAfter);
dayjs.locale({ ...es, weekStart: 0 });
initDB(dBConfig);

const useStyles = makeStyles((theme) => ({
  rootBox: {
    display: "flex",
    flexDirection: "column",
  },
  hideApp: {
    background: "#00000000",
    display: "none",
  },
}));

const useSnackBarStyles = makeStyles(() => ({
  variantDefault: {
    backgroundColor: "rgb(49, 49, 49)",
    color: "#fff",
  },
}));

const basename = process.env.REACT_APP_PUBLIC_URL ? `${process.env.REACT_APP_PUBLIC_URL}/` : "/";

function App() {
  const classes = useStyles();
  const snackBarClasses = useSnackBarStyles();
  const barcodeScanner = useSelector(({ barcodeScanner }) => barcodeScanner);
  const { setContainerRef } = useBarcodeScanner();
  const barcodeScannerContainerRef = useCallback(
    (node) => {
      if (node !== null) {
        setContainerRef(node);
      }
    },
    [setContainerRef]
  );

  return (
    <Sentry.ErrorBoundary fallback={GeneralErrorBoundary}>
      <AmplifyProvider>
        <Authenticator.Provider>
          <AppVersionContextProvider versionUrl="/manifest.json">
            <ApplicationContext.Provider>
              <SnackbarProvider maxSnack={3} hideIconVariant classes={snackBarClasses}>
                <Box id="barcode" ref={barcodeScannerContainerRef} />
                <Box
                  width="inherit"
                  minHeight="100vh"
                  // overflow="auto"
                  className={barcodeScanner.activated ? classes.hideApp : classes.rootBox}
                >
                  <Router basename={basename}>
                    <CssBaseline />
                    <DialogUpdateNotification />
                    <Root />
                  </Router>
                </Box>
              </SnackbarProvider>
            </ApplicationContext.Provider>
          </AppVersionContextProvider>
        </Authenticator.Provider>
      </AmplifyProvider>
    </Sentry.ErrorBoundary>
  );
}

export default App;
