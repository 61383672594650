export const createUserTask = /* GraphQL */ `
  mutation CreateUserTask($input: CreateUserTaskInput!) {
    createUserTask(input: $input) {
      id
      userID
      taskID
      task {
        id
        _version
        _deleted
        _lastChangedAt
      }
      user {
        id
        name
        middlename
        lastnameP
        lastnameM
        email
        phone
        extension  
        _version
        _deleted
        _lastChangedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;