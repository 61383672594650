export const TASK_STATUS_MAP = {
  SCHEDULED: "Atención asignada",
  IN_PROGRESS: "En progreso",
  COMPLETED: "Por firmar",
  REVIEWED: "Validada",
  WITH_INSPECTION_NOTES: "Con observaciones",
  FINALIZED: "Finalizada",
  SHIPMENT_SCHEDULED: "Envío Programado",
  SHIPMENT_PREV_SENT: "Previo envío",
  SHIPMENT_SENT: "Envíado",
  SHIPMENT_PREV_RECEIVED: "Previo Recepción",
  SHIPMENT_RECEIVED: "Recibido",
};

export const TASK_STATUS_MAP_COLOR = {
  GENERAL: "#000000",
  SCHEDULED: "#7f6cff",
  IN_PROGRESS: "#105285",
  COMPLETED: "#f6ce38",
  WITH_INSPECTION_NOTES: "#ff6c6c",
  FINALIZED: "#93c47d",
  REVIEWED: "#00695f",
};

export const MAINTENANCE_STATUS_MAP_COLOR = {
  GENERAL: "#000000",
  SCHEDULED: "#7f6cff",
  IN_PROGRESS: "#105285",
  COMPLETED: "#f6ce38",
  WITH_INSPECTION_NOTES: "#ff6c6c",
  FINALIZED: "#00695f",
  REVIEWED: "#93c47d",
};

export const TASK_STATUS = {
  SCHEDULED: "Asignados",
  IN_PROGRESS: "En progreso",
  COMPLETED: "Por firmar",
  WITH_INSPECTION_NOTES: "Con observaciones",
  REVIEWED: "Revisados",
};
