import { DataStore } from "@aws-amplify/datastore";
import { Logger } from "@aws-amplify/core";

import {
  Asset,
  AssetType,
  Premises,
  RequestChannel,
  ServiceCategory,
  Ticket,
  TicketPriority,
  TicketStatus,
  TicketUrgency,
  User,
  UserRole,
} from "models";
import fetchManageSettingsEnabled from "util/graphql/fetchManageSettingsEnabled";
import * as graphql from "./graphql";
import { WHITE_LABEL_BRANDS } from "constant/whiteLabelBrands";
import { ENVIRONMENT } from "constant/environments";

const logger = new Logger("SupportTicketDialog/helpers/graphql");
const USER_ALLOWED_DOMAIN = "@grupo-siayec.com.mx";
export async function findAssets(searchTerm, page = 0) {
  let assets = await DataStore.query(
    Asset,
    (asset) => {
      return asset
        .or((at) => at.serial("contains", searchTerm).code("contains", searchTerm))
        .type("ne", AssetType.MATERIAL_USED)
        .type("ne", AssetType.MATERIAL_ALLOCATED);
    },
    {
      page,
      limit: 100,
    }
  );

  return assets;
}

export function getPremises() {
  return DataStore.query(Premises);
}

export async function getServiceUsers() {
  let serviceUsers = await DataStore.query(UserRole);
  if (
    process.env.REACT_APP_ENV === ENVIRONMENT.PROD &&
    process.env.REACT_APP_WHITE_LABEL_APP === WHITE_LABEL_BRANDS.GRUPO_SIAYEC
  ) {
    serviceUsers = serviceUsers.filter((ur) => ur?.user?.email?.includes(USER_ALLOWED_DOMAIN));
  }
  serviceUsers = serviceUsers.reduce((filteredUsers, userRole) => {
    if (userRole.role.id === "service-users") {
      filteredUsers.push({ ...userRole.user });
    }
    return filteredUsers;
  }, []);
  return serviceUsers;
}

export async function createTicket({
  name,
  description,
  type,
  impact,
  service,
  premise: premiseId,
  impactDescription,
  userId,
  externalUser,
  requestChannel,
  reportedAsset,
} = {}) {
  const premises = await DataStore.query(Premises, premiseId);

  let user = null;

  if (userId) {
    user = await DataStore.query(User, userId);
  }

  let ticket = {
    subject: name,
    description,
    requestType: type,
    impact,
    impactDetails: impactDescription,
    status: TicketStatus.OPEN,
    mode: RequestChannel.WEB_PAGE,
    urgency: TicketUrgency.UNDEFINED,
    priority: TicketPriority.UNDEFINED,
    serviceCategory: ServiceCategory.ELECTRONIC_SECURITY,
    serviceSubcategory: service,
    premises: premises,
    reportedAsset,
    requestChannel,
    ticketPremisesId: premises.id,
  };

  if (user) {
    ticket.requester = user;
    ticket.ticketRequesterId = user.id;
  } else if (externalUser) {
    ticket.externalRequester = externalUser;
  }

  ticket = new Ticket(ticket);
  logger.debug("Creando ticket en base de datos...");
  const ticketCreatedResult = await DataStore.save(ticket);
  logger.debug("Ticket creado...");
  logger.debug("Obteniendo configuración de integración con ME...");
  const result = await fetchManageSettingsEnabled();
  const manageEngineIntegrationEnabled = result.integration_enabled === "true";

  if (manageEngineIntegrationEnabled) {
    logger.debug("Integración con Manage Engine habilitada. Creando ticket...");
    await graphql.createManageEngineTicket(ticket, reportedAsset, premises);
  }

  return ticketCreatedResult;
}

export async function updateTicket({
  ticketId,
  name,
  description,
  type,
  impact,
  service,
  premise: premiseId,
  impactDescription,
  userId,
  externalUser,
  requestChannel,
  reportedAsset,
}) {
  const premise = await DataStore.query(Premises, premiseId);

  let user = null;
  if (userId) {
    user = await DataStore.query(User, userId);
  }

  let ticket = await DataStore.query(Ticket, ticketId);
  ticket = Ticket.copyOf(ticket, (updatedTicket) => {
    updatedTicket.subject = name;
    updatedTicket.description = description;
    updatedTicket.requestType = type;
    updatedTicket.impact = impact;
    updatedTicket.impactDetails = impactDescription;
    updatedTicket.serviceSubcategory = service;
    updatedTicket.premises = premise;
    updatedTicket.ticketPremisesId = premise.id;
    updatedTicket.reportedAsset = reportedAsset;
    updatedTicket.requestChannel = requestChannel;
    if (user) {
      updatedTicket.requester = user;
      updatedTicket.externalRequester = null;
    } else if (externalUser) {
      updatedTicket.externalRequester = externalUser;
      updatedTicket.requester = null;
      updatedTicket.ticketPremisesId = "";
    }
    return updatedTicket;
  });
  ticket = await DataStore.save(ticket);

  return ticket;
}
