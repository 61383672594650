import React, { useEffect, useState } from "react";
import MaterialTable from "components/custom/MaterialTable";
import useHeaderTitle from "hooks/useHeaderTitle";
import { useSelector } from "react-redux";
import { getActivityTypes } from "redux/dispatcher/activityTypes";
import { useActions } from "hooks/useActions";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { grey } from "@material-ui/core/colors";
import EditIcon from "@material-ui/icons/Edit";
import ActivityTypesDialog from "./ActivityTypesDialog";
import FloatingButton from "components/FloatingButton";
import { REQUEST_STATUS } from "constant/requestStatus";
import { checkNextItems } from "util/lists";

const TABLE_COLUMNS = [
  { title: "Nombre", field: "name" },
  { title: "Fecha de registro", field: "createdAt" },
  { title: "Última actualización", field: "updatedAt" },
];

const useStyles = makeStyles((theme) => ({
  detailsSection: {
    padding: theme.spacing(3),
    backgroundColor: grey[100],
  },
}));

function ActivityTypesView() {
  useHeaderTitle("Tipo de actividades");
  const classes = useStyles();
  const { rdxActivityTypes, rdxListStatus, rdxNextToken } = useSelector(
    ({ activities }) => activities
  );
  const fetchActivities = useActions(getActivityTypes);
  const [tableOptions] = useState({
    exportButton: false,
    columnsButton: false,
  });
  const [showUpdateDialog, setShowUpdateDialog] = useState(false);
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState(null);

  useEffect(() => {
    fetchActivities();
  }, [fetchActivities]);

  const displayUpdate = (event, row) => {
    setSelectedActivity(row);
    setShowUpdateDialog(true);
  };

  const hideUpdate = () => {
    setShowUpdateDialog(false);
    setSelectedActivity(null);
  };

  const hideCreate = () => {
    setShowCreateDialog(false);
  };

  const loadMoreActivities = (page) => {
    const hasMoreItems = checkNextItems(page, rdxActivityTypes.length);
    if (hasMoreItems && rdxNextToken) {
      fetchActivities(rdxNextToken);
    }
  };

  const renderDetails = (row) => {
    return (
      <div className={classes.detailsSection}>
        <Typography variant="subtitle2">Descripción</Typography>
        <Typography variant="body2">{row.description || "-"}</Typography>
      </div>
    );
  };

  const renderActivityDialog = () => {
    if (showUpdateDialog) {
      return (
        <ActivityTypesDialog
          title="Actualizar tipo de actividad"
          confirmationMsg="Estás a punto de actualizar el tipo de actividad."
          onClose={hideUpdate}
          activity={selectedActivity}
        />
      );
    } else if (showCreateDialog) {
      return (
        <ActivityTypesDialog
          title="Crear tipo de actividad"
          confirmationMsg="Estás a punto de registrar un nuevo tipo de actividad."
          onClose={hideCreate}
        />
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <MaterialTable
        title="Tipo de actividades"
        options={tableOptions}
        columns={TABLE_COLUMNS}
        data={rdxActivityTypes}
        detailPanel={[{ tooltip: "Ver descripción", render: renderDetails }]}
        actions={[
          rowData => rowData.owner !== "system" 
          ? ({ icon: EditIcon, tooltip: "Editar", onClick: displayUpdate })
          : null
        ]}
        isLoading={rdxListStatus === REQUEST_STATUS.PENDING}
        onChangePage={loadMoreActivities}
      />
      <FloatingButton
        bottom={15}
        right={15}
        color="primary"
        variant="extended"
        onClick={() => setShowCreateDialog(true)}
      >
        Crear actividad
      </FloatingButton>

      {renderActivityDialog()}
    </>
  );
}

export default ActivityTypesView;
