import React from "react";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
  title: {
    flexGrow: 1
  },
  titleContainer: {
    display: "flex"
  }
}));

function Section({ title, children, action }) {
  const classes = useStyles();
  return (
    <section>
      <div className={classes.titleContainer}>
        <Grid container>
          <Grid item xs={12} sm>
            {title && (
              <Typography variant="h6" className={classes.title} gutterBottom>
                {title}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm container justify="flex-end">
            {action && <>{action}</>}
          </Grid>
        </Grid>
      </div>
      <Paper>{children}</Paper>
    </section>
  );
}

export default Section;
