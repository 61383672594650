import React from "react";
import { Box, Grid, Divider } from "@material-ui/core";
import useStyles from "../MaintenanceStyles";
import PropTypes from "prop-types";
import DataLabel from "components/DataLabel";

const ActivityDetails = ({ data, isLoading }) => {
  const classes = useStyles();

  const remark = data?.remarks?.items.map(({ remark }) => remark)[0] || "No se añadieron comentarios.";

  return (
    <>
      <Grid container>
        <Grid item md={12}>
          <Box className={classes.sectionTitleContainer}>
            <div>
              <img src="/img/usercheck.png" alt="icon" />
            </div>
            <p className={classes.sectionTitleText}>Detalles de la actividad</p>
          </Box>
        </Grid>
      </Grid>
      <Divider variant="middle" />

      <Box padding={"10px 15px"}>
        <DataLabel label="Comentarios:" data={remark} loading={isLoading} inOneLine={true} />
      </Box>
    </>
  );
};

ActivityDetails.propTypes = {
  data: PropTypes.object,
  isLoading: PropTypes.bool,
};

ActivityDetails.defaultProps = {
  data: "",
  isLoading: false,
};

export default ActivityDetails;
