import { DataStore } from "@aws-amplify/datastore";
import { TaskStatus, Ticket, Task, SupportType, TicketStatus, Remark, AssetType, EventCause, TimerEvent } from "models";
import { fetchTaskById, fetchRemarksByTaskId } from "datastore";
import { getCurrentPosition } from "util/geolocation";
import { Logger } from "@aws-amplify/core";
import removeProps from "util/removeProps";
import { handleUpdateLastEvent, handleNewEventCreation } from "components/Timer/helpers/useApiTimerHelpers";

const logger = new Logger("TicketActivityTrackingDialog-helpers");

export async function initTicketActivity(ticketId, supportType, taskId) {
  let result = { updatedTicket: null, updatedTask: null };

  let ticket = await DataStore.query(Ticket, ticketId);
  ticket = Ticket.copyOf(ticket, (updated) => {
    updated.supportType = supportType;
    return updated;
  });
  result.updatedTicket = await DataStore.save(ticket);

  if (supportType === SupportType.REMOTE) {
    result.updatedTask = await startTicketActivity(taskId, ticketId);
  }
  return result;
}

export async function addRemarkTask(taskId, remark) {
  let newRemark = new Remark({ taskID: taskId, remark });
  return DataStore.save(newRemark);
}

export async function updateRemarkTask(remarkId, remark) {
  let newRemark = await DataStore.query(Remark, remarkId);
  newRemark = Remark.copyOf(newRemark, (updated) => {
    updated.remark = remark;
    return updated;
  });
  return DataStore.save(newRemark);
}

export async function startTicketActivity({ taskId = null, ticketId = null, supportType = null } = {}) {
  const defaultTimerEventDescription = "La actividad de soporte ha sido iniciada por el usuario Técnico asignado";
  const defaultStartEventCauseId = "start-task";
  const defaultPropsToDelete = ["_lastChangedAt", "_deleted"];
  let [task, location, ticket, startEventCause] = await Promise.all([
    fetchTaskById(taskId),
    getCurrentPosition(),
    DataStore.query(Ticket, ticketId),
    DataStore.query(EventCause, defaultStartEventCauseId),
  ]);

  //creación de TimerEvent de inicio de temporizador
  let newTimerEvent = await DataStore.save(
    new TimerEvent({
      taskID: task.task.id,
      eventCauseID: startEventCause.id,
      description: defaultTimerEventDescription,
      timestamp: Date.now(),
      createdAt: new Date().toISOString(),
      duration: 0,
      finalized: false,
    })
  );
  logger.debug("startTicketActivity: TimerEvent creado", newTimerEvent);

  //Start event embedded object parse
  const embeddedLastEvent = {
    ...removeProps([newTimerEvent], { propsToDelete: defaultPropsToDelete })[0],
    _version: 1,
    embeddedCause: removeProps([startEventCause], { propsToDelete: defaultPropsToDelete })[0],
  };

  newTimerEvent = TimerEvent.copyOf(newTimerEvent, (updated) => {
    updated.cause = startEventCause;
    return updated;
  });

  //update Ticket
  ticket = Ticket.copyOf(ticket, (updated) => {
    updated.status = TicketStatus.IN_PROGRESS;
    updated.supportType = supportType;
  });
  const updatedTicket = await DataStore.save(ticket);

  // update Task
  task = Task.copyOf(task.task, (updated) => {
    updated.status = TaskStatus.IN_PROGRESS;
    updated.startedAt = new Date().toISOString();
    updated.startLocation = {
      latitude: location.coords.latitude,
      longitude: location.coords.longitude,
    };
    //actualización valores de temporizador
    updated.lastEvent = newTimerEvent;
    updated.events = [newTimerEvent];
    updated.embeddedLastEvent = embeddedLastEvent;
    updated.embeddedEvents = [embeddedLastEvent];
    updated.effectiveTime = 0;
    return updated;
  });

  const updatedTask = await DataStore.save(task);
  logger.debug("startTicketActivity: información de ticket y task actualizada...", { updatedTicket, updatedTask });

  return { updatedTicket, updatedTask };
}

export async function endCloseActivity(taskId, ticketId, timerDuration, embeddedEvents) {
  const defaultEndEventCauseId = "end-task";
  let [task, remarks, ticket, location] = await Promise.all([
    fetchTaskById(taskId),
    fetchRemarksByTaskId(taskId),
    DataStore.query(Ticket, ticketId),
    getCurrentPosition(),
  ]);
  const ticketAsset = task.assets.find((ta) => ta?.asset?.asset?.type === AssetType.MATERIAL_USED);

  //actualizar último evento / evento anterior

  const lastEventId = task.task.embeddedLastEvent?.id || null;
  if (lastEventId) {
    const { updatedEmbeddedEvents } = await handleUpdateLastEvent(lastEventId, embeddedEvents);
    embeddedEvents = updatedEmbeddedEvents;
  }

  //crear evento de finalización
  const endEventInput = {
    eventCauseID: defaultEndEventCauseId,
    description: "La actividad de soporte ha finalizado",
  };
  const { newTimerEvent, newEmbeddedLastEvent, updatedEmbeddedEvents } = await handleNewEventCreation(
    taskId,
    endEventInput,
    embeddedEvents
  );

  //obtener todos los eventos
  const events = await DataStore.query(TimerEvent, (event) => {
    event.taskID("eq", taskId);
  });

  //actualizar ticket
  ticket = Ticket.copyOf(ticket, (updated) => {
    updated.status = TicketStatus.RESOLVED;
    if (ticketAsset) {
      updated.asset = ticketAsset.asset.asset;
    }
    return updated;
  });

  //actualizar task
  task = Task.copyOf(task.task, (updated) => {
    updated.status = TaskStatus.COMPLETED;
    updated.endedAt = new Date().toISOString();
    updated.endLocation = {
      latitude: location.coords.latitude,
      longitude: location.coords.longitude,
    };
    //////////
    //update timer values
    updated.lastEvent = newTimerEvent;
    updated.events = events;
    updated.embeddedLastEvent = newEmbeddedLastEvent;
    updated.embeddedEvents = updatedEmbeddedEvents;
    updated.effectiveTime = timerDuration;
    //////////

    return updated;
  });

  [task, ticket] = await Promise.all([DataStore.save(task), DataStore.save(ticket)]);
  return { task: { ...task, remarks }, ticket: { ...ticket } };
}
