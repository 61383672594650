import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import cleanUUID from "util/cleanUUID";

function getTrackingID(asset) {
  const id = asset?.tracking?.id;
  return id && id !== "unassigned" ? id : null;
}

function MobileTableCell(props) {
  const trackingID = getTrackingID(props);
  let visibleID = (
    <Button color="primary" onClick={props.onQRLinkClick}>
      Vincular QR
    </Button>
  );
  let visibleDate;

  if (trackingID) {
    visibleID = cleanUUID(trackingID);
    visibleDate = `(${dayjs(new Date(props?.tracking._lastChangedAt)).format("DD/MM/YYYY HH:mm")})`;
  }

  return (
    <>
      <Link underline="none" color="textPrimary" onClick={props.onCellClick(props)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <strong>Nombre </strong>
            {props?.material?.name}
          </Grid>
          <Grid item xs={12}>
            <strong>Código/SKU </strong>
            {props.code}
          </Grid>
          <Grid item xs={12}>
            <strong>Serie: </strong>
            {props.serial === "DEFAULT_SERIE" ? "Sin serie" : props.serial}
          </Grid>
        </Grid>
      </Link>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <strong>Código QR: </strong>
          {visibleID} {visibleDate}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <strong>Proyecto: </strong>
          {props.project ? `${props?.project?.name} (${props?.project?.code})` : "Sin asignación"}
        </Grid>
      </Grid>
    </>
  );
}

MobileTableCell.propTypes = {
  code: PropTypes.string,
  serial: PropTypes.string,
  material: PropTypes.shape(),
  tracking: PropTypes.shape(),
  project: PropTypes.shape(),
  onQRLinkClick: PropTypes.func,
  onCellClick: PropTypes.func,
};

MobileTableCell.defaultProps = {
  code: "S/I",
  serial: "S/I",
  onQRLinkClick: () => {},
  onCellClick: () => {},
};

export default MobileTableCell;
