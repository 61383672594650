export const lambdaQrGenerator = /* GraphQL */ `
  query lambdaQrGenerator($input: lambdaQrGeneratorInput) {
    lambdaQrGenerator(input: $input) {
      fileUrl
      assets {
        assetID
        trackingID
        incosistent
      }
      ids
    }
  }
`;
