export const getPremise = /* GraphQL */ `
  query GetPremises($premisesId: ID!) {
    getPremises(id: $premisesId) {
      id
      name
      code
      _version
      _deleted
      buildingLocations {
        items {
          id
          name
          code
          floor
          area
          buildingBlock
          status
          createdAt
          _version
          _deleted
        }
        nextToken
      }
      company {
        id
        name
        description
        _version
        _deleted
      }
      custodian {
        id
        name
        middlename
        lastnameP
        lastnameM
        email
        phone
        extension
        _version
        _deleted
      }
      address {
        id
        street
        number
        zipcode
        city
        settlement
        locality
        _version
        _deleted
      }
    }
  }
`;

export default getPremise;
