import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Controller, useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/custom/Button";
import ConfirmationDialog from "components/ConfirmationDialog/ConfirmationDialog";
import TextField from "@material-ui/core/TextField";
import { useActions } from "hooks/useActions";
import * as activityActions from "redux/dispatcher/activityTypes";
import { REQUEST_STATUS } from "constant/requestStatus";
import { Grid } from "@material-ui/core";
import Select from "components/custom/Select";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    "& > *": {
      marginBottom: theme.spacing(3),
    },
  },
}));

const TASK_CATEGORIES = [
  {
    value: "MAINTENANCE",
    label: "Mantenimiento",
  },
  {
    value: "SUPPORT",
    label: "Soporte",
  },
  {
    value: "IMPLEMENTATION",
    label: "Instalaciones",
  },
  {
    value: "SHIPMENT",
    label: "Envíos",
  },
];

function ActivityTypesUpdate({ title, confirmationMsg, onClose, activity }) {
  const classes = useStyles();
  const { rdxUpdateStatus } = useSelector(({ activities }) => activities);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [patchActivity, createActivity, cleanStatus] = useActions([
    activityActions.updateActivityById,
    activityActions.createActivityType,
    activityActions.resetStatus,
  ]);
  const { control, getValues, trigger, errors } = useForm({
    mode: "onChange",
    defaultValues: { name: activity.name, description: activity.description, category: activity.category },
  });
  const [nameRules] = useState({
    required: "Campo obligatorio",
    maxLength: { value: 50, message: "Máximo 50 caracteres" },
    validate: { notEmpty: (value) => value?.trim() !== "" || "Campo obligatorio" },
  });
  const [descriptionRules] = useState({
    required: "Campo obligatorio",
    maxLength: { value: 500, message: "Máximo 500 caracteres" },
    validate: { notEmpty: (value) => value?.trim() !== "" || "Campo obligatorio" },
  });
  const [categoryRules] = useState({
    required: "Campo obligatorio",
  });

  useEffect(() => {
    if (rdxUpdateStatus === REQUEST_STATUS.SUCCESSFUL) {
      onClose();
    }
  }, [rdxUpdateStatus]);

  useEffect(() => {
    return () => {
      cleanStatus();
    };
  }, []);

  const validateForm = async () => {
    const validForm = await trigger();
    if (validForm) {
      setShowConfirmation(true);
    }
  };

  const updateOrCreate = () => {
    let { name, description, category } = getValues();
    name = name.trim();
    description = description.trim();
    if (activity.id) {
      patchActivity(activity.id, { name, description, category, _version: activity._version });
    } else {
      createActivity({ name, description, category });
    }
  };

  return (
    <>
      <Dialog maxWidth="md" fullWidth open>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent dividers className={classes.dialogContent}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                control={control}
                as={TextField}
                rules={nameRules}
                name="name"
                type="text"
                label="Nombre"
                fullWidth
                required
                error={!!errors.name}
                helperText={errors.name?.message}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                control={control}
                as={Select}
                rules={categoryRules}
                name="category"
                label="Categoría de actividad"
                selectMessage={"Selecciona la categoría del tipo de actividad"}
                fullWidth
                options={TASK_CATEGORIES.map((tc) => ({ value: tc.value, label: tc.label }))}
                required
                error={!!errors.category}
                helperText={errors.category?.message}
              />
            </Grid>
          </Grid>
          <Controller
            control={control}
            as={TextField}
            rules={descriptionRules}
            name="description"
            type="text"
            label="Descripción"
            rows={5}
            variant="outlined"
            multiline
            fullWidth
            required
            error={!!errors.description}
            helperText={errors.description?.message}
          />
        </DialogContent>
        <DialogActions>
          <Button color="default" onClick={onClose}>
            Cancelar
          </Button>
          <Button color="primary" onClick={validateForm}>
            {activity.id ? "Actualizar" : "Crear"}
          </Button>
        </DialogActions>
      </Dialog>

      <ConfirmationDialog
        open={showConfirmation}
        title="Confirmación"
        onCancel={() => setShowConfirmation(false)}
        onConfirm={updateOrCreate}
        loading={rdxUpdateStatus === REQUEST_STATUS.PENDING}
      >
        {confirmationMsg}
        <br />
        <br />
        ¿Deseas continuar?
      </ConfirmationDialog>
    </>
  );
}

ActivityTypesUpdate.propTypes = {
  title: PropTypes.string.isRequired,
  confirmationMsg: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  activity: PropTypes.object,
};

ActivityTypesUpdate.defaultProps = {
  onClose: () => {},
  activity: { id: "", name: "", description: "", category: "", _version: null },
};

export default ActivityTypesUpdate;
