// Pantalla del formulario para agregar un nuevo material
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Logger } from "@aws-amplify/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import CodeReader from "components/common/CodeReader";
import debounce from "lodash/debounce";
import AutocompleteField from "components/common/AutocompleteField";
import VirtualizedListLoader from "components/common/VirtualizedListLoader";
import { CODE_READER_STATUS } from "constant/codeReaderStatus";
import cleanUUID from "util/cleanUUID";
import { fetchAssetByTrackingId, fetchAssetsBySerial } from "datastore";
import useLoadingStatus from "hooks/useLoadingStatus";
import { TASK_TYPES } from "constant/task/types";
import { AssetType } from "models";
import { FORM_ENTITIES } from "constant/task/formEntities";

const FORMS_STATUSES = {
  NEW: "NEW",
  UPDATE: "UPDATE",
};

const logger = new Logger("AddInstallationFormView");

export default function AddInstallationFormView({
  register,
  errors,
  control,
  setValue,
  watch,
  selectMaterialId,
  handleSelectOnChange,
  materialList,
  materialsPage,
  hasNextPage,
  loadingMaterials,
  loadMaterials,
  uomList,
  formStatus,
  isLoading,
  selectedAsset,
  formEntity,
  premisesList,
}) {
  const classes = useStyles();
  const [showQrCodeDialog, setShowQrCodeDialog] = useState(false);
  const [codeReaderStatus, setCodeReaderStatus] = useState(CODE_READER_STATUS.STOP);
  const [codeDetected, setCodeDetected] = useState("");
  const [assets, setAssets] = useState([]);
  const [activeAsset, setActiveAsset] = useState(null);
  const [serialError, setSerialError] = useState("");
  const [loadingAssets, _fetchAssetsBySerial] = useLoadingStatus(fetchAssetsBySerial);
  const [loadingTracking, _fetchAssetByTrackingId] = useLoadingStatus(fetchAssetByTrackingId);
  const assetType = watch("type");
  const [openSelect, setOpenSelect] = useState(false);

  function handleCodeDetection(code) {
    setCodeDetected(code.text);
  }

  async function fetchQrCode() {
    setCodeReaderStatus(CODE_READER_STATUS.STOP);
    setShowQrCodeDialog(false);
    if (!codeDetected.trim().length) {
      return;
    }
    try {
      const { asset, uom } = await _fetchAssetByTrackingId(codeDetected);
      if (!asset) {
        return setSerialError("El material no fue encontrado con el código escaneado.");
      }
      setActiveAsset(asset);
      setFormValues({ ...asset, type: AssetType.REUSED }, uom);
      setSerialError("");
    } catch (error) {
      logger.error(error);
      setValue("fs_service_number", codeDetected);
      setSerialError("El material no está disponible");
    }
  }

  function setFormValues(newAsset, uom) {
    handleSelectOnChange(newAsset?.material?.id || newAsset?.id);
    // handleUomChange(uom?.id || "");
    setValue("fs_service_number", newAsset || "");
    setValue("material_code", newAsset?.code || "", { shouldValidate: true });
    setValue("material_quantity", newAsset?.quantity || 1, { shouldValidate: true });
    setValue("material_unit", uom?.abbreviation || "");
    setValue("comments", newAsset?.comment || "", { shouldValidate: true });
    setValue("type", newAsset?.type || AssetType.USED);
  }

  function removeActiveAsset() {
    setActiveAsset(null);
    setFormValues(null, null);
    setAssets([]);
  }

  function getAssetLabel(option) {
    if (typeof option === "string") {
      return option;
    } else {
      let { serial, material: { name } = {} } = option?.asset || option;
      serial = serial?.trim();
      name = name ? name.trim() : "N/A";
      return `Serie: ${serial} --- Nombre: ${name}`;
    }
  }

  function searchAssets(event, value) {
    value = value.trim();
    if (!value?.length) {
      return;
    }
    _fetchAssetsBySerial(value)
      .then((foundAssets) => {
        setAssets(foundAssets);
      })
      .catch((error) => {
        logger.error(error);
      });
  }

  function setAssetValue(event, newValue) {
    if (newValue === null) {
      return "";
    } else if (typeof newValue === "string") {
      return newValue;
    } else {
      const { asset, uom } = newValue;
      setActiveAsset(asset);
      setFormValues({ ...asset, type: AssetType.REUSED }, uom);
      setSerialError("");
      return newValue;
    }
  }

  function renderSerialFieldAdornment() {
    if (loadingAssets || loadingTracking) {
      return <CircularProgress color="inherit" size={20} />;
    } else if (activeAsset) {
      return (
        <IconButton onClick={removeActiveAsset} color="primary" aria-label="delete">
          <DeleteIcon />
        </IconButton>
      );
    } else if (!activeAsset && formStatus === FORMS_STATUSES.NEW) {
      return (
        <Button onClick={() => setShowQrCodeDialog(true)}>
          <img className={classes.buttonImages} src="/img/qrcode-green.png" alt="icon" />
        </Button>
      );
    } else {
      return null;
    }
  }

  function renderSelectValue(value) {
    const material = materialList.find(({ id }) => id === value);
    if (material) {
      return material.name;
    } else {
      return activeAsset?.material?.name || selectedAsset?.material?.name;
    }
  }

  function renderMaterialItem(material, style) {
    return (
      <MenuItem
        key={material.id}
        value={material.id}
        style={style}
        onClick={() => {
          handleSelectOnChange({ target: { value: material.id } });
          setOpenSelect(false);
        }}
      >
        {material.name}
      </MenuItem>
    );
  }

  function renderMaterialLoader(style) {
    return (
      <Box display="flex" justifyContent="center" style={style}>
        <CircularProgress color="primary" size={20} />
      </Box>
    );
  }

  return (
    <>
      <Grid container>
        <Grid item md={12} className={classes.fullWidth}>
          <AutocompleteField
            name="fs_service_number"
            control={control}
            options={assets}
            getOptionLabel={getAssetLabel}
            onInputChange={debounce(searchAssets, 500)}
            onSelectionChange={setAssetValue}
            autoSelect
            renderInput={(params) => (
              <TextField
                {...params}
                label="Número de serie"
                placeholder="Número de serie del activo"
                error={!!errors?.fs_service_number?.message || !!serialError}
                helperText={errors?.fs_service_number?.message || serialError}
                fullWidth
                variant={
                  activeAsset || (formStatus === FORMS_STATUSES.UPDATE && assetType === AssetType.REUSED)
                    ? "filled"
                    : "standard"
                }
                InputProps={{
                  ...params.InputProps,
                  readOnly: activeAsset || (formStatus === FORMS_STATUSES.UPDATE && assetType === AssetType.REUSED),
                  className: classes.noHorizontalPadding,
                  endAdornment: <>{renderSerialFieldAdornment()}</>,
                }}
              />
            )}
          />
        </Grid>
        <Grid item md={12} className={classes.fullWidth}>
          {formEntity === FORM_ENTITIES.MATERIAL && (
            <FormControl className={classes.formControl}>
              <InputLabel id="material-select">Activo</InputLabel>
              <Select
                fullWidth
                open={openSelect}
                labelId="material-select"
                value={selectMaterialId}
                renderValue={renderSelectValue}
                onOpen={() => setOpenSelect(true)}
                onClose={() => setOpenSelect(false)}
                variant={!!activeAsset || assetType === AssetType.REUSED ? "filled" : "standard"}
                inputProps={{
                  readOnly: !!activeAsset || assetType === AssetType.REUSED,
                }}
              >
                <MenuItem value="">Seleccione un material</MenuItem>
                <VirtualizedListLoader
                  hasNextPage={hasNextPage}
                  isNextPageLoading={loadingMaterials}
                  items={materialList}
                  renderItem={renderMaterialItem}
                  renderLoader={renderMaterialLoader}
                  loadNextPage={() => loadMaterials(materialsPage, 10)}
                />
              </Select>
              <FormHelperText>{errors.material ? errors.material.message : ""}</FormHelperText>
            </FormControl>
          )}
          {formEntity === FORM_ENTITIES.ASSET && (
            <FormControl className={classes.formControl}>
              <TextField
                fullWidth
                required
                id="name"
                label="Nombre"
                name="material_name"
                inputRef={register({ required: "Campo obligatorio" })}
                helperText={errors.material_name?.message}
                error={!!errors.material_name}
              />
            </FormControl>
          )}
        </Grid>
        <Grid item md={12} className={classes.fullWidth}>
          <FormControl className={classes.formControl}>
            <TextField
              InputLabelProps={{ shrink: true }}
              fullWidth
              required
              id="material_code"
              label="Código del Activo"
              name="material_code"
              variant={formEntity === FORM_ENTITIES.MATERIAL ? "filled" : "standard"}
              InputProps={{ readOnly: formEntity === FORM_ENTITIES.MATERIAL }}
              inputRef={register({ required: "Campo obligatorio"})}
              helperText={errors.material_code?.message}
              error={!!errors.material_code}
            />
          </FormControl>
        </Grid>
        {formEntity === FORM_ENTITIES.MATERIAL && (
          <Grid item md={12} className={classes.fullWidth}>
            <Grid container>
              <Grid item xs={6} md={6} className={classes.fullWidth}>
                <FormControl className={classes.formControl}>
                  <TextField
                  InputLabelProps={{ shrink: true }}
                    fullWidth
                    required
                    id="material_quantity"
                    label="Cantidad"
                    name="material_quantity"
                    type="number"
                    variant={
                      !!activeAsset || (formStatus === FORMS_STATUSES.UPDATE && assetType === AssetType.REUSED)
                        ? "filled"
                        : "standard"
                    }
                    InputProps={{
                      readOnly:
                        !!activeAsset || (formStatus === FORMS_STATUSES.UPDATE && assetType === AssetType.REUSED),
                    }}
                    inputRef={register({
                      required: "Campo obligatorio",
                      min: { value: 1, message: "Debes colocar una cantidad mayor que 0" },
                    })}
                    helperText={errors.material_quantity?.message}
                    error={!!errors.material_quantity}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} md={6} className={classes.fullWidth}>
                <FormControl className={classes.formControl}>
                  <TextField
                    label="Unidad"
                    name="material_unit"
                    inputRef={register("material_unit", { required: "Campo obligatorio" })}
                    InputProps={{
                      readOnly:
                        !!activeAsset || (formStatus === FORMS_STATUSES.UPDATE && assetType === AssetType.REUSED),
                    }}
                    variant={
                      !!activeAsset || (formStatus === FORMS_STATUSES.UPDATE && assetType === AssetType.REUSED)
                        ? "filled"
                        : "standard"
                    }
                    onChange={(e) => {
                      setValue("material_unit", e.target.value, true);
                    }}
                    value={watch("material_unit")}
                    helperText={errors.material_unit?.message}
                    error={!!errors.material_unit}
                    select
                    fullWidth
                  >
                    <MenuItem value="">Seleccione una unidad</MenuItem>
                    {uomList.map(({ id, name }) => {
                      return (
                        <MenuItem key={id} value={id}>
                          {name}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        )}
        {formEntity === FORM_ENTITIES.ASSET && (
          <Grid item md={12} className={classes.fullWidth}>
            <Grid container>
              <Grid item xs={6} md={6} className={classes.fullWidth}>
                <FormControl className={classes.formControl}>
                  <TextField
                    label="Sitio"
                    name="premises"
                    variant={!!activeAsset || assetType === AssetType.REUSED ? "filled" : "standard"}
                    inputRef={register("premises", { required: "Campo obligatorio" })}
                    inputProps={{
                      readOnly: !!activeAsset || assetType === AssetType.REUSED,
                    }}
                    onChange={(e) => {
                      setValue("premises", e.target.value, true);
                    }}
                    value={watch("premises")}
                    helperText={errors.premises?.message}
                    error={!!errors.premises}
                    select
                    required
                    fullWidth
                  >
                    <MenuItem value="">Seleccione un sitio</MenuItem>
                    {premisesList.map(({ id, name }) => {
                      return (
                        <MenuItem key={id} value={name}>
                          {name}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={6} md={6} className={classes.fullWidth}>
                <FormControl className={classes.formControl}>
                  <TextField
                    label="Ubicación"
                    name="location"
                    inputRef={register({ required: "Campo obligatorio" })}
                    helperText={errors.location?.message}
                    error={!!errors.location}
                    fullWidth
                    required
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item md={12} className={classes.fullWidth}>
          <FormControl className={classes.formControl}>
            <TextField
              fullWidth
              name="comments"
              id="comments"
              label="Comentarios"
              multiline
              rows={4}
              variant={
                !!activeAsset || (formStatus === FORMS_STATUSES.UPDATE && assetType === AssetType.REUSED)
                  ? "filled"
                  : "outlined"
              }
              InputProps={{
                readOnly: !!activeAsset || (formStatus === FORMS_STATUSES.UPDATE && assetType === AssetType.REUSED),
              }}
              inputRef={register({
                maxLength: { value: 200, message: "Máximo 200 caracteres" },
              })}
              helperText={errors.comments?.message}
              error={!!errors.comments}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <CodeReader
        open={showQrCodeDialog}
        status={codeReaderStatus}
        onClose={() => setShowQrCodeDialog(false)}
        onCodeDetected={handleCodeDetection}
        bottomPanel={
          <>
            <Box p={2} className={classes.codeReaderPanel}>
              <Box mb={2}>
                {codeDetected && (
                  <>
                    <Typography variant="caption" display="inline">
                      CÓDIGO:{" "}
                    </Typography>
                    <Typography variant="body2" display="inline">
                      {cleanUUID(codeDetected)}
                    </Typography>
                  </>
                )}
                {!codeDetected && (
                  <>
                    <Typography variant="caption">Código no detectado</Typography>
                  </>
                )}
              </Box>
              <Button variant="contained" color="primary" onClick={fetchQrCode} disabled={!codeDetected} fullWidth>
                Confirmar
              </Button>
            </Box>
          </>
        }
      />
    </>
  );
}

AddInstallationFormView.propTypes = {
  taskType: PropTypes.oneOf(Object.values(TASK_TYPES)),
  formStatus: PropTypes.oneOf(Object.values(FORMS_STATUSES)),
  isLoading: PropTypes.bool,
  register: PropTypes.func,
  errors: PropTypes.object,
  control: PropTypes.object,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  materialList: PropTypes.array,
  materialsPage: PropTypes.number,
  loadingMaterials: PropTypes.bool,
  hasNextPage: PropTypes.bool,
  loadMaterials: PropTypes.func,
  selectMaterialId: PropTypes.string,
  handleSelectOnChange: PropTypes.func,
  selectedAsset: PropTypes.object,
  ticketAssetId: PropTypes.string,
  formEntity: PropTypes.oneOf(Object.values(FORM_ENTITIES)),
  uomList: PropTypes.array,
  premisesList: PropTypes.array,
};

AddInstallationFormView.defaultProps = {
  taskType: TASK_TYPES.INSTALLATION,
  formStatus: FORMS_STATUSES.NEW,
  isLoading: false,
  register: () => {},
  errors: null,
  control: null,
  setValue: () => {},
  watch: () => {},
  materialList: [],
  materialsPage: 0,
  loadingMaterials: false,
  hasNextPage: true,
  loadMaterials: () => {},
  selectMaterialId: "",
  handleSelectOnChange: () => {},
  selectedAsset: null,
  ticketAssetId: "",
  formEntity: FORM_ENTITIES.MATERIAL,
  uomList: [],
  premisesList: [],
};

const useStyles = makeStyles((theme) => ({
  root: {},
  backdrop: {
    zIndex: theme.zIndex.modal + 2000,
    color: "#fff",
  },
  formControl: {
    width: "100%",
    padding: "15px 5px 6px 5px",
  },
  card: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(5),
  },
  paper: {
    marginTop: theme.spacing(2),
    width: "100%",
    overflowX: "auto",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  sectionTitleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "10px 15px",
  },
  sectionTitleText: {
    margin: 0,
    fontWeight: "bold",
    fontSize: 18,
    marginLeft: 16,
  },
  cursorPointerElement: {
    cursor: "pointer",
  },
  noMarginElement: {
    margin: 0,
  },
  noHorizontalPadding: {
    padding: "auto 0px",
  },
  fullWidth: {
    width: "100%",
  },
  codeReaderPanel: {
    backgroundColor: "white",
    overflow: "clip",
    display: "relative",
  },
}));
