import React, { useState } from "react";
import { Logger } from "@aws-amplify/core";

import PropTypes from "prop-types";
import { useForm } from "react-hook-form";

import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Rating from "@material-ui/lab/Rating";
import ConfirmationDialog from "components/ConfirmationDialog";
import useLoadingStatus from "hooks/useLoadingStatus";

import Button from "components/custom/Button";
import { Box, TextField, Typography } from "@material-ui/core";
import { reviewTicket } from "./helpers/datastore";
import useNotifier from "hooks/useNotifier";

const logger = new Logger("ReviewTaskDialog");

function ReviewTaskDialog(props) {
  const {
    onCancel,
    onConfirm,
    okDisabled,
    cancelDisabled,
    open,
    okBtnText,
    cancelBtnText,
    loading,
    showOkBtn,
    isMobile,
    ...other
  } = props;
  const { showError } = useNotifier();

  const [rating, setRating] = useState(0);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isStarRequiredError, setIsStarRequiredError] = useState(false);
  const [isReviewing, reviewTicketWrapped] = useLoadingStatus(reviewTicket);

  const { errors, reset, getValues, register } = useForm({
    mode: "onChange",
    // defaultValues: DEFAULT_FORM_VALUES,
  });

  const handleCancel = () => {
    onCancel();
  };

  function handleShowConfirmationDialog() {
    setIsStarRequiredError(false);
    if (rating === 0) {
      setIsStarRequiredError(true);
      return;
    } else {
      setShowConfirmation(true);
    }
  }

  const handleOk = async (e) => {
    try {
      const values = getValues();
      await reviewTicketWrapped(props.ticketId, values.reviewComment?.trim(), rating);
      setShowConfirmation(false);
      setIsStarRequiredError(false);
      reset();
      props.onReview();
    } catch (error) {
      logger.error(error);
      showError("Algo salió mal intente de nuevo.");
    }
  };

  return (
    <>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="sm"
        fullScreen={isMobile}
        fullWidth={true}
        aria-labelledby="review-task-dialog"
        open={open}
        {...other}
      >
        <DialogTitle id="confirmation-dialog-title">Calificación de Soporte</DialogTitle>
        <DialogContent dividers>
          <Box textAlign="center">
            <p>Califique el soporte proporcionado e ingrese los comentarios pertinentes</p>
            <Rating
              name="simple-controlled"
              size="large"
              value={rating}
              onChange={(event, newValue) => {
                if (newValue >= 1 && isStarRequiredError) {
                  setIsStarRequiredError(false);
                }
                setRating(newValue);
              }}
            />
            {isStarRequiredError && <p style={{ color: "red" }}>Mínimo debes seleccionar una estrella.</p>}
            <Box mt={3}>
              <TextField
                inputRef={register}
                name="reviewComment"
                type="text"
                label="Comentarios"
                error={!!errors.reviewComment}
                helperText={errors.reviewComment?.message}
                variant="outlined"
                fullWidth
                multiline
                rows={4}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} disabled={cancelDisabled || loading || isReviewing} color="primary">
            {cancelBtnText}
          </Button>
          {showOkBtn && (
            <Button
              onClick={() => handleShowConfirmationDialog()}
              disabled={okDisabled || isReviewing}
              isLoading={loading || isReviewing}
              color="primary"
            >
              {okBtnText}
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        open={showConfirmation}
        title="Confirmación"
        onCancel={() => setShowConfirmation(false)}
        onConfirm={handleOk}
        loading={isReviewing}
      >
        <Typography paragraph>Confirmación</Typography>
        <Typography>Se registrará la calificación del servicio de soporte recibido.</Typography>

        <br />
        <Typography paragraph>¿Deseas continuar?</Typography>
      </ConfirmationDialog>
    </>
  );
}

ReviewTaskDialog.propTypes = {
  onCancel: PropTypes.func,
  isMobile: PropTypes.bool,
  onConfirm: PropTypes.func,
  open: PropTypes.bool,
  loading: PropTypes.bool,
  okBtnText: PropTypes.string,
  cancelBtnText: PropTypes.string,
  okDisabled: PropTypes.bool,
  cancelDisabled: PropTypes.bool,
  showOkBtn: PropTypes.bool,
};

ReviewTaskDialog.defaultProps = {
  onCancel: () => {},
  onConfirm: () => {},
  isMobile: false,
  open: false,
  loading: false,
  cancelBtnText: "Cancelar",
  okBtnText: "Continuar",
  okDisabled: false,
  cancelDisabled: false,
  showOkBtn: true,
};

export default ReviewTaskDialog;
