import { TASK_STATUS_MAP } from "./task/status";

export const MAINTENANCE_STATUS = Object.keys(TASK_STATUS_MAP).reduce((statuses, key) => {
  if (!key.startsWith("SHIPMENT")) {
    statuses[key] = TASK_STATUS_MAP[key];
  }
  return statuses;
}, {});

export const PROGRESS_STATUS = {
  progress: "IN_PROGRESS",
  completed: "COMPLETED",
};

export const UPDATE_TYPES = {
  ALL: "ALL",
  CUSTOM_REDUCER: "CUSTOM_REDUCER",
  INFO: "INFO",
  ASSETS: "ASSETS",
  MATERIALS: "MATERIALS",
  OBSERVATIONS: "OBSERVATIONS",
};
