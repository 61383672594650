import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Logger } from "@aws-amplify/core";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";

import Select from "components/custom/Select";
import useLoadingStatus from "hooks/useLoadingStatus";
import useNotifier from "hooks/useNotifier";
import * as graphql from "./helpers/graphql";

const logger = new Logger("CustomerProjectSelector");

export default function CustomerProjectSelector({
  onChangeProject,
  onChangeCompany,
  boxProps,
  hideTitle,
  hideCustomerSelect,
  hideProjectSelect,
  customerId,
  customerList,
  projectId,
  companyTextFieldProps,
  projectTextFieldProps,
}) {
  const [companyList, setCompanyList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [activeCompanyId, setActiveCompanyId] = useState("");
  const [activeProjectId, setActiveProjectId] = useState("");
  const [loading, _fetchCompanies] = useLoadingStatus(graphql.fetchCompanies);
  const { showError } = useNotifier();

  useEffect(() => {
    if (customerList.length) {
      setCompanyList(customerList);
    } else {
      getCompanies();
    }
  }, [customerList]);

  useEffect(() => {
    if (hideProjectSelect) {
      return;
    }
    if (activeCompanyId && companyList.length) {
      let projects = getProjectsByCompany(companyList, activeCompanyId);
      setProjectList(projects || []);
    } else if (!activeCompanyId) {
      changeProjectId({ target: { value: "" } });
    }
  }, [activeCompanyId, companyList, hideProjectSelect]);

  useEffect(() => {
    if (customerId) {
      setActiveCompanyId(customerId);
    }
  }, [customerId]);

  useEffect(() => {
    if (projectId) {
      setActiveProjectId(projectId);
      onChangeProject(projectId);
    }
  }, [projectId]);

  async function getCompanies() {
    try {
      let { data } = await _fetchCompanies();
      data = data.listCompanys.items.map(({ id, name, projects, enabled }) => ({
        id,
        value: id,
        label: name,
        projects,
        disabled: !enabled,
      }));

      //ordenar clientes por nombre en orden alfabetico
      data = data.sort((a, b) => {
        return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
      });

      setCompanyList(data);
    } catch (error) {
      logger.error(error);
      showError("Error al cargar el listado de clientes.");
    }
  }

  function getProjectsByCompany(companyList, companyId) {
    let {
      projects: { items: projects = [] },
    } = companyList.find(({ id }) => id === companyId);

    //ordenar proyectos por nombre en orden alfabetico
    projects = projects.sort((a, b) => {
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    });

    projects = projects.map(({ id, name, code, enabled }) => {
      return {
        value: id,
        label: code ? `${name} (${code})` : name,
        disabled: Boolean(enabled) || enabled === null ? false : true,
      };
    });
    return projects;
  }

  function changeCompanyId(event) {
    setActiveCompanyId(event.target.value);
    onChangeCompany(event.target.value);
  }

  function changeProjectId(event) {
    setActiveProjectId(event.target.value);
    onChangeProject(event.target.value);
  }

  const projectHelperText = projectList.length ? "" : "Este cliente no cuenta con proyectos disponibles";

  return (
    <Box {...boxProps}>
      {!hideTitle && <Typography variant="h6">Selección de proyecto</Typography>}
      <Grid container spacing={2}>
        {hideCustomerSelect ? null : (
          <Grid item xs={12} md={6}>
            <Select
              label="Cliente"
              name="customer"
              value={activeCompanyId}
              onChange={changeCompanyId}
              options={companyList}
              fullWidth
              SelectProps={{
                IconComponent: loading ? () => <CircularProgress color="primary" size={20} /> : undefined,
              }}
              {...companyTextFieldProps}
            />
          </Grid>
        )}

        {!Boolean(hideProjectSelect) && (
          <Grid item xs={12} md={6}>
            <Select
              label="Proyecto"
              name="project"
              value={activeProjectId}
              onChange={changeProjectId}
              options={projectList}
              fullWidth
              helperText={projectHelperText}
              {...projectTextFieldProps}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

CustomerProjectSelector.propTypes = {
  onChangeProject: PropTypes.func,
  onChangeCompany: PropTypes.func,
  boxProps: PropTypes.object,
  hideCustomerSelect: PropTypes.bool,
  hideProjectSelect: PropTypes.bool,
  hideTitle: PropTypes.bool,
  customerId: PropTypes.string,
  customerList: PropTypes.array,
  projectId: PropTypes.string,
  companyTextFieldProps: PropTypes.shape(),
  projectTextFieldProps: PropTypes.shape(),
};

CustomerProjectSelector.defaultProps = {
  onChangeProject: () => {},
  onChangeCompany: () => {},
  boxProps: {},
  hideCustomerSelect: false,
  hideProjectSelect: false,
  hideTitleSelect: false,
  customerId: "",
  customerList: [],
  projectId: "",
  companyTextFieldProps: {},
  projectTextFieldProps: {},
};
