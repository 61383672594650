export const getTicket = /* GraphQL */ `
  query GetTicket($id: ID!) {
    getTicket(id: $id) {
      id
      subject
      description
      requestType
      impact
      impactDetails
      status
      mode
      supportType
      urgency
      priority
      serviceCategory
      serviceSubcategory
      rating
      ratingComment
      ticketPremisesId
      ticketRequesterId
      ticketAssetId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
