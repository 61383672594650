export const updateKit = /* GraphQL */ `
  mutation UpdateKit(
    $id: ID!,
    $name: String!,
    $description: String!,
    $_version: Int!
  ) {
    updateKit(input: { 
      id: $id,
      name: $name,
      description: $description,
      _version: $_version
    }) {
      id
      name
      description
      _version
      _deleted
    }
  }
`;