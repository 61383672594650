import React, { useState } from "react";
import PropTypes from "prop-types";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Box from "@material-ui/core/Box";
import Button from "components/custom/Button";
import Typography from "@material-ui/core/Typography";
import DialogContentText from "@material-ui/core/DialogContentText";
import ConfirmationDialog from "components/ConfirmationDialog";
import useBooleanFlag from "hooks/useBooleanFlag";
import cleanUUID from "util/cleanUUID";
import useGraphQL from "hooks/useGraphQL";
import updateTaskMutation from "graphql-custom/mutations/updateTask";
import { TaskStatus } from "models";
import { uploadFile } from "util/file";
import useLoadingStatus from "hooks/useLoadingStatus";
import { useEffect } from "react";

function useValidationTaskOperation() {
  const { runGraphQLOperation } = useGraphQL();
  const [loading, _validateTask] = useLoadingStatus(validateTask);

  async function validateTask({ input, attachment }) {
    if (attachment) {
      const attachmentComps = attachment.name.split(".");
      const extension = attachmentComps[attachmentComps.length - 1];
      const objectName = Date.now();
      const filename = `task/${input.id}/validation/attachments/${objectName}.${extension}`;
      await uploadFile(filename, attachment);
      input = {
        ...input,
        validationAttachment: {
          bucket: process.env.REACT_APP_AWS_USER_FILES_S3_BUCKET,
          region: process.env.REACT_APP_AWS_REGION,
          key: filename,
        },
      };
    }

    return runGraphQLOperation({
      operation: updateTaskMutation,
      variables: {
        input: {
          ...input,
        },
      },
      notifications: { errorMsg: "Error al validar la instalación." },
    });
  }

  return { loading, validateTask: _validateTask };
}

function TaskValidationDialog(props) {
  const [file, setFile] = useState(null);
  const [validationDialogOpen, openValidationDialog, closeValidationDialog] = useBooleanFlag();
  const [confirmationDialogOpen, openConfirmationDialog, closeConfirmationDialog] = useBooleanFlag();
  const theme = useTheme();
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const { loading: validating, validateTask } = useValidationTaskOperation();

  useEffect(() => {
    if (props.open && props.attachment) {
      openValidationDialog();
    } else if (!props.open && props.attachment) {
      closeValidationDialog();
    } else if (props.open && !props.attachment) {
      openConfirmationDialog();
    } else if (!props.open && !props.attachment) {
      closeConfirmationDialog();
    }
  }, [props.open]);

  function handleFileChange(event) {
    const [file = null] = event.target.files;
    setFile(file);
  }

  function handleClose() {
    if (props.attachment && file && confirmationDialogOpen) {
      closeConfirmationDialog();
    } else {
      setFile(null);
      props.onClose();
    }
  }

  async function _validateTask() {
    validateTask({
      input: {
        id: props.taskID,
        status: props.validationStatus,
        _version: props.taskVersion,
      },
      attachment: file,
    }).then(({ updateTask }) => {
      closeConfirmationDialog();
      props.onSuccess(updateTask);
    });
  }

  const continueBtnDisabled = props.loading || (props.attachment && props.attachmentRequired && !file);
  const taskID = cleanUUID(props.taskID);
  return (
    <>
      <Dialog maxWidth="sm" fullWidth fullScreen={matchesXS} open={validationDialogOpen} onClose={props.onClose}>
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent dividers>
          {props.attachment && (
            <>
              <DialogContentText color="textPrimary">{props.validationText}</DialogContentText>
              <div>
                <input
                  type="file"
                  name="validationFileInput"
                  accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                  onChange={handleFileChange}
                />
              </div>
              {props.attachmentRequired && (
                <Box mt={1}>
                  <Typography color="textSecondary" variant="caption">
                    <strong>* Archivo requerido</strong>
                  </Typography>
                </Box>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button color="default" onClick={handleClose} disabled={props.loading}>
            CERRAR
          </Button>
          <Button
            color="primary"
            onClick={openConfirmationDialog}
            disabled={continueBtnDisabled}
            isLoading={props.loading}
          >
            CONTINUAR
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        open={confirmationDialogOpen}
        onCancel={handleClose}
        onConfirm={_validateTask}
        cancelBtnText="Cancelar"
        okBtnText="Continuar"
        loading={validating}
        title="Confirmación"
      >
        <DialogContentText color="textPrimary">Se registrará la validación de la actividad {taskID}.</DialogContentText>
        <DialogContentText color="textPrimary">¿Seguro que deseas continuar?</DialogContentText>
      </ConfirmationDialog>
    </>
  );
}

TaskValidationDialog.propTypes = {
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
  open: PropTypes.bool,
  attachment: PropTypes.bool,
  attachmentRequired: PropTypes.bool,
  loading: PropTypes.bool,
  taskID: PropTypes.string.isRequired,
  taskVersion: PropTypes.number.isRequired,
  validationStatus: PropTypes.oneOf(Object.values(TaskStatus)).isRequired,
  title: PropTypes.string,
  validationText: PropTypes.string,
};

TaskValidationDialog.defaultProps = {
  onClose: () => {},
  onConfirm: () => {},
  onSuccess: () => {},
  onFailure: () => {},
  open: false,
  attachment: false,
  attachmentRequired: false,
  loading: false,
  title: "Validación",
  validationText: "Ingrese archivo adjunto de validación",
};

export default TaskValidationDialog;
