import React from "react";

import PropTypes from "prop-types";

import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

const LOADER_WRAPPER_STYLES = {
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: "100%",
};

const LOADER_STYLES = {
  width: 60,
  height: 60,
  margin: 30,
};

/**
 * Componente que muestra un panel de carga cuando la propiedad show=true y muestra
 * su children cuando la propiedad show=false;
 *
 * @param {Object} props Propiedades del componente. Refierete al propTypes para más info.
 */
function LoadingPanel(props) {
  return (
    <>
      {props.show && (
        <div style={LOADER_WRAPPER_STYLES}>
          <CircularProgress style={LOADER_STYLES} />
          <Typography>{props.text}</Typography>
        </div>
      )}
      {!props.show && <>{props.children}</>}
    </>
  );
}

LoadingPanel.propTypes = {
  // Bandera que controla la visibilidad del panel de carga
  show: PropTypes.bool,
  // Mensaje mostrado en la parte inferior del panel de carga
  text: PropTypes.string,
};

LoadingPanel.defaultProps = {
  show: false,
  text: "Cargando...",
};

export default LoadingPanel;
