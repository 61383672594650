/**
 * Disponibiliza uso de Operaciones con graphQL y DataStore utilizando hooks useGraphQL y useDataStore de manera centralizada en este hook
 *
 * usage:
 * const { updateTicket, updateTask } = useGlogalOperations();
 */

import { useCallback } from "react";
import useGraphQL from "hooks/useGraphQL";
// import { Logger } from "@aws-amplify/core";
import useDataStore from "hooks/useDataStore";
import useGenerateOnlineOfflineHandler from "hooks/useGenerateOnlineOfflineHandler";
import { generateTaskAssetMutations } from "graphql-custom/mutations";
import updateTicketGraphql from "graphql-custom/mutations/updateTicket";
import updateTaskGraphql from "graphql-custom/mutations/updateTask";
import * as models from "models";

// const logger = new Logger("useGlobalOperations");

export default function useGlobalOperations() {
  const { runGraphQLOperation } = useGraphQL();
  const DataStoreTicket = useDataStore(models.Ticket);
  const DataStoreTask = useDataStore(models.Task);
  const DataStoreTaskAsset = useDataStore(models.TaskAssets);
  const DataStoreAsset = useDataStore(models.Asset);

  const generateOnlineOfflineHandler = useGenerateOnlineOfflineHandler();

  /* ------------TICKET-------------- */
  const updateTicket = (
    ticketId = "",
    input = null,
    _version,
    { validation = null, onValidationFail, errorMsg = "" } = {}
  ) =>
    generateOnlineOfflineHandler(updateTicketOnline, updateTicketOffline, {
      onlineParams: { ticketId, input, _version },
      offlineParams: { ticketId, input },
      onValidationFail,
      validation:
        validation !== null && validation !== undefined
          ? validation
          : !ticketId || !input || !Object.keys(input).length || !_version,
      errorMsg,
    });

  async function updateTicketOnline({ ticketId = "", input = null, _version } = {}) {
    const updatedTicket = await runGraphQLOperation({
      operation: updateTicketGraphql,
      variables: { input: { id: ticketId, _version, ...input } },
    });
    return updatedTicket?.updateTicket;
  }

  async function updateTicketOffline({ ticketId = "", input = null } = {}) {
    return DataStoreTicket.update({ id: ticketId, values: input });
  }

  /* ------------TASK-------------- */
  const updateTask = (
    taskId = "",
    input = null,
    _version,
    { validation = null, onValidationFail, errorMsg = "" } = {}
  ) =>
    generateOnlineOfflineHandler(updateTaskOnline, updateTaskOffline, {
      onlineParams: { taskId, input, _version },
      offlineParams: { taskId, input },
      onValidationFail,
      validation:
        validation !== null && validation !== undefined
          ? validation
          : !taskId || !input || !Object.keys(input).length || !_version,
      errorMsg,
    });

  async function updateTaskOnline({ taskId = "", input = null, _version } = {}) {
    const updatedTask = await runGraphQLOperation({
      operation: updateTaskGraphql,
      variables: { input: { id: taskId, _version, ...input } },
    });
    return updatedTask?.updateTask;
  }

  async function updateTaskOffline({ taskId = "", input = null } = {}) {
    return DataStoreTask.update({ id: taskId, values: input });
  }

  /* ------------ASSETS-------------- */
  const createTaskAssets = (assetsIds, taskId, { validation = null, onValidationFail, errorMsg = "" } = {}) =>
    generateOnlineOfflineHandler(createTaskAssetsOnline, createTaskAssetsOffline, {
      onlineParams: { assetsIds, taskId },
      offlineParams: { assetsIds, taskId },
      onValidationFail,
      validation:
        validation !== null && validation !== undefined ? validation : !assetsIds || !assetsIds.length || !taskId,
      errorMsg,
    });

  const deleteTaskAssets = (taskAssets = [], taskId, { validation = null, onValidationFail, errorMsg = "" } = {}) =>
    generateOnlineOfflineHandler(deleteTaskAssetsOnline, deleteTaskAssetsOffline, {
      onlineParams: { taskAssets, taskId },
      offlineParams: { taskAssets },
      validation:
        validation !== null && validation !== undefined ? validation : !taskAssets || !taskAssets.length || !taskId,
      onValidationFail,
      errorMsg,
    });

  async function createTaskAssetsOnline({ assetsIds, taskId } = {}) {
    const assetIdsFormated = assetsIds.map((assetId) => ({ id: assetId, scanned: true }));
    const { mutation, variables } = generateTaskAssetMutations(assetIdsFormated, [], taskId);
    let newTaskAssets = await runGraphQLOperation({
      operation: mutation,
      variables: variables,
    });
    newTaskAssets = Object.keys(newTaskAssets).map((assetKey) => newTaskAssets[assetKey]);
    return newTaskAssets;
  }

  async function createTaskAssetsOffline({ assetsIds, taskId } = {}) {
    const taskAssetsPromises = assetsIds.map(async (assetId) => {
      const task = DataStoreTask.get({ criteria: taskId });
      const asset = DataStoreAsset.get({ criteria: assetId });
      return DataStoreTaskAsset.create({
        values: { task, asset, taskAssetsAssetId: asset.id, taskAssetsTaskId: task.id, scanned: true },
      });
    });
    const newAssets = await Promise.all(taskAssetsPromises);
    return newAssets;
  }

  async function deleteTaskAssetsOnline({ taskAssets = [], taskId } = {}) {
    const taskAssetsFormated = taskAssets.map(({ id, _version }) => ({ taskAsset: { id, _version } }));
    const { mutation, variables } = generateTaskAssetMutations([], taskAssetsFormated, taskId);
    let deletedAssets = await runGraphQLOperation({
      operation: mutation,
      variables: variables,
    });
    deletedAssets = Object.keys(deletedAssets).map((assetKey) => deletedAssets[assetKey]);

    return deletedAssets;
  }

  async function deleteTaskAssetsOffline({ taskAssets = [] } = {}) {
    const removeTaskAssetsPromises = taskAssets.map(async (taskAsset) =>
      DataStoreTaskAsset.remove({ condition: taskAsset.id })
    );
    return Promise.all(removeTaskAssetsPromises);
  }

  const run = useCallback(
    (promise) =>
      promise.then(
        (data) => data,
        (error) => error
      ),
    []
  );

  return {
    run,
    updateTask,
    updateTicket,
    createTaskAssets,
    deleteTaskAssets,
  };
}
