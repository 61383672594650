import React from "react";
import PropTypes from "prop-types";

export function ProjectDetailPanel({ description, ...props }) {
  return (
    <div style={{ padding: "16px 32px" }} {...props}>
      {description}
    </div>
  );
}

export default ProjectDetailPanel;
ProjectDetailPanel.propTypes = { description: PropTypes.string };
ProjectDetailPanel.defaultProps = { description: "Sin descripción" };
