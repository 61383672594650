import React from "react";
import PropTypes from "prop-types";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import { makeStyles } from "@material-ui/core/styles";
import StepLabel from "@material-ui/core/StepLabel";

const CustomStepper = ({ activeStep, steps }) => {
  const classesTitle = useStylesTitle();

  return (
    <>
      <div className={classesTitle.root}>
        <Stepper activeStep={activeStep} alternativeLabel className={classesTitle.stepper}>
          {steps.map(({ step, stepKeyname, isStepCompleted, isLastStep }) => (
            <Step key={stepKeyname} completed={isStepCompleted} active={isLastStep}>
              <StepLabel
                className={activeStep === step ? classesTitle.stepActive : ""}
                StepIconProps={
                  activeStep === step
                    ? {
                        style: { color: "inherit" },
                        classes: { root: classesTitle.stepActivoColor, completed: classesTitle.stepActivoColor },
                      }
                    : {}
                }
              />
            </Step>
          ))}
        </Stepper>
      </div>
    </>
  );
};
CustomStepper.propTypes = {
  steps: PropTypes.array,
  activeStep: PropTypes.number,
};
CustomStepper.defaultProps = {
  steps: [],
  activeStep: 0,
};

const useStylesTitle = makeStyles((theme) => ({
  root: {
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  stepper: {
    padding: "5px 0 0 0",
    "&.MuiStep-horizontal": {
      [theme.breakpoints.down(360)]: {
        "padding-left": "4px",
        "padding-right": "4px",
      },
    },
  },
  stepActive: {
    color: "hsla(0, 0%, 0%, 1)",
    transform: "scale(1.2)",
  },
  stepActivoColor: {
    "&.MuiStepIcon-root.MuiStepIcon-completed": {
      color: "inherit",
    },
    "&.MuiStepIcon-root.MuiStepIcon-active": {
      color: "inherit",
    },
  },
}));

export default CustomStepper;
