import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Backdrop from "@material-ui/core/Backdrop";
import MUISpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 380,
    transform: "translateZ(0px)",
    flexGrow: 1,
  },
  speedDial: ({ position }) => ({
    position: position,
    bottom: theme.spacing(2),
    right: theme.spacing(2),

    "& .MuiSpeedDialAction-staticTooltipLabel": {
      minWidth: "200px",
      textAlign: "center",
    },
  }),
}));

function SpeedDial(props) {
  const { actions: actionsFromProps, position, backdrop, hidden, tooltipOpen, id, ...restProps } = props;
  const [open, setOpen] = useState(false);
  const [actions, setActions] = useState([]);

  const classes = useStyles({ position: position });
  const actionsAvailable = actionsFromProps && actionsFromProps?.length;

  useEffect(() => {
    if (!actionsAvailable) return;
    const actions = actionsFromProps.map((action) => ({
      ...action,
      onClick: (event) => {
        setOpen(false);
        action.onClick(event);
      },
    }));
    setActions(actions);
  }, [actionsFromProps, actionsAvailable]);

  const handleOpen = () => {
    if (actionsAvailable) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Backdrop open={backdrop && open} style={{ zIndex: 1000 }} />
      <MUISpeedDial
        ariaLabel="SpeedDial tooltip example"
        className={classes.speedDial}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        hidden={hidden}
        open={open}
        {...restProps}
      >
        {actions.map((action) => (
          <SpeedDialAction
            id={id}
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen={tooltipOpen}
            onClick={action.onClick}
          />
        ))}
      </MUISpeedDial>
    </>
  );
}

SpeedDial.propTypes = {
  // Permite mostrar un backdrop cuando el speel dial está abierto
  backdrop: PropTypes.bool,
  // Permite mostrar los tooltips de los actions al abrirse el speed dial
  tooltipOpen: PropTypes.bool,
  // Oculta o muestra el botón del speed dial
  hidden: PropTypes.bool,
  id: PropTypes.string,

  //valor de la propiedad position de css que tendrá el speedDial
  position: PropTypes.string,
  actions: PropTypes.array,
};

SpeedDial.defaultProps = {
  backdrop: false,
  tooltipOpen: false,
  hidden: false,
  position: "absolute",
  actions: [],
};

export default SpeedDial;
