export const COUNTER_STATUS = {
  RUNNING: "RUNNING",
  PAUSED: "PAUSED",
  STOPPED: "STOPPED",
  EXPIRED: "EXPIRED",
};

export const COUNTER_MODE = {
  COUNTDOWN: "COUNTDOWN",
  COUNTUP: "COUNTUP",
};

export const COUNTER_COLORS = {
  RUNNING: "#7EA1BC",
  PAUSED: "#141414",
  HALF_TIME: "#FF8F20",
  LITTLE_TIME: "#FF6C6C",
};

export const CONFIG_STOP_TIMER = {
  id: "PAUSE",
  title: "Detener tiempo de servicio",
  icon: "/img/icon_stop.svg",
  btnText: "Detener",
  defaultFormValues: {
    reason: "",
    description: "",
  },
};

export const CONFIG_RESTART_TIMER = {
  id: "RESUME",
  title: "Reanudar tiempo de servicio",
  icon: "/img/icon_play.svg",
  btnText: "Reanudar",
  defaultFormValues: {
    description: "",
  },
};

export const CONFIG_SUSPENSION_TIMER = {
  id: "EDIT_PAUSE",
  title: "Motivo de suspensión del ticket",
  icon: "/img/lapiz.svg",
  btnText: "Editar",
  defaultFormValues: {
    description: "",
  },
};
