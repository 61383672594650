import React from "react";
import PropTypes from "prop-types";
import NetworkTooltip from "components/custom/NetworkTooltip";
import { Switch } from "@material-ui/core";

const TableSwitchButton = ({ checked, onChange, onlineTitle, offlineTitle, rowData, ...props }) => {
  return (
    <NetworkTooltip onlineTitle={onlineTitle} offlineTitle={offlineTitle} style={{ width: "64px" }}>
      <Switch
        checked={checked}
        onChange={(event) => onChange(checked, rowData, event)}
        color="primary"
        name="enabled"
        {...props}
      />
    </NetworkTooltip>
  );
};

export default TableSwitchButton;

TableSwitchButton.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  onlineTitle: PropTypes.string,
  offlineTitle: PropTypes.string,
  rowData: PropTypes.shape(),
};
