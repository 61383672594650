import React from "react";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { DataStore, syncExpression } from "@aws-amplify/datastore";
import { Hub, I18n, Logger, Amplify } from "@aws-amplify/core";
import { Auth } from "@aws-amplify/auth";
import { useIndexedDB } from "react-indexed-db";
import * as Sentry from "@sentry/capacitor";

import useDataStoreStatus from "hooks/useDataStoreStatus";
import useGeneralLoaderStatus from "hooks/useGeneralLoader";
import useNetworkStatus from "hooks/useNetworkStatus";
import {
  Attachment,
  AttachmentTicket,
  Tracking,
  AssetAttribute,
  AttachmentAsset,
  AttachmentGoodsReceipt,
} from "models";
import awsConfig from "aws-config";
import amplifyVocabulary from "util/amplifyVocabulary";
import { deleteFiles, uploadFile } from "util/file";
import ActionType from "redux/action";
import { EVIDENCE_TYPES } from "constant/evidenceTypes";
import { DATASTORE_EVENTS } from "constant/datastoreEvents";
import { ENVIRONMENT } from "constant/environments";
import { GRANTED_AGREEMENT_COGINITO_CUSTOM_ATTRIBUTE } from "components/LoginView/constants";
import { LEGAL_DOCUMENT_TYPE, LEGAL_DOCUMENT_STATUS } from "constant/legalDocuments";
import { useAPIHelpers, updateCognitoUserAttribute, hasAgreementByTypeAndStatus } from "./helpers";
import { METADATA_KEYS } from "constant/attachments";
import { captureException } from "./sentry";

const logger = new Logger("setup/amplify");

function getFilterDate(thresholdInMs = process.env.REACT_APP_TRACKING_SYNC_THRESHOLD) {
  let threshold = Number(thresholdInMs);

  if (isNaN(threshold)) {
    logger.warn("syncExpression:callback Variable de entorno indefinida. Se utilizará el tiempo umbral por defecto ");
    threshold = 2629800000;
  }

  const now = Date.now();
  const thresholdDate = new Date(now - threshold);
  const dateToFilter = thresholdDate.toISOString().substring(0, 10);
  return dateToFilter;
}

Amplify.configure(awsConfig);
Amplify.Logger.LOG_LEVEL = process.env.REACT_APP_ENV !== ENVIRONMENT.PROD ? "DEBUG" : Amplify.Logger.LOG_LEVEL;
I18n.setLanguage("es");
I18n.putVocabularies(amplifyVocabulary);

DataStore.configure({
  maxRecordsToSync: 10000,
  errorHandler: (error) => {
    logger.error("errorHandler: Error en sincronización ", error);
  },
  syncExpressions: [
    syncExpression(AttachmentGoodsReceipt, () => {
      const dateToFilter = getFilterDate();
      return (attachment) => attachment.createdAt("gt", dateToFilter);
    }),
    syncExpression(AttachmentAsset, () => {
      const dateToFilter = getFilterDate();
      return (attachment) => attachment.createdAt("gt", dateToFilter);
    }),
    syncExpression(AttachmentTicket, () => {
      const dateToFilter = getFilterDate();
      return (attachment) => attachment.createdAt("gt", dateToFilter);
    }),
    syncExpression(Attachment, () => {
      // NOTE: Tiempo umbral para la consulta de Attachments de 3 meses en milisegundos
      const dateToFilter = getFilterDate(7889400000);
      return (attachment) => attachment.createdAt("gt", dateToFilter);
    }),
    syncExpression(AssetAttribute, () => {
      return (at) => at.id("eq", null);
    }),
    // syncExpression(TimerEvent, () => {
    //   return (te) => te.id("eq", null);
    // }),
    syncExpression(Tracking, () => {
      const dateToFilter = getFilterDate();
      return (tracking) =>
        tracking.or((tracking) =>
          tracking
            .id("eq", "unassigned")
            .status("eq", "assigned")
            .and((tracking) => tracking.status("eq", "unassigned").createdAt("gt", dateToFilter))
        );
    }),
  ],
});

const legalBoxDataDefaultState = {
  showModal: false,
  tabToShow: 0,
};

/**
 * Hook de configuración de los manejadores de eventos de Amplify. Este hook depende del contexto de Redux.
 */
function useAmplifySetup() {
  const history = useHistory();
  const dispatch = useDispatch();
  const imagesDb = useIndexedDB("offline_images");
  const { updateNetworkStatus } = useNetworkStatus();
  const dataStoreStatus = useSelector((state) => state.app.dataStoreStatus);
  const showGeneralLoader = useSelector((state) => state.app.showGeneralLoader);
  // const AttachmentAsset = useDataStore(models.AttachmentAsset);
  const authState = useSelector((state) => state.session.state);
  const { createUserAgreement, getListLegalDocuments, getUserById } = useAPIHelpers();

  const [legalBoxProps, setLegalBoxProps] = React.useState(legalBoxDataDefaultState);

  const { updateDataStoreStatus, updateDataStoreSyncedModels, updateDataStoreModels, resetDataStoreSyncedModels } =
    useDataStoreStatus();
  const { setGeneralLoaderStatus } = useGeneralLoaderStatus();
  const dsSuscriptionRef = useRef({ unsubscribe: () => {} });

  useEffect(() => {
    dataStoreSuscribe();
    return dataStoreUnsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Suscripción de handlers de eventos de Hub de Amplify
    const authListener = Hub.listen("auth", handleAuthEvents);
    const datastoreListener = Hub.listen("datastore", handleDataStoreEvents);
    checkDataStoreSync();
    validateAuthSession();

    return () => {
      // Desuscripción de handlers de Hub de Amplify
      authListener();
      datastoreListener();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function dataStoreSuscribe() {
    // Iniciamos la suscripción sólo si la sincronización ha teminado
    if (dataStoreStatus === "SYNCED") {
      // setGeneralLoaderStatus(true);
      dsSuscriptionRef.current = DataStore.observe().subscribe();
    }
  }

  function dataStoreUnsubscribe() {
    if (dsSuscriptionRef.current) {
      dsSuscriptionRef.current.unsubscribe();
    }
  }

  /**
   * Verifica estado de aplicación e inicia sincronización si las condiciones requeridas se cumplen
   */
  async function checkDataStoreSync() {
    if ((dataStoreStatus === "IN_PROGRESS" || dataStoreStatus === "UNSYNCED") && showGeneralLoader) {
      await DataStore.stop();
      await DataStore.clear();
      DataStore.start();
    } else if (dataStoreStatus === "IN_PROGRESS" && !showGeneralLoader) {
      updateDataStoreStatus("SYNCED");
      updateDataStoreModels([]);
      resetDataStoreSyncedModels([]);
    }
  }

  async function validateAuthSession() {
    try {
      const authUser = await Auth.currentAuthenticatedUser();
      if (authUser && authState !== "signIn") {
        await Auth.signOut();
      }
    } catch (error) {
      logger.error("validateAuthSession: ", error);
      if (error === "The user is not authenticated") {
        await handleSignOut();
      }
    }
  }

  /**
   * Almacena información de la sesión de usuario en Redux e inicia sincronización de DataStore
   */
  async function handleAuthSignIn(data) {
    logger.debug("Iniciando sesión");
    const { "cognito:groups": groups = [] } = data.payload.data.signInUserSession.accessToken.payload;
    const { email: username, "cognito:username": userId } = data.payload.data.signInUserSession.idToken.payload;
    const grantedAgreement = data.payload.data.attributes.hasOwnProperty(GRANTED_AGREEMENT_COGINITO_CUSTOM_ATTRIBUTE);
    let hasTermsAndConditions = false;
    let hasNoticeOfPrivacy = false;

    try {
      //1. el usuario ha aceptado algún acuerdo términos y condicones o de avisos de privacidad
      if (grantedAgreement) {
        const hasAgreements = await handleUserGrantedAnAgreement(userId);
        hasTermsAndConditions = hasAgreements.hasTermsAndConditions;
        hasNoticeOfPrivacy = hasAgreements.hasNoticeOfPrivacy;
      }

      //2. si no cuenta con ningun acuerdo o no cuenta con los acuerdos en vigor, mostrar el modal de aceptación de acuerdos
      if (!grantedAgreement || !hasTermsAndConditions || !hasNoticeOfPrivacy) {
        const onGrantAgreementsProps = {
          userId,
          grantedAgreement,
          hasTermsAndConditions,
          hasNoticeOfPrivacy,
          groups,
          username,
        };
        setLegalBoxProps((currentValues) => ({
          ...currentValues,
          showModal: true,
          showBackdropOnClose: true,
          onGrantAgreements: () => _onGrantAgreements(onGrantAgreementsProps),
          onCloseByIcon: async () => {
            setLegalBoxProps({ ...legalBoxDataDefaultState });
            return Auth.signOut();
          },
        }));
        return;
      }

      handleInitSession(groups, username, userId);
    } catch (error) {
      logger.error("handleAuthSignIn", { error, data });
    }
  }

  async function handleUserGrantedAnAgreement(userId) {
    //1. obtener los acuerdos aceptados por el usuario
    const user = await getUserById(userId);
    const legalAgreements = user.getUser.legalAgreements;

    //2. validar que el usuario tenga la última versión de avisos de privacidad vigente
    const hasNoticeOfPrivacy = hasAgreementByTypeAndStatus(
      legalAgreements,
      LEGAL_DOCUMENT_TYPE.NOTICE_OF_PRIVACY,
      LEGAL_DOCUMENT_STATUS.EFFECTIVE
    );

    //3. validar que el usuario tenga el acuerdo de terminos y condiciones vigente
    const hasTermsAndConditions = hasAgreementByTypeAndStatus(
      legalAgreements,
      LEGAL_DOCUMENT_TYPE.TERMS_AND_CONDITIONS,
      LEGAL_DOCUMENT_STATUS.EFFECTIVE
    );

    return { hasNoticeOfPrivacy, hasTermsAndConditions };
  }

  async function _onGrantAgreements(props = {}) {
    const { userId, grantedAgreement, hasTermsAndConditions, hasNoticeOfPrivacy, groups, username } = props;
    const result = await handlerUserAgreement(userId, grantedAgreement, hasTermsAndConditions, hasNoticeOfPrivacy);
    if (result) {
      handleInitSession(groups, username, userId);
    }
    return result;
  }

  function handleInitSession(groups, username, userId) {
    // si el usuario cuenta con los acuerdos aceptados y vigentes y tiene grupos asignados, se inicia el proceso de inicio de sesión
    dispatch({
      type: ActionType.SIGN_IN,
      payload: { groups, username, userId },
    });
    Sentry.setTag("userId", userId);
    history.push("/inicio");
    setGeneralLoaderStatus(true);
    DataStore.start();
  }

  async function handlerUserAgreement(userId, grantedAgreement, hasTermsAndConditions, hasNoticeOfPrivacy) {
    try {
      const listLegalDocuments_ = await getListLegalDocuments();
      const listLegalDocuments = listLegalDocuments_.listLegalDocuments.items.filter(
        (item) =>
          (item.type === LEGAL_DOCUMENT_TYPE.TERMS_AND_CONDITIONS && item.status === LEGAL_DOCUMENT_STATUS.EFFECTIVE) ||
          (item.type === LEGAL_DOCUMENT_TYPE.NOTICE_OF_PRIVACY && item.status === LEGAL_DOCUMENT_STATUS.EFFECTIVE)
      );
      if (!listLegalDocuments.length) {
        const ERROR = "No hay document legales cargados en la plataforma";
        throw new Error(ERROR);
      }

      //actualizar attributo en cognito
      if (!grantedAgreement) {
        await updateCognitoUserAttribute(GRANTED_AGREEMENT_COGINITO_CUSTOM_ATTRIBUTE, "true");
      }

      //caso 1: el usuario no cuenta con ningún acuerdo aceptado o los que tiene no están en vigor
      if (!grantedAgreement || (!hasNoticeOfPrivacy && !hasTermsAndConditions)) {
        return Promise.all(listLegalDocuments.map(async (item) => createUserAgreement(userId, item.id)));
      }

      //caso 2: los avisos de privacidad aceptados por el usuario ya no están vigentes
      if (!hasNoticeOfPrivacy) {
        const noticeofPrivace = listLegalDocuments.find((item) => item.type === LEGAL_DOCUMENT_TYPE.NOTICE_OF_PRIVACY);
        return createUserAgreement(userId, noticeofPrivace.id);
      }

      //caso 3: los términos y condiciones aceptados por el usuario ya no están vigentes
      if (!hasTermsAndConditions) {
        const termsAndConditions = listLegalDocuments.find(
          (item) => item.type === LEGAL_DOCUMENT_TYPE.TERMS_AND_CONDITIONS
        );
        return createUserAgreement(userId, termsAndConditions.id);
      }

      return true;
    } catch (error) {
      // showError("Ocurrio un error durante la aceptación de los acuerdos legales");
      logger.error("handlerUserAgreement", error);
      return false;
    }
  }

  /**
   * Limpia datos de sesión de aplicación y DataStore
   */
  async function handleSignOut() {
    dataStoreUnsubscribe();
    await DataStore.stop();
    await DataStore.clear();
    dispatch({ type: ActionType.SIGN_OUT });
    updateDataStoreModels([]);
    resetDataStoreSyncedModels([]);
    updateNetworkStatus("");
    updateDataStoreStatus("UNSYNCED");
    history.push("/");
  }

  function handleSignUp(data) {
    logger.debug("Usuario nuevo", { data });
  }

  async function handleAuthEvents(data) {
    logger.debug("handleAuthEvents: ", data.payload.event);
    switch (data.payload.event) {
      case "signIn": {
        await handleAuthSignIn(data);
        break;
      }
      case "signOut": {
        await handleSignOut();
        break;
      }
      case "signUp": {
        handleSignUp(data);
        break;
      }
      case "tokenRefresh_failure": {
        await Auth.signOut();
        break;
      }
      default:
    }
  }

  async function processOfflineImages() {
    const records = await imagesDb.getAll();
    const promises = records.map(async (r) => {
      try {
        if (r.pendingDelete) {
          const { id, ...attach } = r;
          await imagesDb.deleteRecord(r.id);
          return deleteFiles(attach?.file?.key);
        } else {
          if (r.type === EVIDENCE_TYPES.TICKET) {
            // cargar evidencias offline de  tickets
            const { id, blob, filename, metadata, ...attach } = r;

            const s3KeyFile = await uploadFile(filename, blob, { metadata });
            attach.id = id;
            attach.file.key = s3KeyFile.key;

            await imagesDb.deleteRecord(id);
            const attachment = new AttachmentTicket(attach);
            return DataStore.save(attachment);
          } else if (r.type === EVIDENCE_TYPES.TICKET_VALIDATION) {
            const { id, blob, filename, ...attach } = r;
            const s3KeyFile = await uploadFile(filename, blob);
            attach.file.key = s3KeyFile.key;

            await imagesDb.deleteRecord(id);
          } else if (r.type === EVIDENCE_TYPES.ATTACHMENT_ASSET) {
            const { id, type, blob, file, metadata, ...attachment } = r;

            let attachmentAsset = new AttachmentAsset({ ...attachment, file });
            let s3KeyFile = file.key.replace(id, attachmentAsset.id);

            attachmentAsset = AttachmentAsset.copyOf(attachmentAsset, (updated) => {
              updated.file = {
                ...file,
                key: s3KeyFile,
              };
              return updated;
            });
            await DataStore.save(attachmentAsset);

            await uploadFile(s3KeyFile, blob, {
              metadata: {
                ...metadata,
                [METADATA_KEYS.ATTACHMENT_ID]: attachmentAsset.id,
              },
            });

            logger.log("processOfflineImages: ", { attachmentAsset });
            await imagesDb.deleteRecord(id);
          } else {
            // cargar evidencias offline de Attachments
            const { id, blob, filenamee, metadata, ...attach } = r;
            let attachment = new Attachment(attach);
            let s3KeyFile = filenamee.replace(id, attachment.id);

            attachment = Attachment.copyOf(attachment, (updated) => {
              updated.file = {
                ...attach.file,
                key: s3KeyFile,
              };
              return updated;
            });
            await DataStore.save(attachment);

            await uploadFile(s3KeyFile, blob, {
              metadata: {
                ...metadata,
                [METADATA_KEYS.ATTACHMENT_ID]: attachment.id,
              },
            });

            await imagesDb.deleteRecord(id);
          }
        }
      } catch (error) {
        logger.error("processOfflineImages:", error);
        const { file, blob, metadata, ...attachment } = r;
        captureException(error, "processOfflineImages", attachment);
      }
    });
    await Promise.all(promises);
  }

  async function handleDataStoreEvents(data) {
    logger.debug("handleDataStoreEvents: ", data.payload.event);
    switch (data.payload.event) {
      case DATASTORE_EVENTS.NETWORK_STATUS: {
        const { active } = data.payload.data;
        if (active) {
          updateNetworkStatus("ONLINE");
          await processOfflineImages();
        } else {
          updateNetworkStatus("OFFLINE");
        }
        break;
      }
      case DATASTORE_EVENTS.OUTBOX_STATUS: {
        const { isEmpty } = data.payload.data;
        if (!isEmpty) {
          dispatch({
            type: ActionType.PEDING_SYNC_STATUS_CHANGE,
            isPendingSync: true,
          });
        } else {
          dispatch({
            type: ActionType.PEDING_SYNC_STATUS_CHANGE,
            isPendingSync: false,
          });
        }
        break;
      }
      case DATASTORE_EVENTS.SYNC_QUERIES_STARTED: {
        const { models } = data.payload.data;
        updateDataStoreModels(models);
        resetDataStoreSyncedModels([]);
        updateDataStoreStatus("IN_PROGRESS");
        break;
      }
      case DATASTORE_EVENTS.MODEL_SYNCED: {
        const {
          model: { name },
        } = data.payload.data;
        updateDataStoreSyncedModels(name);
        break;
      }
      case DATASTORE_EVENTS.READY: {
        dataStoreSuscribe();
        updateDataStoreModels([]);
        resetDataStoreSyncedModels([]);
        updateDataStoreStatus("SYNCED");
        setGeneralLoaderStatus(false);
        break;
      }
      default:
    }
  }

  return {
    legalBoxProps,
    setLegalBoxProps,
    onCloseLegalBoxModal: () => setLegalBoxProps(legalBoxDataDefaultState),
  };
}

export default useAmplifySetup;
