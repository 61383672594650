export function generateMaterialsUpdate(taskId, materials = [], added = [], updated = [], deleted = []) {
  if (!added.length && !updated.length && !deleted.length) {
    return null;
  }

  let createParams = "";
  let createParamsObj = {};
  let createMutations = "";

  let updateParams = "";
  let updateParamsObj = {};
  let updateMutations = "";

  let deleteParams = "";
  let deleteParamsObj = {};
  let deleteMutations = "";

  materials.forEach(({ materialId, uomId, quantity, assetId, assetVersion }, index) => {
    if (added.includes(materialId)) {
      createParams += `$createTaskAsset${index}: CreateTaskAssetsInput!, `;
      createParamsObj[`createTaskAsset${index}`] = {
        taskAssetsTaskId: taskId,
        taskAssetsAssetId: assetId,
      };
      createMutations += `
        createTaskAsset${index}: createTaskAssets(input: $createTaskAsset${index}) {
          id
          taskAssetsTaskId
          taskAssetsAssetId
          _version
          _lastChangedAt
          _deleted
        },\n
      `;
    } else if (updated.includes(materialId)) {
      updateParams += `$updateAsset${index}: UpdateAssetInput!, `;
      updateParamsObj[`updateAsset${index}`] = {
        id: assetId,
        _version: assetVersion,
        quantity,
        assetUomId: uomId || null,
      };
      updateMutations += `
        updateAsset${index}: updateAsset(input: $updateAsset${index}) {
          id
          trackingID
          type
          _version
          _lastChangedAt
          _deleted
        },\n
      `;
    }
  });

  deleted.forEach(({ id, _version }, index) => {
    deleteParams += `$deleteTaskAsset${index}: DeleteTaskAssetsInput!, `;
    deleteParamsObj[`deleteTaskAsset${index}`] = { id, _version };
    deleteMutations += `
      deleteTaskAsset${index}: deleteTaskAssets(input: $deleteTaskAsset${index}) {
        id
        taskAssetsTaskId
        taskAssetsAssetId
        _version
        _lastChangedAt
        _deleted
      },\n
    `;
  });

  const materialUpdateMutation = `
    mutation UpdateInstallationMaterials(
      ${createParams + updateParams + deleteParams}
      ) {
      ${createMutations + updateMutations + deleteMutations}
    }
  `;
  const materialUpdateParams = {
    ...createParamsObj,
    ...updateParamsObj,
    ...deleteParamsObj,
  };

  return { mutation: materialUpdateMutation, params: materialUpdateParams };
}
