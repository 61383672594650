/** componente que muestra mensajes en forma de snacks */
import { useCallback, useMemo } from "react";
import { OptionsObject, SnackbarKey, SnackbarMessage, useSnackbar } from "notistack";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";

function useNotifier() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const showMessage = useCallback(
    (message: SnackbarMessage, options: OptionsObject = {}): void => {
      const customOptions: OptionsObject = { ...options, style: { whiteSpace: "pre-line" } };
      enqueueSnackbar(
        message,
        !isMobileScreen
          ? customOptions
          : {
              ...customOptions,
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            }
      );
    },
    [enqueueSnackbar, isMobileScreen]
  );

  const showError = useCallback(
    (message: SnackbarMessage, options: OptionsObject = {}): void => {
      const customOptions: OptionsObject = { ...options, variant: "error" };
      enqueueSnackbar(
        message,
        !isMobileScreen
          ? customOptions
          : {
              ...customOptions,
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            }
      );
    },
    [enqueueSnackbar, isMobileScreen]
  );

  const closeMessage = useCallback(
    (key: SnackbarKey): void => {
      closeSnackbar(key);
    },
    [closeSnackbar]
  );

  return useMemo(
    () => ({
      showMessage,
      showError,
      closeMessage,
    }),
    [showMessage, showError, closeMessage]
  );
}

export default useNotifier;
