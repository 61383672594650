import React, { useCallback, useState, useEffect } from "react";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { Logger } from "@aws-amplify/core";
import SignatureCanvas from "react-signature-canvas";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/styles/makeStyles";
import useNotifier from "hooks/useNotifier";
import ConfirmationDialog from "components/ConfirmationDialog";
import LegalBoxModal from "components/LegalBox";
import { useForm } from "react-hook-form";
import useOnlineStatus from "@rehooks/online-status";
import { useIndexedDB } from "react-indexed-db";
import { Typography, TextField, Link } from "@material-ui/core";
import { handleTaskSignature, handleAddInspectionNote } from "./helpers/datastore";
import { uploadFile } from "util/file";
import useLoadingStatus from "hooks/useLoadingStatus";
import { EVIDENCE_TYPES } from "constant/evidenceTypes";
import FeedbackWithIcon from "components/custom/FeedbackWithIcon";
import useLocalStorageState from "hooks/useLocalStorageState";
import { addTaskIdToLocalStorageKey, clearLocalStorage } from "util/localStorage";
import { EMAIL_REGEX } from "util/forms";

const logger = new Logger("SignatureDialog");

const region = process.env.REACT_APP_AWS_REGION;
const bucket = process.env.REACT_APP_AWS_USER_FILES_S3_BUCKET;

const legalBoxDataDefaultState = {
  showModal: false,
  tabToShow: 0,
  showFooter: false,
};

const LS_FORM_KEY = "signature-form-state";

const FORM_KEY_NAMES = {
  validatorName: "validatorName",
  validatorEmail: "validatorEmail",
  inspectionNote: "inspectionNote",
};

const DEFAULT_FORM_VALUES = {
  [FORM_KEY_NAMES.validatorName]: "",
  [FORM_KEY_NAMES.validatorEmail]: "",
  [FORM_KEY_NAMES.inspectionNote]: "",
};

export default function SignatureDialog({
  isOpen,
  onClose,
  ticketId,
  taskId,
  onSigned,
  onAddInspectionNote,
  S3PrefixKey,
  taskStatusToUpdate,
}) {
  const theme = useTheme();
  const onlineStatus = useOnlineStatus();
  const imagesDb = useIndexedDB("offline_images");
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const { showError } = useNotifier();
  const [canvasRefState, setCanvasRefState] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [legalBoxModalState, setLegalBoxModalState] = useState(legalBoxDataDefaultState);
  const [isInspectionNoteSectionShowed, setIsInspectionNoteSectionShowed] = useState(false);
  const [isUploading, uploadFileWrapped] = useLoadingStatus(uploadFile);
  const [isValidating, _handleTaskSignature] = useLoadingStatus(handleTaskSignature);
  const [isAddingInspectionNote, _handleAddInspectionNote] = useLoadingStatus(handleAddInspectionNote);
  let { current: _LS_FORM_KEY_ } = React.useRef(addTaskIdToLocalStorageKey(LS_FORM_KEY, taskId));
  const [LSFormState, setLSFormState] = useLocalStorageState(_LS_FORM_KEY_, null, { storeFalsyValues: false });

  const { errors, getValues, trigger, register, setValue, reset } = useForm({
    mode: "onBlur",
    defaultValues: LSFormState || DEFAULT_FORM_VALUES,
  });

  const canvasRefCB = useCallback((node) => {
    if (node) {
      setCanvasRefState(node);
    }
  }, []);

  const dialogTitle = isInspectionNoteSectionShowed ? "SOLICITAR MODIFICACIONES" : "VALIDACIÓN";
  const cacheSignature = LSFormState?.signature || null;

  useEffect(() => {
    if (isOpen && !LSFormState) {
      setLSFormState({
        ...DEFAULT_FORM_VALUES,
        signature: null,
      });
    }
    if (isOpen && LSFormState) {
      Object.keys(LSFormState).forEach((fieldName) => setValue(fieldName, LSFormState[fieldName]));
    }
  }, [LSFormState, isOpen, setLSFormState, setValue]);

  useEffect(() => {
    if (cacheSignature && canvasRefState) {
      canvasRefState.fromData(cacheSignature);
    }
  }, [cacheSignature, canvasRefState]);

  const onChageFormFields = (event) => {
    //see https://reactjs.org/docs/legacy-event-pooling.html
    event.persist();
    setLSFormState((state) => ({ ...state, [event.target.name]: event.target.value }));
  };

  function clearFormAndLS() {
    clearCanvas();
    clearLocalStorage(_LS_FORM_KEY_);
    setLSFormState();
    reset(DEFAULT_FORM_VALUES);
  }

  function clearCanvas() {
    canvasRefState.clear();
    setLSFormState((state) => ({ ...state, signature: null }));
  }

  const clearCanvasErrorClassName = () => {
    canvasRefState._canvas.classList.remove(classes.canvasError);
  };

  const onCavasEmpty = () => {
    canvasRefState._canvas.classList.add(classes.canvasError);
  };

  const onEndSignature = () => {
    setLSFormState((state) => ({ ...state, signature: canvasRefState?.toData() || null }));
  };

  async function validateSignature() {
    const isNameValid = await trigger("validatorName");
    const isEmailValid = await trigger("validatorEmail");
    const isSignValid = !canvasRefState.isEmpty();

    if (!isNameValid && isEmailValid && !isSignValid) {
      showError("Para continuar añade tu nombre y tu firma de conformidad.");
      onCavasEmpty();
      return;
    }
    if (!isNameValid) {
      showError("Para continuar añade tu nombre.");
      return;
    }
    if (!isEmailValid) {
      showError("El formato del correo es invalido.");
      return;
    }
    if (!isSignValid) {
      showError("Para continuar debes colocar tu firma de conformidad.");
      onCavasEmpty();
      return;
    }
    setShowConfirmation(true);
  }

  async function validateInspectionNote() {
    const isFormValid = await trigger("inspectionNote");
    if (!isFormValid) {
      showError("Para continuar añade el motivo de la solicitud de ajustes.");
      return;
    }
    setShowConfirmation(true);
  }

  async function addInspectionNote() {
    const { inspectionNote } = getValues();
    try {
      const updatedData = await _handleAddInspectionNote(taskId, ticketId, inspectionNote);
      setShowConfirmation(false);
      setShowSuccessMsg(true);
      clearFormAndLS();
      await onAddInspectionNote(updatedData);
    } catch (error) {
      logger.error(error);
      showError("Algo salió mal intente de nuevo.");
    }
  }

  async function sendSignature() {
    const canvas = canvasRefState.getTrimmedCanvas();
    const { validatorName, validatorEmail } = getValues();
    canvas.toBlob(async (blob) => {
      const src = URL.createObjectURL(blob);
      logger.debug(src);
      try {
        const extension = blob.type.split("/")[1];
        const filename = `${S3PrefixKey}/attachments/validation_${dayjs().format("YYYYMMDDHHmmss")}.${extension}`;

        const signatureObject = {
          key: filename,
          bucket,
          region,
        };

        if (onlineStatus) {
          logger.debug("Se subira a S3");
          await uploadFileWrapped(filename, blob);
        } else {
          logger.debug("Se guardara de manera local en indexdb");
          // se guardará de manera local
          const attach = {
            id: ticketId || taskId,
            blob,
            type: EVIDENCE_TYPES.TICKET_VALIDATION,
            filename,
            file: {
              region,
              bucket,
              key: filename,
            },
          };
          await imagesDb.add(attach);
        }
        const updatedData = await _handleTaskSignature(
          ticketId,
          taskId,
          validatorName,
          validatorEmail,
          signatureObject,
          taskStatusToUpdate
        );

        setShowConfirmation(false);
        setShowSuccessMsg(true);
        clearFormAndLS();
        await onSigned(updatedData);
      } catch (error) {
        logger.error(error);
        showError("Algo salió mal intente de nuevo.");
      }
    });
  }

  const handleOpenLegalBoxModal = () =>
    setLegalBoxModalState((state) => ({
      ...state,
      showModal: true,
      setShowModalTerms: () => setLegalBoxModalState(legalBoxDataDefaultState),
    }));

  function closeSuccessMsgDialog() {
    setShowSuccessMsg(false);
    setIsInspectionNoteSectionShowed(false);
    clearFormAndLS();
    onClose();
  }

  function handleAddInspectionNoteButtonClick() {
    setIsInspectionNoteSectionShowed(true);
  }

  return (
    <>
      <Dialog maxWidth="md" fullWidth fullScreen={isMobileScreen} open={isOpen}>
        <DialogTitle align="center">{dialogTitle}</DialogTitle>
        <DialogContent dividers>
          {!isInspectionNoteSectionShowed && (
            <Box display="flex" flexDirection="column" gridGap="32px" className={classes.dialogContent}>
              <Box display="flex" flexDirection="column" gridGap="12px">
                <FeedbackWithIcon
                  description="Si la atención recibida no fue satisfactoria y require modificaciones, solicita los ajustes
                    necesarios."
                >
                  ¿La atención recibida no fue satisfactoria?
                </FeedbackWithIcon>

                <Button color="primary" variant="outlined" onClick={handleAddInspectionNoteButtonClick} fullWidth>
                  Solicitar Modificaciones
                </Button>
              </Box>
              <Box display="flex" flexDirection="column" gridGap="12px">
                <FeedbackWithIcon description="De ser satisfactorio el servicio, ingresa el nombre, correo y la rúbrica de quien valida la atención recibida.">
                  Ingrese el nombre y la firma de quien valida.
                </FeedbackWithIcon>
                <Box mt={1}>
                  <TextField
                    inputRef={register({ required: "Este campo es obligatorio." })}
                    onChange={onChageFormFields}
                    name={FORM_KEY_NAMES.validatorName}
                    defaultValue=""
                    type="text"
                    label="Nombre"
                    error={!!errors.validatorName}
                    helperText={errors.validatorName?.message || "Nombre de la persona que da el visto bueno."}
                    variant="outlined"
                    fullWidth
                    size="small"
                    required
                  />
                </Box>
                <Box mt={1}>
                  <TextField
                    name={FORM_KEY_NAMES.validatorEmail}
                    type="email"
                    onChange={onChageFormFields}
                    defaultValue=""
                    inputRef={register({ pattern: { value: EMAIL_REGEX, message: "Formato de correo invalido." } })}
                    label="Correo Electrónico"
                    error={!!errors.validatorEmail}
                    helperText={errors.validatorEmail?.message || "Correo de la persona que da el visto bueno."}
                    variant="outlined"
                    fullWidth
                    size="small"
                  />
                </Box>

                <Box className={classes.signatureBox}>
                  <SignatureCanvas
                    canvasProps={{
                      className: classes.canvas,
                    }}
                    ref={canvasRefCB}
                    clearOnResize={false}
                    onBegin={clearCanvasErrorClassName}
                    onEnd={onEndSignature}
                  />
                  <div className={classes.clearCanvasButton} onClick={clearCanvas}>
                    Borrar firma
                  </div>
                </Box>

                <Box>
                  <Link componente="button" onClick={handleOpenLegalBoxModal}>
                    Consulta nuestro aviso de privacidad
                  </Link>
                </Box>
                <Box minHeight="16px" width="100%" />
              </Box>
            </Box>
          )}
          {isInspectionNoteSectionShowed && (
            <>
              <Box mt={3}>
                <Typography gutterBottom>
                  Escribe los mótivos por los que se solicitan modificaciones a la atención dada.
                </Typography>

                <TextField
                  inputRef={register({ required: "Este campo es obligatorio." })}
                  name={FORM_KEY_NAMES.inspectionNote}
                  defaultValue=""
                  onChange={onChageFormFields}
                  type="text"
                  label="Modificaciones"
                  error={!!errors.inspectionNote}
                  helperText={errors.inspectionNote?.message || "Ingrese el mótivo de la solicitud de ajustes."}
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  required
                />
              </Box>
            </>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            color="default"
            onClick={isInspectionNoteSectionShowed ? () => setIsInspectionNoteSectionShowed(false) : onClose}
            disabled={isUploading || isValidating || isAddingInspectionNote}
          >
            Cancelar
          </Button>
          <Button
            color="primary"
            onClick={isInspectionNoteSectionShowed ? validateInspectionNote : validateSignature}
            disabled={isUploading || isValidating || isAddingInspectionNote}
          >
            Continuar
          </Button>
        </DialogActions>
      </Dialog>

      <ConfirmationDialog
        open={showConfirmation}
        title="Confirmación"
        onCancel={() => setShowConfirmation(false)}
        onConfirm={isInspectionNoteSectionShowed ? addInspectionNote : sendSignature}
        loading={isUploading || isValidating || isAddingInspectionNote}
      >
        <DialogContentText color="textPrimary">
          {isInspectionNoteSectionShowed ? (
            <>Se registrará una observación para que el ingeniero de soporte pueda atenderla de nuevo.</>
          ) : (
            <>Se registrará la firma de conformidad del servicio de soporte recibido.</>
          )}
        </DialogContentText>
        <br />
        <DialogContentText color="textPrimary">¿Deseas continuar?</DialogContentText>
      </ConfirmationDialog>

      <ConfirmationDialog
        open={showSuccessMsg}
        title="Mensaje"
        onCancel={closeSuccessMsgDialog}
        cancelBtnText="Cerrar"
        showOkBtn={false}
      >
        {isInspectionNoteSectionShowed ? (
          <DialogContentText color="textPrimary">
            Se ha registrado la observación para el ingeniero de soporte, se atenderá de nuevo el ticket.
          </DialogContentText>
        ) : (
          <>
            <DialogContentText color="textPrimary">
              Se ha registrado la firma de conformidad del servicio de soporte.
            </DialogContentText>
            <DialogContentText color="textPrimary">
              En breve llegará un correo electrónico con el detalle del servicio recibido.
            </DialogContentText>
          </>
        )}
      </ConfirmationDialog>

      <LegalBoxModal {...legalBoxModalState} />
    </>
  );
}

SignatureDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSigned: PropTypes.func,
  onAddInspectionNote: PropTypes.func,
  taskId: PropTypes.string.isRequired,
  ticketId: PropTypes.string,
  S3PrefixKey: PropTypes.string.isRequired,
  taskStatusToUpdate: PropTypes.string.isRequired,
};

SignatureDialog.defaultProps = {
  isOpen: false,
  onClose: () => {},
  onSigned: async () => {},
  onAddInspectionNote: async () => {},
};

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    height: "100%",
  },
  canvas: {
    width: "100%",
    height: 200,
    border: `1px solid grey`,
    borderRadius: 4,
    [theme.breakpoints.down("sm")]: {
      flex: 1,
    },
  },
  canvasError: {
    border: `1px solid ${theme.palette.error.main}`,
  },
  signatureBox: {
    position: "relative",
  },
  clearCanvasButton: {
    position: "absolute",
    bottom: "16px",
    right: "16px",
    width: "80px",
    height: "40px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  },
}));
