// Componente tipo dialog para la creación de una instalación.
import React, { useState, useEffect, useRef, useMemo } from "react";
import PropTypes from "prop-types";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Collapse from "@material-ui/core/Collapse";
import DialogContentText from "@material-ui/core/DialogContentText";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import makeStyles from "@material-ui/core/styles/makeStyles";

import ConfirmationDialog from "components/ConfirmationDialog";

import TaskProgressTracker from "../TaskProgressTracker";
import EvidenceViewV2 from "components/common/EvidenceViewV2";
import TaskStepper from "components/custom/TaskStepper";

import useNotifier from "hooks/useNotifier";
import useLoadingStatus from "hooks/useLoadingStatus";
import useWatchPosition from "hooks/useWatchPosition";
import { useIndexedDB } from "react-indexed-db";
import useStepper from "hooks/useStepper";

import * as dataStoreHelpers from "./helpers/datastore";
import { TaskStatus } from "models";
import { filterEvidencesByStatus } from "components/Tickets/TicketActivityTrackingDialog/helpers";
import { ACTIVITY_CATEGORY, S3_KEY_PREFIXES } from "constant/attachments";
import { TASK_TYPES } from "constant/task/types";

export const STEPS = {
  BEGIN_INSTALLATION: 0,
  INSTALLATION_IN_PROGRESS: 1,
  END_INSTALLATION: 2,
};

const STEPS_MOCK_STATE = [
  {
    step: STEPS.BEGIN_INSTALLATION,
    stepKeyname: "BEGIN_INSTALLATION",
    isStepCompleted: false,
    isLastStep: true,
  },
  {
    step: STEPS.INSTALLATION_IN_PROGRESS,
    stepKeyname: "INSTALLATION_IN_PROGRESS",
    isStepCompleted: false,
    isLastStep: false,
  },
  {
    step: STEPS.END_INSTALLATION,
    stepKeyname: "END_INSTALLATION",
    isStepCompleted: false,
    isLastStep: false,
  },
];

const MIN_START_ATTACHMENTS = 3;
const MIN_PROGRESS_ATTACHMENTS = 4;
const MIN_END_ATTACHMENTS = 1;

function countInProgressAttachments(attachments = []) {
  const attachmentsCount = attachments.reduce((prev, current) => {
    if (current.status === TaskStatus.IN_PROGRESS) return prev + 1;
    return prev;
  }, 0);

  return attachmentsCount;
}

function calculateStepsState({
  stepsState,
  currentStep,
  attachmentsByStatus = { beginning: [], inProgress: [], completed: [] },
} = {}) {
  const newStepsState = stepsState.map((stepState) => {
    const isLastStep = currentStep === stepState.step;
    let isStepCompleted = false;

    if (stepState.step === STEPS.BEGIN_INSTALLATION) {
      isStepCompleted = currentStep > STEPS.BEGIN_INSTALLATION;
    }
    if (stepState.step === STEPS.INSTALLATION_IN_PROGRESS) {
      isStepCompleted = attachmentsByStatus.inProgress.length >= MIN_PROGRESS_ATTACHMENTS;
    }
    if (stepState.step === STEPS.END_INSTALLATION) {
      isStepCompleted = attachmentsByStatus.completed.length >= MIN_END_ATTACHMENTS;
    }

    return {
      ...stepState,
      isStepCompleted,
      isLastStep,
    };
  });
  const newCurrentStepState = newStepsState.find((stepState) => stepState.isLastStep);
  return {
    stepsState: newStepsState,
    currentStepState: newCurrentStepState,
  };
}

function InstallationDialog(props) {
  const {
    stepsState: _stepsState,
    setStepsState,
    handleNext,
    handleBack,
  } = useStepper({
    currentStepState: STEPS_MOCK_STATE[0],
    stepsState: STEPS_MOCK_STATE,
  });
  const { stepsState, currentStepState: currentStep } = _stepsState;
  const [confirmStartDialogOpen, setConfirmStartDialogOpen] = useState(false);
  const [confirmEndDialogOpen, setConfirmEndDialogOpen] = useState(false);
  const evidenceRef = useRef(null);
  const evidenceRef2 = useRef(null);

  const theme = useTheme();
  const classes = useStyles({ currentStep: currentStep.step });
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const notifier = useNotifier();
  const imagesDb = useIndexedDB("offline_images");
  const [starting, startInstallationWrapped] = useLoadingStatus(dataStoreHelpers.startInstallation);
  const [ending, endInstallationWrapped] = useLoadingStatus(dataStoreHelpers.endInstallation);
  const [addingAttachment, setAddingAttachment] = useState(false);
  const isLoading = starting || ending || addingAttachment;

  const { attachments = [] } = props.installationDetails;
  const inProgressAttchsCount = countInProgressAttachments(attachments);
  const { position, loading: loadingPosition, error: positionError } = useWatchPosition();

  const attachmentsByStatus = useMemo(() => {
    return filterEvidencesByStatus(attachments);
  }, [attachments]);

  const isNextStepDisabled = useMemo(() => {
    let isDisabled = false;
    const INITIAL_EVIDENCE_STEP_VALID = attachmentsByStatus.beginning.length >= MIN_START_ATTACHMENTS;
    const PROGRESS_EVIDENCE_STEP_VALID = attachmentsByStatus.inProgress.length >= MIN_PROGRESS_ATTACHMENTS;
    const END_EVIDENCE_STEP_VALID = attachmentsByStatus.completed.length >= MIN_END_ATTACHMENTS;
    const HAS_ASSETS = props.installationMaterials.length;

    if (
      (currentStep.step === STEPS.BEGIN_INSTALLATION && !INITIAL_EVIDENCE_STEP_VALID) ||
      (currentStep.step === STEPS.INSTALLATION_IN_PROGRESS && (!PROGRESS_EVIDENCE_STEP_VALID || !HAS_ASSETS)) ||
      (currentStep.step === STEPS.END_INSTALLATION && !END_EVIDENCE_STEP_VALID)
    ) {
      isDisabled = true;
    }

    return isDisabled;
  }, [
    attachmentsByStatus.beginning.length,
    attachmentsByStatus.completed.length,
    attachmentsByStatus.inProgress.length,
    currentStep.step,
    props.installationMaterials.length,
  ]);

  useEffect(() => {
    if (props.open) {
      setStepsState(({ setAcstepsState }) =>
        calculateStepsState({ stepsState, currentStep: props.step, attachmentsByStatus })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  function handleBackBtnClick() {
    switch (currentStep.step) {
      case STEPS.BEGIN_INSTALLATION:
        props.onCancel();
        break;
      case STEPS.INSTALLATION_IN_PROGRESS:
        handleBack();
        break;
      case STEPS.END_INSTALLATION:
        handleBack();
        break;
      default:
        handleBack();
    }
  }

  function handleNextBtnClick() {
    switch (currentStep.step) {
      case STEPS.BEGIN_INSTALLATION: {
        if (attachmentsByStatus.beginning.length >= MIN_START_ATTACHMENTS) {
          if (currentStep.isStepCompleted) {
            handleNext();
          } else {
            setConfirmStartDialogOpen(true);
          }
        } else {
          notifier.showMessage(`Se requieren ${MIN_START_ATTACHMENTS} evidencias para iniciar la instalación`, {
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
        break;
      }

      case STEPS.INSTALLATION_IN_PROGRESS: {
        const usedMaterialCount = props.installationMaterials.reduce((prev, current) => {
          if (current?.asset?.asset?.type === "USED" || current?.asset?.asset?.type === "REUSED") {
            return (prev += 1);
          } else {
            return prev;
          }
        }, 0);

        if (usedMaterialCount >= 1) {
          const attachments = props.installationDetails.attachments || [];
          let progressAttachments = 0;
          attachments.forEach((a) => {
            if (a.status === "IN_PROGRESS") {
              progressAttachments++;
            }
          });

          if (progressAttachments >= MIN_PROGRESS_ATTACHMENTS) {
            handleNext();
          } else {
            notifier.showMessage(`Se requieren ${MIN_PROGRESS_ATTACHMENTS} evidencias`, {
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            });
          }
        } else {
          notifier.showMessage("Debe agregar al menos un material para continuar con la instalación", {
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
        break;
      }

      case STEPS.END_INSTALLATION: {
        if (attachmentsByStatus.completed.length >= MIN_END_ATTACHMENTS) {
          setConfirmEndDialogOpen(true);
        } else {
          notifier.showMessage(`Se requieren ${MIN_END_ATTACHMENTS} evidencias para finalizar la instalación`, {
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
        break;
      }

      default:
    }
  }

  function handleDialogExited() { }

  function handleStartDialogConfirm() {
    const taskID = props?.installationDetails?.task?.id;
    startInstallationWrapped(taskID, attachmentsByStatus.beginning, imagesDb)
      .then((updatedTask) => {
        props.onInstallationUpdate(updatedTask);
        handleNext();
        setConfirmStartDialogOpen(false);
      })
      .catch((err) => {
        console.error(err);
        notifier.showMessage("Ocurrió un error inesperado");
      });
  }

  function handleStartDialogCancel() {
    setConfirmStartDialogOpen(false);
  }

  async function handleEndDialogConfirm() {
    try {
      const updatedTask = await endInstallationWrapped(props?.installationDetails?.task?.id);
      notifier.showMessage("Instalación finalizada y pendiente de validación");
      props.onSuccess({ ...props.installationDetails, task: updatedTask });
      setConfirmEndDialogOpen(false);
    } catch (err) {
      console.error(err);
      notifier.showMessage("Ocurrió un error inesperado");
      setConfirmEndDialogOpen(false);
    }
  }

  function handleEndDialogCancel() {
    setConfirmEndDialogOpen(false);
  }

  const title =
    currentStep.step === STEPS.BEGIN_INSTALLATION
      ? "Iniciando Instalación"
      : currentStep.step === STEPS.INSTALLATION_IN_PROGRESS
        ? "Instalación en Curso"
        : "Finalizando Instalación";
  const evidenceListHeight = fullScreen
    ? "calc(100vh - 90px - 60px - 72px - 48px - 8px)"
    : "calc(100vh - 90px - 60px - 72px - 48px - 80px - 8px)";

  const taskID = props?.installationDetails?.task?.id;
  const attachmentPrefix = `${S3_KEY_PREFIXES.EVIDENCES_UNCOMPRESSED}/${ACTIVITY_CATEGORY.IMPLEMENTATION}/${taskID}`;

  return (
    <>
      <Dialog
        open={props.open}
        onExited={handleDialogExited}
        fullScreen={fullScreen}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          <TaskStepper
            isLoading={isLoading}
            isStepCompleted={currentStep.isStepCompleted}
            isFirstStep={currentStep.step === STEPS.BEGIN_INSTALLATION}
            title={title.toUpperCase()}
            currentStep={currentStep}
            steps={stepsState}
            onNextClick={handleNextBtnClick}
            onBackClick={handleBackBtnClick}
            onBackLongPressClick={() => props.onCancel()}
            isNextStepDisabled={isNextStepDisabled}
          />
        </DialogTitle>
        <DialogContent dividers className={classes.dialogContent} classes={{ dividers: classes.dialogContentDividers }}>
          {/* Primer paso (sin bandera newInstallation). Inicio de instalación */}
          <Collapse in={currentStep.step === STEPS.BEGIN_INSTALLATION} timeout={0}>
            <DialogContentText className={classes.description} color="textPrimary">
              Para iniciar el proceso de la instalación deberás adjuntar fotografías de la zona de trabajo previo a la
              instalación de los activos.
            </DialogContentText>
            <EvidenceViewV2
              taskId={props.taskId}
              prefix={attachmentPrefix}
              status={TaskStatus.SCHEDULED}
              min={MIN_START_ATTACHMENTS}
              inputName="begin_inputFile"
              ref={evidenceRef}
              attachments={attachmentsByStatus.beginning}
              loading={addingAttachment}
              onLoading={setAddingAttachment}
              onEvidenceChange={props.addOptimisticAttachments}
              onDeleteItem={props.deleteOptimisticAttachment}
              taskType={TASK_TYPES.INSTALLATION}
              evidenceListHeight={evidenceListHeight}
              position={position}
              loadingPosition={loadingPosition}
              positionError={positionError}
            />
          </Collapse>
          {/* Segundo paso. Registro de materiales y evidencias */}
          <Collapse in={currentStep.step === STEPS.INSTALLATION_IN_PROGRESS} timeout={0}>
            <TaskProgressTracker
              taskId={props?.installationDetails?.task?.id || ""}
              initialAttachments={props?.installationDetails?.attachments || []}
              initialMaterials={props.installationMaterials}
              addOptimisticMaterial={props.addOptimisticMaterial}
              deleteOptimisticMaterial={props.deleteOptimisticMaterial}
              updateOptimisticMaterial={props.updateOptimisticMaterial}
              addOptimisticAttachments={props.addOptimisticAttachments}
              deleteOptimisticAttachment={props.deleteOptimisticAttachment}
              updateOptimisticAttachment={props.updateOptimisticAttachment}
              attachmentsCount={inProgressAttchsCount}
              taskType={TASK_TYPES.INSTALLATION}
              position={position}
              loadingPosition={loadingPosition}
              positionError={positionError}
            />
          </Collapse>
          {/* Tercer paso. Finalización y registro de evidencia */}
          <Collapse in={currentStep.step === STEPS.END_INSTALLATION} timeout={0}>
            <DialogContentText className={classes.description} color="textPrimary">
              Para concluir con el proceso de instalación deberás adjuntar la fotografía final de la tarea.
            </DialogContentText>
            <EvidenceViewV2
              taskId={props.taskId}
              prefix={attachmentPrefix}
              status={TaskStatus.COMPLETED}
              min={MIN_END_ATTACHMENTS}
              inputName="completed_inputFile"
              ref={evidenceRef2}
              attachments={attachmentsByStatus.completed}
              loading={addingAttachment}
              onLoading={setAddingAttachment}
              onEvidenceChange={props.addOptimisticAttachments}
              onDeleteItem={props.deleteOptimisticAttachment}
              taskType={TASK_TYPES.INSTALLATION}
              evidenceListHeight={evidenceListHeight}
              position={position}
              loadingPosition={loadingPosition}
              positionError={positionError}
            />
          </Collapse>
        </DialogContent>
      </Dialog>
      <ConfirmationDialog
        open={confirmStartDialogOpen}
        title="Confirmación"
        onConfirm={handleStartDialogConfirm}
        onCancel={handleStartDialogCancel}
        loading={starting}
      >
        ¿Estás seguro que vas a empezar?
      </ConfirmationDialog>
      <ConfirmationDialog
        open={confirmEndDialogOpen}
        title="Confirmación"
        onConfirm={handleEndDialogConfirm}
        onCancel={handleEndDialogCancel}
        loading={ending}
      >
        ¿Estás seguro que terminaste?
      </ConfirmationDialog>
    </>
  );
}

InstallationDialog.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
  taskId: PropTypes.string.isRequired,
  onBack: PropTypes.func,
  onNext: PropTypes.func,
  onInstallationUpdate: PropTypes.func,
  newInstallation: PropTypes.bool,
  installationDetails: PropTypes.shape(),
  installationMaterials: PropTypes.array,
  step: PropTypes.oneOf(Object.values(STEPS)),
  addOptimisticMaterial: PropTypes.func,
  deleteOptimisticMaterial: PropTypes.func,
  updateOptimisticMaterial: PropTypes.func,
  addOptimisticAttachments: PropTypes.func,
  deleteOptimisticAttachment: PropTypes.func,
  updateOptimisticAttachment: PropTypes.func,
};

InstallationDialog.defaultProps = {
  open: false,
  onCancel: () => { },
  taskId: "",
  onBack: () => { },
  onNext: () => { },
  onInstallationUpdate: (newData) => { },
  newInstallation: false,
  step: STEPS.BEGIN_INSTALLATION,
  installationMaterials: [],
  addOptimisticMaterial: () => { },
  deleteOptimisticMaterial: () => { },
  updateOptimisticMaterial: () => { },
  addOptimisticAttachments: () => { },
  deleteOptimisticAttachment: () => { },
  updateOptimisticAttachment: () => { },
};

export default InstallationDialog;

const useStyles = makeStyles((theme) => ({
  dialogContent: ({ currentStep }) => ({
    overflow: [STEPS.BEGIN_INSTALLATION, STEPS.END_INSTALLATION].includes(currentStep) ? "hidden" : "auto",
    height: "calc(100vh - 90px)",
    marginBottom: [STEPS.BEGIN_INSTALLATION, STEPS.END_INSTALLATION].includes(currentStep) ? "48px" : "0px",
  }),
  dialogContentDividers: {
    padding: "16px 24px",
    borderTop: "5px solid rgba(16, 82, 133, 0.2)",
    borderBottom: "none",
  },
  description: {
    fontSize: "0.875rem",
  },
}));
