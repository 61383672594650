import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Hidden from "@material-ui/core/Hidden";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";

function ListDrawer(props) {
  const { tapsObjet, indexModule, handle, logo } = props;
  const [value, setValue] = useState(0);

  useEffect(() => {
    setValue(0);
  }, [indexModule]);

  function a11yProps(index) {
    return {
      id: `scrollable-auto-tabpanel-${index}`,
      "aria-controls": `scrollable-auto-tab-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
    handle(newValue);
  };

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Hidden xsDown implementation="css">
        <div className={classes.toolbar}>
          <img src={`/img/${logo}`} alt="logoM" className={classes.logo} />
        </div>
      </Hidden>
      <Divider />
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs"
        className={classes.tabs}
        TabIndicatorProps={{ className: classes.indicator }}
      >
        {tapsObjet.subtitle.map((item, index) => (
          <Tab key={`tab-${index}`} label={item} {...a11yProps(index)} tabIndex={0} />
        ))}
      </Tabs>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  logo: {
    maxWidth: 106,
    marginLeft: "45px",
    marginTop: "18px",
  },
  logoM: {
    maxWidth: 130,
    marginRight: "auto",
    marginLeft: "auto",
  },
  root: {
    "& .Mui-selected": {
      color: "#316c98",
    },
  },
  tabs: {
    "& .MuiTab-wrapper": {
      textAlign: "left",
      justifyContent: "space-between",
      flexDirection: "initial",
      textTransform: "none",
    },
    "& .MuiTab-root": {
      padding: "14px 12px 3px 48px",
    },
  },
  indicator: {
    background: "#4b97cd",
    width: "5px",
  },
}));

ListDrawer.propTypes = {
  tapsObjet: PropTypes.object,
  indexModule: PropTypes.number,
  handle: PropTypes.func,
  logo: PropTypes.string,
};

ListDrawer.defaultProps = {
  tapsObjet: {},
  indexModule: 0,
  handle: () => {},
  logo: "indika-color-logo.png",
};

export default ListDrawer;
