/** Archivo de constantes de uris para el componente de aministración de la aplicación */
export const ROOT = "/admin";

/** Rutar generales */
export const CATALOGS = `${ROOT}/site-catalogs`;
export const GENERAL_CATALOGS = `${ROOT}/general_catalogs`;
export const ASSIGMENTS = `${ROOT}/assignments`;
export const USERS = `${ROOT}/usuarios`;
export const USER_DETAILS = `${ROOT}/usuarios/:id`;
export const DOMAINS = `${ROOT}/config/dominios`;
export const CONTRACTS_AND_NOMENCLATURES = `${ROOT}/contracts`;
export const SETTINGS = `${ROOT}/settings`;
export const ACTIVITIES = `${ROOT}/tipo-actividad`;
export const COMPANY = `${ROOT}/clients`;

/** Rutas especificas */
export const COMPANY_DETAIL = `${COMPANY}/:id`;

export const PRODUCTS_CATALOG = `${GENERAL_CATALOGS}/products`;
export const NEW_PRODUCT = `${PRODUCTS_CATALOG}/product`;
export const PRODUCT_ITEM_VIEW = `${PRODUCTS_CATALOG}/product/:productId`;

export const WAREHOUSES_CATALOG = `${GENERAL_CATALOGS}/warehouses`;
export const NEW_WAREHOUSE = `${WAREHOUSES_CATALOG}/warehouse`;

export const CUSTOMERS_CATALOG = `${GENERAL_CATALOGS}/customers`;
export const NEW_CUSTOMER = `${CUSTOMERS_CATALOG}/customer`;
export const OPTYPES_CATALOG = `${CATALOGS}/optypes`;
export const NEW_OPTYPE = `${CATALOGS}/optype`;
export const GOPTYPES_CATALOG = `${GENERAL_CATALOGS}/goptypes`;
export const NEW_GOPTYPE = `${GOPTYPES_CATALOG}/goptype`;
export const FIRMS_CATALOG = `${GENERAL_CATALOGS}/firms`;
export const ITEMGROUP_CATALOG = `${GENERAL_CATALOGS}/families`;
export const NEW_ITEMGROUP = `${GENERAL_CATALOGS}/families/family`;
export const COMMODITIES_CATALOG = `${GENERAL_CATALOGS}/commodities`;
export const NEW_COMMODITY = `${GENERAL_CATALOGS}/commodities/commodity`;
export const TECHNICIANS_CATALOG = `${GENERAL_CATALOGS}/technicians`;
export const NEW_TECHNICIAN = `${GENERAL_CATALOGS}/technicians/technician`;
export const MANTTO_TYPE_CATALOG = `${GENERAL_CATALOGS}/mantto_types`;
export const NEW_MANTTO_TYPE = `${GENERAL_CATALOGS}/mantto_types/mantto_type`;
export const NEW_FIRM = `${FIRMS_CATALOG}/firm`;
export const DOCTORS_CATALOG = `${CATALOGS}/doctors`;
export const NEW_DOCTOR = `${CATALOGS}/doctor`;
export const SHIFTS_CATALOG = `${CATALOGS}/shifts`;
export const NEW_SHIFT = `${CATALOGS}/shift`;
export const SURGICALROOMS_CATALOG = `${CATALOGS}/surgical_rooms`;
export const NEW_SURGICALROOM = `${CATALOGS}/surgical_room`;
export const SAP_BO_SETTINGS = `${CATALOGS}/sap_bo_settings`;
export const WAREHOUSE_TRANSIT_POLITIC = `${CATALOGS}/wh_transit_politic`;

export const CONTRACTS_CATALOG = `${GENERAL_CATALOGS}/contracts`;
export const NEW_CONTRACT = `${CONTRACTS_CATALOG}/contract`;
export const CONTRACT_ASSIGNMENT = `${ASSIGMENTS}/contract_assigment`;
export const ASSIGN_ITEM_TO_CONTRACT = `${ASSIGMENTS}/items-to-contract/:contractId`;
export const WAREHOUSE_BILLING_WH_ASSIGMENT = `${ASSIGMENTS}/warehouse_billing_wh_assigment`;
export const PROJECT_WAREHOUSES_ASSIGMENT = `${ASSIGMENTS}/project_warehouses_assigment`;
export const WAREHOUSE_CLIENT_ASSIGMENT = `${ASSIGMENTS}/warehouse_client_assigment`;
export const WAREHOUSE_SDR_ASSIGMENT = `${ASSIGMENTS}/warehouse_distribution_rules_assigment`;

export const IKUSERS_CATALOG = `${USERS}/ikusers`;
export const NEW_IKUSER = `${USERS}/ikuser`;
export const IKUSER = `${USERS}/ikuser/:userId`;
export const ROLES = `${ROOT}/roles`;
export const NEW_ROLE = `${ROOT}/role`;
export const EDIT_ROLE = `${ROOT}/role/:roleId`;
export const SCOPES = `${ROOT}/scopes`;
export const NEW_SCOPE = `${ROOT}/scope`;
export const EDIT_SCOPE = `${ROOT}/scope/:scopeId`;

export const SAP_BUSINESS_PARTNERS_CATALOG = `${GENERAL_CATALOGS}/sap_business_partners`;
export const SAP_DISTRIBUTION_RULES_CATALOG = `${GENERAL_CATALOGS}/sap_distribution_rules`;
export const SAP_PROJECT_CATALOG = `${GENERAL_CATALOGS}/sap_projects`;
export const SAP_NOMENCLATURES = `${GENERAL_CATALOGS}/sap_nomenclatures`;
export const SAP_QUERY_GROUPS = `${GENERAL_CATALOGS}/sap_qryGroups`;

export const NOMENCLAUTRES_ASSIGNMENT = `${CONTRACTS_AND_NOMENCLATURES}/nomenclatures`;

export const PREMISES_CATALOG = `${ROOT}/sitios`;
export const PREMISES_DETAILS = `${PREMISES_CATALOG}/:id`;
export const PREMISES_SYNC_CATALOG = `${ROOT}/sincronizar/sitios`;
export const MATERIAL_CATALOG = `${ROOT}/materiales`;
export const MATERIAL_CATALOG_SYNC = `${ROOT}/sincronizar/materiales`;

export const UOM_CATALOG = `${ROOT}/uoms`;
export const UOM_CATALOG_SYNC = `${ROOT}/sincronizar/uoms`;
export const UOM_DATA_LOAD = `${ROOT}/carga-manual/uoms`;
export const MATERIAL_DATA_LOAD = `${ROOT}/carga-manual/materiales`;
export const MATERIAL_CATEGORY_DATA_LOAD = `${ROOT}/carga-manual/categorias`;

export const MATERIAL_CATEGORY_CATALOG = `${ROOT}/categorias`;
export const INTEGRATIONS = `${ROOT}/integraciones`;

const ADMIN = {
  COMPANY,
  COMPANY_DETAIL,
  ACTIVITIES,
  PREMISES_CATALOG,
  MATERIAL_CATEGORY_CATALOG,
  DOMAINS,
  ROOT,
  CATALOGS,
  USERS,
  USER_DETAILS,
  ASSIGMENTS,
  ROLES,
  EDIT_ROLE,
  PRODUCTS_CATALOG,
  NEW_PRODUCT,
  PRODUCT_ITEM_VIEW,
  WAREHOUSES_CATALOG,
  NEW_WAREHOUSE,
  CONTRACTS_CATALOG,
  IKUSERS_CATALOG,
  IKUSER,
  NEW_CONTRACT,
  NEW_IKUSER,
  CONTRACT_ASSIGNMENT,
  ASSIGN_ITEM_TO_CONTRACT,
  SCOPES,
  NEW_SCOPE,
  EDIT_SCOPE,
  CUSTOMERS_CATALOG,
  OPTYPES_CATALOG,
  FIRMS_CATALOG,
  DOCTORS_CATALOG,
  SHIFTS_CATALOG,
  SURGICALROOMS_CATALOG,
  NEW_CUSTOMER,
  NEW_OPTYPE,
  NEW_FIRM,
  NEW_DOCTOR,
  NEW_SHIFT,
  NEW_SURGICALROOM,
  GOPTYPES_CATALOG,
  SAP_BO_SETTINGS,
  NEW_GOPTYPE,
  CONTRACTS_AND_NOMENCLATURES,
  NOMENCLAUTRES_ASSIGNMENT,
  WAREHOUSE_BILLING_WH_ASSIGMENT,
  PROJECT_WAREHOUSES_ASSIGMENT,
  SETTINGS,
  SAP_BUSINESS_PARTNERS_CATALOG,
  SAP_DISTRIBUTION_RULES_CATALOG,
  SAP_PROJECT_CATALOG,
  SAP_NOMENCLATURES,
  WAREHOUSE_CLIENT_ASSIGMENT,
  WAREHOUSE_SDR_ASSIGMENT,
  ITEMGROUP_CATALOG,
  NEW_ITEMGROUP,
  COMMODITIES_CATALOG,
  NEW_COMMODITY,
  TECHNICIANS_CATALOG,
  NEW_TECHNICIAN,
  MANTTO_TYPE_CATALOG,
  NEW_MANTTO_TYPE,
  WAREHOUSE_TRANSIT_POLITIC,
  SAP_QUERY_GROUPS,
  MATERIAL_CATALOG,
  MATERIAL_CATALOG_SYNC,
  UOM_CATALOG,
  UOM_CATALOG_SYNC,
};

export default ADMIN;
