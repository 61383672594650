import { AssetType } from "models";

export function generateNewAssetsMutation(materials = [], added = [], status = AssetType.ALLOCATED) {
  let createParams = "";
  let createParamsObj = {};
  let createMutations = "";

  added.map((materialId, index) => {
    const material = materials.find((m) => m.materialId === materialId);

    if (!material) {
      return undefined;
    }

    createParams += `$materialId${index}: ID!, $quantity${index}: Float!, $uomId${index}: ID, $code${index}: String!, `;
    createParamsObj[`materialId${index}`] = materialId;
    createParamsObj[`quantity${index}`] = material.quantity;
    createParamsObj[`uomId${index}`] = material.uomId || null;
    createParamsObj[`code${index}`] = material.code || "DEFAULT_CODE";
    createMutations += `
      createAsset${index}: createAsset(input: {
        assetMaterialId: $materialId${index}
        assetUomId: $uomId${index}
        quantity: $quantity${index}
        type: ${status}
        trackingID: "unassigned"
        serial: "DEFAULT_SERIE"
        code: $code${index}
      }) {
        id
        serial
        code
        quantity
        locationCode
        trackingID
        type
        material {
          id
          code
          name
          _version
          _lastChangedAt
          _deleted
        }
        uom {
          id
          name
          _version
          _deleted
          _lastChangedAt
        }
        _version
        _lastChangedAt
        _deleted
      },\n
    `;
  });

  const mutation = `
    mutation CreateAsset(${createParams}) {
      ${createMutations}
    }
  `;

  return { mutation, params: createParamsObj };
}
